import { useState, useEffect } from 'react';
import { ContractService } from '../../../core/services/ContractService';
import { Singleton } from '../../../core/services/Singleton';
interface Props {
    idSelector: number;
    onChange: any;
    filterState?: number;
    _idPlan: number;
    _label?: string;
    _returnObject?: boolean;
}
export const SelectAdquisitionPlanFunctionalIDByPlan = ({ filterState, idSelector, onChange, _idPlan, _label, _returnObject }: Props) => {

    const single = Singleton.getInstance();
    const service = new ContractService();
    const [lista, setLista] = useState([]);
    const [id, setID] = useState(NaN);

    useEffect(() => {
        setID(idSelector);
    }, [idSelector]);

    useEffect(() => {
        if (_idPlan > 0) {
            getLista();
        }
    }, [_idPlan]);

    const setValue = (e: any) => {
        const selectedID = parseInt(e);
        setID(selectedID);
        if (_returnObject && selectedID) {
            const selectedObject = lista.find((item: any) => item?.DataBeanProperties?.IDAdquisitionPlanFunctionalID === selectedID);
            onChange(selectedObject?.DataBeanProperties);
        } else {
            onChange(selectedID);
        }
    };

    const getLista = () => {
        service.getAdquisitionPlanFunctionalIDCatalog(_idPlan).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue) {
                    let tmp =resp.DataBeanProperties.ObjectValue;
                    setLista(tmp);

                }
            }
        );
    };

    return (
        <>
            <label>{_label ?? "Área Funcional"}</label>
            <select className="form-control" value={id} onChange={(e) => { setValue(e.target.value) }}>
                <option value={NaN}>...</option>
                {lista &&
                    lista.map((item: any) => {
                        return (
                            <option key={item?.DataBeanProperties?.IDAdquisitionPlanFunctionalID} value={item?.DataBeanProperties?.IDAdquisitionPlanFunctionalID}>
                                {item?.DataBeanProperties?.IDLnFunctionalIDName}
                            </option>
                        );
                    })
                }
            </select>
        </>
    );
};
