import { useEffect, useState } from 'react';
interface Props {
    taps: any;
    index: number;
    updateIndex: any;
    children: any;
}
export const CardTaps = ({ taps, index, updateIndex, children }: Props) => {


    // const [taps] = useState([
    //     { id: 0, name: 'Documentos' },
    //     { id: 1, name: 'Visor',disable:false }
    // ]);


    // let taps = [
    //     { id: 0, name: 'Documentos', disable: false },
    //     { id: 1, name: 'Visor', disable: lista.length===0 }
    // ];

    // const [indexTap, setIndexTap] = useState(0);

    const [disableMenu, setDisableMenu] = useState('');
    const [tap, setTap] = useState<any>([]);

    useEffect(() => {
        setTap(taps);

    }, [taps])

    const isDisable = (menu: any) => {
        let dis = "";
        if (menu.disable) {
            if (menu.disable === true)
                dis = "disabled-li";

        }
        return dis;
    }

    return (
        <>

            <div className="card">
                <div className="card-header align-items-center d-flex overflow-auto">
                    <div className="flex-shrink-0 ms-2">
                        <ul className="nav justify-content-end nav-tabs-custom rounded card-header-tabs border-bottom-0 " role="tablist">
                            {taps &&
                                tap.map((item: any, i: number) => {
                                    return (
                                        <li className={`nav-item cursor color-tabs ${isDisable(item)}`} role="presentation" onClick={() => { updateIndex(i) }} key={item.name}>
                                            <span className={i === index ? `nav-link active ${isDisable(item)}` : 'mtap nav-link'} tabIndex={-1}>
                                                {item.name}
                                            </span>
                                        </li>
                                    )
                                })
                            }

                        </ul>
                    </div>
                </div>
                <div className="card-body">
                    <div className="tab-content text-muted">
                        {children}
                    </div>
                </div>
            </div>

        </>
    )
}