import { useEffect, useState } from "react";
import { _CompetitionLevel } from "../../../../core/services/dataBean/EntityCatalog";
import useDataBean from "../../../../core/services/dataBean/useDataBean";
import { Singleton } from "../../../../core/services/Singleton";
import { ListMenu } from "../../../theme/Component/ListMenu";
import { BasicPage } from "../../../theme/Component/BasicPage";
import { BasicTable } from "../../../theme/Component/BasicTable";
import { Modal } from "../../../theme/Component/Modal";
import { ModalConfirm } from "../../../theme/Component/ModalConfirm";
import { toast } from "react-toastify";
import { PrintJson } from "../../../theme/Component/PrintJson";
import { SelectFunctionalID } from "../../../admin/configuracion/functionalID/SelectFunctionalID";
import { CompetitionTypeEditor } from "../CompetitionType/CompetitionType.editor";

export const CompetitionLevel = () => {

    const competitionLevel = useDataBean(_CompetitionLevel);
    const single = Singleton.getInstance();

    const [compType, setCompType] = useState<number>(null)

    const [lista, setLista] = useState<any>([]);
    const [modal, setModal] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-md' });
    const [modalC, setModalC] = useState<any>({ name: 'Eliminar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });

    useEffect(() => {
        if (compType >= 0) {
            getLista();
        }
    }, [compType])


    const getLista = () => {

        competitionLevel.instance.getCompetitionLevelCatalog(compType).then(
            (resp: any) => {
                if (resp) {
                    setLista(resp)
                }
            }
        )

    }

    const create = () => {
        competitionLevel.setBean({
            IDCompetitionType: compType,
            Name: "",
            Description: "",
        });
        setModal({ ...modal, "name": "Agregar nivel de compentencia Comportamental", "view": true });
    }
    const deleteItem = () => {
        competitionLevel.instance.deleteCompetitionLevel(competitionLevel.bean).then(
            (resp: any) => {
                getLista();
                setModalC({ ...modalC, "view": false });
            }
        )

    }
    const editaItem = () => {
        ////console.log(competitionLevel.bean);
        competitionLevel.update().then(
            (resp: any) => {
                getLista();
                setModal({ ...modal, "view": false });
            }
        ).catch(err => {
            toast.error(err);
        })

    }

    const head = [
        { title: 'ID', property: 'IDCompetitionLevel', visible: true },
        { title: 'Nombre', property: 'Name', visible: true },
        { title: 'Descripción', property: 'Description', visible: true },
    ]

    const iconButtonsItem = [
        { nombre: 'Agregar', visible: single.canCreate(), icono: 'ri-file-add-line', evento: create }
    ]
    const listaBotones = () => {
        const botones = [
            { titulo: 'Editar', icono: 'ri-edit-2-line', visible: single.canEdit(), evento: () => { setModal({ ...modal, "name": "Editar Nivel de competencia Comportamental", "view": true }) } },
            { titulo: 'Eliminar', icono: 'ri-delete-bin-line', visible: single.canDelete(), evento: () => { setModalC({ ...modalC, "view": true }) } },

        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }


    return (
        <BasicPage titulo="Niveles de las Competencias Comportamentales">
            <div className="row">
                <div className="col-6">
                    <CompetitionTypeEditor idSelector={compType} onChange={(e) => { setCompType(e) }} />
                </div>
                <div className="col-md-12">
                    <BasicTable body={lista} head={head} rowSet={competitionLevel.setBean} listButtons={listaBotones()} iconButtons={iconButtonsItem}></BasicTable>
                    <Modal modal={modal} updateModal={setModal} eventModal={editaItem}>
                        <div className="row">
                            <div className="col-md-12">
                                <label>Nombre</label>
                                <input type="text" name="Name" className="form-control" value={competitionLevel.bean.Name} onChange={competitionLevel.handleChange} />
                            </div>
                            <div className="col-md-12">
                                <label>Descripción</label>
                                <textarea name="Description" className="form-control" value={competitionLevel.bean.Description} onChange={competitionLevel.handleChange}></textarea>
                            </div>
                        </div>
                    </Modal>
                    <ModalConfirm modal={modalC} updateModal={setModalC} eventModal={deleteItem}>
                        <strong>Eliminar item {competitionLevel.bean.Name}</strong>
                    </ModalConfirm>
                </div>
            </div>
        </BasicPage>
    )
}