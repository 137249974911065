import { DataBean } from "../DataBean";


export class CompetitionLevel extends DataBean {


    public async getCompetitionLevelCatalog(id: number): Promise<any> {
        return this.getCustomService("PeopleArt", "java.util.List_getCompetitionLevelCatalog_Number", [id]);
    }

    public async deleteCompetitionLevel(bean): Promise<any> {
        return this.getCustomService("PeopleArt", "void_deleteCompetitionLevel_com.peopleart.bean.behavioral.CompetitionLevel", [
            {
                "DataBeanProperties": bean,
                "DataBeanName": "com.peopleart.bean.behavioral.CompetitionLevel"
            }
        ]);
    }
}