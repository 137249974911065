import { DataBean } from "../DataBean";

export class FamilyGroup extends DataBean {

    public async getFamilyGroupCatalogPorPropiedades(properties: any, maxRows: number): Promise<any> {
        return this.getCustomService("TalentERP", "java.util.List_getFamilyGroupCatalogPorPropiedades_java.util.Map_Number", [properties, maxRows]);
    }

    public async getFamilyGroupCatalogRender( maxRows: number, properties: any): Promise<any> {
        return this.getCustomService("TalentERP", "java.util.List_getFamilyGroupCatalogRender_Number_java.util.Map", [ maxRows, properties]);
    }

}