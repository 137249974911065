import React, { useState, useEffect } from 'react';
import { Singleton } from "../../../core/services/Singleton";
import { BasicPage } from "../../theme/Component/BasicPage";
import { BpmService } from "../../../core/services/BpmService";
import { BasicTable } from '../../theme/Component/BasicTable';
import { Modal } from '../../theme/Component/Modal';
import { ListMenu } from '../../theme/Component/ListMenu';
import { useDashboardStore } from '../../pages/hooks/useDashboardStore';
import { BsFillArrowRightCircleFill } from 'react-icons/bs';
import { PrintObjectJson } from '../../theme/Component/PrintObjectJson';

interface Props {
    IDProcedure: any;
}

export const ProcedureActionHistorial = ({ IDProcedure }: Props) => {
    const single = Singleton.getInstance()
    // const [beanT, setBeanT] = useState<any>({});
    const { setLoading } = useDashboardStore();
    const [selectedResponseJson, setSelectedResponseJson] = useState<any>(null); // Estado para guardar el ResponseJsonValue
    const [modal, setModal] = useState<any>({ name: 'Ver Mas', tabIndex: -1, view: false, btnClose: false, btnSubmit: false, format: 'modal-lg' });
    const service = new BpmService();
    const [lista, setLista] = useState([]);

    useEffect(() => {
        getLista();
    }, [IDProcedure]);

    const getLista = () => {

        single.spinner(true);
        service.getProcedureActionByProcedureImp(IDProcedure).subscribe(
            (resp: any) => {

                if (resp.DataBeanProperties.ObjectValue) {
                    setLista(resp.DataBeanProperties.ObjectValue);
                }
                single.spinner(false);
            }
        );
    }
    const abrirmodal = (responseJson: any) => {
        setSelectedResponseJson(responseJson); // Almacenar el ResponseJsonValue en el estado
        setModal({ ...modal, "view": true });

    }

    return (
        <>
            <div className="row">
                <div className="col-md-12">
                    <div className="list-group">
                        {lista.reverse().map((item: any, index: number) => {
                            return (
                                <button type="button" className="list-group-item list-group-item-action" aria-current="true">
                                    {/* <i className="ri-edit-box-line align-middle me-2"></i> */}
                                    <div className="row">
                                        <div className="col">
                                            <div className='d-flex flex-column justify-content-between'>
                                                <div><strong>Actividad</strong> {item.DataBeanProperties.ProcedureName}</div>
                                                <div><strong>Funcionario</strong> {item.DataBeanProperties.AccountNameProcedureImp}</div>
                                                <div><strong>Tipo Actividad</strong> {item.DataBeanProperties.DocumentTypeName}</div>
                                            </div>



                                        </div>
                                        <div className="col">
                                            <div className='d-flex flex-column justify-content-between'>
                                                <div><strong>Estado</strong> {item.DataBeanProperties.StateName}</div>
                                                <div><strong>Actualización</strong > <span className='text-primary'>{item.DataBeanProperties.UptoDate}</span></div>
                                                <div><a href='javascript:void(0);' className='link-succsess' onClick={() => abrirmodal(item.DataBeanProperties.ResponseJsonValue)}>Ver Datos</a></div>
                                            </div>
                                        </div>
                                    </div>
                                </button>
                            )
                        })}

                    </div>
                </div>
            </div>
            {/* <BasicPage titulo="Historial">
                <>
                    {Array.isArray(lista) && lista.map((propiedadcurso: any, index: number) => {
                        return (
                            <>
                                <div className="row">
                                    <div className="col-md-12 " key={index}>
                                        <div className="div-historial mt-3">
                                            <div className="row ">
                                                <div className="col-md-2">
                                                    <strong className="color-tag">-{propiedadcurso.DataBeanProperties.IDDocument}.</strong>
                                                </div>
                                                <div className="col-md-10">
                                                    {propiedadcurso.DataBeanProperties.StateName}
                                                </div>
                                            </div>
                                            <div className="row mt-4">
                                                <div className="col-md-2"></div>
                                                <div className="col-md-4">{propiedadcurso.DataBeanProperties.ProcedureActionName}</div>
                                                <div className="col-md-4">{propiedadcurso.DataBeanProperties.VerifiedDate}</div>
                                                <div className="col-md-2"><button className="btn" onClick={() => abrirmodal(propiedadcurso.DataBeanProperties.ResponseJsonValue)}>
                                                    <BsFillArrowRightCircleFill />
                                                </button></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                            </>
                        )
                    })
                    }

                </>
            </BasicPage> */}

            <Modal modal={modal} updateModal={setModal}>
                <>

                    {selectedResponseJson ? (
                        <PrintObjectJson json={selectedResponseJson} /> // Mostrar el JSON seleccionado
                    ) : (
                        <p>No hay datos</p>
                    )}

                </>
            </Modal>

        </>
    );
};
