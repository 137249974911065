import { useEffect, useState } from "react";
import { PrintJson } from "../../../../theme/Component/PrintJson";
import { UBLInvoiceTaxList } from "./UBLInvoiceTaxList";
import { AddUBLInvoiceTax } from "./AddUBLInvoiceTax";
import { Modal } from "../../../../theme/Component/Modal";
import { Singleton } from "../../../../../core/services/Singleton";
import { TAX_IMPUESTO } from "../ContracInterface";

interface Props {
    ublInvoice: any;
}
export const UBLInvoiceTaxImpuesto = ({ ublInvoice }: Props) => {

    const single = Singleton.getInstance();
    const [ubl, setUbl] = useState<any>({});
    const [myKey, setMykey] = useState<number>(single.getRandomInt());
    const [modal, setModal] = useState<any>({ name: 'Agregar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });

    useEffect(() => {
        setUbl(ublInvoice);
    }, [])

    const updateRandom=()=>{
        setMykey(single.getRandomInt());
        setModal({ ...modal, "view": false })
    }

    return (
        <div className="row">
            {/* <div className="col-md-12">
                <button type="button" className="btn btn-primary waves-effect waves-light shadow-none" onClick={() => { setModal({ ...modal, "view": true }) }}>Agregar</button>
            </div> */}
            <div className="col-md-12">
                {/* <Modal modal={modal} updateModal={setModal}>
                    <AddUBLInvoiceTax ublInvoice={ubl} onChange={updateRandom} />
                </Modal> */}
                {ubl.DataBeanProperties &&
                <UBLInvoiceTaxList key={myKey} idUBLInvoice={ubl.DataBeanProperties.IDUblInvoice} issueDate={ubl.DataBeanProperties.IssueDate} Type={TAX_IMPUESTO} />
                }
                
                {/* <PrintJson json={ubl}/> */}
            </div>
        </div>
    )
}