import { ContractService } from "../../core/services/ContractService";
import { Singleton } from "../../core/services/Singleton";
import { BasicPage } from "../theme/Component/BasicPage"
import { useEffect, useState } from 'react';
import { useDashboardStore } from "../pages/hooks/useDashboardStore";
import { AdquisitionItem } from "./adquisition-setting/AdquisitionItem";
import { _single } from "../../core/services/dataBean/EntityCatalog";
import { SelectAdquisitionPlanFunctionalID } from "./select/SelectAdquisitionPlanFunctionalID";
import { ITEM_PREPARADOR, VISIBLE_ITEMS_PREPARER } from "../Constants";

export const MisPlanesPreparador = () => {

    const role = ITEM_PREPARADOR;
    const single = Singleton.getInstance()
    const { setLoading } = useDashboardStore();
    const contract = new ContractService();
    const [planes, setPlanes] = useState<any>([]);
    const [IDplan, setIDPlan] = useState<number>(0);
    const [IDPrepared, setIDPrepared] = useState<number>(0);
    const [IDArea, setIDArea] = useState<number>(0);
    const [area, setArea] = useState<any>({});


    useEffect(() => {
        getAdquisitionPlanOnStructuringStateForPreparer();
    }, [])


    useEffect(() => {
        getFunctionalIDPreparerByAccount2();
    }, [IDArea])

    const getAdquisitionPlanOnStructuringStateForPreparer = () => {
        setLoading(true);
        contract.getAdquisitionPlanOnStructuringStateForPreparer(single.getAccountID()).subscribe(
            (resp: any) => {

                setLoading(false);
                if (resp.DataBeanProperties.ObjectValue) {
                    setPlanes(resp.DataBeanProperties.ObjectValue);
                    setLoading(false);
                }
            }
        )
    }

    const getFunctionalIDPreparerByAccount2 = () => {
        setLoading(true);
        contract.getFunctionalIDPreparerByAccount(IDArea, single.getAccountID()).subscribe(
            (resp: any) => {
               //console.log(resp);

                setLoading(false);
                if (resp.DataBeanProperties.ObjectValue) {
                    let list = resp.DataBeanProperties.ObjectValue;
                    setIDPrepared(list.DataBeanProperties.IDFunctionalIDPreparer);
                }
            }
        )
    }

    return (
        <>
            <BasicPage titulo="Planes por Preparar">
                {planes.length > 0 ?
                    <>
                        <div className="row">
                            <div className="col">
                                <div className="row">
                                    <div className="col-md-6">
                                        <label>Plan</label>
                                        <select className="form-select mb-3" value={IDplan} onChange={(e) => { setIDPlan(parseInt(e.target.value)) }}>
                                            <option value="0">...</option>
                                            {planes.map((item: any, index: number) => {
                                                return (
                                                    <option key={index} value={item.DataBeanProperties.IDAdquisitionPlan}>
                                                        {item.DataBeanProperties.Description}
                                                    </option>
                                                )
                                            })}
                                        </select>
                                    </div>
                                    <div className="col-md-6">
                                        <SelectAdquisitionPlanFunctionalID idAdquisitionPlan={IDplan} idPreparer={_single.getAccountID()} onChange={(e) => { setIDArea(e.IDAdquisitionPlanFunctionalID); setArea(e) }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {IDArea > 0 ?
                            <div className="row">
                                <div className="col">
                                    <AdquisitionItem beanOficina={area} idAdquisitionPlanFunctionalID={area.IDAdquisitionPlanFunctionalID} rol={role} idPreparer={area.IDFunctionalIDPreparer} filterStateArray={VISIBLE_ITEMS_PREPARER} />
                                </div>
                            </div>
                            :
                            <div className="d-flex mt-4 justify-content-center">
                                <div className="alert alert-warning" role="alert">
                                    <p>
                                        Debe seleccionar una <strong>Área / Dependencia</strong> para poder continuar.
                                    </p>
                                </div>
                            </div>
                        }
                    </>
                    :
                    <div className="d-flex mt-4 justify-content-center">
                        <div className="alert alert-warning" role="alert">
                            <p>
                                <strong>No se encuentra asignado a ningun plan de compras.</strong>
                            </p>
                        </div>
                    </div>
                }
            </BasicPage>
        </>
    )
}