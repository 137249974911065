import { useEffect, useState } from "react";
import { ContractService } from "../../../../../core/services/ContractService";
import { Singleton } from "../../../../../core/services/Singleton";
import { DataBeanProperties, ObjectValue } from '../../../../../core/services/model/server-response.interface';
import { toast } from "react-toastify";
import { BasicTable } from "../../../../theme/Component/BasicTable";

interface Props {
    idSupplier: number;
}
export const ContractList = ({ idSupplier }: Props) => {

    const service = new ContractService();
    const single = Singleton.getInstance();
    const [lista, setLista] = useState<any>([]);
    const [row, setRow] = useState<any>({});

    useEffect(() => {
        getAdquisitionContractRender();
    }, [idSupplier])

    const getAdquisitionContractRender = () => {
        single.spinner(true);
        service.getAdquisitionContractRender(null, null, { IDSupplier: idSupplier }, null, null).subscribe(
            (resp: any) => {

                if (resp.DataBeanProperties.ObjectValue) {
                    setLista(resp.DataBeanProperties.ObjectValue);
                }
                else if (resp.DataBeanProperties.ErrorMessage) {
                    toast.error(resp.DataBeanProperties.ErrorMessage);
                }
                single.spinner(false);
            }
        );
    }

    const head = [
        { title: 'ID', property: 'IDAdquisitionContract', visible: false },
        { title: 'Valor Contrato', property: 'ContractValue', visible: true },
        { title: 'Valor CDP', property: 'CDPValue', visible: true },
        { title: 'Fecha Contrato', property: 'SeleccionDate', visible: true },
        { title: 'Dodo radicado', property: 'Code', visible: false },
        { title: 'Código contrato', property: 'InternalCode', visible: true },
        { title: 'Proveedor', property: 'Name1', visible: false },
        { title: 'Descripción', property: 'Description', visible: true },
        { title: 'No CDP', property: 'NoCDP', visible: false },
        { title: 'Fecha CDP', property: 'DateCDP', visible: false },
        { title: 'No registro presupuestal', property: 'NoRegistroPresupuestal', visible: false },
        { title: 'Fecha registro presupuestal', property: 'DateRegPresupuestal', visible: false },

    ]

    return (
        <div className="row">
            <div className="col">
                <BasicTable head={head} body={lista} rowSet={setRow} ></BasicTable>
            </div>
        </div>
    )
}