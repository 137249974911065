import { useEffect, useState } from "react";
import { _AdquisitionItem, _AdquisitionItemProcess } from "../../../../core/services/dataBean/EntityCatalog";
import useDataBean from "../../../../core/services/dataBean/useDataBean";
import { PrintJson } from "../../../theme/Component/PrintJson";
import { EstudioPrevio } from "../../etapa-contrato/estudio-previo-items/EstudioPrevio";

interface Props{
    data:any;
    exportMap:any;
}
export const EstudioPrevioBpm=({data,exportMap}:Props)=>{


    const _adquisitionItemProcess = useDataBean(_AdquisitionItemProcess);
    const _adquisitionItem = useDataBean(_AdquisitionItem);
    const [step, setStep] = useState(1);
    const [item,setItem]= useState<any>({});
    const [adquisitionItem,setAdquisitionItem]= useState<any>({});
    

    useEffect(()=>{
        _adquisitionItemProcess.getLista({IDProcedureImp:data.IDProcedureImp},null);
    },[data])
    useEffect(()=>{
        if(_adquisitionItemProcess.lista.length>0)
        {
            let i = _adquisitionItemProcess.lista[0].DataBeanProperties;
            setItem(i);
        }
        else{
            setItem({})
        }
    },[_adquisitionItemProcess.lista])
    useEffect(()=>{

        if(item.IDAdquisitionItem)
        {
           _adquisitionItem.instance.getAdquisitionItemForPreviusEstudy(item.IDAdquisitionItem).then(
            (resp:any)=>{
                setAdquisitionItem(resp);
            }
           )
        }
    },[item])
   

    return (
        <div className="row">
            <div className="col">
                {/* <PrintJson json={adquisitionItem}/> */}
                <EstudioPrevio adquisitioItem={adquisitionItem} vista={step} setVista={setStep} rol={1} updateMap={exportMap}/>
            </div>
        </div>
    )
}