import { useState, useEffect } from 'react';
import { Singleton } from '../../../core/services/Singleton';
import { useDashboardStore } from '../../pages/hooks/useDashboardStore';
import { ListMenu } from '../../theme/Component/ListMenu';
import { BasicTable } from '../../theme/Component/BasicTable';
import { ModalConfirm } from '../../theme/Component/ModalConfirm';
import { Modal } from '../../theme/Component/Modal';
import { AdminService } from '../../../core/services/AdminService';
import { BasicSwitch } from '../../theme/Component/BasicSwitch';
import { ContractRoleSelect } from './ContractRoleSelect';
import { SelectorTramites } from '../../bpm/config/Componentes/SelectorTramites';
import { SelectorClaseTramites } from '../../bpm/config/Componentes/SelectorClaseTramites';
import { BasicPage } from '../../theme/Component/BasicPage';





export const RequestTypeBpm = () => {

    const single = Singleton.getInstance()
    const { setLoading } = useDashboardStore();
    const Service = new AdminService();
    const [lista, setLista] = useState([]);
    const [bean, setBean] = useState<any>({});
    const [modal, setModal] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalConfirm, setModalConfirm] = useState<any>({ name: 'Confirmar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });
    const [user, setUser] = useState<any>({});

    const idBean = 'IDRequestTypeBpm';

    const head = [
        { title: 'ID', property: idBean, visible: false },
        { title: 'Código', property: 'CodeProcess' },
        { title: 'Rol', property: 'RoleName' },
        { title: 'Trámite', property: 'Name' },
        { title: 'Propiedades de inicializacion', property: 'InitProps', visible: false },


    ]


    const total = () => {
       //console.log(bean);
        updateItem();
    }

    useEffect(() => {
        setUser(single.getAccountID());
        getLista();
    }, [idBean])


    const setRowBean = (bean: any) => {
       //console.log("Row : ", bean);
        setBean(bean);

    }

    const getLista = () => {
        setLoading(true);
        Service.getRequestTypeBpmCatalogRender(null, null, { State: 1 }).subscribe(
            (resp: any) => {

                if (resp.DataBeanProperties.ObjectValue) {
                    setLista(resp.DataBeanProperties.ObjectValue);
                }

                setLoading(false);
            }
        );
    }

    const agregarItem = () => {

        let b = {

            State: 1,
            IDBusinessProcess: undefined,
            IDContractRole: undefined,
            InitProps: '{}'
        }
        setBean(b);
        setModal({ ...modal, ['view']: true });

    }

    const eliminarItem = () => {
        bean.State = 2;
       //console.log(bean);
        updateItem();
        setModalConfirm({ ...modalConfirm, ['view']: false });
    }
    const updateItem = () => {

        setModal({ ...modal, ['view']: false });

        setLoading(true);
        Service.updateRequestTypeBpm(bean).subscribe(
            (resp: any) => {
                setLoading(false);
                if (resp.DataBeanProperties.ObjectValue) {
                    getLista();
                }
                single.update(bean);
            });

    }

    const listaBotones = () => {
        const botones = [

            { titulo: 'Editar', icono: 'ri-file-list-line', visible: single.canEdit(), evento: () => { setModal({ ...modal, "view": true }) } },
            { titulo: 'Eliminar', icono: 'ri-file-user-line', visible: true, evento: () => { setModalConfirm({ ...modalConfirm, "view": true }) } },


        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }

    const iconButtonsItem = [
        { nombre: 'Agregar', visible: single.canCreate(), icono: 'ri-file-add-line', evento: agregarItem }
    ]




    return (
        <>
            <BasicPage titulo="Flujos de contratos">
                <div className="col-md-12 p-2">
                    <BasicTable listButtons={listaBotones()} iconButtons={iconButtonsItem} head={head} body={lista} rowSet={setRowBean}></BasicTable>
                </div>
            </BasicPage>
            {/* <div className="row">
                <label className="form-label">Flujos de contratos</label>
                <div className="col-md-12 p-2">
                    <BasicTable listButtons={listaBotones()} iconButtons={iconButtonsItem} head={head} body={lista} rowSet={setRowBean}></BasicTable>
                </div>
            </div> */}

            <ModalConfirm modal={modalConfirm} updateModal={setModalConfirm} eventModal={eliminarItem}>
                {bean.Name}
            </ModalConfirm>

            <Modal modal={modal} updateModal={setModal} eventModal={total}>

                <div className="row">

                    <div className="col-md-6">
                        <label>Código</label>
                        <input type="text" name="code" className='form-control' value={bean.CodeProcess} onChange={(e) => { setBean({ ...bean, "CodeProcess": e.target.value }) }} />
                    </div>
                    <div className="col-md-6">

                        <SelectorTramites titulo="Clase de tramites" idSelector={bean.IDBusinessClass} onChange={(e) => { setBean({ ...bean, "IDBusinessClass": e }) }} />
                    </div>
                    <div className="col-md-6">
                        <SelectorClaseTramites idProcess={bean.IDBusinessClass} idSelector={bean.IDBusinessProcess} onChange={(e) => { setBean({ ...bean, "IDBusinessProcess": e }) }} />
                    </div>



                    <div className="col-md-6">
                        <ContractRoleSelect idSelector={bean.IDContractRole} onChange={(e) => { setBean({ ...bean, "IDContractRole": e }) }} />
                    </div>
                    <div className="col-md-12">
                        <label className="form-label">Propiedades de inicializacion</label>
                        {/* <textarea className="form-control" value={JSON.parse(bean.InitProps ?? '{}')} onChange={(e) => { setBean({ ...bean, ['InitProps']: JSON.stringify(e.target.value) }) }}></textarea> */}
                        <textarea className="form-control" rows={5} value={bean.InitProps} onChange={(e) => { setBean({ ...bean, ['InitProps']: e.target.value }) }}></textarea>
                    </div>

                </div>
            </Modal>

        </>
    )
}