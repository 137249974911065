
import { useEffect, useState } from "react";
import { DataBean } from "./DataBean";
import { Singleton } from '../Singleton';

// function useDataBean(Entity:any) {
function useDataBean<T extends DataBean>(Entity: T) {
    
    const [instance] = useState(Entity);
    const [lista, setLista] = useState<any>([]);
    const [customObj, setCustomObj] = useState<any>([]);
    const [bean, setBean] = useState<any>({});
    // const [spin, setSpin] = useState<boolean>(false);
    const [errors, setErros] = useState<any>('');



    useEffect(() => {
        if (errors !== '')
            setTimeout(() => {
                setErros('');
            }, 4000);
    }, [errors])
    
    

    const newBean = () => {
        instance.bean = {};
        setBean(instance.bean);
    }

    const update = async () => {
        

        try {
            instance.bean = bean;
            await instance.update().then((resp:any)=>{setBean(resp)});
        } catch (error) {
            console.error(error);
            setErros(error);
        }
        
    };
    const updateCustonBean = async (miBean: any) => {
        

        try {
            instance.bean = miBean;
            await instance.updateCustonBean(miBean).then((resp:any)=>{setBean(resp)});
        } catch (error) {
            console.error(error);
            setErros(error);
        }
        
    };
    const deleteItem = async (idItem: number) => {
        
        let isDelete = false;
        try {
            await instance.delete(idItem).then((resp:any)=>{});
            isDelete = true;
        } catch (error) {
            console.error(error);
            setErros(error);
        }
        
        return isDelete;
    };
    const deleteItemVoid = async (idItem: number) => {
        
        let isDelete = false;
        try {
            await instance.deleteVoid(idItem).then((resp:any)=>{});
            isDelete = true;
        } catch (error) {
            console.error(error);
            setErros(error);
        }
        
        return isDelete;
    };
    const getLista = async (params: any, rows: number | null) => {
        
        try {
            
            const temp: any = await instance.getList(params, rows);
            setLista(temp);
        } catch (error) {
            console.error(error);
            setErros(error);
        }
        

    };
    const getListaLike = async (params: any, rows: number | null) => {
        
        try {
            const temp: any = await instance.getList(params, rows);
            setLista(temp);
        } catch (error) {
            console.error(error);
            setErros(error);
        }
        
    };
    const getCustomFunction = async (service: string, hash: string, propsArray: any, setMiObject?: (state: any) => void, doAfter?: () => void) => {
        

        try {
            const retorno: any = await instance.getCustomService(service, hash, propsArray);
            ////console.log('Rrn', retorno);
            if (setMiObject) {
                setMiObject(retorno);
               //console.log(retorno)
            }
            else {
                if (retorno.DataBeanProperties) {
                    if (retorno.DataBeanProperties.ErrorMessage) {
                        setErros(retorno.DataBeanProperties.ErrorMessage);
                    }
                    else if (retorno.DataBeanProperties.ObjectValue) {
                        setCustomObj(retorno.DataBeanProperties.ObjectValue);
                    }
                    else {
                        Entity.dataBeanList = retorno.DataBeanProperties;
                        setCustomObj(retorno.DataBeanProperties);
                    }
                }
                else
                    setCustomObj(retorno);
            }

            if (doAfter) {
                doAfter()
            }

        } catch (error) {
            console.error(error);
            setCustomObj(undefined);
            setErros(error);
        }
        

    };
   

    const handleChange = (e: any) => {
        if (e) {
            if (e.target.name) {
                setBean({ ...bean, [e.target.name]: processValue(e.target.value) });
            } else {
                setErros("El atributo Name de la propiedad no esta definido");
            }
        } else {
            setErros("El valor que intenta cambiar esta incompleto");
        }
    }
    function processValue(value: any): number | string | boolean {
        if (typeof value === 'number') {
            return value; // Si es un número, lo retorna como número
        } else if (typeof value === 'boolean') {
            return value; // Si es boolean, lo retorna como boolean
        } else if (typeof value === 'string') {
            const parsedNumber = parseFloat(value); // Intenta convertir el string a número
            if (!isNaN(parsedNumber) && isFinite(parsedNumber)) {
                return parsedNumber; // Si la conversión es exitosa, retorna el número
            } else if (value.toLowerCase() === 'true' || value.toLowerCase() === 'false') {
                return value.toLowerCase() === 'true'; // Convierte "true"/"false" a boolean
            } else {
                return value; // Si no es un número ni boolean, retorna el string original
            }
        } else {
            return value.toString(); // Para otros tipos de datos, los convierte a string
        }
    }


    return {
        instance,
        lista,
        customObj,
        bean,        
        errors,
        update,
        updateCustonBean,
        newBean,
        setBean,
        setLista,
        deleteItem,
        deleteItemVoid,
        getLista,
        getCustomFunction,
        getListaLike,
        handleChange
    };
}


export default useDataBean;