import { DataBean } from "../DataBean";

export class PaymentType extends DataBean {


    public async getPaymentTypeCatalog(): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_getPaymentTypeCatalog_Number", [null]);
    }

    public async updatePaymentType(bean: any): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "admin.PaymentType_updatePaymentType_admin.PaymentType", [
            {
                "DataBeanProperties": bean,
                "DataBeanName": "admin.PaymentType"
            }
        ]);
    }

    public async deletePaymentType(bean): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "void_deletePaymentType_Number", [
            {
                "DataBeanProperties": bean,
                "DataBeanName": "admin.PaymentType"
            }
        ]);
    }
}