import { useEffect, useState } from "react";
import { Singleton } from "../../../../../core/services/Singleton";
import { ForpoService } from "../service/ForpoService";
import { useDashboardStore } from "../../../../pages/hooks/useDashboardStore";
import { FileService } from "../../../../../core/services/FileService";

export const useIntro = () => {
    const single = Singleton.getInstance()
    const { setLoading } = useDashboardStore();
    const service = new ForpoService();
    const [lista, setLista] = useState([]);
    const [bean, setBean] = useState<any>({});
    const fileService = new FileService();
    const [modalConfirm, setModalConfirm] = useState<any>({ name: "Confirmar", tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: "modal-sm", });
    const [modal, setModal] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: "modal-lg", });

    useEffect(() => {
        getLista();
    }, []);


    const idBean = "IDIntro";

    const setRowBean = (bean: any) => {
        setBean(bean);
    };

    const getLista = () => {
        setLoading(true);
        service.getIntroCatalogPorPropiedad(null, null, null).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue) {
                    let temp = resp.DataBeanProperties.ObjectValue;
                    temp = temp.filter((obj: any) => obj.DataBeanProperties.State !== 3);
                   //console.log(temp)
                    setLista(temp);
                }
                setLoading(false);
            }
        );
    };

    const head = [
        { title: "ID", property: idBean },
        { title: "Nombre", property: "Name" },
        { title: "Descripción", property: "Description" },
        { title: "Desde", property: "FromDate" },
        { title: "Hasta", property: "UptoDate" },
        { title: "Estado", property: "State" },
    ];

    const agregarItem = () => {
        let b = {
            Description: "",
            IDEmployee: single.getAccountID(),
            State: 1,
            UptoDate: single.getCurrenDate(),
            FromDate: single.getCurrenDate(),
            Name: "",
        };
        setBean(b);
        setModal({ ...modal, "view": true, name: "Agregar Introducción" });
    };

    const editarItem = () => {
        setModal({ ...modal, "view": true, name: 'Editar Introducción' });
    };

    const eliminarItem = () => {
        bean.State = 3;
       //console.log(bean);
        updateItem();
        setModalConfirm({ ...modalConfirm, 'view': false });
    }

    const updateItem = () => {
        setLoading(true);
        service.updateIntro(bean).subscribe((resp: any) => {
            setLoading(false);
            if (resp.DataBeanProperties.ObjectValue) {
                getLista();
            }
            if (bean.idBean > 0) {
                single.update(bean);
            } else {
                single.create(bean);
            }
        });
        setModal({ ...modal, "view": false });
    };

    const iconButtonsItem = [
        { nombre: "Agregar", visible: single.canCreate(), icono: "ri-file-add-line", evento: agregarItem, },
    ];

    return {
        single,
        fileService,
        service,
        lista,
        setLista,
        bean,
        setBean,
        modal,
        setModal,
        modalConfirm,
        setModalConfirm,
        idBean,
        updateItem,
        head,
        iconButtonsItem,
        editarItem,
        agregarItem,
        eliminarItem,
        setRowBean,
    };
};
