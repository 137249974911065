import { useEffect, useState } from "react";
import { AdminService } from "../../../core/services/AdminService";
import { BasicPage } from '../../theme/Component/BasicPage';
import { BasicButton } from '../../theme/Component/BasicButton';
import { ModalConfirm } from '../../theme/Component/ModalConfirm';
import { Modal } from "../../theme/Component/Modal";

import { ApiPermissions } from "./ApiPermissions";
import { useDashboardStore } from "../../pages/hooks/useDashboardStore";


export const ApiModule = () => {

    const { setLoading } = useDashboardStore();
    const service = new AdminService()
    const [lista, setLista] = useState([]);
    const [idApi, setIDApi] = useState(0);
    const [drop, setDrop] = useState<any>({});
    const [bean, setBean] = useState<any>({});
    const [modal, setModal] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalA, setModalA] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalConfirm, setModalConfirm] = useState<any>({ name: 'Confirmar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });



    const idBean = 'IDApiModule';

    const head = [
        { title: 'ID', property: idBean },
        { title: 'Name', property: 'Name' },
        { title: 'ClassForName', property: 'ClassForName' },
        { title: 'Fecha', property: 'Since' },
    ]

    useEffect(() => {
        getLista();
    }, [])


    const setid = (e: any) => {
        let id = 0;
        let b = {};
        lista.forEach((el: any) => {
            if (el.DataBeanProperties.IDApiModule == e) {
                b = el.DataBeanProperties;
                id = e;

               //console.log(el.DataBeanProperties);

            }
        })
        setBean(b);
        setIDApi(id);
    }

    const getLista = () => {

        setLoading(true);
        service.getApiModuleCatalog(null).subscribe(
            (resp: any) => {
                setLista([]);

                if (resp.DataBeanProperties.ObjectValue) {
                    let tmp = resp.DataBeanProperties.ObjectValue;

                    setLista(tmp);
                }

                setLoading(false);
            }
        );
    }

    const agregarItem = () => {

        let b = {
            Name: '',
            ClassForName: ''
        }

        setBean(b);
        setModal({ ...modal, ['view']: true });

    }
    const editarItem = () => {

        setModal({ ...modal, ['view']: true });

    }
    const eliminarItem = () => {

        setLoading(true);
        service.deleteApiModule(bean[idBean]).subscribe(
            (resp: any) => {
                setLoading(false);
                if (resp) {
                    getLista();
                }
            }
        );
        setModalConfirm({ ...modalConfirm, ['view']: false });

    }
    const updateItem = () => {

        setModal({ ...modal, ['view']: false });

        setLoading(true);
        service.createApiModule(bean.classForName).subscribe(
            (resp: any) => {
                setLoading(false);
                if (resp.DataBeanProperties.ObjectValue) {
                    getLista();
                }
            });

    }

    // dropdown
    const showTable = (drop: any) => {
       //console.log(drop);
        setDrop(drop);
    }

    const updateItemDrop = () => {

    }


    return (
        <>
            <BasicPage titulo="Matriz de Permisos de Roles">
                <div className="row">
                    <div className="col-md-6">
                        <label htmlFor="api">ApiModule</label>
                        <select className="form-control" value={idApi} onChange={(e) => { setid(e.target.value) }}>
                            <option value="{}">...</option>
                            {lista.map((item: any) => {
                                return (
                                    <option value={item.DataBeanProperties.IDApiModule}>{item.DataBeanProperties.Name} / {item.DataBeanProperties.ClassForName}</option>
                                )
                            })}
                        </select>
                    </div>
                    <div className="col-md-2 mt-4">
                        <BasicButton value="Agregar Modulo" icon="ri-file-add-line" eventClick={agregarItem} ></BasicButton>
                    </div>
                    <div className="col-md-4 mt-4">

                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 p-2">
                        <ApiPermissions idApiModule={idApi}></ApiPermissions>
                    </div>
                </div>
            </BasicPage>

            <ModalConfirm modal={modalConfirm} updateModal={setModalConfirm} eventModal={eliminarItem}>
                {bean.Name}
            </ModalConfirm>

            {/* modal para acciones del dropdown */}
            <Modal modal={modalA} updateModal={setModalA} eventModal={updateItemDrop}>
                <div></div>
            </Modal>

            {/* modal para acciones del bean  */}
            <Modal modal={modal} updateModal={setModal} eventModal={updateItem}>
                <div className="row">
                    {bean[idBean] &&
                        <div className="col-md-12">
                            <label>Name</label>
                            <input type="text" className="form-control" value={bean.Name} onChange={(e) => { setBean({ ...bean, ['Name']: e.target.value }) }} />
                        </div>
                    }
                    <div className="col-md-12">
                        <label className="form-label">ClassForName</label>
                        <input type="text" className="form-control" value={bean.ClassForName} onChange={(e) => { setBean({ ...bean, ['ClassForName']: e.target.value }) }} />
                    </div>


                </div>
            </Modal>
        </>
    )
}