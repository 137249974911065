import { useEffect, useState } from "react";
import { Singleton } from "../../../../core/services/Singleton";
import { useDashboardStore } from "../../../pages/hooks/useDashboardStore";
import { AdminService } from "../../../../core/services/AdminService";

export const useDisclaimer = () => {
  const { setLoading } = useDashboardStore();
  const single = Singleton.getInstance()
  const service = new AdminService();
  const [lista, setLista] = useState([]);
  const [modalConfirm, setModalConfirm] = useState<any>({ name: "Confirmar", tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: "modal-sm", });
  const [modal, setModal] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: "modal-xl", });
  const [bean, setBean] = useState<any>({
    Message: "",
    Code: "",
    GroupCode: "",
    State: 1,
  });

  useEffect(() => {
    getLista();
  }, []);

  const idBean = "IDDisclaimer";

  const head = [
    { title: "ID", property: idBean },
    { title: "Mensaje", property: "Message" },
    { title: "Código", property: "Code" },
    { title: "Grupo", property: "GroupCode" },
  ];

  const setRowBean = (bean: any) => {
    setBean(bean);
   //console.log(bean)
  };

  const getLista = () => {
    setLoading(true);
    service.getDisclaimerCatalogPorPropiedad('State', 1, null).subscribe(
      (resp: any) => {
        if (resp.DataBeanProperties.ObjectValue) {
          setLista(resp.DataBeanProperties.ObjectValue);
        }
        setLoading(false);
      });
  };


  const agregarItem = () => {
    let b = {
      Message: "",
      Code: "",
      GroupCode: "",
      State: 1,
    };
    setBean(b);
    setModal({ ...modal, "view": true, name: "Agregar" });
  };

  const editarItem = () => {
    setModal({ ...modal, "view": true, name: 'Editar' });
  };

  const eliminarItem = () => {
    bean.State = 2;
   //console.log(bean);
    updateItem();
    setModalConfirm({ ...modalConfirm, 'view': false });
  }

  const updateItem = () => {
    setLoading(true);
    service.updateDisclaimer(bean).subscribe((resp: any) => {
      setLoading(false);
      if (resp.DataBeanProperties.ObjectValue) {
        getLista();
      }
      if (bean.idBean > 0) {
        single.update(bean);
      } else {
        single.create(bean);
      }
    });
    setModal({ ...modal, "view": false });
  };

  const mostrar = () => {
   //console.log(bean);
  }

  const iconButtonsItem = [
    { nombre: "Agregar", visible: single.canCreate(), icono: "ri-file-add-line", evento: agregarItem, },
  ];

  return {
    single,
    service,
    lista,
    setLista,
    bean,
    setBean,
    modal,
    setModal,
    modalConfirm,
    setModalConfirm,
    idBean,
    updateItem,
    head,
    iconButtonsItem,
    editarItem,
    agregarItem,
    eliminarItem,
    setRowBean,
    mostrar,
  };
};
