import { useEffect, useState } from "react";
import { PrintJson } from "../../theme/Component/PrintJson";
import { _single } from "../../../core/services/dataBean/EntityCatalog";
import { BasicTable, MASK_TEXT } from "../../theme/Component/BasicTable";
import useModal, { MODAL_md } from "../../theme/Component/hooks/useModal";
import { Modal } from "../../theme/Component/Modal";
import { RemoteForm } from "./RemoteForm";
import { ListMenu } from "../../theme/Component/ListMenu";

interface Props {
    idApplicationInvoker: any;
    fieldList: any;
    data: any;
    setData: any;
    propertyName:string;
}
export const RemoteTable = ({ fieldList, idApplicationInvoker, propertyName,data, setData }: Props) => {


    const [col, setCol] = useState<any>([]);
    const [head, setHead] = useState<any>([]);
    const [form, setForm] = useState<any>({});
    const [rows, setRows] = useState<any>([]);
    const [row, setRow] = useState<any>({});
    const modalEdit = useModal('Editar', MODAL_md);

    // const head = [
    //     {title:'ID',property:'IDAccountProject',visible:true,mask:},
    //     {title:'Nombre',property:'Name',visible:true},
    //     {title:'Descripcion',property:'Description',visible:true}        
    // ]

    useEffect(() => {

        if (fieldList) {

            const rows = Object.keys(fieldList);
            let filas = [];
            rows.forEach(fila => {
                filas.push(
                    {
                        ...fieldList[fila], "IDProperty": fila, "LineNumber": fieldList[fila].LineNumber ?? 1, "Index": _single.getRandomInt()
                    }
                )
            });
            setCol(filas.sort((a, b) => a.LineNumber - b.LineNumber));
        }
        return ()=>{setRows([])}
    }, [])

    useEffect(()=>{
        let temp = [];
        rows.forEach(element => {
                temp.push(element.DataBeanProperties)
        });
        setData(temp)
    },[rows])

    useEffect(() => {
        let h = [];
        let f = [];
        col.forEach(element => {
            if (element.VisibleOnTableRow === true) {
                h.push({
                    title: element.Name,
                    property: element.IDProperty,
                    visible: true,
                    mask: element.Mask ?? MASK_TEXT
                })
            }
        });
        console.log(" ==== CABECERA TABLA ====");
        console.table(h);
        setHead(h);
        const campForm = Object.keys(fieldList);
        let c = {};
        campForm.forEach(element => {
            if (fieldList[element].VisibleOnTableForm) {
                c[element] = fieldList[element];
            }
        });
        setForm(c);


        // setForm(fieldList.filter(item => item.VisibleOnTableForm === true));

    }, [col])

    const addToList = (item:any) => {
        // setRow(item);
        item.id = _single.getRandomInt();
        setRows(prev => [...prev, { DataBeanProperties: item }]);
        modalEdit.close();
    }

    const iconButtonsItem = [
        { nombre: 'Agregar', visible: true, icono: 'ri-file-add-line', evento: () => { modalEdit.open() } }
    ]

    const removeItem=()=>{
        let filtro = rows.filter( (item,row) => item.id != row.id);
        console.log(filtro);
        setRows(filtro);
        
    }

    const listaBotones =()=>{
        const botones = [
            
            // {titulo:'Editar',icono:'ri-edit-2-line',visible:bean[idBean],evento:editarItem},
             {titulo:'Eliminar',icono:'ri-edit-2-line',visible:true,evento:removeItem},
            

        ]
        return(
            <ListMenu listaBotones={botones}/>
        )
    }

    return (
        <div className="row">
            <div className="col-md-12">

                {head.length > 0 &&
                    <>

                        <BasicTable body={rows} head={head} rowSet={setRow} iconButtons={iconButtonsItem} listButtons={listaBotones()}></BasicTable>
                        {/* <hr />
                        <h6>Cabecera tabla</h6>
                        <PrintJson json={head} /> */}
                    </>

                }
            </div>
            <div className="col-md-12">
                <Modal modal={modalEdit.modal} updateModal={modalEdit.setModal}>
                    <div className="row">
                        <div className="col">
                            <RemoteForm propertyName={propertyName} useAsForm={true} idApplicationInvoker={idApplicationInvoker} fieldList={form} data={row} setData={addToList} />
                        </div>
                    </div>
                </Modal>
            </div>

        </div>
    )

}