import { useState, useEffect } from 'react';
import { ContractService } from '../../../core/services/ContractService';
import { Singleton } from '../../../core/services/Singleton';
interface Props {
    idAdquisitionPlanFunctionalID: number;
    idSelector: number;
    onChange: any;
}
export const SelectPreparadorCatalog = ({ idAdquisitionPlanFunctionalID, idSelector, onChange }: Props) => {

    const service = new ContractService();
    const [lista, setLista] = useState([]);
    const [id, setID] = useState(0);



    useEffect(() => {
        setID(idSelector);
        getLista();
    }, [idAdquisitionPlanFunctionalID])

    // useEffect(()=>{                
    //     setID(idSelector);
    //     getLista();

    // },[idAdquisitionPlanFunctionalID,idSelector])

    useEffect(() => {
        onChange(id);
    }, [id])

    const setValue = (e: any) => {
        setID(parseInt(e));
    }

    const getLista = () => {
        
            service.getFunctionalIDPreparerCatalog(idAdquisitionPlanFunctionalID, null, null).subscribe(
                (resp: any) => {
                    if (resp.DataBeanProperties.ObjectValue.DataBeanProperties.List) {
                        setLista(resp.DataBeanProperties.ObjectValue.DataBeanProperties.List);                                                                        
                    }
                }
            );
        
    }

    return (
        <>
            <label>Preparador</label>
            <select className="form-select" value={id} onChange={(e) => { setValue(e.target.value) }}>
                <option value={0}>...</option>
                {lista &&
                    lista.map((item: any) => {
                        return (
                            <option key={item.DataBeanProperties.IDFunctionalIDPreparer} value={item.DataBeanProperties.IDFunctionalIDPreparer} >{item.DataBeanProperties.AccountName}</option>
                        )
                    })
                }
            </select>
        </>
    )
}