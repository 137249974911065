import { useState, useEffect } from 'react';
import useDataBean from '../../../../../core/services/dataBean/useDataBean';
import { _MacroProcess } from '../../../../../core/services/dataBean/EntityCatalog';


interface Props {
    idSelector: number;
    onChange: any;
    label?: string;
}

export const MarcroProccesEditor = ({ idSelector, onChange, label }: Props) => {

    const macroProcess = useDataBean(_MacroProcess)
    const [lista, setLista] = useState<any>([])
    const [id, setID] = useState(0);

    useEffect(() => {
        if (idSelector >= 0) {
            setID(idSelector);
        }
    }, [])

    useEffect(() => {
        getLista();
    }, [])


    useEffect(() => {
        onChange(id);
    }, [id])

    const setValue = (e: any) => {
        setID(parseInt(e));
        onChange(id);
    }

    // const getLista = () => {
    //     macroProcess.instance.getMacroProcessCatalog().then(
    //         (resp) => {
    //             setLista(resp)
    //             if (resp.length > 0 && !idSelector) {
    //                 setID(resp[0].DataBeanProperties.IDMacroProcess)
    //             }
    //         }
    //     )
    // }

    const getLista = () => {

        macroProcess.instance.getMacroProcessCatalog().then(
            (resp: any) => {
                if (resp) {
                    setLista(resp)
                   //console.log(resp)
                }
            }
        )

    }

    return (
        <>
            <label>{label ?? "Macro Proceso:"}</label>
            <select className="form-select" value={id} onChange={(e) => { setValue(e.target.value) }}>
                <option>...</option>
                {lista &&
                    lista.map((item: any, index: number) => {
                        return (
                            <option key={index} value={item.DataBeanProperties.IDMacroProcess} >{item.DataBeanProperties.Name}</option>
                        )
                    })
                }
            </select>
        </>
    )
}