import { useEffect, useState } from "react";
import useDataBean from "../../../../core/services/dataBean/useDataBean";
import { _CostingCenter, _MeasurementUnitCode } from "../../../../core/services/dataBean/EntityCatalogVdos";
import { Singleton } from "../../../../core/services/Singleton";
import { CashReimbursementServices } from "../CashDesk/CashReimbursement/CashReimbursement-service";
import { toast } from "react-toastify";
import { BasicTable, MASK_NUMBER } from "../../../theme/Component/BasicTable";
import { ListMenu } from "../../../theme/Component/ListMenu";
import { BasicPage } from "../../../theme/Component/BasicPage";
import { Modal } from "../../../theme/Component/Modal";
import { ModalConfirm } from "../../../theme/Component/ModalConfirm";





export const CostingCenter = () => {

    const CostingCenter = useDataBean(_CostingCenter);

    const single = Singleton.getInstance();
    const [lista, setLista] = useState<any>([]);

    const [modal, setModal] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalC, setModalC] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });



    useEffect(() => {
        getLista()

    }, [])




    const getLista = () => {

        CostingCenter.instance.getCostingCenterCatalog(null).then(
            (resp: any) => {
                if (resp) {
                    setLista(resp)

                   //console.log(resp)
                    toast.success('Completado')
                } else {
                    toast.error('NO hay datos')
                }

            }
        )

    }


    const create = () => {
        CostingCenter.setBean({


        });

        setModal({ ...modal, "view": true });
    }


    const deleteItem = () => {
        CostingCenter.deleteItemVoid(CostingCenter.bean.IDCostingCenter).then(
            (resp: any) => {
                getLista();
                setModalC({ ...modalC, "view": false });
            }
        )

    }


    const editaItem = () => {
       //console.log(CostingCenter.bean);
        CostingCenter.update().then(
            (resp: any) => {
                getLista();
                setModal({ ...modal, "view": false });
            }
        ).catch(err => {
            toast.error(err);
        })

    }


    const head = [
        { title: 'ID', property: 'IDCostingCenter', visible: true, mask: MASK_NUMBER },
        { title: 'Nombre', property: 'Name', visible: true },

        { title: 'Código', property: 'Code', visible: true },
        { title: 'Descripción', property: 'Description', visible: true },


    ]

    const iconButtonsItem = [
        { nombre: 'Agregar', visible: single.canCreate(), icono: 'ri-file-add-line', evento: create }
    ]
    const listaBotones = () => {
        const botones = [

            { titulo: 'Editar', icono: 'ri-edit-2-line', visible: single.canEdit(), evento: () => { setModal({ ...modal, "view": true }) } },
            { titulo: 'Eliminar', icono: 'ri-delete-bin-line', visible: single.canDelete(), evento: () => { setModalC({ ...modalC, "view": true }) } },

        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }



    return (
        <BasicPage titulo="Centros de Costo">
            <div className="row">

                <div className="col-md-12">

                    <BasicTable body={lista} head={head} rowSet={CostingCenter.setBean} listButtons={listaBotones()} iconButtons={iconButtonsItem}></BasicTable>
                    <Modal modal={modal} updateModal={setModal} eventModal={editaItem}>
                        <>
                            <div className="row">
                                <div className="col-md-6">
                                    <label>Nombre</label>
                                    <input type="text" name="Name" className="form-control" value={CostingCenter.bean.Name} onChange={CostingCenter.handleChange} />

                                </div>

                                <div className="col-md-6">
                                    <label>Código</label>
                                    <input type="text" name="Code" className="form-control" value={CostingCenter.bean.Code} onChange={CostingCenter.handleChange} />

                                </div>

                                <div className="col-md-12">
                                    <label>Description</label>
                                    <textarea name="Description" className="form-control" value={CostingCenter.bean.Description} onChange={CostingCenter.handleChange} />
                                </div>
                            </div>

                        </>
                    </Modal>

                    <ModalConfirm modal={modalC} updateModal={setModalC} eventModal={deleteItem}>
                        <strong>Eliminar item</strong>
                    </ModalConfirm>
                </div>

            </div>
        </BasicPage>
    )
}