import { DataBean } from "../DataBean";

export class StrategicObjetive extends DataBean {


    public async getStrategicObjectiveCatalog(idMacroProcess: number | null, idSubProcess): Promise<any> {
        return this.getCustomService("PeopleArt", "java.util.List_getStrategicObjectiveCatalog_Number_Number", [idMacroProcess, idSubProcess]);
    }

    public async deleteStrategicObjective(bean): Promise<any> {
        return this.getCustomService("PeopleArt", "void_deleteStrategicObjective_com.peopleart.bean.appointment.StrategicObjective", [
            {
                "DataBeanProperties": bean,
                "DataBeanName": "com.peopleart.bean.appointment.StrategicObjective"
            }
        ]);
    }
}