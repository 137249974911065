import { useEffect, useState } from "react";
import { GarantiasService } from "../../../core/services/GarantiasService";
import { useDashboardStore } from "../../pages/hooks/useDashboardStore";
import { Singleton } from "../../../core/services/Singleton";
import { ExportXLSL } from "../../theme/Component/ExportXLSX";
import { BasicButton } from "../../theme/Component/BasicButton";

interface Props {
    typeGuarantee: number;
}
export const ReportCredit = ({ typeGuarantee }: Props) => {

    const single = Singleton.getInstance();
    const [lista, setLista] = useState([]);
    const service = new GarantiasService();
    const [year, setYear] = useState(single.getCurrenYear());
    const [monthID, setMonthID] = useState(1);
    const [monthName, setMonthName] = useState('');
    const { setLoading } = useDashboardStore();

    const [month, setMonth] = useState([
        { id: 0, name: "Enero" },
        { id: 1, name: "Febrero" },
        { id: 2, name: "Marzo" },
        { id: 3, name: "Abril" },
        { id: 4, name: "Mayo" },
        { id: 5, name: "Junio" },
        { id: 6, name: "Julio" },
        { id: 7, name: "Agosto" },
        { id: 8, name: "Septiembre" },
        { id: 9, name: "Octubre" },
        { id: 10, name: "Noviembre" },
        { id: 11, name: "Diciembre" }
    ]);

    useEffect(()=>{
        setLista([]);
    },[year,monthID])

    useEffect(()=>{
       

        const m:any =  month.filter(function(e:any){
            return e.id === monthID
        });
        
        
        setMonthName(year+"-"+m[0].name);

    },[monthID])

    const getLista = () => {
        setLoading(true);
        service.getAllCreditMonthRender(year,monthID,1,typeGuarantee).subscribe(        
            (resp: any) => {

                if (resp.DataBeanProperties.ObjectValue) {
                    let list = resp.DataBeanProperties.ObjectValue;
                    list.forEach((ele: any) => {

                        try {
                            ele.DataBeanProperties.AccountName =
                                (ele.DataBeanProperties.Name1 ?? '') + " " +
                                (ele.DataBeanProperties.Name2 ?? '') + " ";

                            ele.DataBeanProperties.AccountSurname =
                                (ele.DataBeanProperties.Surname1 ?? '') + " " +
                                (ele.DataBeanProperties.Surname2 ?? '');

                            ele.DataBeanProperties.TypeGuaranteeName =
                                ele.DataBeanProperties.TypeGuarantee === 1 ? 'Hipotecario' : 'Leasing';

                            ele.DataBeanProperties.CiuMun = ele.DataBeanProperties.NameCiu + " / " + ele.DataBeanProperties.Departamento;
                            ele.DataBeanProperties.StartDate = ele.DataBeanProperties.StartDate.substring(0, 10);
                            ele.DataBeanProperties.EndDate = ele.DataBeanProperties.EndDate.substring(0, 10);

                            ele.DataBeanProperties.FixedValueMonth = ele.DataBeanProperties.FixedValueMonth ?? 0;
                            ele.DataBeanProperties.Total = ele.DataBeanProperties.PropertyValue + ele.DataBeanProperties.FixedValueAcu;
                            

                        } catch (error) {

                        }

                    });
                    setLista(list);

                }
                setLoading(false);
            }
        );
    }

    const getLista2 = () => {

        setLoading(true);

        let params: any = {
            State: 1,
            TypeGuarantee: typeGuarantee
        };

        service.getRealesCreditRender(null, null, params).subscribe(
            (resp: any) => {

                if (resp.DataBeanProperties.ObjectValue) {
                    let rec = resp.DataBeanProperties.ObjectValue;
                   

                    if (Array.isArray(rec)) {
                        let list = rec[1];


                        if (Array.isArray(list)) {
                            list.forEach((ele: any) => {

                                try {
                                    ele.DataBeanProperties.AccountName =
                                        (ele.DataBeanProperties.Name1 ?? '') + " " +
                                        (ele.DataBeanProperties.Name2 ?? '') + " ";

                                    ele.DataBeanProperties.AccountSurname =
                                        (ele.DataBeanProperties.Surname1 ?? '') + " " +
                                        (ele.DataBeanProperties.Surname2 ?? '');

                                    ele.DataBeanProperties.TypeGuaranteeName =
                                        ele.DataBeanProperties.TypeGuarantee === 1 ? 'Hipotecario' : 'Leasing';

                                    ele.DataBeanProperties.CiuMun = ele.DataBeanProperties.Name + " / " + ele.DataBeanProperties.Departamento;
                                    ele.DataBeanProperties.StartDate = ele.DataBeanProperties.StartDate.substring(0, 10);
                                    ele.DataBeanProperties.EndDate = ele.DataBeanProperties.EndDate.substring(0, 10);

                                    // ele.DataBeanProperties.PropertyValue = ele.DataBeanProperties.PropertyValue ?? 0;
                                    // ele.DataBeanProperties.FixedValueAcu = ele.DataBeanProperties.FixedValueAcu ?? 0;

                                    ele.DataBeanProperties.AjusteGarantia =
                                        parseFloat(ele.DataBeanProperties.PropertyValue ?? 0) +
                                        parseFloat(ele.DataBeanProperties.FixedValueAcu ?? 0);
                                } catch (error) {

                                }

                            });
                            setLista(list);
                        } 
                    }
                }
                setLoading(false);
            }
        );
    }
    let head = [

        { title: 'Tipo', property: 'TypeGuaranteeName', visible: false },
        
        { title: 'Radicado', property: 'IDProcedure' },
        { title: 'Documento', property: 'nit' },
        { title: 'Nombre', property: 'AccountName' },
        { title: 'Apellidos', property: 'AccountSurname' },
        { title: 'Ciudad ', property: 'CiuMun' },

        { title: 'Fecha Inicial', property: 'StartDate' },
        { title: 'Fecha Final', property: 'EndDate' },
        
        { title: 'Tasa', property: 'PercentValue' },
        { title: 'Valor Inmueble ', property: 'PropertyValue' },    
        { title: 'Tasa', property: 'PercentValue', visible: false },
        { title: monthName, property: 'FixedValueMonth' },
        { title: 'Ajuste Acumulado', property: 'FixedValueAcu' },
        { title: 'Total', property: 'Total' },
        { title: 'Chip', property: 'ChipProperty', visible: false },
        { title: 'Cédula catastral', property: 'NitProperty', visible: false },
        { title: 'Matrícula Inmobiliaria del Inmueble', property: 'TuitionProperty', visible: false },
        { title: 'Matrícula Inmobiliaria del parqueadero', property: 'ParkingProperty', visible: false },
        { title: 'Dirección del inmueble', property: 'AddressProperty', visible: false },

        

    ]
    return (
        <div className="row">
            <div className="col-md-6">
                <label>Año</label>
                <input type="number" className="form-control" value={year} onChange={(e) => { setYear(parseInt(e.target.value)) }} />
            </div>
            <div className="col-md-6">
                <label>Mes</label>
                <select className="form-select" value={monthID} onChange={(e) => { setMonthID(parseInt(e.target.value)) }}>
                    {month.map((mes: any) => {
                        return (
                            <option value={mes.id}>{mes.name}</option>
                        )
                    })}
                </select>
            </div>
            <div className="col-md-6">
                <BasicButton icon="ri-find-replace-line" eventClick={getLista}>Buscar</BasicButton>
            </div>
            <div className="col-md-6 mt-3">
              {lista.length>0 &&
                <ExportXLSL customName={monthName} optionIcon="ri-download-cloud-2-line text-success" title={head} data={lista} onExport={(name: string) => {
                    single.download("formato xlsx [" + name + "] con " + lista.length + " registros");
                }} />
              }
            </div>
            
        </div>
    )

}