import { useState, useEffect } from 'react';
import { Singleton } from "../../../../core/services/Singleton";
import { BpmService } from "../../../../core/services/BpmService";
import { useForm } from "react-hook-form";
import { useDashboardStore } from "../../../pages/hooks/useDashboardStore";

export const Formreactform = () => {

    const single = Singleton.getInstance()
    const { setLoading } = useDashboardStore();
    const service = new BpmService();
    const [lista, setLista] = useState([]);
    const [bean, setBean] = useState<any>({});
    const [user, setUser] = useState<any>({});
    const { register, handleSubmit } = useForm();

    const [ciudad, setCiudad] = useState(0);
    const [modal, setModal] = useState<any>({ name: 'Agregar o Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalLista, setModalLista] = useState<any>({ name: 'Agregar o Editar', tabIndex: -1, view: false, format: 'modal-lg' });
    const [modalConfirm, setModalConfirm] = useState<any>({ name: 'Confirmar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });
    const [modalT, setModalT] = useState<any>({ name: 'Lista de tramites por sucursal', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    //cambios

    const idBean = 'IDOffice';

    const head = [
        { title: 'ID', property: idBean },
        { title: 'Nombre', property: 'Name' },
        { title: 'Descripción', property: 'Description' },
        { title: 'Fecha', property: 'Since' },
    ]

    useEffect(() => {
        // getgetSiteID();
        setUser(single.getAccountID());
        getLista();
    }, [])

    const setRowBean = (bean: any) => {
       //console.log("Row : ", bean);
        setBean(bean);

    }

    const getLista = () => {
        setLoading(true);
        service.getOfficeCatalog(null).subscribe(
            (resp: any) => {

                if (resp.DataBeanProperties.ObjectValue) {
                    setLista(resp.DataBeanProperties.ObjectValue);
                }
                setLoading(false);
            }
        );
    }

    const agregarItem = () => {
        let b = {
            Name: '',
            Description: '',
            State: 0,
            Since: single.getCurrenDate(),
            Code: 0,
            IDSiteLn: 0,
            IDEmployee: user.IDAccount,
        }
        setBean(b);
        setModal({ ...modal, ['view']: true });
    }

    const editarItem = () => {
        setModal({ ...modal, ['view']: true });
    }

    const eliminarItem = () => {

        setLoading(true);
        service.deleteOffice(bean[idBean]).subscribe(
            (resp: any) => {
                setLoading(false);
                if (resp) {
                    getLista();
                }
            }
        );
        setModalConfirm({ ...modalConfirm, ['view']: false });

    }
    const updateItem = () => {

        setModal({ ...modal, ['view']: false });

        setLoading(true);
        service.updateOffice(bean).subscribe(
            (resp: any) => {
                setLoading(false);
                if (resp.DataBeanProperties.ObjectValue) {
                    getLista();
                }
            });

    }
    // const updateItem1 = () => {

    //     setModal({ ...tramites, ['view']: false });

    //     setLoading(true);
    //     service.updateOffice(bean).subscribe(
    //         (resp: any) => {
    //             setLoading(false);
    //             if (resp.DataBeanProperties.ObjectValue) {
    //                 getListaT();
    //             }
    //         });

    // }
    const verlista = () => {

        setModalLista({ ...modalLista, ['view']: true });

    }

    // const renderButton =()=>{
    //     return(
    //         <>
    //             <div className="col-md-12">
    //                 <BasicButton value="Agregar" icon="ri-file-add-line" eventClick={agregarItem} ></BasicButton>
    //                 <BasicButton value="tramites" icon="ri-building-4-line" eventClick={()=>{setModalLista({...modalLista, ['view']: true}); }}></BasicButton>
    //                 { bean[idBean] &&
    //                     <>
    //                         <BasicButton value="Editar" icon="ri-edit-2-line" eventClick={editarItem} ></BasicButton>
    //                         <BasicButton value="Eliminar" icon="ri-eraser-line" eventClick={() => { setModalConfirm({ ...modalConfirm, ['view']: true }); }} ></BasicButton>
    //                     </>
    //                 }
    //             </div>
    //         </>
    //     )
    // }

    const onSubmit = (data: any) => {
       //console.log(data);
    }

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <label >Nombre</label>
                                <input type="text" {...register('nombre', { required: true })} className="form-control" id="inputAddress" placeholder="..." />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label >Apellido</label>
                                <input type="text" {...register('Apellido', { required: true })} className="form-control" id="inputAddress2" placeholder="..." />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <label >E-mail</label>
                                <input type="text" {...register('E-mail', { required: true })} className="form-control" id="inputAddress" placeholder="..." />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label >Telefono</label>
                                <input type="text" {...register('Telefono', { required: true })} className="form-control" id="inputAddress2" placeholder="..." />
                            </div>
                        </div>
                    </div>


                    <div className="form-row">

                        <div className="form-group col-md-6">
                            <label >Tipo de Documento</label>
                            <select id="inputState" {...register('Documento', { required: true })} className="form-control">
                                <option selected value={0}>...</option>
                                <option value={1}>C.C</option>
                                <option value={2}>Pasaporte</option>
                                <option value={3}>Nit</option>
                                <option value={4}>Otro</option>
                            </select>
                        </div>

                    </div>
                    <div className="form-group">
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" id="gridCheck" />
                            <label className="form-check-label" >
                                Check me out
                            </label>
                        </div>
                    </div>
                    <input type="submit" value="Enviar" className="btn btn-primary" />
                </div>


            </form>
        </>
    )
}