import { useEffect, useState } from "react";
import { _EmployeeContract, _EmployeeContractHistory } from "../../../../core/services/dataBean/EntityCatalog";
import useDataBean from "../../../../core/services/dataBean/useDataBean";
import { Singleton } from "../../../../core/services/Singleton";
import { BasicPage } from "../../../theme/Component/BasicPage";
import { BasicTable, MASK_DATE, MASK_MONEY, MASK_NUMBER, MASK_TEXT_NOWRAP } from "../../../theme/Component/BasicTable";
import { BasicButton } from "../../../theme/Component/BasicButton";
import usePaginator from "../../../theme/Component/usePaginator";

export const EmployeeContractWithoutFA = () => {

    const employeeContract = useDataBean(_EmployeeContract);
    const single = Singleton.getInstance();
    const [lista, setLista] = useState<any>([]);
    const [pages, setPages] = useState<number>(null);
    const [records, setRecords] = useState<number>(15);

    const paginador = usePaginator();

    useEffect(() => {
        getLista();
    }, [paginador.page])

    const getLista = () => {
        employeeContract.instance.getActiveEmployeeContractsWithoutFunctionalArea(paginador.page, records).then(
            (resp) => {
               //console.log(resp.NumberOfPages)
                paginador.setPages(resp.NumberOfPages);
                paginador.setRecords(resp.NumberOfPages * (records));
                paginador.setRegiPerPage(records)

                setLista(resp.List)
            }
        )
    }

    const head = [
        { title: 'IDContrato', property: 'IDContract', visible: true, mask: MASK_NUMBER },
        { title: 'Identificación', property: 'Nit', visible: true },
        { title: 'Nombres', property: 'FullNames', visible: true, mask: MASK_TEXT_NOWRAP },
        { title: 'Apellidos', property: 'FullSurNames', visible: true, mask: MASK_TEXT_NOWRAP },
        { title: 'Perfil de Liquidación', property: 'IDEmployeeLiquidate', visible: true },
        { title: 'Nivel Jerárquico', property: 'EntailmentName', visible: true },
        { title: 'Nivel Salarial/Grado', property: 'AppointmentName', visible: true },
        { title: 'Tipo Vinculación', property: 'ContractType', visible: true },
        { title: 'Área Funcional', property: 'IDLnFunctionalArea', mask: MASK_NUMBER, visible: true },
        { title: 'Fecha de Inicio', property: 'InitContract', mask: MASK_DATE, visible: true },
        { title: 'Ingreso Nación', property: 'NationInit', mask: MASK_DATE, visible: true },
        { title: 'EPS', property: 'EPSName', visible: true },
        { title: 'ARP', property: 'ARPName', visible: true },
        { title: 'CCF', property: 'CCFName', visible: true },
        { title: 'Fondo Pensional', property: 'FPName', visible: true },
        { title: 'Fondo Cesantías', property: 'FCEName', visible: true },
        { title: 'Salario', property: 'Revenue', mask: MASK_MONEY, visible: true },
        { title: 'Tipo de Salario', property: 'RevenueType', visible: true },
        { title: 'Tipo Riesgo', property: 'ARPRiskName', visible: true },
        { title: 'Tipo Cotizante', property: 'EmployeeTypeName', visible: true },
        { title: 'Subtipo Cotizante', property: 'EmployeeSubtypeName', visible: true },
        { title: 'Fecha Renuncia', property: 'EndingDate', mask: MASK_DATE, visible: true },
        { title: 'Estado', property: 'ContractStateName', visible: true },
        { title: 'Fecha Liquidación', property: 'UptoContract', mask: MASK_DATE, visible: true },
        { title: 'Fecha de Nacimiento', property: 'BornDate', mask: MASK_DATE, visible: true },
        { title: 'Dirección', property: 'Address', mask: MASK_TEXT_NOWRAP, visible: true },
        { title: 'Teléfono', property: 'Tel', visible: true },
        { title: 'Correo Electrónico', property: 'eMail', visible: true },
        { title: 'Género', property: 'GenType', visible: true },
        { title: 'Resposable ImpoRenta', property: 'RentTax', visible: true },
        { title: 'Último Exámen Médico', property: 'HealthTestDate', mask: MASK_DATE, visible: true },
        { title: 'Últimas Vacaciones', property: 'LastVacDate', mask: MASK_DATE, visible: true },
        { title: 'Última Dotación', property: 'LastEndowmentDate', mask: MASK_DATE, visible: true },
        { title: 'Tipo de Empleo', property: 'EmploymentTypeName', visible: true },
        { title: 'Código CIIU', property: 'CIIUCode', mask: MASK_NUMBER, visible: true },
        { title: 'Subtipo ARL', property: 'ARPRiskName', visible: true },
        { title: 'Definir Centro de Costo', property: 'UseOwnCostingCenter', visible: true },
        { title: 'Centro de Costo', property: 'CostingCenter', mask: MASK_NUMBER, visible: true },
        { title: 'Tipo de Contrato', property: 'EmployeeContractType', visible: true },
        { title: 'Definir Límite de UVTs', property: 'UseUVTMaxRetefuente', visible: true },
        { title: 'Limite UVTs', property: 'UVTMaxRetefuente', visible: true },
        { title: 'Ciudad de Ejecución del Contrato', property: 'SiteIDName', visible: true },
        { title: 'Creado por', property: 'EmployeeName', visible: true },
        { title: 'Actualizado por', property: 'LastUpdateEmployeeName', visible: true },
        { title: 'Última Actualización', property: 'IDEmployeeLastUpdate', visible: true },
    ]

    return (
        <BasicPage titulo="Contratos Laborales sin Plaza Laboral">
            <div className="row">
                <div className="col-5">
                    <label>Registros por Página</label>
                    <input type="number" className="form-control" value={records} onChange={(e) => { setRecords(parseInt(e.target.value)) }} />
                </div>
                <div className="col-2 d-flex align-items-end">
                    <BasicButton icon="ri-search-line" eventClick={() => getLista()}>Buscar</BasicButton>
                </div>
            </div>
            <BasicTable
                head={head}
                body={lista}
                rowSet={employeeContract.setBean}
                pageTop={paginador.page}
                next={paginador.next}
                back={paginador.back}
                records={paginador}
                customRows={records}
            />
        </BasicPage>
    )
}