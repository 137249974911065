import { useEffect, useState } from "react"
import { StepWizard, Wizard } from "../../bpm/config/Componentes/wizard/Wizard"
import { BasicPage } from "../../theme/Component/BasicPage"
import { AdquisitionContractEditor } from "./AdquisitionContractEditor"
import { RegisterRFContract } from "./RegisterRFContract"
import { CreateRFDocument } from "./CreateRFDocument"

export const BudgetRPFactory = () => {

    const [contrato, setContrato] = useState<any>({});
    const [rpList, setRPList] = useState<any>({});
    const [allPerfect, setAllPerfect] = useState<boolean>(false)

    const validarContrato = () => {

    }

    useEffect(() => {
        let allItemsHaveIDDocument = rpList.length > 0 && rpList.every(item => item.DataBeanProperties.CDPIDDocument);
        setAllPerfect(allItemsHaveIDDocument);
    }, [rpList])

    const steps: StepWizard[] = [
        {
            keyID: "0",
            name: 'PASO 1. Formulario',
            onEvent: validarContrato,
            children: <AdquisitionContractEditor onChange={setContrato} />,
            validButton: (contrato?.Contract?.IDAdquisitionContract && contrato?.AccDate),
            canReturn: false
        },
        {
            keyID: "1",
            name: 'PASO 2. Rubros Presupuestales a Registrar',
            onEvent: validarContrato,
            children: <RegisterRFContract contract={contrato.Contract} onChange={(e) => { setRPList(e) }} />,
            validButton: allPerfect,
            canReturn: true
        },
        {
            keyID: "2",
            name: 'PASO 3. Crear Registro Presupuestal',
            onEvent: validarContrato,
            children: <CreateRFDocument rpList={rpList} contrato={contrato} />,
            validButton: false,
            canReturn: false
        }
    ]
    return (
        <BasicPage titulo="Generar Registro Presupuestal de Contrato">
            <Wizard title="Certificado" listStep={steps} />
        </BasicPage>
    )
}