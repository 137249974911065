import { useEffect, useState } from "react";
import { _AdquisitionContract, _BudgetRPFactory, _single } from "../../../core/services/dataBean/EntityCatalog";
import { InputDate } from "../../theme/Input/InputDate";
import { AccountEditor } from "../../editor/AccountEditor";

interface Props {
    onChange: Function;
}

export const BudgetRPFactoryWithoutForm = ({ onChange }: Props) => {

    const [response, setResponse] = useState<any>({
        AccDate: _single.getCurrenDate(),
    });

    useEffect(() => {
        onChange(response)
    }, [response])

    return (
        <div className="row">
            <div className="col-md-6">
                <label >Fecha de Registro</label>
                <InputDate name="" setDate={(e) => { setResponse({ ...response, "AccDate": e }) }} value={response.AccDate} />
            </div>
            <div className="col-md-6">
                <label>Numero de Consecutivo Externo</label>
                <input type="number" value={response.Consecutive} onChange={(e) => { setResponse({ ...response, "Consecuitive": parseInt(e.target.value) }) }} className="form-control" />
            </div>
            <div className="col-md-6">
                <AccountEditor idAccount={response.IdAccount} label="Beneficiario del contrato" onChange={(e) => { setResponse({ ...response, "IdAccount": e.IDAccount }) }} />
            </div>
            <div className="col-md-12">
                <label>Descripción</label>
                <textarea className="form-control" value={response.Description} onChange={(e) => { setResponse({ ...response, "Description": e.target.value }) }} />
            </div>
        </div>
    )
}