
import { QuickAction } from '../MenuDashed/MenuDashed';
import './menubutton.scss'

interface Props {
    item: QuickAction
    labelSize?: number
    iconSize?: number
    outline?: boolean
    active?: boolean
}

export const MenuButton = ({ item, iconSize, labelSize, outline, active }: Props) => {



    return (
        <div className={`menu_button ${active ? 'active' : ''}`}
            // style={{ border: outline ? '0.5px solid' : '' }}
            onClick={() => item.event(item)}>
            <div className="menu_label">
                {item.label}
            </div>
            <div className="menu_icon" style={{
                fontSize: iconSize ? iconSize : '',
                color: active ? 'orange' : '',
                scale: active ? '0.7' : ''
            }}>
                {item.icon}
            </div>
        </div>
    )
}
