
export const UBLCONTRACT = 2;
export const UBLSUPPLIER = 1;
export const SCHEMA_IMPUESTO = 1;
export const SCHEMA_RETENCION = 2;
export const TAX_IMPUESTO = 1;
export const TAX_RETENCION = 2;


export interface UBLParam {
    IDAccountSupplier: number;
    IDAdquisitionContract: number | null;
    From: Date | string | null;
    Upto: Date | string | null;
    State: number | null;

}