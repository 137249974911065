import { BasicPage } from "../../../../theme/Component/BasicPage";
import { BasicTable } from "../../../../theme/Component/BasicTable";
import { ListMenu } from "../../../../theme/Component/ListMenu";
import { useCredit } from "./useCredit";



export const Credit = () => {
    const {
        lista,
        estados,
        head,
        state,
        setState,
        setRowBean,
    } = useCredit();

    const listaBotones = () => {
        const botones = [
            {}
        ];
        return <ListMenu listaBotones={botones} />;
    };

    return (
        <>
            <BasicPage titulo="Creditos Consultados/Financiados">
                <div className="row">
                    <div className="col-md-6">
                        <label>Estado</label>
                        <select name="estado" className="form-select" value={state} onChange={(e)=>{setState(parseInt(e.target.value))}}>
                            {estados.map((item:any,index:number)=>{
                                return(
                                    <option key={index} value={item.id}>{item.name}</option>
                                )
                            })}
                        </select>
                    </div>
                    <div className="col-md-12">
                        <BasicTable listButtons={listaBotones()} head={head} body={lista} rowSet={setRowBean} />
                    </div>
                </div>
            </BasicPage>

        </>
    );
};
