import { useEffect, useState } from 'react';
import { Modal } from '../../../../theme/Component/Modal';
import useDataBean from '../../../../../core/services/dataBean/useDataBean';
import { _EmployeeBonus } from '../../../../../core/services/dataBean/EntityCatalog';

interface Props {
    label?: string;
    onChange: Function;
    values?: any;
}

export const MultiMonthEditor = ({ onChange, label, values }: Props) => {

    const [modal, setModal] = useState<any>({ name: 'Lista de Meses Seleccionados', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });
    const [lista, setLista] = useState<any>([]);
    const wellfare = useDataBean(_EmployeeBonus);
    const [selectedMonths, setSelectedMonths] = useState([]);
    const [selectedMonthsName, setSelectedMonthsName] = useState([]);

    useEffect(() => {
        if (Array.isArray(values)) {
            setSelectedMonths(values);
        } else if (typeof values === 'string') {
            let x = JSON.parse(values);
            setSelectedMonths(x);
        } else {
            console.error(`Error: Se esperaba un array o una cadena, pero se recibió ${typeof values}`);
        }
        getLista();
    }, [values]);

    useEffect(() => {
        const selectedMonthNames = lista
            .filter(month => selectedMonths.includes(month.DataBeanProperties.Value))
            .map(month => month.DataBeanProperties.Property);

        setSelectedMonthsName(selectedMonthNames);
    }, [selectedMonths, lista]);

    const getItem = () => {
        onChange(selectedMonths);
        setModal({ ...modal, "view": false });
    }

    const getLista = () => {
        wellfare.instance.getPayrollMonthsConstants(null).then(
            (resp) => {
                setLista(resp);
                if (!values) {
                    setSelectedMonths(resp.map(month => month.DataBeanProperties.Value))
                }
            }
        );
    }

    const handleCheckboxChange = (value) => {
        const updatedSelectedMonths = selectedMonths.includes(value)
            ? selectedMonths.filter(month => month !== value)
            : [...selectedMonths, value];

        setSelectedMonths(updatedSelectedMonths);
    };

    return (
        <>
            <label>{label ?? "Lista de Meses"}</label>
            <span
                className="form-control text-wrap"
                style={{ minHeight: '35px' }}
                onClick={() => setModal({ ...modal, view: true })}
            >
                {selectedMonthsName.length ? selectedMonthsName.join(', ') : "Meses Seleccionados"}
            </span>

            <Modal modal={modal} updateModal={setModal} eventModal={getItem}>
                <>
                    <div className="row">
                        {lista.map((month) => (
                            <div className='d-flex' key={month.DataBeanProperties.Value}>
                                <input
                                    type="checkbox"
                                    value={month.DataBeanProperties.Value}
                                    checked={selectedMonths.includes(month.DataBeanProperties.Value)}
                                    onChange={() => handleCheckboxChange(month.DataBeanProperties.Value)}
                                />
                                <div className='p-1'>
                                    {month.DataBeanProperties.Property}
                                </div>
                            </div>
                        ))}
                    </div>
                </>
            </Modal>
        </>
    );
}
