import { useEffect, useRef, useState } from "react";
import { _UBLInvoice, _UBLItemInvoice } from "../../../../../../core/services/dataBean/EntityCatalog";
import useDataBean from "../../../../../../core/services/dataBean/useDataBean";
import { Singleton } from "../../../../../../core/services/Singleton";
import { toast } from "react-toastify";
import { BasicTable, MASK_MONEY } from "../../../../../theme/Component/BasicTable";
import { Modal } from "../../../../../theme/Component/Modal";
import { ListMenu } from "../../../../../theme/Component/ListMenu";
import { AssetClassfierEditor } from "../../../../../editor/AssetClassifier/AssetClassfierEditor";
import { DropDown } from "../../../../../theme/Component/DropDown";
import { SelectStoreEditor } from "../SelectStore.editor";
import { DocumentViewer } from "../../../../../theme/Component/DocumentViewer";

interface Props {
    _idUBLInvoice: number;
    _issueDate: Date;
    _invoice?: any;
    _onChange?: Function;
}

export const ManageUBLInvoiceItems = ({ _idUBLInvoice, _issueDate, _invoice, _onChange }: Props, ref) => {

    const single = Singleton.getInstance();
    const { bean, setBean, lista, setLista, instance } = useDataBean(_UBLItemInvoice);
    const ublInvoice = useDataBean(_UBLInvoice);

    const [store, setStore] = useState<number>(0)
    const [missingFields, setMissingFields] = useState<any[]>([]);
    const [action, setAction] = useState<any>({});
    const [docs, setDocs] = useState<any>([]);

    const [modal, setModal] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalValidate, setModalValidate] = useState<any>({ name: 'Generar Entrada', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalSee, setModalSee] = useState<any>({ name: 'Documentos Generados', tabIndex: -1, view: false, btnClose: false, btnSubmit: true, format: 'modal-xl' });


    const assetClassfierRef = useRef<any>(null);

    useEffect(() => {
        getLista();
    }, [_idUBLInvoice])

    //servicios Basicos UBLInvoiceItem

    const getLista = () => {
        instance.getUBLItemInvoiceCatalog(_idUBLInvoice, _issueDate).then(
            (resp) => {
                setLista(resp);
            }
        ).catch((err) => {
           console.error(err);
            toast.error("No se pudo obtener la lista");
        });

    };

    useEffect(() => {
        if (lista.length > 0) {
            const fieldsMissing = lista.reduce((acc, objeto) => {
                if (!objeto.DataBeanProperties.IDFixedAsset && !objeto.DataBeanProperties.IDProduct) {
                    acc.push({
                        IDUBLItemInvoice: objeto.DataBeanProperties.IDUBLItemInvoice,
                        Description: objeto.DataBeanProperties.Description
                    });
                }
                return acc;
            }, []);

            setMissingFields(fieldsMissing);
        }
    }, [lista]);


    useEffect(() => {
        if (docs.length > 0) {
            setModalSee({ ...modalSee, "view": true })
        }
    }, [docs])

    const generateEntry = () => {
       //console.log(_idUBLInvoice, _issueDate, single.getCurrenDate(), store, single.getAccountID())
        ublInvoice.instance.createFixedAssetEntryForUBLInvoice(_idUBLInvoice, _issueDate, single.getCurrenDate(), store, single.getAccountID()).then(
            (resp) => {
                if (resp.DocumentURL.length > 0) {
                    let x = resp.DocumentURL;
                    let list = [];

                    x.forEach(element => {
                        list.push(element.DataBeanProperties)
                    });

                    setDocs(list)
                    setModalSee({ ...modalSee, "view": true })

                   //console.log(list)
                    setModalValidate({ ...modalValidate, "view": false })
                } else if (resp.MsgList.length > 0) {
                    let x = resp.MsgList;
                    x.forEach(element => {
                        toast.error(element, { autoClose: false })
                    });
                } else {
                    toast.error("No se pudieron generar los documentos")
                }
            }
        ).catch(
            err => {
               console.error(err)
                toast.error("No se Pudo Realizar la Accion")
            }
        )
    }

    const updateItem = () => {
        if (assetClassfierRef.current) {
            const { typeClass } = assetClassfierRef.current;
            const isValid = assetClassfierRef.current.validateData();
           //console.log(assetClassfierRef.current.validateData())

            if (typeClass == 5) {
                if (isValid) {
                    let x = assetClassfierRef.current.updateObject();
                   //console.log(x);
                    instance.updateUBLItemInvoice(x).then(
                        (resp) => {
                            getLista();
                            setModal({ ...modal, "view": false })
                        }
                    ).catch(
                        err => {
                           console.error(err);
                            toast.error("No Se Pudo Realizar la Accion", {})
                        }
                    )
                } else {
                    toast.error("Debe crear primero el objeto", {})
                }
            } else {
                instance.updateUBLItemInvoice(bean).then(
                    (resp) => {
                        getLista();
                        setModal({ ...modal, "view": false })
                    }
                ).catch(
                    err => {
                       console.error(err);
                        toast.error("No Se Pudo Realizar la Accion")
                    }
                )
            }

        } else {
            instance.updateUBLItemInvoice(bean).then(
                (resp) => {
                    getLista();
                    setModal({ ...modal, "view": false })
                }
            ).catch(
                err => {
                   console.error(err);
                    toast.error("No Se Pudo Realizar la Accion", {})
                }
            )
        }
    }

    const head = [
        { title: 'ID', property: 'IDUBLItemInvoice', visible: true },
        { title: 'Tipo Artículo', property: 'AdquisitionArticleTypeName', visible: true },
        { title: 'Nombre del Bien o Servicio', property: 'Description', visible: true },
        { title: 'Valor Unitario', property: 'UnitaryValue', visible: true, mask: MASK_MONEY },
        { title: 'Cantidad', property: 'Quantity', visible: true },
        { title: 'Subtotal', property: 'PriceValue', visible: true, mask: MASK_MONEY },
        { title: 'Subtotal Impuestos', property: 'TaxAmount', visible: true, mask: MASK_MONEY },
        { title: 'Valor Total', property: 'TotalItemAmount', visible: true, mask: MASK_MONEY },
        { title: 'Rubro Presupuestal', property: 'BudgetName', visible: true },
        { title: 'Tipo de Bien', property: 'AssetTypeName', visible: true },
        { title: 'Producto/Activo Asociado', property: "ProductDescription", visible: true },
    ]

    const listaBotones = () => {
        const botones = [
            { titulo: 'Clasificar Activos y Productos', icono: 'ri-file-list-2-line', visible: true, evento: () => { setModal({ ...modal, "view": true, "name": "Clasificar Activos y Productos" }) } },
        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }

    const actionsDropDown = [
        { id: 1, name: 'Generar Entrada de Almacén', permiso: null },
    ];

    const buttons = () => {
        return (
            <div className="row">
                <div className="col-md-12">
                    <DropDown lista={actionsDropDown} eventClick={setAction}></DropDown>
                </div>
            </div>
        )
    }

    useEffect(() => {
        if (action.id === 1) {
            if (missingFields.length > 0) {
                toast.error(`Hay elementos sin gestionar: ${missingFields.map(item => item.Description).join(", ")}`, { autoClose: false });
            } else {
                setModalValidate({ ...modalValidate, "name": "Generar Entrada de Almacén", "view": true });
            }
        }
    }, [action])

    return (
        <>
            <div className="row">
                {_invoice &&
                    <>
                        <div className="col-md-8">
                            <div className="row">
                                <div className="col-md-6">
                                    <strong>Proveedor </strong><small>{_invoice.AccountSupplierName}</small>
                                </div>
                                <div className="col-md-6">
                                    <strong>Idenficación </strong><small>{_invoice.AccountSupplierNit}</small>
                                </div>
                                <div className="col-md-6">
                                    <strong>Valor </strong><small>{single.formatMoney(_invoice.TotalInvoiceAmount)}</small>
                                </div>
                                <div className="col-md-6">
                                    <strong>Concepto </strong><small>{_invoice.AccountantConceptName}</small>
                                </div>
                                <div className="col-md-6">
                                    <strong>Proceso </strong><small>{_invoice.ProcessTypeName}</small>
                                </div>
                                <div className="col-md-6">
                                    <strong>ID Factura </strong><small>{_invoice.IDUBLInvoice}</small>
                                </div>
                                <div className="col-md-6">
                                    <strong>Fecha Factura </strong><small>{_invoice.IssueDate}</small>
                                </div>
                            </div>
                        </div>
                    </>
                }
                <div className="col-md-4">
                    <strong>Bien/Servicio sin gestion de Producto/Activo Asociado:</strong> <small>{missingFields.length}</small>
                </div>
            </div>
            {/* <PrintJson json={missingFields} /> */}
            <BasicTable headButtons={buttons()} listButtons={listaBotones()} body={lista} head={head} rowSet={setBean} />

            {modal.view &&
                <Modal modal={modal} updateModal={setModal} eventModal={updateItem}>
                    <AssetClassfierEditor ref={assetClassfierRef} _itemInvoice={bean} _onChange={(e) => { setBean(e) }} />
                </Modal>
            }

            
                <Modal modal={modalValidate} updateModal={setModalValidate} onSubmitLabel="Generar Entrada" eventModal={() => { generateEntry() }}>
                    <SelectStoreEditor idSelector={store} onChange={(e) => { setStore(e.IDStore) }} />
                </Modal>
            

            {modalSee.view &&
                <Modal modal={modalSee} updateModal={setModalSee} eventModal={() => { _onChange(true) }} onSubmitLabel="Finalizar" >
                    <DocumentViewer list={docs} />
                </Modal>
            }
        </>
    )
}