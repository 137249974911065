import { DataBean } from "../DataBean";

export class AbstractAccount extends DataBean {

    public async getAccount(Name: String | null, Surname: String | null): Promise<any> {
        return this.getCustomService("OrangeBase", "java.util.List_getAccount_String_String", [Name, Surname]);
    }

    public async getAccountbyID(idAccount: number | null): Promise<any> {
        return this.getCustomService("OrangeBase", "com.advantage.bean.account.AbstractAccount_getAccount_Number", [idAccount]);
    }

    public async getAccountByNit(Nit: number | null): Promise<any> {
        return this.getCustomService("OrangeBase", "java.util.List_getAccountByNit_Number", [Nit]);
    }

    public async getEntityTypeConstants(arg: any | null): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_getEntityTypeConstants_Number", [arg]);
    }

    public async getDocTypeConstants(arg: any | null): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_getDocTypeConstants_Number", [arg]);
    }

    public async getGenderTypeConstants(arg: any | null): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_getGenderTypeConstants_Number", [arg]);
    }

    public async getBloodTypeConstants(arg: any | null): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_getBloodTypeConstants_Number", [arg]);
    }
    public async getRhTypeConstants(arg: any | null): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_getRhTypeConstants_Number", [arg]);
    }
    public async getMilitaryStatusConstants(arg: any | null): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_getMilitaryStatusConstants_Number", [arg]);
    }

    public async getCivilStatusConstants(arg: any | null): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_getCivilStatusConstants_Number", [arg]);
    }

    public async getRaceTypeConstants(arg: any | null): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_getRaceTypeConstants_Number", [arg]);
    }

    public async getHousingTypeConstants(arg: any | null): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_getHousingTypeConstants_Number", [arg]);
    }

    public async getStudiesTypeConstants(arg: any | null): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_getStudiesTypeConstants_Number", [arg]);
    }

    public async createAccount(entityType: number | null, docType: number | null, nit: number | null,): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "com.advantage.bean.account.AbstractAccount_createAccount_Number_Number_Number", [entityType, docType, nit]);
    }

    public async updateAbstractAccount(abstractAccount: any): Promise<any> {
        return this.getCustomService("OrangeBase", "com.advantage.bean.account.AbstractAccount_updateAbstractAccount_com.advantage.bean.account.AbstractAccount", [
            {
                "DataBeanProperties": abstractAccount,
                "DataBeanName": "com.advantage.bean.account.AbstractAccount"
            }
        ]);
    }

}
