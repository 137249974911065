import { DataBean } from "../DataBean"

export class BudgetObligationFactory extends DataBean {

    public async getRPBudgetForObligation(idDocument: number, date: any): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_getRPBudgetForObligation_Number_java.util.Date", [idDocument, date]);
    }

    public async createOB(rpIDDocument: number, rpDocumentDate: any, accountantDate: any, externalConsecutive: number, items: any, description: string, idEmployee: number, createPdf: boolean): Promise<any> {
        return this.getCustomService("QuickDataERPCore",
            "com.quickdataerp.bean.accountant.AccountantDocument_createOB_Number_java.util.Date_java.util.Date_Number_java.util.List_String_Number_Boolean",
            [rpIDDocument, rpDocumentDate, accountantDate, externalConsecutive, items, description, idEmployee, createPdf]);
    }

}