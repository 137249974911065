import { useEffect, useState } from "react";
import { Singleton } from "../../../../core/services/Singleton";
import { toast } from "react-toastify";
import { BasicTable, MASK_MONEY, MASK_NUMBER } from "../../../theme/Component/BasicTable";
import { Modal } from "../../../theme/Component/Modal";
import { ModalConfirm } from "../../../theme/Component/ModalConfirm";
import useDataBean from "../../../../core/services/dataBean/useDataBean";
import { _SupplierPaymentOrder } from "../../../../core/services/dataBean/EntityCatalogVdos";
import { PrintJson } from "../../../theme/Component/PrintJson";
import useModal, { MODAL_LG, MODAL_md, MODAL_SM } from "../../../theme/Component/hooks/useModal";

import { SuppliertPaymentOptions } from "../SuppliertPaymentOptions";
interface Props {
    _AdquisitionContrac: any;
}
export const SupplierPaymentOrder = ({ _AdquisitionContrac }: Props) => {

    const SupplierPaymentOrder = useDataBean(_SupplierPaymentOrder);
    const single = Singleton.getInstance();
    const [row, setRow] = useState<any>({});
    const [lista, setLista] = useState<any>([]);
    const [modalC, setModalC] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });
    const modalEditar = useModal("Orden", MODAL_md);

    const head = [
        { title: 'ID', property: 'IDSupplierPaymentOrder', visible: false, mask: MASK_NUMBER },
        { title: 'Valor de la Orden de Pago', property: 'Value', visible: true, mask: MASK_MONEY },
        { title: 'Etapa de la Orden', property: 'OrderStateName', visible: true },
        { title: 'Empleado', property: 'EmployeeName', visible: true },
        { title: 'Estado', property: 'StateName', visible: false },
        { title: 'Fecha Creación', property: 'SupplierPaymentOrderDate', visible: true },
        { title: 'Descripción', property: 'Description', visible: true },
    ]



    useEffect(() => {

        getLista()

    }, [_AdquisitionContrac])

    const getLista = () => {

        SupplierPaymentOrder.instance.getSupplierPaymentOrderCatalogForAdquisitionContract(_AdquisitionContrac.IDAdquisitionContract, _AdquisitionContrac.AdquisitionContractDate).then(
            (resp: any) => {
                if (resp) {
                    setLista(resp)
                   //console.log(resp)
                }
            }
        ).catch(err =>console.error(err))

    }

    const create = () => {

        modalEditar.open();

        SupplierPaymentOrder.setBean({
            Type: 2,
            IDAdquisitionContract: _AdquisitionContrac.IDAdquisitionContract,
            AdquisitionContractDate: _AdquisitionContrac.SeleccionDate,
            IDEmployee: single.getAccountID(),

        });


    }
    const iconButtonsItem = [
        { nombre: 'Agregar', visible: single.canCreate(), icono: 'ri-file-add-line', evento: create }
    ]


    const deleteItem = () => {
        SupplierPaymentOrder.instance.deleteSupplierPaymentOrder(SupplierPaymentOrder.bean.IDSupplierPaymentOrder, SupplierPaymentOrder.bean.SupplierPaymentOrderDate).then(
            (resp) => {
               //console.log(resp);
                getLista();

                setModalC({ ...modalC, "view": false });
            }
        )
    }

    const editaItem = () => {
       //console.log(SupplierPaymentOrder.bean);
        SupplierPaymentOrder.update().then(
            (resp: any) => {
                getLista();
                modalEditar.close();
            }
        ).catch(err => {
            toast.error(err);
        })

    }




    const [listaBotones, setListaBotones] = useState<any>([]);


    return (

        <div className="row">
            <div className="col">
                <SuppliertPaymentOptions key={row} contract={_AdquisitionContrac} paymentOrder={row} listComponent={setListaBotones} updateList={getLista} />
            </div>
            <div className="col-md-12">
                <p>{_AdquisitionContrac.Description}</p>
                <BasicTable body={lista} head={head} rowSet={setRow} listButtons={listaBotones} iconButtons={iconButtonsItem}></BasicTable>

                <Modal modal={modalEditar.modal} updateModal={modalEditar.setModal} eventModal={editaItem}>
                    <div className="row">
                        <div className="col-md-12">
                            <label>Observaciones</label>
                            <textarea name="Description" className="form-control" rows={5} value={SupplierPaymentOrder.bean.Description} onChange={SupplierPaymentOrder.handleChange} />
                        </div>
                    </div>
                </Modal>

                <ModalConfirm modal={modalC} updateModal={setModalC} eventModal={deleteItem}>
                    <strong>Eliminar item</strong>
                </ModalConfirm>
            </div>

        </div>

    )
}