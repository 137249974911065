import { useEffect, useState } from "react";
import { BasicTable } from "../../../../theme/Component/BasicTable";
import { Singleton } from "../../../../../core/services/Singleton";
import { BpmService } from "../../../../../core/services/BpmService";
import { ListMenu } from "../../../../theme/Component/ListMenu";
import { Modal } from "../../../../theme/Component/Modal";
import { ModalConfirm } from "../../../../theme/Component/ModalConfirm";
import { SelectorRol } from "../../../../bpm/config/Componentes/SelectorRol";
import { BasicSwitch } from "../../../../theme/Component/BasicSwitch";

interface Props {
    _IDWorkGroupMember: number;
    _IdLnOffice: number;
    _update: Function;
}

interface obj {
    Description?: string,
    IDLnFunctionalID?: number,
    State?: number,
    IDBusinessRole?: number,
    BusinessRoleName?: string;
    RoleChief?: boolean;
    IDWorkGroupRole?: number;
}

export const WorkGroup = ({ _IDWorkGroupMember, _IdLnOffice, _update }: Props) => {

    const single = Singleton.getInstance();
    const [lista, setLista] = useState([]);
    const [bean, setBean] = useState<obj>();
    const service = new BpmService();
    const [modal, setModal] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-md' });
    const [modalConfirm, setModalConfirm] = useState<any>({ name: 'Atención!', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });


    useEffect(() => {
        if (_IDWorkGroupMember > 0) {
            getLista();
        }
    }, [_IDWorkGroupMember])

    const head = [
        { title: 'ID', property: 'IDBusinessRole' },
        { title: 'Nombre', property: 'BusinessRoleName' },
        { title: 'Rol predefinido', property: 'RoleChief' },
    ]

    const getLista = () => {
        single.spinner(true)
        service.getWorkGroupRoleCatalog(_IDWorkGroupMember).subscribe(
            (resp: any) => {
                single.spinner(false)
                if (resp) {
                    setLista(resp.DataBeanProperties.ObjectValue)
                } else {
                   //console.log(resp.DataBeanProperties.ErrorMessage)
                }
            }
        )
    }

    const agregarItem = () => {
        let b = {
            IDWorkGroupMember: _IDWorkGroupMember,
            State: 0,
            RoleChief: false,
            idBusinessRole: undefined,
        }
        setBean(b);
        setModal({ ...modal, 'view': true, 'name': 'Agregar Rol' });
    }

    const updateItem = () => {
        setModal({ ...modal, 'view': false });
        single.spinner(true);
        service.updateWorkGroupRole(bean).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue) {
                    getLista();
                    _update();
                    if (bean.IDBusinessRole) {
                        single.update(bean);
                    }
                    else {
                        single.create(bean);
                    }
                }
                single.spinner(false);
            }
        );

    }

    const setRow = (b: any) => {
       //console.log(b);
        setBean(b);
    }

    const eliminarItem = () => {
        single.spinner(true);
        service.deleteWorkGroupRole(bean.IDBusinessRole).subscribe(
            (resp: any) => {
                if (resp) {
                    getLista();
                }
                single.delete(bean.IDBusinessRole, bean);
                single.spinner(false);
            }
        );
        setModalConfirm({ ...modalConfirm, 'view': false });
    }

    const listaBotones = () => {
        const botones = [

            { titulo: 'Editar', icono: 'ri-file-list-line', visible: single.canEdit(), evento: () => { setModal({ ...modal, 'view': true, 'name': 'Editar Rol' }) } },
            { titulo: 'Eliminar', icono: 'ri-file-user-line', visible: single.canDelete(), evento: () => { setModalConfirm({ ...modalConfirm, "view": true }) } },
        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }

    const iconButtonsItem = [
        { nombre: 'Agregar', visible: single.canCreate(), icono: 'ri-file-add-line', evento: agregarItem }
    ]

    return (
        <>
            <div className="row">
                <BasicTable listButtons={listaBotones()} iconButtons={iconButtonsItem} body={lista} head={head} rowSet={setRow} ></BasicTable>
            </div>

            {modal.view &&
                <Modal modal={modal} updateModal={setModal} eventModal={updateItem}>
                    <>
                        <div className="row">
                            <div className="col-12">
                                <SelectorRol idLnFunctionalID={_IdLnOffice} idSelector={bean.IDBusinessRole} onChange={(e) => { setBean({ ...bean, "IDBusinessRole": e }) }} titulo="Seleccione un rol" />
                            </div>
                            <div className="col-12 mt-2">
                                <BasicSwitch estado={bean.RoleChief} eventChange={(e) => { setBean({ ...bean, "RoleChief": e }) }} label=" Rol predefinido"></BasicSwitch>
                            </div>
                        </div>
                    </>
                </Modal>
            }
            {modalConfirm.view &&
                <ModalConfirm modal={modalConfirm} updateModal={setModalConfirm} eventModal={eliminarItem}>
                    <>
                        {bean.BusinessRoleName}
                    </>
                </ModalConfirm>
            }
        </>
    )
}