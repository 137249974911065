import { useEffect, useState } from "react"
import { _UBLItemInvoice } from "../../../../../core/services/dataBean/EntityCatalog"
import useDataBean from "../../../../../core/services/dataBean/useDataBean"
import { YearSelector } from "../../../../../core/shared/inputs/YearSelector"
import { AccountEditor } from "../../../../editor/AccountEditor"
import { BasicButton } from "../../../../theme/Component/BasicButton"
import { BasicPage } from "../../../../theme/Component/BasicPage"
import { BasicTable, MASK_DATE, MASK_MONEY, MASK_NUMBER } from "../../../../theme/Component/BasicTable"
import useModal, { MODAL_LG, MODAL_XL } from "../../../../theme/Component/hooks/useModal"
import { ListMenu } from "../../../../theme/Component/ListMenu"
import { Modal } from "../../../../theme/Component/Modal"
import { AccountingUBL } from "./AccountingUBL"
import { FileService } from "../../../../../core/services/FileService"
import { SEE_DOCUMENT } from "../../../../editor/Editors"
import { ThirdBankingAccountEditor } from "../../../../Talentohumano/ParametrosTalentoHumano/ThirdBankingAccount/ThirdBanking.editor"
import { Singleton } from "../../../../../core/services/Singleton"
import { toast } from "react-toastify"
import usePaginator from "../../../../theme/Component/usePaginator"

interface Props {
    entry?: "Reimbursement" | "Accounting";
}

export const AccountingUBLInvoice = ({ entry = "Accounting" }: Props) => {

    const { bean, setBean, instance, lista, setLista } = useDataBean(_UBLItemInvoice);
    const paginator = usePaginator();

    const fileService = new FileService();
    const single = Singleton.getInstance();
    const [ubl, setUbl] = useState<any>({});
    const [accountBank, setAccountBank] = useState<number>(NaN);
    const [register, setRegister] = useState<number>(15);
    const [year, setYear] = useState<number>(single.getCurrenYear());

    const modalAccounting = useModal("Contabilizar Factura", MODAL_XL);
    const [modal, setModal] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });

    const head = [
        { title: 'ID Factura', property: 'IDUBLInvoice', visible: true },
        { title: 'No Factura', property: 'InvoiceID', visible: true },
        { title: 'Procesar', property: 'isValid', visible: false },
        { title: 'Nit Proveedor', property: 'AccountSupplierNit', visible: true },
        { title: 'Proveedor', property: 'AccountSupplierName', visible: true },
        { title: 'Líneas Factura', property: 'LineCountNumeric', visible: true },
        { title: 'Valor Total', property: 'TotalInvoiceAmount', visible: true },
        { title: 'Descuentos', property: 'DiscountAmount', visible: true },
        { title: 'Fecha Factura', property: 'IssueDate', visible: true, mask: MASK_DATE },
        { title: 'Entrada Almacén', property: 'EAFMnemonic', visible: false },
        { title: 'Consecutivo Entrada', property: 'EAFConsecutive', visible: false },
        { title: 'Fecha Entrada', property: 'EAFDate', visible: false, mask: MASK_DATE },
        { title: 'Fecha Procesamiento', property: 'Since', visible: true, mask: MASK_DATE },
        { title: 'Estado Factura', property: 'DocumentStateName', visible: true },
        { title: 'Tipo Factura', property: 'SourceTypeName', visible: true },
        { title: 'Estado EAF', property: 'EAFDocumentStateName', visible: true },
        { title: 'Estado Salidas', property: 'OutputCostStateName', visible: true },
        { title: 'Documento Soporte', property: 'IsDocumentSupport', visible: true },
        { title: 'Documento Soporte', property: 'IsDocumentSupport', visible: true },
        { title: 'Documento', property: 'Media', visible: true, cellEditor: SEE_DOCUMENT, modalZise: MODAL_XL },
    ]

    // useEffect(() => {
    //     setLista([])
    // }, [entry])



    const getLista = () => {

        if (entry === "Accounting") {
            instance.getUblInvoiceCatalogForAccountingApproval(bean.IDAccount, year, 1, register).then(
                (resp: any) => {

                    paginator.setPages(resp.NumberOfPages);
                    paginator.setRecords(resp.NumberOfPages * (register ?? 15));
                    paginator.setRegiPerPage(register)

                    let newlis = resp.List;
                    if (Array.isArray(newlis)) {
                        newlis.forEach((factura: any) => {
                            if (factura.DataBeanProperties.Media) {
                                factura.DataBeanProperties.DocUrl = fileService.getUrlFile(factura.DataBeanProperties.MediaContext, factura.DataBeanProperties.Media);
                            } else {
                                factura.DataBeanProperties.DocUrl = "N/A";
                            }
                        });
                    }

                    setLista(newlis);
                }
            ).catch(error => {
                console.error("Error fetching invoice catalog:", error);
            });
        } else if (entry === "Reimbursement") {
            instance.getUblInvoiceCatalogForCashReimbursementAccountingApproval(bean.IDAccount, year, 1, register).then(
                (resp: any) => {
                    let newlis = resp.List;
                    if (Array.isArray(newlis)) {
                        newlis.forEach((factura: any) => {
                            if (factura.DataBeanProperties.Media) {
                                factura.DataBeanProperties.DocUrl = fileService.getUrlFile(factura.DataBeanProperties.MediaContext, factura.DataBeanProperties.Media);
                            } else {
                                factura.DataBeanProperties.DocUrl = "N/A";
                            }
                        });
                    }

                    setLista(newlis);
                }
            ).catch(
                err => {
                   //console.log("Error fetching invoice catalog:", err);
                }
            )
        }
    };

    const addAccountToUbl = () => {
        instance.setThirdBankingAccountToUBLInvoice(ubl.IDUBLInvoice, ubl.IssueDate, accountBank, single.getAccountID()).then(
            (resp) => {
                setModal({ ...modal, "view": false });
                getLista();
                toast.success("Cuenta Actualizada")
            }
        ).catch(
            err => {
               console.error(err)
                toast.error("No se pudo realizar la Acción solicitada", { autoClose: false })
            }
        )
    }

    const validateAccount = () => {
        if (accountBank > 0) {
            addAccountToUbl();
        } else {
            toast.error("Debe seleccionar una Cuenta Valida")
        }
    }

    const listaBotones = () => {
        const botones = [
            { titulo: 'Contabilizar Factura', icono: ' ri-file-forbid-fill', visible: true, evento: () => { modalAccounting.open() } },
            { titulo: 'Modificar Cuenta Bancaria/Medio de Pago', icono: ' ri-file-forbid-fill', visible: true, evento: () => { setModal({ ...modal, "view": true, "name": "Modificar Cuenta Bancaria/Medio de Pago" }) } },
        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }

    useEffect(() => {
        getLista();
    }, [paginator.page, entry])

    return (
        <>
            <BasicPage titulo={entry === "Accounting" ? "Contabilizar Factura Electrónica" : "Contabilizar Factura Electrónica de Reembolsos"}>
                <div className="row">
                    <div className="col-md-4">
                        <AccountEditor idAccount={bean.IDAccount} label="Proveedor" onChange={({ IDAccount }: any) => { setBean({ ...bean, "IDAccount": IDAccount }) }} />
                    </div>
                    <div className="col-md-4">
                        <YearSelector value={year} onChange={(e) => { setYear(e) }} />
                    </div>
                    <div className="col-md-4">
                        <label>Resgistros por Pagina</label>
                        <input type="number" value={register} onChange={(e) => { setRegister(parseInt(e.target.value)) }} className="form-control" />
                    </div>
                    <div className="col-md-4 mt-4">
                        <BasicButton icon="" eventClick={getLista}>Buscar</BasicButton>
                    </div>
                    <div className="col-12">
                        <BasicTable
                            body={lista}
                            head={head}
                            rowSet={setUbl}
                            listButtons={listaBotones()}
                            pageTop={paginator.page}
                            next={paginator.next}
                            back={paginator.back}
                            records={paginator}
                            customRows={register}
                        />
                    </div>
                </div>
            </BasicPage>

            {modalAccounting &&
                <Modal modal={modalAccounting.modal} updateModal={modalAccounting.setModal}>
                    <AccountingUBL ublInvoice={ubl} />
                </Modal>
            }

            {modal.view &&
                <Modal modal={modal} updateModal={setModal} eventModal={() => { validateAccount() }} >
                    <ThirdBankingAccountEditor idselectorAccount={ubl.IDAccountSupplier} idSelector={accountBank} onChange={(e) => { setAccountBank(e) }} />
                </Modal>
            }


        </>
    )
}