import { useEffect, useState } from "react";
import { BasicButton } from "../../../../../theme/Component/BasicButton";
import { _ItemFlow, _single } from "../../../../../../core/services/dataBean/EntityCatalog";
import { ContractService } from "../../../../../../core/services/ContractService";
import useDataBean from "../../../../../../core/services/dataBean/useDataBean";

interface Props {
    exportMap: Function;
    procedure: any;
}

export const PrevEstudyOBS = ({ exportMap, procedure }: Props) => {

    const { instance, setBean, bean } = useDataBean(_ItemFlow);
    const [obs, setObs] = useState<string>("")
    const service = new ContractService();

    const onExportMap = (approve: boolean) => {
        exportMap({
            Response: `${approve}`,
            Observation: obs
        })
    }

    useEffect(() => {
        getItem();
    }, [procedure])

    const getItem = () => {
        let x = {
            IDProcedureImp: procedure.IDProcedureImp,
        };
        instance.getItemFlowCatalogPorPropiedades(x, null).then(
            (resp) => {
                if (resp.length > 0) {
                    setBean(resp[0])
                }
            }
        ).catch(err => { console.log(err) })
    }

    const updateState = (_approve: boolean) => {
        if (_approve == true) {

            _single.spinner(true);
            service.changeAdquisitionItemApprovalNote(bean.DataBeanProperties?.IDAdquisitionItem, _single.getAccountID(), obs, 40).subscribe(
                (resp: any) => {
                    onExportMap(_approve);
                    _single.spinner(false)
                }
            )
        } else {
            onExportMap(_approve);
        }
    }

    return (
        <div className="row d-flex justify-content-between">
            {/* <PrintJson json={bean} /> */}
            <div className="col-md-12 mb-2">
                <label>Observaciones</label>
                <textarea className="form-control" value={obs} onChange={(e) => { setObs(e.target.value) }} />
            </div>
            <div className="col-md-4">
                <BasicButton icon="ri-checkbox-circle-line" eventClick={() => { updateState(true) }} >Arpobar</BasicButton>
            </div>
            <div className="col-md-4">
                <BasicButton icon="ri-close-circle-line" eventClick={() => { updateState(false) }} >Rechazar</BasicButton>
            </div>
        </div>
    );
};