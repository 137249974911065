import { ActionSide } from "./ActionSide";
import { ReactiveSide } from "./ReactiveSide";
import { useInboxStore } from "../hooks/useInboxStore";
import { CardTramitesProfile } from "../../../pages/components/CardTramitesProfile";

export const InboxManagment = () => {

  const { procedureImpSelected } = useInboxStore()

  return (
    <div className="container-fluid" >
      <div className="row">
        <div className="col-md-12 d-flex justify-content-center flex-column border-bottom mb-3">
          <CardTramitesProfile idaccount={procedureImpSelected?.IDAccount} inbox={true} />
          <ActionSide />
        </div>
        <div className="col-md-12">
          <ReactiveSide />
        </div>
      </div>
    </div>
  );
};
