import { useEffect, useState } from "react"
import { BasicPage } from "../../theme/Component/BasicPage"
import { GenericCellEditorClassForNameList } from "./GenericCellEditorClassForNameList";
import { GenericCellEditorCatalogByType } from './GenericCellEditorCatalogByType';
import { BasicTable } from "../../theme/Component/BasicTable";
import { ListMenu } from "../../theme/Component/ListMenu";
import { PrintJson } from "../../theme/Component/PrintJson";
import { Singleton } from "../../../core/services/Singleton";
import { useDashboardStore } from "../../pages/hooks/useDashboardStore";
import { AdminService } from "../../../core/services/AdminService";
import { BasicButton } from "../../theme/Component/BasicButton";
import { Modal } from "../../theme/Component/Modal";
import { ModalConfirm } from "../../theme/Component/ModalConfirm";


export const GenericCellEditor = () => {

    const single = Singleton.getInstance()
    const { setLoading } = useDashboardStore();
    const service = new AdminService();
    const [modal, setModal] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalc, setModalC] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });

    const [lista, setLista] = useState<any>([]);
    const [row, setRow] = useState<any>({});
    const [props, setProps] = useState<any>({
        classForName: '',
        classForName1: '',
        idCellEditorClass: 0

    });

    const head = [
        { title: 'ID', property: 'IDGenericCellEditor', visible: true },
        { title: 'Clase del DataBean relacionado', property: 'ClassForName', visible: true },
        { title: 'Componente Front', property: 'FrontComponent', visible: true },
        { title: 'Nombre del Editor', property: 'Name', visible: true },
        { title: 'Código del Editor', property: 'Code', visible: true },
        { title: 'Descripción', property: 'Description', visible: true },
        { title: 'Clase de Retorno', property: 'ReturnClassForName', visible: true },
        { title: 'Fecha de Creación', property: 'Since', visible: true },
    ]


    const getLista = () => {
        setLoading(true);
        service.getGenericCellEditorCatalogByType(props.classForName ? props.classForName : null).subscribe(
            (resp: any) => {
               //console.log(resp);
                if (resp.DataBeanProperties.ObjectValue) {
                    setLista(resp.DataBeanProperties.ObjectValue);
                }
                setLoading(false);
            }
        )
    }
    const agregarItem = ()=>{
        setRow({});
        setModal({...modal,"view":true});
    }

    const iconButtonsItem = [
        { nombre: 'Agregar', visible: single.canCreate(), icono: 'ri-file-add-line', evento: agregarItem }
    ]

    const listaBotones = () => {
        const botones = [

            { titulo: 'Editar', icono: 'ri-edit-2-line', visible: single.canEdit(), evento: () => { setModal({ ...modal, "view": true }) } },
            { titulo: 'Eliminar', icono: 'ri-eraser-line', visible: single.canDelete(), evento: () => { setModalC({ ...modalc, "view": true }) } },
        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }

    const handleChange = (e:any)=>{
        setRow(single.handleFormChange(row,e));
    }
    const editForm=()=>{
        
        single.spinner(true);
        service.updateGenericCellEditor(row).subscribe(
            (resp:any)=>{
                getLista();
                single.spinner(false);
                setModal({...modal,"view":false});
            }
        )

        
    }
    const deleteForm=()=>{
        
        single.spinner(true);
        service.deleteGenericCellEditor(row.IDGenericCellEditor).subscribe(
            (resp:any)=>{
                getLista();
                single.spinner(false);
                setModalC({...modalc,"view":false});
            }
        )

        
    }

    return (
        <BasicPage titulo="Editores del sistema">
          
            <div className="row">
                <div className="col-md-6">
                    <GenericCellEditorClassForNameList label="Bean" value={props.classForName} onChange={(e: any) => { setProps({ ...props, "classForName": e === '...' ? null : e }) }} />
                </div>
                <div className="col-md-6 mt-3">
                    <BasicButton icon="" eventClick={getLista}>Buscar</BasicButton>
                    {/* <GenericCellEditorCatalogByType label="Opción" classForName={props.classForName} value={props.classForName1} onChange={(e:any)=>{setProps({...props,"classForName1":e})}}/> */}
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">

                    <BasicTable iconButtons={iconButtonsItem} listButtons={listaBotones()} head={head} body={lista} rowSet={setRow}></BasicTable>
                </div>
                <div className="col-md-12">
                    <Modal modal={modal} updateModal={setModal} eventModal={editForm}>
                        <div className="row">
                            <div className="col-md-6">
                                <label>Código editor</label>
                                <input type="text" className="form-control" name="Code" value={row.Code} onChange={handleChange}/>
                            </div>
                            <div className="col-md-6">
                                <label>Nombre editor</label>
                                    <input type="text" className="form-control" name="Name" value={row.Name} onChange={handleChange}/>
                                
                            </div>
                            <div className="col-md-12">
                                <label>Clase del DataBean relacionado</label>
                                    <input type="text" className="form-control" name="ClassForName" value={row.ClassForName} onChange={handleChange}/>
                                
                            </div>
                            <div className="col-md-12">
                                <label>Clase del retorno del editor</label>
                                    <input type="text" className="form-control" name="ReturnClassForName" value={row.ReturnClassForName} onChange={handleChange}/>
                                
                            </div>
                            <div className="col-md-6">
                                <label>Componente Front</label>
                                    <input type="text" className="form-control" name="FrontComponent" value={row.FrontComponent} onChange={handleChange}/>
                                
                            </div>
                            <div className="col-md-12">
                                <label>Descripción</label>
                                    <textarea name="Description" className="form-control" value={row.Description} onChange={handleChange}></textarea>
                                
                            </div>
                        </div>
                    </Modal>
                </div>
                <div className="col-md-12">
                    <ModalConfirm modal={modalc} updateModal={setModalC} eventModal={deleteForm}>
                        <p>Eliminar editor</p>
                    </ModalConfirm>
                </div>
            </div>
        </BasicPage>
    )
}