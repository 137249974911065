import { useEffect, useState } from "react";
import { _Appointment } from "../../../../core/services/dataBean/EntityCatalog";
import useDataBean from "../../../../core/services/dataBean/useDataBean";
import { Singleton } from "../../../../core/services/Singleton";
import { ListMenu } from "../../../theme/Component/ListMenu";
import { BasicPage } from "../../../theme/Component/BasicPage";
import { BasicTable } from "../../../theme/Component/BasicTable";
import { Modal } from "../../../theme/Component/Modal";
import { ModalConfirm } from "../../../theme/Component/ModalConfirm";
import { toast } from "react-toastify";
import { EntailmentEditor } from "../Entailment/Entailment.editor";

export const Appointment = () => {

    const appointment = useDataBean(_Appointment);
    const single = Singleton.getInstance();

    const [lista, setLista] = useState<any>([]);
    const [modal, setModal] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalC, setModalC] = useState<any>({ name: 'Eliminar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });

    useEffect(() => {
        getLista();
    }, [])


    const getLista = () => {

        appointment.instance.getAppointmentCatalog().then(
            (resp: any) => {
                if (resp) {
                    setLista(resp)
                }
            }
        )

    }

    const create = () => {
        appointment.setBean({});
        setModal({ ...modal, "name": "Agregar Nivel de Empleo", "view": true });
    }
    const deleteItem = () => {
        appointment.deleteItemVoid(appointment.bean.IDAppointment).then(
            (resp: any) => {
                getLista();
                setModalC({ ...modalC, "view": false });
            }
        )

    }
    const editaItem = () => {
        ////console.log(appointment.bean);
        appointment.update().then(
            (resp: any) => {
                getLista();
                setModal({ ...modal, "view": false });
            }
        ).catch(err => {
            toast.error(err);
        })

    }

    const head = [
        { title: 'ID', property: 'IDAppointment', visible: true },
        { title: 'Código', property: 'Code', visible: true },
        { title: 'Nombre', property: 'Name', visible: true },
        { title: 'Salario', property: 'Salary', visible: true }
    ]

    const iconButtonsItem = [
        { nombre: 'Agregar', visible: single.canCreate(), icono: 'ri-file-add-line', evento: create }
    ]
    const listaBotones = () => {
        const botones = [
            { titulo: 'Editar', icono: 'ri-edit-2-line', visible: single.canEdit(), evento: () => { setModal({ ...modal, "name": "Editar Nivel de Empleo", "view": true }) } },
            { titulo: 'Eliminar', icono: 'ri-delete-bin-line', visible: single.canDelete(), evento: () => { setModalC({ ...modalC, "view": true }) } },

        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }


    return (
        <BasicPage titulo="Niveles de Empleo">
            <div className="row">
                <div className="col-md-12">
                    <BasicTable body={lista} head={head} rowSet={appointment.setBean} listButtons={listaBotones()} iconButtons={iconButtonsItem}></BasicTable>
                    <Modal modal={modal} updateModal={setModal} eventModal={editaItem}>
                        <div className="row">
                            <div className="col-md-6">
                                <EntailmentEditor idSelector={appointment.bean.IDEntailment} onChange={(e) => appointment.setBean({ ...appointment.bean, "IDEntailment": e })} />
                            </div>
                            <div className="col-md-6">
                                <label>Código:</label>
                                <input type="text" name="Code" className="form-control" value={appointment.bean.Code} onChange={appointment.handleChange} />
                            </div>
                            {/* <div className="col-md-6">
                                <label>Salario:</label>
                                <input type="Number" name="Salary" className="form-control" value={appointment.bean.Salary} onChange={appointment.handleChange} />
                            </div> */}
                            <div className="col-md-12">
                                <label>Nombre:</label>
                                <input type="text" name="Name" className="form-control" value={appointment.bean.Name} onChange={appointment.handleChange} />
                            </div>
                            <div className="col-md-12">
                                <label>Descripción:</label>
                                <textarea name="Description" className="form-control" value={appointment.bean.Description} onChange={appointment.handleChange}></textarea>
                            </div>
                            {/* <PrintJson json={appointment.bean} /> */}
                        </div>
                    </Modal>
                    <ModalConfirm modal={modalC} updateModal={setModalC} eventModal={deleteItem}>
                        <strong>Eliminar item {appointment.bean.Name}</strong>
                    </ModalConfirm>
                </div>

            </div>
        </BasicPage>
    )
}