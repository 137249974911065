import { useEffect, useState } from "react";

import useDataBean from "../../../core/services/dataBean/useDataBean";
import { CashReimbursementServices } from "../cellEditor/CashDesk/CashReimbursement/CashReimbursement-service";
import { toast } from "react-toastify";
import { Singleton } from "../../../core/services/Singleton";
import { ListMenu } from "../../theme/Component/ListMenu";
import { BasicTable, MASK_DATE, MASK_MONEY, MASK_NUMBER } from "../../theme/Component/BasicTable";
import { BasicPage } from "../../theme/Component/BasicPage";
import { Modal } from "../../theme/Component/Modal";

import { ModalConfirm } from "../../theme/Component/ModalConfirm";
import { _AccountantConcept, _FiscalResponsability, _FiscalResponsabilityType } from "../../../core/services/dataBean/EntityCatalog";

import { _AdquisitionBeneficiary, _AdquisitionProject, _ArticleClassType, _MeasurementUnitCode, _ProcessStep, _ProjectBeneficiary, _RequirementStep } from "../../../core/services/dataBean/EntityCatalogVdos";
import { SelectAdquisitionArticleConstants } from "../../contract/select/SelectAdquisitionArticleConstants";
import useModal, { MODAL_md, MODAL_SM } from "../../theme/Component/hooks/useModal";
import { InputDate } from "../../theme/Input/InputDate";
import { AccountEditor } from "../../editor/AccountEditor";
import { SelectFunctionalID } from "../../admin/configuracion/functionalID/SelectFunctionalID";
import { DocumentTypeEditor } from "../DocumentType/DocumentTypeEditor";
import { SelectorTypeForm } from "../../bpm/config/Componentes/SelectorTypeForm";
import { FormforGroupSelect } from "../../bpm/config/Componentes/FormforGroupSelect";
import { Selectortipodeformulario } from "../../bpm/config/Componentes/Selectortipodeformulario";
import { SelectorForm } from "../../bpm/config/Componentes/SelectorForm";


interface Props {
    IDProcessStep: number;
}

export const RequirementStep = ({ IDProcessStep }: Props) => {

    const requirementstep = useDataBean(_RequirementStep);

    const single = Singleton.getInstance();
    const [lista, setLista] = useState<any>([]);
    const [groupSelected, setGroupSelected] = useState('')
    const [requirementStep, setRequirementStep] = useState<any>({ name: '', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modal, setModal] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalC, setModalC] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const modalConfirm = useModal("Orden", MODAL_SM);
    const modalEditar = useModal(" Agregar", MODAL_md);
    const [idModule, setIDModule] = useState(0);
    // Estado para controlar el tipo seleccionado
    const [selectedType, setSelectedType] = useState<number>(0);


    useEffect(() => {
        getLista()

    }, [IDProcessStep])


    const getLista = () => {

        requirementstep.instance.getRequirementStepCatalogPorPropiedades({ IDProcessStep: IDProcessStep, State: 1 }, null).then(
            (resp: any) => {
                if (resp) {
                    setLista(resp)
                   //console.log(resp)

                }

            }
        )

    }



    const create = () => {
        requirementstep.setBean({

            State: 1,
            IDProcessStep: IDProcessStep
        });

        modalEditar.setModal({ ...modalEditar.modal, "view": true });
    }


    // const deleteItem = () => {
    //     AdquisitionProject.deleteItemVoid(AdquisitionProject.bean.IDMeasurementUnitCode).then(
    //         (resp: any) => {
    //             getLista();
    //             setModalC({ ...modalC, "view": false });
    //         }
    //     )

    // }


    const editaItem = () => {
       //console.log(requirementstep.bean);
        requirementstep.update().then(
            (resp: any) => {
                getLista();
                modalEditar.setModal({ ...modalEditar.modal, "view": false });
            }
        ).catch(err => {
            toast.error(err);
        })

    }
    const deleteItem = () => {
        requirementstep.bean.State = 2;
       //console.log(requirementstep.bean);
        editaItem();
        modalConfirm.close();
    }


    // const editaItem = () => {
    //     MeasurementUnitCode.instance.updateMeasurementUnitCode(MeasurementUnitCode.bean.IDMeasurementUnitCode).then(
    //         (resp: any) => {
    //             getLista();
    //             setModal({ ...modal, "view": false });
    //         }
    //     ).catch(err => {
    //         toast.error(err);
    //     })

    // }

    const head = [
        { title: 'ID', property: 'IDRequirementStep', visible: false, mask: MASK_NUMBER },
        { title: 'Tipo Requerimiento', property: 'TypeRequirement', visible: true },
        { title: 'Formulario', property: 'IDForm', visible: true },
        { title: 'Documento', property: 'IDDocumentType', visible: true },





    ]

    const iconButtonsItem = [
        { nombre: 'Agregar', visible: single.canCreate(), icono: 'ri-file-add-line', evento: create }
    ]
    const listaBotones = () => {
        const botones = [

            { titulo: 'Editar', icono: 'ri-edit-2-line', visible: single.canEdit(), evento: () => { modalEditar.setModal({ ...modalEditar.modal, "view": true }) } },
            { titulo: 'Eliminar', icono: 'ri-delete-bin-line', visible: single.canDelete(), evento: () => { setModalC({ ...modalC, "view": true }) } },


        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }



    return (
        <BasicPage titulo="Pasos para Configuraración">
            <div className="row">

                <div className="col-md-12">

                    <BasicTable body={lista} head={head} rowSet={requirementstep.setBean} listButtons={listaBotones()} iconButtons={iconButtonsItem}></BasicTable>
                    <Modal modal={modalEditar.modal} updateModal={modalEditar.setModal} eventModal={editaItem}>
                        <>
                            <div className="row">

                                <div className="col-md-6">
                                    <label>Tipo</label>

                                    <select className="form-control" value={requirementstep.bean.TypeRequirement} onChange={(e) => {
                                        const typeValue = parseInt(e.target.value, 10);
                                        setSelectedType(typeValue);
                                        requirementstep.setBean({ ...requirementstep.bean, 'TypeRequirement': typeValue });
                                    }}>
                                        <option value={0}>...</option>
                                        <option value={10}>Documento</option>
                                        <option value={11}>Formulario</option>
                                    </select>
                                </div>
                                {/* Mostrar DocumentTypeEditor si selectedType es 10 */}
                                {selectedType === 10 && (
                                    <div className="col-md-12">
                                        <label>Documento</label>
                                        <DocumentTypeEditor
                                            idSelector={requirementstep.bean.IDDocumentType}
                                            onChange={(e) => {
                                                requirementstep.setBean({ ...requirementstep.bean, 'IDDocumentType': e });
                                            }}
                                        />
                                    </div>
                                )}
                                {/* Mostrar SelectorTypeForm si selectedType es 11 */}
                                {selectedType === 11 && (
                                    <div className="col-md-12">
                                        <Selectortipodeformulario idSelector={idModule} onChange={(e: any) => { setIDModule(e) }} />
                                        {/* <SelectorTypeForm value={groupSelected} onChange={(e: string) => setGroupSelected(e)} /> */}

                                    </div>
                                )}
                                {idModule != 0 &&
                                    <div className="col-md-12">
                                        <SelectorForm idForm={idModule} idSelector={requirementstep.bean.IDForm} onChange={(e: any) => requirementstep.setBean({ ...requirementstep.bean, 'IDForm': e })}></SelectorForm>
                                        {/* <FormforGroupSelect IDGrouspSelect={groupSelected} idSelector={requirementstep.bean.IDForm} onChange={(e) => { requirementstep.setBean({ ...requirementstep.bean, 'IDForm': e }) }} /> */}
                                    </div>
                                }


                            </div>
                        </>
                    </Modal>

                    <ModalConfirm modal={modalC} updateModal={setModalC} eventModal={deleteItem}>
                        <strong>Eliminar item</strong>
                    </ModalConfirm>
                </div>

            </div>
        </BasicPage>
    )
}