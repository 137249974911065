import { DataBean } from "../DataBean";

export class TaxCodeAccount extends DataBean {


    public async getTaxCodeAccountCatalog(idAccount: any): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_getTaxCodeAccountCatalog_Number", [idAccount]);
    }

    public async getTaxCodeCatalogPaginador(idTaxCodeScheme: number | null,code:any,name:any,page:any,recordsPerPage:any): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "com.advantage.shared.Report_getTaxCodeCatalog_Number_String_String_Number_Number", [idTaxCodeScheme,code,name,page,recordsPerPage]);
    }


    public async updateTaxCodeAccount(bean: any): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "com.quickdataerp.bean.tax.TaxCodeAccount_updateTaxCodeAccount_com.quickdataerp.bean.tax.TaxCodeAccount", [
            {
                "DataBeanProperties": bean,
                "DataBeanName": "com.quickdataerp.bean.tax.TaxCodeAccount"
            }
        ]);
    }

    
    public async getTaxCodeCatalog(idTaxCodeScheme: number): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_getTaxCodeCatalog_Number", [idTaxCodeScheme]);
    }



}