import { DataBean } from "../DataBean";

export class CashRequest extends DataBean {

    public async getCashRequestCatalogForApplicant(idAccount: number, year: number, state: number): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_getCashRequestCatalogForApplicant_Number_Number_Number", [idAccount, year, state]);
    }

    public async getCashRequestCatalogForState(idAccount: number | null, year: number, state: number, cashID: number): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_getCashRequestCatalogForState_Number_Number_Number_Number", [idAccount, year, state, cashID]);
    }

    public async getCashRequestStateConstants(arg: number | null): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_getCashRequestStateConstants_Number", [arg]);
    }

    public async getCashRequestRefundConstants(arg: number | null): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_getCashRequestRefundConstants_Number", [arg]);
    }

    public async getCashReceiptToCashRequest(idCashRequest: number, cashRequestDate: any, refundType: number, idBankingAccount: number, observations: string, value: number, idEmployee: number): Promise<any> {
        return this.getCustomService(
            "QuickDataERPCore",
            "com.quickdataerp.bean.accountant.AccountantDocument_getCashReceiptToCashRequest_Number_java.util.Date_Number_Number_String_Number_Number",
            [idCashRequest, cashRequestDate, refundType, idBankingAccount, observations, value, idEmployee]);
    }

    public async deleteCashRequest(idCashRequest: number, cashRequestDate: any): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "void_deleteCashRequest_Number_java.util.Date", [idCashRequest, cashRequestDate]);
    }

    public async postCashRequest(idCashRequest: number, cashRequestDate: any, idCashDeskAccount: number, idEmployee: number, observations: string): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "com.quickdataerp.bean.cashreimbursement.CashRequest_postCashRequest_Number_java.util.Date_Number_Number_String", [idCashRequest, cashRequestDate, idCashDeskAccount, idEmployee, observations]);
    }

    public async updateCashRequest(bean: any): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "com.quickdataerp.bean.cashreimbursement.CashRequest_updateCashRequest_com.quickdataerp.bean.cashreimbursement.CashRequest", [
            {
                "DataBeanProperties": bean,
                "DataBeanName": "com.quickdataerp.bean.cashreimbursement.CashRequest"
            }
        ]);
    }

    public async processCashRequest(idCashRequest: number, cashRequestDate: any, idEmployee: number, observations: string): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "com.quickdataerp.bean.cashreimbursement.CashRequest_processCashRequest_Number_java.util.Date_Number_String", [idCashRequest, cashRequestDate, idEmployee, observations]);
    }

    public async rejectCashRequest(idCashRequest: number, cashRequestDate: any, idEmployee: number, observations: string): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "com.quickdataerp.bean.cashreimbursement.CashRequest_rejectCashRequest_Number_java.util.Date_Number_String", [idCashRequest, cashRequestDate, idEmployee, observations]);
    }

    public async addAdvanceValueToCashRequest(idCashRequest: number, cashRequestDate: any, observations: string, value: number, idEmployee: number): Promise<any> {
        return this.getCustomService(
            "QuickDataERPCore",
            "com.quickdataerp.bean.accountant.AccountantDocument_addAdvanceValueToCashRequest_Number_java.util.Date_String_Number_Number",
            [idCashRequest, cashRequestDate, observations, value, idEmployee]);
    }

}
