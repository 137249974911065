import { useState, useEffect } from 'react';

import { toast } from 'react-toastify';
import { BpmService } from '../../../core/services/BpmService';
import { useDashboardStore } from '../../pages/hooks/useDashboardStore';
import { Singleton } from '../../../core/services/Singleton';
import { ListMenu } from '../../theme/Component/ListMenu';
import { DropDown } from '../../theme/Component/DropDown';
import { BasicTable } from '../../theme/Component/BasicTable';
import { BasicPage } from '../../theme/Component/BasicPage';
import { ModalConfirm } from '../../theme/Component/ModalConfirm';
import { Modal } from '../../theme/Component/Modal';
import { THConstantsValue } from '../../bpm/config/Componentes/THConstantsValue';
import { InputDate } from '../../theme/Input/InputDate';
import { CourseTopic } from './CourseTopic';

export const Course = () => {

    const single = Singleton.getInstance()
    const { setLoading } = useDashboardStore();
    const service = new BpmService();
    const [lista, setLista] = useState([]);
    const [bean, setBean] = useState<any>({});
    const [modal, setModal] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalTypeCourse, setModalTypeCourse] = useState<any>({ name: 'Tema del curso', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalConfirm, setModalConfirm] = useState<any>({ name: 'Confirmar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });
    const [user, setUser] = useState<any>({});

    const idBean = 'IDCourse';

    const head = [
        { title: 'ID', property: idBean },
        { title: 'Nombre', property: 'Name' },
        { title: 'Descripción', property: 'Description' },
        { title: 'Desde', property: 'From' },
        { title: 'Hasta', property: 'Upto' },
    ]

    useEffect(() => {
        setUser(single.getAccountID());
        getLista();
    }, [idBean])

    const setRowBean = (bean: any) => {
       //console.log("Row : ", bean);
        setBean(bean);
    }

    const getLista = () => {

        setLoading(true);
        service.getCourseCatalogPorPropiedad('State', 1, null).subscribe(
            (resp: any) => {

                if (resp.DataBeanProperties.ObjectValue) {
                    setLista(resp.DataBeanProperties.ObjectValue);
                }
                setLoading(false);
            }
        );
    }

    const agregarItem = () => {
        let b = {
            Name: '',
            Description: '',
            From: single.getCurrenDate(),
            Upto: single.getCurrenDate(),
            IDEmployee: single.getAccountID(),
            State: 1,
        }
        setBean(b);
        setModal({ ...modal, ['view']: true });

    }

    const eliminarItem = () => {
        bean.State = 2;
       //console.log(bean);
        updateItem();
        setModalConfirm({ ...modalConfirm, ['view']: false });
    }

    const updateItem = () => {
        setModal({ ...modal, ['view']: false });
        setLoading(true);
        service.updateCourse(bean).subscribe(
            (resp: any) => {
                setLoading(false);
                if (resp.DataBeanProperties.ObjectValue) {
                    getLista();
                }
                single.update(bean);
            }
        );
    }

    const listaBotones = () => {
        const botones = [

            { titulo: 'Editar', icono: 'ri-file-list-line', visible: true, evento: () => { setModal({ ...modal, "view": true }) } },
            { titulo: 'Eliminar', icono: 'ri-file-user-line', visible: true, evento: () => { setModalConfirm({ ...modalConfirm, "view": true }) } },
            { titulo: 'Tema del Curso', icono: 'ri-file-user-line', visible: true, evento: () => { setModalTypeCourse({ ...modalTypeCourse, "view": true }) } },

        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }

    const iconButtonsItem = [
        { nombre: 'Agregar', visible: single.canCreate(), icono: 'ri-file-add-line', evento: agregarItem }
    ]


    return (
        <>
            <BasicPage titulo='Configuración curso'>
                <div className="row">
                    <div className="col-md-12 p-2">
                        <BasicTable listButtons={listaBotones()} iconButtons={iconButtonsItem} head={head} body={lista} rowSet={setRowBean}></BasicTable>
                    </div>
                </div>
            </BasicPage>

            <ModalConfirm modal={modalConfirm} updateModal={setModalConfirm} eventModal={eliminarItem}>
                {bean.Name}
            </ModalConfirm>

            <Modal modal={modal} updateModal={setModal} eventModal={updateItem}>
                <div className="row">
                    <div className="col-md-12">
                        <label className="form-label">Nombre</label>
                        <input type="text" className="form-control" value={bean.Name} onChange={(e) => { setBean({ ...bean, ['Name']: e.target.value }) }} />
                    </div>


                    <div className="col-md-6">
                        <label className="form-label">Desde</label>
                        <InputDate label="Día" name="Desde" value={bean.From} setDate={(e: any) => { setBean({ ...bean, 'From': e }) }} ></InputDate>
                    </div>
                    <div className="col-md-6">
                        <label className="form-label">Hasta</label>
                        <InputDate label="Día" name="hasta" value={bean.Upto} setDate={(e: any) => { setBean({ ...bean, 'Upto': e }) }} ></InputDate>
                    </div>
                    <div className="col-md-12">
                        <label className="form-label">Descripción</label>
                        <textarea className="form-control" value={bean.Description} onChange={(e) => { setBean({ ...bean, ['Description']: e.target.value }) }}></textarea>
                    </div>

                </div>
            </Modal>

            <Modal modal={modalTypeCourse} updateModal={setModalTypeCourse}>
                <div className="row">
                    <div className="col-md-12">

                        <CourseTopic IDCourse={bean[idBean]} />
                    </div>
                </div>
            </Modal>


        </>
    )
}