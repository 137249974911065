import { DataBean } from "../DataBean";

export class UBLTaxWithHoldingTax extends DataBean{

    public async getUBLTaxWithHoldingTaxCatalog(idUBLInvoice:number,issueDate:any):Promise<any>
    {
        return this.getCustomService("QuickDataERPCore","java.util.List_getUBLTaxWithHoldingTaxCatalog_Number_java.util.Date",[idUBLInvoice,issueDate]);
    }
   
      
}