import { DataBean } from "../DataBean";


export class CreateSupplier extends DataBean {


    public async createSupplier(nit: number | null, idemployee: number | null, supplier: any | null): Promise<any> {
        return this.getCustomService("SupplierERP", "com.orange.finance.supplier.bean.Supplier_createSupplier_Number_Number_com.orange.finance.supplier.bean.Supplier", [nit, idemployee, supplier]);
    }

}