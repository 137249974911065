import { useEffect, useState } from "react";
import { _DaoInfo } from "../../core/services/dataBean/EntityCatalog";
import useDataBean from "../../core/services/dataBean/useDataBean"
import { PrintJson } from "../theme/Component/PrintJson";
import { MultiSelect } from "../theme/Component/MultiSelect";
import { DataBeanProperties } from '../../core/services/model/server-response.interface';
import { BasicButton } from "../theme/Component/BasicButton";
import { FileInput } from "../theme/Component/FileInput";

interface Props {
    data: any;

}

export const ExportUtil = ({ data }: Props) => {



    const service = useDataBean(_DaoInfo);
    const [lista, setLista] = useState<any>([]);

    const [par, setPar] = useState<any>([]);
    const [sele, setSele] = useState<any>([]);
    const [report, setReport] = useState<any>([]);
    const [tipoExport, setTipoExport] = useState<number>(0);
    const [file, setFile] = useState<any>({});

    useEffect(() => {
        getDataBeanList();
        setSele([]);
        setReport({});
    }, [data.daoClassForName])



    const getDataBeanList = () => {
        service.instance.getDataBeanList(data.daoClassForName).then(
            (resp: any) => {
                setLista(resp);
                if (resp) {
                    let tmp = [];
                    resp.forEach(element => {
                        tmp.push(
                            {
                                id: element.DataBeanProperties.IDDataBeanInfo,
                                Name: element.DataBeanProperties.ClassForName,
                            }
                        )
                    });
                    setPar(tmp);
                }
            }
        )
    }
    const getClassNames=()=>{
        let lista = [];

        par.forEach(element => {
                if(sele.includes(element.id))
                {
                    lista.push(element.Name);
                }
        });

        return lista;
    }

    const exportDataBaseAsJson = () => {
        service.instance.exportDataBaseAsJson(data.daoClassForName, data.charset, getClassNames()).then(
            (resp: any) => {
                setReport(resp);
            }
        )
    }
    const exportDataBaseAsJsonFromScriptFile = () => {
        service.instance.exportDataBaseAsJsonFromScriptFile(file.Media,file.MediaContext,null).then(
            (resp: any) => {
                setReport(resp);
            }
        )
    }

    return (
        <div className="row">
            <div className="col-md-12">
                <label>Tipo export</label>
                <select name="export" className="form-select" value={tipoExport} onChange={(e) => { setTipoExport(parseInt(e.target.value)) }}>
                    <option value={0}>Exportar Objetos en Json</option>
                    <option value={1}>Exportar Objectos Json desde Archivo Script</option>
                </select>
            </div>

            {tipoExport === 0 &&
                <>
                    <div className="col-md-6">
                        <BasicButton icon="ri-file-download-line" disable={sele.length === 0} eventClick={exportDataBaseAsJson}>Exportar</BasicButton>
                    </div>
                    <div className="col-md-6 mt-3">
                        {report.URL &&
                            <a className="link-success   h4" href={report.URL} target="_blank">Descargar <i className="ri-download-cloud-2-line align-middle"></i></a>
                        }
                    </div>
                    <div className="col-md-12">
                        <MultiSelect lista={par} getLista={setSele} />
                    </div>
                </>
            }
            {tipoExport === 1 &&
                <>
                    <div className="col-md-4">
                        <FileInput onCreate={setFile} directory="tmp" label="Script" />
                    </div>
                    <div className="col-md-4 mt-3">
                        <BasicButton icon="ri-file-download-line" disable={!file.Media} eventClick={exportDataBaseAsJsonFromScriptFile}>Exportar</BasicButton>
                    </div>
                    <div className="col-md-4 mt-3">
                        {report.URL &&
                            <a className="link-success   h4" href={report.URL} target="_blank">Descargar <i className="ri-download-cloud-2-line align-middle"></i></a>
                        }
                    </div>
                </>
            }

            {/* <div className="col-md-12">
                <PrintJson json={sele} />
                <PrintJson json={report} />
            </div> */}


        </div>
    )
}