import { useEffect, useState } from "react";
import { FinanceService } from '../../../../core/services/FinanceService';
import { Singleton } from '../../../../core/services/Singleton';
import { BasicPage } from '../../../theme/Component/BasicPage';
import { ButtonUnspscID } from './ButtonUnspscID';
import { UnspscIDChilds } from './UnspscIDChilds';
import { useDashboardStore } from "../../../pages/hooks/useDashboardStore";
import { _single } from "../../../../core/services/dataBean/EntityCatalog";


interface Props {
  id: number | null;
  edit: boolean;
}
export const UnspscID = ({ id, edit }: Props) => {
  const service = new FinanceService();
  const { setLoading } = useDashboardStore();
  const [lista, setLista] = useState([]);
  const [year, setYear] = useState(0);
  const [name, setName] = useState('');
  const [bean, setBean] = useState({});

  const [idchild, setIDchild] = useState(0);

  useEffect(() => {

    let date = new Date();
    setYear(date.getFullYear());
    getListatree(date.getFullYear());
    getSeparator();

  }, []);

  const getListatree = (year: number) => {
   
    let tmp = _single.getCacheItem('treeunscp');
    if (tmp) {
      setLista(tmp);
    }else{

      setLoading(true);
      service.getTreeForUnspscID().subscribe((resp: any) => {
  
        if (resp.DataBeanProperties.ObjectValue) {
          let arbol: any = resp.DataBeanProperties.ObjectValue.EnvolvedObject.DataBeanProperties;
          setName(arbol.Name);
          setLista(resp.DataBeanProperties.ObjectValue.Childs);
          _single.setCacheItem('treeunscp',resp.DataBeanProperties.ObjectValue.Childs);
        } 
        setLoading(false);
      });
    }
   
  };

  const getSeparator = () => {
    localStorage.setItem('separador', '-');
    // service.getUnspscIDSeparator().subscribe(
    //   (resp: any) => {
    //     let separador = resp.DataBeanProperties.ObjectValue;
    //     localStorage.setItem('separador', separador);
    //   }
    // );
  }

  const agregarItem = () => {

  }

  return (
    <>
      {/* <BasicPage titulo="Árbol Clasificador de Bienes y Servicios Unspsc"> */}

      <div className="row">


        <div className="col-md-12">
          <ul className="list-group">
            {lista.length > 0 &&
              lista.map((item: any, index: number) => {
                return (
                  <li key={index} className="list-group-item itemh" onClick={() => { setBean(item.EnvolvedObject.DataBeanProperties) }}>
                    {edit &&
                      <ButtonUnspscID
                        id={item.EnvolvedObject.DataBeanProperties.IDLn}
                        bean={item.EnvolvedObject.DataBeanProperties}
                        updateEvent={() => { getListatree(year); }}
                      />
                    }
                    <strong className={idchild === item.EnvolvedObject.DataBeanProperties.IDLn ? 'cursor treeselect' : 'cursor'} onClick={() => {
                      localStorage.setItem('UnscpID', JSON.stringify(item.EnvolvedObject.DataBeanProperties));

                      setIDchild(item.EnvolvedObject.DataBeanProperties.IDLn);
                    }}>
                      <strong style={{ marginLeft: '15px' }}>{item.EnvolvedObject.DataBeanProperties.UnspscViewCode} </strong>
                      <small>{item.EnvolvedObject.DataBeanProperties.Name}</small>
                    </strong>
                    <span className="badge bg-success ms-1">{item.ChildrenCount}</span>
                    {(idchild === item.EnvolvedObject.DataBeanProperties.IDLn) &&
                      <UnspscIDChilds edit={edit} idChild={item.EnvolvedObject.DataBeanProperties.IDLn} editBean={(item: any) => { setBean(item) }} />
                    }
                  </li>
                );
              })}
          </ul>
        </div>
      </div>
      {/* </BasicPage> */}
    </>
  );
};
