import { DataBean } from "../DataBean";

export class ContractType extends DataBean {
    
    


    public async getContractTypeCatalogPorPropiedad(nombrePropiedad:any,value:any,maxRows:any): Promise<any> {
        return this.getCustomService("FinanceCore", "java.util.List_getContractTypeCatalogPorPropiedad_String_Object_Number", [nombrePropiedad,value,maxRows]);
    }

}