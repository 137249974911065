import { DataBean } from "../DataBean";

export class CashReimbursementItem extends DataBean {

    public async getCashReimbursementItemCatalog(idCashReimbursement: number, cashReimbursementDate: any, state: number): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_getCashReimbursementItemCatalog_Number_java.util.Date_Number", [idCashReimbursement, cashReimbursementDate, state]);
    }

    public async releaseUBLInvoiceFromCashReimbursement(idUBLInvoice: number, issueDate: any, idCashReimbursement: number, cashReimbursementDate: any, idEmployee: number, obsDeleted: string | null): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "com.quickdataerp.bean.accountant.CashReimbursementItem_releaseUBLInvoiceFromCashReimbursement_Number_java.util.Date_Number_java.util.Date_Number_String", [idUBLInvoice, issueDate, idCashReimbursement, cashReimbursementDate, idEmployee, obsDeleted]);
    }

    public async getCashReimbursementStateConstants(arg: number | null): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_getCashReimbursementStateConstants_Number", [arg]);
    }

    public async addUBLInvoiceToCashReimbursementFromCashRequestItem(idCashRequestItem: number, cashRequestDate: any, idCashReimbursement: number, cashReimbursementDate: any, idThirdBankingAccount: number, idEmployee: number): Promise<any> {
        return this.getCustomService(
            "QuickDataERPCore",
            "com.quickdataerp.bean.accountant.CashReimbursementItem_addUBLInvoiceToCashReimbursementFromCashRequestItem_Number_java.util.Date_Number_java.util.Date_Number_Number",
            [idCashRequestItem, cashRequestDate, idCashReimbursement, cashReimbursementDate, idThirdBankingAccount, idEmployee]
        );
    }
}