import { BasicTable, MASK_MONEY, MASK_NUMBER } from '../theme/Component/BasicTable';
import { Singleton } from '../../core/services/Singleton';
import { ContractService } from '../../core/services/ContractService';
import { useState, useEffect } from 'react';
import { ModalConfirm } from '../theme/Component/ModalConfirm';
import { Modal } from '../theme/Component/Modal';
import { ItemDropDown } from "./ItemDropDown";
import { ListMenu } from "../theme/Component/ListMenu";
import { ReclasificarItem } from "./ReclasificarItem";
import { AdquisitionItemAnalyst } from "./AdquisitionItemAnalyst";
import { SelectPreparadorCatalog } from "./select/SelectPreparadorCatalog";
import { InputMoney } from "../theme/Input/InputMoney";
import { SelectMonths } from "./select/SelectMonths";
import { SelectBudgetResource } from "./select/SelectBudgetResource";
import { SelectProjectID } from "../admin/configuracion/ProjectID/SelectProjectID";
import { AdquisitionArticle } from "./adquisition-setting/AdquisitionArticle";
import { AdquisitionItemBudgetID } from "./AdquisitionItemBudgetID";
import { useDashboardStore } from "../pages/hooks/useDashboardStore";
import { SelectModalityNewSer } from './select/SelectModalityNewSer';
import { SelectUnspscID } from '../admin/configuracion/UnspscID/SelectUnspscID';
import usePaginator from '../theme/Component/usePaginator';
import { ExportXLSL } from '../theme/Component/ExportXLSX';
import { DropDown } from '../theme/Component/DropDown';
import { ESTATE_ADQUISITIONITEM } from '../Constants';
interface Props {
    idAdquisitionPlan: number;
    _phaseState: number;
    rol?: number;
}
export const AdquisitionItemCatalog = ({ idAdquisitionPlan, _phaseState, rol }: Props) => {


    const single = Singleton.getInstance()
    const { setLoading } = useDashboardStore();
    const service = new ContractService();
    const [lista, setLista] = useState([]);
    const [listaExport, setListaExport] = useState([]);
    const [token, setToken] = useState<number>(0);
    const [beanItem, setBeanItem] = useState<any>({});
    const [permisos, setPermisos] = useState([]);
    const [modal, setModal] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: false, format: 'modal-lg' });
    const [modalConfirm, setModalConfirm] = useState<any>({ name: 'Confirmar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });
    const [modalAnalyst, setModalAnalyst] = useState<any>({ name: 'Analista', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });
    const [modalConfirm1, setModalConfirm1] = useState<any>({ name: 'Confirmar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });
    const [modalItem, setModalItem] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalArticulo, setModalArticulo] = useState<any>({ name: 'Articulos', tabIndex: -1, view: false, btnClose: true, btnSubmit: false, format: 'modal-lg' });
    const [modalRubro, setModalRubro] = useState<any>({ name: 'Rubros', tabIndex: -1, view: false, btnClose: true, btnSubmit: false, format: 'modal-lg' });
    const [modalCSV, setModalCSV] = useState<any>({ name: 'Reporte', tabIndex: -1, view: false, btnClose: true, btnSubmit: false, format: 'modal-md' });

    const paginador = usePaginator();

    const idBean = 'IDAdquisitionItem';
    const listaEstados = ESTATE_ADQUISITIONITEM;

    const head = [
        { title: 'ID', property: idBean, mask: MASK_NUMBER },
        { title: 'Necesidad', property: 'Description' },
        { title: 'Justificación', property: 'Justification', visible: false },
        { title: 'Modalidad de selección', property: 'IDHiringModalityName', visible: false },
        { title: 'Valor total estimado', property: 'TotalValue', mask: MASK_MONEY },
        { title: 'Valor total Bien/servicio', property: 'TotalArticle', mask: MASK_MONEY },
        { title: 'Rubro', property: 'IDLnBudgetIDNameList' },
        { title: 'Iniciativa Estratégica/Proyecto', property: 'NameProject', visible: false },
        { title: 'Dependencia Solicitante', property: 'IDLnFunctionalIDName' },
        { title: "Preparador", property: "PreparerName", visible: false },
        { title: "Analista", property: "AnalystName", visible: false },
        { title: "Estado preparación", property: "PreparationStateName" },        
    ]


    useEffect(() => {
        getLista();
        return () => {
            paginador.setPage(1);
        }
    }, [])
    useEffect(() => {
        setToken(single.getRandomInt());
    }, [modalArticulo])
    useEffect(() => {
        getLista();
    }, [paginador.page])

    const setRowBean = (bean: any) => {
        setBeanItem(bean);
    }

    const getLista = () => {

        setLoading(true);
        service.getAdquisitionItemCatalog(idAdquisitionPlan, null, paginador.page, 15).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue.DataBeanProperties.List) {
                   //console.log(resp.DataBeanProperties.ObjectValue.DataBeanProperties);
                    let pag = resp.DataBeanProperties.ObjectValue.DataBeanProperties;

                    paginador.setPages(pag.NumberOfPages);

                    setLista(pag.List);
                }
                setLoading(false);
            }
        );
        setModal({ ...modal, ['view']: false });
    }
    const getListaExport = () => {
        setLoading(true);
        service.getAdquisitionItemCatalog(idAdquisitionPlan, null, null, null).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue.DataBeanProperties.List) {                    
                    let pag = resp.DataBeanProperties.ObjectValue.DataBeanProperties;
                    setListaExport(pag.List);
                }
                setLoading(false);
            }
        );        
    }

    const eliminarItem = () => {

        setLoading(true);
        service.deleteAdquisitionItem(beanItem.IDAdquisitionItem).subscribe(
            (resp: any) => {
                setLoading(false);
                if (resp) {
                    getLista();
                }
                single.delete(beanItem[idBean], beanItem);
            }
        );
        setModalConfirm1({ ...modalConfirm1, "view": false });
    }

    const updateItem = () => {

        setModal({ ...modal, ['view']: false });
        setLoading(true);
        service.updateHiringModality(beanItem).subscribe(
            (resp: any) => {
                setLoading(false);
                if (resp.DataBeanProperties.ObjectValue) {
                    getLista();
                }
                if (beanItem[idBean]) {
                    single.update(beanItem);
                }
                else {
                    single.create(beanItem);
                }
            }
        );
    }

    const agregarItemList = () => {

        let b = {
            IDAdquisitionPlanFunctionalID: beanItem.IDAdquisitionPlanFunctionalID,
            Description: '',
            EstimatedValue: 0,
            ItemOrder: 1,
            ExecutionMonth: 0,
            EstimatedMonths: 1,
            IDBudgetIDType: 0,
            IDLnBudgetID: 0,
            Justification: '',
            IDFunctionalIDPreparer: 0,
        }

        setBeanItem(b);
        setModalItem({ ...modalItem, ['view']: true });
    }

    const editarMItem = () => {
        let b = beanItem;
        setBeanItem(b);
        setModalItem({ ...modalItem, "view": true });
    }

    const updateMItem = () => {

        service.updateAdquisitionItem(beanItem).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue) {
                    getLista();
                }
                if (beanItem[idBean]) {
                    single.update(beanItem);
                }
                else {
                    single.create(beanItem);
                }
                setModalItem({ ...modalItem, "view": false });
            }
        )
    }

    // const buttons = () => {
    //     return (
    //         <div className="row">
    //             <div className="col-md-12">
    //                 {beanItem[idBean] &&
    //                     <ItemDropDown rol={1} bean={beanItem} permisos={permisos} updateEvent={() => { getLista(); setBeanItem({}) }}></ItemDropDown>
    //                 }
    //             </div>
    //         </div>
    //     )
    // }

    let validarDelete = () => {
        let a = false;
        if (
            single.canDelete() &&
            (beanItem.PreparationState === 30 ||
                beanItem.PreparationState === 7 ||
                beanItem.PreparationState == null) &&
            (_phaseState == 0 ||
                _phaseState == 1)) {
            a = true;
        } else {
            a = false;
        }
        return a;
    }

    const listaBotones = () => {

        const botones = [
            { titulo: 'Editar', icono: 'ri-edit-2-line', visible: (single.canEdit() && (_phaseState == 0 || _phaseState == 1)), evento: editarMItem },
            { titulo: 'Eliminar', icono: 'ri-eraser-line', visible: validarDelete(), evento: () => { setModalConfirm1({ ...modalConfirm1, ['view']: true }) } },
            // { titulo: 'Rubros', icono: 'ri-git-branch-line', visible: (_phaseState == 0 || _phaseState == 1), evento: () => { setModalRubro({ ...modalRubro, "view": true }) } },
            // { titulo: 'Bien/Servicio', icono: 'ri-file-list-line', visible: true, evento: () => { setModalArticulo({ ...modalArticulo, 'view': true }) } },
            { titulo: 'Reclasificar', icono: 'ri-exchange-line', visible: (_phaseState == 0 || _phaseState == 1), evento: () => { setModal({ ...modal, ['view']: true }); } },
            { titulo: 'Analista Compras', icono: 'ri-exchange-line', visible: (_phaseState == 0 || _phaseState == 1) || rol === 1, evento: () => { setModalAnalyst({ ...modalAnalyst, ['view']: true }); } },

        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }
    const actionsDropDown = [
        { id: 1, name: 'Descargar Detallado', permiso: null, activo: true },

    ];
    const buttons = () => {
        return (
            <div className="row">
                <div className="col-md-12">
                    {single.canExport() &&
                        <DropDown lista={actionsDropDown} eventClick={actions}></DropDown>
                    }

                </div>
            </div>
        )
    }

    const actions = (e: any) => {

        if (e.id === 1) {
            getListaExport();
            setModalCSV({ ...modalCSV, "view": true });
        }
    }

    return (
        <>

            <div className="row">
                <div className="col-md-12 p-2">
                    <BasicTable headButtons={buttons()} listButtons={listaBotones()} head={head} body={lista} rowSet={setRowBean}
                        pageTop={paginador.page} next={paginador.next} back={paginador.back} records={paginador}
                    ></BasicTable>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <ModalConfirm modal={modalConfirm} updateModal={setModalConfirm} eventModal={eliminarItem}>
                        {beanItem.Name}
                    </ModalConfirm>

                    <Modal modal={modalAnalyst} updateModal={setModalAnalyst}>
                        <AdquisitionItemAnalyst idAdquisitionItem={beanItem.IDAdquisitionItem} updateFather={getLista} />
                    </Modal>
                    {/*Modal de reclasificar item */}
                    <Modal modal={modal} updateModal={setModal} eventModal={updateItem}>
                        {modal.view &&
                            <ReclasificarItem Item={beanItem} idAdquisitionPlan={idAdquisitionPlan} onChange={getLista} />
                        }
                    </Modal>

                    <ModalConfirm modal={modalConfirm1} updateModal={setModalConfirm1} eventModal={eliminarItem}>
                        {beanItem.Description}
                    </ModalConfirm>
                  
                    {/* rubros de la necesidad  */}
                    <Modal modal={modalRubro} updateModal={setModalRubro} eventModal={() => { }}>
                        <div className="row">
                            <div className="col">
                                {modalRubro.view &&
                                    <AdquisitionItemBudgetID key={beanItem.IDAdquisitionItem} CanModify={beanItem.PreparationState} adquisitionItem={beanItem} iDBudgetIDType={beanItem.IDBudgetIDType} />
                                }

                            </div>
                        </div>
                    </Modal>
                    {/* modal para exportar la lista */}
                    <Modal modal={modalCSV} updateModal={setModalCSV}>
                        <div className="row">
                            <div className="col">
                                {lista.length > 0 &&
                                    <ExportXLSL customName={"Necesidades"} optionIcon="ri-download-cloud-2-line text-success" title={head} data={listaExport} onExport={(name: string) => {
                                        single.download("formato xlsx [" + name + "] con " + lista.length + " registros");
                                    }} />
                                }
                            </div>
                        </div>
                    </Modal>
                    {/* adquisition item */}
                    <Modal modal={modalItem} updateModal={setModalItem} eventModal={updateMItem}>
                        <div className="row">
                            {rol != 2 &&
                                <div className="col-md-6">
                                    <SelectPreparadorCatalog key={token} idAdquisitionPlanFunctionalID={beanItem.IDAdquisitionPlanFunctionalID} idSelector={beanItem.IDFunctionalIDPreparer != null ? beanItem.IDFunctionalIDPreparer : 0}
                                        onChange={(id: number) => { setBeanItem({ ...beanItem, "IDFunctionalIDPreparer": id }) }}
                                    />
                                </div>
                            }
                            <div className="col-md-6">
                                <label>Valor Estimado</label>
                                <InputMoney readonly={(beanItem.IDAdquisitionItem >= 0 && beanItem.PhaseState == 2)} id={0} name='Valor Estimado' value={beanItem.TotalValue} onChange={(e: any) => { setBeanItem({ ...beanItem, ['TotalValue']: parseInt(e) }) }} />
                            </div>
                            <div className="col-md-6">
                                <SelectMonths idSelector={beanItem.ExecutionMonth} onChange={(e: number) => { setBeanItem({ ...beanItem, "ExecutionMonth": e }) }}></SelectMonths>
                            </div>
                            <div className="col-md-6">
                                <label>Meses Estimados</label>
                                <input type="number" className="form-control" value={beanItem.EstimatedMonths} onChange={(e) => { setBeanItem({ ...beanItem, "EstimatedMonths": e.target.value }) }} />
                            </div>
                            {/* <div className="col-md-6">
                            <SelectBudgetResource idSelector={beanItem.IDBudgetResource} onChange={(e: any) => {
                                setBeanItem({ ...beanItem, "IDBudgetResource": e })
                            }}></SelectBudgetResource>
                        </div> */}
                            <div className="col-md-6">
                                <SelectModalityNewSer idSelector={beanItem.IDHiringModality} onChange={(e) => { setBeanItem({ ...beanItem, "IDHiringModality": e }) }} />
                            </div>
                            <div className="col-md-6">
                                <SelectUnspscID label="Código CIIU Principal" renderValue="" onChange={(e) => { setBeanItem({ ...beanItem, "IDLnUnspscItem": e.IDLn }) }} />
                            </div>
                            <div className="col-md-12">
                                <SelectProjectID edit={false} projectIDLn={beanItem.IDLnProjectID} onChange={(e: any) => { setBeanItem({ ...beanItem, "IDLnProjectID": e.IDLn }) }} />
                            </div>

                            <div className="col-md-12">
                                <label >Justificación</label>
                                <textarea className="form-control" placeholder='Incluya los aspectos legales, procedimentales, funcionales que sustentan la solicitud'
                                    value={beanItem.Justification != null ? beanItem.Justification : ''} onChange={(e) => { setBeanItem({ ...beanItem, "Justification": e.target.value }) }}></textarea>
                            </div>
                            <div className="col-md-12">
                                <label >Objecto de la Necesidad</label>
                                <textarea className="form-control" value={beanItem.Description} onChange={(e) => { setBeanItem({ ...beanItem, "Description": e.target.value }) }}></textarea>
                            </div>
                        </div>
                        {/* <div className="row">
                            
                                <div className="col-md-6">
                                    <SelectPreparadorCatalog key={beanItem.IDAdquisitionPlanFunctionalID} idAdquisitionPlanFunctionalID={beanItem.IDAdquisitionPlanFunctionalID} idSelector={beanItem.IDFunctionalIDPreparer ?? 0}
                                        onChange={(id: number) => { setBeanItem({ ...beanItem, "IDFunctionalIDPreparer": id }) }}
                                    />
                                </div>

                                <div className="col-md-6">
                                    <label>Valor Estimado</label>
                                    <InputMoney readonly={(beanItem.IDAdquisitionItem >= 0 && beanItem.PhaseState == 2)} id={0} name='Valor Estimado' value={beanItem.TotalValue ?? 0} onChange={(e: any) => { setBeanItem({ ...beanItem, ['TotalValue']: parseInt(e) }) }} />
                                </div>
                                <div className="col-md-6">
                                    <SelectMonths idSelector={beanItem.ExecutionMonth} onChange={(e: number) => { setBeanItem({ ...beanItem, "ExecutionMonth": e }) }}></SelectMonths>
                                </div>
                                <div className="col-md-6">
                                    <label>Meses Estimados</label>
                                    <input type="number" className="form-control mt-2" value={beanItem.EstimatedMonths} onChange={(e) => { setBeanItem({ ...beanItem, "EstimatedMonths": e.target.value }) }} />
                                </div>
                                <div className="col-md-6">
                                    <SelectBudgetResource idSelector={beanItem.IDBudgetResource} onChange={(e: any) => {
                                        setBeanItem({ ...beanItem, "IDBudgetResource": e })
                                    }}></SelectBudgetResource>
                                </div>
                                <div className="col-md-12">
                                    <SelectProjectID renderValue={beanItem.NameProject} edit={false} onChange={(e: any) => {
                                        setBeanItem({ ...beanItem, "IDLnProjectID": e.IDLn })
                                    }}></SelectProjectID>
                                </div>

                                <div className="col-md-12">
                                    <label className="form-label">Justificación</label>
                                    <textarea className="form-control" placeholder='Incluya los aspectos legales, procedimentales, funcionales que sustentan la solicitud'
                                        value={beanItem.Justification != null ? beanItem.Justification : ''} onChange={(e) => { setBeanItem({ ...beanItem, "Justification": e.target.value }) }}></textarea>
                                </div>
                                <div className="col-md-12">
                                    <label className="form-label">Necesidad</label>
                                    <textarea className="form-control" value={beanItem.Description} onChange={(e) => { setBeanItem({ ...beanItem, "Description": e.target.value }) }}></textarea>
                                </div>
                            
                        </div> */}
                    </Modal>
                </div>
            </div>
        </>
    )
}