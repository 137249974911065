export const processState = [
    { id: 1, name: 'Elaboración' },
    { id: 2, name: 'Publicado' }
]

export const budgetTypes = [
    { id: 4, name: 'RECURSOS HUMANOS-NOMINA' },
    { id: 5, name: 'ACTIVOS FIJOS E INVENTARIOS' },
    { id: 6, name: 'CONTABILIDAD Y FINANZAS' },
    { id: 7, name: 'CONTRATACIÓN' },
    { id: 8, name: 'PRESUPUESTO' },
    { id: 9, name: 'CORE DEL NEGOCIO' },
]
export const ESTATE_ADQUISITIONITEM = [
    { id: 30, name: 'EN PREPARACION' },
    { id: 31, name: 'PREPARADA' },
    { id: 27, name: 'VALORES NO COINCIDEN' },
    { id: 9, name: 'INCOMPLETO' },
    { id: 8, name: 'CANCELADA' },
    { id: 4, name: 'ESTRUCTURADA' },
    { id: 6, name: 'APROBADA' },
    { id: 7, name: 'RECHAZADA' },
    { id: 44, name: 'EN ESTUDIO PREVIO' },
    { id: 40, name: 'PRECONTRACTUAL' },
    { id: 41, name: 'EN CONTRATACIÓN' },
    { id: 42, name: 'LIQUIDADA' },
    { id: 43, name: 'EN EDICIÓN' },
]

export const VISIBLE_ITEMS_PREPARER = [30, 31, 4, 9, 27];

export const MONTHS = [
    { id: 1, name: 'Enero' },
    { id: 2, name: 'Febrero' },
    { id: 3, name: 'Marzo' },
    { id: 4, name: 'Abril' },
    { id: 5, name: 'Mayo' },
    { id: 6, name: 'Junio' },
    { id: 7, name: 'Julio' },
    { id: 8, name: 'Agosto' },
    { id: 9, name: 'Septiembre' },
    { id: 10, name: 'Octubre' },
    { id: 11, name: 'Noviembre' },
    { id: 12, name: 'Diciembre' },
]

export const ITEM_ADMIN = 1;
export const ITEM_JEFE = 2;
export const ITEM_PREPARADOR = 3;
