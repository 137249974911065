import { useEffect, useState } from "react";
import useDataBean from "../../../core/services/dataBean/useDataBean";
import { Singleton } from "../../../core/services/Singleton";
import { toast } from "react-toastify";
import { ListMenu } from "../../theme/Component/ListMenu";
import { BasicPage } from "../../theme/Component/BasicPage";
import { BasicTable } from "../../theme/Component/BasicTable";
import { Modal } from "../../theme/Component/Modal";
import { Submacroprocces } from "../ParametrosTalentoHumano/MacroProcess/Submacroprocces";
import { ModalConfirm } from "../../theme/Component/ModalConfirm";
import { AdminService } from "../../../core/services/AdminService";
import { BsSend } from "react-icons/bs";
import { FileInput } from "../../theme/Component/FileInput";
import { _EmployeeContract } from "../../../core/services/dataBean/EntityCatalog";
import { BasicButton } from "../../theme/Component/BasicButton";




export const Contratoslaborales = () => {
    const Service = new AdminService();
    const importContrac = useDataBean(_EmployeeContract);
    const [bean, setBean] = useState<any>({});
    const [fileI, setFileI] = useState<any>({});
    const [lista, setLista] = useState<any>([]);

    useEffect(() => {
        getLista()
    }, [])



    const getLista = () => {

        Service.exportEmployeeContractsTemplateXlsxFile(null).subscribe(
            (resp: any) => {

                if (resp.DataBeanProperties.ObjectValue) {
                    setLista(resp.DataBeanProperties.ObjectValue);
                   //console.log(resp)
                    toast.success("Cargando Lista de Archivos")
                } else {
                    toast.error("No se pudo cargar la lista de Archivos")
                }
            }
        );
    }

    const importar = () => {
        importContrac.instance.importEmployeeContracts(fileI.Media, fileI.MediaContext, null).then(
            (resp: any) => {
               //console.log(resp)
                toast.success("Archivo Cargado")

            }
        )
    }


    return (
        <BasicPage titulo=" Importar Contratos Laborales ">

            <div className="row">
                <div className="col-md-4">
                    <label >Listar Documentos</label>
                    <BasicButton icon="ri" eventClick={getLista}>    <BsSend />Documentos</BasicButton>

                </div>
                <div className="col-md-8">
                    <FileInput directory="contrato-laboral" label="Importar Contratos Laborales" onCreate={(e: any) => {
                        setFileI(e);
                    }} />
                    <BasicButton disable={!fileI.Media} icon="ri-database-2-line" eventClick={importar}>Importar</BasicButton>
                </div>

            </div>
            <>

                {Array.isArray(lista) && lista.map((mensaje: any) => {
                    return (
                        <div className="cardbandeja" key={mensaje.DataBeanProperties}>

                        </div>
                    )
                })
                }

            </>
        </BasicPage>
    )
}