import { useEffect, useState } from "react";
import { _EmployeeContract, _EmployeeContractHistory } from "../../../../core/services/dataBean/EntityCatalog";
import useDataBean from "../../../../core/services/dataBean/useDataBean";
import { Singleton } from "../../../../core/services/Singleton";
import { BasicPage } from "../../../theme/Component/BasicPage";
import { SelectMonths } from "../../../contract/select/SelectMonths";
import { BasicButton } from "../../../theme/Component/BasicButton";
import { EntailmentEditor } from "../../ParametrosTalentoHumano/Entailment/Entailment.editor";
import { AppointmentEditor } from "../../ParametrosTalentoHumano/Appointment/Appointment.editor";
import { BasicTable, MASK_DATE, MASK_MONEY, MASK_NUMBER, MASK_TEXT_NOWRAP } from "../../../theme/Component/BasicTable";
import { SelectFunctionalID } from "../../../admin/configuracion/functionalID/SelectFunctionalID";
import { MultiAccountanEditor } from "../../../editor/MultiAccountanEditor";
import { SelectSiteID } from "../../../admin/configuracion/SiteID/SelectSiteID";
import { ListMenu } from "../../../theme/Component/ListMenu";
import { Modal } from "../../../theme/Component/Modal";
import { ContractHistory } from "./ComponentsContract/ContracHistory/ContractHistory";
import { CDPLink } from "./ComponentsContract/CdpLink/CDPLink";
import { ConstractStateEditor } from "./ComponentsContract/EditorsEmployeContrac/ConstractState.editor";
import { ContractTypeEditor } from "./ComponentsContract/EditorsEmployeContrac/ContractType.editor";
import { InputDate } from "../../../theme/Input/InputDate";
import { WelfareEntityEditor } from "./ComponentsContract/EditorsEmployeContrac/WelfareEntity.editor";
import { RevenueTypeEditor } from "./ComponentsContract/EditorsEmployeContrac/RevenueType.editor";
import { BasicSwitch } from "../../../theme/Component/BasicSwitch";
import { ARPRiskEditor } from "./ComponentsContract/EditorsEmployeContrac/ARPRisk.editor";
import { ContributorTypeEditor } from "./ComponentsContract/EditorsEmployeContrac/ContributorType.editor";
import { ContributorSubTypeEditor } from "./ComponentsContract/EditorsEmployeContrac/ContributorSubType.editor";
import { EmployeeContractTypeEditor } from "./ComponentsContract/EditorsEmployeContrac/EmployeeContractType.editor";
import { EmploymentTypeEditor } from "./ComponentsContract/EditorsEmployeContrac/EmploymentType.editor";
import { EmployeeLiquidateEditor } from "./ComponentsContract/EditorsEmployeContrac/EmployeeLiquidate.editor";
import { ExportEmployeeContract } from "./ComponentsContract/ExportEmployeeContract/ExportEmployeeContract";
import { ImportEmployeeContract } from "./ComponentsContract/ImportEmployeeContract/ImportEmployeeContract";
import { SelectFunctionalArea } from "../../../admin/configuracion/FunctionalArea/SelectFunctionalArea";
import usePaginator from "../../../theme/Component/usePaginator";
import { DropDown } from "../../../theme/Component/DropDown";
import useModal, { MODAL_SM } from "../../../theme/Component/hooks/useModal";
import { ExportXLSL } from "../../../theme/Component/ExportXLSX";
import { CardTaps } from "../../../theme/Component/CardTaps";
import { CostingCenterSelect } from "../../../erp/cellEditor/CostingCenter/CostingCenterSelect";
import { InputMoney } from "../../../theme/Input/InputMoney";

export const EmployeeContract = () => {

    const employeeContract = useDataBean(_EmployeeContract);
    const hisotryContract = useDataBean(_EmployeeContractHistory);
    const single = Singleton.getInstance();
    const [bean, setBean] = useState<any>({});
    const [vigencia, setVigenia] = useState<number>(single.getCurrenYear());
    const [registers, setRegisters] = useState<number>(15);
    const [month, setMonth] = useState<number>(single.getCurrentMonth());
    // const [planta, setPlanta] = useState<string>(null);
    const [employmentType, setEmploymentType] = useState<number>(null);
    const [regexStx, setRegexStx] = useState<string>(null);
    const [funtional, setFuntional] = useState<number>(null);
    const [entailment, setEntailment] = useState<number>(null);
    const [appointment, setAppointment] = useState<number>(null);
    const [idSite, setIdSite] = useState<number>(null);
    const [contractState, setContractState] = useState<number>(2);
    const [lista, setLista] = useState<any>([]);
    const [listaAccs, setListaAccs] = useState<any>([]);
    const [listE, setListE] = useState<any>([]);

    const [modal, setModal] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalExport, setModalExport] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalImport, setModalImport] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });
    const [modalAddHistory, setModalAddHistory] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-md' });
    const [modalHistory, setModalHistory] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalLinkCDP, setModalLinkCDP] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalUpdateParameters, setModalUpdateParameters] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-md' });
    const modalExportList = useModal("Exportar Historial", MODAL_SM);

    const paginador = usePaginator();

    useEffect(() => {
        getLista();
    }, [paginador.page])

    const getLista = () => {
        paginador.setRegiPerPage(registers)
        ////console.log(vigencia, month, listaAccs.length > 0 ? listaAccs : null, employmentType, funtional, entailment, appointment, contractState, idSite, registers, paginador.page, regexStx)

        employeeContract.instance.getEmployeeContracts(vigencia, month, listaAccs.length ? listaAccs : null, employmentType ? [employmentType] : null, funtional, entailment, appointment, contractState, idSite, regexStx, paginador.page, registers).then(
            (resp) => {
                if (resp) {
                    paginador.setPages(resp.NumberOfPages);
                    paginador.setRecords(resp.NumberOfPages * (registers ?? 15));
                    paginador.setRegiPerPage(registers)

                    setLista(resp.List)

                }
            }
        )
    }

    const getListaReport = () => {
        paginador.setRegiPerPage(registers)
        ////console.log(vigencia, month, listaAccs.length > 0 ? listaAccs : null, employmentType, funtional, entailment, appointment, contractState, idSite, registers, paginador.page, regexStx)

        employeeContract.instance.getEmployeeContracts(vigencia, month, listaAccs.length ? listaAccs : null, employmentType ? [employmentType] : null, funtional, entailment, appointment, contractState, idSite, regexStx, null, null).then(
            (resp) => {
                if (resp) {
                    setListE(resp.List)
                }
            }
        )
    }

    const update = () => {
        let x = bean;
        x.IDEmployeeLastUpdate = single.getAccountID()
        employeeContract.updateCustonBean(x).then(
            (resp) => {
               //console.log(x)
                single.update(bean)
                getLista();
                setBean({});
                setModal({ ...modal, "view": false });
            }
        )
    }

    const updateFunctionalArea = () => {
        employeeContract.instance.updateFunctionalAreaAndAppointmentForEmployeeContract(bean.IDContract, bean.fromDate, bean.IDAppointment, bean.IDLnFunctionalArea, single.getAccountID()).then(
            (resp) => {
               //console.log(resp)
                getLista();
                setModalUpdateParameters({ ...modalUpdateParameters, "view": false })
                single.update(bean)
                setBean({})
            }
        )
    }


    const create = () => {
        hisotryContract.instance.createEmployeeContractHistory(bean.IDContract, bean.IDAppointment ?? null, bean.InitDate, bean.UptoDate, bean.LastSalary ?? null, single.getAccountID()).then(
            (resp) => {
               //console.log(resp)
                single.create({ IDContract: bean.IDContract, IDAppointment: bean.IDAppointment, from: bean.InitDate, upto: bean.UptoDate, LastSalary: bean.LastSalary, IDEmployee: single.getAccountID() })
                setModalAddHistory({ ...modalAddHistory, "view": false })
                setBean({})
                getLista();
            }
        )
    }

    const head = [
        { title: 'IDContrato', property: 'IDContract', visible: false, mask: MASK_NUMBER },
        { title: 'Usuario', property: 'AccountName', visible: true, mask: MASK_TEXT_NOWRAP },
        { title: 'Perfil de Liquidación', property: 'IDEmployeeLiquidate', visible: true },
        { title: 'Nivel Jerárquico', property: 'EntailmentName', visible: true },
        { title: 'Nivel Salarial/Grado', property: 'AppointmentName', visible: true },
        { title: 'Tipo Vinculación', property: 'ContractTypeName', visible: true },
        { title: 'Área Funcional', property: 'IDLnFunctionalArea', mask: MASK_NUMBER, visible: true },
        { title: 'Fecha de Inicio', property: 'InitContract', mask: MASK_DATE, visible: true },
        { title: 'Ingreso Nación', property: 'NationInit', mask: MASK_DATE, visible: true },
        { title: 'EPS', property: 'EPSName', visible: true },
        { title: 'ARP', property: 'ARPName', visible: true },
        { title: 'CCF', property: 'CCFName', visible: true },
        { title: 'Fondo Pensional', property: 'FPName', visible: true },
        { title: 'Fondo Cesantías', property: 'FCEName', visible: true },
        { title: 'Tipo de Salario', property: 'RevenueType', visible: true },
        { title: 'Salario', property: 'Revenue', mask: MASK_MONEY, visible: true },
        { title: 'Usar Salario Definido en el Contrato', property: 'UseContractSalaryValue', visible: true },
        { title: 'Salario Definido por Contrato', property: 'ContractSalaryValue', mask: MASK_MONEY, visible: true },
        { title: 'Tipo Riesgo', property: 'ARPRiskName', visible: true },
        { title: 'Tipo Cotizante', property: 'EmployeeTypeName', visible: true },
        { title: 'Subtipo Cotizante', property: 'EmployeeSubtypeName', visible: true },
        { title: 'Fecha Renuncia', property: 'EndingDate', mask: MASK_DATE, visible: true },
        { title: 'Estado', property: 'ContractStateName', visible: true },
        { title: 'Fecha Liquidación', property: 'UptoContract', mask: MASK_DATE, visible: true },
        { title: 'Fecha de Nacimiento', property: 'BornDate', mask: MASK_DATE, visible: true },
        { title: 'Dirección', property: 'Address', mask: MASK_TEXT_NOWRAP, visible: true },
        { title: 'Teléfono', property: 'Tel', visible: true },
        { title: 'Correo Electrónico', property: 'eMail', visible: true },
        { title: 'Género', property: 'GenType', visible: true },
        { title: 'Resposable ImpoRenta', property: 'RentTax', visible: true },
        { title: 'Último Exámen Médico', property: 'HealthTestDate', mask: MASK_DATE, visible: true },
        { title: 'Últimas Vacaciones', property: 'LastVacDate', mask: MASK_DATE, visible: true },
        { title: 'Última Dotación', property: 'LastEndowmentDate', mask: MASK_DATE, visible: true },
        { title: 'Tipo de Empleo', property: 'EmploymentTypeName', visible: true },
        { title: 'Código CIIU', property: 'CIIUCode', mask: MASK_NUMBER, visible: true },
        { title: 'Subtipo ARL', property: 'ARPRiskName', visible: true },
        { title: 'Definir Centro de Costo', property: 'UseOwnCostingCenter', visible: true },
        { title: 'Centro de Costo', property: 'CostingCenter', mask: MASK_NUMBER, visible: true },
        { title: 'Tipo de Contrato', property: 'EmployeeContractType', visible: true },
        { title: 'Definir Límite de UVTs', property: 'UseUVTMaxRetefuente', visible: true },
        { title: 'Limite UVTs', property: 'UVTMaxRetefuente', visible: true },
        { title: 'Ciudad de Ejecución del Contrato', property: 'SiteIDName', visible: true },
        { title: 'Creado por', property: 'EmployeeName', visible: true },
        { title: 'Actualizado por', property: 'LastUpdateEmployeeName', visible: true },
        { title: 'Última Actualización', property: 'LastUpdate', visible: true, mask: MASK_DATE },
    ]

    const listaBotones = () => {
        const botones = [
            { titulo: 'Editar Contrato', icono: 'ri-file-list-line', visible: true, evento: () => { setModal({ ...modal, "name": "Editar Contrato Laboral", "view": true }) } },
            { titulo: 'Agregar Historial de contrato', icono: 'ri-file-list-line', visible: true, evento: () => { setModalAddHistory({ ...modalAddHistory, "name": "Agregar Historial de contrato", "view": true }) } },
            { titulo: 'Historial de Contrato Laboral', icono: 'ri-file-user-line', visible: true, evento: () => { setModalHistory({ ...modalHistory, "name": "Historial de Contrato Laboral", "view": true }) } },
            { titulo: 'Vincular CDPs/RPs', icono: 'ri-file-user-line', visible: true, evento: () => { setModalLinkCDP({ ...modalLinkCDP, "name": "Vincular CDPs/RPs", "view": true }) } },
            { titulo: 'Modificar Parámetros de Contrato Laboral', icono: 'ri-file-user-line', visible: true, evento: () => { setModalUpdateParameters({ ...modalUpdateParameters, "name": "Modificar Parámetros de Contrato Laboral", "view": true }) } },
        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }
    const actionsDropDown = [
        { id: 1, name: 'Exportar', permiso: null, activo: true },

    ];
    const buttons = () => {
        return (
            <div className="row">
                <div className="col-md-12">
                    <DropDown lista={actionsDropDown} eventClick={buttonEvent}></DropDown>
                </div>
            </div>
        )
    }
    const buttonEvent = (e) => {
        if (e.id === 1) {
            getListaReport();
            modalExportList.open();

        }

    }

    const taps = [
        { id: 0, name: 'Información Contrato' },
        { id: 1, name: 'Información interna' },
        { id: 2, name: 'Seguridad Social y Aportes' },
    ]

    const [indexTap, setIndexTap] = useState(0);

    const clearFilter = () => {
        setVigenia(single.getCurrenYear());
        setMonth(single.getCurrentMonth());
        setContractState(2);
        setListaAccs([]);
        setEmploymentType(NaN)
        setFuntional(NaN)
        setEntailment(NaN)
        setAppointment(NaN)
        setIdSite(NaN)
        setLista([]);
    }
    const filter = () => {
        return (
            <>
                <div className="row ">
                    <div className="col-md-4">
                        <MultiAccountanEditor values={listaAccs} label="Filtro Empleados" onChange={(e) => { setListaAccs(e) }} />
                    </div>
                    <div className="col-md-4">
                        <EmploymentTypeEditor label="Tipo de Grupo Laboral" idSelector={employmentType} onChange={(e) => { setEmploymentType(e) }} />
                    </div>
                    <div className="col-md-4">
                        <SelectFunctionalID label="Unidad Organizacional" onChange={(e) => { setFuntional(e.IDLn) }} />
                    </div>
                    <div className="col-md-4">
                        <EntailmentEditor label="Nivel Jerárquico" idSelector={entailment} onChange={(e) => { setEntailment(e) }} />
                    </div>
                    <div className="col-md-4">
                        <AppointmentEditor label="Nivel Salarial" idSelector={appointment} onChange={(e) => { setAppointment(e) }} />
                    </div>
                    <div className="col-md-4">
                        <SelectSiteID label="Ubicación Geográfica" edit idLnSite={idSite} onChange={(e) => { setIdSite(e.IDLn) }} />
                    </div>
                    <div className="col-md-4">
                        <label>Búsqueda por Texto</label>
                        <input type="text" className="form-control" value={regexStx} onChange={(e) => { setRegexStx(e.target.value) }} />
                    </div>
                    <div className="col-md-4">
                        <label>Número de Registros Por Pagina</label>
                        <input type="number" className="form-control" value={registers} onChange={(e) => { setRegisters(parseInt(e.target.value)) }} />
                    </div>

                </div>
                <div className="row">
                    <div className="col-md-12 mt-4">
                        <div className="row">
                            <div className="col-md-2 largerow"><BasicButton icon="ri-search-line" eventClick={() => paginador.page === 1 ? getLista() : paginador.setPage(1)}>Buscar</BasicButton>
                            </div>

                            <div className="col-md-3"> <BasicButton clase="danger" icon="ri-delete-bin-2-line" eventClick={() => clearFilter()}>Limpiar Filtros</BasicButton></div>
                            <div className="col-md-7"></div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    return (
        <>
            <BasicPage titulo="Historial de Contratos de Trabajo">
                <div className="row">
                    <div className="col-12">
                        <div className="row">
                            <div className="col-4">
                                <label>Vigencia:</label>
                                <input type="number" className="form-control" value={vigencia} onChange={(e) => { setVigenia(parseInt(e.target.value)) }} />
                            </div>
                            <div className="col-4">
                                <SelectMonths idSelector={month} onChange={(e) => { setMonth(e) }}></SelectMonths>
                            </div>
                            <div className="col-4">
                                <ConstractStateEditor label="Estado del Contrato" idSelector={contractState} onChange={(e) => { setContractState(e) }} />
                            </div>
                            {/* <div className="col-5 mt-4">
                                <div className="row ">
                                    <div className="col-md-4">  <BasicButton icon="ri-search-line" eventClick={() => paginador.page === 1 ? getLista() : paginador.setPage(1)}>Buscar</BasicButton></div>
                                    <div className="col-md-2"></div>
                                    <div className="col-md-6"> <BasicButton clase="danger" icon="ri-delete-bin-2-line" eventClick={() => clearFilter()}>Limpiar Filtros</BasicButton></div>


                                </div>

                            </div> */}


                        </div>
                    </div>
                </div>
                {filter()}
                <div className="row">
                    <div className="col-md-12">
                        <BasicTable
                            headButtons={buttons()}
                            listButtons={listaBotones()}
                            head={head} body={lista}
                            rowSet={setBean}
                            pageTop={paginador.page}
                            next={paginador.next}
                            back={paginador.back}
                            records={paginador}
                            customRows={registers}
                        // filterModal={filter()}
                        />
                    </div>
                </div>

                {/* <PrintJson json={paginador} /> */}

                {/* <BasicTable registersPerPage={registers} listButtons={listaBotones()} head={head} body={lista} rowSet={(bean) => { setBean(bean) }} /> */}


            </BasicPage>

            <Modal modal={modalExportList.modal} updateModal={modalExportList.setModal}>
                {listE.length > 0 &&
                    <ExportXLSL customName={"Historial"} optionIcon="ri-download-cloud-2-line text-success" title={head} data={listE} onExport={(name: string) => {
                        single.download("formato xlsx [" + name + "] con " + lista.length + " registros");
                    }} />
                }
            </Modal>

            {modal.view &&
                <Modal modal={modal} updateModal={setModal} eventModal={() => { update() }} >
                    <CardTaps index={indexTap} taps={taps} updateIndex={setIndexTap}>
                        {indexTap == 0 &&
                            <div className="row">
                                <div className="col-md-6">
                                    <ContractTypeEditor idSelector={bean.ContractType} onChange={(e) => { setBean({ ...bean, "ContractType": e }) }} />
                                </div>
                                <div className="col-md-6">
                                    <EmployeeContractTypeEditor idSelector={bean.IDEmployeeContractType} onChange={(e) => { setBean({ ...bean, "IDEmployeeContractType": e }) }} />
                                </div>
                                <div className="col-md-6">
                                    <label>Tiempo de Prueba</label>
                                    <input type="text" className="form-control" name="JobTestTime" value={bean.JobTestTime} onChange={(e) => { setBean({ ...bean, "JobTestTime": e.target.value }) }} />
                                </div>
                                <div className="col-md-6">
                                    <EmployeeLiquidateEditor idSelector={bean.IDEmployeeLiquidate} onChange={(e) => { setBean({ ...bean, "IDEmployeeLiquidate": e }) }} />
                                </div>
                                <div className="col-md-6">
                                    <label>Fecha de Inicio en Nación/Grupo Empresarial</label>
                                    <InputDate name="NationInit" value={bean.NationInit} setDate={(e) => { setBean({ ...bean, "NationInit": e }) }} />
                                </div>
                                <div className="col-md-6">
                                    <ConstractStateEditor idSelector={bean.ContractState} onChange={(e) => { setBean({ ...bean, "ContractState": e }) }} />
                                </div>
                                <div className="col-md-6">
                                    <label>Fecha de Inicio de Contrato</label>
                                    <InputDate name="InitContract" value={bean.InitContract} setDate={(e) => { setBean({ ...bean, "InitContract": e }) }} />
                                </div>
                                <div className="col-md-6">
                                    <RevenueTypeEditor label="Tipo de Salario" idSelector={bean.RevenueType} onChange={(e) => { setBean({ ...bean, "RevenueType": e }) }} />
                                </div>
                                <div className="col-md-6">
                                    <label>Fecha de Renuncia</label>
                                    <InputDate name="EndingDate" value={bean.EndingDate} setDate={(e) => { setBean({ ...bean, "EndingDate": e }) }} />
                                </div>
                                <div className="col-md-6">
                                    <label>Fecha de Liquidación de Contrato</label>
                                    <InputDate name="UptoContract" value={bean.UptoContract} setDate={(e) => { setBean({ ...bean, "UptoContract": e }) }} />
                                </div>
                                <div className="col-md-6 d-flex-wrap align-content-center">
                                    <BasicSwitch estado={bean.UseContractSalaryValue} eventChange={(e) => { setBean({ ...bean, "UseContractSalaryValue": e }) }} label="Usar Salario Definido en el Contrato" />
                                </div>
                                {bean.UseContractSalaryValue &&
                                    <div className="col-md-6">
                                        <label>Salario Definido por Contrato</label>
                                        <InputMoney id={1} name="ContractSalaryValue" value={bean.ContractSalaryValue} onChange={(e) => { setBean({ ...bean, "ContractSalaryValue": e }) }} />
                                    </div>
                                }
                            </div>
                        }
                        {indexTap == 1 &&
                            <div className="row">
                                <div className="col-md-6">
                                    <label >Actividad Economicá (CIIU)</label>
                                    <input type="number" className="form-control" value={bean.CIIUCode} onChange={(e) => { setBean({ ...bean, "CIIUCode": e.target.value }) }} />
                                </div>
                                <div className="col-md-6">
                                    <EmploymentTypeEditor idSelector={bean.IDEmploymentType} onChange={(e) => { setBean({ ...bean, "IDEmploymentType": e }) }} />
                                </div>
                                <div className="col-md-6">
                                    <SelectSiteID label="Ciudad de Ejecución de Labores" edit idLnSite={bean.IDLnSiteID} onChange={(e) => { setBean({ ...bean, "IDLnSiteID": e.IDLn }) }} />
                                </div>
                                <div className="col-md-6 d-flex-wrap align-content-center">
                                    <BasicSwitch label=" Definir CDPs para este contrato" estado={bean.UseCreatedCDP} eventChange={(e) => { setBean({ ...bean, "UseCreatedCDP": e }) }} />
                                </div>
                                <div className="col-md-6 d-flex-wrap align-content-center">
                                    <BasicSwitch label="Definir Centro de Costo" estado={bean.UseOwnCostingCenter} eventChange={(e) => { setBean({ ...bean, "UseOwnCostingCenter": e }) }} />
                                </div>
                                {bean.UseOwnCostingCenter &&
                                    <div className="col-md-6">
                                        <label>Centro de Costo</label>
                                        <CostingCenterSelect onChange={(e) => { setBean({ ...bean, "CostingCenter": e }) }} idSelector={bean.CostingCenter} label="Centro de Costo" />
                                        {/* <input className="form-control" type="number" value={bean.CostingCenter} onChange={(e) => { setBean({ ...bean, "CostingCenter": e.target.value }) }} /> */}
                                    </div>
                                }
                                <div className="col-md-6 d-flex-wrap align-content-center">
                                    <BasicSwitch label="Definir UVTs máximas para Retefuente" estado={bean.UseUVTMaxRetefuente} eventChange={(e) => { setBean({ ...bean, "UseUVTMaxRetefuente": e }) }} />
                                </div>
                                {bean.UseUVTMaxRetefuente &&
                                    <div className="col-md-6">
                                        <label>UVTs Máximas de Descuentos</label>
                                        <input className="form-control" type="number" value={bean.UVTMaxRetefuente} onChange={(e) => { setBean({ ...bean, "UVTMaxRetefuente": e.target.value }) }} />
                                    </div>
                                }
                            </div>
                        }
                        {indexTap == 2 &&
                            <div className="row">
                                <div className="col-md-6">
                                    <WelfareEntityEditor entity={0} idSelector={bean.EPS} onChange={(e) => { setBean({ ...bean, "EPS": e }) }} label="Entidad Promotora de Salud" />
                                </div>
                                <div className="col-md-6">
                                    <WelfareEntityEditor entity={1} idSelector={bean.ARP} onChange={(e) => { setBean({ ...bean, "ARP": e }) }} label="Aseguradora de Riesgos Profesionales" />
                                </div>
                                <div className="col-md-6">
                                    <WelfareEntityEditor entity={2} idSelector={bean.CCF} onChange={(e) => { setBean({ ...bean, "CCF": e }) }} label="Caja de Compensación Familiar" />
                                </div>
                                <div className="col-md-6">
                                    <WelfareEntityEditor entity={3} idSelector={bean.FP} onChange={(e) => { setBean({ ...bean, "FP": e }) }} label="Fondo de Pensiones" />
                                </div>
                                <div className="col-md-6">
                                    <WelfareEntityEditor entity={9} idSelector={bean.FCE} onChange={(e) => { setBean({ ...bean, "FCE": e }) }} label="Fondo de Cesantias" />
                                </div>

                                <div className="col-md-6" />
                                <div className="col-md-6">
                                    <ContributorTypeEditor idSelector={bean.EmployeeType} onChange={(e) => { setBean({ ...bean, "EmployeeType": e }) }} />
                                </div>
                                <div className="col-md-6">
                                    <ContributorSubTypeEditor idSelector={bean.EmployeeSubtype} onChange={(e) => { setBean({ ...bean, "EmployeeSubtype": e }) }} />
                                </div>
                                <div className="col-md-6">
                                    <ARPRiskEditor idSelector={bean.ARPRisk} onChange={(e) => { setBean({ ...bean, "ARPRisk": e }) }} />
                                </div>
                                <div className="col-md-6">
                                    <label>Subtipo de Riesgo Laboral</label>
                                    <input type="number" className="form-control" value={bean.ARPRiskSubtype} onChange={(e) => { setBean({ ...bean, "ARPRiskSubtype": e.target.value }) }} />
                                </div>
                                <div className="col-md-6">
                                    <label>Fecha de Ultimo Exámen Medico/Ocupacional</label>
                                    <InputDate name="HealthTestDate" value={bean.HealthTestDate} setDate={(e) => { setBean({ ...bean, "HealthTestDate": e }) }} />
                                </div>
                                <div className="col-md-6">
                                    <label>Fecha de Ultimas Vacaciones</label>
                                    <InputDate name="LastVacDate" value={bean.LastVacDate} setDate={(e) => { setBean({ ...bean, "LastVacDate": e }) }} />
                                </div>
                                <div className="col-md-6">
                                    <label>Fecha de Ultima Dotación</label>
                                    <InputDate name="LastEndowmentDate" value={bean.LastEndowmentDate} setDate={(e) => { setBean({ ...bean, "LastEndowmentDate": e }) }} />
                                </div>

                            </div>
                        }
                    </CardTaps>
                </Modal>
            }

            {modalAddHistory.view &&
                <Modal modal={modalAddHistory} updateModal={setModalAddHistory} eventModal={create} >
                    <div className="row">
                        <div className="col-md-6">
                            <InputDate name="InitDate" label="Fecha de Inicio" value={bean.InitDate} setDate={(e) => { setBean({ ...bean, "InitDate": e }) }} />
                        </div>
                        <div className="col-md-6">
                            <InputDate name="UptoDate" label="Fecha de Fin" value={bean.UptoDate} setDate={(e) => { setBean({ ...bean, "UptoDate": e }) }} />
                        </div>
                        <div className="col-12">
                            <AppointmentEditor label="Nivel Salarial" idSelector={null} onChange={(e) => { setBean({ ...bean, "IDAppointment": e }) }} />
                        </div>
                        <div className="col-12">
                            <label>Valor del Salario</label>
                            <input type="number" className="form-control" value={null} onChange={(e) => { setBean({ ...bean, "LastSalary": e.target.value }) }} />
                        </div>
                    </div>
                </Modal>
            }

            {modalHistory.view &&
                <Modal modal={modalHistory} updateModal={setModalHistory} >
                    <ContractHistory _IDContract={bean.IDContract}></ContractHistory>
                </Modal>
            }

            {modalLinkCDP.view &&
                <Modal modal={modalLinkCDP} updateModal={setModalLinkCDP} >
                    <CDPLink _IDContract={bean.IDContract} />
                </Modal>
            }

            {modalExport.view &&
                <Modal modal={modalExport} updateModal={setModalExport} >
                    <ExportEmployeeContract />
                </Modal>
            }

            {modalImport.view &&
                <Modal modal={modalImport} updateModal={setModalImport} >
                    <ImportEmployeeContract />
                </Modal>
            }

            {modalUpdateParameters.view &&
                <Modal modal={modalUpdateParameters} updateModal={setModalUpdateParameters} eventModal={updateFunctionalArea} >
                    <div className="row">
                        <div className="col-12">
                            <label>Fecha desde la que aplica el cambio</label>
                            <InputDate name="fromDate" value={bean.fromDate} setDate={(e) => { setBean({ ...bean, "fromDate": e }) }} />
                        </div>
                    </div>
                    <div className="col-12">
                        <AppointmentEditor seeMoney={true} label="Nivel Salarial" idSelector={bean.IDAppointment} onChange={(e) => { setBean({ ...bean, "IDAppointment": e }) }} />
                    </div>
                    <div className="col-12">
                        <SelectFunctionalArea onChange={(e) => { setBean({ ...bean, "IDLnFunctionalArea": e.IDLn }) }} />
                    </div>
                </Modal>
            }

        </>
    )
}