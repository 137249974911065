import { th } from "date-fns/locale";

interface Props {
    titulo: any[];
}

export const HeadMultiSelectTable = ({ titulo }: Props) => {

    return (
        <thead>
            <tr className="trtable">
                
                {titulo.length > 0 &&
                    <>
                        <th></th>
                        {titulo.map((item: any, index: number) => {
                            return (
                                <>
                                    {item.visible &&
                                        <th key={index} className='align-middle'>{item.title}</th>
                                    }
                                </>
                            )
                        })}
                    </>
                    
                    
                }
            </tr>
        </thead>
    )
}