import { DataBean } from "../DataBean";

export class RecordBudget extends DataBean {

    public async getBudgetRecordForAdquisitionContract(idAdquisitionContract: number, adquisitionContractDate: any, date: any): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_getBudgetRecordForAdquisitionContract_Number_java.util.Date_java.util.Date", [idAdquisitionContract, adquisitionContractDate, date]);
    }

    public async releaseRecordBudgetFromAdquisitionContract(idDocument: number, documentDate: any, idEmployee: number): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "Boolean_releaseRecordBudgetFromAdquisitionContract_Number_java.util.Date_Number", [idDocument, documentDate, idEmployee]);
    }

    public async setAdquisitionContractToRP(idAdquisitionContract: number, adquisitionContractDate: any, idDocument: number, documentDate: any, idEmployee: number, createPdf: boolean): Promise<any> {
        return this.getCustomService(
            "QuickDataERPCore",
            "com.quickdataerp.bean.accountant.AccountantDocument_setAdquisitionContractToRP_Number_java.util.Date_Number_java.util.Date_Number_Boolean",
            [idAdquisitionContract, adquisitionContractDate, idDocument, documentDate, idEmployee, createPdf]);
    }


}
