import { useState, useEffect } from 'react';
import useDataBean from '../../../core/services/dataBean/useDataBean';
import { _FixedAssetLine } from '../../../core/services/dataBean/EntityCatalog';

interface Props {
    idSelector: number;
    idLine: number;
    onChange: Function;
    label?: string;
}

export const BranListForFixedAssetLineEditor = ({ idSelector, onChange, label, idLine }: Props) => {

    const { instance, bean, setBean, lista, setLista } = useDataBean(_FixedAssetLine)
    const [id, setID] = useState(null);

    useEffect(() => {
        if (idSelector) {
            setID(idSelector);
        }
    }, [idSelector])

    useEffect(() => {
        getLista();
    }, [idLine])

    const setValue = (selectedId: number) => {
        setID(selectedId);
        onChange(selectedId)
    };

    const getLista = () => {
        instance.getBrandListForFixedAssetLine(idLine).then(
            (resp) => {
                setLista(resp);
               //console.log(resp)
            }
        ).catch(
            err => {
               console.error(err)
            }
        )
    }


    return (
        <>
            <label>{label ?? "Clase de Producto"}</label>
            <select className="form-select" value={id} onChange={(e) => { setValue(parseInt(e.target.value)) }}>
                <option value={null} >...</option>
                {lista &&
                    lista.map((item: any, index: number) => {
                        return (
                            <option key={index} value={item.DataBeanProperties.Value} >{item.DataBeanProperties.Property}</option>
                        )
                    })
                }
            </select>
        </>
    )
}