import { useState } from "react";
import { Modal } from "../../../../../theme/Component/Modal";
import { ManageCashReibursementSelector } from "./ManageCashReibursementSelector";
import { toast } from "react-toastify";

interface Props {
    onChage: Function;
    label?: string;
    _idCashAccount?: number;
}
export const CashReibursementSelector = ({ onChage, label, _idCashAccount }: Props) => {

    const [item, setItem] = useState<any>({});

    const [modal, setModal] = useState<any>({ name: "Rembolsos de Caja Menor", tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-xl' });

    const selecionarItem = () => {
        if (item.IDCashReimbursement) {
            onChage(item)
            setModal({ ...modal, "view": false });
        } else {
            toast.error("Rembolso de Caja no Valido")
        }
    }

    return (
        <>
            <div className="row">
                <div className="col-lg-12">
                    {item.IDCashReimbursement ?
                        <label>Rembolso Seleccionado</label>
                        :
                        <label>{label ?? "Buscar Rembolso de Caja Menor"}</label>
                    }
                    <div className="form-control text-wrap" style={{ minHeight: '35px' }} onClick={() => setModal({ ...modal, "view": true })}>
                        {item.IDCashReimbursement || item.SecuenceName
                            ? `${item.IDCashReimbursement} - ${item.SecuenceName}`
                            : <span>Seleccionar...</span>
                        }
                    </div>
                </div>
            </div>


            {modal.view &&
                <Modal modal={modal} updateModal={setModal} eventModal={selecionarItem} onSubmitLabel="Seleccionar Rembolso" >
                    <ManageCashReibursementSelector idcashAccount={_idCashAccount} _onChange={(e) => { setItem(e) }} />
                </Modal>
            }
        </>
    )
}