import { useState, useEffect } from 'react';
import useDataBean from '../../../../core/services/dataBean/useDataBean';
import { _PaymentMethod } from '../../../../core/services/dataBean/EntityCatalogVdos';
import { _BankAccount } from '../../../../core/services/dataBean/EntityCatalog';




interface Props {
    idSelector: number;
    onChange: any;
    label?: string;
}

export const BankAccountSelect = ({ idSelector, onChange, label }: Props) => {

    const BankAccountCatalog = useDataBean(_BankAccount)
    const [lista, setLista] = useState<any>([])
    const [id, setID] = useState(0);

    useEffect(() => {
        if (idSelector >= 0) {
            setID(idSelector);
        }
    }, [])

    useEffect(() => {
        getLista();
    }, [])


    useEffect(() => {
        onChange(id);
    }, [id])

    const setValue = (e: any) => {
        setID(parseInt(e));
        onChange(id);
    }



    const getLista = () => {

        BankAccountCatalog.instance.getBankingAccountCatalog(null).then(
            (resp) => {
                setLista(resp)
            }
        )

    }

    return (
        <>
            <label>{label ?? ":"}</label>
            <select className="form-select" value={id} onChange={(e) => { setValue(e.target.value) }}>
                <option>...</option>
                {lista &&
                    lista.map((item: any, index: number) => {
                        return (
                            <option key={index} value={item.DataBeanProperties.IDBankingAccount} >{item.DataBeanProperties.BankingAccountName}</option>
                        )
                    })
                }
            </select>
        </>
    )
}