import { useEffect, useState } from "react"
import { YearSelector } from "../../../core/shared/inputs/YearSelector"
import { BasicPage } from "../../theme/Component/BasicPage"
import { SelectorDocumentFactory } from "./SelectorDocumentFactory"
import { PrintJson } from "../../theme/Component/PrintJson"
import { _DocumentFactory, _single } from "../../../core/services/dataBean/EntityCatalog"
import { BasicButton } from "../../theme/Component/BasicButton"
import useDataBean from "../../../core/services/dataBean/useDataBean"
import { DocumentViewer } from "../../theme/Component/DocumentViewer"
import { toast } from "react-toastify"
import useModal, { MODAL_LG } from "../../theme/Component/hooks/useModal"
import { Modal } from "../../theme/Component/Modal"
import { BasicTable } from "../../theme/Component/BasicTable"

export const BodegaDocumentos = () => {

    const { lista, setLista, instance } = useDataBean(_DocumentFactory);
    const [documentos, setDocuementos] = useState<any>([]);
    const modal = useModal("Documentos", MODAL_LG);

    const [bean, setBean] = useState<any>({
        Year: _single.getCurrenYear()
    });
    useEffect(()=>{
            if(modal.modal.view===false)
                setDocuementos([]);
    },[modal.modal.view])

    const buscarDocumento = () => {
        instance.getDocuments(bean.Year, bean.Mnemonic, bean.Consecutive, null).then(
            (resp: any) => {
                setLista(resp);
                if (resp.length === 1) {
                    getPdfAccountantDocument(resp[0].DataBeanProperties);
                }
                else if (resp.length === 0) {
                    toast.warning("No se encontraron documentos");
                }
            }
        ).catch(err => toast.error(err));
    }
    
    const getPdfAccountantDocument = (data: any) => {
        instance.getPdfAccountantDocument(data.IDDocument, data.Mnemonic, data.Since, _single.getAccountID()).then(
            (resp: any) => {                
                setDocuementos(resp.DocumentURL);
            }
        ).catch(err => toast.error(err));
        modal.open();
    }
     const head = [
        {title:'ID',property:'IDDocument',visible:false},
        {title:'Mnemonic',property:'Mnemonic',visible:true},
        {title:'Consecutivo',property:'Consecutive',visible:true},
        {title:'Estado',property:'DocumentStateName',visible:true},
        {title:'Fecha',property:'Since',visible:true},
    ]

    return (
        <BasicPage titulo="Bodega Documentos">
            <div className="row">
                <div className="col-md-6">
                    <YearSelector value={bean.Year} onChange={(e) => { setBean({ ...bean, "Year": e }) }} />
                </div>
                <div className="col-md-6">
                    <SelectorDocumentFactory label="Tipo de Documento" onChange={(e) => { setBean({ ...bean, "Mnemonic": e.Mnemonic }) }} />
                </div>
                <div className="col-md-6">
                    <label>Consecutivo</label>
                    <input type="number" name="consecutivo" className="form-control" value={bean.Consecutive} onChange={(e) => { setBean({ ...bean, "Consecutive": parseInt(e.target.value) }) }} />
                </div>
                <div className="col-md-6 mt-4">
                    <BasicButton icon="" eventClick={buscarDocumento}>Buscar</BasicButton>
                </div>
                {/* <PrintJson json={lista} /> */}
            </div>
            <div className="row">
                <div className="col">
                    <BasicTable body={lista} head={head} rowSet={(e)=>{getPdfAccountantDocument(e)}}></BasicTable>
                </div>
            </div>
            <Modal modal={modal.modal} updateModal={modal.setModal}>
                <div className="row">
                    <div className="col">
                        <DocumentViewer list={documentos} />
                    </div>
                </div>
            </Modal>
        </BasicPage>
    )
}