import { useEffect, useState } from "react";
import { BasicButton } from "./BasicButton"
import * as XLSX from 'xlsx';
import { DataBeanProperties } from '../../../core/services/model/server-response.interface';
import { Singleton } from "../../../core/services/Singleton";
import { useDashboardStore } from "../../pages/hooks/useDashboardStore";

interface Props {
    title: any;
    data: any;
    onExport: Function;
    optionIcon?:string;
    customName?:string;
}
export const ExportXLSL = ({ title, data, onExport ,optionIcon,customName}: Props) => {

    const single = Singleton.getInstance()
    const { setLoading } = useDashboardStore();
    const [miData, setMiData] = useState<any>([]);

    useEffect(() => {
        let tmp: any = [];
        try {
            data.forEach((element: any) => {

                let row = element.DataBeanProperties;
                let row1: any = {};
                title.forEach((ti: any) => {
                    row1[ti.title] = row[ti.property];
                });
                tmp.push(row1);

            });

            setMiData(tmp);
        } catch (error) {

        }
    }, [data])

    const exportToExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(miData);
    
        Object.keys(worksheet).forEach((cell) => {                        
            if (typeof worksheet[cell].v === 'string') {
                // Validar si la cadena parece una fecha válida
                const dateValue = new Date(worksheet[cell].v);
                
                // Validar que el valor es una fecha válida (no un número)
                if (!isNaN(dateValue.getTime()) && worksheet[cell].v.includes('-')) {
                    worksheet[cell].t = 'n'; 
                    worksheet[cell].z = 'dd-mm-yyyy';
                    
                    // Convertir la fecha al formato numérico de Excel
                    worksheet[cell].v = (dateValue.getTime() - new Date('1899-12-30').getTime()) / (24 * 60 * 60 * 1000);
                }
            }
        });
    
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Hoja1');
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const dataBlob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const fileName = single.basicName(customName ? customName : 'reporte');
    
        onExport(fileName);
    
        const link = document.createElement('a');
        const url = URL.createObjectURL(dataBlob);
        link.href = url;
        link.download = fileName;
        link.click();
        URL.revokeObjectURL(url);
    };
    
    
    



    return (
        <>

            {single.canExport() &&
                <button title='Exportar XLSX' type="button" className="btn btn--primary btn-icon " onClick={exportToExcel}>
                    <i className={optionIcon ? `${optionIcon} m-2 icontable icontable` : 'ri-profile-line icontable icontable'}></i>
                </button>
            }
        </>
        // <BasicButton icon="" eventClick={exportToExcel}>Exportar</BasicButton>
    )
}