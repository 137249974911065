import { useState } from "react";
import { PrintJson } from "../../theme/Component/PrintJson";
import { BasicButton } from "../../theme/Component/BasicButton";
import { toast } from "react-toastify";

interface Props {
    data: any;
    event: any;
}

export const ValueApropRender = ({ data, event }: Props) => {
    const [value, setValue] = useState<number>(null)

    const validatecampos = () => {
        if (value > data.BudgetValue) {
            toast.error('El valor de la apropiación no puede ser mayor al saldo del Rubro Presupuestal ')
        }
        else {
            let row = data;
            row.Value = value;
            event(row)
        }
    }
    return (
        <>

            <label>Valor de la Apropiación</label>
            <input type="number" name="Value" className="form-control" value={value} onChange={(e) => { setValue(parseInt(e.target.value)) }} />
            <div className="col-md-6 mt-3">

                <BasicButton icon="ri-search-line" eventClick={validatecampos} >Selecionar</BasicButton>

            </div>
        </>
    )
}