import { useEffect, useState } from "react";
import { _single, _TaxCode } from "../../../../../core/services/dataBean/EntityCatalog";
import useDataBean from "../../../../../core/services/dataBean/useDataBean";
import { BasicButton } from "../../../../theme/Component/BasicButton"
import { FileInput } from "../../../../theme/Component/FileInput"
import { TaxCodeSchemeEditor } from "../../TaxCodeScheme/TaxCodeScheme.editor";
import { ImportXLSX } from "../../../../theme/Component/ImportXLSX";

interface Props {
    closeModal: Function;
}


export const ImportFileTaxCode = ({ closeModal }: Props) => {

    const taxCode = useDataBean(_TaxCode);
    const [document, setDocument] = useState<any>({})
    const [codeScheme, setCodeScheme] = useState<number>(null);
    const [lista, setLista] = useState<any>([]);
    const [importado, setImportado] = useState<any>([]);
    const [message, setMessage] = useState<string>('');

    useEffect(() => { setDocument({}) }, [])

    const createItem = async (bean: any) => {
        try {
            const resp = await taxCode.instance.addTaxCode(codeScheme, bean.Code, bean.Name, bean.Description);
            
            // Actualizar el estado de la lista de manera síncrona
            setImportado((prevList) => {
                const newList = [...prevList, resp];
                
                // Si el nuevo tamaño es múltiplo de 10, actualizar el mensaje
                if (newList.length % 10 === 0) {
                    setMessage(`Se han cargado ${newList.length} elementos.`);
                }
                
                return newList;
            });
           //console.log(resp);
            
            return resp; // Retornar la respuesta para saber que se completó
        } catch (error) {
            console.error('Error al crear el item:', error);
            throw error;
        }
    };

    const importDocument = async () => {

        const newArray = lista.slice(1);
        for(const element of newArray)
        {
            let b = {
                IDTaxCodeScheme:codeScheme,
                Code: element[0],
                Name: element[1],
                Description: element[2],
            }
            await createItem(b);
        }
    }

   


    return (
        <div className="row">
            <div className="col-12">
                <TaxCodeSchemeEditor idSelector={codeScheme} onChange={(e) => { setCodeScheme(e) }} />
            </div>
            <div className="col-12">
                {/* <FileInput docAcept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" label="Cargar Documento de Codigos tipo excel" onCreate={(document) => { setDocument(document) }} /> */}
                <ImportXLSX lodaData={setLista} />
            </div>
            <div className="col-md-12">
                <BasicButton icon="" eventClick={() => { importDocument() }}>Confirmar</BasicButton>

                {importado.length > 0 &&
                    <strong>Cargando <small>{message}</small></strong>

                }


            </div>

        </div>
    )
}