import { useState, useEffect } from 'react';
import { BpmService } from '../../../../core/services/BpmService';
import { TalentService } from '../../../../core/services/TalentService';
import { Singleton } from '../../../../core/services/Singleton';

interface Props {
    idSelector: number;
    onChange: any;
    label?: string;
}

export const SelectTypeTest = ({ idSelector, onChange, label }: Props) => {

    const services = new TalentService();
    const single = Singleton.getInstance();
    const [lista, setLista] = useState([]);
    const [id, setID] = useState(idSelector ?? 0);

    useEffect(() => {
        getLista();
    }, [])

    useEffect(() => {
        onChange(id);
    }, [id])

    const setValue = (e: any) => {
        setID(parseInt(e));
        onChange(id);
    }

    const renderName = (value: number) => {
        let type = '';
        if (value === 1) {
            type = 'Respuesta Unica'
        } else if (value === 2) {
            type = 'Respuestas Ponderadas'
        }
        return type;
    }

    const getLista = () => {
        const x = {
            "State": 1
        }
        single.spinner(true);
        services.getTestCatalogPorPropiedades(x, null).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue) {
                    let x = resp.DataBeanProperties.ObjectValue;
                    x.forEach((test: any) => {
                        test.DataBeanProperties.TypeName = renderName(test.DataBeanProperties.TestType)
                    });
                    setLista(x)
                }
                single.spinner(false)
            }
        )
    }

    return (
        <>
            <label>{label ?? "Prueba seleccionada"}</label>
            <select className="form-control" value={id} onChange={(e) => { setValue(e.target.value) }}>
                <option value={0}>...</option>
                {lista &&
                    lista.map((item: any) => {
                        return (
                            <>
                                <option key={item.DataBeanProperties.IDTest} value={item.DataBeanProperties.IDTest} >{item.DataBeanProperties.Name}</option>
                            </>)
                    })
                }
            </select>
        </>
    )
}