import { DataBean } from "../DataBean";

export class ProcedureImp extends DataBean{

    PENDING_FOR_INPUT=0;
    FOR_VERIFY = 1;
    RETURNED = 2;
    VERIFIED = 3;
    SUBMITTED = 4;
    REJECTED = 5;

    public async getActualProcedureActionByProcedureImp(idProcedureImp:number): Promise<any> {
        return this.getCustomService("BpmService", "java.util.List_getActualProcedureActionByProcedureImp_Number", [idProcedureImp]);
    }

    

}