import { useEffect, useState } from "react";
import { _PaymentRequirement } from "../../../core/services/dataBean/EntityCatalogVdos";
import useModal, { MODAL_md, MODAL_SM } from "../../theme/Component/hooks/useModal";
import useDataBean from "../../../core/services/dataBean/useDataBean";
import { Singleton } from "../../../core/services/Singleton";
import { toast } from "react-toastify";
import { ModalConfirm } from "../../theme/Component/ModalConfirm";
import { Modal } from "../../theme/Component/Modal";
import { BasicTable } from "../../theme/Component/BasicTable";
import { ListMenu } from "../../theme/Component/ListMenu";
import { SelectModality } from "../../contract/select/SelectModality";
import { BasicPage } from "../../theme/Component/BasicPage";
import { SelectTrdsID } from "./TrdID/SelectTrdsID";
import { BasicSwitch } from "../../theme/Component/BasicSwitch";
import { FileInput } from "../../theme/Component/FileInput";
import { SelectModalityNewSer } from "../../contract/select/SelectModalityNewSer";
import { FileService } from "../../../core/services/FileService";

export const PaymentRequirement = () => {

    const PaymentRequirement = useDataBean(_PaymentRequirement);
    const [bean, setbean] = useState<any>({})
    const single = Singleton.getInstance();
    const [lista, setLista] = useState<any>([]);
    const fileService = new FileService();
    const [Modality, setModality] = useState<any>({});
    const [modal, setModal] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-md' });
    const [modalC, setModalC] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });
    const modalConfirm = useModal("Orden", MODAL_SM);
    const modalEditar = useModal("Orden", MODAL_md);

    useEffect(() => {

        getLista()

    }, [Modality])

    const getLista = () => {

        PaymentRequirement.instance.getPaymentRequirementCatalogPorPropiedadesRender({ State: 1, IDHiringModality: Modality }, null).then(
            (resp: any) => {
                if (resp) {
                    setLista(resp)
                   //console.log(resp)
                    // let tmp = resp.DataBeanProperties.ObjectValue.DataBeanProperties;
                    // tmp.forEach((element: any) => {
                    //     let url = fileService.getUrlFile(
                    //         element.DataBeanProperties.MediaContext, element.DataBeanProperties.Media
                    //     );
                    //     element.DataBeanProperties.URL = `<a href='${url}' target='_blank'>${element.DataBeanProperties.Media}</a>`

                    // });

                    // setLista(tmp)
                }
            }
        ).catch(err =>console.error(err))

    }

    const create = () => {

        modalEditar.open();

        PaymentRequirement.setBean({
            State: 1,
            IDHiringModality: Modality

        });


    }


    const deleteItem = () => {
        PaymentRequirement.bean.State = 2;
       //console.log(bean);
        editaItem();
        modalConfirm.close();
    }



    const editaItem = () => {
       //console.log(PaymentRequirement.bean);
        PaymentRequirement.update().then(
            (resp: any) => {
                getLista();
                modalEditar.close();

            }
        ).catch(err => {
            toast.error(err);
        })

    }





    const head = [
        { title: 'ID', property: 'IDPaymentRequirement', visible: true },
        { title: 'Nombre Documento', property: 'Name', visible: true },
        { title: 'Es un formato', property: 'IsFormat', visible: true },
        { title: 'Archivo', property: 'FormatMedia', visible: false },
        { title: 'Trd', property: 'NameTRD', visible: true },
        { title: 'Modalidad', property: 'NameModality' },
        { title: 'Descripción', property: 'Description', visible: true },



    ]

    const iconButtonsItem = [
        { nombre: 'Agregar', visible: single.canCreate(), icono: 'ri-file-add-line', evento: create }
    ]
    const listaBotones = () => {
        const botones = [

            { titulo: 'Editar', icono: 'ri-edit-2-line', visible: single.canEdit(), evento: () => { modalEditar.setModal({ ...modalEditar.modal, "view": true }) } },
            { titulo: 'Eliminar', icono: 'ri-delete-bin-line', visible: single.canDelete(), evento: () => { setModalC({ ...modalC, "view": true }) } },

        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }


    return (

        <div className="row">
            <BasicPage titulo="Requisitos de pago">
                <div className="col-md-6">
                    <SelectModalityNewSer idSelector={Modality} onChange={(e) => { setModality(e) }} />

                </div>
                <div className="col-md-12">

                    <BasicTable body={lista} head={head} rowSet={PaymentRequirement.setBean} listButtons={listaBotones()} iconButtons={iconButtonsItem}></BasicTable>

                    <Modal modal={modalEditar.modal} updateModal={modalEditar.setModal} eventModal={editaItem}>
                        <div className="row">
                            <div className="col-md-6">
                                <label>Nombre</label>
                                <input type="string" className="form-control" name="Name" value={PaymentRequirement.bean.Name} onChange={PaymentRequirement.handleChange} />
                            </div>
                            <div className="col-md-6">
                                <BasicSwitch estado={PaymentRequirement.bean.IsFormat} label="Es un formato ?" eventChange={(e: any) => {
                                    PaymentRequirement.setBean({ ...PaymentRequirement.bean, "IsFormat": e })
                                }}></BasicSwitch>
                            </div>
                            <div className="col-md-12">
                                {/* Condicional para mostrar FormatMedia si IsFormat es true */}
                                {PaymentRequirement.bean.IsFormat && (
                                    <div className="col-md-6">
                                        <FileInput
                                            Media={PaymentRequirement.bean.Media}
                                            MediaContext={PaymentRequirement.bean.MediaContext}
                                            directory="requirement"
                                            label="Cargar un archivo"
                                            onCreate={({ MediaContext, Media }: any) => {
                                                PaymentRequirement.setBean({
                                                    ...PaymentRequirement.bean,
                                                    FormatMedia: JSON.stringify({
                                                        MediaContext: MediaContext,
                                                        Media: Media,
                                                    }),
                                                });
                                            }}
                                        />
                                    </div>
                                )}

                                {/* <FileInput
                                    Media={PaymentRequirement.bean.Media}
                                    MediaContext={PaymentRequirement.bean.MediaContext}
                                    directory='requirement'
                                    label='Cargar un archivo'
                                    onCreate={({ MediaContext, Media }: any) => { PaymentRequirement.setBean({ ...PaymentRequirement.bean, "FormatMedia": JSON.stringify({ MediaContext: MediaContext, Media: Media }) }); }}
                                ></FileInput> */}
                            </div>
                            <div className="col-md-12">
                                <SelectTrdsID edit={false} trdIDLn={PaymentRequirement.bean.IDLnTrd} onChange={(e) => { PaymentRequirement.setBean({ ...PaymentRequirement.bean, "IDLnTrd": e.IDLn }) }} />

                            </div>
                            <div className="col-md-12">
                                <label>Descripción</label>
                                <textarea name="Description" className="form-control" rows={5} value={PaymentRequirement.bean.Description} onChange={PaymentRequirement.handleChange} />
                            </div>



                        </div>
                    </Modal>
                    <ModalConfirm modal={modalC} updateModal={setModalC} eventModal={deleteItem}>
                        <strong>Eliminar item</strong>
                    </ModalConfirm>
                </div>
            </BasicPage>


        </div>

    )
}