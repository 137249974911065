import { useEffect, useState } from "react";
import { ContractService } from "../../../../core/services/ContractService";
import { Singleton } from "../../../../core/services/Singleton";
import { _single } from "../../../../core/services/dataBean/EntityCatalog";
import { PrintJson } from "../../../theme/Component/PrintJson";
import { BasicSwitch } from "../../../theme/Component/BasicSwitch";
import { MASK_MONEY } from "../../../theme/Component/BasicTable";

interface Props {
    idAdquisitionContract: number;
    idAdquisitionItem: number;
    updateParent?:Function;
}
export const ContractArticles = ({ idAdquisitionContract, idAdquisitionItem, updateParent}: Props) => {

    const single = Singleton.getInstance();
    const service = new ContractService();
    const [lista, setLista] = useState<any>([]);
    const [listaCheck, setListaCheck] = useState<any>([]);
    const [listaCheck2, setListaCheck2] = useState<any>([]);


    useEffect(() => {
        if (idAdquisitionItem > 0) {

            getContractArticleItemByIDAdquisitionItem();
        }

    }, [idAdquisitionItem])


    const getContractArticleItemByIDAdquisitionItem = () => {
        single.spinner(true);
        service.getContractArticleItemByIDAdquisitionItem(idAdquisitionItem, idAdquisitionContract).subscribe(
            (resp: any) => {
               //console.log(resp);
                
                if (resp.DataBeanProperties.ObjectValue) {
                    let tmp = resp.DataBeanProperties.ObjectValue;
                    tmp.forEach(element => {
                        if (element.DataBeanProperties.IDAdquisitionContract) {
                            element.DataBeanProperties.Active = true;
                        } else {
                            element.DataBeanProperties.Active = false;
                        }

                    });
                    setLista(tmp);                    
                    updateAdquisitionContractValueByItem();
                }
                single.spinner(false);
            }
        );
    }

    const updateAdquisitionContractValueByItem=()=>{
        service.updateAdquisitionContractValueByItem(idAdquisitionContract).subscribe(
            (resp:any)=>{
                
                if(updateParent)
                    updateParent(resp);
            }
        )
    }





    return (
        <div className="row">
            <div className="col">
                {/* <div className="list-group">
                        <PrintJson json={lista}/>
                </div> */}

            </div>
            <div className="col-md-12">
                <table className="table table-stripted">
                    <thead>
                        <th>Contratado </th>
                        <th>Articulo</th>
                        <th>Valor Estimado</th>
                        <th>Valor Contrato</th>
                        <th>Opcion</th>


                    </thead>
                    <tbody>
                        {lista.map((item: any, index: number) => {
                            return (
                                <ContractArticleItem item={item.DataBeanProperties} idAdquisitionContract={idAdquisitionContract} updateElement={getContractArticleItemByIDAdquisitionItem} />
                            )
                        })}
                    </tbody>
                </table>
            </div>
         
        </div>
    )
}
interface PropsItem {
    item: any;
    updateElement: any;
    idAdquisitionContract: number;
}
export const ContractArticleItem = ({ item, updateElement, idAdquisitionContract }: PropsItem) => {

    const service = new ContractService();
    const [miItem, setMiItem] = useState<any>({});

    useEffect(() => {
        let copy = item;
        if(item.ContractedValue === 0)
            copy.ContractedValue = item.EstimatedUnitaryValue * item.Quantity;
        copy.active = item.ContractedValue > 0 ? true : false;
        setMiItem(copy);
    }, [])
    useEffect(() => {
       
        if(miItem.Active == false)
        {
            updateValue();
        }

    }, [miItem.Active])


    const updateValue = () => {
        let val = item.EstimatedUnitaryValue * item.Quantity;

        if (miItem.Active == false) {
            service.deleteContractArticleItem(miItem.IDContractArticleItem).subscribe(
                (resp: any) => {
                    updateElement();
                }
            )

        } else {
            let copy: any = {
                IDContractArticleItem: miItem.IDContractArticleItem > 0 ? miItem.IDContractArticleItem : null,
                IDAdquisitionArticle: miItem.IDAdquisitionArticle,
                ContractedValue: miItem.ContractedValue,
                IDAdquisitionContract: idAdquisitionContract,
                IDArticleItem: miItem.IDAdquisitionItem,
                State: 1
            };

            service.updateContractArticleItem(copy).subscribe(
                (resp: any) => {
                    if (resp.DataBeanProperties.ObjectValue) {
                        let m1 = resp.DataBeanProperties.ObjectValue;
                        updateElement();
                    }
                }
            )
        }

    }


    return (

        <tr>
            <td>
                <BasicSwitch estado={miItem.Active} eventChange={(e) => { setMiItem({ ...miItem, "Active": e }) }} />
            </td>
            <td>{miItem.Description}</td>
            <td>
                <div dangerouslySetInnerHTML={{ __html: _single.formatFieldMask(miItem.EstimatedUnitaryValue*miItem.Quantity, MASK_MONEY) }}></div>
            </td>
            <td>
                <input disabled={!miItem.Active} style={{ minWidth: '150px' }} type="number" name="val" id="" className="form-control" value={miItem.ContractedValue}
                    onChange={(e) => { setMiItem({ ...miItem, "ContractedValue": e.target.value }) }} />
            </td>

            <td>
                <i className="ri-save-2-line diskSave cursor" title="Guardar" onClick={updateValue}></i>
            </td>
        </tr>



    )
}