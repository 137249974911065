import { DataBean } from "../DataBean";

export class ViaticumPaymentsTypeType extends DataBean {


    public async getViaticumPaymentsTypeTypeCatalog(): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_getViaticumPaymentsTypeTypeCatalog_Number", [null]);
    }
    public async updateViaticumPaymentsTypeType(bean: any): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "com.quickdataerp.bean.employees.ViaticumPaymentsType_updateViaticumPaymentsTypeType_com.quickdataerp.bean.employees.ViaticumPaymentsType", [
            {
                "DataBeanProperties": bean,
                "DataBeanName": "com.quickdataerp.bean.employees.ViaticumPaymentsType"
            }
        ]);
    }


    public async deleteViaticumPaymentsTypeType(id:number): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "void_deleteViaticumPaymentsTypeType_Number", [id]);
    }
}