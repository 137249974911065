import { DataBean } from "../DataBean";

export class ArticleClassType extends DataBean {

    public async getArticleClassTypeCatalog(type:any): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_getArticleClassTypeCatalog_Number", [type]);
    }

   



}