import { DataBean } from "../DataBean";

export class HiringModality extends DataBean {
    
    


    public async getHiringModalityRender(rows:any,props): Promise<any> {
        return this.getCustomService("FinanceCore", "java.util.List_getHiringModalityRender_Number_java.util.Map", [rows,props]);
    }

}