import { useEffect, useState } from "react";
import { FinanceService } from '../../../../core/services/FinanceService';
import { useDashboardStore } from "../../../pages/hooks/useDashboardStore";
import { ButtonAccountantTree } from "./ButtonAccountantTree";
import useDataBean from "../../../../core/services/dataBean/useDataBean";
import { _AccountantID, _single } from "../../../../core/services/dataBean/EntityCatalog";


interface Tree {
    idChild: number;
    edit?: boolean;
    idAccountantIDType: number;
}
export const AccountantTreeChilds = ({ idChild, idAccountantIDType, edit }: Tree) => {

    const service = new FinanceService();
    const accountantTree = useDataBean(_AccountantID);

    const { setLoading } = useDashboardStore();
    const [lista, setLista] = useState([]);

    const [idchild, setIDchild] = useState(idChild);
    const [count, setCount] = useState<number>(0);



    useEffect(() => {

        if (idchild > 0) {
            getListatree(idChild);
        }
        else {
            setLista([]);
        }


    }, [idchild])

    const getListatree = (idArbol: any) => {

        let temp = _single.getCacheItem('AccountantIDTree_'+idArbol);
        if (temp) {
            setLista(temp);
        } else {

            accountantTree.instance.loadAccountantIDTree(idArbol).then(
                (resp: any) => {
                    
                    setLista(resp.Childs);
                    _single.setCacheItem('AccountantIDTree_'+idArbol,resp.Childs)
                }
            )
        }
    };


    return (
        <>
            {/* <PrintJson json={util}/> */}
            <ul className="list-group">
                {
                    lista.length > 0 &&
                    lista.map((item: any) => {
                        return (
                            <li className="list-group-item itemh" style={{ border: '0' }} key={item.EnvolvedObject.DataBeanProperties.IDLn} >
                                {edit &&
                                    <ButtonAccountantTree
                                        id={item.EnvolvedObject.DataBeanProperties.IDLn}
                                        idAccountantIDType={idAccountantIDType}
                                        bean={item.EnvolvedObject.DataBeanProperties}
                                        updateEvent={() => { getListatree(idChild); }}
                                    />
                                }

                                <strong className={idchild === item.EnvolvedObject.DataBeanProperties.IDLn ? 'cursor treeselect' : 'cursor'}
                                    onClick={() => {
                                        localStorage.setItem('accountantTree', JSON.stringify(item.EnvolvedObject.DataBeanProperties));

                                        if (item.EnvolvedObject.DataBeanProperties.IDLn === idchild)
                                            setIDchild(0);
                                        else
                                            setIDchild(item.EnvolvedObject.DataBeanProperties.IDLn);

                                    }}
                                >
                                    <strong style={{ marginLeft: '15px' }}>{item.EnvolvedObject.DataBeanProperties.AccountantIDViewCode} </strong>
                                    <small>{item.EnvolvedObject.DataBeanProperties.Name}</small>

                                </strong>
                                <span className="badge bg-success ms-1">{item.ChildrenCount}</span>
                                {idchild === item.EnvolvedObject.DataBeanProperties.IDLn &&

                                    <AccountantTreeChilds idAccountantIDType={idAccountantIDType} edit={edit} idChild={idchild} />
                                }
                            </li>
                        )
                    })
                }
            </ul>
        </>
    )
}