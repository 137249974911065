import { useEffect, useState } from "react";
import { AdminService } from "../../../../../core/services/AdminService";
import { Singleton } from "../../../../../core/services/Singleton";
import { useDashboardStore } from "../../../../pages/hooks/useDashboardStore";
import { ListMenu } from "../../../../theme/Component/ListMenu";
import { BasicPage } from "../../../../theme/Component/BasicPage";
import { BasicTable } from "../../../../theme/Component/BasicTable";
import { ModalConfirm } from "../../../../theme/Component/ModalConfirm";
import { Modal } from "../../../../theme/Component/Modal";
import { FormParam } from "./FormParam.advantage";

const service = new AdminService();
const single = Singleton.getInstance()

export const FormType = () => {

    const { setLoading } = useDashboardStore();
    const [lista, setLista] = useState([]);
    const [bean, setBean] = useState<any>({});
    const [modalpropiedadesform, setModalpropiedadesform] = useState<any>({ name: 'Propiedades', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modal, setModal] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalConfirm, setModalConfirm] = useState<any>({ name: 'Confirmar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });
    const [formulario, setFormualrio] = useState<any>({});
    // const [groupSelected, setGroupSelected] = useState('')
    const [modalViasulizer, setModalVisualizer] = useState<any>({ name: '', tabIndex: -1, view: false, btnClose: true, btnSubmit: false, format: 'modal-lg' });
    const idBean = 'IDFormType';

    const head = [
        { title: 'ID', property: idBean },
        { title: 'Código', property: 'CodeForm' },
        { title: 'Nombre', property: 'Name' },
        { title: 'Descripción', property: 'Description' },
        { title: 'Código de Grupo', property: 'GroupCode' },

    ]

    useEffect(() => {
        getLista();
    }, [])

    // useEffect(() => {
    //     if (groupSelected !== '') {
    //         getListaFiltro();
    //     } else
    //         getLista();
    // }, [groupSelected])

    useEffect(() => {
        getLista();
    }, [])

    const setRowBean = (bean: any) => {
       //console.log("Row : ", bean);
        setBean(bean);

    }

    // const getListaFiltro = () => {
    //     setLoading(true);
    //     service.getFormTypeCatalogPorPropiedadesLike(groupSelected).subscribe(
    //         (resp: any) => {
    //             if (resp.DataBeanProperties.ObjectValue) {
    //                 setLista(resp.DataBeanProperties.ObjectValue);
    //             }
    //             setLoading(false);
    //         }
    //     );
    // }

    const getLista = () => {
        setLoading(true);
        service.getGenericFormTypeCatalogPorPropiedad(null, null, null).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue) {
                    setLista(resp.DataBeanProperties.ObjectValue);
                }
                setLoading(false);
            }
        );
    }

    const agregarItem = () => {
        let b = {
            Name: '',
            Description: '',
            State: 1,
            Since: single.getCurrenDate(),
            CodeForm: 0,
            GroupCode: ''
        }
        setBean(b);
        setModal({ ...modal, 'view': true });
    }

    const eliminarItem = () => {
        bean.State = 2;
       //console.log(bean);
        updateItem();
        setModalConfirm({ ...modalConfirm, 'view': false });
    }

    const verpropiedades = () => {
        setModalpropiedadesform({ ...modalpropiedadesform, 'view': true });
    }

    const updateItem = () => {
        setModal({ ...modal, 'view': false });
        setLoading(true);
        service.updateGenericFormType(bean).subscribe(
            (resp: any) => {
                setLoading(false);
                if (resp.DataBeanProperties.ObjectValue) {
                    getLista();
                    if (bean.idBean > 0) {
                        single.update(bean);
                    } else {
                        single.create(bean);
                    }
                }
            }
        );
    }

    const iconButtonsItem = [{ nombre: 'Agregar', visible: single.canCreate(), icono: 'ri-file-add-line', evento: agregarItem }]

    const listaBotones = () => {
        const botones = [
            { titulo: 'Editar', icono: 'ri-file-list-line', visible: true, evento: () => { setModal({ ...modal, "view": true }) } },
            { titulo: 'Eliminar', icono: 'ri-file-user-line', visible: true, evento: () => { setModalConfirm({ ...modalConfirm, "view": true }) } },
            { titulo: 'Propiedades', icono: 'ri-file-user-line', visible: true, evento: () => { setFormualrio(bean); setModalpropiedadesform({ ...modalpropiedadesform, "view": true }); } },
            { titulo: 'Visualizar', icono: 'ri-eye-line', visible: true, evento: () => { setFormualrio(bean); setModalVisualizer({ ...modalViasulizer, name: bean.Name, view: true }) } },
        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }

    return (
        <>
            <BasicPage titulo="Formularios">
                <div className="row">
                    {/* <div className="col-6">
                        <SelectorTypeForm value={groupSelected} onChange={(e: string) => setGroupSelected(e)} />
                    </div> */}
                    <div className="col-md-12 p-2">
                        <BasicTable listButtons={listaBotones()} iconButtons={iconButtonsItem} head={head} body={lista} rowSet={setRowBean}></BasicTable>
                    </div>
                </div>
            </BasicPage>

            {modalConfirm &&
                <ModalConfirm modal={modalConfirm} updateModal={setModalConfirm} eventModal={eliminarItem}>
                    {bean.Name}
                </ModalConfirm>
            }

            {modal.view &&
                <Modal modal={modal} updateModal={setModal} eventModal={updateItem}>
                    <div className="row">
                        <div className="col-md-6">
                            <label className="form-label">Código <small>(Identificación)</small></label>
                            <input type="number" className="form-control" value={bean.CodeForm} onChange={(e) => { setBean({ ...bean, 'CodeForm': e.target.value }) }} />
                        </div>
                        <div className="col-md-6">
                            <label className="form-label">Nombre</label>
                            <input type="text" className="form-control" value={bean.Name} onChange={(e) => { setBean({ ...bean, 'Name': e.target.value }) }} />
                        </div>
                        <div className="col-md-12">
                            <label className="form-label">Grupo de Formulario</label>
                            <input type="text" className="form-control" value={bean.GroupCode}
                                onChange={(e) => { setBean({ ...bean, 'GroupCode': e.target.value }) }} />
                        </div>
                        <div className="col-md-12">
                            <label className="form-label">Descripción</label>
                            <textarea className="form-control" value={bean.Description} onChange={(e) => { setBean({ ...bean, 'Description': e.target.value }) }}></textarea>
                        </div>

                    </div>
                </Modal>}
            {modalpropiedadesform.view &&
                <Modal modal={modalpropiedadesform} updateModal={setModalpropiedadesform} eventModal={verpropiedades}>
                    <FormParam idSelector={formulario.IDFormType} CodePar={formulario.CodeForm} />
                </Modal>
            }
            {/* {modalViasulizer.view &&
                <FormVisualizer formCode={bean.CodeForm} idData={0} modal={modalViasulizer} setModal={setModalVisualizer} />
            } */}
        </>
    )
}