import { useState, useEffect } from 'react';
import { BpmService } from '../../../../core/services/BpmService';

interface Props {
    idSelector: number;
    onChange: any;
    titulo: string;
    _idProcedure: number;
}

export const SelectorProcedureDcoumentWithJsonFormAndJsonService = ({ titulo, idSelector, onChange, _idProcedure }: Props) => {
    const service = new BpmService();
    const [lista, setLista] = useState([]);
    const [id, setID] = useState(undefined);

    useEffect(() => {
        setID(idSelector);
        getLista();
    }, [idSelector, _idProcedure]);

    const setValue = (e: any) => {
        setID(parseInt(e));
        onChange(parseInt(e));
    }

    const getLista = () => {
        service.getProcedureDocumentWithJsonFormAndJsonService(_idProcedure).subscribe(
            (resp: any) => {
                setLista([]);
                if (resp.DataBeanProperties.ObjectValue) {
                    setLista(resp.DataBeanProperties.ObjectValue);
                }
               //console.log("DataAcces", lista);
            }
        );
    }

    return (
        <>
            <label>{titulo}</label>
            <select className="form-control" value={id} onChange={(e) => { setValue(e.target.value) }}>
                <option value={null}>...</option>
                {lista &&
                    lista.map((item: any, index: number) => (
                        <option key={index} value={item.DataBeanProperties.IDDocument}>
                            {item.DataBeanProperties.IDDocument}-{item.DataBeanProperties.Name}
                        </option>
                    ))
                }
            </select>
        </>
    );
}
