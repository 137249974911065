import { useEffect, useState } from "react";
import { _CompetitionType, _Entailment, _MacroProcess, _SubMacroProcess } from "../../../../core/services/dataBean/EntityCatalog";

import { Singleton } from "../../../../core/services/Singleton";
import { toast } from "react-toastify";
import { ListMenu } from "../../../theme/Component/ListMenu";
import { BasicPage } from "../../../theme/Component/BasicPage";
import { BasicTable, MASK_BOOLEAN_ICON, MASK_NUMBER } from "../../../theme/Component/BasicTable";
import { Modal } from "../../../theme/Component/Modal";
import { ModalConfirm } from "../../../theme/Component/ModalConfirm";
import useDataBean from "../../../../core/services/dataBean/useDataBean";
import { PrintJson } from "../../../theme/Component/PrintJson";
import { PrintObjectJson } from "../../../theme/Component/PrintObjectJson";
import { _PaymentAccount } from "../../../../core/services/dataBean/EntityCatalogVdos";
import { SelectAccountantTree } from "../../../contabilidad/catalogo-cuentas/AccountantTree/SelectAccountantTree";
import { SelectBudgetID } from "../../../admin/configuracion/BudgetID/SelectBudgetID";

interface Props {
    IDPaymentAccount: number;
}

export const PaymentAccountItem = ({ IDPaymentAccount }: Props) => {

    const PaymentAccountItem = useDataBean(_PaymentAccount);

    const single = Singleton.getInstance();

    const [lista, setLista] = useState<any>([]);
    const [modal, setModal] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-md' });
    const [modalC, setModalC] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });

    useEffect(() => {
        getLista();
    }, [IDPaymentAccount])

    const getLista = () => {

        PaymentAccountItem.instance.getPaymentAccountItemByPaymentAccount(IDPaymentAccount).then(
            (resp: any) => {
                if (resp) {
                    setLista(resp)
                   //console.log(resp)
                }
            }
        )

    }

    const deleteItem = () => {
        PaymentAccountItem.deleteItemVoid(PaymentAccountItem.bean.IDPaymentAccount).then(
            (resp: any) => {
                getLista();
                setModalC({ ...modalC, "view": false });
            }
        )

    }


    const create = () => {
        PaymentAccountItem.setBean({
            IDPaymentAccount: IDPaymentAccount
        });
        setModal({ ...modal, "view": true });
    }

    const editaItem = () => {
        ////console.log(SubMacroProcess.bean);
        PaymentAccountItem.update().then(
            (resp: any) => {
                getLista();
                setModal({ ...modal, "view": false });
            }
        ).catch(err => {
            toast.error(err);
        })

    }

    const head = [
        { title: 'ID', property: 'IDPaymentAccountItem', visible: true, mask: MASK_NUMBER },
        { title: 'Cuenta Contable', property: 'AccountantID', visible: true, mask: MASK_NUMBER },
        { title: 'Rubro Presupuestal', property: 'BudgetID', visible: true },
        { title: 'Código', property: 'CodeID', visible: true },
        { title: 'Concepto', property: 'ConceptID', visible: true },
        { title: 'Cuenta Consolida Tercero', property: 'RequiresConsolidateThird', visible: true, mask: MASK_BOOLEAN_ICON },
        { title: 'Valor', property: 'Value', visible: true },
        { title: 'Cantidad Terceros', property: 'AccountsQuantity', visible: true },

    ]

    // const iconButtonsItem = [
    //     { nombre: 'Agregar', visible: single.canCreate(), icono: 'ri-file-add-line', evento: create }
    // ]
    const listaBotones = () => {
        const botones = [

            { titulo: 'Editar', icono: 'ri-edit-2-line', visible: single.canEdit(), evento: () => { setModal({ ...modal, "view": true }) } },
            { titulo: 'Eliminar', icono: 'ri-delete-bin-line', visible: single.canDelete(), evento: () => { setModalC({ ...modalC, "view": true }) } },

        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }
    return (
        <div className="row">
            <div className="col-md-12">
                <BasicTable body={lista} head={head} rowSet={PaymentAccountItem.setBean}  ></BasicTable>
                <Modal modal={modal} updateModal={setModal} eventModal={editaItem}>
                    <div className="row">
                        <div className="col-md-6">
                            <SelectAccountantTree
                                label="Cuenta Contable"
                                jsonCode={PaymentAccountItem.bean.AccountantID}
                                onChange={(e) => {
                                    PaymentAccountItem.setBean({ ...PaymentAccountItem.bean, "AccountantID": e.JsonCode });
                                }}
                            />
                        </div>

                        <div className="col-md-6">
                            <SelectBudgetID edit={false} tipoPresupuesto={0} idBudgetID={PaymentAccountItem.bean.BudgetID} onChange={(e) => {
                                PaymentAccountItem.setBean({
                                    ...PaymentAccountItem.bean,
                                    "BudgetID": e.IDLn
                                });
                            }} />
                        </div>
                        <div className="col-md-6">
                            <div className="col-md-12">
                                <label>Código:</label>
                                <input type="text" name="CodeID" className="form-control" value={PaymentAccountItem.bean.CodeID} onChange={PaymentAccountItem.handleChange} />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="col-md-12">
                                <label>Concepto:</label>
                                <input type="text" name="ConceptID" className="form-control" value={PaymentAccountItem.bean.ConceptID} onChange={PaymentAccountItem.handleChange} />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="col-md-12">
                                <label>NO TIENE TITULO EN EL CRUD:</label>
                                <input type="text" name="RequiresConsolidateThird" className="form-control" value={PaymentAccountItem.bean.RequiresConsolidateThird} onChange={PaymentAccountItem.handleChange} />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="col-md-12">
                                <label>Valor:</label>
                                <input type="text" name="Value" className="form-control" value={PaymentAccountItem.bean.Value} onChange={PaymentAccountItem.handleChange} />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="col-md-12">
                                <label>Cantidad Terceros:</label>
                                <input type="number" name="AccountsQuantity" className="form-control" value={PaymentAccountItem.bean.AccountsQuantity} onChange={PaymentAccountItem.handleChange} />
                            </div>
                        </div>

                    </div>
                </Modal>
                <ModalConfirm modal={modalC} updateModal={setModalC} eventModal={deleteItem}>
                    <strong>Eliminar item</strong>
                </ModalConfirm>
            </div>

        </div>
    )
}