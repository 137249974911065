import { BasicPage } from "../../theme/Component/BasicPage"
import { useEffect, useState } from "react"
import { ModalConfirm } from "../../theme/Component/ModalConfirm";
import { Modal } from "../../theme/Component/Modal";
import { Singleton } from "../../../core/services/Singleton";

import { BasicTable } from "../../theme/Component/BasicTable";
import { ListMenu } from "../../theme/Component/ListMenu";
import { AdminService } from "../../../core/services/AdminService";
import { InputDate } from "../../theme/Input/InputDate";

import { useDashboardStore } from "../../pages/hooks/useDashboardStore";





export const FestiveDay = () => {

    const single = Singleton.getInstance()
    const { setLoading } = useDashboardStore();
    const service = new AdminService();
    const [lista, setLista] = useState([]);
    const [modal, setModal] = useState<any>({ name: 'Permisos', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });
    const [modalConfirm, setModalConfirm] = useState<any>({ name: 'Confirmar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });

    const [bean, setBean] = useState<any>({});

    const [user, setUser] = useState<any>({});

    const idBean = 'IDFestivalDay';

    const head = [
        { title: 'ID', property: idBean },
        { title: 'Dia', property: 'Day' },
        { title: 'Descripción', property: 'Description' },

    ]

    useEffect(() => {

        getLista();
    }, [])

    const setRowBean = (bean: any) => {
       //console.log("Row : ", bean);
        setBean(bean);

    }

    const getLista = () => {

        setLoading(true);
        service.getFestiveDayCatalogPorPropiedad(null, null, null).subscribe(
            (resp: any) => {

                if (resp.DataBeanProperties.ObjectValue) {
                    setLista(resp.DataBeanProperties.ObjectValue);
                }

                setLoading(false);
            }
        );
    }



    const agregarItem = () => {

        let b = {
            Day: single.getCurrenDate(),
            Description: '',

            State: 1,
        }
        setBean(b);
        setModal({ ...modal, ['view']: true });
    }

    const editarItem = () => {
        setModal({ ...modal, ['view']: true });
    }

    const eliminarItem = () => {

        setLoading(true);
        service.deleteFestiveDay(bean[idBean]).subscribe(
            (resp: any) => {
                setLoading(false);
                if (resp) {
                    getLista();
                }
                single.delete(bean[idBean], bean);
            }
        );
        setModalConfirm({ ...modalConfirm, ['view']: false });
    }

    const updateItem = () => {
       //console.log(bean);
        setModal({ ...modal, ['view']: false });
        setLoading(true);
        service.updateFestiveDay(bean).subscribe(
            (resp: any) => {
                setLoading(false);
                if (resp.DataBeanProperties.ObjectValue) {
                    getLista();
                }
                single.update(bean);
            });
    }


    const listaBotones = () => {
        const botones = [

            { titulo: 'Editar', icono: 'ri-file-list-line', visible: true, evento: () => { setModal({ ...modal, "view": true }) } },
            { titulo: 'Eliminar', icono: 'ri-file-user-line', visible: true, evento: () => { setModalConfirm({ ...modalConfirm, "view": true }) } },
        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }

    const iconButtonsItem = [{ nombre: 'Agregar', visible: single.canCreate(), icono: 'ri-file-add-line', evento: agregarItem }]

    return (
        <>
            <BasicPage titulo="Calendario Festivos">
                <div className="row">
                    <div className="col-md-12 p-2">
                        <BasicTable listButtons={listaBotones()} iconButtons={iconButtonsItem} head={head} body={lista} rowSet={setRowBean}></BasicTable>
                    </div>
                </div>
            </BasicPage>
            <ModalConfirm modal={modalConfirm} updateModal={setModalConfirm} eventModal={eliminarItem}>
                {bean.Name}
            </ModalConfirm>
            <Modal modal={modal} updateModal={setModal} eventModal={updateItem}>
                <div className="row">
                    <div className="col-md-12">
                        <InputDate label="Día" name="Desde" value={bean.Day} setDate={(e: any) => { setBean({ ...bean, 'Day': e }) }} ></InputDate>
                    </div>
                    <div className="col-md-12">
                        <label className="form-label">Descripción</label>
                        <textarea className="form-control" value={bean.Description} onChange={(e) => { setBean({ ...bean, ['Description']: e.target.value }) }}></textarea>
                    </div>
                </div>
            </Modal>
        </>
    )
}