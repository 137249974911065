import { useEffect, useState } from "react";
import useDataBean from "../../../core/services/dataBean/useDataBean";
import { _SupplierPaymentOrder } from "../../../core/services/dataBean/EntityCatalogVdos";
import { Singleton } from "../../../core/services/Singleton";
import useModal, { MODAL_LG, MODAL_md } from "../../theme/Component/hooks/useModal";
import { toast } from "react-toastify";
import { ListMenu } from "../../theme/Component/ListMenu";
import { Modal } from "../../theme/Component/Modal";
import { SupplierPaymentOrderItem } from "./SupplierPaymentOrderItem/SupplierPaymentOrderItem";
import { CreateUBLInvoiceItems } from "../../contract/etapa-contrato/contrac/ublinvoice/CreateUBLInvoice/CreateUBLInvoiveItem";
import { MakeUBLInvoice } from "../../contract/etapa-contrato/contrac/ublinvoice/MakeUBLInvoice";
import { ModalConfirm } from "../../theme/Component/ModalConfirm";
import { BasicButton } from "../../theme/Component/BasicButton";
import { _single, _UBLInvoice } from "../../../core/services/dataBean/EntityCatalog";
import { DocumentViewer } from "../../theme/Component/DocumentViewer";
import { ThirdBankingAccountEditor } from "../ParametrosTalentoHumano/ThirdBankingAccount/ThirdBanking.editor";
import { PrintJson } from "../../theme/Component/PrintJson";

interface Props {
    paymentOrder: any;
    listComponent: any;
    updateList: any;
    contract?: any;
}
export const SuppliertPaymentOptions = ({ paymentOrder, listComponent, updateList, contract }: Props) => {

    const SupplierPaymentOrder = useDataBean(_SupplierPaymentOrder);
    const _ublInvoice = useDataBean(_UBLInvoice);
    const [bean, setbean] = useState<any>({})
    const single = Singleton.getInstance();
    const [filteredLista, setFilteredLista] = useState<any>([]);
    const [filteredDocs, setFilteredDocs] = useState<any>([]);
    const [modalGR, setModalGR] = useState<any>({ name: 'Generar Reporte de la Orden de Pago', tabIndex: -1, view: false, btnClose: true, btnSubmit: false, format: 'modal-xl' });
    const [lista, setLista] = useState<any>([]);
    const [invoice, setinvoice] = useState<any>({});
    const [modalFactura, setModalFactura] = useState<any>({ name: 'Agregar Factura a la Orden de Pago', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-xl' });
    const [modalRC, setModalRC] = useState<any>({ name: ' Rechazar Orden de Pago', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalPOP, setModalPOP] = useState<any>({ name: 'Publicar Orden de Pago', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalPC, setModalPC] = useState<any>({ name: 'Agregar Artículo para Pago', tabIndex: -1, view: false, btnClose: true, btnSubmit: false, format: 'modal-lg' });
    const [modal, setModal] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-md' });
    const [modalDoc, setModalDoc] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-xl' });
    const [modalC, setModalC] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });
    const [modalConfAdd, setModalConfAdd] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: "modal-md", })
    const modalEditar = useModal("Orden", MODAL_md);
    const modalReleaseInvoice = useModal("Eliminar Factura de Contrato", MODAL_md);
    const modalPostOrder = useModal("Publicar orden a proveedor", MODAL_md);
    const modalReject = useModal("Rechazar Factura", MODAL_md);
    const modalPostPaymentOrderToHiring = useModal("Publicar a compras", MODAL_md);
    const modalRejectOrderFromHiring = useModal("Rechazar orden", MODAL_md);
    const modalVerifyUBL = useModal("Verificar Factura", MODAL_md);




    const DRAFT = 5;
    const SUPPLIER_SIDE = 6;
    const PENDING_FOR_SUPERVISOR_APPROVAL = 7;
    const APPROVED = 8;
    const PAIDED = 9;
    const REJECTED = 10;
    const INVOICE_DRAFT = 12;
    const PENDING_FOR_HIRING_APPROVAL = 13;




    useEffect(() => {
        SupplierPaymentOrder.setBean(paymentOrder);
    }, [paymentOrder])


    useEffect(() => {
        listComponent(listaBotones(SupplierPaymentOrder.bean.OrderState));
    }, [SupplierPaymentOrder.bean])









    const listaBotones = (orderState: number) => {

        const botones = [
            {
                titulo: 'Editar', icono: 'ri-edit-2-line',
                visible: single.canEdit() && [DRAFT].includes(orderState) || orderState == null, evento: () => { modalEditar.open() }
            },
            {
                titulo: 'Eliminar', icono: 'ri-delete-bin-line',
                visible: single.canDelete() && [DRAFT].includes(orderState) || orderState == null, evento: () => { setModalC({ ...modalC, "view": true }) }
            },
            {
                titulo: 'Items de la Orden de Pago', icono: 'ri-wallet-fill',
                visible: [DRAFT].includes(orderState), evento: () => { setModalPC({ ...modalPC, "view": true }) }
            },
            {
                titulo: 'Rechazar Orden de Pago', icono: ' ri-file-forbid-fill',
                visible: [PENDING_FOR_SUPERVISOR_APPROVAL].includes(orderState), evento: () => { setModalRC({ ...modalRC, "view": true }) }
            },
            {
                titulo: 'Publicar Orden de Pago / Enviar a Supervisor', icono: 'ri-file-upload-fill',
                visible: [SUPPLIER_SIDE, INVOICE_DRAFT].includes(orderState), evento: () => { modalPostOrder.open() }
            },
            {
                titulo: 'Aprobar Factura', icono: 'ri-file-upload-fill',
                visible: [PENDING_FOR_SUPERVISOR_APPROVAL].includes(orderState), evento: () => { modalVerifyUBL.open(); modalVerifyUBL.setSumbmitLabel('Verificar') }
            },
            {
                titulo: 'Gestionar Factura', icono: ' ri-file-forbid-fill',
                visible: [DRAFT, SUPPLIER_SIDE, INVOICE_DRAFT, PENDING_FOR_SUPERVISOR_APPROVAL].includes(orderState), evento: () => { setModalFactura({ ...modalFactura, "view": true }) }
            },
            {
                titulo: 'Rechazar Factura', icono: ' ri-file-forbid-fill',
                visible: [PENDING_FOR_SUPERVISOR_APPROVAL].includes(orderState), evento: () => { modalReject.open(); modalReject.setSumbmitLabel('Rechazar') }
            },
            {
                titulo: 'Enviar a verificación compras', icono: ' ri-file-forbid-fill',
                visible: [PENDING_FOR_HIRING_APPROVAL].includes(orderState), evento: () => { modalPostPaymentOrderToHiring.open(); modalPostPaymentOrderToHiring.setSumbmitLabel('Enviar') }
            },
            {
                titulo: 'Rechazar Orden desde compras', icono: ' ri-file-forbid-fill',
                visible: [PENDING_FOR_HIRING_APPROVAL].includes(orderState), evento: () => { modalRejectOrderFromHiring.open(); modalRejectOrderFromHiring.setSumbmitLabel('Rechazar') }
            },
            {
                titulo: 'Eliminar Factura de Contrato', icono: ' ri-file-forbid-fill',
                visible: [DRAFT, SUPPLIER_SIDE, PENDING_FOR_SUPERVISOR_APPROVAL].includes(orderState), evento: () => { modalReleaseInvoice.open() }
            },
            {
                titulo: 'Reporte Orden de Pago', icono: ' ri-file-forbid-fill',
                visible: [DRAFT, SUPPLIER_SIDE, APPROVED, PAIDED, REJECTED, INVOICE_DRAFT, PENDING_FOR_SUPERVISOR_APPROVAL, PENDING_FOR_HIRING_APPROVAL].includes(orderState),
                evento: () => { setModalGR({ ...modalGR, "view": true }); GenerarReporte() }
            },
            { titulo: 'Ver Documentos', icono: 'ri-file-list-3-fill', visible: single.canDelete(), evento: () => { GenerarDocumentos() } },
        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }


    const GenerarReporte = () => {
        SupplierPaymentOrder.instance.exportSupplierPaymentOrder(SupplierPaymentOrder.bean.IDSupplierPaymentOrder, SupplierPaymentOrder.bean.SupplierPaymentOrderDate).then(
            (resp: any) => {
                if (resp) {
                   //console.log(resp)
                    let temp = [];
                    resp.forEach(element => {
                        temp.push(element.DataBeanProperties);
                    });
                    setFilteredLista(temp);

                }
            }
        )
    }


    // const GenerarDocumentos = () => {
    //     SupplierPaymentOrder.instance.getAccountantDocumentList(SupplierPaymentOrder.bean.IDUBLInvoice, SupplierPaymentOrder.bean.IssueDate, single.getAccountID()).then(
    //         (resp: any) => {
    //             if (resp.DocumentURL) {
    //                //console.log(resp.DocumentURL)
    //                 let temp = [];
    //                 resp.DocumentURL.forEach(element => {
    //                     temp.push(element.DocumentURL);
    //                 });
    //                 setFilteredDocs(temp);

    //             }
    //         }
    //     )
    // }


    const GenerarDocumentos = () => {
        SupplierPaymentOrder.instance.getAccountantDocumentList(SupplierPaymentOrder.bean.IDUBLInvoice, SupplierPaymentOrder.bean.IssueDate, single.getAccountID()).then(
            (resp: any) => {
                if (resp) {

                    let temp = [];
                    resp.forEach(element => {
                        if (element.DataBeanProperties) {
                            element.DataBeanProperties.DocumentURL.forEach(item => {
                                temp.push(item);
                            })
                        }
                    });

                    setFilteredDocs(temp);
                    setModalDoc({ ...modalDoc, "view": true });
                }
            }
        )
    }

    const RechazarOrden = () => {
        SupplierPaymentOrder.instance.rejectSupplierPaymentOrder(SupplierPaymentOrder.bean.IDSupplierPaymentOrder, SupplierPaymentOrder.bean.SupplierPaymentOrderDate, single.getAccountID(), SupplierPaymentOrder.bean.observations).then(
            (resp: any) => {
                if (resp) {
                    toast.success('Orden Rechazada')
                    setModalRC({ ...modalRC, "view": false });
                    updateList();
                } else {
                    toast.error('No se pudo completar la Acción')
                }
            }
        )
    }

    const AgregarFactura = () => {
       //console.log(SupplierPaymentOrder.bean.IDSupplierPaymentOrder, SupplierPaymentOrder.bean.SupplierPaymentOrderDate, invoice.IDUBLInvoice, invoice.IssueDate, SupplierPaymentOrder.bean.observations, single.getAccountID(), SupplierPaymentOrder.bean.IDThirdBankingAccount)
        SupplierPaymentOrder.instance.addUBLInvoiceToAdquisitionContract(SupplierPaymentOrder.bean.IDSupplierPaymentOrder, SupplierPaymentOrder.bean.SupplierPaymentOrderDate, invoice.IDUBLInvoice, invoice.IssueDate, SupplierPaymentOrder.bean.observations, single.getAccountID(), SupplierPaymentOrder.bean.IDThirdBankingAccount).then(
            (resp: any) => {
                if (resp) {
                    toast.success('Factura agregada')
                    setModalConfAdd({ ...modalConfAdd, "view": false });

                    setinvoice({});
                    updateList();
                }
            }
        )
            .catch(err => {
                toast.error(err)
            })
    }

    const releaseUBLInvoiceFromAdquisitionContract = () => {

        const b = SupplierPaymentOrder.bean;
        SupplierPaymentOrder.instance.releaseUBLInvoiceFromAdquisitionContract(b.IDSupplierPaymentOrder, b.SupplierPaymentOrderDate, _single.getAccountID(), b.observations).then(
            (resp: any) => {
               //console.log(resp);
                modalReleaseInvoice.close();
                toast.success("Se elimino la factura del contrato");
                updateList();
            }
        ).catch(err => toast.error(err));

    }
    const postSupplierPaymentOrderToSupervisor = () => {

        const b = SupplierPaymentOrder.bean;
        _ublInvoice.instance.postSupplierPaymentOrderToSupervisor(b.IDSupplierPaymentOrder, b.SupplierPaymentOrderDate, b.observations, _single.getAccountID()).then(
            (resp: any) => {
               //console.log(resp);
                modalPostOrder.close();
                toast.success("Orden de pago publicada.");
                updateList();
            }
        ).catch(err => toast.error(err));

    }
    const rejectSupplierPaymentOrderFromSupervisor = () => {

        const b = SupplierPaymentOrder.bean;
        SupplierPaymentOrder.instance.rejectSupplierPaymentOrderFromSupervisor(b.IDSupplierPaymentOrder, b.SupplierPaymentOrderDate, _single.getAccountID(), b.observations).then(
            (resp: any) => {
               //console.log(resp);
                modalPostOrder.close();
                toast.success("Orden de pago publicada.");
                updateList();
            }
        ).catch(err => toast.error(err));

    }
    const rejectSupplierPaymentOrderFromHiring = () => {

        const b = SupplierPaymentOrder.bean;
        SupplierPaymentOrder.instance.rejectSupplierPaymentOrderFromHiring(b.IDSupplierPaymentOrder, b.SupplierPaymentOrderDate, _single.getAccountID(), b.observations).then(
            (resp: any) => {
               //console.log(resp);
                modalPostOrder.close();
                toast.success("Orden de pago publicada.");
                updateList();
            }
        ).catch(err => toast.error(err));

    }
    const postSupplierPaymentOrderToHiring = () => {

        const b = SupplierPaymentOrder.bean;
        SupplierPaymentOrder.instance.postSupplierPaymentOrderToHiring(b.IDSupplierPaymentOrder, b.SupplierPaymentOrderDate, b.observations, _single.getAccountID()).then(
            (resp: any) => {
               //console.log(resp);
                modalPostOrder.close();
                toast.success("Orden de pago publicada.");
                updateList();
            }
        ).catch(err => toast.error(err));

    }


    const [validate, setValidate] = useState<any>([]);
    const [accountingOption, setAccountingOption] = useState(false);
    const requestUBLInvoiceToAccounting = () => {

        const b = SupplierPaymentOrder.bean;
        _ublInvoice.instance.requestUBLInvoiceToAccounting(b.IDUBLInvoice, b.IssueDate, _single.getAccountID(), b.observations).then(
            (resp: any) => {
               //console.log(resp);
                setValidate(resp);
                if (resp.ErrorList.length == 0) {
                    setAccountingOption(true);
                    toast.success("Factura correcta");
                } else {
                    setAccountingOption(false);
                    toast.warning("Factura con problemas");
                }

                updateList();
            }
        ).catch(err => toast.error(err));

    }
    const sendSupplierPaymentOrderToAccounting = () => {

        const b = SupplierPaymentOrder.bean;
        SupplierPaymentOrder.instance.sendSupplierPaymentOrderToAccounting(b.IDSupplierPaymentOrder, b.SupplierPaymentOrderDate, b.observations, _single.getAccountID()).then(
            (resp: any) => {
               //console.log(resp);
                toast.success("Factura enviada a contabilización");
                modalVerifyUBL.close();
                updateList();
            }
        ).catch(err => toast.error(err));

    }
    const sendSupplierPaymentOrderToStore = () => {

        const b = SupplierPaymentOrder.bean;
        SupplierPaymentOrder.instance.sendSupplierPaymentOrderToStore(b.IDSupplierPaymentOrder, b.SupplierPaymentOrderDate, b.observations, _single.getAccountID()).then(
            (resp: any) => {
               //console.log(resp);
                modalVerifyUBL.close();
                updateList();
            }
        ).catch(err => toast.error(err));

    }


    const canDeleteInvoice = () => {

        if (SupplierPaymentOrder.bean.IDUBLInvoice > 0)
            return true;
        else
            return false;
    }
    const deleteItem = () => {
        SupplierPaymentOrder.instance.deleteSupplierPaymentOrder(SupplierPaymentOrder.bean.IDSupplierPaymentOrder, SupplierPaymentOrder.bean.SupplierPaymentOrderDate).then(
            (resp) => {
               //console.log(resp);
                updateList();

                setModalC({ ...modalC, "view": false });
            }
        )
    }

    const editaItem = () => {
       //console.log(SupplierPaymentOrder.bean);
        SupplierPaymentOrder.update().then(
            (resp: any) => {
                updateList();
                modalEditar.close();
            }
        ).catch(err => {
            toast.error(err);
        })

    }


    const closeModalAdd = () => {
        SupplierPaymentOrder.setBean({ ...SupplierPaymentOrder.bean, "observations": "", "IDThirdBankingAccount": NaN })
        if (!invoice.IDUBLInvoice) {
            toast.error("Debe seleccionar una factura valida")
        } else {
            setModalFactura({ ...modalFactura, "view": false });
            setModalConfAdd({ ...modalConfAdd, "view": true, "name": "Confirmar Agregar Factura" });
        }
    }

    const options = () => {
        return (
            <>
                <div className="col-md-12">

                    <Modal modal={modalEditar.modal} updateModal={modalEditar.setModal} eventModal={editaItem}>
                        <div className="row">
                            <div className="col-md-12">
                                <label>Observaciones</label>
                                <textarea name="Description" className="form-control" rows={5} value={SupplierPaymentOrder.bean.Description} onChange={SupplierPaymentOrder.handleChange} />
                            </div>
                        </div>
                    </Modal>

                    <ModalConfirm modal={modalC} updateModal={setModalC} eventModal={deleteItem}>
                        <strong>Eliminar item</strong>
                    </ModalConfirm>
                    <Modal modal={modalPC} updateModal={setModalPC}>
                        <>
                            <SupplierPaymentOrderItem IDSupplierPaymentOrder={SupplierPaymentOrder.bean} onUpdate={updateList} />
                        </>
                    </Modal>

                    {SupplierPaymentOrder.bean.IDUBLInvoice ?
                        <Modal modal={modalFactura} onSubmitLabel="Seleccionar Factura" updateModal={setModalFactura} >
                            <>
                                <CreateUBLInvoiceItems
                                    _data={SupplierPaymentOrder.bean}
                                    issueDate={SupplierPaymentOrder.bean.IssueDate}
                                    idUBLInvoice={SupplierPaymentOrder.bean.IDUBLInvoice}
                                />

                            </>
                        </Modal>
                        :
                        <Modal modal={modalFactura} onSubmitLabel="Seleccionar Factura" updateModal={setModalFactura} eventModal={() => { closeModalAdd() }}>
                            <>
                                {contract &&
                                    <MakeUBLInvoice idAccount={contract.IDAccountSupplier} _onChange={(e) => { setinvoice(e) }} />
                                }
                            </>
                        </Modal>
                    }


                    <ModalConfirm modal={modalConfAdd} updateModal={setModalConfAdd} eventModal={AgregarFactura} >
                        <p>Esta seguro de agregar la Facrtua con ID {invoice.IDUBLInvoice} <br /> del proveedor {invoice.AccountSupplierNit} - {invoice.AccountSupplierName}</p>
                        <div className="row">
                            <div className="col-12">
                                <label>Observaciones</label>
                                <textarea name="observations" className="form-control" value={SupplierPaymentOrder.bean.observations} onChange={SupplierPaymentOrder.handleChange} />
                            </div>
                            <div className="col-12">
                                {contract &&
                                    <ThirdBankingAccountEditor label="Cuenta Bancaria del Tercero" idSelector={SupplierPaymentOrder.bean.IDThirdBankingAccount} idselectorAccount={contract.IDAccountSupplier} onChange={(e) => { SupplierPaymentOrder.setBean({ ...SupplierPaymentOrder.bean, "IDThirdBankingAccount": e }) }} />
                                }
                            </div>
                        </div>
                    </ModalConfirm>
                    <Modal modal={modalDoc} updateModal={setModalDoc} >

                        <div className="row">
                            <div className="col-md-12"><p>Ver Documentos</p></div>
                            <div className="col-md-12">

                                <DocumentViewer list={filteredDocs} />
                            </div>
                        </div>

                    </Modal>
                    <ModalConfirm modal={modalGR} updateModal={setModalGR} eventModal={GenerarReporte}>

                        <div className="row">
                            <div className="col-md-12"><p>Descargar Reporte de la Orden de Pago</p></div>
                            <div className="col-md-12">
                                {/* <PrintJson json={filteredLista}/> */}
                                <DocumentViewer list={filteredLista} />
                            </div>
                        </div>

                    </ModalConfirm>
                    <ModalConfirm modal={modalRC} updateModal={setModalRC} eventModal={RechazarOrden}>

                        <div className="row">
                            <div className="col-md-12"><p>- Rechazar Orden de Pago ?</p></div>
                            <div className="col-md-12">
                                <div className="col-md-12">
                                    <label>Observaciones</label>
                                    <textarea name="observations" className="form-control" value={SupplierPaymentOrder.bean.observations} onChange={SupplierPaymentOrder.handleChange} />
                                </div>
                            </div>
                        </div>
                    </ModalConfirm>
                    <Modal modal={modalReleaseInvoice.modal} updateModal={modalReleaseInvoice.setModal} eventModal={releaseUBLInvoiceFromAdquisitionContract} onSubmitLabel="Retirar">

                        <div className="row">
                            <div className="col-md-12">
                                <label>Observaciones</label>
                                <textarea name="observations" className="form-control" value={SupplierPaymentOrder.bean.observations} onChange={SupplierPaymentOrder.handleChange} />
                            </div>
                        </div>
                    </Modal>
                    <Modal modal={modalPostOrder.modal} updateModal={modalPostOrder.setModal} eventModal={postSupplierPaymentOrderToSupervisor} onSubmitLabel="Enviar">

                        <div className="row">
                            <div className="col-md-12">
                                <label>Observaciones</label>
                                <textarea name="observations" className="form-control" value={SupplierPaymentOrder.bean.observations} onChange={SupplierPaymentOrder.handleChange} />
                            </div>
                        </div>
                    </Modal>
                    <Modal modal={modalReject.modal} updateModal={modalReject.setModal} eventModal={rejectSupplierPaymentOrderFromSupervisor} onSubmitLabel={modalReject.submitLabel}>

                        <div className="row">
                            <div className="col-md-12">
                                <label>Observaciones</label>
                                <textarea name="observations" className="form-control" value={SupplierPaymentOrder.bean.observations} onChange={SupplierPaymentOrder.handleChange} />
                            </div>
                        </div>
                    </Modal>
                    <Modal modal={modalPostPaymentOrderToHiring.modal} updateModal={modalPostPaymentOrderToHiring.setModal} eventModal={postSupplierPaymentOrderToHiring} onSubmitLabel={modalPostPaymentOrderToHiring.submitLabel}>

                        <div className="row">
                            <div className="col-md-12">
                                <label>Observaciones</label>
                                <textarea name="observations" className="form-control" value={SupplierPaymentOrder.bean.observations} onChange={SupplierPaymentOrder.handleChange} />
                            </div>
                        </div>
                    </Modal>
                    <Modal modal={modalRejectOrderFromHiring.modal} updateModal={modalRejectOrderFromHiring.setModal} eventModal={rejectSupplierPaymentOrderFromHiring} onSubmitLabel={modalRejectOrderFromHiring.submitLabel}>

                        <div className="row">
                            <div className="col-md-12">
                                <label>Observaciones</label>
                                <textarea name="observations" className="form-control" value={SupplierPaymentOrder.bean.observations} onChange={SupplierPaymentOrder.handleChange} />
                            </div>
                        </div>
                    </Modal>

                    <Modal modal={modalVerifyUBL.modal} updateModal={modalVerifyUBL.setModal} eventModal={requestUBLInvoiceToAccounting} onSubmitLabel={modalVerifyUBL.submitLabel}>

                        <div className="row">
                            <div className="col-md-12">
                                <strong>Proveedor : </strong><small>{SupplierPaymentOrder.bean.SupplierName}</small><br />
                                <strong>Valor : </strong><small>{_single.formatMoney(SupplierPaymentOrder.bean.Value)}</small><br />
                            </div>

                            <div className="col-md-12">
                                <label>Observaciones</label>
                                <textarea name="observations" className="form-control" value={SupplierPaymentOrder.bean.observations} onChange={SupplierPaymentOrder.handleChange} />
                            </div>
                            {accountingOption &&
                                <div className="col-md-12">
                                    <div className="d-flex p-2">
                                        <BasicButton icon="" eventClick={sendSupplierPaymentOrderToStore}>Enviarlo a Almacen</BasicButton>
                                        <BasicButton icon="" eventClick={sendSupplierPaymentOrderToAccounting}>Enviarlo a Contabilización</BasicButton>
                                    </div>
                                </div>
                            }



                            <div className="col-md-12">
                                {validate.ErrorList &&
                                    <>
                                        <hr />
                                        <ul className="list-group">
                                            {
                                                validate.ErrorList.map((item: any, index: number) => {
                                                    return (
                                                        <li className="list-group-item">
                                                            {item}
                                                        </li>
                                                    )
                                                })
                                            }
                                        </ul>
                                    </>
                                }
                            </div>

                        </div>
                    </Modal>

                </div>
            </>
        )
    }

    return (

        <div className="row">
            <div className="col">
                {options()}
            </div>
        </div>

    )
}