import { DataBean } from "../DataBean";

export class Process extends DataBean {
    
    


    public async getProcessCatalogPorPropiedades(properties:any,maxRows:any): Promise<any> {
        return this.getCustomService("ProcessFlow", "java.util.List_getProcessCatalogPorPropiedades_java.util.Map_Number", [properties,maxRows]);
    }

    // public async getProjectCatalogPorPropiedadesRender(props:any,rows:any): Promise<any> {
    //     return this.getCustomService("FinanceCore", "java.util.List_getProjectCatalogPorPropiedadesRender_java.util.Map_Number", [props,rows]);
    // }
  

}