import { useEffect, useState } from "react";
import useDataBean from "../../../../core/services/dataBean/useDataBean";
import { Singleton } from "../../../../core/services/Singleton";
import { toast } from "react-toastify";
import { ListMenu } from "../../../theme/Component/ListMenu";
import { BasicPage } from "../../../theme/Component/BasicPage";
import { BasicTable, MASK_NUMBER } from "../../../theme/Component/BasicTable";
import { Modal } from "../../../theme/Component/Modal";
import { ModalConfirm } from "../../../theme/Component/ModalConfirm";
import { _TaxScheme } from "../../../../core/services/dataBean/EntityCatalog";
import { TaxSchemeContextEditor } from "../TaxSchemeCode/TaxSchemeContext.editor";
import { TaxSchemeTypeEditor } from "./TaxSchemeType.editor";
import { SelectAccountantTree } from "../../../contabilidad/catalogo-cuentas/AccountantTree/SelectAccountantTree";
import { TaxSchemeContextStateEditor } from "./TaxSchemeContextState.editor";
import { BasicSwitch } from "../../../theme/Component/BasicSwitch";
import { TaxSchemeValue } from "./FuncionesTaxScheme/TaxSchemeValue";
import { FileInput } from "../../../theme/Component/FileInput";
import { PrintJson } from "../../../theme/Component/PrintJson";


export const TaxScheme = () => {

    const taxScheme = useDataBean(_TaxScheme);
    const single = Singleton.getInstance();

    const [squemaCode, setSquemaCode] = useState<number>(null);
    const [taxType, setTaxType] = useState<number>(null);
    const [lista, setLista] = useState<any>([]);
    const [documento, setDocumento] = useState<any>({});

    const [modal, setModal] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalTarfias, setModalTarifas] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-xl' });
    const [modalC, setModalC] = useState<any>({ name: "Eliminar", tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });
    const [modalImport, setModalImport] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-md' });

    useEffect(() => {
        if (squemaCode && typeof taxType === 'number' && taxType >= 0) {
            getLista();
        }
    }, [squemaCode, taxType]);

    const getLista = () => {

        taxScheme.instance.getTaxSchemeCatalog(squemaCode, taxType).then(
            (resp: any) => {
                if (resp) {
                    setLista(resp)
                }
            }
        )

    }

    const create = () => {
        taxScheme.setBean({
            IDAccount: single.getAccountID(),
            IDTaxSchemeContext: squemaCode,
        });
        setModal({ ...modal, "view": true, "name": `Agregar Tipos de Impuestos` });
    }

    const deleteItem = () => {
        taxScheme.deleteItemVoid(taxScheme.bean.IDTaxScheme).then(
            (resp: any) => {
                getLista();
                setModalC({ ...modalC, "view": false });
            }
        )

    }

    const editaItem = () => {
       //console.log(taxScheme.bean);
        taxScheme.update().then(
            (resp: any) => {
                getLista();
                setModal({ ...modal, "view": false });
            }
        ).catch(err => {
            toast.error(err);
        })
    }

    const head = [
        { title: 'ID', property: 'IDTaxScheme', visible: true, mask: MASK_NUMBER },
        { title: 'Código', property: 'Code', visible: true },
        { title: 'Nombre', property: 'Name', visible: true },
        { title: 'Descripción', property: 'Description', visible: true },
        { title: 'Tipo de Impuesto', property: 'TaxSchemeTypeName', visible: true },
        { title: 'Cuenta Contable Retención/Descuento', property: 'AccountantIDName', visible: true },
        { title: 'Cuenta Contable Vr Agregado', property: 'AccountantIDAddedValueName', visible: true },
        { title: 'Estado', property: 'StateName', visible: true },
        { title: 'Aplicar a Códigos de Actividad Unicamente', property: 'ApplyCodeFilter', visible: true },
    ]

    const iconButtonsItem = [
        { nombre: 'Agregar', visible: single.canCreate(), icono: 'ri-file-add-line', evento: create }
    ]
    const listaBotones = () => {
        const botones = [
            { titulo: 'Editar', icono: 'ri-edit-2-line', visible: single.canEdit(), evento: () => { setModal({ ...modal, "name": `Editar ${taxScheme.bean.Name}`, "view": true }) } },
            { titulo: 'Eliminar', icono: 'ri-delete-bin-line', visible: single.canDelete(), evento: () => { setModalC({ ...modalC, "view": true }) } },
            { titulo: 'Configurar Tarifa de Impuestos', icono: 'ri-settings-5-line', visible: single.canDelete(), evento: () => { setModalTarifas({ ...modalTarfias, "name": "Configurar Tarifas de Impuestos", "view": true }) } },
            { titulo: 'Importar Tarifas de Impuestos y Actividades', icono: 'ri-inbox-unarchive-line', visible: single.canDelete(), evento: () => { setModalImport({ ...modalImport, "name": "Importar Tarifas de Impuestos y Actividades", "view": true }) } },
        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }

    const importTaxScheValue = () => {
        taxScheme.instance.importTaxSchemeValueCatalog(taxScheme.bean.IDTaxScheme, documento.Media, documento.MediaContext, null).then(
            (resp) => {
               //console.log(resp);
                setModalImport({ ...modalImport, "view": false });
                getLista();
            }
        ).catch(
            err => {
                toast.error(err)
            }
        )
    }


    return (
        <>
            <BasicPage titulo="Tipos de Impuestos">
                <div className="row">
                    <div className="col-6 mb-2">
                        <TaxSchemeContextEditor label="Esquemas de Recaudo de Impuestos" idSelector={squemaCode} onChange={(e) => { setSquemaCode(e) }} />
                    </div>
                    <div className="col-6">
                        <TaxSchemeTypeEditor idSelector={taxType} onChange={(e) => { setTaxType(e) }} />
                    </div>
                    <div className="col-12">
                        <BasicTable body={lista} head={head} rowSet={taxScheme.setBean} listButtons={listaBotones()} iconButtons={iconButtonsItem}></BasicTable>
                    </div>
                </div>
            </BasicPage>

            {modal.view &&
                <Modal modal={modal} updateModal={setModal} eventModal={editaItem}>
                    <div className="row">
                        <div className="col-6">
                            <TaxSchemeTypeEditor label="Tipo de Impuesto" idSelector={taxScheme.bean.TaxSchemeType} onChange={(e) => { taxScheme.setBean({ ...taxScheme.bean, "TaxSchemeType": e }) }} />
                        </div>
                        <div className="col-6">
                            <TaxSchemeContextStateEditor idSelector={taxScheme.bean.State} onChange={(e) => { taxScheme.setBean({ ...taxScheme.bean, "State": e }) }} />
                        </div>
                        <div className="col-6">
                            <SelectAccountantTree label="Cuenta Contable Retención/Descuento" jsonCode={taxScheme.bean.AccountantIDStr} onChange={(e) => { taxScheme.setBean({ ...taxScheme.bean, "AccountantIDStr": e.JsonCode }) }} />
                        </div>
                        <div className="col-6">
                            <SelectAccountantTree label="Cuenta Contable Vr Agregado" jsonCode={taxScheme.bean.AccountantIDAddedValueStr} onChange={(e) => { taxScheme.setBean({ ...taxScheme.bean, "AccountantIDAddedValueStr": e.JsonCode }) }} />
                        </div>

                        <div className="col-6 d-flex align-items-center">
                            <BasicSwitch label="Aplicar a Codigos de Actividad Unicamente" estado={taxScheme.bean.ApplyCodeFilter} eventChange={(e) => { taxScheme.setBean({ ...taxScheme.bean, "ApplyCodeFilter": e }) }} />
                        </div>
                    </div>
                    <div className="row">

                        <div className="col-6">
                            <label>Código</label>
                            <input type="text" name="Code" className="form-control" value={taxScheme.bean.Code} onChange={taxScheme.handleChange} />
                        </div>
                        <div className="col-6">
                            <label>Nombre</label>
                            <input type="text" name="Name" className="form-control" value={taxScheme.bean.Name} onChange={taxScheme.handleChange} />
                        </div>
                        <div className="col-12">
                            <label>Descripción</label>
                            <textarea name="Description" className="form-control" value={taxScheme.bean.Description} onChange={taxScheme.handleChange} />
                        </div>
                    </div>
                </Modal>
            }

            {modalTarfias.view &&
                <Modal modal={modalTarfias} updateModal={setModalTarifas}>
                    <TaxSchemeValue _IDTaxScheme={taxScheme.bean.IDTaxScheme} />
                </Modal>
            }

            {modalImport.view &&
                <Modal modal={modalImport} updateModal={setModalImport} eventModal={importTaxScheValue}>
                    <FileInput onCreate={(e) => { setDocumento(e) }} label="Archivo a Importar" />
                </Modal>
            }

            {modalC.view &&
                <ModalConfirm modal={modalC} updateModal={setModalC} eventModal={deleteItem}>
                    <strong>Eliminar item {taxScheme.bean.Name}</strong>
                </ModalConfirm>
            }
        </>
    )
}