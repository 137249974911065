import { DataBean } from "../DataBean";

export class CourseAccount extends DataBean {


    public async addAccountToCourse(idAccount:any,idCourse:any): Promise<any> {
        return this.getCustomService("CourseERP", "com.orange.people.course.bean.CourseAccount_addAccountToCourse_Number_Number", [idAccount,idCourse]);
    }



}