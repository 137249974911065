import { useState, useEffect } from 'react';
import useDataBean from '../../../../../../core/services/dataBean/useDataBean';
import { _Committe, _Credit } from '../../../../../../core/services/dataBean/EntityCatalog';
import { PrintJson } from '../../../../../theme/Component/PrintJson';
import { DataBeanProperties } from '../../../../../../core/services/model/server-response.interface';


interface Props {
    idSelector: number;
    onChange: any;
    onChangeState: any;
    label?: string;

}

export const ComiteEditor = ({ idSelector, onChange,onChangeState, label }: Props) => {


    const service = useDataBean(_Committe);
    
    const [id, setID] = useState(null);
    const [state, setState] = useState(1);
    const [comiteState, setComiteState] = useState<any>([
        {id:1,name:'Abierto'},
        {id:2,name:'Cerrado'},
        {id:3,name:'Aprobado'},
        {id:4,name:'Declinado'},
        {id:5,name:'Inforpo'},
    ]);


    useEffect(() => {
        setID(idSelector);
        getLista();
    }, [])
    useEffect(() => {
        onChangeState(true);
    }, [state])
    useEffect(() => {
        getLista();
        setValue(undefined);
    }, [state])

    useEffect(() => {
        onChange(id);
    }, [id])

    const setValue = (e: any) => {
        setID(parseInt(e));
        onChange(id);
    }

    const getLista = () => {
        
        service.instance.getListCommitte(null,null,state).then(
            (resp:any)=>{
               //console.log(resp);
                service.setLista(resp);
                
            }
        );
    }

    return (
        <div className="row">
            <div className="col-md-6">
                <label>Estado</label>
                <select className='form-select' name="estado" value={state} onChange={(e)=>{setState(parseInt(e.target.value))}}>
                    {comiteState.map((item:any,index:number)=>{
                        return(
                            <option value={item.id}>{item.name}</option>
                        )
                    })}
                </select>
            </div>
            <div className="col-md-6">
            <label>{label ?? "Comite"}</label>
            <select className="form-select" value={id} onChange={(e) => { setValue(e.target.value) }}>
                <option value={null}>...</option>
                {service.lista &&
                    service.lista.map((item: any, _i) => {
                        return (
                            <>
                                <option key={_i} value={item.DataBeanProperties.LoteCode} >{item.DataBeanProperties.LoteCode} </option>
                            </>)
                    })
                }
            </select>
            </div>
            {/* <PrintJson json={service.lista}/> */}
        </div>
    )
}