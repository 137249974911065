import { BasicPage } from "../theme/Component/BasicPage"
import { BasicButton } from '../theme/Component/BasicButton';
import { BasicTable } from '../theme/Component/BasicTable';
import { Singleton } from '../../core/services/Singleton';
import { ContractService } from '../../core/services/ContractService';
import { useState, useEffect } from 'react';
import { ModalConfirm } from '../theme/Component/ModalConfirm';
import { Modal } from '../theme/Component/Modal';
import { InputDate } from "../theme/Input/InputDate";
import { SelectModality } from "./select/SelectModality";
import { processState } from "../Constants";
import { ProcessActions } from './ProcessActions';
import { SelectAdquisitionPlan } from './select/SelectAdquisitionPlan';
import { SelectLegalTypeTermsConstants } from "./select/SelectLegalTypeTermsConstants";
import { SelectRequirementType } from "./select/SelectRequirementType";
import { SelectLegalRequirementTypeConstants } from "./select/SelectLegalRequirementTypeConstants";
import { ListMenu } from "../theme/Component/ListMenu";
import { useDashboardStore } from "../pages/hooks/useDashboardStore";

interface Props {
    idStructuringDocument: number;
}
export const DocumentGroup = ({ idStructuringDocument }: Props) => {

    const single = Singleton.getInstance()
    const { setLoading } = useDashboardStore();
    const service = new ContractService();
    const [lista, setLista] = useState([]);
    const [bean, setBean] = useState<any>({});
    const [modal, setModal] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalConfirm, setModalConfirm] = useState<any>({ name: 'Confirmar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });


    const states = processState;

    const idBean = 'IDDocumentGroup';

    const head = [
        { title: 'ID', property: idBean },
        { title: 'Tipo Requisito', property: 'IDRequirementType' },
        { title: 'Nombre', property: 'Name' },
        { title: 'Tipo Requerimiento', property: 'LegalRequirementTypeName' },
        { title: 'Requerimiento', property: 'RequirementTypeName' },
        { title: 'Description', property: 'Descripción' },

    ]

    useEffect(() => {
        getLista();
    }, [idStructuringDocument])

    const setRowBean = (bean: any) => {
       //console.log("Row : ", bean);
        setBean(bean);

    }

    const getLista = () => {

        setLoading(true);
        service.getDocumentGroupCatalog(idStructuringDocument).subscribe(
            (resp: any) => {
                setLista([]);

                if (resp.DataBeanProperties.ObjectValue) {
                    let lis: any = resp.DataBeanProperties.ObjectValue;
                    setLista(lis);
                }

                setLoading(false);
            }
        );
    }

    const agregarItem = () => {

        let b = {
            Name: '',
            Description: '',
            IDStructuringDocument: idStructuringDocument,
            IDRequirementType: 0,
            IDEmployee: null,
            LegalRequirementType: 0
        }

        setBean(b);
        setModal({ ...modal, ['view']: true });

    }
    const editarItem = () => {

        setModal({ ...modal, ['view']: true });

    }
    const eliminarItem = () => {

        setLoading(true);
        service.deleteProcess(bean[idBean]).subscribe(
            (resp: any) => {
                setLoading(false);
                if (resp) {
                    getLista();
                }
            }
        );
        setModalConfirm({ ...modalConfirm, ['view']: false });

    }
    const updateItem = () => {

        setModal({ ...modal, ['view']: false });

       //console.log("Update ", bean);

        setLoading(true);
        service.updateProcess(bean).subscribe(
            (resp: any) => {
                setLoading(false);
                if (resp.DataBeanProperties.ObjectValue) {
                    getLista();
                }
            });

    }

    const updateBottons = (bean: any) => {
        setBean(bean);
        updateItem();
    }

    const renderButtons = () => {
        return (
            <>
                <BasicButton value="Agregar" icon="ri-file-add-line" eventClick={agregarItem} ></BasicButton>
                {bean[idBean] &&
                    <>
                        <BasicButton value="Editar" icon="ri-edit-2-line" eventClick={editarItem} ></BasicButton>
                        <BasicButton value="Eliminar" icon="ri-eraser-line" eventClick={() => { setModalConfirm({ ...modalConfirm, ['view']: true }); }} ></BasicButton>

                    </>
                }
            </>
        )
    }

    const iconButtonsItem = [
        { nombre: 'Agregar', icono: 'ri-file-add-line', evento: agregarItem }
    ]

    const listaBotones = () => {
        const botones = [
            { titulo: 'Editar', icono: 'ri-edit-2-line', visible: true, evento: () => { setModal({ ...modal, "view": true }); } },
            { titulo: 'Eliminar', icono: 'ri-eraser-line', visible: true, evento: () => { setModalConfirm({ ...modalConfirm, "view": true }); } },
        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }

    return (
        <>

            <div className="row">
                <div className="col-md-12 p-2">
                    <BasicTable iconButtons={iconButtonsItem} listButtons={listaBotones()} head={head} body={lista} rowSet={setRowBean}></BasicTable>
                </div>
            </div>


            <ModalConfirm modal={modalConfirm} updateModal={setModalConfirm} eventModal={eliminarItem}>
                {bean.Name}
            </ModalConfirm>

            <Modal modal={modal} updateModal={setModal} eventModal={updateItem}>
                <div className="row">

                    <div className="col-md-12">
                        <label className="form-label">Nombre</label>
                        <input type="text" className="form-control" value={bean.Name} onChange={(e) => { setBean({ ...bean, ['Name']: e.target.value }) }} />
                    </div>

                    <div className="col-md-6">
                        <SelectLegalRequirementTypeConstants idSelector={bean.LegalRequirementType} onChange={(e: number) => {
                            setBean({ ...bean, "LegalRequirementType": e })
                        }}></SelectLegalRequirementTypeConstants>
                    </div>
                    <div className="col-md-6">
                        <SelectRequirementType idPadre={bean.LegalRequirementType} idSelector={bean.IDRequirementType} onChange={(e: number) => {
                            setBean({ ...bean, "IDRequirementType": e })
                        }}></SelectRequirementType>
                    </div>

                    <div className="col-md-12">
                        <label className="form-label">Descripción</label>
                        <textarea className="form-control" value={bean.Description} onChange={(e) => { setBean({ ...bean, ['Description']: e.target.value }) }}></textarea>
                    </div>

                </div>
            </Modal>
        </>
    )
}