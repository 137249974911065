import { DataBean } from "../DataBean";

export class TaxCode extends DataBean {


    public async getTaxCodeCatalog(idTaxCodeScheme: number): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_getTaxCodeCatalog_Number", [idTaxCodeScheme]);
    }


}