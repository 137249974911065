import { useState, useEffect } from 'react';
import { AccountEditor } from '../../editor/AccountEditor';
import { ModalConfirm } from '../../theme/Component/ModalConfirm';
import { Singleton } from '../../../core/services/Singleton';

interface Props {
    listP: any;
    listRoles?: any;
    updateLista: Function;
    notVisibleRol?: boolean;
}
export const ListEmployee = ({ listP, listRoles, updateLista, notVisibleRol }: Props) => {


    const single = Singleton.getInstance()
    const [bean, setBean] = useState<any>({});
    const [acc, setAcc] = useState<any>({});
    const [idRol, setIDRol] = useState<number>(0);
    const [lista, setLista] = useState<any>([]);
    const [modalConf, setModalConf] = useState<any>({ name: 'Confirmacion', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });

    useEffect(() => {
        if (listRoles.length > 0) {
            setIDRol(listRoles[0].id);
        }
    }, [])

    useEffect(() => {
        try {
            setLista(listP ? JSON.parse(listP) : []);
        } catch (error) {
            setLista([]);

        }

    }, [listP])

    const agregarFuncionario = (account: any) => {
        account.rol = idRol;
       //console.log(account);
        let contiene = 0;

        lista.forEach((acc: any) => {
            if (acc.IDAccount == account.IDAccount) {
                contiene++;
                acc.State = 'ACTIVO';
                acc.CreateBy = single.getAccount().EntityName;
                acc.Since = single.getCurrenDate();
            }
            setLista(lista);
            updateLista(lista);

        });

        if (contiene === 0 && account.IDAccount) {
            let l: any = lista;
            l.push({
                IDAccount: account.IDAccount,
                EntityName: account.EntityName,
                rol: idRol ?? 0,
                State: 'ACTIVO',
                CreateBy: single.getAccount().EntityName,
                Since: single.getCurrenDate()
            })
           //console.log("lista", l);
            setAcc({});
            setLista(l);
            updateLista(l);

        }
    }

    const renderRol = (id: number) => {
        let name = "";

        listRoles.forEach((element: any) => {
            if (element.id === id)
                name = element.name;
        });

        return name;
    }

    const removeFile = () => {



        bean.State = 'ELIMINADO';
        bean.SinceDelete = single.getCurrenDate();
        bean.DeleteBy = single.getAccount().EntityName;

        const nuevaLista = lista.filter((objeto: any) => objeto.IDAccount !== bean.IDAccount);

        nuevaLista.push(bean);
       //console.log("elimiando ", bean);

        updateLista(nuevaLista);
        setLista(nuevaLista);
        setModalConf({ ...modalConf, "view": false });
    }

    return (
        <div className="row">
            {(listRoles && !notVisibleRol) &&
                <div className="col-md-6">
                    <label>Rol</label>
                    <select className='form-select' value={idRol} onChange={(e) => {
                        setIDRol(parseInt(e.target.value));
                    }} >
                        {listRoles.map((item: any) => {
                            return (
                                <option value={item.id}>{item.name}</option>
                            )
                        })}
                    </select>
                </div>
            }
            <div className={`col-md-${notVisibleRol ? 12 : 6}`}>
                <AccountEditor label="Funcionario" idAccount={0} onChange={agregarFuncionario} />
            </div>
            <div className="col-md-12 mt-4">
                <ul className="list-group">
                    {lista.map((acc: any, index: number) => {
                        return (
                            <li key={index} className="list-group-item" aria-disabled="true">
                                <div className="d-flex align-items-center">
                                    <div className="flex-shrink-0">
                                        <img src="./assets/images/core/avatar.png" alt="" className="avatar-xs rounded-circle" />
                                    </div>
                                    <div className="flex-grow-1 ms-2">

                                        {acc.EntityName} | <strong>{acc.rol && renderRol(acc.rol)}</strong> <br />
                                        {acc.Since}| {acc.State == 'ELIMINADO' ? <strong className='text-danger'>Eliminado : <small>{acc.DeleteBy}</small></strong> : <strong className='text-success'>Activo : <small>{acc.CreateBy}</small></strong>}
                                    </div>
                                    <div className="flex-shrink-0">

                                        <span className="text-danger cursor" onClick={() => { setBean(acc); setModalConf({ ...modalConf, "view": true }) }} >Eliminar</span>
                                    </div>
                                </div>
                            </li>
                        )
                    })}

                </ul>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <ModalConfirm modal={modalConf} updateModal={setModalConf} eventModal={removeFile} >
                        Eliminar Funcionario
                    </ModalConfirm>
                </div>
            </div>
        </div>
    )
}