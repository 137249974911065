import { useEffect, useState } from "react";

import useDataBean from "../../../core/services/dataBean/useDataBean";
import { CashReimbursementServices } from "../cellEditor/CashDesk/CashReimbursement/CashReimbursement-service";
import { toast } from "react-toastify";
import { Singleton } from "../../../core/services/Singleton";
import { ListMenu } from "../../theme/Component/ListMenu";
import { BasicTable, MASK_DATE, MASK_MONEY, MASK_NUMBER } from "../../theme/Component/BasicTable";
import { BasicPage } from "../../theme/Component/BasicPage";
import { Modal } from "../../theme/Component/Modal";

import { ModalConfirm } from "../../theme/Component/ModalConfirm";
import { _AccountantConcept, _FiscalResponsability, _FiscalResponsabilityType } from "../../../core/services/dataBean/EntityCatalog";

import { _AdquisitionBeneficiary, _AdquisitionProject, _ArticleClassType, _MeasurementUnitCode, _ProcessStep, _ProjectBeneficiary } from "../../../core/services/dataBean/EntityCatalogVdos";
import { SelectAdquisitionArticleConstants } from "../../contract/select/SelectAdquisitionArticleConstants";
import useModal, { MODAL_md, MODAL_SM } from "../../theme/Component/hooks/useModal";
import { InputDate } from "../../theme/Input/InputDate";
import { AccountEditor } from "../../editor/AccountEditor";
import { SelectFunctionalID } from "../../admin/configuracion/functionalID/SelectFunctionalID";
import { RequirementStep } from "../RequirementStep/RequirementStep";


interface Props {
    IDProcess: number;
}

export const ProcessStep = ({ IDProcess }: Props) => {

    const processstep = useDataBean(_ProcessStep);

    const single = Singleton.getInstance();
    const [lista, setLista] = useState<any>([]);

    const [requirementStep, setRequirementStep] = useState<any>({ name: '', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modal, setModal] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalC, setModalC] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const modalConfirm = useModal("Orden", MODAL_SM);
    const modalEditar = useModal("Orden", MODAL_md);


    useEffect(() => {
        getLista()

    }, [IDProcess])


    const getLista = () => {

        processstep.instance.getProcessStepCatalogPorPropiedades({ IDProcess: IDProcess, State: 1 }, null).then(
            (resp: any) => {
                if (resp) {
                    setLista(resp)
                   //console.log(resp)

                }

            }
        )

    }



    const create = () => {
        processstep.setBean({

            State: 1,
            IDProcess: IDProcess
        });

        modalEditar.setModal({ ...modalEditar.modal, "view": true });
    }


    // const deleteItem = () => {
    //     AdquisitionProject.deleteItemVoid(AdquisitionProject.bean.IDMeasurementUnitCode).then(
    //         (resp: any) => {
    //             getLista();
    //             setModalC({ ...modalC, "view": false });
    //         }
    //     )

    // }


    const editaItem = () => {
       //console.log(processstep.bean);
        processstep.update().then(
            (resp: any) => {
                getLista();
                modalEditar.setModal({ ...modalEditar.modal, "view": false });
            }
        ).catch(err => {
            toast.error(err);
        })

    }
    const deleteItem = () => {
        processstep.bean.State = 2;
       //console.log(processstep.bean);
        editaItem();
        modalConfirm.close();
    }


    // const editaItem = () => {
    //     MeasurementUnitCode.instance.updateMeasurementUnitCode(MeasurementUnitCode.bean.IDMeasurementUnitCode).then(
    //         (resp: any) => {
    //             getLista();
    //             setModal({ ...modal, "view": false });
    //         }
    //     ).catch(err => {
    //         toast.error(err);
    //     })

    // }

    const head = [
        { title: 'ID', property: 'IDProcessStep', visible: false, mask: MASK_NUMBER },
        { title: 'Nombre', property: 'Name', visible: true },
        { title: 'Orden', property: 'StepOrder', visible: true, mask: MASK_MONEY },
        { title: 'Descripción', property: 'Description', visible: true },




    ]

    const iconButtonsItem = [
        { nombre: 'Agregar', visible: single.canCreate(), icono: 'ri-file-add-line', evento: create }
    ]
    const listaBotones = () => {
        const botones = [

            { titulo: 'Editar', icono: 'ri-edit-2-line', visible: single.canEdit(), evento: () => { modalEditar.setModal({ ...modalEditar.modal, "view": true }) } },
            { titulo: 'Eliminar', icono: 'ri-delete-bin-line', visible: single.canDelete(), evento: () => { setModalC({ ...modalC, "view": true }) } },
            { titulo: 'Requerimiento', icono: 'ri-edit-2-line', visible: single.canDelete(), evento: () => { setRequirementStep({ ...requirementStep, "view": true }) } },

        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }



    return (
        <BasicPage titulo="Pasos para Configuraración">
            <div className="row">

                <div className="col-md-12">

                    <BasicTable body={lista} head={head} rowSet={processstep.setBean} listButtons={listaBotones()} iconButtons={iconButtonsItem}></BasicTable>
                    <Modal modal={modalEditar.modal} updateModal={modalEditar.setModal} eventModal={editaItem}>
                        <>
                            <div className="row">

                                {/* <div className="col-md-6">
                                    <label>Tipo</label>

                                    <select className="form-control" value={ProjectBeneficiary.bean.Type} onChange={(e) => { ProjectBeneficiary.setBean({ ...ProjectBeneficiary.bean, 'Type': e.target.value }) }}>
                                        <option value={0}>...</option>
                                        <option value={10}>Tercero</option>
                                        <option value={11}>Área Funcional</option>
                                    </select>
                                </div> */}

                                <div className="col-md-12">
                                    <label>Nombre</label>
                                    <input type="text" name="Name" className="form-control" value={processstep.bean.Name} onChange={processstep.handleChange} />

                                </div>
                                <div className="col-md-12">
                                    <label>Orden</label>
                                    <input type="number" name="StepOrder" className="form-control" value={processstep.bean.StepOrder} onChange={processstep.handleChange} />

                                </div>
                                <div className="col-md-12">
                                    <label>Descripción</label>
                                    <textarea name="Description" className="form-control" value={processstep.bean.Description} onChange={processstep.handleChange} />
                                </div>
                            </div>



                        </>
                    </Modal>

                    <Modal modal={requirementStep} updateModal={setRequirementStep} >
                        <>
                            <RequirementStep IDProcessStep={processstep.bean.IDProcessStep} />

                        </>
                    </Modal>

                    <ModalConfirm modal={modalC} updateModal={setModalC} eventModal={deleteItem}>
                        <strong>Eliminar item</strong>
                    </ModalConfirm>
                </div>

            </div>
        </BasicPage>
    )
}