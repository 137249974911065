import { useState } from "react";
import { CardTaps } from "../../../../theme/Component/CardTaps";
import { PrintJson } from "../../../../theme/Component/PrintJson";
import { ConfigAdminFunctionalID } from "./ConfigurarAdministrador";
import { ConfigAdminPersonal } from "./ConfigAdminPersonal";
import { CostingCenter } from "./ModificarCentro";
import { ListPersonal } from "./ListPersonal";
import { BusinessRoleCatalog } from "../RolesComponents/BusinessRoleCatalog";
import { CodigosUnscpFuntionalID } from "../CodigosUnscpFuntionalID";

interface Props {
    functionaID: any;
}
export const RolesModLOption = ({ functionaID }: Props) => {


    let taps = [
        { id: 0, name: 'Roles', disable: false },
        { id: 1, name: 'Arbol uno', disable: false },
        { id: 2, name: 'Arbol dos', disable: false },

    ];

    const [indexTap, setIndexTap] = useState(0);



    return (
        <div className="row">
            <div className="col-md-12">
                <CardTaps index={indexTap} updateIndex={setIndexTap} taps={taps}>

                    {indexTap === 0 &&
                        <>
                            <BusinessRoleCatalog IDLnFunctional={functionaID.IDLn} />
                        </>

                    }
                    {indexTap === 1 &&
                        <>
                            <CodigosUnscpFuntionalID IDLnFunctional={functionaID.IDLn} />
                        </>
                    }
                    {indexTap === 2 &&
                        <>

                        </>
                    }


                </CardTaps>
            </div>
            <div className="col">
            </div>
        </div>
    )

}