import { useEffect, useState } from "react";
import { _RemoteAbstractAction, _single } from "../../../core/services/dataBean/EntityCatalog"
import useDataBean from "../../../core/services/dataBean/useDataBean"

interface Props {
    label?: string;
    name: string;
    idSelect: number | null;
    onChange: Function;
}
export const ProjectSelect = ({ idSelect, label, name, onChange }: Props) => {

    const { lista, setLista, instance } = useDataBean(_RemoteAbstractAction);
    const [id, setId] = useState<number>(NaN);

    useEffect(() => {
        instance.getProjectCatalogPorPropiedadesRender({ State: 1 }).then(
            (resp: any) => {
               //console.log(resp);

                setLista(resp);
            }
        )
    }, [])
    useEffect(() => {
        if (idSelect) {
            setId(idSelect);
        }
    }, [idSelect])

    const setValue = (e: any) => {
        setId(e.target.value);
        onChange(e);
    }

    return (
        <>
            {label != '' && <label>{label}</label>}
            <select name={name} className="form-select" value={id} onChange={setValue}>
                <option key={_single.getRandomInt()} value={NaN}>...</option>
                {lista.map((item: any, index: number) => {
                    return (
                        <option key={index} value={item.DataBeanProperties.IDProject}>{item.DataBeanProperties.Name}</option>
                    )
                })}
            </select>
        </>
    )
}