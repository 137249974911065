import { useEffect, useState } from "react";

interface Props {
    label?: string;
    name: string;
    idSelect: number | null;
    onChange: Function;
    lista: any;
    onBlurEvent?:Function;
}

export const GenericSelect = ({ idSelect, label, name, onChange, lista ,onBlurEvent}: Props) => {

    const [id, setId] = useState<number>(NaN);


    useEffect(() => {
        if (idSelect) {
            setId(idSelect);
            
        }
    }, [idSelect])

    const setValue = (e: any) => {
        setId(e.target.value);
        onChange(e);
    }
    const on_blur=(e:any)=>{
        if(onBlurEvent)
            onBlurEvent(e);
    }

    return (
        <>
            {lista ?
                <>                    
                    <select name={name} className="form-select" value={id} onChange={setValue} >
                        <option value={undefined}>...</option>
                        {lista.map((item: any, index: number) => {
                            return (
                                <option key={index} value={item.DataBeanProperties.Value}>{item.DataBeanProperties.Property}</option>
                            )
                        })}
                    </select>
                </>
                :
                <input type="text" readOnly className="form-control" placeholder="Sin opciones" />
            }
        </>
    )
}