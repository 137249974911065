import { useState, useEffect } from 'react';
import { _TaxSchemeValue } from '../../../../../core/services/dataBean/EntityCatalog';
import { ContractService } from '../../../../../core/services/ContractService';

interface Props {
    idSelector: number;
    onChange: any;
    label?: string;
}

export const TaxableArticleTypesEditor = ({ idSelector, onChange, label }: Props) => {

    const [lista, setLista] = useState<any>([])
    const [id, setID] = useState(null);
    const service = new ContractService();

    useEffect(() => {
        if (idSelector >= 0) {
            setID(idSelector);
        }
        getLista();
    }, [])

    useEffect(() => {
        onChange(id);
    }, [id])

    const setValue = (e: any) => {
        setID(parseInt(e));
        onChange(id);
    }

    const getLista = () => {
        service.getTaxableArticleTypes().subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue) {
                    setLista(resp.DataBeanProperties.ObjectValue);
                }
            }
        );
    }

    return (
        <>
            <label>{label ?? "Tipo de Articulo"}</label>
            <select className="form-select" value={id} onChange={(e) => { setValue(e.target.value) }}>
                <option value={null}>...</option>
                {lista &&
                    lista.map((item: any, index: number) => {
                        return (
                            <option key={index} value={item.DataBeanProperties.Value} >{item.DataBeanProperties.Property}</option>
                        )
                    })
                }
            </select>
        </>
    )
}