import { useEffect, useState } from "react";
import useDataBean from "../../../../../core/services/dataBean/useDataBean";
import { Singleton } from "../../../../../core/services/Singleton";
import { toast } from "react-toastify";
import { ListMenu } from "../../../../theme/Component/ListMenu";
import { BasicTable } from "../../../../theme/Component/BasicTable";
import { Modal } from "../../../../theme/Component/Modal";
import { ModalConfirm } from "../../../../theme/Component/ModalConfirm";
import { _Function } from "../../../../../core/services/dataBean/EntityCatalog";

interface Funcion {
    _IDAppointManual: number;
}

export const Function = ({ _IDAppointManual }: Funcion) => {

    const funcion = useDataBean(_Function);
    const single = Singleton.getInstance();

    const [lista, setLista] = useState<any>([]);
    const [modal, setModal] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalC, setModalC] = useState<any>({ name: 'Eliminar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });

    useEffect(() => {
        getLista();
    }, [_IDAppointManual])

    const getLista = () => {
        funcion.instance.getFunctionCatalog(null, _IDAppointManual, null).then(
            (resp: any) => {
                if (resp) {
                    setLista(resp)
                }
            }
        )

    }

    const create = () => {
        funcion.setBean({
            IDAppointmentManual: _IDAppointManual,
        });
        setModal({ ...modal, "name": "Agregar Funcion del cargo", "view": true });
    }
    const deleteItem = () => {
        funcion.instance.deleteFunction(funcion.bean).then(
            (resp: any) => {
                getLista();
                setModalC({ ...modalC, "view": false });
            }
        )

    }
    const editaItem = () => {
       //console.log(funcion.bean);
        funcion.update().then(
            (resp: any) => {
                getLista();
                setModal({ ...modal, "view": false });
            }
        ).catch(err => {
            toast.error(err);
        })

    }

    const head = [
        { title: 'ID', property: 'IDFunction', visible: true },
        { title: 'Descripción', property: 'Description', visible: true },
    ]

    const iconButtonsItem = [
        { nombre: 'Agregar', visible: single.canCreate(), icono: 'ri-file-add-line', evento: create }
    ]
    const listaBotones = () => {
        const botones = [
            { titulo: 'Editar', icono: 'ri-edit-2-line', visible: single.canEdit(), evento: () => { setModal({ ...modal, "name": `Editar Funcion del Cargo`, "view": true }) } },
            { titulo: 'Eliminar', icono: 'ri-delete-bin-line', visible: single.canDelete(), evento: () => { setModalC({ ...modalC, "view": true }) } },

        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }


    return (
        <>
            <div className="row">
                <div className="col-md-12">
                    <BasicTable body={lista} head={head} rowSet={funcion.setBean} listButtons={listaBotones()} iconButtons={iconButtonsItem}></BasicTable>
                </div>
            </div>

            <Modal modal={modal} updateModal={setModal} eventModal={editaItem}>
                <div className="row">
                    <div className="col-md-12">
                        <label>Descripción: </label>
                        <textarea name="Description" className="form-control" value={funcion.bean.Description} onChange={funcion.handleChange} />
                    </div>
                </div>
            </Modal>
            <ModalConfirm modal={modalC} updateModal={setModalC} eventModal={deleteItem}>
                <strong>Eliminar item con el ID: {funcion.bean.IDFunction}</strong>
            </ModalConfirm>
        </>
    )
}