import { useEffect, useState } from "react";
import { ListMenu } from "../../theme/Component/ListMenu";
import { TalentService } from "../../../core/services/TalentService";
import { Singleton } from "../../../core/services/Singleton";
import { BasicTable } from "../../theme/Component/BasicTable";
import { Modal } from "../../theme/Component/Modal";
import { ModalConfirm } from "../../theme/Component/ModalConfirm";
import { MyEditor } from "../../theme/Component/MyEditor";
import { BasicSwitch } from "../../theme/Component/BasicSwitch";

interface Props {
    QuestionTest: any;
    _idTest: number;
}

export const QuestionItems = ({ QuestionTest, _idTest }: Props) => {

    const services = new TalentService();
    const single = Singleton.getInstance();
    const [lista, setLista] = useState([]);
    const [bean, setBean] = useState<any>({});
    const [modal, setModal] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalConfirm, setModalConfirm] = useState<any>({ name: 'Confirmar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });

    useEffect(() => {
        if (QuestionTest.IDTestQuestion >= 0) {
            getLista()
        }
    }, [QuestionTest])

    const idBean = 'IDQuestionItems';

    const head = [
        { title: 'ID', property: idBean },
        { title: 'Respuesta', property: 'OptionText' }, ,
        { title: 'Peso de la respuesta', property: 'Weighing' },
        { title: '¿Es valida?', property: 'TypeValidName' },
    ]

    const setRowBean = (bean: any) => {
       //console.log("Row : ", bean);
        setBean(bean);
    }

    const renderName = (value: boolean) => {
        let type = '';
        if (value === true) {
            type = 'Verdadero'
        } else if (value === false) {
            type = 'Falso'
        }
        return type;
    }

    const getLista = () => {
        const x = {
            IDTestQuestion: QuestionTest.IDTestQuestion
        }
        single.spinner(true);
        services.getQuestionItemsCatalogPorPropiedades(x, null).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue) {
                    let x = resp.DataBeanProperties.ObjectValue;
                    x.forEach((test: any) => {
                        test.DataBeanProperties.TypeValidName = renderName(test.DataBeanProperties.IsValid)
                    });
                    setLista(x)
                }
                single.spinner(false)
            }
        )
    }

    const deleteItem = () => {
        bean.State = 2;
        updateItem();
        setModalConfirm({ ...modalConfirm, 'view': false });
    }

    const updateItem = () => {
        setModal({ ...modal, "view": false })
        single.spinner(true);
        services.updateQuestionItems(bean).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue) {
                    getLista();
                }
                single.spinner(false)
                if (bean.IDTest) {
                    single.update(bean);
                } else {
                    single.create(bean)
                }
            }
        )
    }

    const agregarItem = () => {
        let b = {
            OptionText: "",
            IDTest: _idTest,
            IsValid: false,
            IDTestQuestion: QuestionTest.IDTestQuestion,
            Weighing: 0,
            State: 1,
            Question: QuestionTest.Question,
            QuestionType: QuestionTest.QuestionType,
        }
        setBean(b);
        setModal({ ...modal, 'view': true });

    }

    const listaBotones = () => {
        const botones = [
            { titulo: 'Editar', icono: 'ri-file-list-line', visible: single.canEdit(), evento: () => { setModal({ ...modal, "view": true }) } },
            { titulo: 'Eliminar', icono: 'ri-file-user-line', visible: single.canDelete(), evento: () => { setModalConfirm({ ...modalConfirm, "view": true }) } },
        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }

    const iconButtonsItem = [
        { nombre: 'Agregar', visible: single.canCreate(), icono: 'ri-file-add-line', evento: agregarItem }
    ]


    return (
        <>
            <div className="row">
                <div className="col-12">
                    <BasicTable body={lista} head={head} rowSet={setRowBean} iconButtons={iconButtonsItem} listButtons={listaBotones()} />
                </div>
            </div>

            {modal.view &&
                <Modal modal={modal} updateModal={setModal} eventModal={updateItem}>
                    <div className="row">
                        <div className="col-md-12">
                            <label className="form-label">Respuesta</label>
                            <MyEditor val={bean.OptionText} onChange={(e: any) => { setBean({ ...bean, "OptionText": e }) }} />
                        </div>
                        <div className="col-md-4">
                            <label className="form-label">¿Es valida?</label>
                            <BasicSwitch estado={bean.IsValid} eventChange={(e: any) => { setBean({ ...bean, "IsValid": e }) }} />
                        </div>
                        <div className="col-md-8">
                            <label className="form-label">Peso de ponderado</label>
                            <input className="form-control" type="number" value={bean.Weighing} onChange={(e: any) => { setBean({ ...bean, "Weighing": e.target.value }) }} />
                        </div>
                    </div>
                </Modal>
            }
            {modalConfirm.view &&
                <ModalConfirm modal={modalConfirm} updateModal={setModalConfirm} eventModal={deleteItem}>
                    {bean.OptionText}
                </ModalConfirm>
            }
        </>
    )
}