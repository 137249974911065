import { useEffect, useState } from "react";
import { ContractService } from "../../../../core/services/ContractService"
import { BasicPage } from "../../../theme/Component/BasicPage"
import { Singleton } from '../../../../core/services/Singleton';
import { InputDate } from "../../../theme/Input/InputDate";
import { BasicSwitch } from "../../../theme/Component/BasicSwitch";
import { BasicButton } from "../../../theme/Component/BasicButton";
import { FindSupplier } from "../../FindSupplier";
import { BasicTable } from "../../../theme/Component/BasicTable";
import { Modal } from "../../../theme/Component/Modal";
import { InputMoney } from "../../../theme/Input/InputMoney";
import { ListMenu } from "../../../theme/Component/ListMenu";
import { AdquisitionArticle } from "../../adquisition-setting/AdquisitionArticle";
import { AdquisitionItemBudgetID } from "../../AdquisitionItemBudgetID";
import { OfferEmployee } from "../../FlowHiring/OfferEmployee/OfferEmployee";
import { ContractArticles } from "./ContractArticles";
import { AccountEditor } from "../../../editor/AccountEditor";
import { AdquisitionItemSelector } from "./ublinvoice/AdquisitionItemEditor/AdqusitionItemSelector";
import { toast } from "react-toastify";
import { SelectModalityNewSer } from "../../select/SelectModalityNewSer";
import { ContractTypeEditor } from "../../select/ContractTypeEditor";
import { PrintJson } from "../../../theme/Component/PrintJson";
import { _single } from "../../../../core/services/dataBean/EntityCatalog";
import { AdquisitionContractRP } from "./adquisitionContractRP/AdquisitionContractRP";
import { AdquisitionContractCDP } from "./adquisitionContractCDP/AdquisitionContractCDP";



export const RegisterContract = () => {

    const single = Singleton.getInstance();
    const contracService = new ContractService();

    const [bean, setBean] = useState<any>({});
    const [beanItem, setBeanItem] = useState<any>({});
    const [IdAcoount, setIdAcoount] = useState<number>(null)
    const [FechaIni, setIFechaIni] = useState<number>(null)
    const [FechaOut, setIFechaOut] = useState<number>(null)
    const [missingFields, setMissingFields] = useState<string[]>([]);
    const [modalCDP, setModalCDP] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalRP, setModalRP] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modal, setModal] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalConf, setModalConf] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });
    const [modalRubro, setModalRubro] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: false, format: 'modal-md' });
    const [modalArt, setModalArt] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: false, format: 'modal-lg' });
    const [modalPer, setModalPer] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: false, format: 'modal-lg' });
    const [modalRelacion, setModalRelacion] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: false, format: 'modal-lg' });

    useEffect(() => {


        initBean();

        return () => {
            setBean({});
        }
    }, [IdAcoount, FechaIni, FechaOut])


    const initBean = () => {
        setBean({
            ...bean,
            IDEmployee: single.getAccountID(),
            isLote: false,
            SeleccionDate: single.getCurrenDate(),
            IDAdquisitionContract: null,
            IDSupplier: null,
            ContractValue: null,
            Code: "",
            IDAdquisitionItem: null,
        });
    }

    const crearContrato = () => {
        //console.log(bean.IDAdquisitionItem, bean.IDSupplier, bean.IDEmployee, bean.SeleccionDate, bean.isLote, bean.Code)
        single.spinner(true);
        contracService.registerAdquisitionContractByItems(_single.getAccountID(), bean).subscribe(
            (resp: any) => {
                //console.log(resp);

                if (resp.DataBeanProperties.ErrorMessage) {
                    toast.error(resp.DataBeanProperties.ErrorMessage);
                }
                else if (resp.DataBeanProperties.ObjectValue) {
                    let tmp = resp.DataBeanProperties.ObjectValue.DataBeanProperties;
                    if (tmp.IDAdquisitionContract) {
                        bean.IDAdquisitionContract = tmp.IDAdquisitionContract;
                    }
                    actualizarContrato();
                    setModal({ ...modal, "view": false })
                }
                single.spinner(false);
            }
        )
    }
    const actualizarContrato = () => {
        single.spinner(true);
        contracService.updateAdquisitionContract(bean).subscribe(
            (resp: any) => {

                if (resp.DataBeanProperties.ErrorMessage) {
                    toast.error(resp.DataBeanProperties.ErrorMessage);
                }
                else if (resp.DataBeanProperties.ObjectValue) {

                    getAdquisitionContractRender();
                    setModal({ ...modal, "view": false })
                }
                single.spinner(false);
            }
        )
    }
    const elimiarItem = () => {
        single.spinner(true);
        let beandelete = {
            IDAdquisitionContract: bean.IDAdquisitionContract,
            State: 2,
            IDEmployeeDelete: single.getAccountID(),
            LastUpdate: single.getCurrenDate()
        }
        contracService.updateAdquisitionContract(beandelete).subscribe(
            (resp: any) => {

                if (resp.DataBeanProperties.ObjectValue) {

                    getAdquisitionContractRender();

                }
                single.spinner(false);
            }
        )
    }

    const getAdquisitionContractRender = () => {
        single.spinner(true);

        // Condición para definir el objeto 'x' según el valor de 'IdAcoount'
        let x = IdAcoount ? {
            IDAccount: IdAcoount,
            State: 1,
        } : {
            State: 1
        };

        contracService.getAdquisitionContractRender(null, null, x, FechaIni, FechaOut).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue) {
                    setLista(resp.DataBeanProperties.ObjectValue);
                }
                setModalConf({ ...modalConf, "view": false });
                single.spinner(false);
            }
        );
    }

    const clearFilter = () => {
        setIdAcoount(null)
        setIFechaIni(null)
        setIFechaOut(null)
    }

    const validateData = () => {
        bean.SeleccionDate = bean.AdquisitionContractDate;
        if (validateForm() > 0) {
            toast.error("Faltan campos obligatorios por completar");
            return;
        } else {
            if (bean.IDAdquisitionContract > 0) {
                actualizarContrato();
            }
            else {
                crearContrato();
            }
        }
    }

    const create = () => {
        setBean({
            IDEmployee: single.getAccountID(),
            isLote: false,
            SeleccionDate: single.getCurrenDate(),
            IDAdquisitionContract: null,
            IDSupplier: null,
            ContractValue: null,
            Code: "",
            IDAdquisitionItem: null,
            State: 1
        });
        setModal({ ...modal, "view": true })
    }

    const iconButtonsItem = [
        { nombre: 'Agregar', visible: single.canCreate(), icono: 'ri-file-add-line', evento: () => { create() } }
    ]

    const [lista, setLista] = useState<any>([]);

    const head = [
        { title: 'ID', property: 'IDAdquisitionContract', visible: false },
        { title: 'Valor Contrato', property: 'ContractValue', visible: true },
        { title: 'Valor CDP', property: 'CDPValue', visible: true },
        { title: 'Fecha Contrato', property: 'SeleccionDate', visible: true },
        { title: 'Dodo radicado', property: 'Code', visible: false },
        { title: 'Código contrato', property: 'InternalCode', visible: true },
        { title: 'Proveedor', property: 'Name1', visible: true },
        { title: 'Descripción', property: 'Description', visible: true },
        { title: 'No CDP', property: 'NoCDP', visible: false },
        { title: 'Fecha CDP', property: 'DateCDP', visible: false },
        { title: 'No registro presupuestal', property: 'NoRegistroPresupuestal', visible: false },
        { title: 'Fecha registro presupuestal', property: 'DateRegPresupuestal', visible: false },

    ]
    const listaBotones = () => {
        const botones = [

            { titulo: 'Editar', icono: 'ri-edit-2-line', visible: single.canEdit(), evento: () => { setModal({ ...modal, "view": true }) } },
            { titulo: 'Eliminar', icono: 'ri-eraser-fill', visible: single.canDelete(), evento: () => { setModalConf({ ...modalConf, "view": true }) } },
            { titulo: 'Personal', icono: 'ri-group-line', visible: true, evento: () => { setModalPer({ ...modalPer, "view": true, "name": "Personal a cargo" }) } },
            { titulo: 'Rubros', icono: 'ri-currency-line', visible: true, evento: () => { setModalRubro({ ...modalRubro, "view": true, "name": "Rubro Presupuestal" }) } },
            { titulo: 'Articulos Necesidad', icono: 'ri-list-unordered', visible: true, evento: () => { setModalArt({ ...modalArt, "view": true, "name": "Articulos de la necesidad" }) } },
            { titulo: 'Articulos Contrato', icono: 'ri-list-ordered text-success', visible: true, evento: () => { setModalRelacion({ ...modalRelacion, "view": true, "name": "Articulos del contrato" }) } },
            { titulo: 'Asociar RP', icono: 'ri-file-list-line', visible: true, evento: () => { setModalRP({ ...modalRP, "view": true, "name": "RP Asociados" }) } },
            // { titulo: 'Asociar CDP', icono: 'ri-file-list-line', visible: true, evento: () => { setModalCDP({ ...modalCDP, "view": true, "name": "CDP Asociados" }) } },
        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }

    const validateForm = () => {
        const requiredFields = [
            "AdquisitionContractDate", "IDAccount",
        ];
        let missing = [];
        for (let field of requiredFields) {
            if (!bean[field]) {
                missing.push(field);
            }
        }
        setMissingFields(missing);
        return missing.length
    };

    // const getIDSupplierByNit = (id) => {
    //     contracService.getSupplierByNit(id).subscribe(
    //         (resp: any) => {
    //             if (resp.DataBeanProperties.ObjectValue.DataBeanProperties) {
    //                 let x = resp.DataBeanProperties.ObjectValue.DataBeanProperties;
    //                //console.log(x.IDSupplier)
    //                 setSupplierID(x.IDSupplier)
    //             }
    //         }
    //     )
    // }

    function convertirExponencialANatural(numero) {
        if (numero === undefined || numero === null || numero === "0") {
            console.error("Número indefinido o nulo");
            return 0
        }

        if (numero.toString().includes('E')) {
            return parseInt(Number(numero).toLocaleString('fullwide', { useGrouping: false }));
        } else {
            return parseInt(numero);
        }
    }

    return (
        <>
            <BasicPage titulo="Registro Contratos">
                <div className="row">
                    <div className="col-md-4">
                        <AccountEditor label="Beneficiario" idAccount={IdAcoount} onChange={(e) => { setIdAcoount(e.IDAccount) }} />
                    </div>
                    <div className="col-md-4">
                        <InputDate notDate={true} name="desde" label="Inicio" value={FechaIni} setDate={(e: any) => { setIFechaIni(e) }} />
                    </div>
                    <div className="col-md-4">
                        <InputDate notDate={true} name="hasta" label="Final" value={FechaOut} setDate={(e: any) => { setIFechaOut(e) }} />
                    </div>

                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-4">

                                <BasicButton value="Buscar" icon="ri-search-line" eventClick={getAdquisitionContractRender} />

                            </div>
                            <div className="col-md-4">
                                <BasicButton clase="danger" icon="ri-delete-bin-2-line" eventClick={() => clearFilter()}>Limpiar Filtros</BasicButton>
                            </div>
                        </div>
                    </div>
                </div>
                <BasicTable head={head} body={lista} rowSet={setBean} iconButtons={iconButtonsItem} listButtons={listaBotones()}></BasicTable>
            </BasicPage>

            {modalRubro.view &&
                <Modal modal={modalRubro} updateModal={setModalRubro} >
                    <AdquisitionItemBudgetID iDBudgetIDType={null} adquisitionItem={bean} />
                </Modal>
            }

            {modalArt.view &&
                <Modal modal={modalArt} updateModal={setModalArt} >
                    <AdquisitionArticle viewHiringProcces={true} adquisitionItem={bean} beanItem={beanItem}></AdquisitionArticle>
                </Modal>
            }

            <Modal modal={modalRP} updateModal={setModalRP}>
                <AdquisitionContractRP adquisitionContract={bean} />
            </Modal>
            <Modal modal={modalCDP} updateModal={setModalCDP}>
                <AdquisitionContractCDP adquisitionContract={bean} />
            </Modal>

            {modalPer.view &&
                <Modal modal={modalPer} updateModal={setModalPer}>
                    <OfferEmployee IDAdquisitionItem={bean.IDAdquisitionItem} />
                </Modal>
            }

            {modalRelacion.view &&
                <Modal modal={modalRelacion} updateModal={setModalRelacion}>
                    <ContractArticles idAdquisitionContract={bean.IDAdquisitionContract} idAdquisitionItem={bean.IDAdquisitionItem} updateParent={getAdquisitionContractRender} />
                </Modal>
            }

            {modalConf.view &&
                <Modal modal={modalConf} updateModal={setModalConf} eventModal={elimiarItem}>
                    <div className="row">
                        <div className="col">
                            <strong>Eliminar registro</strong>
                        </div>
                    </div>
                </Modal>
            }

            {modal.view &&
                <Modal modal={modal} updateModal={setModal} eventModal={validateData} >
                    <div className="row">
                        <div className="col-md-12">
                            <div className="row">
                                <div className="col-12">
                                    <label className={`${missingFields.includes('IDAdquisitionItem') ? 'has-error' : ''}`}>Necesidad</label>
                                </div>
                                <div className="col-12">
                                    <AdquisitionItemSelector idAdquisitionItem={bean.IDAdquisitionItem} onChage={(item: any) => {
                                        setBean({ ...bean, "IDAdquisitionItem": item.IDAdquisitionItem });
                                        setBeanItem(item)
                                    }} />
                                </div>
                            </div>
                            <hr />
                        </div>
                        <div className="col-md-6">
                            <label className={`${missingFields.includes('AdquisitionContractDate') ? 'has-error' : ''}`}>Fecha Contrato</label>
                            <InputDate label=" " name="AdquisitionContractDate" value={bean.AdquisitionContractDate} setDate={(e) => { setBean({ ...bean, "AdquisitionContractDate": e }) }} />
                        </div>
                        {/* <div className="col-md-6">
                            <label className={`${missingFields.includes('ContractValue') ? 'has-error' : ''}`}>Valor Contrato</label>
                            <InputMoney name=" " value={bean.ContractValue} id={bean.IDAdquisitionContract} onChange={(e: any) => {
                                setBean({ ...bean, "ContractValue": e });
                                setValueItem(e);
                            }} />
                        </div> */}
                        <div className="col-md-6">
                            <label>Código Interno Contrato</label>
                            <input type="text" className="form-control" value={bean.InternalCode} onChange={(e) => { setBean({ ...bean, "InternalCode": e.target.value }) }} />
                        </div>

                        <div className="col-md-6">
                            <label>Radicado Contrato</label>
                            <input type="text" className="form-control" value={bean.Code} onChange={(e) => { setBean({ ...bean, "Code": e.target.value }) }} />
                        </div>
                        <div className="col-md-6">
                            <label className={`${missingFields.includes('IDAccount') ? 'has-error' : ''}`}>Proveedor</label>
                            <AccountEditor idAccount={bean.IDAccount} label=" " onChange={(e) => { setBean({ ...bean, "IDAccount": e.IDAccount }) }} />
                        </div>
                        {/* <div className="col-md-3 mt-3 mb-2">
                            <BasicSwitch label="Contrato por lote" estado={bean.isLote} eventChange={(e: any) => { setBean({ ...bean, "isLote": e }) }} />
                        </div>
                        <div className="col-md-12">
                            <hr />
                        </div> */}

                        {/* <div className="col-md-6">
                            <label>Valor CDP</label>
                            <InputMoney name="" value={bean.CDPValue} id={bean.IDAdquisitionContract} onChange={(e: number) => { setBean({ ...bean, "CDPValue": e }) }} />
                        </div> */}
                        {/* <div className="col-md-6">
                            <label>Número CDP</label>
                            <input type="text" className="form-control" value={bean.NoCDP} onChange={(e) => { setBean({ ...bean, "NoCDP": e.target.value }) }} />
                        </div>
                        <div className="col-md-6">
                            <InputDate label="Fecha CDP" name="fechacdp" value={bean.DateCDP} setDate={(e) => { setBean({ ...bean, "DateCDP": e }) }} />
                        </div>
                        <div className="col-md-12">
                            <hr />
                        </div>
                        <div className="col-md-6">
                            <label>Número registro presupuestal</label>
                            <input type="text" className="form-control" value={bean.NoRegistroPresupuestal} onChange={(e) => { setBean({ ...bean, "NoRegistroPresupuestal": e.target.value }) }} />
                        </div>
                        <div className="col-md-6">
                            <InputDate label="Fecha registro presupuestal" name="fecharp" value={bean.DateRegPresupuestal} setDate={(e) => { setBean({ ...bean, "DateRegPresupuestal": e }) }} />
                        </div> */}
                        <div className="col-md-6">
                            <SelectModalityNewSer label={'Modalidad'} idSelector={bean.IDHiringModality} onChange={(e) => { setBean({ ...bean, "IDHiringModality": e }) }} />
                        </div>
                        <div className="col-md-6">
                            <ContractTypeEditor label={'Tipo Contrato'} idSelector={bean.IDContractType} onChange={(e) => { setBean({ ...bean, "IDContractType": e }) }} />
                        </div>

                        {/* <div className="col-md-6">
                            <BasicButton icon="" clase="danger" eventClick={initBean}>Limpiar</BasicButton>
                        </div> */}
                    </div>
                    {/* <PrintJson json={bean}/> */}
                </Modal>
            }

        </>
    )
}