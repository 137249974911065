import { useEffect, useState } from "react";
import { Singleton } from "../../../../core/services/Singleton";
import useDataBean from "../../../../core/services/dataBean/useDataBean";
import { _TaxCode } from "../../../../core/services/dataBean/EntityCatalog";
import { TaxCodeSchemeEditor } from "../TaxCodeScheme/TaxCodeScheme.editor";
import { Modal } from "../../../theme/Component/Modal";
import { BasicButton } from "../../../theme/Component/BasicButton";
import usePaginator from "../../../theme/Component/usePaginator";
import { PrintJson } from "../../../theme/Component/PrintJson";
import { BasicMultiSelectTable } from "../../../theme/Component/BasicMultiSelecTable";

interface Props {
    label?: string;
    _onChange: Function;
}

export const TaxCodeMultiEditor = ({ label, _onChange }: Props) => {

    const { lista, setLista, bean, setBean, instance } = useDataBean(_TaxCode);
    const single = Singleton.getInstance();
    const paginador = usePaginator();

    const [codeScheme, setCodeScheme] = useState<number>(null);
    const [Filter, SetFilter] = useState('');
    const [Code, SetCode] = useState('');
    const [Register, SetRegister] = useState(15);

    const [taxCodeListName, setTaxCodeListName] = useState<any[]>([]);
    const [listaResult, setListaResult] = useState<any>([]);

    const [modal, setModal] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });

    useEffect(() => {
        if (typeof codeScheme === 'number' && codeScheme >= 0 && !isNaN(codeScheme)) {
            getLista();
        }
    }, [codeScheme]);

    const getLista = () => {
        instance.getTaxCodeCatalogPaginador(codeScheme, Code ? Code : null, Filter ? Filter : null, paginador.page, Register).then(
            (resp) => {
                setLista(resp.List)
                let paginas = resp.NumberOfPages;
                paginador.setPages(paginas);
            }
        )
    }

    useEffect(() => {
        if (paginador.page > 0) {
            getLista();
        }
    }, [paginador.page])

    const saveInfo = () => {
        setModal({ ...modal, "view": false })
    }

    const removeItem = (id: number) => {
        const updatedMultiValue = listaResult.filter(
            (item: any) => item.DataBeanProperties.IDTaxCode !== id
        );

        const updatedMultiNames = taxCodeListName.filter(
            (entry: any) => entry.IDTaxCode !== id
        );

        setTaxCodeListName(updatedMultiNames);
        setListaResult(updatedMultiValue);
    };

    const head = [
        { title: 'ID', property: 'IDTaxCode' },
        { title: 'Código', property: 'Code' },
        { title: 'Nombre', property: 'Name' },
        { title: 'Descripción', property: 'Description' },
        { title: 'Desde', property: 'Since' },
    ]

    useEffect(() => {
        if (listaResult.length > 0) {
            const newTaxCodeList = listaResult.map((item: any) => ({
                IDTaxCode: item.DataBeanProperties.IDTaxCode,
                CodeName: `${item.DataBeanProperties.Code} - ${item.DataBeanProperties.Name}`
            }));

            setTaxCodeListName((prevListNames) => {
                const existingIDs = new Set(prevListNames.map(item => item.IDTaxCode));
                const filteredNewTaxCodes = newTaxCodeList.filter(item => !existingIDs.has(item.IDTaxCode));
                return [...prevListNames, ...filteredNewTaxCodes];
            });
        }
    }, [listaResult]);


    return (
        <>
            <div className="row">
                <div className="col-12">
                    <label>{label ?? "Ciudades"}</label>
                    <div className="form-control text-wrap" style={{ maxHeight: "135px", overflow: "auto" }} onClick={() => setModal({ ...modal, "view": true })}>
                        {taxCodeListName.length > 0 ? (
                            taxCodeListName.map((item, index) => (
                                <span key={index} className="badge m-1 btn btn-primary d-flex align-items-center " style={{ maxWidth: "fit-content" }}>
                                    <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                        {item.CodeName}
                                    </div>
                                    <button
                                        type="button"
                                        className="btn btn-sm btn-danger ms-2"
                                        style={{ marginLeft: '8px' }}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            removeItem(item.IDTaxCode);
                                        }}
                                    >
                                        &times;
                                    </button>
                                </span>
                            ))
                        ) : (
                            <span>Seleccionar...</span>
                        )}
                    </div>
                </div>
            </div>

            {modal.view && (
                <Modal onSubmitLabel="Guardar Selección" modal={modal} updateModal={setModal} eventModal={() => { saveInfo() }}>
                    <div className="row">
                        <div className="col-6 mb-2">
                            <TaxCodeSchemeEditor idSelector={codeScheme} onChange={(e) => { setCodeScheme(e) }} />
                        </div>
                        <div className="col-md-6">
                            <label>Filtrar por Nombre</label>
                            <input type="text" name="Filter" className="form-control" value={Filter} onChange={(e) => { SetFilter(e.target.value) }} />
                        </div>
                        <div className="col-md-6">
                            <label>Filtrar por Código</label>
                            <input type="text" name="Code" className="form-control" value={Code} onChange={(e) => { SetCode((e.target.value)) }} />
                        </div>
                        <div className="col-md-6">
                            <label>Cantidad Registro por pagina</label>
                            <input type="number" name="Value" className="form-control" value={Register} onChange={(e) => { SetRegister(parseInt(e.target.value)) }} />
                        </div>
                        <div className="col-md-6 mt-4">
                            <BasicButton icon="ri-search-line" eventClick={getLista}>Buscar</BasicButton>
                        </div>

                        <div className="col-12 mt-2">
                            <BasicMultiSelectTable keyId="IDTaxCode" body={lista} bodyResult={listaResult} head={head}
                                updateValues={({ bodyResult }) => {
                                    setListaResult(bodyResult);
                                    _onChange(bodyResult)
                                }}
                                pageTop={paginador.page} next={paginador.next} back={paginador.back} records={paginador}
                            />
                        </div>
                    </div>
                </Modal>
            )}
        </>
    );
}