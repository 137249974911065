import { useEffect, useState } from 'react'
import { _years } from '../../../modules/admin/perfil/elements/PayrollProcess'

interface Props {
    value?: number,
    onChange: (number) => void
    disabled?: boolean
    size?: 'sm' | 'md' | 'lg'
    tittle?: string;
}

export const YearSelector = ({ value, disabled, onChange, size = 'md', tittle }: Props) => {
    const [val, setVal] = useState<number>(value || new Date().getFullYear());

    useEffect(() => {
        setVal(value || new Date().getFullYear());
    }, [value]);

    useEffect(() => {
        onChange(val);
    }, [val]);

    return (
        <>
            <label>{tittle ?? "Año a consultar"}</label>
            <select
                className={`form-select form-select-${size}`}
                id="yearSelector"
                disabled={disabled}
                value={val}
                onChange={(e) => { setVal(parseInt(e.target.value)) }}
            >
                {_years.map((_e) => (
                    <option value={_e} key={_e}>
                        {_e}
                    </option>
                ))}
            </select>
        </>
    );
};
