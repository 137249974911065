import { useEffect, useState } from "react"
import { BasicPage } from "../../../../theme/Component/BasicPage"
import { ImportUBLInvoice } from "./ImportUBLInvoice";
import { CreateUBLInvoice } from "./CreateUBLInvoice/CreateUBLInvoice";
import { SearchUBLInovice } from "./CreateUBLInvoice/SearchUBLInovice";

interface Props {
    _onChange?: Function;
    idAccount?: number;
    _entry?: "Accounting" | "CashReimbursement";
}

export const MakeUBLInvoice = ({ _onChange, idAccount, _entry = "Accounting" }: Props) => {



    const [step, setStep] = useState<any>({});


    const [card, setCard] = useState<any[]>([
        { id: 1, name: `Crear Factura Electrónica`, descripcion: `Crear documento`, boton: 'Entrar' },
        { id: 2, name: "Importar Factura Electrónica", descripcion: "Documento electrónico y representación pdf", boton: 'Entrar' },
        { id: 3, name: "Importar Contenedor de Facturas Electrónicas", descripcion: "Importa y procesa múltiples facturas", boton: 'Entrar' },
        { id: 4, name: "Consultar Facturas", descripcion: "Consultar facturas por el proveedor", boton: 'Entrar' },
    ]);

    useEffect(() => {
        setStep(
            { id: 0, name: '' }
        );
    }, [])

    return (
        <div className="row">
            <div className="col-12">

                <BasicPage titulo="Factura Electrónica">
                    <div className="row">
                        <div className="col">
                            {step.id > 0 &&
                                <>
                                    <div className="d-flex justify-content-around">
                                        <div>

                                            <h5> {step.name}</h5>

                                        </div>
                                        <div>
                                            <a href="javascript:void(0)" className="btn btn-primary btn-sm" onClick={() => { setStep({ id: 0 }) }}>Regresar</a>
                                        </div>
                                    </div>
                                    <hr />
                                </>

                            }
                        </div>
                    </div>
                    {step.id == 0 &&
                        <div className="row">
                            {card.map((item: any) => {
                                return (
                                    <div className="col-xxl-4 col-md-6">
                                        <div className="card card-body">
                                            <div className="flex-grow-1 ms-2">
                                                <h5>{item.name}</h5>
                                                <p>{item.descripcion}</p>
                                                <a href="javascript:void(0)" className="btn btn-primary btn-sm" onClick={() => { setStep(item) }}>{item.boton}</a>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    }
                    {step.id === 1 &&
                        <>
                            <CreateUBLInvoice _idAccount={idAccount} _onChange2={_onChange} entry={_entry} />
                        </>
                    }
                    {step.id === 2 &&
                        <>
                            <ImportUBLInvoice _onChange2={_onChange} entry={_entry} />
                        </>
                    }
                    {step.id === 3 &&
                        <>
                            <ImportUBLInvoice _multipleUBLs={true} _onChange2={_onChange} entry={_entry} />
                        </>
                    }
                    {step.id === 4 &&
                        <SearchUBLInovice _idAccount={idAccount} _onChange2={_onChange} />
                    }
                </BasicPage>
            </div>
        </div>
    )
}