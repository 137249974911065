import { useEffect, useState } from "react";
import useDataBean from "../../../../core/services/dataBean/useDataBean";
import { Singleton } from "../../../../core/services/Singleton";
import { BasicPage } from "../../../theme/Component/BasicPage";
import { _EmployeeContract, _ScheduledDiscount } from "../../../../core/services/dataBean/EntityCatalog";
import { SelectFunctionalArea } from "../../../admin/configuracion/FunctionalArea/SelectFunctionalArea";
import { BasicButton } from "../../../theme/Component/BasicButton";
import { InputDate } from "../../../theme/Input/InputDate";
import { AccountEditor } from "../../../editor/AccountEditor";
import { toast } from "react-toastify";
import { EmployeeDiscountTypeEditor } from "../../ParametrosTalentoHumano/EmployeeDiscountType/EmployeeDiscountType.editor";
import { BasicTable, MASK_DATE, MASK_MONEY, MASK_NUMBER } from "../../../theme/Component/BasicTable";
import { ListMenu } from "../../../theme/Component/ListMenu";
import { ModalConfirm } from "../../../theme/Component/ModalConfirm";
import { Modal } from "../../../theme/Component/Modal";
import { BasicSwitch } from "../../../theme/Component/BasicSwitch";
import { DateInput } from "../../../../core/shared/inputs/DateInput";
import { EmployeeBonusTypeConstatsEditor } from "../../ParametrosTalentoHumano/EmployeeBonus/Editors/EmployeeBonusTypeConstats.edtior";
import { PrintJson } from "../../../theme/Component/PrintJson";


export const ScheduledDiscount = () => {

    const scheduledDiscount = useDataBean(_ScheduledDiscount);
    const single = Singleton.getInstance();
    const [account, setAccount] = useState<number>(null);
    const [type, setType] = useState<number>(null);
    const [useBeneficiary, setUseBeneficiary] = useState<boolean>(false);
    const [dates, setDates] = useState<any>(null);
    const [lista, setLista] = useState<any>([]);
    const [missingFields, setMissingFields] = useState<string[]>([]);
    const [modal, setModal] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalConcepts, setModalConcepts] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-md' });
    const [modalC, setModalC] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });

    const getLista = () => {
        scheduledDiscount.instance.getEmployeeContributions(account, type, dates, null).then(
            (resp) => {
                let x = resp;
                x.forEach((descuentos) => {
                    descuentos.DataBeanProperties.DiscountTypes = (descuentos.DataBeanProperties.EmployeeDiscountTypeCode ?? "") + " - " + (descuentos.DataBeanProperties.employeeDiscountTypeName ?? "")
                });
                setLista(resp)
            }
        )
    }

    const validateGetLista = () => {
        if (!dates) {
            toast.error("Fecha no valida")
        } else if (!type) {
            toast.error("Mes no valido")
        } else {
            getLista();
        }
    }

    const create = () => {
        scheduledDiscount.setBean({
            DiscountBase: 100,
            Periodic: true

        });
        setModal({ ...modal, "view": true, "name": `Agregar Aportes y Descuentos Programados` });
    }

    const editaItem = () => {
       //console.log(scheduledDiscount.bean);
        scheduledDiscount.instance.updateEmployeeContribution(scheduledDiscount.bean).then(
            (resp: any) => {
                getLista();
                setModal({ ...modal, "view": false });
                setModalConcepts({ ...modalConcepts, "view": false })
            }
        ).catch(err => {
            toast.error(err);
        })
    }

    const deleteItem = () => {
        scheduledDiscount.instance.deleteEmployeeContribution(scheduledDiscount.bean.IDContribution).then(
            (resp: any) => {
                getLista();
                setModalC({ ...modalC, "view": false });
            }
        )
    }

    const listaBotones = () => {
        const botones = [
            {
                titulo: 'Editar Aportes/Descuentos', icono: 'ri-file-list-line', visible: true, evento: () => {
                    setModal({ ...modal, "name": "Editar Aportes/Descuentos", "view": true })
                    scheduledDiscount.setBean({
                        ...scheduledDiscount.bean,
                        IDEmployee: single.getAccountID(),
                    });
                }
            },
            { titulo: 'Eliminar Aportes/Descuentos', icono: 'ri-delete-bin-line', visible: single.canDelete(), evento: () => { setModalC({ ...modalC, "name": "Eliminar Aportes/Descuentos", "view": true }) } },
            { titulo: 'Definir referencias externas del descuento', icono: 'ri-command-line', visible: single.canDelete(), evento: () => { setModalConcepts({ ...modalConcepts, "name": "Definir referencias externas del descuento", "view": true }) } },
        ]

        return (
            <ListMenu listaBotones={botones} />
        )
    }

    const iconButtonsItem = [
        { nombre: 'Agregar', visible: single.canCreate(), icono: 'ri-file-add-line', evento: create }
    ]

    const head = [
        { title: 'ID', property: 'IDContribution', visible: true, mask: MASK_NUMBER },
        { title: 'Aplicar', property: 'Apply', visible: true },
        { title: 'Prioridad', property: 'Priority', visible: true },
        { title: 'Aplicar Mensual/Quincenal', property: 'DiscountPeriod', visible: true },
        { title: 'Tipo Descuento', property: 'DiscountTypes', visible: true },
        { title: 'Empleado', property: 'AccountName', visible: true },
        { title: 'Referencia/Proceso', property: 'ProcessCode', visible: true },
        { title: 'Peticionario/Demandante', property: 'PetitionerName', visible: true },
        { title: 'Beneficiario', property: 'BeneficiaryName', visible: true, },
        { title: 'Autorizar Pago a', property: 'Beneficiary2Name', visible: true },
        { title: 'Descripción', property: 'Description', visible: true },
        { title: 'Desde', property: 'Since', visible: true, mask: MASK_DATE },
        { title: 'Hasta', property: 'UptoDate', visible: true, mask: MASK_DATE },
        { title: 'Tipo', property: 'TypeName', visible: true },
        { title: 'Vr Descuento', property: 'DiscountValue', visible: true, mask: MASK_MONEY },
        { title: '% Base Salario', property: 'DiscountBase', visible: true, mask: MASK_NUMBER },
        { title: 'Aporte', property: 'Periodic', visible: true },
        { title: 'Descontar hasta', property: 'MaxDiscount', visible: true, mask: MASK_MONEY },
        { title: 'Vr Renta Excenta', property: 'ExemptBase', visible: true, mask: MASK_MONEY },
        { title: 'Base Retención', property: 'TaxHoldValue', visible: true },
        { title: 'Usar Cuenta Bancaria de Beneficiario', property: 'UseBeneficiary', visible: true },
    ]

    const validateData = () => {
        if (validateForm() > 0) {
            toast.error("Faltan campos obligatorios por completar");
            return;
        } else {
            editaItem();
        }
    }

    const validateForm = () => {
        const requiredFields = [
            "IDType", "IDAccount", "Since", "UptoDate", "Type", "DiscountValue", "DiscountBase",
        ];

        let missing = [];
        for (let field of requiredFields) {
            if (scheduledDiscount.bean[field] === null || scheduledDiscount.bean[field] === undefined) {
                missing.push(field);
            }
        }

        setMissingFields(missing);
        return missing.length;
    };


    const setRowBean = (bean: any) => {
        scheduledDiscount.setBean(bean);
    }

    return (
        <>
            <BasicPage titulo="Aportes y Descuentos Programados">
                <div className="row mb-2">
                    <div className="col-4">
                        <label>Empleado</label>
                        <AccountEditor label=" " idAccount={account} onChange={(e) => { setAccount(e.IDAccount) }} />
                    </div>
                    <div className="col-3">
                        <EmployeeDiscountTypeEditor label="Concepto de Descuento" idSelector={type} onChange={(e) => { setType(e.IDType) }} />
                    </div>
                    <div className="col-3">
                        <label>Fecha</label>
                        <InputDate name="InitContract" value={dates} notDate={true} setDate={(e) => { setDates(e) }} />
                    </div>
                    <div className="col-2 d-flex">
                        <div className="row d-flex align-content-end">
                            <BasicButton icon="ri-search-line" eventClick={validateGetLista} >Buscar</BasicButton>
                        </div>
                    </div>
                </div>
                <BasicTable iconButtons={iconButtonsItem} listButtons={listaBotones()} head={head} body={lista} rowSet={(e) => { setRowBean(e) }} />
            </BasicPage>

            {modal.view &&
                <Modal modal={modal} updateModal={setModal} eventModal={validateData}>
                    <div className="row">
                        <div className="col-6 d-flex align-items-center">
                            <BasicSwitch label="Aplicar" estado={scheduledDiscount.bean.Apply} eventChange={(e) => { scheduledDiscount.setBean({ ...scheduledDiscount.bean, "Apply": e }) }} />
                        </div>
                        <div className="col-6">
                            <label>Prioridad</label>
                            <input type="number" name="Priority" className="form-control" value={scheduledDiscount.bean.Priority} onChange={scheduledDiscount.handleChange} />
                        </div>
                        <div className="col-6 d-flex align-items-center">
                            <BasicSwitch label="Aplicar Mensual/Quincenal" estado={scheduledDiscount.bean.DiscountPeriod} eventChange={(e) => { scheduledDiscount.setBean({ ...scheduledDiscount.bean, "DiscountPeriod": e }) }} />
                        </div>
                        <div className="col-6">
                            <label className={`${missingFields.includes('IDType') ? 'has-error' : ''}`}>Tipo Descuento</label>
                            <EmployeeDiscountTypeEditor idSelector={scheduledDiscount.bean.IDType} onChange={(e) => { scheduledDiscount.setBean({ ...scheduledDiscount.bean, "IDType": e.IDType }); setUseBeneficiary(e.UseBeneficiary);  }} label=" " />
                        </div>
                        <div className="col-6">
                            <label className={`${missingFields.includes('IDAccount') ? 'has-error' : ''}`}>Empleado</label>
                            <AccountEditor idAccount={scheduledDiscount.bean.IDAccount} label=" " onChange={(e) => { scheduledDiscount.setBean({ ...scheduledDiscount.bean, "IDAccount": e.IDAccount }) }} />
                        </div>
                        <div className="col-6">
                            <label>Referencia/Proceso</label>
                            <input type="text" name="ProcessCode" className="form-control" value={scheduledDiscount.bean.ProcessCode} onChange={scheduledDiscount.handleChange} />
                        </div>
                        <div className="col-6">
                            <AccountEditor idAccount={scheduledDiscount.bean.IDPetitioner} label="Peticionario/Demandante" onChange={(e) => { scheduledDiscount.setBean({ ...scheduledDiscount.bean, "IDPetitioner": e.IDAccount }) }} />
                        </div>
                        <div className="col-6">
                            <AccountEditor idAccount={scheduledDiscount.bean.IDBeneficiary} label="Beneficiario" onChange={(e) => { scheduledDiscount.setBean({ ...scheduledDiscount.bean, "IDBeneficiary": e.IDAccount }) }} />
                        </div>
                        <div className="col-6">
                            <AccountEditor idAccount={scheduledDiscount.bean.IDBeneficiary2} label="Autorizar Pago a" onChange={(e) => { scheduledDiscount.setBean({ ...scheduledDiscount.bean, "IDBeneficiary2": e.IDAccount }) }} />
                        </div>
                        <div className="col-12">
                            <label>Descripcion</label>
                            <textarea name="Description" className="form-control" value={scheduledDiscount.bean.Description} onChange={scheduledDiscount.handleChange} />
                        </div>
                        <div className="col-6">
                            <label className={`${missingFields.includes('Since') ? 'has-error' : ''}`}>Desde</label>
                            <InputDate value={scheduledDiscount.bean.Since} name="Since" setDate={(e) => { scheduledDiscount.setBean({ ...scheduledDiscount.bean, "Since": e });  }} label=" " />
                        </div>
                        <div className="col-6">
                            <label className={`${missingFields.includes('UptoDate') ? 'has-error' : ''}`}>Hasta</label>
                            <InputDate notDate={!scheduledDiscount.bean.UptoDate} value={scheduledDiscount.bean.UptoDate} name="UptoDate" setDate={(e) => { scheduledDiscount.setBean({ ...scheduledDiscount.bean, "UptoDate": e }) }} label=" " />
                        </div>
                        <div className="col-6">
                            <label className={`${missingFields.includes('Type') ? 'has-error' : ''}`}>Tipo</label>
                            <EmployeeBonusTypeConstatsEditor label=" " idSelector={scheduledDiscount.bean.Type} onChange={(e) => { scheduledDiscount.setBean({ ...scheduledDiscount.bean, "Type": e }) }} />
                        </div>
                        <div className="col-6">
                            <label className={`${missingFields.includes('DiscountValue') ? 'has-error' : ''}`}>Vr Descuento</label>
                            <input type="number" name="DiscountValue" className="form-control" value={scheduledDiscount.bean.DiscountValue} onChange={scheduledDiscount.handleChange} />
                        </div>
                        <div className="col-6">
                            <label className={`${missingFields.includes('DiscountBase') ? 'has-error' : ''}`}>% Base Salario</label>
                            <input type="number" name="DiscountBase" className="form-control" value={scheduledDiscount.bean.DiscountBase} onChange={scheduledDiscount.handleChange} />
                        </div>
                        <div className="col-6 d-flex align-items-center">
                            <BasicSwitch label="Aporte" estado={scheduledDiscount.bean.Periodic ?? false} eventChange={(e) => { scheduledDiscount.setBean({ ...scheduledDiscount.bean, "Periodic": e }) }} />
                        </div>
                        <div className="col-6">
                            <label>Descontar hasta</label>
                            <input type="number" name="MaxDiscount" className="form-control" value={scheduledDiscount.bean.MaxDiscount} onChange={scheduledDiscount.handleChange} />
                        </div>
                        <div className="col-6">
                            <label>Vr Renta Excenta</label>
                            <input type="number" name="ExemptBase" className="form-control" value={scheduledDiscount.bean.ExemptBase} onChange={scheduledDiscount.handleChange} />
                        </div>
                        <div className="col-6">
                            <label>Base Retención</label>
                            <input type="number" name="TaxHoldValue" className="form-control" value={scheduledDiscount.bean.TaxHoldValue} onChange={scheduledDiscount.handleChange} />
                        </div>
                        <div className="col-6 d-flex align-items-center">
                            <BasicSwitch label="Usar Cuenta Bancaria de Beneficiario" estado={scheduledDiscount.bean.UseBeneficiary} eventChange={(e) => { scheduledDiscount.setBean({ ...scheduledDiscount.bean, "UseBeneficiary": e }) }} />
                        </div>
                    </div>
                </Modal>
            }

            {modalConcepts.view &&
                <Modal modal={modalConcepts} eventModal={validateData} updateModal={setModalConcepts} >
                    <div className="row">
                        <div className="col-12">
                            <label>Referencia 1</label>
                            <input type="text" name="ThirdConceptCode1" className="form-control" value={scheduledDiscount.bean.ThirdConceptCode1} onChange={scheduledDiscount.handleChange} />
                        </div>
                        <div className="col-12">
                            <label>Referencia 2</label>
                            <input type="text" name="ThirdConceptCode2" className="form-control" value={scheduledDiscount.bean.ThirdConceptCode2} onChange={scheduledDiscount.handleChange} />
                        </div>
                        <div className="col-12">
                            <label>Referencia 3</label>
                            <input type="text" name="ThirdConceptCode3" className="form-control" value={scheduledDiscount.bean.ThirdConceptCode3} onChange={scheduledDiscount.handleChange} />
                        </div>
                        <div className="col-12">
                            <label>Referencia 4</label>
                            <input type="text" name="ThirdConceptCode4" className="form-control" value={scheduledDiscount.bean.ThirdConceptCode4} onChange={scheduledDiscount.handleChange} />
                        </div>
                        <div className="col-12">
                            <label>Referencia 5</label>
                            <input type="text" name="ThirdConceptCode5" className="form-control" value={scheduledDiscount.bean.ThirdConceptCode5} onChange={scheduledDiscount.handleChange} />
                        </div>
                        <div className="col-12">
                            <label>Referencia 6</label>
                            <input type="text" name="ThirdConceptCode6" className="form-control" value={scheduledDiscount.bean.ThirdConceptCode6} onChange={scheduledDiscount.handleChange} />
                        </div>
                        <div className="col-12">
                            <label>Referencia 7</label>
                            <input type="text" name="ThirdConceptCode7" className="form-control" value={scheduledDiscount.bean.ThirdConceptCode7} onChange={scheduledDiscount.handleChange} />
                        </div>
                        <div className="col-12">
                            <label>Referencia 8</label>
                            <input type="text" name="ThirdConceptCode8" className="form-control" value={scheduledDiscount.bean.ThirdConceptCode8} onChange={scheduledDiscount.handleChange} />
                        </div>
                    </div>
                </Modal>
            }

            {modalC.view &&
                <ModalConfirm modal={modalC} updateModal={setModalC} eventModal={deleteItem}>
                    <strong>Eliminar aporte/descuento {scheduledDiscount.bean.IDContribution} de {scheduledDiscount.bean.AccountName}</strong>
                </ModalConfirm>
            }

        </>
    )
}