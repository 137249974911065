import { useEffect, useState } from "react";
import useDataBean from "../../../../../../../core/services/dataBean/useDataBean";
import { _single } from "../../../../../../../core/services/dataBean/EntityCatalog";
import { BasicTable, MASK_MONEY } from "../../../../../../theme/Component/BasicTable";
import { ListMenu } from "../../../../../../theme/Component/ListMenu";
import { toast } from "react-toastify";
import { Modal } from "../../../../../../theme/Component/Modal";
import { SelectBudgetID } from "../../../../../../admin/configuracion/BudgetID/SelectBudgetID";
import { InputMoney } from "../../../../../../theme/Input/InputMoney";
import { SelectBudgetResource } from "../../../../../../contract/select/SelectBudgetResource";
import { _BudgetSource } from "../../../../../../../core/services/dataBean/EntityCatalogVdos";

interface Props {
    formData: Record<string, any>;
    onChange: Function;
    idItems?: any;
    proceder?: Function
}

export const CreateCDPValue = ({ onChange, formData, idItems, proceder }: Props) => {

    const { bean, setBean, lista, setLista, instance } = useDataBean(_BudgetSource);
    const [initBean, setInitBean] = useState<any>({});
    const [item, setItem] = useState<any>([]);

    const [modal, setModal] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });

    const head = [
        { title: 'Rubro Presupuestal', property: 'BudgetName', visible: true },
        { title: 'Saldo del Rubro', property: 'LeftValue', visible: true },
        { title: 'Valor de la Apropiación', property: 'Value', visible: true },
        { title: 'Presupuesto', property: 'BudgetTypeName', visible: true },
        { title: 'Recurso Presupuestal', property: 'BudgetResourceName', visible: true },
        { title: 'Descripción', property: 'Description', visible: true },
    ]

    useEffect(() => {
        if (idItems) {
            getItem()
        }
    }, [idItems])

    const getItem = () => {
        instance.getBudgetByArquisitionItem(idItems).then(
            (resp) => {
                if (resp.length > 0) {
                    setItem(resp)
                    if (proceder) {
                        proceder(true)
                    }
                } else {
                    toast.error("Validar Estructuracion de la Necesidad")
                    if (proceder) {
                        proceder(false)
                    }
                }
            }
        ).catch(err => { console.log(err) })
    }

    const listaBotones = () => {
        const botones = [
            { titulo: 'Editar', icono: 'ri-edit-2-line', visible: _single.canEdit(), evento: () => { setModal({ ...modal, "view": true, "name": "Editar" }) } },
            { titulo: 'Eliminar', icono: 'ri-delete-bin-line', visible: _single.canDelete(), evento: () => { eliminarItem() } },

        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }

    const createItem = () => {
        setBean({});
        setModal({ ...modal, "view": true, "name": "Crear" })
    }

    const eliminarItem = () => {
        const updatedLista = lista.filter((item: any) =>
            JSON.stringify(item.DataBeanProperties) !== JSON.stringify(bean)
        );

        setBean({});
        setLista(updatedLista);
    }

    const iconButtonsItem = [
        { nombre: 'Agregar', visible: _single.canCreate(), icono: 'ri-file-add-line', evento: createItem }
    ]

    const createNewItem = () => {

        const updatedLista = lista.filter((item: any) =>
            JSON.stringify(item.DataBeanProperties) !== JSON.stringify(initBean)
        );

        instance.createBudgetRowCDP(formData.Date, formData.BudgetIDType, bean.BudgetIDStr, bean.IDBudgetResource, bean.Value, bean.Description).then(
            (resp: any) => {
                let temp = [...updatedLista, {
                    DataBeanProperties: resp,
                    DataBeanName: 'budget.Budget'
                }];
                setLista(temp);
                setBean({});
                setModal({ ...modal, "view": false })
            }
        ).catch(err => toast.error(err))
    }


    useEffect(() => {
        if (item.length > 0) {
            processItems();
        }
    }, [item]);

    const processItems = async () => {
        const errors: string[] = [];
        const promises = item.map((obj: any) => {
            instance.createBudgetRowCDP(
                formData.Date,
                formData.BudgetIDType,
                obj.DataBeanProperties.JsonCode,
                obj.DataBeanProperties.IDBudgetResource,
                obj.DataBeanProperties.TotalValue,
                `Creado el ${_single.getCurrenDate()}`
            ).then((resp: any) => {
                let temp = [...lista, {
                    DataBeanProperties: resp,
                    DataBeanName: 'budget.Budget'
                }];
                setLista(temp);
                setBean({});
            })
                .catch((err) => {
                    errors.push(`Error al procesar JsonCode: ${obj.JsonCode}`);
                    console.error(err);
                });
        });

        await Promise.all(promises);

        if (errors.length > 0) {
            toast.error(`Errores encontrados: ${errors.join(", ")}`);
        } else {
            toast.success("Todos los ítems fueron procesados correctamente");
        }
    };


    const setRowBean = (object: any) => {
        setBean(object);
        setInitBean(object)
    }

    useEffect(() => {
        onChange(lista);
    }, [lista])

    return (
        <div className="row">
            <div className="col-md-12">
                <BasicTable body={lista} head={head} rowSet={(e) => { setRowBean(e) }} listButtons={listaBotones()} iconButtons={iconButtonsItem} />
            </div>

            <div className="col-md-12">
                <Modal modal={modal} updateModal={setModal} eventModal={createNewItem} >
                    <div className="row">
                        <div className="col-md-12">
                            <SelectBudgetID onChange={({ JsonCode }) => { setBean({ ...bean, "BudgetIDStr": JsonCode }) }} jsonCode={bean.BudgetIDStr} edit={false} tipoPresupuesto={null} />
                        </div>
                        <div className="col-md-6">
                            <InputMoney id={_single.getRandomInt} _label='Valor a solicitar' name="valor" value={bean.Value} onChange={(e: any) => { setBean({ ...bean, ['Value']: parseInt(e) }) }} />
                        </div>
                        <div className="col-md-6">
                            <SelectBudgetResource idSelector={bean.IDBudgetResource} onChange={(e) => { setBean({ ...bean, "IDBudgetResource": e }) }} />
                        </div>
                        <div className="col-md-12">
                            <label>Descripción</label>
                            <textarea name="Description" className="form-control" value={bean.Description} onChange={(e) => { setBean({ ...bean, "Description": e.target.value }) }} />
                        </div>
                    </div>
                </Modal>
            </div>
        </div>
    )
}