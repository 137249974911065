import { useState, useEffect } from 'react';
import useDataBean from '../../../../core/services/dataBean/useDataBean';
import { _ThirdBankingAccount } from '../../../../core/services/dataBean/EntityCatalog';
import { AccountEditor } from '../../../editor/AccountEditor';
import { PrintJson } from '../../../theme/Component/PrintJson';



interface Props {
    idSelector: number;
    onChange: any;
    idselectorAccount?: any;
    label?: string;
}

export const ThirdBankingAccountEditor = ({ idSelector, onChange, label, idselectorAccount }: Props) => {

    const macroProcess = useDataBean(_ThirdBankingAccount)
    const [lista, setLista] = useState<any>([])
    const [id, setID] = useState(null);
    const [idAccount, setIDAccount] = useState<number>(null);

    useEffect(() => {
        if (idSelector >= 0) {
            setID(idSelector);
        }
    }, [])

    useEffect(() => {
        if (idselectorAccount) {
            setIDAccount(idselectorAccount)
        }
    }, [idselectorAccount])

    useEffect(() => {
        onChange(id);
    }, [id])

    const setValue = (e: any) => {
        setID(parseInt(e));
        onChange(id);
    }

    useEffect(() => {
        if (idAccount !== null && idAccount != undefined) {
            getLista();
        }
    }, [idAccount])

    const getLista = () => {

        macroProcess.instance.getThirdBankingAccountCatalogByIDAccount(idAccount, null).then(
            (resp: any) => {
                if (resp) {
                    setLista(resp)
                   //console.log(resp)
                }
            }
        )

    }

    return (
        <>
            <div className="row">
                {!idselectorAccount &&
                    <div className="col-12">
                        <div className="col-12">
                            <AccountEditor label="Tercero" idAccount={idAccount} onChange={(e) => { setIDAccount(e.IDAccount) }} />
                        </div>
                    </div>
                }
                <div className="col-12">
                    <label>{label ?? "Cuenta Bancaria"}</label>
                    <select className="form-select" value={id} onChange={(e) => { setValue(e.target.value) }}>
                        <option>...</option>
                        {lista &&
                            lista.map((item: any, index: number) => {
                                return (
                                    <option key={index} value={item.DataBeanProperties.IDThirdBankingAccount} >{item.DataBeanProperties.BankingName} - {item.DataBeanProperties.AccountTypeName} - {item.DataBeanProperties.AccountNumber}</option>
                                )
                            })
                        }
                    </select>
                </div>
            </div>

        </>
    )
}