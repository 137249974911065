import { DataBean } from "../DataBean";

export class BankingAccountCatalog extends DataBean {


    public async getBankingAccountCatalog(idCompany: any): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_getBankingAccountCatalog_Number", [idCompany]);
    }

    public async updateBankingAccount(bean: any): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "eBankTransfer.BankingAccount_updateBankingAccount_eBankTransfer.BankingAccount", [
            {
                "DataBeanProperties": bean,
                "DataBeanName": "eBankTransfer.BankingAccount"
            }
        ]);
    }

    public async deleteBankingAccount(bean): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "void_deleteBankingAccount_Number", [
            {
                "DataBeanProperties": bean,
                "DataBeanName": "eBankTransfer.BankingAccount"
            }
        ]);
    }
}