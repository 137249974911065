import { useEffect, useState } from "react";
import { ContractService } from "../../../core/services/ContractService";
import { Singleton } from "../../../core/services/Singleton";
import { BasicButton } from "../../theme/Component/BasicButton";
import { Alert } from "../../theme/Component/Alert";
import { SelectModality } from "../select/SelectModality";
import { useDashboardStore } from "../../pages/hooks/useDashboardStore";
import {
  BasicTable,
  MASK_MONEY,
  MASK_NUMBER,
} from "../../theme/Component/BasicTable";
import { Modal } from "../../theme/Component/Modal";
import { ListMenu } from "../../theme/Component/ListMenu";
import { toast } from "react-toastify";
import useDataBean from "../../../core/services/dataBean/useDataBean";
import { _AdquisitionItemProcess, _BusinessProcess, _single } from "../../../core/services/dataBean/EntityCatalog";

interface Props {
  beanOficina: any;
  beanH: any;
  updateHiring: any;
  rol: number;
}
export const ConfigHiringProcess = ({ beanOficina, beanH, updateHiring, rol }: Props) => {

  const _busProcess = useDataBean(_BusinessProcess)

  const service = new ContractService();
  const _adquisitionItemProcess = useDataBean(_AdquisitionItemProcess);
  const single = Singleton.getInstance();
  const { setLoading } = useDashboardStore();
  const [message, setMessage] = useState("");
  const [bean, setBean] = useState<any>({});
  const [beanItem, setBeanItem] = useState<any>({});
  const [listItems, setListItems] = useState<any>([]);
  const [isValid, setIsValid] = useState<boolean>(false);
  const [validacion, setValidacion] = useState<any>({});
  const [modalI, setModalI] = useState<any>({
    name: "Solicitar",
    tabIndex: -1,
    view: false,
    btnClose: true,
    btnSubmit: false,
    format: "modal-lg",
  });

  useEffect(() => {
    if (!beanH.IDAdquisitionPlanFunctionalID) {
      initBean();
    } else {
      setBean(beanH);
    }

    getAdquisitionItemAprovedState();
  }, [beanH]);
  useEffect(() => {
    setIsValid(false);
  }, [beanH]);

  const initBean = () => {
    let b = {
      IDAdquisitionPlanFunctionalID: beanOficina.IDAdquisitionPlanFunctionalID,
      IDEmployee: single.getAccountID(),
      IDStructuringDocument: null,
      Justification: "",
      ObjectContract: "",
      StructuringState: 10,
      IDHiringModality: 0,
    };
    setBean(b);
  };

  const editarHiring = () => {
    //console.log(bean);
    setLoading(true);
    service.updateHiringProcess(bean).subscribe((resp: any) => {
      setLoading(false);
      setMessage("Proceso actualizado");
    });
  };

  const crearHiring = () => {
    //console.log(beanItem);

    const AdquisitionItems = [];
    AdquisitionItems.push(beanItem.IDAdquisitionItem);
    beanItem.AdquisitionItems = AdquisitionItems;

    setLoading(true);
    service.createHiringProcess(beanItem).subscribe((resp: any) => {
      if (resp.DataBeanProperties.ObjectValue) {
        _adquisitionItemProcess.instance.requestContractItem(beanItem.IDAdquisitionItem, single.getAccountID(), _adquisitionItemProcess.bean.obs).then(
          (resp: any) => {
            if (resp.IDProcedureImp) {
              toast.success("Se a solicitado la contratación de la necesidad.");
              setModalI({ ...modalI, "view": false });
            } else {
              toast.error("No se pudo completar la solicitud intentelo nuevamente");
            }


          }
        )
      } else if (resp.DataBeanProperties.ErrorMessage) {
        setMessage(resp.DataBeanProperties.ErrorMessage);
      } else {
        toast.error("No se pudo iniciar la solicitud.");
      }
      setLoading(false);
      getAdquisitionItemAprovedState();
      setModalI({ ...modalI, view: false });
    });

  };



  const getAdquisitionItemAprovedState = () => {
    if (rol === 2) {
      setLoading(true);
      service.getAdquisitionItemAprovedStateByPreparered(beanOficina.IDAdquisitionPlanFunctionalID, single.getAccountID()).subscribe((resp: any) => {
        setLoading(false);

        if (resp.DataBeanProperties.ObjectValue) {
          let tmp: any = [];
          setListItems(resp.DataBeanProperties.ObjectValue);
          //console.log(resp.DataBeanProperties.ObjectValue);
        }
      });
    } else {
      setLoading(true);
      service.getAdquisitionItemAprovedState(beanOficina.IDAdquisitionPlanFunctionalID).subscribe((resp: any) => {
        setLoading(false);
        if (resp.DataBeanProperties.ObjectValue) {
          let tmp: any = [];
          setListItems(resp.DataBeanProperties.ObjectValue);
          //console.log(resp.DataBeanProperties.ObjectValue);
        }
      });
    }
  };

  const head = [
    { title: "ID", property: "IDAdquisitionItem", visible: true, mask: MASK_NUMBER, },
    { title: "Necesidad", property: "Description" },
    { title: "Justificación", property: "Justification", visible: true },
    { title: "Valor total estimado", property: "TotalValue", mask: MASK_MONEY },
  ];

  const openModal = () => {
    setModalI({ ...modalI, view: true });
    // single.spinner(true);
    // service
    //   .validateRequestContract(beanItem.IDAdquisitionItem)
    //   .subscribe((resp: any) => {
    //     if (resp.DataBeanProperties.ObjectValue) {
    //       let obj = resp.DataBeanProperties.ObjectValue;

    //       setIsValid(obj.DataBeanProperties.Valid);

    //     }
    //     setValidacion(resp.DataBeanProperties.ObjectValue);
    //     single.spinner(false);
    //   });
  };

  const listaBotonesItems = () => {
    const botones = [
      { titulo: "Solicitar Estudio Previo", icono: "ri-file-list-line", visible: true, evento: openModal, },
    ];
    return <ListMenu listaBotones={botones} />;
  };

  const requestPrevStudy = () => {
    _busProcess.instance.requestBusinessProcessByIDAdquisitionItem(beanItem.IDAdquisitionItem, _single.getAccountID(), _busProcess.bean.obs, "EPREVIO", 44).then(
      (resp) => {
        setModalI({ ...modalI, "view": false });
        getAdquisitionItemAprovedState();
      }
    ).catch(err => { console.log(err) })
  }

  return (
    <>
      <div className="row mt-4">
        <div className="col">
          <strong>Necesidades Aprobadas</strong>
          <hr />
        </div>
      </div>
      <div className="row p-4">
        <BasicTable head={head} body={listItems} listButtons={listaBotonesItems()} rowSet={setBeanItem} />
        <div className="col-md-12">
          {message != "" && (
            <Alert clase="warning" key={"mi-message"}>
              {message}
            </Alert>
          )}
        </div>
      </div>
      <div className="row">
        <div className="col">
          <Modal modal={modalI} updateModal={setModalI}>
            <div className="row">
              <div className="col-md-12">
                <label>Observaciones</label>
                <textarea name="obs" rows={5} className="form-control" value={_busProcess.bean.obs} onChange={_busProcess.handleChange}></textarea>
              </div>
              <div className="col-md-6 mt-2">
                <BasicButton icon="bx bxs-send" eventClick={requestPrevStudy}>Enviar</BasicButton>
              </div>
              {isValid ? (
                <div className="row">
                </div>
              ) : (
                <div className="col-md-12">
                  {validacion.DataBeanProperties && (
                    <Alert clase="warning">
                      <strong>{validacion.DataBeanProperties.Message}</strong>
                    </Alert>
                  )}
                </div>
              )}
            </div>
          </Modal>
        </div>
      </div>
    </>
  );
};
