import { useState, useEffect } from 'react';
import { ContractService } from '../../../core/services/ContractService';
import { Singleton } from '../../../core/services/Singleton';
import { FinanceService } from '../../../core/services/FinanceService';
import { useDashboardStore } from '../../pages/hooks/useDashboardStore';
interface Props {
    idAdquisitionPlan: number;
    idPreparer?: number;
    onChange: any;
}
export const SelectAdquisitionPlanFunctionalID = ({ idAdquisitionPlan, idPreparer, onChange }: Props) => {

    const service = new FinanceService();
    const single = Singleton.getInstance();
    const [lista, setLista] = useState([]);
    const [id, setID] = useState(0);
    const [year, setYear] = useState('2023');
    const { setLoading } = useDashboardStore();


    useEffect(() => {

        let date = new Date();
        setYear(date.getFullYear() + '');
        if (idPreparer)
            getListaPreparer();
        else
            getLista();

    }, [idAdquisitionPlan])


    const setValue = (e: any) => {
        setID(parseInt(e));


        let tem = lista.filter(ele => ele.DataBeanProperties.IDAdquisitionPlanFunctionalID == e);
        onChange(tem.length > 0 ? tem[0].DataBeanProperties : {});


    }

    const getLista = () => {
        let cache = Singleton.getInstance().getCacheItem("AdquisitionPlanCatalog");
        if (cache) {
            setLista(cache);
        }
        else {
            setLoading(true);
            service.getAdquisitionPlanFunctionalIDByIDAccount(idAdquisitionPlan, single.getAccountID())
                .subscribe((resp: any) => {


                    if (resp.DataBeanProperties.ObjectValue) {
                        let list = resp.DataBeanProperties.ObjectValue;
                        list.forEach((ele: any) => {

                            ele.DataBeanProperties.AccountName =
                                (ele.DataBeanProperties.Name1 ?? '') + ' ' +
                                (ele.DataBeanProperties.Name2 ?? '') + ' ' +
                                (ele.DataBeanProperties.Surname1 ?? '') + ' ' +
                                (ele.DataBeanProperties.Surname2 ?? '');

                        });
                        setLista(list);
                    }
                    setLoading(false);
                });
        }
    }
    const getListaPreparer = () => {
        let cache = Singleton.getInstance().getCacheItem("AdquisitionPlanCatalog");
        if (cache) {
            setLista(cache);
        }
        else {
            setLoading(true);
            service.getAdquisitionPlanFunctionalIDForPreparer(idAdquisitionPlan, single.getAccountID())
                .subscribe((resp: any) => {


                    if (resp.DataBeanProperties.ObjectValue) {
                        let list = resp.DataBeanProperties.ObjectValue;
                        list.forEach((ele: any) => {

                            ele.DataBeanProperties.AccountName =
                                (ele.DataBeanProperties.Name1 ?? '') + ' ' +
                                (ele.DataBeanProperties.Name2 ?? '') + ' ' +
                                (ele.DataBeanProperties.Surname1 ?? '') + ' ' +
                                (ele.DataBeanProperties.Surname2 ?? '');

                        });
                        setLista(list);
                    }
                    setLoading(false);
                });
        }
    }

    return (
        <>
            <label>Oficina / Área</label>
            <select className="form-select" value={id} onChange={(e) => { setValue(e.target.value) }}>
                <option value={0}>...</option>
                {lista &&
                    lista.map((item: any, index: number) => {
                        return (

                            <option key={index} value={item.DataBeanProperties.IDAdquisitionPlanFunctionalID} >{item.DataBeanProperties.Name}</option>
                        )
                    })
                }
            </select>
        </>
    )
}