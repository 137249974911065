import { AccountEditor } from "../../../editor/AccountEditor";
import { Modal } from "../../../theme/Component/Modal";
import { useState, useEffect } from 'react';
import { FinanceService } from '../../../../core/services/FinanceService';
import { Alert } from "../../../theme/Component/Alert";
import { ModalConfirm } from '../../../theme/Component/ModalConfirm';
import { Singleton } from "../../../../core/services/Singleton";


interface Props {
    id: number;
    bean: any;
    updateEvent: any;
}
export const ButtonUnspscID = ({ id, bean, updateEvent }: Props) => {

    const service = new FinanceService();
    const single = Singleton.getInstance()
    const [modal, setModal] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [confirm, setConfirm] = useState<any>({ name: 'Confirmación', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });
    const [modalAdd, setModalAdd] = useState<any>({ name: 'Agregar Item', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });
    const [miBean, setMibean] = useState<any>({});
    const [code, setCode] = useState('');
    const [nombre, setNombre] = useState('');
    const [message, setMessage] = useState('');

    useEffect(() => {
        setMibean(bean);
    }, [])
    useEffect(() => {
    }, [miBean.IDLn])


    const updateBean = () => {


        service.updateUnspscID(miBean).subscribe(
            (resp: any) => {
               //console.log("Update : ", resp);

                updateEvent();
                setModal({ ...modal, ['view']: false });
                single.create(miBean);
            }
        );

    }
    const updateBoss = (account: any) => {
        setMibean({ ...miBean, ['IDBoss']: account.IDAccount });
    }
    const updateDistributionChannel = (account: any) => {
        setMibean({ ...miBean, ['DistributionChannel']: account.IDAccount });
    }
    const createBean = () => {
        if (code != '' && nombre != '') {
            let separador = localStorage.getItem('separador') || '-';
            let arr: any[] = code.split(separador);
            if (arr.length > 0) {
                let tmp: number[] = [];
                arr.forEach(element => {
                    tmp.push(parseInt(element));
                });
                arr = tmp;
            }

            service.createUnspscID(arr, nombre).subscribe(
                (resp: any) => {
                   //console.log("Crear Item ", resp);
                    updateEvent();
                }
            );
            setModalAdd({ ...modalAdd, ['view']: false });
        } else {
            setMessage('Código y Nombre son obligatorios');
        }
    }

    const deleteID = () => {
       //console.log("bean ", bean);

        service.deleteUnspscID(bean.IDLn).subscribe(
            (resp: any) => {
                updateEvent();
                setConfirm({ ...confirm, ['view']: false });
            }
        )

    }

    return (
        <>
            {single.canCreate() &&
                <i className="ri-file-add-line cursor btnicon_sm" title="Agregar Hijo" onClick={() => {
                    setCode(bean.UnspscViewCode);
                    setModalAdd({ ...modalAdd, ['view']: true });
                }}></i>
            }
            {single.canDelete() &&
                <i className="ri-file-reduce-line cursor btnicon_sm" title="Eliminar" onClick={() => setConfirm({ ...confirm, ['view']: true })}></i>
            }
            {single.canEdit() &&
                <i className="ri-edit-2-line cursor btnicon_sm" title="Editar" onClick={() => {
                    setModal({ ...modal, ['view']: true });
                }}></i>
            }

            <Modal modal={modal} updateModal={setModal} eventModal={updateBean}>
                <div className="row">
                    <div className="col-md-6">
                        <label>Código</label>
                        <input type="number" className="form-control" value={miBean.Code} onChange={(e) => { setMibean({ ...miBean, ['Code']: e.target.value }) }} />
                    </div>
                    <div className="col-md-12">
                        <label>Nombre</label>
                        <textarea className="form-control" value={miBean.Name} onChange={(e) => { setMibean({ ...miBean, ['Name']: e.target.value }) }}></textarea>

                    </div>
                </div>
            </Modal>

            <Modal modal={modalAdd} updateModal={setModalAdd} eventModal={createBean}>
                <div className="row">
                    <div className="col-md-12">
                        <label htmlFor="code">Código
                            <input type="text" className="form-control" value={code} onChange={(e) => { setCode(e.target.value) }} />
                        </label>
                        <label htmlFor="code">Nombre
                            <input type="text" className="form-control" value={nombre} onChange={(e) => { setNombre(e.target.value) }} />
                        </label>
                    </div>
                    <div className="col-md-12">
                        {message != '' &&
                            <Alert clase="warning">
                                <strong>Parametros</strong> {message}
                            </Alert>}
                    </div>
                </div>
            </Modal>
            <ModalConfirm modal={confirm} updateModal={setConfirm} eventModal={deleteID}>
                <h4>Eliminar el item ?</h4>
            </ModalConfirm>
        </>
    )
}