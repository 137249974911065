import { DataBean } from "../DataBean";

export class BudgetID extends DataBean {

    public async getBudgetIDByKey(idlnBudget: number): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "com.quickdataerp.bean.budget.BudgetID_getBudgetIDByKey_Number", [idlnBudget]);
    }

    public async getBudgetIDByCode(jsonCode: string): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "com.quickdataerp.bean.budget.BudgetID_getBudgetIDByCode_String", [jsonCode]);
    }

    public async getExpensivesBudgetIDType(year: number): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "com.quickdataerp.bean.budget.BudgetIDType_getExpensivesBudgetIDType_Number", [year]);
    }

}
