
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
  } from 'chart.js';
  import { Line } from 'react-chartjs-2';
  import { Bar } from 'react-chartjs-2';
import { useEffect, useState } from 'react';


  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Filler,
    Legend
  );

  interface Props{
    lista:any;
    titulo:string;
    maxRang?:number;
    tipo:string;
  }

export const ChartArea =({lista,titulo,tipo}:Props)=>{

  const [labels,setLabels] = useState<any>([]);
  const [miData,setData] = useState<any>([]);
  
  useEffect(()=>{
    formatData();
  },[lista])

  const formatData=()=>{
    let l:any = [];
    let d:any = [];
      lista.forEach((item:any) => {
          l.push(item.DataBeanProperties.Property);
          d.push(item.DataBeanProperties.Value);
      });
     //console.log(l," >> ",d);
      setLabels(l);
      setData(d);      
  }
  
  const options:any = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: true,
        text: titulo,
      },
    },
  };
  
  const data = {
    labels,
    datasets: [
      {
        fill: true,
        label: titulo,
        data: miData,        
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
      },
    ],
  };

  const renderSwitch = (t: string) => {
    switch(t){
      case 'line':
      return <Line options={options} data={data} />
      case 'bar':
      return <Bar options={options} data={data} />
      default:
        <h4>Sin elementos para mostrar</h4>

    }
  }

    return(
      <>
      {renderSwitch(tipo)}
      </>     
    )
}