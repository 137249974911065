import { useEffect, useState } from "react";
import { SelectConstantsValue } from "../theme/Component/SelectConstantsValue";
import { BasicSwitch } from "../theme/Component/BasicSwitch";
import { InputDate } from "../theme/Input/InputDate";
import { BasicButton } from "../theme/Component/BasicButton";

interface Props {
    supplier: any;
    formSupplier: any;
    updateInfo: Function;
}
export const Entrevista = ({ supplier, formSupplier, updateInfo }: Props) => {

    const [formulario, setFormulario] = useState<any>({});
    const [bean, setBean] = useState<any>({});
    const [miswitch, setMiswitch] = useState(false);

    useEffect(() => {
        if (formSupplier.forma3 === undefined)
            formSupplier.forma3 = {};
        setFormulario(formSupplier.forma3);
    }, [formSupplier])

    const updateConsentimiento = () => {
        let temp: any = formSupplier;
        formulario.state = true;
        temp.forma3 = formulario;
        ////console.log(temp);

        updateInfo(temp);
    }

    return (
        <>
            <h5 className='titul-form' style={{ textAlign: 'center' }}>Entrevista</h5>
            <hr />
            <div className="row">
                <div className="col-md-6">
                    <label className='titul-form'>Su profesión es:</label>
                    <input type="text" className='form-control' value={formulario.profesion} onChange={(e) => setFormulario({ ...formulario, "profesion": e.target.value })} />
                </div>
                <div className="col-md-6">
                    <label className='titul-form'>Actividad:</label>
                    <SelectConstantsValue code={10} value={formulario.actividad} onChange={(e: any) => { setFormulario({ ...formulario, "actividad": e }) }} />
                </div>
                <div className="col-md-6">
                    <label className='titul-form'>Nombre de la Empresa donde Trabaja:</label>
                    <input type="text" className='form-control' value={formulario.empresalabor} onChange={(e) => setFormulario({ ...formulario, "empresalabor": e.target.value })} />
                </div>
                <div className="col-md-6">
                    <label className='titul-form'>Cargo:</label>
                    <input type="text" className='form-control' value={formulario.cargo} onChange={(e) => setFormulario({ ...formulario, "cargo": e.target.value })} />
                </div>

                <div className="col-md-6">
                    <label className='titul-form'>Maneja usted recursos publicos:</label>
                    <BasicSwitch label="NO / SI" estado={formulario.recursosentrevista} eventChange={(e: any) => {
                        // setMiswitch(e);
                        setFormulario({ ...formulario, "recursosentrevista": e });
                    }} />
                </div>
                <div className="col-md-12 mt-3">
                    <hr />
                    <h5 className='titul-form'>Entrevista realizada por: </h5>
                </div>
                <div className="col-md-6">
                    <label className='titul-form'>Nombre Completo del Funcionario:</label>
                    <input type="text" className='form-control' value={formulario.nombrefuncionario} onChange={(e) => setFormulario({ ...formulario, "nombrefuncionario": e.target.value })} />
                </div>
                <div className="col-md-6">
                    <label className='titul-form'>No de Cédula del Funcionario:</label>
                    <input type="text" className='form-control' value={formulario.cedulafuncionario} onChange={(e) => setFormulario({ ...formulario, "cedulafuncionario": e.target.value })} />
                </div>
                <div className="col-md-6">
                    {/* <label className='titul-form'>Fecha  </label>
                    <input type="text" className='form-control mt-4' value={formulario.fecha} onChange={(e)=>setFormulario({...formulario,"fecha":e.target.value})}/> */}
                    <label className='titul-form'>Fecha - dd/mm/año </label>
                    <InputDate name='f1' label='' value={formulario.fechaentrevista} setDate={(e: any) => setFormulario({ ...formulario, "fechaentrevista": e })} />
                </div>
                <div className="col-md-6">
                    <label className='titul-form'>Hora:</label>
                    <input type="text" className='form-control' value={formulario.hora} onChange={(e) => setFormulario({ ...formulario, "hora": e.target.value })} />
                </div>
                <div className="col-md-6">
                    <label className='titul-form'>Lugar:</label>
                    <input type="text" className='form-control' value={formulario.lugar} onChange={(e) => setFormulario({ ...formulario, "lugar": e.target.value })} />
                </div>
            </div>
            {/* <PrintJson json={formulario}/> */}

            <BasicButton icon='ri-save-3-line' eventClick={updateConsentimiento}>Guardar</BasicButton>
        </>
    )
}