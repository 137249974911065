import { DataBean } from "../DataBean";

export class EmployeePayments extends DataBean {


    public async getEmployeePaymentsTypeCatalog(): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_getEmployeePaymentsTypeCatalog_Number", [null]);
    }

    public async updateEmployeePaymentsType(bean: any): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "employees.EmployeePayments_updateEmployeePaymentsType_employees.EmployeePayments", [
            {
                "DataBeanProperties": bean,
                "DataBeanName": "employees.EmployeePayments"
            }
        ]);
    }

    public async getEmployeePaymentsUnitsTypeConstants(): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_getEmployeePaymentsUnitsTypeConstants_Number", [null]);
    }

    public async deleteEmployeePaymentsType(id:number): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "void_deleteEmployeePaymentsType_Number", [id]);
    }
}