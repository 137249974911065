import { DataBean } from "../DataBean";

export class SubMacroProcess extends DataBean {


    public async getSubProcessCatalog(ID: number): Promise<any> {
        return this.getCustomService("PeopleArt", "java.util.List_getSubProcessCatalog_Number", [ID]);
    }

    public async deleteSubProcess(bean): Promise<any> {
        return this.getCustomService("PeopleArt", "void_deleteSubProcess_com.peopleart.bean.appointment.SubProcess", [
            {
                "DataBeanProperties": bean,
                "DataBeanName": "com.peopleart.bean.appointment.SubProcess"
            }
        ]);
    }
}