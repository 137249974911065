import { useEffect, useRef, useState } from "react";
import useDataBean from "../../../core/services/dataBean/useDataBean";
import { ButtonArticleClassCodeTree } from "./ButtonArticleClassCodeTree";
import { BasicButton } from "../../theme/Component/BasicButton";
import { ArticleClassCode } from "../../../core/services/dataBean/contabilidad/ArticleClassCode.Entity";
import ArticleClassCodeTreeChilds from "./ArticleClassCodeTreeChilds";
import { toast } from "react-toastify";
import { _single } from "../../../core/services/dataBean/EntityCatalog";


interface Tree {
  id: number | null;
  edit?: boolean;
  _articleClas?: number;
}
export const ArticleClassCodeTree = ({ id, edit, _articleClas }: Tree) => {


  const treeClass = new ArticleClassCode("QuickDataERPCore", "com.quickdataerp.bean.supplier.ArticleClassCode");
  const tree = useDataBean(treeClass);

  const [txt, setText] = useState<string>('');


  const [lista, setLista] = useState([]);
  const [idchild, setIDchild] = useState(undefined);
  const [accountantIDType, setAccountantIDType] = useState<any>([]);
  const [idaccountantIDType, setIDAccountantIDType] = useState<number>(undefined);



  useEffect(() => {
    getListatree();

    localStorage.setItem('articleclasscodeTree', JSON.stringify({}));

    tree.instance.getArticleClassTypeCatalog().then(
      (resp: any) => {
        let filteredResp = resp;
        if (_articleClas !== undefined && _articleClas !== null) {
          filteredResp = resp.filter(
            (item: any) => item.DataBeanProperties.Type === _articleClas
          );
        }

        setAccountantIDType(filteredResp);

        if (filteredResp && filteredResp.length > 0) {
          setIDAccountantIDType(filteredResp[0].DataBeanProperties.IDArticleClassType);
        }
      }
    );

    return () => {
      setIDAccountantIDType(undefined);
      setIDchild(undefined);
    };
  }, [_articleClas]);

  useEffect(() => {
    if (idaccountantIDType >= 0) {
      getListatree();
    }
  }, [idaccountantIDType]);

  useEffect(() => {

    getFunctionalIDSeparator();
  }, [lista]);

  const getListatree = () => {



    if (idaccountantIDType > 0) {
      let tmp = _single.getCacheItem('treeciuu');
      if (tmp) {
        setLista(tmp);
      } else {

        tree.instance.getTreeForArticleClassCode(idaccountantIDType).then(
          (resp: any) => {
            if (resp) {
              setLista(resp.Childs);
              _single.setCacheItem('treeciuu', resp.Childs);
            }
          }
        )
      }
    }


  };
  const getTreeForTXT = () => {

    if (txt.length > 2) {
      tree.instance.getArticleClassCodeByName(idaccountantIDType, txt).then(
        (resp: any) => {

          let tmp = [];
          resp.forEach((element: any) => {
            tmp.push({
              EnvolvedObject: element
            })
          });
         //console.log(tmp);
          setLista(tmp);

        }
      )

    } else {
      toast.warning("Minimo 3 caracteres para la busqueda")
    }
  }

  const getFunctionalIDSeparator = () => {

    if (lista)
      if (lista.length > 0) {
        let tmp = lista[0].EnvolvedObject.DataBeanProperties;
        ////console.log(tmp);

        localStorage.setItem('separador', tmp.CodeSeparator);
        localStorage.setItem('CodeFormat', tmp.CodeFormat);
        localStorage.setItem('CodeSeparator', tmp.CodeSeparator);
      }

  }



  return (
    <>
      <div className="row">

        <div className="col-md-4">
          <label>Tipo de articulo </label>
          <select className="form-select" value={idaccountantIDType} onChange={(e) => { setIDAccountantIDType(parseInt(e.target.value)) }}>
            {accountantIDType.map((item: any, index: number) => {
              return (
                <option key={index} value={item.DataBeanProperties.IDArticleClassType}>{item.DataBeanProperties.Name}</option>
              )
            })}
          </select>
        </div>
        <div className="col-md-4">
          <label>Buscar</label>

          <input type="text" name="txt" className="form-control" placeholder="..." value={txt} onChange={(e) => { setText(e.target.value) }} />
        </div>
        <div className="col-md-4 mt-4">
          <BasicButton icon="" eventClick={getTreeForTXT}>Buscar</BasicButton>
        </div>

        <div className="col-md-12">
          {edit &&
            <ButtonArticleClassCodeTree
              id={0}
              idAccountantIDType={idaccountantIDType}
              bean={{}}
              updateEvent={() => { getListatree(); }}
            />
          }
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <ul className="list-group">
            {lista.length > 0 &&
              lista.map((item: any) => {
                return (
                  <li className="list-group-item itemh" style={{ border: '0' }} key={item.EnvolvedObject.DataBeanProperties.IDLn} >
                    <div className={idchild === item.EnvolvedObject.DataBeanProperties.IDLn ? 'cursor treeselect d-flex ' : 'cursor d-flex '}
                    >
                      <div>
                        {edit &&
                          <ButtonArticleClassCodeTree
                            id={item.EnvolvedObject.DataBeanProperties.IDLn}
                            idAccountantIDType={accountantIDType}
                            bean={item.EnvolvedObject.DataBeanProperties}
                            updateEvent={() => { getListatree(); }}
                          />
                        }
                      </div>
                      <div>
                        <strong>
                          <span className="d-inline-block text-truncate" style={{ maxWidth: '40vw', fontSize: '0.7rem' }} onClick={() => {
                            localStorage.setItem('articleclasscodeTree', JSON.stringify(item.EnvolvedObject.DataBeanProperties));
                            setIDchild(item.EnvolvedObject.DataBeanProperties.IDLn);
                          }}>
                            {item.EnvolvedObject.DataBeanProperties.ArticleClassCode} - {item.EnvolvedObject.DataBeanProperties.Name}
                          </span>
                        </strong>
                      </div>
                      <div>
                        <span className="badge bg-success ms-1">{item.ChildrenCount}</span>
                      </div>
                    </div>
                    {(idchild === item.EnvolvedObject.DataBeanProperties.IDLn) &&
                      <ArticleClassCodeTreeChilds idAccountantIDType={accountantIDType} edit={edit} idChild={idchild} />
                    }
                  </li>
                );
              })
            }
          </ul>
        </div>
      </div>

    </>
  );
};
