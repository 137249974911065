import { useEffect, useState } from "react";
import { _RemoteAbstractAction, _single } from "../../../core/services/dataBean/EntityCatalog"
import useDataBean from "../../../core/services/dataBean/useDataBean"
import { ListMenu } from "../../theme/Component/ListMenu";
import { PrintJson } from "../../theme/Component/PrintJson";
import { Singleton } from "../../../core/services/Singleton";
import { BasicTable, MASK_NUMBER } from "../../theme/Component/BasicTable";
import useModal, { MODAL_LG, MODAL_XL } from "../../theme/Component/hooks/useModal";
import { Modal } from "../../theme/Component/Modal";
import { toast } from "react-toastify";
import { AbstractStep } from "./AbstractStep";
import { ExcecuteRemoteAbstract } from "./ExcecuteRemoteAbstract";
import { Alert } from "../../theme/Component/Alert";


export const RemoteAbstractAction=()=>{


    const single = Singleton.getInstance();
    const _remoteAbstractAction = useDataBean(_RemoteAbstractAction);
    const [listMenuType,setListMenuType] = useState<any>([]);
    const [listApplication,setListApplication] = useState<any>([]);
    const [listApplicationType,setListApplicationType] = useState<any>([]);
    const [lista,setLista] = useState<any>([]);

    const modalUpdate = useModal("Registrar",MODAL_LG);
    const modalCatalogo = useModal("Catálogo de Etapas",MODAL_LG);
    const modalRunApp = useModal("Ejecutar Aplicación",MODAL_LG);

    const [data,setData] = useState<any>({
        IDMenu:0,
        IDApplication:0,
        IDApplicationType:0
    });

    useEffect(()=>{
        getApplicationTypeConstants();
        getApplicationCatalog();
        
    },[])

    useEffect(()=>{
        getApplicationTypeCatalogByMenuType();
    },[data.IDMenu,data.IDApplication])
    useEffect(()=>{
        
        getRemoteAbstractActionByApplicationType();
        
    },[data.IDApplicationType])


    const getApplicationTypeConstants=()=>{
        _remoteAbstractAction.instance.getApplicationTypeConstants().then(
            (resp:any)=>{
                if(resp.length>0)
                {
                    setData({...data,"IDMenu":resp[0].DataBeanProperties.Value});
                }
                setListMenuType(resp);                
            }
        )
    }
    const getApplicationCatalog=()=>{
        _remoteAbstractAction.instance.getApplicationCatalog().then(
            (resp:any)=>{
                if(resp.length>0)
                {
                    setData({...data,"IDApplication":resp[0].DataBeanProperties.IDApplication});
                }
                setListApplication(resp);
            }
        )
    }
    const getApplicationTypeCatalogByMenuType=()=>{
        _remoteAbstractAction.instance.getApplicationTypeCatalogByMenuType(data.IDMenu,data.IDApplication).then(
            (resp:any)=>{
                if(resp.length>0)
                {
                    setData({...data,"IDApplicationType":resp[0].DataBeanProperties.IDApplicationType});
                }
                setListApplicationType(resp);
            }
        )
    }
    const getRemoteAbstractActionByApplicationType=()=>{
        _remoteAbstractAction.instance.getRemoteAbstractActionByApplicationType(data.IDApplicationType,null).then(
            (resp:any)=>{
               //console.log(resp);
                _remoteAbstractAction.setLista(resp);
            }
        )
    }
    const agregar =()=>{
        _remoteAbstractAction.setBean({"IDMenu":data.IDMenu,"IDApplication":data.IDApplication});
        modalUpdate.open();
    }
    const updateBean =()=>{
       //console.log(_remoteAbstractAction.bean);
        _remoteAbstractAction.instance.registerRemoteAbstractAccount(data.IDApplication,data.IDApplicationType,_remoteAbstractAction.bean.classForName).then(
            (resp:any)=>{
                _single.printOut(resp);
                getRemoteAbstractActionByApplicationType();                
            }
        ).catch(err=>{toast.error(err)});
        
    }

    const handleChange=(e:any)=>{        
       setData(single.handleFormChange(data,e));
    }

     const head = [
        {title:'ID',property:'IDRemoteAbstractAction',visible:true,mask:MASK_NUMBER},
        {title:'Nombre',property:'Name',visible:true},
        {title:'Código',property:'Code',visible:true},
        {title:'Tipo Acceso',property:'AccessTypeName',visible:true},        
        {title:'Estado',property:'StateName',visible:true},        
    ]
    const iconButtonsItem = [
        { nombre: 'Agregar',visible:true, icono: 'ri-file-add-line', evento: agregar }
    ]
     const listaBotones =()=>{
        const botones = [
            {titulo:'Catálogo de Etapas',icono:'ri-file-add-line',visible:true,evento:()=>{modalCatalogo.open()}},
            {titulo:'Ejecutar Aplicación',icono:'ri-file-add-line',visible:true,evento:()=>{modalRunApp.open()}},
            

        ]
        return(
            <ListMenu listaBotones={botones}/>
        )
    }
    return(
        <div className="row">
            <div className="col-md-12">
                <div className="row">
                    <div className="col">
                        <label>Tipo de Menú</label>
                        <select name="IDMenu" className="form-select" value={data.IDMenu} onChange={handleChange}>
                            {listMenuType.map((item:any,index:number)=>{
                                return(
                                    <option key={index} value={item.DataBeanProperties.Value}>{item.DataBeanProperties.Property}</option>
                                )
                            })
                            }
                        </select>
                    </div>
                    <div className="col">
                        <label>Aplicación </label>
                        <select name="IDApplication" className="form-select" value={data.IDApplication} onChange={handleChange}>
                            {listApplication.map((item:any,index:number)=>{
                                return(
                                    <option key={index} value={item.DataBeanProperties.IDApplication}>{item.DataBeanProperties.Name} - {item.DataBeanProperties.Description}</option>
                                )
                            })
                            }
                        </select>
                    </div>
                    <div className="col">
                        <label>Tipo de Aplicación </label>
                        <select name="IDApplicationType" className="form-select" value={data.IDApplicationType} onChange={handleChange}>
                            {listApplicationType.map((item:any,index:number)=>{
                                return(
                                    <option key={index} value={item.DataBeanProperties.IDApplicationType}>{item.DataBeanProperties.Name} - {item.DataBeanProperties.Description}</option>
                                )
                            })
                            }
                        </select>
                    </div>
                </div>
            </div>
            <div className="col-md-12 mt-4">
                <BasicTable body={_remoteAbstractAction.lista} head={head} rowSet={_remoteAbstractAction.setBean} iconButtons={iconButtonsItem} listButtons={listaBotones()}></BasicTable>
            </div>
            <div className="col-md-12">
                <Modal modal={modalUpdate.modal} updateModal={modalUpdate.setModal} eventModal={updateBean}>
                    <div className="row">                        
                        {(data.IDApplication>0 && data.IDApplicationType>0) ?
                            <div className="col-md-12">
                                <label>Nombre de la clase java a incluir</label>
                                <input type="text" className="form-control" name="classForName" value={_remoteAbstractAction.bean.classForName} onChange={_remoteAbstractAction.handleChange} />
                            </div>
                            :
                            <Alert clase="warning" >
                                <p>Seleccione un tipo de aplicación y aplicación valida</p>
                            </Alert>
                        }
                    </div>
                </Modal>
                <Modal modal={modalCatalogo.modal} updateModal={modalCatalogo.setModal}>
                        <div className="row">
                            <div className="col">
                                <AbstractStep fieldList={_remoteAbstractAction.bean.PROPERTIES_STRUCTURE}/>
                            </div>
                        </div>
                </Modal>
                <Modal modal={modalRunApp.modal} updateModal={modalRunApp.setModal}>                    
                       <ExcecuteRemoteAbstract remoteAbstractAction={_remoteAbstractAction.bean}/>  
                </Modal>
                {/* <PrintJson json={_remoteAbstractAction.bean}/> */}
            </div>
        </div>
        
    )

}