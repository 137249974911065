import { DataBean } from "../DataBean";


export class PositionObjective extends DataBean {


    public async getPositionObjectiveCatalog(idContract: number, year: number): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_getPositionObjectiveCatalog_Number_Number", [idContract, year]);
    }

    public async getYearListForPerformanceObjectives(): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_getYearListForPerformanceObjectives_Number", [null]);
    }

    public async deletePositionObjective(idPositionObjective: number, idAccount: number, obs: string): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "com.quickdataerp.bean.employees.PositionObjective_deletePositionObjective_Number_Number_String", [idPositionObjective, idAccount, obs]);
    }
}