import { DataBean } from "../DataBean";


export class CashReimbursementConcept extends DataBean {

    public async getCashReimbursementConceptTypeCatalog(year: any): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_getCashReimbursementConceptTypeCatalog_Number", [year]);
    }

    public async deleteCashReimbursementConceptType(idCashReimbursementConcept: number): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "void_deleteCashReimbursementConceptType_Number", [idCashReimbursementConcept]);
    }

    public async updateCashReimbursementConceptType(bean: any): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "com.quickdataerp.bean.accountant.CashReimbursementConcept_updateCashReimbursementConceptType_com.quickdataerp.bean.accountant.CashReimbursementConcept", [
            {
                "DataBeanProperties": bean,
                "DataBeanName": "com.quickdataerp.bean.accountant.CashReimbursementConcept"
            }
        ]);
    }

}