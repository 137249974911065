import { useEffect, useState } from "react";
import { _TechnicalCriteria, _TechnicalSkillProduct } from "../../../../../../core/services/dataBean/EntityCatalog";
import useDataBean from "../../../../../../core/services/dataBean/useDataBean";
import { Singleton } from "../../../../../../core/services/Singleton";
import { toast } from "react-toastify";
import { ListMenu } from "../../../../../theme/Component/ListMenu";
import { BasicTable } from "../../../../../theme/Component/BasicTable";
import { Modal } from "../../../../../theme/Component/Modal";
import { ModalConfirm } from "../../../../../theme/Component/ModalConfirm";


interface Funcion {
    _IDTSkill: number;
}

export const TechnicalCriteria = ({ _IDTSkill }: Funcion) => {

    const techCriteria = useDataBean(_TechnicalCriteria);
    const single = Singleton.getInstance();

    const [lista, setLista] = useState<any>([]);
    const [modal, setModal] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-md' });
    const [modalC, setModalC] = useState<any>({ name: 'Eliminar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });

    useEffect(() => {
        getLista();
    }, [_IDTSkill])

    const getLista = () => {
        techCriteria.instance.getTechnicalCriteriaCatalog(_IDTSkill).then(
            (resp: any) => {
                if (resp) {
                    setLista(resp)
                }
            }
        )

    }

    const create = () => {
        techCriteria.setBean({
            IDTechnicalSkill: _IDTSkill,
        });
        setModal({ ...modal, "name": "Agregar Criterios del Desempeño Técnico", "view": true });
    }

    const deleteItem = () => {
        techCriteria.deleteItemVoid(techCriteria.bean.IDTechnicalCriteria).then(
            (resp: any) => {
                getLista();
                setModalC({ ...modalC, "view": false });
            }
        )

    }

    const editaItem = () => {
       //console.log(techCriteria.bean);
        techCriteria.update().then(
            (resp: any) => {
                getLista();
                setModal({ ...modal, "view": false });
            }
        ).catch(err => {
            toast.error(err);
        })

    }

    const head = [
        { title: 'ID', property: 'IDTechnicalCriteria', visible: true },
        { title: 'Nombre', property: 'InterfaceName', visible: true },
        { title: 'Descripción', property: 'Description', visible: true },
    ]

    const iconButtonsItem = [
        { nombre: 'Agregar', visible: single.canCreate(), icono: 'ri-file-add-line', evento: create }
    ]
    const listaBotones = () => {
        const botones = [
            { titulo: 'Editar', icono: 'ri-edit-2-line', visible: single.canEdit(), evento: () => { setModal({ ...modal, "name": `Editar Criterios del Desempeño Técnico`, "view": true }) } },
            { titulo: 'Eliminar', icono: 'ri-delete-bin-line', visible: single.canDelete(), evento: () => { setModalC({ ...modalC, "view": true }) } },

        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }


    return (
        <>
            <div className="row">
                <div className="col-md-12">
                    <BasicTable body={lista} head={head} rowSet={techCriteria.setBean} listButtons={listaBotones()} iconButtons={iconButtonsItem}></BasicTable>
                </div>
            </div>

            <Modal modal={modal} updateModal={setModal} eventModal={editaItem}>
                <div className="row">
                    <div className="col-12">
                        <label>Nombre: </label>
                        <input name="InterfaceName" className="form-control" value={techCriteria.bean.InterfaceName} onChange={techCriteria.handleChange} />
                    </div>
                    <div className="col-12">
                        <label>Descripción: </label>
                        <textarea name="Description" className="form-control" value={techCriteria.bean.Description} onChange={techCriteria.handleChange} />
                    </div>
                </div>
            </Modal>
            <ModalConfirm modal={modalC} updateModal={setModalC} eventModal={deleteItem}>
                <strong>Eliminar item: {techCriteria.bean.InterfaceName}</strong>
            </ModalConfirm>
        </>
    )
}