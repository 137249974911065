import { useEffect, useState } from "react";
import { _BudgetRPFactory } from "../../../core/services/dataBean/EntityCatalog";
import useDataBean from "../../../core/services/dataBean/useDataBean";
import { Modal } from "../../theme/Component/Modal";
import { BasicButton } from "../../theme/Component/BasicButton";
import { YearSelector } from "../../../core/shared/inputs/YearSelector";
import { Singleton } from "../../../core/services/Singleton";
import { toast } from "react-toastify";
import { BasicTable, MASK_DATE, MASK_NUMBER } from "../../theme/Component/BasicTable";

interface Props {
    value?:any;
    contrat?: any;
    label?: string;
    onChange: Function;
}

export const CDPsEditor = ({ contrat, label, onChange }: Props) => {

    const _budgetRDFactory = useDataBean(_BudgetRPFactory);
    const single = Singleton.getInstance();

    const [valor, setValor] = useState<string>();
    const [year, setYear] = useState<number>(single.getCurrenYear());
    const [consecutive, setConsecutive] = useState<number>(NaN);

    const [modal, setModal] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });

    useEffect(() => {
        if (contrat?.IDAdquisitionContract) {
            getCDPContract();
        }
    }, [contrat])

    const getItem = () => {
        if (_budgetRDFactory.bean.IDDocument) {

            setModal({ ...modal, "view": false })
            setValor(`${_budgetRDFactory.bean.IDDocument} - ${_budgetRDFactory.bean.Mnemonic} - ${_budgetRDFactory.bean.Consecutive}`);
            onChange(_budgetRDFactory.bean)
        } else {
            toast.error("CDP no valido")
        }
    }

    const getCDPConsecutive = () => {
        _budgetRDFactory.instance.getCDPByConsecutive(year, consecutive, null).then(
            (resp) => {
                _budgetRDFactory.setLista(resp);
            }
        ).catch(
            err => { toast.error(err) }
        )
    }

    const getCDPContract = () => {
        _budgetRDFactory.instance.getCDPsByAdquisitionContract(contrat.IDAdquisitionContract, contrat.AdquisitionContractDate).then(
            (resp) => {
                _budgetRDFactory.setLista(resp)
            }
        ).catch(err => { toast.error(err, { autoClose: false }) })
    }

    const head = [
        { title: 'ID', property: 'IDDocument', visible: true, mask: MASK_NUMBER },
        { title: 'Fecha', property: 'Since', visible: true, mask: MASK_DATE },
        { title: 'Mnemonic', property: 'Mnemonic', visible: true },
        { title: 'Consecutivo', property: 'Consecutive', visible: true },
        { title: 'Estado del Documento', property: 'DocumentStateName', visible: true },
    ]

    return (
        <>

            <label>{label ?? "CDP"}</label>
            <div className="form-control text-wrap" style={{ minHeight: '35px' }} onClick={() => setModal({ ...modal, "view": true })}>
                {valor ? (
                    valor
                ) : (
                    <span>Seleccionar...</span>
                )}
            </div>

            <Modal onSubmitLabel='Seleccionar' modal={modal} updateModal={setModal} eventModal={getItem}>
                <div className="row">
                    {!contrat?.IDAdquisitionContract &&
                        <>
                            <div className="col-md-5">
                                <YearSelector tittle="Año" value={year} onChange={(e) => { setYear(e) }} />
                            </div>
                            <div className="col-md-5">
                                <label>Consecutivo</label>
                                <input type="number" className="form-control" value={consecutive} onChange={(e) => { setConsecutive(parseInt(e.target.value)) }} />
                            </div>
                            <div className="col-md-2 mt-4">
                                <BasicButton eventClick={getCDPConsecutive} icon="ri-search-line">Buscar</BasicButton>
                            </div>
                        </>
                    }
                    <div className="col-md-12">
                        <BasicTable body={_budgetRDFactory.lista} head={head} rowSet={_budgetRDFactory.setBean} />
                    </div>
                </div>
            </Modal>
        </>
    )
}