import { useState } from "react";
import { _DocumentFactory } from "../../../core/services/dataBean/EntityCatalog";
import useDataBean from "../../../core/services/dataBean/useDataBean";
import { Modal } from "../../theme/Component/Modal";
import { toast } from "react-toastify";
import { YearSelector } from "../../../core/shared/inputs/YearSelector";
import { BasicButton } from "../../theme/Component/BasicButton";
import { BasicTable } from "../../theme/Component/BasicTable";


interface Props {
    onChange: Function;
    label?: string;
}

export const DocumentHeaderEditor = ({ onChange, label }: Props) => {

    const { instance, bean, setBean, lista, setLista } = useDataBean(_DocumentFactory);

    const [valor, setValor] = useState<string>();
    const [response, setResponse] = useState<any>({})
    const [modal, setModal] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });

    const getItem = () => {
        if (bean.IDDocument) {
            setModal({ ...modal, "view": false })
            setValor(`${bean.IDDocument} - ${bean.Mnemonic} - ${bean.Consecutive}`);
            onChange(bean)
        } else {
            toast.error("Documento no valido")
        }
    }

    const buscarDocumento = () => {
        instance.getDocumentHeaderByConsecutive(response.Year, "RP", response.Consecutive).then(
            (resp: any) => {
                setLista(resp);
            }
        ).catch(err => toast.error(err));
    }

    const head = [
        { title: 'ID', property: 'IDDocument', visible: false },
        { title: 'Mnemonic', property: 'Mnemonic', visible: true },
        { title: 'Consecutivo', property: 'Consecutive', visible: true },
        { title: 'Estado', property: 'DocumentStateName', visible: true },
        { title: 'Fecha', property: 'Since', visible: true },
    ]

    return (
        <>
            <label>{label ?? "Documento Seleccionado"}</label>
            <div className="form-control text-wrap" style={{ minHeight: '35px' }} onClick={() => setModal({ ...modal, "view": true, "name": "Busqueda de Documento" })}>
                {valor ? (
                    valor
                ) : (
                    <span>Seleccionar...</span>
                )}
            </div>

            <Modal onSubmitLabel='Seleccionar' modal={modal} updateModal={setModal} eventModal={getItem}>
                <div className="row">
                    <div className="col-md-6">
                        <YearSelector value={response.Year} onChange={(e) => { setResponse({ ...response, "Year": e }) }} />
                    </div>
                    <div className="col-md-6">
                        <label>Consecutivo</label>
                        <input type="number" name="consecutivo" className="form-control" value={response.Consecutive} onChange={(e) => { setResponse({ ...response, "Consecutive": parseInt(e.target.value) }) }} />
                    </div>
                    <div className="col-md-6 mt-4">
                        <BasicButton icon="ri-search-line" eventClick={buscarDocumento}>Buscar</BasicButton>
                    </div>

                    <BasicTable body={lista} head={head} rowSet={setBean}></BasicTable>
                </div>
            </Modal>
        </>
    )
}