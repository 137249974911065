import { DataBean } from "../DataBean";

export class AdquisitionContractRP extends DataBean {

    public async getAdquisitionContractRPCatalogPorPropiedades(props: any, maxRows: number): Promise<any> {
        return this.getCustomService("FinanceCore", "java.util.List_getAdquisitionContractRPCatalogPorPropiedades_java.util.Map_Number", [props, maxRows]);
    }

    public async setAdquisitionContractToRP(idAdquisitionContract: number, adquisitionContractDate: any, idDocument: number, documentDate: any, idEmployee: number, createPdf: true): Promise<any> {
        return this.getCustomService(
            "QuickDataERPCore",
            "com.quickdataerp.bean.accountant.AccountantDocument_setAdquisitionContractToRP_Number_java.util.Date_Number_java.util.Date_Number_Boolean",
            [idAdquisitionContract, adquisitionContractDate, idDocument, documentDate, idEmployee, createPdf]
        );
    }

}