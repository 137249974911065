import { DataBean } from "../DataBean";

export class ManagementType extends DataBean {

    public async getManagementTypeRender(row: number | null, props: any): Promise<any> {
        return this.getCustomService("FinanceCore", "java.util.List_getManagementTypeRender_Number_java.util.Map", [row, props]);
    }

    public async getManagementTypeConstants(arg: number | null): Promise<any> {
        return this.getCustomService("FinanceCore", "java.util.List_getManagementTypeConstants_Number", [arg]);
    }

    public async addManagementTypeListForIDLn(idln: number, types: any): Promise<any> {
        return this.getCustomService("FinanceCore", "com.orange.bean.ManagementType_addManagementTypeListForIDLn_Number_java.util.List", [idln, types]);
    }
}