import { forwardRef, useEffect, useState } from "react";
import useDataBean from "../../../../../core/services/dataBean/useDataBean";
import { _CashReimbursement } from "../../../../../core/services/dataBean/EntityCatalog";
import { PrintJson } from "../../../../theme/Component/PrintJson";
import { SelectAccountantConcept } from "../../../../contract/etapa-contrato/contrac/ublinvoice/SelectAccountantConcept";
import { Singleton } from "../../../../../core/services/Singleton";
import { CashReimbursementConceptEditor } from "../../../../Talentohumano/CajasMenoresConceptos/CashReimbursementConcept.editor";



interface Props {
    idCashReimbursement: number;
    reimbursementDate: any;

}

export const EstablishBaseCashReimbursement = forwardRef(({ idCashReimbursement, reimbursementDate }: Props, ref) => {

    const { instance } = useDataBean(_CashReimbursement);
    const [moneyBase, setMoneyBase] = useState<any>({});
    const [constValue, setConstValue] = useState<any>({});
    const single = Singleton.getInstance();

    useEffect(() => {
        setMoneyBase({ ...moneyBase, "CashReimbursementID": idCashReimbursement, "DateReimbursement": reimbursementDate, "IdEmployee": single.getAccountID() })
    }, [idCashReimbursement])

    const createMoneyBase = () => {
        // instance.createCashReimbursementMoneyBase(1, 1, 1, 1, 1, "1", 1).then(
        //     (resp) => {
        //        //console.log(resp);
        //     }
        // ).catch(
        //     err => {
        //        console.error(err)
        //     }
        // )
    }

    return (
        <>
            <div className="row">
                <div className="col-md-6">
                    <SelectAccountantConcept idSelector={moneyBase.IdAccConcept} onChange={(e) => { setMoneyBase({ ...moneyBase, "IdAccConcept": e }) }} />
                </div>

                <div className="col-12">
                    <label>Valores a Constituir</label>
                    <div className="row">
                        <div className="col-md-5">
                            <CashReimbursementConceptEditor idSelector={constValue.BudgetIDStr} onChange={() => { }} />
                        </div>
                        <div className="col-md-5">

                        </div>
                        <div className="col-md-2">

                        </div>
                    </div>
                </div>

                <div className="col-12">
                    <label>Descripción</label>
                    <textarea rows={4} className="form-control" value={moneyBase.Obs} onChange={(e) => { setMoneyBase({ ...moneyBase, "Obs": e.target.value }) }}></textarea>
                </div>
            </div>
            <PrintJson json={moneyBase} />
        </>
    )
}
)