import { env } from "../../env";
import { CommitteSST } from "../../modules/Talentohumano/CommiteSST";
import { ModuleSSTT } from "../../modules/Talentohumano/ModuleSSTT";
import api from "./api/api";



export class ContractService {

  private url: string = env.REACT_APP_ENDPOINT + env.REACT_APP_DEPLOY + env.REACT_APP_SERVICE;
  // updateOfficeBusinessProcess 

  //fin

  public getSuppConstantsValue(idConstant: number) {
    const parametros = {
      ServiceName: "SupplierERP",
      MethodHash: "com.orange.finance.supplier.bean.SuppConstantsValue_getSuppConstantsValue_Number",
      ArgumentList: [
        idConstant
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public getAccountByNit(Nit: Number | null) {
    const parametros = {
      ServiceName: "OrangeBase",
      MethodHash: "java.util.List_getAccountByNit_Number",
      ArgumentList: [Nit],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public getBusinessProcessCatalog(idBusinessProcess: number | null, publicBusiness: boolean | null, privateBusiness: boolean | null, idBusinessClass: number | null, idCustomerType: number | null, idCharacterization: number | null) {
    const parametros = {
      ServiceName: "BpmService",
      MethodHash: "java.util.List_getBusinessProcessCatalog_Number_Boolean_Boolean_Number_Number_Number",
      ArgumentList: [idBusinessProcess, publicBusiness, privateBusiness, idBusinessClass, idCustomerType, idCharacterization],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }


  public getProcedureImpByAccount(idBusinessProcess: number | null, idaccount: number | null, fromdate: number | null, uptodate: number | null, state: number | null, page: number | null, recordsPerPage: number | null) {
    const parametros = {
      ServiceName: "BpmService",
      MethodHash: "com.advantage.shared.Report_getProcedureImpByAccount_Number_Number_java.util.Date_java.util.Date_Number_Number_Number",
      ArgumentList: [idBusinessProcess, idaccount, fromdate, uptodate, state, page, recordsPerPage],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }


  //Obligation
  public getObligationCatalog(idAdquisitionItem: number | null) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "java.util.List_getObligationCatalog_Number",
      ArgumentList: [idAdquisitionItem,],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public getManagementTypeConstants() {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "java.util.List_getManagementTypeConstants_Number",
      ArgumentList: [null],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public deleteObligation(idObligation: number | null) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "void_deleteObligation_Number",
      ArgumentList: [idObligation],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public updateObligation(bean: any) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "com.orange.bean.supplier.Obligation_updateObligation_com.orange.bean.supplier.Obligation",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: 'com.orange.bean.supplier.Obligation'
        }
      ],
    };

    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  //FIN Obligation

  public addOfficeToBusinessProcess(idBusinessProcess: number, idOffice: number) {
    const parametros = {
      ServiceName: "BpmService",
      MethodHash: "com.quickbpm.bean.OfficeBusinessProcess_addOfficeToBusinessProcess_Number_Number",
      ArgumentList: [
        idBusinessProcess,
        idOffice
      ],
    };

    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public removeOfficeToBusinessOffice(idBusinessProcess: number, idOffice: number) {
    const parametros = {
      ServiceName: "BpmService",
      MethodHash: "com.quickbpm.bean.OfficeBusinessProcess_removeOfficeToBusinessOffice_Number_Number",
      ArgumentList: [
        idBusinessProcess,
        idOffice
      ],
    };

    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }


  //getOfficeBusinessProcessCatalog service
  public getOfficeBusinessProcessCatalog(idOffice: number | null) {
    const parametros = {
      ServiceName: "BpmService",
      MethodHash: "java.util.List_getOfficeBusinessProcessCatalog_Number",
      ArgumentList: [idOffice],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public updateOfficeBusinessProcess(bean: any) {
    const parametros = {
      ServiceName: "BpmService",
      MethodHash: "com.quickbpm.bean.OfficeBusinessProcess_updateOfficeBusinessProcess_com.quickbpm.bean.OfficeBusinessProcess",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: 'com.quickbpm.bean.OfficeBusinessProcess'
        }
      ],
    };

    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  //fin
  //Responsevalueforform
  public getResponseValueForForm(IDForm: number | null) {
    const parametros = {
      ServiceName: "BpmService",
      MethodHash: "java.util.List_getResponseValueForForm_Number",
      ArgumentList: [IDForm],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public getJsonDataTypes() {
    const parametros = {
      ServiceName: "BpmService",
      MethodHash: "java.util.List_getJsonDataTypes_Number",
      ArgumentList: [null],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public addResponseValue(bean: any) {
    const parametros = {
      ServiceName: "BpmService",
      MethodHash:
        "com.quickbpm.bean.ResponseValue_addResponseValue_com.quickbpm.bean.ResponseValue",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: "com.quickbpm.bean.ResponseValue",
        },
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public deleteResponseValue(idResponse: number) {
    const parametros = {
      ServiceName: "BpmService",
      MethodHash: "Integer_deleteResponseValue_Number",
      ArgumentList: [idResponse],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public updateResponseValue(bean: any) {
    const parametros = {
      ServiceName: "BpmService",
      MethodHash: "com.quickbpm.bean.ResponseValue_updateResponseValue_com.quickbpm.bean.ResponseValue",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: 'com.quickbpm.bean.ResponseValue'
        }
      ],
    };

    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  //fin
  //getNoveltyCatalogPorIDTypeNovelty service
  public getNoveltyCatalogPorIDTypeNovelty(IDTipoNovedad: number | null, TypeNov: number | null,) {
    const parametros = {
      ServiceName: "TalentERP",
      MethodHash: "java.util.List_getNoveltyCatalogPorIDTypeNovelty_Number_Number",
      ArgumentList: [IDTipoNovedad, TypeNov,],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public deleteNovelty(IDTypeNovelty: number | null) {
    const parametros = {
      ServiceName: "TalentERP",
      MethodHash: "boolean_deleteNovelty_Number",
      ArgumentList: [IDTypeNovelty],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public updateNovelty(bean: any) {
    const parametros = {
      ServiceName: "TalentERP",
      MethodHash: "com.orange.people.talent.bean.Novelty_updateNovelty_com.orange.people.talent.bean.Novelty",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: 'com.orange.people.talent.bean.Novelty'
        }
      ],
    };

    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  //getNoveltyCatalogPorIDTypeNovelty fin
  //getTypeNoveltyCatalogPorPropiedad services
  public getTypeNoveltyCatalogPorPropiedad(TypeNovelty: number | null, TypeNov: number | null, Type: number | null) {
    const parametros = {
      ServiceName: "TalentERP",
      MethodHash: "java.util.List_getTypeNoveltyCatalogPorPropiedad_String_Object_Number",
      ArgumentList: [TypeNovelty, TypeNov, Type],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public deleteTypeNovelty(IDTypeNovelty: number | null) {
    const parametros = {
      ServiceName: "TalentERP",
      MethodHash: "boolean_deleteTypeNovelty_Number",
      ArgumentList: [IDTypeNovelty],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public updateTypeNovelty(bean: any) {
    const parametros = {
      ServiceName: "TalentERP",
      MethodHash: "com.com.orange.people.bean.TypeNovelty_updateTypeNovelty_com.com.orange.people.bean.TypeNovelty",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: 'com.com.orange.people.bean.TypeNovelty'
        }
      ],
    };

    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  //getTypeNoveltyCatalogPorPropiedad fin
  public getContractualStageConstants(idHiringModality: number | null) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "java.util.List_getContractualStageConstants_Number",
      ArgumentList: [idHiringModality],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }


  public getHiringModalityStageCatalog(idHiringModality: number | null, page: string | null, recordsPerPage: number | null) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "com.advantage.shared.Report_getHiringModalityStageCatalog_Number_Number_Number",
      ArgumentList: [idHiringModality, page, recordsPerPage],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public deleteHiringModalityStage(id: number) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "void_deleteHiringModalityStage_Number",
      ArgumentList: [id],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }


  public updateHiringModalityStage(bean: any) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "com.orange.bean.supplier.HiringModalityStage_updateHiringModalityStage_com.orange.bean.supplier.HiringModalityStage",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: 'com.orange.bean.supplier.HiringModalityStage'
        }
      ],
    };

    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  //Budget Modality
  public getModalityCatalogPorPropiedad(nombrePropiedad: string | null, value: string | null, maxRows: number | null) {
    const parametros = {
      ServiceName: "ContractErp",
      MethodHash: "java.util.List_getModalityCatalogPorPropiedad_String_Object_Number",
      ArgumentList: [nombrePropiedad, value, maxRows],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public deleteModality(id: number) {
    const parametros = {
      ServiceName: "ContractErp",
      MethodHash: "boolean_deleteModality_Number",
      ArgumentList: [id],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public updateModality(bean: any) {
    const parametros = {
      ServiceName: "ContractErp",
      MethodHash: "com.contract.bean.Modality_updateModality_com.contract.bean.Modality",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: 'com.contract.bean.Modality'
        }
      ],
    };

    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  //Fin Modality
  //Budget Modality
  public getHiringModalityCatalog(state: string | null, page: string | null, recordsPerPage: number | null) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "com.advantage.shared.Report_getHiringModalityCatalog_Number_Number_Number	",
      ArgumentList: [state, page, recordsPerPage],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public getHiringModalityCatalog2(year: number, month: number, state: string | null, page: string | null, recordsPerPage: number | null) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "com.advantage.shared.Report_getHiringModalityCatalog_Number_Number_Number_Number_Number",
      ArgumentList: [year, month, state, page, recordsPerPage],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public getHiringModalityRender(rows: number, props: any) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "java.util.List_getHiringModalityRender_Number_java.util.Map",
      ArgumentList: [rows, props],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public deleteHiringModality(id: number) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "void_deleteHiringModality_Number",
      ArgumentList: [id],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public updateHiringModality(bean: any) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "com.orange.bean.supplier.HiringModality_updateHiringModality_com.orange.bean.supplier.HiringModality",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: 'com.orange.bean.supplier.HiringModality'
        }
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  //Fin Modality
  // RequirementDocs
  public getRequirementDocsCatalogPorPropiedad(nombrePropiedad: string | null, value: string | null, maxRows: number | null) {
    const parametros = {
      ServiceName: "ContractErp",
      MethodHash: "java.util.List_getRequirementDocsCatalogPorPropiedad_String_Object_Number	",
      ArgumentList: [nombrePropiedad, value, maxRows],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public deleteRequirementDocs(id: number) {
    const parametros = {
      ServiceName: "ContractErp",
      MethodHash: "boolean_deleteRequirementDocs_Number",
      ArgumentList: [id],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public updateRequirementDocs(bean: any) {
    const parametros = {
      ServiceName: "ContractErp",
      MethodHash: "com.contract.bean.RequirementDocs_updateRequirementDocs_com.contract.bean.RequirementDocs",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: 'com.contract.bean.RequirementDocs'
        }
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  //Fin RequirementDocs
  // Process
  public getProcessCatalogPorPropiedad(nombrePropiedad: string | null, value: string | null, maxRows: number | null) {
    const parametros = {
      ServiceName: "ContractErp",
      MethodHash: "java.util.List_getProcessCatalogPorPropiedad_String_Object_Number",
      ArgumentList: [nombrePropiedad, value, maxRows],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public deleteProcess(id: number) {
    const parametros = {
      ServiceName: "ContractErp",
      MethodHash: "boolean_deleteProcess_Number",
      ArgumentList: [id],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public updateProcess(bean: any) {
    const parametros = {
      ServiceName: "ContractErp",
      MethodHash: "com.contract.bean.Process_updateProcess_com.contract.bean.Process",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: 'com.contract.bean.Process'
        }
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  //Fin Process
  // AdquisitionArticle


  public deleteAdquisitionArticle(id: number) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "void_deleteAdquisitionArticle_Number",
      ArgumentList: [id],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public updateAdquisitionArticle(bean: any) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "com.orange.bean.supplier.AdquisitionArticle_updateAdquisitionArticle_com.orange.bean.supplier.AdquisitionArticle",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: 'com.orange.bean.supplier.AdquisitionArticle'
        }
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  //Fin AdquisitionArticle

  // AdquisitionPlan

  public getAdquisitionPlanCatalog(fromYear: number | null, uptoYear: number | null, state: number | null, page: number | null, recordsPerPage: number | null) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "com.advantage.shared.Report_getAdquisitionPlanCatalog_Number_Number_Number_Number_Number",
      ArgumentList: [fromYear, uptoYear, state, page, recordsPerPage],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public getAdquisitionItemCatalog(idAdquisitionPlan: number | null, state: number | null, page: number | null, recordsPerPage: number | null) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "com.advantage.shared.Report_getAdquisitionItemCatalog_Number_Number_Number_Number",
      ArgumentList: [idAdquisitionPlan, state, page, recordsPerPage],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public getAdquisitionItemByFuncionalID(idAdquisitionPlanFunctionalID: number | null, state: number | null, page: number | null, recordsPerPage: number | null) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "com.advantage.shared.Report_getAdquisitionItemByFuncionalID_Number_Number_Number_Number	",
      ArgumentList: [idAdquisitionPlanFunctionalID, state, page, recordsPerPage],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public getAdquisitionItemByPreparer(idFunctionalIDPreparer: number, idAccount: number, page: number | null, recordsPerPage: number | null) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "com.advantage.shared.Report_getAdquisitionItemByPreparer_Number_Number_Number_Number",
      ArgumentList: [idFunctionalIDPreparer, idAccount, page, recordsPerPage],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public getAdquisitionArticleStructuringStateList(idAdquisitionItem: number) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "java.util.List_getAdquisitionArticleStructuringStateList_Number",
      ArgumentList: [idAdquisitionItem],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public getAdquisitionItem(idAdquisitionItem: number) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "com.orange.bean.supplier.AdquisitionItem_getAdquisitionItem_Number",
      ArgumentList: [idAdquisitionItem],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public getAdquisitionArticleCatalog(idAdquisitionItem: number, state: number | null, page: number | null, recordsPerPage: number | null) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "com.advantage.shared.Report_getAdquisitionArticleCatalog_Number_Number_Number_Number",
      ArgumentList: [idAdquisitionItem, state, page, recordsPerPage],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public getMonth(arg: number | null) {
    const parametros = {
      ServiceName: "OrangeCore",
      MethodHash: "java.util.List_getMonths_String",
      ArgumentList: [arg],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public getMonthConstants(arg: number | null) {
    const parametros = {
      ServiceName: "OrangeCore",
      MethodHash: "java.util.List_getMonths_String",
      ArgumentList: [arg],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public getAdquisitionArticleConstants(arg: number | null) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "java.util.List_getAdquisitionArticleConstants_Number",
      ArgumentList: [arg],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public getAdquisitionPlanFunctionalIDCatalogReport(idAdquisitionPlan: number, page: number | null, recordsPerPage: number | null) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "com.advantage.shared.Report_getAdquisitionPlanFunctionalIDCatalog_Number_Number_Number",
      ArgumentList: [idAdquisitionPlan, page, recordsPerPage],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public getAdquisitionPlanFunctionalIDCatalog(idAdquisitionPlan: number) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "java.util.List_getAdquisitionPlanFunctionalIDCatalog_Number",
      ArgumentList: [idAdquisitionPlan],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public getManagementTypeForIDLn(idFunctionalIDLn: number) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "java.util.List_getManagementTypeForIDLn_Number",
      ArgumentList: [idFunctionalIDLn],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public addFunctionalIDToAdquisitionPlan(idAdquisitionPlan: number, idLnFunctionalID: number, idAccount: number, idEmployee: number, description: string | null, closeToEstimatedValue: boolean, estimatedValue: number) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "boolean_addFunctionalIDToAdquisitionPlan_Number_Number_Number_Number_String_Boolean_Number",
      ArgumentList: [idAdquisitionPlan, idLnFunctionalID, idAccount, idEmployee, description, closeToEstimatedValue, estimatedValue],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public removeFunctionalIDFromAdquisitionPlan(idAdquisitionPlan: number, idLnFunctionalID: number) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "boolean_removeFunctionalIDFromAdquisitionPlan_Number_Number	",
      ArgumentList: [idAdquisitionPlan, idLnFunctionalID],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public getAdquisitionPlanApprovalNoteCatalog(idAdquisitionPlan: number, type: number | null, page: number | null, recordsPerPage: number | null) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "com.advantage.shared.Report_getAdquisitionPlanApprovalNoteCatalog_Number_Number_Number_Number",
      ArgumentList: [idAdquisitionPlan, type, page, recordsPerPage],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public getAdquisitionPlanPhaseConstants() {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "java.util.List_getAdquisitionPlanPhaseConstants_Number",
      ArgumentList: [null],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public getAdquisitionItemStructuringStateList(idAdquisitionPlan: number) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "java.util.List_getAdquisitionItemStructuringStateList_Number",
      ArgumentList: [idAdquisitionPlan],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public getAdquisitionArticleStructuringStateListByAdquisitionPlan(idAdquisitionPlan: number) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "java.util.List_getAdquisitionArticleStructuringStateListByAdquisitionPlan_Number",
      ArgumentList: [idAdquisitionPlan],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public adquisitionArticleStructured(idAdquisitionPlan: number, idEmployee: number | null, description: string | null) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "com.orange.bean.supplier.AdquisitionArticleApprovalNote_adquisitionArticleStructured_Number_Number_String	",
      ArgumentList: [idAdquisitionPlan, idEmployee, description],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public adquisitionArticleReviewed(idAdquisitionPlan: number, idEmployee: number | null, description: string | null) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "com.orange.bean.supplier.AdquisitionArticleApprovalNote_adquisitionArticleReviewed_Number_Number_String",
      ArgumentList: [idAdquisitionPlan, idEmployee, description],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public adquisitionArticleApproved(idAdquisitionPlan: number, idEmployee: number | null, description: string | null) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "com.orange.bean.supplier.AdquisitionArticleApprovalNote_adquisitionArticleApproved_Number_Number_String",
      ArgumentList: [idAdquisitionPlan, idEmployee, description],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public adquisitionArticleRejected(idAdquisitionPlan: number, idEmployee: number | null, description: string | null) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "com.orange.bean.supplier.AdquisitionArticleApprovalNote_adquisitionArticleRejected_Number_Number_String	",
      ArgumentList: [idAdquisitionPlan, idEmployee, description],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public adquisitionArticleCancelled(idAdquisitionPlan: number, idEmployee: number | null, description: string | null) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "com.orange.bean.supplier.AdquisitionArticleApprovalNote_adquisitionArticleCancelled_Number_Number_String",
      ArgumentList: [idAdquisitionPlan, idEmployee, description],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public adquisitionArticleIncompleted(idAdquisitionPlan: number, idEmployee: number | null, description: string | null) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "com.orange.bean.supplier.AdquisitionArticleApprovalNote_adquisitionArticleIncompleted_Number_Number_String",
      ArgumentList: [idAdquisitionPlan, idEmployee, description],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public openAdquisitionArticle(idAdquisitionPlan: number, idEmployee: number | null, description: string | null) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "com.orange.bean.supplier.AdquisitionArticleApprovalNote_openAdquisitionArticle_Number_Number_String	",
      ArgumentList: [idAdquisitionPlan, idEmployee, description],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public getAdquisitionArticleApprovalNoteCatalog(idAdquisitionArticle: number, type: number | null, page: number | null, recordsPerPage: number | null) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "com.advantage.shared.Report_getAdquisitionArticleApprovalNoteCatalog_Number_Number_Number_Number",
      ArgumentList: [idAdquisitionArticle, type, page, recordsPerPage],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }


  public setAdquisitionPlanOnReviewedState(idAdquisitionPlan: number, idEmployee: number | null, description: string | null) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "com.orange.bean.supplier.AdquisitionPlanApprovalNote_setAdquisitionPlanOnReviewedState_Number_Number_String",
      ArgumentList: [idAdquisitionPlan, idEmployee, description],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public setAdquisitionPlanOnApprovedState(idAdquisitionPlan: number, idEmployee: number | null, description: string | null) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "com.orange.bean.supplier.AdquisitionPlanApprovalNote_setAdquisitionPlanOnReviewedState_Number_Number_String",
      ArgumentList: [idAdquisitionPlan, idEmployee, description],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public setAdquisitionPlanOnCancelledState(idAdquisitionPlan: number, idEmployee: number | null, description: string | null) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "com.orange.bean.supplier.AdquisitionPlanApprovalNote_setAdquisitionPlanOnCancelledState_Number_Number_String",
      ArgumentList: [idAdquisitionPlan, idEmployee, description],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public setPhaseToAdquisitionPlan(idAdquisitionPlan: number, phase: number, idEmployee: number, description: string | null) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "boolean_setPhaseToAdquisitionPlan_Number_Number_Number_String",
      ArgumentList: [idAdquisitionPlan, phase, idEmployee, description],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public updateAdquisitionPlanStructuringState(idAdquisitionPlan: number) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "boolean_updateAdquisitionPlanStructuringState_Number",
      ArgumentList: [idAdquisitionPlan],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public updateAdquisitionPlan(bean: any) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "com.orange.bean.supplier.AdquisitionPlan_updateAdquisitionPlan_com.orange.bean.supplier.AdquisitionPlan",
      ArgumentList: [{
        DataBeanProperties: bean,
        DataBeanName: 'com.orange.bean.supplier.AdquisitionPlan'
      }],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public createPlanByOtherIDPlan(idAdquisitionPlan: number, idEmployee: number, beanPlan: any) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "com.orange.bean.supplier.AdquisitionPlan_createPlanByOtherIDPlan_Number_Number_com.orange.bean.supplier.AdquisitionPlan",
      ArgumentList: [
        idAdquisitionPlan, idEmployee,
        {
          DataBeanProperties: beanPlan,
          DataBeanName: 'com.orange.bean.supplier.AdquisitionPlan'
        }],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public deleteAdquisitionPlan(id: number) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "void_deleteAdquisitionPlan_Number",
      ArgumentList: [id],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  // AdquisitionPlan

  // items plan 

  public setAdquisitionItemOnReviewedState(idAdquisitionItem: number, idEmployee: number | null, description: string | null) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "com.orange.bean.supplier.AdquisitionItemApprovalNote_setAdquisitionItemOnReviewedState_Number_Number_String",
      ArgumentList: [idAdquisitionItem, idEmployee, description],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public setAdquisitionItemOnApprovedState(idAdquisitionItem: number, idEmployee: number | null, description: string | null) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "com.orange.bean.supplier.AdquisitionItemApprovalNote_setAdquisitionItemOnApprovedState_Number_Number_String",
      ArgumentList: [idAdquisitionItem, idEmployee, description],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public setAdquisitionItemOnCancelledState(idAdquisitionItem: number, idEmployee: number | null, description: string | null) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "com.orange.bean.supplier.AdquisitionItemApprovalNote_setAdquisitionItemOnCancelledState_Number_Number_String",
      ArgumentList: [idAdquisitionItem, idEmployee, description],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public setAdquisitionItemOnPreparedState(idAdquisitionItem: number, idEmployee: number | null, description: string | null) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "com.orange.bean.supplier.AdquisitionItemApprovalNote_setAdquisitionItemOnPreparedState_Number_Number_String",
      ArgumentList: [idAdquisitionItem, idEmployee, description],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public setAdquisitionItemOnRejectedPreparedState(idAdquisitionItem: number, idEmployee: number | null, description: string | null) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "com.orange.bean.supplier.AdquisitionItemApprovalNote_setAdquisitionItemOnRejectedPreparedState_Number_Number_String",
      ArgumentList: [idAdquisitionItem, idEmployee, description],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public setAdquisitionItemOnApprovedPreparedState(idAdquisitionItem: number, idEmployee: number | null, description: string | null) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "com.orange.bean.supplier.AdquisitionItemApprovalNote_setAdquisitionItemOnApprovedPreparedState_Number_Number_String",
      ArgumentList: [idAdquisitionItem, idEmployee, description],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public getAdquisitionItemOnPreparedState(idFunctionalIDPreparer: number, page: number | null, rows: number | null) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "com.advantage.shared.Report_getAdquisitionItemOnPreparedState_Number_Number_Number",
      ArgumentList: [idFunctionalIDPreparer, page, rows],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }


  public updateAdquisitionItemStructuringState(idAdquisitionItem: number) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "boolean_updateAdquisitionItemStructuringState_Number",
      ArgumentList: [idAdquisitionItem],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public getAdquisitionItemApprovalNoteCatalog(idAdquisitionItem: number, state: number | null, page: number | null, record: number | null) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "com.advantage.shared.Report_getAdquisitionItemApprovalNoteCatalog_Number_Number_Number_Number	",
      ArgumentList: [idAdquisitionItem, state, page, record],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public updateAdquisitionPlanFunctionalIDStructuringState(idAdquisitionPlanFunctionalID: number) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "boolean_updateAdquisitionPlanFunctionalIDStructuringState_Number	",
      ArgumentList: [idAdquisitionPlanFunctionalID],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public getAdquisitionArticleStructuringStateListByAdquisitionPlanFunctionalID(IDAdquisitionPlanFunctionalID: number) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "java.util.List_getAdquisitionArticleStructuringStateListByAdquisitionPlanFunctionalID_Number",
      ArgumentList: [IDAdquisitionPlanFunctionalID],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public getAdquisitionItemStructuringStateListForFunctionalID(IDAdquisitionPlanFunctionalID: number) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "java.util.List_getAdquisitionItemStructuringStateListForFunctionalID_Number",
      ArgumentList: [IDAdquisitionPlanFunctionalID],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public validateAdquisitionItem(id: number) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "java.util.List_validateAdquisitionItem_Number",
      ArgumentList: [id],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public updateAdquisitionItem(bean: any) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "com.orange.bean.supplier.AdquisitionItem_updateAdquisitionItem_com.orange.bean.supplier.AdquisitionItem",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: 'com.orange.bean.supplier.AdquisitionItem'
        }
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public changeAdquisitionItemApprovalNote(idAdquisitionItem: number, idEmployee: number, description: string, stateItem: number) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "com.orange.bean.supplier.AdquisitionItemApprovalNote_changeAdquisitionItemApprovalNote_Number_Number_String_Number",
      ArgumentList: [
        idAdquisitionItem, idEmployee, description, stateItem
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public deleteAdquisitionItem(id: number) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "void_deleteAdquisitionItem_Number",
      ArgumentList: [
        id
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public deleteAdquisitionPlanFunctionalID(id: number) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "void_deleteAdquisitionPlanFunctionalID_Number",
      ArgumentList: [
        id
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }


  // fin items plan 

  //IDRequirementType

  public getLegalRequirementTypeConstants() {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "java.util.List_getLegalRequirementTypeConstants_Number	",
      ArgumentList: [null],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public getRequirementTypeCatalog(legalRequirementType: number, page: number | null, records: number | null) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "com.advantage.shared.Report_getRequirementTypeCatalog_Number_Number_Number",
      ArgumentList: [legalRequirementType, page, records],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public deleteRequirementType(idRequirementType: number) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "void_deleteRequirementType_Number",
      ArgumentList: [idRequirementType],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public getBudgetIDList(idAdquisitionItem: number | null) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "java.util.List_getBudgetIDList_Number	",
      ArgumentList: [idAdquisitionItem],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public updateRequirementType(bean: any) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "com.orange.bean.supplier.RequirementType_updateRequirementType_com.orange.bean.supplier.RequirementType",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: 'com.orange.bean.supplier.RequirementType'
        }
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }


  // fin IDRequirementType
  //tHiringModalityDocument


  public getLegalTypeTermsConstants() {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "java.util.List_getLegalTypeTermsConstants_Number	",
      ArgumentList: [null],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public getHiringModalityDocumentCatalog(id: number, page: number | null, records: number | null) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "com.advantage.shared.Report_getHiringModalityDocumentCatalog_Number_Number_Number",
      ArgumentList: [id, page, records],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public deleteHiringModalityDocument(id: number) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "void_deleteHiringModalityDocument_Number",
      ArgumentList: [id],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public getAdquisitionPlanTypeConstants(id: number | null) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "java.util.List_getAdquisitionPlanTypeConstants_Number",
      ArgumentList: [id],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public updateHiringModalityDocument(bean: any) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "com.orange.bean.supplier.HiringModalityDocument_updateHiringModalityDocument_com.orange.bean.supplier.HiringModalityDocument",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: 'com.orange.bean.supplier.HiringModalityDocument'
        }
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }


  // fin HiringModalityDocument

  //StructuringDocument

  public getStructuringDocumentCatalog(args: any) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "java.util.List_getStructuringDocumentCatalog_Number",
      ArgumentList: [
        args
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public updateStructuringDocument(bean: any) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "com.orange.bean.supplier.StructuringDocument_updateStructuringDocument_com.orange.bean.supplier.StructuringDocument",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: 'com.orange.bean.supplier.StructuringDocument'
        }
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public deleteStructuringDocument(id: number) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "void_deleteStructuringDocument_Number",
      ArgumentList: [
        id
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  //Fin StructuringDocument

  //DocumentGroup

  public getDocumentGroupCatalog(idStructuringDocument: number) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "java.util.List_getDocumentGroupCatalog_Number	",
      ArgumentList: [
        idStructuringDocument
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public deleteDocumentGroup(id: number) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "void_deleteDocumentGroup_Number",
      ArgumentList: [
        id
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public updateDocumentGroup(bean: any) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "com.orange.bean.supplier.DocumentGroup_updateDocumentGroup_com.orange.bean.supplier.DocumentGroup",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: 'com.orange.bean.supplier.DocumentGroup'
        }
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  //Fin DocumentGroup

  //HiringProcess

  public getHiringProcessCatalog(idAdquisitionPlanFunctionalID: number, phaseState: number | null, structuringState: number | null) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "java.util.List_getHiringProcessCatalog_Number_Number_Number",
      ArgumentList: [
        idAdquisitionPlanFunctionalID, phaseState, structuringState
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public getHiringProcessByIDAdquisitionPlanFunctionalID(idAdquisitionPlanFunctionalID: number, state: number | null) {
    const parametros = {
      ServiceName: "SupplierERP",
      MethodHash: "java.util.List_getHiringProcessByIDAdquisitionPlanFunctionalID_Number_Number",
      ArgumentList: [
        idAdquisitionPlanFunctionalID, state
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public getHiringProcessByIDAdquisitionPlanFunctionalIDPrepared(idAdquisitionPlanFunctionalID: number, state: number | null, idAccountPrepared) {
    const parametros = {
      ServiceName: "SupplierERP",
      MethodHash: "java.util.List_getHiringProcessByIDAdquisitionPlanFunctionalIDPrepared_Number_Number_Number",
      ArgumentList: [
        idAdquisitionPlanFunctionalID, state, idAccountPrepared
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public getHiringProcess(id: any) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "com.orange.bean.supplier.HiringProcess_getHiringProcess_Number",
      ArgumentList: [
        id
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public getAdquisitionItemByHiringProcess(idHiringProcess: number, page: any, records: any) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "com.advantage.shared.Report_getAdquisitionItemByHiringProcess_Number_Number_Number	",
      ArgumentList: [
        idHiringProcess, page, records
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public addAdquisitionItemToHiringProcess(idHiringProcess: number, idAdquisitionItem: number, idEmployee: number) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "boolean_addAdquisitionItemToHiringProcess_Number_Number_Number",
      ArgumentList: [
        idHiringProcess, idAdquisitionItem, idEmployee
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public removeAdquisitionItemFromHiringProcess(idHiringProcess: number, idAdquisitionItem: number) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "boolean_removeAdquisitionItemFromHiringProcess_Number_Number",
      ArgumentList: [
        idHiringProcess, idAdquisitionItem
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public updateHiringProcess(bean: any) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "com.orange.bean.supplier.HiringProcess_updateHiringProcess_com.orange.bean.supplier.HiringProcess	",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: 'com.orange.bean.supplier.HiringProcess'
        }
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public updateHiringProcessObligations(bean: any) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "com.orange.bean.supplier.HiringProcessObligations_updateHiringProcessObligations_com.orange.bean.supplier.HiringProcessObligations",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: 'com.orange.bean.supplier.HiringProcessObligations'
        }
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public updateSupervisor(bean: any) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "com.orange.bean.supplier.Supervisor_updateSupervisor_com.orange.bean.supplier.Supervisor",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: 'com.orange.bean.supplier.Supervisor'
        }
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public addSupervisorToHiringProcess(idHiringProcess: number, idAccount: number, idEmployee: number, description: string) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "boolean_addSupervisorToHiringProcess_Number_Number_Number_String	",
      ArgumentList: [
        idHiringProcess, idAccount, idEmployee, description
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public removeSupervisorFromHiringProcess(idHiringProcess: number, idAccount: number) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "boolean_removeSupervisorFromHiringProcess_Number_Number",
      ArgumentList: [
        idHiringProcess, idAccount
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public deleteSupervisor(idHiringProcess: number) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "void_deleteSupervisor_Number",
      ArgumentList: [
        idHiringProcess
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public deleteHiringProcessObligations(idHiringProcess: number) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "void_deleteHiringProcessObligations_Number",
      ArgumentList: [
        idHiringProcess
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public getSupervisorCatalog(idHiringProcess: number) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "java.util.List_getSupervisorCatalog_Number",
      ArgumentList: [
        idHiringProcess
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public getHiringProcessObligationsCatalog(idHiringProcess: number) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "java.util.List_getHiringProcessObligationsCatalog_Number",
      ArgumentList: [
        idHiringProcess
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  //Fin HiringProcess


  //inicio TipoNovedad
  public getTipoNovedadCatalogPorPropiedad(nombrePropiedad: string | null, value: object | null, maxRows: number | null) {
    const parametros = {
      ServiceName: "InventariosService",
      MethodHash: "java.util.List_getTipoNovedadCatalogPorPropiedad_String_Object_Number",
      ArgumentList: [nombrePropiedad, value, maxRows],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public deleteTipoNovedad(IDTipoNovedad: number) {
    const parametros = {
      ServiceName: "InventariosService",
      MethodHash: "boolean_deleteTipoNovedad_Number",
      ArgumentList: [IDTipoNovedad],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public updateTipoNovedad(bean: any) {
    const parametros = {
      ServiceName: "InventariosService",
      MethodHash: "co.mil.dccae.inventarios.bean.TipoNovedad_updateTipoNovedad_co.mil.dccae.inventarios.bean.TipoNovedad",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: 'co.mil.dccae.inventarios.bean.TipoNovedad'
        }
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  //fin TipoNovedad

  //FunctionalIDPreparer
  public getFunctionalIDPreparerCatalog(idAdquisitionPlanFunctionalID: number, page: number | null, rows: number | null) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "com.advantage.shared.Report_getFunctionalIDPreparerCatalog_Number_Number_Number",
      ArgumentList: [
        idAdquisitionPlanFunctionalID, page, rows
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public addPreparerToAdquisitionPlanFunctionalID(idAdquisitionPlanFunctionalID: number, idAccount: number | null, idEmployee: number | null, description: string, estimatedValue: number) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "boolean_addPreparerToAdquisitionPlanFunctionalID_Number_Number_Number_String_Number",
      ArgumentList: [
        idAdquisitionPlanFunctionalID, idAccount, idEmployee, description, estimatedValue
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public removePreparerToAdquisitionPlanFunctionalID(idAdquisitionPlanFunctionalID: number, idAccount: number | null, idEmployee: number | null) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "boolean_removePreparerToAdquisitionPlanFunctionalID_Number_Number_Number",
      ArgumentList: [
        idAdquisitionPlanFunctionalID, idAccount, idEmployee
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public getAdquisitionPlanOnStructuringStateForPreparer(idAccount: number | null) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "java.util.List_getAdquisitionPlanOnStructuringStateForPreparer_Number",
      ArgumentList: [
        idAccount
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public getAdquisitionPlanFunctionalIDForPreparer(idAdquisitionPlan: number | null, idAccount: number) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "java.util.List_getAdquisitionPlanFunctionalIDForPreparer_Number_Number",
      ArgumentList: [
        idAdquisitionPlan, idAccount
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public getFunctionalIDPreparerByAccount(idAdquisitionPlanFunctionalID: number, idAccount: number) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "com.orange.bean.supplier.FunctionalIDPreparer_getFunctionalIDPreparerByAccount_Number_Number",
      ArgumentList: [
        idAdquisitionPlanFunctionalID, idAccount
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  //Fin FunctionalIDPreparer

  public getUnspscByName(txt: string | null) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "java.util.List_getUnspscByName_String",
      ArgumentList: [
        txt
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public getAdquisitionPlanByYear(ano: number) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "com.orange.bean.supplier.AdquisitionPlan_getAdquisitionPlanByYear_Number",
      ArgumentList: [
        ano
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public getAdquisitionPlanByYearSearch(ano: number) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "java.util.List_getAdquisitionPlanByYearSearch_Number",
      ArgumentList: [
        ano
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public getPlanTableCatalogPorPropiedad(property: any, valor: any, rows: any) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "java.util.List_getPlanTableCatalogPorPropiedad_String_Object_Number",
      ArgumentList: [
        property, valor, rows
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public getSeriesByAdquisitionPlanResources(idAdquisitionPlan: number, variable: string) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "java.util.List_getSeriesByAdquisitionPlanResources_Number_String",
      ArgumentList: [
        idAdquisitionPlan, variable
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public getSeriesByAdquisitionPlan(idAdquisitionPlan: number, variable: string) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "java.util.List_getSeriesByAdquisitionPlan_Number_String ",
      ArgumentList: [
        idAdquisitionPlan, variable
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public updatePlanTable(bean: any) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "com.orange.bean.PlanTable_updatePlanTable_com.orange.bean.PlanTable",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: "com.orange.bean.PlanTable"
        }
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  //approval plan 

  public getPlanApprovalCatalogPorPropiedades(mapProperties: any, rows: number | null) {
    const parametros = {
      ServiceName: "SupplierERP",
      MethodHash: "java.util.List_getPlanApprovalCatalogPorPropiedades_java.util.Map_Number",
      ArgumentList: [
        mapProperties,
        rows
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public getApprovalUserCatalogPorPropiedades(mapProperties: any, rows: number | null) {
    const parametros = {
      ServiceName: "SupplierERP",
      MethodHash: "java.util.List_getApprovalUserCatalogPorPropiedades_java.util.Map_Number",
      ArgumentList: [
        mapProperties,
        rows
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public ApprovalUserRender(idApprovalPlan: number | null) {
    const parametros = {
      ServiceName: "SupplierERP",
      MethodHash: "java.util.List_ApprovalUserRender_Number",
      ArgumentList: [
        idApprovalPlan
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public deleteApprovalUser(idapprovaluser: number | null) {
    const parametros = {
      ServiceName: "SupplierERP",
      MethodHash: "boolean_deleteApprovalUser_Number",
      ArgumentList: [
        idapprovaluser
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public updatePlanApproval(bean: any) {
    const parametros = {
      ServiceName: "SupplierERP",
      MethodHash: "com.orange.finance.supplier.bean.PlanApproval_updatePlanApproval_com.orange.finance.supplier.bean.PlanApproval",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: "com.orange.finance.supplier.bean.PlanApproval"
        }
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public updateApprovalUser(bean: any) {
    const parametros = {
      ServiceName: "SupplierERP",
      MethodHash: "com.orange.finance.supplier.bean.ApprovalUser_updateApprovalUser_com.orange.finance.supplier.bean.ApprovalUser",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: "com.orange.finance.supplier.bean.ApprovalUser"
        }
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public getRenderApprovalInbox(idAccount: number, state: number) {
    const parametros = {
      ServiceName: "SupplierERP",
      MethodHash: "java.util.List_getRenderApprovalInbox_Number_Number",
      ArgumentList: [
        idAccount, state
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public changeApprovalUserStatus(idApprovalUser: number, state: number, token: string, observation: string) {
    const parametros = {
      ServiceName: "SupplierERP",
      MethodHash: "com.orange.finance.supplier.bean.ApprovalUser_changeApprovalUserStatus_Number_Number_String_String",
      ArgumentList: [
        idApprovalUser, state, token, observation
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public requestApprovalUser(idApprovalUser: number) {
    const parametros = {
      ServiceName: "SupplierERP",
      MethodHash: "com.orange.finance.supplier.bean.PlanApproval_requestApprovalUser_Number",
      ArgumentList: [
        idApprovalUser
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public updateHiringDocumentStage(bean: any) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "com.orange.bean.HiringDocumentStage_updateHiringDocumentStage_com.orange.bean.HiringDocumentStage",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: "com.orange.bean.HiringDocumentStage"
        }
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public getHiringDocumentStageCatalogPorPropiedades(map: any) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "java.util.List_getHiringDocumentStageCatalogPorPropiedades_java.util.Map_Number",
      ArgumentList: [
        map, null
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public deleteHiringDocumentStage(idHiringDocumentStage: any) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "boolean_deleteHiringDocumentStage_Number",
      ArgumentList: [
        idHiringDocumentStage
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public renderHiringDocumentStage(idStage: number, stateDocument: number) {
    const parametros = {
      ServiceName: "SupplierERP",
      MethodHash: "java.util.List_renderHiringDocumentStage_Number_Number",
      ArgumentList: [
        idStage, stateDocument
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public getAdquisitionItemAprovedState(idAdquisitionPlanFunctionalID: number) {
    const parametros = {
      ServiceName: "SupplierERP",
      MethodHash: "java.util.List_getAdquisitionItemAprovedState_Number",
      ArgumentList: [
        idAdquisitionPlanFunctionalID
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public validateRequestContract(idAdquisitionItem: number) {
    const parametros = {
      ServiceName: "SupplierERP",
      MethodHash: "com.orange.bean.supplier.HiringProcess_validateRequestContract_Number",
      ArgumentList: [
        idAdquisitionItem
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public getAdquisitionItemAprovedStateByPreparered(idAdquisitionPlanFunctionalID: number, idAccountPrepared: number) {
    const parametros = {
      ServiceName: "SupplierERP",
      MethodHash: "java.util.List_getAdquisitionItemAprovedStateByPreparered_Number_Number",
      ArgumentList: [
        idAdquisitionPlanFunctionalID, idAccountPrepared
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public createHiringProcess(bean: any) {

    const parametros = {
      ServiceName: "SupplierERP",
      MethodHash: "com.orange.bean.supplier.HiringProcess_createHiringProcess_Number_com.orange.bean.supplier.HiringProcess",
      ArgumentList: [
        0,
        {
          DataBeanProperties: bean,
          DataBeanName: "com.orange.bean.supplier.HiringProcess"
        }
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);

  }
  public getSupplierCatalog(idSupplier: number, rows: number | null, page: number | null) {

    const parametros = {
      ServiceName: "SupplierERP",
      MethodHash: "java.util.List_getSupplierCatalog_Number_Number_Number",
      ArgumentList: [
        idSupplier, rows, page
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);

  }
  public getSupplierCatalogPorPropiedades(map: any, maxRow: number | null) {

    const parametros = {
      ServiceName: "SupplierERP",
      MethodHash: "java.util.List_getSupplierCatalogPorPropiedades_java.util.Map_Number",
      ArgumentList: [
        map, maxRow
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);

  }
  public getSupplierRender(state: number, maxRow: number | null) {

    const parametros = {
      ServiceName: "SupplierERP",
      MethodHash: "java.util.List_getSupplierRender_Number_Number",
      ArgumentList: [
        state, maxRow
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);

  }
  public getRequestQuoteRenderByIDHiringProcess(idHiringProcess: number | null) {

    const parametros = {
      ServiceName: "SupplierERP",
      MethodHash: "java.util.List_getRequestQuoteRenderByIDAdquisitionItem_Number",
      ArgumentList: [
        idHiringProcess
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);

  }


  public addSupplierToRequestQuote(idHiringProcess: number, idSupplier: number, idEmployee: number) {

    const parametros = {
      ServiceName: "SupplierERP",
      MethodHash: "com.orange.bean.RequestQuote_addSupplierToRequestQuote_Number_Number_Number",
      ArgumentList: [
        idHiringProcess, idSupplier, idEmployee
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);

  }
  public cancelRequestQuote(idRequestQuote: number, idEmployee: number) {

    const parametros = {
      ServiceName: "SupplierERP",
      MethodHash: "com.orange.bean.RequestQuote_cancelRequestQuote_Number_Number",
      ArgumentList: [
        idRequestQuote, idEmployee
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);

  }
  public getSupplierByNit(cadena: string) {

    const parametros = {
      ServiceName: "SupplierERP",
      MethodHash: "com.orange.finance.supplier.bean.Supplier_getSupplierByNit_Number",
      ArgumentList: [
        cadena
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);

  }
  public getSupplierFindByIdentification(rows: number | null, identificacion: string) {

    const parametros = {
      ServiceName: "SupplierERP",
      MethodHash: "java.util.List_getSupplierFindByIdentification_Number_Number",
      ArgumentList: [
        rows, identificacion
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);

  }
  public getSupplierFindByName(rows: number | null, cadena: string) {

    const parametros = {
      ServiceName: "SupplierERP",
      MethodHash: "java.util.List_getSupplierFindByName_Number_String",
      ArgumentList: [
        rows, cadena
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);

  }



  public updateRequestQuote(bean: any) {

    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "com.orange.bean.RequestQuote_updateRequestQuote_com.orange.bean.RequestQuote",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: "com.orange.bean.RequestQuote"
        }
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);

  }
  public updateHiringProcessConfig(bean: any) {

    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "com.orange.bean.HiringProcessConfig_updateHiringProcessConfig_com.orange.bean.HiringProcessConfig",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: "com.orange.bean.HiringProcessConfig"
        }
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);

  }

  public getHiringProcessConfigCatalogPorPropiedad(property: string | null, value: number | null, rows: number | null) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "java.util.List_getHiringProcessConfigCatalogPorPropiedad_String_Object_Number",
      ArgumentList: [
        property, value, rows
      ],
    };

    const data = JSON.stringify(parametros);
    return api.post(this.url, data);

  }
  public StartHiringProcess(idHiringProcess: number | null, idAdquisitionItem: number | null, initDate: any) {
    const parametros = {
      ServiceName: "SupplierERP",
      MethodHash: "com.orange.bean.HiringProcessConfig_StartHiringProcess_Number_Number_java.util.Date",
      ArgumentList: [
        idHiringProcess, idAdquisitionItem, initDate
      ],
    };

    const data = JSON.stringify(parametros);
    return api.post(this.url, data);

  }
  public makeCalendar(idHiringProcessConfig: number | null, fromDate: any) {
    const parametros = {
      ServiceName: "SupplierERP",
      MethodHash: "String_makeCalendar_Number_java.util.Date",
      ArgumentList: [
        idHiringProcessConfig, fromDate
      ],
    };

    const data = JSON.stringify(parametros);
    return api.post(this.url, data);

  }
  public makeFlowHiringModality(idHiringProcessConfig: number | null) {
    const parametros = {
      ServiceName: "SupplierERP",
      MethodHash: "String_makeFlowHiringModality_Number",
      ArgumentList: [
        idHiringProcessConfig
      ],
    };

    const data = JSON.stringify(parametros);
    return api.post(this.url, data);

  }
  public consolidateItems(idAdquisitionPlan: number) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "java.util.List_consolidateItems_Number",
      ArgumentList: [
        idAdquisitionPlan
      ],
    };

    const data = JSON.stringify(parametros);
    return api.post(this.url, data);

  }
  public closeAdquisitionPlan(idAdquisitionPlan: number) {
    const parametros = {
      ServiceName: "SupplierERP",
      MethodHash: "java.util.List_closeAdquisitionPlan_Number",
      ArgumentList: [
        idAdquisitionPlan
      ],
    };

    const data = JSON.stringify(parametros);
    return api.post(this.url, data);

  }
  public getPreviousStudyCatalogPorPropiedad(propiedad: string | null, value: number | null, rows: number | null) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "java.util.List_getPreviousStudyCatalogPorPropiedad_String_Object_Number",
      ArgumentList: [
        propiedad, value, rows
      ],
    };

    const data = JSON.stringify(parametros);
    return api.post(this.url, data);

  }
  public updatePreviousStudy(bean: any) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "com.orange.bean.PreviousStudy_updatePreviousStudy_com.orange.bean.PreviousStudy",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: "com.orange.bean.PreviousStudy"
        }
      ],
    };

    const data = JSON.stringify(parametros);
    return api.post(this.url, data);

  }
  public getHiringConstants(type: string) {
    const parametros = {
      ServiceName: "SupplierERP",
      MethodHash: "java.util.List_getHiringConstants_Number_String",
      ArgumentList: [
        0, type
      ],
    };

    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public postOffer(
    idHiringProcessConfig: number,
    idEmployee: number,
    obs: string,
    isPublic: boolean,
    suppliers: number[]
  ) {
    const parametros = {
      ServiceName: "FinanceCore",
      /* Cambiar Method Hash */
      MethodHash: "com.orange.bean.PostOffer_postOfert_Number_Number_String_Boolean_java.util.List",
      ArgumentList: [
        idHiringProcessConfig,
        idEmployee,
        obs,
        isPublic,
        suppliers
      ],
    };

    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public addOfferSupplier(idPostOfert: number, idSupplier: number) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "java.util.List_getHiringConstants_Number_String",
      ArgumentList: [
        idPostOfert,
        idSupplier
      ],
    };

    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public removeOfferSupplier(idPostOfert: number, idSupplier: number) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "Boolean_removeOfferSupplier_Number_Number",
      ArgumentList: [
        idPostOfert,
        idSupplier
      ],
    };

    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public getPostOfertRenderByIDHiringConfig(idHiringProcessConfig: number,) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "com.orange.bean.PostOffer_getPostOfertRenderByIDHiringConfig_Number",
      ArgumentList: [
        idHiringProcessConfig
      ],
    };

    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public getModuleSSTCatalogPorPropiedades() {
    const parametros = {
      ServiceName: "SafetyErp",
      MethodHash: "java.util.List_getModuleSSTCatalogPorPropiedades_java.util.Map_Number",
      ArgumentList: [
        {
          State: 1
        },
        null
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public updateModuleSST(bean: ModuleSSTT) {
    const parametros = {
      ServiceName: "SafetyErp",
      MethodHash: "com.orange.people.sgsst.bean.ModuleSST_updateModuleSST_com.orange.people.sgsst.bean.ModuleSST",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: "com.orange.people.sgsst.bean.ModuleSST"
        }
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public getCommitteeSSTCatalogPorPropiedades(mapFilters: any) {
    const parametros = {
      ServiceName: "SafetyErp",
      MethodHash: "java.util.List_getCommitteeSSTCatalogPorPropiedades_java.util.Map_Number",
      ArgumentList: [
        {
          ...mapFilters,
          State: 1
        },
        null
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public getCommitteeSSTCatalogPorPropiedadesRender(mapFilters: any) {
    const parametros = {
      ServiceName: "SafetyErp",
      MethodHash: "java.util.List_getCommitteeSSTCatalogPorPropiedadesRender_java.util.Map_Number_Number",
      ArgumentList: [
        {
          ...mapFilters,
          State: 1
        },
        null,
        null
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public updateCommitteeSST(bean: CommitteSST) {
    const parametros = {
      ServiceName: "SafetyErp",
      MethodHash: "com.orange.people.sgsst.bean.CommitteeSST_updateCommitteeSST_com.orange.people.sgsst.bean.CommitteeSST",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: "com.orange.people.sgsst.bean.CommitteeSST"
        }
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public getGroupCodeList() {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "java.util.List_getGroupCodeList_Number",
      ArgumentList: [
        null
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public getInboxByEmployee(idEmployee: number, props: any) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "java.util.List_getInboxByEmployee_Number_java.util.Map",
      ArgumentList: [
        idEmployee, props
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public getActionListByOfferEmployee(idOfferSupplier: number, props: any) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "java.util.List_getActionListByOfferEmployee_Number_java.util.Map",
      ArgumentList: [
        idOfferSupplier, props
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public getOfferSupplierComparative(idPostOffer: number) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "com.orange.bean.PostOffer_getOfferSupplierComparative_Number",
      ArgumentList: [
        idPostOffer
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public getOfferSupplierCatalogPorPropiedad(prop: string, value: any) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "java.util.List_getOfferSupplierCatalogPorPropiedad_String_Object_Number",
      ArgumentList: [
        prop,
        value,
        null
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public registerAdquisitionContract(idAdquisitionItem: number, idSupplier: number, idEmployee: number, contractDate: dateFns, isLote: boolean, codeContract: string) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "com.orange.bean.AdquisitionContract_registerAdquisitionContract_Number_Number_Number_java.util.Date_Boolean_String",
      ArgumentList: [
        idAdquisitionItem, idSupplier, idEmployee, contractDate, isLote, codeContract
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public updateAdquisitionContract(bean: any) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "com.orange.bean.AdquisitionContract_updateAdquisitionContract_com.orange.bean.AdquisitionContract",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: "com.orange.bean.AdquisitionContract"
        }
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public registerAdquisitionContractByItems(idEmploye: number, bean: any) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "com.orange.bean.AdquisitionContract_registerAdquisitionContractByItems_Number_com.orange.bean.AdquisitionContract",
      ArgumentList: [
        idEmploye,
        {
          DataBeanProperties: bean,
          DataBeanName: "com.orange.bean.AdquisitionContract"
        }
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public deleteAdquisitionContract(id: number) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "boolean_deleteAdquisitionContract_Number",
      ArgumentList: [
        id
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public getAdquisitionContractRender(page: number | null, row: number | null, props: any, date1: any, date2) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "java.util.List_getAdquisitionContractRender_Number_Number_java.util.Map_java.util.Date_java.util.Date",
      ArgumentList: [
        page, row, props, date1, date2
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public getAdquisitionContractRenderByIDAccount(page: number | null, row: number | null, props: any, idAccount: number) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "java.util.List_getAdquisitionContractRenderByIDAccount_Number_Number_java.util.Map_Number",
      ArgumentList: [
        page, row, props, idAccount
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }


  public getOfferEmployeeCatalogPorPropiedades(mapProperties: any, rows: number | null) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "java.util.List_getOfferEmployeeCatalogPorPropiedades_java.util.Map_Number",
      ArgumentList: [
        mapProperties,
        rows
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public getOfferEmployeeCatalogRender(idAdquisitionItem: number) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "java.util.List_getOfferEmployeeCatalogRender_Number",
      ArgumentList: [
        idAdquisitionItem
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public updateOfferEmployee(bean: any) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "com.orange.bean.OfferEmployee_updateOfferEmployee_com.orange.bean.OfferEmployee",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: "com.orange.bean.OfferEmployee"
        }
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public getUblInvoiceCatalogByAdquisitionContract(idAccountSupplier: number, idAdquisitionContract: number, from: any, upto: any, state: number | null) {
    const parametros = {
      ServiceName: "QuickDataERPCore",
      MethodHash: "java.util.List_getUblInvoiceCatalogByAdquisitionContract_Number_Number_java.util.Date_java.util.Date_Number",
      ArgumentList: [
        idAccountSupplier, idAdquisitionContract, from, upto, state
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public getUblInvoiceCatalog(idAccountSupplier: number, from: any, upto: any, state: number | null) {
    const parametros = {
      ServiceName: "QuickDataERPCore",
      MethodHash: "java.util.List_getUblInvoiceCatalog_Number_java.util.Date_java.util.Date_Number",
      ArgumentList: [
        idAccountSupplier, from, upto, state
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public getUBLInvoiceContractCatalog(idAdquisitionContract: number, adquisitionContractDate: Date) {
    const parametros = {
      ServiceName: "QuickDataERPCore",
      MethodHash: "java.util.List_getUBLInvoiceContractCatalog_Number_java.util.Date",
      ArgumentList: [
        idAdquisitionContract, adquisitionContractDate
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public releaseUBLInvoiceFromAdquisitionContract(idUBLInvoice: number, issueDate: Date | string, idAdquisitionContract: number, contractDate: Date | string, idAccountFiled: number, obsDeleted: string) {
    const parametros = {
      ServiceName: "QuickDataERPCore",
      MethodHash: "com.quickdataerp.bean.ubl.UBLInvoice_releaseUBLInvoiceFromAdquisitionContract_Number_java.util.Date_Number_java.util.Date_Number_String",
      ArgumentList: [
        idUBLInvoice, issueDate, idAdquisitionContract, contractDate, idAccountFiled, obsDeleted
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public releaseUBLInvoice(idUBLInvoice: number, issueDate: Date | string) {
    const parametros = {
      ServiceName: "QuickDataERPCore",
      MethodHash: "void_releaseUBLInvoice_Number_java.util.Date",
      ArgumentList: [
        idUBLInvoice, issueDate
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public importUBLInvoice(idConcept: number, media: string, mediaContext: string, dataStore: string | null, issueDate: Date | string, importProperties: any, idEmployee: number, observation: string) {
    const parametros = {
      ServiceName: "QuickDataERPCore",
      MethodHash: "com.quickdataerp.bean.ubl.UBLInvoice_importUBLInvoice_Number_String_String_String_java.util.Date_java.util.Map_Number_String",
      ArgumentList: [
        idConcept, media, mediaContext, dataStore, issueDate, importProperties, idEmployee, observation
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public importUBLInvoiceContainer(idAccountantConcept: number, media: string, mediaContext: string, dataStore: string | null, issueDate: Date | string, importProperties: any, idEmployee: number, observation: string) {
    const parametros = {
      ServiceName: "QuickDataERPCore",
      MethodHash: "java.util.List_importUBLInvoiceContainer_Number_String_String_String_java.util.Date_java.util.Map_Number_String",
      ArgumentList: [idAccountantConcept, media, mediaContext, dataStore, issueDate, importProperties, idEmployee, observation],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public getUBLItemInvoiceCatalog(idUBLInvoice: number, issueDate: Date) {
    const parametros = {
      ServiceName: "QuickDataERPCore",
      MethodHash: "java.util.List_getUBLItemInvoiceCatalog_Number_java.util.Date",
      ArgumentList: [
        idUBLInvoice, issueDate
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public getTaxSchemaValuesForUBLInvoiceItem(idTaxScheme: number, idUBLItem: number, issueDate: Date) {
    const parametros = {
      ServiceName: "QuickDataERPCore",
      MethodHash: "java.util.List_getTaxSchemaValuesForUBLInvoiceItem_Number_Number_java.util.Date",
      ArgumentList: [
        idTaxScheme, idUBLItem, issueDate
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public getTaxSchemaValuesWithHoldingTaxForUBLInvoiceItem(idTaxScheme: number, idUBLItem: number, issueDate: Date) {
    const parametros = {
      ServiceName: "QuickDataERPCore",
      MethodHash: "java.util.List_getTaxSchemaValuesWithHoldingTaxForUBLInvoiceItem_Number_Number_java.util.Date",
      ArgumentList: [
        idTaxScheme, idUBLItem, issueDate
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public addUBLInvoiceToAdquisitionContract(idUBLInvoice: number, issueDate: Date, idAdquisitionContract: number,
    contractDate: Date, idAccountSupplier, observations: string | null, idEmployee: number) {
    const parametros = {
      ServiceName: "QuickDataERPCore",
      MethodHash: "com.quickdataerp.bean.ubl.UBLInvoice_addUBLInvoiceToAdquisitionContract_Number_java.util.Date_Number_java.util.Date_Number_String_Number",
      ArgumentList: [
        idUBLInvoice, issueDate, idAdquisitionContract, contractDate, idAccountSupplier, observations, idEmployee
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public getAdquisitionContractCatalogPorPropiedades(props: any, rows: number | null) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "java.util.List_getAdquisitionContractCatalogPorPropiedades_java.util.Map_Number",
      ArgumentList: [
        props, rows
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public getAdquisitionArticleByAdquisitionItem(idAdquisitionItem: number) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "java.util.List_getAdquisitionArticleByAdquisitionItem_Number",
      ArgumentList: [
        idAdquisitionItem
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public addUBLItemInvoiceToAdquisitionArticle(idUBLInvoice: number, issueDate: Date, idAdquisitionContract: number, contractDate: Date, idUBLItemInvoice: number, idAdquisitionArticle: number) {
    const parametros = {
      ServiceName: "QuickDataERPCore",
      MethodHash: "com.quickdataerp.bean.ubl.UBLItemInvoice_addUBLItemInvoiceToAdquisitionArticle_Number_java.util.Date_Number_java.util.Date_Number_Number",
      ArgumentList: [
        idUBLInvoice, issueDate, idAdquisitionContract, contractDate, idUBLItemInvoice, idAdquisitionArticle
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public releaseUBLItemInvoiceFromAdquisitionArticle(idUBLInvoice: number, issueDate: Date, idAdquisitionContract: number, contractDate: Date, idUBLItemInvoice: number) {
    const parametros = {
      ServiceName: "QuickDataERPCore",
      MethodHash: "com.quickdataerp.bean.ubl.UBLItemInvoice_releaseUBLItemInvoiceFromAdquisitionArticle_Number_java.util.Date_Number_java.util.Date_Number",
      ArgumentList: [
        idUBLInvoice, issueDate, idAdquisitionContract, contractDate, idUBLItemInvoice
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public getUBLItemInvoicePendingForAssign(idUBLInvoice: number, issueDate: Date) {
    const parametros = {
      ServiceName: "QuickDataERPCore",
      MethodHash: "double_getUBLItemInvoicePendingForAssign_Number_java.util.Date",
      ArgumentList: [
        idUBLInvoice, issueDate
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public getUBLItemArticleConsolidaded(idAdquisitionContract: number, adquisitionContractDate: Date) {
    const parametros = {
      ServiceName: "QuickDataERPCore",
      MethodHash: "java.util.List_getUBLItemArticleConsolidaded_Number_java.util.Date",
      ArgumentList: [
        idAdquisitionContract, adquisitionContractDate
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public getContractArticleItemByIDAdquisitionItem(idAdquisitionItem: number, idContract: number) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "java.util.List_getContractArticleItemByIDAdquisitionItem_Number_Number",
      ArgumentList: [
        idAdquisitionItem, idContract
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public updateAdquisitionContractValueByItem(idAdquisitionItem: number) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "com.orange.bean.AdquisitionContract_updateAdquisitionContractValueByItem_Number",
      ArgumentList: [
        idAdquisitionItem
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public getContractArticleItemCatalogPorPropiedades(props: any, rows: number | null) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "java.util.List_getContractArticleItemCatalogPorPropiedades_java.util.Map_Number",
      ArgumentList: [
        props, rows
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public updateContractArticleItem(bean: any) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "com.orange.bean.ContractArticleItem_updateContractArticleItem_com.orange.bean.ContractArticleItem",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: "com.orange.bean.ContractArticleItem"
        }
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public deleteContractArticleItem(idbean: number) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "boolean_deleteContractArticleItem_Number",
      ArgumentList: [
        idbean
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public getAdquisitionArticleByIDAdquisitionContract(idAdquisitionContract: number) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "java.util.List_getAdquisitionArticleByIDAdquisitionContract_Number",
      ArgumentList: [
        idAdquisitionContract
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public getPdfAccountantDocument(documentLocation: number, mnemonic: string, date: Date) {
    const parametros = {
      ServiceName: "QuickDataERPCore",
      MethodHash: "java.util.List_getPdfAccountantDocument_Number_String_java.util.Date",
      ArgumentList: [
        documentLocation, mnemonic, date
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public getUBLTaxItemWithHoldingTaxCatalog(idUBLInvoice: number, issueDate: Date | string) {
    const parametros = {
      ServiceName: "QuickDataERPCore",
      MethodHash: "java.util.List_getUBLTaxItemWithHoldingTaxCatalog_Number_java.util.Date",
      ArgumentList: [
        idUBLInvoice, issueDate
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public getUBLTaxItemCatalog(idUBLInvoice: number, issueDate: Date | string) {
    const parametros = {
      ServiceName: "QuickDataERPCore",
      MethodHash: "java.util.List_getUBLTaxItemCatalog_Number_java.util.Date",
      ArgumentList: [
        idUBLInvoice, issueDate
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public getTaxFilerTypes() {
    const parametros = {
      ServiceName: "QuickDataERPCore",
      MethodHash: "java.util.List_getTaxFilerTypes_Number",
      ArgumentList: [
        null
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public getTaxableArticleTypes() {
    const parametros = {
      ServiceName: "QuickDataERPCore",
      MethodHash: "java.util.List_getTaxableArticleTypes_Number",
      ArgumentList: [
        null
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public getTaxSchemeContextWithHoldingTaxCatalog(adquisitionArticleType: number, taxFilerType: number) {
    const parametros = {
      ServiceName: "QuickDataERPCore",
      MethodHash: "java.util.List_getTaxSchemeContextWithHoldingTaxCatalog_Number_Number_Number",
      ArgumentList: [
        null, adquisitionArticleType, taxFilerType
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public getTaxSchemeCatalogWithHoldingTax(idTaxSchemeContext: number, adquisitionArticleType: number, taxFilerType: number) {
    const parametros = {
      ServiceName: "QuickDataERPCore",
      MethodHash: "java.util.List_getTaxSchemeCatalogWithHoldingTax_Number_Number_Number",
      ArgumentList: [
        idTaxSchemeContext, adquisitionArticleType, taxFilerType
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public getTaxSchemeValueCatalogAvailable(idTaxScheme: number, adquisitionArticleType: number, taxFilerType: number) {
    const parametros = {
      ServiceName: "QuickDataERPCore",
      MethodHash: "java.util.List_getTaxSchemeValueCatalogAvailable_Number_Number_Number",
      ArgumentList: [
        idTaxScheme, adquisitionArticleType, taxFilerType
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public addTaxWithHoldingTaxToUBLInvoice(idUBLInvoice: number, issueDate: Date | string, idTaxSchemeValue: number, adquisitionArticleType: number) {
    const parametros = {
      ServiceName: "QuickDataERPCore",
      MethodHash: "boolean_addTaxWithHoldingTaxToUBLInvoice_Number_java.util.Date_Number_Number",
      ArgumentList: [
        idUBLInvoice, issueDate, idTaxSchemeValue, adquisitionArticleType
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public getProcessReport(idUBLInvoice: number, issueDate: Date | string) {
    const parametros = {
      ServiceName: "QuickDataERPCore",
      MethodHash: "java.util.List_getProcessReport_Number_java.util.Date",
      ArgumentList: [
        idUBLInvoice, issueDate
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public createAccountantPaymentForUBLInvoice(idUBLInvoice: number, issueDate: Date | string, recordDate: Date | string, idAccount: number) {
    const parametros = {
      ServiceName: "QuickDataERPCore",
      MethodHash: "java.util.List_createAccountantPaymentForUBLInvoice_Number_java.util.Date_java.util.Date_Number",
      ArgumentList: [
        idUBLInvoice, issueDate, recordDate, idAccount
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public createAccountantDocumentForUBLInvoice(idUBLInvoice: number, issueDate: Date | string, recordDate: Date | string, idAccount: number) {
    const parametros = {
      ServiceName: "QuickDataERPCore",
      MethodHash: "java.util.List_createAccountantDocumentForUBLInvoice_Number_java.util.Date_java.util.Date_Number",
      ArgumentList: [
        idUBLInvoice, issueDate, recordDate, idAccount
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }


}