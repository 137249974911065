
import { AccountantIDType } from "./entityBean/AccountantIDType";
import { AdquisitionBeneficiary } from "./talentoHumano/AdquisitionBeneficiary";
import { AdquisitionProject } from "./talentoHumano/AdquisitionProject";
import { ArticleClassType } from "./talentoHumano/ArticleClassType";
import { BudgetAccountantID } from "./talentoHumano/BudgetAccountantID";
import { BudgetCDPReductionFactory } from "./talentoHumano/BudgetCDPreductionFactory";
import { BudgetSource } from "./talentoHumano/BudgetSource";
import { CodeUnscp } from "./talentoHumano/CodeUnscp";
import { ConfigAdmin } from "./talentoHumano/ConfigAdmin";
import { ContractArticleItem } from "./talentoHumano/ContractArticleItem";
import { ContractType } from "./talentoHumano/ContractType";
import { CostingCenter } from "./talentoHumano/CostingCenter";
import { CourseAccount } from "./talentoHumano/CourseAccount";
import { DocumentTypeC } from "./talentoHumano/DocumentTypeC";
import { EmployeeRevenue } from "./talentoHumano/EmployeeRevenue";
import { FunctionalAreavdos } from "./talentoHumano/FunctionalArea";
import { HiringModality } from "./talentoHumano/HiringModality";
import { ImportedPayrollBeneficiary } from "./talentoHumano/ImportedPayrollBeneficiary";
import { MeasurementUnitCode } from "./talentoHumano/MeasurementUnitCode";
import { PaymentAccount } from "./talentoHumano/PaymentAccount";
import { PaymentAccountItem } from "./talentoHumano/PaymentAccountItem";
import { PaymentMethod } from "./talentoHumano/PaymentMethod";
import { PaymentRequirement } from "./talentoHumano/PaymentRequirement";
import { Process } from "./talentoHumano/Process";
import { ProcessStep } from "./talentoHumano/ProcessStep";
import { Product } from "./talentoHumano/Product";
import { ProductLine } from "./talentoHumano/ProductLine";
import { Project } from "./talentoHumano/Project";
import { ProjectBeneficiary } from "./talentoHumano/ProjectBeneficiary";
import { RequirementStep } from "./talentoHumano/RequirementStep";
import { SupplierPaymentOrder } from "./talentoHumano/SupplierPaymentOrder";
import { SupplierPaymentOrderItem } from "./talentoHumano/SupplierPaymentOrderItem";
import { TaxCode } from "./talentoHumano/TaxCode";
import { TaxCodeAccount } from "./talentoHumano/TaxCodeAccount";
import { TaxCodeScheme } from "./talentoHumano/TaxCodeScheme";




export const _AccountantIDType = new AccountantIDType("QuickDataERPCore", "com.quickdataerp.bean.accountant.AccountantIDType");
export const _TaxCodeAccount = new TaxCodeAccount("QuickDataERPCore", "com.quickdataerp.bean.tax.TaxCodeAccount");
export const _TaxCode = new TaxCode("QuickDataERPCore", "com.quickdataerp.bean.tax.TaxCode");
export const _TaxCodeScheme = new TaxCodeScheme("QuickDataERPCore", "com.quickdataerp.bean.tax.TaxCodeScheme");
export const _EmployeeRevenue = new EmployeeRevenue("QuickDataERPCore", "employees.EmployeeRevenue");
export const _MeasurementUnitCode = new MeasurementUnitCode("QuickDataERPCore","com.quickdataerp.bean.ubl.MeasurementUnitCode");
export const _CostingCenter = new CostingCenter("QuickDataERPCore","com.quickdataerp.bean.accountant.CostingCenter");
export const _SupplierPaymentOrder = new SupplierPaymentOrder("QuickDataERPCore","com.quickdataerp.bean.supplier.SupplierPaymentOrder");
export const _SupplierPaymentOrderItem =new SupplierPaymentOrderItem("QuickDataERPCore","com.quickdataerp.bean.supplier.SupplierPaymentOrderItem");
export const _ContractArticleItem =new ContractArticleItem("QuickDataERPCore","com.quickdataerp.bean.supplier.ContractedArticleItem");
export const _ArticleClassType = new ArticleClassType("QuickDataERPCore","com.quickdataerp.bean.supplier.ArticleClassType");
export const _PaymentRequirement = new PaymentRequirement("FinanceCore","com.orange.bean.PaymentRequirement");
export const _HiringModality = new HiringModality("FinanceCore","com.orange.bean.supplier.HiringModality");
export const _ContractType = new ContractType("FinanceCore","com.orange.bean.ContractType");
export const _AdquisitionProject = new AdquisitionProject("FinanceCore","com.orange.bean.AdquisitionProject");
export const _AdquisitionBeneficiary = new AdquisitionBeneficiary("FinanceCore","com.orange.bean.AdquisitionBeneficiary");
export const _CourseAccount =new CourseAccount("CourseERP","com.orange.people.course.bean.CourseAccount");
export const _ConfigAdmin = new ConfigAdmin("QuickDataERPCore","com.orange.bean.functional.FunctionalID");
export const _FunctionalAreavdos =  new FunctionalAreavdos("QuickDataERPCore","admin.FunctionalArea");
export const _Product = new Product("QuickDataERPCore","inventory.Product");
export const _ProductLine = new ProductLine("QuickDataERPCore","inventory.ProductLine");
export const _BudgetAccountantID = new BudgetAccountantID("QuickDataERPCore","admin.BudgetAccountantID");
export const _CodeUnscp = new CodeUnscp("FinanceCore","com.orange.bean.UnscpFunctionalID");
export const _PaymentAccount = new PaymentAccount("QuickDataERPCore","documents.PaymentAccount");
export const _PaymentMethod = new PaymentMethod("FinanceCore","com.orange.bean.PaymentMethod");
export const _ImportedPayrollBeneficiary = new ImportedPayrollBeneficiary("QuickDataERPCore","com.quickdataerp.bean.employees.ImportedPayrollBeneficiary");
export const _BudgetSource = new BudgetSource("QuickDataERPCore","com.quickdataerp.bean.budget.BudgetSource");
export const _BudgetCDPreductionFactory = new BudgetCDPReductionFactory("","");
export const _Project = new Project("FinanceCore","com.orange.bean.Project");
export const _ProjectBeneficiary = new ProjectBeneficiary("FinanceCore","com.orange.bean.ProjectBeneficiary");
export const _DocumentTypeC = new DocumentTypeC("ProcessFlow","com.advantage.processflow.bean.DocumentType");
export const _Process = new Process("ProcessFlow","com.advantage.processflow.bean.Process");
export const _ProcessStep = new ProcessStep("ProcessFlow","com.advantage.processflow.bean.ProcessStep");
export const _RequirementStep = new RequirementStep("ProcessFlow","com.advantage.processflow.bean.RequirementStep")