import { DataBean } from "../DataBean";


export class EmploymentBonusValue extends DataBean {

    public async getAppointmentsByEmployeeBonus(idEmploymentBonus: number): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_getAppointmentsByEmployeeBonus_Number", [idEmploymentBonus]);
    }

    public async createEmployeeBonus(idEmploymentBonus: number, idAccount: number, bonusValue: number, idEmployee: number, isTemporary: boolean, from: any, upto: any): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "employees.EmployeeBonusValue_createEmployeeBonus_Number_Number_Number_Number", [idEmploymentBonus, idAccount, bonusValue, idEmployee, isTemporary, from, upto]);
    }

    public async deleteEmployeeBonusValue(idType: number): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "void_deleteEmployeeBonusValue_Number", [idType]);
    }

    public async getAppointmentCatalogByEmploymentBonus(idEmploymentBonus: number): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_getAppointmentCatalogByEmploymentBonus_Number", [idEmploymentBonus]);
    }

    public async createAppointmentBonus(idAppointment: number, idEmploymentBonus: number, idEmployee: number): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "employees.AppointmentBonus_createAppointmentBonus_Number_Number_Number", [idAppointment, idEmploymentBonus, idEmployee]);
    }

    public async deleteAppointmentBonus(idAppointment: number, idEmploymentBonus: number, idEmployee: number): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "employees.AppointmentBonus_deleteAppointmentBonus_Number_Number_Number", [idAppointment, idEmploymentBonus, idEmployee]);
    }

}