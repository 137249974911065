import { DataBean } from "../DataBean";

export class SupplierPaymentOrderItem extends DataBean {

    public async addPaymentOrderItem(idSupplierPaymentOrder:any,supplierPaymentOrderDate: any,idAdquisitionArticle:any,value:any): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "com.quickdataerp.bean.supplier.SupplierPaymentOrderItem_addPaymentOrderItem_Number_java.util.Date_Number_Number", [idSupplierPaymentOrder,supplierPaymentOrderDate,idAdquisitionArticle,value]);
    }

    public async deleteSupplierPaymentOrderItem(idSupplierPaymentOrderItem:any,since:any,idEmployee:any): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "void_deleteSupplierPaymentOrderItem_Number_java.util.Date_Number", [idSupplierPaymentOrderItem,since,idEmployee]);
    }

    public async getSupplierPaymentOrderItemCatalogForAdquisitionContract(idSupplierPaymentOrder:number,supplierPaymentOrderDate:any): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_getSupplierPaymentOrderItemCatalogForAdquisitionContract_Number_java.util.Date", [idSupplierPaymentOrder,supplierPaymentOrderDate]);
    }
    public async addUBLItemInvoiceToAdquisitionArticle(idSupplierPaymentOrderItem:number,supplierPaymentOrderDate:any,idUBLItemInvoice:number): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "com.quickdataerp.bean.ubl.UBLItemInvoice_addUBLItemInvoiceToAdquisitionArticle_Number_java.util.Date_Number", [idSupplierPaymentOrderItem,supplierPaymentOrderDate,idUBLItemInvoice]);
    }
    public async releaseUBLItemInvoiceFromAdquisitionArticle(idUBLInvoice:number,issueDate:any,idAdquisitionContract:number): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "com.quickdataerp.bean.ubl.UBLItemInvoice_releaseUBLItemInvoiceFromAdquisitionArticle_Number_java.util.Date_Number", [idUBLInvoice,issueDate,idAdquisitionContract]);
    }




} 