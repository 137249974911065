
import { useEffect, useState } from 'react';
import { Singleton } from '../../../../../core/services/Singleton';
import { BasicTable, MASK_DATE } from '../../../../theme/Component/BasicTable';
import { toast } from 'react-toastify';
import { StateReimbursementEditor } from './StateReimbursementEditor';
import { BasicPage } from '../../../../theme/Component/BasicPage';
import { BasicButton } from '../../../../theme/Component/BasicButton';
import { ListMenu } from '../../../../theme/Component/ListMenu';
import { Modal } from '../../../../theme/Component/Modal';
import { CashReimbursementBudget } from './CashReimbursementBudget';
import useDataBean from '../../../../../core/services/dataBean/useDataBean';
import { _CashReimbursement, _CashReimbursementBudget } from '../../../../../core/services/dataBean/EntityCatalog';
import { ModalConfirm } from '../../../../theme/Component/ModalConfirm';
import { CashReimbursementItem } from './CashReimbursementItem';
import { EstablishBaseCashReimbursement } from './EstablishBaseCashReimbursement';
import { CashDeskAccountSelector } from '../../CashDeskAccount/CashDeskAccountSelector';

interface Props {
    idCashDeskA?: number;
    menuComponent?: boolean;
}

export const CashReimbursementHistory = ({ idCashDeskA, menuComponent }: Props) => {
    const { bean, setBean, lista, setLista, instance, handleChange } = useDataBean(_CashReimbursement);
    const single = Singleton.getInstance()

    const [state, setState] = useState<number>(0)
    const [year, setYear] = useState<number>(single.getCurrenYear())
    const [cashDesk, setCashDesk] = useState<number>(null);

    const [modal, setModal] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: "modal-sm", });
    const [modalC, setModalC] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: "modal-sm", });
    const [modalConcep, setModalConcep] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: "modal-lg", });
    const [modalSeeUBL, setModalSeeUBL] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: "modal-xl", });
    const [modalMoneyBase, setModalMoneyBase] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: "modal-xl", });

    const head = [
        { title: 'ID', property: "IDCashReimbursement" },
        { title: 'Caja Dependiente', property: 'IDCashDeskAccount' },
        { title: 'Estado del Proceso', property: 'StateName' },
        { title: 'Estado del rembolso', property: 'ReimbursementStateName' },
        { title: 'No Externo de Referencia', property: 'SecuenceName' },
        { title: 'No CDP', property: 'CDPMnemonic' },
        { title: 'Fecha de CDP', property: 'CDPDate' },
        { title: 'Documento Contable', property: 'DocMnemonic' },
        { title: 'Fecha de Documento Contable', property: 'DocDate' },
        { title: 'Valor Autorizado', property: 'CDPValue' },
        { title: 'Valor Utilizado', property: 'TotalValue' },
        { title: '% de Uso', property: 'UsePercent' },
        { title: 'Creado por', property: 'EmployeeName' },
        { title: 'Fecha de Creación', property: 'Since', mask: MASK_DATE },
    ]

    const validateGetLista = () => {
        if (!year) {
            toast.error("año no valido");
        } else if (isNaN(state)) {
            toast.error("Estado no valido");
        } else if (idCashDeskA ? !idCashDeskA : !cashDesk) {
            toast.error("Caja no valida");
        } else {
            getLista()
        }
    }

    useEffect(() => {
        if (!menuComponent) {
            getLista()
        }
    }, [])

    const getLista = () => {
        instance.getCashReimbursementCatalog(year, idCashDeskA ?? cashDesk, state).then(
            (resp) => {
                const filteredList = resp.filter(item =>
                    item.DataBeanProperties.IDCashDeskAccount === (idCashDeskA ?? cashDesk)
                );
                setLista(filteredList);
            }
        ).catch(
            err => {
                toast.error(err);
            }
        )
    }

    const create = () => {
        setBean({
            IDCashDeskAccount: idCashDeskA,
            IDEmployee: single.getAccountID(),
            Year: single.getCurrenYear(),
        })
        setModal({ ...modal, view: true, "name": `Crear Rembolso` });
    }

    const createCashReimbursement = () => {
        instance.updateCashReimbursement(bean).then(
            (resp: any) => {
                setModal({ ...modal, "view": false })
                getLista();
                setBean({});
            }
        ).catch(
            err => {
                toast.error(err);
            }
        )
        setModal({ ...modal, "view": false });
    }

    const deleteReimbursement = () => {
        instance.deleteCashReimbursement(bean.IDCashReimbursement, single.getCurrenDate()).then(
            (resp) => {
                getLista();
                setModalC({ ...modalC, "view": false })
            }
        ).catch(
            err => {
                toast.error(err)
            }
        )
    }


    const listaBotones = () => {
        const botones = [
            { titulo: "Ver conceptos de Caja", icono: "ri-file-list-line", visible: true, evento: () => { setModalConcep({ ...modalConcep, "view": true, "name": "Conceptos de Caja" }) }, },
        ];
        return <ListMenu listaBotones={botones} />;
    };

    const listaBotonesMenuComp = () => {
        const botones = [
            { titulo: "Ver Facturas del Rembolso", icono: "ri-file-list-line", visible: true, evento: () => { setModalSeeUBL({ ...modalSeeUBL, "view": true, "name": "Facturas del Rembolso Seleccionado" }) }, },
            // { titulo: "Constituir Base de la Caja Menor", icono: "ri-money-dollar-box-line", visible: true, evento: () => { setModalMoneyBase({ ...modalMoneyBase, "view": true, "name": "Constituir Base de la Caja Menor" }) }, },
        ];
        return <ListMenu listaBotones={botones} />;
    };

    const iconButtonsItem = [
        { nombre: "Crear reembolsos de Caja Menor", visible: single.canCreate(), icono: "ri-file-add-line", evento: create },
    ];

    return (
        <>
            {menuComponent ?
                <BasicPage titulo='Gestionar Reembolsos de Caja Menor'>
                    <div className="row mb-3">
                        <div className="col-2">
                            <label className=''>Año:</label>
                            <input type='number' className='form-control' value={year} onChange={(e) => setYear(parseInt(e.target.value))} />
                        </div>
                        <div className="col-5">
                            <CashDeskAccountSelector id={cashDesk} onchange={(e) => { setCashDesk(e) }} idAccount={single.getAccountID()} />
                        </div>
                        <div className="col-3">
                            <StateReimbursementEditor idSelector={state} onChange={(e) => setState(parseInt(e))} />
                        </div>
                        <div className="col-2 d-flex align-items-end">
                            <BasicButton icon='ri-search-line' eventClick={() => { validateGetLista() }} >Buscar</BasicButton>
                        </div>
                    </div>
                    <BasicTable listButtons={listaBotonesMenuComp()} body={lista} head={head} rowSet={setBean} />
                </BasicPage>
                :
                <>
                    <div className="row mb-2">
                        <div className="col-5">
                            <StateReimbursementEditor idSelector={state} onChange={(e) => setState(e)} />
                        </div>
                        <div className="col-5">
                            <label className=''>Año:</label>
                            <input type='number' className='form-control' value={year} onChange={(e) => setYear(parseInt(e.target.value))} />
                        </div>
                        <div className="col-2 d-flex align-items-end">
                            <BasicButton icon='ri-search-line' eventClick={() => { validateGetLista() }} >Buscar</BasicButton>
                        </div>
                    </div>
                    <BasicTable iconButtons={iconButtonsItem} listButtons={listaBotones()} body={lista} head={head} rowSet={setBean} />
                </>
            }

            {modalC.view &&
                <ModalConfirm modal={modalC} updateModal={setModalC} eventModal={deleteReimbursement} >
                    Eliminar Item
                </ModalConfirm>
            }

            {modalSeeUBL.view &&
                <Modal modal={modalSeeUBL} updateModal={setModalSeeUBL}>
                    <CashReimbursementItem _bean={bean} _cashReimbursementDate={bean.Since} _idCashReimbursement={bean.IDCashReimbursement} />
                </Modal>
            }

            {modalConcep.view &&
                <Modal modal={modalConcep} updateModal={setModalConcep}>
                    <CashReimbursementBudget idCashReimbursement={bean.IDCashReimbursement} cashReimbursementDate={bean.Since} />
                </Modal>
            }

            {modalMoneyBase.view &&
                <Modal modal={modalMoneyBase} updateModal={setModalMoneyBase} eventModal={() => { }}>
                    <EstablishBaseCashReimbursement idCashReimbursement={bean.IDCashReimbursement} reimbursementDate={bean.Since} />
                </Modal>
            }

            {modal.view &&
                <Modal modal={modal} updateModal={setModal} eventModal={createCashReimbursement} >
                    <div className="row">
                        <div className="col-12">
                            <label>Año</label>
                            <input type="number" className='form-control' name='Year' value={bean.Year} onChange={handleChange} />
                        </div>
                        <div className="col-12">
                            <label >Número de Referencia Externo:</label>
                            <input type="text" className="form-control" name='SecuenceName' value={bean.SecuenceName} onChange={handleChange} />
                        </div>
                        <div className="col-12">
                            <label >Número de Expediente/Radicado:</label>
                            <input type="text" className="form-control" name='IDFiledNumber' value={bean.IDFiledNumber} onChange={handleChange} />
                        </div>
                    </div>
                </Modal>
            }

        </>
    )
}