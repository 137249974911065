import { useEffect, useState } from "react";
import { ButtonAccountantTree } from "./ButtonAccountantTree";
import { AccountantTreeChilds } from "./AccountantTreeChilds";
import useDataBean from "../../../../core/services/dataBean/useDataBean";
import { _AccountantID, _AccountantIDType, _single } from "../../../../core/services/dataBean/EntityCatalog";


interface Tree {
  id: number | null;
  edit?: boolean;
}
export const AccountantTree = ({ id, edit }: Tree) => {


  const accountantTree = useDataBean(_AccountantID);
  const accountantID = useDataBean(_AccountantIDType);


  const [lista, setLista] = useState([]);
  const [idchild, setIDchild] = useState(0);
  const [accountantIDType, setAccountantIDType] = useState<any>([]);
  const [idaccountantIDType, setIDAccountantIDType] = useState<number>(0);

  useEffect(() => {

    accountantIDType.forEach((element: any) => {
      if (element.DataBeanProperties.IDAccountantIDType === idaccountantIDType) {
        localStorage.setItem("CodeSeparator", element.DataBeanProperties.CodeSeparator);
        localStorage.setItem("CodeFormat", element.DataBeanProperties.CodeFormat);

      }
    });

  }, [idaccountantIDType])

  useEffect(() => {
    let temp = _single.getCacheItem('AccountantIDType');
    if (temp) {
      setAccountantIDType(temp);
      setIDAccountantIDType(temp[0].DataBeanProperties.IDAccountantIDType);
    } else {

      accountantID.instance.getAccountantIDTypeCatalog().then(
        (resp: any) => {
          setAccountantIDType(resp);
          if (resp.length > 0) {

            setIDAccountantIDType(resp[0].DataBeanProperties.IDAccountantIDType);
            _single.setCacheItem('AccountantIDType', resp);
          }
        }
      )
    }


  }, []);

  useEffect(() => {
    if (idaccountantIDType > 0) {
      getListatree(idaccountantIDType);
    }
  }, [idaccountantIDType]);

  useEffect(() => {

    getFunctionalIDSeparator();
  }, [lista]);

  const getListatree = (idArbol: number) => {

    let temp = _single.getCacheItem('TreeForAccountantID');
    if (temp) {
      setLista(temp);
    } else {
      accountantTree.instance.getTreeForAccountantID(idArbol).then(
        (resp: any) => {
          if (resp) {

            setLista(resp.Childs);
            _single.setCacheItem('TreeForAccountantID',resp.Childs);
          }
        }
      )
    }


  };

  const getFunctionalIDSeparator = () => {


    if (lista.length > 0) {
      let tmp = lista[0].EnvolvedObject.DataBeanProperties.CodeSeparator;
      localStorage.setItem('separador', tmp);
    }

  }



  return (
    <>


      <div className="row">
        <div className="col-md-6 mb-2">
          <label>Tipo Catálogo</label>
          <select className="form-select" value={idaccountantIDType} onChange={(e) => { setIDAccountantIDType(parseInt(e.target.value)) }}>
            {accountantIDType.map((item: any) => {
              return (
                <option value={item.DataBeanProperties.IDAccountantIDType}>{item.DataBeanProperties.Description}</option>
              )
            })}
          </select>
        </div>
        <div className="col-md-12">
          {edit &&
            <ButtonAccountantTree
              id={0}
              idAccountantIDType={idaccountantIDType}
              bean={{}}
              updateEvent={() => { getListatree(id); }}
            />
          }
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <ul className="list-group">
            {lista.length > 0 &&
              lista.map((item: any) => {
                return (
                  <li className="list-group-item itemh" key={item.EnvolvedObject.DataBeanProperties.IDLn}>
                    {edit &&
                      <ButtonAccountantTree
                        id={item.EnvolvedObject.DataBeanProperties.IDLn}
                        idAccountantIDType={1}
                        bean={item.EnvolvedObject.DataBeanProperties}
                        updateEvent={() => { getListatree(id); }}
                      />
                    }
                    <strong className="cursor" onClick={() => {


                      // if(item.EnvolvedObject.DataBeanProperties.IDLn === idchild)
                      //   setIDchild(0);
                      // else
                      setIDchild(item.EnvolvedObject.DataBeanProperties.IDLn);
                      localStorage.setItem('accountantTree', JSON.stringify(item.EnvolvedObject.DataBeanProperties));
                    }}>
                      <strong style={{ marginLeft: '15px' }}>{item.EnvolvedObject.DataBeanProperties.AccountantIDViewCode} </strong>
                      <small>{item.EnvolvedObject.DataBeanProperties.Name}</small>
                    </strong>
                    <span className="badge bg-success ms-1">{item.ChildrenCount}</span>
                    {idchild === item.EnvolvedObject.DataBeanProperties.IDLn &&
                      // renderSon(item.EnvolvedObject.DataBeanProperties.IDLn)
                      <>

                        <AccountantTreeChilds idAccountantIDType={idaccountantIDType} edit={edit} idChild={idchild} />
                      </>

                    }
                  </li>
                );
              })}
          </ul>
        </div>
      </div>

    </>
  );
};
