import { useState, useEffect } from 'react';
import useDataBean from '../../../../core/services/dataBean/useDataBean';
import { _BudgetResource, _CompetitionType, _Entailment } from '../../../../core/services/dataBean/EntityCatalog';

interface Props {
    idSelector: number;
    onChange: any;
    label?: string;
}

export const BudgetResourceEditor = ({ idSelector, onChange, label }: Props) => {

    const competitionType = useDataBean(_BudgetResource)
    const [lista, setLista] = useState([]);
    const [id, setID] = useState(null);

    useEffect(() => {
        if (idSelector) {
            setID(idSelector)
        }
    }, [idSelector])

    useEffect(() => {
        getLista();
    }, [])

    const setValue = (e: any) => {
        setID(parseInt(e));
        onChange(parseInt(e));
    }


    const getLista = () => {

        competitionType.instance.getBudgetResourceCatalog(null).then(
            (resp: any) => {
                if (resp) {

                    setLista(resp)
                    if(resp.length>0)
                    {
                        setValue(resp[0].DataBeanProperties.IDBudgetResource);
                    }
                    
                }
            }
        )

    }

    return (
        <>
            <label>{label ?? "Tipos de Recurso"}</label>
            <select className="form-select" value={id} onChange={(e) => { setValue(e.target.value) }}>
                {lista &&
                    lista.map((item: any, index: number) => {
                        return (
                            <option key={index} value={item.DataBeanProperties.IDBudgetResource} >{`${item.DataBeanProperties.Code ?? ""} - ${item.DataBeanProperties.Name ?? ""}`}</option>
                        )
                    })
                }
            </select>
        </>
    )
}