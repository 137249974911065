import { useState, useEffect } from 'react';
import useDataBean from '../../../core/services/dataBean/useDataBean';
import { _CashReimbursementConcept } from '../../../core/services/dataBean/EntityCatalog';


interface Props {
    _year?: number;
    idSelector: any;
    onChange: any;
    label?: string;
}

export const CashReimbursementConceptEditor = ({ idSelector, onChange, label, _year }: Props) => {

    const reimbursementConcept = useDataBean(_CashReimbursementConcept);

    const [lista, setLista] = useState<any[]>([]);
    const [id, setID] = useState(NaN);

    useEffect(() => {
        setID(idSelector);
        getLista();
    }, [])

    useEffect(() => {
        onChange(id);
    }, [id])

    const setValue = (e: any) => {
        setID(e);
        onChange(e);
    }

    const getLista = () => {
        reimbursementConcept.instance.getCashReimbursementConceptTypeCatalog(_year).then(
            (resp) => {
                setLista(resp)
            }
        )
    }

    return (
        <>
            <label>{label ?? "Estado"}</label>
            <select className="form-control" value={id} onChange={(e) => { setValue(parseInt(e.target.value)) }}>
                <option value={NaN}>...</option>
                {lista.map((item: any) => (
                    <option key={item.DataBeanProperties.IDCashReimbursementConcept} value={item.DataBeanProperties.IDCashReimbursementConcept} >{item.DataBeanProperties.Name}</option>
                ))}
            </select>
        </>
    )
}
