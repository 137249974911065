import { useEffect, useState } from "react";
import { _ImportedPayrollConcept } from "../../../../core/services/dataBean/EntityCatalog";
import useDataBean from "../../../../core/services/dataBean/useDataBean";
import { Singleton } from "../../../../core/services/Singleton";
import { ListMenu } from "../../../theme/Component/ListMenu";
import { BasicPage } from "../../../theme/Component/BasicPage";
import { BasicTable } from "../../../theme/Component/BasicTable";
import { Modal } from "../../../theme/Component/Modal";
import { ValueTypeSelector } from "./EditorsySelectors/ValueType.Selector";
import { PrintJson } from "../../../theme/Component/PrintJson";
import { EmployeeDiscountTypeEditor } from "../EmployeeDiscountType/EmployeeDiscountType.editor";
import { AccrualTypeSelector } from "./EditorsySelectors/AccrualType.Selector";
import { EmployeeBonusEditor } from "../EmployeeBonus/Editors/EmployeeBonus.Editor";
import { EmployeePaymentsEditor } from "../EmployeePayments/EmployyePayment.editor.select";
import { AbsenteeismClassSelector } from "./EditorsySelectors/AbsenteeismClass.Selector";
import { AbsenteeismTypeConstantsEditor } from "../../GestionTH/EmployeeNovelty/NoveltyEditors/AbsenteeismTypeConstants.editor";
import { IncapacityTypeConstantsEditor } from "../../GestionTH/EmployeeIncapacity/IncapacityEditors/IncapacityTypeConstants.editor";
import { IncapacityLiquidateSelector } from "./EditorsySelectors/IncapacityLiquidate.Selector";

export const ImportedPayrollConcept = () => {

    const { bean, setBean, instance, lista, setLista, handleChange, update } = useDataBean(_ImportedPayrollConcept);
    const single = Singleton.getInstance();

    const [modal, setModal] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });

    useEffect(() => {
        getLista();
    }, [])

    const getLista = () => {
        instance.getImportedPayrollConceptCatalog(null).then(
            (resp) => {
               //console.log(resp);
                setLista(resp)
            }
        ).catch(
            err => {
               console.error(err)
            }
        )
    }

    const editaItem = () => {
        instance.updateImportedPayrollConcept(bean).then(
            (resp) => {
               //console.log(resp);
                getLista();
                setModal({ ...modal, "view": false })
            }
        ).catch(
            err => {
               console.error(err)
            }
        )
    }

    const head = [
        { title: 'ID', property: 'IDImportedPayrollConcept', visible: true },
        { title: 'Nombre del Concepto', property: 'Name', visible: true },
        { title: 'Clase de Concepto', property: 'ValueTypeName', visible: true },
        { title: 'Tipo de Devengo', property: 'AccrualTypeName', visible: true },
        { title: 'Clase de Ausentismo', property: 'AbsenteeismTypeName', visible: true },
        { title: 'Tipo de Novedad', property: 'NoveltyTypeName', visible: true },
        { title: 'Tipo de Liquidación de Incapacidad', property: 'IncapacityLiquidateTypeName', visible: true },
        { title: 'Concepto Homologado', property: 'ConceptName', visible: true },
    ]

    const listaBotones = () => {
        const botones = [
            { titulo: 'Editar', icono: 'ri-edit-2-line', visible: single.canEdit(), evento: () => { setModal({ ...modal, "name": "Editar Nivel de Empleo", "view": true }) } },
        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }

    const validateValue = (value: any) => {
       //console.log(value)
        if (value == null || value == undefined || isNaN(value)) {
            return -1;
        } else {
            return parseInt(value)
        }
    }

    return (
        <>
            <BasicPage titulo="Conceptos de Nóminas por Importar">
                <BasicTable body={lista} head={head} rowSet={setBean} listButtons={listaBotones()} />
            </BasicPage>

            {modal.view &&
                <Modal modal={modal} updateModal={setModal} eventModal={editaItem}>
                    <div className="row">
                        <div className="col-md-6">
                            <label>Nombre del Concepto</label>
                            <input type="text" disabled name="Code" className="form-control" value={bean.Name} onChange={handleChange} />
                        </div>

                        {/* #1 */}
                        <div className="col-md-6">
                            <ValueTypeSelector label="Clase de Concepto" idSelector={bean.ValueType} onChange={(e) => {
                                if (bean.AccrualType) {
                                    setBean({ ...bean, "ValueType": validateValue(e), "IDType": -1 })
                                } else if (bean.IDType) {
                                    setBean({ ...bean, "ValueType": validateValue(e), "AccrualType": -1 })
                                } else {
                                    setBean({ ...bean, "ValueType": validateValue(e), "AccrualType": -1, "IDType": -1 })
                                }
                            }} />
                        </div>
                    </div>

                    {bean.ValueType === 38 &&
                        <>
                            <div className="row">

                                {/* #2 */}
                                <div className="col-md-6">
                                    <AccrualTypeSelector idSelector={bean.AccrualType} onChange={(e) => {
                                        if (bean.idEmploymentBonus) {
                                            setBean({ ...bean, "AccrualType": validateValue(e) })
                                        } else {
                                            setBean({ ...bean, "AccrualType": validateValue(e), "IDEmploymentBonus": -1 })
                                        }
                                    }} />
                                </div>

                                {/* #3 */}
                                {bean.AccrualType == 55 &&
                                    <div className="col-md-6">
                                        <EmployeeBonusEditor label="Tipo de Beneficio/Ingreso" idSelector={bean.IDEmploymentBonus} onChange={(e) => { setBean({ ...bean, "IDEmploymentBonus": validateValue(e) }) }} />
                                    </div>
                                }

                                {bean.AccrualType == 2 &&
                                    <div className="col-md-6">
                                        <EmployeePaymentsEditor label="Tipo de Beneficio/Ingreso" idSelector={bean.IDEmploymentBonus} onChange={(e) => { setBean({ ...bean, "IDEmploymentBonus": validateValue(e?.IDEmploymentBonus ?? null) }) }} />
                                    </div>
                                }
                            </div>
                        </>
                    }

                    {bean.ValueType == 39 &&
                        <>
                            {/* 4# */}
                            <div className="row">
                                <div className="col-md-6">
                                    <EmployeeDiscountTypeEditor label="Tipo de Descuento" idSelector={bean.IDType} onChange={(e) => { setBean({ ...bean, "IDType": validateValue(e.IDType) }) }} />
                                </div>
                            </div>
                        </>
                    }

                    <div className="row">
                        {/* #5 */}
                        <div className="col-md-6">
                            <AbsenteeismClassSelector idSelector={bean.AbsenteeismType} onChange={(e) => { setBean({ ...bean, "AbsenteeismType": validateValue(e) }) }} />
                        </div>

                        {/* #6 */}
                        {bean.AbsenteeismType == 76 &&
                            <div className="col-md-6">
                                <AbsenteeismTypeConstantsEditor idSelector={bean.NoveltyType} onChange={(e) => { setBean({ ...bean, "NoveltyType": validateValue(e) }) }} />
                            </div>
                        }

                        {bean.AbsenteeismType == 75 &&
                            <>
                                <div className="col-md-6">
                                    <IncapacityTypeConstantsEditor idSelector={bean.NoveltyType} onChange={(e) => { setBean({ ...bean, "NoveltyType": validateValue(e) }) }} />
                                </div>

                                {/* #7 */}
                                <div className="col-md-6">
                                    <IncapacityLiquidateSelector idSelector={bean.IncapacityLiquidateType} onChange={(e) => { setBean({ ...bean, "IncapacityLiquidateType": validateValue(e) }) }} />
                                </div>
                            </>
                        }
                    </div>
                </Modal>
            }
        </>
    )
}