import { useEffect, useState } from "react";
import { _BudgetMovementFactory, _single } from "../../../core/services/dataBean/EntityCatalog";
import useDataBean from "../../../core/services/dataBean/useDataBean";
import { toast } from "react-toastify";
import { BasicTable } from "../../theme/Component/BasicTable";
import { ListMenu } from "../../theme/Component/ListMenu";
import { Modal } from "../../theme/Component/Modal";
import { InputMoney } from "../../theme/Input/InputMoney";
import { SelectBudgetID } from "../../admin/configuracion/BudgetID/SelectBudgetID";
import { MASK_MONEY } from "../../theme/Component/BasicTable2";
import { PrintJson } from "../../theme/Component/PrintJson";

interface Props {
    formData: Record<string, any>;
    onChange: Function;
}

export const RegisterSheetBudgetValue = ({ onChange, formData }: Props) => {

    const { bean, setBean, lista, setLista, instance } = useDataBean(_BudgetMovementFactory);
    const [initBean, setInitBean] = useState<any>({});
    const [missingFields, setMissingFields] = useState<string[]>([]);

    const [modal, setModal] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });

    const head = [
        { title: 'Rubro Contracrédito', property: 'BudgetNameFrom', visible: true },
        { title: 'Saldo del Rubro Contracrédito', property: 'LeftValue', visible: true, mask: MASK_MONEY },
        { title: 'Valor Crédito', property: 'Value', visible: true, mask: MASK_MONEY },
        { title: 'Observación', property: 'Description', visible: true },
        { title: 'Rubro Crédito', property: 'BudgetNameDestinity', visible: true },
    ]

    const listaBotones = () => {
        const botones = [
            { titulo: 'Editar', icono: 'ri-edit-2-line', visible: _single.canEdit(), evento: () => { setModal({ ...modal, "view": true, "name": "Editar" }) } },
            { titulo: 'Eliminar', icono: 'ri-delete-bin-line', visible: _single.canDelete(), evento: () => { eliminarItem() } },

        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }

    const createItem = () => {
        setBean({});
        setModal({ ...modal, "view": true, "name": "Crear" })
    }

    const eliminarItem = () => {
        const updatedLista = lista.filter((item: any) =>
            JSON.stringify(item.DataBeanProperties) !== JSON.stringify(bean)
        );

        setBean({});
        setLista(updatedLista);
    }

    const iconButtonsItem = [
        { nombre: 'Agregar', visible: _single.canCreate(), icono: 'ri-file-add-line', evento: createItem }
    ]

    const createNewItem = () => {

        const updatedLista = lista.filter((item: any) =>
            JSON.stringify(item.DataBeanProperties) !== JSON.stringify(initBean)
        );

        instance.createBudgetRowForCreditContraCredit(formData.IDBudgetType, bean.BudgetIDFromStr, bean.BudgetIDDestinityStr, bean.Value, bean.Description).then(
            (resp) => {
                let temp = [...updatedLista, {
                    DataBeanProperties: resp,
                    DataBeanName: 'budget.Budget'
                }];
                setLista(temp);
                setBean({});
                setModal({ ...modal, "view": false })
            }
        ).catch(
            err => {
                toast.error(err);
            }
        )
    }

    const setRowBean = (object: any) => {
        setBean(object);
        setInitBean(object)
    }

    const validateData = () => {
        if (validateForm() > 0) {
            toast.error("Faltan campos obligatorios por completar");
            return;
        } else {
            createNewItem();
        }
    }

    const validateForm = () => {
        const requiredFields = [
            "Value", "BudgetIDFromStr", "BudgetIDDestinityStr"
        ];

        let missing = [];
        for (let field of requiredFields) {
            if (!bean[field]) {
                missing.push(field);
            }
        }
        setMissingFields(missing);
        return missing.length
    };

    useEffect(() => {
        onChange(lista);
    }, [lista])

    return (
        <div className="row">
            <div className="col-md-12">
                <BasicTable body={lista} head={head} rowSet={(e) => { setRowBean(e) }} listButtons={listaBotones()} iconButtons={iconButtonsItem} />
            </div>

            <div className="col-md-12">
                <Modal modal={modal} updateModal={setModal} eventModal={validateData} >
                    <div className="row">
                        <div className="col-md-6">
                            <label className={`${missingFields.includes('BudgetIDFromStr') ? 'has-error' : ''}`}>Rubro Presupuestal Contracrédito</label>
                            <SelectBudgetID label=" " tipoPresupuesto={0} jsonCode={bean.BudgetIDFromStr} onChange={(e) => { setBean({ ...bean, "BudgetIDFromStr": e.JsonCode }) }} edit={false} />
                        </div>
                        <div className="col-md-6">
                            <label className={`${missingFields.includes('BudgetIDDestinityStr') ? 'has-error' : ''}`}>Rubro Presupuestal Crédito</label>
                            <SelectBudgetID label=" " tipoPresupuesto={0} jsonCode={bean.BudgetIDDestinityStr} onChange={(e) => { setBean({ ...bean, "BudgetIDDestinityStr": e.JsonCode }) }} edit={false} />
                        </div>
                        <div className="col-md-6">
                            <label className={`${missingFields.includes('Value') ? 'has-error' : ''}`}>Valor Presupuesto</label>
                            <InputMoney id={0} name="Value" value={bean.Value} onChange={(e) => { setBean({ ...bean, "Value": e }) }} />
                        </div>
                        <div className="col-md-12">
                            <label>Descripción</label>
                            <textarea className="form-control" value={bean.Description} onChange={(e) => { setBean({ ...bean, "Description": e.target.value }) }} />
                        </div>
                    </div>
                </Modal>
            </div>
        </div>
    )
}