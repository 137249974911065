import { useEffect, useState } from "react";
import { ContractService } from "../../../core/services/ContractService";
import { Singleton } from "../../../core/services/Singleton"

import { Alert } from "../../theme/Component/Alert";

import { InputDate } from "../../theme/Input/InputDate";
import { SelectModality } from "../select/SelectModality";
import { BasicButton } from "../../theme/Component/BasicButton";

import { ListEmployee } from "./ListEmployee";
import { useDashboardStore } from "../../pages/hooks/useDashboardStore";
import { FactorMatrix } from "../etapa-contrato/estudio-previo-items/FactorMatrix";
import { PublishHiringProcess } from "./PublishHiringProcess";
import { toast } from "react-toastify";
import { OfferEmployee } from "./OfferEmployee/OfferEmployee";

interface Props {
    idHiringProcess: number;
    idAdquisitionItem: number;
    exportMap:any;
}
export const InitHiringProcess = ({ idHiringProcess, idAdquisitionItem,exportMap }: Props) => {

    const single = Singleton.getInstance()
    const { setLoading } = useDashboardStore();
    const service = new ContractService();
    const [config, setConfig] = useState<any>({});
    const [roles, setRoles] = useState<any>([]);
    const [step, setStep] = useState(0);

    const listStep = [
        { id: 0, name: 'Configuración' },
        { id: 1, name: 'Responsables' },
        { id: 2, name: 'Cronograma' },
        { id: 3, name: 'Flujo del Proceso' },
        { id: 4, name: 'Factores' },
        { id: 5, name: 'Publicar' },
    ]

    useEffect(() => {
        setStep(0);
        getRolesConstants();
    }, [])

    useEffect(() => {
        setStep(0);
        getHiringConfig();

    }, [idHiringProcess])

    useEffect(() => {

        if (step == 1)
            getHiringConfig();

    }, [step])

    const getRolesConstants = () => {
        service.getHiringConstants("HIRINGROLES").subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue) {
                    let arr: any = [];
                    let temp = resp.DataBeanProperties.ObjectValue;
                    temp.forEach((element: any) => {
                        arr.push({
                            id: element.DataBeanProperties.Value,
                            name: element.DataBeanProperties.Property
                        })
                    });
                    setRoles(arr);
                }

            }
        );
    }

    const getHiringConfig = () => {
        setLoading(true);
        service.getHiringProcessConfigCatalogPorPropiedad("IDHiringProcess", idHiringProcess, null).subscribe(
            (resp: any) => {

                if (resp.DataBeanProperties.ObjectValue) {
                    let obj = resp.DataBeanProperties.ObjectValue;
                    if (obj.length > 0) {
                        let c: any = obj[0].DataBeanProperties;

                        // c.CalendarModality = [];
                       //console.log('c ---->', c);

                        // try {

                        //   c.CalendarModality = c.CalendarModality  ? JSON.parse(c.CalendarModality) : [];                         
                        //   c.FlowStructure = c.FlowStructure ? JSON.parse(c.FlowStructure) : [];
                        // } catch (error) {

                        //   c.CalendarModality =  [];                         
                        //   c.FlowStructure = [];
                        // }

                        setConfig(c);
                        // setFecha(c.FromDate);
                    }

                   //console.log(obj);

                }

                setLoading(false);
            }
        )
    }
    const actualizarConfig = (configUpdate?: any) => {

        setLoading(true);
       //console.log(configUpdate);
       //console.log('config ----->', config);

        service.updateHiringProcessConfig(configUpdate !== undefined ? configUpdate : config).subscribe(
            (resp: any) => {
                getHiringConfig();
            }
        )

    }
    const updateListaEmpleados = (e: any) => {


        setConfig({ ...config, "EmployeeList": e ? JSON.stringify(e) : '[]' });

       //console.log(config);


    }
    const makeCalendar = () => {

        setLoading(true);
        service.makeCalendar(config.IDHiringProcessConfig, config.FromDate).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue) {
                    let cal = resp.DataBeanProperties.ObjectValue;
                    // setCalendar(cal ? JSON.parse(cal) : []);
                    // setConfig({...config,"CalendarModality":cal ? JSON.parse(cal) : []});
                    getHiringConfig();
                }
                setLoading(false);
            }
        )

    }
    const makeFlowHiringModality = () => {
        setLoading(true);
        service.makeFlowHiringModality(config.IDHiringProcessConfig).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue) {
                    let cal = resp.DataBeanProperties.ObjectValue;
                    // setFlow(cal ? JSON.parse(cal) : []);
                    // setConfig({...config,"FlowStructure":cal ? JSON.parse(cal) : []});
                    getHiringConfig();
                }
                setLoading(false);
            }
        )
    }

    const StartHiringProcess = () => {
        setLoading(true);
        service.StartHiringProcess(idHiringProcess, idAdquisitionItem, single.getCurrenDate()).subscribe(
            (resp: any) => {
                setLoading(false);
                getHiringConfig();

            }
        )
    }

    const updateProcess =(e:any)=>{
        
        
        let bean = {
            Funcionario:single.getAccount(),
            Proceso:single.getRandomInt()
        }
        exportMap(bean);
    }


    return (

        <div className="row">
            <div className="col-2">
                <ul className="list-group">
                    {listStep.map((item: any) => {
                        return (
                            <li key={item.id} className={item.id === step ? 'list-group-item cursor selected_li' : 'list-group-item cursor'}
                                onClick={() => (config.IDHiringProcess !== undefined) ? setStep(item.id) : toast.error('Debe inicializar una configuación')}>{item.name}</li>
                        )
                    })}
                </ul>
            </div>
            <div className="col-10">
                {step === 0 &&
                    <>
                        {config.IDHiringProcess > 0 ?
                            <>
                                <div className="row">
                                    <div className="col-md-12">
                                        <SelectModality idSelector={config.IDHiringModality} onChange={(e: any) => { setConfig({ ...config, "IDHiringModality": e }) }} />
                                    </div>

                                    <div className="col-md-12">
                                        <InputDate label="Fecha estimada de publicación" name="FromDate" value={config.FromDate} setDate={(e: any) => { setConfig({ ...config, "FromDate": e }) }} ></InputDate>
                                    </div>
                                    <div className="col-md-12">
                                        <BasicButton icon="ri-refresh-fill" eventClick={() => actualizarConfig(undefined)}>Actualizar</BasicButton>
                                        {/* <PrintJson json={config}></PrintJson> */}
                                    </div>
                                </div>

                            </>
                            :

                            <Alert clase="warning">
                                No existe una configuración para el proceso. Desea crear una ?<br />
                                <BasicButton icon=" ri-logout-circle-r-line" eventClick={StartHiringProcess}>Crear</BasicButton>
                            </Alert>

                        }
                    </>
                }
                {step === 1 &&
                    <>
                        <OfferEmployee IDAdquisitionItem={idAdquisitionItem} ></OfferEmployee>
                        {/* <ListEmployee listP={config.EmployeeList} listRoles={roles} updateLista={updateListaEmpleados} />
                        <BasicButton icon="ri-refresh-fill" eventClick={() => actualizarConfig(undefined)}>Actualizar</BasicButton> */}
                    </>
                }
                {step === 2 &&
                    <>
                        {config.CalendarModality ?
                            <>
                                <ul className="list-group">
                                    {JSON.parse(config.CalendarModality ?? []).map((item: any) => {
                                        return (
                                            <li key={item.Name} className="list-group-item">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <strong>{item.Name}</strong>
                                                    </div>
                                                    <div className="col-md-6 d-flex align-items-end flex-column">
                                                        <div>
                                                            Fecha Inicial : {item.initDate}
                                                        </div>
                                                        <div>
                                                            Fecha Final : {item.endDate}
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        )
                                    })
                                    }
                                </ul>
                                <BasicButton icon=" ri-logout-circle-r-line" clase="warning" eventClick={makeCalendar}>Regenerar</BasicButton>
                            </>
                            :
                            <>
                                <Alert clase="warning">
                                    No existe un conograma desea crear uno ? <br />
                                    <BasicButton icon=" ri-logout-circle-r-line" eventClick={makeCalendar}>Crear</BasicButton>
                                </Alert>
                            </>
                        }
                    </>
                }
                {step === 3 &&
                    <>
                        {config.FlowStructure ?
                            <>
                                <ul className="list-group">
                                    {JSON.parse(config?.FlowStructure ?? []).map((item: any) => {
                                        return (
                                            <li className="list-group-item">
                                                <div className="row">
                                                    <div className="col-md-8">
                                                        <strong>{item.Name}</strong> <br />
                                                        <small>{item.Description}</small>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <strong>{item.TermInDays}  Dias {item.TermType === 0 ? 'CALENDARIO' : 'HABIL'} </strong>
                                                    </div>
                                                    <div className="col-md-12">
                                                        {item.Documents &&
                                                            <>
                                                                <ul className="list-group">
                                                                    {item.Documents.map((doc: any) => {
                                                                        return (
                                                                            <li className="list-group-item" style={{ border: '0' }}>
                                                                                <div className="row">
                                                                                    <div className="col-md-1">
                                                                                        <div className="flex-shrink-0">
                                                                                            <i className={doc.Responsible === 20 ? 'ri-hotel-line' : 'ri-user-3-line'} style={{ fontSize: '1.1rem' }}></i>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-md-11">
                                                                                        <strong>{doc.ResponsibleName}</strong> <br />
                                                                                        <small>{doc.Type} : {doc.NameType}</small>
                                                                                    </div>
                                                                                </div>
                                                                            </li>
                                                                        )
                                                                    })
                                                                    }
                                                                </ul>
                                                            </>
                                                        }
                                                    </div>
                                                </div>
                                            </li>
                                        )
                                    })

                                    }
                                </ul>
                                <BasicButton icon=" ri-logout-circle-r-line" clase="warning" eventClick={makeFlowHiringModality}>Regenerar</BasicButton>
                            </>
                            :
                            <>
                                <Alert clase="warning">
                                    No existe un flujo del proceso? <br />
                                    <BasicButton icon=" ri-logout-circle-r-line" eventClick={makeFlowHiringModality}>Crear</BasicButton>
                                </Alert>
                            </>
                        }
                    </>
                }
                {step === 4 &&
                    <div>
                        <FactorMatrix value={{ factors: (config?.FactorMatrix) ? JSON.parse(config?.FactorMatrix) : [] }}
                            updateMatrix={actualizarConfig}
                            onChange={(e) => {
                                setConfig({ ...config, FactorMatrix: JSON.stringify(e.factors) })
                                actualizarConfig({ ...config, FactorMatrix: JSON.stringify(e.factors) })
                            }} />
                    </div>
                }
                {
                    step === 5 &&
                    <PublishHiringProcess config={config} setLoading={setLoading} exportMap={updateProcess} />
                }
            </div>
        </div>
    )
}