import { useState, useEffect } from 'react';
import useDataBean from '../../../../core/services/dataBean/useDataBean';
import { _Entailment } from '../../../../core/services/dataBean/EntityCatalog';

interface Props {
    idSelector: number;
    onChange: any;
    label?: string;
}

export const EntailmentEditor = ({ idSelector, onChange, label }: Props) => {

    const entailment = useDataBean(_Entailment);
    const [lista, setLista] = useState<any[]>([]);
    const [id, setID] = useState<number>(idSelector);

    useEffect(() => {
        getLista();
    }, []);

    useEffect(() => {
        if (idSelector !== id) {
            setID(idSelector);
            ////console.log(idSelector)
        }
    }, [idSelector]);

    const setValue = (e: any) => {
        const selectedID = parseInt(e);
        onChange(selectedID);
    };

    const getLista = () => {
        entailment.instance.getEntailmentCatalog().then(
            (resp: any) => {
                if (resp) {
                    setLista(resp);
                }
            }
        );
    };

    return (
        <>
            <label>{label ?? "Niveles Jerárquicos del Empleo:"}</label>
            <select className="form-select" value={id} onChange={(e) => setValue(e.target.value)}>
                <option value={null}>...</option>
                {lista &&
                    lista.map((item: any, index: number) => (
                        <option key={index} value={item.DataBeanProperties.IDEntailment}>
                            {item.DataBeanProperties.Name}
                        </option>
                    ))
                }
            </select>
        </>
    );
};
