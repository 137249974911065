import { AdminService } from "../../../core/services/AdminService";

import { useState, useEffect } from 'react';
import { useDashboardStore } from "../../pages/hooks/useDashboardStore";

interface Props {

    label?: string;

    code: number;

    value: any;

    onChange: Function;

}



export const Selectjuridica = ({ label, code, value, onChange }: Props) => {


    const { setLoading } = useDashboardStore();

    const service = new AdminService();

    const [lista, setLista] = useState<any>([]);

    const [id, setId] = useState(0);



    useEffect(() => {

        getLista();

    }, [code])

    useEffect(() => {

        onChange(id);

    }, [id])





    const getLista = () => {

        setLoading(true);

        service.getSuppConstantsValuesByCode(0, [code]).subscribe(

            (resp: any) => {

                ////console.log(resp);

                if (resp.DataBeanProperties.ObjectValue) {

                    let lista = resp.DataBeanProperties.ObjectValue;

                    setLista(lista['Code_' + code]);

                    ////console.log(lista);

                }

                setLoading(false);

            }

        )

    }





    return (

        <>

            <label>{label ? label : 'Opciones'}</label>

            <select className="form-select" value={id} onChange={(e) => { setId(parseInt(e.target.value)) }} >

                {lista.map((item: any, index: number) => {

                    return (

                        <option key={index} value={item.DataBeanProperties.IDSuppConstantsValue}>{item.DataBeanProperties.Name}</option>

                    )

                })}

            </select>

        </>

    )

}