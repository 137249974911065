import { useState, useEffect } from "react";
import { Singleton } from "../../../core/services/Singleton";
import { BasicTable } from "../../theme/Component/BasicTable";
import { ModalConfirm } from "../../theme/Component/ModalConfirm";
import { Modal } from "../../theme/Component/Modal";
import { BpmService } from "../../../core/services/BpmService";
import { BasicSwitch } from "../../theme/Component/BasicSwitch";
import { Selectortipodeformulario } from "./Componentes/Selectortipodeformulario";
import { SelectorForm } from "./Componentes/SelectorForm";
import { CaracterizacionDocRequeridos } from "../../theme/Component/CaracterizacionDocRequeridos";
import { SelectorJsonClass } from "./Componentes/SelectorJsonClass";
import { SelectorJson } from "./Componentes/SelectorJson";
import { ListMenu } from "../../theme/Component/ListMenu";
import { useDashboardStore } from "../../pages/hooks/useDashboardStore";

interface Props {
  idProcedure: number;
}

export const ProcedureDocument = ({ idProcedure }: Props) => {
  const single = Singleton.getInstance()
  const { setLoading } = useDashboardStore();
  const service = new BpmService();
  const [lista, setLista] = useState([]);
  const [bean, setBean] = useState<any>({});
  const [modal, setModal] = useState<any>({
    name: "Documento/Formulario",
    tabIndex: -1,
    view: false,
    btnClose: true,
    btnSubmit: true,
    format: "modal-lg",
  });
  const [meliminar, setMeliminar] = useState<any>({
    name: "Confirmar",
    tabIndex: -1,
    view: false,
    btnClose: true,
    btnSubmit: true,
    format: "modal-sm",
  });
  const [modalCar, setModalCar] = useState<any>({
    name: "Asignar Caracterizaciones a Documento",
    tabIndex: -1,
    view: false,
    btnClose: true,
    btnSubmit: false,
    format: "modal-md",
  });

  const idBean = "IDDocument";

  const head = [
    { title: "ID", property: idBean },
    { title: "Nombre", property: "Name" },
    { title: "Nombre del proceso", property: "ProcedureName", visible: false },
    { title: "Nombre del Servico JSON", property: "JsonServiceName", visible: false },
    { title: "Nombre del Formulario", property: "FormName", visible: false },
    { title: "Tipo", property: "DocumentTypeName" },
    { title: "Descripción", property: "Description", visible: false },
    { title: "Aplica a todas las caracterizaciones", property: "ApplyForAllChar" },
    { title: "Es opcional", property: "IsOptional" },
    { title: "Caracterizaciones", property: "CharacterizationListNames", visible: false },
    { title: "", property: "Dtimes" },
  ];

  useEffect(() => {
    getLista();
  }, [idProcedure, modalCar]);

  const setRowBean = (bean: any) => {
    setBean(bean);
  };

  const rTiempo = (item: any) => {
    let renderTiempo =
      `<div>
      <strong>Tiempo de validez:</strong><small>${item.DataBeanProperties.DefeatTime ? item.DataBeanProperties.DefeatTime : 'NA'}</small><br/>
      <strong>Tiempo por defecto:</strong><small>${item.DataBeanProperties.ValidityType ? item.DataBeanProperties.ValidityType : 'NA'}</small>
    </div>`

    return renderTiempo
  }

  const getLista = () => {
   //console.log(idProcedure);

    setLoading(true);
    service.getProcedureDocumentCatalog(idProcedure)
      .subscribe((resp: any) => {
        setLoading(false);
       //console.log(resp);
        if (resp.DataBeanProperties.ObjectValue) {
          let listaR = resp.DataBeanProperties.ObjectValue;
          listaR.forEach((element: any) => {
            element.DataBeanProperties.Dtimes = rTiempo(element);
          });
          setLista(listaR);
        }
      });
  };

  const eliminarItem = () => {
    setLoading(true);
    service
      .deleteProcedureDocument(bean[idBean], null)
      .subscribe((resp: any) => {
        setLoading(false);
        if (resp) {
          getLista();
        }
      });
    setMeliminar({ ...meliminar, "view": false });
  };

  const agregarItem = () => {
    let b = {
      Name: "",
      Description: "",
      ApplyForAllChar: true,
      FormEngine: "",
      FormURLComponent: "",
      IsOptional: false,
      IDProcedure: idProcedure,
      DefeatTime: 0,
      ValidityType: 0,
      DocumentType: null,
      DocumentTypeName: "",
      Since: single.getCurrenDate(),
    };
    setBean(b);
    setModal({ ...modal, "view": true });
  };

  const updateItem = () => {
    setLoading(true);
    service.updateProcedureDocument(bean).subscribe((resp: any) => {
      setLoading(false);
      if (resp.DataBeanProperties.ObjectValue) {
        getLista();
      }
    });
    setModal({ ...modal, "view": false });
  };

  const listaBotones = () => {
    const botones = [
      {
        titulo: "Editar",
        icono: "ri-file-list-line",
        visible: single.canEdit(),
        evento: () => {
          setModal({ ...modal, 'view': true, name: "Editar" });
        },
      },
      {
        titulo: "Eliminar",
        icono: "ri-file-user-line",
        visible: single.canDelete(),
        evento: () => {
          setMeliminar({ ...meliminar, 'view': true });
        },
      },
      {
        titulo: "Asignar Caraterizaciones",
        icono: "ri-edit-2-line",
        visible: single.canEdit(),
        evento: () => {
          setModalCar({ ...modalCar, "view": true });
        },
      },
    ];
    return <ListMenu listaBotones={botones} />;
  };

  const iconButtonsItem = [
    {
      nombre: "Agregar",
      visible: single.canCreate(),
      icono: "ri-file-add-line",
      evento: agregarItem,
    },
  ];

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <BasicTable listButtons={listaBotones()} iconButtons={iconButtonsItem} head={head} body={lista} rowSet={setRowBean}></BasicTable>
        </div>
      </div>

      <ModalConfirm
        modal={meliminar}
        updateModal={setMeliminar}
        eventModal={eliminarItem}
      >
        <div className="div">
          Esta seguro que desa eliminar <strong>{bean.Name}</strong>
        </div>
      </ModalConfirm>

      <Modal modal={modal} updateModal={setModal} eventModal={updateItem}>
        <div className="container text-aling-start">
          <div className="row mb-3 mt-3">
            <div className="col-md-12">
              <label className="form-label">Nombre</label>
              <input
                type="text"
                className="form-control"
                value={bean.Name}
                onChange={(e) => {
                  setBean({ ...bean, "Name": e.target.value });
                }}
              />
            </div>
            <div className="col-md-6">
              <label className="form-label">Tiempo de validez</label>
              <input
                type="number"
                className="form-control"
                value={bean.ValidityType}
                onChange={(e) => {
                  setBean({ ...bean, "ValidityType": e.target.value });
                }}
              />
            </div>
            <div className="col-md-6">
              <label className="form-label">Tiempo por defecto</label>
              <input
                type="number"
                className="form-control"
                value={bean.DefeatTime}
                onChange={(e) => {
                  setBean({ ...bean, "DefeatTime": e.target.value });
                }}
              />
            </div>

          </div>
          <div className="row mb-3 mt-3">
            <div className="col-md-4">
              <label className="mb-2">Tipo de Docuento</label>
              <select
                className="form-control"
                title="Tipo de Docuento"
                value={bean.DocumentType}
                onChange={(e: any) => {
                  setBean({ ...bean, "DocumentType": e.target.value });
                }}
              >
                <option value={null}>...</option>
                <option value={2}>Documento para Cargar</option>
                <option value={3}>Formulario Java</option>
                <option value={4}>Servicio JSON</option>
                <option value={6}>Formulario JSON</option>
              </select>
            </div>
            {bean.DocumentType == 3 && (
              <>
                <div className="col-md-4">
                  <label className="mb-2">URL del formato</label>
                  <input
                    type="text"
                    className="form-control"
                    value={bean.FormURLComponent}
                    onChange={(e: any) => { setBean({ ...bean, "FormURLComponent": e.target.value, }); }}></input>
                </div>
                <div className="col-md-4">
                  <label className="mb-2">Clase java de la Forma</label>
                  <input
                    type="text"
                    className="form-control"
                    value={bean.FormEngine}
                    onChange={(e: any) => {
                      setBean({ ...bean, "FormEngine": e.target.value });
                    }}
                  ></input>
                </div>
              </>
            )}
            {bean.DocumentType == 4 && (
              <>
                <div className="col-md-4">
                  <SelectorJsonClass idSelector={bean.IDJsonServiceClass} onChange={(e: any) => { setBean({ ...bean, "IDJsonServiceClass": e }); }}></SelectorJsonClass>
                </div>
                {bean.IDJsonServiceClass != null &&
                  <div className="col-md-4">
                    <SelectorJson idJsonServiceClass={bean.IDJsonServiceClass} idSelector={bean.IDJsonService} onChange={(e: any) => { setBean({ ...bean, "IDJsonService": e }); }}></SelectorJson>
                  </div>
                }
              </>
            )}
            {bean.DocumentType == 6 && (
              <>
                <div className="col-md-4">
                  <Selectortipodeformulario idSelector={bean.IDFormClass} onChange={(e: any) => { setBean({ ...bean, "IDFormClass": e }); }} />
                </div>
                {bean.IDFormClass != null && (
                  <div className="col-md-4">
                    <SelectorForm idSelector={bean.IDForm} onChange={(e: any) => { setBean({ ...bean, "IDForm": e }); }} idForm={bean.IDFormClass} />
                  </div>
                )}
              </>
            )}
          </div>
          <div className="row">
            <div className="col-md-12">
              <label className="form-label mb-2">Descripción</label>
              <textarea className="form-control" value={bean.Description} onChange={(e) => { setBean({ ...bean, 'Description': e.target.value }) }}></textarea>
            </div>
          </div>
          <div className="row">
            <div className="col-md-5">
              <div className="form-group mt-4">
                <label>¿Aplica a todas las caracterizaciones?</label>
                <BasicSwitch
                  label=""
                  estado={bean.ApplyForAllChar}
                  eventChange={(e: any) => {
                    setBean({ ...bean, ApplyForAllChar: e });
                  }}
                ></BasicSwitch>
              </div>
            </div>
            <div className="col-md-5">
              <div className="form-group mt-4">
                <label>¿Es Opcional?</label>
                <BasicSwitch
                  label=""
                  estado={bean.IsOptional}
                  eventChange={(e: any) => {
                    setBean({ ...bean, IsOptional: e });
                  }}
                ></BasicSwitch>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <Modal modal={modalCar} updateModal={setModalCar} eventModal={""}>
        <CaracterizacionDocRequeridos
          id={bean[idBean]}
        ></CaracterizacionDocRequeridos>
      </Modal>
    </>
  );
};
