import { useState, useEffect } from 'react';
import useDataBean from '../../../../core/services/dataBean/useDataBean';
import usePaginator from '../../../theme/Component/usePaginator';
import { BasicTable, MASK_NUMBER } from '../../../theme/Component/BasicTable';
import { TaxCodeSchemeEditor } from '../TaxCodeScheme/TaxCodeScheme.editor';
import { BasicButton } from '../../../theme/Component/BasicButton';
import { _TaxCode } from '../../../../core/services/dataBean/EntityCatalog';



interface Props {
    onChange: any;
    label?: string;
}

export const TaxCodeEditorTable = ({ onChange, label }: Props) => {

    const { lista, setLista, bean, setBean, instance } = useDataBean(_TaxCode)
    const [idTaxCodeScheme, setIdTaxCodeScheme] = useState<number>(null)
    const paginador = usePaginator();

    const [Filter, SetFilter] = useState('');
    const [Register, SetRegister] = useState(15);
    const [Code, SetCode] = useState('');

    useEffect(() => {
        if (paginador.page > 0) {
            getLista();
        }
    }, [paginador.page])

    const getLista = () => {
        instance.getTaxCodeCatalogPaginador(idTaxCodeScheme, Code ? Code : null, Filter ? Filter : null, paginador.page, Register).then(
            (resp) => {
                setLista(resp.List)
                let paginas = resp.NumberOfPages;
                paginador.setPages(paginas);
               //console.log(resp)
            }
        )
    }

    const headtwo = [
        { title: 'ID', property: 'IDTaxCode', visible: false },
        { title: 'Código', property: 'Code', visible: true, mask: MASK_NUMBER },
        { title: 'Nombre', property: 'Name', visible: true },
    ]

    const setRowBean = (obje: any) => {
        setBean(obje);
        onChange(obje);
    }

    return (
        <>
            <div className="row">
                <div className="col-12 mb-2">
                    <TaxCodeSchemeEditor idSelector={idTaxCodeScheme} onChange={(e) => { setIdTaxCodeScheme(e) }} />
                </div>
                <div className="col-md-6">
                    <label>Filtrar por Nombre</label>
                    <input type="text" name="Filter" className="form-control" value={Filter} onChange={(e) => { SetFilter(e.target.value) }} />
                </div>
                <div className="col-md-6">
                    <label>Filtrar por Código</label>
                    <input type="text" name="Code" className="form-control" value={Code} onChange={(e) => { SetCode((e.target.value)) }} />
                </div>
                <div className="col-md-6">
                    <label>Cantidad Registro por pagina</label>
                    <input type="number" name="Value" className="form-control" value={Register} onChange={(e) => { SetRegister(parseInt(e.target.value)) }} />
                </div>
                <div className="col-md-6 mt-4">
                    <BasicButton icon="ri-search-line" eventClick={getLista}>Buscar</BasicButton>
                </div>

                <div className="col-md-12">
                    <BasicTable customRows={Register} body={lista} head={headtwo} rowSet={(e: any) => { setRowBean(e) }}
                        pageTop={paginador.page} next={paginador.next} back={paginador.back} records={paginador}
                    />
                </div>
            </div>
        </>
    )
}