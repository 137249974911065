/* eslint-disable react/jsx-no-target-blank */
import { useEffect, useState } from 'react'
import { BasicPage } from '../theme/Component/BasicPage'
import { AccountEditor } from '../editor/AccountEditor'
import { CERTIFICATES, FIXED_ASSETS, PAY_ROLL } from '../pages/ProfileDashboard'
import { YearSelector } from '../../core/shared/inputs/YearSelector'
import { AdminService } from '../../core/services/AdminService'
import { BasicTable } from '../theme/Component/BasicTable'
import { ListMenu } from '../theme/Component/ListMenu'
import { RiFileDownloadLine } from 'react-icons/ri'
import { toast } from 'react-toastify'
import { Modal } from '../theme/Component/Modal'
import { useDashboardStore } from '../pages/hooks/useDashboardStore'
import { Singleton } from '../../core/services/Singleton';
import { PrintJson } from '../theme/Component/PrintJson'
import { FormGenericCellEditor } from '../admin/generic-editor/FormGenericCellEditor'
import { BasicButton } from '../theme/Component/BasicButton'

const options = [
    {
        value: PAY_ROLL,
        label: 'Desprendible nómina'
    },
    // {
    //     value: FIXED_ASSETS,
    //     label: 'Activos Fijos'
    // },
    {
        value: CERTIFICATES,
        label: 'Certificados'
    },
]

const service = new AdminService();
export const SalaryInquiry = () => {

    const single = Singleton.getInstance();
    const { setLoading } = useDashboardStore()
    const [type, setType] = useState(PAY_ROLL)
    const [yearSelected, setYearSelected] = useState(new Date().getFullYear())
    const [idEmployee, setIdEmployee] = useState(0);
    const [typeCert, setTypeCert] = useState(0);
    const [year, setYear] = useState(Singleton.getInstance().getCurrenYear());
    const [groupCert, setGroupCert] = useState('');
    const [list, setList] = useState([]);
    const [listCert, setListCert] = useState([]);
    const [props, setProps] = useState([]);
    const [row, setRow] = useState<any>()
    const [includeSalary, setIncludeSalary] = useState(false)
    const [_url, set_url] = useState('');
    const [data, setData] = useState<any>({ Year: single.getCurrenYear() });
    const [modalacciones, setModalacciones] = useState<any>(
        {
            name: 'Descargar Archivo',
            tabIndex: -1,
            view: false,
            btnClose: true,
            btnSubmit: true,
            format: 'modal-sm'
        });

    useEffect(() => {
        setData({ ...data, "IDAccount": idEmployee });
    }, [idEmployee])

    useEffect(() => {

        getDocumentTemplateCatalog();

    }, [type])
    // useEffect(() => {

    //     if(type === PAY_ROLL)
    //         searchList();

    // }, [data.Year,idEmployee])

    useEffect(() => {

        if (!modalacciones.view)
            set_url('');

    }, [modalacciones])

    useEffect(() => {
        listCert.forEach((element: any) => {
            if (element.DataBeanProperties.IDDocumentTemplate === typeCert) {
                setGroupCert(element.DataBeanProperties.GroupCode);
            }
        });
    }, [typeCert])



    const searchList = () => {
        setLoading(true);
        if (type === PAY_ROLL) {
            let listFortnight = []
            let ldgs = []
            service.getPayrollProcessListByEmployee(data.Year, data.IDAccount).subscribe(
                (resp: any) => {
                    setLoading(false);
                    if (resp.DataBeanProperties.ObjectValue) {
                        listFortnight = resp.DataBeanProperties.ObjectValue;
                        listFortnight = listFortnight.reverse();
                        listFortnight.forEach(() => {
                            ldgs.push(false)
                        });
                        listFortnight.forEach((element: any) => {
                            element.DataBeanProperties.Doc = element.DataBeanProperties.DocMnemonic + "-" + element.DataBeanProperties.DocConsecutive;
                        });
                       //console.log(setList);
                        setList(listFortnight)
                    }
                }
            )
        } else {
            service.getEmployeeContract(idEmployee).subscribe(
                (resp: any) => {
                    setLoading(false);
                   //console.log(resp);
                    if (resp.DataBeanProperties.ObjectValue) {
                        let listFortnight = resp.DataBeanProperties.ObjectValue;
                        setList(listFortnight)
                    }
                }
            )
        }
    }

    const getTicketReport = (idDocument: number, docDate: any) => {
        setLoading(true)

        service.getTicketReport(idDocument, docDate).subscribe(
            (resp: any) => {
                setLoading(false)
                if (resp.DataBeanProperties.ObjectValue) {
                    let listFortnight = resp.DataBeanProperties.ObjectValue;
                   //console.log(listFortnight);
                    set_url(listFortnight[0].DataBeanProperties.URLLink)
                    /* setDownloadingBtn(_iLoading, false) */

                }
                else {
                    toast.error('Algo salió mal')
                }
            }
        )

    }

    const getDocumentTemplateCatalog = () => {

        setLoading(true);

        service.getDocumentTemplateCatalog(4, null, true).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue) {
                    setListCert(resp.DataBeanProperties.ObjectValue);
                    if (resp.DataBeanProperties.ObjectValue.length > 0) {


                        setTypeCert(resp.DataBeanProperties.ObjectValue[0].DataBeanProperties.IDDocumentTemplate);
                        setGroupCert(resp.DataBeanProperties.ObjectValue[0].DataBeanProperties.GroupCode);

                    }
                }
                else {
                    setListCert([]);
                }

                setLoading(false);
            }
        )

    }

    const getDocumentTemplateParsed = () => {

        setLoading(true);

        service.getDocumentTemplateParsed(typeCert, data, "DOCX").subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue) {
                    let listFortnight = resp.DataBeanProperties.ObjectValue;


                    if (listFortnight.length > 0) {
                        set_url(listFortnight[0].DataBeanProperties.URLLink)
                    } else {
                        toast.error('No se han encontrado documentos');
                    }

                    /* setDownloadingBtn(_iLoading, false) */

                }
                else {
                    toast.error('Algo salió mal')
                }
                setLoading(false);
            }
        )


    }

    const getPDFArchivo = () => {

        if (type === PAY_ROLL) {
            if (row.IDDocument)
                getTicketReport(row.IDDocument, row.DocDate);
        }
        else {
            getDocumentTemplateParsed();
        }




    }

    const listaBotones = () => {
        const botones = [
            {
                titulo: "Generar archivo",
                icono: "ri-file-download-line",
                visible: true,
                evento: () => {
                    if (row !== undefined) {

                       

                        setModalacciones({ ...modalacciones, view: true })
                    }
                }
            }
        ]
        return <ListMenu listaBotones={botones} />;
    };

    const head = [
        { title: "ID", property: 'IDDocument' },
        { title: "Mes", property: "MonthName" },
        { title: "Descripción", property: "Description" },
    ];

    const head2 = [
        { title: "ID", property: 'IDContract' },
        { title: "Cargo", property: "EntailmentName" },
        { title: "Tipo", property: "EmploymentTypeName" },
    ];

    const [observations, setObservations] = useState('')

    return (
        <BasicPage titulo='Consulta a Terceros'>
            {/* <div className="row">
                <div className="col">
                    <PrintJson json={data}></PrintJson>
                </div>
            </div> */}
            <div className='row'>
                <div className="col-md-4">
                    <AccountEditor label="Funcionario" idAccount={0} onChange={(e) => { setIdEmployee(e.IDAccount) }} />
                </div>

                <div className="col-md-4">
                    <label htmlFor='inquiry-selector'>Tipo de consulta</label>
                    <select className="form-select" id='inquiry-selector'
                        onChange={(e) => {
                            setType(parseInt(e.target.value))
                        }}
                    >
                        {options.map((_e, _i) => (
                            <option key={_i} selected={_i === 0 ? true : false} value={_e.value} >
                                {_e.label}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="col-md-4">
                    {type === CERTIFICATES &&
                        <div className="w-100">
                            <label>Certificado</label>
                            <select className='form-select' value={typeCert} onChange={(e) => { setTypeCert(parseInt(e.target.value)) }}>
                                {listCert.map((item: any, index: number) => {
                                    return (
                                        <option key={index} value={item.DataBeanProperties.IDDocumentTemplate} >{item.DataBeanProperties.Name}</option>
                                    )
                                })}
                            </select>
                        </div>
                    }
                    {type === PAY_ROLL &&
                        <div className="row">
                            <div className='col-md-4'>
                                <YearSelector disabled={type !== PAY_ROLL} value={data.Year ?? undefined} onChange={(e) => setData({ ...data, "Year": e })} />
                            </div>
                            <div className="col-md-8 mt-3">
                                <BasicButton icon='' disable={!idEmployee} eventClick={searchList}>Buscar</BasicButton>
                            </div>
                        </div>
                    }
                </div>
                <div className="col-md-12">
                    <hr />

                    {type == PAY_ROLL &&
                        <div className="row">
                            <div className="col-md-12">
                                <BasicTable
                                    listButtons={listaBotones()}
                                    head={type === PAY_ROLL ? head : head2}
                                    body={list}
                                    rowSet={setRow}
                                />

                            </div>
                        </div>
                    }
                    {type === CERTIFICATES &&
                        <FormGenericCellEditor nameButton='Consultar' key={idEmployee} idAccount={idEmployee} idDocumentTemplate={typeCert} onChange={(e: any) => { setData(e); setModalacciones({ ...modalacciones, "view": true }); }} />
                    }
                </div>
                <div className="col-md-12 d-flex gap-3">

                    <div className='w-100'>

                    </div>

                    {modalacciones.view &&
                        <Modal modal={modalacciones} updateModal={setModalacciones} eventModal={getPDFArchivo} onSubmitLabel='Generar'>

                            <div className='w-100 mt-3 _dashed'>
                                <RiFileDownloadLine />
                                {_url !== ''
                                    ? <small><strong><a href={_url} target='_blank'>Clic aquí para descargar el archivo</a></strong></small>
                                    : <small style={{
                                        cursor: _url !== '' ? 'pointer' : 'not-allowed'
                                    }}><strong>Clic aquí para descargar el archivo</strong></small>
                                }
                            </div>
                        </Modal>
                    }
                </div>

            </div>
        </BasicPage>
    )
}
