import { useState } from "react";
import { Alert } from "../../../../../theme/Component/Alert";
import { BasicButton } from "../../../../../theme/Component/BasicButton";
import { FileInput } from "../../../../../theme/Component/FileInput";
import { Modal } from "../../../../../theme/Component/Modal";
import { toast } from "react-toastify";

interface Props {
    exportMap: Function;
    functionAprove?: Function;
}

export const ApprovalFlow = ({ exportMap, functionAprove }: Props) => {

    const [obs, setObs] = useState<string>("")

    const onExportMap = (approve: number) => {
        exportMap({
            Approve: `${approve}`,
            Observation: obs
        })
    }

    return (
        <div className="row d-flex justify-content-between">
            <div className="col-md-12 mb-2">
                <label>Observaciones</label>
                <textarea className="form-control" value={obs} onChange={(e) => { setObs(e.target.value) }} />
            </div>
            <div className="col-md-4">
                <BasicButton icon="ri-checkbox-circle-line" eventClick={() => { onExportMap(1) }} >Arpobar</BasicButton>
            </div>
            <div className="col-md-4">
                <BasicButton icon="ri-close-circle-line" eventClick={() => { onExportMap(2) }} >Rechazar</BasicButton>
            </div>
        </div>
    );
};