import { DataBean } from "../DataBean";

export class Project extends DataBean {
    
    


    public async getProjectCatalogPorPropiedades(properties:any,maxRows:any): Promise<any> {
        return this.getCustomService("FinanceCore", "java.util.List_getProjectCatalogPorPropiedades_java.util.Map_Number", [properties,maxRows]);
    }

    public async getProjectCatalogPorPropiedadesRender(props:any,rows:any): Promise<any> {
        return this.getCustomService("FinanceCore", "java.util.List_getProjectCatalogPorPropiedadesRender_java.util.Map_Number", [props,rows]);
    }
  

}