import { useEffect, useState } from "react";
import { BpmService } from "../../../../../core/services/BpmService";
import { toast } from "react-toastify";
import { Singleton } from "../../../../../core/services/Singleton";
import { FaCheckCircle } from "react-icons/fa";
import { Modal } from "../../../../theme/Component/Modal";
import { RenderInbox } from "./RenderInbox";
import { BasicButton } from "../../../../theme/Component/BasicButton";


interface Props {
    Procedure: { IDProcedureImp: number };
    requestLista: Function;
}

export const ProcedureActionInbox = ({ Procedure, requestLista }: Props) => {

    const service = new BpmService();
    const single = Singleton.getInstance();
    const [action, setAction] = useState<any>({});
    const [lista, setLista] = useState([]);
    const [modalAction, setModalAction] = useState<any>({ name: 'Documentos requeridos', tabIndex: -1, view: false, btnClose: true, btnSubmit: false, format: 'modal-lg' });

    useEffect(() => {
        setTimeout(() => {
            getLista();
        }, 50)
    }, [Procedure])

    useEffect(() => {
        if (action.IDAction > 0)
            setModalAction({ ...modalAction, "view": true });
    }, [action.IDAction])

    const getLista = () => {
        single.spinner(true);
        service.getProcedureActionByAccount(single.getAccountID(), Procedure.IDProcedureImp).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue.length > 0) {
                    setLista(resp.DataBeanProperties.ObjectValue)
                    ////console.log(resp.DataBeanProperties.ObjectValue)
                } else {
                    toast.success("sin acciones pendientes");
                }
                single.spinner(false)
            }
        )
    }

    const responseForm = (mapa: any) => {
        single.spinner(true);
        service.responseProcedureAction(action.IDAction, mapa).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue) {
                    setModalAction({ ...modalAction, "view": false });
                    toast.success("Se almaceno al respuesta correctamente");
                } else {
                    toast.error("Algo salio mal")
                }
                requestLista();
                getLista();
                single.spinner(false);
            }
        )
    }


    return (
        <>
            {lista.length > 0 ?
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">Pendiente</th>
                            <th scope="col">Estado</th>
                            <th scope="col">Proceso</th>
                            <th scope="col">Fecha</th>
                            <th scope="col">Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        {lista &&
                            <>
                                {lista.map((e: any, i: number) =>
                                    <tr key={i} >
                                        <th scope="row">{e.DataBeanProperties.Name}</th>
                                        <td><strong>{e.DataBeanProperties.StateName}</strong></td>
                                        <td>{e.DataBeanProperties.ProcedureName}</td>
                                        <td>{e.DataBeanProperties.Since}</td>
                                        <td>
                                            <div className="d-flex justify-content-center gap-2 mt-3 fs-2 text-warning">
                                                <FaCheckCircle title='Gestionar' className="pointer" onClick={() => { setAction(e.DataBeanProperties); setModalAction({ ...modalAction, "view": true }) }} />
                                            </div>
                                        </td>
                                    </tr>
                                )}
                            </>
                        }
                    </tbody>
                </table>
                :
                <div className='border rounded p-5 text-justify'>
                    <p>
                        Estimado usuario usted NO tiene acciones pendientes por gestionar en
                        esta solicitud.
                        <br />
                        <br />
                        Si el estado de su solicitud está <strong className='text-primary'>EN PROGRESO</strong> debe esperar
                        a que las próximas actualizaciones le sean notificadas.
                    </p>
                </div>
            }
            <Modal modal={modalAction} updateModal={setModalAction} >
                <RenderInbox key={action.IDAction} exportMap={(map: any) => { responseForm(map) }} action={action} />
            </Modal>
        </>
    )
}