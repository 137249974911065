import { useEffect, useState } from "react";
import { BasicPage } from "../theme/Component/BasicPage";
import { CardTaps } from "../theme/Component/CardTaps";
import { Singleton } from "../../core/services/Singleton";
import { ExportUtil } from "./ExportUtil";
import { ImportUtil } from "./ImportUtil";
import { DaoUtil } from "./DaoUtil";
import useDataBean from "../../core/services/dataBean/useDataBean";
import { _DaoInfo } from "../../core/services/dataBean/EntityCatalog";
import { Spinner } from "../../core/components/Spinner";
import { RemoteAbstractAction } from "./remoteAbstractAction/RemoteAbstractAction";
import { ToastContainer } from "react-toastify";
import { MenuUsuario } from "../admin/menu/MenuUsuario";
import { SystemRoles } from "../admin/Roles/SystemRoles";
import { AccountEditor } from "../editor/AccountEditor";
interface Props {
    view: string;
}
export const SystemUtil = ({ view }: Props) => {

    const [viewPage, setViewPage] = useState<boolean>(false);
    const single = Singleton.getInstance();
    const service = useDataBean(_DaoInfo);
    const [webService, setWebSerice] = useState<any>([]);
    const [indexTap, setIndexTap] = useState(0);

    const [daoList, setDaoList] = useState<any>([]);
    const [data, setData] = useState<any>({
        charset: "UTF-8"
    });
    const taps = [
        { id: 0, name: 'Exportar', disable: false },
        { id: 1, name: 'Importar', disable: false },
        { id: 2, name: 'Dao', disable: false },
        { id: 3, name: 'Catálogo de Aplicaciones por Tipo', disable: false },
        { id: 4, name: 'Menu del sistema', disable: false },
        { id: 5, name: 'Gestionar Usuario & Roles', disable: false },
        { id: 6, name: 'Roles', disable: false },
    ];

    useEffect(() => {
        getDao();
    }, [view])
    useEffect(() => {

        if (indexTap === 2) {
            getWebServiceInfoCatalog();
        }
    }, [indexTap])

    useEffect(() => {

        daoList.forEach(element => {
            if (element.DataBeanProperties.IDDaoInfo === data.idDaoInfo) {
                setData({ ...data, "daoClassForName": element.DataBeanProperties.ClassForName });
            }
        });
    }, [data.idDaoInfo])
    useEffect(() => {

        daoList.forEach(element => {
            if (element.DataBeanProperties.IDDaoInfo === data.idDaoInfo) {
                setData({ ...data, "daoClassForName": element.DataBeanProperties.ClassForName });
            }
        });
    }, [data.idDaoInfo])
    useEffect(() => {

        webService.forEach(element => {
            if (element.DataBeanProperties.IDWebServiceInfo === data.IDWebServiceInfo) {
                setData({ ...data, "webClassForName": element.DataBeanProperties.ClassForName });
            }
        });
    }, [data.IDWebServiceInfo])


    const getWebServiceInfoCatalog = () => {
        service.instance.getWebServiceInfoCatalog(null).then(
            (resp: any) => {
                setWebSerice(resp);
            }
        )
    }

    const getDao = () => {
        service.instance.getDaoInfoCatalog(null).then(
            (resp: any) => {
                setDaoList(resp);
            }
        )
    }





    return (
        <>
            <Spinner />
            <ToastContainer
                newestOnTop
                position="top-right"
                autoClose={3500}
                hideProgressBar={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            <div className="container">
                <div className="row mt-2">
                    {/* <PrintJson json={data}/> */}
                    <div className="col">
                        <BasicPage titulo="Propiedades del Sistema">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <label>Dao</label>
                                            <select name="dao" className="form-select" value={data.idDaoInfo ?? undefined} onChange={(e) => { setData({ ...data, "idDaoInfo": parseInt(e.target.value) }) }}>
                                                <option value={undefined}>---</option>
                                                {daoList.map((item: any, index: number) => {
                                                    return (
                                                        <option value={item.DataBeanProperties.IDDaoInfo}>{item.DataBeanProperties.ClassForName} - {item.DataBeanProperties.Name ?? 'No definido'}</option>
                                                    )
                                                })}
                                            </select>
                                        </div>
                                        <div className="col-md-6">
                                            {indexTap === 2 &&
                                                <>
                                                    <label>WebServiceInfo</label>
                                                    <select name="webservice" className="form-select" value={data.IDWebServiceInfo} onChange={(e) => { setData({ ...data, "IDWebServiceInfo": parseInt(e.target.value) }) }}>
                                                        <option value={undefined}>- - -</option>
                                                        {webService.map((item: any, index: number) => {
                                                            return (
                                                                <option key={index} value={item.DataBeanProperties.IDWebServiceInfo}>{item.DataBeanProperties.ClassForName}</option>
                                                            )
                                                        })}
                                                    </select>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 mt-2">
                                    <strong>ClassForName Dao: </strong>{data.daoClassForName}
                                </div>
                                <div className="col-md-6 mt-2">
                                    <strong>ClassForName Service: </strong>{data.webClassForName}
                                </div>
                                <div className="col-md-12">
                                    <CardTaps taps={taps} updateIndex={setIndexTap} index={indexTap}>
                                        <div className="row">
                                            <div className="col">
                                                {indexTap === 0 &&
                                                    <ExportUtil data={data} />
                                                }
                                                {indexTap === 1 &&
                                                    <ImportUtil data={data} />
                                                }
                                                {indexTap === 2 &&
                                                    <DaoUtil classForName={data.webClassForName} daoclassForName={data.daoClassForName} />
                                                }
                                                {indexTap === 3 &&
                                                    <RemoteAbstractAction />
                                                }
                                                {indexTap === 4 &&
                                                    <MenuUsuario />
                                                }
                                                {indexTap === 5 &&
                                                    <SystemRoles />

                                                }
                                                {indexTap === 6 &&
                                                    <>
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <AccountEditor idAccount={null} label="Busqueda y Gestion" onChange={() => { }} canCreate />
                                                            </div>

                                                        </div>
                                                    </>

                                                }
                                            </div>
                                        </div>
                                    </CardTaps>
                                </div>
                            </div>

                        </BasicPage>
                    </div>
                </div>
            </div>
        </>
    )
}