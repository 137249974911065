import { BasicPage } from "../theme/Component/BasicPage"
import { BasicTable } from '../theme/Component/BasicTable';
import { Singleton } from '../../core/services/Singleton';
import { ContractService } from '../../core/services/ContractService';
import { useState, useEffect } from 'react';
import { ModalConfirm } from '../theme/Component/ModalConfirm';
import { useForm } from 'react-hook-form';
import { HiringModalityDocument } from "./HiringModalityDocument";
import { ListMenu } from "../theme/Component/ListMenu";
import { useDashboardStore } from "../pages/hooks/useDashboardStore";
import { Modal } from "../theme/Component/Modal";

export const Modality = () => {

    const single = Singleton.getInstance()
    const { setLoading } = useDashboardStore();
    const service = new ContractService();
    const [lista, setLista] = useState([]);
    const [year, setYear] = useState(0);
    const [month, setMonth] = useState(0);
    const [bean, setBean] = useState<any>({});
    const { register, formState: { errors }, handleSubmit } = useForm();
    const [modal, setModal] = useState<any>({ name: '', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg', action: '' });
    const [modalDoc, setModalDoc] = useState<any>({ name: 'Documentos', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-xl' });
    const [modalConfirm, setModalConfirm] = useState<any>({ name: 'Confirmar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });
    const [modalP, setModalP] = useState<any>({
        name: "Preparadores",
        tabIndex: -1,
        view: false,
        btnClose: true,
        btnSubmit: true,
        format: "modal-lg",
    });


    const idBean = 'IDHiringModality';

    const head = [
        { title: 'ID', property: idBean },
        { title: 'Nombre', property: 'Name' },
        { title: 'Descripción', property: 'Description' },
        // { title: 'Desde', property: 'FromDate' },
        // { title: 'Hasta', property: 'UntilDate' },
    ]

    useEffect(() => {
        // let d = new Date();
        // setYear(d.getFullYear());
        ////console.log("Permisos de la pantalla ", single.getAccess());
        getLista();
    }, [])

    const setRowBean = (bean: any) => {
        setBean(bean);
    }

    const getLista = () => {

        setLoading(true);
        service.getHiringModalityRender(null, {}).subscribe(
            (resp: any) => {

                if (resp.DataBeanProperties.ObjectValue) {
                    setLista(resp.DataBeanProperties.ObjectValue);
                }

                setLoading(false);
            }
        );
    }

    const agregarItem = () => {

        let d = new Date();
        let b = {
            Name: '',
            Description: '',
            State: 1,
            FromDate: single.getCurrenDate(),
            UntilDate: single.getCurrenDate(),
            Rules: JSON.stringify([]),
            Since: single.getCurrenDate()
        }

        setBean(b);
        setModal({ ...modal, ['view']: true, action: 'Agregar' });

    }
    const editarItem = () => {

        setModal({ ...modal, ['view']: true });

    }
    const eliminarItem = () => {

        setLoading(true);
        service.deleteHiringModality(bean[idBean]).subscribe(
            (resp: any) => {
                setLoading(false);
                if (resp) {
                    getLista();
                }
                single.delete(bean[idBean], bean);
            }
        );
        setModalConfirm({ ...modalConfirm, ['view']: false });

    }
    const updateItem = () => {

        setModal({ ...modal, ['view']: false });

        setLoading(true);
        service.updateHiringModality(bean).subscribe(
            (resp: any) => {
                setLoading(false);
                if (resp.DataBeanProperties.ObjectValue) {
                    getLista();
                }
                single.update(bean);
            });

    }



    const listaBotones = () => {
        const botones = [

            { titulo: 'Editar', icono: 'ri-file-list-line', visible: single.canEdit(), evento: () => { setModal({ ...modal, "view": true, action: 'Editar' }) } },
            { titulo: 'Eliminar', icono: 'ri-file-user-line', visible: single.canDelete(), evento: () => { setModalConfirm({ ...modalConfirm, "view": true }) } },
            { titulo: 'Documentos', icono: 'ri-file-user-line', visible: true, evento: () => { setModalDoc({ ...modalDoc, "view": true }) } },
        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }

    const iconButtonsItem = [

        { nombre: 'Agregar', visible: single.canCreate(), icono: 'ri-file-add-line', evento: agregarItem }

    ]

    return (
        <>
            <BasicPage titulo="Modalidades de Contrato">
                {/* <div className="row">
                    <div className="col-md-4">
                        <label >Año</label>
                        <input type="text" className="form-control mt-2" value={year} onChange={(e) => { setYear(parseInt(e.target.value)) }} />
                    </div>
                    <div className="col-md-4">
                        <SelectMonths idSelector={month} onChange={(e: any) => { setMonth(e) }}></SelectMonths>
                    </div>
                    <div className="col-md-4 mt-4">
                        <BasicButton icon="ri-file-search-line" value="Buscar" eventClick={() => { getLista() }}></BasicButton>
                    </div>
                </div> */}
                <div className="row">

                    <div className="col-md-12 p-2">
                        <BasicTable listButtons={listaBotones()} iconButtons={iconButtonsItem} head={head} body={lista} rowSet={setRowBean}></BasicTable>
                    </div>
                </div>
            </BasicPage>

            <ModalConfirm modal={modalConfirm} updateModal={setModalConfirm} eventModal={eliminarItem}>
                {bean.Name}
            </ModalConfirm>

            {modalDoc.view &&
                <Modal modal={modalDoc} updateModal={setModalDoc} eventModal={updateItem}>
                    <HiringModalityDocument idModality={bean.IDHiringModality}></HiringModalityDocument>
                </Modal>
            }

            <Modal modal={modal} updateModal={setModal} eventModal={updateItem}>
                <h5>{modal.action === 'Agregar' ? 'Agregar Modalidad' : 'Editar Modalidad'}</h5>
                <div className="row">
                    <div className="col-md-12">
                        <label className="form-label">Nombre</label>
                        <input type="text" className="form-control" value={bean.Name} onChange={(e) => { setBean({ ...bean, ['Name']: e.target.value }) }} />
                    </div>
                    <div className="col-md-12">
                        <label className="form-label">Descripción</label>
                        <textarea className="form-control" value={bean.Description} onChange={(e) => { setBean({ ...bean, ['Description']: e.target.value }) }}></textarea>
                    </div>
                </div>
            </Modal>
        </>
    )
}