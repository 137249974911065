import { useState, useEffect } from 'react';
import useDataBean from '../../../../core/services/dataBean/useDataBean';
import { _EmployeePayments, _TaxHold } from '../../../../core/services/dataBean/EntityCatalog';



interface Props {

    idSelector: number;
    onChange: any;
    label?: string;
}

export const EmployeePaymentsUnits = ({ idSelector, onChange, label }: Props) => {

    const EmployeePaymentsUnits = useDataBean(_EmployeePayments)
    const [lista, setLista] = useState<any>([])
    const [id, setID] = useState(null);

    useEffect(() => {
        if (idSelector >= 0) {
            setID(idSelector)
        }
    }, [])

    useEffect(() => {
        getLista();
    }, [])

    useEffect(() => {
        onChange(id);
    }, [id])

    const setValue = (e: any) => {
        setID(parseInt(e));
        onChange(id);
    }

    const getLista = () => {
        EmployeePaymentsUnits.instance.getEmployeePaymentsUnitsTypeConstants().then(
            (resp) => {
                setLista(resp)
            }
        )
    }

    return (
        <>
            <label>{label ?? "Proceso"}</label>
            <select className="form-select" value={id} onChange={(e) => { setValue(e.target.value) }}>
                <option value={null}>...</option>

                {lista &&
                    lista.map((item: any, index: number) => {
                        return (
                            <option key={index} value={item.DataBeanProperties.Value} >{item.DataBeanProperties.Property}</option>
                        )
                    })
                }
            </select>
        </>
    )
}