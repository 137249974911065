import { map, Observable } from "rxjs";
import { env } from "../../env";
import api from "../services/api/api";
import http from "../services/api/api";
import { DataBeanProperties } from './model/server-response.interface';

export class AdminService {

  private url: string = env.REACT_APP_ENDPOINT + env.REACT_APP_DEPLOY + env.REACT_APP_SERVICE;
  public baseUrl: string = env.REACT_APP_ENDPOINT || "";
  private tipoApp = env.REACT_APP_DEPLOY;

  public securitymanager(): Observable<any> {
    const dataObj = {
      ServiceName: "OrangeCore",
      MethodHash:
        "com.advantage.bean.securitymanager.PublicKeyInfo_getPublicKeyForApiRest_String",
      ArgumentList: [null],
    };
    const data = JSON.stringify(dataObj);
    return http.post(this.url, data);
  }

  public updateSupplier(bean: any) {
    const parametros = {
      ServiceName: "SupplierERP",
      MethodHash: "com.orange.finance.supplier.bean.Supplier_updateSupplier_com.orange.finance.supplier.bean.Supplier",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: 'com.orange.finance.supplier.bean.Supplier'
        }
      ],
    };
    const data = JSON.stringify(parametros);
    return http.post(this.url, data);
  }

  public login(user: string, password: string, tipo: string, esquemaldap: string): Observable<any> {
    const dataObj = {
      ServiceName: "OrangeBase",
      MethodHash:
        "com.advantage.bean.account.WorkSession_validateWorkSession_String_String_String_String_String_String",
      ArgumentList: [tipo, user, password, esquemaldap, null, null],
    };
    const data = JSON.stringify(dataObj);
    return http.postH(this.url, data);
  }
  public getLoginTypes() {
    const parametros = {
      ServiceName: "OrangeBase",
      MethodHash: "java.util.List_getLoginTypes_Number",
      ArgumentList: [null],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  //historialtrmaite
  public getMessageThreadCatalogRender(page: string | null, rows: number | null, idMessage: any) {
    const parametros = {
      ServiceName: "MessageERP",
      MethodHash: "java.util.List_getMessageThreadCatalogRender_Number_Number_Number",
      ArgumentList: [page, rows, idMessage],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }




  //
  //enviar a bandeja
  // public putMessageList(idAccountFrom: number | null, Message: number | null, AccountTo: number | null, idEmployee: number | null) {
  //   const parametros = {
  //     ServiceName: "MessageERP",
  //     MethodHash: "com.advantage.message.bean.Message_putMessageList_Number_String_java.util.List_Number",
  //     ArgumentList: [idAccountFrom, Message, AccountTo, idEmployee],
  //   };
  //   const data = JSON.stringify(parametros);
  //   return api.post(this.url, data);
  // }

  public putMessage(idAccountTo: number | null, idAccountFrom: number | null, Message: number | null, idEmployee: number | null) {
    const parametros = {
      ServiceName: "MessageERP",
      MethodHash: "com.advantage.message.bean.Message_putMessage_Number_Number_String_Number",
      ArgumentList: [idAccountTo, idAccountFrom, Message, idEmployee],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  //
  //messagenviar

  public markReadMessage(IDAccount: number | null, IDMessage: any) {
    const parametros = {
      ServiceName: "MessageERP",
      MethodHash: "com.advantage.message.bean.Message_markReadMessage_Number_Number",
      ArgumentList: [IDAccount, IDMessage],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public replyMessage(Message: string | null, idEmployee: any, idMessage: number | null) {
    const parametros = {
      ServiceName: "MessageERP",
      MethodHash: "com.advantage.message.bean.Message_replyMessage_String_Number_Number",
      ArgumentList: [Message, idEmployee, idMessage],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }



  //

  //messagedash
  public getMessageCatalogRender(page: string | null, rows: number | null, props: any) {
    const parametros = {
      ServiceName: "MessageERP",
      MethodHash: "java.util.List_getMessageCatalogRender_Number_Number_java.util.Map",
      ArgumentList: [page, rows, props],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  //

  validateOTP(nit: string, codeOTP: string, OTPTransaction: string) {
    const dataObj = {
      ServiceName: "CajaHonor",
      MethodHash:
        "com.advantage.bean.account.WorkSession_validateOTPCode_Number_Number_Number_String",
      ArgumentList: [null, nit, codeOTP, OTPTransaction],
    };
    const data = JSON.stringify(dataObj);
    return api.postH(this.url, data, {
      observe: "response" as "body",
      responseType: "json",
    });
  }

  //menu de usuario
  getUserMenu(idAccount: number) {
    const parametros = {
      ServiceName: "OrangeBase",
      MethodHash: "java.util.List_getUserMenu_Number",
      ArgumentList: [idAccount],
    };
    const data = JSON.stringify(parametros);
    return http.post(this.url, data);
  }
  //contantsvaluecode


  public getSuppConstantsValuesByCode(rows: number | null, lcodes: any) {
    const parametros = {
      ServiceName: "SupplierERP",
      MethodHash: "java.util.Map_getSuppConstantsValuesByCode_Number_java.util.List",
      ArgumentList: [rows, lcodes],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);

  }
  // public   getFormatBodyCatalogPorPropiedad(name:string|null,value:number|null,number:null) {
  //   const parametros = {
  //     ServiceName: "FormatERP",
  //     MethodHash:"java.util.List_getFormatBodyCatalogPorPropiedad_String_Object_Number",
  //     ArgumentList: [name,value,number],
  //   };
  //   const data = JSON.stringify(parametros);
  //   return api.post(this.url, data);
  // }
  //
  public getFormatBodyCatalogPorPropiedad(name: string | null, value: number | null, number: null) {
    const parametros = {
      ServiceName: "FormatERP",
      MethodHash: "java.util.List_getFormatBodyCatalogPorPropiedad_String_Object_Number",
      ArgumentList: [name, value, number],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  //

  public updateFormatBody(bean: any) {
    const parametros = {
      ServiceName: "FormatERP",
      MethodHash: "com.orange.finance.format.bean.FormatBody_updateFormatBody_com.orange.finance.format.bean.FormatBody",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: 'com.orange.finance.format.bean.FormatBody'
        }
      ],
    };

    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }


  public getRequestTypeBpmCatalogRender(page: number | null, map: number | null, props: any) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "java.util.List_getRequestTypeBpmCatalogRender_Number_Number_java.util.Map",
      ArgumentList: [page, map, props],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }


  public exportEmployeeContractsTemplateXlsxFile(args: number | null) {
    const parametros = {
      ServiceName: "QuickDataERPCore",
      MethodHash: "java.util.List_exportEmployeeContractsTemplateXlsxFile_Number",
      ArgumentList: [args],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }



  public getContractRoleCatalogPorPropiedad(name: string | null, value: number | null, number: null) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "java.util.List_getContractRoleCatalogPorPropiedad_String_Object_Number",
      ArgumentList: [name, value, number],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public updateRequestTypeBpm(bean: any) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "com.orange.bean.RequestTypeBpm_updateRequestTypeBpm_com.orange.bean.RequestTypeBpm",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: 'com.orange.bean.RequestTypeBpm'
        }
      ],
    };

    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }




  public updateContractRole(bean: any) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "com.orange.bean.ContractRole_updateContractRole_com.orange.bean.ContractRole",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: 'com.orange.bean.ContractRole'
        }
      ],
    };

    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  ///propertietypeformatservice

  public getFormatPropertiesCatalogPorPropiedad(name: string | null, value: number | null, number: null) {
    const parametros = {
      ServiceName: "FormatERP",
      MethodHash: "java.util.List_getFormatPropertiesCatalogPorPropiedad_String_Object_Number",
      ArgumentList: [name, value, number],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }


  public updateFormatProperties(bean: any) {
    const parametros = {
      ServiceName: "FormatERP",
      MethodHash: "com.orange.finance.format.bean.FormatProperties_updateFormatProperties_com.orange.finance.format.bean.FormatProperties",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: 'com.orange.finance.format.bean.FormatProperties'
        }
      ],
    };

    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }



  ///



  //Formabody

  public getFormatTypeCatalogPorPropiedad(name: string | null, value: number | null, number: null) {
    const parametros = {
      ServiceName: "FormatERP",
      MethodHash: "java.util.List_getFormatTypeCatalogPorPropiedad_String_Object_Number",
      ArgumentList: [name, value, number],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public updateFormatType(bean: any) {
    const parametros = {
      ServiceName: "FormatERP",
      MethodHash: "com.orange.finance.format.bean.FormatType_updateFormatType_com.orange.finance.format.bean.FormatType",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: 'com.orange.finance.format.bean.FormatType'
        }
      ],
    };

    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }


  //


  //
  public getFormTypeCatalogPorPropiedad(name: string | null, value: number | null, number: null) {
    const parametros = {
      ServiceName: "FormsERP",
      MethodHash: "java.util.List_getFormTypeCatalogPorPropiedad_String_Object_Number",
      ArgumentList: [name, value, number],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }



  public updateFormType(bean: any) {
    const parametros = {
      ServiceName: "FormsERP",
      MethodHash: "com.orange.bean.FormType_updateFormType_com.orange.bean.FormType",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: 'com.orange.bean.FormType'
        }
      ],
    };

    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public deleteFormBody(IDForm: number | null) {
    const parametros = {
      ServiceName: "FormsERP",
      MethodHash: "boolean_deleteFormBody_Number",
      ArgumentList: [IDForm],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  // servicios template & group Template

  public getGroupTemplateCatalogPorPropiedad(name: string | null, value: any | null, maxRows: number | null) {
    const parametros = {
      ServiceName: "TemplateERP",
      MethodHash: "java.util.List_getGroupTemplateCatalogPorPropiedad_String_Object_Number",
      ArgumentList: [name, value, maxRows],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public updateGroupTemplate(bean: any) {
    const parametros = {
      ServiceName: "TemplateERP",
      MethodHash: "com.advantage.templates.bean.GroupTemplate_updateGroupTemplate_com.advantage.templates.bean.GroupTemplate",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: 'com.advantage.templates.bean.GroupTemplate'
        }
      ],
    };

    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public deleteGroupTemplate(IDpropertie: number) {
    const parametros = {
      ServiceName: "TemplateERP",
      MethodHash: "boolean_deleteGroupTemplate_Number",
      ArgumentList: [IDpropertie],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public getTemplateCatalogPorPropiedades(map: any) {
    const parametros = {
      ServiceName: "TemplateERP",
      MethodHash: "java.util.List_getTemplateCatalogPorPropiedades_java.util.Map_Number",
      ArgumentList: [
        map, null
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public updateTemplate(bean: any) {
    const parametros = {
      ServiceName: "TemplateERP",
      MethodHash: "com.advantage.templates.bean.Template_updateTemplate_com.advantage.templates.bean.Template",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: 'com.advantage.templates.bean.Template'
        }
      ],
    };

    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public deleteTemplate(IDpropertie: number) {
    const parametros = {
      ServiceName: "TemplateERP",
      MethodHash: "boolean_deleteTemplate_Number",
      ArgumentList: [IDpropertie],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }



  //applicationtype

  public deleteApplication(application: any) {
    const parametros = {
      ServiceName: "OrangeBase",
      MethodHash:
        "void_deleteApplication_com.orange.bean.application.Application",
      ArgumentList: [
        {
          DataBeanProperties: application,
          DataBeanName: "com.orange.bean.application.Application"
        }
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  getApplicationTypeCatalog(idAccount: number) {
    const parametros = {
      ServiceName: "OrangeBase",
      MethodHash: "java.util.List_getUserMenu_Number",
      ArgumentList: [null],
    };
    const data = JSON.stringify(parametros);
    return http.post(this.url, data);
  }

  deleteApplicationType(id: number) {
    const parametros = {
      ServiceName: "OrangeBase",
      MethodHash: "void_deleteApplicationType_Number",
      ArgumentList: [id],
    };
    const data = JSON.stringify(parametros);
    return http.post(this.url, data);
  }

  getApplicationCatalog() {
    const parametros = {
      ServiceName: "OrangeBase",
      MethodHash: "java.util.List_getApplicationCatalog_Number",
      ArgumentList: [null],
    };
    const data = JSON.stringify(parametros);
    return http.post(this.url, data);
  }

  getMenuTypeConstants(arg: number | null) {
    const parametros = {
      ServiceName: "OrangeBase",
      MethodHash: "java.util.List_getMenuTypeConstants_Number",
      ArgumentList: [arg],
    };
    const data = JSON.stringify(parametros);
    return http.post(this.url, data);
  }

  updateApplicationType(bean: any) {
    const parametros = {
      ServiceName: "OrangeBase",
      MethodHash: "com.orange.bean.application.ApplicationType_updateApplicationType_com.orange.bean.application.ApplicationType",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: 'com.orange.bean.application.ApplicationType'
        }
      ],
    };
    const data = JSON.stringify(parametros);
    return http.post(this.url, data);
  }
  getApplicationTypeCatalogByApplication(id: any) {
    const parametros = {
      ServiceName: "OrangeBase",
      MethodHash: "java.util.List_getApplicationTypeCatalogByApplication_Number",
      ArgumentList: [
        id
      ],
    };
    const data = JSON.stringify(parametros);
    return http.post(this.url, data);
  }

  getApplicationTypeCatalogByMenuType(id: any) {
    const parametros = {
      ServiceName: "OrangeBase",
      MethodHash: "java.util.List_getApplicationTypeCatalogByMenuType_Number",
      ArgumentList: [
        id
      ],
    };
    const data = JSON.stringify(parametros);
    return http.post(this.url, data);
  }

  getApplicationTypeCatalogByMenuTypeandIdAplication(idmenuType: number | null, idApplication: number | null) {
    const parametros = {
      ServiceName: "OrangeBase",
      MethodHash: "java.util.List_getApplicationTypeCatalogByMenuType_Number_Number",
      ArgumentList: [
        idmenuType,
        idApplication,
      ],
    };
    const data = JSON.stringify(parametros);
    return http.post(this.url, data);
  }
  updateApplication(bean: any) {
    const parametros = {
      ServiceName: "OrangeBase",
      MethodHash: "com.orange.bean.application.Application_updateApplication_com.orange.bean.application.Application",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: 'com.orange.bean.application.Application'
        }
      ],
    };
    const data = JSON.stringify(parametros);
    return http.post(this.url, data);
  }

  //ApplicationID

  getApplicationIDByKey(IDLn: number | null) {
    const parametros = {
      ServiceName: "OrangeBase",
      MethodHash: "com.orange.bean.application.ApplicationID_getApplicationIDByKey_Number",
      ArgumentList: [IDLn],
    };
    const data = JSON.stringify(parametros);
    return http.post(this.url, data);
  }
  getTreeApplicationId(idModule: number | null) {
    const dataObj = {
      ServiceName: 'OrangeBase',
      MethodHash: 'java.util.List_getApplicationIDAtLevel_Number_int_String_Number_Number_Number',
      ArgumentList: [
        idModule,
        0,
        null,
        null,
        null,
        null
      ]
    };
    const data = JSON.stringify(dataObj);
    return http.post(this.url, data);
  }
  updateApplicationID(bean: any) {
    const parametros = {
      ServiceName: "OrangeBase",
      MethodHash: "com.orange.bean.application.ApplicationID_updateApplicationID_com.orange.bean.application.ApplicationID",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: 'com.orange.bean.application.ApplicationID'
        }
      ],
    };
    const data = JSON.stringify(parametros);
    return http.post(this.url, data);
  }

  deleteApplicationID(idLn: any) {

    const parametros = {
      ServiceName: "OrangeBase",
      MethodHash: "Number_deleteApplicationID_Number",
      ArgumentList: [
        idLn
      ],
    };
    const data = JSON.stringify(parametros);
    return http.post(this.url, data);
  }

  //Fin ApplicationID

  // Roles de usuario 

  getRoleCatalog(idRole: number | null) {
    const dataObj = {
      ServiceName: 'OrangeBase',
      MethodHash: 'java.util.List_getRoleCatalog_Number',
      ArgumentList: [
        idRole
      ]
    };
    const data = JSON.stringify(dataObj);
    return http.post(this.url, data);
  }
  getHiringRoles(id: number | null) {
    const dataObj = {
      ServiceName: 'FinanceCore',
      MethodHash: 'java.util.List_getHiringRoles_Number',
      ArgumentList: [
        id
      ]
    };
    const data = JSON.stringify(dataObj);
    return http.post(this.url, data);
  }
  deleteRole(bean: any) {
    const dataObj = {
      ServiceName: 'OrangeBase',
      MethodHash: 'void_deleteRole_com.advantage.bean.securitymanager.Role',
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: "com.advantage.bean.securitymanager.Role"
        }
      ]
    };
    const data = JSON.stringify(dataObj);
    return http.post(this.url, data);
  }
  updateRole(bean: any) {
    const parametros = {
      ServiceName: "OrangeBase",
      MethodHash: "com.advantage.bean.securitymanager.Role_updateRole_com.advantage.bean.securitymanager.Role",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: 'com.advantage.bean.securitymanager.Role'
        }
      ],
    };
    const data = JSON.stringify(parametros);
    return http.post(this.url, data);
  }
  public getCheckRole(idRole: number, idLn: number): Observable<any> {
    const dataObj = {
      ServiceName: 'OrangeBase',
      MethodHash: 'Boolean_checkBusinessLogicForRole_Number_Number',
      ArgumentList: [
        idRole,
        idLn
      ]
    };
    const data = JSON.stringify(dataObj);
    return api.post(this.url, data);
  }
  public addBusinessLogicToRole(idRole: number, idLn: number) {
    const parametros = {
      ServiceName: "OrangeBase",
      MethodHash:
        "com.advantage.bean.securitymanager.BusinessFunction_addBusinessLogicToRole_Number_Number",
      ArgumentList: [idRole, idLn],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public deleteBusinessLogicToRole(type: number, idRole: number, idLn: number) {
    const parametros = {
      ServiceName: "OrangeBase",
      MethodHash:
        "com.advantage.bean.securitymanager.BusinessFunction_removeBusinessLogicToRole_Number_Number_Number",
      ArgumentList: [type, idRole, idLn],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public addBusinessLogicToRoleJson(idRole: number, idLn: number, idLnPermissions: string) {
    const parametros = {
      ServiceName: "OrangeBase",
      MethodHash:
        "com.advantage.bean.securitymanager.BusinessFunction_addBusinessLogicToRole_Number_Number_String",
      ArgumentList: [idRole, idLn, idLnPermissions],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public addBusinessLogicToRoleType(type: number, idRole: number, idLn: number, idLnPermissions: string) {
    const parametros = {
      ServiceName: "OrangeBase",
      MethodHash: "com.advantage.bean.securitymanager.BusinessFunction_addBusinessLogicToRole_Number_Number_Number_String",
      ArgumentList: [type, idRole, idLn, idLnPermissions],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }


  // Fin Roles de usuario 

  //DevOps
  public getSoftwareProjectCatalog() {
    const parametros = {
      ServiceName: "OrangeCore",
      MethodHash: "java.util.List_getSoftwareProjectCatalog_Number",
      ArgumentList: [null],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public deleteSoftwareProject(idSoftwareProject: number) {
    const parametros = {
      ServiceName: "OrangeCore",
      MethodHash: "void_deleteSoftwareProject_Number",
      ArgumentList: [idSoftwareProject],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  updateSoftwareProject(bean: any) {
    const parametros = {
      ServiceName: "OrangeCore",
      MethodHash: "com.advantage.bean.dev.SoftwareProject_updateSoftwareProject_com.advantage.bean.dev.SoftwareProject",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: 'com.advantage.bean.dev.SoftwareProject'
        }
      ],
    };
    const data = JSON.stringify(parametros);
    return http.post(this.url, data);
  }

  public getStepDevopsCatalogPorPropiedad(nombrePropiedad: any, value: any, maxRows: any) {
    const parametros = {
      ServiceName: "QuickErp",
      MethodHash: "java.util.List_getStepDevopsCatalogPorPropiedad_String_Object_Number",
      ArgumentList: [nombrePropiedad, value, maxRows],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public deleteStepDevops(idstepdevops: number) {
    const parametros = {
      ServiceName: "QuickErp",
      MethodHash: "boolean_deleteStepDevops_Number",
      ArgumentList: [idstepdevops],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public updateStepDevops(bean: any) {
    const parametros = {
      ServiceName: "QuickErp",
      MethodHash: "com.quickdataerp.bean.StepDevops_updateStepDevops_com.quickdataerp.bean.StepDevops",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: 'com.quickdataerp.bean.StepDevops'
        }
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  //RolDevelop
  public getRolDevopsCatalogPorPropiedad(nombrePropiedad: any, value: any, maxRows: any) {
    const parametros = {
      ServiceName: "QuickErp",
      MethodHash: "java.util.List_getRolDevopsCatalogPorPropiedad_String_Object_Number",
      ArgumentList: [nombrePropiedad, value, maxRows],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public deleteRolDevops(id: number) {
    const parametros = {
      ServiceName: "QuickErp",
      MethodHash: "boolean_deleteRolDevops_Number",
      ArgumentList: [id],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public updateRolDevops(bean: any) {
    const parametros = {
      ServiceName: "QuickErp",
      MethodHash: "com.quickdataerp.bean.RolDevops_updateRolDevops_com.quickdataerp.bean.RolDevops",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: 'com.quickdataerp.bean.RolDevops'
        }
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  //AccountProject
  public getAccountProjectCatalogPorPropiedad(nombrePropiedad: any, value: any, maxRows: any) {
    const parametros = {
      ServiceName: "QuickErp",
      MethodHash: "java.util.List_getAccountProjectCatalogPorPropiedad_String_Object_Number",
      ArgumentList: [nombrePropiedad, value, maxRows],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public deleteAccountProject(id: number) {
    const parametros = {
      ServiceName: "QuickErp",
      MethodHash: "boolean_deleteAccountProject_Number",
      ArgumentList: [id],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public updateAccountProject(bean: any) {
    const parametros = {
      ServiceName: "QuickErp",
      MethodHash: "com.quickdataerp.bean.AccountProject_updateAccountProject_com.quickdataerp.bean.AccountProject",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: 'com.quickdataerp.bean.AccountProject'
        }
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }


  //Fin DevOps

  //Account

  getAccountByNit(nit: number) {
    const parametros = {
      ServiceName: "OrangeBase",
      MethodHash: "java.util.List_getAccountByNit_Number",
      ArgumentList: [nit],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  getAccountByID(nit: number | null) {
    const parametros = {
      ServiceName: "OrangeBase",
      MethodHash: "com.advantage.bean.account.AbstractAccount_getAccount_Number",
      ArgumentList: [nit],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  getAccountByName(name: any | null, surname: any | null) {
    const parametros = {
      ServiceName: "OrangeBase",
      MethodHash: "java.util.List_getAccount_String_String",
      ArgumentList: [name, surname],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  createAbstractAccount(bean: any) {
    const parametros = {
      ServiceName: "OrangeBase",
      MethodHash: "com.advantage.bean.account.AbstractAccount_createAbstractAccount_com.advantage.bean.account.AbstractAccount",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: "com.advantage.bean.account.AbstractAccount",
        }
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public createSupplier(Nit: number | null, idEmployee: number | null, supplier: any) {
    const parametros = {
      ServiceName: "SupplierERP",
      MethodHash: "com.orange.finance.supplier.bean.Supplier_createSupplier_Number_Number_com.orange.finance.supplier.bean.Supplier",
      ArgumentList: [Nit,
        idEmployee,
        {
          DataBeanProperties: supplier,
          DataBeanName: "com.orange.finance.supplier.bean.Supplier"
        }
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  updateAbstractAccount(bean: any) {
    const parametros = {
      ServiceName: "OrangeBase",
      MethodHash: "com.advantage.bean.account.AbstractAccount_updateAbstractAccount_com.advantage.bean.account.AbstractAccount",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: "com.advantage.bean.account.AbstractAccount",
        }
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  activateLdapUser(idAccount: number, ldapUser: string) {
    const parametros = {
      ServiceName: "OrangeBase",
      MethodHash: "com.orange.bean.account.UserAccount_activateLdapUser_Number_String",
      ArgumentList: [
        idAccount,
        ldapUser
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  activateLdapUserDomain(idAccount: number, ldapUser: string, domainName: string) {
    const parametros = {
      ServiceName: "OrangeBase",
      MethodHash: "com.orange.bean.account.UserAccount_activateLdapUser_Number_String_String",
      ArgumentList: [
        idAccount,
        ldapUser,
        domainName
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  // Fin Account


  //History
  public getHistoryCatalogPorPropiedad(nombrePropiedad: any, value: any, maxRows: any) {
    const parametros = {
      ServiceName: "QuickErp",
      MethodHash: "java.util.List_getHistoryCatalogPorPropiedad_String_Object_Number	",
      ArgumentList: [nombrePropiedad, value, maxRows],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public deleteHistory(id: number) {
    const parametros = {
      ServiceName: "QuickErp",
      MethodHash: "boolean_deleteHistory_Number",
      ArgumentList: [id],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public updateHistory(bean: any) {
    const parametros = {
      ServiceName: "QuickErp",
      MethodHash: "com.quickdataerp.bean.History_updateHistory_com.quickdataerp.bean.History	",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: 'com.quickdataerp.bean.History'
        }
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  //Fin History

  //Requirement

  public getRequirementCatalogPorPropiedad(nombrePropiedad: any, value: any, maxRows: any) {
    const parametros = {
      ServiceName: "QuickErp",
      MethodHash: "java.util.List_getRequirementCatalogPorPropiedad_String_Object_Number",
      ArgumentList: [nombrePropiedad, value, maxRows],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public deleteRequirement(id: number) {
    const parametros = {
      ServiceName: "QuickErp",
      MethodHash: "boolean_deleteRequirement_Number",
      ArgumentList: [id],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public updateRequirement(bean: any) {
    const parametros = {
      ServiceName: "QuickErp",
      MethodHash: "com.quickdataerp.bean.Requirement_updateRequirement_com.quickdataerp.bean.Requirement",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: 'com.quickdataerp.bean.Requirement'
        }
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }


  //fin Requirement

  //ApiModule
  public getApiModuleCatalogPorPropiedad(nombrePropiedad: any, value: any, maxRows: any) {
    const parametros = {
      ServiceName: "QuickErp",
      MethodHash: "java.util.List_getApiModuleCatalogPorPropiedad_String_Object_Number",
      ArgumentList: [nombrePropiedad, value, maxRows],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public deleteApiModule(id: number) {
    const parametros = {
      ServiceName: "QuickErp",
      MethodHash: "boolean_deleteApiModule_Number",
      ArgumentList: [id],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public updateApiModule(bean: any) {
    const parametros = {
      ServiceName: "QuickErp",
      MethodHash: "com.quickdataerp.bean.Requirement_updateApiModule_com.quickdataerp.bean.Requirement",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: 'com.quickdataerp.bean.ApiModule'
        }
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public getApiModuleCatalog(args: number | null) {
    const parametros = {
      ServiceName: "OrangeBase",
      MethodHash: "java.util.List_getApiModuleCatalog_Number",
      ArgumentList: [
        args
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public getApiRoleCatalog(args: number | null) {
    const parametros = {
      ServiceName: "OrangeBase",
      MethodHash: "java.util.List_getApiRoleCatalog_Number",
      ArgumentList: [
        args
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public addRoleToApiPermissions(idApiModule: number, idRole: number, roleList: any) {
    const parametros = {
      ServiceName: "OrangeCore",
      MethodHash: "boolean_addRoleToApiPermissions_Number_Number_java.util.List",
      ArgumentList: [
        idApiModule, idRole, roleList
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public removeRoleFromApiPermissions(idApiModule: number, idRole: number) {
    const parametros = {
      ServiceName: "OrangeCore",
      MethodHash: "boolean_removeRoleFromApiPermissions_Number_Number",
      ArgumentList: [
        idApiModule, idRole
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public createApiModule(classForName: string) {
    const parametros = {
      ServiceName: "OrangeBase",
      MethodHash: "boolean_createApiModule_String",
      ArgumentList: [
        classForName
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public getRoleGroups(idApiModule: number) {
    const parametros = {
      ServiceName: "OrangeCore",
      MethodHash: "java.util.List_getRoleGroups_Number",
      ArgumentList: [
        idApiModule
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public getStateGroups(idApiModule: number) {
    const parametros = {
      ServiceName: "OrangeCore",
      MethodHash: "java.util.List_getStateGroups_Number	",
      ArgumentList: [
        idApiModule
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public getApiPermissionsCatalog(idApiModule: number, page: number | null, records: number | null) {
    const parametros = {
      ServiceName: "OrangeBase",
      MethodHash: "com.advantage.shared.Report_getApiPermissionsCatalog_Number_Number_Number",
      ArgumentList: [
        idApiModule, page, records
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public getStateList(idApiModule: number, stateGroupName: string) {
    const parametros = {
      ServiceName: "OrangeCore",
      MethodHash: "java.util.List_getStateList_Number_String",
      ArgumentList: [idApiModule, stateGroupName],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public setRolesForApiPermissions(idApiPermissions: number, roles: any, applyRolesFilter: boolean) {
    const parametros = {
      ServiceName: "OrangeCore",
      MethodHash: "boolean_setRolesForApiPermissions_Number_java.util.List_Boolean",
      ArgumentList: [idApiPermissions, roles, applyRolesFilter],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public setApiStatesForApiPermissions(idApiPermissions: number, apiStateName: string, states: any, applyRolesFilter: boolean) {
    const parametros = {
      ServiceName: "OrangeCore",
      MethodHash: "boolean_setApiStatesForApiPermissions_Number_String_java.util.List_Boolean",
      ArgumentList: [idApiPermissions, apiStateName, states, applyRolesFilter],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public getAdquisitionPlan(id: number) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "com.orange.bean.supplier.AdquisitionPlan_getAdquisitionPlan_Number	",
      ArgumentList: [id],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public desactivateUserAccount(id: number) {
    const parametros = {
      ServiceName: "OrangeBase",
      MethodHash: "Number_desactivateUserAccount_Number",
      ArgumentList: [id],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public activateUserAccount(id: number, user: string, pass: string) {
    const parametros = {
      ServiceName: "OrangeBase",
      MethodHash: "Number_activateUserAccount_Number_String_String",
      ArgumentList: [id, user, pass],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public addAccountToRole(idAccount: number, idEmployee: number, idRole: number) {
    const parametros = {
      ServiceName: "OrangeBase",
      MethodHash: "com.advantage.bean.securitymanager.AccountRole_addAccountToRole_Number_Number_Number",
      ArgumentList: [idAccount, idEmployee, idRole],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public removeAccountToRole(idAccount: number, idEmployee: number, idRole: number) {
    const parametros = {
      ServiceName: "OrangeBase",
      MethodHash: "void_removeAccountToRole_Number_Number_Number",
      ArgumentList: [idAccount, idEmployee, idRole],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }



  //Fin ApiModule

  // Permisos especiales modulos
  public getApiPermissionsByRole(idApiModule: number, idRole: number, states: any) {
    const parametros = {
      ServiceName: "OrangeCore",
      MethodHash: "java.util.List_getApiPermissionsByRole_Number_Number_java.util.Map",
      ArgumentList: [idApiModule, idRole, states],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public getApiPermissions(moduleName: string, idAccount: number, states: any) {
    const parametros = {
      ServiceName: "OrangeCore",
      MethodHash: "java.util.List_getApiPermissions_String_Number_java.util.Map",
      ArgumentList: [moduleName, idAccount, states],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  // Permisos especiales modulos

  public getAccountsByRole(IDRole: number) {
    const parametros = {
      ServiceName: "OrangeBase",
      MethodHash: "java.util.List_getAccountsByRole_Number",
      ArgumentList: [IDRole],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public getRolePermissionByMenu(idApplicationType: number, idRole: number, businessLogicType: number | null) {
    const parametros = {
      ServiceName: "OrangeBase",
      MethodHash: "java.util.List_getRolePermissionByMenu_Number_Number_Number",
      ArgumentList: [idApplicationType, idRole, businessLogicType],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public getBusinessLogicByType(processTypeList: any, types: any) {
    const parametros = {
      ServiceName: "OrangeBase",
      MethodHash: "java.util.List_getBusinessLogicByType_java.util.List_java.util.List",
      ArgumentList: [processTypeList, types],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public getBusinessLogicByTypeRol(idRole:number,processTypeList: any, types: any) {
    const parametros = {
      ServiceName: "OrangeBase",
      MethodHash: "java.util.List_getBusinessLogicByType_Number_java.util.List_java.util.List",
      ArgumentList: [idRole,processTypeList, types],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public getRemoteAbstractActionByApplicationType(idApplicationType: number, state: number | null) {
    const parametros = {
      ServiceName: "OrangeCore",
      MethodHash: "java.util.List_getRemoteAbstractActionByApplicationType_Number_Number",
      ArgumentList: [idApplicationType, state],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public getAccountRoleByRole(idRole: number, state: number | null) {
    const parametros = {
      ServiceName: "OrangeBase",
      MethodHash: "java.util.List_getAccountRoleByRole_Number_Number",
      ArgumentList: [idRole, state],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public getAccountRoleByAccount(idAccount: number, state: number) {
    const parametros = {
      ServiceName: "OrangeBase",
      MethodHash: "java.util.List_getAccountRoleByAccount_Number_Number",
      ArgumentList: [idAccount, state],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public getUserMenuRoles(idAccount: number, state: number) {
    const parametros = {
      ServiceName: "OrangeBase",
      MethodHash: "java.util.List_getUserMenu_Number_Number",
      ArgumentList: [idAccount, state],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public getUserMenuRolesTypes(idAccount: number, idRol: number, ApplicationType: number | null) {
    const parametros = {
      ServiceName: "OrangeBase",
      MethodHash: "java.util.List_getUserMenu_Number_Number_Number_Number_String",
      ArgumentList: [idAccount, idRol, ApplicationType, null, null],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public getUserMenuCache(idAccount: number, idRol: number, ApplicationType: number | null) {
    const parametros = {
      ServiceName: "CacheOptions",
      MethodHash: "java.util.List_getUserMenuCache_Number_Number_Number",
      ArgumentList: [idAccount, idRol, ApplicationType],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public clearUserMenuCache(rows: number) {
    const parametros = {
      ServiceName: "CacheOptions",
      MethodHash: "boolean_clearUserMenuCache_Number",
      ArgumentList: [rows],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public encryptGPGFile(state: number, map: any) {
    const parametros = {
      ServiceName: "SupplierERP",
      MethodHash: "com.advantage.bean.securitymanager.AuditFunction_encryptGPGFile_Number_java.util.Map",
      ArgumentList: [state, map],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  //festivedAY
  public getFestiveDayCatalogPorPropiedad(nombrePropiedad: number | null, value: string | null, maxRows: null) {
    const parametros = {
      ServiceName: "CacheOptions",
      MethodHash: "java.util.List_getFestivalDayCatalogPorPropiedad_String_Object_Number",
      ArgumentList: [nombrePropiedad, value, maxRows],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public updateFestiveDay(bean: any) {
    const parametros = {
      ServiceName: "CacheOptions",
      MethodHash: "com.advantage.cache.bean.FestivalDay_updateFestivalDay_com.advantage.cache.bean.FestivalDay",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: 'com.advantage.cache.bean.FestivalDay'
        }
      ],
    };

    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public deleteFestiveDay(bean: any) {
    const parametros = {
      ServiceName: "CacheOptions",
      MethodHash: "boolean_deleteFestivalDay_Number",
      ArgumentList: [bean],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public getSiteIDTree() {
    const parametros = {
      ServiceName: "OrangeBase",
      MethodHash: "com.advantage.shared.DataBean_getSiteIDTree_Number",
      ArgumentList: [0],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public getSiteIDTreeChilds(IDLn: number) {
    const parametros = {
      ServiceName: "OrangeBase",
      MethodHash: "java.util.List_getSiteIDTreeChilds_Number",
      ArgumentList: [IDLn],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public updateSiteID(bean: any) {
    const parametros = {
      ServiceName: "OrangeBase",
      MethodHash: "com.orange.bean.site.SiteID_updateSiteID_com.orange.bean.site.SiteID",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: "com.orange.bean.site.SiteID"
        }
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public deleteSiteID(bean: any) {
    const parametros = {
      ServiceName: "OrangeBase",
      MethodHash: "com.orange.bean.site.SiteID_deleteSiteID_com.orange.bean.site.SiteID",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: "com.orange.bean.site.SiteID"
        }
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public createSiteID(beanId: any, beanIdRoot: any) {
    const parametros = {
      ServiceName: "OrangeBase",
      MethodHash: "com.orange.bean.site.SiteID_createSiteID_com.orange.bean.site.SiteID_com.orange.bean.site.SiteID",
      ArgumentList: [
        {
          DataBeanProperties: beanId,
          DataBeanName: "com.orange.bean.site.SiteID"
        },
        {
          DataBeanProperties: beanIdRoot,
          DataBeanName: "com.orange.bean.site.SiteID"
        }
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public importSiteID(rows: number, dataList: string) {
    const parametros = {
      ServiceName: "CacheOptions",
      MethodHash: "java.util.List_importSiteID_Number_String",
      ArgumentList: [rows, dataList],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public importGuaranteeList(tipe: number, dataList: string, date: any) {
    const parametros = {
      ServiceName: "GuaranteeErp",
      MethodHash: "java.util.List_importGuaranteeList_Number_String_java.util.Date",
      ArgumentList: [tipe, dataList, date],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public importSupplier(idEmployee: number, dataJson: string) {
    const parametros = {
      ServiceName: "SupplierERP",
      MethodHash: "java.util.List_importSupplier_Number_String",
      ArgumentList: [idEmployee, dataJson],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }


  public getApplicationGroupCatalogByType(type: number | null, page: number | null, recordsPerPage: number | null) {
    const parametros = {
      ServiceName: "OrangeCore",
      MethodHash: "com.advantage.shared.Report_getApplicationGroupCatalogByType_Number_Number_Number",
      ArgumentList: [type, page, recordsPerPage],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public getProcessTypeConstants() {
    const parametros = {
      ServiceName: "QuickDataERPCore",
      MethodHash: "java.util.List_getProcessTypeConstants_Number",
      ArgumentList: [0],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public addProcessTypeToApplicationID(idApplicationType: number, types: any[]) {
    const parametros = {
      ServiceName: "OrangeBase",
      MethodHash: "com.advantage.bean.securitymanager.BusinessLogic_addProcessTypeToApplicationID_Number_java.util.List",
      ArgumentList: [idApplicationType, types],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public addProcessTypeToRemoteAbstractAction(idRemoteAbstractAction: number, types: any[]) {
    const parametros = {
      ServiceName: "OrangeBase",
      MethodHash: "com.advantage.bean.securitymanager.BusinessLogic_addProcessTypeToRemoteAbstractAction_Number_java.util.List",
      ArgumentList: [idRemoteAbstractAction, types],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public addProcessTypeToBusinessLogic(idBusinessLogic: number, listTypes: any[]) {
    const parametros = {
      ServiceName: "OrangeBase",
      MethodHash: "com.advantage.bean.securitymanager.BusinessLogic_addProcessTypeToBusinessLogic_Number_java.util.List",
      ArgumentList: [idBusinessLogic, listTypes],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public getAbstractActionTypeConstants() {
    const parametros = {
      ServiceName: "OrangeBase",
      MethodHash: "java.util.List_getAbstractActionTypeConstants_Number",
      ArgumentList: [0],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public getBusinessLogicStateConstants() {
    const parametros = {
      ServiceName: "OrangeBase",
      MethodHash: "java.util.List_getBusinessLogicStateConstants_Number",
      ArgumentList: [0],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public getBusinessLogicTypeConstants() {
    const parametros = {
      ServiceName: "OrangeBase",
      MethodHash: "java.util.List_getBusinessLogicTypeConstants_Number",
      ArgumentList: [0],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public getBusinessLogicAccessTypeConstants() {
    const parametros = {
      ServiceName: "OrangeBase",
      MethodHash: "java.util.List_getBusinessLogicAccessTypeConstants_Number",
      ArgumentList: [0],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public deleteApplicationGroup(id: number) {
    const parametros = {
      ServiceName: "OrangeCore",
      MethodHash: "void_deleteApplicationGroup_Number",
      ArgumentList: [id],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public deleteRemoteAbstractAction(id: number) {
    const parametros = {
      ServiceName: "OrangeCore",
      MethodHash: "void_deleteRemoteAbstractAction_Number",
      ArgumentList: [id],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public getRemoteAbstractActionCatalogByApplicationGroup(id: number, page: number | null, rows: number | null) {
    const parametros = {
      ServiceName: "OrangeCore",
      MethodHash: "com.advantage.shared.Report_getRemoteAbstractActionCatalogByApplicationGroup_Number_Number_Number",
      ArgumentList: [id, page, rows],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public updateApplicationGroup(bean: any) {
    const parametros = {
      ServiceName: "OrangeCore",
      MethodHash: "com.advantage.bean.dev.ApplicationGroup_updateApplicationGroup_com.advantage.bean.dev.ApplicationGroup",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: "com.advantage.bean.dev.ApplicationGroup"
        }
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public updateRemoteAbstractAction(bean: any) {
    const parametros = {
      ServiceName: "OrangeCore",
      MethodHash: "com.advantage.bean.dev.RemoteAbstractAction_updateRemoteAbstractAction_com.advantage.bean.dev.RemoteAbstractAction",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: "com.advantage.bean.dev.RemoteAbstractAction"
        }
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }


  public getPayrollProcessListByEmployee(year: number, idaccount: number) {
    const parametros = {
      ServiceName: "QuickDataERPCore",
      MethodHash: "java.util.List_getPayrollProcessListByEmployee_Number_Number",
      ArgumentList: [
        year, idaccount
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public getTicketReport(idDocument: number, date: any) {
    const parametros = {
      ServiceName: "QuickDataERPCore",
      MethodHash: "java.util.List_getTicketReport_Number_java.util.Date",
      ArgumentList: [
        idDocument, date
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public getEmployeeContract(idAccount: number) {
    const parametros = {
      ServiceName: "QuickDataERPCore",
      MethodHash: "java.util.List_getEmployeeContract_Number",
      ArgumentList: [
        idAccount
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public getEmployeeFixedAssets(idAccount: number) {
    const parametros = {
      ServiceName: "QuickDataERPCore",
      MethodHash: "java.util.List_getEmployeeFixedAssetsReport_Number",
      ArgumentList: [
        idAccount
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public getEmployeeContractCertificate(idDocument: number, includeSalary: boolean) {
    const parametros = {
      ServiceName: "QuickDataERPCore",
      MethodHash: "java.util.List_getEmployeeContractCertificate_Number_Boolean",
      ArgumentList: [
        idDocument,
        includeSalary
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public getDocumentTemplateGroupCode(processType: number) {
    const parametros = {
      ServiceName: "QuickDataERPCore",
      MethodHash: "java.util.List_getDocumentTemplateGroupCode_Number",
      ArgumentList: [
        processType
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public getDocumentTemplateCatalog(processType: number, groupCode: any, isEnabled: boolean | null) {
    const parametros = {
      ServiceName: "QuickDataERPCore",
      MethodHash: "java.util.List_getDocumentTemplateCatalog_Number_String_Boolean",
      ArgumentList: [
        processType, groupCode, isEnabled
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public updateDocumentTemplate(bean: any) {
    const parametros = {
      ServiceName: "QuickDataERPCore",
      MethodHash: "com.quickdataerp.bean.admin.DocumentTemplate_updateDocumentTemplate_com.quickdataerp.bean.admin.DocumentTemplate",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: "com.quickdataerp.bean.admin.DocumentTemplate"
        }
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public getDocumentTemplateVersionCatalog(idDocumentTemplate: number) {
    const parametros = {
      ServiceName: "QuickDataERPCore",
      MethodHash: "java.util.List_getDocumentTemplateVersionCatalog_Number",
      ArgumentList: [
        idDocumentTemplate
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public createDocumentTemplateVersion(idDocumentTemplate: number, versionCode: string, media: string, mediaContext: string, replaceVersion: boolean, idEmployee: number) {
    const parametros = {
      ServiceName: "QuickDataERPCore",
      MethodHash: "com.quickdataerp.bean.admin.DocumentTemplateVersion_createDocumentTemplateVersion_Number_String_String_String_boolean_Number",
      ArgumentList: [
        idDocumentTemplate, versionCode, media, mediaContext, replaceVersion, idEmployee
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public deleteDocumentTemplateVersion(idDocumentTemplateVersion: number) {
    const parametros = {
      ServiceName: "QuickDataERPCore",
      MethodHash: "void_deleteDocumentTemplateVersion_Number",
      ArgumentList: [
        idDocumentTemplateVersion
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public getDocumentTemplateFileTypeCatalog(idDocumentTemplateVersion: number) {
    const parametros = {
      ServiceName: "QuickDataERPCore",
      MethodHash: "java.util.List_getDocumentTemplateFileTypeCatalog_Number",
      ArgumentList: [
        idDocumentTemplateVersion
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public setActiveDocumentTemplateVersion(idDocumentTemplateVersion: number) {
    const parametros = {
      ServiceName: "QuickDataERPCore",
      MethodHash: "void_setActiveDocumentTemplateVersion_Number",
      ArgumentList: [
        idDocumentTemplateVersion
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public getDictionaryWords(idDocumentTemplateVersion: number) {
    const parametros = {
      ServiceName: "QuickDataERPCore",
      MethodHash: "java.util.List_getDictionaryWords_Number",
      ArgumentList: [
        idDocumentTemplateVersion
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public getProperties(idDocumentTemplateVersion: number) {
    const parametros = {
      ServiceName: "QuickDataERPCore",
      MethodHash: "java.util.List_getProperties_Number",
      ArgumentList: [
        idDocumentTemplateVersion
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public getDocumentTemplateParsed(idDocumentTemplate: number, props: any, fileType: string) {
    const parametros = {
      ServiceName: "QuickDataERPCore",
      MethodHash: "java.util.List_getDocumentTemplateParsed_Number_java.util.Map_String",
      ArgumentList: [
        idDocumentTemplate, props, fileType
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public getDocumentTemplateFileTypeList(idDocumentTemplate: number) {
    const parametros = {
      ServiceName: "QuickDataERPCore",
      MethodHash: "java.util.List_getDocumentTemplateFileTypeList_Number",
      ArgumentList: [
        idDocumentTemplate
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public getGenericCellEditorCatalogByType(classForName: string | null) {
    const parametros = {
      ServiceName: "OrangeCore",
      MethodHash: "java.util.List_getGenericCellEditorCatalogByType_String",
      ArgumentList: [
        classForName
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public getGenericCellEditorClassForNameList(classForName: string | null) {
    const parametros = {
      ServiceName: "OrangeCore",
      MethodHash: "java.util.List_getGenericCellEditorClassForNameList_String",
      ArgumentList: [
        classForName
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public updateGenericCellEditor(bean: any) {
    const parametros = {
      ServiceName: "OrangeCore",
      MethodHash: "com.advantage.bean.dev.GenericCellEditor_updateGenericCellEditor_com.advantage.bean.dev.GenericCellEditor",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: "com.advantage.bean.dev.GenericCellEditor"
        }
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public deleteGenericCellEditor(id: number) {
    const parametros = {
      ServiceName: "OrangeCore",
      MethodHash: "void_deleteGenericCellEditor_Number",
      ArgumentList: [
        id
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  /* PROFILE PHOTO SERVICES */

  public getProfilePhotobyAccount(idAccount: number) {
    const parametros = {
      ServiceName: "OrangeBase",
      MethodHash: "java.util.List_getAccountPhotoByAccount_Number",
      ArgumentList: [
        idAccount
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public updateProfilePhoto(idAccount: number) {
    const parametros = {
      ServiceName: "QuickDataERPCore",
      MethodHash: "java.util.List_getAccountPhotoByAccount_Number",
      ArgumentList: [
        idAccount
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public exportMenuAndRoles(args: string | null) {
    const parametros = {
      ServiceName: "OrangeBase",
      MethodHash: "com.advantage.shared.Report_exportMenuAndRoles_String",
      ArgumentList: [
        args
      ],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public getGenericFormTypeCatalogPorPropiedad(name: string | null, value: number | null, number: null) {
    const parametros = {
      ServiceName: "Forms",
      MethodHash: "java.util.List_getFormTypeCatalogPorPropiedad_String_Object_Number",
      ArgumentList: [name, value, number],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public updateGenericFormType(bean: any) {
    const parametros = {
      ServiceName: "Forms",
      MethodHash: "com.advantage.forms.bean.FormType_updateFormType_com.advantage.forms.bean.FormType",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: 'com.advantage.forms.bean.FormType'
        }
      ],
    };

    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public getGenericFormParamCatalogPorPropiedad(name: string | null, value: number | null, number: null) {
    const parametros = {
      ServiceName: "Forms",
      MethodHash: "java.util.List_getFormParamCatalogPorPropiedad_String_Object_Number",
      ArgumentList: [name, value, number],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public updateGenericFormParam(bean: any) {
    const parametros = {
      ServiceName: "Forms",
      MethodHash: "com.advantage.forms.bean.FormParam_updateFormParam_com.advantage.forms.bean.FormParam",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: 'com.advantage.forms.bean.FormParam'
        }
      ],
    };

    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public getGenericFormAtributesByCode(IDForm: number) {
    const parametros = {
      ServiceName: "CacheOptions",
      MethodHash: "java.util.List_getFormAtributesByCode_Number",
      ArgumentList: [IDForm],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  // Disclaimer

  public getDisclaimerCatalogPorPropiedad(name: string | null, value: any | null, maxRows: number | null) {
    const parametros = {
      ServiceName: "TemplateERP",
      MethodHash: "java.util.List_getDisclaimerCatalogPorPropiedad_String_Object_Number",
      ArgumentList: [name, value, maxRows],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public updateDisclaimer(bean: any) {
    const parametros = {
      ServiceName: "TemplateERP",
      MethodHash: "com.advantage.templates.bean.Disclaimer_updateDisclaimer_com.advantage.templates.bean.Disclaimer",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: 'com.advantage.templates.bean.Disclaimer'
        }
      ],
    };

    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  // MessageTemplate

  public getMessageTemplateCatalogPorPropiedad(name: string | null, value: any | null, maxRows: number | null) {
    const parametros = {
      ServiceName: "TemplateERP",
      MethodHash: "java.util.List_getMessageTemplateCatalogPorPropiedad_String_Object_Number",
      ArgumentList: [name, value, maxRows],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public updateMessageTemplate(bean: any) {
    const parametros = {
      ServiceName: "TemplateERP",
      MethodHash: "com.advantage.templates.bean.MessageTemplate_updateMessageTemplate_com.advantage.templates.bean.MessageTemplate",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: 'com.advantage.templates.bean.MessageTemplate'
        }
      ],
    };

    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public sendCustonEmailMessage(to: any, subject: string | null, message: string) {
    const parametros = {
      ServiceName: "MessageERP",
      MethodHash: "Boolean_sendCustonEmailMessage_java.util.List_String_String_String_String",
      ArgumentList: [
        to, subject, null, message, null
      ],
    };

    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  // GroupRole
  public getRoleGroupsCatalogPorPropiedades(props: any, maxRows: number | null) {
    const parametros = {
      ServiceName: "CacheOptions",
      MethodHash: "java.util.List_getRoleGroupsCatalogPorPropiedades_java.util.Map_Number",
      ArgumentList: [props, maxRows],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public getRoleGroupsCatalogRender(maxRows: number | null, props: any) {
    const parametros = {
      ServiceName: "CacheOptions",
      MethodHash: "java.util.List_getRoleGroupsCatalogRender_Number_java.util.Map",
      ArgumentList: [maxRows, props],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }



  public updateRoleGroups(bean: any) {
    const parametros = {
      ServiceName: "CacheOptions",
      MethodHash: "com.advantage.cache.bean.RoleGroups_updateRoleGroups_com.advantage.cache.bean.RoleGroups",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: 'com.advantage.cache.bean.RoleGroups'
        }
      ],
    };

    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  //GroupRoleItems
  public getRoleGroupsItemsCatalogPorPropiedades(props: any, maxRows: number | null) {
    const parametros = {
      ServiceName: "CacheOptions",
      MethodHash: "java.util.List_getRoleGroupsItemsCatalogPorPropiedades_java.util.Map_Number",
      ArgumentList: [props, maxRows],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public getRoleGroupsItemsCatalogRender(maxRows: number | null, props: any) {
    const parametros = {
      ServiceName: "CacheOptions",
      MethodHash: "java.util.List_getRoleGroupsItemsCatalogRender_Number_java.util.Map",
      ArgumentList: [maxRows, props],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public updateRoleGroupsItems(bean: any) {
    const parametros = {
      ServiceName: "CacheOptions",
      MethodHash: "com.advantage.cache.bean.RoleGroupsItems_updateRoleGroupsItems_com.advantage.cache.bean.RoleGroupsItems",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: 'com.advantage.cache.bean.RoleGroupsItems'
        }
      ],
    };

    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
}
