import { useEffect, useState } from "react"
import { _BusinessCore, _single } from "../../../../core/services/dataBean/EntityCatalog"
import useDataBean from "../../../../core/services/dataBean/useDataBean"
import { BasicPage } from "../../../theme/Component/BasicPage"
import { BasicTable } from "../../../theme/Component/BasicTable"
import { ListMenu } from "../../../theme/Component/ListMenu"
import { Modal } from "../../../theme/Component/Modal"
import { ModalConfirm } from "../../../theme/Component/ModalConfirm"
import { toast } from "react-toastify"

export const BusinessCore = () => {

    const { bean, setBean, lista, setLista, instance, handleChange, deleteItemVoid, update } = useDataBean(_BusinessCore)

    const [modal, setModal] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-md' });
    const [modalC, setModalC] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });

    useEffect(() => {
        getLista();
    }, [])

    const create = () => {
        setBean({});
        setModal({ ...modal, "view": true, "name": "Crear Core de Negocio" })
    }

    const getLista = () => {
        instance.getBusinessCoreCatalog(null).then(
            (resp) => {
                setLista(resp);
            }
        ).catch(err => { toast.error(err) })
    }

    const updateItem = () => {
        update().then(
            (resp) => {
                setModal({ ...modal, "view": false });
                getLista();
            }
        ).catch(
            err => { toast.error(err) }
        )
    }

    const deleteItem = () => {
        deleteItemVoid(bean.IDBudgetAccountantOperation).then(
            (resp) => {
                getLista();
                setModalC({ ...modalC, "view": false });
            }
        ).catch(
            err => { toast.error(err) }
        )
    }

    const iconButtonsItem = [
        { nombre: 'Agregar', visible: _single.canCreate(), icono: 'ri-file-add-line', evento: create }
    ]

    const listaBotones = () => {
        const botones = [
            { titulo: 'Editar', icono: 'ri-edit-2-line', visible: _single.canEdit(), evento: () => { setModal({ ...modal, "view": true, "name": "Editar" }) } },
            { titulo: 'Eliminar', icono: 'ri-delete-bin-line', visible: _single.canDelete(), evento: () => { setModalC({ ...modalC, "view": true, "name": "Eliminar" }) } },
        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }

    const head = [
        { title: 'ID', property: 'IDBusinessCore', visible: true },
        { title: 'Código', property: 'Code', visible: true },
        { title: 'Nombre', property: 'Name', visible: true },
        { title: 'Descripción', property: 'Description', visible: true },
    ]

    return (
        <BasicPage titulo="Catálogo de Core de Negocio">
            <div className="row">
                <div className="col-md-12">
                    <BasicTable iconButtons={iconButtonsItem} listButtons={listaBotones()} body={lista} head={head} rowSet={setBean} />
                </div>
            </div>

            <div className="row">
                <div className="col-md-12">
                    <Modal modal={modal} updateModal={setModal} eventModal={updateItem} >
                        <div className="row">
                            <div className="col-md-6">
                                <label>Código</label>
                                <input type="text" className="form-control" name="Code" value={bean.Code} onChange={handleChange} />
                            </div>
                            <div className="col-md-6">
                                <label>Nombre</label>
                                <input type="text" className="form-control" name="Name" value={bean.Name} onChange={handleChange} />
                            </div>
                            <div className="col-md-12">
                                <label>Descripción</label>
                                <textarea className="form-control" name="Description" value={bean.Description} onChange={handleChange} />
                            </div>
                        </div>
                    </Modal>
                </div>
            </div>

            <div className="row">
                <div className="col-md-12">
                    <ModalConfirm modal={modalC} updateModal={setModalC} eventModal={deleteItem} >
                        <p>¿Desea eliminat el item {bean.IDBusinessCore} - {bean.Name}?</p>
                    </ModalConfirm>
                </div>
            </div>
        </BasicPage>
    )
}