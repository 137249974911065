import { useState, useEffect } from 'react';
import { BpmService } from '../../../../core/services/BpmService';



interface Props {
    idSelector: string;
    onChange: any;
    titulo?: string;
}

export const SelectorFlowControlOperators = ({ titulo, idSelector, onChange }: Props) => {


    const service = new BpmService();
    const [lista, setLista] = useState([]);

    const [id, setID] = useState(idSelector);

    useEffect(() => {
        getLista();
    }, [idSelector])

    useEffect(() => {
        onChange(id);
    }, [id])

    const setValue = (e: any) => {
        setID(e);
        onChange(id);
    }

    const getLista = () => {
        service.getFlowControlOperators(null).subscribe(
            (resp: any) => {
                setLista([]);
                if (resp.DataBeanProperties.ObjectValue) {
                    setLista(resp.DataBeanProperties.ObjectValue);
                }
               //console.log("DataAcces", lista);
            }
        );
    }

    return (
        <>
            <label>{titulo}</label>
            <select className="form-control" value={id} onChange={(e) => { setValue(e.target.value); }}>
                <option value={null}>...</option>
                {lista &&
                    lista.map((item: any) => {
                        return (
                            <>
                                <option value={item.DataBeanProperties.Property} >{item.DataBeanProperties.Value}</option>
                            </>)
                    })
                }
            </select>
        </>
    )
}