import { useEffect, useState } from "react";
import { _single } from "../../../core/services/dataBean/EntityCatalog";
import { YearSelector } from "../../../core/shared/inputs/YearSelector";
import { SelectBudgetType } from "../../admin/configuracion/select/SelectBudgetType";
import { InputDate } from "../../theme/Input/InputDate";

interface Props {
    onChange: Function;
}

export const BudgetCDPReductionForm = ({ onChange }: Props) => {

    const [response, setResponse] = useState<any>({
        FormDate: _single.getCurrenDate(),
        Consecutive:''
    });

    useEffect(() => {
        onChange(response)
    }, [response])

    return (
        <div className="row">
            <div className="col-md-6">
                <label>Fecha Inicial</label>
                <InputDate setDate={(e) => {
                    setResponse
                        ({ ...response, "FormDate": e })
                }} name="Fecha" value={response.FormDate} />
            </div>
            {/* <div className="col-md-6">
                <SelectBudgetType idSelector={response.IDBudgetType} onChange={(e) => { setResponse({ ...response, "IDBudgetType": e }) }} />
            </div> */}
            <div className="col-md-6">
                <label>Consecutivo Externo</label>
                <input
                    type="number"
                    name="Value"
                    className="form-control"
                    value={response.Consecutive}
                    onChange={(e) => {
                        // Convertir el valor a un número (parseInt o parseFloat según sea necesario)
                        setResponse({ ...response, ['Consecutive']: e.target.value ? Number(e.target.value) : '' });
                    }}
                />
            </div>
            <div className="col-md-12">
            <label>Descripción</label>
                <textarea className="form-control" value={response.Description} onChange={(e) => { setResponse({ ...response, ['Description']: e.target.value }) }}></textarea>
            </div>
        </div>
    )
}