import { useEffect, useState } from "react"
import { StepWizard, Wizard } from "../../bpm/config/Componentes/wizard/Wizard"
import { BasicPage } from "../../theme/Component/BasicPage"
import { BudgetSheetFactoryForm } from "./BudgetSheetFactoryForm";
import { RegisterSheetBudgetValue } from "./RegisterSheetBudgetValue";
import { CreateBudgetSheetDocument } from "./CreateBudgetSheetDocument";
import { PrintJson } from "../../theme/Component/PrintJson";

interface MapProperties {
    FormData: Record<string, any>;
    ObjectList: object[];
    DocumentList: Record<string, any>;
}

export const BudgetSheetFactory = () => {

    const [canDiscard, setCanDiscard] = useState<boolean>(false);

    const [map, setMap] = useState<MapProperties>({
        FormData: {},
        ObjectList: [],
        DocumentList: {}
    });

    const validarContrato = () => {

    }

    useEffect(() => {
        if (map.DocumentList?.MSGList?.length > 0) {
            setCanDiscard(true)
        } else {
            setCanDiscard(false)
        }
    }, [map.DocumentList])

    const steps: StepWizard[] = [
        {
            keyID: "0",
            name: 'PASO 1. Formulario',
            onEvent: validarContrato,
            children: <BudgetSheetFactoryForm onChange={(e) => { setMap({ ...map, "FormData": e }) }} />,
            validButton: (map.FormData.Year && map.FormData.IDBudgetType && map.FormData.IDBudgetIDType),
            canReturn: false
        },
        {
            keyID: "1",
            name: 'PASO 2. Selección de Rubros',
            onEvent: validarContrato,
            children: <RegisterSheetBudgetValue sheetForm={map.FormData} onChange={(e) => { setMap({ ...map, "ObjectList": e }) }} />,
            validButton: map.ObjectList.length > 0,
            canReturn: true
        },
        {
            keyID: "2",
            name: 'PASO 3. Crear Presupuesto Inicial',
            onEvent: validarContrato,
            children: <CreateBudgetSheetDocument budgetList={map.ObjectList} formData={map.FormData} onChange={(e) => { setMap({ ...map, "DocumentList": e }) }} />,
            validButton: false,
            canReturn: canDiscard,
            canDiscard: canDiscard
        }
    ]
    return (
        <BasicPage titulo="Presupuesto Inicial">
            <Wizard title="Certificado" listStep={steps} />
        </BasicPage>
    )
}