import { useEffect, useState } from "react";
import { BasicPage } from "../../../../theme/Component/BasicPage";
import { BasicTable } from "../../../../theme/Component/BasicTable";
import { ListMenu } from "../../../../theme/Component/ListMenu";
import { Singleton } from "../../../../../core/services/Singleton";
import { DropDown } from "../../../../theme/Component/DropDown";
import { ComiteEditor } from "./ComponentesComite/Comite.editor";
import { Modal } from "../../../../theme/Component/Modal";
import useDataBean from "../../../../../core/services/dataBean/useDataBean";
import { _Committe } from "../../../../../core/services/dataBean/EntityCatalog";
import useModal, { MODAL_LG, MODAL_md, MODAL_SM } from "../../../../theme/Component/hooks/useModal";
import { MakeCommitte } from "./MakeCommitte";
import { ModalConfirm } from "../../../../theme/Component/ModalConfirm";
import { toast } from "react-toastify";
import { BasicButton } from "../../../../theme/Component/BasicButton";



export const Comite = () => {
    const single = Singleton.getInstance();
    const committe = useDataBean(_Committe);


    const [lista, setLista] = useState([]);
    const [bean, setBean] = useState<any>({});
    const [fecha, setFecha] = useState<any>(single.getCurrenDate());
    const [comite, setComite] = useState<number>(null);
    const [token, setToken] = useState<number>(single.getRandomInt());

    const modalMakeCommitte = useModal("Crear comité", MODAL_LG);
    const modalInforpoCommitte = useModal("Crear comité Inforpo", MODAL_SM);
    const modalInforpoEnviar = useModal("Enviar créditos a Inforpo", MODAL_SM);

    // useEffect(() => {

    //     getLista();
    // }, [comite]);

    const setRowBean = (bean: any) => {
        setBean(bean);
    };

    const getLista = () => {
        if (comite) {
            committe.instance.getCoomitteRender(null, null, { LoteCode: comite }).then(
                (resp: any) => {
                   //console.log(resp);
                    let tmp = resp;
                    tmp.forEach(element => {
                        element.DataBeanProperties.EntityName = single.getEntityName(element);
                        element.DataBeanProperties.Amount = parseInt(element.DataBeanProperties.amount);
                    });

                    setLista(tmp);
                }
            )
        } else {
            toast.error("Debe seleccionar un comite");
        }
    };

    const crearComiteInforpo = () => {
        committe.instance.crearComiteInforpo(single.getAccountID(), comite).then(
            (resp: any) => {
                toast.success(resp.Response);
                modalInforpoCommitte.close();
               //console.log(resp);

                setToken(single.getRandomInt());
            }
        ).catch(err => {
            toast.error(err);
        })
    }
    const registrarUsuarioComiteInforpo = () => {
        committe.instance.registrarUsuarioComiteInforpo(comite).then(
            (resp: any) => {
                toast.success(resp.Response);
                modalInforpoEnviar.close();
               //console.log(resp);

                setToken(single.getRandomInt());

            }
        ).catch(err => {
            toast.error(err);
        })
    }

    const head = [

        { title: "ID", property: "IDCredit", visible: false },
        { title: "Identificación", property: "Nit" },
        { title: "Nombre", property: "EntityName" },
        { title: "Valor", property: "Amount" },
        { title: "Cuotas", property: "quotacredit" },
        { title: "Email", property: "eMail" },
        { title: "Fecha Solicitud", property: "Since" },



    ];

    const listaBotones = () => {
        const botones = [
            {}
        ];
        return <ListMenu listaBotones={botones} />;
    };




    const actionsDropDown = [
        { id: 1, name: 'Crear comite ERP', permiso: null, activo: true },
        { id: 2, name: 'Crear comite Inforpo', permiso: null, activo: comite > 0 },
        { id: 3, name: 'Enviar creditos a Inforpo', permiso: null, activo: comite > 0 },
    ];

    const eventChange = (e: any) => {

        if (e.id === 1) {
            modalMakeCommitte.open();
        }
        else if (e.id === 2) {
            modalInforpoCommitte.open();
        }
        if (e.id === 3) {
            modalInforpoEnviar.open();
        }

    }

    const buttons = () => {
        return (
            <>

                <div className="row">
                    <div className="col-md-12">
                        <DropDown lista={actionsDropDown} eventClick={eventChange}></DropDown>
                    </div>
                </div>

            </>
        )
    }

    return (
        <>
            <BasicPage titulo="Comite crédito">
                <div className="row">
                    {fecha &&
                        <div className="col-8">
                            <ComiteEditor key={token} idSelector={comite} onChangeState={(e) => { setLista([]) }} onChange={(e) => { setComite(e); }} />
                        </div>
                    }
                    <div className="col-4 mt-4">
                        <BasicButton icon="" eventClick={getLista}>Buscar</BasicButton>
                    </div>
                    <div className="col-md-12">
                        <BasicTable headButtons={buttons()} listButtons={listaBotones()} head={head} body={lista} rowSet={setRowBean} />
                    </div>
                </div>
            </BasicPage>

            <Modal modal={modalMakeCommitte.modal} updateModal={modalMakeCommitte.setModal} >
                {/* <ValidationOtp /> */}
                <MakeCommitte lote={comite} callBack={() => { modalMakeCommitte.close(); setComite(0) }} />
            </Modal>
            <ModalConfirm modal={modalInforpoCommitte.modal} eventModal={crearComiteInforpo} updateModal={modalInforpoCommitte.setModal}>
                <strong>Crear comite en inforpo</strong>
            </ModalConfirm>
            <ModalConfirm modal={modalInforpoEnviar.modal} eventModal={registrarUsuarioComiteInforpo} updateModal={modalInforpoEnviar.setModal}>
                <strong>Crear comite en inforpo</strong>
            </ModalConfirm>

        </>
    );
};
