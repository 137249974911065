import { useEffect, useState } from "react"
import { CreateCDPValue } from "./CreateCDPValue";
import { CreateCDPForm } from "./CreateCDPForm";
import { CreateCDPFactoryDocument } from "./CreateCDPFactoryDocument";
import { StepWizard, Wizard } from "../../../../../../bpm/config/Componentes/wizard/Wizard";
import { PrintJson } from "../../../../../../theme/Component/PrintJson";
import { BasicButton } from "../../../../../../theme/Component/BasicButton";
import useDataBean from "../../../../../../../core/services/dataBean/useDataBean";
import { _AdquisitionContractCDP, _ItemFlow } from "../../../../../../../core/services/dataBean/EntityCatalog";
import { toast } from "react-toastify";

interface MapProperties {
    FormData: Record<string, any>;
    ObjectList: object[];
    DocumentList: Record<string, any>;
}

interface Props {
    exportMap: Function;
    procedure: any;
}

export const CreateCDP = ({ exportMap, procedure }: Props) => {

    const { instance, setBean, bean } = useDataBean(_ItemFlow);

    const _adquisitioCDP = useDataBean(_AdquisitionContractCDP)

    const [canDiscard, setCanDiscard] = useState<boolean>(false);
    const [map, setMap] = useState<MapProperties>({
        FormData: {},
        ObjectList: [],
        DocumentList: {}
    });

    useEffect(() => {
        getItem()
    }, [])

    const getItem = () => {
        let x = {
            IDProcedureImp: procedure.IDProcedureImp,
        };
        instance.getItemFlowCatalogPorPropiedades(x, null).then(
            (resp) => {
                if (resp.length > 0) {
                    setBean(resp[0])
                }
            }
        ).catch(err => { console.log(err) })
    }

    const onExportMap = (cdp) => {
        let doc = {
            IDAdquisitionItem: bean.DataBeanProperties?.IDAdquisitionItem,
            IDDocument: cdp.IDDocument,
            DocumentDate: cdp.DocumentDate,
            Mnemonic: cdp.Mnemonic,
            Consecutive: cdp.Consecutive,
        }
        console.log(doc)
        _adquisitioCDP.updateCustonBean(doc).then(
            (resp) => {
                exportMap({
                    DocumentDate: cdp.DocumentDate,
                    Consecutive: cdp.Consecutive,
                    IDDocument: cdp.IDDocument,
                    IDAdquisitionItem: bean.DataBeanProperties?.IDAdquisitionItem
                })
            }
        ).catch(err => { console.log(err); toast.error("Hubo un error en Vincular el CDP") })
    }



    const validarContrato = () => {

    }

    useEffect(() => {
        if (map.DocumentList.MSGList?.length > 0) {
            setCanDiscard(true)
        } else {
            setCanDiscard(false)
        }
    }, [map.DocumentList])

    const [validItem, setValidItem] = useState<boolean>(false);

    const steps: StepWizard[] = [
        {
            keyID: "0",
            name: 'PASO 1. Formulario',
            onEvent: validarContrato,
            children: <CreateCDPForm onChange={(e) => { setMap({ ...map, "FormData": e }) }} />,
            validButton: (map.FormData.Date && map.FormData.BudgetIDType),
            canReturn: false
        },
        {
            keyID: "1",
            name: 'PASO 2. Créditos/Contracréditos',
            onEvent: validarContrato,
            children: <CreateCDPValue proceder={(e) => { setValidItem(e) }} idItems={bean.DataBeanProperties?.IDAdquisitionItem} formData={map.FormData} onChange={(e) => { setMap({ ...map, "ObjectList": e }) }} />,
            validButton: (map.ObjectList.length > 0 && validItem),
            canReturn: true
        },
        {
            keyID: "2",
            name: 'PASO 3. Generar Certificado de Disponibilidad Presupuestal',
            onEvent: () => { },
            children: <CreateCDPFactoryDocument objectList={map.ObjectList} formData={map.FormData} onChange={(e) => { setMap({ ...map, "DocumentList": e }) }} />,
            validButton: false,
            canReturn: canDiscard,
            canDiscard: canDiscard
        }
    ]

    return (
        <>
            <Wizard title="Certificado Disponibilidad Presupuestal" listStep={steps} />
            {map.DocumentList?.Response?.IDDocument &&
                <BasicButton eventClick={() => { onExportMap(map.DocumentList.Response) }} icon="ri-save-line" >Finalizar Proceso</BasicButton>
            }
        </>
    )
}