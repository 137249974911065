import { DataBean } from "../DataBean";

export class Committe extends DataBean{

    public async makeCommite(idEmployee:number,approvals:any):Promise<any>
    {
        return this.getCustomService("ForpoBpm","com.orange.bpm.forpo.bean.CreditCampaign_makeCommite_Number_java.util.List",[idEmployee,approvals]);
    }
    public async getCoomitteRender(page:number|null,rows:number|null,props:any):Promise<any>
    {
        return this.getCustomService("ForpoBpm","java.util.List_getCoomitteRender_Number_Number_java.util.Map",[page,rows,props]);
    }
    public async getRoleCommitteeUser(idEmployee:number|null):Promise<any>
    {
        return this.getCustomService("ForpoBpm","java.util.List_getRoleCommitteeUser_Number",[idEmployee]);
    }
    public async getListCommitte(page:number|null,rows:number,state:number):Promise<any>
    {
        return this.getCustomService("ForpoBpm","java.util.List_getListCommitte_Number_Number_Number",[page,rows,state]);
    }
   
    public async crearComiteInforpo(idEmployee:number,loteCode:number):Promise<any>
    {
        return this.getCustomService("ForpoBpm","com.orange.bpm.forpo.bean.Credit_crearComiteInforpo_Number_Number",[idEmployee,loteCode]);
    }
    public async registrarUsuarioComiteInforpo(lote:number):Promise<any>
    {
        return this.getCustomService("ForpoBpm","com.orange.bpm.forpo.bean.Credit_registrarUsuarioComiteInforpo_Number",[lote]);
    }

    
}
