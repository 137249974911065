import { useState, useEffect } from 'react';
import { useDashboardStore } from '../../../pages/hooks/useDashboardStore';
import { ForpoService } from './service/ForpoService';

export const TIPOID_ID = 1;
export const TIPOID_CODE = 2;

interface Props {
    label?: string;
    code: number;
    value: number | undefined;
    onChange: (value: number | undefined) => void;
    disable?: boolean;
    style?: string;
    TipoID?: number;
}

export const SelectConstantsValueForpo = ({ label, code, value, onChange, disable, style, TipoID }: Props) => {
    const { setLoading } = useDashboardStore();
    const service = new ForpoService();
    const [lista, setLista] = useState<any[]>([]);
    const [id, setId] = useState<number>(undefined);
    const [valorID, setVarloID] = useState<number>(TipoID ?? TIPOID_ID);

    useEffect(() => {
        if (value >= 0) {
            setId(value);
        }
    }, [value]);

    useEffect(() => {
        getLista();
    }, []);

    useEffect(() => {
        onChange(id);
    }, [id]);

    const getLista = () => {
        setLoading(true);
        service.getSuppConstantsValuesByCode(0, [code]).subscribe(
            (resp: any) => {

                if (resp.DataBeanProperties.ObjectValue) {
                    let lista = resp.DataBeanProperties.ObjectValue;
                    setLista(lista['Code_' + code]);
                }
                setLoading(false);
            }
        )
    };

    return (
        <>
            <label>{label ? label : ''}</label>
            <select className={`form-control ${style}`} value={id} id={`codeid-${code}`} onChange={(e) => setId(parseInt(e.target.value))} disabled={disable}>
                <option value={0}>...</option>
                {lista.map((item, index) => (
                    <option key={index} value={valorID === TIPOID_ID ? item.DataBeanProperties.IDSuppConstantsValue : item.DataBeanProperties.CodeID}>
                        {item.DataBeanProperties.Name}
                    </option>
                ))}
            </select>
        </>
    );
};
