import { useState } from 'react';
import { FileService } from '../../../../core/services/FileService';


interface Props {
    doc: any | undefined
}

const fService = new FileService()
export const PDFViewer = ({ doc }: Props) => {
    // const newPlugin = defaultLayoutPlugin()

    const [_url, set_url] = useState('/assets/pdf-open-parameters.pdf')

    // useEffect(() => {
    //     const urlDoc = fService.getUrlFile(doc?.MediaContext ?? '', doc?.Media ?? '')
    //    //console.log(urlDoc);
    //     fService.getPdfAsBase64(urlDoc)
    //         .then(base64 => {
    //            //console.log(base64);
    //             if (base64) { set_url(base64) }
    //         })
    // }, [])


    return (
        <>
            <object data={_url} type="application/pdf" style={{ width: '80vw', height: '70vh' }}></object>
        </>
    )
}
