import { NotFound } from "./theme/NotFound";
import { useParams } from "react-router-dom";
import { Process } from "./contract/Process";
import { Modality } from "./contract/Modality";
import { RequirementDocs } from "./contract/RequirementDocs";
import { AdquisitionPlanCatalog } from "./contract/AdquisitionPlanCatalog";
import { MisPlanesContratar } from "./contract/MisPlanesContratar";
import { MisPlanesPreparador } from "./contract/MisPlanesPreparador";
import { PlanTable } from "./contract/PlanTable";
import { DashboardPlan } from "./contract/DashboardPlan";
import { InboxSupplier } from "./supplier/InboxSupplier";
import { FixedCost } from "./contract/budget/FixedCost";
import { Formatos } from "./bpm/config/Componentes/Formatos";
import { FactorMatrix } from "./bpm/config/Componentes/FactorMatrix";
import { InboxEmployeeSupplier } from "./contract/InboxSupplier/InboxEmployeeSupplier";
import { RegisterContract } from "./contract/etapa-contrato/contrac/RegisterContract";
import { MisPlanesEstructured } from './contract/MisPlanesEstructured';
import { Contracts } from "./contract/etapa-contrato/contrac/Contracts";
import { ImportContract } from "./contract/etapa-contrato/contrac/importdata/ImportContract";
import { MakeUBLInvoice } from "./contract/etapa-contrato/contrac/ublinvoice/MakeUBLInvoice";
import { DraftOrders } from "./contract/etapa-contrato/contrac/DraftOrders";
import { ManageFixedAssetsUBL } from "./contract/etapa-contrato/contrac/ublinvoice/ManageAssets/ManageFixedAssets.UBL";
import { OrderByReview } from "./contract/etapa-contrato/contrac/OrderByReview";
import { AccountingUBLInvoice } from "./contract/etapa-contrato/contrac/ublinvoice/AccountingUBLInvoice";
import { ManageAdquisitionItem } from "./contract/etapa-contrato/contrac/ublinvoice/AdquisitionItemEditor/ManageAdquisitionItem";

export const RouterContract = (props: any) => {
  const { module, view }: any = useParams();

  const renderSwitch = (url: string) => {
    switch (url) {
      case "procesos":
        return <Process />;
      case "modality":
        return <Modality />;
      case "documentos-requeridos":
        return <RequirementDocs />;
      case "planes-adquisicion":
        return <AdquisitionPlanCatalog />;
      // case "mis-planes":
      //   return <MisPlanes />;
      case "mis-planes-estructuracion":
        return <MisPlanesEstructured />;
      case "mis-planes-por-contratar":
        return <MisPlanesContratar rol={1} />;
      case "mis-planes-por-contratar-preparador":
        return <MisPlanesContratar rol={2} />;
      case "gestion-compras":
        return <MisPlanesContratar rol={4} />;
      case "mis-planes-preparador":
        return <MisPlanesPreparador />;
      case "mesa-trabajo":
        return <PlanTable />;
      case "formatos":
        return <Formatos />;
      case "Matrizdefactores":
        return <FactorMatrix />;
      case "dashboard-plan":
        return <DashboardPlan />;
      case "aprobacion-proveedores":
        return <InboxSupplier area={2} />;
      case "costos-fijos":
        return <FixedCost />;
      case "bandeja-gestion-compras":
        return <InboxEmployeeSupplier />;
      case "registar-contrato":
        return <RegisterContract />;
      case "mis-contratos":
        return <Contracts />;
      case "masivos-compras-contratacion":
        return <ImportContract />;
      case "gestion-cuentas-facturacion":
        return <MakeUBLInvoice />;
      case "entrada-activos-fijos":
        return <ManageFixedAssetsUBL />;
      case "ordenes-de-pago-borrador":
        return <DraftOrders />;
      case "ordenes-pago-gestion":
        return <OrderByReview />;
      case "contabilizar-factura":
        return <AccountingUBLInvoice />;
      case "nuevas-necesidades":
        return <ManageAdquisitionItem menuComponent filterState={30} />;

      case "contabilizar-factura-rembolso":
        return <AccountingUBLInvoice entry="Reimbursement" />;


      default:
        return <NotFound />;
    }
  };

  return (
    <div className="px-5" >
      {renderSwitch(view)}
    </div>
  );
};
