import { useEffect, useState } from "react";
import { AdminService } from "../../../core/services/AdminService";

interface Props {
  _value: number;
  _onChange: Function;
  _user: number;
  titulo?: string;
}

export const SelectRoleGroup = ({ _value, _onChange, _user, titulo }: Props) => {

  const service = new AdminService();
  const [lista, setLista] = useState<any[]>([]);
  const [selectedItem, setSelectedItem] = useState<any>(null);

  useEffect(() => {
    if (_value >= 0 && lista.length > 0) {
      const item = lista.find((item: any) => item.DataBeanProperties.IDRoleGroups === _value);
      if (item) {
        setSelectedItem(item);
      } else {
        setSelectedItem(null);
      }
    }
  }, [_value]);

  useEffect(() => {
    getLista();
  }, []);

  const getLista = () => {
    const requestPayload = {
      State: 1,
      IDAccount: _user,
    };
    service.getRoleGroupsCatalogPorPropiedades(requestPayload, null).subscribe(
      (resp: any) => {
        if (resp?.DataBeanProperties?.ObjectValue) {
          setLista(resp.DataBeanProperties.ObjectValue);
        }
      }
    );
  };

  const setValue = (value: string) => {
    const selectedID = parseInt(value);
    const selected = lista.find((item: any) => item.DataBeanProperties.IDRoleGroups === selectedID);
    setSelectedItem(selected);
    _onChange(selected?.DataBeanProperties || null);
  };

  return (
    <>
      {titulo && <label>{titulo}</label>}
      <select className="form-control" value={selectedItem?.DataBeanProperties.IDRoleGroups ?? ''} onChange={(e) => setValue(e.target.value)}>
        <option value="">...</option>
        {lista.map((item: any, index: number) => (
          <option key={index} value={item.DataBeanProperties.IDRoleGroups}>
            {item.DataBeanProperties.Name}
          </option>
        ))}
      </select>
    </>
  );
};
