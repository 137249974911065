import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { _FixedAsset } from "../../../core/services/dataBean/EntityCatalog";
import useDataBean from "../../../core/services/dataBean/useDataBean";
import { toast } from "react-toastify";
import { Modal } from "../../theme/Component/Modal";
import { InputMoney } from "../../theme/Input/InputMoney";
import { SelectStoreEditor } from "../../contract/etapa-contrato/contrac/ublinvoice/SelectStore.editor";
import { InputDate } from "../../theme/Input/InputDate";
import { BasicSwitch } from "../../theme/Component/BasicSwitch";
import { FixedAssetLineEditor } from "./FixedAssetLine.editor";
import { BranListForFixedAssetLineEditor } from "./BranListForFixedAssetLine.editor";
import { BasicButton } from "../../theme/Component/BasicButton";

interface Props {
    fixedAsset?: any;
    _onChange?: Function;
}


export const FixedAssetEditor = forwardRef(({ fixedAsset, _onChange }: Props, ref) => {

    const { bean, setBean, instance, handleChange } = useDataBean(_FixedAsset);
    const [missingFields, setMissingFields] = useState<string[]>([]);

    useEffect(() => {
        if (fixedAsset.IDFixedAsset >= 0) {
            toast.success("Se Encontro un Activo Fijo Realiacionado");
            setBean(fixedAsset)
        } else {
            setBean({
                Disaggregate: false,
                IvaValue: 0,
            });
        }
    }, [fixedAsset])

    const getLista = () => {
        instance.getFixedAsset(fixedAsset.IDFixedAsset).then(
            (resp) => {
                setBean(resp)
            }
        ).catch(
            err => {
               console.error(err);
                toast.error("Aglo Salio Mal");
            }
        )
    }

    const update = () => {
        instance.updateFixedAsset(bean).then(
            (resp) => {
                if (!bean.IDFixedAsset) {
                    toast.success("Se creo el Objeto satisfactoriamente")
                }
                setBean(resp);
            }
        ).catch(
            err => {
               console.error(err);
                toast.error("Algo Salio Mal");
            }
        )
    }

    const validateData = () => {
        if (validateForm() > 0) {
            toast.error("Faltan campos obligatorios por completar");
            return false;
        } else {
            update();
            _onChange(bean)
            return true;
        }
    }

    const validateForm = () => {
        const requiredFields = [
            "Description", "Mark", "InitialQuantity", "AssetValue", "IvaValue", "AliveValue", "IDStore", "Measurement", "IDLine",
            "FixedAssetAccountantID", "Disaggregate", "LiveTime"
        ];
        let missing = [];

        for (let field of requiredFields) {
            if (bean[field] === null || bean[field] === undefined || bean[field] === "") {
                missing.push(field);
            }
        }
        setMissingFields(missing);
        return missing.length;
    };

    useImperativeHandle(ref, () => ({
        validateData, bean
    }));

    return (
        <>
            <div className="row">
                <div className="col-md-6">
                    <label className={`${missingFields.includes('Description') ? 'has-error' : ''}`} >Nombre Activo</label>
                    <input type="text" className="form-control" name="Description" value={bean.Description} onChange={handleChange} />
                </div>
                <div className="col-md-6">
                    <label className={`${missingFields.includes('Mark') ? 'has-error' : ''}`} >Marca</label>
                    <input type="text" className="form-control" name="Mark" value={bean.Mark} onChange={handleChange} />
                </div>
                <div className="col-md-6">
                    <label className={`${missingFields.includes('InitialQuantity') ? 'has-error' : ''}`} >Cantidad</label>
                    <input type="number" disabled={bean.IDUBLInvoice} className="form-control" name="InitialQuantity" value={bean.InitialQuantity} onChange={handleChange} />
                </div>
                <div className="col-md-6">
                    <label className={`${missingFields.includes('AssetValue') ? 'has-error' : ''}`}>Valor Unitario</label>
                    <InputMoney id={0} readonly={bean.IDUBLInvoice} name="AssetValue" onChange={(e) => { setBean({ ...bean, "AssetValue": parseInt(e) }) }} value={bean.AssetValue} />
                </div>
                <div className="col-md-6">
                    <label className={`${missingFields.includes('IvaValue') ? 'has-error' : ''}`}>IVA %</label>
                    <input type="number" disabled={bean.IDUBLInvoice} className="form-control" name="IvaValue" value={bean.IvaValue} onChange={handleChange} />
                </div>
                <div className="col-md-6">
                    <label className={`${missingFields.includes('AliveValue') ? 'has-error' : ''}`}>Valor Total</label>
                    <InputMoney id={1} name="AliveValue" readonly={bean.IDUBLInvoice} onChange={(e) => { setBean({ ...bean, "AliveValue": parseInt(e) }) }} value={bean.AliveValue} />
                </div>
                <div className="col-md-12">
                    <label>Observaciones</label>
                    <textarea className="form-control" name="Observations" value={bean.Observations} onChange={handleChange} />
                </div>
                <div className="col-md-6">
                    <label className={`${missingFields.includes('IDStore') ? 'has-error' : ''}`}>Ubicación</label>
                    <SelectStoreEditor label=" " idSelector={bean.IDStore} onChange={(e) => { setBean({ ...bean, "IDStore": e.IDStore }) }} />
                </div>
                <div className="col-md-6">
                    <label className={`${missingFields.includes('Measurement') ? 'has-error' : ''}`}>Unidad de Medida</label>
                    <input type="text" className="form-control" name="Measurement" value={bean.Measurement} onChange={handleChange} />
                </div>
                <div className="col-md-6">
                    <label>Vencimiento Garantía</label>
                    <InputDate notDate name="WarrantyDefeat" value={bean.WarrantyDefeat} setDate={(e) => { setBean({ ...bean, "WarrantyDefeat": e }) }} />
                </div>
                <div className="col-md-6">
                    <label>Serial</label>
                    <input type="text" className="form-control" name="SerialNumber" value={bean.SerialNumber} onChange={handleChange} />
                </div>
                <div className="col-md-6">
                    <label>Código de Barras</label>
                    <input type="text" className="form-control" name="BarCode" value={bean.BarCode} onChange={handleChange} />
                </div>
                <div className="col-md-6">
                    <label className={`${missingFields.includes('IDLine') ? 'has-error' : ''}`}>Línea de Producto</label>
                    <FixedAssetLineEditor label=" " idSelector={bean.IDLine} onChange={(e) => {
                        if (!bean.LiveTime) {
                            setBean({ ...bean, "LiveTime": e.LiveTime, "IDLine": e.IDLine })
                        } else {
                            setBean({ ...bean, "IDLine": e.IDLine })
                        }
                    }} />
                </div>
                <div className="col-md-6">
                    <label className={`${missingFields.includes('FixedAssetAccountantID') ? 'has-error' : ''}`}>Clase de Producto</label>
                    <BranListForFixedAssetLineEditor label=" " idLine={bean.IDLine} idSelector={bean.FixedAssetAccountantID} onChange={(e) => { setBean({ ...bean, "FixedAssetAccountantID": e }) }} />
                </div>
                <div className="col-md-6">
                    <label className={`${missingFields.includes('Disaggregate') ? 'has-error' : ''}`}>Generar Placa para cada uno</label>
                    <BasicSwitch estado={bean.Disaggregate ?? false} eventChange={(e) => { setBean({ ...bean, "Disaggregate": e }) }} />
                </div>
                <div className="col-md-6">
                    <label className={`${missingFields.includes('LiveTime') ? 'has-error' : ''}`}>Tiempo de Depreciación</label>
                    <input type="number" className="form-control" name="LiveTime" value={bean.LiveTime} onChange={handleChange} />
                </div>
                <div className="col-md-6">
                    <label>Materialidad</label>
                    <BasicSwitch estado={bean.Materiality} eventChange={(e) => { setBean({ ...bean, "Materiality": e }) }} />
                </div>
                <div className="col-md-6">
                    <label>Mínima Cuantía</label>
                    <BasicSwitch estado={bean.MinAssetValue} eventChange={(e) => { setBean({ ...bean, "MinAssetValue": e }) }} />
                </div>
                <div className="col-md-6">
                    <label>Vr Sugerido Comercial</label>
                    <InputMoney id={2} name="CommercialValue" onChange={(e) => { setBean({ ...bean, "CommercialValue": parseInt(e) }) }} value={bean.CommercialValue} />
                </div>
                <div className="col-md-6">
                    <label>Activo Fijo Padre</label>
                </div>
                <div className="col-md-6">
                    <label>Fecha de Adquisición/Compra</label>
                    <InputDate notDate name="AdquisitionDate" value={bean.AdquisitionDate} setDate={(e) => { setBean({ ...bean, "AdquisitionDate": e }) }} />
                </div>
                {!bean.IDFixedAsset &&
                    <div className="row">
                        <div className="col-12">
                            <BasicButton eventClick={validateData} icon="ri-save-line" >Crear</BasicButton>
                        </div>
                    </div>
                }
            </div>
        </>
    );
}
);