import React, { useState, useEffect } from 'react';
import { BpmService } from '../../../../core/services/BpmService';
import { Singleton } from '../../../../core/services/Singleton';

interface Props {
    idSelector: string;
    onChange: any;
    dataBeanClass: string;
}

export const SelectorPropertiesForQuery = ({ idSelector, onChange, dataBeanClass }: Props) => {

    const service = new BpmService();
    const [lista, setLista] = useState<any[]>([]);
    const [id, setID] = useState('');

    useEffect(() => {
        setID(idSelector);
        getLista();
    }, [dataBeanClass]);

    useEffect(() => {
        onChange(id);
    }, [id]);

    const setValue = (e: any) => {
        setID(e);
        onChange(e);
    }

    const getLista = () => {
        service.getPropertiesForQuery(dataBeanClass).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue) {
                    setLista(resp.DataBeanProperties.ObjectValue);
                    ////console.log(resp.DataBeanProperties.ObjectValue);  
                }
            }
        );
    }

    return (
        <>
            <label>Tipo de método</label>
            <select className="form-control mt-2" value={id} onChange={(e) => { setValue(e.target.value) }}>
                <option value={0} key={Singleton.getInstance().getRandomInt()}>...</option>
                {lista &&
                    lista.map((item: any, index: number) => (
                        <option key={index} value={item.DataBeanProperties.Value} >{item.DataBeanProperties.Property}</option>
                    ))
                }
            </select>
        </>
    )
}
