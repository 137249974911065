import React, { useState } from "react";
import { Singleton } from "../services/Singleton";
import { AdminService } from "../services/AdminService";
import { useEffect } from "react";
import { encrypt, encryptData } from "../../utils/helpers";
import { useNavigate } from "react-router-dom";

import { Modal } from "../../modules/theme/Component/Modal";
import { ActivateAccountLdap } from "./ActivateAccountLdap";
import { env } from "../../env";
import { PrintJson } from "../../modules/theme/Component/PrintJson";

interface ILogin {
  setIsLogged: any;
}

export const Login2 = (props: ILogin) => {

  const custosClient = env.CUSTOM_CLIENT ?? false;

  const [logo, setLogo] = useState("assets/images/core/ERP.png");

  const [errorMessage, setErrorMessage] = useState<string>("");
  const [user, setUser] = useState({ user: "", pass: "", tipo: "ldap" });
  // const entidadDomain = "@cajahonor.gov.co";
  const entidadDomain = env.LDAP_DOMAIN;
  // 1 LDAP
  // 2 local
  const loginType = env.LOGIN_TYPE;

  const single = Singleton.getInstance();
  const [yek, setYek] = useState<string>('');

  const [tipoLogin, setTipoLogin] = useState([]);
  const [account, setAccount] = useState<any>({});
  const [esquema, setEsquema] = useState<any>({});
  const [login, setLoign] = useState<any>("");



  const _AdminService = new AdminService();
  const _single = Singleton.getInstance();
  const navigate = useNavigate();

  const [modal, setModal] = useState<any>({
    name: "LDAP",
    tabIndex: -1,
    view: false,
    btnClose: true,
    btnSubmit: false,
    format: "modal-lg",
  });

  //METODO INICIAL
  useEffect(() => {

    if (custosClient) {
      setLogo("./conf/custom/image/logo.png");
    }

  }, []);

  useEffect(() => {
   //console.log("ESPERANDO LLAVE");

    setTimeout(() => {
     //console.log(single.encrypKey);
      setYek(single.encrypKey);
      getLoginTypes();
    }, 1000);

  }, [])

  useEffect(() => {
    tipoLogin.forEach((element: any) => {
      if (element.DataBeanProperties.AuthType === login) {
        setEsquema(element.DataBeanProperties);
        ////console.log(element.DataBeanProperties);
      }
    });
  }, [login]);

  const getLoginTypes = () => {
    single.spinner(true);
    _AdminService.getLoginTypes().subscribe((resp: any) => {
      if (resp.DataBeanProperties.ObjectValue) {
        let tipos = resp.DataBeanProperties.ObjectValue;


        setTipoLogin(tipos);
        if (tipos.length > 0) {
          setEsquema(tipos[0].DataBeanProperties);
        }
      }
      single.spinner(false);
    });
  }

  const getKey = () => {
    _AdminService.securitymanager().subscribe((resp: any) => {
      if (resp.DataBeanProperties.ObjectValue) {

        single.setCookie("yek", resp.DataBeanProperties.ObjectValue.DataBeanProperties.Key, 1);
        single.setCookie("yek", resp.DataBeanProperties.ObjectValue.DataBeanProperties.Key, 1);
        single.setCookie("yekDate", resp.DataBeanProperties.ObjectValue.DataBeanProperties.Name, 1);
        setYek(resp.DataBeanProperties.ObjectValue.DataBeanProperties.Key);
      }
    });
  };

  const updateUser = (event: any) => {
    let propiedad = event.target.name;
    let propiedadValor = event.target.value;



    let tmp: any = user;
    tmp[propiedad] = propiedadValor;

    setUser(tmp);
  };


  const mostrar = () => {
    setErrorMessage("Error de login")
  }

  const loginUser = (event: any) => {

    if (yek === '') {
      getKey();
      setTimeout(() => {
       //console.log("Esperando llave");

      }, 2000)
    }




    let enviar = true;
    if (user.user == "" || user.pass == "") {
      enviar = false;
      setErrorMessage("Usuario o contraseña no pueden estar vacios");
    }

    if (enviar)
      _AdminService.login(encryptData(user.user), encryptData(user.pass), esquema.AuthType, esquema.Ldap).subscribe(

        (respLog: any) => {
          let resp = respLog.data;


          single.setCookie("auth", respLog.headers['authorization'], 1);

          if (resp) {

            if (resp.DataBeanProperties.hasOwnProperty("ErrorMessage")) {
              props.setIsLogged(false);
              setErrorMessage(resp.DataBeanProperties.ErrorMessage);
              getKey();
            } else if (resp.DataBeanProperties.ObjectValue) {
              let rest: any = resp.DataBeanProperties.ObjectValue;
             //console.log("rest : ", rest);

              if (rest.DataBeanProperties.State === 8) {
                //mostrar modal para crear o actualizar cuenta de usuario ldap
                setModal({ ...modal, view: true });
              } else if (rest.DataBeanProperties.IDAccount >= 0 && rest.DataBeanProperties.IDSession >= 0) {
                _single.startSesion(
                  rest.DataBeanProperties.Account.DataBeanProperties
                );
                props.setIsLogged(true);
                if (_single.getAccountID() === 0) {
                  navigate("/system");
                } else {
                  navigate("/app");
                }


              } else {
                setErrorMessage("Usuario o contraseña invalido");
              }
            } else {
              setErrorMessage("Datos invalidos");
            }
          } else {
            setErrorMessage("Usuario no autorizado");
          }
        },
        (err: any) => {
         console.error(err);
        }
      );

  };

  return (
    <>
      {/* <div className="navbar-menu"></div> */}
      {/* <!-- auth-page wrapper --> */}
      <div className="auth-page-wrapper fondcolor py-5 d-flex justify-content-center align-items-center min-vh-100">
        {/* <!-- auth-page content --> */}
        <div className="auth-page-content">
          <div className="container">

            {custosClient == false &&
              <div className="row d-flex justify-content-center">
                <div className="col-md-1">
                  <img src="assets/images/core/simbolds.png" className="img-fluid" alt="" />
                </div>
              </div>
            }

            <div className="row">
              <div className="col-md-12 d-flex justify-content-center mb-2">
                <div className="image-container">
                  <img src={logo} className="img-login" alt="Logo" />
                </div>
              </div>
            </div>

            <div className="row d-flex justify-content-center">
              <div className="col-lg-6 col-md-9 login my-3">
                <div className="row">

                  <div className="col-lg-6 ">
                    <label className="form-label collabel" htmlFor="password-input"> USUARIO</label>
                    <input type="text" className="form-control" id="username" name="user" placeholder="Ingrese Usuario" onChange={updateUser} />
                  </div>

                  <div className="col-lg-6">
                    <label className="form-label collabel" htmlFor="password-input">TIPO DE USUARIO</label>
                    <select id="inputState" className="form-select" name="tipo" onChange={(e) => { setLoign(e.target.value); }}>
                      {tipoLogin?.map((tipo: any) => (
                        <option value={tipo.DataBeanProperties.AuthType} key={tipo.DataBeanProperties?.AuthType}>{tipo.DataBeanProperties?.Name}</option>
                      ))}
                    </select>
                  </div>

                  <div className="col-lg-12">
                    <label className="form-label collabel" htmlFor="password-input" >CONTRASEÑA</label>
                    <input type="password" className="form-control pe-5 password-input" placeholder="Ingrese Contraseña" id="password-input" name="pass" onChange={updateUser} />
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <button type="button" className="colbut w-100 mt-3" onClick={loginUser}>
                      <strong>INGRESAR</strong>
                    </button>
                  </div>
                </div>

                <div className="row">
                  <div className="col mt-4">
                    <div className={
                      errorMessage === ""
                        ? "alert alert-primary alert-dismissible shadow fade "
                        : "alert alert-primary alert-dismissible shadow fade show"
                    }
                      role="alert"
                    >
                      <strong> Login! </strong> {errorMessage}
                      <button type="button" className="btn-close" aria-label="Close" onClick={() => { setErrorMessage(""); }} />
                    </div>
                  </div>
                </div>
              </div>
            </div>


            {custosClient &&
              <div className="watermark">
                <a href="https://www.advantage.com.co/" target="_blank" rel="noopener noreferrer">@advantage.com.co</a>
              </div>
            }

            {custosClient == false &&
              <div className="row d-flex justify-content-center">
                <div className="col-1 text-center">
                  <p className="power">Powered by</p>
                  <img
                    src="assets/images/core/logoblancoamc.png"
                    className="imglog"
                    alt=""
                  />
                </div>
              </div>
            }

          </div>
          {/* <!-- end row --> */}
        </div>
        {/* <!-- end container --> */}
      </div>
      {/* <!-- end auth page content --> */}

      {/* <Footer/> */}

      {/* <!-- end Footer --> */}
      {/* <!-- end auth-page-wrapper --> */}

      {modal.view &&
        <Modal modal={modal} updateModal={setModal} eventModal={() => { }}>
          <h6>Actualizar Información de la cuenta</h6>
          <hr />
          <ActivateAccountLdap user={user.user} setAccount={setAccount} domain={esquema.Name} />
        </Modal>
      }

    </>
  );
};
