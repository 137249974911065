import { toast } from "react-toastify";
import { _UBLInvoice } from "../../../../core/services/dataBean/EntityCatalog";
import useDataBean from "../../../../core/services/dataBean/useDataBean";
import { useEffect, useState } from "react";
import { PrintJson } from "../../../theme/Component/PrintJson";
import { FileService } from "../../../../core/services/FileService";


interface Props {
    idInvoice: number;
    issueDate: any;
}

export const SeeInvoice = ({ idInvoice, issueDate }: Props) => {

    const { instance, bean, setBean } = useDataBean(_UBLInvoice);
    const fileService = new FileService();

    const [fileType, setFileType] = useState<string>(null);
    const [urlFile, setUrlFile] = useState<string | null>(null);
    const [donwloadURL, setDonwloadURL] = useState<string>(null);

    useEffect(() => {
        if (bean.IDUblInvoice) {
            setFileType(bean.Media?.split('.').pop())
            setDonwloadURL(fileService.getUrlFile(bean.MediaContext, bean.Media))
        }
    }, [bean])

    useEffect(() => {
        if (donwloadURL) {
            const fetchPdf = async () => {
                try {
                    const data = await fileService.getPdfAsBase642(donwloadURL);
                    setUrlFile(data);
                } catch (error) {
                    console.error('Error loading PDF:', error);
                }
            };

            fetchPdf();
        }
    }, [donwloadURL]);

    useEffect(() => {
        getInvoice();
    }, [idInvoice])

    const getInvoice = () => {
        instance.getUblInvoice(idInvoice, issueDate).then(
            (resp) => {
                setBean(resp)
            }
        ).catch(
            err => {
                toast.error("no se pudo cargar la factura")
            }
        )
    }


    return (
        <>
            <div className="row">
                <div className="col-md-12">
                    <div className="iframe-container">

                        {fileType ?
                            <>
                                {fileType === 'pdf' && (
                                    urlFile ? (
                                        <iframe name="pdfviwer" src={urlFile} onError={(e) => console.error('Error loading PDF:', e)}></iframe>
                                    ) : (
                                        <p>Cargando PDF...</p>
                                    )
                                )}
                                {fileType === `"jpg"` || fileType === "png" &&
                                    (
                                        <img src={urlFile} alt="Image Preview" width="100%" />
                                    )
                                }
                                {fileType !== "pdf" && "jpg" && "png" &&
                                    <>
                                        <a href={donwloadURL} target='_blank'>Clic aquí para descargar el archivo {bean.Media}</a>
                                    </>
                                }
                            </>
                            :
                            <>
                                No se pudo cargar el Documento
                            </>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}