import { DataBean } from "../DataBean";

export class ApplicationID extends DataBean {

    public async getSystemMenuByType(type: number, props: any): Promise<any> {
        return this.getCustomService("CacheOptions", "java.util.List_getSystemMenuByType_Number_java.util.Map", [type, props]);
    }
    public async updateSystemMenuProcess(type: number, data: any): Promise<any> {
        return this.getCustomService("CacheOptions", "Boolean_updateSystemMenuProcess_Number_String", [type, data]);
    }
    public async getBudgetIDTypeCatalog(): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_getBudgetIDTypeCatalog_Number", [null]);
    }
    public async getTreeForUnspscID(): Promise<any> {
        return this.getCustomService("FinanceCore", "com.advantage.shared.Tree_getTreeForUnspscID_Number", [null]);
    }
    public async getTreeForBudgetID(typeBudget: number): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "com.advantage.shared.Tree_getTreeForBudgetID_Number", [typeBudget]);
    }
    public async getBudgetCodesRender(IDLn: number): Promise<any> {
        return this.getCustomService("FinanceCore", "java.util.List_getBudgetCodesRender_Number", [IDLn]);
    }
    public async getUnscpCodesRender(IDLn: number): Promise<any> {
        return this.getCustomService("FinanceCore", "java.util.List_getUnscpCodesRender_Number", [IDLn]);
    }

    public async addUnscpCodesByFunctionalID(IDLnFunctionalID: number, idEmployee: number, codes: any): Promise<any> {
        return this.getCustomService("FinanceCore", "java.util.List_addUnscpCodesByFunctionalID_Number_Number_java.util.List", [IDLnFunctionalID, idEmployee, codes]);
    }

    public async addBudgetCodesByFunctionalID(IDLnFunctionalID: number, idBudgetType: number, idEmployee: number, codes: any): Promise<any> {
        return this.getCustomService("FinanceCore", "java.util.List_addBudgetCodesByFunctionalID_Number_Number_Number_java.util.List", [IDLnFunctionalID, idBudgetType, idEmployee, codes]);
    }

    public async deleteBudgetFunctionalID(budgetfunctionalid: number): Promise<any> {
        return this.getCustomService("FinanceCore", "boolean_deleteBudgetFunctionalID_Number", [budgetfunctionalid]);
    }

    public async deleteUnscpFunctionalID(unscpfunctionalid): Promise<any> {
        return this.getCustomService("FinanceCore", "boolean_deleteUnscpFunctionalID_Number", [unscpfunctionalid]);
    }
}