import { useState } from "react";
import useDataBean from "../../../core/services/dataBean/useDataBean";
import { Singleton } from "../../../core/services/Singleton";
import { toast } from "react-toastify";
import { ListMenu } from "../../theme/Component/ListMenu";
import { BasicPage } from "../../theme/Component/BasicPage";
import { BasicTable, MASK_NUMBER } from "../../theme/Component/BasicTable";
import { Modal } from "../../theme/Component/Modal";
import { ModalConfirm } from "../../theme/Component/ModalConfirm";
import { _FunctionalCompetence } from "../../../core/services/dataBean/EntityCatalog";
import { AccountEditor } from "../../editor/AccountEditor";
import { PayrollMonthsConstantsEditor } from "../ParametrosTalentoHumano/EmployeeBonus/Editors/PayrollMonthsConstants.editor";
import { _EmployeeRevenue } from "../../../core/services/dataBean/EntityCatalogVdos";
import { YearSelector } from "../../../core/shared/inputs/YearSelector";
import { useDashboardStore } from "../../pages/hooks/useDashboardStore";
import { EmployeePaymentsEditor } from "../ParametrosTalentoHumano/EmployeePayments/EmployyePayment.editor.select";
import { InputDate } from "../../theme/Input/InputDate";
import { BasicButton } from "../../theme/Component/BasicButton";
import { PrintJson } from "../../theme/Component/PrintJson";

export const EmployeeRevenue = () => {

    const EmployeeRevenue = useDataBean(_EmployeeRevenue);

    const single = Singleton.getInstance();
    const { setLoading } = useDashboardStore();
    const [IdAcoount, setIdAcoount] = useState<number>(null)
    const [Montedit, setMontedit] = useState<number>(null)
    const [year, setYear] = useState<number>(null)
    const [lista, setLista] = useState<any>([]);
    const [missingFields, setMissingFields] = useState<string[]>([]);
    const [modal, setModal] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalC, setModalC] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });

    // useEffect(() => {
    //     if (year > 0 && Montedit !== null && Montedit >= 0) {
    //         getLista();
    //     }
    // }, [IdAcoount, year, Montedit]);

    const validateGetLista = () => {
        if (!year) {
            toast.error("Año no valido")
        } else if (Montedit == null || Montedit == undefined) {
            toast.error("Mes no valido")
        } else {
            getLista();
        }
    }

    const getLista = () => {
        setLoading(true);
        EmployeeRevenue.instance.getEmployeeRevenue(IdAcoount, year, Montedit, null).then(
            (resp: any) => {
                if (resp) {
                    setLista(resp)
                   //console.log(resp)
                }
                setLoading(false);
            }
        )
    }

    const create = () => {
        EmployeeRevenue.setBean({
            IDEmployee: single.getAccountID(),
            FromDate: single.getCurrenDate(),
        });
        setModal({ ...modal, "view": true });
    }

    const deleteItem = () => {
        EmployeeRevenue.instance.deleteEmployeeRevenue(EmployeeRevenue.bean.IDRevenue).then(
            (resp: any) => {
                getLista();
                setModalC({ ...modalC, "view": false });
            }
        )
    }

    const editaItem = () => {
       //console.log(EmployeeRevenue.bean);
        EmployeeRevenue.update().then(
            (resp: any) => {
                getLista();
                setModal({ ...modal, "view": false });
            }
        ).catch(err => {
            toast.error(err);
        })

    }

    const head = [
        { title: 'ID', property: 'IDRevenue', visible: true, mask: MASK_NUMBER },
        { title: 'Empleado', property: 'AccountName', visible: true },
        { title: 'Tipo', property: 'EmployeePaymentsCode', visible: true },
        { title: 'Horas Base Liquidación', property: 'BaseHours', visible: true, mask: MASK_NUMBER },
        { title: 'Cantidad', property: 'Units', visible: true, mask: MASK_NUMBER },
        { title: 'Unidades', property: 'UnitsType', visible: true, mask: MASK_NUMBER },
        { title: 'Fecha', property: 'FromDate', visible: true },



    ]

    const iconButtonsItem = [
        { nombre: 'Agregar', visible: single.canCreate(), icono: 'ri-file-add-line', evento: create }
    ]
    const listaBotones = () => {
        const botones = [

            { titulo: 'Editar', icono: 'ri-edit-2-line', visible: single.canEdit(), evento: () => { setModal({ ...modal, "view": true }) } },
            { titulo: 'Eliminar', icono: 'ri-delete-bin-line', visible: single.canDelete(), evento: () => { setModalC({ ...modalC, "view": true }) } },

        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }

    const validateData = () => {
        if (validateForm() > 0) {
            toast.error("Faltan campos obligatorios por completar");
            return;
        } else {
            editaItem();
        }
    }

    const validateForm = () => {
        const requiredFields = [
            "IDAccount", "IDEmploymentBonus", "Units", "FromDate"
        ];

        let missing = [];
        for (let field of requiredFields) {
            if (!EmployeeRevenue.bean[field]) {
                missing.push(field);
            }
        }
        //console.log(missing)
        setMissingFields(missing);
        return missing.length
    };

    return (
        <>
            <BasicPage titulo="Ingresar Otros Ingresos Laborales ">
                <div className="row mb-2">
                    <div className="col-md-4">
                        <AccountEditor label="Empleado" idAccount={IdAcoount} onChange={(e) => { setIdAcoount(e.IDAccount) }} />
                    </div>
                    <div className="col-md-3">
                        <YearSelector tittle="Vigencia" value={year} onChange={setYear} />
                    </div>
                    <div className="col-md-3">
                        <PayrollMonthsConstantsEditor label="Mes" idSelector={Montedit} onChange={(e) => { setMontedit(e) }} />
                    </div>
                    <div className="col-2 d-flex">
                        <div className="row d-flex align-content-end">
                            <BasicButton icon="ri-search-line" eventClick={validateGetLista} >Buscar</BasicButton>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <BasicTable body={lista} head={head} rowSet={EmployeeRevenue.setBean} listButtons={listaBotones()} iconButtons={iconButtonsItem}></BasicTable>
                    </div>
                </div>
            </BasicPage>

            {modal.view &&
                <Modal modal={modal} updateModal={setModal} eventModal={validateData}>
                    <div className="row">
                        <div className="col-md-6">
                            <label className={`${missingFields.includes('IDAccount') ? 'has-error' : ''}`}>Empleado</label>
                            <AccountEditor label=" " idAccount={EmployeeRevenue.bean.IDAccount} onChange={(e) => { EmployeeRevenue.setBean({ ...EmployeeRevenue.bean, "IDAccount": e.IDAccount }) }}></AccountEditor>
                        </div>
                        <div className="col-md-6">
                            <label className={`${missingFields.includes('IDEmploymentBonus') ? 'has-error' : ''}`}>Tipo Concepto de Ingreso</label>
                            <EmployeePaymentsEditor label=" " idSelector={EmployeeRevenue.bean.IDEmploymentBonus} onChange={({ IDEmploymentBonus, BaseHours, UnitsType }) => { EmployeeRevenue.setBean({ ...EmployeeRevenue.bean, "IDEmploymentBonus": IDEmploymentBonus, "BaseHours": BaseHours, "UnitsType": UnitsType }) }} />
                        </div>
                        <div className="col-md-6">
                            <label>Horas Base Liquidación:</label>
                            <input type="text" name="BaseHours" className="form-control" value={EmployeeRevenue.bean.BaseHours} onChange={EmployeeRevenue.handleChange} disabled />
                        </div>
                        <div className="col-md-6">
                            <label>Unidades</label>
                            <input type="text" name="UnitsType" className="form-control" value={EmployeeRevenue.bean.UnitsType} onChange={EmployeeRevenue.handleChange} disabled />
                        </div>
                        <div className="col-md-6">
                            <label className={`${missingFields.includes('Units') ? 'has-error' : ''}`}>Cantidad</label>
                            <input type="text" name="Units" className="form-control" value={EmployeeRevenue.bean.Units} onChange={EmployeeRevenue.handleChange} />
                        </div>
                        <div className="col-md-6">
                            <label className={`${missingFields.includes('FromDate') ? 'has-error' : ''}`}>Fecha</label>
                            <InputDate label=" " name="FromDate" value={EmployeeRevenue.bean.FromDate} setDate={(e: any) => { EmployeeRevenue.setBean({ ...EmployeeRevenue.bean, 'FromDate': e }) }} ></InputDate>
                        </div>
                        <div className="col-md-12">
                            <label>Description</label>
                            <textarea name="Description" className="form-control" value={EmployeeRevenue.bean.Description} onChange={EmployeeRevenue.handleChange} />
                        </div>
                    </div>
                </Modal>
            }

            {modalC.view &&
                <ModalConfirm modal={modalC} updateModal={setModalC} eventModal={deleteItem}>
                    <strong>Eliminar item</strong>
                </ModalConfirm>
            }
        </>
    )
}