import { DataBean } from "../DataBean";


export class Competition extends DataBean {


    public async getCompetitionCatalog(idLn: number, idAppoManual: number, level: number | null): Promise<any> {
        return this.getCustomService("PeopleArt", "java.util.List_getCompetitionCatalog_Number_Number_Number", [idLn, idAppoManual, level]);
    }

    public async deleteCompetition(bean): Promise<any> {
        return this.getCustomService("PeopleArt", "void_deleteCompetition_com.peopleart.bean.appointment.Competition", [
            {
                "DataBeanProperties": bean,
                "DataBeanName": "com.peopleart.bean.appointment.Competition"
            }
        ]);
    }
}