import { useEffect, useState } from "react";
import { ContractService } from "../../../core/services/ContractService";
import { Singleton } from "../../../core/services/Singleton";
import { ListMenu } from "../../theme/Component/ListMenu";
import { BasicTable, MASK_MONEY, MASK_NUMBER } from "../../theme/Component/BasicTable";
import { Modal } from "../../theme/Component/Modal";
import { AdquisitionItemBudgetID } from "../AdquisitionItemBudgetID";
import { SelectProjectID } from "../../admin/configuracion/ProjectID/SelectProjectID";
import { SelectMonths } from "../select/SelectMonths";
import { InputMoney } from "../../theme/Input/InputMoney";
import { SelectPreparadorCatalog } from "../select/SelectPreparadorCatalog";
import { ModalConfirm } from "../../theme/Component/ModalConfirm";
import { AdquisitionArticle } from "./AdquisitionArticle";
import { useDashboardStore } from "../../pages/hooks/useDashboardStore";
import useModal, { MODAL_LG, MODAL_md, MODAL_XL } from "../../theme/Component/hooks/useModal";
import { ESTATE_ADQUISITIONITEM, ITEM_ADMIN, ITEM_JEFE, ITEM_PREPARADOR } from "../../Constants";
import { OfferEmployee } from "../FlowHiring/OfferEmployee/OfferEmployee";
import { ChartArea } from "../../theme/charts/ChartArea";
import { SelectModalityNewSer } from "../select/SelectModalityNewSer";
import { SelectUnspscID } from "../../admin/configuracion/UnspscID/SelectUnspscID";
import { _single } from "../../../core/services/dataBean/EntityCatalog";
import { IDAdquisitionProject } from "../../erp/AdquisitionProject/AdquisitionProjectSelect";
import { SelectAdquisitionItemConstan } from "./SelectAdquisitionItemConstan";

interface Props {
    idAdquisitionPlanFunctionalID: number;
    selectRow?: any;
    rol: number;
    beanOficina: any;
    phaseState?: number;
    idPreparer?: number;
    filterState?: number;
    filterStateArray?: any[];
}
export const AdquisitionItem = ({ idAdquisitionPlanFunctionalID, selectRow, rol, beanOficina, phaseState, filterState, idPreparer, filterStateArray }: Props) => {

    const single = Singleton.getInstance()
    const { setLoading } = useDashboardStore();
    const service = new ContractService();
    const [lista, setLista] = useState([]);
    const [listaGra, setListaGra] = useState<any>([]);
    const [types, setTypes] = useState<any>([]);
    const [ListaTypes, setListaTypes] = useState<any>([]);
    const [bean, setBean] = useState<any>({});
    const [plan, setPlan] = useState<any>({});

    const [listaHistorico, setHistorico] = useState<any>([]);
    const [token, setToken] = useState<number>(0);
    const [obs, setObs] = useState<string | undefined>('');
    const [nueva, setNueva] = useState<boolean>((phaseState ?? 0) === 2 ? true : false);//si es nueva necesidad 100 plan 101 nueva necesidad 

    //Modales
    const [modalRubro, setModalRubro] = useState<any>({ name: 'Rubros', tabIndex: -1, view: false, btnClose: true, btnSubmit: false, format: 'modal-lg' });
    const [modalItem, setModalItem] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalArticulo, setModalArticulo] = useState<any>({ name: 'Articulos', tabIndex: -1, view: false, btnClose: true, btnSubmit: false, format: 'modal-lg' });
    const [modalConfirm, setModalConfirm] = useState<any>({ name: 'Confirmar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });

    const modalEstado = useModal("Estado necesidad", MODAL_md);
    const modalReturn = useModal("Devolver a Preparador", MODAL_md);
    // const modalAnalyst = useModal("Analista", MODAL_LG);
    const [modalAnalyst, setModalAnalist] = useState<any>({ name: 'Equipo de Trabajo', tabIndex: -1, view: false, btnClose: true, btnSubmit: false, format: 'modal-lg' });
    const [modalHistorico, setModalHistorico] = useState<any>({ name: 'Histórico de Aprobaciones', tabIndex: -1, view: false, btnClose: true, btnSubmit: false, format: 'modal-lg' });
    const [modalInfoArticles, setModalInfoArticles] = useState<any>({ name: 'Información de Estados de los Artículos del Plan', tabIndex: -1, view: false, btnClose: true, btnSubmit: false, format: 'modal-lg' });
    const [modalSend, setModalSend] = useState<any>({ name: 'Enviar a Responsable del Área para revisión', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalAprobe, setModalAprobe] = useState<any>({ name: 'Aprobar item', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-md' });

    const modalEnviarJefe = useModal("Solicitar aprobación", MODAL_md);

    const idBean = 'IDAdquisitionItem';

    const head = [
        { title: 'ID', property: idBean, mask: MASK_NUMBER, visible: false },
        { title: 'Necesidad', property: 'Description' },
        { title: 'Justificación', property: 'Justification', visible: false },
        { title: 'Modalidad de selección', property: 'IDHiringModalityName', visible: false },
        { title: 'Valor total estimado', property: 'TotalValue', mask: MASK_MONEY },
        { title: 'Valor total Bien/Servicio', property: 'TotalArticle', mask: MASK_MONEY },
        { title: 'Nota', property: 'Note', visible: false },
        { title: 'Rubro', property: 'IDLnBudgetIDNameList' },
        { title: 'Iniciativa Estratégica/Proyecto', property: 'NameProject', visible: false },
        { title: 'Dependencia Solicitante', property: 'IDLnFunctionalIDName', visible: false },
        { title: "Preparador", property: "PreparerName", visible: false },
        { title: "Analista", property: "AnalystName", visible: false },
        { title: "Estado", property: "PreparationStateName" },
    ]

    const headHistorico = [
        { title: 'Fecha', property: 'EventDate' },
        { title: 'Identificación', property: 'AccountID' },
        { title: 'Nombre', property: 'AccountName' },
        { title: 'Aprobado', property: 'Approved', visible: false },
        { title: 'Descripción', property: 'Description' },
        { title: 'Estado', property: 'sh se ' }
    ]


    useEffect(() => {
        // getPlan();
        getManagementTypeForIDLn();
        getLista();
    }, [idAdquisitionPlanFunctionalID, idPreparer])
    useEffect(() => {

        getManagementTypeForIDLn();


    }, [beanOficina])





    const renderEstados = (item: any) => {

        let render = item.DataBeanProperties.PhaseState;
        if (item.DataBeanProperties.PhaseState === 0) {
            render = item.DataBeanProperties.PlanningStateName;
        }
        else if (item.DataBeanProperties.PhaseState === 1) {
            render = item.DataBeanProperties.StructuringStateName;
        }
        else if (item.DataBeanProperties.PhaseState === 2) {
            render = item.DataBeanProperties.HiringStateName;
        }
        return render;
    }

    const getManagementTypeForIDLn = () => {

        _single.spinner(false);
        if (beanOficina.IDLnFunctionalID > 0)
            service.getManagementTypeForIDLn(beanOficina.IDLnFunctionalID).subscribe(
                (resp: any) => {
                    //console.log(resp);

                    if (resp.DataBeanProperties.ObjectValue) {

                        let t = resp.DataBeanProperties.ObjectValue;
                        let lis = [];
                        if (t.length > 0) {
                            let primero = t[0].DataBeanProperties;
                            lis = primero.JsonType ? JSON.parse(primero.JsonType) : [];
                        }

                        setTypes(lis);


                        if (lis.length > 0)
                            getManagementTypeConstants(lis);
                    }
                }
            );

    }
    const getManagementTypeConstants = (listaTypes: any) => {
        // let listTypes = _single.getCacheItem('listTypes');
        // if (listTypes)
        //     setListaTypes(listTypes);
        // else {
        service.getManagementTypeConstants().subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue) {
                    let tmp = [];
                    let lista = resp.DataBeanProperties.ObjectValue;
                    lista.forEach(element => {
                        if (listaTypes.includes(element.DataBeanProperties.Value)) {
                            tmp.push(element)
                        }
                    });
                    setListaTypes(tmp);
                }
            }
        )
        // }

    }

    const validateValue = (item: any) => {
        const TotalValue = item.TotalValue;
        const TotalArticle = item.TotalArticle;
        let text = "";
        if (TotalArticle > TotalValue)
            text = "<span class='text-primary'>Bienes/Servicios superan el valor de la necesidad</span>";
        else if (TotalValue == 0)
            text = "<span class='text-primary'>Necesidad no tiene valor asignado</span>";

        return text;
    }

    const getLista = () => {

        //console.log(idPreparer, idAdquisitionPlanFunctionalID);


        if (idPreparer && idAdquisitionPlanFunctionalID) {

            setLoading(true);
            service.getAdquisitionItemByPreparer(idAdquisitionPlanFunctionalID, _single.getAccountID(), null, null).subscribe(
                (resp: any) => {
                    //console.log(resp)
                    if (resp.DataBeanProperties.ObjectValue.DataBeanProperties.List) {
                        let ll = resp.DataBeanProperties.ObjectValue.DataBeanProperties.List;


                        if (filterState) {
                            ll = ll.filter(ele => { return ele.DataBeanProperties.PreparationState === filterState })
                        }

                        if (filterStateArray) {
                            ll = ll.filter(ele => filterStateArray.includes(ele.DataBeanProperties.PreparationState));
                        }

                        ll.forEach((element: any) => {
                            element.DataBeanProperties.Estados = renderEstados(element);
                            element.DataBeanProperties.Note = validateValue(element.DataBeanProperties);

                        });

                        setLista(ll);
                    }

                    setLoading(false);
                }
            );
        } else {
            if (idAdquisitionPlanFunctionalID) {
                setLoading(true);
                service.getAdquisitionItemByFuncionalID(idAdquisitionPlanFunctionalID, null, null, null).subscribe(
                    (resp: any) => {

                        if (resp.DataBeanProperties.ObjectValue.DataBeanProperties.List) {
                            let ll = resp.DataBeanProperties.ObjectValue.DataBeanProperties.List;


                            if (filterState) {
                                ll = ll.filter(ele => { return ele.DataBeanProperties.PreparationState === filterState })
                            }

                            if (filterStateArray && filterStateArray.length > 0) {
                                ll = ll.filter(ele => filterStateArray.includes(ele.DataBeanProperties.PreparationState));
                            }

                            ll.forEach((element: any) => {
                                element.DataBeanProperties.Estados = renderEstados(element);
                                element.DataBeanProperties.Note = validateValue(element.DataBeanProperties);

                            });

                            setLista(ll);
                        }

                        setLoading(false);
                    }
                );
            }
        }
    }

    const agregarItem = () => {
        let b = {
            IDAdquisitionPlanFunctionalID: idAdquisitionPlanFunctionalID,
            TypeManagment: 100,
            Description: '',
            EstimatedValue: 0,
            ItemOrder: 1,
            ExecutionMonth: 0,
            EstimatedMonths: 1,
            IDBudgetIDType: plan.IDBudgetIDType,
            IDLnBudgetID: 0,
            Justification: '',
            IDFunctionalIDPreparer: beanOficina.IDFunctionalIDPreparer != null ? beanOficina.IDFunctionalIDPreparer : 0,
        }
        setBean(b);
        setModalItem({ ...modalItem, "view": true, "name": 'Agregar Ítem' });
    }

    const updateState = (objeto: any) => {
        _single.spinner(true);
        service.changeAdquisitionItemApprovalNote(objeto.IDAdquisitionItem, _single.getAccountID(), obs, objeto.PreparationState).subscribe(
            (resp: any) => {
                setObs("")
                getLista();
                _single.spinner(false);
                setModalConfirm({ ...modalConfirm, "view": false });
                modalReturn.close();
                modalEstado.close();
                setModalAprobe({ ...modalAprobe, "view": false });
                setModalSend({ ...modalSend, "view": false });
            }
        )
    }

    const validateItem = (IDAdquisitionItem: number) => {
        _single.spinner(true);
        service.validateAdquisitionItem(IDAdquisitionItem).subscribe(
            (resp: any) => {
                console.log(resp)
            }
        )
    }

    const updateMItem = () => {
        _single.spinner(true)
        if (nueva)
            bean.TypeAdquisition = 101;
        else {
            bean.TypeAdquisition = 100;
        }
        service.updateAdquisitionItem(bean).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue) {
                    getLista();
                    setModalItem({ ...modalItem, "view": false });
                }
                _single.spinner(false);
            }
        )
    }

    const eliminarItem = () => {
        let obje = bean;
        obje.PreparationState = 8;
        updateState(bean);
    }

    const editarMItem = () => {
        setModalItem({ ...modalItem, "view": true, "name": 'Editar' });
    }

    const iconButtonsItem = [
        { nombre: 'Agregar', icono: 'ri-file-add-line', visible: single.canCreate(), evento: agregarItem }
    ]

    const validateCanCancel = () => {
        if (rol === ITEM_PREPARADOR) {
            const invalidStates = [40, 41, 42, 43, 7, 31];
            return single.canDelete() && !invalidStates.includes(bean.PreparationState);
        } else if (rol === ITEM_JEFE) {
            const invalidStates = [40, 41, 42, 43, 7, 8];
            return single.canDelete() && !invalidStates.includes(bean.PreparationState);
        } else {
            const invalidStates = [8]
            return single.canDelete() && !invalidStates.includes(bean.PreparationState);
        }
    };

    const validateCanEdit = () => {
        const validStates = [30, 27, 9];
        return single.canEdit() && validStates.includes(bean.PreparationState);
    }

    const validateSendBossRevision = () => {
        if (rol == ITEM_PREPARADOR) {
            const validStates = [30, 27, 9];
            return rol === ITEM_PREPARADOR && validStates.includes(bean.PreparationState);
        } else if (rol == ITEM_JEFE) {
            const validStates = [30, 27, 9];
            return rol === ITEM_JEFE && validStates.includes(bean.PreparationState);
        } else {
            return (false)
        }
    }

    const validateReturnPreparer = () => {
        if (rol == ITEM_JEFE) {
            const invalidStates = [4, 8]
            const validStates = [31];
            return rol === ITEM_JEFE && !invalidStates.includes(bean.PreparationState) && validStates.includes(bean.PreparationState);
        } else if (rol == ITEM_ADMIN) {
            const invalidStates = [4, 8]
            const validStates = [31];
            return rol === ITEM_ADMIN && !invalidStates.includes(bean.PreparationState) && validStates.includes(bean.PreparationState);
        } else {
            return (false)
        }
    }

    const listaBotonesItems = () => {

        const validStates = [];

        const invalidStates = [8];

        const botones = [
            { titulo: 'Editar', icono: 'ri-edit-2-line', visible: validateCanEdit(), evento: editarMItem },
            { titulo: 'Cancelar', icono: 'ri-delete-bin-2-line', visible: validateCanCancel(), evento: () => { setModalConfirm({ ...modalConfirm, 'view': true }) } },
            { titulo: 'Rubros', icono: 'ri-git-branch-line', visible: !invalidStates.includes(bean.PreparationState), evento: () => { setModalRubro({ ...modalRubro, "view": true }) } },
            { titulo: 'Bien/Servicio', icono: 'ri-file-list-line', visible: true, evento: () => { setModalArticulo({ ...modalArticulo, 'view': true }) } },
            { titulo: 'Cambiar estado', icono: 'ri-exchange-line', visible: rol === ITEM_ADMIN, evento: () => { modalEstado.open() } },
            { titulo: 'Devolver a Preparador', icono: 'ri-arrow-go-back-line', visible: validateReturnPreparer(), evento: () => { modalReturn.open() } },
            { titulo: 'Equipo de trabajo', icono: 'bx bxs-user-check', visible: (rol === ITEM_ADMIN && !invalidStates.includes(bean.PreparationState)), evento: () => { setModalAnalist({ ...modalAnalyst, "view": true, "name": `Equipo de Trabajo ${bean.IDAdquisitionItem ? `de la Necesidad Identificada con ID ${bean.IDAdquisitionItem}` : ""}` }) } },
            { titulo: 'Ver histórico de aprobaciones', icono: 'ri-file-history-line', visible: true, evento: () => { seeHisotryApproval() } },
            { titulo: 'Enviar a Responsable del Área para revisión', icono: 'ri-mail-send-line', visible: validateSendBossRevision(), evento: () => { setModalSend({ ...modalSend, "view": true }) } },
            { titulo: 'Aprobar Estructuracion del Item', icono: 'bx bx-check', visible: (rol == ITEM_JEFE && bean.PreparationState == 31), evento: () => { setModalAprobe({ ...modalAprobe, "view": true }) } },
        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }

    const aprobarItem = () => {
        setLoading(true);
        let b = bean;
        b.PreparationState = 4;
        updateState(b)
    }

    const seeHisotryApproval = () => {
        setModalHistorico({ ...modalHistorico, "view": true });
        setLoading(true);
        service.getAdquisitionItemApprovalNoteCatalog(bean.IDAdquisitionItem, null, null, null).subscribe(
            (resp: any) => {
                //console.log(resp);
                if (resp.DataBeanProperties.ObjectValue.DataBeanProperties.List) {
                    setHistorico(resp.DataBeanProperties.ObjectValue.DataBeanProperties.List);
                }
                setLoading(false);
                single.makeAcction("El usuario consultó el histórico de aprobaciones del ítem " + (bean.IDAdquisitionItem), bean);
            }
        )
    }

    const sendItemApporval = () => {
        setLoading(true);
        let obje = bean;
        obje.PreparationState = 31;
        updateState(bean);
    }

    const setRow = (item: any) => {
        setBean(item);
        if (selectRow)
            selectRow(item);

    }

    return (
        <>
            <BasicTable iconButtons={iconButtonsItem} listButtons={listaBotonesItems()} head={head} body={lista} rowSet={setRow}></BasicTable>
            {/* adquisitionItem debe recibir el IDAdquisitionPlanFunctionalID*/}
            {modalItem.view &&
                <Modal modal={modalItem} updateModal={setModalItem} eventModal={updateMItem}>

                    <div className="row">
                        {ListaTypes.length > 0 &&
                            <div className="col-md-6">
                                <label>Tipo de Necesidad</label>
                                <select name="type" className="form-select" value={bean.TypeManagment} onChange={(e) => { setBean({ ...bean, "TypeManagment": parseInt(e.target.value) }) }}>
                                    {ListaTypes.map((item) => {
                                        return (
                                            <option value={item.DataBeanProperties.Value}>{item.DataBeanProperties.Property}</option>
                                        )
                                    })}
                                </select>
                            </div>
                        }
                        {/* convenios */}
                        {bean.TypeManagment === 101 &&
                            <div className="col-md-6">
                                <IDAdquisitionProject idSelector={bean.IDProject} onChange={(e) => { setBean({ ...bean, "IDProject": e }) }} />
                            </div>
                        }
                        {rol !== ITEM_PREPARADOR &&
                            <div className="col-md-6">
                                <SelectPreparadorCatalog key={token} idAdquisitionPlanFunctionalID={bean.IDAdquisitionPlanFunctionalID} idSelector={bean.IDFunctionalIDPreparer != null ? bean.IDFunctionalIDPreparer : 0}
                                    onChange={(id: number) => { setBean({ ...bean, "IDFunctionalIDPreparer": id }) }}
                                />
                            </div>
                        }
                        <div className="col-md-6">
                            <label>Valor Estimado</label>
                            <InputMoney readonly={(bean.IDAdquisitionItem >= 0 && bean.PhaseState == 2)} id={0} name='Valor Estimado' value={bean.TotalValue} onChange={(e: any) => { setBean({ ...bean, ['TotalValue']: parseInt(e) }) }} />
                        </div>
                        <div className="col-md-6">
                            <SelectMonths idSelector={bean.ExecutionMonth} onChange={(e: number) => { setBean({ ...bean, "ExecutionMonth": e }) }}></SelectMonths>
                        </div>
                        <div className="col-md-6">
                            <label>Meses Estimados</label>
                            <input type="number" className="form-control" value={bean.EstimatedMonths} onChange={(e) => { setBean({ ...bean, "EstimatedMonths": e.target.value }) }} />
                        </div>
                        {/* <div className="col-md-6">
                            <SelectBudgetResource idSelector={bean.IDBudgetResource} onChange={(e: any) => {
                                setBean({ ...bean, "IDBudgetResource": e })
                            }}></SelectBudgetResource>
                        </div> */}
                        <div className="col-md-6">
                            <SelectModalityNewSer idSelector={bean.IDHiringModality} onChange={(e) => { setBean({ ...bean, "IDHiringModality": e }) }} />
                        </div>
                        <div className="col-md-6">
                            <SelectUnspscID label="Código Principal" renderValue="" onChange={(e) => { setBean({ ...bean, "IDLnUnspscItem": e.IDLn }) }} />
                        </div>
                        <div className="col-md-12">
                            <SelectProjectID projectIDLn={bean.IDLnProjectID} edit={false} onChange={(e: any) => { setBean({ ...bean, "IDLnProjectID": e.IDLn }) }} />
                        </div>

                        <div className="col-md-12">
                            <label >Justificación</label>
                            <textarea className="form-control" placeholder='Incluya los aspectos legales, procedimentales, funcionales que sustentan la solicitud'
                                value={bean.Justification != null ? bean.Justification : ''} onChange={(e) => { setBean({ ...bean, "Justification": e.target.value }) }}></textarea>
                        </div>
                        <div className="col-md-12">
                            <label >Objeto de la Necesidad</label>
                            <textarea className="form-control" value={bean.Description} onChange={(e) => { setBean({ ...bean, "Description": e.target.value }) }}></textarea>
                        </div>
                    </div>
                </Modal>
            }

            {/* articulos de la necesidad  */}
            {modalArticulo.view &&
                <Modal modal={modalArticulo} updateModal={setModalArticulo}>
                    <div className="row">
                        <div className="col-md-12">
                            <AdquisitionArticle key={token} adquisitionItem={bean} rol={rol} updateFather={() => { getLista() }} beanItem={bean}></AdquisitionArticle>

                        </div>
                    </div>
                </Modal>
            }

            {/* rubros de la necesidad  */}
            {modalRubro.view &&
                <Modal modal={modalRubro} updateModal={setModalRubro} eventModal={() => { }}>
                    <div className="row">
                        <div className="col">

                            <AdquisitionItemBudgetID CanModify={bean.PreparationState} adquisitionItem={bean} iDBudgetIDType={bean.IDBudgetIDType} estimateValue={bean.TotalValue} onChange={getLista} />
                        </div>
                    </div>
                </Modal>
            }

            {/* estados de la necesidad */}
            <Modal modal={modalEstado.modal} updateModal={modalEstado.setModal} eventModal={() => { updateState(bean) }}>
                <div className="row">
                    <div className="col-md-6">
                        <SelectAdquisitionItemConstan idSelector={bean.PreparationState} onChange={(e) => { setBean({ ...bean, "PreparationState": e }) }} />
                    </div>
                    <div className="col-md-12">
                        <label>Observaciones</label>
                        <textarea name="obs" className="form-control" value={obs} onChange={(e) => { setObs(e.target.value) }}></textarea>
                    </div>
                </div>
            </Modal>

            <Modal modal={modalReturn.modal} updateModal={modalReturn.setModal} eventModal={() => { updateState(bean) }}>
                <div className="row">
                    <div className="col-md-6">
                        <SelectAdquisitionItemConstan idSelector={bean.PreparationState} onChange={(e) => { setBean({ ...bean, "PreparationState": e }) }} filter={[27, 9]} />
                    </div>
                    <div className="col-md-12">
                        <label>Observaciones</label>
                        <textarea name="obs" className="form-control" value={obs} onChange={(e) => { setObs(e.target.value) }}></textarea>
                    </div>
                </div>
            </Modal>

            <Modal modal={modalAnalyst} updateModal={setModalAnalist} eventModal={() => { setModalAnalist({ ...modalAnalyst, "view": false }); getLista() }}>
                <OfferEmployee IDAdquisitionItem={bean.IDAdquisitionItem} />
            </Modal>

            {modalConfirm.view &&
                <ModalConfirm modal={modalConfirm} updateModal={setModalConfirm} eventModal={eliminarItem}>
                    {bean.Description}
                </ModalConfirm>
            }

            {modalAprobe.view &&
                <Modal modal={modalAprobe} updateModal={setModalAprobe} eventModal={aprobarItem} >
                    <p>Cambiar el item a estado aprobado ? </p>
                    <div className="row">
                        <div className="col-md-12">
                            <label>Observaciones</label>
                            <textarea className="form-control" value={obs} onChange={(e) => { setObs(e.target.value) }}></textarea>
                        </div>
                    </div>
                </Modal>
            }

            {modalHistorico.view &&
                <Modal modal={modalHistorico} updateModal={setModalHistorico} >
                    <BasicTable head={headHistorico} body={listaHistorico} rowSet={() => { }}></BasicTable>
                </Modal>
            }

            {modalSend.view &&
                <Modal modal={modalSend} updateModal={setModalSend} eventModal={sendItemApporval} >
                    <div className="row">
                        <div className="col-md-12">
                            <label>Observaciones</label>
                            <textarea className="form-control" value={obs} onChange={(e) => { setObs(e.target.value) }}></textarea>
                        </div>
                    </div>
                </Modal>
            }

            {modalInfoArticles.view &&
                <Modal modal={modalInfoArticles} updateModal={setModalInfoArticles} >
                    <ChartArea lista={listaGra} maxRang={100} titulo="Estados" tipo="bar"></ChartArea>
                </Modal>
            }
        </>
    )
}