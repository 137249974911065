import { useEffect, useState } from "react";
import { _Entailment } from "../../../../core/services/dataBean/EntityCatalog"
import useDataBean from "../../../../core/services/dataBean/useDataBean"
import { BasicPage } from "../../../theme/Component/BasicPage"
import { BasicTable } from "../../../theme/Component/BasicTable";
import { ListMenu } from "../../../theme/Component/ListMenu";
import { Modal } from "../../../theme/Component/Modal";
import { Singleton } from '../../../../core/services/Singleton';
import { toast } from "react-toastify";
import { _ImportedPayrollBeneficiary } from "../../../../core/services/dataBean/EntityCatalogVdos";
import { WelfareEntityTypeEditor } from "../EmployeeBonus/Editors/WelfareEntityType.Editor";
import { WelfareEntityEditor } from "../../GestionTH/EmployeeContract/ComponentsContract/EditorsEmployeContrac/WelfareEntity.editor";
import { AccountEditor } from "../../../editor/AccountEditor";
import { WelfareEntityTypeConstantsSelector } from "./WelfareEntityTypeConstants";
import { PrintJson } from "../../../theme/Component/PrintJson";


export const ImportedPayrollBeneficiary = () => {

    const { bean, setBean, lista, setLista, instance, handleChange, update } = useDataBean(_ImportedPayrollBeneficiary);
    const single = Singleton.getInstance();

    const [modal, setModal] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-md' });

    useEffect(() => {
        getLista();
    }, [])

    const getLista = () => {
        instance.getImportedPayrollBeneficiaryCatalog(null).then(
            (resp: any) => {
                if (resp) {
                    setLista(resp)
                }
            }
        )

    }


    const editaItem = () => {
       //console.log(bean);
        update().then(
            (resp: any) => {
                getLista();
                setModal({ ...modal, "view": false });
            }
        ).catch(err => {
            toast.error(err);
        })

    }

    const head = [
        { title: 'ID', property: 'IDImportedPayrollBeneficiary', visible: true },
        { title: 'Nombre del Beneficiario', property: 'Name', visible: true },
        { title: 'Tercero Homologado', property: 'AccountName', visible: true },
        { title: 'Tipo de Entidad', property: 'WelfareEntityType', visible: true },
        { title: 'Entidad', property: 'WelfareEntityCode', visible: true },
    ]


    const listaBotones = () => {
        const botones = [
            { titulo: 'Editar', icono: 'ri-edit-2-line', visible: single.canEdit(), evento: () => { setModal({ ...modal, "name": `Editar ${bean.Name}`, "view": true }) } },
        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }


    return (
        <>
            <BasicPage titulo="Tipos de Beneficiarios de Nominas Importadas">
                <BasicTable body={lista} head={head} rowSet={setBean} listButtons={listaBotones()} ></BasicTable>
            </BasicPage>

            {modal.view &&
                <Modal modal={modal} updateModal={setModal} eventModal={editaItem}>
                    <div className="row">

                        <div className="col-12">
                            <WelfareEntityTypeConstantsSelector idSelector={bean.Type} onChange={(e) => { setBean({ ...bean, "Type": e }) }} label="Tipo de Entidad" />
                        </div>

                        {bean.Type !== 8 &&
                            <div className="col-12">
                                <WelfareEntityEditor
                                    entity={bean.Type}
                                    idSelector={bean.IDWelfareEntity}
                                    onChange={(e) => {
                                        setBean({ ...bean, "IDWelfareEntity": e });
                                    }}
                                    label="Entidad"
                                />
                            </div>
                        }

                        {bean.Type == 8 &&
                            <div className="col-12">
                                <AccountEditor label="Tercero Homologado" idAccount={bean.IDAccount} onChange={(e) => { setBean({ ...bean, "IDAccount": e.IDAccount }) }}></AccountEditor>
                            </div>

                        }

                    </div>
                </Modal>
            }
        </>
    )
}