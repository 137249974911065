import { useEffect, useState } from "react";
import { _CompetitionType, _EmployeePayments, _Entailment, _MacroProcess, _ViaticumPaymentsTypeType } from "../../../../core/services/dataBean/EntityCatalog";

import { Singleton } from "../../../../core/services/Singleton";
import { toast } from "react-toastify";
import { ListMenu } from "../../../theme/Component/ListMenu";
import { BasicPage } from "../../../theme/Component/BasicPage";
import { BasicTable } from "../../../theme/Component/BasicTable";
import { Modal } from "../../../theme/Component/Modal";
import { ModalConfirm } from "../../../theme/Component/ModalConfirm";
import useDataBean from "../../../../core/services/dataBean/useDataBean";
import { SelectAccountantTree } from "../../../contabilidad/catalogo-cuentas/AccountantTree/SelectAccountantTree";

import { TaxHoldeditor } from "../EmployeePayments/EmployeePayments.editor";
import { EmployeePaymentsUnits } from "../EmployeePayments/EmployeePaymentsUnits.editor";
import { SelectBudgetID } from "../../../admin/configuracion/BudgetID/SelectBudgetID";





export const ViaticumPaymentsTypeType = () => {

    const ViaticumPaymentsTypeType = useDataBean(_ViaticumPaymentsTypeType);

    const single = Singleton.getInstance();

    const [lista, setLista] = useState<any>([]);
    const [modal, setModal] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-md' });
    const [modalC, setModalC] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });

    useEffect(() => {
        getLista();

    }, [])

    const getLista = () => {

        ViaticumPaymentsTypeType.instance.getViaticumPaymentsTypeTypeCatalog().then(
            (resp: any) => {
                if (resp) {
                    setLista(resp)
                   //console.log(resp)
                }
            }
        )

    }

    const create = () => {
        ViaticumPaymentsTypeType.setBean({

        });
        setModal({ ...modal, "name": "Agregar", "view": true });
    }

    const deleteItem = () => {
        ViaticumPaymentsTypeType.instance.deleteViaticumPaymentsTypeType(ViaticumPaymentsTypeType.bean.IDViaticumPaymentsType).then(
            (resp: any) => {


                getLista();
                setModalC({ ...modalC, "view": false });
            }
        )

    }

    const editaItem = () => {
       //console.log(ViaticumPaymentsTypeType.bean);
        ViaticumPaymentsTypeType.instance.updateViaticumPaymentsTypeType(ViaticumPaymentsTypeType.bean).then(
            (resp: any) => {
                getLista();
                setModal({ ...modal, "view": false });
            }
        ).catch(err => {
            toast.error(err);
        })

    }

    const head = [
        { title: 'ID', property: 'IDViaticumPaymentsType', visible: true },
        { title: 'Nombre', property: 'Name', visible: true },
        { title: 'Mnemonico', property: 'Code', visible: true },
        { title: 'Cuenta Débito', property: 'AccountantIDName', visible: true },
        { title: 'Factor Salarial', property: 'WageFactor', visible: true },
        { title: 'Tipo de presupuesto', property: 'BudgetIDStr', visible: true },
        { title: 'Factor de Retención', property: 'TaxHoldDescription', visible: true },
        { title: 'Cuenta Crédito', property: 'CreditAccountantIDName', visible: true },
        { title: 'Horas Base', property: 'BaseHours', visible: true },
        { title: 'Unidades', property: 'UnitsType', visible: true },
        { title: 'Aplicar Presupuesto', property: 'ApplyBudget', visible: true },
        { title: 'Retefuente', property: 'DoTaxHold', visible: true },


    ]

    const iconButtonsItem = [
        { nombre: 'Agregar', visible: single.canCreate(), icono: 'ri-file-add-line', evento: create }
    ]
    const listaBotones = () => {
        const botones = [

            { titulo: 'Editar', icono: 'ri-edit-2-line', visible: single.canEdit(), evento: () => { setModal({ ...modal, "view": true }) } },
            { titulo: 'Eliminar', icono: 'ri-delete-bin-line', visible: single.canDelete(), evento: () => { setModalC({ ...modalC, "view": true }) } },

        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }


    return (
        <BasicPage titulo="Conceptos de Viáticos">
            <div className="row">
                <div className="col-md-12">

                    <BasicTable body={lista} head={head} rowSet={ViaticumPaymentsTypeType.setBean} listButtons={listaBotones()} iconButtons={iconButtonsItem}></BasicTable>
                    <Modal modal={modal} updateModal={setModal} eventModal={editaItem}>
                        <div className="row">
                            <div className="col-md-6">
                                <label>Nombre</label>
                                <input type="text" name="Name" className="form-control" value={ViaticumPaymentsTypeType.bean.Name} onChange={ViaticumPaymentsTypeType.handleChange} />

                            </div>
                            <div className="col-md-6">
                                <label>Mnemonico </label>
                                <input type="text" name="Code" className="form-control" value={ViaticumPaymentsTypeType.bean.Code} onChange={ViaticumPaymentsTypeType.handleChange} />
                            </div>
                            <div className="col-md-6">
                                <label>Cuenta Débito</label>
                                <SelectAccountantTree label="Cuenta Contable" jsonCode={ViaticumPaymentsTypeType.bean.AccountantIDStr} onChange={(e) => { ViaticumPaymentsTypeType.setBean({ ...ViaticumPaymentsTypeType.bean, "AccountantIDStr": e.JsonCode }) }} />
                            </div>
                            <div className="col-md-6">
                                <label>Factor Salarial</label>
                                <input type="number" name="WageFactor" className="form-control" value={ViaticumPaymentsTypeType.bean.WageFactor} onChange={ViaticumPaymentsTypeType.handleChange} />
                            </div>
                            <div className="col-md-6">
                                <SelectBudgetID tipoPresupuesto={0} edit={false} onChange={(e: any) => { ViaticumPaymentsTypeType.setBean({ ...ViaticumPaymentsTypeType.bean, "BudgetIDStr": e.IDLn }) }} idBudgetID={ViaticumPaymentsTypeType.bean.BudgetIDStr} />
                            </div>
                            <div className="col-md-6">
                                <label>Factor de Retención</label>
                                <TaxHoldeditor idSelector={ViaticumPaymentsTypeType.bean.IDTaxHold} onChange={(e) => { ViaticumPaymentsTypeType.setBean({ ...ViaticumPaymentsTypeType.bean, "IDTaxHold": e }) }} />


                            </div>
                            <div className="col-md-6">
                                <label>Cuenta Crédito</label>
                                <SelectAccountantTree label="Cuenta Contable" jsonCode={ViaticumPaymentsTypeType.bean.CreditAccountantIDStr} onChange={(e) => { ViaticumPaymentsTypeType.setBean({ ...ViaticumPaymentsTypeType.bean, "CreditAccountantIDStr": e.JsonCode }) }} />
                            </div>
                            <div className="col-md-6">
                                <label>Horas Base</label>
                                <input type="text" name="BaseHours" className="form-control" value={ViaticumPaymentsTypeType.bean.BaseHours} onChange={ViaticumPaymentsTypeType.handleChange} />
                            </div>
                            <div className="col-md-6">
                                <label>Unidades</label>
                                <EmployeePaymentsUnits idSelector={ViaticumPaymentsTypeType.bean.UnitsType} onChange={(e) => { ViaticumPaymentsTypeType.setBean({ ...ViaticumPaymentsTypeType.bean, "UnitsType": e }) }} />

                            </div>
                            <div className="col-md-6">
                                <label>Aplicar Presupuesto</label>
                                <input type="number" name="ApplyBudget" className="form-control" value={ViaticumPaymentsTypeType.bean.ApplyBudget} onChange={ViaticumPaymentsTypeType.handleChange} />
                            </div>
                            <div className="col-md-6">
                                <label>Retefuente</label>
                                <input type="number" name="DoTaxHold" className="form-control" value={ViaticumPaymentsTypeType.bean.DoTaxHold} onChange={ViaticumPaymentsTypeType.handleChange} />
                            </div>
                        </div>
                    </Modal>
                    <ModalConfirm modal={modalC} updateModal={setModalC} eventModal={deleteItem}>
                        <strong>Eliminar item</strong>
                    </ModalConfirm>
                </div>

            </div>
        </BasicPage>
    )
}