import { useParams } from 'react-router-dom';
import { ProfileDashboard } from '../../pages/ProfileDashboard';
import { CreditCanpaign } from './components/CreditCampaign/CreditCampaign';
import { Credit } from './components/Credit/Credit';
import { SuppConstantsForpo } from '../../bpm/config/Componentes/Hooks/SuppConstants/SuppConstantsForpo';
import { StoreCategory } from './components/TiendaVirtual/StoreCategory';
import { StoreItem } from './components/TiendaVirtual/StoreItem';
import { Comiteroles } from '../../bpm/config/Componentes/Hooks/SuppConstants/Comiteroles';
import { Policy } from './components/Policy/Policy';
import { Portalterceros } from './components/Portalterceros/Portalterceros';
import { MediaSlide } from './components/MediaSlide/MediaSlide';
import { MediaSlideItem } from './components/MediaSlide/MediaSlideItem';
import { Intro } from './components/Intro/Intro';
import { InternalMenuItem } from './components/InternalMenu/InternalMenuItem';
import { InternalMenu } from './components/InternalMenu/InternalMenu';
import { Comite } from './components/ComitCredits/Comite';

export const ForpoRouter = (props: any) => {
  const { module, view }: any = useParams();

  const renderSwitch = (url: string) => {
    switch (url) {
      case "credit-campaing":
        return <CreditCanpaign />;
      case "credit":
        return <Credit />;
      case "comite":
        return <Comite />;
      case "parameters":
        return <SuppConstantsForpo />;
      case "portalterceros":
        return <Portalterceros />;
      case "category-store":
        return <StoreCategory />;
      case "item-store":
        return <StoreItem />;
      case "comite-roles":
        return <Comiteroles />;
      case "policy":
        return <Policy />;
      case "media-slide":
        return <MediaSlide />;
      // case "media-slide-items":
      //   return <MediaSlideItem />;
      case "introforpo":
        return <Intro />;
      case "internal-menu":
        return <InternalMenu />;
      // case "policy":
      //   return <Policy />;
      default:
        return <ProfileDashboard />;
    }
  };

  return (
    <>
      {renderSwitch(view)}
    </>
  )

}