import { useEffect, useState } from "react";
import { _CompetitionType, _EmployeePayments, _Entailment, _MacroProcess } from "../../../../core/services/dataBean/EntityCatalog";

import { Singleton } from "../../../../core/services/Singleton";
import { toast } from "react-toastify";
import { ListMenu } from "../../../theme/Component/ListMenu";
import { BasicPage } from "../../../theme/Component/BasicPage";
import { BasicTable, MASK_NUMBER } from "../../../theme/Component/BasicTable";
import { Modal } from "../../../theme/Component/Modal";
import { ModalConfirm } from "../../../theme/Component/ModalConfirm";
import useDataBean from "../../../../core/services/dataBean/useDataBean";
import { SelectAccountantTree } from "../../../contabilidad/catalogo-cuentas/AccountantTree/SelectAccountantTree";
import { TaxHoldeditor } from "./EmployeePayments.editor";
import { EmployeePaymentsUnits } from "./EmployeePaymentsUnits.editor";
import { SelectBudgetID } from "../../../admin/configuracion/BudgetID/SelectBudgetID";
import { BasicSwitch } from "../../../theme/Component/BasicSwitch";




export const EmployeePayments = () => {

    const EmployeePayments = useDataBean(_EmployeePayments);

    const single = Singleton.getInstance();

    const [lista, setLista] = useState<any>([]);
    const [modal, setModal] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-md' });
    const [modalC, setModalC] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });

    useEffect(() => {
        getLista();

    }, [])

    const getLista = () => {

        EmployeePayments.instance.getEmployeePaymentsTypeCatalog().then(
            (resp: any) => {
                if (resp) {
                    setLista(resp)
                   //console.log(resp)
                }
            }
        )

    }

    const create = () => {
        EmployeePayments.setBean({

        });
        setModal({ ...modal, "name": "Agregar", "view": true });
    }

    const deleteItem = () => {
        EmployeePayments.instance.deleteEmployeePaymentsType(EmployeePayments.bean.IDEmploymentBonus).then(
            (resp: any) => {
                getLista();
                setModalC({ ...modalC, "view": false });
            }
        )

    }

    const editaItem = () => {
       //console.log(EmployeePayments.bean);
        EmployeePayments.instance.updateEmployeePaymentsType(EmployeePayments.bean).then(
            (resp: any) => {
                getLista();
                setModal({ ...modal, "view": false });
            }
        ).catch(err => {
            toast.error(err);
        })

    }

    // const editaItem = () => {
    //    //console.log(EmployeePayments.bean);
    //     EmployeePayments.update().then(
    //         (resp: any) => {
    //             getLista();
    //             setModal({ ...modal, "view": false });
    //         }
    //     ).catch(err => {
    //         toast.error(err);
    //     })

    // }

    const head = [
        { title: 'ID', property: 'IDEmploymentBonus', visible: true },
        { title: 'Nombre', property: 'Name', visible: true },
        { title: 'Mnemonico', property: 'Code', visible: true },
        { title: '%', property: 'Percent', visible: true, mask: MASK_NUMBER },
        { title: 'Cuenta Débito', property: 'AccountantID', visible: true, mask: MASK_NUMBER },
        { title: 'Factor Salarial', property: 'WageFactor', visible: true },
        { title: 'Factor de Retención', property: 'IDTaxHold', visible: true },
        { title: 'Cuenta Crédito', property: 'CreditAccountantID', visible: true, mask: MASK_NUMBER },
        { title: 'Horas Base', property: 'BaseHours', visible: true },
        { title: 'Unidades', property: 'UnitsType', visible: true },
        { title: 'Aplicar Presupuesto', property: 'ApplyBudget', visible: true },
        { title: 'Retefuente', property: 'DoTaxHold', visible: true },
    ]

    const iconButtonsItem = [
        { nombre: 'Agregar', visible: single.canCreate(), icono: 'ri-file-add-line', evento: create }
    ]
    const listaBotones = () => {
        const botones = [

            { titulo: 'Editar', icono: 'ri-edit-2-line', visible: single.canEdit(), evento: () => { setModal({ ...modal, "view": true }) } },
            { titulo: 'Eliminar', icono: 'ri-delete-bin-line', visible: single.canDelete(), evento: () => { setModalC({ ...modalC, "view": true }) } },

        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }


    return (
        <BasicPage titulo="Conceptos de Ingresos Laborales">
            <div className="row">
                <div className="col-md-12">

                    <BasicTable body={lista} head={head} rowSet={EmployeePayments.setBean} listButtons={listaBotones()} iconButtons={iconButtonsItem}></BasicTable>
                    <Modal modal={modal} updateModal={setModal} eventModal={editaItem}>
                        <div className="row">
                            <div className="col-md-6">
                                <label>Nombre</label>
                                <input type="text" name="Name" className="form-control" value={EmployeePayments.bean.Name} onChange={EmployeePayments.handleChange} />

                            </div>
                            <div className="col-md-6">
                                <label>Mnemonico </label>
                                <input type="text" name="Code" className="form-control" value={EmployeePayments.bean.Code} onChange={EmployeePayments.handleChange} />
                            </div>
                            <div className="col-md-6">
                                <label>%</label>
                                <input type="text" name="Percent" className="form-control" value={EmployeePayments.bean.Percent} onChange={EmployeePayments.handleChange} />
                            </div>
                            <div className="col-md-6">
                                <label>Cuenta Débito</label>
                                <SelectAccountantTree label="Cuenta Contable" jsonCode={EmployeePayments.bean.AccountantID} onChange={(e) => { EmployeePayments.setBean({ ...EmployeePayments.bean, "AccountantID": e.JsonCode }) }} />
                            </div>
                            <div className="col-md-6">
                                <label>Factor Salarial</label>
                                <BasicSwitch label="Activo" estado={EmployeePayments.bean.WageFactor} eventChange={(e) => { EmployeePayments.setBean({ ...EmployeePayments.bean, "WageFactor": e }) }} />
                            </div>
                            <div className="col-md-6">
                                <SelectBudgetID tipoPresupuesto={0} idBudgetID={EmployeePayments.bean.BudgetIDStr} edit={false} onChange={(e: any) => { EmployeePayments.setBean({ ...EmployeePayments.bean, "BudgetIDStr": e.IDLn }) }} />
                            </div>
                            <div className="col-md-6">
                                <label>Factor de Retención</label>
                                <TaxHoldeditor idSelector={EmployeePayments.bean.IDTaxHold} onChange={(e) => { EmployeePayments.setBean({ ...EmployeePayments.bean, "IDTaxHold": e }) }} />

                            </div>
                            <div className="col-md-6">
                                <label>Cuenta Crédito</label>
                                <SelectAccountantTree label="Cuenta Contable" jsonCode={EmployeePayments.bean.CreditAccountantID} onChange={(e) => { EmployeePayments.setBean({ ...EmployeePayments.bean, "CreditAccountantID": e.JsonCode }) }} />
                            </div>
                            <div className="col-md-6">
                                <label>Horas Base</label>
                                <input type="text" name="BaseHours" className="form-control" value={EmployeePayments.bean.BaseHours} onChange={EmployeePayments.handleChange} />
                            </div>
                            <div className="col-md-6">
                                <label>Unidades</label>
                                <EmployeePaymentsUnits idSelector={EmployeePayments.bean.UnitsType} onChange={(e) => { EmployeePayments.setBean({ ...EmployeePayments.bean, "UnitsType": e }) }} />

                            </div>
                            <div className="col-md-6">
                                <label>Aplicar Presupuesto</label>
                                <BasicSwitch label="Activo" estado={EmployeePayments.bean.ApplyBudget} eventChange={(e) => { EmployeePayments.setBean({ ...EmployeePayments.bean, "ApplyBudget": e }) }} />

                            </div>
                            <div className="col-md-6">
                                <label>Retefuente</label>
                                <BasicSwitch label="Activo" estado={EmployeePayments.bean.DoTaxHold} eventChange={(e) => { EmployeePayments.setBean({ ...EmployeePayments.bean, "DoTaxHold": e }) }} />
                            </div>
                        </div>
                    </Modal>
                    <ModalConfirm modal={modalC} updateModal={setModalC} eventModal={deleteItem}>
                        <strong>Eliminar item</strong>
                    </ModalConfirm>
                </div>

            </div>
        </BasicPage>
    )
}