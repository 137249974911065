import { useState, useEffect } from 'react';
import useDataBean from '../../../../../core/services/dataBean/useDataBean';
import { _EmployeeBonus } from '../../../../../core/services/dataBean/EntityCatalog';

interface Props {
    idSelector: number;
    onChange: any;
    label?: string;
}

export const EmployeeBonusTypeConstatsEditor = ({ idSelector, onChange, label }: Props) => {

    const employeeBonus = useDataBean(_EmployeeBonus)
    const [lista, setLista] = useState<any>([])
    const [id, setID] = useState(null);

    useEffect(() => {
        setID(idSelector);
        getLista();
    }, [])

    useEffect(() => {
        onChange(id);
    }, [id])

    const setValue = (e: any) => {
        setID(parseInt(e));
        onChange(id);
    }

    const getLista = () => {
        employeeBonus.instance.getEmployeeBonusTypeConstants(null).then(
            (resp) => {
                setLista(resp)
            }
        )
    }

    return (
        <>
            <label>{label ?? "Tipos de Bonificaciones"}</label>
            <select className="form-select" value={id} onChange={(e) => { setValue(e.target.value) }}>
                <option value={null} >...</option>
                {lista &&
                    lista.map((item: any, index: number) => {
                        return (
                            <option key={index} value={item.DataBeanProperties.Value} >{item.DataBeanProperties.Property}</option>
                        )
                    })
                }
            </select>
        </>
    )
}