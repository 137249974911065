import { useEffect, useState } from "react";

interface Props {
    component: any;
    setClose?: boolean;
}
export const FilterModal = ({ component, setClose }: Props) => {

    const [view, setView] = useState(false);

    useEffect(() => {
        if (setClose)
            setView(false);
    }, [setClose])

    return (
        <>
            <button title='Filtros' type="button" className="btn btn-outline btn-icon">
                <i className="ri-filter-2-line m2" onClick={() => { setView(!view) }}></i>
                <div className="position-relative">
                    {view &&
                        <div className="position-absolute m-3 FilterTab">
                            <div style={{ padding: '3%' }}>
                                <div className="row">
                                    <div className="col-md-5"></div>
                                    <div className="col-md-6"></div>
                                    <div className="col-md-1"><i className=" ri-close-line cursor" style={{ fontSize: '1rem' }} onClick={() => { setView(false) }} title="Cerrar"></i></div>
                                </div>

                                <hr />
                                {component}
                            </div>
                        </div>
                    }
                </div>
            </button>
        </>

    )
}