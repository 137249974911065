import { DataBean } from "../DataBean";

export class BankingEntity extends DataBean {


    public async getBankingEntityCatalog(code: any): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_getBankingEntityCatalog_String", [code]);
    }

    public async updateBankingEntity(bean: any): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "admin.BankingEntity_updateBankingEntity_admin.BankingEntity", [
            {
                "DataBeanProperties": bean,
                "DataBeanName": "admin.BankingEntity"
            }
        ]);
    }

    public async deleteBankingEntity(bean): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "void_deleteBankingEntity_Number", [
            {
                "DataBeanProperties": bean,
                "DataBeanName": "admin.BankingEntity"
            }
        ]);
    }
}