import { DataBean } from "../DataBean";

export class TaxHold extends DataBean {


    public async getTaxHoldCatalog(): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_getTaxHoldCatalog_Number", [null]);
    }


}