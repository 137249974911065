import { useEffect, useState } from "react";
import { ContractService } from "../../../../../core/services/ContractService";
import { Singleton } from "../../../../../core/services/Singleton";
import { PrintJson } from "../../../../theme/Component/PrintJson";
import { BasicTable } from "../../../../theme/Component/BasicTable";
import { SCHEMA_IMPUESTO, SCHEMA_RETENCION } from "../ContracInterface";

interface Props{
    idTaxScheme:number|null;
    idUBLItemInvoice:number;
    issueDate:Date;
    TypeSheme:number;
}
export const TaxSchemeValueList =({idTaxScheme,idUBLItemInvoice,issueDate,TypeSheme}:Props)=>{


    const single = Singleton.getInstance();
    const contracService = new ContractService();
    const [lista,setLista] = useState<any>([]);
    const [row,setRow] = useState<any>({});

    useEffect(()=>{

        if(TypeSheme === SCHEMA_IMPUESTO)
            getUBLItemInvoiceCatalog();
        if(TypeSheme === SCHEMA_RETENCION)
            getTaxSchemaValuesWithHoldingTaxForUBLInvoiceItem();

    },[idUBLItemInvoice])

    
    const getUBLItemInvoiceCatalog=()=>{
        single.spinner(true);
        contracService.getTaxSchemaValuesForUBLInvoiceItem(idTaxScheme,idUBLItemInvoice,issueDate).subscribe(
            (resp:any)=>{

                if(resp.DataBeanProperties.ObjectValue)
                {
                    setLista(resp.DataBeanProperties.ObjectValue);
                }

                single.spinner(false);
            }
        )
    }
    const getTaxSchemaValuesWithHoldingTaxForUBLInvoiceItem=()=>{
        single.spinner(true);
        contracService.getTaxSchemaValuesWithHoldingTaxForUBLInvoiceItem(idTaxScheme,idUBLItemInvoice,issueDate).subscribe(
            (resp:any)=>{

                if(resp.DataBeanProperties.ObjectValue)
                {
                    setLista(resp.DataBeanProperties.ObjectValue);
                }

                single.spinner(false);
            }
        )
    }

    const head = [
        {title:'ID',property:'IDTaxSchemeValue',visible:true},
        {title:'Valor (%)',property:'TaxValue',visible:true},
        {title:'Unidades',property:'ValueTypeName',visible:true},
        {title:'Impuesto',property:'TaxSchemeName',visible:true},
        {title:'Aplicado',property:'IsSelected',visible:true},
        
            
    ]


    return(
        <div className="row">
            <div className="col">
            <BasicTable body={lista} head={head} rowSet={setRow} ></BasicTable>
            </div>
        </div>
    )
}