import { useEffect, useState } from "react";
import { SelectStoreCategory } from "../../../../bpm/config/Componentes/SelectStoreCategory";
import { BasicPage } from "../../../../theme/Component/BasicPage";
import { BasicTable } from "../../../../theme/Component/BasicTable";
import { FileInput } from "../../../../theme/Component/FileInput";
import { ListMenu } from "../../../../theme/Component/ListMenu";
import { Modal } from "../../../../theme/Component/Modal";
import { InputDate } from "../../../../theme/Input/InputDate";
import { InputMoney } from "../../../../theme/Input/InputMoney";
import { useStoreItem } from "./useStoreItem";
import { Doc } from "../../../../../interfaces";
import { FileService } from "../../../../../core/services/FileService";
import './store.styles.css'
import { ModalConfirm } from "../../../../theme/Component/ModalConfirm";
import { Inventory } from "../../../../bpm/config/Componentes/Inventory";
import { toast } from "react-toastify";
import { SelectorTypeForm } from "../../../../bpm/config/Componentes/SelectorTypeForm";

const _serviceFile = new FileService()

export const StoreItem = () => {
    const {
        lista,
        head,
        setRowBean,
        modal,
        modalConfirm,
        modalinventario,
        setModalinventario,
        setModal,
        setModalConfirm,
        single,
        updateItem,
        bean,
        setBean,
        iconButtonsItem,
        idStore,
        setIDStore,
        eliminarItem,
    } = useStoreItem();

    const [select, setSelect] = useState<string>("");

    const idBean = "IDStoreItem";

    const listaBotones = () => {
        const botones = [
            { titulo: "Editar", icono: "ri-file-list-line", visible: single.canEdit(), evento: () => { setModal({ ...modal, view: true, name: "Editar" }) } },
            { titulo: "Eliminar", icono: "ri-file-user-line", visible: single.canDelete(), evento: () => { setModalConfirm({ ...modalConfirm, view: true }) } },
            { titulo: "Inventario", icono: "ri-file-user-line", visible: single.canEdit(), evento: () => { setModalinventario({ ...modalinventario, view: true }) } },
        ];
        return <ListMenu listaBotones={botones} />;
    };

    const [photos, setPhotos] = useState<Doc[]>([])

    useEffect(() => {
        if (bean.IDStoreItem >= 0) {
            if (bean.MediaListJSON) {
                let imgs = JSON.parse(bean.MediaListJSON)
                setPhotos(imgs);
            } else {
                toast.error("No se pudo cargar la lista de images");
            }
        }
    }, [bean.IDStoreItem])


    const handleUpload = (item: Doc) => {
        setPhotos([...photos, {
            MediaContext: item.MediaContext,
            Media: item.Media
        }])
        const arr = [...photos, item]
        setBean({ ...bean, MediaList: JSON.stringify(arr) })
    }

    return (
        <>
            <BasicPage titulo="Items de los Convenios">
                <div className="row">
                    <div className="col-md-6">
                        <SelectStoreCategory idSelector={idStore} onChange={setIDStore}></SelectStoreCategory>
                    </div>
                    {/* <div className="col-6">
                        <SelectorTypeForm value={""} onChange={(e: string) => } />
                    </div> */}
                    <div className="col-md-12">
                        <BasicTable listButtons={listaBotones()} iconButtons={iconButtonsItem} head={head} body={lista} rowSet={setRowBean} />
                    </div>
                </div>
            </BasicPage>
            <Modal modal={modal} updateModal={setModal} eventModal={updateItem}>
                <div className="row">
                    <div className="col-md-6">
                        <label className="form-label">Nombre: </label>
                        <input type="text" className="form-control" value={bean.Name} onChange={(e) => { setBean({ ...bean, 'Name': e.target.value }) }} />
                    </div>
                    <div className="col-md-6">
                        <label className="form-label">Descripción: </label>
                        <input type="text" className="form-control" value={bean.Description} onChange={(e) => { setBean({ ...bean, 'Description': e.target.value }) }} />
                    </div>
                    <div className="col-md-4">
                        <label className="form-label">Fecha desde: </label>
                        <InputDate label="" name="" setDate={(e: any) => { setBean({ ...bean, 'FromDate': e }) }} value={bean.FromDate}></InputDate>
                    </div>
                    <div className="col-md-4">
                        <label className="form-label">Fecha hasta: </label>
                        <InputDate label="" name="" setDate={(e: any) => { setBean({ ...bean, 'UptoDate': e }) }} value={bean.UptoDate}></InputDate>
                    </div>
                    <div className="col-md-4">
                        <label className="form-label">Disponibilidad del item: </label>
                        <select className="form-control" value={bean.State} onChange={(e) => { setBean({ ...bean, 'State': parseInt(e.target.value) }) }}>
                            <option value={null}>...</option>
                            <option value={1}>Creado</option>
                            <option value={2}>Publicado</option>
                            <option value={3}>Detenido</option>
                        </select>
                    </div>
                    <div className="col-md-6">
                        <label className="form-label">Precio del Item :</label>
                        <InputMoney id={0} name="valinm" value={bean.ItemPrice} onChange={(e: any) => { setBean({ ...bean, "ItemPrice": e }) }}></InputMoney>
                    </div>
                    <div className="col-md-6">
                        <label className="form-label">Código externo: </label>
                        <input type="text" className="form-control mt-2" value={bean.ExternalCode} onChange={(e) => { setBean({ ...bean, 'ExternalCode': e.target.value }) }} />
                    </div>
                    <div className="col-md-6">
                        <label className="form-label">Link Código externo: </label>
                        <input type="text" className="form-control" value={bean.LinkExternalStore} onChange={(e) => { setBean({ ...bean, 'LinkExternalStore': e.target.value }) }} />
                    </div>
                    <div className="col-md-6">
                        <label className="form-label">ItemTags: </label>
                        <input type="text" className="form-control" value={bean.ItemTags} onChange={(e) => { setBean({ ...bean, 'ItemTags': e.target.value }) }} />
                    </div>
                    <div className="col-md-12">
                        <label className="form-label">Cantidad Maxima: </label>
                        <input type="number" className="form-control" value={bean.QuantityItem} onChange={(e) => { setBean({ ...bean, 'QuantityItem': e.target.value }) }} />
                    </div>
                    <div className="col-md-6">
                        <label className="form-label">Talla: </label>
                        <input type="string" className="form-control" value={bean.Talla} onChange={(e) => { setBean({ ...bean, 'Talla': e.target.value }) }} />
                    </div>
                    <div className="col-md-6">
                        <label className="form-label">Grupo del objeto: </label>
                        <input type="string" className="form-control" value={bean.GroupItem} onChange={(e) => { setBean({ ...bean, 'GroupItem': e.target.value }) }} />
                    </div>

                    <div className="col-md-12">
                        <div className="border  my-3 mx-2 p-3 pt-2">
                            <div className="d-flex flex-column">
                                <span><strong>{`Imágenes del producto ${photos.length}`}</strong></span>
                                <FileInput label='Documento Adjunto' onCreate={(e) => {
                                    handleUpload(e)
                                }} />
                            </div>
                            {photos.length > 0 &&
                                <div className="my-2 d-flex flex-wrap gap-2">
                                    {photos.map((_e, _i) => (
                                        <div className="border rounded thumbnail" key={_i}>
                                            <img src={_serviceFile.getUrlFile(_e.MediaContext, _e.Media)} alt={_e.Media}
                                                style={{ objectFit: 'cover' }} width={100} height={100}
                                                onClick={() =>{}}
                                            />
                                            <div className="close_x" onClick={() => {
                                                const arr = photos.filter((elemento, index) => index !== _i);
                                                setBean({ ...bean, MediaList: JSON.stringify(arr) })
                                                setPhotos(arr)
                                            }}>
                                                &#10006;
                                            </div>
                                        </div>
                                    ))}
                                </div>}
                        </div>
                    </div>
                </div>
            </Modal>

            {modalinventario.view &&
                <Modal modal={modalinventario} updateModal={setModalinventario} eventModal={updateItem}>
                    <>
                        <Inventory IDinventory={bean[idBean]} />
                    </>
                </Modal>
            }

            <ModalConfirm modal={modalConfirm} updateModal={setModalConfirm} eventModal={eliminarItem}>
                {bean.Name}
            </ModalConfirm>
        </>
    );
};
