import { useState, useEffect } from 'react';
import { BpmService } from '../../../../core/services/BpmService';
import { Singleton } from '../../../../core/services/Singleton';

interface Props {
    idSelector: number;
    onChange: any;
}

export const SelectoCustomerType = ({ idSelector, onChange }: Props) => {

    const service = new BpmService();
    const [lista, setLista] = useState([]);
    const [id, setID] = useState(0);

    useEffect(() => {
        setID(idSelector);
        getLista();
    }, [])

    useEffect(() => {
        onChange(id);
    }, [id])

    const setValue = (e: any) => {
        setID(parseInt(e));
        onChange(id);
    }

    const getLista = () => {
        service.getCustomerTypeCatalog(null).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue) {
                    setLista(resp.DataBeanProperties.ObjectValue);
                }
               //console.log("DataAcces", lista);
            }
        );
    }

    return (
        <>
            <label>Tipo de cliente</label>
            <select className="form-control" value={id} onChange={(e) => { setValue(e.target.value) }}>
                <option value={0} key={Singleton.getInstance().getRandomInt()}>...</option>
                {lista &&
                    lista.map((item: any, index: number) => {
                        return (

                            <option key={index} value={item.DataBeanProperties.IDCustomerType} >{item.DataBeanProperties.Name}</option>
                        )
                    })
                }
            </select>
        </>
    )
}