import { DataBean } from "../DataBean";


export class ItemFlow extends DataBean {

    public async getItemFlowCatalogPorPropiedades(props: any, maxRows: number): Promise<any> {
        return this.getCustomService("FinanceCore", "java.util.List_getItemFlowCatalogPorPropiedades_java.util.Map_Number", [props, maxRows]);
    }

    public async cancelBusinessProcesByIDItemFlow(idItemFlow: number, idEmployee: number): Promise<any> {
        return this.getCustomService("SupplierERP", "com.orange.bean.ItemFlow_cancelBusinessProcesByIDItemFlow_Number_Number", [idItemFlow, idEmployee]);
    }

}