import { BasicButton } from '../../theme/Component/BasicButton';
import { BasicTable, MASK_MONEY, MASK_NUMBER } from '../../theme/Component/BasicTable';
import { Singleton } from '../../../core/services/Singleton';
import { ContractService } from '../../../core/services/ContractService';
import { useState, useEffect } from 'react';
import { Modal } from '../../theme/Component/Modal';
import { SelectAdquisitionArticleConstants } from "../select/SelectAdquisitionArticleConstants";
import { SelectUnspscID } from "../../admin/configuracion/UnspscID/SelectUnspscID";
import { FileInput } from "../../theme/Component/FileInput";
import { FileService } from "../../../core/services/FileService";
import { InputMoney } from "../../theme/Input/InputMoney";
import { ListMenu } from '../../theme/Component/ListMenu';
import { SelectorUmeasurement } from '../../bpm/config/Componentes/SelectorUmeasurement';
import { Alert } from '../../theme/Component/Alert';
import { useDashboardStore } from '../../pages/hooks/useDashboardStore';
import { ModalConfirm } from '../../theme/Component/ModalConfirm';
import { _single } from '../../../core/services/dataBean/EntityCatalog';
import { SelectArticleClassCodeTree } from '../../contabilidad/catalogo-bienes/SelectArticleClassCodeTree';
import { SelectSiteID } from '../../admin/configuracion/SiteID/SelectSiteID';
import { OfficeEditor } from '../../erp/cellEditor/Office/OfficeEditor';
import { PrintJson } from '../../theme/Component/PrintJson';
import { SelectBudgetIDList } from '../select/SelectBudgetIDList';
import { AdquisitionBeneficiarySelect } from '../../erp/AdquisitionProject/AdquisitionBeneficiarySelect';
interface Props {
    adquisitionItem: any;
    selectRow?: any;
    rol?: number;
    onlyView?: boolean;
    itemName?: string;
    _ItemState?: number;
    _PlanState?: number;
    viewHiringProcces?: boolean;
    updateFather?: any;
    beanItem: any;
}
export const AdquisitionArticle = ({ adquisitionItem, selectRow, rol, onlyView, itemName, _ItemState, _PlanState, viewHiringProcces, updateFather, beanItem }: Props) => {
    const single = Singleton.getInstance()
    const { setLoading } = useDashboardStore();
    const service = new ContractService();
    const fileService = new FileService();
    const [listaA, setListaA] = useState([]);
    const [budgetList, setbudgetlist] = useState<any>([]);
    const [step, setStep] = useState(0);
    const [beanA, setBeanA] = useState<any>({});
    const [modal, setModal] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalRubro, setModalRubro] = useState<any>({ name: 'Rubros', tabIndex: -1, view: false, btnClose: true, btnSubmit: false, format: 'modal-lg' });
    const [modalConfirm, setModalConfirm] = useState<any>({ name: 'Confirmar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });

    const idBeanA = 'IDAdquisitionArticle';

    const headArticule = [
        // { title: 'Orden del Artículo', property: 'ItemOrder' },
        { title: 'ID', property: 'IDAdquisitionArticle', visible: false, mask: MASK_NUMBER },
        { title: 'Tipo', property: 'TypeName', visible: true },
        { title: 'Descripción', property: 'Description', visible: true },
        { title: 'Unidad de Medida', property: 'MeasurementUnit', visible: false },
        { title: 'Cantidad', property: 'Quantity', visible: false },
        { title: 'Valor Unitario', property: 'EstimatedUnitaryValue', visible: false, mask: MASK_MONEY },
        { title: 'Valor Total', property: 'TotalValue', visible: true, mask: MASK_MONEY },
        { title: 'Especificaciones Técnicas', property: 'TechnicalSpecifications', visible: false },
        { title: 'Rubro', property: 'IDLnBudgetIDName', visible: true },
        { title: 'Código UNSPSC', property: 'IDLnUnspscIDName', visible: true },
        { title: 'Código Bien/Servicio', property: 'IDLnArticleClassCodeName', visible: true },
        // { title: 'Estado', property: 'StructuringStateName', visible: true },
        { title: 'Adjunto', property: 'URL', visible: false },
        // { title: 'Mes a ejecutar', property: 'MonthName' },
        // { title: 'Meses estimados ejecución del bien/servicio', property: 'EstimatedMonths' },
    ]

    useEffect(() => {
        getAdquisitionArticleCatalog();
        getBudgetIDList();
    }, [adquisitionItem.IDAdquisitionItem])
    // useEffect(() => {

    // }, [listaA])

    const setRowBeanA = (bean: any) => {
        setBeanA(bean);
        validarAdd();
        validarDelete();
        validarUpdate();
    }

    const getBudgetIDList = () => {
        service.getBudgetIDList(adquisitionItem.IDAdquisitionItem).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue) {
                    //console.log("Rubros ", resp.DataBeanProperties.ObjectValue);
                    let lis = resp.DataBeanProperties.ObjectValue;
                    //console.log(lis)
                    setbudgetlist(lis);
                    if (lis.length > 0 && lis[0].DataBeanProperties.IDLn) {
                        setRowBeanA({ ...beanA, "IDLnBudgetID": lis[0].DataBeanProperties.IDLn });
                    }
                }
                else {
                    setbudgetlist([]);
                }
            }
        );
        setModalRubro({ ...modalRubro, "view": false });
    }

    const getAdquisitionArticleCatalog = () => {

        setLoading(true);
        service.getAdquisitionArticleCatalog(adquisitionItem.IDAdquisitionItem, null, null, null).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue.DataBeanProperties.List) {
                    let tmp = resp.DataBeanProperties.ObjectValue.DataBeanProperties.List;
                    tmp.forEach((element: any) => {
                        if (element.DataBeanProperties.MediaContext) {
                            element.DataBeanProperties.URL = ` <a href="${fileService.getUrlFile(element.DataBeanProperties.MediaContext, element.DataBeanProperties.Media)}" target='_blank'>Ver</a>`
                        } else {
                            element.DataBeanProperties.URL = "";
                        }
                    });
                    setListaA(tmp);
                } else {
                    setListaA([]);
                }
                setLoading(false);
            }
        );
    }

    const agregarItemArticule = () => {

        let b = {
            ItemOrder: 1,
            Description: '',
            TechnicalSpecifications: '',
            Quantity: 1,
            MeasurementUnit: '',
            Type: 0,
            UnspscViewCode: '',
            EstimatedUnitaryValue: 0,
            MonthName: '',
            EstimatedMonths: 1,
            Media: '',
            IDAccountStructurer: single.getAccountID(),
            IDAdquisitionItem: adquisitionItem.IDAdquisitionItem,
            IDLnBudgetID: 0
        }
        setBeanA(b);
        setModal({ ...modal, "view": true });
    }

    const editarItem = () => {
        setModal({ ...modal, "view": true });
    }

    const eliminarItemArticulo = () => {

        setLoading(true);
        service.deleteAdquisitionArticle(beanA.IDAdquisitionArticle).subscribe(
            (resp: any) => {
                setLoading(false);
                if (resp) {
                    getAdquisitionArticleCatalog();
                }
                single.delete(beanA[idBeanA], beanA);
            }
        );
        setModalConfirm({ ...modalConfirm, "view": false });
    }


    const updateItemArticulo = () => {

        //console.log(beanA);
        setModal({ ...modal, "view": false });
        setLoading(true);
        service.updateAdquisitionArticle(beanA).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue) {
                    getAdquisitionArticleCatalog();
                }
                if (updateFather) {
                    updateFather(true);
                }
                setLoading(false);
            });
    }

    const renderButton = () => {
        return (
            <>
                <div className="row">
                    <div className="col-md-12">
                        <div className="row">
                            {step === 1 &&
                                <div className="col-md">

                                    <BasicButton value="Regresar" icon="ri-arrow-left-fill" clase='primary' eventClick={() => { setStep(0); setBeanA({}) }}></BasicButton>

                                </div>
                            }
                        </div>
                    </div>
                </div>
            </>
        )
    }

    let validarDelete = () => {
        let a = false
        if (single.canDelete() && (_ItemState === 30 || _ItemState === 7 || _ItemState == null) && (_PlanState == 0 || _PlanState == 1 || _PlanState == 2)) {
            a = true;
        } else {
            a = false;
        }
        return a;
    }
    let validarUpdate = () => {
        let b = false
        // if (single.canEdit() && (_ItemState === 30 || _ItemState === 7 || _ItemState == null) && (_PlanState == 0 || _PlanState == 1 || _PlanState == 2)) {
        if (single.canEdit()) {
            b = true;
        } else {
            b = false;
        }
        return b;
    }
    let validarAdd = () => {
        let c = false
        // if (single.canCreate() && (_ItemState === 30 || _ItemState === 7 || _ItemState == null) && (_PlanState == 0 || _PlanState == 1 || _PlanState == 2)) {
        if (single.canCreate()) {
            c = true;
        } else {
            c = false;
        }
        return c;
    }

    const iconButtonsArticule = [
        { nombre: 'Agregar', icono: 'ri-file-add-line', visible: viewHiringProcces ? true : validarAdd(), evento: agregarItemArticule }
    ]

    const listaBotonesArticulos = () => {
        const botones = [
            { titulo: 'Editar', icono: 'ri-edit-2-line', visible: viewHiringProcces ? true : validarUpdate(), evento: editarItem },
            { titulo: 'Eliminar', icono: 'ri-eraser-line', visible: viewHiringProcces ? true : validarDelete(), evento: () => { setModalConfirm({ ...modalConfirm, 'view': true }) } },
        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }



    return (
        <>
            {/* <PrintJson json={adquisitionItem} /> */}
            <p>
                <small>Bienes/Servicios del Ítem </small><br />
                <strong>{beanItem ? beanItem.Description : ''}</strong>
            </p>
            {onlyView ?
                <BasicTable head={headArticule} body={listaA} rowSet={setRowBeanA}></BasicTable>
                :
                <>
                    <BasicTable listButtons={listaBotonesArticulos()} headButtons={renderButton()} iconButtons={iconButtonsArticule} head={headArticule} body={listaA} rowSet={setRowBeanA}></BasicTable>
                </>
            }


            <Modal modal={modal} updateModal={setModal} eventModal={updateItemArticulo}>

                {budgetList.length == 0 &&
                    <Alert clase='primary'>
                        La necesidad debe tener al menos un <b>Rubro</b>
                    </Alert>
                }

                {budgetList.length > 0 &&
                    <>
                        <div className="row">

                            <div className="col-md-4">
                                <label >Cantidad</label>
                                <input type="number" className="form-control " value={beanA.Quantity} onChange={(e) => { setBeanA({ ...beanA, "Quantity": e.target.value }) }} />
                            </div>
                            <div className="col-md-4">
                                <label >Valor estimado unitario</label>

                                <InputMoney id={_single.getRandomInt} name='Valor estimado unitario' value={beanA.EstimatedUnitaryValue} onChange={(e: any) => { setBeanA({ ...beanA, ['EstimatedUnitaryValue']: parseInt(e) }) }} />
                            </div>
                            <div className="col-md-4">
                                <SelectorUmeasurement titulo='Unidad de Medida' idSelector={beanA.MeasurementUnit} onChange={(e: any) => { setBeanA({ ...beanA, "MeasurementUnit": e }) }} />
                            </div>

                            <div className="col-md-12 mt-1">
                                <label >Especificaciones Técnicas</label>
                                <textarea className="form-control mt-2" value={beanA.TechnicalSpecifications} onChange={(e) => { setBeanA({ ...beanA, "TechnicalSpecifications": e.target.value }) }}></textarea>
                            </div>
                            <div className="col-md-12 mt-2">
                                <label >Descripción</label>
                                <textarea className="form-control mt-2" value={beanA.Description} onChange={(e) => { setBeanA({ ...beanA, "Description": e.target.value }) }}></textarea>
                            </div>

                            <div className="col-md-2 mt-2">
                                <SelectAdquisitionArticleConstants idSelector={beanA.Type} onChange={(e: any) => { setBeanA({ ...beanA, "Type": e }) }}></SelectAdquisitionArticleConstants>
                            </div>

                            {!isNaN(beanA.Type) &&
                                <div className="col-md-10 mt-2">
                                    <SelectArticleClassCodeTree articleClass={beanA.Type} onChange={(e: any) => { setBeanA({ ...beanA, "IDLnArticleClassCode": e.IDLn }) }} IDLn={beanA.IDLnArticleClassCode} label='Catálogo de Clasificación de Bienes' />
                                </div>
                            }

                            <div className="col-md-12 mt-2">

                                <SelectUnspscID renderValue={beanA.IDLnUnspscIDName} onChange={(e: any) => {
                                    setBeanA({ ...beanA, "IDLnUnspsc": e.IDLn })
                                }}></SelectUnspscID>
                            </div>
                            {adquisitionItem.IDProject &&
                                <>
                                    <div className="col-md-6">
                                        <AdquisitionBeneficiarySelect label='Beneficiario de convenio' idAdquisitionProject={adquisitionItem.IDProject} idSelector={beanA.IDProjectBeneficiary}
                                            onChange={(e) => { setBeanA({ ...beanA, "IDProjectBeneficiary": e }) }} />
                                    </div>

                                </>
                            }
                        </div>
                        <div className="row mt-2">
                            <div className="col-md-6">
                                <SelectBudgetIDList idSelector={beanA.IDLnBudgetID} onChange={({ IDLn, IDAdquisitionItemBudgetID }: any) => {
                                    setBeanA({ ...beanA, "IDLnBudgetID": parseInt(IDLn), "IDAdquisitionItemBudgetID": IDAdquisitionItemBudgetID })
                                }} idAdquisitionItem={adquisitionItem.IDAdquisitionItem} />
                            </div>
                            <div className="col-md-6">
                                <FileInput Media={beanA.Media} MediaContext={beanA.MediaContext} onCreate={(fileMap: any) => {
                                    setBeanA({ ...beanA, "Media": fileMap.Media, "MediaContext": fileMap.MediaContext })
                                }}></FileInput>
                            </div>
                            <hr className='mt-2' />
                            <div className="col-md-6">
                                <SelectSiteID label='Ciudad destino Bien/Servicio' edit onChange={(e) => { setBeanA({ ...beanA, "IDLnSiteID": e.IDLn }) }} idLnSite={beanA.IDLnSiteID} />
                            </div>
                            <div className="col-md-6">
                                <OfficeEditor id={beanA.IDOffice} onchange={(e) => { setBeanA({ ...beanA, "IDOffice": e }) }} />
                            </div>
                            {/* <PrintJson json={beanA} /> */}
                        </div>
                    </>
                }
            </Modal>
            <ModalConfirm eventModal={eliminarItemArticulo} modal={modalConfirm} updateModal={setModalConfirm} children={''} />

        </>
    )
}