import { useEffect, useState } from "react";
import { _SupplierPaymentOrder } from "../../../../core/services/dataBean/EntityCatalogVdos"
import useDataBean from "../../../../core/services/dataBean/useDataBean"
import { toast } from "react-toastify";
import { PrintJson } from "../../../theme/Component/PrintJson";
import { BasicPage } from "../../../theme/Component/BasicPage";
import { YearSelector } from "../../../../core/shared/inputs/YearSelector";
import { _single } from "../../../../core/services/dataBean/EntityCatalog";
import { BasicButton } from "../../../theme/Component/BasicButton";
import { BasicTable, MASK_NUMBER } from "../../../theme/Component/BasicTable";
import { MASK_MONEY } from "../../../theme/Component/BasicTable2";
import { SuppliertPaymentOptions } from "../../../Talentohumano/GestionTH/SuppliertPaymentOptions";

export const DraftOrders = () => {

    const service = useDataBean(_SupplierPaymentOrder);
    const [row, setRow] = useState<any>({});

    useEffect(() => {
        service.setBean({
            idPosition: 0,
            Year: _single.getCurrenYear()
        });
        // service.setLista([]);
        getPosition();
    }, [])

    // useEffect(() => {
    //     getLista();
    // }, [service.bean.idPosition])


    const [position, setPosition] = useState<any>([]);

    const getPosition = () => {
        service.instance.getSupplierPaymentOrderConstants().then(
            (resp: any) => {
                if (resp.length > 0) {
                    service.setBean({ ...service.bean, "idPosition": resp[0].DataBeanProperties.Value, "Year": _single.getCurrenYear() });
                }
                setPosition(resp);
            }
        ).catch(err => toast.error(err))
    }

    const getLista = () => {
        service.instance.getSupplierPaymentOrderCatalogForSupervisor(_single.getAccountID(), service.bean.Year, service.bean.idPosition).then(
            (resp: any) => {
                service.setLista(resp);
            }
        )
    }

    const head = [
        { title: 'ID', property: 'IDSupplierPaymentOrder', visible: true, mask: MASK_NUMBER },
        { title: 'Fecha Orden', property: 'SupplierPaymentOrderDate', visible: true },
        { title: 'Contratante', property: 'ContractName', visible: true },
        { title: 'No. Contrato', property: 'InternalCode', visible: true },
        { title: 'Fecha Contrato', property: 'AdquisitionContractDate', visible: true },
        { title: 'Objeto del Contrato', property: 'Justification', visible: true },
        { title: 'Valor del Contrato', property: 'ContractValue', visible: true, mask: MASK_MONEY },
        { title: 'Valor de la Orden de Pago', property: 'Value', visible: true, mask: MASK_MONEY },
        { title: 'Posición de la Orden', property: 'OrderStateName', visible: true },
        { title: 'Estado de la Orden', property: 'StateName', visible: true },
        { title: 'Descripción de la Orden', property: 'Description', visible: true },
        { title: 'Empleado', property: 'EmployeeName', visible: true },
    ]



    const [listaBotones, setListaBotones] = useState<any>([]);
    return (
        <BasicPage titulo="Ordenes en Pago">
            <div className="row">
                <div className="col-md-12">
                    <div className="col">
                        <SuppliertPaymentOptions key={row} paymentOrder={row} listComponent={setListaBotones} updateList={getLista} />
                    </div>
                </div>
                <div className="col-md-4">
                    <label>Estado</label>
                    <select name="idPosition" className="form-select" value={service.bean.idPosition} onChange={service.handleChange}>
                        {position.map((item: any, index: number) => {
                            return (
                                <option key={index} value={item.DataBeanProperties.Value}>{item.DataBeanProperties.Property}</option>
                            )
                        })}
                    </select>
                </div>
                <div className="col-md-4">
                    <YearSelector tittle="Año" onChange={(e) => { service.setBean({ ...service.bean, "Year": e }) }} />
                </div>
                <div className="col-md-4 mt-4">
                    <BasicButton icon="ri-file-search-line" eventClick={getLista} >Buscar</BasicButton>
                </div>
                {/* <div className="col">
                    <PrintJson json={service.bean} />
                </div> */}
            </div>
            <div className="row">
                <BasicTable head={head} body={service.lista} rowSet={setRow} listButtons={listaBotones}></BasicTable>
            </div>
        </BasicPage>
    )
}