import { useState } from "react"
import { Singleton } from "../../../core/services/Singleton"
import { BasicPage } from "../../theme/Component/BasicPage"
import { BasicTable } from "../../theme/Component/BasicTable"
import { Bandeja } from "./Bandeja/Bandeja"



export const VisualBandeja = () => {

    const single = Singleton.getInstance()
    const [listahist, setListahist] = useState<any>([]);

    const setRowBean = (bean: any) => {

    }

    const head = [


    ]


    return (
        <>

            <BasicPage titulo='Bandeja de mensajes'>
                <>


                    <Bandeja IDAccount={single.getAccountID()} />



                </>
            </BasicPage>
        </>
    )
} 