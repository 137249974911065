import { useEffect, useState } from "react";
import useDataBean from "../../../core/services/dataBean/useDataBean"
import { BasicPage } from "../../theme/Component/BasicPage"
import { PrintJson } from "../../theme/Component/PrintJson";
import { DataBeanProperties } from '../../../core/services/model/server-response.interface';
import { FileInput } from "../../theme/Component/FileInput";
import { BasicButton } from "../../theme/Component/BasicButton";
import { toast } from "react-toastify";
import { _AccountantIDType, _ArticleClassType } from "../../../core/services/dataBean/EntityCatalogVdos";

export const ImportarCuentasContables = () => {

    const service = useDataBean(_AccountantIDType);
    const _articleClassType = useDataBean(_ArticleClassType);

    const [idCatalog, setIDCatalog] = useState<any>(1);
    const [idAccountant, setIDAccountan] = useState<any>(undefined);
    const [idBudget, setIDBudget] = useState<any>(undefined);
    const [idArticleClassType, setIDArticleClassType] = useState<any>(undefined);
    const [respuesta, setRespuesta] = useState<any>(undefined);
    const [fileI, setFileI] = useState<any>({});
    const [formato, setFormato] = useState<any>({});

    const [typeCatalog, setTypeCatalog] = useState<any>([
        {
            id: 1,
            name: "Plan de Cuentas Contable"
        },
        {
            id: 2,
            name: "Catálogo Presupuestal"
        },
        {
            id: 3,
            name: "Catálogo de Centros de Costo"
        },
        {
            id: 4,
            name: "Clasificador de Bienes y Servicios"
        },

    ]);
    const [accountantCatalog, setaccountantCatalog] = useState<any>([]);
    const [budgetID, setBudgetID] = useState<any>([]);


    useEffect(() => {
        setIDAccountan(undefined);
        setIDBudget(undefined);
        setFormato({});
        setFileI({});
        if (idCatalog === 1)
            service.instance.getAccountantIDTypeCatalog().then((resp: any) => {
                setaccountantCatalog(resp);
            });
        else if (idCatalog === 2)
            service.instance.getBudgetIDTypeCatalog().then((resp: any) => {
                setBudgetID(resp);
            });
        else if (idCatalog === 4)
            _articleClassType.instance.getArticleClassTypeCatalog(null).then((resp: any) => {
                setBudgetID(resp);
            });

        if(idCatalog===3)
            getFormato();
        
        if(idCatalog===4)
            getFormato();


    }, [idCatalog])

    useEffect(() => {
        
            getFormato();
    }, [idBudget, idAccountant,idArticleClassType])


    const importData = () => {

       //console.log(idCatalog);
       //console.log(fileI);

        if (fileI.Media) {
            if (idCatalog === 1) {
                service.instance.importAccountantIDCatalog(idAccountant, fileI.Media, fileI.MediaContext, null).then(
                    (resp: any) => {
                        

                        setRespuesta(resp);
                        toast.success("Archivo cargado correctamente");
                    }
                ).catch(errr => {
                    toast.error(errr)
                })
            }
            else if (idCatalog === 2) {
                service.instance.importBudgetIDCatalog(idBudget, fileI.Media, fileI.MediaContext, null).then(
                    (resp: any) => {
                        
                        setRespuesta(resp);
                        toast.success("Archivo cargado correctamente");
                    }
                ).catch(errr => {
                    toast.error(errr)
                })
            }
            else if (idCatalog === 3) {
                service.instance.importCostingCenterCatalog(fileI.Media, fileI.MediaContext, null).then(
                    (resp: any) => {
                        
                        setRespuesta(resp);
                        toast.success("Archivo cargado correctamente");
                    }
                ).catch(errr => {
                    toast.error(errr)
                })
            }
            else if (idCatalog === 4) {
                service.instance.importArticleClassTypeCatalog(idArticleClassType,fileI.Media, fileI.MediaContext, null).then(
                    (resp: any) => {
                        
                        setRespuesta(resp);
                        toast.success("Archivo cargado correctamente");
                    }
                ).catch(errr => {
                    toast.error(errr)
                })
            }

        } else {
            toast.error("No se ha seleccionado un archivo");
        }

    }
    const getFormato = () => {

        if (idCatalog === 1) {
            service.instance.exportAccountantIDXlsxFile(idAccountant).then(
                (resp: any) => {
                    let formatos = resp;
                    if (formatos.length > 0)
                        setFormato(formatos[0].DataBeanProperties);
                    else
                        setFormato({});
                }
            ).catch(errr => {
                setFormato({});
                toast.error(errr)
            })
        }
        else if (idCatalog === 2) {
            service.instance.exportBudgetIDTemplateXlsxFile(idBudget).then(
                (resp: any) => {
                    let formatos = resp;
                    if (formatos.length > 0)
                        setFormato(formatos[0].DataBeanProperties);
                    else
                        setFormato({});
                }
            ).catch(errr => {
                setFormato({});
                toast.error(errr)
            })
        }
        else if (idCatalog === 3) {
            service.instance.exportCostingCenterTemplateXlsxFile(null).then(
                (resp: any) => {
                    let formatos = resp;
                    if (formatos.length > 0)
                        setFormato(formatos[0].DataBeanProperties);
                    else
                        setFormato({});
                }
            ).catch(errr => {
                setFormato({});
                toast.error(errr)
            })
        }
        else if (idCatalog === 4) {
            service.instance.exportArticleClassTypeXlsxFile(idArticleClassType).then(
                (resp: any) => {
                    let formatos = resp;
                    if (formatos.length > 0)
                        setFormato(formatos[0].DataBeanProperties);
                    else
                        setFormato({});
                }
            ).catch(errr => {
                setFormato({});
                toast.error(errr)
            })
        }

    }
    const cantDelete = () => {

        let cant = false;
        if (idCatalog === 1)
            if (idAccountant > 0)
                cant = true;
            else if (idCatalog === 2)
                if (idBudget > 0)
                    cant = true;


        return cant;
    }



    return (
        <BasicPage titulo=" Importar Plan de Cuentas Contables y Catálogo Presupuestal">
            <div className="row">
                <div className="col-md-4">
                    <label>Tipo importartación</label>
                    <select name="import" className="form-select" value={idCatalog} onChange={(e) => { setIDCatalog(parseInt(e.target.value)) }}>
                        {typeCatalog.map((item: any) => {
                            return (
                                <option value={item.id}>{item.name}</option>
                            )
                        })}
                    </select>
                </div>
                <div className="col-md-4">

                    {idCatalog === 1 &&
                        <>
                            <label>Catálogo</label>
                            <select name="import" className="form-select" value={idAccountant} onChange={(e) => { setIDAccountan(parseInt(e.target.value)) }}>
                                <option value={undefined}>...</option>
                                {accountantCatalog.map((item: any) => {
                                    return (
                                        <option value={item.DataBeanProperties.IDAccountantIDType}>{item.DataBeanProperties.Description}</option>
                                    )
                                })}
                            </select>
                        </>
                    }
                    {idCatalog === 2 &&
                        <>
                            <label>Catálogo</label>
                            <select name="import" className="form-select" value={idBudget} onChange={(e) => { setIDBudget(parseInt(e.target.value)) }}>
                                <option value={undefined}>...</option>
                                {budgetID.map((item: any) => {
                                    return (
                                        <option value={item.DataBeanProperties.IDBudgetIDType}>{item.DataBeanProperties.Description}</option>
                                    )
                                })}
                            </select>
                        </>
                    }
                    {idCatalog === 4 &&
                        <>
                            <label>Catálogo</label>
                            <select name="import" className="form-select" value={idArticleClassType} onChange={(e) => { setIDArticleClassType(parseInt(e.target.value)) }}>
                                <option value={undefined}>...</option>
                                {budgetID.map((item: any) => {
                                    return (
                                        <option value={item.DataBeanProperties.IDArticleClassType}>{item.DataBeanProperties.Description}</option>
                                    )
                                })}
                            </select>
                        </>
                    }
                   
                </div>
                <div className="col-md-4"></div>
                <div className="col-md-4">
                    <FileInput directory="temp" Media={fileI.Media} MediaContext={fileI.MediaContext} onCreate={(e: any) => {
                        setFileI(e);
                    }} />
                </div>
                <div className="col-md-4 mt-3">
                    {formato.URLLink &&
                        <p >
                           Descargar Formato<br />
                            <a href={formato.URLLink} target="_blank">{formato.Filename}</a>
                            {/* <PrintJson json={formato} /> */}
                        </p>
                    }
                </div>
                <div className="col-md-6">
                    <BasicButton disable={!fileI.Media} icon="ri-database-2-line" eventClick={importData}>Importar</BasicButton>
                </div>
                <div className="col-md-12">
                    <PrintJson json={respuesta} />
                </div>
            </div>
        </BasicPage>
    )
}