import { useEffect, useState } from "react";
import useDataBean from "../../../../core/services/dataBean/useDataBean";
import { Singleton } from "../../../../core/services/Singleton";
import { ListMenu } from "../../../theme/Component/ListMenu";
import { BasicPage } from "../../../theme/Component/BasicPage";
import { BasicTable, MASK_MONEY } from "../../../theme/Component/BasicTable";
import { Modal } from "../../../theme/Component/Modal";
import { ModalConfirm } from "../../../theme/Component/ModalConfirm";
import { toast } from "react-toastify";
import { _EmployeeBonus, _single } from "../../../../core/services/dataBean/EntityCatalog";
import { BasicSwitch } from "../../../theme/Component/BasicSwitch";
import { EmployeeBonusTypeConstatsEditor } from "./Editors/EmployeeBonusTypeConstats.edtior";
import { SelectAccountantTree } from "../../../contabilidad/catalogo-cuentas/AccountantTree/SelectAccountantTree";
import { SelectBudgetID } from "../../../admin/configuracion/BudgetID/SelectBudgetID";
import { EmploymentBonusValue } from "./EmploymentBonusValue/EmploymentBonusValue";
import { AppointMentByEmploymentBonu } from "./EmploymentAppointment/AppointMentByEmploymentBonu";
import { TaxHoldeditor } from "../EmployeePayments/EmployeePayments.editor";
import { WelfareEntityTypeEditor } from "./Editors/WelfareEntityType.Editor";
import { MultiMonthEditor } from "./Editors/MultiMonthEditor";
import { MultiEmploymentTypeEditor } from "./Editors/MultiEmploymentType.editor";
import { AccountEditor } from "../../../editor/AccountEditor";
import { InputMoney } from "../../../theme/Input/InputMoney";
import { InputDate } from "../../../theme/Input/InputDate";

export const EmployeeBonus = () => {

    const employeeBonus = useDataBean(_EmployeeBonus);
    const single = Singleton.getInstance();
    const [response, setResponse] = useState<any>({})

    const [lista, setLista] = useState<any>([]);
    const [modal, setModal] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalEBV, setModalEBV] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: false, format: 'modal-lg' });
    const [modalAppointment, setModalAppointment] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: false, format: 'modal-lg' });
    const [modalC, setModalC] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });
    const [modalAllAppointment, setModalAllAppointment] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-md' });
    const [modalAddUser, setModalAddUser] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });

    useEffect(() => {
        getLista();
    }, [])


    const getLista = () => {
        employeeBonus.instance.getEmployeeBonusCatalog(null).then(
            (resp) => {
               //console.log(resp)
                setLista(resp)
            }
        )
    }

    const create = () => {
        employeeBonus.setBean({
            ActiveBonus: true,
            UserValue: false,
            WageFactor: true,
            FortnightInclude: false,
            DoProvision: false,
            BonusFormula: "com.colombia.ley100.Default",
            ApplyBudget: false,
            ApportionmentTime: false,
            DoLiquidate: false,
            DoTaxHold: false,
            DoDiscounts: false,
            DoVacProvision: false,
            PaymentToWelfare: false,
            UseWelfareBudget: false,
            ProvisionCalculationMonths: 12,
            ProvisionCalculationPercent: 100,
            BonusValue: 0,
            BringToPresentValue: false,
        });
        setModal({ ...modal, "name": "Agregar Prima Acional/Bonificaciones", "view": true });
    }

    const deleteItem = () => {
        employeeBonus.deleteItemVoid(employeeBonus.bean.IDEmploymentBonus).then(
            (resp: any) => {
                getLista();
                setModalC({ ...modalC, "view": false });
                single.delete(employeeBonus.bean.IDEmploymentBonus, employeeBonus.bean)
            }
        )

    }

    const editaItem = () => {
       //console.log(employeeBonus.bean);
        employeeBonus.update().then(
            (resp: any) => {
                getLista();
                setModal({ ...modal, "view": false });
                if (employeeBonus.bean.IDEmploymentBonus) {
                    single.update(employeeBonus.bean)
                } else {
                    single.create(employeeBonus.bean)
                }
            }
        ).catch(err => {
            toast.error(err);
        })
    }

    const addEmployeeToBonus = () => {
        employeeBonus.instance.createEmployeeBonus(employeeBonus.bean.IDEmploymentBonus, response.IDAccount, response.BonusValue, single.getAccountID(), response.IsTemporary, response.From, response.Upto).then(
            (resp) => {
                setModalAddUser({ ...modalAddUser, "view": false });
                setResponse({});

            }
        ).catch(
            err => { toast.error(err) }
        )
    }

    const activateBonus = () => {
        employeeBonus.instance.addEmployeeBonusToAllAppointments(employeeBonus.bean.IDEmploymentBonus, _single.getAccountID()).then(
            (resp) => {
               //console.log(resp);
                setModalAllAppointment({ ...modalAllAppointment, "view": false });
            }
        ).catch(err => { toast.error(err) })
    }

    const head = [
        { title: 'ID', property: 'IDEmploymentBonus', visible: true },
        { title: 'Activo', property: 'ActiveBonus', visible: true },
        { title: 'Código', property: 'Code', visible: true },
        { title: 'Nombre', property: 'Name', visible: true },
        { title: 'Tipo', property: 'TypeName', visible: true },
        { title: 'Valor', property: 'BonusValue', visible: true },
        { title: 'Vr Usuario', property: 'UserValue', visible: true },
        { title: 'Vr Mínimo', property: 'MinSalary', mask: MASK_MONEY, visible: true },
        { title: 'Vr Máximo', property: 'MaxSalary', mask: MASK_MONEY, visible: true },
        { title: 'Meses', property: 'MonthsJsonName', visible: true },
        { title: 'Grupos Laborales', property: 'EmploymentTypeJsonName', visible: true },
        { title: 'Factor Salarial', property: 'WageFactor', visible: true },
        { title: 'Incluir en Nomina', property: 'FortnightInclude', visible: true },
        { title: 'Cuenta por Pagar', property: 'AccountantIDName', visible: true },
        { title: 'Cuenta de Gasto', property: 'ExpensiveAccountantIDName', visible: true },
        { title: 'Cuenta de Costo', property: 'CostAccountantIDName', visible: true },
        { title: 'Provisionar', property: 'DoProvision', visible: true },
        { title: 'Cuenta de Provision', property: 'ProvisionAccountantIDName', visible: true },
        { title: 'Clase Liquidador Prima', property: 'BonusFormula', visible: true },
        { title: 'Aplica Presupuesto', property: 'ApplyBudget', visible: true },
        { title: 'Rubro Presupuestal', property: 'BudgetName', visible: true },
        { title: 'Factor de Retencion', property: 'TaxHoldName', visible: true },
        { title: 'Validar Tiempo Laborado', property: 'ApportionmentTime', visible: true },
        { title: 'Incluir en Liquidacion Final', property: 'DoLiquidate', visible: true },
        { title: 'Retefuente ', property: 'DoTaxHold', visible: true },
        { title: 'Descuentos ', property: 'DoDiscounts', visible: true },
        { title: 'Provision Seguridad Social', property: 'DoVacProvision', visible: true },
        { title: 'Nomina Electronica', property: 'IDElectronicPayroll', visible: true },
        { title: 'Pagar a Fondo', property: 'PaymentToWelfare', visible: true },
        { title: 'Tipo Fondo', property: 'WelfareType', visible: true },
        { title: 'Usar Rubro Fondo', property: 'UseWelfareBudget', visible: true },
        { title: 'Meses de Provisión', property: 'ProvisionCalculationMonths', visible: true },
        { title: '% Provisión', property: 'ProvisionCalculationPercent', visible: true },
        { title: 'Trae Beneficio a Valor Presente', property: 'BringToPresentValue', visible: true },
        { title: 'Tasa de Descuento', property: 'DiscountRate', visible: true },
        { title: 'Tasa de Rotación', property: 'TurnoverRate', visible: true },
    ]

    const iconButtonsItem = [
        { nombre: 'Agregar', visible: single.canCreate(), icono: 'ri-file-add-line', evento: create },
    ]
    const listaBotones = () => {
        const botones = [
            { titulo: 'Editar Prima Adicional/Bonificaciones', icono: 'ri-edit-2-line', visible: single.canEdit(), evento: () => { setModal({ ...modal, "name": "Editar Primas Adicionales y Bonificaciones", "view": true }) } },
            { titulo: 'Eliminar Prima Adicional/Bonificaciones', icono: 'ri-delete-bin-line', visible: single.canDelete(), evento: () => { setModalC({ ...modalC, "name": 'Eliminar Valor del Beneficio', "view": true }) } },
            { titulo: 'Agregar Empleado a Beneficio', icono: 'ri-user-add-line', visible: single.canCreate(), evento: () => { setModalAddUser({ ...modalAddUser, "name": 'Agregar Empleado a Beneficio', "view": true }) } },
            { titulo: 'Definir Valor/Empleado', icono: 'ri-currency-line', visible: true, evento: () => { setModalEBV({ ...modalEBV, "name": "Definir Valor/Empleado", "view": true }) } },
            { titulo: 'Activar beneficio en todos los niveles salariales', icono: 'ri-folder-shared-line', visible: true, evento: () => { setModalAllAppointment({ ...modalAllAppointment, "name": "Activar beneficio en todos los niveles salariales", "view": true }) } },
            { titulo: 'Activar/Desactivar Beneficio por Nivel Salarial', icono: 'ri-file-settings-line', visible: true, evento: () => { setModalAppointment({ ...modalAppointment, "name": "Activar/Desactivar Beneficio por Nivel Salarial", "view": true }) } },
        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }

    return (
        <>
            <BasicPage titulo="Primas Adicionales y Bonificaciones">
                <div className="row">
                    <div className="col-md-12">
                        <BasicTable body={lista} head={head} rowSet={employeeBonus.setBean} listButtons={listaBotones()} iconButtons={iconButtonsItem} />
                    </div>

                </div>
            </BasicPage>

            {modal.view &&
                <Modal modal={modal} updateModal={setModal} eventModal={editaItem}>
                    <div className="row">
                        <div className="col-6 d-flex align-items-center">
                            <BasicSwitch label="Activo" estado={employeeBonus.bean.ActiveBonus} eventChange={(e) => { employeeBonus.setBean({ ...employeeBonus.bean, "ActiveBonus": e }) }} />
                        </div>
                        <div className="col-6">
                            <label>Código</label>
                            <input type="text" className="form-control" name="Code" value={employeeBonus.bean.Code} onChange={employeeBonus.handleChange} />
                        </div>
                        <div className="col-12">
                            <label>Nombre</label>
                            <input type="text" className="form-control" name="Name" value={employeeBonus.bean.Name} onChange={employeeBonus.handleChange} />
                        </div>
                        <div className="col-6">
                            <EmployeeBonusTypeConstatsEditor idSelector={employeeBonus.bean.Type} onChange={(e) => { employeeBonus.setBean({ ...employeeBonus.bean, "Type": e }) }} />
                        </div>
                        <div className="col-6">
                            <label>Valor</label>
                            <input type="number" className="form-control" name="BonusValue" value={employeeBonus.bean.BonusValue} onChange={employeeBonus.handleChange} />
                        </div>
                        <div className="col-6 d-flex align-items-center">
                            <BasicSwitch label="VR Usuario" estado={employeeBonus.bean.UserValue} eventChange={(e) => { employeeBonus.setBean({ ...employeeBonus.bean, "UserValue": e }) }} />
                        </div>
                        <div className="col-6">
                            <label>Vr Mínimo</label>
                            <input type="number" className="form-control" name="MinSalary" value={employeeBonus.bean.MinSalary} onChange={employeeBonus.handleChange} />
                        </div>
                        <div className="col-6">
                            <label>Vr Máximo</label>
                            <input type="number" className="form-control" name="MaxSalary" value={employeeBonus.bean.MaxSalary} onChange={employeeBonus.handleChange} />
                        </div>
                        <div className="col-6">
                            <MultiMonthEditor onChange={(e) => { employeeBonus.setBean({ ...employeeBonus.bean, "MonthsJson": e }) }} label="Lista de Meses" values={employeeBonus.bean.MonthsJson} />
                        </div>
                        <div className="col-6">
                            <MultiEmploymentTypeEditor label="Grupos Laborales" values={employeeBonus.bean.EmploymentTypeJson} onChange={(e) => { employeeBonus.setBean({ ...employeeBonus.bean, "EmploymentTypeJson": e }) }} />
                        </div>
                        <div className="col-6 d-flex align-items-center">
                            <BasicSwitch label="Factor Salarial" estado={employeeBonus.bean.WageFactor} eventChange={(e) => { employeeBonus.setBean({ ...employeeBonus.bean, "WageFactor": e }) }} />
                        </div>
                        <div className="col-6 d-flex align-items-center">
                            <BasicSwitch label="Inlcuir en Nómina" estado={employeeBonus.bean.FortnightInclude} eventChange={(e) => { employeeBonus.setBean({ ...employeeBonus.bean, "FortnightInclude": e }) }} />
                        </div>
                        <div className="col-6">
                            <SelectAccountantTree label="Cuenta por Pagar" jsonCode={employeeBonus.bean.AccountantIDStr} onChange={(e) => { employeeBonus.setBean({ ...employeeBonus.bean, "AccountantIDStr": e.JsonCode }) }} />
                        </div>
                        <div className="col-6">
                            <SelectAccountantTree label="Cuenta de Gasto" jsonCode={employeeBonus.bean.ExpensiveAccountantIDStr} onChange={(e) => { employeeBonus.setBean({ ...employeeBonus.bean, "ExpensiveAccountantIDStr": e.JsonCode }) }} />
                        </div>
                        <div className="col-6">
                            <SelectAccountantTree label="Cuenta de Costo" jsonCode={employeeBonus.bean.CostAccountantIDStr} onChange={(e) => { employeeBonus.setBean({ ...employeeBonus.bean, "CostAccountantIDStr": e.JsonCode }) }} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6 d-flex align-items-center">
                            <BasicSwitch label="Provisionar" estado={employeeBonus.bean.DoProvision} eventChange={(e) => { employeeBonus.setBean({ ...employeeBonus.bean, "DoProvision": e }) }} />
                        </div>
                        {employeeBonus.bean.DoProvision === true &&
                            <div className="col-6">
                                <SelectAccountantTree label="Cuenta de Provision" idAccountant={employeeBonus.bean.ProvisionAccountantID} onChange={(e) => { employeeBonus.setBean({ ...employeeBonus.bean, "ProvisionAccountantID": e.AccountantID }) }} />
                            </div>
                        }
                    </div>
                    <div className="row">

                        <div className="col-6">
                            <label>Clase Liquidador Prima</label>
                            <input type="text" className="form-control" name="BonusFormula" value={employeeBonus.bean.BonusFormula} onChange={employeeBonus.handleChange} />
                        </div>
                    </div>
                    <div className="row">

                        <div className="col-6 d-flex align-items-center">
                            <BasicSwitch label="Aplica Presupuesto" estado={employeeBonus.bean.ApplyBudget} eventChange={(e) => { employeeBonus.setBean({ ...employeeBonus.bean, "ApplyBudget": e }) }} />
                        </div>
                        {employeeBonus.bean.ApplyBudget === true &&
                            <div className="col-6">
                                <SelectBudgetID edit={false} tipoPresupuesto={null} jsonCode={employeeBonus.bean.BudgetIDStr} onChange={(e) => { employeeBonus.setBean({ ...employeeBonus.bean, "BudgetIDStr": e.JsonCode }) }} />
                            </div>
                        }
                    </div>
                    <div className="row">

                        <div className="col-6">
                            <TaxHoldeditor label="Factor de Retención" idSelector={employeeBonus.bean.IDTaxHold} onChange={(e) => { employeeBonus.setBean({ ...employeeBonus.bean, "IDTaxHold": e }) }} />
                        </div>
                        <div className="col-6 d-flex align-items-center">
                            <BasicSwitch label="Validar Tiempo Laborado" estado={employeeBonus.bean.ApportionmentTime} eventChange={(e) => { employeeBonus.setBean({ ...employeeBonus.bean, "ApportionmentTime": e }) }} />
                        </div>
                        <div className="col-6 d-flex align-items-center">
                            <BasicSwitch label="Incluir en Liquidacion Final" estado={employeeBonus.bean.DoLiquidate} eventChange={(e) => { employeeBonus.setBean({ ...employeeBonus.bean, "DoLiquidate": e }) }} />
                        </div>
                        <div className="col-6 d-flex align-items-center">
                            <BasicSwitch label="Retefuente" estado={employeeBonus.bean.DoTaxHold} eventChange={(e) => { employeeBonus.setBean({ ...employeeBonus.bean, "DoTaxHold": e }) }} />
                        </div>
                        <div className="col-6 d-flex align-items-center">
                            <BasicSwitch label="Descuentos" estado={employeeBonus.bean.DoDiscounts} eventChange={(e) => { employeeBonus.setBean({ ...employeeBonus.bean, "DoDiscounts": e }) }} />
                        </div>
                        <div className="col-6 d-flex align-items-center">
                            <BasicSwitch label="Provision Seguridad Social" estado={employeeBonus.bean.DoVacProvision} eventChange={(e) => { employeeBonus.setBean({ ...employeeBonus.bean, "DoVacProvision": e }) }} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6 d-flex align-items-center">
                            <BasicSwitch label="Pagar a Fondo" estado={employeeBonus.bean.PaymentToWelfare} eventChange={(e) => { employeeBonus.setBean({ ...employeeBonus.bean, "PaymentToWelfare": e }) }} />
                        </div>
                        {employeeBonus.bean.PaymentToWelfare === true &&
                            <>
                                <div className="col-6">
                                    <WelfareEntityTypeEditor idSelector={employeeBonus.bean.WelfareType} onChange={(e) => { employeeBonus.setBean({ ...employeeBonus.bean, "WelfareType": e }) }} label="Tipo Fondo" />
                                </div>
                                <div className="col-6 d-flex align-items-center">
                                    <BasicSwitch label="Usar Rubro Fondo" estado={employeeBonus.bean.UseWelfareBudget} eventChange={(e) => { employeeBonus.setBean({ ...employeeBonus.bean, "UseWelfareBudget": e }) }} />
                                </div>
                            </>
                        }
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <label>Meses de Provisión</label>
                            <input type="number" className="form-control" name="ProvisionCalculationMonths" value={employeeBonus.bean.ProvisionCalculationMonths} onChange={employeeBonus.handleChange} />
                        </div>
                        <div className="col-6">
                            <label>% Provisión</label>
                            <input type="number" className="form-control" name="ProvisionCalculationPercent" value={employeeBonus.bean.ProvisionCalculationPercent} onChange={employeeBonus.handleChange} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6 d-flex align-items-center">
                            <BasicSwitch label="Trae Beneficio a Valor Presente" estado={employeeBonus.bean.BringToPresentValue} eventChange={(e) => { employeeBonus.setBean({ ...employeeBonus.bean, "BringToPresentValue": e }) }} />
                        </div>
                        {employeeBonus.bean.BringToPresentValue === true &&
                            <>
                                <div className="col-6">
                                    <label>Tasa de Descuento</label>
                                    <input type="number" className="form-control" name="DiscountRate" value={employeeBonus.bean.DiscountRate} onChange={employeeBonus.handleChange} />
                                </div>
                                <div className="col-6">
                                    <label>Tasa de Rotación</label>
                                    <input type="number" className="form-control" name="TurnoverRate" value={employeeBonus.bean.TurnoverRate} onChange={employeeBonus.handleChange} />
                                </div>
                            </>
                        }
                    </div>
                </Modal>
            }

            {modalEBV.view &&
                <Modal modal={modalEBV} updateModal={setModalEBV} >
                    <EmploymentBonusValue idEmploymentBonus={employeeBonus.bean.IDEmploymentBonus} />
                </Modal>
            }

            {modalAppointment.view &&
                <Modal modal={modalAppointment} updateModal={setModalAppointment} >
                    <AppointMentByEmploymentBonu idEmploymentBonus={employeeBonus.bean.IDEmploymentBonus} />
                </Modal>
            }

            {modalC.view &&
                <ModalConfirm modal={modalC} updateModal={setModalC} eventModal={deleteItem}>
                    <strong>Eliminar item {employeeBonus.bean.Name}</strong>
                </ModalConfirm>
            }

            <ModalConfirm modal={modalAllAppointment} updateModal={setModalAllAppointment} eventModal={activateBonus}>
                <p>¿Esta seguro que desea activar <strong>{employeeBonus.bean.Name}</strong> en todos los niveles salariales?</p>
            </ModalConfirm>

            <Modal modal={modalAddUser} updateModal={setModalAddUser} eventModal={addEmployeeToBonus} >
                <div className="row">
                    <div className="col-md-6">
                        <AccountEditor label="Empleado" idAccount={response.IDAccount} onChange={(e) => { setResponse({ ...response, "IDAccount": e.IDAccount }) }} />
                    </div>
                    <div className="col-md-6">
                        <label>Valor del Beneficio</label>
                        <InputMoney id={0} name="BonusValue" value={response.BonusValue} onChange={(e) => { setResponse({ ...response, "BonusValue": e }) }} />
                    </div>
                    <div className="col-md-6 d-flex-wrap align-content-center">
                        <BasicSwitch label="Beneficio Temporal" estado={response.IsTemporary} eventChange={(e) => { setResponse({ ...response, "IsTemporary": e }) }} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <InputDate notDate label="Fecha Inicio" name="From" value={response.From} setDate={(e) => { setResponse({ ...response, "From": e }) }} />
                    </div>
                    <div className="col-md-6">
                        <InputDate notDate label="Fecha Fin" name="Upto" value={response.Upto} setDate={(e) => { setResponse({ ...response, "Upto": e }) }} />
                    </div>
                </div>
            </Modal>
        </>
    )
}