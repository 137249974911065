import { DataBean } from "../DataBean";


export class UBLTaxSchemeContextCode extends DataBean {

    public async getUBLTaxSchemeContextCodeCatalogByAccount(idAccount: number | null): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_getUBLTaxSchemeContextCodeCatalogByAccount_Number", [idAccount]);
    }

    public async addTaxSchemeContextToAccount(idAccount: number, idTaxSchemeContextCode: number, from: Date, upto: Date, filer: number): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "com.quickdataerp.bean.ubl.UBLTaxSchemeContextCode_addTaxSchemeContextToAccount_Number_Number_java.util.Date_java.util.Date_Number", [idAccount, idTaxSchemeContextCode, from, upto, filer]);
    }

}