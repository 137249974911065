import { useEffect, useState } from "react"
import { StepWizard, Wizard } from "../../bpm/config/Componentes/wizard/Wizard"
import { BasicPage } from "../../theme/Component/BasicPage"
import { RegisterSheetBudgetValue } from "./RegisterSheetBudgetValue";
import { BudgetMovementFactoryForm } from "./BudgetMovementFactoryForm";
import { CreateBudgetMovementFactoryDocument } from "./CreateBudgetMovementFactoryDocument";

interface MapProperties {
    FormData: Record<string, any>;
    ObjectList: object[];
    DocumentList: Record<string, any>;
}

export const BudgetMovementFactory = () => {

    const [canDiscard, setCanDiscard] = useState<boolean>(false);
    const [map, setMap] = useState<MapProperties>({
        FormData: {},
        ObjectList: [],
        DocumentList: {}
    });

    const validarContrato = () => {

    }

    useEffect(() => {
        if (map.DocumentList.MSGList?.length > 0) {
            setCanDiscard(true)
        } else {
            setCanDiscard(false)
        }
    }, [map.DocumentList])

    const steps: StepWizard[] = [
        {
            keyID: "0",
            name: 'PASO 1. Formulario',
            onEvent: validarContrato,
            children: <BudgetMovementFactoryForm onChange={(e) => { setMap({ ...map, "FormData": e }) }} />,
            validButton: (map.FormData.DateForm && map.FormData.IDBudgetType),
            canReturn: false
        },
        {
            keyID: "1",
            name: 'PASO 2. Créditos/Contracréditos',
            onEvent: validarContrato,
            children: <RegisterSheetBudgetValue formData={map.FormData} onChange={(e) => { setMap({ ...map, "ObjectList": e }) }} />,
            validButton: map.ObjectList.length > 0,
            canReturn: true
        },
        {
            keyID: "2",
            name: 'PASO 3. Generar Crédito/Contracrédito de Rubros Presupuestales',
            onEvent: validarContrato,
            children: <CreateBudgetMovementFactoryDocument objectList={map.ObjectList} formData={map.FormData} onChange={(e) => { setMap({ ...map, "DocumentList": e }) }} />,
            validButton: false,
            canReturn: canDiscard,
            canDiscard: canDiscard
        }
    ]

    return (
        <BasicPage titulo="Crédito/Contracrédito de Rubros Presupuestales">
            <Wizard title="Certificado" listStep={steps} />
        </BasicPage>
    )
}