import { useEffect, useState } from 'react';
import { Modal } from '../../theme/Component/Modal';
import { FileInput } from '../../theme/Component/FileInput';
import { Singleton } from '../../../core/services/Singleton';
import { SupplierService } from '../../../core/services/SupplierService';

interface Props {
    _idSupplier: number;
}
export const DocsSupplier = ({ _idSupplier, }: Props) => {


    const single = Singleton.getInstance();
    const service = new SupplierService();
    const [bean, setBean] = useState<any>({});
    const [docs, setDocs] = useState<any>([]);
    const [atta, setAtta] = useState<any>({});
    const [itemDoc, setItemDoc] = useState<any>({});
    const [modal, setModal] = useState<any>({ name: 'Documentos', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });

    useEffect(() => {
        getListDocumentSupplier();
    }, [_idSupplier])

    const getListDocumentSupplier = () => {
        single.spinner(true);
        service.getListDocumentSupplier(_idSupplier).subscribe(
            (resp: any) => {
               //console.log(resp);
                if (resp.DataBeanProperties.ObjectValue) {
                    let lista = resp.DataBeanProperties.ObjectValue;
                    lista.forEach((element: any) => {
                        element.DataBeanProperties.Attach = element.DataBeanProperties.Attach ? JSON.parse(element.DataBeanProperties.Attach) : {}
                    });
                    setDocs(lista);
                } else {
                    setDocs([]);
                }

                single.spinner(false);
            }
        )
    }

    const update = (b: any) => {
       //console.log(b);
        service.updateDocsBySypplier(b).subscribe(
            (resp: any) => {
                single.spinner(false);
                if (resp.DataBeanProperties.ObjectValue) {
                    getListDocumentSupplier();
                }
                setModal({ ...modal, "view": false });
            }
        );
    }

    const updateItem = () => {
        let b = {
            IDDocsBySypplier: bean.IDDocsBySypplier,
            UpdateDate: single.getCurrenDate(),
            Attach: JSON.stringify(atta),
            IDSupplier: _idSupplier,
            Description: bean.Description,
            Name: bean.Name,
            IDDocumento: bean.IDDocumento,
            IDDocSupplier: bean.IDDocSupplier,
        }
        update(b);
    }

    return (
        <>
            <div className="row mb-4">
                <div className="col-md-12">
                    <ul className="list-group">
                        {docs.length > 0 &&
                            docs.map((item: any) => {
                                return (

                                    <li className="list-group-item">

                                        <div className="row">
                                            <div className="col-md-10">
                                                <p>
                                                    <i className="ri-attachment-line align-middle me-2"></i>
                                                    <strong>{item?.DataBeanProperties?.Name}</strong> <br />

                                                    {item?.DataBeanProperties?.Description}
                                                </p>
                                                {item.DataBeanProperties?.Attach?.URL &&
                                                    <a href={item.DataBeanProperties?.Attach?.URL} target='_blank'>{item.DataBeanProperties.Name}</a>
                                                }
                                            </div>
                                            <div className="col-md-2">
                                                <span className='link cursor mi-span' onClick={() => {
                                                    setBean(item.DataBeanProperties);
                                                    setModal({ ...modal, "view": true });
                                                    let mFile = {};
                                                    if (item.DataBeanProperties.Attach) {
                                                        mFile = item.DataBeanProperties.Attach;
                                                    }
                                                    setItemDoc(mFile);
                                                }} > {item.DataBeanProperties?.Attach?.Media ?
                                                    'Cambiar'
                                                    : 'Cargar Documento'} </span>
                                            </div>
                                        </div>
                                    </li>
                                )
                            })}
                    </ul>
                </div>
            </div>
            <Modal modal={modal} updateModal={setModal} eventModal={updateItem}>
                {/* <PrintJson  json={itemDoc}/> */}
                <FileInput
                    Media={itemDoc.fileMap ? itemDoc.fileMap?.Media : null}
                    MediaContext={itemDoc.fileMap ? itemDoc.fileMedia?.MediaContext : null}
                    directory={'supplier'}
                    onCreate={({ Media, MediaContext, URL }: any) => {

                        let temp = {
                            Media: Media,
                            MediaContext: MediaContext,
                            URL: URL,
                        }
                        setAtta(temp);

                    }} />
            </Modal>
        </>
    )
}