import { useEffect, useState } from "react";
import { FinanceService } from '../../../../core/services/FinanceService';
import { ButtonUnspscID } from './ButtonUnspscID';
import { useDashboardStore } from "../../../pages/hooks/useDashboardStore";
import { _single } from "../../../../core/services/dataBean/EntityCatalog";


interface Props {
    idChild: number;
    editBean: Function;
    edit: boolean;
}
export const UnspscIDChilds = ({ idChild, editBean, edit }: Props) => {

    const service = new FinanceService();
    const { setLoading } = useDashboardStore();
    const [lista, setLista] = useState([]);
    const [name, setName] = useState('');
    const [bean, setBean] = useState({});
    const [showchild, setShowchild] = useState(false);
    const [idchild, setIDchild] = useState(0);

    useEffect(() => {
        getListatree(idChild);
    }, [])

    const getListatree = (idArbol: any) => {


        let tmp = _single.getCacheItem('treeciuu_' + idArbol);
        if (tmp) {
            setLista(tmp);
        } else {

            setLoading(true);
            service.loadUnspscIDTree(idArbol).subscribe((resp: any) => {

                if (resp.DataBeanProperties.ObjectValue) {
                    let arbol: any = resp.DataBeanProperties.ObjectValue.EnvolvedObject.DataBeanProperties;
                    setName(arbol.Name);
                    setLista(resp.DataBeanProperties.ObjectValue.Childs);
                    _single.setCacheItem('treeciuu_' + idArbol,resp.DataBeanProperties.ObjectValue.Childs);   
                 
                }
                setLoading(false);

            });
        }

    };

    return (
        <>

            <ul className="list-group">
                {
                    lista.length > 0 &&
                    lista.map((item: any) => {
                        return (
                            <li className="list-group-item itemh" style={{ border: '0' }} key={item.EnvolvedObject.DataBeanProperties.IDLn}>

                                {edit &&
                                    <ButtonUnspscID
                                        id={item.EnvolvedObject.DataBeanProperties.IDLn}
                                        bean={item.EnvolvedObject.DataBeanProperties}
                                        updateEvent={() => { getListatree(idChild); }}
                                    />
                                }
                                <strong className={idchild === item.EnvolvedObject.DataBeanProperties.IDLn ? 'cursor treeselect' : 'cursor'} onClick={() => {
                                    localStorage.setItem('UnscpID', JSON.stringify(item.EnvolvedObject.DataBeanProperties));
                                    setIDchild(item.EnvolvedObject.DataBeanProperties.IDLn);


                                }}>
                                    <strong style={{ marginLeft: '15px' }}>{item.EnvolvedObject.DataBeanProperties.UnspscViewCode} </strong>
                                    <small>{item.EnvolvedObject.DataBeanProperties.Name}</small>

                                </strong>
                                <span className="badge bg-success ms-1">{item.ChildrenCount}</span>
                                {(idchild === item.EnvolvedObject.DataBeanProperties.IDLn) &&
                                    <UnspscIDChilds edit={edit} idChild={item.EnvolvedObject.DataBeanProperties.IDLn} editBean={(item: any) => { setBean(item) }} />
                                }
                            </li>
                        )
                    })
                }
            </ul>
        </>
    )
}