import { toast } from "react-toastify";
import { BasicPage } from "../../../theme/Component/BasicPage";
import { BasicTable, MASK_DATE, MASK_MONEY_ROUND, MASK_NUMBER } from "../../../theme/Component/BasicTable";
import { ListMenu } from "../../../theme/Component/ListMenu";
import { Modal } from "../../../theme/Component/Modal";
import { ModalConfirm } from "../../../theme/Component/ModalConfirm";
import { useEffect, useState } from "react";
import { Singleton } from "../../../../core/services/Singleton";
import useDataBean from "../../../../core/services/dataBean/useDataBean";
import { _TaxCode } from "../../../../core/services/dataBean/EntityCatalog";
import { TaxCodeSchemeEditor } from "../TaxCodeScheme/TaxCodeScheme.editor";
import { DownloadFileTaxCode } from "./TaxCodeOptions/DownloadFileTaxCode";
import { ImportFileTaxCode } from "./TaxCodeOptions/ImportFileTaxCode";

export const TaxCode = () => {

    const taxCode = useDataBean(_TaxCode);
    const single = Singleton.getInstance();
    const [codeScheme, setCodeScheme] = useState<number>(null);
    const [lista, setLista] = useState<any>([]);
    const [modal, setModal] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalDownFile, setModalDownFile] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-md' });
    const [modalImport, setModalImport] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-md' });
    const [modalC, setModalC] = useState<any>({ name: "Eliminar", tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });

    useEffect(() => {
        if (codeScheme >= 0) {
            getLista();
        }
    }, [codeScheme])

    const getLista = () => {
        taxCode.instance.getTaxCodeCatalog(codeScheme).then(
            (resp: any) => {
                if (resp) {
                    setLista(resp)
                }
            }
        )

    }

    const create = () => {
        taxCode.setBean({});
        setModal({ ...modal, "view": true, "name": `Agregar Códigos de Clasificación de Impuestos` });
    }

    const createItem = () => {
        taxCode.instance.addTaxCode(codeScheme, taxCode.bean.Code, taxCode.bean.Name, taxCode.bean.Description).then(
            (resp) => {
                getLista();
                single.create(taxCode.bean)
                setModal({ ...modal, "view": false })
            }
        )
    }

    const deleteItem = () => {
        taxCode.deleteItemVoid(taxCode.bean.IDTaxCode).then(
            (resp: any) => {
                getLista();
                setModalC({ ...modalC, "view": false });
                single.delete(taxCode.bean.IDTaxCode, taxCode.bean)
            }
        )
    }

    const editaItem = () => {
        taxCode.update().then(
            (resp: any) => {
                getLista();
                setModal({ ...modal, "view": false });
                single.update(taxCode.bean)
            }
        ).catch(err => {
            toast.error(err);
        })
    }

    const updateOrCreate = () => {
        if (taxCode.bean.IDTaxCode) {
            editaItem();
           //console.log("editar")
        } else {
            createItem();
           //console.log("crear")
        }
    }

    const importFile = () => {
        setModalImport({ ...modalImport, "name": "Importar códigos", "view": true })
    }

    const downloadFile = () => {
        setModalDownFile({ ...modalDownFile, "name": "", "view": true })
    }

    const head = [
        { title: 'ID', property: 'IDTaxCode', mask: MASK_NUMBER, visible: true },
        { title: 'Código', property: 'Code', visible: true,mask:MASK_NUMBER },
        { title: 'Nombre', property: 'Name', visible: true },
        { title: 'Descripción', property: 'Description', visible: true },
        { title: 'Desde', property: 'Since', mask: MASK_DATE, visible: true },
    ]

    const iconButtonsItem = [
        { nombre: 'Agregar', visible: single.canCreate(), icono: 'ri-file-add-line', evento: create },
        { nombre: 'Descargar Formato de Importación', visible: true, icono: 'ri-file-download-line', evento: downloadFile },
        { nombre: 'Importar Códigos', visible: true, icono: 'ri-file-upload-line', evento: importFile },
    ]
    const listaBotones = () => {
        const botones = [
            { titulo: 'Editar', icono: 'ri-edit-2-line', visible: single.canEdit(), evento: () => { setModal({ ...modal, "name": `Editar ${taxCode.bean.Name}`, "view": true }) } },
            { titulo: 'Eliminar', icono: 'ri-delete-bin-line', visible: single.canDelete(), evento: () => { setModalC({ ...modalC, "view": true }) } },
        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }


    return (
        <>
            <BasicPage titulo="Códigos de Clasificación de Impuestos">
                <div className="col-6 mb-2">
                    <TaxCodeSchemeEditor idSelector={codeScheme} onChange={(e) => { setCodeScheme(e) }} />
                </div>
                {codeScheme ?
                    <div className="col-12">
                        <BasicTable body={lista} head={head} rowSet={taxCode.setBean} listButtons={listaBotones()} iconButtons={iconButtonsItem}></BasicTable>
                    </div>
                    :
                    <div className="alert alert-warning text-center">
                        Debe seleccionar un Tipo de Código
                    </div>
                }
            </BasicPage>

            {modal.view &&
                <Modal modal={modal} updateModal={setModal} eventModal={updateOrCreate}>
                    <div className="row">
                        <div className="col-6">
                            <label>Código</label>
                            <input type="text" name="Code" className="form-control" value={taxCode.bean.Code} onChange={taxCode.handleChange} />
                        </div>
                        <div className="col-6">
                            <label>Nombre</label>
                            <input type="text" name="Name" className="form-control" value={taxCode.bean.Name} onChange={taxCode.handleChange} />
                        </div>
                        <div className="col-12">
                            <label>Descripción</label>
                            <textarea name="Description" className="form-control" value={taxCode.bean.Description} onChange={taxCode.handleChange} />
                        </div>
                    </div>
                </Modal>
            }

            {modalImport.view &&
                <Modal modal={modalImport} updateModal={setModalImport}>
                    <ImportFileTaxCode closeModal={() => { setModalImport({ ...modalImport, "view": false }) }} />
                </Modal>
            }

            {modalDownFile.view &&
                <Modal modal={modalDownFile} updateModal={setModalDownFile} eventModal={() => { }}>
                    {/* <DownloadFileTaxCode /> */}
                    <div className="row">
                        <div className="col">
                            <p><a href="assets/extra/FormatoCodigoCIIU.xlsx" target="_blank">Descargar Formato</a></p>
                        </div>
                    </div>
                </Modal>
            }

            {modalC.view &&
                <ModalConfirm modal={modalC} updateModal={setModalC} eventModal={deleteItem}>
                    <strong>Eliminar item {taxCode.bean.Name}</strong>
                </ModalConfirm>
            }
        </>
    )
}