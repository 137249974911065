import { DataBean } from "../DataBean";


export class TaxSchemeValue extends DataBean {

    public async getTaxSchemeValueCatalog(idTaxScheme: number | null): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_getTaxSchemeValueCatalog_Number", [idTaxScheme]);
    }

    public async getTaxSchemeValueConstants(arg: number | null): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_getTaxSchemeValueConstants_Number", [arg]);
    }

    public async addTaxSchemeValue(idTaxScheme: number, taxValue: number): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "com.quickdataerp.bean.admin.TaxSchemeValue_addTaxSchemeValue_Number_Number", [idTaxScheme, taxValue]);
    }

    public async addTaxSchemeValueWithDescription(idTaxScheme: number, taxValue: number, description: string): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "com.quickdataerp.bean.admin.TaxSchemeValue_addTaxSchemeValue_Number_Number_String", [idTaxScheme, taxValue, description]);
    }

    public async getTaxSchemeValueCatalogWithTaxHolding(taxCodeList: any, siteIDLnList: any, idAccount: number, taxSchemeName: string): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_getTaxSchemeValueCatalogWithTaxHolding_java.util.List_java.util.List_Number_String", [taxCodeList, siteIDLnList, idAccount, taxSchemeName]);
    }

    public async getTaxSchemeCatalogWithHoldingTax(idTaxSchemeContext: number | null, adquisitionArticleType: number | null, taxFilerType: number | null): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_getTaxSchemeCatalogWithHoldingTax_Number_Number_Number", [idTaxSchemeContext, adquisitionArticleType, taxFilerType]);
    }

    public async getTaxSchemeValueCatalogAvailable(idTaxScheme: number | null, adquisitionArticleType: number | null, taxFilerType: number | null): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_getTaxSchemeValueCatalogAvailable_Number_Number_Number", [idTaxScheme, adquisitionArticleType, taxFilerType]);
    }

}