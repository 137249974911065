import { useEffect, useState } from "react";
import { _CashRequest } from "../../../../core/services/dataBean/EntityCatalog"
import useDataBean from "../../../../core/services/dataBean/useDataBean"
import { BasicPage } from "../../../theme/Component/BasicPage"
import { Singleton } from "../../../../core/services/Singleton";
import { BasicTable, MASK_MONEY, MASK_NUMBER } from "../../../theme/Component/BasicTable";
import { YearSelector } from "../../../../core/shared/inputs/YearSelector";
import { BasicButton } from "../../../theme/Component/BasicButton";
import { ListMenu } from "../../../theme/Component/ListMenu";
import { ModalConfirm } from "../../../theme/Component/ModalConfirm";
import { Modal } from "../../../theme/Component/Modal";
import { InputDate } from "../../../theme/Input/InputDate";
import { CashRequestItem } from "../CashResquestItems/CahsRequestItems";
import { BasicSwitch } from "../../../theme/Component/BasicSwitch";
import { CashRequestConstantSelector } from "./CashRequestConstantSelector";
import { InputMoney } from "../../../theme/Input/InputMoney";
import { toast } from "react-toastify";
import { CashDeskAccountEditor } from "../CashDeskAccount/CashDeskAccountEditor";


export const CashRequest = () => {

    const { lista, setLista, bean, setBean, instance, handleChange } = useDataBean(_CashRequest);
    const single = Singleton.getInstance();

    const [year, setYear] = useState<number>(single.getCurrenYear());
    const [obs, setObs] = useState<string>("");
    const [state, setState] = useState<number>(2);
    const [cashID, setCashID] = useState<number>(NaN);

    const [modal, setModal] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: "modal-lg", });
    const [modalItems, setModalItems] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: "modal-lg", });
    const [modalPost, setModalPost] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: "modal-md", });
    const [modalConfirm, setModalConfirm] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: "modal-sm", });

    useEffect(() => {
        if (state) {
            getLista();
        }
    }, [state])

    const getLista = () => {
        instance.getCashRequestCatalogForApplicant(single.getAccountID(), year, state).then(
            (resp) => {
               //console.log(resp)
                setLista(resp)
            }
        ).catch(
            err => {
               console.error(err)
            }
        )
    }

    const createItem = () => {
        setBean({
            IDAccount: single.getAccountID(),
            RequiresAdvance: false,
        });
        setModal({ ...modal, "view": true, "name": "Crear una Solicitud de Pago" })
    }

    const updateItem = () => {
        instance.updateCashRequest(bean).then(
            (resp) => {
                getLista();
                setModal({ ...modal, "view": false });
            }
        ).catch(
            err => {
               console.error(err);
            }
        )
    }

    const eliminarItem = () => {
        instance.deleteCashRequest(bean.IDCashRequest, bean.CashRequestDate).then(
            (resp) => {
                setModalConfirm({ ...modalConfirm, "view": false });
                getLista();
            }
        ).catch(
            err => {
               console.error(err)
            }
        )
    }

    const postRequest = () => {
        instance.postCashRequest(bean.IDCashRequest, bean.CashRequestDate, cashID, single.getAccountID(), obs).then(
            (resp) => {
                setModalPost({ ...modalPost, "view": false })
                getLista();
            }
        ).catch(
            err => {
               console.error(err)
            }
        )
    }

    const head = [
        { title: 'ID', property: 'IDCashRequest', mask: MASK_NUMBER },
        { title: 'Fecha Solicitud', property: 'CashRequestDate' },
        { title: 'Solicitado por', property: 'ApplicantName' },
        { title: 'Descripción', property: 'Description' },
        { title: 'Estado de la Operación', property: 'OrderStateName' },
        { title: 'Estado', property: 'StateName' },
        { title: 'Solicitante', property: 'AccountName' },
        { title: 'Requiere Anticipo', property: 'RequiresAdvance' },
        { title: 'Anticipo Solicitado', property: 'AdvanceValue', mask: MASK_MONEY },
        { title: 'Anticipo Pagado', property: 'AdvanceApprovedValue', mask: MASK_MONEY },
        { title: 'Documento', property: 'AdvanceDocumentConsecutive' },
        { title: 'Valor Rembolsado', property: 'Value', mask: MASK_MONEY },
        { title: 'Caja Solicitada', property: 'CashDeskAccountName' },
        { title: 'Responsable de Caja', property: 'IDEmployeeName' },
    ]

    const iconButtonsItem = [
        { nombre: "Agregar", visible: single.canCreate(), icono: "ri-file-add-line", evento: createItem },
    ];

    const listaBotones = () => {
        const botones = [
            { titulo: "Editar", icono: "ri-edit-2-line", visible: (single.canEdit() && (bean.OrderState !== 3)), evento: () => { setModal({ ...modal, view: true, name: "Editar" }); }, },
            { titulo: "Eliminar", icono: "ri-delete-bin-line", visible: (single.canDelete() && (bean.OrderState !== 3)), evento: () => { setModalConfirm({ ...modalConfirm, view: true, name: `Eliminar ${bean.Name}` }); }, },
            { titulo: "Ver Items de la solicitud", icono: "ri-file-list-line", visible: single.canEdit(), evento: () => { setModalItems({ ...modalItems, "name": "Items de la solicitud de Pago", "view": true }) }, },
            { titulo: "Enviar Solicitud para Reemsolso por Caja Menor", icono: "ri-send-plane-line", visible: bean.OrderState !== 3, evento: () => { setModalPost({ ...modalPost, "name": "Enviar Solicitud para Reemsolso por Caja Menor", "view": true }) }, },
        ];
        return <ListMenu listaBotones={botones} />;
    };

    const setRowBean = (objeto: any) => {
        setBean(objeto);
        setObs("");
    }

    const [missingFields, setMissingFields] = useState<string[]>([]);


    const validateData = () => {
        if (validateForm() > 0) {
            toast.error("Faltan campos obligatorios por completar");
            return;
        } else {
            updateItem();
        }
    }

    const validateForm = () => {
        const requiredFields = [
            "CashRequestDate", "Description"
        ];

        let missing = [];
        for (let field of requiredFields) {
            if (!bean[field]) {
                missing.push(field);
            }
        }

        //console.log(missing)
        setMissingFields(missing);
        return missing.length
    };

    return (
        <>
            <BasicPage titulo="Solicitud de Pago por Caja Menor">
                <div className="row">
                    <div className="col-md-4">
                        <YearSelector onChange={(e) => { setYear(e) }} value={year} />
                    </div>
                    <div className="col-md-4">
                        <CashRequestConstantSelector idSelector={state} onChange={(e) => { setState(e) }} label="Estado de la Operacón" />
                    </div>
                </div>
                <BasicTable listButtons={listaBotones()} iconButtons={iconButtonsItem} body={lista} head={head} rowSet={(e) => { setRowBean(e) }} />
            </BasicPage>

            <ModalConfirm modal={modalConfirm} updateModal={setModalConfirm} eventModal={eliminarItem}>
                {bean.Name}
            </ModalConfirm>

            <Modal modal={modal} updateModal={setModal} eventModal={validateData}>
                <div className="row">
                    <div className="col-md-6">
                        <label className={`${missingFields.includes('CashRequestDate') ? 'has-error' : ''}`}>Fecha de la solicitud</label>
                        <InputDate name="CashRequestDate" label=" " value={bean.CashRequestDate} setDate={(e) => { setBean({ ...bean, "CashRequestDate": e }) }} notDate={!bean.CashRequestDate} />
                    </div>
                    <div className="col-12">
                        <label className={`${missingFields.includes('Description') ? 'has-error' : ''}`}>Descripción</label>
                        <textarea value={bean.Description} name="Description" className="form-control" onChange={handleChange} rows={4} />
                    </div>
                    <div className="col-md-6 d-flex align-items-center">
                        <BasicSwitch estado={bean.RequiresAdvance} eventChange={(e) => { setBean({ ...bean, "RequiresAdvance": e }) }} label="Requiere Anticipo" />
                    </div>
                    {bean.RequiresAdvance &&
                        <div className="col-md-6">
                            <label>Valor a Anticipar</label>
                            <InputMoney id={1} name="value" value={bean.AdvanceValue} onChange={(e) => { setBean({ ...bean, "AdvanceValue": e }) }} />
                        </div>
                    }
                </div>
            </Modal>

            <Modal modal={modalPost} updateModal={setModalPost} eventModal={postRequest} >
                <div className="row">
                    <div className="col-12">
                        <CashDeskAccountEditor onChange={({ IDCashDeskAccount }) => { setCashID(parseInt(IDCashDeskAccount)) }} />
                    </div>
                    <div className="col-12">
                        <label>Observaciones</label>
                        <textarea value={obs} name="Observations" className="form-control" onChange={(e) => { setObs(e.target.value) }} rows={4} />
                    </div>
                </div>
            </Modal>

            <Modal modal={modalItems} updateModal={setModalItems} >
                <CashRequestItem updateFunction={() => { getLista() }} idCashRequest={bean.IDCashRequest} cashRequestDate={bean.CashRequestDate} />
            </Modal>
        </>
    )
}