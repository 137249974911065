import { useEffect, useState } from 'react';
import { Modal } from '../../../theme/Component/Modal';
import { BudgetID } from './BudgetID';
import useDataBean from '../../../../core/services/dataBean/useDataBean';
import { _BudgetID } from '../../../../core/services/dataBean/EntityCatalog';
import { toast } from 'react-toastify';


interface Props {
    tipoPresupuesto?: number;
    idBudgetID?: number;
    jsonCode?: string;
    value?: any;
    onChange: any;
    edit?: boolean;
    label?: string;
    multiSelect?: boolean;
    _year?: number;
    _isExpensive?: boolean;
}
export const SelectBudgetID = ({ idBudgetID, jsonCode, onChange, tipoPresupuesto, edit, label, multiSelect, _year, _isExpensive }: Props) => {

    const { instance } = useDataBean(_BudgetID)

    const [modal, setModal] = useState<any>({ name: 'Rubro de Presupuestos', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [valor, setValor] = useState('');
    const [multiValue, setMultivalue] = useState<any>([]);
    const [multiNames, setMultiNames] = useState<string[]>([]);

    useEffect(() => {
        if (idBudgetID) {
            getBudgetId()
        }
    }, [idBudgetID])

    const getBudgetId = () => {
        instance.getBudgetIDByKey(idBudgetID).then(
            (resp) => {
                setValor(`${resp.BudgetViewCode ?? ""} - ${resp.Name ?? ""}`)
                onChange(resp)
            }
        ).catch(
            err => {
                console.error(err)
            }
        )
    }

    useEffect(() => {
        if (jsonCode) {
            getBudgetJson()
        }
    }, [jsonCode])


    const getBudgetJson = () => {
        instance.getBudgetIDByCode(jsonCode).then(
            (resp) => {
                setValor(`${resp.BudgetViewCode ?? ""} - ${resp.Name ?? ""}`)
                onChange(resp)
            }
        ).catch(
            err => {
                console.error(err)
            }
        )
    }

    const getItem = () => {

        let bean: any = JSON.parse(localStorage.getItem('BudgetID') || '{}');
        if (!bean.IDLn) {
            toast.error("No se pudo seleccionar correctamente")
        } else {
            if (multiSelect) {
                const exists = multiValue.some((item: any) => item.IDLn === bean.IDLn);
                if (!exists) {
                    const updatedMultiValue = [...multiValue, bean];
                    setMultivalue(updatedMultiValue);
                    onChange(updatedMultiValue);

                    const updatedMultiNames = updatedMultiValue.map((item: any) => item.Name);
                    setMultiNames(updatedMultiNames);
                } else {
                    toast.error("Ya se Encuentra Seleccionado")
                }
            } else {
                onChange(bean);
                setValor(bean.BudgetViewCode + " " + bean.Name);
            }
            setModal({ ...modal, "view": false });
        }
    }

    const removeItem = (index: number) => {
        const updatedMultiValue = [...multiValue];
        updatedMultiValue.splice(index, 1);
        setMultivalue(updatedMultiValue);
        onChange(updatedMultiValue);

        const updatedMultiNames = updatedMultiValue.map((item: any) => item.Name);
        setMultiNames(updatedMultiNames);
    };

    return (
        <>
            {/* <span className="form-control text-wrap " style={{ minHeight: '35px' }} onClick={() => setModal({ ...modal, "view": true })}>{valor}</span> */}

            <label>{label ?? "Rubro Presupuestal"}</label>
            <div className="form-control text-wrap" style={{ minHeight: '35px' }} onClick={() => setModal({ ...modal, "view": true })}>
                {multiSelect ? (
                    multiNames.length > 0 ? (
                        multiNames.map((name, index) => (
                            <span key={index} className="badge m-1 btn btn-primary">
                                {name}
                                <button
                                    type="button"
                                    className="btn btn-sm btn-danger ms-2"
                                    style={{ marginLeft: '8px' }}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        removeItem(index);
                                    }}
                                >
                                    &times;
                                </button>
                            </span>
                        ))
                    ) : (
                        <span>Seleccionar...</span>
                    )
                ) : valor ? (
                    valor
                ) : (
                    <span>Seleccionar...</span>
                )}
            </div>

            <Modal onSubmitLabel='Seleccionar' modal={modal} updateModal={setModal} eventModal={getItem}>
                <div style={{ maxHeight: '60vh', overflow: 'auto' }}>
                    <BudgetID tipoPresupuesto={tipoPresupuesto} edit={edit} id={null} year={_year} isExpensive={_isExpensive} />
                </div>
            </Modal>

        </>
    )
}