import { useEffect, useState } from 'react';
interface Props {
    label?: string;
    name: string;
    value: any;
    setDate?: any;
    _disable?: boolean;
    notDate?: boolean
    onChangeE?: Function;
}
export const InputDate = ({ label, name, value, setDate, _disable, notDate, onChangeE }: Props) => {


    const [miFecha, setMifecha] = useState<any>();

    useEffect(() => {
        if (!notDate) {
           //console.log("value inicial => ", value);
            try {
                let v: Date = new Date(value);
                if (value === undefined || value === null) {
                    v = new Date();
                }
                setMifecha(v.toISOString().slice(0, 10));
            } catch (error) { }
        }
    }, [])
    useEffect(() => {

        if (value) {
           //console.log("miFecha ",miFecha);
           //console.log("value carga ",value);
            
           
               //console.log("value => ", value);
                try {
                    let v: Date = new Date(value);
                    if (value === undefined || value === null) {
                        v = new Date();
                    }
                    setMifecha(v.toISOString().slice(0, 10));
                } catch (error) { }
            
        } else {
            setMifecha(undefined);
        }

    }, [value])

    // useEffect(() => {

    //     if(miFecha)
    //     {

    //        //console.log(value , miFecha , value == miFecha);            
    //         if(value != miFecha)
    //         {            
    //             setDate(`${miFecha} 00:00:00`);
    //            //console.log("Update => ",`${miFecha} 00:00:00`);

    //         }

    //     }


    // }, [miFecha])

    const update = (e) => {

        setDate(e + " 00:00:00");
        setMifecha(e);
        if (onChangeE)
            onChangeE(e);


    }

    return (
        <>
            {label != '' && <label>{label}</label>}
            <input disabled={_disable} type="date" className="form-control" name={name} value={miFecha} onChange={(e) => { update(e.target.value) }} />
        </>
    )
}