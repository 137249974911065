import { DataBean } from "../DataBean";

export class EmployeeDiscount extends DataBean {

    public async getOtherDiscounts(accountID:any,year: any,month:any,profile:any): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_getOtherDiscounts_Number_Number_Number_Number", [accountID,year,month,profile]);
    }

    public async deleteEmployeeDiscount(idDiscount:any): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "Integer_deleteEmployeeDiscount_Number", [idDiscount]);
    }


}