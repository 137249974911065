import { useEffect, useState } from "react";
import { GarantiasService } from "../../../core/services/GarantiasService";
import { Singleton } from "../../../core/services/Singleton";
import { ListDocuments } from "../../contract/ListDocuments";
import { useDashboardStore } from "../../pages/hooks/useDashboardStore";

interface Props {
  idRealesCredit: number;
  _obj: any;
}

export const RealesCVDocs = ({ idRealesCredit, _obj }: Props) => {

  const single = Singleton.getInstance()
  const { setLoading } = useDashboardStore();
  const service = new GarantiasService();
  const [lista, setLista] = useState<any>([]);
  const [bean, setBean] = useState<any>({});
  const [user, setUser] = useState<any>({});

  useEffect(() => {
    setUser(single.getAccount());
    setBean(_obj);
    getLista();
  }, [idRealesCredit, _obj])


  const getLista = () => {
    setLoading(true)
    service.getRealesCVCatalogPorPropiedades({ IDRealesCredit: idRealesCredit }, null).subscribe(
      (resp: any) => {
       //console.log(resp)
        if (resp.DataBeanProperties.ObjectValue) {
          let tmp = resp.DataBeanProperties.ObjectValue;
          tmp.forEach((element: any) => {
            if (element.DataBeanProperties.Attach !== null, element.DataBeanProperties.Attach !== undefined) {
              element.DataBeanProperties.Document = JSON.parse(element.DataBeanProperties.Attach);
            }
          });
          setLista(tmp)
        }
        setLoading(false)
      }
    )
  }

  const updateReaalesCV = (doc: any) => {
    let x = JSON.stringify(doc);
    let document = {
      IDRealesCredit: idRealesCredit,
      IDEmployee: user.IDAccount,
      LastUpdate: single.getCurrenDate(),
      Attach: x,
      BasicData: bean.DataBeanProperties?.BasicData,
    }

    setLoading(true);
    service.updateRealesCV(document).subscribe(
      (resp: any) => {
        setLoading(false);
        if (resp.DataBeanProperties.ObjectValue) {
         //console.log("documento agregado");
        } else {
         //console.log("intentelo nuevamente");
        }
        getLista();
        single.update(doc);
      });
  }

  const deleteRealesCV = (doc: any) => {
   //console.log(doc)
    setLoading(true);
    service.deleteRealesCV(doc.DataBeanProperties.IDRealesCV).subscribe(
      (resp: any) => {
        setLoading(false)
        if (resp.DataBeanProperties.ObjectValue) {
          single.delete(doc.DataBeanProperties.IDRealesCV, doc.DataBeanProperties)
        }
        getLista();
      }
    )
  }

  return (
    <>
      <strong>Adjuntos</strong>
      <ListDocuments ListDocuments={lista} DeleteItem={(i: any) => { deleteRealesCV(i) }} updateList={(e: any) => { updateReaalesCV(e) }} />
    </>
  )
}