import { useEffect, useState } from 'react';
import { Modal } from '../../theme/Component/Modal';
import { ArticleClassCodeTree } from './ArticleClassCodeTree';
import useDataBean from '../../../core/services/dataBean/useDataBean';
import { ArticleClassCode } from '../../../core/services/dataBean/contabilidad/ArticleClassCode.Entity';

interface Props {
    onChange: any;
    label?: string;
    articleClass?: number;
    IDLn?: number;
}
export const SelectArticleClassCodeTree = ({ IDLn, onChange, label, articleClass }: Props) => {
    const treeClass = new ArticleClassCode("QuickDataERPCore", "com.quickdataerp.bean.supplier.ArticleClassCode");
    const { instance } = useDataBean(treeClass)

    const [modal, setModal] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [valor, setValor] = useState('');


    // useEffect(() => {
    //     if (IDLn) {
    //         getArticleClasCode();
    //     }
    // }, [IDLn])

    // const getArticleClasCode = () => {
    //     instance.getArticleClassCodeByKey(IDLn).then(
    //         (resp) => {
    //            //console.log(resp)
    //         }
    //     ).catch(
    //         err => {
    //            console.error(err)
    //         }
    //     )
    // }

    const getItem = () => {
        let bean: any = JSON.parse(localStorage.getItem('articleclasscodeTree') || '{}');
        onChange(bean);
        setValor(bean.JsonCode + " - " + bean.Name);
        setModal({ ...modal, "view": false });
    }

    return (
        <>
            <label>{"Catálogo de Clasificación de Bienes / Servicios"}</label>
            <span className="form-control text-wrap " style={{ minHeight: '35px' }} onClick={() => setModal({ ...modal, "view": true })}>{valor}</span>

            {modal.view &&
                <Modal onSubmitLabel='Seleccionar' modal={modal} updateModal={setModal} eventModal={getItem}>
                    <ArticleClassCodeTree edit={false} id={null} _articleClas={articleClass} />
                </Modal>
            }
        </>
    )
}