import { useEffect, useState } from "react";
import useDataBean from "../../../../../core/services/dataBean/useDataBean";
import { Singleton } from "../../../../../core/services/Singleton";
import { toast } from "react-toastify";
import { ListMenu } from "../../../../theme/Component/ListMenu";
import { BasicPage } from "../../../../theme/Component/BasicPage";
import { BasicTable } from "../../../../theme/Component/BasicTable";
import { Modal } from "../../../../theme/Component/Modal";
import { ModalConfirm } from "../../../../theme/Component/ModalConfirm";
import { _ConfigAdmin } from "../../../../../core/services/dataBean/EntityCatalogVdos";
import { PrintJson } from "../../../../theme/Component/PrintJson";
import { BasicSwitch } from "../../../../theme/Component/BasicSwitch";
import { AccountEditor } from "../../../../editor/AccountEditor";
import { TaxHoldeditor } from "../../../../Talentohumano/ParametrosTalentoHumano/EmployeePayments/EmployeePayments.editor";
import { SelectonfigAdminbyaccount } from "./SelectonfigAdminbyaccount";
import { SelectonfigAdminbycargo } from "./SelectconfigAdminbycargo";

interface Props {
    functionaID: any;
}

export const ConfigAdminFunctionalID = ({ functionaID }: Props) => {

    const ConfigAdmin = useDataBean(_ConfigAdmin);
    const single = Singleton.getInstance();
    const [IdAcoount, setIdAcoount] = useState<number>(null)
    const [lista, setLista] = useState<any>([]);
    const [properties, setProperties] = useState<any>({});
    const [appoGroup, setAppoGroup] = useState<number>(null)
    const [modal, setModal] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalC, setModalC] = useState<any>({ name: "Eliminar", tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });

    useEffect(() => {
        getLista();
        ConfigAdmin.setBean(functionaID)
    }, [])

    const getLista = () => {

        ConfigAdmin.instance.getFunctionalIDProperties(functionaID.IDLn).then(
            (resp: any) => {
                if (resp) {
                    // setLista(resp)
                    setProperties(resp);
                    let list = [];
                    list.push({
                        DataBeanProperties: resp
                    });
                    ConfigAdmin.setLista(list)
                   //console.log(resp)
                }
            }
        )

    }

    const create = () => {

        setModal({ ...modal, "view": true });
    }
    const deleteItem = () => {
        ConfigAdmin.deleteItemVoid(ConfigAdmin.bean.IDEntailment).then(
            (resp: any) => {
                getLista();
                setModalC({ ...modalC, "name": `Agregar Nivel Jerárquico de Empleo`, "view": false });
            }
        )

    }
    // const editaItem = () => {
    //    //console.log(ConfigAdmin.bean);
    //     ConfigAdmin.update().then(
    //         (resp: any) => {
    //             getLista();
    //             setModal({ ...modal, "view": false });
    //         }
    //     ).catch(err => {
    //         toast.error(err);
    //     })

    // }

    const editaItem = () => {
        ConfigAdmin.instance.updateFunctionalIDProperties(ConfigAdmin.bean).then(
            (resp: any) => {
                getLista();
                setModal({ ...modal, "view": false });
            }
        ).catch(err => {
            toast.error(err);
        })
    }




    const head = [
        { title: 'ID', property: 'IDLn', visible: true },
        { title: 'Jefe del Área', property: 'UseFunctionalAreaAsBoss', visible: true },
        { title: 'Jefe de Área Funcional', property: 'BossName', visible: true },

    ]

    const iconButtonsItem = [
        { nombre: 'Agregar', visible: single.canCreate(), icono: 'ri-file-add-line', evento: create }
    ]
    const listaBotones = () => {
        const botones = [
            { titulo: 'Editar', icono: 'ri-edit-2-line', visible: single.canEdit(), evento: () => { setModal({ ...modal, "name": `Editar ${ConfigAdmin.bean.Name}`, "view": true }) } },
            { titulo: 'Eliminar', icono: 'ri-delete-bin-line', visible: single.canDelete(), evento: () => { setModalC({ ...modalC, "view": true }) } },

        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }


    return (
        <BasicPage titulo="Configurar Jefe de Área">
            <div className="row">
                <div className="col-md-12">
                    {/* <PrintJson json={ConfigAdmin.bean} />
                    <PrintJson json={properties} /> */}
                    <BasicTable body={ConfigAdmin.lista} head={head} rowSet={ConfigAdmin.setBean} listButtons={listaBotones()} iconButtons={iconButtonsItem}></BasicTable>
                    <Modal modal={modal} updateModal={setModal} eventModal={editaItem}>
                        <div className="row">

                            {/* <div className="col-md-6">
                                <label>Código</label>
                                <input type="text" name="Code" className="form-control" value={ConfigAdmin.bean.Code} onChange={ConfigAdmin.handleChange} />
                            </div> */}

                            <div className="col-6">
                                <BasicSwitch label="Jefe del Área Funcional desde Planta" estado={ConfigAdmin.bean.UseFunctionalAreaAsBoss} eventChange={(e) => { ConfigAdmin.setBean({ ...ConfigAdmin.bean, "UseFunctionalAreaAsBoss": e }) }} />
                            </div>
                            {
                                ConfigAdmin.bean.UseFunctionalAreaAsBoss ? (
                                    <>
                                        <div className="row">
                                            <label>Buscar por Tercero</label>
                                            <div className="col-md-6">

                                                <AccountEditor label="Beneficiario" idAccount={IdAcoount} onChange={(e) => { setIdAcoount(e.IDAccount) }} />

                                            </div>
                                            <div className="col-md-6">
                                                <SelectonfigAdminbyaccount Acccount={IdAcoount} idSelector={ConfigAdmin.bean.IDLnFunctionalAreaBoss} onChange={(e) => { ConfigAdmin.setBean({ ...ConfigAdmin.bean, "IDLnFunctionalAreaBoss": e }) }} />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <label>Buscar por Cargo</label>
                                            <div className="col-md-6">
                                                <label>Nombre del Cargo</label>
                                                <input type="text" name="Name" className="form-control" value={ConfigAdmin.bean.Name} onChange={ConfigAdmin.handleChange} />
                                            </div>
                                            <div className="col-md-6">
                                                <SelectonfigAdminbycargo Name={ConfigAdmin.bean.Name} idSelector={ConfigAdmin.bean.IDLnFunctionalAreaBoss} onChange={(e) => { ConfigAdmin.setBean({ ...ConfigAdmin.bean, "IDLnFunctionalAreaBoss": e }) }} />
                                            </div>
                                        </div>
                                    </>

                                ) : (
                                    <div className="row">
                                        <div className="col-md-12">
                                            <AccountEditor label="Tercero" idAccount={ConfigAdmin.bean.IDBoss} onChange={(e) => { ConfigAdmin.setBean({ ...ConfigAdmin.bean, "IDBoss": e.IDAccount }) }}></AccountEditor>
                                        </div>
                                    </div>
                                )
                            }

                        </div>
                    </Modal>
                    <ModalConfirm modal={modalC} updateModal={setModalC} eventModal={deleteItem}>
                        <strong>Eliminar item {ConfigAdmin.bean.Name}</strong>
                    </ModalConfirm>
                </div>

            </div>
        </BasicPage>
    )
}