import { useEffect, useState } from 'react';
import { Modal } from '../../../theme/Component/Modal';
import { FunctionalAreeaStateEditor } from './FunctionalAreeaState.editor';
import { SelectFunctionalID } from '../functionalID/SelectFunctionalID';
import { AppointmentEditor } from '../../../Talentohumano/ParametrosTalentoHumano/Appointment/Appointment.editor';
import { ConstractStateEditor } from '../../../Talentohumano/GestionTH/EmployeeContract/ComponentsContract/EditorsEmployeContrac/ConstractState.editor';
import { BasicButton } from '../../../theme/Component/BasicButton';
import { BasicTable, MASK_DATE, MASK_NUMBER } from '../../../theme/Component/BasicTable';
import useDataBean from '../../../../core/services/dataBean/useDataBean';
import { _FunctionalArea } from '../../../../core/services/dataBean/EntityCatalog';
import { Singleton } from '../../../../core/services/Singleton';
import { SelectSiteID } from '../SiteID/SelectSiteID';
import { AppointmentGroupManualEditor } from '../../../Talentohumano/ParametrosTalentoHumano/AppointmentGroupManual/AppointmentGroupManual.editor';
import { AppointmentManualEditor } from '../../../Talentohumano/ParametrosTalentoHumano/AppointmentManual/AppointmentManual.editor';
import { ARPRiskEditor } from '../../../Talentohumano/GestionTH/EmployeeContract/ComponentsContract/EditorsEmployeContrac/ARPRisk.editor';
import { EmploymentTypeEditor } from '../../../Talentohumano/GestionTH/EmployeeContract/ComponentsContract/EditorsEmployeContrac/EmploymentType.editor';
import { EmployeeContractTypeEditor } from '../../../Talentohumano/GestionTH/EmployeeContract/ComponentsContract/EditorsEmployeContrac/EmployeeContractType.editor';

interface Props {
    label?: string;
    onChange: Function;
    value?: any;
}
export const SelectFunctionalArea = ({ onChange, label, value }: Props) => {

    const [modal, setModal] = useState<any>({ name: 'Plazas Laborales', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalUpdate, setModalUpdate] = useState<any>({ name: 'Crear Plaza Laboral', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [lista, setLista] = useState<any>([]);
    const single = Singleton.getInstance();
    const functionalArea = useDataBean(_FunctionalArea);
    const [state, setState] = useState<number>(3);
    const [contracState, setcontracState] = useState<number>(null);
    const [appointment, setAppointment] = useState<number>(null);
    const [funtional, setFuntional] = useState<number>(null);
    const [name, setName] = useState<string>(null);
    const [objectLn, setObjectLn] = useState<any>({});
    const [group, setGroup] = useState<number>(null);

    useEffect(() => {
        if (value?.IDLn !== objectLn.IDLn) {
            setObjectLn(value)
        }
    }, [value])

    const getLista = () => {
        functionalArea.instance.getFunctionalAreaCatalog(state, name, funtional, appointment, contracState).then(
            (resp) => {
                setLista(resp)
            }
        )
    }

    const getItem = () => {
        onChange(objectLn)
        setModal({ ...modal, "view": false });
    }

    const editaItem = () => {
        let x = functionalArea.bean;
        x.IDEmployeeLastUpdate = single.getAccountID();
       //console.log(x)
        functionalArea.update().then(
            (resp) => {
               //console.log(resp)
                getLista()
                setModalUpdate({ ...modalUpdate, "view": false })
            }
        )
    }

    const create = () => {
        functionalArea.setBean({
            State: 3,
        })
        setModalUpdate({ ...modalUpdate, "name": "Agregar Plaza Laboral", "view": true })
    }

    const iconButtonsItem = [
        { nombre: 'Agregar', visible: single.canCreate(), icono: 'ri-file-add-line', evento: create }
    ]

    const head = [
        { title: 'ID Plaza', property: 'IDLn', mask: MASK_NUMBER, visible: true },
        { title: 'Código', property: 'Code', mask: MASK_NUMBER, visible: false },
        { title: 'Nombre', property: 'Name', visible: true },
        { title: 'Descripción', property: 'Description', visible: false },
        { title: 'Nivel Jerárquico', property: 'EntailmentName', visible: false },
        { title: 'Nivel Salarial', property: 'AppointmentName', visible: false },
        { title: 'Ubicación Geográfica de la Plaza', property: 'SiteIDName', visible: true },
        { title: 'Manual de Perfil y Funciones del Cargo', property: 'AppointmentManualName', visible: false },
        { title: 'Centro de Costo', property: 'CostingCenterCode', visible: false },
        { title: 'Riesgo Laboral', property: 'ARPRiskName', visible: true },
        { title: 'Unidad Organizacional', property: 'FunctionalIDName', visible: false },
        { title: 'Estado', property: 'StateName', visible: false },
        { title: 'IDContrato Laboral', property: 'IDContract', mask: MASK_NUMBER, visible: false },
        { title: 'Empleado', property: 'AccountName', visible: true },
        { title: 'Estado del Contrato', property: 'ContractStateName', visible: false },
        { title: 'Creado por', property: 'EmployeeName', visible: false },
        { title: 'Fecha de Creación', property: 'Since', mask: MASK_DATE, visible: true },
        { title: 'Última actualización', property: 'LastUpdateEmployeeName', visible: false },
        { title: 'Fecha Última Actualización', property: 'LastUpdate', visible: false },
    ]

    return (
        <>
            <label>{label ?? "Área Funcional"}</label>
            <span className="form-control text-wrap" style={{ minHeight: '35px' }} onClick={() => setModal({ ...modal, view: true })}>
                {objectLn.IDLn ? `${objectLn.IDLn} - ${objectLn.Name}` : "Seleccione una Plaza"}
            </span>

            {modal.view &&
                <Modal modal={modal} updateModal={setModal} eventModal={getItem}>
                    <>
                        <div className="row">
                            <div className="col-4">
                                <FunctionalAreeaStateEditor label="Estado" idSelector={state} onChange={(e) => { setState(e) }} />
                            </div>
                            <div className="col-4">
                                <label>Nombre de la plaza</label>
                                <input type="text" className="form-control" value={name} onChange={(e) => { setName(e.target.value) }} />
                            </div>
                            <div className="col-4">
                                <SelectFunctionalID label="Unidad Organizacional" onChange={(e) => { setFuntional(e.IDLn) }} />
                            </div>
                            <div className="col-4">
                                <AppointmentEditor label="Nivel Salarial" idSelector={appointment} onChange={(e) => { setAppointment(e) }} />
                            </div>
                            <div className="col-4">
                                <ConstractStateEditor idSelector={contracState} onChange={(e) => { setcontracState(e) }} />
                            </div>
                            <div className="col-12">
                                <BasicButton icon="" eventClick={getLista}>Buscar</BasicButton>
                            </div>
                        </div>
                        <div className="row">
                            <BasicTable iconButtons={iconButtonsItem} body={lista.reverse()} head={head} rowSet={(bean) => { setObjectLn(bean) }} />
                        </div>
                    </>
                </Modal>
            }

            {modal.view &&
                <Modal modal={modalUpdate} updateModal={setModalUpdate} eventModal={editaItem} >
                    <div className="row">
                        <div className="col-6">
                            <label>Código</label>
                            <input type="number" name="Code" className="form-control" value={functionalArea.bean.Code} onChange={functionalArea.handleChange} />
                        </div>
                        <div className="col-6">
                            <label>Nombre</label>
                            <input type="text" name="Name" className="form-control" value={functionalArea.bean.Name} onChange={functionalArea.handleChange} />
                        </div>
                        <div className="col-12">
                            <label>Descripción</label>
                            <textarea name="Description" className="form-control" value={functionalArea.bean.Description} onChange={functionalArea.handleChange} />
                        </div>
                        <div className="col-6">
                            <AppointmentEditor idSelector={functionalArea.bean.IDAppointment} onChange={(e) => { functionalArea.setBean({ ...functionalArea.bean, "IDAppointment": e }) }} />
                        </div>
                        <div className="col-6">
                            <SelectSiteID edit idLnSite={functionalArea.bean.IDLnSiteID} onChange={(e) => { functionalArea.setBean({ ...functionalArea.bean, "IDLnSiteID": e.IDLn }) }} />
                        </div>
                        <div className="col-6">
                            <AppointmentGroupManualEditor idSelector={group} onChange={(e) => { setGroup(e) }} />
                        </div>
                        <div className="col-6">
                            <AppointmentManualEditor appoGroup={group} idSelector={functionalArea.bean.IDAppointmentManual} onChange={(e) => { functionalArea.setBean({ ...functionalArea.bean, "IDAppointmentManual": e }) }} />
                        </div>
                        <div className="col-12">
                            <div className="col-12">
                                <label>Centro de Costo</label>
                                <textarea name="CostingCenterCode" className="form-control" value={functionalArea.bean.CostingCenterCode} onChange={functionalArea.handleChange} />
                            </div>
                        </div>
                        <div className="col-6">
                            <SelectFunctionalID functionalIDLn={functionalArea.bean.IDLnFunctionalID} onChange={(e) => { functionalArea.setBean({ ...functionalArea.bean, "IDLnFunctionalID": e.IDLn }) }} />
                        </div>
                        <div className="col-6">
                            <ARPRiskEditor idSelector={functionalArea.bean.ARPRisk} onChange={(e) => { functionalArea.setBean({ ...functionalArea.bean, "ARPRisk": e }) }} />
                        </div>
                        <div className="col-6">
                            <EmploymentTypeEditor idSelector={functionalArea.bean.IDEmploymentType} onChange={(e) => { functionalArea.setBean({ ...functionalArea.bean, "IDEmploymentType": e }) }} />
                        </div>
                        <div className="col-6">
                            <EmployeeContractTypeEditor idSelector={functionalArea.bean.IDEmployeeContractType} onChange={(e) => { functionalArea.setBean({ ...functionalArea.bean, "IDEmployeeContractType": e }) }} />
                        </div>
                        {/* <PrintJson json={functionalArea.bean} /> */}
                    </div>
                </Modal>
            }
        </>
    )
}