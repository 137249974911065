import { useEffect, useState } from "react";
import { _BankingEntity, _CompetitionType, _Entailment, _MainTheme, _PaymentType, _StrategicObjetive, _ThirdBankingAccount } from "../../../../core/services/dataBean/EntityCatalog";
import { Singleton } from "../../../../core/services/Singleton";
import { toast } from "react-toastify";
import { ListMenu } from "../../../theme/Component/ListMenu";
import { BasicPage } from "../../../theme/Component/BasicPage";
import { BasicTable } from "../../../theme/Component/BasicTable";
import { Modal } from "../../../theme/Component/Modal";
import { ModalConfirm } from "../../../theme/Component/ModalConfirm";
import useDataBean from "../../../../core/services/dataBean/useDataBean";

import { AccountEditor } from "../../../editor/AccountEditor";
import { BasicSwitch } from "../../../theme/Component/BasicSwitch";

export const BankingEntity = () => {

    const BankingEntity = useDataBean(_BankingEntity);
    const single = Singleton.getInstance();
    const [lista, setLista] = useState<any>([]);
    const [modal, setModal] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalC, setModalC] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });

    useEffect(() => {
        getLista();
    }, [])

    const getLista = () => {

        BankingEntity.instance.getBankingEntityCatalog(null).then(
            (resp: any) => {
                if (resp) {
                    setLista(resp)
                   //console.log(resp)
                }
            }
        )

    }

    const create = () => {
        BankingEntity.setBean({

        });
        setModal({ ...modal, "view": true });
    }
    const deleteItem = () => {
        BankingEntity.instance.deleteBankingEntity(BankingEntity.bean).then(
            (resp: any) => {
                getLista();
                setModalC({ ...modalC, "view": false });
            }
        )

    }
    const editaItem = () => {
       //console.log(BankingEntity.bean);
        BankingEntity.instance.updateBankingEntity(BankingEntity.bean).then(
            (resp: any) => {
                getLista();
                setModal({ ...modal, "view": false });
            }
        ).catch(err => {
            toast.error(err);
        })

    }

    const head = [
        { title: 'ID', property: 'IDBankingEntity', visible: true },
        { title: 'Código', property: 'Code', visible: true },
        { title: 'Entidad', property: 'IDAccount', visible: true },
        { title: 'Descripción', property: 'Name', visible: true },
        { title: 'Tipo', property: 'Type', visible: true },
        { title: 'Código 2', property: 'Code2' },
        { title: 'Código 3', property: 'Code3' },
        { title: 'Código 4', property: 'Code4' },
        { title: 'Código 5', property: 'Code5' },
        { title: 'Swift', property: 'SwiftCode' },
        { title: 'Abba', property: 'AbbaCode' },
        { title: 'Pais', property: 'Country' },
        { title: 'Direccion', property: 'MainAddress' },
        { title: 'Telefono', property: 'MainTel' },



    ]

    const iconButtonsItem = [
        { nombre: 'Agregar', visible: single.canCreate(), icono: 'ri-file-add-line', evento: create }
    ]
    const listaBotones = () => {
        const botones = [

            { titulo: 'Editar', icono: 'ri-edit-2-line', visible: single.canEdit(), evento: () => { setModal({ ...modal, "view": true }) } },
            { titulo: 'Eliminar', icono: 'ri-delete-bin-line', visible: single.canDelete(), evento: () => { setModalC({ ...modalC, "view": true }) } },

        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }


    return (
        <BasicPage titulo="Entidades Financieras">
            <div className="row">


            </div>
            <div className="row">
                <div className="col-md-12">

                    <BasicTable body={lista} head={head} rowSet={BankingEntity.setBean} listButtons={listaBotones()} iconButtons={iconButtonsItem}></BasicTable>
                    <Modal modal={modal} updateModal={setModal} eventModal={editaItem}>
                        <div className="row">

                            <div className="col-md-6">

                                <label>Código</label>
                                <input type="text" name="Code" className="form-control" value={BankingEntity.bean.Code} onChange={BankingEntity.handleChange} />
                            </div>
                            <div className="col-md-6">

                                <label>Descripción</label>
                                <input type="text" name="Name" className="form-control" value={BankingEntity.bean.Name} onChange={BankingEntity.handleChange} />
                            </div>
                            <div className="col-md-12">
                                <AccountEditor label="Entidad" idAccount={BankingEntity.bean.IDAccount} onChange={({ IDAccount }) => { BankingEntity.setBean({ ...BankingEntity.bean, "IDAccount": IDAccount }) }}></AccountEditor>
                            </div>

                            <div className="col-md-6">

                                <label>Tipo</label>
                                <input type="text" name="Type" className="form-control" value={BankingEntity.bean.Type} onChange={BankingEntity.handleChange} />
                            </div>
                            <div className="col-md-6">

                                <label>Código 2</label>
                                <input type="text" name="Code2" className="form-control" value={BankingEntity.bean.Code2} onChange={BankingEntity.handleChange} />
                            </div>
                            <div className="col-md-6">

                                <label>Código 3</label>
                                <input type="text" name="Code3" className="form-control" value={BankingEntity.bean.Code3} onChange={BankingEntity.handleChange} />
                            </div>
                            <div className="col-md-6">

                                <label>Código 4</label>
                                <input type="text" name="Code4" className="form-control" value={BankingEntity.bean.Code4} onChange={BankingEntity.handleChange} />
                            </div>
                            <div className="col-md-6">

                                <label>Código 5</label>
                                <input type="text" name="Code5" className="form-control" value={BankingEntity.bean.Code5} onChange={BankingEntity.handleChange} />
                            </div>
                            <div className="col-md-6">

                                <label>Swift</label>
                                <input type="text" name="SwiftCode" className="form-control" value={BankingEntity.bean.SwiftCode} onChange={BankingEntity.handleChange} />
                            </div>
                            <div className="col-md-6">

                                <label>Abba</label>
                                <input type="text" name="AbbaCode" className="form-control" value={BankingEntity.bean.AbbaCode} onChange={BankingEntity.handleChange} />
                            </div>
                            <div className="col-md-6">

                                <label>Pais</label>
                                <input type="text" name="Country" className="form-control" value={BankingEntity.bean.Country} onChange={BankingEntity.handleChange} />
                            </div>
                            <div className="col-md-6">

                                <label>Direccion</label>
                                <input type="text" name="MainAddress" className="form-control" value={BankingEntity.bean.MainAddress} onChange={BankingEntity.handleChange} />
                            </div>
                            <div className="col-md-6">

                                <label>Telefono</label>
                                <input type="text" name="MainTel" className="form-control" value={BankingEntity.bean.MainTel} onChange={BankingEntity.handleChange} />
                            </div>



                        </div>
                    </Modal>
                    <ModalConfirm modal={modalC} updateModal={setModalC} eventModal={deleteItem}>
                        <strong>Eliminar item</strong>
                    </ModalConfirm>
                </div>

            </div>
        </BasicPage>
    )
}