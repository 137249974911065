import { useEffect, useState } from "react";
import { Singleton } from "../../../core/services/Singleton";
import { ExportXLSL } from "../../theme/Component/ExportXLSX";
import useDataBean from "../../../core/services/dataBean/useDataBean";
import { _ApplicationID } from "../../../core/services/dataBean/EntityCatalog";
import { toast } from "react-toastify";

interface Props
{
    idApplication:number;
}

export const ExportApplicationID=({idApplication}:Props)=>{

    const service = useDataBean(_ApplicationID);
    const single = Singleton.getInstance();
    const [lista, setLista] = useState([]);
    const [type, setType] = useState(5);
    const [tipo,setTipo] = useState([
        {id:5,name:'Menú Principal'},
        {id:8,name:'Menú Opciones'},
    ])

    const head = [
        { title: 'ID', property: type == 5 ? 'IDLn' :'IDRemoteAbstractAction', visible: false },                
        { title: 'TipoAplicacion', property: 'IDApplication' },
        { title: 'Menu', property: 'Menu' },
        { title: 'Componente', property: 'Name' },
        { title: 'Url', property: 'URL' },
        { title: 'Description', property: 'Description' },
        { title: 'Recursos Humano', property: 'Description1' },
        { title: 'Activos fijos-inventarios', property: 'Description1' },
        { title: 'Contabilidad', property: 'Description1' },
        { title: 'Compras', property: 'Description1' },
        { title: 'Presupuesto', property: 'Description1' },
        { title: 'Core', property: 'Description1' },
        { title: 'Fiscal', property: 'Description1' },
    ]
    useEffect(()=>{
        getLista();
    },[type])

    const getLista =()=>{
        service.instance.getSystemMenuByType(type,{IDApplication:idApplication}).then(
            (resp:any)=>{
                setLista(resp);
                toast.success("Información cargada.");
            }
        ).catch(err=>toast.error(err));
    }


    return(
        <div className="row">  
            <div className="col-md-12">
                <label>Tipo Menú</label>
                <select name="type" className="form-select" value={type} onChange={(e)=>{setType(parseInt(e.target.value))}}>
                    {tipo.map((item:any,index:number)=>{
                        return(
                            <option key={index} value={item.id}>{item.name}</option>
                        )
                    })}
                </select>
            </div>
            <div className="col-md-12">
              {lista.length>0 &&
                <ExportXLSL customName={'menu'} optionIcon="ri-download-cloud-2-line text-success" title={head} data={lista} onExport={(name: string) => {
                    single.download("formato xlsx [" + name + "] con " + lista.length + " registros");
                }} />
              }
            </div>
        </div>
    )
}