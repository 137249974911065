import { useEffect, useState } from 'react';
import { Modal } from '../../../theme/Component/Modal';
import { FunctionalID } from './FunctionalID';
import useDataBean from '../../../../core/services/dataBean/useDataBean';
import { _FunctionalID } from '../../../../core/services/dataBean/EntityCatalog';
interface Props {
    onChange: any;
    label?: string
    functionalIDLn?: number;
    functionalJsonCode?: string;
}
export const SelectFunctionalID = ({ onChange, label, functionalIDLn, functionalJsonCode }: Props) => {

    const { instance } = useDataBean(_FunctionalID);

    const [modal, setModal] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [valor, setValor] = useState('');

    useEffect(() => {
        if (functionalIDLn) {
            getFunctionalIDln();
        }
    }, [functionalIDLn])

    const getFunctionalIDln = () => {
        instance.getFunctionalIDByKey(functionalIDLn).then(
            (resp) => {
                onChange(resp);
                setValor(`${resp.IDLn} - ${resp.Name}`);
            }
        ).catch(
            err => {
                console.error(err)
            }
        )
    }

    useEffect(() => {
        if (functionalJsonCode) {
            getFunctionalJsonCode();
        }
    }, [functionalJsonCode])

    const getFunctionalJsonCode = () => {
        instance.getFunctionalIDByCode(functionalJsonCode).then(
            (resp) => {
                onChange(resp);
                setValor(`${resp.JsonCode} - ${resp.Name}`);
            }
        ).catch(
            err => {
                console.error(err)
            }
        )
    }

    const getItem = () => {
        let bean: any = JSON.parse(localStorage.getItem('FuntionalID') || '{}');
        onChange(bean);
        setValor(bean.Name);

        setModal({ ...modal, "view": false });
    }

    return (
        <>
            <label>{label ?? "Areas"}</label>
            <div className="form-control text-wrap " style={{ minHeight: '35px' }} onClick={() => setModal({ ...modal, "view": true })}>
                {valor ?
                    <span>{valor}</span>
                    :
                    <span>Seleccionar.....</span>
                }
            </div>

            <Modal onSubmitLabel='Seleccionar' modal={modal} updateModal={setModal} eventModal={getItem}>
                <FunctionalID edit={false} id={null} />
            </Modal>

        </>
    )
}