import { BasicPage } from "../theme/Component/BasicPage"
import { BasicTable, MASK_NUMBER } from '../theme/Component/BasicTable';
import { Singleton } from '../../core/services/Singleton';
import { ContractService } from '../../core/services/ContractService';
import { useState, useEffect } from 'react';
import { ModalConfirm } from '../theme/Component/ModalConfirm';
import { Modal } from '../theme/Component/Modal';
import { SelectModality } from "./select/SelectModality";
import { SelectLegalTypeTermsConstants } from "./select/SelectLegalTypeTermsConstants";
import { ListMenu } from "../theme/Component/ListMenu";
import { DocStage } from "./FlowHiring/DocStage";
import { useDashboardStore } from "../pages/hooks/useDashboardStore";

export const HiringModalityStage = () => {

    const single = Singleton.getInstance()
    const { setLoading } = useDashboardStore();
    const service = new ContractService();
    const [lista, setLista] = useState([]);
    const [listaTypeLegal, setListaTypeLegal] = useState([]);
    const [idTipo, setIDTipo] = useState(0);
    const [idLegalType, setIDLegalType] = useState(0);
    const [bean, setBean] = useState<any>({});
    const [modal, setModal] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalDocs, setModalDocs] = useState<any>({ name: 'Configurar Documentos', tabIndex: -1, view: false, btnClose: true, btnSubmit: false, format: 'modal-lg' });
    const [modalConfirm, setModalConfirm] = useState<any>({ name: 'Confirmar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });

    const [rolesCodes, setRolesCodes] = useState<any>([]);


    const [formData, setFormData] = useState<any>({
        IDHiringModality: idTipo,
        ContractualStageType: 0,
        RequirementTypeName: 0,
        Name: '',
        Description: '',
        DocumentDefeat: false,
        TermInDays: undefined,
        TermType: 0,
        ItemOrder: 0,
        Since: single.getCurrenDate()
    });

    const idBean = 'IDHiringModalityStage';


    const head = [
        { title: 'ID', property: idBean ,visible:false ,mask:MASK_NUMBER},
        { title: 'Orden', property: 'ItemOrder' },
        { title: 'Tipo de Etapa Contractual', property: 'ContractualStageTypeName' },
        { title: 'Nombre', property: 'Name' },
        { title: 'Descripción', property: 'Description' },
        { title: 'Término en días', property: 'TermInDays' },
        { title: 'Tipo de Término Legal', property: 'TermTypeName' },
    ]

    const setRowBean = (bean: any) => {
       //console.log("Row : ", bean);
        setFormData(bean);

    }

    useEffect(() => {
        // getRolesConstants();
        getContractualStageConstants();
    }, [])

    useEffect(() => {
        getLista();
    }, [idTipo])


    // const getRolesConstants = () => {
    //     service.getHiringConstants("HIRINGROLES").subscribe(
    //         (resp: any) => {
    //             if (resp.DataBeanProperties.ObjectValue) {
    //                 let arr: any = [];
    //                 let codes: any = [];
    //                 let temp = resp.DataBeanProperties.ObjectValue;
    //                 temp.forEach((element: any) => {
    //                     arr.push({
    //                         id: element.DataBeanProperties.Value,
    //                         name: element.DataBeanProperties.Property
    //                     })
    //                     codes[element.DataBeanProperties.Value] = element.DataBeanProperties.Property;
    //                 });
                    
    //                 setRolesCodes(arr);
                    
                    
    //             }

    //         }
    //     );
    // }

    const getContractualStageConstants = () => {

        setLoading(true);
        service.getContractualStageConstants(null).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue) {
                    setListaTypeLegal(resp.DataBeanProperties.ObjectValue);
                }
                setLoading(false);
            }
        );
    }
    const getLista = () => {

        setLoading(true);
        service.getHiringModalityStageCatalog(idTipo, null, null).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue.DataBeanProperties.List) {
                    let list = resp.DataBeanProperties.ObjectValue.DataBeanProperties.List;
                    list.forEach((element: any) => {
                        element.DataBeanProperties.TermTypeName = element.DataBeanProperties.TermType === 0 ? 'CALENDARIO' : 'HABIL';
                    });
                    setLista(list);
                } else {
                    setLista([]);
                }
                setLoading(false);
            }
        );
    }

    const agregarItem = () => {

        let b = {
            IDHiringModality: idTipo,
            ContractualStageType: 0,
            RequirementTypeName: 0,
            Name: '',
            Description: '',
            DocumentDefeat: false,
            TermInDays: 1,
            TermType: 0,
            ItemOrder: 99,
            Since: single.getCurrenDate()
        }

        setFormData(b);
        setModal({ ...modal, ['view']: true });

    }


    const eliminarItem = () => {

        setLoading(true);
        service.deleteHiringModalityStage(formData[idBean]).subscribe(
            (resp: any) => {
                setLoading(false);
                if (resp) {
                    getLista();
                }
                single.delete(formData[idBean], bean);
            }
        );
        setModalConfirm({ ...modalConfirm, ['view']: false });

    }
    const updateItem = () => {

        setModal({ ...modal, ['view']: false });

        setLoading(true);
        service.updateHiringModalityStage(formData).subscribe(
            (resp: any) => {
                setLoading(false);
                if (resp.DataBeanProperties.ObjectValue) {
                    getLista();
                }
                if (formData[idBean]) {

                    single.update(formData);

                }
                else {
                    single.create(formData);
                }
            });

    }

    const iconButtonsItem = [
        { nombre: 'Agregar', visible: single.canCreate(), icono: 'ri-file-add-line', evento: () => { agregarItem() } }
    ]

    const listaBotones = () => {
        const botones = [
            { titulo: 'Editar', icono: 'ri-edit-2-line', visible: single.canEdit(), evento: () => { setModal({ ...modal, "view": true }); } },
            { titulo: 'Eliminar', icono: 'ri-eraser-line', visible: single.canDelete(), evento: () => { setModalConfirm({ ...modalConfirm, "view": true }); } },
            { titulo: 'Requisitos', icono: 'ri-inbox-line', visible: single.canEdit(), evento: () => { setModalDocs({ ...modalDocs, "view": true }); } },
        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }

    const handleChange = (e: any) => {
        // Actualiza el estado con el nuevo valor del campo
        setFormData({
            ...formData,
            [e.target.name]: formatInput(e),
        });
    };


    const formatInput = (e: any) => {

        if (e.target.type == 'number')
            return parseInt(e.target.value ?? '0');
        else
            return e.target.value;
    }

    const handleSubmit = (e: any) => {
        e.preventDefault();
        // Aquí puedes realizar acciones con los datos del formulario
       //console.log('Datos enviados:', formData);
        updateItem();

    };

    return (
        <>
            <BasicPage titulo="Etapas Modalidad de Selección">

                <div className="row">

                    <div className="col-md-12">
                        <SelectModality idSelector={idTipo} onChange={(e: any) => { setIDTipo(e) }}></SelectModality>
                    </div>
                    <div className="col-md-12 p-2">
                        <BasicTable iconButtons={iconButtonsItem} listButtons={listaBotones()} head={head} body={lista} rowSet={setRowBean}></BasicTable>
                    </div>
                </div>

            </BasicPage>

            <ModalConfirm modal={modalConfirm} updateModal={setModalConfirm} eventModal={eliminarItem}>
                <>{formData.Name}</>
            </ModalConfirm>

            <Modal modal={modalDocs} updateModal={setModalDocs}
                eventModal={() => {
                    }}>
                <DocStage rolesCodes={rolesCodes} IDHiringModality={formData.IDHiringModality} IDHiringModalityStage={formData.IDHiringModalityStage} updateStage={(e: any) => { }} />
            </Modal>

            <Modal modal={modal} updateModal={setModal} eventModal={handleSubmit}>

                <form onSubmit={handleSubmit}>
                    <div className="row">
                        <div className="col-md-6">
                            <label>Orden</label>
                            <input type="number" className="form-control" name="ItemOrder" value={formData.ItemOrder} onChange={handleChange} />
                        </div>
                        <div className="col-md-6">
                            <label>Tipo de Etapa Contractual</label>
                            <select className="form-select" name="ContractualStageType" value={formData.ContractualStageType} onChange={handleChange}>
                                <option value={0}>...</option>
                                {listaTypeLegal.map((item: any) => {
                                    return (
                                        <option value={item.DataBeanProperties.Value}>{item.DataBeanProperties.Property}</option>
                                    )
                                })}
                            </select>
                        </div>

                        <div className="col-md-6">
                            <label htmlFor="nombre">Nombre:</label>
                            <input className="form-control" type="text" name="Name" value={formData.Name} onChange={handleChange} />
                        </div>

                        <div className="col-md-12">
                            <label >Descripción</label>
                            <textarea className="form-control" name="Description" value={formData.Description} onChange={handleChange}></textarea>
                        </div>

                        <div className="col-md-6">
                            <label >Término en días</label>
                            <input type="number" className="form-control" name="TermInDays" value={formData.TermInDays} onChange={handleChange} />
                        </div>

                        <div className="col-md-6">
                            <SelectLegalTypeTermsConstants idSelector={formData.TermType} onChange={(e: number) => { setFormData({ ...formData, "TermType": e }) }}></SelectLegalTypeTermsConstants>
                        </div>

                    </div>
                </form>
            </Modal>
        </>
    )
}