import { useEffect, useState } from "react"
import { BasicPage } from "../../theme/Component/BasicPage"
import { BasicButton } from "../../theme/Component/BasicButton";
import { Singleton } from "../../../core/services/Singleton";
import { ContractService } from "../../../core/services/ContractService";
import { BasicTable } from "../../theme/Component/BasicTable";
import { Modal } from "../../theme/Component/Modal";
import { UBLInvoiceList } from "../etapa-contrato/contrac/ublinvoice/UBLInvoiceList";
import { UBLSUPPLIER } from "../etapa-contrato/contrac/ContracInterface";
import { ListMenu } from "../../theme/Component/ListMenu";
import { ContractList } from "../etapa-contrato/contrac/ublinvoice/ContractList";
import { toast } from "react-toastify";
import useDataBean from "../../../core/services/dataBean/useDataBean";
import { _createSupplier } from "../../../core/services/dataBean/EntityCatalog";
import { PrintJson } from "../../theme/Component/PrintJson";
import { CreatedSupplier } from "./CreatedSupplier";
import { AbstractAccount } from "../../admin/configuracion/AbstractAccount";
import { BpmService } from "../../../core/services/BpmService";
import { AdminService } from "../../../core/services/AdminService";
import { useDashboardStore } from "../../pages/hooks/useDashboardStore";

export const SupplierSearch = () => {

    const single = Singleton.getInstance();
    const _service = new BpmService();
    const serviceAdmin = new AdminService();
    const service = new ContractService();
    const createSupplier = useDataBean(_createSupplier);
    const [type, setType] = useState<number>(0);
    const [idNit, setNIT] = useState(0);
    const [nit, setNit] = useState(0);
    const [account, setAccount] = useState<any>({});
    const [valor, setValor] = useState<any>('');
    const [bean, setBean] = useState<any>({});
    const [lista, setLista] = useState<any>([]);
    const { setLoading } = useDashboardStore();
    const [row, setRow] = useState<any>({});
    const [name, setName] = useState('');
    const [supplier, setSupplier] = useState<any>({});
    const [isInputEnabled, setIsInputEnabled] = useState<boolean>(false);
    const [modalEditar, setModalEditar] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modal, setModal] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalContract, setModalContract] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });

    useEffect(() => {
        setValor('');
        setLista([]);
    }, [type])

    useEffect(() => {
        setAccount(single.getAccount());
       //console.log(single.getAccount());
    }, []);


    const buscar = () => {
        single.spinner(true);
        if (type === 0) {
            service.getSupplierFindByIdentification(null, valor).subscribe(
                (resp: any) => {
                    if (resp.DataBeanProperties.ObjectValue) {
                        setLista(resp.DataBeanProperties.ObjectValue);
                    }
                    single.spinner(false);
                }
            );

        } else {
            service.getSupplierFindByName(null, valor).subscribe(
                (resp: any) => {
                    if (resp.DataBeanProperties.ObjectValue) {
                        setLista(resp.DataBeanProperties.ObjectValue);
                    }
                    single.spinner(false);
                }
            );
        }
    }


    const editaItem = () => {
       //console.log(createSupplier.bean);
        // createSupplier.instance.createSupplier(idNit, single.getAccountID(), createSupplier.bean).then(
        //     (resp: any) => {
        //         buscar()
        //         setModal({ ...modal, "view": false });
        //     }
        // ).catch(err => {
        //     toast.error(err);
        // })

    }

    const create = () => {
        createSupplier.setBean({
        });

        setModalEditar({ ...modalEditar, "view": true });
    }

    const agregarItem = () => {

        let b = {
            Nit: null,
            Name1: '',
            Name2: '',
            Surname1: '',
            Surname2: '',
            Tel: '',
            eMail: '',
        }
        setBean(b);
        setModalEditar({ ...modalEditar, ['view']: true });
    }


    const head = [
        { title: 'ID', property: 'IDSupplier', visible: false },
        { title: 'Identificación', property: 'nit', visible: true },
        { title: 'Nombre', property: 'Name1', visible: true },
        { title: 'Email', property: 'eMail', visible: true },
        { title: 'Dirección', property: 'address', visible: true },
        { title: 'Telefono', property: 'tel', visible: true },
        { title: 'Contacto', property: 'ContactName', visible: true },

    ]

    // const iconButtonsItem = [

    //     { nombre: 'Agregar', visible: single.canCreate(), icono: 'ri-file-add-line', evento: agregarItem }

    // ]
    const listaBotones = () => {
        const botones = [
            {
                titulo: 'Ver Facturas', icono: 'ri-file-zip-line', visible: true, evento: () => {
                    setModal({ ...modal, "view": true, "name": "Documentos" });

                }
            },
            {
                titulo: 'Ver Contratos', icono: 'ri-folder-2-line', visible: true, evento: () => {
                    setModalContract({ ...modalContract, "view": true, "name": "Contratos" });

                }
            },
        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }

    const getListaBN2 = (cc: any) => {
        setLoading(true);
        service.getAccountByNit(cc).subscribe(
            (resp: any) => {

                if (resp.DataBeanProperties.ObjectValue) {
                    let temp = resp.DataBeanProperties.ObjectValue;
                    if (temp.length > 0) {
                        setBean(temp[0].DataBeanProperties);
                    }
                }
                setLoading(false);
            }
        );
    }



    const updateItem = () => {
        setModalEditar({ ...modalEditar, "view": false });
        if (bean.IDAccount) {
            setLoading(true);
            _service.updateAbstractAccount(bean).subscribe(
                (resp: any) => {
                    setLoading(false);
                    if (resp.DataBeanProperties.ObjectValue) {
                        toast.success('Se ha Actualizado la Cuenta')
                    }
                });
        } else {
            setLoading(true);
            _service.createAccount(bean).subscribe(
                (resp: any) => {
                    setLoading(false);
                    if (resp.DataBeanProperties.ObjectValue) {
                        toast.success('Se ha creado la cuenta')
                    }
                });
        }
    }

    return (
        <BasicPage titulo="Proveedores">
            <div className="row">
                <div className="col-md-4">
                    <label>Buscar por</label>
                    <select className="form-control" value={type} onChange={(e) => { setType(parseInt(e.target.value)) }}>
                        <option value={0}>Identificación</option>
                        <option value={1}>Nombre</option>
                    </select>
                </div>
                <div className="col-md-4">
                    <label>Valor</label>
                    <input type={type === 0 ? 'number' : 'text'} className="form-control" value={valor} onChange={(e) => { setValor(e.target.value) }} />
                </div>
                <div className="col-md-4 mt-3">
                    <BasicButton icon="" eventClick={buscar}>Buscar</BasicButton>
                </div>
                <div className="col-md-12">
                    {/* <PrintJson json={lista}/> */}
                    <BasicTable listButtons={listaBotones()} head={head} body={lista} rowSet={setRow} ></BasicTable>
                </div>
                <div className="col-md-12">
                    <Modal modal={modal} updateModal={setModal} >
                        <UBLInvoiceList type={UBLSUPPLIER} formData={
                            {
                                IDAccountSupplier: row.IDAccount,
                                IDAdquisitionContract: null,
                                From: null,
                                Upto: null,
                                State: null
                            }
                        } importUBL={false} />
                    </Modal>
                    <Modal modal={modalContract} updateModal={setModalContract} >
                        <ContractList idSupplier={row.IDSupplier} />
                    </Modal>
                    <Modal modal={modalEditar} updateModal={setModalEditar} eventModal={updateItem} >
                        <>

                            <div className="row">
                                <div className="col-md-12">
                                    <label className="form-label">No. Identificación *</label>
                                    <input type="text" className="form-control" value={bean.Nit} onBlur={(e) => {
                                       //console.log("Valor cambiado ", e.target.value);
                                        getListaBN2(e.target.value);
                                    }} onChange={(e) => {
                                        setBean({ ...bean, ['Nit']: e.target.value })
                                    }} />
                                </div>
                                <div className="col-md-6">
                                    <label className="form-label">Primer Nombre *</label>
                                    <input type="text" className="form-control" value={bean.Name1} onChange={(e) => { setBean({ ...bean, ['Name1']: e.target.value }) }} />
                                </div>
                                <div className="col-md-6">
                                    <label className="form-label">Segundo Nombre </label>
                                    <input type="text" className="form-control" value={bean.Name2} onChange={(e) => { setBean({ ...bean, ['Name2']: e.target.value }) }} />
                                </div>
                                <div className="col-md-6">
                                    <label className="form-label">Primer Apellido *</label>
                                    <input type="text" className="form-control" value={bean.Surname1} onChange={(e) => { setBean({ ...bean, ['Surname1']: e.target.value }) }} />
                                </div>

                                <div className="col-md-6">
                                    <label className="form-label">Segundo Apellido *</label>
                                    <input type="text" className="form-control" value={bean.Surname2} onChange={(e) => { setBean({ ...bean, ['Surname2']: e.target.value }) }} />
                                </div>
                                <div className="col-md-6">
                                    <label className="form-label">Celular *</label>
                                    <input type="text" className="form-control" value={bean.Tel} onChange={(e) => { setBean({ ...bean, ['Tel']: e.target.value }) }} />
                                </div>
                                <div className="col-md-6">
                                    <label className="form-label">Correo Electronico *</label>
                                    <input type="text" className="form-control" value={bean.eMail} onChange={(e) => { setBean({ ...bean, ['eMail']: e.target.value }) }} />
                                </div>
                                {/* <div className="col-md-12">                    
                        <label className="form-label">Lugar de expedición de documento *</label>
                        <input type="text" className="form-control" value={bean.Name} onChange={(e)=>{setBean({...bean,['Name']:e.target.value})}}/>
                    </div>                                                                                       */}

                            </div>
                        </>
                    </Modal>
                </div>
            </div>
        </BasicPage>
    )
}