import { useEffect, useState } from 'react';
import { Modal } from '../../../theme/Component/Modal';
import { AccountantTree } from './AccountantTree';
import useDataBean from '../../../../core/services/dataBean/useDataBean';
import { _AccountantID, _single } from '../../../../core/services/dataBean/EntityCatalog';


interface Props {
    onChange: any;
    value?: any;
    jsonCode?: string;
    idlnAccountant?: number;
    idAccountant?: number;
    label?: string;
    disabled?: any
}
export const SelectAccountantTree = ({ idAccountant, idlnAccountant, onChange, label, jsonCode }: Props) => {

    const { instance } = useDataBean(_AccountantID)

    const [modal, setModal] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [valor, setValor] = useState('');

    useEffect(() => {
        if (idlnAccountant) {
            getBudgetIDLn()
        }
    }, [idlnAccountant])

    const getBudgetIDLn = () => {

        instance.getAccountantIDByKey(idlnAccountant).then(
            (resp) => {
                setValor(`${resp.JsonCode ?? ""} - ${resp.Name ?? ""}`)
                onChange(resp)
            }
        ).catch(
            err => {
               console.error(err)
            }
        )
    }

    useEffect(() => {
        if (idAccountant) {
            getBudgetId()
        }
    }, [idAccountant])

    const getBudgetId = () => {
        instance.getAccountantID(idAccountant).then(
            (resp) => {
                setValor(`${resp.JsonCode ?? ""} - ${resp.Name ?? ""}`)
                onChange(resp)
            }
        ).catch(
            err => {
               console.error(err)
            }
        )
    }

    useEffect(() => {
        if (jsonCode) {
            getBudgetJson()
        }
    }, [jsonCode])


    const getBudgetJson = () => {
        instance.getAccountantIDByCode(jsonCode).then(
            (resp) => {
                setValor(`${resp.JsonCode ?? ""} - ${resp.Name ?? ""}`)
                onChange(resp)
            }
        ).catch(
            err => {
               console.error(err)
            }
        )
    }

    const getItem = () => {
        let bean: any = JSON.parse(localStorage.getItem('accountantTree') || '{}');
        onChange(bean);
        setValor(bean.JsonCode + " - " + bean.Name);
        setModal({ ...modal, "view": false });
    }

    return (
        <>
            {label != '' && <label>{label}</label>}
            <div className="form-control text-wrap " style={{ minHeight: '35px' }} onClick={() => setModal({ ...modal, "view": true })}>
                {valor ?
                    <span>{valor}</span>
                    :
                    <span>Seleccionar...</span>
                }
            </div>
            <Modal onSubmitLabel='Seleccionar' modal={modal} updateModal={setModal} eventModal={getItem}>
                <AccountantTree edit={false} id={null} />
            </Modal>

        </>
    )
}