import { useEffect, useState } from "react"
import { _AcademicInformation, _single } from "../../../../core/services/dataBean/EntityCatalog"
import useDataBean from "../../../../core/services/dataBean/useDataBean"
import { BasicPage } from "../../../theme/Component/BasicPage"
import { ListMenu } from "../../../theme/Component/ListMenu"
import { BasicTable, MASK_DATE } from "../../../theme/Component/BasicTable"
import { ModalConfirm } from "../../../theme/Component/ModalConfirm"
import { Modal } from "../../../theme/Component/Modal"
import { AccountEditor } from "../../../editor/AccountEditor"
import { InputDate } from "../../../theme/Input/InputDate"
import { FileInput } from "../../../theme/Component/FileInput"
import { SelectTHConstantsValue } from "../../../bpm/config/Componentes/SelectTHConstantsValue"
import { FileService } from "../../../../core/services/FileService"
import { BasicButton } from "../../../theme/Component/BasicButton"
import { MenuDashed } from "../../../pages/Dashed/components/MenuDashed/MenuDashed"
import { PrintJson } from "../../../theme/Component/PrintJson"

interface Props {
    dashedMenu?: boolean;
}

export const AcademicInformation = ({ dashedMenu }: Props) => {

    const { lista, setLista, bean, setBean, instance, update, handleChange } = useDataBean(_AcademicInformation);
    const fileService = new FileService();

    const [account, setAccount] = useState<number>(dashedMenu ? _single.getAccountID() : NaN);
    const [document, setDocument] = useState<any>({});

    const [modal, setModal] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalC, setModalC] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });
    const [modalA, setModalA] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });

    useEffect(() => {
        getLista()
    }, [])

    const getLista = () => {
        const Props = {
            State: 1,
            IDAccount: account,
        }
        instance.getAcademicInformationCatalogRender(null, Props).then(
            (rta: any) => {
                console.log(rta)
                rta.forEach((element) => {
                    if (element.DataBeanProperties.QualificationMedia) {
                        let doc = JSON.parse(element.DataBeanProperties.QualificationMedia);
                        if (doc.Media) {
                            element.DataBeanProperties.RespDoc = `<a href="${fileService.getUrlFile(element.DataBeanProperties.MediaContext, element.DataBeanProperties.Media)}" target='_blank'>${doc.Name ?? "Ver Documento"}</a>`
                        }
                    } else {

                    }
                });
                setLista(rta)
            }
        ).catch(
            err => { console.log(err) }
        )
    }

    const editaItem = () => {
        update().then(
            (resp) => {
                setModal({ ...modal, "view": false });
                setModalC({ ...modalC, "view": false });
                setModalA({ ...modalA, "view": false });
                getLista();
            }
        ).catch(
            err => { console.log(err) }
        )
    }

    const deleteItem = () => {
        let objet = bean;
        objet.State = 2;
        objet.IDEmployeeDelete = _single.getAccountID();
        setBean(objet);

        editaItem();
    }

    const aprobeItem = () => {
        let objet = bean;
        objet.IsApproved = true;
        objet.IDemployeeApproved = _single.getAccountID();
        setBean(objet);

        editaItem();
    }

    const head = [
        { title: 'ID', property: 'IDAcademicInformation', visible: true },
        { title: 'Usuario', property: 'EntityName', visible: true },
        { title: 'Nivel de Estudio', property: 'LevelStudyName', visible: true },
        { title: 'Institución Educativa', property: 'InstitutionName', visible: true },
        { title: 'Titulo Obtenido', property: 'Qualification', visible: true },
        { title: 'Estado del Estudio', property: 'QualificationState', visible: true },
        { title: 'Adjuntos', property: 'RespDoc', visible: true },
        { title: 'Esta Aprobada', property: 'IsApproved', visible: true },
        { title: 'Fecha de Inicio', property: 'FromDate', visible: true, mask: MASK_DATE },
        { title: 'Fecha de Finalización', property: 'UptoDate', visible: true, mask: MASK_DATE },
    ]

    const create = () => {
        let x = {
            State: 1,
            IDEmployee: _single.getAccountID(),
            IsApproved: false,
            IDAccount: dashedMenu ? _single.getAccountID() : NaN,
        }

        setBean(x);
        setDocument({ Media: "", MediaContext: "" })
        setModal({ ...modal, "view": true, "name": "Crear Información Academica" });
    }

    const iconButtonsItem = [
        { nombre: 'Agregar', visible: (_single.canEdit() || dashedMenu), icono: 'ri-file-add-line', evento: create }
    ]

    const listaBotones = () => {
        const botones = [
            { titulo: 'Editar', icono: 'ri-edit-2-line', visible: (_single.canEdit() || dashedMenu && !bean.IsApproved), evento: () => { setModal({ ...modal, "view": true }) } },
            { titulo: 'Eliminar', icono: 'ri-delete-bin-line', visible: (_single.canEdit() || dashedMenu), evento: () => { setModalC({ ...modalC, "view": true, "name": `Eliminar Informacion Academica` }) } },
            { titulo: 'Aprobar', icono: 'ri-check-line', visible: (_single.canDelete() && !bean.IsApproved), evento: () => { setModalA({ ...modalA, "view": true, "name": "Aprobar Información Academica" }) } },
        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }

    const setRowBean = (objeto: any) => {
        setBean(objeto);
        if (objeto.QualificationMedia) {
            let doc = JSON.parse(objeto.QualificationMedia)
            setDocument(doc);
        } else {
            setDocument({})
        }
    }

    return (
        <div className="row">
            <div className="col-md-12">
                {dashedMenu ?
                    <div className="row">
                        <div className="col-md-12">
                                <BasicTable listButtons={listaBotones()} head={head} body={lista} rowSet={setRowBean} iconButtons={iconButtonsItem} />
                        </div>
                    </div>
                    :
                    <BasicPage titulo="Información Academica" >
                        <div className="row">
                            <div className="col-md-6">
                                <AccountEditor idAccount={account} onChange={(e) => { setAccount(e.IDAccount) }} label="Usuario" />
                            </div>
                            <div className="col-md-4 mt-4">
                                <BasicButton icon="ri-search-line" eventClick={getLista} >Buscar</BasicButton>
                            </div>

                            <div className="col-md-12">
                                <BasicTable listButtons={listaBotones()} head={head} body={lista} rowSet={setRowBean} iconButtons={iconButtonsItem} />
                            </div>
                        </div>
                    </BasicPage>

                }
            </div>

            <div className="col-md-12">
                <ModalConfirm modal={modalC} updateModal={setModalC} eventModal={deleteItem}>
                    <strong>Eliminar Informacion de {bean.IDAccount}</strong>
                </ModalConfirm>
            </div>

            <div className="col-md-12">
                <ModalConfirm modal={modalA} updateModal={setModalA} eventModal={aprobeItem}>
                    <strong>Aprobar Información Academica de {bean.IDAccount}</strong>
                </ModalConfirm>
            </div>

            <div className="col-md-12">
                <Modal modal={modal} updateModal={setModal} eventModal={editaItem}>
                    <div className="row">
                        {!dashedMenu &&
                            <div className="col-md-6">
                                <AccountEditor idAccount={bean.IDAccount} label="Empleado" onChange={({ IDAccount }) => { setBean({ ...bean, "IDAccount": IDAccount }) }} />
                            </div>
                        }
                        <div className="col-md-6">
                            <SelectTHConstantsValue code={103} value={bean.Institution} onChange={(e) => { setBean({ ...bean, "Institution": e }) }} label="Institución Educativa" />
                        </div>
                        <div className="col-md-6">
                            <SelectTHConstantsValue code={102} value={bean.LevelStudy} onChange={(e) => { setBean({ ...bean, "LevelStudy": e }) }} label="Nivel de Estudio" />
                        </div>
                        <div className="col-md-6">
                            <label>Titulo Obtenido</label>
                            <input className="form-control" name="Qualification" value={bean.Qualification} onChange={handleChange} type="text" />
                        </div>
                        <div className="col-md-6">
                            <label>Estado del Estudio</label>
                            <select className="form-select" value={bean.QualificationState} onChange={(e) => { setBean({ ...bean, "QualificationState": parseInt(e.target.value) }) }}>
                                <option value={NaN}>...</option>
                                <option value={100}>En Progreso</option>
                                <option value={101}>Finalizado</option>
                                <option value={102}>Incompleto</option>
                            </select>
                        </div>
                        <div className="col-md-6">
                            <FileInput
                                label='Adjuntos'
                                Media={document.Media ?? ""}
                                MediaContext={document.MediaContext ?? ""}
                                directory='AcademicInformation'
                                onCreate={({ Media, MediaContext, Name }) => {
                                    let document = {
                                        Name: Name,
                                        Media: Media,
                                        MediaContext: MediaContext,
                                    }
                                    setBean({ ...bean, "QualificationMedia": JSON.stringify(document) });
                                }}
                            />
                        </div>
                        <div className="col-md-12">
                            <div className="row">
                                <div className="col-md-6">
                                    <InputDate notDate label="Fecha de Inicio" name="FromDate" value={bean.FromDate} setDate={(e) => { setBean({ ...bean, "FromDate": e }) }} />
                                </div>
                                <div className="col-md-6">
                                    <InputDate notDate label="Fecha de Finalización" name="UptoDate" value={bean.UptoDate} setDate={(e) => { setBean({ ...bean, "UptoDate": e }) }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        </div >
    )
}



