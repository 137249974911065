import { useParams } from 'react-router-dom';
import { BasicPage } from './theme/Component/BasicPage';
import { useEffect, useState } from 'react';
import { _single } from '../core/services/dataBean/EntityCatalog';
import { RenderRemoteAction } from './RemoteAbstractAction/RenderRemoteAction';

export const RouterAbstractAction = (props: any) => {

    const { module, view,param }: any = useParams();
    const [remoteAction,setRemoteAction] = useState<any>({});
    
    useEffect(()=>{          
       //console.log(param);
                              
        setRemoteAction(JSON.parse(atob(param)));                
    },[param])

    return (
        <div className="px-5" >
            <BasicPage titulo={remoteAction.Name}>
                <div className="row">
                    <div className="col">
                            <RenderRemoteAction remoteAction={remoteAction}/>
                    </div>
                </div>

            </BasicPage>
        </div>
        
    );
}