import { DataBean } from "../DataBean"

export class BudgetSheetFactory extends DataBean {

    public async getInitialBudgetIDCatalog(year: number, idBudgetType: number, idEmployee: number): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_getInitialBudgetIDCatalog_Number_Number_Number", [year, idBudgetType, idEmployee]);
    }

    public async createInitialBudgetRow(idBudgetIDType: number, budgetIDStr: string, idBudgetResource: number, value: number, description: string): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "budget.Budget_createInitialBudgetRow_Number_String_Number_Number_String", [idBudgetIDType, budgetIDStr, idBudgetResource, value, description]);
    }

    public async importInitialBudgetRows(idBudgetIDType: number, filename: string, directory: string, dataStore: string): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "com.quickdataerp.bean.accountant.Movement_importInitialBudgetRows_Number_String_String_String", [idBudgetIDType, filename, directory, dataStore]);
    }

    public async createBudgetRowForReductionToInitialBudget(accountantDate: any, idBudgetType: any, budgetIDStr: string, value: number, description: string): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "budget.Budget_createBudgetRowForReductionToInitialBudget_java.util.Date_Number_String_Number_String", [accountantDate, idBudgetType, budgetIDStr, value, description]);
    }

    public async createInitialBudgetCatalog(idBudgetType: number, idBudgetIDType: number, year: number, items: any, description: string, idEmployee: number, createPdf: boolean): Promise<any> {
        return this.getCustomService(
            "QuickDataERPCore",
            "com.quickdataerp.bean.accountant.AccountantDocument_createInitialBudgetCatalog_Number_Number_Number_java.util.List_String_Number_Boolean",
            [idBudgetType, idBudgetIDType, year, items, description, idEmployee, createPdf]
        );
    }

}