import { useEffect, useState } from "react";
import { Singleton } from "../../../core/services/Singleton";
import { Modal } from "./Modal";
import { PrintJson } from "./PrintJson";
import { CellEditorRender } from "../../editor/CellEditorRender";

interface Props {
    head: any;
    cell: any;
    callBack: any;
    returnValue?: Function;
}

export const TableCell = ({ head, cell, callBack, returnValue }: Props) => {
    const single = Singleton.getInstance();
    const [modal, setModal] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: false, format: 'modal-lg' });

    useEffect(()=>{
        if(head.modalZise)
        {
            setModal({...modal,"format":head.modalZise});
        }
    },[head])

    const responseEditor = (e: any) => {
        
        setModal({ ...modal, "view": false });
        callBack(e);
    }

    return (
        <div className="row">
            <div className="col" >
                {head.cellEditor ?
                    <span onClick={() => { setModal({ ...modal, "view": true, "name": head.title }) }}
                        className="link-opacity-100 text-primary-emphasis"
                        style={{ ...head.Style, wordWrap: 'break-word', whiteSpace: 'normal' }}
                        dangerouslySetInnerHTML={{
                            __html: cell[head.property] !== null && cell[head.property] !== undefined
                                ? `<strong><u>${ single.formatFieldMask(cell[head.property], head.mask)}</u></strong>`
                                : '<strong>click para editar</strong>',
                        }}>
                    </span>
                    :
                    <div onClick={() => { returnValue(cell[head.property]) }} style={{ ...head.Style, wordWrap: 'break-word', whiteSpace: 'normal' }}
                        dangerouslySetInnerHTML={{ __html: single.formatFieldMask(cell[head.property], head.mask) }}></div>
                }
                <Modal modal={modal} updateModal={setModal}>
                    <div className="row">
                        <div className="col">
                            <CellEditorRender cellEditor={head.cellEditor} data={cell} callBack={responseEditor} />
                        </div>
                    </div>
                </Modal>
            </div>
        </div>
    );
};
