import { useEffect, useState } from "react";
import { _single, _WorkExperience } from "../../../../core/services/dataBean/EntityCatalog";
import useDataBean from "../../../../core/services/dataBean/useDataBean";
import { BasicPage } from "../../../theme/Component/BasicPage";
import { ListMenu } from "../../../theme/Component/ListMenu";
import { BasicTable, MASK_DATE, MASK_MONEY } from "../../../theme/Component/BasicTable";
import { ModalConfirm } from "../../../theme/Component/ModalConfirm";
import { Modal } from "../../../theme/Component/Modal";
import { AccountEditor } from "../../../editor/AccountEditor";
import { InputDate } from "../../../theme/Input/InputDate";
import { FileInput } from "../../../theme/Component/FileInput";
import { InputMoney } from "../../../theme/Input/InputMoney";
import { FileService } from "../../../../core/services/FileService";
import { BasicButton } from "../../../theme/Component/BasicButton";

interface Props {
    dashedMenu?: boolean;
}

export const WorkExperience = ({ dashedMenu }: Props) => {

    const { lista, setLista, bean, setBean, instance, update, handleChange } = useDataBean(_WorkExperience);
    const fileService = new FileService();

    const [account, setAccount] = useState<number>(dashedMenu ? _single.getAccountID() : NaN);
    const [document, setDocument] = useState<any>({});

    const [modal, setModal] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalC, setModalC] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });
    const [modalA, setModalA] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });

    useEffect(() => {
        getLista()
    }, [])

    const getLista = () => {
        const Props = {
            State: 1,
            IDAccount: account,
        }
        instance.getWorkExperienceCatalogRender(null, Props).then(
            (rta: any) => {
                rta.forEach((element) => {
                    if (element.DataBeanProperties.JobReferenceMedia) {
                        let doc = JSON.parse(element.DataBeanProperties.JobReferenceMedia);
                        if (doc.Media) {
                            element.DataBeanProperties.RespDoc = `<a href="${fileService.getUrlFile(element.DataBeanProperties.MediaContext, element.DataBeanProperties.Media)}" target='_blank'>${doc.Name ?? "Ver Documento"}</a>`
                        }
                    } else {

                    }
                });
                setLista(rta)
            }
        ).catch(
            err => { console.log(err) }
        )
    }

    const editaItem = () => {
        update().then(
            (resp) => {
                setModal({ ...modal, "view": false });
                setModalC({ ...modalC, "view": false });
                setModalA({ ...modalA, "view": false });
                getLista();
            }
        ).catch(
            err => { console.log(err) }
        )
    }

    const deleteItem = () => {
        let objet = bean;
        objet.State = 2;
        objet.IDEmployeeDelete = _single.getAccountID();
        objet.DeleteDate = _single.getCurrenDate();
        setBean(objet);

        editaItem();
    }

    const head = [
        { title: 'ID', property: 'IDWorkExperience', visible: true },
        { title: 'Usuario', property: 'EntityName', visible: true },
        { title: 'Nombre de la Empresa', property: 'CompanyName', visible: true },
        { title: 'Cargo Desempeñado', property: 'JobTitle', visible: true },
        { title: 'Motivo de Retiro', property: 'TerminationReason', visible: true },
        { title: 'Actividades', property: 'JobActivities', visible: true },
        { title: 'Ultimo Salario', property: 'LastSalary', visible: true, mask: MASK_MONEY },
        { title: 'Nombre Ultimo Jefe', property: 'LastBossName', visible: true },
        { title: 'Telefono Ultimo Jefe', property: 'PhoneLastBoss', visible: true },
        { title: 'Adjunto Referencia Laboral', property: 'RespDoc', visible: true },
        { title: 'Fecha de Inicio', property: 'FromDate', visible: true, mask: MASK_DATE },
        { title: 'Fecha de Finalización', property: 'EndDate', visible: true, mask: MASK_DATE },
    ]

    const create = () => {
        setBean({
            State: 1,
            IDEmployee: _single.getAccountID(),
            IDAccount: dashedMenu ? _single.getAccountID() : NaN,
        });
        setModal({ ...modal, "view": true, "name": "Crear Experiencia Laboral" });
    }

    const iconButtonsItem = [
        { nombre: 'Agregar', visible: (_single.canCreate() || dashedMenu), icono: 'ri-file-add-line', evento: create }
    ]

    const listaBotones = () => {
        const botones = [
            { titulo: 'Editar', icono: 'ri-edit-2-line', visible: (_single.canCreate() || dashedMenu), evento: () => { setModal({ ...modal, "view": true, "name": "Editar Experiencia Laboral" }) } },
            { titulo: 'Eliminar', icono: 'ri-delete-bin-line', visible: (_single.canCreate() || dashedMenu), evento: () => { setModalC({ ...modalC, "view": true, "name": `Eliminar Experiencia Laboral` }) } },
        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }

    const setRowBean = (objeto: any) => {
        setBean(objeto);
        if (objeto.JobReferenceMedia) {
            let doc = JSON.parse(objeto.JobReferenceMedia)
            setDocument(doc);
        }
    }

    return (
        <div className="row">
            <div className="col-md-12">
                {dashedMenu ?
                    <div className="row">
                        <div className="col-md-12">
                            <BasicTable listButtons={listaBotones()} head={head} body={lista} rowSet={setRowBean} iconButtons={iconButtonsItem} />
                        </div>
                    </div>
                    :
                    <BasicPage titulo="Experiencia Laboral" >
                        <div className="row">
                            <div className="col-md-6">
                                <AccountEditor idAccount={account} onChange={(e) => { setAccount(e.IDAccount) }} label="Usuario" />
                            </div>
                            <div className="col-md-4 mt-4">
                                <BasicButton icon="ri-search-line" eventClick={getLista} >Buscar</BasicButton>
                            </div>
                            <div className="col-md-12">
                                <BasicTable listButtons={listaBotones()} head={head} body={lista} rowSet={setRowBean} iconButtons={iconButtonsItem} />
                            </div>
                        </div>
                    </BasicPage>
                }
            </div>

            <div className="col-md-12">
                <ModalConfirm modal={modalC} updateModal={setModalC} eventModal={deleteItem}>
                    <strong>Eliminar Informacion de {bean.IDAccount}</strong>
                </ModalConfirm>
            </div>

            <div className="col-md-12">
                <Modal modal={modal} updateModal={setModal} eventModal={editaItem}>
                    <div className="row">
                        {!dashedMenu &&
                            <div className="col-md-6">
                                <AccountEditor idAccount={bean.IDAccount} label="Empleado" onChange={({ IDAccount }) => { setBean({ ...bean, "IDAccount": IDAccount }) }} />
                            </div>
                        }
                        <div className="col-md-6">
                            <label>Nombre de la Empresa</label>
                            <input className="form-control" name="CompanyName" value={bean.CompanyName} onChange={handleChange} type="text" />
                        </div>
                        <div className="col-md-6">
                            <label>Cargo Desempeñado</label>
                            <input className="form-control" name="JobTitle" value={bean.JobTitle} onChange={handleChange} type="text" />
                        </div>
                        <div className="col-md-12">
                            <div className="row">
                                <div className="col-md-6">
                                    <InputDate notDate label="Fecha de Inicio" name="FromDate" value={bean.FromDate} setDate={(e) => { setBean({ ...bean, "FromDate": e }) }} />
                                </div>
                                <div className="col-md-6">
                                    <InputDate notDate label="Fecha de Finalización" name="EndDate" value={bean.UptoDate} setDate={(e) => { setBean({ ...bean, "EndDate": e }) }} />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <label>Motivo de Retiro</label>
                            <textarea className="form-control" name="TerminationReason" value={bean.TerminationReason} onChange={handleChange} />
                        </div>
                        <div className="col-md-12">
                            <label>Actividades</label>
                            <textarea className="form-control" name="JobActivities" value={bean.JobActivities} onChange={handleChange} />
                        </div>
                        <div className="col-md-6">
                            <label>Ultimo Salario</label>
                            <InputMoney id={1} name="LastSalary" onChange={(e) => { setBean({ ...bean, "LastSalary": e }) }} value={bean.LastSalary} />
                        </div>
                        <div className="col-md-6">
                            <label>Nombre Ultimo Jefe</label>
                            <input className="form-control" name="LastBossName" value={bean.LastBossName} onChange={handleChange} type="text" />
                        </div>
                        <div className="col-md-6">
                            <label>Télefono Ultimo Jefe</label>
                            <input className="form-control" name="PhoneLastBoss" value={bean.PhoneLastBoss} onChange={handleChange} type="text" />
                        </div>
                        <div className="col-md-6">
                            <FileInput
                                label='Adjunto Referencia Laboral'
                                Media={document.Media}
                                MediaContext={document.MediaContext}
                                directory='AcademicInformation'
                                onCreate={({ Media, MediaContext, Name }) => {
                                    let document = {
                                        Name: Name,
                                        Media: Media,
                                        MediaContext: MediaContext,
                                    }
                                    setBean({ ...bean, "JobReferenceMedia": JSON.stringify(document) });
                                }}
                            />
                        </div>
                    </div>
                </Modal>
            </div>
        </div>
    )
}



