import { BasicPage } from '../../../theme/Component/BasicPage';
import './Office-service';
import { BasicTable } from '../../../theme/Component/BasicTable';
import { OfficeService } from './Office-service';
import { useEffect, useState } from 'react';
import { CompanyEditor } from '../Company/CompanyEditor';
import { toast } from 'react-toastify';
import { Singleton } from '../../../../core/services/Singleton';
import { Modal } from '../../../theme/Component/Modal';
import { ModalConfirm } from '../../../theme/Component/ModalConfirm';
import { ListMenu } from '../../../theme/Component/ListMenu';
import { SelectSiteID } from '../../../admin/configuracion/SiteID/SelectSiteID';
import { MyEditor } from '../../../theme/Component/MyEditor';

export const Office = () => {
    const service = new OfficeService();
    const single = Singleton.getInstance()
    const [bean, setBean] = useState<any>({});
    const [lista, setLista] = useState<any>([]);
    const [conpany, setCompany] = useState<number>(null);
    const [modal, setModal] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: "modal-lg", });
    const [modalConfirm, setModalConfirm] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: "modal-sm", });

    const idBean = 'IDOffice';
    const head = [
        { title: 'ID', property: idBean },
        { title: 'Ciudad', property: 'SiteIDName' },
        { title: 'Compañia', property: 'CompanyName' },
        { title: 'Nombre', property: 'Name' },
        { title: 'Descripción', property: 'Description' },
    ]

    const setRowBean = (bean: any) => {
        setBean(bean);
       //console.log(bean)
    }

    useEffect(() => {
        if (conpany) {
            getLista();
        }
    }, [conpany])

    const getLista = () => {
        service.spinON();
        service.getOfficeCatalog(null).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ErrorMessage) {
                    toast.error(resp.DataBeanProperties.ErrorMessage);
                }
                else if (resp.DataBeanProperties.ObjectValue) {
                    setLista(resp.DataBeanProperties.ObjectValue);
                }
                service.spinOFF();
            }
        )
    }

    const eliminarItem = () => {
        service.spinON();
        service.deleteOffice(bean[idBean]).subscribe(
            (resp: any) => {
                service.spinOFF();
                if (resp) {
                    getLista();
                }
                single.delete(bean[idBean], bean);
            }
        );
        setModalConfirm({ ...modal, "view": false });
    }

    const updateItem = () => {
        service.spinON();
        service.updateOffice(bean).subscribe((resp: any) => {
            service.spinOFF();
            if (resp.DataBeanProperties.ObjectValue) {
                getLista();
            }
            single.update(bean);
        });
        setModal({ ...modal, "view": false });
    };

    const agregarItem = () => {
        let b = {
            IDCompany: conpany,
            Name: "",
            IDSite: null,
        };
        setBean(b);
        setModal({ ...modal, "view": true, name: "Agregar" });
    };

    const iconButtonsItem = [
        { nombre: "Agregar", visible: single.canCreate(), icono: "ri-file-add-line", evento: agregarItem },
    ];

    const listaBotones = () => {
        const botones = [
            { titulo: "Editar", icono: "ri-file-list-line", visible: single.canEdit(), evento: () => { setModal({ ...modal, view: true, name: "Editar" }); }, },
            { titulo: "Eliminar", icono: "ri-file-user-line", visible: single.canDelete(), evento: () => { setModalConfirm({ ...modalConfirm, view: true, name: `Eliminar ${bean.Name}` }); }, },
        ];
        return <ListMenu listaBotones={botones} />;
    };

    return (
        <BasicPage titulo='Catálogo de Oficinas'>
            <div className="row mt-3">
                <div className="col-12 mb-2">
                    <CompanyEditor id={conpany} onchange={(e: any) => { setCompany(e) }}></CompanyEditor>
                </div>
                {conpany ?
                    <div className="col">
                        <BasicTable listButtons={listaBotones()} iconButtons={iconButtonsItem} body={lista} head={head} rowSet={setRowBean} ></BasicTable>
                    </div>

                    :
                    <div className="alert alert-warning text-center">
                        Debe seleccionar una Compañía
                    </div>
                }
            </div>

            <ModalConfirm modal={modalConfirm} updateModal={setModalConfirm} eventModal={eliminarItem}>
                {bean.Name}
            </ModalConfirm>

            <Modal modal={modal} updateModal={setModal} eventModal={updateItem}>
                <div className="row">
                    <div className="col-md-6">
                        <CompanyEditor id={bean.IDCompany} onchange={(e: any) => { setBean({ ...bean, "IDCompany": e }) }}></CompanyEditor>
                    </div>
                    <div className="col-md-6">
                        <SelectSiteID edit={false} idLnSite={bean.IDSite} onChange={(e: any) => { setBean({ ...bean, "IDSite": e.IDLn }); }}></SelectSiteID>
                    </div>
                    <div className="col-md-12">
                        <label className="form-label">Nombre:</label>
                        <input type="text" className="form-control" value={bean.Name} onChange={(e) => { setBean({ ...bean, "Name": e.target.value }); }} />
                    </div>
                    <div className="col-md-12">
                        <label className="form-label">Descripción:</label>
                        <MyEditor val={bean.Description} onChange={(e: any) => { setBean({ ...bean, "Description": e }) }}></MyEditor>
                    </div>
                </div>
            </Modal>
        </BasicPage>
    )
}