import { BasicPage } from "../theme/Component/BasicPage"
import { BasicButton } from '../theme/Component/BasicButton';
import { BasicTable } from '../theme/Component/BasicTable';
import { Singleton } from '../../core/services/Singleton';
import { ContractService } from '../../core/services/ContractService';
import { useState, useEffect } from 'react';
import { ModalConfirm } from '../theme/Component/ModalConfirm';
import { Modal } from '../theme/Component/Modal';
import { InputDate } from "../theme/Input/InputDate";
import { SelectModality } from "./select/SelectModality";
import { processState } from "../Constants";
import { ProcessActions } from './ProcessActions';
import { SelectAdquisitionPlan } from './select/SelectAdquisitionPlan';
import { useDashboardStore } from "../pages/hooks/useDashboardStore";

export const Process = () => {

    const single = Singleton.getInstance()
    const { setLoading } = useDashboardStore();
    const service = new ContractService();
    const [lista, setLista] = useState([]);
    const [bean, setBean] = useState<any>({});
    const [modal, setModal] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalConfirm, setModalConfirm] = useState<any>({ name: 'Confirmar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });


    const states = processState;

    const idBean = 'IDProcess';

    const head = [
        { title: 'ID', property: idBean },
        { title: 'Nombre', property: 'Name' },
        { title: 'Justificación', property: 'Causal' },
        // { title: 'Modalidad', property: 'IDModality' },
        { title: 'Fecha Creación', property: 'Since' },
        { title: 'Fecha Cierre', property: 'EndDate' },
        { title: 'Valor Total', property: 'TotalValue' },
        { title: 'IVA', property: 'ValueIva' },
        // { title: 'Estado', property: 'State' },
        { title: 'Estado', property: 'Estado' },
    ]

    useEffect(() => {
        getLista();
    }, [])

    const setRowBean = (bean: any) => {
       //console.log("Row : ", bean);
        setBean(bean);

    }

    const getLista = () => {

        setLoading(true);
        service.getProcessCatalogPorPropiedad(null, null, null).subscribe(
            (resp: any) => {
                setLista([]);

                if (resp.DataBeanProperties.ObjectValue) {
                    let lis: any = resp.DataBeanProperties.ObjectValue;

                    lis.forEach((item: any) => {
                        item.DataBeanProperties.Estado = single.renderConstant(states, item.DataBeanProperties.State);
                    });

                    setLista(lis);
                }

                setLoading(false);
            }
        );
    }

    const agregarItem = () => {

        let b = {
            Name: '',
            Causal: '',
            State: 1,
            Participants: 0,
            TotalValue: 0,
            IDModality: 0,
            ValueIva: 0,
            IDAdquisitionPlan: 0,
            Docs: JSON.stringify([]),
            Since: single.getCurrenDate(),
            EndDate: single.getCurrenDate()
        }

        setBean(b);
        setModal({ ...modal, ['view']: true });

    }
    const editarItem = () => {

        setModal({ ...modal, ['view']: true });

    }
    const eliminarItem = () => {

        setLoading(true);
        service.deleteProcess(bean[idBean]).subscribe(
            (resp: any) => {
                setLoading(false);
                if (resp) {
                    getLista();
                }
            }
        );
        setModalConfirm({ ...modalConfirm, ['view']: false });

    }
    const updateItem = () => {

        setModal({ ...modal, ['view']: false });

       //console.log("Update ", bean);

        setLoading(true);
        service.updateProcess(bean).subscribe(
            (resp: any) => {
                setLoading(false);
                if (resp.DataBeanProperties.ObjectValue) {
                    getLista();
                }
            });

    }

    const updateBottons = (bean: any) => {
        setBean(bean);
        updateItem();
    }


    return (
        <>
            <BasicPage titulo="Procesos Contractuales">
                <div className="row">
                    <div className="col-md-4">
                        <BasicButton value="Agregar" icon="ri-file-add-line" eventClick={agregarItem} ></BasicButton>
                        {bean[idBean] &&
                            <>
                                <BasicButton value="Editar" icon="ri-edit-2-line" eventClick={editarItem} ></BasicButton>
                                <BasicButton value="Eliminar" icon="ri-eraser-line" eventClick={() => { setModalConfirm({ ...modalConfirm, ['view']: true }); }} ></BasicButton>

                            </>
                        }

                    </div>

                    <div className="col-md-8 align-items-end">
                        {bean[idBean] &&
                            <ProcessActions bean={bean} eventChange={updateBottons} />
                        }

                    </div>
                    <div className="col-md-12 p-2">
                        <BasicTable head={head} body={lista} rowSet={setRowBean}></BasicTable>
                    </div>
                </div>
            </BasicPage>

            <ModalConfirm modal={modalConfirm} updateModal={setModalConfirm} eventModal={eliminarItem}>
                {bean.Name}
            </ModalConfirm>

            <Modal modal={modal} updateModal={setModal} eventModal={updateItem}>
                <div className="row">

                    <div className="col-md-6">
                        <label className="form-label">Nombre</label>
                        <input type="text" className="form-control" value={bean.Name} onChange={(e) => { setBean({ ...bean, ['Name']: e.target.value }) }} />
                    </div>
                    <div className="col-md-6 mt-2">
                        <SelectAdquisitionPlan idSelector={bean.IDAdquisitionPlan} onChange={(e: any) => {
                            setBean({ ...bean, ['IDAdquisitionPlan']: e });

                        }} />
                    </div>
                    <div className="col-md-6 mt-2">
                        <SelectModality idSelector={bean.IDModality} onChange={(e: any) => { setBean({ ...bean, ['IDModality']: e }) }} />
                    </div>
                    <div className="col-md-4">
                        <label>Cantidad Participantes</label>
                        <input type="number" className="form-control" value={bean.Participants} onChange={(e) => { setBean({ ...bean, ['Participants']: e.target.value }) }} />
                    </div>
                    <div className="col-md-4">
                        <label className="form-label">Valor Total</label>
                        <input type="text" className="form-control" value={bean.TotalValue} onChange={(e) => { setBean({ ...bean, ['TotalValue']: e.target.value }) }} />
                    </div>
                    <div className="col-md-4">
                        <label className="form-label">Porcentaje IVA</label>
                        <input type="text" className="form-control" value={bean.ValueIva} onChange={(e) => { setBean({ ...bean, ['ValueIva']: e.target.value }) }} />
                    </div>
                    <div className="col-md-4">
                        <InputDate label="Fecha de Cierre" value={bean.EndDate} name="EndDate" setDate={(e: any) => { setBean({ ...bean, ['EndDate']: e }) }} />
                    </div>
                    <div className="col-md-12">
                        <label className="form-label">Justificación</label>
                        <textarea className="form-control" value={bean.Causal} onChange={(e) => { setBean({ ...bean, ['Causal']: e.target.value }) }}></textarea>
                    </div>

                </div>
            </Modal>
        </>
    )
}