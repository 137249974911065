import { useEffect, useState } from "react";
import { _BudgetAccountantMovement, _BudgetAccountantOperation, _single } from "../../../../core/services/dataBean/EntityCatalog";
import useDataBean from "../../../../core/services/dataBean/useDataBean"
import { BasicPage } from "../../../theme/Component/BasicPage"
import { BasicTable, MASK_MONEY, MASK_NUMBER } from "../../../theme/Component/BasicTable";
import { toast } from "react-toastify";
import { ListMenu } from "../../../theme/Component/ListMenu";
import { Modal } from "../../../theme/Component/Modal";
import { ModalConfirm } from "../../../theme/Component/ModalConfirm";
import { BasicSwitch } from "../../../theme/Component/BasicSwitch";
import { SelectAccountantTree } from "../../../contabilidad/catalogo-cuentas/AccountantTree/SelectAccountantTree";
import { BudgetAccountantMovement } from "./BudgetAccountantMovement/BudgetAccountantMovement";
import { BasicButton } from "../../../theme/Component/BasicButton";
import { AccountEditor } from "../../../editor/AccountEditor";
import { CostingCenterSelect } from "../CostingCenter/CostingCenterSelect";
import { SelectProject } from "./SelectProject";
import { SelectBusinessCore } from "../BusinessCore/SelectBusinessCore";
import { SelectValidBudgetAccountantMovementTypeConstants } from "./SelectValidBudgetAccountantMovementTypeConstants";
import { CardTaps } from "../../../theme/Component/CardTaps";
import { ErrorList } from "../../../theme/Component/ErrorList";
import { DocumentViewer } from "../../../theme/Component/DocumentViewer";
import { _SupplierPaymentOrder } from "../../../../core/services/dataBean/EntityCatalogVdos";
import useModal, { MODAL_LG, MODAL_md } from "../../../theme/Component/hooks/useModal";
import { encryptData } from "../../../../utils/helpers";


export const BudgetAccountantOperation = () => {

    const { lista, setLista, bean, setBean, instance, handleChange, deleteItemVoid } = useDataBean(_BudgetAccountantOperation);
    const _budgetAccountantMovement = useDataBean(_BudgetAccountantMovement);
    const _supplierOrder = useDataBean(_SupplierPaymentOrder);

    const [missingFields, setMissingFields] = useState<string[]>([]);
    const [json, setJson] = useState<string>('');
    const [idAccount, setIDAccount] = useState<number>(NaN);
    const [initBean, setInitBean] = useState<any>({});
    const [response, setResponse] = useState<any>();
    const [errors, setErrors] = useState<any[]>([])
    const [rpDocument, setRPDocument] = useState<any>({});
    const [cdpDocument, setCDPDocument] = useState<any>({});
    const [obDocument, setOBDocument] = useState<any>({});
    const [fcDocument, setFCDocument] = useState<any>({});
    const [paymentOrder, setPaymentOrder] = useState<any>({});

    const [modal, setModal] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalMovement, setModalMovement] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-md' });
    const [modalC, setModalC] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });
    const [modalAccountantMovement, setModalAccountantMovement] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalAPI, setModalAPI] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: false, format: 'modal-xl' });
    const [modalResponseAPI, setModalResponseAPI] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-xl' });
    const modalUtil = useModal("Encriptar", MODAL_LG);

    const head = [
        { title: 'ID', property: 'IDBudgetAccountantOperation', visible: true, mask: MASK_NUMBER },
        { title: 'Código', property: 'Code', visible: true, mask: MASK_NUMBER },
        { title: 'Nombre', property: 'Name', visible: true },
        { title: 'Generar Comprobante Contable', property: 'Accounting', visible: true },
        { title: 'Crear Certificado de Disponibilidad Presupuestal', property: 'CreateCDP', visible: true },
        { title: 'Requerir Certificado de Disponibilidad Presupuestal', property: 'RequiresCDP' },
        { title: 'Crear Registro Presupuestal', property: 'CreateRP' },
        { title: 'Requerir Registro Presupuestal', property: 'RequiresRP' },
        { title: 'Crear Obligación Presupuestal', property: 'CreateOB' },
        { title: 'Crear Orden de Pago', property: 'CreateSupplierPaymentOrder' },
        { title: 'Cuenta Contable por Pagar', property: 'AccountantIDName' },
        { title: 'Clase', property: 'DoAfterSaveActionClass' },
    ]

    useEffect(() => {
        getLista()
    }, [])

    const create = () => {
        setBean({
            Accounting: false,
            CreateCDP: false,
            RequiresCDP: false,
            CreateRP: false,
            RequiresRP: false,
            CreateOB: false,
            CreateSupplierPaymentOrder: false,
        });
        setModal({ ...modal, "view": true, "name": "Crear Catálogo" });
    }

    const getLista = () => {
        instance.getBudgetAccountantOperationCatalog(null).then(
            (resp) => {
                setLista(resp);
            }
        ).catch(
            err => { toast.error(err) }
        )
    }

    useEffect(() => {
        if (modalAPI.view == true) {
            getAccountanMovement()
            setTap(0);
            setJson("")
        } else {

        }
    }, [modalAPI.view])

    const getAccountanMovement = () => {
        _budgetAccountantMovement.instance.getBudgetAccountantMovementCatalog(bean.IDBudgetAccountantOperation).then(
            (resp) => {
                _budgetAccountantMovement.setLista(resp);
            }
        ).catch(
            err => { toast.error(err) }
        )
    }

    const updateItem = () => {
        instance.updateBudgetAccountantOperation(bean).then(
            (resp) => {
                setModal({ ...modal, "view": false });
                getLista();
            }
        ).catch(
            err => { toast.error(err) }
        )
    }

    const deleteItem = () => {
        deleteItemVoid(bean.IDBudgetAccountantOperation).then(
            (resp) => {
                getLista();
                setModalC({ ...modalC, "view": false });
            }
        ).catch(
            err => { toast.error(err) }
        )
    }

    const getApi = () => {
        instance.getBudgetAccountantMovementJsonService(bean.IDBudgetAccountantOperation, idAccount, _budgetAccountantMovement.lista, _single.getAccountID()).then(
            (resp) => {
               //console.log(resp)
                if (resp) {
                    setJson(JSON.stringify(JSON.parse(resp)));
                }
            }
        ).catch(
            err => { toast.error(err) }
        )
    }

    const invokeAPI = () => {
        instance.invokeBudgetAccountantOperation(JSON.parse(json)).then(
            (resp) => {
               //console.log(resp)
                setResponse(resp);
                if (resp.ErrorsInfo && resp.ErrorsInfo.length > 0) {
                    setErrors(resp.ErrorsInfo);
                } else {
                    setErrors([])
                }
                if (resp.RPDocument && resp.RPDocument.DataBeanProperties.IDDocument) {
                    setRPDocument(resp.RPDocument.DataBeanProperties)
                } else {
                    setRPDocument({})
                }
                if (resp.OBDocument && resp.OBDocument.DataBeanProperties.IDDocument) {
                    setOBDocument(resp.OBDocument.DataBeanProperties)
                } else {
                    setOBDocument({})
                }
                if (resp.CDPDocument && resp.CDPDocument.DataBeanProperties.IDDocument) {
                    setCDPDocument(resp.CDPDocument.DataBeanProperties)
                } else {
                    setCDPDocument({})
                }
                if (resp.FCDocument && resp.FCDocument.DataBeanProperties.IDDocument) {
                    setFCDocument(resp.FCDocument.DataBeanProperties)
                } else {
                    setFCDocument({})
                }
                if (resp.PaymentOrderInfo && resp.PaymentOrderInfo.IDSupplierPaymentOrder) {
                    setPaymentOrder(resp.PaymentOrderInfo)
                } else {
                    setPaymentOrder({})
                }
                setTap(2);
            }
        ).catch(
            err => { toast.error(err) }
        )
    }

    const iconButtonsItem = [
        { nombre: 'Agregar', visible: _single.canCreate(), icono: 'ri-file-add-line', evento: create }
    ]
    const listaBotones = () => {
        const botones = [
            { titulo: 'Editar', icono: 'ri-edit-2-line', visible: _single.canEdit(), evento: () => { setModal({ ...modal, "view": true, "name": "Editar" }) } },
            { titulo: 'Eliminar', icono: 'ri-delete-bin-line', visible: _single.canDelete(), evento: () => { setModalC({ ...modalC, "view": true, "name": "Eliminar" }) } },
            { titulo: 'Estructura de Movimientos Contable-Presupuestal', icono: 'ri-file-list-line', visible: _single.canCreate(), evento: () => { setModalAccountantMovement({ ...modalAccountantMovement, "view": true, "name": "Estructura de Movimientos Contable-Presupuestal" }) } },
            { titulo: 'Consultar/Ejecutar APIRest del Servicio', icono: 'ri-file-code-line', visible: true, evento: () => { setModalAPI({ ...modalAPI, "view": true, "name": "Obtener y Ejecutar JSON" }) } },
        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }

    const validateData = () => {
        if (validateForm() > 0) {
            toast.error("Faltan campos obligatorios por completar");
            return;
        } else {
            updateItem();
        }
    }

    const validateForm = () => {
        const requiredFields = [
            "AccountantIDStr", "Name", "Code"
        ];

        let missing = [];
        for (let field of requiredFields) {
            if (!bean[field]) {
                missing.push(field);
            }
        }
        setMissingFields(missing);
        return missing.length
    };

    useEffect(() => {
        let temp = json;
        if (temp) {
            try {
                let j = JSON.parse(temp);
               //console.log(j);
            } catch (error) {

            }

        }
    }, [json])

    function renderJson(jsonString: string) {
        try {
            let tmp: any = JSON.stringify(JSON.parse(jsonString), undefined, 2);
            setJson(tmp);
            toast.success('Estructura JSON correcta.');
            invokeAPI();
        } catch (error) {
           //console.log(error);
            if (error instanceof SyntaxError) {
                toast.error('Estructura Incorrecta, verifica la estructura del JSON');
            } else {
                toast.error('Ocurrió un error inesperado.');
            }
        }
    }

    function formatJson(jsonString: string) {
        try {
            let tmp: any = JSON.stringify(JSON.parse(jsonString), undefined, 2);
            setJson(tmp);
            navigator.clipboard.writeText(tmp).then(() => toast.success("Copiado al portapapeles"))
        } catch (error) {
           //console.log(error);
            if (error instanceof SyntaxError) {
                toast.error('Estructura Incorrecta, verifica la estructura del JSON');
            } else {
                toast.error('Ocurrió un error inesperado.');
            }
        }
    }

    const headMovement = [
        { title: 'ID', property: 'IDBudgetAccountantMovement', visible: true, mask: MASK_NUMBER },
        { title: 'Código', property: 'Code', visible: true, mask: MASK_NUMBER },
        { title: 'Nombre', property: 'Name', visible: true },
        { title: 'Centro de Costo', property: 'CostingCenterCodeName', visible: true },
        { title: 'Proyecto', property: 'ProjectCodeName', visible: true },
        { title: 'Core de Negocio', property: 'BusinessCoreCodeName', visible: true },
        { title: 'Tercero', property: 'IDAccountName', visible: true },
        { title: 'Requiere Centro de Costo', property: 'MovementNatureName', visible: true },
    ]

    const listaBotonesMovement = () => {
        const botones = [
            { titulo: 'Editar', icono: 'ri-edit-2-line', visible: _single.canEdit(), evento: () => { setModalMovement({ ...modalMovement, "view": true, "name": "Editar" }) } },
        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }

    const editMovement = () => {
        const updatedLista = _budgetAccountantMovement.lista.filter((item: any) =>
            JSON.stringify(item.DataBeanProperties) !== JSON.stringify(initBean)
        );

        let temp = [...updatedLista, {
            DataBeanProperties: _budgetAccountantMovement.bean,
            DataBeanName: 'com.quickdataerp.bean.admin.BudgetAccountantMovement'
        }];

        _budgetAccountantMovement.setLista(temp)
        setModalMovement({ ...modalMovement, "view": false });
    }

    const setRowBean = (obje: any) => {
        _budgetAccountantMovement.setBean(obje)
        setInitBean(obje);
    }

    const [tap, setTap] = useState<number>(0);
    let taps = [
        { id: 0, name: 'Datos', disable: false },
        { id: 1, name: 'Edición JSON', disable: false },
        { id: 2, name: 'Orden de Pago', disable: false },
    ];

    const headPaymentOrder = [
        { title: 'ID', property: 'IDSupplierPaymentOrder', visible: false, mask: MASK_NUMBER },
        { title: 'Valor de la Orden de Pago', property: 'Value', visible: true, mask: MASK_MONEY },
        { title: 'Etapa de la Orden', property: 'OrderStateName', visible: true },
        { title: 'Empleado', property: 'EmployeeName', visible: true },
        { title: 'Estado', property: 'StateName', visible: false },
        { title: 'Fecha Creación', property: 'SupplierPaymentOrderDate', visible: true },
        { title: 'Descripción', property: 'Description', visible: true },
    ]

    useEffect(() => {
        if (paymentOrder.IDSupplierPaymentOrder) {
            getPaymentOrder()
        }
    }, [paymentOrder])

    const getPaymentOrder = () => {
        _supplierOrder.instance.getSupplierPaymentOrder(paymentOrder.IDSupplierPaymentOrder, paymentOrder.SupplierPaymentOrderDate).then(
            (resp) => {
                let x = [];
                x.push({
                    DataBeanProperties: resp,
                    DataBeanName: "com.quickdataerp.bean.supplier.SupplierPaymentOrder"
                })
                _supplierOrder.setLista(x)
            }
        ).catch(err => { toast.error(err) })
    }

    const [txt, setTxt] = useState<string>('');
    const [encript, setEncript] = useState<string>('');
    const encriptar = () => {
        setEncript(encryptData(txt));
    }

    return (
        <BasicPage titulo="Catálogo de Servicios Contable-Presupuestales">
            <div className="row">
                <div className="col">
                    <BasicButton icon="" eventClick={() => { modalUtil.open() }}>Encriptar Mensaje</BasicButton>
                    <Modal modal={modalUtil.modal} updateModal={modalUtil.setModal}>
                        <div className="row">
                            <div className="col-md-12">
                                <label>Mensaje</label>
                                <textarea className="form-control" rows={5} value={txt} onChange={(e) => { setTxt(e.target.value) }} ></textarea>
                                <BasicButton icon="" eventClick={encriptar}>Encriptar</BasicButton>
                            </div>
                            <div className="col-md-12">
                                <label >Encriptado</label>
                                <textarea className="form-control" rows={5} value={encript}></textarea>
                            </div>
                        </div>
                    </Modal>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <BasicTable body={lista} head={head} rowSet={setBean} iconButtons={iconButtonsItem} listButtons={listaBotones()} />
                </div>
            </div>

            <div className="row">
                <div className="col-md-12">
                    <Modal modal={modal} updateModal={setModal} eventModal={validateData} >
                        <div className="row">
                            <div className="col-md-6">
                                <label className={`${missingFields.includes('Code') ? 'has-error' : ''}`}>Código</label>
                                <input type="number" className="form-control" name="Code" value={bean.Code} onChange={handleChange} />
                            </div>
                            <div className="col-md-6">
                                <label className={`${missingFields.includes('Name') ? 'has-error' : ''}`}>Nombre</label>
                                <input type="text" className="form-control" name="Name" value={bean.Name} onChange={handleChange} />
                            </div>
                            <div className="col-md-6">
                                <BasicSwitch estado={bean.Accounting} eventChange={(e) => { setBean({ ...bean, "Accounting": e }) }} label="Generar Comprobante Contable" />
                            </div>
                            <div className="col-md-6">
                                <BasicSwitch estado={bean.CreateCDP} eventChange={(e) => { setBean({ ...bean, "CreateCDP": e }) }} label="Crear Certificado de Disponibilidad Presupuestal" />
                            </div>
                            <div className="col-md-6">
                                <BasicSwitch estado={bean.RequiresCDP} eventChange={(e) => { setBean({ ...bean, "RequiresCDP": e }) }} label="Requerir Certificado de Disponibilidad Presupuestal" />
                            </div>
                            <div className="col-md-6">
                                <BasicSwitch estado={bean.CreateRP} eventChange={(e) => { setBean({ ...bean, "CreateRP": e }) }} label="Crear Registro Presupuestal" />
                            </div>
                            <div className="col-md-6">
                                <BasicSwitch estado={bean.RequiresRP} eventChange={(e) => { setBean({ ...bean, "RequiresRP": e }) }} label="Requerir Registro Presupuestal" />
                            </div>
                            <div className="col-md-6">
                                <BasicSwitch estado={bean.CreateOB} eventChange={(e) => { setBean({ ...bean, "CreateOB": e }) }} label="Crear Obligación Presupuestal" />
                            </div>
                            <div className="col-md-6">
                                <BasicSwitch estado={bean.CreateSupplierPaymentOrder} eventChange={(e) => { setBean({ ...bean, "CreateSupplierPaymentOrder": e }) }} label="Crear Orden de Pago" />
                            </div>
                            <div className="col-md-6" />
                            <div className="col-md-6">
                                <label className={`${missingFields.includes('AccountantIDStr') ? 'has-error' : ''}`}>Cuenta Contable por Pagar</label>
                                <SelectAccountantTree jsonCode={bean.AccountantIDStr} onChange={(e) => { setBean({ ...bean, "AccountantIDStr": e.JsonCode }) }} label=" " />
                            </div>
                            <div className="col-md-6">
                                <label>Clase DoAfterSaveActionClass</label>
                                <input type="text" name="DoAfterSaveActionClass" className="form-control" value={bean.DoAfterSaveActionClass} onChange={handleChange} />
                            </div>
                        </div>
                    </Modal>
                </div>
            </div>

            <div className="row">
                <div className="col-md-12">
                    <ModalConfirm modal={modalC} updateModal={setModalC} eventModal={deleteItem} >
                        <p>¿Desea eliminat el item {bean.IDBudgetAccountantOperation} - {bean.Name}?</p>
                    </ModalConfirm>
                </div>
            </div>

            <div className="row">
                <div className="col-md-12">
                    <Modal modal={modalAccountantMovement} updateModal={setModalAccountantMovement}>
                        <BudgetAccountantMovement idBudgetAccountantOperation={bean.IDBudgetAccountantOperation} />
                    </Modal>
                </div>
            </div>

            <div className="row">
                <div className="col-md-12">
                    <Modal modal={modalAPI} updateModal={setModalAPI} eventModal={getApi} onSubmitLabel="Generar JSON">
                        <CardTaps index={tap} updateIndex={setTap} taps={taps}>
                            {tap === 0 &&
                                <div className="row">
                                    <div className="col-md-12">
                                        <AccountEditor label="Beneficiario" idAccount={idAccount} onChange={(e) => { setIDAccount(e.IDAccount) }} />
                                    </div>
                                    <div className="col-md-12">
                                        <BasicTable body={_budgetAccountantMovement.lista} listButtons={listaBotonesMovement()} head={headMovement} rowSet={(e) => { setRowBean(e) }} />
                                    </div>
                                    <div className="col-md-4 mt-2">
                                        <BasicButton icon="ri-skip-forward-line" eventClick={() => {
                                            if (idAccount) {
                                                setTap(1);
                                                getApi();
                                            } else {
                                                toast.error("Cuenta no valida");
                                            }
                                        }}>Generar JSON</BasicButton>
                                    </div>
                                </div>
                            }
                            {tap === 1 &&
                                <div className="row">
                                    <div className="col-md-12">
                                        <label>JSON Generado</label>
                                        <textarea
                                            value={json}
                                            className="form-control"
                                            onChange={(e) => { setJson(e.target.value) }}
                                            style={{ height: "60vh", overflowY: "scroll" }}
                                        />
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-4 ">
                                            <BasicButton icon="ri-skip-back-line" eventClick={() => { setTap(0) }}>Volver</BasicButton>
                                        </div>
                                        <div className="col-md-4 d-flex justify-content-center">
                                            <BasicButton icon="ri-save-line" eventClick={() => {
                                                formatJson(json);
                                            }}>
                                                Formatear y Copiar JSON
                                            </BasicButton>
                                        </div>
                                        <div className="col-md-4 d-flex justify-content-end">
                                            <BasicButton icon="ri-terminal-box-line" eventClick={() => { renderJson(json) }}>Validar y Ejecutar</BasicButton>
                                        </div>
                                    </div>
                                </div>
                            }
                            {tap === 2 &&
                                <div className="row">
                                    <div className="col-md-12">
                                        {errors.length > 0 &&
                                            <ErrorList lista={response.ErrorsInfo} />
                                        }
                                    </div>

                                    {paymentOrder.IDSupplierPaymentOrder &&
                                        <BasicTable body={_supplierOrder.lista} head={headPaymentOrder} rowSet={_supplierOrder.setBean} />
                                    }

                                    {cdpDocument &&
                                        <div className="col-md-12">
                                            {cdpDocument?.MsgList?.length > 0 &&
                                                <ErrorList lista={cdpDocument.MsgList} />

                                            }
                                            {cdpDocument?.MsgList?.length === 0 && cdpDocument?.DocumentURL?.length > 0 &&
                                                <DocumentViewer key={cdpDocument.DocumentURL} list={cdpDocument.DocumentURL} />
                                            }
                                        </div>
                                    }

                                    {rpDocument &&
                                        <div className="col-md-12">
                                            {rpDocument?.MsgList?.length > 0 &&
                                                <ErrorList lista={rpDocument.MsgList} />

                                            }
                                            {rpDocument?.MsgList?.length === 0 && rpDocument?.DocumentURL?.length > 0 &&
                                                <DocumentViewer key={rpDocument.DocumentURL} list={rpDocument.DocumentURL} />
                                            }
                                        </div>
                                    }

                                    {obDocument &&
                                        <div className="col-md-12">
                                            {obDocument?.MsgList?.length > 0 &&
                                                <ErrorList lista={obDocument.MsgList} />
                                            }
                                            {obDocument?.MsgList?.length === 0 && obDocument?.DocumentURL?.length > 0 &&
                                                <DocumentViewer key={obDocument.DocumentURL} list={obDocument.DocumentURL} />
                                            }
                                        </div>
                                    }

                                    {fcDocument &&
                                        <div className="col-md-12">
                                            {fcDocument?.MsgList?.length > 0 &&
                                                <ErrorList lista={fcDocument.MsgList} />
                                            }
                                            {fcDocument?.MsgList?.length === 0 && fcDocument?.DocumentURL?.length > 0 &&
                                                <DocumentViewer key={fcDocument.DocumentURL} list={fcDocument.DocumentURL} />
                                            }
                                        </div>
                                    }
                                    <div className="col-md-6 mt-2">
                                        <BasicButton icon="ri-terminal-box-line" eventClick={() => { setModalResponseAPI({ ...modalResponseAPI, "view": true, "name": "JSON de Respuesta" }) }}>Mostrar Json de Respuesta</BasicButton>
                                    </div>
                                </div>
                            }
                        </CardTaps>


                        <div className="row">
                            <div className="col-md-12">
                                <Modal modal={modalResponseAPI} updateModal={setModalResponseAPI} onSubmitLabel="Copiar JSON" eventModal={() => {
                                    navigator.clipboard.writeText(json)
                                        .then(() => toast.success("Copiado al portapapeles"))
                                        .catch(() => toast.error("Error al copiar al portapapeles"));
                                }}>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <label>JSON de Respuesta</label>
                                            <textarea
                                                value={JSON.stringify(response, null, 2)}
                                                disabled
                                                className="form-control"
                                                onChange={(e) => { setJson(e.target.value) }}
                                                style={{ overflow: "auto", minHeight: "60vh" }}
                                            />
                                        </div>
                                    </div>
                                </Modal>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12">
                                <Modal modal={modalMovement} updateModal={setModalMovement} eventModal={editMovement}>
                                    <div className="row">
                                        {_budgetAccountantMovement.bean.RequiresCostingCenter === true &&
                                            <div className="col-md-12">
                                                <CostingCenterSelect idSelector={_budgetAccountantMovement.bean.CostingCenterCode} returnType="Object" onChange={(e) => { _budgetAccountantMovement.setBean({ ..._budgetAccountantMovement.bean, "CostingCenterCode": (parseInt(e.Code)), "CostingCenterCodeName": e.Name }) }} />
                                            </div>
                                        }
                                        {_budgetAccountantMovement.bean.RequiresProject === true &&
                                            <div className="col-md-12">
                                                <SelectProject idSelector={_budgetAccountantMovement.bean.ProjectCode} returnType="Object" onChange={(e) => { _budgetAccountantMovement.setBean({ ..._budgetAccountantMovement.bean, "ProjectCode": e.IDProject, "ProjectCodeName": `${e.Code} - ${e.Name}` }) }} />
                                            </div>
                                        }
                                        {_budgetAccountantMovement.bean.RequiresBusinessCore === true &&
                                            <div className="col-md-12">
                                                <SelectBusinessCore returnType="Object" idSelector={_budgetAccountantMovement.bean.BusinessCoreCode} onChange={(e) => { _budgetAccountantMovement.setBean({ ..._budgetAccountantMovement.bean, "BusinessCoreCode": e.Code, "BusinessCoreCodeName": `${e.Code} - ${e.Name}` }) }} />
                                            </div>
                                        }
                                        {_budgetAccountantMovement.bean.RequiresThird === true &&
                                            <div className="col-md-12">
                                                <AccountEditor label="Tercero" idAccount={_budgetAccountantMovement.bean.IDAccount} onChange={(e) => { _budgetAccountantMovement.setBean({ ..._budgetAccountantMovement.bean, "IDAccount": e.IDAccount, "IDAccountName": `${e.Nit} - ${e.EntityName}` }) }} />
                                            </div>
                                        }
                                        {_budgetAccountantMovement.bean.MovementType == 2 &&
                                            <div className="col-md-12">
                                                <SelectValidBudgetAccountantMovementTypeConstants returnType="Object" label="Tipo de Movimiento" idSelector={_budgetAccountantMovement.bean.MovementNature} onChange={(e) => { _budgetAccountantMovement.setBean({ ..._budgetAccountantMovement.bean, "MovementNature": e.Value, "MovementNatureName": e.Property }) }} />
                                            </div>
                                        }
                                    </div>
                                </Modal>
                            </div>
                        </div>
                    </Modal>
                </div>
            </div>
        </BasicPage>
    )

}