import { useState, useEffect } from 'react';
import useDataBean from '../../../core/services/dataBean/useDataBean';
import { _AdquisitionProject, _PaymentMethod, _Project } from '../../../core/services/dataBean/EntityCatalogVdos';
import { PrintJson } from '../../theme/Component/PrintJson';
import { _single } from '../../../core/services/dataBean/EntityCatalog';



interface Props {
    idSelector: number;
    onChange: any;
    label?: string;
}
export const IDAdquisitionProject = ({ idSelector, onChange, label }: Props) => {

    const IDAdquisitionProject = useDataBean(_Project)
    const [lista, setLista] = useState<any>([])
    const [id, setID] = useState(0);

    useEffect(() => {
        if (idSelector >= 0) {
            setID(idSelector);
        }
    }, [])


    useEffect(() => {
        getLista();
    }, [])



    const setValue = (e: any) => {
        setID(parseInt(e));
        onChange(e);
    }



    const getLista = () => {

        const x = {
            "State": 1
        }
        let temp = _single.getCacheItem('proyect1');
        if (temp) {
            setLista(temp);
        }
        else {
            IDAdquisitionProject.instance.getProjectCatalogPorPropiedades(x, null).then(
                (resp: any) => {
                    if (resp) {
                        setLista(resp)
                        _single.setCacheItem('proyect1', resp);
                    }
                }
            )
        }

    }

    return (
        <>

            <label>{label ?? "Proyecto"}</label>
            <select className="form-select" value={id} onChange={(e) => { setValue(e.target.value) }}>
                <option>...</option>
                {lista &&
                    lista.map((item: any, index: number) => {
                        return (
                            <option key={index} value={item.DataBeanProperties.IDProject} >{item.DataBeanProperties.Name}</option>
                        )
                    })
                }
            </select>
        </>
    )
}