import { useEffect, useState } from "react";
import useDataBean from "../../../../../core/services/dataBean/useDataBean";
import { _EmployeeBonus, _EmploymentBonusValue } from "../../../../../core/services/dataBean/EntityCatalog";
import { Singleton } from "../../../../../core/services/Singleton";
import { BasicTable, MASK_MONEY, MASK_NUMBER } from "../../../../theme/Component/BasicTable";
import { ListMenu } from "../../../../theme/Component/ListMenu";
import { Modal } from "../../../../theme/Component/Modal";
import { ModalConfirm } from "../../../../theme/Component/ModalConfirm";
import { AccountEditor } from "../../../../editor/AccountEditor";
import { InputDate } from "../../../../theme/Input/InputDate";
import { BasicSwitch } from "../../../../theme/Component/BasicSwitch";
import { InputMoney } from "../../../../theme/Input/InputMoney";

interface Props {
    idEmploymentBonus: number;
}

export const EmploymentBonusValue = ({ idEmploymentBonus }: Props) => {

    const employeeBonusVal = useDataBean(_EmploymentBonusValue);
    const employeeBonus = useDataBean(_EmployeeBonus);
    const single = Singleton.getInstance();
    const [lista, setLista] = useState<any>([]);
    const [modal, setModal] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-md' });
    const [modalC, setModalC] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });

    useEffect(() => {
        getLista();
    }, [idEmploymentBonus])


    const getLista = () => {
        employeeBonusVal.instance.getAppointmentsByEmployeeBonus(idEmploymentBonus).then(
            (resp) => {
                let bonuses = resp;
                bonuses.forEach((bonus) => {
                    bonus.DataBeanProperties.Apointmentself = (bonus.DataBeanProperties.Code ?? "") + " " + (bonus.DataBeanProperties.Name ?? "")
                });
                setLista(bonuses)
            }
        )
    }

    const deleteItem = () => {
        employeeBonusVal.instance.deleteEmployeeBonusValue(employeeBonusVal.bean.IDValue).then(
            (resp) => {
               //console.log(resp);
                getLista();
                single.delete(employeeBonusVal.bean.IDAccount, employeeBonusVal.bean);
                setModalC({ ...modalC, "view": false });
            }
        )
    }

    const createItem = () => {
        employeeBonus.instance.createEmployeeBonus(idEmploymentBonus, employeeBonusVal.bean.IDAccount, employeeBonusVal.bean.BonusValue, single.getAccountID(), employeeBonusVal.bean.IsTemporary, employeeBonusVal.bean.FromDate, employeeBonusVal.bean.UptoDate).then(
            (resp) => {
               //console.log(resp);
                single.create(employeeBonusVal.bean);
                setModal({ ...modal, "view": false })
                getLista();
            }
        )
    }

    const head = [
        { title: 'ID', property: 'IDValue', mask: MASK_NUMBER, visible: true },
        { title: 'Identificación', property: 'Nit', mask: MASK_NUMBER, visible: true },
        { title: 'Empleado', property: 'EntityName', visible: true },
        { title: 'Nivel Salarial', property: 'Apointmentself', visible: true },
        { title: 'Salario', property: 'Salary', mask: MASK_MONEY, visible: true },
        { title: 'Vr Beneficio', property: 'BonusValue', mask: MASK_MONEY, visible: true },
        { title: 'Temporal', property: 'IsTemporary', visible: true },
        { title: 'Fecha Inicio', property: 'FromDate', visible: true },
        { title: 'Fecha Final', property: 'UptoDate', visible: true },
    ]

    const iconButtonsItem = [
        // { nombre: 'Agregar', visible: single.canCreate(), icono: 'ri-file-add-line', evento: create }
    ]
    const listaBotones = () => {
        const botones = [
            { titulo: 'Definir Valor del Beneficio por Empleado', icono: 'ri-edit-2-line', visible: single.canEdit(), evento: () => { setModal({ ...modal, "name": "Editar Primas Adicionales y Bonificaciones", "view": true }) } },
            { titulo: 'Eliminar Valor del Beneficio', icono: 'ri-delete-bin-line', visible: single.canDelete(), evento: () => { setModalC({ ...modalC, "name": 'Eliminar Valor del Beneficio', "view": true }) } },
        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }


    return (
        <>
            <div className="row">
                <div className="col-md-12">
                    <BasicTable body={lista} head={head} rowSet={employeeBonusVal.setBean} listButtons={listaBotones()} iconButtons={iconButtonsItem} />
                </div>

            </div>

            {modal.view &&
                <Modal modal={modal} updateModal={setModal} eventModal={createItem}>
                    <div className="row">
                        <div className="col-md-6">
                            <label>Vr Beneficio</label>
                            <InputMoney id={0} name="BonusValue" value={employeeBonusVal.bean.BonusValue} onChange={(e) => { employeeBonusVal.setBean({ ...employeeBonusVal.bean, "BonusValue": e }) }} />
                        </div>
                        <div className="col-md-6 d-flex-wrap align-content-center">
                            <BasicSwitch label="Beneficio Temporal" estado={employeeBonusVal.bean.IsTemporary} eventChange={(e) => { employeeBonusVal.setBean({ ...employeeBonusVal.bean, "IsTemporary": e }) }} />
                        </div>
                        <div className="col-md-6">
                            <InputDate notDate label="Fecha Inicio" name="From" value={employeeBonusVal.bean.FromDate} setDate={(e) => { employeeBonusVal.setBean({ ...employeeBonusVal.bean, "FromDate": e }) }} />
                        </div>
                        <div className="col-md-6">
                            <InputDate notDate label="Fecha Fin" name="Upto" value={employeeBonusVal.bean.UptoDate} setDate={(e) => { employeeBonusVal.setBean({ ...employeeBonusVal.bean, "UptoDate": e }) }} />
                        </div>
                    </div>
                </Modal>
            }

            {modalC.view &&
                <ModalConfirm modal={modalC} updateModal={setModalC} eventModal={deleteItem}>
                    <strong>Eliminar Bonus de {employeeBonusVal.bean.EntityName}</strong>
                </ModalConfirm>
            }
        </>
    )
}