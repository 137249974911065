import { useEffect, useState } from "react";
import { _Training } from "../../../../../core/services/dataBean/EntityCatalog";
import useDataBean from "../../../../../core/services/dataBean/useDataBean";
import { Singleton } from "../../../../../core/services/Singleton";
import { ListMenu } from "../../../../theme/Component/ListMenu";
import { BasicTable } from "../../../../theme/Component/BasicTable";
import { Modal } from "../../../../theme/Component/Modal";
import { ModalConfirm } from "../../../../theme/Component/ModalConfirm";
import { toast } from "react-toastify";

interface PropTraining {
    _IDAppointManual: number;
}

export const Training = ({ _IDAppointManual }: PropTraining) => {

    const training = useDataBean(_Training);
    const single = Singleton.getInstance();
    const [lista, setLista] = useState<any>([]);
    const [modal, setModal] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-md' });
    const [modalC, setModalC] = useState<any>({ name: 'Eliminar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });

    useEffect(() => {
        if (_IDAppointManual >= 0) {
            getLista();
        }
    }, [_IDAppointManual])

    const getLista = () => {

        training.instance.getTrainingCatalog(null, _IDAppointManual, null).then(
            (resp: any) => {
                if (resp) {
                    setLista(resp)
                   //console.log(resp)
                }
            }
        )

    }

    const create = () => {
        training.setBean({
            IDAppointmentManual: _IDAppointManual,
        });
        setModal({ ...modal, "name": "Agregar Formación Académica del Cargo", "view": true });
    }

    const deleteItem = () => {
        training.instance.deleteTraining(training.bean).then(
            (resp: any) => {
                getLista();
                setModalC({ ...modalC, "view": false });
            }
        )

    }

    const editaItem = () => {
        training.update().then(
            (resp: any) => {
                getLista();
                setModal({ ...modal, "view": false });
            }
        ).catch(err => {
            toast.error(err);
        })

    }

    const head = [
        { title: 'ID', property: 'IDTraining', visible: true },
        { title: 'Descripción', property: 'Description', visible: true },

    ]

    const iconButtonsItem = [
        { nombre: 'Agregar', visible: single.canCreate(), icono: 'ri-file-add-line', evento: create }
    ]
    const listaBotones = () => {
        const botones = [
            { titulo: 'Editar', icono: 'ri-edit-2-line', visible: single.canEdit(), evento: () => { setModal({ ...modal, "name": `Editar Formación Académica del Cargo`, "view": true }) } },
            { titulo: 'Eliminar', icono: 'ri-delete-bin-line', visible: single.canDelete(), evento: () => { setModalC({ ...modalC, "view": true }) } },

        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }


    return (
        <div className="row">
            <div className="col-md-12">
                <BasicTable body={lista} head={head} rowSet={training.setBean} listButtons={listaBotones()} iconButtons={iconButtonsItem}></BasicTable>
                <Modal modal={modal} updateModal={setModal} eventModal={editaItem}>
                    <div className="row">
                        <div className="col-md-12">
                            <label>Descripción</label>
                            <textarea name="Description" className="form-control" value={training.bean.Description} onChange={training.handleChange} />
                        </div>

                    </div>
                </Modal>
                <ModalConfirm modal={modalC} updateModal={setModalC} eventModal={deleteItem}>
                    <strong>Eliminar item {training.bean.IDTraining}</strong>
                </ModalConfirm>
            </div>
        </div>
    )
}