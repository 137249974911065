import { LuPalmtree } from 'react-icons/lu'
import { LiaCertificateSolid } from 'react-icons/lia'
import { PiMoney, PiOfficeChairFill, PiNotebookFill, PiNoteFill } from 'react-icons/pi'
import { QuickAction } from '../components/MenuDashed/MenuDashed'
import { useState } from 'react'
import { EmployeeContract } from '../../../admin/perfil/elements/EmployeeContract'
import { PayrollProcess } from '../../../admin/perfil/elements/PayrollProcess'
import { EmployeeFixedAssets } from '../../../admin/perfil/elements/EmployeeFixedAssets'
import { OnVacation } from '../components/OnVacation/OnVacation'
import { PerosnalInbox } from '../components/PersonaInbox/Personalnbox'
import { Navigate, useNavigate } from 'react-router-dom'
import { ContracsDashedMenu } from '../components/ContracsDashed/ContracsDashedMenu'

const useDashed = () => {
    const navigate = useNavigate();

    const [activeQA, setActiveQA] = useState<QuickAction | undefined>({
        icon: <LiaCertificateSolid />,
        label: 'Certificados',
        event: (e) => { setActiveQA(e) }
    })

    const example: QuickAction[] = [
        {
            icon: <LiaCertificateSolid />,
            label: 'Certificados',
            event: (e) => { setActiveQA(e) }
        },
        {
            icon: <PiMoney />,
            label: 'Nómina',
            event: (e) => { setActiveQA(e) }
        },
        {
            icon: <LuPalmtree />,
            label: 'Vacaciones',
            event: (e) => { setActiveQA(e) }
        },
        {
            icon: <PiOfficeChairFill />,
            label: 'Activos Fijos',
            event: (e) => { setActiveQA(e) }
        },
        {
            icon: <PiNotebookFill />,
            label: 'Cursos',
            event: (e) => { navigate("/app/admin/miscursos"); }
        },
        {
            icon: <PiNoteFill />,
            label: 'Contratos Laborales',
            event: (e) => { setActiveQA(e) }
        },
    ]

    const renderSwitch = () => {
        switch (activeQA ? activeQA.label : '') {
            case 'Certificados': return (
                <EmployeeContract />
            )
            case 'Nómina': return (
                <PayrollProcess />
            )
            case 'Activos Fijos': return (
                <EmployeeFixedAssets />
            )
            case 'Vacaciones': return (
                <OnVacation />
            )
            case 'Contratos Laborales': return (
                <ContracsDashedMenu />
            )
            default:
                break;
        }
    }

    return { example, activeQA, setActiveQA, renderSwitch }
}

export default useDashed