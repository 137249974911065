import { DataBean } from "../DataBean";

export class AdquisitionContractCDP extends DataBean {

    public async getAdquisitionContractCDPCatalogPorPropiedades(props: any, maxRows: number): Promise<any> {
        return this.getCustomService("FinanceCore", "java.util.List_getAdquisitionContractCDPCatalogPorPropiedades_java.util.Map_Number", [props, maxRows]);
    }



}