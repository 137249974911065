import { useEffect, useState } from 'react'
import { Doc, ModalGeneric } from '../../../interfaces'
import { FileService } from '../../../core/services/FileService'
import { Modal } from '../../theme/Component/Modal'
import { FileInput } from '../../theme/Component/FileInput'
import { AdminService } from '../../../core/services/AdminService'
import { Singleton } from '../../../core/services/Singleton'
import '../styles/dashboard.css'

const _fileService = new FileService()
const _adminService = new AdminService()
const _single = Singleton.getInstance()

interface Props {
    idAccount: number | undefined
    size: 'xl' | 'lg' | 'md' | 'sm' | 'xs'
    className?: string
}
export const Avatar = ({ idAccount, size, className }: Props) => {

    const [profileImage, setProfileImage] = useState<Doc>({
        Media: '',
        MediaContext: ''
    })
    const [modalUpload, setModalUpload] = useState<ModalGeneric>({
        name: 'Foto de perfil',
        tabIndex: -1,
        btnClose: true,
        btnSubmit: true,
        format: 'modal-md',
        view: false
    })

    useEffect(() => {
        getAccountPhoto()
    }, [])


    const getAccountPhoto = () => {
        _single.spinner(true)
        _adminService.getProfilePhotobyAccount(idAccount)
            .subscribe((resp: any) => {
                _single.spinner(false)
               //console.log(resp)
            })
    }

    return (
        <>
            <div className={`${className}`}>
                <div className={`avatar-${size}`} onClick={() => {
                    setModalUpload({ ...modalUpload, view: true });
                    setProfileImage({
                        Media: '',
                        MediaContext: ''
                    })
                }}>
                    <img
                        className='img-thumbnail rounded-circle h-100'
                        style={{ objectFit: 'cover' }}
                        src={(profileImage.MediaContext !== '' && profileImage.MediaContext !== undefined)
                            ? _fileService.getUrlFile(profileImage.MediaContext, profileImage.Media)
                            : "assets/images/core/avatar.png"}
                        alt="profile_image" />
                    <div className="edit_ph text-white">
                        <strong>Cambiar foto</strong>
                    </div>
                </div>
            </div>
            {modalUpload.view &&
                <Modal modal={modalUpload} updateModal={setModalUpload} eventModal={() => {
                    setModalUpload({ ...modalUpload, view: false });
                }} >
                    <FileInput onCreate={({ Media, MediaContext }: any) => {
                        setProfileImage({
                            MediaContext,
                            Media
                        })
                    }} />
                </Modal>
            }
        </>
    )
}
