import { useEffect, useState } from 'react';

interface Props {
    children: any;
    modal: any;
    eventModal?: any;
    updateModal: any;
    onSubmit?: any;
    onSubmitLabel?: string;
}

export const Modal = ({ children, modal, eventModal, updateModal, onSubmit, onSubmitLabel }: Props) => {
    const [ver, setVer] = useState('none');
    const [opne, setOpen] = useState(false);

    useEffect(() => {
        setOpen(modal.view);
        return () => {
            setOpen(false);
        };
    }, [modal]);

    useEffect(() => {
        setVer(modal.view ? 'block' : 'none');
        setOpen(modal.view);
    }, [modal.view]);

    const handleSizeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        updateModal({ ...modal, format: e.target.value });
    };

    return (
        <>
            <div
                className={`modal fade show ${modal.format} animate__animated animate__fadeIn`}
                tabIndex={modal.tabIndex}
                style={{ display: ver }}
            >
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="myModalLabel">
                                {modal.name}
                            </h5>
                            {/* <select
                                className="form-select form-select-sm w-auto ms-3"
                                value={modal.format}
                                onChange={handleSizeChange}
                            >
                                <option value="modal-sm">sm</option>
                                <option value="modal-md">md</option>
                                <option value="modal-lg">lg</option>
                                <option value="modal-xl">xl</option>
                            </select> */}
                            <button
                                type="button"
                                className="btn-close w-auto"
                                aria-label="Close"
                                onClick={() => {
                                    updateModal({ ...modal, view: false });
                                }}
                            ></button>
                        </div>
                        <div className="modal-body">
                            <div>
                                {opne === true && (
                                    <>
                                        {children}
                                    </>
                                )}
                            </div>
                        </div>
                        <div className="modal-footer">
                            {modal.btnClose && (
                                <button
                                    type="button"
                                    className="btn btn-light"
                                    data-bs-dismiss="modal"
                                    onClick={() => {
                                        updateModal({ ...modal, view: false });
                                    }}
                                >
                                    Cerrar
                                </button>
                            )}
                            {modal.btnSubmit && eventModal && (
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={eventModal}
                                >
                                    {onSubmitLabel ? onSubmitLabel : 'Guardar'}
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            {ver === 'block' && <div className="modal-backdrop fade show"></div>}
        </>
    );
};
