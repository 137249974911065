import { useEffect, useState } from "react";
import { _single } from "../../../../core/services/dataBean/EntityCatalog";

interface Props {
    listButtons: any[];
    titulo: any[];
}
export const HeadTable = ({ listButtons, titulo }: Props) => {

    const [lista, setLista] = useState<any[]>([]);

    useEffect(() => {
        if (listButtons)
            setLista(listButtons);
    }, [])

    return (
        <thead className="table-head">
            <tr>
                {listButtons &&
                    <th key={_single.getRandomInt()}></th>
                }
                {titulo.length > 0 &&
                    titulo.map((item: any, index: number) => (
                        item.visible && <th key={index} className="align-middle">{item.title}</th>
                    ))
                }
            </tr>
        </thead>
    )
}