import { DataBean } from "../DataBean";

export class AcademicInformation extends DataBean {

    public async getAcademicInformationCatalogPorPropiedades(properties: any, maxRows: number): Promise<any> {
        return this.getCustomService("TalentERP", "java.util.List_getAcademicInformationCatalogPorPropiedades_java.util.Map_Number", [properties, maxRows]);
    }

    public async getAcademicInformationCatalogRender( maxRows: number, properties: any): Promise<any> {
        return this.getCustomService("TalentERP", "java.util.List_getAcademicInformationCatalogRender_Number_java.util.Map", [ maxRows, properties]);
    }

}