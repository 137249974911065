import { DataBean } from "../DataBean";

export class AdquisitionPlan extends DataBean{

     PLANNING_PHASE = 0;
     STRUCTURING_PHASE = 1;
     HIRING_PHASE = 2;
     IN_PREPARATION = 30;
     PREPARED = 31;
     CREATED = 3;
     STRUCTURED = 4;
     REVIEWED = 5;
     APPROVED = 6;
     REJECTED = 7;
     CANCELLED = 8;
     INCOMPLETED = 9;
     VALUE_MISMATCH = 27;
     IN_PRECONTRACTUAL = 40;
     IN_CONTRACTET = 41;
     OPENED = 10;
     PREHIRING = 11;
     CONTRACTED = 12;
     PLAN_LEVEL = 13;
     ITEM_LEVEL = 14;
     ARTICLE_LEVEL = 15;
     STRUCTURER_PLAN_LEVEL = 16;
     STRUCTURER_ITEM_LEVEL = 17;
     STRUCTURER_ARTICLE_LEVEL = 18;
     REVIEWER_PLAN_LEVEL = 19;
     REVIEWER_ITEM_LEVEL = 20;
     REVIEWER_ARTICLE_LEVEL = 21;
     APPROVER_PLAN_LEVEL = 22;
     APPROVER_ITEM_LEVEL = 23;
     APPROVER_ARTICLE_LEVEL = 24;
     OPEN = 25;
     CLOSE = 26;
     ANNUAL_PLAN = 28;
     EVENT_PLAN = 29;

    public async getAdquisitionPlanCatalog(fromYear:number,uptoYear:number): Promise<any> {
        return this.getCustomService("FinanceCore", "com.advantage.shared.Report_getAdquisitionPlanCatalog_Number_Number_Number_Number_Number", [fromYear,uptoYear,null,null,null]);
    }
    public async getAdquisitionPlanPhaseConstants(): Promise<any> {
        return this.getCustomService("FinanceCore", "java.util.List_getAdquisitionPlanPhaseConstants_Number", [null]);
    }
   
}