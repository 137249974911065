import { useEffect, useState } from "react";
import { BpmService } from "../../../../../core/services/BpmService";
import { toast } from "react-toastify";
import { PrintJson } from "../../../../theme/Component/PrintJson";
import { useDashboardStore } from "../../../../pages/hooks/useDashboardStore";

interface Props {
    idJasonS: number,
}

export const ValidateInvJson = ({ idJasonS }: Props) => {


    const { setLoading } = useDashboardStore();
    const service = new BpmService();
    const [bean, setBean] = useState<any>({});

    useEffect(() => {
        invoke();
        //console.log(idJasonS)
    }, [idJasonS])

    const invoke = () => {
        setLoading(true);
        service.validateForInvokeJsonService(idJasonS).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue) {
                    setLoading(false);
                    setBean(resp.DataBeanProperties.ObjectValue)
                    toast.success('🚀 Se ha invocado el servicio satisfatoriamente!')
                }
                else {
                    toast.error('Ha ocurrido un error inesperado')
                }
            });
    }


    return (
        <>
            <div className="row">
                <div className="col-md-12">
                    <PrintJson json={bean}></PrintJson>
                </div>
            </div>
        </>
    )
}