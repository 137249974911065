import { useEffect, useState } from "react";
import { FindSupplier } from "../FindSupplier";
import { BasicButton } from "../../theme/Component/BasicButton";
import { ContractService } from "../../../core/services/ContractService";
import { Singleton } from "../../../core/services/Singleton";
import { Alert } from "../../theme/Component/Alert";
import { FaIndustry, FaTrash } from "react-icons/fa";
import { toast } from "react-toastify";

interface Props {
    config: any
    setLoading: (nValue: boolean) => void
    exportMap:any;
}

const INVITED = 1
const ACCEPTED = 2
const DELETED = 7
const DEFEATED = 4
const DISCARTED = 5
const AWARED = 6

const service = new ContractService();
const single = Singleton.getInstance()
export const PublishHiringProcess = ({ setLoading, config,exportMap }: Props) => {

    const [created, setCreated] = useState<any>(undefined)
    const [obs, setObs] = useState('')
    const [isPublic, setIsPublic] = useState(true)
    const [suppliers, setSuppliers] = useState<any[]>([])

    useEffect(() => {
        getPostOfertRenderByIDHiringConfig()
    }, [])

    const getPostOfertRenderByIDHiringConfig = () => {
        setLoading(true)
        service.getPostOfertRenderByIDHiringConfig(config.IDHiringProcessConfig)
            .subscribe((resp: any) => {
                setLoading(false)
               //console.log(resp.DataBeanProperties.ObjectValue);
                if (resp.DataBeanProperties.ObjectValue) {
                    const arr = resp.DataBeanProperties.ObjectValue.DataBeanProperties.OffertList.map(e => e.DataBeanProperties)
                    setSuppliers(arr)
                    setIsPublic(resp.DataBeanProperties.ObjectValue.DataBeanProperties.IsPublic)
                    setObs(resp.DataBeanProperties.ObjectValue.DataBeanProperties.Observation)
                    setCreated(resp.DataBeanProperties.ObjectValue)
                }
            })
    }


    const postOffer = () => {
        setLoading(true)
        service.postOffer(
            config.IDHiringProcessConfig,
            single.getAccountID(),
            obs,
            isPublic,
            suppliers.map(e => e.IDSupplier)
        )
            .subscribe((resp: any) => {
               //console.log('resp --->', resp);
                setLoading(false)
                if (resp.DataBeanProperties.ObjectValue) {
                    setCreated(true)
                    getPostOfertRenderByIDHiringConfig()
                    toast.success('Publicación realizada con éxito!')
                    exportMap(resp.DataBeanProperties.ObjectValue);
                }
                else if (resp.DataBeanProperties.ErrorMessage) {
                   
                    toast.error(resp.DataBeanProperties.ErrorMessage);
                }
            })
    }

    const removeOfferSupplier = (idSupplier) => {
        setLoading(true)
        service.removeOfferSupplier(created.IDPostOffer, idSupplier)
            .subscribe((resp: any) => {
                setLoading(false)
               //console.log(resp);
                if (resp.DataBeanProperties.ObjectValue) {
                    getPostOfertRenderByIDHiringConfig()
                    toast.success('Se eliminó correctamente!')
                } else {
                    toast.error('Algo salió mal!')
                }
            })
    }

    const canPost = (): boolean => {
        if (!isPublic) {
            if (suppliers.length > 0) {
                return true
            } else {
                return false
            }
        } else if (suppliers.length > 0) {
            return true
        } else {
            return false
        }
    }

    const getErrorMessase = () => {
        if (!isPublic) {
            if (suppliers.length > 0) {
                return ''
            } else {
                return <span className="text-danger">Se necesita al menos un Proveeedor</span>
            }
        } else if (suppliers.length === 0) {
            return <span className="text-warning">No se ha agregado ningún Proveedor aún!</span>
        }
    }

    const removeFromList = (idSupplier) => {
        const arr = suppliers.filter((e) => e.IDSupplier !== idSupplier)
        setSuppliers(arr)
    }

    return (
        <>
            {(created === undefined) &&
                <Alert clase="warning">
                    No existe una configuración para el proceso desea crear una <br />
                    <BasicButton icon=" ri-logout-circle-r-line" eventClick={() => { setCreated(true) }}>Crear</BasicButton>
                </Alert>
            }
            {(created !== undefined) &&
                <div>
                    <div className="d-flex align-items-center gap-2 mb-2 px-5">
                        <input className="form-check-input" type="checkbox" checked={isPublic} id="flexCheckDefault"
                            onChange={() => setIsPublic(prev => !prev)}
                        />
                        <label className="form-check-label mb-2" htmlFor="flexCheckDefault">
                            ¿Es público?
                        </label>
                    </div>
                    <div className="px-5 py-2">
                        <FindSupplier idSupplier={0} handleEvent={(e: any) => {
                            
                            if (e.IDSupplier) {
                                setSuppliers([...suppliers, e])

                            }
                        }} />
                        <div className="alert alert-success mt-5 p-3">
                            <span className="text-dark"><small>Proveedores Invitados:</small></span>
                            <div style={{ maxHeight: 230, overflowY: 'auto' }}>
                                {suppliers.map((e, i) =>
                                    <li key={i} className="list-group-item" aria-disabled="true">
                                        <div className="d-flex justify-content-between align-items-center gap-5 m-3 px-3 py-2 border rounded alert alert-light"
                                            style={{ borderColor: 'black', width: '60%' }}
                                        >
                                            <div className="d-flex">
                                                <div className="flex-shrink-0 text-dark">
                                                    <FaIndustry />
                                                </div>
                                                <div className="flex-grow-1 ms-2 text-dark d-flex flex-column">
                                                    <div>{e.NameLegalRep} | <strong className={e.StateFlow === DELETED ? 'text-danger' : 'text-success'}>{e.nit ?? e.StateFlowName}</strong></div>
                                                    <div><small>({e.Name1 ?? 'Nombre de la compañia'})</small></div>
                                                    <b />
                                                </div>
                                            </div>
                                            {e.StateFlow !== DELETED &&
                                                <div className="flex-shrink-0 text-danger">
                                                    <FaTrash className="pointer" title="Eliminar" onClick={() => { created !== undefined ? removeOfferSupplier(e.IDSupplier) : removeFromList(e.IDSupplier) }} />
                                                </div>
                                            }
                                        </div>
                                    </li>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="mt-3 px-5">
                        <div className="d-flex flex-column">
                            <label htmlFor="floatingTextarea">Observaciones:</label>
                            <textarea style={{ resize: 'none', minHeight: 100 }} className="form-control"
                                placeholder="Deje sus observaciones aquí"
                                value={obs}
                                onChange={(e) => setObs(e.target.value)} />
                        </div>
                    </div>
                    <div className="mt-5">
                        {getErrorMessase()}
                        <BasicButton
                            disable={!canPost()}
                            icon="ri-logout-circle-r-line"
                            clase="primary"
                            eventClick={() => { postOffer() }}>
                            Publicar
                        </BasicButton>
                    </div>
                </div>}
        </>
    )
}
