import { useEffect, useState } from "react";
import { Singleton } from "../../../core/services/Singleton";
import { TalentService } from "../../../core/services/TalentService";
import { BasicTable } from "../../theme/Component/BasicTable";
import { ListMenu } from "../../theme/Component/ListMenu";

interface Props {
    idProofAcc: number;
}

export const ProofAnswer = ({ idProofAcc }: Props) => {

    const services = new TalentService();
    const single = Singleton.getInstance();
    const [lista, setLista] = useState([]);
    const [bean, setBean] = useState<any>({});
    const [modal, setModal] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalConfirm, setModalConfirm] = useState<any>({ name: 'Confirmar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });

    useEffect(() => {
        if (idProofAcc >= 0) {
            getLista()
        }
    }, [idProofAcc])

    const idBean = 'IDProofAnswer';

    const head = [
        { title: 'ID', property: idBean },
    ]

    const setRowBean = (bean: any) => {
       //console.log("Row : ", bean);
        setBean(bean);
    }

    const getLista = () => {
        const x = {
            IDProofAccounts: idProofAcc,
        }
        single.spinner(true);
        services.getProofAnswerCatalogPorPropiedades(x, null).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue) {
                    setLista(resp.DataBeanProperties.ObjectValue)
                }
                single.spinner(false)
            }
        )
    }

   

    const updateItem = () => {
        setModal({ ...modal, "view": false })

    }

    const agregarItem = () => {
        let b = {
        }
        setBean(b);
        setModal({ ...modal, 'view': true });
    }

  

  

    return (
        <>
            <div className="row">
                <BasicTable body={lista} head={head} rowSet={setRowBean}></BasicTable>
            </div>
        </>
    )
}