import { useEffect, useState } from "react";
import useDataBean from "../../../core/services/dataBean/useDataBean";
import { _Entailment } from "../../../core/services/dataBean/EntityCatalog";
import { Singleton } from "../../../core/services/Singleton";
import { toast } from "react-toastify";
import { ListMenu } from "./ListMenu";
import { BasicPage } from "./BasicPage";
import { BasicTable } from "./BasicTable";
import { Modal } from "./Modal";
import { ModalConfirm } from "./ModalConfirm";
import { BasicButton } from "./BasicButton";
import { BasicSwitch } from "./BasicSwitch";
import { SelectAdquisitionArticleConstants } from "../../contract/select/SelectAdquisitionArticleConstants";
import { SelectorUmeasurement } from "../../bpm/config/Componentes/SelectorUmeasurement";
import { FinanceService } from "../../../core/services/FinanceService";
import { _Product } from "../../../core/services/dataBean/EntityCatalogVdos";
import { SelectProductLine } from "./SelectProductLine";
import { PrintJson } from "./PrintJson";

interface Props {
    UBLItemInvoice?: any;
    _onChange?: Function;
}

export const ProductEditor = ({ UBLItemInvoice, _onChange }: Props) => {
    const service = new FinanceService();
    const ProductEditor = useDataBean(_Product);
    const single = Singleton.getInstance();
    const [nameprefix, setNameprefix] = useState<string>(null);
    const [lista, setLista] = useState<any>([]);
    const [missingFields, setMissingFields] = useState<string[]>([]);
    const [modal, setModal] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalC, setModalC] = useState<any>({ name: "Eliminar", tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });
    const [modalUnit, setModalUnit] = useState<any>({ name: "Agregar Unidad", tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-md' });

    useEffect(() => {
        getLista();
    }, [UBLItemInvoice])

    const getLista = () => {

        ProductEditor.instance.getInventoryCatalog(nameprefix, false, null).then(
            (resp: any) => {
                if (resp) {
                    setLista(resp)
                }
            }
        )

    }

    const Unidad = () => {
        let b = {
            Name: '',
            Code: '',
            Since: single.getCurrenDate(),
        }
        ProductEditor.setBean(b);

        setModalUnit({ ...modalUnit, "view": true });

    }

  

    const create = () => {
        ProductEditor.setBean({
            Description: UBLItemInvoice ? UBLItemInvoice.Description : "",
            Recipe: false,

        });
        setModal({ ...modal, "view": true });
    }

    const deleteItem = () => {
        ProductEditor.deleteItemVoid(ProductEditor.bean.IDEntailment).then(
            (resp: any) => {
                getLista();
                setModalC({ ...modalC, "name": `Eliminar`, "view": false });
            }
        )
    }

    const editaItem = () => {
        ProductEditor.update().then(
            (resp: any) => {
               //console.log(resp);
                getLista();
                setModal({ ...modal, "view": false });
            }
        ).catch(err => {
            toast.error(err);
        })

    }

    const head = [
        { title: 'ID', property: 'IDProduct', visible: true },
        { title: 'Nombre', property: 'Description', visible: true },
        { title: 'Código del Producto', property: 'Prefix', visible: true },
        { title: 'Código de Barras', property: 'Reference', visible: true },
        { title: 'Grupo del Producto', property: 'IDLine', visible: true },
        { title: 'Linea del Prodcuto', property: 'ProductLine', visible: true },
        { title: 'Es receta', property: 'Recipe', visible: true },
        { title: 'Unidad de Medida', property: 'IDMeasurementUnit', visible: true },
        { title: 'Tipo de Artículo', property: 'AdquisitionArticleType', visible: true },
    ]

    const iconButtonsItem = [
        { nombre: 'Agregar', visible: single.canCreate(), icono: 'ri-file-add-line', evento: create }
    ]

    const listaBotones = () => {
        const botones = [
            { titulo: 'Editar', icono: 'ri-edit-2-line', visible: single.canEdit(), evento: () => { setModal({ ...modal, "name": `Editar ${ProductEditor.bean.Name}`, "view": true }) } },
            { titulo: 'Eliminar', icono: 'ri-delete-bin-line', visible: single.canDelete(), evento: () => { setModalC({ ...modalC, "view": true }) } },
        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }

    const setRowBean = (bean: any) => {
        ProductEditor.setBean(bean);
        if (_onChange) {
            _onChange(bean);
        }
    }

    const validateData = () => {

        if (validateForm() > 0) {
            toast.error("Faltan campos obligatorios por completar");
            return;
        } else {
            editaItem();
        }
    }

    const validateForm = () => {
        const requiredFields = [
            "Description", "Prefix", "Reference", "IDLine", "IDMeasurementUnit", "AdquisitionArticleType"
        ];
        let missing = [];

        for (let field of requiredFields) {
            if (ProductEditor.bean[field] === null || ProductEditor.bean[field] === undefined || ProductEditor.bean[field] === "") {
                missing.push(field);
            }
        }
        setMissingFields(missing);
        return missing.length;
    };

    return (
        <>
            <div className="row">
                <div className="col-md-6">
                    <label>Nombre a buscar</label>
                    <input type="text" className="form-control" value={nameprefix} onChange={(e) => { setNameprefix(e.target.value) }} />
                </div>
                <div className="col-6 mt-4">
                    <BasicButton icon="ri-search-line" eventClick={getLista}>Buscar</BasicButton>
                </div>
            </div>
            <BasicTable body={lista} head={head} rowSet={setRowBean} listButtons={listaBotones()} iconButtons={iconButtonsItem}></BasicTable>

            <Modal modal={modal} updateModal={setModal} eventModal={validateData}>
                <div className="row">

                    <div className="col-md-12">
                        <label className={`${missingFields.includes('Description') ? 'has-error' : ''}`} >Nombre</label>
                        <textarea name="Description" className="form-control" value={ProductEditor.bean.Description} onChange={ProductEditor.handleChange} />
                    </div>
                    <div className="col-md-6">
                        <label className={`${missingFields.includes('Prefix') ? 'has-error' : ''}`} >Código del Producto</label>
                        <input type="text" name="Prefix" className="form-control" value={ProductEditor.bean.Prefix} onChange={ProductEditor.handleChange} />

                    </div>
                    <div className="col-md-6">
                        <label className={`${missingFields.includes('Reference') ? 'has-error' : ''}`} >Código de Barras</label>
                        <input type="text" name="Reference" className="form-control" value={ProductEditor.bean.Reference} onChange={ProductEditor.handleChange} />

                    </div>
                    <div className="col-md-6">
                        <label className={`${missingFields.includes('IDLine') ? 'has-error' : ''}`} >Grupo del Producto:</label>
                        <SelectProductLine label=" " idSelector={ProductEditor.bean.IDLine} onChange={(e: any) => { ProductEditor.setBean({ ...ProductEditor.bean, "IDLine": e }) }}></SelectProductLine>
                    </div>
                    <div className="col-md-6">
                        <label className={`${missingFields.includes('Recipe') ? 'has-error' : ''}`} >Es receta ?</label>
                        <BasicSwitch estado={ProductEditor.bean.Recipe} label=" " eventChange={(e: any) => {
                            ProductEditor.setBean({ ...ProductEditor.bean, "Recipe": e })
                        }}></BasicSwitch>

                    </div>
                    {/* <div className="col-md-6">
                        <div className="row d-flex align-items-end">
                            <div className="col-md-8">
                                <label className={`${missingFields.includes('IDMeasurementUnit') ? 'has-error' : ''}`} >Unidad de Medida</label>
                                <SelectorUmeasurement titulo=' ' idSelector={ProductEditor.bean.IDMeasurementUnit} onChange={(e: any) => { ProductEditor.setBean({ ...ProductEditor.bean, "IDMeasurementUnit": e }) }} />

                            </div>
                            <div className="col-md-4 ">
                                <BasicButton icon="ri-add-line" eventClick={Unidad}>Agregar</BasicButton>
                            </div>
                        </div>

                    </div> */}
                    <div className="col-md-6">                        
                            <SelectorUmeasurement titulo='' idSelector={ProductEditor.bean.IDMeasurementUnit} onChange={(e: any) => { ProductEditor.setBean({ ...ProductEditor.bean, "IDMeasurementUnit": e }) }} />                            
                    </div>
                    <div className="col-md-6">
                        <label className={`${missingFields.includes('AdquisitionArticleType') ? 'has-error' : ''}`} >Tipo</label>
                        <SelectAdquisitionArticleConstants label=" " idSelector={ProductEditor.bean.AdquisitionArticleType} onChange={(e: any) => { ProductEditor.setBean({ ...ProductEditor.bean, "AdquisitionArticleType": e }) }}></SelectAdquisitionArticleConstants>
                    </div>
                </div>
            </Modal>

           

            <ModalConfirm modal={modalC} updateModal={setModalC} eventModal={deleteItem}>
                <strong>Eliminar item {ProductEditor.bean.Name}</strong>
            </ModalConfirm>
        </>
    )
}