import { useEffect, useState } from "react";
import { _CompetitionType, _Entailment, _MainTheme, _StrategicObjetive, _TransversalSkill, _TransversalSkillProduct } from "../../../../core/services/dataBean/EntityCatalog";

import { Singleton } from "../../../../core/services/Singleton";
import { toast } from "react-toastify";
import { ListMenu } from "../../../theme/Component/ListMenu";
import { BasicPage } from "../../../theme/Component/BasicPage";
import { BasicTable } from "../../../theme/Component/BasicTable";
import { Modal } from "../../../theme/Component/Modal";
import { ModalConfirm } from "../../../theme/Component/ModalConfirm";
import useDataBean from "../../../../core/services/dataBean/useDataBean";

interface Props {
    IDTransversalSkill: number;
}


export const TransversalSkillProduct = ({ IDTransversalSkill }: Props) => {

    const TransversalSkillProduct = useDataBean(_TransversalSkillProduct);

    const single = Singleton.getInstance();

    const [lista, setLista] = useState<any>([]);
    const [modalCri, setModalCri] = useState<any>({ name: 'Criterios de Desempeño', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalPro, setModalPro] = useState<any>({ name: 'Productos del Desempeño', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [transversalskilltype, setTransversalskilltype] = useState<number>(null)
    const [modal, setModal] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalC, setModalC] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });

    useEffect(() => {
        getLista();
    }, [IDTransversalSkill])

    const getLista = () => {

        TransversalSkillProduct.instance.getTransversalSkillProductCatalog(IDTransversalSkill).then(
            (resp: any) => {
                if (resp) {
                    setLista(resp)
                   //console.log(resp)
                }
            }
        )

    }

    const create = () => {
        TransversalSkillProduct.setBean({

            IDTransversalSkill: IDTransversalSkill
        });
        setModal({ ...modal, "view": true });
    }
    const deleteItem = () => {
        TransversalSkillProduct.instance.deleteTransversalSkillProduct(TransversalSkillProduct.bean).then(
            (resp: any) => {
                getLista();
                setModalC({ ...modalC, "view": false });
            }
        )

    }
    const editaItem = () => {
       //console.log(TransversalSkillProduct.bean);
        TransversalSkillProduct.update().then(
            (resp: any) => {
                getLista();
                setModal({ ...modal, "view": false });
            }
        ).catch(err => {
            toast.error(err);
        })

    }

    const head = [
        { title: 'ID', property: 'IDProduct', visible: true },
        { title: 'Descripción', property: 'Description', visible: true },



    ]

    const iconButtonsItem = [
        { nombre: 'Agregar', visible: single.canCreate(), icono: 'ri-file-add-line', evento: create }
    ]
    const listaBotones = () => {
        const botones = [

            { titulo: 'Editar', icono: 'ri-edit-2-line', visible: single.canEdit(), evento: () => { setModal({ ...modal, "view": true }) } },
            { titulo: 'Eliminar', icono: 'ri-delete-bin-line', visible: single.canDelete(), evento: () => { setModalC({ ...modalC, "view": true }) } },

        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }


    return (
        <BasicPage titulo="Producto de  Competencias Transversales">

            <div className="row">
                <div className="col-md-12">

                    <BasicTable body={lista} head={head} rowSet={TransversalSkillProduct.setBean} listButtons={listaBotones()} iconButtons={iconButtonsItem}></BasicTable>
                    <Modal modal={modal} updateModal={setModal} eventModal={editaItem}>
                        <div className="row">



                            <div className="col-md-12">
                                <label>Descripción</label>

                                <textarea name="Description" className="form-control" value={TransversalSkillProduct.bean.Description} onChange={TransversalSkillProduct.handleChange} />
                            </div>



                        </div>
                    </Modal>

                    <ModalConfirm modal={modalC} updateModal={setModalC} eventModal={deleteItem}>
                        <strong>Eliminar item</strong>
                    </ModalConfirm>
                </div>

            </div>
        </BasicPage>
    )
}