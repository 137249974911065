import { useEffect, useState } from "react";
import useDataBean from "../../../../../../../core/services/dataBean/useDataBean";
import { _RemoteAbstractAction, _single } from "../../../../../../../core/services/dataBean/EntityCatalog";
import { toast } from "react-toastify";
import { BasicButton } from "../../../../../../theme/Component/BasicButton";
import { PrintJson } from "../../../../../../theme/Component/PrintJson";
import { DocumentViewer } from "../../../../../../theme/Component/DocumentViewer";

interface Props {
    idApplicationInvoker: number,
    properties: any;
    data: any;
    setData: any;
    lastStep: boolean;
}
export const ReportViewer = ({ idApplicationInvoker, properties, lastStep, data, setData }: Props) => {

    const { instance } = useDataBean(_RemoteAbstractAction);

    const [form, setForm] = useState<any>({});
    const [required, setRequired] = useState<any>([]);
    const [documents, setDocuments] = useState<any>([]);

    useEffect(() => {
        if (properties) {
            let mapa = {};
            let req = [];
            for (const key in properties) {
                let obj = properties[key];


                mapa[key] = obj.DefaultValue;
                if (obj.Required == true) {
                    req.push(key);
                }


            }
            setRequired(req);
            console.table(mapa);
           //console.log(req);
            setForm(mapa);
        }
    }, [properties])

    const handleChange = (e: any) => {
        setForm(_single.handleFormChange(form, e));
    }
    const fireEvent = (e: any, nameEvent) => {

        instance.fireAbstractStepEvent(idApplicationInvoker, nameEvent, form).then(
            (resp: any) => {
               //console.log(resp);

                setForm(resp);
            }
        ).catch(err => toast.error(err));

    }
    const submitForm = () => {
        if (setData)
            setData(form);
    }
    return (
        <div className="row">
            <div className="col-md-12">
                <DocumentViewer list={data.ReportList} />
                {/* <PrintJson json={data} /> */}
            </div>
            {!lastStep &&
                <div className="col-md-12">
                    <BasicButton icon="ri-send-plane-2-line" clase="primary" eventClick={submitForm}>Siguiente</BasicButton>
                </div>
            }
        </div>
    )
}