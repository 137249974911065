import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import { encrypt } from "../../../utils/helpers";
import { env } from "../../../env";
import { toast } from "react-toastify";



let yekCilbup = "";
let auth = "";
let hash: any;
let clearHash: any;
const showHash = env.REACT_HASH ?? true;
const print_log = env.PRINT_LOG ?? false;
const initialization = (config: AxiosRequestConfig): AxiosInstance => {


  const axiosInstance = axios.create(config);
  /*
        Add default headers, interceptors etc..
    */


  if (yekCilbup === '') {
    const cookies = document.cookie.split('; ');
    for (const cookie of cookies) {
      const [cookieName, cookieValue] = cookie.split('=');
      if (cookieName === "yek") {
        yekCilbup = decodeURIComponent(cookieValue);

      }
    }
  }
  //  if(auth === '' || auth == undefined)
  {
    const cookies = document.cookie.split('; ');
    for (const cookie of cookies) {
      const [cookieName, cookieValue] = cookie.split('=');
      if (cookieName === "auth") {
        auth = decodeURIComponent(cookieValue);

      }

    }
  }




  axiosInstance.interceptors.request.use(
    function (request: any) {

      if (!(request.data instanceof FormData)) {

        try {
          hash = JSON.parse(request.data);

        } catch (error) {

          return request;
        }

        clearHash = hash.MethodHash;
        if (hash.MethodHash == '')
          return request;

        if (hash.MethodHash !== "com.advantage.bean.securitymanager.PublicKeyInfo_getPublicKeyForApiRest_String") 
          {

          hash.MethodHash = encrypt(yekCilbup, hash.MethodHash);

          if(hash.MethodHash === "false")
            return request;

          request.data = JSON.stringify(hash);
  
  
          if(print_log)
            if (showHash) {
              console.log("<<========== ");
              console.log("Request => ", clearHash);
              // console.log("Encrypt Request => ", hash.MethodHash);
              console.log("ArgumentList Request => ", hash.ArgumentList);
              console.log("=========>> ");
            }

        }

        if (auth !== "") {


          request.headers["Authorization"] = auth;

          // request.headers["Authorization"] = localStorage.getItem("authorization");

          return request;
        } else {

          return request;
        }

      } else {
        return request;
      }
    },
  );

  axiosInstance.interceptors.response.use(
    (response: any) => {

      return response;
    },
    (error: any) => {

      
      
      if (error.response.status === 401) {

      }
      else if (error.response.status === 400) {
        console.error(error.response.data.DataBeanProperties.ErrorMessage, "401");

        if("ERROR:JsonWebToken Invalid" === error.response.data.DataBeanProperties.ErrorMessage)
          toast.error("La sesion es invalida");
        if (print_log) {
          console.error(clearHash, "401");
        }
        console.error(error.response.data.DataBeanProperties.ErrorMessage, "400");
        localStorage.setItem("eventos", JSON.stringify({ spin: false }));
      }
      return error.response;
    }
  );

  return axiosInstance;
};

export default initialization;
