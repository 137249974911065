import { useState, useEffect } from 'react';
import { FinanceService } from '../../../../core/services/FinanceService';

interface Props {
    idType?: number;
    idSelector: number;
    onChange: any;
    _disabled?: any;
    label?: string;
}
export const SelectBudgetType = ({ idType, idSelector, onChange, _disabled, label }: Props) => {

    const service = new FinanceService();
    const [lista, setLista] = useState([]);
    const [id, setID] = useState(0);



    useEffect(() => {

        setID(idSelector);
        getLista();

    }, [])
    useEffect(() => {
        if (idType)
            getLista();
    }, [idType])


    const setValue = (e: any) => {
        setID(parseInt(e));
        onChange(parseInt(e));
    }

    const getLista = () => {

        service.getBudgetTypeCatalog(idType ?? null).subscribe(
            (resp: any) => {

                //console.log("BudgetType ", resp);

                if (resp.DataBeanProperties.ObjectValue) {
                    setLista(resp.DataBeanProperties.ObjectValue);
                }
            }
        );
    }

    return (
        <>
            <label>{label ?? "Tipo de Presupuesto"}</label>
            <select disabled={_disabled} className="form-control" value={id} onChange={(e) => { setValue(e.target.value) }}>
                <option value={0}>...</option>
                {lista &&
                    lista.map((item: any, index: number) => {
                        return (
                            <option key={item.DataBeanProperties.IDBudgetType} value={item.DataBeanProperties.IDBudgetType} >{item.DataBeanProperties.Name}</option>
                        )
                    })
                }
            </select>
        </>
    )
}