import { DataBean } from "../DataBean";

export class ArticleClassCode extends DataBean{
    public async getArticleClassCode(idArticleClassType: number, accountantID: any): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "com.quickdataerp.bean.supplier.ArticleClassCode_getArticleClassCode_Number_Number", 
            [idArticleClassType,accountantID]);
    }
    public async getArticleClassCodeByCode(idArticleClassType: number, jsonCode: string): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "com.quickdataerp.bean.supplier.ArticleClassCode_getArticleClassCodeByCode_Number_String", 
            [idArticleClassType,jsonCode]);
    }
    public async getArticleClassCodeByKey(idLn: number): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "com.quickdataerp.bean.supplier.ArticleClassCode_getArticleClassCodeByKey_Number", 
            [idLn]);
    }
    public async getArticleClassCodeChilds(idLn: number): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_getArticleClassCodeChilds_Number", 
            [idLn]);
    }
    public async getArticleClassCodeCatalog(idLn: number): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_getArticleClassCodeCatalog_Number", 
            [idLn]);
    }
    public async getGoodTypesConstants(arg: number): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_getGoodTypesConstants_Number", 
            [arg]);
    }
    public async getTreeForArticleClassCode(idArticleClassType: number): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "com.advantage.shared.Tree_getTreeForArticleClassCode_Number", 
            [idArticleClassType]);
    }
    public async getArticleClassTypeCatalog(): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_getArticleClassTypeCatalog_Number", 
            [null]);
    }
    public async createArticleClassCode(idArticleClassType:number,code:string,name:string): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "com.quickdataerp.bean.supplier.ArticleClassCode_createArticleClassCode_Number_String_String", 
            [idArticleClassType,code,name]);
    }
    public async deleteArticleClassCode(idLn:number): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "boolean_deleteArticleClassCode_Number", 
            [idLn]);
    }
    public async loadArticleClassCodeTree(idLn:number): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "com.advantage.shared.Tree_loadArticleClassCodeTree_Number", 
            [idLn]);
    }
    public async getArticleClassCodeByName(idArticleClassType:number,name:string): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_getArticleClassCodeByName_Number_String", 
            [idArticleClassType,name]);
    }
   
}