import { format } from 'date-fns';
import { AuditService } from './AuditService';
import { ContractService } from './ContractService';
import { DataBeanProperties, ObjectValue } from './model/server-response.interface';
import { CookieManager } from './CookieManager';
import { BasicStore } from './BasicStore';
import { AdminService } from './AdminService';
import { env } from '../../env';
export class Singleton {

    private static instance: Singleton;
    private cookie: CookieManager;
    private audit = new AuditService();
    private contracService = new ContractService();
    private adminService = new AdminService();

    private account: any = {};
    private menu: any = [];
    public spin = false;
    public eventos: any;
    private dollarUSLocale = Intl.NumberFormat('en-US');
    private permisos: any = [];
    public rolContratos: any = [];
    private linkPermisions: any = {};
    public currentURI: any = {};

    public mapBudgetID = [];
    public defaultCache = [];
    private lastView = '';
    public contractStore;
    public encrypKey: string | undefined;

    private printLog: boolean = env.PRINT_LOG ?? false;

    private constructor() {

        this.eventos = {
            spin: false
        };
        this.cookie = new CookieManager("/");
        this.contractStore = new BasicStore('COMPRAS');
        this.encrypKey = undefined;
    }

    public setCookie(name: string, value: string, days: number) {
        if (name === 'yek')
            this.encrypKey = value;

        this.cookie.setCookie(name, value, days);
    }
    public getCookie(name: string) {
        return this.cookie.getCookie(name);
    }
    public removeCookie(name: string) {
        this.cookie.setCookie(name, '', -1);
    }
    public purgeCookie() {
        this.cookie.deleteAllCookies();
    }



    public setCacheItem(key: string, value: any) {
        this.defaultCache[key] = value;
    }

    public deleteCacheItem(partialKey: string) {
        for (const key in this.defaultCache) {
            if (key.includes(partialKey)) {
                delete this.defaultCache[key];
            }
        }
    }

    public getCacheItem(key: string) {
        return this.defaultCache[key];
    }


    //Auditoria 

    getRolesContratos() {
        if (this.rolContratos.length == 0) {
            this.contracService.getHiringConstants("HIRINGROLES").subscribe(
                (resp: any) => {
                    if (resp.DataBeanProperties.ObjectValue) {
                        let temp = resp.DataBeanProperties.ObjectValue;
                        temp.forEach((item: any) => {
                            this.rolContratos.push({
                                id: item.DataBeanProperties.Value,
                                name: item.DataBeanProperties.Property
                            })
                        });




                    }
                }
            )
        }
        return this.rolContratos;
    }

    public getAudit(): AuditService {
        return this.audit;
    }


    public view(obs: string, bean: any) {
        let b = {
            IDAction: this.audit.VIEW,
            JsonBefore: JSON.stringify(bean ? bean : '{}'),
            State: 1,
            Observations: obs,
            IDAccount: this.getAccountID(),
            DataBeanClass: '',
            IDLn: 0
        }
        if (this.lastView != b.Observations) {
            this.lastView = b.Observations;
            this.audit.updateAuditFunction(b).subscribe(
                (resp: any) => {
                    ////console.log("AUDIT ", resp);

                }

            )
        }

    }
    public update(bean: any) {
        let uri = this.currentURI.URL ? this.currentURI.URL : "NA";
        let b = {
            IDAction: this.audit.UPDATE,
            JsonBefore: JSON.stringify(bean ? bean : '{}'),
            State: 1,
            Observations: "El usuario ha modificado " + uri,
            IDAccount: this.getAccountID(),
            DataBeanClass: '',
            IDLn: 0
        }
        // this.audit.updateAuditFunction(b).subscribe(
        //     (resp: any) => {                
        //     }
        // )
    }
    public download(obs: string) {


        let uri = this.currentURI.URL ? this.currentURI.URL : "NA";

        let b = {
            IDAction: this.audit.DOWNLOAD,
            JsonBefore: '{}',
            State: 1,
            Observations: "descarga modulo " + uri + " " + obs,
            IDAccount: this.getAccountID(),
            DataBeanClass: '',
            IDLn: 0
        }

        this.audit.updateAuditFunction(b).subscribe(
            (resp: any) => { }
        );

    }



    public makeAcction(obs: string, bean: any) {
        let uri = this.currentURI.URL ? this.currentURI.URL : "NA";

        let b = {
            IDAction: this.audit.ACTIVON,
            JsonBefore: JSON.stringify(bean ? bean : {}),
            State: 1,
            Observations: "Acción " + uri + " " + obs,
            IDAccount: this.getAccountID(),
            DataBeanClass: '',
            IDLn: 0
        }
        // this.audit.updateAuditFunction(b).subscribe(
        //     (resp: any) => { }
        // );

    }
    public create(bean: any) {
        let uri = this.currentURI.URL ? this.currentURI.URL : "NA";
        let b = {
            IDAction: this.audit.CREATE,
            JsonBefore: JSON.stringify(bean ? bean : {}),
            State: 1,
            Observations: "El usuario ha creado en " + uri,
            IDAccount: this.getAccountID(),
            DataBeanClass: '',
            IDLn: 0
        }
        // this.audit.updateAuditFunction(b).subscribe(
        //     (resp: any) => {
        //         //console.log("AUDIT ",resp);

        //     }

        // )
    }
    public delete(idBean: number, bean: any) {
        let uri = this.currentURI.URL ? this.currentURI.URL : "NA";
        let b = {
            IDAction: this.audit.DELETE,
            JsonBefore: JSON.stringify(bean ? bean : {}),
            State: 1,
            Observations: "El usuario ha eliminado en " + uri,
            IDAccount: this.getAccountID(),
            DataBeanClass: '',
            IDDataBean: idBean,
            IDLn: 0
        }
        // this.audit.updateAuditFunction(b).subscribe(
        //     (resp: any) => {
        //         //console.log("AUDIT ",resp);

        //     }

        // )
    }

    //Auditoria 

    public static getInstance(): Singleton {
        if (!Singleton.instance) {
            Singleton.instance = new Singleton();

        }

        return Singleton.instance;
    }


    public spinner(estado: boolean) {
        this.spin = estado;
        this.eventos.spin = estado;
        localStorage.setItem("eventos", JSON.stringify(this.eventos));
        window.dispatchEvent(new Event("storage"));
    }

    public startSesion(Account: any) {



        if (Account.IDAccount === 0) {
            this.account = {
                IDAccount: 0
            };
            localStorage.setItem('admin', "true");
        }
        else {
            this.account = Account;
        }

        this.account.session = this.getCurrenDate2();
        //console.log("Iniciando sesion ", this.account);
        this.setCookie("code", JSON.stringify(this.account), 1);

    }

    public setPermision(p: any) {
        this.permisos = p;
    }
    public canEdit() {

        let c = false;
        if (this.linkPermisions) {
            if (this.linkPermisions.editar === true) {
                c = true;
            }
        }
        return c;
    }
    public canDelete() {

        let c = false;
        if (this.linkPermisions) {
            if (this.linkPermisions.eliminar === true) {
                c = true;
            }
        }
        return c;
    }
    public canCreate() {

        let c = false;
        if (this.linkPermisions) {
            if (this.linkPermisions.crear === true) {
                c = true;
            }
        }
        return c;
    }

    public canExport() {
        let c = false;
        if (this.linkPermisions) {
            if (this.linkPermisions.exportar === true) {
                c = true;
            }
        }
        return c;
    }

    public getLinkPermisions() {

        return this.linkPermisions;
    }
    public havePermision(link: string) {
        let permiso = false;
        console.log(link);

        this.permisos.forEach((m: any) => {
            if (m.URL == link) {
                permiso = true;
                this.linkPermisions = JSON.parse(m.IDLnPermissions ? m.IDLnPermissions : '{}');
                this.currentURI = m;

            }
        })


        if (link == '/admin/perfil')
            permiso = true;

        return permiso;
    }
    public havePermision64(link: string) {
        let permiso = false;


        try {
            let base = JSON.parse(atob(link));

            if (base) {
                if (base.IDLn) {
                    let menus = this.permisos.filter(item => item.IDLn == base.IDLn);

                    if (menus.length > 0) {
                        permiso = true;
                        this.linkPermisions = JSON.parse(menus[0].IDLnPermissions ? menus[0].IDLnPermissions : '{}');
                        this.currentURI = menus[0];
                    }


                } else {
                    let menus = this.permisos.filter(item => item.IDRemoteAbstractAction == base.IDRemoteAbstractAction);

                    if (menus.length > 0) {
                        permiso = true;
                        this.linkPermisions = JSON.parse(menus[0].IDLnPermissions ? menus[0].IDLnPermissions : '{}');
                        this.currentURI = menus[0];
                    }

                }

            }



        } catch (error) {

        }




        return permiso;
    }
    public getAccess() {
        return this.linkPermisions;
    }


    public getAccount() {
        ////console.log("getAccount => ",this.account);

        let code = this.getCookie('code') || "{}";
        ////console.log("COOKIE => ",code);
        let acc = null;
        if (code) {
            acc = JSON.parse(code);
            this.account = acc;
        }

        return acc;
    }
    public getAccountID() {
        let id = null;
        this.account = this.getAccount();
        if (this.account != null) {
            id = this.account.IDAccount;
        }
        return id;
    }
    public closeSesion() {
        localStorage.clear();
        this.purgeCookie();
        return true;
    }
    public getRandomInt() {
        return Math.floor(Math.random() * 10000);
    }
    public setMenuUsuario(menuUsuario: any) {
        this.menu = menuUsuario;
    }

    public simplificarLista(lista = []) {
        let lista2: any = [];

        lista.map((element) => {

        })

        return lista2;
    }
    public parseDate(originalDate: any) {
        let ret = "";
        if (typeof (originalDate)) {

            const dateObject = new Date(originalDate);

            const year = dateObject.getFullYear();
            const month = String(dateObject.getMonth() + 1).padStart(2, '0');
            const day = String(dateObject.getDate()).padStart(2, '0');
            const hours = String(dateObject.getHours()).padStart(2, '0');
            const minutes = String(dateObject.getMinutes()).padStart(2, '0');
            const seconds = String(dateObject.getSeconds()).padStart(2, '0');

            const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
            ret = formattedDate;
            //console.log(formattedDate); // Salida: "2022-01-08 16:43:16"

        }
        return ret;
    }
    public formatearFecha(date: Date) {
        let fecha = "";
        import('date-fns/locale/es').then((locale) => {
            const fechaFormateada = format(date, 'dd MMMM yyyy', { locale: locale.default });
            fecha = fechaFormateada;
            //console.log(fechaFormateada); // Esto imprimirá la fecha en español en la consola
        });
        return fecha;
    };
    public getCurrenDate() {
        return (new Date().toISOString().slice(0, 10)) + " 00:00:00";
    }
    public getCurrenDate2() {
        return (new Date().toISOString());
    }

    public getCurrenYear() {
        return parseInt((new Date().toISOString().slice(0, 4)));
    }
    public renderConstant(constant: any, id: number) {

        let value = '';
        value = constant.map((item: any) => {
            if (item.id === id)
                return item.name;
        });

        return value;

    }

    public getCurrentMonth() {
        return new Date().getMonth();
    }

    public renderValue(valor: any) {
        let value1 = valor;

        // Intenta convertir el valor a un número
        if (!isNaN(valor)) {
            return parseInt(valor);
        }

        // Intenta analizar el valor como una fecha
        const date = new Date(valor);
        if (!isNaN(date.getTime())) {
            return this.formatearFecha(date);
        }


        return value1;
    }

    public isNumber = (n: any) => (typeof (n) === 'number' || n instanceof Number);
    public formatField = (value: any) => {
        let val = value;

        if (this.isNumber(value)) {
            // Asegúrate de que el valor es un número primitivo
            const numericValue = typeof value === 'number' ? value : value.valueOf();
            val = "<div class='text-end'>" + this.dollarUSLocale.format(numericValue) + "</div>";
        }
        else if (typeof val === "boolean") {
            val = val ? "Si" : "No";
        }
        else {
            val = `<div>${value ? value : ''}</div>`;
        }
        return val;

    }

    public formatFieldMask = (value: any, mask: number | null) => {
        let val = value;

        if (mask) {
            switch (mask) {
                case 1://numero
                    val = `<div class='miNumero'>${parseInt(value ?? 0)}</div>`;
                    break;
                case 11://numero redondeado
                    val = `<div class='miNumero'>${Math.round(parseInt(value ?? 0))}</div>`;
                    break;
                case 2: //dinero
                    val = val = "<div class='text-end text-nowrap'> $ " + this.dollarUSLocale.format(value ?? 0) + "</div>";
                    break;
                case 22://dinero reondeado
                    val = val = "<div class='text-end text-nowrap'> $ " + this.dollarUSLocale.format(Math.round(parseInt(value ?? 0))) + "</div>";
                    break;
                case 3: //texto
                    val = `<div class='miTexto'>${value ? value : '- - -'}</div>`
                    break;
                case 33: //texto sin wrap
                    val = `<div class='miTextoNW'>${value ? value : '- - -'}</div>`
                    break;
                case 4: //MyEditor
                    val = `<div>${value ? value : ''}</div>`
                    break;
                case 5: //solo fecha
                    val = `<div class='mifecha text-nowrap'>${value ? value.slice(0, 10) : ''}</div>`
                    break;
                case 6: //solo fecha dd-mm-yyyy
                    let f = value ? value.slice(0, 10) : '';
                    if (f != '') {
                        val = `<div class='mifecha text-nowrap'>${this.convertirFecha(f)}</div>`
                    }
                    else {
                        val = `<div class='mifecha text-nowrap'></div>`
                    }
                    break;
                case 7: //Cambiar los booleanos por iconos
                    val = val ?
                        `<div class='d-flex align-items-center justify-content-center'><i class='bx bx-check-circle text-success' style='font-size: 1rem;'></i></div>`
                        :
                        `<div class='d-flex align-items-center justify-content-center'><i class='ri-close-fill text-danger' style='font-size: 1rem;'></i></div>`;
                    break;
                default:
                    val = `<p>${val}</p>`;
            }
        } else {
            if (this.isNumber(value)) {
                const numericValue = typeof value === 'number' ? value : value.valueOf();
                val = "<div class='text-end'>" + this.dollarUSLocale.format(numericValue) + "</div>";

            }
            else if (typeof val === "boolean") {

                val = val ? "Si" : "No";

            }
            else {
                val = `<div>${value ? value : ''}</div>`
                // val = `<div class='text-capitalize'>${value ? value : ''}</div>`
            }
        }



        return val;
    }
    public convertirFecha(formatoOriginal) {
        const [year, month, day] = formatoOriginal.split("-");
        return `${day}-${month}-${year}`;
    }
    public formatMoney = (value: any) => {
        let val: any = value;


        if (this.isNumber(value)) {

            if (val > 0) {
                val = this.dollarUSLocale.format(parseInt(value + ""));
                ////console.log(val,isNaN(val));

                if (!isNaN(val))
                    val = '';
            }

        }

        return val;
    }
    public getEntityName(bean: any) {
        let name =
            (bean.DataBeanProperties.Name1 ? bean.DataBeanProperties.Name1 : ' ') + ' ' +
            (bean.DataBeanProperties.Name2 ? bean.DataBeanProperties.Name2 : ' ') + ' ' +
            (bean.DataBeanProperties.Surname1 ? bean.DataBeanProperties.Surname1 : ' ') + ' ' +
            (bean.DataBeanProperties.Surname2 ? bean.DataBeanProperties.Surname2 : ' ');

        return name;
    }

    public basicName(nombre: string) {
        // Obtener la fecha y hora actual
        var fecha = new Date();
        var dia = fecha.getDate();
        var mes = fecha.getMonth() + 1; // Los meses comienzan en 0
        var año = fecha.getFullYear();
        var hora = fecha.getHours();
        var minutos = fecha.getMinutes();
        var segundos = fecha.getSeconds();

        // Crear un nombre aleatorio
        var nombreAleatorio = nombre + "_" + dia + mes + año + "_" + hora + minutos + segundos;


        return nombreAleatorio;
    }
    public esNumerico(valor) {
        return /^\d+$/.test(valor) || /^-\d+$/.test(valor) || /^(\d+\.\d+|\.\d+|\d+)$/.test(valor);
    }
    private esDouble(valor) {
        return /^-?\d*\.?\d+$/.test(valor);
    }

    public handleFormChange(bean: any, e: any) {

        // como usar 
        // setRow(single.handleFormChange({...row},e));


        if (e.target.name) {

            if (this.esNumerico(e.target.value)) {
                bean = { ...bean, [e.target.name]: parseInt(e.target.value) };
            }
            else if (e.target.type === 'number' || e.target.type == 'change') {
                if (this.esDouble(e.target.value)) {
                    bean = { ...bean, [e.target.name]: parseFloat(e.target.value) };
                }
                else if (this.esNumerico(e.target.value)) {
                    bean = { ...bean, [e.target.name]: parseInt(e.target.value) };
                }
            }
            else {
                bean = { ...bean, [e.target.name]: e.target.value };
            }

        }

        return bean;
    }
    public getValueInput(e: any) {

        // como usar 
        // setRow(single.handleFormChange({...row},e));

        let valor = undefined;
        if (e.target.name) {

            if (this.esNumerico(e.target.value)) {
                valor = parseInt(e.target.value);
            }
            else if (e.target.type === 'number' || e.target.type == 'change') {
                if (this.esDouble(e.target.value)) {
                    valor = parseFloat(e.target.value);
                }
                else if (this.esNumerico(e.target.value)) {
                    valor = parseInt(e.target.value);
                }
            }
            else {
                valor = e.target.value;
            }

        }

        return valor;
    }



    public sendEmailMessage(idaccount: number, subject: string | null, message: string) {

        this.adminService.getAccountByID(idaccount).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue) {
                    let acc = resp.DataBeanProperties.ObjectValue;
                    if (acc.DataBeanProperties) {

                        let to = acc.DataBeanProperties.eMail;
                        if (to !== undefined || to !== null)
                            this.adminService.sendCustonEmailMessage([to], subject, message).subscribe(
                                (resp: any) => {


                                }
                            )
                    }
                }
            }
        )
    }

    public printOut(message: any) {

        if (this.printLog)
            console.log(message);
    }
    public printTable(message: any) {

        if (this.printLog)
            console.table(message);
    }
    public printError(message: any) {

        if (this.printLog)
            console.warn(message);
    }

}