import { useEffect, useState } from "react";
import { _CompetitionType, _EmployeeDiscountType, _EmployeePayments, _Entailment, _MacroProcess } from "../../../../core/services/dataBean/EntityCatalog";

import { Singleton } from "../../../../core/services/Singleton";
import { toast } from "react-toastify";
import { ListMenu } from "../../../theme/Component/ListMenu";
import { BasicTable, MASK_NUMBER } from "../../../theme/Component/BasicTable";
import { Modal } from "../../../theme/Component/Modal";
import { ModalConfirm } from "../../../theme/Component/ModalConfirm";
import useDataBean from "../../../../core/services/dataBean/useDataBean";
import { _SupplierPaymentOrder, _SupplierPaymentOrderItem } from "../../../../core/services/dataBean/EntityCatalogVdos";
import { PrintJson } from "../../../theme/Component/PrintJson";
import useModal, { MODAL_md, MODAL_SM } from "../../../theme/Component/hooks/useModal";

import { ContractArticleItemEditor } from "../../../contract/etapa-contrato/contrac/ContractArticles.editor";
import { MASK_MONEY } from "../../../theme/Component/BasicTable2";
interface Props {
    IDSupplierPaymentOrder: any;
    onUpdate?:any;
}

export const SupplierPaymentOrderItem = ({ IDSupplierPaymentOrder,onUpdate }: Props) => {

    const SupplierPaymentOrderItem = useDataBean(_SupplierPaymentOrderItem);
    const [bean, setbean] = useState<any>({})
    const single = Singleton.getInstance();

    const [lista, setLista] = useState<any>([]);
    const [modalPC, setModalPC] = useState<any>({ name: 'Agregar Artículo para Pago', tabIndex: -1, view: false, btnClose: true, btnSubmit: false, format: 'modal-lg' });
    const [modal, setModal] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-md' });
    const [modalC, setModalC] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });
    const [idAdquisitionArticle, setidAdquisitionArticle] = useState<number>(null)
    const modalConfirm = useModal("Orden", MODAL_SM);
    const modalEditar = useModal("Orden", MODAL_md);


    useEffect(() => {

        getLista();

    }, [IDSupplierPaymentOrder])

    const getLista = () => {

        SupplierPaymentOrderItem.instance.getSupplierPaymentOrderItemCatalogForAdquisitionContract(IDSupplierPaymentOrder.IDSupplierPaymentOrder, IDSupplierPaymentOrder.SupplierPaymentOrderDate).then(
            (resp: any) => {                
                if (resp) {
                    let List = resp;
                    List.forEach((e: any) => {

                        if(e.DataBeanProperties.BudgetJsconCode)
                        {
                            e.DataBeanProperties.Budget = (e.DataBeanProperties.BudgetJsconCode ?? '') +' / '+ ( e.DataBeanProperties.BudgetName ?? '');
                            
                            
                        }
                            

                    })
                    setLista(List);
                    
                }
            }
        )

    }

    const create = () => {        
        modalEditar.open();
        SupplierPaymentOrderItem.setBean({});
    }

    const deleteItem = () => {
        SupplierPaymentOrderItem.instance.deleteSupplierPaymentOrderItem(SupplierPaymentOrderItem.bean.IDSupplierPaymentOrderItem, SupplierPaymentOrderItem.bean.Since, SupplierPaymentOrderItem.bean.IDEmployee).then(
            (resp) => {
               //console.log(resp);
                getLista();

                setModalC({ ...modalC, "view": false });
            }
        )
    }

    const editaItem = () => {
       //console.log(SupplierPaymentOrderItem.bean);
        SupplierPaymentOrderItem.instance.addPaymentOrderItem(IDSupplierPaymentOrder.IDSupplierPaymentOrder, IDSupplierPaymentOrder.SupplierPaymentOrderDate, SupplierPaymentOrderItem.bean.IDAdquisitionArticle, SupplierPaymentOrderItem.bean.Value).then(
            (resp: any) => {
                getLista();
                modalEditar.close();
                // modalConfirm.close();
                onUpdate();
            }
        ).catch(err => {
            toast.error(err);
        })

    }



    const head = [
        { title: 'ID', property: 'IDSupplierPaymentOrderItem', visible: true,mask:MASK_NUMBER },
        { title: 'ID Artículo', property: 'IDAdquisitionArticle', visible: false,mask:MASK_NUMBER },
        { title: 'Rubro Presupuestal', property: 'Budget', visible: true },
        { title: 'Código CPC', property: 'IDLncPCP', visible: true },
        { title: 'Tipo Arículo', property: 'TypeName', visible: true },        
        { title: 'Descripción', property: 'Description', visible: true },
        { title: 'Unidad Medida', property: 'MeasurementUnit', visible: true },
        { title: 'Ubicación', property: 'SiteIDName', visible: true },
        { title: 'Cantidad', property: 'Quantity', visible: true,mask:MASK_NUMBER },
        { title: 'Valor', property: 'PaymentValue', visible: true,mask:MASK_MONEY },



    ]

    const iconButtonsItem = [
        { nombre: 'Agregar', visible: single.canCreate(), icono: 'ri-file-add-line', evento: create }
    ]

    const listaBotones = () => {
        const botones = [

            { titulo: 'Editar', icono: 'ri-edit-2-line', visible: single.canEdit(), evento: () => { modalEditar.open()} },
            { titulo: 'Eliminar', icono: 'ri-delete-bin-line', visible: single.canDelete(), evento: () => { setModalC({ ...modalC, "view": true }) } },

        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }


    return (

        <div className="row">
            <div className="col-md-12">
                <p>{IDSupplierPaymentOrder.Description}</p>
                <BasicTable body={lista} head={head} rowSet={SupplierPaymentOrderItem.setBean} listButtons={listaBotones()} iconButtons={iconButtonsItem}></BasicTable>

                <Modal modal={modalEditar.modal} updateModal={modalEditar.setModal} eventModal={editaItem}>
                    <div className="row">
                        {/* 

                        <div className="col-md-6">

                            <InputDate label="Fecha Creación" name="SupplierPaymentOrderDate" value={SupplierPaymentOrderItem.bean.SupplierPaymentOrderDate} setDate={(fecha: any) => { SupplierPaymentOrderItem.setBean({ ...SupplierPaymentOrderItem.bean, "SupplierPaymentOrderDate": fecha }) }}></InputDate>

                        </div> */}
                        <div className="col-md-12">
                            <ContractArticleItemEditor idAdquisitionContract={IDSupplierPaymentOrder.IDAdquisitionContract} idSelector={SupplierPaymentOrderItem.bean.IDAdquisitionArticle} onChange={(e) => {  
                             SupplierPaymentOrderItem.setBean({ ...SupplierPaymentOrderItem.bean, "IDAdquisitionArticle": e.IDAdquisitionArticle,"Value": e.ContractedValue });
                             
                             }} />
                        </div>
                        <div className="col-md-12">
                            <label>Valor</label>
                            <input type="number" name="Value" className="form-control" value={SupplierPaymentOrderItem.bean.Value} onChange={SupplierPaymentOrderItem.handleChange} />
                            {/* <SelectFunctionalID renderValue="" onChange={()=>{}}/> */}
                        </div>
                    </div>
                </Modal>
                {/* <PrintJson json={SupplierPaymentOrderItem.bean}></PrintJson> */}
                <ModalConfirm modal={modalC} updateModal={setModalC} eventModal={deleteItem}>
                    <strong>Eliminar item</strong>
                </ModalConfirm>
            </div>

        </div>

    )
}