import { useEffect, useState } from 'react';
import { Modal } from '../../../theme/Component/Modal';
import { TrdID } from './TrdID';
import useDataBean from '../../../../core/services/dataBean/useDataBean';
import { _TrdID } from '../../../../core/services/dataBean/EntityCatalog';

interface Props {
    onChange: any;
    edit?: boolean;
    label?: string;
    trdIDLn?: number;
}

export const SelectTrdsID = ({ edit, onChange, label, trdIDLn }: Props) => {

    const { instance } = useDataBean(_TrdID);

    const [modal, setModal] = useState<any>({ name: 'Códigos TRD', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [valor, setValor] = useState('');

    useEffect(() => {
        if (trdIDLn) {
            getBudgetIDLn()
        }
    }, [trdIDLn])

    const getBudgetIDLn = () => {
        instance.getTrdIDByKey(trdIDLn).then(
            (resp) => {
                setValor(`${resp.TrdViewCode ?? ""} - ${resp.Name ?? ""}`)
                onChange(resp)
            }
        ).catch(
            err => {
               console.error(err)
            }
        )
    }

    const getItem = () => {

        let bean: any = JSON.parse(localStorage.getItem('TrdID') || '{}');
        onChange(bean);
        setValor(bean.TrdViewCode + " " + bean.Name);

        setModal({ ...modal, "view": false });
    }

    return (
        <>
            <label>{label ?? "Código TRD"}</label>
            <div className="form-control text-wrap mt-2" style={{ minHeight: '35px' }} onClick={() => setModal({ ...modal, "view": true })}>
                {valor ?
                    <span>{valor}</span>
                    :
                    <span>Seleccionar....</span>
                }
            </div>

            <Modal onSubmitLabel='Seleccionar' modal={modal} updateModal={setModal} eventModal={getItem}>
                <div style={{ maxHeight: '60vh', overflow: 'auto' }}>
                    <TrdID edit={edit} id={null} />
                </div>
            </Modal>

        </>
    )
}