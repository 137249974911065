import { useEffect, useState } from "react";
import { DropDown } from "../theme/Component/DropDown";
import { Modal } from "../theme/Component/Modal";
import { ContractService } from "../../core/services/ContractService";
import { Alert } from "../theme/Component/Alert";
import { ChartArea } from "../theme/charts/ChartArea";
import { useDashboardStore } from "../pages/hooks/useDashboardStore";

interface Props {
    bean: any;
    updateEvent: any;
    permisos?: any;
}
export const FunctionalIDDropDown = ({ bean, updateEvent, permisos }: Props) => {

    const service = new ContractService();
    const { setLoading } = useDashboardStore();
    const [modal, setModal] = useState<any>({ name: 'Articulos', tabIndex: -1, view: false, btnClose: true, btnSubmit: false, format: 'modal-lg' });
    const [btn, setBTN] = useState<any>({});
    const [obs, setObs] = useState<string | undefined>('');
    const [message, setMessage] = useState<string | null>('');
    const [listaGra, setListaGra] = useState<any>([]);

    useEffect(() => {
        setObs('');
        setMessage('');
    }, [bean])
    useEffect(() => {
        if (message !== '') {
            updateEvent();
        }
    }, [message])

    // const actionsDropDown = [
    //     { id: 1, name: 'Actualizar el Estado de Configuración',permiso:'updateAdquisitionPlanFunctionalIDStructuringState' },
    //     { id: 2, name: 'Lista de Pair/Value de Estado/Número de Artículos',permiso:'getAdquisitionArticleStructuringStateListByAdquisitionPlanFunctionalID' },
    //     { id: 3, name: 'Lista de Pair/Value de Estado/Número de Items',permiso:'getAdquisitionItemStructuringStateListForFunctionalID' },    
    // ]
    const actionsDropDown = [
        { id: 1, name: 'Actualizar el Estado de Configuración', permiso: null },
        { id: 2, name: 'Lista de Pair/Value de Estado/Número de Artículos', permiso: null },
        { id: 3, name: 'Lista de Pair/Value de Estado/Número de Items', permiso: null },
    ]

    const handleEvent = (item: any) => {

        setBTN(item);
        if (item.id === 1) {
            setModal({ ...modal, "view": true, "name": item.name, "btnSubmit": true });

        }
        else if (item.id === 2) {
            setModal({ ...modal, "view": true, "name": item.name, "btnSubmit": false });

            setLoading(true);
            service.getAdquisitionArticleStructuringStateListByAdquisitionPlanFunctionalID(bean.IDAdquisitionPlanFunctionalID).subscribe(
                (resp: any) => {
                   //console.log(resp);
                    if (resp.DataBeanProperties.ObjectValue) {
                        setListaGra(resp.DataBeanProperties.ObjectValue);
                    }
                    setLoading(false);
                }
            )

        }
        else if (item.id === 3) {
            setModal({ ...modal, "view": true, "name": item.name, "btnSubmit": false });
            setLoading(true);
            service.getAdquisitionItemStructuringStateListForFunctionalID(bean.IDAdquisitionPlanFunctionalID).subscribe(
                (resp: any) => {
                   //console.log(resp);
                    if (resp.DataBeanProperties.ObjectValue) {
                        setListaGra(resp.DataBeanProperties.ObjectValue);
                    }
                    setLoading(false);
                }
            )

        }





    }





    const updateAction = () => {


        if (btn.id === 1) {
            setLoading(true);
            service.updateAdquisitionPlanFunctionalIDStructuringState(bean.IDAdquisitionPlanFunctionalID).subscribe(
                (resp: any) => {
                    setMessage("Estado Actualizado");
                    setLoading(false);
                }
            )
        }




    }

    const head = [
        { title: 'Fecha', property: 'EventDate' },
        { title: 'Identificación', property: 'AccountID' },
        { title: 'Nombre', property: 'AccountName' },
        { title: 'Aprobado', property: 'Approved' },
        { title: 'Tipo Evento', property: 'TypeName' },
        { title: 'Descripción', property: 'Description' }
    ]

    return (
        <>
            <DropDown lista={actionsDropDown} permisos={permisos} eventClick={(e: any) => {
                handleEvent(e);
            }}></DropDown>

            <Modal modal={modal} updateModal={setModal} eventModal={updateAction}>


                <div className="row">
                    <div className="col-md-12">
                        {btn.id === 1 &&
                            <h4>Actualizar Etapa ?</h4>
                        }
                        {btn.id === 2 &&
                            <ChartArea lista={listaGra} maxRang={100} titulo="Estados" tipo="bar"></ChartArea>
                        }
                        {btn.id === 3 &&
                            <ChartArea lista={listaGra} maxRang={100} titulo="Estados" tipo="bar"></ChartArea>
                        }


                    </div>

                    <div className="col-md-12">
                        {message !== '' &&
                            <Alert clase="warning">{message}</Alert>
                        }
                    </div>

                </div>

            </Modal>
        </>
    )
}