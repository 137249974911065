import { useEffect, useState } from "react";
import { _CompetitionType, _Entailment, _MainTheme, _StrategicObjetive, _ThirdBankingAccount } from "../../../../core/services/dataBean/EntityCatalog";
import { Singleton } from "../../../../core/services/Singleton";
import { toast } from "react-toastify";
import { ListMenu } from "../../../theme/Component/ListMenu";
import { BasicPage } from "../../../theme/Component/BasicPage";
import { BasicTable } from "../../../theme/Component/BasicTable";
import { Modal } from "../../../theme/Component/Modal";
import { ModalConfirm } from "../../../theme/Component/ModalConfirm";
import useDataBean from "../../../../core/services/dataBean/useDataBean";
import { AccountEditor } from "../../../editor/AccountEditor";
import { BakingEntityEditor } from "../../../erp/cellEditor/BankingEntity/BankingEntitySelect";
import { BankingAccountEditor } from "./BankingAccountSelect";
import { PaymentTypeEditor } from "../../../erp/cellEditor/PaymentType/PaymentTypeSelect";
import { BasicSwitch } from "../../../theme/Component/BasicSwitch";

interface Props {
    isModal?: boolean;
    idAccount?: number;
}

export const ThirdBankingAccount = ({ isModal, idAccount }: Props) => {

    const ThirdBankingAccount = useDataBean(_ThirdBankingAccount);

    const single = Singleton.getInstance();

    const [lista, setLista] = useState<any>([]);


    const [IdAcoount, setIdAcoount] = useState<number>(null)
    const [modal, setModal] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalC, setModalC] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });

    useEffect(() => {
        if (idAccount) {
            setIdAcoount(idAccount);
        }
    }, [idAccount])

    useEffect(() => {
        getLista();
    }, [IdAcoount])

    const getLista = () => {

        ThirdBankingAccount.instance.getThirdBankingAccountCatalogByIDAccount(IdAcoount, null).then(
            (resp: any) => {
                if (resp) {
                    let List = resp;
                    List.forEach((e: any) => {

                        e.DataBeanProperties.BankingNameCode = (e.DataBeanProperties.BankingCode ?? '') +
                            (e.DataBeanProperties.BankingName ?? '')


                    })
                    setLista(List);
                }
            }
        )

    }

    const create = () => {
        ThirdBankingAccount.setBean({

            IDAccount: IdAcoount
        });
        setModal({ ...modal, "view": true });
    }
    const deleteItem = () => {
        ThirdBankingAccount.instance.deleteThirdBankingAccount(ThirdBankingAccount.bean).then(
            (resp: any) => {
                getLista();
                setModalC({ ...modalC, "view": false });
            }
        )

    }
    const editaItem = () => {
       //console.log(ThirdBankingAccount.bean);
        ThirdBankingAccount.instance.updateThirdBankingAccount(ThirdBankingAccount.bean).then(
            (resp: any) => {
                getLista();
                setModal({ ...modal, "view": false });
            }
        ).catch(err => {
            toast.error(err);
        })

    }

    const head = [
        { title: 'ID', property: 'IDThirdBankingAccount', visible: true },
        { title: 'Código', property: 'Code', visible: true },
        { title: 'Entidad Bancaria', property: 'BankingNameCode', visible: true },
        { title: 'Código de Oficina', property: 'IDBranch', visible: true },
        { title: 'Tipo Cuenta', property: 'AccountTypeName', visible: true },
        { title: 'No. Cuenta', property: 'AccountNumber', visible: true },
        { title: 'Tipo de Pago', property: 'PaymentTypeName', visible: true },
        { title: 'Detalle Proveedor', property: 'ExternalCode', visible: true },
        { title: 'Cuenta por Defecto', property: 'DefaultBanking', visible: true },


    ]

    const iconButtonsItem = [
        { nombre: 'Agregar', visible: (single.canCreate() && IdAcoount), icono: 'ri-file-add-line', evento: create }
    ]
    const listaBotones = () => {
        const botones = [

            { titulo: 'Editar', icono: 'ri-edit-2-line', visible: single.canEdit(), evento: () => { setModal({ ...modal, "view": true }) } },
            { titulo: 'Eliminar', icono: 'ri-delete-bin-line', visible: single.canDelete(), evento: () => { setModalC({ ...modalC, "view": true }) } },
            // { titulo: 'Eliminar', icono: 'ri-delete-bin-line', visible: single.canDelete(), evento: () => { setModalC({ ...modalC, "view": true }) } },

        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }


    return (
        <>
            {isModal ?
                <div className="row">
                    <div className="col-md-12">
                        <BasicTable body={lista} head={head} rowSet={ThirdBankingAccount.setBean} listButtons={listaBotones()} iconButtons={iconButtonsItem}></BasicTable>
                    </div>
                </div>
                :
                <BasicPage titulo="Cuentas de Terceros">
                    <div className="row">
                        <div className="col-md-6">
                            <AccountEditor label="Beneficiario" idAccount={IdAcoount} onChange={(e) => { setIdAcoount(e.IDAccount) }} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <BasicTable body={lista} head={head} rowSet={ThirdBankingAccount.setBean} listButtons={listaBotones()} iconButtons={iconButtonsItem}></BasicTable>
                        </div>
                    </div>
                </BasicPage>
            }


            <Modal modal={modal} updateModal={setModal} eventModal={editaItem}>
                <div className="row">
                    <div className="col-md-6">
                        <label>Código</label>
                        <input type="text" name="Code" className="form-control" value={ThirdBankingAccount.bean.Code} onChange={ThirdBankingAccount.handleChange} />
                    </div>
                    <div className="col-md-6">
                        <label>Código de Oficina</label>
                        <input type="text" name="IDBranch" className="form-control" value={ThirdBankingAccount.bean.IDBranch} onChange={ThirdBankingAccount.handleChange} />
                    </div>
                    <div className="col-md-6">
                        <BakingEntityEditor label="Entidad Bancaria" idSelector={ThirdBankingAccount.bean.IDBankingEntity} onChange={(e) => { ThirdBankingAccount.setBean({ ...ThirdBankingAccount.bean, "IDBankingEntity": e }) }} />
                    </div>
                    <div className="col-md-6">
                        <BankingAccountEditor label="Tipo de Cuenta" idSelector={ThirdBankingAccount.bean.AccountType} onChange={(e) => { ThirdBankingAccount.setBean({ ...ThirdBankingAccount.bean, "AccountType": e }) }} />
                    </div>
                    <div className="col-md-12">
                        <label>No. Cuenta</label>
                        <input type="text" name="AccountNumber" className="form-control" value={ThirdBankingAccount.bean.AccountNumber} onChange={(e) => { ThirdBankingAccount.setBean({ ...ThirdBankingAccount.bean, "AccountNumber": e.target.value }) }} />
                    </div>
                    <div className="col-md-12">
                        <PaymentTypeEditor label="Tipo de Pago" idSelector={ThirdBankingAccount.bean.IDPaymentType} onChange={(e) => { ThirdBankingAccount.setBean({ ...ThirdBankingAccount.bean, "IDPaymentType": e }) }} />
                    </div>
                    <div className="col-md-6">
                        <label>Detalle Proveedor</label>
                        <input type="text" name="ExternalCode" className="form-control" value={ThirdBankingAccount.bean.ExternalCode} onChange={ThirdBankingAccount.handleChange} />
                        {/* <label>Detalle Proveedor</label>
                                <input type="text" name="ExternalCode" className="form-control" value={ThirdBankingAccount.bean.ExternalCode} onChange={ThirdBankingAccount.handleChange} /> */}
                    </div>
                    <div className="col-md-6">
                        <label>Cuenta por Defecto</label>
                        <BasicSwitch label="" estado={ThirdBankingAccount.bean.DefaultBanking} eventChange={(e: any) => { ThirdBankingAccount.setBean({ ...ThirdBankingAccount.bean, "DefaultBanking": e }) }}></BasicSwitch>
                        {/* <input type="text" name="DefaultBanking" className="form-control" value={ThirdBankingAccount.bean.DefaultBanking} onChange={ThirdBankingAccount.handleChange} /> */}
                    </div>

                </div>
            </Modal>

            <ModalConfirm modal={modalC} updateModal={setModalC} eventModal={deleteItem}>
                <strong>Eliminar item</strong>
            </ModalConfirm>

        </>
    )
}