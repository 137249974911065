import { useEffect, useState } from "react"
import useDataBean from "../../../../../core/services/dataBean/useDataBean"
import { BasicTable, MASK_NUMBER } from "../../../../theme/Component/BasicTable"
import { _BudgetAccountantMovement, _single } from "../../../../../core/services/dataBean/EntityCatalog";
import { toast } from "react-toastify";
import { ListMenu } from "../../../../theme/Component/ListMenu";
import { Modal } from "../../../../theme/Component/Modal";
import { ModalConfirm } from "../../../../theme/Component/ModalConfirm";
import { SelectAccountantTree } from "../../../../contabilidad/catalogo-cuentas/AccountantTree/SelectAccountantTree";
import { SelectBudgetID } from "../../../../admin/configuracion/BudgetID/SelectBudgetID";
import { SelectBudgetAccountantMovementType } from "./SelectBudgetAccountantMovementType";
import { BasicSwitch } from "../../../../theme/Component/BasicSwitch";
import { AccountEditor } from "../../../../editor/AccountEditor";

interface Props {
    idBudgetAccountantOperation: number;
}

export const BudgetAccountantMovement = ({ idBudgetAccountantOperation }: Props) => {

    const { lista, setLista, bean, setBean, instance, deleteItemVoid, handleChange } = useDataBean(_BudgetAccountantMovement);
    const [missingFields, setMissingFields] = useState<string[]>([]);

    const [modal, setModal] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalC, setModalC] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });

    useEffect(() => {
        getLista();
    }, [idBudgetAccountantOperation])

    const getLista = () => {
        instance.getBudgetAccountantMovementCatalog(idBudgetAccountantOperation).then(
            (resp) => {
                setLista(resp);
            }
        ).catch(
            err => { toast.error(err) }
        )
    }

    const updateItem = () => {
        instance.updateBudgetAccountantMovement(bean).then(
            (resp) => {
                getLista();
                setModal({ ...modal, "view": false });
            }
        ).catch(
            err => { toast.error(err) }
        )
    }

    const deleteItem = () => {
        deleteItemVoid(bean.IDBudgetAccountantMovement).then(
            (resp) => {
                getLista();
                setModalC({ ...modalC, "view": false });
            }
        ).catch(
            err => { toast.error(err) }
        )
    }

    const create = () => {
        setBean({
            RequiresThird: false,
            RequiresCostingCenter: false,
            RequiresProject: false,
            RequiresBusinessCore: false,
            UseBudget: false,
            IDBudgetAccountantOperation: idBudgetAccountantOperation,
        });
        setModal({ ...modal, "view": true, "name": "Agregar Estructura de Movimientos" });
    }

    const iconButtonsItem = [
        { nombre: 'Agregar', visible: _single.canCreate(), icono: 'ri-file-add-line', evento: create }
    ]

    const listaBotones = () => {
        const botones = [
            { titulo: 'Editar', icono: 'ri-edit-2-line', visible: _single.canEdit(), evento: () => { setModal({ ...modal, "view": true, "name": "Editar" }) } },
            { titulo: 'Eliminar', icono: 'ri-delete-bin-line', visible: _single.canDelete(), evento: () => { setModalC({ ...modalC, "view": true, "name": "Eliminar" }) } },
        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }

    const head = [
        { title: 'ID', property: 'IDBudgetAccountantMovement', visible: true, mask: MASK_NUMBER },
        { title: 'Código', property: 'Code', visible: true, mask: MASK_NUMBER },
        { title: 'Nombre', property: 'Name', visible: true },
        { title: 'Cuenta Contable', property: 'AccountantIDName', visible: true },
        { title: 'Rubro Presupuestal', property: 'BudgetIDName', visible: true },
        { title: 'Tipo de Movimiento', property: 'MovementTypeName', visible: true },
        { title: 'Utilizar Rubro', property: 'UseBudget', visible: true },
        { title: 'Requiere Centro de Costo', property: 'RequiresCostingCenter', visible: true },
        { title: 'Requiere Proyecto', property: 'RequiresProject', visible: true },
        { title: 'Requiere Core de Negocio', property: 'RequiresBusinessCore', visible: true },
        { title: 'Requiere Tercero', property: 'RequiresThird', visible: true },
        { title: 'Tercero por Defecto', property: 'DefaultAccountName', visible: true },
    ]

    const validateData = () => {
        if (validateForm() > 0) {
            toast.error("Faltan campos obligatorios por completar");
            return;
        } else {
            updateItem();
        }
    }

    const validateForm = () => {
        const requiredFields = [
            "AccountantIDStr", "Code",
        ];

        let missing = [];
        for (let field of requiredFields) {
            if (!bean[field]) {
                missing.push(field);
            }
        }

        //console.log(missing)
        setMissingFields(missing);
        return missing.length
    };

    return (
        <div className="row">
            <div className="col-md-12">
                <BasicTable body={lista} head={head} rowSet={setBean} iconButtons={iconButtonsItem} listButtons={listaBotones()} />
            </div>

            <div className="col-md-12">
                <Modal modal={modal} updateModal={setModal} eventModal={validateData} >
                    <div className="row">
                        <div className="col-md-6">
                            <label className={`${missingFields.includes('Code') ? 'has-error' : ''}`}>Código</label>
                            <input type="number" className="form-control" name="Code" value={bean.Code} onChange={handleChange} />
                        </div>
                        <div className="col-md-6">
                            <label>Nombre</label>
                            <input type="text" className="form-control" name="Name" value={bean.Name} onChange={handleChange} />
                        </div>
                        <div className="col-md-6">
                            <label className={`${missingFields.includes('AccountantIDStr') ? 'has-error' : ''}`}>Cuenta Contable</label>
                            <SelectAccountantTree jsonCode={bean.AccountantIDStr} onChange={(e) => { setBean({ ...bean, "AccountantIDStr": e.JsonCode }) }} label=" " />
                        </div>
                        <div className="col-md-6">
                            <label className={`${missingFields.includes('BudgetIDStr') ? 'has-error' : ''}`}>Rubro Presupuestal</label>
                            <SelectBudgetID edit={false} tipoPresupuesto={null} jsonCode={bean.BudgetIDStr} onChange={(e) => { setBean({ ...bean, "BudgetIDStr": e.JsonCode }) }} label=" " />
                        </div>
                        <div className="col-md-6 d-flex-wrap align-content-center">
                            <BasicSwitch estado={bean.UseBudget} eventChange={(e) => { setBean({ ...bean, "UseBudget": e }) }} label="Utilizar Rubro" />
                        </div>
                        <div className="col-md-6">
                            <SelectBudgetAccountantMovementType idSelector={bean.MovementType} onChange={(e) => { setBean({ ...bean, "MovementType": e }) }} />
                        </div>
                        <div className="col-md-6 d-flex-wrap align-content-center">
                            <BasicSwitch estado={bean.RequiresThird} eventChange={(e) => { setBean({ ...bean, "RequiresThird": e }) }} label="Requiere Tercero" />
                        </div>
                        <div className="col-md-6">
                            <BasicSwitch estado={bean.RequiresCostingCenter} eventChange={(e) => { setBean({ ...bean, "RequiresCostingCenter": e }) }} label="Requiere Centro de Costo" />
                        </div>
                        <div className="col-md-6">
                            <BasicSwitch estado={bean.RequiresBusinessCore} eventChange={(e) => { setBean({ ...bean, "RequiresBusinessCore": e }) }} label="Requiere Core de Negocio" />
                        </div>
                        <div className="col-md-6 d-flex-wrap align-content-center">
                            <BasicSwitch estado={bean.RequiresProject} eventChange={(e) => { setBean({ ...bean, "RequiresProject": e }) }} label="Requiere Proyecto" />
                        </div>
                        <div className="col-md-6">
                            <AccountEditor idAccount={bean.IDAccountDefault} label="Tercero por Defecto" onChange={(e) => { setBean({ ...bean, "IDAccountDefault": e.IDAccount }) }} />
                        </div>
                    </div>
                </Modal>
            </div>

            <div className="col-md-12">
                <ModalConfirm modal={modalC} updateModal={setModalC} eventModal={deleteItem} >
                    <p>¿Desea eliminat el item {bean.IDBudgetAccountantMovement} - {bean.Code}?</p>
                </ModalConfirm>
            </div>
        </div>
    )
}