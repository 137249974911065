import { useEffect, useState } from "react";
import * as XLSX from 'xlsx';
import { BasicPage } from "../../../../theme/Component/BasicPage";
import { BasicButton } from "../../../../theme/Component/BasicButton";
import useDataBean from "../../../../../core/services/dataBean/useDataBean";
import { _AdquisitionContract, _AdquisitionPlan } from "../../../../../core/services/dataBean/EntityCatalog";
import { YearSelector } from "../../../../../core/shared/inputs/YearSelector";
import { PrintJson } from "../../../../theme/Component/PrintJson";
import { FileService } from "../../../../../core/services/FileService";
import { AccountEditor } from "../../../../editor/AccountEditor";

export const ImportContract = () => {

    const _contract = useDataBean(_AdquisitionContract);
    const _plan = useDataBean(_AdquisitionPlan);
    const _fileService = new FileService();

    const [opc, setOpc] = useState<number>(1);
    const [fileItem, setFileItem] = useState<any>({});
    const [resumen, setResumen] = useState<any>(undefined);
    const [idAccount, setIDAccount] = useState<number>(0);
    const [excelData, setExcelData] = useState<any>([]);
    const [file, setFile] = useState<any>();

    const [opciones] = useState<any[]>([
        { id: 1, name: "Importar Contratos", plantilla: 'contratos.xlsx' },
        { id: 2, name: "Importar Plan anual", plantilla: 'plan_anual.xlsx' },
    ]);

    useEffect(() => {
        if (_plan.bean.UptoYear > 0) {
            getPlanes();
        }
    }, [_plan.bean.UptoYear])

    const getPlanes = () => {
        _plan.instance.getAdquisitionPlanCatalog(_plan.bean.UptoYear, _plan.bean.UptoYear).then(
            (resp: any) => {
                if (Array.isArray(resp.List)) {

                    let tmp: any[] = resp.List.filter((e: any) => e.DataBeanProperties.PhaseState === 0);
                    if (tmp.length > 0) {
                        _plan.setBean({ ..._plan.bean, "IDAdquisitionPlan": tmp[0].DataBeanProperties.IDAdquisitionPlan });
                    }

                    _plan.setLista(tmp);
                }
            }
        )
    }

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
    
        if (file) {
            const reader = new FileReader();
    
            reader.onload = (e) => {
                const data = new Uint8Array(e.target?.result as ArrayBuffer);
    
                // Leer el archivo Excel
                const workbook = XLSX.read(data, { type: 'array', cellDates: true });
                const sheetName = workbook.SheetNames[0];
                const sheet = workbook.Sheets[sheetName];
    
                // Convertir el contenido de la hoja a JSON, preservando valores en crudo
                const sheetData: any[][] = XLSX.utils.sheet_to_json(sheet, { header: 1, raw: true });
    
                // Iterar sobre el array para detectar y convertir números largos en strings
                const processedData = sheetData.map(row => 
                    row.map(cell => {
                        if (typeof cell === 'number' && cell > Number.MAX_SAFE_INTEGER) {
                            return cell.toString();  // Convertir números largos a strings
                        }
                        return cell;
                    })
                );
    
                // Guardar los datos procesados
                setExcelData(processedData);
                createJSONFile(processedData);
            };
    
            reader.readAsArrayBuffer(file);
        }
    };
    
    const createTXT = (data: any[]) => {
        try {
           //console.log(data);

            // Convertir cada objeto JSON a una cadena de texto y unirlas con saltos de línea
            const text = data.map(item => JSON.stringify(item)).join('\n');

            // Crear archivo .txt
            const blob = new Blob([text], { type: 'text/plain' });
            const file = new File([blob], 'mi-archivo.txt', { type: 'text/plain' });

            // Enviar el archivo usando el servicio _fileService
            _fileService.postFile(file, "tmp").subscribe(
                (resp: any) => {
                   //console.log(resp);
                    setFileItem(resp);
                },
                (error) => {
                    console.error("Error al subir el archivo:", error);
                }
            );
        } catch (error) {
            console.error("Error en createTXT:", error);
        }
    };
    const convertLargeNumbersToStrings = (data: any) => {
        const jsonData = JSON.stringify(data, (key, value) => {
            // Si es un número grande, lo convertimos a cadena
            if (typeof value === 'number' && value > Number.MAX_SAFE_INTEGER) {
                return value.toString();
            }
            return value;
        });
        return jsonData;
    };
    const createJSONFile = (data: any) => {
        if (data)
            try {
               //console.log(data);

                const jsonContent = convertLargeNumbersToStrings(data);  

                const utf8BOM = '\uFEFF';
                const textWithBOM = utf8BOM + jsonContent;


                const blob = new Blob([jsonContent], { type: 'application/json' });
                const file = new File([blob], 'data.json', { type: 'application/json' });

                _fileService.postFile(file, "tmp").subscribe(
                    (resp: any) => {
                        if (resp.DataBeanProperties.ObjectValue) {
                           //console.log(resp.DataBeanProperties.ObjectValue);
                            setFileItem(resp.DataBeanProperties.ObjectValue.DataBeanProperties);
                        }
                    },
                    (error) => {
                        console.error("Error al subir el archivo:", error);
                    }
                );
            } catch (error) {
                console.error("Error en createJSONFile:", error);
            }
    };

    const cargarArchico = () => {
        
        let obj = {
            URL:fileItem.URL,
            Area:1,
            IDEmployee:idAccount
        }
       //console.log(obj);
        

        _contract.instance.importDataContract(opc, JSON.stringify(obj), _plan.bean.IDAdquisitionPlan).then(
            (resp: any) => {
               //console.log(resp);
                setResumen(resp);
            }
        )

    }

    return (
        <BasicPage titulo="Funciones masivas compras">
            <div className="row">
                <div className="col-md-4">
                    <label>Tipo de importacion</label>
                    <select name="importe" className="form-select" value={opc} onChange={(e) => { setOpc(parseInt(e.target.value)) }}>
                        {opciones.map((item: any) => {
                            return (
                                <option value={item.id}>{item.name}</option>
                            )
                        })}
                    </select>
                </div>
                <div className="col-md-4">
                    <YearSelector onChange={(e) => { _plan.setBean({ ..._plan.bean, "UptoYear": e }) }} />
                </div>
                <div className="col-md-4">
                    <label>Plan</label>
                    <select name="IDAdquisitionPlan" id="idplan" className="form-select" onChange={_plan.handleChange}>
                        {_plan.lista.map((item: any, index: number) => {
                            return (
                                <option value={item.DataBeanProperties.IDAdquisitionPlan}>{item.DataBeanProperties.Description}</option>
                            )
                        })

                        }
                    </select>
                </div>
                <div className="col-md-6">
                    <label >Archivo</label>
                    <input type="file" className="form-control" name='archivo' accept=".xlsx" value={file} onChange={handleFileChange} />
                    {/* <FileInput onCreate={setFile}/> */}
                </div>
                <div className="col-md-6">
                    <AccountEditor idAccount={idAccount} label="Responsable" onChange={({IDAccount}:any)=>{setIDAccount(IDAccount)}}/>
                </div>
                <div className="col-md-6 mt-4">
                    <a className="text-primary" href={opciones.filter(e => e.id === opc)[0].plantilla} target="_blank">Descargar Plantilla</a>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <BasicButton icon="ri-chat-upload-line" eventClick={cargarArchico}>Cargar</BasicButton>
                </div>
            </div>
            <PrintJson json={resumen} />
        </BasicPage>
    )
}