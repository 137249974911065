import { useState } from "react";
import { RenderForm } from "../../../../theme/Component/RenderForm";
import { FileInput } from "../../../../theme/Component/FileInput";
import { BasicButton } from "../../../../theme/Component/BasicButton";
import { PrintJson } from "../../../../theme/Component/PrintJson";
import { UploadQuotes } from "./CustomForms/UploadQuotes";
import { ApprovalFlow } from "./CustomForms/ApprovalFlow";
import { CreateCDP } from "./CustomForms/CreateCDPFactory/CreateCDP";


interface Props {
    action: any
    exportMap: Function
}

export const RenderInbox = ({ action, exportMap }: Props) => {

    const [doc, setDoc] = useState<any>({});

    const updateDoc = () => {
        //console.log("doc=======>", doc)
        exportMap(doc);
    }

    const updateForm = (map: any) => {
        exportMap(map);
        //console.log("MPA======>", map)
    }

    const renderSwitch = (item: any) => {

        const isNumeric = !isNaN(Number(item.FormCode));

        if (isNumeric) {
            return (
                <RenderForm idFormType={item.FormCode} handleUpdate={(e: any) => updateForm(e)} />
            );
        }

        switch (item.FormCode) {
            case "EP01":
                return (
                    <div className="row">
                        <div className="col-md-12 d-flex justify-content-center">
                            <div className="row w-75">
                                <div className="col-md-12">
                                    <FileInput label='Cargar Docuemento Requerido' onCreate={({ Media, MediaContext, Name }) => { setDoc({ ...doc, 'Media': Media, 'MediaContext': MediaContext, "Name": Name }) }} />
                                </div>
                                <div className="col-4 mt-2">
                                    <BasicButton icon="ri-send-plane-2-line" clase='primary' eventClick={() => updateDoc()}>Cargar</BasicButton>
                                </div>
                            </div>
                        </div>
                    </div>
                )

            case "EP02":
                return (
                    <UploadQuotes exportMap={(data) => updateForm(data)} />
                )
            case "EP03":
                return (
                    <ApprovalFlow exportMap={(data) => updateForm(data)} />
                )
            case "EP04":
                return (
                    <CreateCDP procedure={item} exportMap={(data) => updateForm(data)} />
                )
            default:
                return (
                    <RenderForm idFormType={901} handleUpdate={(data: any) => { updateForm(data) }} />
                );
        }
    };

    return (
        <>
            <div className="row">
                {/* <PrintJson json={action}></PrintJson> */}
                <div className="col-md-12">
                    {action.FormCode ?
                        <div className="text-center">
                            <h4>{action.Name}</h4>
                            <div className='mb-4' >
                                {/* <PrintJson json={action} /> */}
                                {renderSwitch(action)}
                            </div>
                        </div>
                        :
                        < div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body text-center">
                                        <h4>{action.Name}</h4>
                                        <FileInput
                                            docAcept="application/pdf"
                                            label=""
                                            Media={doc.Media}
                                            MediaContext={doc.MediaContext}
                                            directory="Antecedentes"
                                            onCreate={(e: any) => setDoc({ ...doc, 'Media': e.Media, 'MediaContext': e.MediaContext })}
                                        ></FileInput>
                                    </div>
                                    <div className="card-footer">
                                        <div className="row">
                                            {doc.Media &&
                                                <>
                                                    <div className="col-md-12">
                                                        <div className="col-md-12 mt-3" style={{ display: "flex", justifyContent: "center" }}>
                                                            <BasicButton icon="ri-send-plane-2-line" clase='primary' eventClick={() => updateDoc()}>Cargar</BasicButton>
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="col-md-6">

                            </div>
                        </div>
                    }
                </div>
            </div >

        </>
    )
}