import { DataBean } from "../DataBean";

export class UBLInvoice extends DataBean {

    public async getAccountantIDTypeCatalog(): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_getAccountantIDTypeCatalog_Number", [null]);
    }

    public async getAccountantConceptCatalog(): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_getAccountantConceptCatalog_Number", [null]);
    }

    public async getAccountantConceptCatalogForCashDesk(arg: null): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_getAccountantConceptCatalogForCashDesk_Number", [arg]);
    }

    public async getUblInvoiceCatalog(idAccountSupplier: number, from: any, upto: any, state: number | null): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_getUblInvoiceCatalog_Number_java.util.Date_java.util.Date_Number", [idAccountSupplier, from, upto, state]);
    }

    public async getUblInvoice(idUBLInvoice: number, issueDate: any): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "com.quickdataerp.bean.ubl.UBLInvoice_getUblInvoice_Number_java.util.Date", [idUBLInvoice, issueDate]);
    }

    public async createUBLInvoice(idAccountantConcept: number, idAccount: number, invoiceNumber: string, isDocumentSupport: boolean, issueDate: any, accountantDate: any, media: string, mediaContext: string, idAccountFiled: number, obs: string): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "com.quickdataerp.bean.ubl.UBLInvoice_createUBLInvoice_Number_Number_String_Boolean_java.util.Date_java.util.Date_String_String_Number_String",
            [idAccountantConcept, idAccount, invoiceNumber, isDocumentSupport, issueDate, accountantDate, media, mediaContext, idAccountFiled, obs]);
    }

    public async getUBLEventHistoryCatalog(idUBLInvoice: number, issueDate: any): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_getUBLEventHistoryCatalog_Number_java.util.Date", [idUBLInvoice, issueDate]);
    }

    public async getInvoiceStateConstants(arg: null): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_getInvoiceStateConstants_Number", [null]);
    }

    public async addUBLItemInvoiceToAdquisitionArticle(idSupplierPaymentOrderItem: any, supplierPaymentOrderDate: any, idUBLItemInvoice: any): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "com.quickdataerp.bean.ubl.UBLItemInvoice_addUBLItemInvoiceToAdquisitionArticle_Number_java.util.Date_Number", [idSupplierPaymentOrderItem, supplierPaymentOrderDate, idUBLItemInvoice]);
    }

    public async releaseUBLItemInvoiceFromAdquisitionArticle(idUBLInvoice: any, issueDate: any, idAdquisitionContract: any): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "com.quickdataerp.bean.ubl.UBLItemInvoice_releaseUBLItemInvoiceFromAdquisitionArticle_Number_java.util.Date_Number", [idUBLInvoice, issueDate, idAdquisitionContract]);
    }

    public async importUBLInvoice(idConcept: number, media: string, mediaContext: string, dataStore: string | null, issueDate: Date | string, importProperties: any, idEmployee: number, observation: string): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "com.quickdataerp.bean.ubl.UBLInvoice_importUBLInvoice_Number_String_String_String_java.util.Date_java.util.Map_Number_String", [idConcept, media, mediaContext, dataStore, issueDate, importProperties, idEmployee, observation]);
    }

    public async importUBLInvoiceContainer(idAccountantConcept: number, media: string, mediaContext: string, dataStore: string | null, issueDate: Date | string, importProperties: any, idEmployee: number, observation: string): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "com.quickdataerp.bean.ubl.UBLInvoice_importUBLInvoice_Number_String_String_String_java.util.Date_java.util.Map_Number_String", [idAccountantConcept, media, mediaContext, dataStore, issueDate, importProperties, idEmployee, observation]);
    }

    public async getUblInvoiceCatalogForStore(idAccountSupplier: number | null, year: number, page: number, recordsPerPage: number): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "com.advantage.shared.Report_getUblInvoiceCatalogForStore_Number_Number_Number_Number", [idAccountSupplier, year, page, recordsPerPage]);
    }

    public async postSupplierPaymentOrderToSupervisor(idSupplierPaymentOrder: number, supplierPaymentOrderDate: any, observations: string, idAccountFiled: number): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "com.quickdataerp.bean.supplier.SupplierPaymentOrder_postSupplierPaymentOrderToSupervisor_Number_java.util.Date_String_Number", [idSupplierPaymentOrder, supplierPaymentOrderDate, observations, idAccountFiled]);
    }

    public async requestUBLInvoiceToAccounting(idUBLInvoice: number, issueDate: any, idAccountFiled: number, observations: string): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "com.quickdataerp.bean.ubl.UBLInvoice_requestUBLInvoiceToAccounting_Number_java.util.Date_Number_String", [idUBLInvoice, issueDate, idAccountFiled, observations]);
    }

    public async createFixedAssetEntryForUBLInvoice(idUBLInvoice: number, issueDate: any, recordDate: any, idStore: number, idEmployee: number): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "com.quickdataerp.bean.accountant.AccountantDocument_createFixedAssetEntryForUBLInvoice_Number_java.util.Date_java.util.Date_Number_Number",
            [idUBLInvoice, issueDate, recordDate, idStore, idEmployee]);
    }

    public async createAccountantDocumentForUBLInvoice(idUBLInvoice: number, issueDate: any, recordDate: any, idEmployee: number): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "com.quickdataerp.bean.accountant.AccountantDocument_createAccountantDocumentForUBLInvoice_Number_java.util.Date_java.util.Date_Number",
            [idUBLInvoice, issueDate, recordDate, idEmployee]);
    }

    public async parseTaxSchemaValuesWithHoldingTaxForUBLInvoice(idUBLInvoice: number, issueDate: any, taxSchemeValueList: any): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "double_parseTaxSchemaValuesWithHoldingTaxForUBLInvoice_Number_java.util.Date_java.util.List",
            [idUBLInvoice, issueDate, taxSchemeValueList]);
    }

    public async deleteUBLTaxWithHoldingTax(idUBLInvoice: number, issueDate: any, idUBLTaxWithHoldingTax: number): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "void_deleteUBLTaxWithHoldingTax_Number_java.util.Date_Number",
            [idUBLInvoice, issueDate, idUBLTaxWithHoldingTax]);
    }

}