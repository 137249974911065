import { useState, useEffect } from 'react';
import useDataBean from '../../../../../core/services/dataBean/useDataBean';
import { _single, _Store } from '../../../../../core/services/dataBean/EntityCatalog';
import { BasicTable } from '../../../../theme/Component/BasicTable';
import { Modal } from '../../../../theme/Component/Modal';

interface Props {
    label?: string;
    idSelector: number;
    onChange?: any;
}

export const SelectStoreEditor = ({ idSelector, label, onChange }: Props) => {

    const { lista, setLista, bean, setBean, instance } = useDataBean(_Store);
    const [searchTerm, setSearchTerm] = useState("");
    const [filteredLista, setFilteredLista] = useState([]);

    const [modal, setModal] = useState<any>({ name: 'Buscar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });

    useEffect(() => {
        if (idSelector) {
            getStore(idSelector);
        }
    }, [idSelector]);
    



    const getLista = () => {
        let store = _single.getCacheItem('StoreList');
        _single.printOut(store);
        
        if(store)
        {
           
            setLista(JSON.parse(store));
            setFilteredLista(JSON.parse(store));
           
            setModal({ ...modal, view: true }); 
        }else{

            instance.getStoresCatalog(null).then(            
                (resp: any) => {
                    if (resp) {
                        _single.printOut(resp);
                        _single.setCacheItem('StoreList',JSON.stringify(resp));
                        setLista(resp);
                        setFilteredLista(resp);
                        setModal({ ...modal, view: true }); 
                        
                    }
                }
            );
        }

    }

    const getStore = (id: number) => {
        instance.getStore(id).then(
            (resp: any) => {
                if (resp) {
                    setBean(resp);
                    onChange(resp);
                }
            }
        );
    }

    const head = [
        { title: 'ID', property: "IDStore" },
        { title: 'Nombre', property: 'Description' },
    ];

    useEffect(() => {
        if (searchTerm === "") {
            setFilteredLista(lista);
        } else {
            setFilteredLista(lista.filter((item: any) =>
                item.DataBeanProperties.Description.toLowerCase().includes(searchTerm.toLowerCase())
            ));
        }
    }, [searchTerm]);

    const selectItem = () => {
        let x = bean;
        setModal({ ...modal, "view": false });
        if (onChange) {
            onChange(x);
        }
    };

    return (
        <>
            <label>{label ? label : 'Ubicación'}</label>
            <input
                type="text"
                className="form-control"
                aria-label="Recipient's username"
                aria-describedby="button-addon2"
                placeholder="Buscar..."
                value={bean.IDStore ? `${bean.IDStore} - ${bean.Description}` : ""}
                onClick={() => { getLista(); }}
            />

            
                <Modal modal={modal} updateModal={setModal} eventModal={() => { selectItem() }}>
                    <>
                        <label>Filtro</label>
                        <input
                            type="text"
                            className="form-control mb-2"
                            placeholder="Buscar área..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                        <BasicTable body={lista} head={head} rowSet={setBean} />
                    </>
                </Modal>
            
        </>
    );
}
