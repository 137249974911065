import { DataBean } from "../DataBean";


export class EmployeeNovelty extends DataBean {

    public async getEmployeeNovelties(accountID: number | null, year: number, month: number): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_getEmployeeNovelties_Number_Number_Number_Number", [accountID, year, month, null]);
    }

    public async deleteEmployeeNovelty(idNovelty: number): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "Integer_deleteEmployeeNovelty_Number", [idNovelty]);
    }

    public async getAbsenteeismTypeConstants(agr: any | null): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_getAbsenteeismTypeConstants_Number", [agr]);
    }

}