import { useEffect, useState } from "react"
import { StepWizard, Wizard } from "../../bpm/config/Componentes/wizard/Wizard"
import { BasicPage } from "../../theme/Component/BasicPage"
import { RegisterBudgetReductionRPValue } from "./RegisterBudgetReductionRPValue";
import { CreateBudgetReductionRPFactoryDocument } from "./CreateBudgetReductionRPFactoryDocument";
import { BudgetReductionRPFactoryForm } from "./BudgetReductionRPFactoryForm";
import { PrintJson } from "../../theme/Component/PrintJson";

interface MapProperties {
    FormData: Record<string, any>;
    ObjectList: object[];
    DocumentList: Record<string, any>;
}

export const BudgetReductionRPFactory = () => {

    const [canDiscard, setCanDiscard] = useState<boolean>(false);
    const [map, setMap] = useState<MapProperties>({
        FormData: {},
        ObjectList: [],
        DocumentList: {}
    });

    const validarContrato = () => {

    }

    useEffect(() => {
        if (map.DocumentList.MSGList?.length > 0) {
            setCanDiscard(true)
        } else {
            setCanDiscard(false)
        }
    }, [map.DocumentList])

    const steps: StepWizard[] = [
        {
            keyID: "0",
            name: 'PASO 1. Formulario',
            onEvent: validarContrato,
            children: <BudgetReductionRPFactoryForm onChange={(e) => { setMap({ ...map, "FormData": e }) }} />,
            validButton: (map.FormData.RPSelected?.IDDocument),
            canReturn: false
        },
        {
            keyID: "1",
            name: 'PASO 2. Rubros Registrados',
            onEvent: validarContrato,
            children: <RegisterBudgetReductionRPValue formData={map.FormData} onChange={(e) => { setMap({ ...map, "ObjectList": e }) }} />,
            validButton: map.ObjectList.length > 0,
            canReturn: true
        },
        {
            keyID: "2",
            name: 'PASO 3. Generar Reducción de RP',
            onEvent: validarContrato,
            children: <CreateBudgetReductionRPFactoryDocument objectList={map.ObjectList} formData={map.FormData} onChange={(e) => { setMap({ ...map, "DocumentList": e }) }} />,
            validButton: false,
            canReturn: canDiscard,
            canDiscard: canDiscard
        }
    ]

    return (
        <BasicPage titulo="Generar Reducción de RP">
            <Wizard title="Certificado" listStep={steps} />
        </BasicPage>
    )
}