import { AdminService } from "../../../core/services/AdminService"
import { Singleton } from "../../../core/services/Singleton"
import { BasicButton } from "../../theme/Component/BasicButton"
import { BasicPage } from "../../theme/Component/BasicPage"

export const ExportMenu=()=>{


    const single = Singleton.getInstance();
    const service = new AdminService();

    const exportMenu=()=>{
        single.spinner(true);
        service.exportMenuAndRoles(null).subscribe(
            (resp:any)=>{
               //console.log(resp);
                single.spinner(false);
            }
        )
    }


    return(
        <BasicPage titulo="Exportar Menus y opciones">
            <div className="row">
                <div className="col">
                    <BasicButton icon="" eventClick={exportMenu}>Exportar Menú</BasicButton>
                </div>
            </div>
        </BasicPage>
    )
}