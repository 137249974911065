import { useEffect, useState } from "react";
import { FinanceService } from "../../core/services/FinanceService";
import { BasicPage } from "../theme/Component/BasicPage";
import { Singleton } from "../../core/services/Singleton";
import { FunctionalIDPreparer } from "./FunctionalIDPreparer";
import { AdquisitionItem } from "./adquisition-setting/AdquisitionItem";
import { useDashboardStore } from "../pages/hooks/useDashboardStore";
import { SelectAdquisitionPlanFunctionalID } from "./select/SelectAdquisitionPlanFunctionalID";
import { CardTaps } from "../theme/Component/CardTaps";
import { ITEM_JEFE } from "../Constants";
import { _single } from "../../core/services/dataBean/EntityCatalog";
import { SelectAdquisitionItemConstan } from "./adquisition-setting/SelectAdquisitionItemConstan";


export const MisPlanesEstructured = () => {
  const serviceFinance = new FinanceService();
  const single = Singleton.getInstance()
  const { setLoading } = useDashboardStore();
  const [lista, setLista] = useState<any>([]);
  const [listaFun, setListaFun] = useState<any>([]);
  const [idPlan, setIDPlan] = useState(0);
  const [idArea, setIDArea] = useState(0);
  const [state, setState] = useState(null);
  const [area, setArea] = useState<any>({});
  const [bean, setBean] = useState<any>({});
  const role = ITEM_JEFE;

  useEffect(() => {
    getAdquisitionPlanOnStructuringState();
    return () => { setIDPlan(0) }
  }, []);

  useEffect(() => {
    getAdquisitionPlanFunctionalIDCatalog();
  }, [idPlan]);

  useEffect(() => {
    _single.printOut(idArea);
  }, [idArea]);

  const getAdquisitionPlanOnStructuringState = () => {
    setLoading(true);
    serviceFinance
      .getAdquisitionPlanOnStructuringState(single.getAccountID())
      .subscribe((resp: any) => {
        if (resp.DataBeanProperties.ObjectValue) {
          setLista(resp.DataBeanProperties.ObjectValue);
        }
        setLoading(false);
      });
  };

  const getAdquisitionPlanFunctionalIDCatalog = () => {
    setLoading(true);
    serviceFinance
      // .getAdquisitionPlanFunctionalIDCatalog(idPlan)
      .getAdquisitionPlanFunctionalIDByIDAccount(idPlan, single.getAccountID())
      .subscribe((resp: any) => {
        if (resp.DataBeanProperties.ObjectValue) {
          let list = resp.DataBeanProperties.ObjectValue;
          list.forEach((ele: any) => {

            ele.DataBeanProperties.AccountName =
              (ele.DataBeanProperties.Name1 ?? '') + ' ' +
              (ele.DataBeanProperties.Name2 ?? '') + ' ' +
              (ele.DataBeanProperties.Surname1 ?? '') + ' ' +
              (ele.DataBeanProperties.Surname2 ?? '');

          });
          setListaFun(list);

        }
        setLoading(false);
      });
  };

  const head = [
    { title: "ID", property: "IDAdquisitionPlanFunctionalID" },
    // { title: 'Tipo de Presupuesto', property: 'BudgetTypeName' },
    { title: "Oficina", property: "IDLnFunctionalIDName" },
    { title: "Funcionario Responsable", property: "AccountName" },
    { title: "Valor Estimado", property: "EstimatedValue" },
    { title: "Valor Estructurado", property: "TotalValueEstimate" },
    // {title: "Limitar Plan al Valor Estimado",property: "CloseToEstimatedValue"},
    { title: "Etapa del Plan", property: "PhaseStateName" },
    // { title: "Estado de Planeación del Plan", property: "PlanningStateName" },
    // { title: "Estado de Estructuración del Plan", property: "StructuringStateName" },
    // { title: "Estado de Contratación del Plan", property: "HiringStateName" },
  ];

  const setRow = (item: any) => {
    setBean(item);
   //console.log(idPlan + single.getAccount());
  };

  const taps = [
    { id: 0, name: 'Necesidades' },
    { id: 1, name: 'Preparadores' }
  ]

  const [indexTap, setIndexTap] = useState(0);

  return (
    <BasicPage titulo="Gestión Plan de Compras">
      <div className="row">
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-6">
              <label>Mis Planes</label>
              <select
                className="form-select "
                value={idPlan}
                onChange={(e) => {
                  setIDPlan(parseInt(e.target.value));
                  setIDArea(NaN)
                }}
              >
                <option value="0">...</option>
                {lista.map((item: any) => {
                  return (
                    <option value={item.DataBeanProperties.IDAdquisitionPlan}>
                      {item.DataBeanProperties.Description}
                    </option>
                  );
                })}
              </select>
            </div>

            <div className="col-md-6">
              <SelectAdquisitionPlanFunctionalID idAdquisitionPlan={idPlan} onChange={(e) => { setIDArea(e.IDAdquisitionPlanFunctionalID); setArea(e) }} />
            </div>

            {/* <div className="col-md-4">
              <SelectAdquisitionItemConstan idSelector={state} onChange={(e) => { setState(e) }} />
            </div> */}

          </div>
        </div>
      </div>
      {idArea > 0 ?
        <div className="row">
          <div className="col">
            {/* cuando es jefe ? */}
            <CardTaps index={indexTap} taps={taps} updateIndex={setIndexTap}>
              {indexTap === 0 &&
                <AdquisitionItem key={idArea} idAdquisitionPlanFunctionalID={idArea} rol={role} beanOficina={area} />
              }
              {indexTap === 1 &&
                <FunctionalIDPreparer IDArea={idArea} />
              }
            </CardTaps>
          </div>
        </div>
        :
        <div className="d-flex mt-4 justify-content-center">
          <div className="alert alert-warning" role="alert">
            <p>
              Debe seleccionar una <strong>Oficina/Área</strong> para poder continuar.
            </p>
          </div>
        </div>
      }

    </BasicPage>
  );
};
