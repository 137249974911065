import { useState, useEffect } from 'react';
import { PrintJson } from '../theme/Component/PrintJson';
import { FileInput } from '../theme/Component/FileInput';
import { FileService } from '../../core/services/FileService';

interface Props {
    ListDocuments: any;
    updateList?: Function;
    DeleteItem?: Function;

}
export const ListDocuments = ({ ListDocuments, updateList, DeleteItem }: Props) => {

    const [lista, setLista] = useState<any>([]);
    const [file, setFile] = useState<any>({});
    const fileService = new FileService();

    useEffect(() => {
       //console.log(ListDocuments);
        let x = ListDocuments;
        setLista(ListDocuments != null && ListDocuments != undefined ? ListDocuments : []);
    }, [ListDocuments])

    return (
        <>
            <div className="row">
                <div className="col-md-9">
                    <FileInput
                        label='Documento Adjunto'
                        directory='DocsRealesCredit'
                        onCreate={({ Media, MediaContext, Name }) => { setFile({ ...file, "Media": Media, "MediaContext": MediaContext, "Name": Name }) }}
                    />
                </div>
                <div className="col-md-3">
                    <button style={{ marginTop: '38px' }} disabled={!file.Media} className='btn btn-primary' onClick={() => updateList(file)}>Agregar</button>
                </div>
                <div className="col-md-12 mt-4">
                    {lista.length > 0 &&
                        <ul className="list-group">
                            {Array.isArray(lista) && lista.map((acc: any, index: number) => {
                                return (
                                    <li key={index} className="list-group-item " aria-disabled="true">
                                        <div className="d-flex align-items-center">
                                            <div className="flex-shrink-0">
                                                <i className='ri-attachment-line'></i>
                                            </div>
                                            <div className="flex-grow-1 ms-2">
                                                {acc.DataBeanProperties.IDRealesCV} -
                                                {acc.DataBeanProperties.Document?.Name ?
                                                    <a href={fileService.getUrlFile(acc.DataBeanProperties.Document?.MediaContext, acc.DataBeanProperties.Document?.Media)} target='_blank'><strong className='ml-4'> {acc.DataBeanProperties.Document?.Name}</strong></a>
                                                    :
                                                    <b> No presenta documento</b>
                                                }
                                            </div>
                                            <div className="flex-shrink-0">
                                                <span className="text-danger cursor" onClick={() => { DeleteItem(acc) }}>Eliminar</span>
                                            </div>
                                        </div>
                                    </li>
                                )
                            })}

                        </ul>
                    }
                </div>
            </div>
        </>
    )

}