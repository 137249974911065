import { useEffect, useState } from "react";
import useDataBean from "../../../../../../core/services/dataBean/useDataBean";
import { _CDPAvailableForUse } from "../../../../../../core/services/dataBean/EntityCatalog";
import { Singleton } from "../../../../../../core/services/Singleton";
import { ListMenu } from "../../../../../theme/Component/ListMenu";
import { BasicTable } from "../../../../../theme/Component/BasicTable";
import { Modal } from "../../../../../theme/Component/Modal";
import { BasicSwitch } from "../../../../../theme/Component/BasicSwitch";



interface PropAbility {
    _IDContract: number;
}

export const CDPLink = ({ _IDContract }: PropAbility) => {

    const cdpLink = useDataBean(_CDPAvailableForUse);
    const single = Singleton.getInstance();
    const [lista, setLista] = useState<any>([]);
    const [modal, setModal] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-md' });
    const [modalC, setModalC] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });

    useEffect(() => {
        if (_IDContract >= 0) {
            getLista();
        }
    }, [_IDContract])

    const getLista = () => {
        cdpLink.instance.getCDPAvailableForUseCatalog(_IDContract, null).then(
            (resp) => {
               //console.log(resp)
                setLista(resp)
            }
        )
    }

    const create = () => {
        cdpLink.setBean({
            IDContract: _IDContract
        });
        setModal({ ...modal, "name": "Agregar Hisotrial Laboral", "view": true });
    }

    const deleteItem = () => {
        cdpLink.deleteItemVoid(cdpLink.bean.IDCDPAvailableForUse).then(
            (resp) => {
                getLista();
                setModalC({ ...modalC, "view": false })
                single.delete(cdpLink.bean.IDCDPAvailableForUse, cdpLink.bean)
            }
        )
    }

    const editaItem = () => {
        cdpLink.update().then(
            (resp) => {
                getLista();
                setModal({ ...modal, "view": false });
                if (cdpLink.bean.IDCDPAvailableForUse) {
                    single.create(cdpLink.bean)
                } else {
                    single.update(cdpLink.bean)
                }
            }
        )
    }

    const head = [
        { title: 'ID', property: 'IDCDPAvailableForUse', visible: true },
        { title: 'Mnemonico', property: 'Mnemonic', visible: true },
        { title: 'Consecutivo', property: 'Consecutive', visible: true },
        { title: 'Doc Externo', property: 'ExternalNumber', visible: true },
        { title: 'Valor Doc Externo', property: 'ExternalValue', visible: true },
        { title: 'Orden de Aplicación', property: 'OrderForUse', visible: true },
        { title: 'Habilitado ', property: 'IsEnabled', visible: true },
    ]

    const iconButtonsItem = [
        { nombre: 'Agregar Hisotrial Laboral', visible: single.canCreate(), icono: 'ri-file-add-line', evento: create }
    ]

    const listaBotones = () => {
        const botones = [
            { titulo: 'Editar', icono: 'ri-edit-2-line', visible: single.canEdit(), evento: () => { setModal({ ...modal, "name": `Editar CDP`, "view": true }) } },
            { titulo: 'Eliminar', icono: 'ri-edit-2-line', visible: single.canDelete(), evento: () => { setModalC({ ...modalC, "name": `Eliminar`, "view": true }) } },
        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }

    return (
        <>
            <div className="row">
                <div className="col-12">
                    <BasicTable iconButtons={iconButtonsItem} listButtons={listaBotones()} head={head} body={lista} rowSet={cdpLink.setBean} />
                </div>
            </div>

            {modal.view &&
                <Modal modal={modal} updateModal={setModal} eventModal={editaItem} >
                    <div className="row">
                        <div className="col-12">
                            <label>Mnemonico</label>
                            <input type="text" name="Mnemonic" className="form-control" value={cdpLink.bean.Mnemonic} onChange={cdpLink.handleChange} />
                        </div>
                        <div className="col-12">
                            <label>Consecutivo</label>
                            <input type="text" name="Consecutive" className="form-control" value={cdpLink.bean.Consecutive} onChange={cdpLink.handleChange} />
                        </div>
                        <div className="col-12">
                            <label>Doc Externo</label>
                            <input type="number" name="ExternalNumber" className="form-control" value={cdpLink.bean.ExternalNumber} onChange={cdpLink.handleChange} />
                        </div>
                        <div className="col-12">
                            <label>Valor Doc Externo</label>
                            <input type="text" name="ExternalValue" className="form-control" value={cdpLink.bean.ExternalValue} onChange={cdpLink.handleChange} />
                        </div>
                        <div className="col-12">
                            <label>Orden de Aplicación</label>
                            <input type="text" name="OrderForUse" className="form-control" value={cdpLink.bean.OrderForUse} onChange={cdpLink.handleChange} />
                        </div>
                        <div className="col-12">
                            <label>Habilitado</label>
                            <BasicSwitch estado={cdpLink.bean.IsEnabled} eventChange={(e) => { cdpLink.setBean({ ...cdpLink.bean, "IsEnabled": e }) }} />
                        </div>
                    </div>
                </Modal>
            }

            {modalC.view &&
                <Modal modal={modalC} updateModal={setModalC} eventModal={deleteItem} >
                    <p>
                        Desea eliminar el CDP {cdpLink.bean.Mnemonic} - {cdpLink.bean.Consecutive} con el valor de {cdpLink.bean.ExternalValue}
                    </p>
                </Modal>
            }
        </>
    )
}