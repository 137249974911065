import { DataBean } from "../DataBean";


export class Experience extends DataBean {


    public async getExperienceCatalog(idLn: number, idAppoManual: number, type: number | null): Promise<any> {
        return this.getCustomService("PeopleArt", "java.util.List_getExperienceCatalog_Number_Number_Number", [idLn, idAppoManual, type]);
    }

    public async deleteExperience(bean): Promise<any> {
        return this.getCustomService("PeopleArt", "void_deleteExperience_com.peopleart.bean.appointment.Experience", [
            {
                "DataBeanProperties": bean,
                "DataBeanName": "com.peopleart.bean.appointment.Experience"
            }
        ]);
    }
}