import { useState, useEffect } from 'react';
import useDataBean from '../../../../../core/services/dataBean/useDataBean';
import { _BudgetAccountantMovement } from '../../../../../core/services/dataBean/EntityCatalog';

interface Props {
    idSelector: number;
    onChange: any;
    label?: string;
}

export const SelectBudgetAccountantMovementType = ({ idSelector, onChange, label }: Props) => {

    const { lista, setLista, instance } = useDataBean(_BudgetAccountantMovement)
    const [id, setID] = useState(NaN);

    useEffect(() => {
        setID(idSelector);
    }, [idSelector])

    useEffect(() => {
        getLista();
    }, [])

    const setValue = (e: any) => {
        setID(parseInt(e));
        onChange(e);
    }

    const getLista = () => {
        instance.getBudgetAccountantMovementTypeConstants(null).then(
            (resp) => {
                setLista(resp);
            }
        ).catch(
            err => {console.error(err) }
        )
    }

    return (
        <>
            <label>{label ?? "Tipo de Movimiento"}</label>
            <select className="form-control" value={id} onChange={(e) => { setValue(e.target.value) }}>
                <option value={NaN} >...</option>
                {lista &&
                    lista.map((item: any, index: number) => {
                        return (

                            <option key={index} value={item.DataBeanProperties.Value} >{item.DataBeanProperties.Property}</option>
                        )
                    })
                }
            </select>
        </>
    )
}