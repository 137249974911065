import { DataBean } from "../DataBean";

export class WorkExperience extends DataBean {

    public async getWorkExperienceCatalogPorPropiedades(properties: any, maxRows: number): Promise<any> {
        return this.getCustomService("TalentERP", "java.util.List_getWorkExperienceCatalogPorPropiedades_java.util.Map_Number", [properties, maxRows]);
    }

    public async getWorkExperienceCatalogRender(maxRows: number, properties: any): Promise<any> {
        return this.getCustomService("TalentERP", "java.util.List_getWorkExperienceCatalogRender_Number_java.util.Map", [ maxRows, properties]);
    }

}