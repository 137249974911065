import { useEffect, useState } from "react";
import { BasicButton } from "../../../../theme/Component/BasicButton";
import { Alert } from "../../../../theme/Component/Alert";

export interface StepWizard {
    keyID: string;
    name: string;
    onEvent: Function;
    children: any;
    validButton: boolean;
    canReturn: boolean;
    canDiscard?: boolean;
}

interface Props {
    title: string;
    listStep: StepWizard[];
    onStep?: Function;
}
export const Wizard = ({ title, listStep, onStep }: Props) => {

    const [index, setIndex] = useState<number>(0);
    const [current, setCurrent] = useState<StepWizard>(undefined);

    useEffect(() => {
        getStep(index);
    }, [listStep])

    useEffect(() => {
        getStep(index);

    }, [index])

    const next = () => {
       //console.log("avanzar");
        setIndex(prev => prev + 1);
    }
    const prev = () => {
       //console.log("avanzar");
        setIndex(prev => prev - 1);
    }

    const discard = () => {
       //console.log("descartar");
        setIndex(0)
    }

    const getStep = (i: number) => {
        if (i < listStep.length) {
            setCurrent(listStep[i]);
            if (onStep)
                onStep(listStep[i].keyID);
        }
    }

    return (
        <div className="row">
            <div className="col">
                {current ?
                    <div className="card">
                        <div className="card-body">
                            <div className="card-title">
                                {current.name}
                                <hr />
                            </div>
                            {current.children}
                        </div>
                        <div className="card-footer">
                            {index > 0 && current.canReturn &&
                                <BasicButton icon="ri-skip-back-line" eventClick={prev}>Atras</BasicButton>
                            }
                            {index > 0 && current.canDiscard &&
                                <BasicButton icon="ri-delete-bin-line" clase="danger" eventClick={discard}>Descartar</BasicButton>
                            }
                            {index < listStep.length &&

                                <BasicButton icon=" ri-skip-forward-line" disable={!current.validButton} eventClick={next}>Siguiente</BasicButton>
                            }
                        </div>
                    </div>
                    :
                    <Alert clase="warning">
                        <strong>Sin elementos</strong>
                    </Alert>
                }
            </div>
        </div>
    )
}