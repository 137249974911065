import { DataBean } from "../DataBean";


export class Ability extends DataBean {


    public async getAbilityCatalog(idAppoManual: number, idAbiliType: number | null): Promise<any> {
        return this.getCustomService("PeopleArt", "java.util.List_getAbilityCatalog_Number_Number", [idAppoManual, idAbiliType]);
    }

    public async deleteAbility(bean): Promise<any> {
        return this.getCustomService("PeopleArt", "void_deleteAbility_com.peopleart.bean.appointment.Ability", [
            {
                "DataBeanProperties": bean,
                "DataBeanName": "com.peopleart.bean.appointment.Ability"
            }
        ]);
    }
}