import { useEffect, useState } from "react";
import useDataBean from "../../../../../../core/services/dataBean/useDataBean";
import { _EmployeeContract } from "../../../../../../core/services/dataBean/EntityCatalog";
import { Singleton } from "../../../../../../core/services/Singleton";
import { Alert } from "../../../../../theme/Component/Alert";

export const ExportEmployeeContract = () => {

    const exportContract = useDataBean(_EmployeeContract);
    const single = Singleton.getInstance();
    const [lista, setLista] = useState<any>([]);

    useEffect(() => {
        exportar()
    }, [])

    const exportar = () => {
        exportContract.instance.exportEmployeeContractsTemplateXlsxFile(null).then(
            (resp) => {
               //console.log(resp)
            }
        )
    }

    return (
        <>
            {lista.length > 0 ?
                <h1>
                    componente
                </h1>
                :
                <Alert clase="warning">
                    No se genero ningun archivo
                </Alert>
            }
        </>
    )
}