import { useEffect, useState } from "react";
import { FinanceService } from "../../core/services/FinanceService";
import { MyEditor } from "../theme/Component/MyEditor";
import { Singleton } from "../../core/services/Singleton";
import { BasicButton } from "../theme/Component/BasicButton";
import { Alert } from "../theme/Component/Alert";
import { useDashboardStore } from "../pages/hooks/useDashboardStore";

interface Props {
    bean: any;
}
export const AdquisitionStates = ({ bean }: Props) => {

    const serviceFinance = new FinanceService();
    const single = Singleton.getInstance()
    const { setLoading } = useDashboardStore();
    const [adquisition, setAdquisition] = useState<any>({});
    const [idestado, setIDEstado] = useState(0);
    const [observacion, setObservacion] = useState<any>('');
    const [message, setMessage] = useState('');
    const [login, setLogin] = useState<any>(single.getAccount());

    const estados = [
        { id: 1, name: 'Estructuración' },
        { id: 2, name: 'Revisado' },
        { id: 3, name: 'Aprobado' },
        { id: 4, name: 'Cancelado' },
        { id: 5, name: 'Configuración' },
    ]

    useEffect(() => {
        setAdquisition(bean);
        setIDEstado(0);
        setObservacion('');
       //console.log("Account : ", login);

    }, [])


    const updateState = () => {

       //console.log(login, idestado, observacion);

        if (idestado === 1) {
            serviceFinance.setAdquisitionPlanOnStructuringState(bean.IDAdquisitionPlan, login.IDAccount, observacion).subscribe(
                (resp: any) => {
                   //console.log(resp);

                    setMessage('Estado actualizado');
                }
            )
        }
        else if (idestado === 2) {
            serviceFinance.setAdquisitionPlanOnReviewedState(bean.IDAdquisitionPlan, login.IDAccount, observacion).subscribe(
                (resp: any) => {
                    setMessage('Estado actualizado');
                   //console.log(resp);

                }
            )
        }
        else if (idestado === 3) {
            serviceFinance.setAdquisitionPlanOnApprovedState(bean.IDAdquisitionPlan, login.IDAccount, observacion).subscribe(
                (resp: any) => {
                   //console.log(resp);
                    setMessage('Estado actualizado');
                }
            )
        }
        else if (idestado === 4) {
            serviceFinance.setAdquisitionPlanOnCancelledState(bean.IDAdquisitionPlan, login.IDAccount, observacion).subscribe(
                (resp: any) => {
                   //console.log(resp);
                    setMessage('Estado actualizado');
                }
            )
        }
        else if (idestado === 5) {
            serviceFinance.setAdquisitionPlanOnCreatedState(bean.IDAdquisitionPlan, login.IDAccount, observacion).subscribe(
                (resp: any) => {
                   //console.log(resp);
                    setMessage('Estado actualizado');
                }
            )
        }


    }



    return (
        <div className="row">
            <div className="col-md-12">
                <h4>Cambiar Estados</h4>
                <hr />
                <div className="row">
                    <div className="col-md-12">
                        <label>Estado</label>
                        <select className="form-control" value={idestado} onChange={(e) => { setIDEstado(parseInt(e.target.value)) }} >
                            {estados.map((item: any) => {
                                return (
                                    <option value={item.id}>{item.name}</option>
                                )
                            })}
                        </select>
                    </div>
                    <div className="col-md-12">
                        <label>Observaciones</label>
                        <MyEditor val={observacion} onChange={(e: any) => { setObservacion(e) }}></MyEditor>
                    </div>
                    <div className="col-md-12">
                        <BasicButton value="Actualizar" icon="ri-refresh-line" eventClick={updateState} />
                    </div>
                    <div className="col-md-12">
                        {message != '' &&
                            <Alert clase="warning">
                                {message}
                            </Alert>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}