import { useEffect, useState } from "react";
import { _CompetitionType, _Entailment, _MainTheme, _StrategicObjetive } from "../../../../core/services/dataBean/EntityCatalog";
import { Singleton } from "../../../../core/services/Singleton";
import { toast } from "react-toastify";
import { ListMenu } from "../../../theme/Component/ListMenu";
import { BasicPage } from "../../../theme/Component/BasicPage";
import { BasicTable } from "../../../theme/Component/BasicTable";
import { Modal } from "../../../theme/Component/Modal";
import { ModalConfirm } from "../../../theme/Component/ModalConfirm";
import useDataBean from "../../../../core/services/dataBean/useDataBean";
import { MarcroProccesEditor } from "../MacroProcess/MacroProccesEditors/MacroProcess.editor";
import { SubMarcroProccesEditor } from "../MacroProcess/MacroProccesEditors/SubMarcroProcces.editor";

export const StrategicObjetive = () => {

    const StrategicObjetive = useDataBean(_StrategicObjetive);

    const single = Singleton.getInstance();

    const [lista, setLista] = useState<any>([]);

    const [IdmacroProcess, setIdmacroProcess] = useState<number>(null)
    const [IdSubProcess, setIdSubProcess] = useState<number>(null)
    const [modal, setModal] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalC, setModalC] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });

    useEffect(() => {
        getLista();
    }, [IdmacroProcess, IdSubProcess])

    const getLista = () => {

        StrategicObjetive.instance.getStrategicObjectiveCatalog(IdmacroProcess, IdSubProcess).then(
            (resp: any) => {
                if (resp) {
                    setLista(resp)
                   //console.log(resp)
                }
            }
        )

    }

    const create = () => {
        StrategicObjetive.setBean({

            IDMacroProcess: IdmacroProcess
        });
        setModal({ ...modal, "view": true });
    }
    const deleteItem = () => {
        StrategicObjetive.instance.deleteStrategicObjective(StrategicObjetive.bean).then(
            (resp: any) => {
                getLista();
                setModalC({ ...modalC, "view": false });
            }
        )

    }
    const editaItem = () => {
       //console.log(StrategicObjetive.bean);
        StrategicObjetive.update().then(
            (resp: any) => {
                getLista();
                setModal({ ...modal, "view": false });
            }
        ).catch(err => {
            toast.error(err);
        })

    }

    const head = [
        { title: 'ID', property: 'IDStrategicObjective', visible: true },
        { title: 'Descripción', property: 'Description', visible: true },
        { title: 'Nombre', property: 'Name', visible: true },
        { title: 'Macro Proceso', property: 'MacroProcessName', visible: true },
        { title: 'Sub Proceso', property: 'SubProcessName', visible: true },

    ]

    const iconButtonsItem = [
        { nombre: 'Agregar', visible: single.canCreate(), icono: 'ri-file-add-line', evento: create }
    ]
    const listaBotones = () => {
        const botones = [

            { titulo: 'Editar', icono: 'ri-edit-2-line', visible: single.canEdit(), evento: () => { setModal({ ...modal, "view": true }) } },
            { titulo: 'Eliminar', icono: 'ri-delete-bin-line', visible: single.canDelete(), evento: () => { setModalC({ ...modalC, "view": true }) } },

        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }


    return (
        <BasicPage titulo=" Objetivos Estratégicos">
            <div className="row">
                <div className="col-md-6">
                    <MarcroProccesEditor idSelector={IdmacroProcess} onChange={(e) => { setIdmacroProcess(e) }} />
                </div>
                <div className="col-md-6">
                    <SubMarcroProccesEditor label="SubProceso" macroProcess={IdmacroProcess} idSelector={IdSubProcess} onChange={(e) => { setIdSubProcess(e) }} />
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">

                    <BasicTable body={lista} head={head} rowSet={StrategicObjetive.setBean} listButtons={listaBotones()} iconButtons={iconButtonsItem}></BasicTable>
                    <Modal modal={modal} updateModal={setModal} eventModal={editaItem}>
                        <div className="row">
                            <div className="col-md-6">
                                {/* <label>Macro Proceso</label> */}

                                <MarcroProccesEditor idSelector={StrategicObjetive.bean.IDMacroProcess} onChange={(e) => { StrategicObjetive.setBean({ ...StrategicObjetive.bean, "IDMacroProcess": e }) }} />
                            </div>
                            <div className="col-md-6">
                                <SubMarcroProccesEditor label="SubProceso" macroProcess={StrategicObjetive.bean.IDMacroProcess} idSelector={StrategicObjetive.bean.IDSubProcess} onChange={(e) => { StrategicObjetive.setBean({ ...StrategicObjetive.bean, "IDSubProcess": e }) }} />
                            </div>
                            <div className="col-md-12">

                                <label>Nombre</label>
                                <input type="text" name="Name" className="form-control" value={StrategicObjetive.bean.Name} onChange={StrategicObjetive.handleChange} />
                            </div>



                            <div className="col-md-12">
                                <label>Descripción</label>

                                <textarea name="Description" className="form-control" value={StrategicObjetive.bean.Description} onChange={StrategicObjetive.handleChange} />
                            </div>
                        </div>
                    </Modal>
                    <ModalConfirm modal={modalC} updateModal={setModalC} eventModal={deleteItem}>
                        <strong>Eliminar item</strong>
                    </ModalConfirm>
                </div>

            </div>
        </BasicPage>
    )
}