import { useEffect, useState } from "react"
import { TalentService } from "../../../core/services/TalentService"
import { Singleton } from "../../../core/services/Singleton";
import { BasicPage } from "../../theme/Component/BasicPage";
import { BasicTable, MASK_DATE, MASK_NUMBER } from "../../theme/Component/BasicTable";
import { Modal } from "../../theme/Component/Modal";
import { ModalConfirm } from "../../theme/Component/ModalConfirm";
import { ProofAccounts } from "./ProofAccounts";
import { ListMenu } from "../../theme/Component/ListMenu";
import { InputDate } from "../../theme/Input/InputDate";
import { SelectTypeTest } from "../../bpm/config/Componentes/SelectTypeTest";

export const Proof = () => {

    const services = new TalentService();
    const single = Singleton.getInstance();
    const [lista, setLista] = useState([]);
    const [bean, setBean] = useState<any>({});
    const [modal, setModal] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-md' });
    const [modalProAcc, setModalProAcc] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalConfirm, setModalConfirm] = useState<any>({ name: 'Confirmar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });

    useEffect(() => {
        getLista()
    }, [])

    const idBean = 'IDProof';

    const head = [
        { title: 'ID', property: idBean, mask: MASK_NUMBER },
        { title: 'Prueba', property: 'Name' },
        { title: 'Desde', property: 'FromDate', mask: MASK_DATE },
        { title: 'Hasta', property: 'Upto', mask: MASK_DATE },
        { title: 'Estado', property: 'StateName' },
    ]

    const setRowBean = (bean: any) => {
       //console.log("Row : ", bean);
        setBean(bean);
    }

    const getLista = () => {
        const x = {
            "State": 1
        }
        single.spinner(true);
        services.getProofCatalogPorPropiedadesRender(null, x).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue) {
                    let x = resp.DataBeanProperties.ObjectValue;
                    // x.forEach((test: any) => {
                    //     test.DataBeanProperties.TypeName = renderName(test.DataBeanProperties.TestType)
                    // });
                    setLista(x)
                }
                single.spinner(false)
            }
        )
    }

    const deleteItem = () => {
        bean.State = 2;
       //console.log(bean);
        updateItem();
        setModalConfirm({ ...modalConfirm, 'view': false });
    }

    const updateItem = () => {
       //console.log(bean)
        setModal({ ...modal, "view": false })
        single.spinner(true);
        services.updateProof(bean).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue) {
                    getLista();
                }
                single.spinner(false)
                if (bean.IDTest) {
                    single.update(bean);
                } else {
                    single.create(bean)
                }
            }
        )
    }

    const agregarItem = () => {
        let b = {
            IDEmployee: single.getAccountID(),
            State: 1,
            IDTest: undefined,
            JSONTest: '',
            From: single.getCurrenDate(),
            Upto: single.getCurrenDate(),
        }
        setBean(b);
        setModal({ ...modal, 'view': true });

    }

    const listaBotones = () => {
        const botones = [

            { titulo: 'Editar', icono: 'ri-file-list-line', visible: single.canEdit(), evento: () => { setModal({ ...modal, "view": true }) } },
            { titulo: 'Eliminar', icono: 'ri-file-user-line', visible: single.canDelete(), evento: () => { setModalConfirm({ ...modalConfirm, "view": true }) } },
            { titulo: 'Usuarios en la Prueba', icono: 'ri-file-user-line', visible: true, evento: () => { setModalProAcc({ ...modalProAcc, "name": `Usuarios de la prueba `, "view": true }) } },

        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }

    const iconButtonsItem = [
        { nombre: 'Agregar', visible: single.canCreate(), icono: 'ri-file-add-line', evento: agregarItem }
    ]

    return (
        <BasicPage titulo="Resultados Pruebas">
            <div className="row">
                <div className="col-12">
                    <BasicTable body={lista} head={head} rowSet={setRowBean} iconButtons={iconButtonsItem} listButtons={listaBotones()} />
                </div>
            </div>

            {modal.view &&
                <Modal modal={modal} updateModal={setModal} eventModal={updateItem}>
                    <div className="row">
                        <div className="col-6">
                            <InputDate label="Fecha desde:" name="Fecha desde:" value={bean.FromDate} setDate={(e: any) => { setBean({ ...bean, "FromDate": e }) }} />
                        </div>
                        <div className="col-6">
                            <InputDate label="Fecha hasta:" name="Fecha hasta:" value={bean.Upto} setDate={(e: any) => { setBean({ ...bean, "Upto": e }) }} />
                        </div>
                        <div className="col-12">
                            <SelectTypeTest idSelector={bean.IDTest} onChange={(e: any) => { setBean({ ...bean, "IDTest": e }) }}></SelectTypeTest>
                        </div>
                    </div>
                </Modal>
            }
            {modalProAcc.view &&
                <Modal modal={modalProAcc} updateModal={setModalProAcc} eventModal={""}>
                    <ProofAccounts idProof={bean.IDProof} />
                </Modal>
            }
            {modalConfirm.view &&
                <ModalConfirm modal={modalConfirm} updateModal={setModalConfirm} eventModal={deleteItem}>
                    {bean.Name}
                </ModalConfirm>
            }
        </BasicPage>
    )
}