import { useEffect, useState } from "react";

import useDataBean from "../../../core/services/dataBean/useDataBean";
import { CashReimbursementServices } from "../cellEditor/CashDesk/CashReimbursement/CashReimbursement-service";
import { toast } from "react-toastify";
import { Singleton } from "../../../core/services/Singleton";
import { ListMenu } from "../../theme/Component/ListMenu";
import { BasicTable, MASK_DATE, MASK_MONEY, MASK_NUMBER } from "../../theme/Component/BasicTable";
import { BasicPage } from "../../theme/Component/BasicPage";
import { Modal } from "../../theme/Component/Modal";

import { ModalConfirm } from "../../theme/Component/ModalConfirm";
import { _AccountantConcept, _FiscalResponsability, _FiscalResponsabilityType } from "../../../core/services/dataBean/EntityCatalog";

import { _AdquisitionProject, _ArticleClassType, _DocumentTypeC, _MeasurementUnitCode, _Project } from "../../../core/services/dataBean/EntityCatalogVdos";
import { SelectAdquisitionArticleConstants } from "../../contract/select/SelectAdquisitionArticleConstants";
import useModal, { MODAL_md, MODAL_SM } from "../../theme/Component/hooks/useModal";
import { InputDate } from "../../theme/Input/InputDate";
import { AccountEditor } from "../../editor/AccountEditor";
import { AdquisitionBeneficiary } from "../AdquisitionBeneficiary/AdquisitionBeneficiary";
import { PaymentMethodSelect } from "../../Talentohumano/PaymentMethod/PaymentMethodSelect";

import { BasicSwitch } from "../../theme/Component/BasicSwitch";
import { SelectProjectID } from "../../admin/configuracion/ProjectID/SelectProjectID";
import { SelectTrdsID } from "../../admin/configuracion/TrdID/SelectTrdsID";
import { SelectorProcessTypeConstants } from "../../bpm/config/Componentes/SelectorProcessTypeConstants";
import { FileInput } from "../../theme/Component/FileInput";




export const DocumentType = () => {

    const DocumentType = useDataBean(_DocumentTypeC);

    const single = Singleton.getInstance();
    const [lista, setLista] = useState<any>([]);
    const [beneficiario, setbeneficiario] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modal, setModal] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalC, setModalC] = useState<any>({ name: '', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });
    const [swich, setSwich] = useState<boolean>(null)
    const modalConfirm = useModal("Orden", MODAL_SM);
    const modalEditar = useModal("Agregar Proyecto", MODAL_md);


    useEffect(() => {
        getLista()

    }, [])


    const getLista = () => {

        DocumentType.instance.getDocumentTypeCatalogPorPropiedades({ State: 1 }, null).then(
            (resp: any) => {
                if (resp) {
                    setLista(resp)
                }

            }
        )

    }



    const create = () => {
        DocumentType.setBean({
            State: 1,
            IDEmployee: single.getAccountID(),

        });
        setSwich(false);
        modalEditar.setModal({ ...modalEditar.modal, "view": true });
    }

    const editaItem = () => {
       //console.log(DocumentType.bean);
        DocumentType.update().then(
            (resp: any) => {
                getLista();
                modalEditar.setModal({ ...modalEditar.modal, "view": false });
            }
        ).catch(err => {
            toast.error(err);
        })

    }
    const deleteItem = () => {
        DocumentType.bean.State = 2;
       //console.log(DocumentType.bean);
        editaItem();
        modalConfirm.close();
    }




    const head = [
        { title: 'ID', property: 'IDDocumentType', visible: true, mask: MASK_NUMBER },
        { title: 'Nombre', property: 'Name', visible: true },
        { title: 'Descripción', property: 'Description', visible: true },
        { title: 'Codigo', property: 'Code', visible: true },
        { title: 'Tipo proceso', property: 'TypeProcess', visible: true },
        { title: 'Documento', property: 'JSONTemplate', visible: true },



    ]

    const iconButtonsItem = [
        { nombre: 'Agregar', visible: single.canCreate(), icono: 'ri-file-add-line', evento: create }
    ]
    const listaBotones = () => {
        const botones = [

            { titulo: 'Editar', icono: 'ri-edit-2-line', visible: single.canEdit(), evento: () => { modalEditar.setModal({ ...modalEditar.modal, "view": true }) } },
            { titulo: 'Eliminar', icono: 'ri-delete-bin-line', visible: single.canDelete(), evento: () => { setModalC({ ...modalC, "view": true }) } },
            // { titulo: 'Beneficiarios', icono: 'ri-edit-2-line', visible: single.canDelete(), evento: () => { setbeneficiario({ ...beneficiario, "view": true }) } },

        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }



    return (
        <BasicPage titulo="Documentos Tipo del bean">
            <div className="row">

                <div className="col-md-12">

                    <BasicTable body={lista} head={head} rowSet={DocumentType.setBean} listButtons={listaBotones()} iconButtons={iconButtonsItem}></BasicTable>
                    <Modal modal={modalEditar.modal} updateModal={modalEditar.setModal} eventModal={editaItem}>
                        <>
                            <div className="row">
                                <div className="col-md-12">
                                    <SelectTrdsID edit={false} onChange={(e: any) => { DocumentType.setBean({ ...DocumentType.bean, "IDLnTRD": e.IDLn }); }} trdIDLn={DocumentType.bean.IDLnTRD} ></SelectTrdsID>
                                </div>
                                <div className="col-md-12">
                                    <SelectorProcessTypeConstants idSelector={DocumentType.bean.TypeProcess} onChange={(e: any) => { DocumentType.setBean({ ...DocumentType.bean, "TypeProcess": e }); }} />
                                </div>
                                <div className="col-md-6">
                                    <label>Nombre</label>
                                    <input type="text" name="Name" className="form-control" value={DocumentType.bean.Name} onChange={DocumentType.handleChange} />

                                </div>
                                <div className="col-md-6">
                                    <label>Porcentaje de Administración</label>
                                    <input type="number" name="Code" className="form-control" value={DocumentType.bean.Code} onChange={DocumentType.handleChange} />
                                </div>

                                <div className="col-md-12">
                                    <label>Descripción</label>
                                    <textarea name="Description" className="form-control" value={DocumentType.bean.Description} onChange={DocumentType.handleChange} />
                                </div>
                                <div className="col-md-6 ">
                                    <label>Documento plantilla?</label>
                                    <BasicSwitch estado={swich} eventChange={(e) => { setSwich(e) }} />
                                </div>
                                {swich && (
                                    <>
                                        <FileInput
                                            Media={DocumentType.bean.Media}
                                            MediaContext={DocumentType.bean.MediaContext}
                                            directory='requirement'
                                            label='Cargar un archivo'
                                            onCreate={({ MediaContext, Media }: any) => { DocumentType.setBean({ ...DocumentType.bean, "JSONTemplate": JSON.stringify({ MediaContext: MediaContext, Media: Media }) }); }}
                                        ></FileInput>

                                    </>
                                )}


                            </div>

                        </>
                    </Modal>
                    <ModalConfirm modal={modalC} updateModal={setModalC} eventModal={deleteItem}>
                        <strong>Eliminar item</strong>
                    </ModalConfirm>
                </div>

            </div>
        </BasicPage>
    )
}