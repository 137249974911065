import { useEffect, useState } from "react"
import { AccountEditor } from "../../../../../editor/AccountEditor"
import { SelectAccountantConcept } from "../SelectAccountantConcept"
import useDataBean from "../../../../../../core/services/dataBean/useDataBean"
import { _UBLInvoice } from "../../../../../../core/services/dataBean/EntityCatalog"
import { FileInput } from "../../../../../theme/Component/FileInput"
import { BasicSwitch } from "../../../../../theme/Component/BasicSwitch"
import { InputDate } from "../../../../../theme/Input/InputDate"
import { BasicButton } from "../../../../../theme/Component/BasicButton"
import { PrintJson } from "../../../../../theme/Component/PrintJson"
import { BasicTable, MASK_DATE, MASK_MONEY, MASK_NUMBER } from "../../../../../theme/Component/BasicTable"
import { ListMenu } from "../../../../../theme/Component/ListMenu"
import { Modal } from "../../../../../theme/Component/Modal"
import { FileService } from "../../../../../../core/services/FileService"
import { HistoryUBLInvoice } from "../HistoryUBLInvoice"
import { Singleton } from "../../../../../../core/services/Singleton"
import { CreateUBLInvoiceItems } from "./CreateUBLInvoiveItem"
import { SelectAccountantConceptForCashDesk } from "./SelectAccountantConceptForCashDesk"

interface Props {
    _onChange2?: Function;
    _idAccount?: number;
    entry?: "Accounting" | "CashReimbursement";
}

export const CreateUBLInvoice = ({ _onChange2, _idAccount, entry }: Props) => {

    const _ubl = useDataBean(_UBLInvoice);
    const fileService = new FileService();
    const single = Singleton.getInstance();

    const [idconcept, setIDConcep] = useState<number>(0);
    const [file, setFile] = useState<any>({});
    const [row, setRow] = useState<any>({});
    const [urlFile, setUrlFile] = useState<string | null>(null);
    const [donwloadURL, setDonwloadURL] = useState<string>(null);
    const [obs, setObs] = useState<string>(" ");
    const [fileType, setFileType] = useState<string>(null);

    const [modalB, setModalB] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: false, format: 'modal-lg' });
    const [modalC, setModalC] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modal, setModal] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-xl' });

    useEffect(() => {
        if (donwloadURL) {
            const fetchPdf = async () => {
                try {
                    const data = await fileService.getPdfAsBase642(donwloadURL);
                    setUrlFile(data);
                } catch (error) {
                    console.error('Error loading PDF:', error);
                }
            };

            fetchPdf();
        }
    }, [donwloadURL]);

    useEffect(() => {
        return () => { _ubl.setBean({}) }
    }, [])

    useEffect(() => {
        _ubl.setBean({ ..._ubl.bean, "Media": file.Media, "MediaContext": file.MediaContext });
    }, [file])

    useEffect(() => {
        _ubl.setBean({ ..._ubl.bean, "idConcept": idconcept });
    }, [idconcept])

    useEffect(() => {
        setFileType(row.MediaContext?.type || row.Media?.split('.').pop())
        setDonwloadURL(fileService.getUrlFile(row.MediaContext, row.Media))
    }, [row])

    const createUBLInvoice = () => {
        _ubl.instance.createUBLInvoice(
            idconcept,
            _idAccount ?? _ubl.bean.IDAccount,
            _ubl.bean.invoiceNumber + "",
            _ubl.bean.isDocumentSupport,
            _ubl.bean.issueDate,
            _ubl.bean.accountantDate,
            _ubl.bean.Media,
            _ubl.bean.MediaContext,
            single.getAccountID(),
            obs,
        ).then(
            (resp: any) => {
                setRow({});
               //console.log(resp);

                let temp = [];

                temp.push({
                    DataBeanProperties: resp
                });

               //console.log(temp);
                _ubl.setLista(temp);
            }
        )
    }

    const getUblInvoice = () => {
        _ubl.instance.getUblInvoice(row.IDUBLInvoice, row.IssueDate)
            .then((resp) => {
               //console.log("Respuesta de la factura:", resp);
                const updatedList = _ubl.lista.map((item) => {
                    if (item.DataBeanProperties.IDUBLInvoice === resp.IDUBLInvoice) {
                        return { DataBeanProperties: { ...item.DataBeanProperties, ...resp } };
                    }
                    return item;
                });

                _ubl.setLista(updatedList);
            })
            .catch((err) => {
               //console.log("Error al obtener la factura:", err);
            });
    };


    const head = [
        { title: 'ID Factura', property: 'IDUBLInvoice', visible: true, mask: MASK_NUMBER },
        { title: 'Nit - Proveedor', property: 'SupplierName', visible: true },
        { title: 'No Factura', property: 'InvoiceID', visible: true },
        { title: 'Líneas Factura', property: 'LineCountNumeric', visible: true },
        { title: 'Valor Total', property: 'TotalInvoiceAmount', visible: true, mask: MASK_MONEY },
        { title: 'Impuesto', property: 'TaxAmount', visible: true },
        { title: 'Descuentos', property: 'DiscountAmount', visible: true },
        { title: 'Fecha Factura', property: 'IssueDate', visible: true, mask: MASK_DATE },
        { title: 'Entrada Almacén', property: 'EAFMnemonic', visible: true, mask: MASK_DATE },
        { title: 'Consecutivo Entrada', property: 'EAFConsecutive', visible: true },
        { title: 'Fecha Entrada', property: 'EAFDate', visible: true, mask: MASK_DATE },
        { title: 'Fecha Procesamiento', property: 'Since', visible: true, mask: MASK_DATE },
        { title: 'Estado Factura', property: 'DocumentStateName', visible: true },
        { title: 'Tipo Factura', property: 'SourceTypeName', visible: true },
        { title: 'Estado EAF', property: 'EAFDocumentStateName', visible: true },
        { title: 'Estado Salidas', property: 'OutputCostStateName', visible: true },
        { title: 'Documento Soporte', property: 'IsDocumentSupport', visible: true },
        { title: 'Concepto de Ingreso', property: 'AccountantConceptName', visible: true },
        { title: 'Cuenta Contable', property: 'AccountantIDName', visible: true },
        { title: 'Cuenta Puente Ingreso Bienes', property: 'CreditAccountantIDName', visible: true },
        { title: 'Pertenece a Proceso', property: 'ProcessTypeName', visible: true },
    ]

    const listaBotones = () => {
        const botones = [
            { titulo: 'Abrir Factura', icono: 'ri-file-list-line', visible: true, evento: () => { setModal({ ...modal, "name": "Abrir archivo", "view": true, "format": `${fileType !== "pdf" && "jpg" && "png" ? "modal-sm" : "modal-xl"}` }) } },
            { titulo: 'Ver eventos en la Factura', icono: 'ri-history-line', visible: true, evento: () => { setModalC({ ...modalC, "view": true, "name": "Ver eventos en la Factura" }) } },
            { titulo: 'ver Items de la Factura', icono: 'ri-list-ordered', visible: true, evento: () => { setModalB({ ...modalB, "view": true, "name": `Items de la Factura ${row.IDUblInvoice}` }) } },
        ]

        return (
            <ListMenu listaBotones={botones} />
        )
    }

    const setRowBean = (bean: any) => {
        if (_onChange2) {
            _onChange2(bean)
        }
        setRow(bean)
    }

    return (
        <>
            <div className="row">
                <div className="col-md-6">
                    {entry == "CashReimbursement" ?
                        <SelectAccountantConceptForCashDesk idSelector={idconcept} onChange={setIDConcep} />
                        :
                        <SelectAccountantConcept idSelector={idconcept} onChange={setIDConcep} />
                    }
                </div>
                <div className="col-md-6">
                    <AccountEditor disabled={(typeof _idAccount === "number" && _idAccount >= 0)} idAccount={_idAccount ?? _ubl.bean.IDAccount} label="Tercero" onChange={({ IDAccount }: any) => { _ubl.setBean({ ..._ubl.bean, "IDAccount": IDAccount }) }} />
                </div>
                <div className="col-md-6">
                    <FileInput Media={_ubl.bean.Media} MediaContext={_ubl.bean.MediaContext} label="Archivo Factura" directory="temp" onCreate={(e) => { setFile(e) }} />
                </div>
                <div className="col-md-6">
                    <label>Número de Factura</label>
                    <input type="text" className="form-control" name="invoiceNumber" value={_ubl.bean.invoiceNumber} onChange={_ubl.handleChange} />
                </div>
                <div className="col-md-4 mt-4">
                    <BasicSwitch label="Documento Soporte" estado={_ubl.bean.isDocumentSupport} eventChange={(e) => { _ubl.setBean({ ..._ubl.bean, "isDocumentSupport": e }) }} />
                </div>
                <div className="col-md-4">
                    <InputDate notDate label="Fecha de Generación de Factura" name="issueDate" value={_ubl.bean.issueDate} setDate={(e) => { _ubl.setBean({ ..._ubl.bean, "issueDate": e }) }} />
                </div>
                <div className="col-md-4">
                    <InputDate notDate label="Fecha de Registro Contable" name="accountantDate" value={_ubl.bean.accountantDate} setDate={(e) => { _ubl.setBean({ ..._ubl.bean, "accountantDate": e }) }} />
                </div>
                {file.Media &&
                    <div className="row">
                        <div className="col-md-12">
                            <label>Observaciones</label>
                            <textarea name="obs" className="form-control" value={obs} onChange={(e) => { setObs(e.target.value) }} ></textarea>
                        </div>
                        <div className="col-md-6">
                            <BasicButton icon="ri-save-2-line" eventClick={createUBLInvoice}>Crear</BasicButton>
                        </div>
                    </div>
                }
            </div>
            <div className="row">
                <div className="col-md-12">
                    <BasicTable body={_ubl.lista} head={head} rowSet={(e) => { setRowBean(e) }} listButtons={listaBotones()} />
                </div>
            </div>

            {modalB.view &&
                <Modal modal={modalB} updateModal={setModalB} >
                    <CreateUBLInvoiceItems idUBLInvoice={row.IDUBLInvoice} issueDate={row.IssueDate} _onChange={() => { getUblInvoice(); }} />
                </Modal>
            }

            {modal.view &&
                <Modal modal={modal} updateModal={setModal} eventModal={() => { }} >
                    <div className="row">
                        <div className="col-md-12">
                            <div className="iframe-container">

                                {fileType ?
                                    <>
                                        <PrintJson json={fileType} />
                                        {fileType === 'pdf' && (
                                            urlFile ? (
                                                <iframe name="pdfviwer" src={urlFile} onError={(e) => console.error('Error loading PDF:', e)}></iframe>
                                            ) : (
                                                <p>Cargando PDF...</p>
                                            )
                                        )}
                                        {fileType === `"jpg"` || fileType === "png" &&
                                            (
                                                <img src={urlFile} alt="Image Preview" width="100%" />
                                            )
                                        }
                                        {fileType !== "pdf" && "jpg" && "png" &&
                                            <>
                                                <a href={donwloadURL} target='_blank'>Clic aquí para descargar el archivo {row.Media}</a>
                                            </>
                                        }
                                    </>
                                    :
                                    <>
                                        No se pudo cargar el Documento
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </Modal>
            }

            {modalC.view &&
                <Modal modal={modalC} updateModal={setModalC} >
                    <HistoryUBLInvoice _idUBLInvoice={row.IDUBLInvoice} _isseDate={row.IssueDate} />
                </Modal>
            }
        </>
    )
}