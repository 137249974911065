import { useEffect, useState } from "react";
import { _CashRequestMoney } from "../../../../core/services/dataBean/EntityCatalog"
import useDataBean from "../../../../core/services/dataBean/useDataBean"
import { Singleton } from "../../../../core/services/Singleton";
import { BasicTable, MASK_MONEY, MASK_NUMBER } from "../../../theme/Component/BasicTable";
import { ListMenu } from "../../../theme/Component/ListMenu";
import { Modal } from "../../../theme/Component/Modal";

interface Props {
    idCashRequest: number;
    cashRequestDate: any;
}

export const CashRequestMoney = ({ idCashRequest, cashRequestDate }: Props) => {

    const { lista, setLista, bean, setBean, instance, handleChange } = useDataBean(_CashRequestMoney);
    const single = Singleton.getInstance();

    const [modal, setModal] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: false, format: "modal-lg", });

    useEffect(() => {
        getLista()
    }, [idCashRequest])

    const getLista = () => {
        instance.getCashRequestMoneyCatalog(idCashRequest, cashRequestDate).then(
            (resp) => {
                setLista(resp)
            }
        ).catch(
            err => {
               console.error(err)
            }
        )
    }

    const getDocument = () => {
        instance.getPdfAccountantDocument(bean.IDDocument, " ", bean.DocDate, single.getAccountID()).then(
            (resp) => {
               //console.log(resp);
            }
        ).catch(
            err => {
               console.error(err);
            }
        )
    }

    const head = [
        { title: 'ID', property: 'IDCashRequestMoney', mask: MASK_NUMBER },
        { title: 'Tipo', property: 'TypeName' },
        { title: 'Valor de la Operación', property: 'value', mask: MASK_MONEY },
        { title: 'Documento', property: 'DocumentConsecutive' },
        { title: 'Estado del Documento', property: 'DocStateName' },
        { title: 'Realizado por', property: 'EmployeeName' },
        { title: 'Observaciones', property: 'Observations' },
    ]

    const listaBotones = () => {
        const botones = [
            { titulo: "Ver Documento", icono: "ri-eye-line", visible: true, evento: () => { getDocument() }, },
        ];
        return <ListMenu listaBotones={botones} />;
    };

    return (
        <>
            <BasicTable listButtons={listaBotones()} body={lista} head={head} rowSet={setBean} />

            <Modal modal={modal} updateModal={setModal}>
                <div className="row">
                </div>
            </Modal>
        </>
    )
}