

import { useState, useEffect } from 'react';
import useDataBean from '../../core/services/dataBean/useDataBean';
import { _ManagementType } from '../../core/services/dataBean/EntityCatalog';
import { PrintJson } from '../theme/Component/PrintJson';
import { BasicTable } from '../theme/Component/BasicTable';
import { toast } from 'react-toastify';
import { BasicMultiSelectTable } from '../theme/Component/BasicMultiSelecTable';

interface Props {
    idLn: any;
    _onChange?: Function;
}

export const ManagementType = ({ idLn, _onChange }: Props) => {

    const { bean, setBean, instance, lista, setLista } = useDataBean(_ManagementType);
    const [listaResult, setListaResult] = useState<number[]>([])
    const [listaResultList, setListaResultList] = useState<any[]>([])

    useEffect(() => {
        getListaConstant();
        if (idLn > 0) {
            getLista();
        }
    }, [idLn])

    const getLista = () => {
        const props = {
            State: 1,
            IDFunctionIDLn: idLn
        }
        instance.getManagementTypeRender(null, props).then(
            (resp) => {
                if (resp.length > 0) {

                    const types = JSON.parse(resp[0].DataBeanProperties.JsonType);
                    if (types.length > 0) {
                        setListaResult(types)
                    } else {
                        toast.error("Sin gestiones Activas")
                    }
                } else {
                    toast.error("sin resultados")
                }
            }
        ).catch(
            err => {
               console.error(err)
            }
        )
    }

    const getListaConstant = () => {
        instance.getManagementTypeConstants(null).then(
            (resp) => {
                setLista(resp)
               //console.log(resp)
            }
        ).catch(
            err => {
               console.error(err)
            }
        )
    }

    useEffect(() => {
        const selectedObjects = lista
            .filter((item) => listaResult.includes(item.DataBeanProperties.Value))
            .map((item) => ({ DataBeanProperties: item.DataBeanProperties }));
        setListaResultList(selectedObjects);
    }, [lista, listaResult]);

    useEffect(() => {
        if (_onChange) {
            const selectedIds = listaResultList.map(item => item.DataBeanProperties.Value);
           //console.log(selectedIds)
            _onChange(selectedIds);
        }
    }, [listaResultList])

    const head = [
        { title: 'Tipo de Gestion', property: 'Property' },
    ]

    return (
        <>
        
            <BasicMultiSelectTable keyId='Value' body={lista} bodyResult={listaResultList} head={head} updateValues={({ bodyResult }) => { setListaResultList(bodyResult) }} />
        </>
    )
}