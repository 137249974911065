import { useState, useEffect } from 'react';
import { ESTATE_ADQUISITIONITEM } from '../../Constants';


interface Props {
    idSelector: number;
    onChange: any;
    label?: string;
    filter?: any[];
}

export const SelectAdquisitionItemConstan = ({ idSelector, onChange, label, filter }: Props) => {

    const [id, setID] = useState(NaN);
    const [lista, setLista] = useState(ESTATE_ADQUISITIONITEM);

    useEffect(() => {
        setID(idSelector);
    }, [idSelector])

    useEffect(() => {
        let ll = lista;
        if (filter) {
            ll = ll.filter(ele => filter.includes(ele.id));
        }
        setLista(ll)
    }, [filter])

    const setValue = (e: any) => {
        setID(parseInt(e));
        onChange(e);
    }

    return (
        <>
            <label>{label ?? "Estado de la Necesidad"}</label>
            <select className="form-select" value={id} onChange={(e) => { setValue(e.target.value) }}>
                <option value={NaN} >...</option>
                {lista.map((item: any, index: number) => {
                    return (
                        <option key={index} value={item.id} >{item.name}</option>
                    )
                })}
            </select>
        </>
    )
}