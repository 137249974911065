import { useEffect, useState } from "react";
import { BasicTable } from "../../../../theme/Component/BasicTable";
import { Singleton } from "../../../../../core/services/Singleton";
import { ContractService } from "../../../../../core/services/ContractService";
import { DataBeanProperties, ObjectValue } from '../../../../../core/services/model/server-response.interface';
import { TAX_IMPUESTO, TAX_RETENCION } from "../ContracInterface";

interface Props{
    idUBLInvoice:number;
    issueDate:Date|string;
    Type:number;
}
export const UBLInvoiceTaxList=({idUBLInvoice,issueDate,Type}:Props)=>{

    const [lista,setLista]=useState<any>([]);
    const [row,setRow] = useState<any>({});
    const single = Singleton.getInstance();
    const service = new ContractService();

    useEffect(()=>{
        if(Type === TAX_RETENCION)
            getUBLTaxItemWithHoldingTaxCatalog();
        if(Type === TAX_IMPUESTO)
            getUBLTaxItemCatalog();

    },[idUBLInvoice])

    const getUBLTaxItemWithHoldingTaxCatalog=()=>{
        single.spinner(true);

        service.getUBLTaxItemWithHoldingTaxCatalog(idUBLInvoice,issueDate).subscribe(
            (resp:any)=>{
                if(resp.DataBeanProperties.ObjectValue)
                {
                    setLista(resp.DataBeanProperties.ObjectValue);
                }
                single.spinner(false);
            }
        );

    }
    const getUBLTaxItemCatalog=()=>{
        single.spinner(true);

        service.getUBLTaxItemCatalog(idUBLInvoice,issueDate).subscribe(
            (resp:any)=>{
                if(resp.DataBeanProperties.ObjectValue)
                {
                    setLista(resp.DataBeanProperties.ObjectValue);
                }
                single.spinner(false);
            }
        );

    }

    const head = [
        {title:'ID',property:'IDTaxSchemeValue',visible:true},
        {title:'Valor Retenciones',property:'TaxAmount',visible:true},
        {title:'Base',property:'TaxableAmount',visible:true},        
        {title:'ID Tipo Impuesto',property:'TaxCategoryID',visible:true},        
        {title:'Tipo Impuesto',property:'TaxCategoryName',visible:true},        
        {title:'Tarifa Aplicada',property:'TaxCategoryPercent',visible:true},        
    ]

    return(
        <div className="row">
            <div className="col">
                <BasicTable body={lista} head={head} rowSet={setRow} ></BasicTable>
            </div>
        </div>

    )
}