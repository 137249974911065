import React, { useEffect, useState } from "react";
import { BpmService } from "../../../../../core/services/BpmService";
import { Singleton } from "../../../../../core/services/Singleton";
import { BasicTable } from "../../../../theme/Component/BasicTable";
import { ListMenu } from "../../../../theme/Component/ListMenu";
import { Modal } from "../../../../theme/Component/Modal";
import { SelectorJsonPropertyType } from "../../Componentes/SelectorJsonPropertyType";
import { SelectorBPMStructsForQuery } from "../../Componentes/SelectorBPMStructsForQuery";
import { SelectorJsonPropertyDocType } from "../../Componentes/SelectorJsonPropertyDocType";
import { Selectortipodeformulario } from "../../Componentes/Selectortipodeformulario";
import { SelectorForm } from "../../Componentes/SelectorForm";
import { SelectorJsonClass } from "../../Componentes/SelectorJsonClass";
import { SelectorJson } from "../../Componentes/SelectorJson";
import { SelectorPropertiesForQuery } from "../../Componentes/SelectorPropertiesForQuery";
import { useDashboardStore } from "../../../../pages/hooks/useDashboardStore";

interface JsonProperty {
    IDJsonProperty?: number;
    PropertyPath: string;
    PropertyIndex: number;
    TypeName: string;
    DataBeanClass: string;
    UserValue: string;
    BPMDataBeanClass: string;
    BPMDataBeanProperty: string;
    DocumentType: number;
    IDFormSrc: number;
    IDJsonServiceClass: number;
    IDJsonService: number;
    IDJsonServiceSrc: number;
    Type: number;
}

interface Props {
    IDJService: number;
}

export const JsonPropertie = ({ IDJService }: Props) => {
    const single = Singleton.getInstance()
    const { setLoading } = useDashboardStore();
    const service = new BpmService();
    const [bean, setBean] = useState<JsonProperty>({ // Proporciona valores iniciales para las propiedades clave
        PropertyPath: "",
        PropertyIndex: 0,
        TypeName: "",
        DataBeanClass: "",
        UserValue: "",
        BPMDataBeanClass: "",
        BPMDataBeanProperty: "",
        DocumentType: 0,
        IDFormSrc: 0,
        IDJsonServiceClass: 0,
        IDJsonService: IDJService,
        Type: 0,
        IDJsonServiceSrc: 0,
    });
    const [form, setForm] = useState<number | undefined>();
    const [lista, setLista] = useState<JsonProperty[]>([]);
    const [modal, setModal] = useState<any>({
        name: 'Configurar Fuente de Datos',
        tabIndex: -1,
        view: false,
        btnClose: true,
        btnSubmit: true,
        format: 'modal-md',
    });

    useEffect(() => {
        getLista();
    }, [IDJService]);

    const setRowBean = (rowBean: JsonProperty) => { // Pasa el objeto 'bean' como argumento
        setBean(rowBean);
    };

    const getLista = () => {
        setLoading(true);
        service.getJsonPropertyCatalog(IDJService).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue) {
                    setLista(resp.DataBeanProperties.ObjectValue);
                }
                setLoading(false);
            },
        );
    };

    const updateProperty = () => {
       //console.log(bean);
        setModal({ ...modal, ['view']: false });
        setLoading(true);
        service.updateJsonProperty(bean).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue) {
                    getLista();
                    setLoading(false);
                }
                single.update(bean);
            });
    };

    const listaBotones = () => {
        const botones = [
            { titulo: 'Editar', icono: 'ri-file-list-line', visible: true, evento: () => { setModal({ ...modal, view: true }) } },
        ];
        return (
            <ListMenu listaBotones={botones} />
        );
    };

    const head = [
        { title: 'ID de la propiedad', property: 'IDJsonProperty' },
        { title: 'Ruta de la variable en el servicio json', property: 'PropertyPath' },
        // { title: 'Indice en la lista', property: 'PropertyIndex' },
        { title: 'Tipo de Objeto', property: 'TypeName' },
        { title: 'Tipo de Fuente de Dato', property: 'PropertyClass' },
        { title: 'Valor de la Propiedad', property: 'PropertyValue' },
        // { title: 'Acciones', property: 'PropertyIndex' },
    ];

    return (
        <>
            <div className="row">
                <div className="col">
                    <BasicTable body={lista} head={head} listButtons={listaBotones()} rowSet={setRowBean}></BasicTable>
                </div>
            </div>

            <Modal modal={modal} updateModal={setModal} eventModal={(updateProperty)}>
                {modal.view &&
                    <div className="row">
                        <div className="col-md-12">
                            <SelectorJsonPropertyType idSelector={bean.Type} onChange={(e: any) => setBean({ ...bean, 'Type': e })}></SelectorJsonPropertyType>
                        </div>
                        {bean.Type == 2 &&
                            <>
                                <div className="col-md-6">
                                    <SelectorBPMStructsForQuery idSelector={bean.BPMDataBeanClass} onChange={(e: any) => setBean({ ...bean, 'BPMDataBeanClass': e })}></SelectorBPMStructsForQuery>
                                </div>
                                <div className="col-md-6">
                                    <SelectorPropertiesForQuery dataBeanClass={bean.BPMDataBeanClass} idSelector={bean.BPMDataBeanProperty} onChange={(e: any) => setBean({ ...bean, 'BPMDataBeanProperty': e })}></SelectorPropertiesForQuery>
                                </div>
                            </>
                        }
                        {bean.Type == 3 &&
                            <>
                                <div className="col-md-12">
                                    <SelectorJsonPropertyDocType idSelector={bean.DocumentType} onChange={(e: any) => setBean({ ...bean, 'DocumentType': parseInt(e) })}></SelectorJsonPropertyDocType>
                                </div>
                                {/* formulario JSON */}
                                {bean.DocumentType == 6 &&
                                    <>
                                        <div className="col-md-6">
                                            <Selectortipodeformulario idSelector={form} onChange={(e: any) => setForm(e)}></Selectortipodeformulario>
                                        </div>
                                        <div className="col-md-6">
                                            <SelectorForm idForm={form} idSelector={bean.IDFormSrc} onChange={(e: any) => setBean({ ...bean, 'IDFormSrc': e })}></SelectorForm>
                                        </div>
                                    </>
                                }
                                {/* Serivicio Json */}
                                {bean.DocumentType == 4 &&
                                    <>
                                        <div className="col-md-6">
                                            <SelectorJsonClass idSelector={bean.IDJsonServiceClass} onChange={(e: any) => setBean({ ...bean, 'IDJsonServiceClass': e })}></SelectorJsonClass>
                                        </div>
                                        <div className="col-md-6">
                                            <SelectorJson idJsonServiceClass={bean.IDJsonServiceClass} idSelector={bean.IDJsonServiceSrc} onChange={(e: any) => setBean({ ...bean, 'IDJsonServiceSrc': e })}></SelectorJson>
                                        </div>
                                    </>
                                }
                            </>
                        }
                        {bean.Type == 4 &&
                            <>
                                <div className="col-md-12">
                                    <label className="form-label">Valor definido por el usuario</label>
                                    <textarea className="form-control" value={bean.UserValue} onChange={(e) => { setBean({ ...bean, ['UserValue']: e.target.value }) }}></textarea>
                                </div>
                            </>
                        }
                    </div>
                }
            </Modal>
        </>
    );
};
