import { AccountEditor } from "../editor/AccountEditor";
import { useState, useEffect } from 'react';
import { ModalConfirm } from "../theme/Component/ModalConfirm";


interface Props {
    planBean: any;
    updateLista:Function;
}
export const ListParticipantes = ({ planBean,updateLista }: Props) => {


    const [bean, setBean] = useState<any>(planBean);
    const [acc, setAcc] = useState<any>({});
    const [lista, setLista] = useState<any>(planBean.Participants ? JSON.parse(planBean.Participants) : '[]');
    const [modalConf, setModalConf] = useState<any>({ name: 'Confirmacion', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });

    useEffect(()=>{
        setLista(planBean.Participants ? JSON.parse(planBean.Participants) : []);
    },[planBean])
   

  

    const agregarFuncionario = (account: any) => {
       //console.log(account);
        let contiene = 0;

        lista.forEach((acc: any) => {
            if (acc.IDAccount == account.IDAccount) {
                contiene++;
            }

        });

        if (contiene === 0 && account.IDAccount) {
            let l:any = lista;
            l.push({
                IDAccount: account.IDAccount,
                EntityName: account.EntityName
            })
           //console.log("lista", l);
            setAcc({});
            setLista(l);
            updateLista(l);
            
        }
        


    }

    const removeFile=()=>{
        
       //console.log("elimiando ",bean.IDAccount);
        
        const nuevaLista = lista.filter((objeto:any) => objeto.IDAccount !== bean.IDAccount);
        updateLista(nuevaLista);
        setLista(nuevaLista);
        setModalConf({...modalConf,"view":false});
    }

    return (
        <div className="row">
            <div className="col-md-12">

                <AccountEditor label="Funcionario" idAccount={0} onChange={agregarFuncionario} />

            </div>
            <div className="col-md-12 mt-4">
                <ul className="list-group">
                   {lista.map((acc:any,index:number)=>{
                    return(
                        <li key={index} className="list-group-item" aria-disabled="true">
                        <div className="d-flex align-items-center">
                            <div className="flex-shrink-0">
                                <img src="assets/images/users/user-dummy-img.jpg" alt="" className="avatar-xs rounded-circle" />
                            </div>
                            <div className="flex-grow-1 ms-2">
                                {acc.EntityName}
                            </div>
                            <div className="flex-shrink-0">

                                <span className="text-danger cursor" onClick={()=>{setBean(acc);setModalConf({...modalConf,"view":true})}} >Eliminar</span>
                            </div>
                        </div>
                    </li>
                    )
                   })}

                </ul>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <ModalConfirm modal={modalConf} updateModal={setModalConf} eventModal={removeFile} >
                        Eliminar Funcionario
                    </ModalConfirm>
                </div>
            </div>
        </div>
    )
}