import { useEffect, useState } from "react";
import { FinanceService } from '../../../../core/services/FinanceService';
import { ButtonProjectID } from './ButtonProjectID';
import { ProjectIDChilds } from './ProjectIDChilds';
import { YearSelector } from "../../../../core/shared/inputs/YearSelector";
import { BpmService } from "../../../../core/services/BpmService";
import { useDashboardStore } from "../../../pages/hooks/useDashboardStore";
import { _single } from "../../../../core/services/dataBean/EntityCatalog";

interface Props {

  id: number | null;
  edit: boolean;
}
export const ProjectID = ({ id, edit }: Props) => {

  const service = new FinanceService();
  const bpmservice = new BpmService();

  const { setLoading } = useDashboardStore();
  const [lista, setLista] = useState([]);
  const [year, setYear] = useState<number>(undefined);

  const [idchild, setIDchild] = useState(0);

  useEffect(() => {
    getSeparator();
    let year1: number = new Date().getFullYear();
    bpmservice.getSystemPropertyValue('project_year').subscribe(
      (resp: any) => {
        if (resp.DataBeanProperties.ObjectValue) {
          let y = resp.DataBeanProperties.ObjectValue;


          if (y) {
            try {
              year1 = parseInt(y);

              setYear(year1);
            } catch (error) {

            }
          }
        }
      }
    );



  }, []);

  useEffect(() => {
    getListatree();

  }, [year]);

  const getListatree = () => {

    let tmp = _single.getCacheItem('treeIniciativa');
    if (tmp) {
      setLista(tmp);
    } else {

      setLoading(true);
      service.getTreeForProjectID(year).subscribe((resp: any) => {
        if (resp.DataBeanProperties.ObjectValue) {
          setLista(resp.DataBeanProperties.ObjectValue.Childs);
          _single.setCacheItem('treeIniciativa', resp.DataBeanProperties.ObjectValue.Childs);
        }
        setLoading(false);
      });
    }

  };

  const getSeparator = () => {
    service.getProjectIDSeparator().subscribe(
      (resp: any) => {
        let separador = resp.DataBeanProperties.ObjectValue;
        localStorage.setItem('separador', separador);
      }
    );
  }


  return (
    <>
      {/* <BasicPage titulo="Árbol Proyectos"> */}

      <div className="row">
        <div className="col-md-6 mb-2">
          {edit &&
            <YearSelector onChange={setYear} value={year}></YearSelector>
          }

        </div>
        <div className="col-md-12">
          {lista.length === 0 && edit &&
            <ButtonProjectID year={year} bean={{}} updateEvent={getListatree}></ButtonProjectID>
          }
        </div>
        <div className="col-md-12">
          <ul className="list-group">
            {lista.length > 0 &&
              lista.map((item: any, index: number) => {
                return (
                  <li key={index} className="list-group-item itemh" >
                    {edit &&
                      <ButtonProjectID
                        year={year}
                        bean={item.EnvolvedObject.DataBeanProperties}
                        updateEvent={() => { getListatree(); }}
                      />
                    }
                    <strong className={idchild === item.EnvolvedObject.DataBeanProperties.IDLn ? 'cursor treeselect' : 'cursor'} onClick={() => {
                      setIDchild(item.EnvolvedObject.DataBeanProperties.IDLn);
                      localStorage.setItem('ProjectID', JSON.stringify(item.EnvolvedObject.DataBeanProperties));
                    }}>
                      <strong style={{ marginLeft: '15px' }}>{item.EnvolvedObject.DataBeanProperties.ProjectViewCode} </strong>
                      <small>{item.EnvolvedObject.DataBeanProperties.Name}</small>
                    </strong>
                    <span className="badge bg-success ms-1">{item.ChildrenCount}</span>
                    {(idchild === item.EnvolvedObject.DataBeanProperties.IDLn) &&
                      <ProjectIDChilds year={year} edit={edit} idChild={item.EnvolvedObject.DataBeanProperties.IDLn} />
                    }
                  </li>
                );
              })}
          </ul>
        </div>
      </div>
      {/* </BasicPage> */}
    </>
  );
};