import { BpmService } from "../../../../../core/services/BpmService";
import { useDashboardStore } from "../../../../pages/hooks/useDashboardStore";

interface Props {
    idJasonS: number;
    output?: any;
    response?: any;
}

export const JsonPrototype = ({ idJasonS, output, response }: Props) => {

    const { setLoading } = useDashboardStore();
    const service = new BpmService();

    const test = () => {
        setLoading(true);
        service.getResponseValueForJsonService(idJasonS).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue) {
                   //console.log('testeo exitoso')
                } else {
                   //console.log('testeo no exitoso')
                }
                setLoading(false);
            }
        );
    }

    const copiarAlPortapapeles = () => {

    };

    return (
        <>
            <div className="row">
                <div className="col">
                    <button onClick={copiarAlPortapapeles} className="btn btn-primary">Copiar JSON</button>
                </div>
                <div className="col-md-6" />
                <div className="col">
                    <button onClick={test} className="btn btn-success">Iniciar Testeo</button>
                </div>
                <div className="col-md-12">
                    <label className="form-label">Descripción</label>
                    <textarea disabled className="form-control" defaultValue={response ? response : 'null'} ></textarea>
                </div>
            </div>
        </>
    )
}