import { useEffect, useState } from "react";
import { _EmployeeDiscount } from "../../../../core/services/dataBean/EntityCatalog";
import useDataBean from "../../../../core/services/dataBean/useDataBean";
import { Singleton } from "../../../../core/services/Singleton";
import { ListMenu } from "../../../theme/Component/ListMenu";
import { BasicPage } from "../../../theme/Component/BasicPage";
import { BasicTable, MASK_NUMBER } from "../../../theme/Component/BasicTable";
import { Modal } from "../../../theme/Component/Modal";
import { AccountEditor } from "../../../editor/AccountEditor";
import { YearSelector } from "../../../../core/shared/inputs/YearSelector";
import { PayrollMonthsConstantsEditor } from "../../ParametrosTalentoHumano/EmployeeBonus/Editors/PayrollMonthsConstants.editor";
import { useDashboardStore } from "../../../pages/hooks/useDashboardStore";
import { toast } from "react-toastify";
import { ModalConfirm } from "../../../theme/Component/ModalConfirm";
import { InputDate } from "../../../theme/Input/InputDate";
import { EmployeeDiscountTypeEditor } from "../../ParametrosTalentoHumano/EmployeeDiscountType/EmployeeDiscountType.editor";
import { TaxHoldeditor } from "../../ParametrosTalentoHumano/EmployeePayments/EmployeePayments.editor";
import { BasicButton } from "../../../theme/Component/BasicButton";

export const EmployeeDiscount = () => {

    const EmployeeDiscount = useDataBean(_EmployeeDiscount);
    const { setLoading } = useDashboardStore();
    const single = Singleton.getInstance();
    const [IdAcoount, setIdAcoount] = useState<number>(null)
    const [Montedit, setMontedit] = useState<number>(null)
    const [year, setYear] = useState<number>(single.getCurrenYear())
    const [lista, setLista] = useState<any>([])
    const [modal, setModal] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalC, setModalC] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });

    // useEffect(() => {
    //     if (year > 0 && Montedit !== null && Montedit >= 0) {
    //         getLista();
    //     }
    // }, [IdAcoount, year, Montedit]);

    const validateGetLista = () => {
        if (!year) {
            toast.error("Año no valido")
        } else if (Montedit == null || Montedit == undefined) {
            toast.error("Mes no valido")
        } else {
            getLista();
        }
    }

    const getLista = () => {
        setLoading(true);
        EmployeeDiscount.instance.getOtherDiscounts(IdAcoount, year, Montedit, null).then(
            (resp: any) => {
                if (resp) {
                    setLista(resp)
                   //console.log(resp)
                }
                setLoading(false);
            }
        )

    }

    const create = () => {
        EmployeeDiscount.setBean({

            IDEmployee: single.getAccountID()

        });
        setModal({ ...modal, "view": true });
    }
    const deleteItem = () => {
        EmployeeDiscount.instance.deleteEmployeeDiscount(EmployeeDiscount.bean.IDDiscount).then(
            (resp: any) => {
                getLista();
                setModalC({ ...modalC, "view": false });
            }
        )

    }
    const editaItem = () => {
       //console.log(EmployeeDiscount.bean);
        EmployeeDiscount.update().then(
            (resp: any) => {
                getLista();
                setModal({ ...modal, "view": false });
            }
        ).catch(err => {
            toast.error(err);
        })

    }

    const head = [
        { title: 'ID', property: 'IDDiscount', visible: true, mask: MASK_NUMBER },
        { title: 'Aportes y Descuentos Programados', property: 'IDType', visible: true, },
        { title: 'Empleado', property: 'AccountName', visible: true },
        { title: 'Concepto', property: 'Concept', visible: true },
        { title: 'Descripción', property: 'Description', visible: true },
        { title: 'Valor', property: 'Value', visible: true, mask: MASK_NUMBER },
        { title: 'Fecha del descuento', property: 'FromDate', visible: true },
        { title: 'Beneficiario', property: 'BeneficiaryName', visible: true },
        { title: 'No Crédito', property: 'LoanNumber', visible: true, mask: MASK_NUMBER },
        { title: 'Valor Desembolso', property: 'LoanValue', visible: true, mask: MASK_NUMBER },
        { title: 'Valor Pendiente', property: 'LoanLeft', visible: true, mask: MASK_NUMBER },
        { title: 'Fecha Desembolso', property: 'Since', visible: true },
        { title: 'Fecha Final', property: 'DateLeft', visible: true },
        { title: 'Vr Vencido', property: 'DefeatedValue', visible: true },
        { title: 'Vr Cuota', property: 'QuotaValue', visible: true },
        { title: 'Cuotas', property: 'TotalTerm', visible: true },
        { title: 'Cuota Actual', property: 'ActualTerm', visible: true, mask: MASK_NUMBER },
        { title: 'Base Excenta', property: 'ExemptBase', visible: true },
        { title: 'Factor de Retención', property: 'IDTaxHold', visible: true },
        { title: 'Base de Retención', property: 'TaxHoldValue', visible: true },
        { title: 'Funcionario ', property: 'EmployeeName', visible: true },
    ]

    const iconButtonsItem = [
        { nombre: 'Agregar', visible: single.canCreate(), icono: 'ri-file-add-line', evento: create }
    ]
    const listaBotones = () => {
        const botones = [

            { titulo: 'Editar', icono: 'ri-edit-2-line', visible: single.canEdit(), evento: () => { setModal({ ...modal, "view": true }) } },
            { titulo: 'Eliminar', icono: 'ri-delete-bin-line', visible: single.canDelete(), evento: () => { setModalC({ ...modalC, "view": true }) } },

        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }


    return (
        <>


            <BasicPage titulo="Ingresar Otros Egresos Laborales">
                <div className="row">
                    <div className="col-md-4">  <AccountEditor label="Beneficiario" idAccount={IdAcoount} onChange={(e) => { setIdAcoount(e.IDAccount) }} /></div>
                    <div className="col-md-3"><YearSelector value={year} onChange={setYear} /></div>
                    <div className="col-md-3"> <PayrollMonthsConstantsEditor idSelector={Montedit} onChange={(e) => { setMontedit(e) }} /></div>
                    <div className="col-2 d-flex">
                        <div className="row d-flex align-content-end">
                            <BasicButton icon="ri-search-line" eventClick={validateGetLista} >Buscar</BasicButton>
                        </div>
                    </div>
                </div>
                <BasicTable listButtons={listaBotones()} head={head} body={lista} rowSet={EmployeeDiscount.setBean} iconButtons={iconButtonsItem} />
            </BasicPage>
            <Modal modal={modal} updateModal={setModal} eventModal={editaItem}>
                <div className="row">
                    <div className="col-md-6">
                        <EmployeeDiscountTypeEditor idSelector={EmployeeDiscount.bean.IDType} onChange={(e) => { EmployeeDiscount.setBean({ ...EmployeeDiscount.bean, "IDType": e }) }} />
                    </div>
                    <div className="col-md-6">
                        <AccountEditor label="Empleado" idAccount={EmployeeDiscount.bean.IDAccount} onChange={(e) => { EmployeeDiscount.setBean({ ...EmployeeDiscount.bean, "IDAccount": e.IDAccount }) }}></AccountEditor>
                    </div>
                    <div className="col-md-6">
                        <label>Concepto</label>
                        <input type="text" name="Concept" className="form-control" value={EmployeeDiscount.bean.Concept} onChange={EmployeeDiscount.handleChange} />
                    </div>
                    <div className="col-md-6">
                        <label>Valor</label>
                        <input type="number" name="Value" className="form-control" value={EmployeeDiscount.bean.Value} onChange={EmployeeDiscount.handleChange} />
                    </div>
                    <div className="col-md-6">
                        <InputDate label="Fecha del descuento" name="FromDate" value={EmployeeDiscount.bean.FromDate} setDate={(e: any) => { EmployeeDiscount.setBean({ ...EmployeeDiscount.bean, 'FromDate': e }) }} ></InputDate>
                    </div>
                    <div className="col-md-6">
                        <label>Description</label>
                        <input type="text" name="Description" className="form-control" value={EmployeeDiscount.bean.Description} onChange={EmployeeDiscount.handleChange} />
                    </div>
                    <div className="col-md-6">
                        <AccountEditor label="Beneficiario" idAccount={EmployeeDiscount.bean.IDBeneficiary} onChange={(e) => { EmployeeDiscount.setBean({ ...EmployeeDiscount.bean, "IDBeneficiary": e.IDAccount }) }}></AccountEditor>
                    </div>
                    <div className="col-md-6">
                        <label>No Crédito</label>
                        <input type="number" name="LoanNumber" className="form-control" value={EmployeeDiscount.bean.LoanNumber} onChange={EmployeeDiscount.handleChange} />
                    </div>
                    <div className="col-md-6">
                        <label>Valor Desembolso</label>
                        <input type="number" name="LoanValue" className="form-control" value={EmployeeDiscount.bean.LoanValue} onChange={EmployeeDiscount.handleChange} />
                    </div>
                    <div className="col-md-6">
                        <label>Valor Pendiente</label>
                        <input type="number" name="LoanLeft" className="form-control" value={EmployeeDiscount.bean.LoanLeft} onChange={EmployeeDiscount.handleChange} />
                    </div>
                    <div className="col-md-6">
                        <InputDate label="Fecha Final" name="DateLeft" value={EmployeeDiscount.bean.DateLeft} setDate={(e: any) => { EmployeeDiscount.setBean({ ...EmployeeDiscount.bean, 'DateLeft': e }) }} ></InputDate>
                    </div>
                    <div className="col-md-6">
                        <label>Vr Vencido</label>
                        <input type="number" name="DefeatedValue" className="form-control" value={EmployeeDiscount.bean.DefeatedValue} onChange={EmployeeDiscount.handleChange} />
                    </div>
                    <div className="col-md-6">
                        <label>Vr Cuota</label>
                        <input type="number" name="QuotaValue" className="form-control" value={EmployeeDiscount.bean.QuotaValue} onChange={EmployeeDiscount.handleChange} />
                    </div>
                    <div className="col-md-6">
                        <label>Cuotas</label>
                        <input type="number" name="TotalTerm" className="form-control" value={EmployeeDiscount.bean.TotalTerm} onChange={EmployeeDiscount.handleChange} />
                    </div>
                    <div className="col-md-6">
                        <label>Cuota Actual</label>
                        <input type="number" name="ActualTerm" className="form-control" value={EmployeeDiscount.bean.ActualTerm} onChange={EmployeeDiscount.handleChange} />
                    </div>
                    <div className="col-md-6">
                        <label>Base Excenta</label>
                        <input type="number" name="ExemptBase" className="form-control" value={EmployeeDiscount.bean.ExemptBase} onChange={EmployeeDiscount.handleChange} />
                    </div>
                    <div className="col-md-6">
                        <label>Factor de Retención</label>
                        <TaxHoldeditor idSelector={EmployeeDiscount.bean.IDTaxHold} onChange={(e) => { EmployeeDiscount.setBean({ ...EmployeeDiscount.bean, "IDTaxHold": e }) }} />
                    </div>
                    <div className="col-md-6">
                        <label>Base de Retención</label>
                        <input type="text" name="TaxHoldValue" className="form-control" value={EmployeeDiscount.bean.TaxHoldValue} onChange={EmployeeDiscount.handleChange} />
                    </div>
                </div>
            </Modal>

            <ModalConfirm modal={modalC} updateModal={setModalC} eventModal={deleteItem}>
                <strong>Eliminar item</strong>
            </ModalConfirm>


        </>
    )
}