import { useState } from "react";
import { CardTaps } from "../../../../theme/Component/CardTaps";
import { PrintJson } from "../../../../theme/Component/PrintJson";
import { ConfigAdminFunctionalID } from "./ConfigurarAdministrador";
import { ConfigAdminPersonal } from "./ConfigAdminPersonal";
import { CostingCenter } from "./ModificarCentro";
import { ListPersonal } from "./ListPersonal";

interface Props {
    functionaID: any;
}
export const FunctionalIDOptions = ({ functionaID }: Props) => {


    let taps = [
        { id: 0, name: 'Jefe de Área', disable: false },
        { id: 1, name: 'Administrador de Personal', disable: false },
        { id: 2, name: 'Centro de Costo', disable: false },
        { id: 3, name: 'Lista de Personal', disable: false },
    ];

    const [indexTap, setIndexTap] = useState(0);



    return (
        <div className="row">
            <div className="col-md-12">
                <CardTaps index={indexTap} updateIndex={setIndexTap} taps={taps}>
                    {/* {indexTap} */}
                    {/* <PrintJson json={functionaID} /> */}
                    {indexTap === 0 &&
                        <>


                            <ConfigAdminFunctionalID functionaID={functionaID} />
                        </>

                    }
                    {indexTap === 1 &&
                        <ConfigAdminPersonal functionaID={functionaID} />
                    }
                    {indexTap === 2 &&
                        <CostingCenter functionaID={functionaID} />
                    }
                    {indexTap === 3 &&
                        <ListPersonal functionaID={functionaID} />
                    }

                </CardTaps>
            </div>
            <div className="col">
            </div>
        </div>
    )

}