import { useState, useEffect } from 'react';
import { BpmService } from '../../../../core/services/BpmService';



interface Props {
    idSelector: number;
    onChange: any;
    titulo?: string;
}

export const SelectorProcedureType = ({ titulo, idSelector, onChange }: Props) => {

    const service = new BpmService();
    const [lista, setLista] = useState([]);
    const [id, setID] = useState(undefined);

    useEffect(() => {
        getLista();
        if (idSelector > 0) {
            setID(idSelector)
           //console.log(idSelector)
        }
    }, [idSelector])

    useEffect(() => {
        onChange(id);
    }, [id])

    const setValue = (e: any) => {
        setID(parseInt(e));
        onChange(id);
    }

    const getLista = () => {
        service.getProcedureTypeList(null).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue) {
                    setLista(resp.DataBeanProperties.ObjectValue);
                }
               //console.log("DataAcces", lista);
            }
        );
    }

    return (
        <>
            <label>{titulo}</label>
            <select className="form-control" value={id} onChange={(e) => { setValue(e.target.value) }}>
                <option value={undefined}>...</option>
                {lista &&
                    lista.map((item: any, index: number) => {
                        return (
                            <>
                                <option key={index} value={item.DataBeanProperties.Value} >{item.DataBeanProperties.Property}</option>
                            </>)
                    })
                }
            </select>
        </>
    )
}