import { useEffect, useState } from "react";
import { FinanceService } from '../../../../core/services/FinanceService';
import { Singleton } from '../../../../core/services/Singleton';
import { ButtonProjectID } from './ButtonProjectID';
import { useDashboardStore } from "../../../pages/hooks/useDashboardStore";
import { _single } from "../../../../core/services/dataBean/EntityCatalog";

interface Props {
    idChild: number;
    edit: boolean;
    year: number;
}
export const ProjectIDChilds = ({ idChild, edit, year }: Props) => {

    const service = new FinanceService();
    const { setLoading } = useDashboardStore();
    const [lista, setLista] = useState([]);
    const [name, setName] = useState('');
    const [bean, setBean] = useState({});
    const [showchild, setShowchild] = useState(false);
    const [idchild, setIDchild] = useState(0);

    useEffect(() => {
        if (idchild != null && idchild != undefined)
            getListatree(idChild);
        else
            setLista([]);

    }, [])

    const getListatree = (idArbol: any) => {

        let tmp = _single.getCacheItem('treeIniciativa_' + idArbol);
        if (tmp) {
            setLista(tmp);
        } else {
            setLoading(true);
            service.loadProjectIDTree(idArbol).subscribe((resp: any) => {
                setLoading(false);
                if (resp.DataBeanProperties.ObjectValue) {
                    let arbol: any = resp.DataBeanProperties.ObjectValue.EnvolvedObject.DataBeanProperties;
                    setName(arbol.Name);
                    setLista(resp.DataBeanProperties.ObjectValue.Childs);
                    _single.setCacheItem('treeIniciativa_' + idArbol, resp.DataBeanProperties.ObjectValue.Childs);

                }
            });

        }

    };

    return (
        <>

            <ul className="list-group">
                {
                    lista.length > 0 &&
                    lista.map((item: any) => {
                        return (
                            <li className="list-group-item itemh" style={{ border: '0' }} key={item.EnvolvedObject.DataBeanProperties.IDLn}>

                                {edit &&
                                    <ButtonProjectID
                                        year={year}
                                        bean={item.EnvolvedObject.DataBeanProperties}
                                        updateEvent={() => { getListatree(idChild); }}
                                    />
                                }
                                <strong className={idchild === item.EnvolvedObject.DataBeanProperties.IDLn ? 'cursor treeselect' : 'cursor'} onClick={() => {

                                    setIDchild(item.EnvolvedObject.DataBeanProperties.IDLn);
                                    localStorage.setItem('ProjectID', JSON.stringify(item.EnvolvedObject.DataBeanProperties));
                                }}>
                                    <strong style={{ marginLeft: '15px' }}>{item.EnvolvedObject.DataBeanProperties.ProjectViewCode} </strong>
                                    <small>{item.EnvolvedObject.DataBeanProperties.Name}</small>

                                </strong>
                                <span className="badge bg-success ms-1">{item.ChildrenCount}</span>
                                {(idchild === item.EnvolvedObject.DataBeanProperties.IDLn) &&
                                    <ProjectIDChilds year={year} idChild={idchild} edit={edit} />
                                }
                            </li>
                        )
                    })
                }
            </ul>
        </>
    )
}