import { useEffect, useState } from 'react';
import { Modal } from '../../../theme/Component/Modal';
import { ProjectID } from './ProjectID';
import useDataBean from '../../../../core/services/dataBean/useDataBean';
import { _ProjectID } from '../../../../core/services/dataBean/EntityCatalog';



interface Props {
    onChange: any;
    edit: boolean;
    projectIDLn?: number;
    label?: string;
}
export const SelectProjectID = ({ onChange, edit, projectIDLn, label }: Props) => {

    const { instance } = useDataBean(_ProjectID)

    const [modal, setModal] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-xl' });
    const [valor, setValor] = useState('');

    useEffect(() => {
        if (projectIDLn) {
            getProjectIDLn();
        }
    }, [projectIDLn])

    const getProjectIDLn = () => {
        instance.getFunctionalIDByKey(projectIDLn).then(
            (resp) => {
                onChange(resp);
                setValor(`${resp.ProjectViewCode} - ${resp.Name}`)
            }
        ).catch(
            err => {
               console.error(err);
            }
        )
    }

    const getItem = () => {

        let bean: any = JSON.parse(localStorage.getItem('ProjectID') || '{}');
        onChange(bean);
        setValor(bean.ProjectViewCode + " " + bean.Name);

        setModal({ ...modal, "view": false });
    }

    return (
        <>
            <label>{label ?? "Iniciativa Estratégica"}</label>
            <div className="form-control text-wrap" style={{ minHeight: '35px' }} onClick={() => setModal({ ...modal, "view": true })}>
                {valor ?
                    valor
                    :
                    <span>Seleccionar...</span>
                }
            </div>

            <Modal modal={modal} updateModal={setModal} eventModal={getItem}>
                <div style={{ maxHeight: '60vh', overflow: 'auto' }}>
                    <ProjectID edit={edit} id={null} />
                </div>
            </Modal>

        </>
    )
}