import { DataBean } from "../DataBean";

export class PaymentAccount extends DataBean {



    public async getPaymentAccountList(processType: any, paymentType: any, idAccount: any, year: any, month: any, state: any, idBankingEntity: any, page: any, recordsPerPage: any): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "com.advantage.shared.Report_getPaymentAccountList_Number_Number_Number_Number_Number_Number_Number_Number_Number", [processType, paymentType, idAccount, year, month, state, idBankingEntity, page, recordsPerPage]);
    }


    public async getPaymentAccountItemByPaymentAccount(idPaymentAccount: any): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_getPaymentAccountItemByPaymentAccount_Number", [idPaymentAccount]);
    }


    public async createEgressDocumentByCashDeskAccount(idCashDeskAccount: any, idPaymentAccount: any, accountantDate: any, description: any, idEmployee: any, sessionID: any, officeID: any): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "com.quickdataerp.bean.accountant.AccountantDocument_createEgressDocumentByCashDeskAccount_Number_Number_java.util.Date_String_Number_Number_Number", [idCashDeskAccount, idPaymentAccount, accountantDate, description, idEmployee, sessionID, officeID]);
    }


    public async createEgressDocumentByBankingAccount(idBankingAccount: any, idPaymentAccount: any, accountantDate: any, description: any, idEmployee: any, sessionID: any, officeID: any): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "com.quickdataerp.bean.accountant.AccountantDocument_createEgressDocumentByBankingAccount_Number_Number_java.util.Date_String_Number_Number_Number", [idBankingAccount, idPaymentAccount, accountantDate, description, idEmployee, sessionID, officeID]);
    }

}