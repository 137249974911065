
import { BsChevronCompactDown, BsEyeFill, BsFillArrowRightCircleFill, BsFillEmojiHeartEyesFill } from 'react-icons/bs'
import { ReactNode, useEffect, useState } from 'react'
import { FaSyncAlt, FaTimes } from 'react-icons/fa';
import { Singleton } from '../../../core/services/Singleton';
import { BpmService } from '../../../core/services/BpmService';
import { Modal } from '../../theme/Component/Modal';
import { CourseTopicmodal } from './CourseTopicmodal';
import { CursoOverview } from './CursoOverview';





export const MisSubsCourse = () => {
    const [loading, setLoading] = useState(false);
    const single = Singleton.getInstance();
    const service = new BpmService();
    const [lista, setLista] = useState([]);
    const [modal, setModal] = useState<any>({ name: 'Temas del curso', tabIndex: -1, view: false, btnClose: false, btnSubmit: false, format: 'modal-lg' });
    const [user, setUser] = useState<any>({});
    const [bean, setBean] = useState<any>({});
    const [selectedCourse, setSelectedCourse] = useState<any>(null); // Nuevo estado para curso seleccionado
    const [activeTab, setActiveTab] = useState('tab1');
    // const idBean = 'IDCourseAccount';

    useEffect(() => {
        setUser(single.getAccountID());
        getLista();
    }, [])

    const getLista = () => {

        single.spinner(true);
        let x = {
            IDAccount: single.getAccountID()

        }
        service.getCourseByAccount(null, null, x).subscribe(
            (resp: any) => {

                if (resp.DataBeanProperties.ObjectValue) {
                    setLista(resp.DataBeanProperties.ObjectValue);
                }
                single.spinner(false);
            }
        );
    }
    const handleViewCourse = (course: any) => {
        setSelectedCourse(course); // Guardar el curso seleccionado
        setActiveTab('tab2'); // Cambiar a la pestaña 'tab2'
    };


    return (
        <>
            <div className="container-fluid">
                {/* <div className="row">
                    <div className="col-md-12">
                        <h3> </h3>


                    </div>

                </div> */}
            </div>

            <div className="container-fluid">
                <div className="row">

                    <div>
                        <ul className="nav nav-tabs">
                            <li className="nav-item">
                                <button
                                    className={`nav-link ${activeTab === 'tab1' ? 'active' : ''}`}
                                    onClick={() => setActiveTab('tab1')}
                                >
                                    <strong>MIS CURSOS SUSUCRITOS</strong>

                                </button>
                            </li>
                            <li className="nav-item">
                                <button
                                    className={`nav-link ${activeTab === 'tab2' ? 'active' : ''}`}
                                    onClick={() => setActiveTab('tab2')}
                                ><strong>CURSO</strong>

                                </button>
                            </li>

                        </ul>

                        <div className="tab-content mt-3">
                            {activeTab === 'tab1' && <div className="row">   {Array.isArray(lista) && lista.map((propiedad: any, index: number) => {
                                return (
                                    <>
                                        <div className="col-md-4" key={index}>
                                            <div className="card" style={{ padding: '1rem', marginTop: '20px' }}>
                                                <div key={propiedad.id}>


                                                    <div className="card-header d-flex justify-content-between align-items-center">


                                                    </div>
                                                    <div className=" row card-body-curso p-2" >
                                                        <span><strong style={{ color: '#003366' }}>{propiedad.DataBeanProperties.NameCourse}</strong> </span>
                                                        <div className="col-md-8"><strong style={{ color: '#003366' }}>SUSCRIPCIÓN:</strong></div>
                                                        <div className="col-md-4"><strong style={{ color: '#488C04' }}> {propiedad.DataBeanProperties.StateName}</strong>

                                                        </div>
                                                        <span><strong style={{ color: '#003366' }}>INICIO:</strong> {propiedad.DataBeanProperties.Upto}</span>
                                                    </div>
                                                </div>
                                                <hr></hr>
                                                <div className="container">
                                                    <div className="row">
                                                        <div className="col-md-8">
                                                            <span><strong style={{ color: '#003366' }}>INGRESAR</strong></span>
                                                        </div>
                                                        <div className="col-md-4 ">
                                                            <button
                                                                className="new button"
                                                                onClick={() => handleViewCourse(propiedad)} // Cambiar a 'tab2' con curso seleccionado
                                                            >
                                                                <BsEyeFill />
                                                            </button>
                                                        </div>

                                                    </div>
                                                </div>

                                            </div>


                                        </div>
                                    </>

                                )
                            })
                            }

                            </div>}
                            {activeTab === 'tab2' && selectedCourse && (
                                <div>
                                    <h4>Detalles del {selectedCourse.DataBeanProperties.NameCourse}:</h4>
                                    <ul>
                                        {/* <li><strong>Nombre del curso:</strong> {selectedCourse.DataBeanProperties.NameCourse}</li> */}

                                        <li><strong>Estado:</strong > <strong style={{ color: '#488C04' }}> {selectedCourse.DataBeanProperties.StateName}</strong></li>
                                        <li><strong>Inicio:</strong > {selectedCourse.DataBeanProperties.Upto}</li>
                                        <li><strong>Descripción:</strong> {selectedCourse.DataBeanProperties.Description}</li>
                                        {/* Agrega más detalles según los datos del curso */}

                                        <CursoOverview IDCourse={selectedCourse.DataBeanProperties.IDCourse} />
                                    </ul>
                                </div>
                            )}

                        </div>
                    </div>




                </div>
            </div>



        </>
    )
}
