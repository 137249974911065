import { useEffect, useState } from "react";
import { PrintJson } from "../../theme/Component/PrintJson";
import useDataBean from "../../../core/services/dataBean/useDataBean";
import { _RemoteAbstractAction, _single } from "../../../core/services/dataBean/EntityCatalog";
import { BasicButton } from "../../theme/Component/BasicButton";
import { toast } from "react-toastify";

interface Props {
    invoke: any;    
    isComplete:Function;
}
export const ExecBackAction = ({ invoke ,isComplete }: Props) => {

    const {  instance } = useDataBean(_RemoteAbstractAction);
    const [retry,setRetry] = useState(false);

    useEffect(()=>{
        senddata();
    },[])
    
    useEffect(()=>{
        if(retry === true)
        {
            senddata();
        }
    },[retry])


    const senddata=()=>{

            setRetry(false);
            instance.setRemoteAbstractActionProperties(invoke.IDApplicationInvoker, {}, _single.getAccountID()).then(
                (resp: any) => {
                   //console.log("RESPONSE BACK => ");
                   //console.log(resp);
                    if(resp)
                    {
                        if(resp.MESSAGE != '')
                            toast.warn(resp.MESSAGE);
                        if(resp.RESPONSE_ID === 1)
                            isComplete(true);
                        setRetry(false);
                    }
                }
            ).catch(err => { console.error(err);setRetry(true)})
           
    }

    return (
        <div className="row">
            <div className="col-md-12">
                <div className="spinner-border text-primary" role="status">
                    <span className="sr-only"> Loading...</span>
                </div>
                
            </div>
            {/* <div className="col-md-12">
                <PrintJson json={invoke} />
            </div> */}
        </div>
    )
}