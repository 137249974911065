import { DataFilter5 } from "../../RemoteAbstractAction/components/com/quickdataerp/uicomponents/reports/DataFilter5";
import { ReportViewer } from "../../RemoteAbstractAction/components/com/quickdataerp/uicomponents/reports/ReportViewer";

interface Props {
    idApplicationInvoker:number;
    slide:any;
    setData: any;
}
export const RemoteComponentProxy = ({idApplicationInvoker, slide, setData }: Props) => {

    const enviarForm=(form:any)=>{
        setData(form);
    }

    const renderComponent = (name: string) => {
        switch(name)
        {
            case 'com.quickdataerp.uicomponents.reports.DataFilter5':
                return <DataFilter5 idApplicationInvoker={idApplicationInvoker} properties={slide.PROPERTIES_STRUCTURE} data={slide.PROPERTIES} lastStep={slide.LASTSTEP} setData={enviarForm}/>            
            case 'com.quickdataerp.uicomponents.reports.ReportViewer':
                return <ReportViewer idApplicationInvoker={idApplicationInvoker} properties={slide.PROPERTIES_STRUCTURE} data={slide.PROPERTIES} lastStep={slide.LASTSTEP} setData={enviarForm}/>            
            default :
                return <p></p>

        }
    }

    return (
        <div className="row">
            <div className="col">
                {slide.COMPONENT_NAME ?
                    <>
                        {renderComponent(slide.COMPONENT_NAME)}
                    </>
                    :
                    <p>Componente no encontrado : {slide.COMPONENT_NAME}</p>
                }
            </div>
        </div>
    )
}