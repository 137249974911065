import { toast } from "react-toastify";
import { ContractService } from "../../core/services/ContractService";
import { Singleton } from "../../core/services/Singleton";
import { useEffect, useState } from "react";
import { BasicButton } from "../theme/Component/BasicButton";
import { PrintJson } from "../theme/Component/PrintJson";
import useDataBean from "../../core/services/dataBean/useDataBean";
import { _SupplierPaymentOrderItem } from "../../core/services/dataBean/EntityCatalogVdos";

interface Props {
    data: any;
    event: any;
}
export const AdquisitionArticleEditor = ({ data, event }: Props) => {

    const single = Singleton.getInstance();
    const service = new ContractService();
    const supplierItem = useDataBean(_SupplierPaymentOrderItem);
    const [contract, setContract] = useState<any>({});
    const [article, setArticle] = useState<any>({});
    const [idArticle, setIDArticle] = useState<number>(0);

    const [list, setList] = useState<any>([]);

    useEffect(() => {
        getAdquisitionContractCatalogPorPropiedades();        
    }, [data])
    // useEffect(() => {
    //     if (contract.IDAdquisitionItem) {
    //         getAdquisitionArticleByAdquisitionItem();
    //     }
    // }, [contract])
    useEffect(() => {
        if (idArticle > 0) {
            list.forEach((element: any) => {

                if (element.DataBeanProperties.IDAdquisitionArticle === idArticle) {
                    setArticle(element.DataBeanProperties);
                }

            });
        }
    }, [idArticle])

    const getAdquisitionContractCatalogPorPropiedades = () => {

       //console.log("aqui articulos");
        if(data.Orden)
        supplierItem.instance.getSupplierPaymentOrderItemCatalogForAdquisitionContract(data.Orden.IDSupplierPaymentOrder, data.Orden.SupplierPaymentOrderDate).then(
            (resp: any) => {
               //console.log(resp);
                setList(resp);

                if (resp.length > 0) {
                    let item = resp[0].DataBeanProperties;
                    if (data.IDAdquisitionArticle > 0)
                        setIDArticle(data.IDAdquisitionArticle);
                    else
                        setIDArticle(item.IDAdquisitionArticle);
                }
            }
        );

        // single.spinner(true);
        // service.getAdquisitionContractCatalogPorPropiedades({ IDAdquisitionContract: data.IDAdquisitionContract }, null).subscribe(
        //     (resp: any) => {
        //         if (resp.DataBeanProperties.ObjectValue) {
        //             let temp = resp.DataBeanProperties.ObjectValue;
        //             if (temp.length > 0) {
        //                 setContract(temp[0].DataBeanProperties);
        //             }
        //         }
        //         single.spinner(false);
        //     }
        // );


    }
    // const getAdquisitionArticleByAdquisitionItem = () => {

    //     single.spinner(true);
    //     supplierItem.instance.getSupplierPaymentOrderItemCatalogForAdquisitionContract(data.IDSupplierPaymentOrder, data.SupplierPaymentOrderDate).then(
    //         (resp: any) => {
    //             setList(resp);
    //            //console.log(resp);

    //             if (resp.length > 0) {
    //                 let item = resp[0].DataBeanProperties;
    //                 if (data.IDAdquisitionArticle > 0)
    //                     setIDArticle(data.IDAdquisitionArticle);
    //                 else
    //                     setIDArticle(item.IDAdquisitionArticle);
    //             }
    //         }
    //     );
    // }

    const addUBLItemInvoiceToAdquisitionArticle = (e: any) => {
        supplierItem.instance.addUBLItemInvoiceToAdquisitionArticle(article.IDSupplierPaymentOrderItem, article.SupplierPaymentOrderDate, data.IDUBLItemInvoice).then(
            (resp: any) => {
                if (resp) {
                    event(e);
                    toast.success("Articulo relacionado.");
                }
            }
        ).catch(err => toast.error(err))
        // single.spinner(true);
        // service.addUBLItemInvoiceToAdquisitionArticle().subscribe(
        //     (resp: any) => {
        //        //console.log(resp);

        //         if (resp.DataBeanProperties.ObjectValue) {
        //             event(e);
        //         }
        //         else if (resp.DataBeanProperties.ErrorMessage) {
        //             toast.error(resp.DataBeanProperties.ErrorMessage);                
        //         }
        //         single.spinner(false);
        //     }
        // );       
    }
    const releaseUBLItemInvoiceFromAdquisitionArticle = () => {
        supplierItem.instance.releaseUBLItemInvoiceFromAdquisitionArticle(data.IDUBLInvoice, data.IssueDate, data.IDUBLItemInvoice).then(
            (resp: any) => {
                if (resp) {
                    event(data);
                    toast.success("Articulo removido.");
                }
            }
        ).catch(err => toast.error(err))
        // single.spinner(true);
        // service.releaseUBLItemInvoiceFromAdquisitionArticle(data.IDUBLInvoice, data.IssueDate, data.IDAdquisitionContract, data.AdquisitionContractDate, data.IDUBLItemInvoice).subscribe(
        //     (resp: any) => {
        //         if (resp.DataBeanProperties.ObjectValue) {
        //             event(data);
        //         }
        //         else if (resp.DataBeanProperties.ErrorMessage) {
        //             toast.error(resp.DataBeanProperties.ErrorMessage);
        //         }
        //         single.spinner(false);
        //     }
        // );
    }



    return (
        <div className="row">
            {/* <PrintJson json={data} /> */}



            <div className="col-md-12">
                <label><strong>Articulo</strong></label>
                <select className="form-select" value={idArticle} onChange={(e) => { setIDArticle(parseInt(e.target.value)) }}>
                    {list.map((item: any, index: number) => {
                        return (
                            <option key={index} value={item.DataBeanProperties.IDAdquisitionArticle}>
                                {item.DataBeanProperties.IDAdquisitionArticle} - {item.DataBeanProperties.Description}
                            </option>
                        )
                    })}
                </select>


            </div>
            <div className="col-md-12 mt-4 " style={{ fontWeight: '200' }} >
                {idArticle > 0 &&
                    <div className="row">
                        <div className="col-md-3">
                            <strong>Presupuesto</strong><br />
                            <small>{article.BudgetJsconCode} - {article.BudgetName}</small>
                        </div>
                        <div className="col-md-3">
                            <strong>Tipo Artículo</strong><br />
                            <small>{single.formatMoney(article.TypeName)}</small>
                        </div>
                        <div className="col-md-3">
                            <strong>Valor</strong><br />
                            <small>{single.formatMoney(article.PaymentValue)}</small>
                        </div>

                        {/* <PrintJson json={article}/> */}
                        {data.IDAdquisitionArticle > 0 &&
                            <div className="col-md-4">
                                <BasicButton icon="ri-delete-bin-2-line" clase="danger" eventClick={releaseUBLItemInvoiceFromAdquisitionArticle}>Eliminar relación</BasicButton>
                            </div>


                        }
                        <div className="col-md-4">
                            <BasicButton icon="ri-checkbox-circle-line" eventClick={() => { addUBLItemInvoiceToAdquisitionArticle(article) }}>Seleccionar</BasicButton>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}