import { BasicPage } from "../theme/Component/BasicPage"
import { BasicTable } from '../theme/Component/BasicTable';
import { Singleton } from '../../core/services/Singleton';
import { ContractService } from '../../core/services/ContractService';
import { useState, useEffect } from 'react';
import { ModalConfirm } from '../theme/Component/ModalConfirm';
import { Modal } from '../theme/Component/Modal';
import { ListMenu } from "../theme/Component/ListMenu";
import { useDashboardStore } from "../pages/hooks/useDashboardStore";

export const RequirementType = () => {

    const single = Singleton.getInstance()
    const { setLoading } = useDashboardStore();
    const service = new ContractService();
    const [lista, setLista] = useState([]);
    const [listaType, setListaType] = useState([]);
    const [idTipo, setIDTipo] = useState(0);
    const [bean, setBean] = useState<any>({});
    const [modal, setModal] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalConfirm, setModalConfirm] = useState<any>({ name: 'Confirmar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });



    const idBean = 'IDRequirementType';

    const head = [
        { title: 'ID', property: idBean },
        { title: 'Tipo Requerimiento', property: 'LegalRequirementTypeName' },
        { title: 'Nombre', property: 'Name' },
        { title: 'Descripción', property: 'Description' },
        { title: 'Año', property: 'Year' },
        { title: 'Mes', property: 'Month' },
        { title: 'Fecha', property: 'Since' },
    ]

    useEffect(() => {
        getLegalRequirementTypeConstants();
    }, [])
    useEffect(() => {
        getLista();
    }, [idTipo])

    const setRowBean = (bean: any) => {
       //console.log("Row : ", bean);
        setBean(bean);

    }

    const getLegalRequirementTypeConstants = () => {

        setLoading(true);
        service.getLegalRequirementTypeConstants().subscribe(
            (resp: any) => {

               //console.log(resp);

                if (resp.DataBeanProperties.ObjectValue) {
                    setListaType(resp.DataBeanProperties.ObjectValue);
                }

                setLoading(false);
            }
        );
    }
    const getLista = () => {

        setLoading(true);
        service.getRequirementTypeCatalog(idTipo, null, null).subscribe(
            (resp: any) => {
                setLista([]);

                if (resp.DataBeanProperties.ObjectValue.DataBeanProperties.List) {
                    setLista(resp.DataBeanProperties.ObjectValue.DataBeanProperties.List);
                }

                setLoading(false);
            }
        );
    }

    const agregarItem = () => {



        let b = {
            LegalRequirementType: idTipo,
            Name: '',
            Description: '',
            State: 1,

            Rules: JSON.stringify([]),
            Since: single.getCurrenDate()
        }

        setBean(b);
        setModal({ ...modal, ['view']: true });

    }
    const editarItem = () => {

        setModal({ ...modal, ['view']: true });

    }
    
    const eliminarItem = () => {

        setLoading(true);
        service.deleteRequirementType(bean[idBean]).subscribe(
            (resp: any) => {
                setLoading(false);
                if (resp) {
                    getLista();
                }
                single.delete(bean[idBean], bean);
            }
        );
        setModalConfirm({ ...modalConfirm, ['view']: false });

    }
    const updateItem = () => {

        setModal({ ...modal, ['view']: false });

        setLoading(true);
        service.updateRequirementType(bean).subscribe(
            (resp: any) => {
                setLoading(false);
                if (resp.DataBeanProperties.ObjectValue) {
                    getLista();
                }
                if (bean[idBean]) {

                    single.update(bean);

                }
                else {
                    single.create(bean);
                }
            });

    }

    const iconButtonsItem = [
        { nombre: 'Agregar', visible: single.canCreate(), icono: 'ri-file-add-line', evento: agregarItem }
    ]

    const listaBotones = () => {
        const botones = [
            { titulo: 'Editar', icono: 'ri-edit-2-line', visible: single.canEdit(), evento: () => { setModal({ ...modal, "view": true }); } },
            { titulo: 'Eliminar', icono: 'ri-eraser-line', visible: single.canDelete(), evento: () => { setModalConfirm({ ...modalConfirm, "view": true }); } },
        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }

    return (
        <>
            <BasicPage titulo="Tipos de Requerimiento">

                <div className="row">

                    <div className="col-md-12">
                        <label>Tipo Requerimiento Legal</label>
                        <select className="form-control" value={idTipo} onChange={(e) => { setIDTipo(parseInt(e.target.value)) }}>
                            <option value={0}>...</option>
                            {listaType.map((item: any) => {
                                return (
                                    <option value={item.DataBeanProperties.Value}>{item.DataBeanProperties.Property}</option>
                                )
                            })}
                        </select>
                    </div>
                    <div className="col-md-12 p-2">
                        <BasicTable iconButtons={iconButtonsItem} listButtons={listaBotones()} head={head} body={lista} rowSet={setRowBean}></BasicTable>
                    </div>
                </div>
            </BasicPage>

            <ModalConfirm modal={modalConfirm} updateModal={setModalConfirm} eventModal={eliminarItem}>
                {bean.Name}
            </ModalConfirm>

            <Modal modal={modal} updateModal={setModal} eventModal={updateItem}>
                <div className="row">


                    <div className="col-md-12">
                        <label className="form-label">Nombre</label>
                        <input type="text" className="form-control" value={bean.Name} onChange={(e) => { setBean({ ...bean, ['Name']: e.target.value }) }} />
                    </div>
                    <div className="col-md-12">
                        <label className="form-label">Descripción</label>
                        <textarea className="form-control" value={bean.Description} onChange={(e) => { setBean({ ...bean, ['Description']: e.target.value }) }}></textarea>
                    </div>

                </div>
            </Modal>
        </>
    )
}