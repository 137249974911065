import { DataBean } from "../DataBean";


export class EmployeeContract extends DataBean {

    public async getEmployeeContracts(year: number | null, month: number | null, employeeIDList: any | null, employmentTypeList: any | null, idLnFunctionalID: number | null, idEntailment: number | null, idAppointment: number | null, state: number | null, idLnSite: number | null, regexStr: string | null, page:number,recordsPerPage: number): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "com.advantage.shared.Report_getEmployeeContracts_Number_Number_java.util.List_java.util.List_Number_Number_Number_Number_Number_String_Number_Number", [year, month, employeeIDList, employmentTypeList, idLnFunctionalID, idEntailment, idAppointment, state, idLnSite, regexStr, page, recordsPerPage]);
    }

    public async getActiveEmployeeContractsWithoutFunctionalArea(page: number, recordsPerPage: number): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "com.advantage.shared.Report_getActiveEmployeeContractsWithoutFunctionalArea_Number_Number", [page, recordsPerPage]);
    }

    public async updateFunctionalAreaAndAppointmentForEmployeeContract(idContract: number, fromDate: Date, idAppointment: number | null, idLnFunctionalArea: number | null, idEmployee: number): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "employees.EmployeeContract_updateFunctionalAreaAndAppointmentForEmployeeContract_Number_java.util.Date_Number_Number_Number", [idContract, fromDate, idAppointment, idLnFunctionalArea, idEmployee]);
    }

    public async updateEmployeeContract(bean: any): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "employees.EmployeeContract_updateEmployeeContract_employees.EmployeeContract", [{
            "DataBeanProperties": bean,
            "DataBeanName": "employees.EmployeeContract"
        }]);
    }

    public async getPayrollMnemonicCatalog(payrollMnemonic: string | null): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_getPayrollMnemonicCatalog_String", [payrollMnemonic]);
    }

    public async getContractStateConstants(arg: string | null): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_getContractStateConstants_Number", [arg]);
    }

    public async getContractTypeConstants(arg: string | null): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_getContractTypeConstants_Number", [arg]);
    }

    public async getWelfareEntityCatalogByType(arg: number | null): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_getWelfareEntityCatalogByType_Number", [arg]);
    }

    public async getSalaryTypeConstants(arg: number | null): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_getSalaryTypeConstants_Number", [arg]);
    }

    public async getARPRiskCatalog(arg: number | null): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_getARPRiskCatalog_Number", [arg]);
    }

    public async getContributorTypeCatalog(arg: number | null): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_getContributorTypeCatalog_Number", [arg]);
    }

    public async getContributorSubtypeCatalog(arg: number | null): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_getContributorSubtypeCatalog_Number", [arg]);
    }

    public async getEmployeeContractTypeCatalog(arg: number | null): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_getEmployeeContractTypeCatalog_Number", [arg]);
    }

    public async getEmploymentTypeCatalog(arg: number | null): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_getEmploymentTypeCatalog_Number", [arg]);
    }

    public async getEmployeeLiquidateList(arg: number | null): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_getEmployeeLiquidateList_Number", [arg]);
    }

    public async exportEmployeeContractsTemplateXlsxFile(arg: number | null): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_exportEmployeeContractsTemplateXlsxFile_Number", [arg]);
    }

    public async importEmployeeContracts(filename: string, directory: string, dataStore: string): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_importEmployeeContracts_String_String_String", [filename, directory, dataStore]);
    }

}