import { useEffect, useState } from 'react';
import { Modal } from '../theme/Component/Modal';
import { BasicTable } from '../theme/Component/BasicTable';
import { AccountEditor } from './AccountEditor';
import { PrintJson } from '../theme/Component/PrintJson';
import { FaCheckCircle, FaTimes, FaTimesCircle } from 'react-icons/fa';
import { toast } from 'react-toastify';
interface Props {
    label?: string;
    onChange: Function;
    values?: any;
}
export const MultiAccountanEditor = ({ onChange, label, values }: Props) => {

    const [modal, setModal] = useState<any>({ name: 'Lista de usuarios', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [lista, setLista] = useState<any>([]);
    const [idList, setIdList] = useState<any>([]);

    useEffect(() => {
        setLista(values)
        setIdList(values)
    }, [values])


    const getItem = () => {
        onChange(idList)
        setModal({ ...modal, "view": false });
    }

    const agregarUser = (user: any) => {
        if (user.IDAccount >= 0) {
            const temp = [...lista, { IDAccount: user.IDAccount, EntityName: user.EntityName }];
            const tempID = [...idList, user.IDAccount];
            setLista(temp);
            setIdList(tempID);
           //console.log("Usuario======>", user);
        } else {
            toast.error("Usuario no Encontrado")
        }
    }

    const eliminarUser = (id: number) => {
        if (id >= 0) {
            const nuevaLista = lista.filter((user: any) => user.IDAccount !== id);
            const nuevaIdList = idList.filter((userId: number) => userId !== id);
            setLista(nuevaLista);
            setIdList(nuevaIdList);
        } else {
            toast.error("No se puso completar la acción");
        }
    }

    return (
        <>
            <label>{label ?? "Usuarios Seleccionados"}</label>
            <span
                className="form-control text-wrap"
                style={{ minHeight: '35px' }}
                onClick={() => setModal({ ...modal, view: true })}
            >
                {idList.length ? idList.join(', ') : "Usuarios Seleccionados"}
            </span>

            <Modal modal={modal} updateModal={setModal} eventModal={getItem}>
                <>
                    <div className="row">
                        <div className="col-12">
                            <AccountEditor label='Buscar Usuario' idAccount={null} onChange={(e) => { agregarUser(e) }} />
                        </div>
                        <div className="col-12 mt-2">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col">ID</th>
                                        <th scope="col">Nombre</th>
                                        <th scope="col">Acciones</th>
                                    </tr>
                                </thead>
                                {lista &&
                                    <>
                                        <tbody>
                                            {lista.map((e: any, i: number) =>
                                                <tr key={i}>
                                                    <th className='text-center' scope="row">{e.IDAccount}</th>
                                                    <td className='text-center'><strong>{e.EntityName}</strong></td>
                                                    <td>
                                                        <div className="d-flex justify-content-center gap-2 fs-2 text-warning">
                                                            <FaTimesCircle title='Retirar de la lista' className="pointer" onClick={() => eliminarUser(e.IDAccount)} />
                                                        </div>
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </>
                                }
                            </table>
                        </div>
                    </div>
                </>
            </Modal>
        </>
    )
}