import { useEffect, useState } from "react";
import useDataBean from "../../../core/services/dataBean/useDataBean";
import { _FunctionalCompetence } from "../../../core/services/dataBean/EntityCatalog";
import { Singleton } from "../../../core/services/Singleton";
import { toast } from "react-toastify";
import { BasicPage } from "../../theme/Component/BasicPage";
import { MainThemeEditor } from "../../Talentohumano/ParametrosTalentoHumano/MainTheme/MainTheme.editor";
import { BasicTable } from "../../theme/Component/BasicTable";
import { Modal } from "../../theme/Component/Modal";
import { ModalConfirm } from "../../theme/Component/ModalConfirm";
import { ListMenu } from "../../theme/Component/ListMenu";
import { _BudgetAccountantID } from "../../../core/services/dataBean/EntityCatalogVdos";
import { FinanceService } from "../../../core/services/FinanceService";
import { SelectAccountantTree } from "../../contabilidad/catalogo-cuentas/AccountantTree/SelectAccountantTree";
import { SelectTipoRubro } from "./SelectTipoRubro";
import { SelectBudgetID } from "../../admin/configuracion/BudgetID/SelectBudgetID";
import { BasicButton } from "../../theme/Component/BasicButton";





export const BudgetAccountantID = () => {

    const BudgetAccountantID = useDataBean(_BudgetAccountantID);
    const service = new FinanceService();
    const single = Singleton.getInstance();
    const [BudgetID, setBudgetID] = useState<number>(null)
    const [isEditMode, setIsEditMode] = useState(false);
    const [listaType, setListaType] = useState([]);
    const [lista, setLista] = useState<any>([]);
    const [budgetIDStr, setBudgetIDStr] = useState<number>(null);
    const [accountIDStr, setAccountIDStr] = useState<number>(null);
    const [code, setCode] = useState<number>(null);
    const [name, setName] = useState<number>(null);
    const [modalcriterios, setModalcriterios] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modal, setModal] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalC, setModalC] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [showSelectAccountant, setShowSelectAccountant] = useState(true);



    const getLista = () => {

        BudgetAccountantID.instance.getBudgetAccountantIDCatalog(accountIDStr, BudgetID, code, name).then(
            (resp: any) => {
                if (resp) {
                    let List = resp;
                    List.forEach((e: any) => {

                        e.DataBeanProperties.RubroName = (e.DataBeanProperties.BudgetJsconCode ?? '') +
                            (e.DataBeanProperties.BudgetIDName ?? '')

                        e.DataBeanProperties.CuentaName = (e.DataBeanProperties.AccountantIDJsconCode ?? '') +
                            (e.DataBeanProperties.AccountantIDName ?? '')


                    })
                    setLista(List);
                }
            }
        )

    }

    const create = () => {
        BudgetAccountantID.setBean({
            AccountantIDStr: BudgetAccountantID.bean.AccountantIDStr,
            BudgetID: BudgetAccountantID.bean.BudgetIDStr,
            Code: BudgetAccountantID.bean.Code,
            Name: BudgetAccountantID.bean.Name
            // AccountantIDStr: BudgetAccountantID.bean.AccountantIDStr


        });
        setModal({ ...modal, "view": true });
    }
    const deleteItem = () => {
        BudgetAccountantID.deleteItemVoid(BudgetAccountantID.bean.IDBudgetAccountantID).then(
            (resp: any) => {
                getLista();
                setModalC({ ...modalC, "view": false });
            }
        )

    }

    const editaItem = () => {


        BudgetAccountantID.instance.addBudgetAccountantID(BudgetAccountantID.bean.BudgetIDStr, BudgetAccountantID.bean.AccountantIDStr, BudgetAccountantID.bean.Code).then(
            (resp: any) => {
                if (resp) {
                   //console.log(resp)
                    getLista();
                    update();
                    setModal({ ...modal, "view": false });

                }

            }
        ).catch(err =>console.error(err))

    }




    const update = () => {
        BudgetAccountantID.update().then(
            (resp: any) => {
                getLista();

            }
        ).catch(err => {
            toast.error(err);
        })

    }


    // const editaItem = async () => {
    //     try {
    //         // Primero guarda la información principal (BudgetID y AccountantIDStr)
    //         const resp = await BudgetAccountantID.instance.addBudgetAccountantID(
    //             BudgetAccountantID.bean.BudgetIDStr,
    //             BudgetAccountantID.bean.AccountantIDStr,
    //             BudgetAccountantID.bean.Code

    //         );

    //         if (resp) {
    //            //console.log(resp);

    //             // Ahora actualiza los campos adicionales (Code e IDConcept)
    //             BudgetAccountantID.setBean({
    //                 ...BudgetAccountantID.bean,
    //                 Code: BudgetAccountantID.bean.Code,
    //                 IDConcept: BudgetAccountantID.bean.IDConcept
    //             });

    //             // Llamar a la función de actualización para guardar Code e IDConcept
    //             await BudgetAccountantID.update();

    //             // Finalmente, actualiza la lista para reflejar los cambios
    //             getLista();

    //             // Cerrar el modal
    //             setModal({ ...modal, "view": false });
    //         }
    //     } catch (err) {
    //        console.error(err);
    //         toast.error("Error al editar el elemento.");
    //     }
    // };



    const head = [
        { title: 'ID', property: 'IDBudgetAccountantID', visible: true },
        { title: 'Rubro Presupuestal', property: 'RubroName', visible: true },
        { title: 'Cuenta Contable', property: 'CuentaName', visible: true },
        { title: 'Código/Producto', property: 'Code', visible: true },
        { title: 'Concepto', property: 'IDConcept', visible: true },


    ]

    const iconButtonsItem = [
        { nombre: 'Agregar', visible: single.canCreate(), icono: 'ri-file-add-line', evento: create }
    ]
    const listaBotones = () => {
        const botones = [

            {
                titulo: 'Editar', icono: 'ri-edit-2-line', visible: single.canEdit(), evento: () => {
                    // Verificar si el AccountantIDStr ya está definido
                    if (BudgetAccountantID.bean.AccountantIDStr) {
                        // Si ya tiene valor, ocultar el SelectAccountantTree
                        setShowSelectAccountant(false);
                    } else {
                        // Si no tiene valor, mostrarlo
                        setShowSelectAccountant(true);
                    }

                    // Abrir el modal
                    setModal({ ...modal, "view": true });
                }
            },
            { titulo: 'Eliminar', icono: 'ri-delete-bin-line', visible: single.canDelete(), evento: () => { setModalC({ ...modalC, "view": true }) } },

        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }


    return (
        <BasicPage titulo="Configurar Segmentos Contable-Presupuestal ">
            <div className="row">
                <div className="col-md-6"><SelectAccountantTree label="Cuenta Contable" value={accountIDStr} onChange={(e) => { setAccountIDStr(e.JsonCode) }} /></div>
                <div className="col-md-6">
                    <SelectBudgetID value={BudgetID} onChange={(e) => { setBudgetID(e.JsonCode) }} />
                    {/* <SelectTipoRubro label={'Rubros Presupuestales'} idSelector={BudgetID} onChange={(e) => { setBudgetID(e.JsonCode) }} /> */}

                </div>

                <div className="col-md-6">
                    <label>Código</label>
                    <input type="text" name="code" className="form-control" value={code} onChange={(e: any) => { setCode(e.target.value) }} />
                </div>
                <div className="col-md-6 mb-5">
                    <label>Nombre</label>
                    <input type="text" name="name" className="form-control" value={name} onChange={(e: any) => { setName(e.target.value) }} />
                </div>
                <div className="col-md-6 mt-4">
                    <BasicButton icon="" eventClick={getLista}>BUSCAR</BasicButton>
                </div>

            </div>
            <div className="row">
                <div className="col-md-12">

                    <BasicTable body={lista} head={head} rowSet={BudgetAccountantID.setBean} listButtons={listaBotones()} iconButtons={iconButtonsItem}></BasicTable>
                    <Modal modal={modal} updateModal={setModal} eventModal={editaItem} >
                        <div className="row">
                            {/* <div className="col-md-12">
                                <SelectAccountantTree label="Cuenta Contable" renderValue={BudgetAccountantID.bean.AccountantIDStr} onChange={(e) => { BudgetAccountantID.setBean({ ...BudgetAccountantID.bean, "AccountantIDStr": e.JsonCode }) }} disabled={!!BudgetAccountantID.bean.AccountantIDStr} />
                            </div>
                            <div className="col-md-12">
                                {showSelectAccountant && (
                                    <SelectAccountantTree
                                        label="Cuenta Contable"
                                        jsonCode={BudgetAccountantID.bean.AccountantIDStr}
                                        onChange={(e) => {
                                            BudgetAccountantID.setBean({ ...BudgetAccountantID.bean, "AccountantIDStr": e.JsonCode });
                                        }}
                                    />
                                )}
                            </div> */}
                            <div className="col-md-6">
                                <SelectBudgetID label="Rubro Presupuestal" tipoPresupuesto={0} jsonCode={BudgetAccountantID.bean.BudgetIDStr} onChange={(e) => { BudgetAccountantID.setBean({ ...BudgetAccountantID.bean, "BudgetIDStr": e.JsonCode }) }} edit={false} />
                            </div>
                            <div className="col-md-6">
                                <SelectAccountantTree label="Cuenta Contable" value={BudgetAccountantID.bean.AccountantIDStr} onChange={(e) => { BudgetAccountantID.setBean({ ...BudgetAccountantID.bean, "AccountantIDStr": e.JsonCode }) }} />
                            </div>
                            <div className="col-md-6">
                                <label>Código/Producto</label>
                                <input type="text" name="Code" className="form-control" value={BudgetAccountantID.bean.Code} onChange={BudgetAccountantID.handleChange} />
                            </div>
                            <div className="col-md-6">
                                <label>Nombre</label>
                                <input type="text" name="Name" className="form-control" value={BudgetAccountantID.bean.Name} onChange={BudgetAccountantID.handleChange} />
                            </div>
                            {/* <div className="col-md-6">
                                <label>Concepto</label>
                                <input type="number" name="IDConcept" className="form-control" value={BudgetAccountantID.bean.IDConcept} onChange={BudgetAccountantID.handleChange} />
                            </div> */}


                        </div>
                    </Modal>



                    <ModalConfirm modal={modalC} updateModal={setModalC} eventModal={deleteItem}>
                        <strong>Eliminar item</strong>
                    </ModalConfirm>
                </div>

            </div>
        </BasicPage>
    )
}