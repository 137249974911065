import { useEffect, useState } from "react";
import { _FamilyGroup, _single } from "../../../../core/services/dataBean/EntityCatalog";
import useDataBean from "../../../../core/services/dataBean/useDataBean";
import { BasicPage } from "../../../theme/Component/BasicPage";
import { ListMenu } from "../../../theme/Component/ListMenu";
import { BasicTable, MASK_DATE, MASK_MONEY } from "../../../theme/Component/BasicTable";
import { ModalConfirm } from "../../../theme/Component/ModalConfirm";
import { Modal } from "../../../theme/Component/Modal";
import { AccountEditor } from "../../../editor/AccountEditor";
import { InputDate } from "../../../theme/Input/InputDate";
import { FileService } from "../../../../core/services/FileService";
import { SelectTHConstantsValue } from "../../../bpm/config/Componentes/SelectTHConstantsValue";
import { BasicSwitch } from "../../../theme/Component/BasicSwitch";
import { BasicButton } from "../../../theme/Component/BasicButton";

interface Props {
    dashedMenu?: boolean;
}

export const FamilyGroup = ({ dashedMenu }: Props) => {

    const { lista, setLista, bean, setBean, instance, update, handleChange } = useDataBean(_FamilyGroup);
    const fileService = new FileService();

    const [account, setAccount] = useState<number>(dashedMenu ? _single.getAccountID() : NaN);

    const [modal, setModal] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalC, setModalC] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });
    const [modalA, setModalA] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });

    useEffect(() => {
        if (account) {
            getLista()
        }
    }, [account])

    const getLista = () => {
        const Props = {
            State: 1,
            IDAccount: account,
        }
        instance.getFamilyGroupCatalogRender(null, Props).then(
            (rta: any) => {
                rta.forEach((element) => {
                    if (element.DataBeanProperties.JobReferenceMedia) {
                        let doc = JSON.parse(element.DataBeanProperties.JobReferenceMedia);
                        if (doc.Media) {
                            element.DataBeanProperties.RespDoc = `<a href="${fileService.getUrlFile(element.DataBeanProperties.MediaContext, element.DataBeanProperties.Media)}" target='_blank'>${doc.Name ?? "Ver Documento"}</a>`
                        }
                    } else {

                    }
                });
                setLista(rta)
            }
        ).catch(
            err => { console.log(err) }
        )
    }

    const editaItem = () => {
        update().then(
            (resp) => {
                setModal({ ...modal, "view": false });
                setModalC({ ...modalC, "view": false });
                setModalA({ ...modalA, "view": false });
                getLista();
            }
        ).catch(
            err => { console.log(err) }
        )
    }

    const deleteItem = () => {
        let objet = bean;
        objet.State = 2;
        objet.IDEmployeeDelete = _single.getAccountID();
        objet.DeleteDate = _single.getCurrenDate();
        setBean(objet);

        editaItem();
    }

    const head = [
        { title: 'ID', property: 'IDFamilyGroup', visible: true },
        { title: 'Usuario', property: 'EntityName', visible: true },
        { title: 'Familiar', property: 'Name', visible: true },
        { title: 'Relación Familiar', property: 'IDRelationshipName', visible: true },
        { title: 'Fecha de Nacimiento', property: 'BornDate', visible: true, mask:MASK_DATE },
        { title: 'Tipo de Documento', property: 'IDDocumentTypeName', visible: true },
        { title: 'Numero de Documento', property: 'Nit', visible: true },
        { title: 'Telefono', property: 'Phone', visible: true },
        { title: 'Correo', property: 'Email', visible: true },
        { title: 'Depende Economicamente', property: 'EconomicDependence', visible: true },
    ]

    const create = () => {
        setBean({
            State: 1,
            IDEmployee: _single.getAccountID(),
            IDAccount: dashedMenu ? _single.getAccountID() : NaN,
            EconomicDependence: false,
        });
        setModal({ ...modal, "view": true, "name": "Agregar Informacion a Grupo Familiar" });
    }

    const iconButtonsItem = [
        { nombre: 'Agregar', visible: (_single.canCreate() || dashedMenu), icono: 'ri-file-add-line', evento: create }
    ]

    const listaBotones = () => {
        const botones = [
            { titulo: 'Editar', icono: 'ri-edit-2-line', visible: (_single.canEdit() || dashedMenu), evento: () => { setModal({ ...modal, "view": true, "name": "Editar Información de Grupo Familiar" }) } },
            { titulo: 'Eliminar', icono: 'ri-delete-bin-line', visible: (_single.canDelete() || dashedMenu), evento: () => { setModalC({ ...modalC, "view": true, "name": `Eliminar Informacion de Grupo Familiar` }) } },
        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }

    return (
        <div className="row">
            <div className="col-md-12">
                {dashedMenu ?
                    <div className="row">
                        <div className="col-md-12">
                            <BasicTable listButtons={listaBotones()} head={head} body={lista} rowSet={setBean} iconButtons={iconButtonsItem} />
                        </div>
                    </div>
                    :
                    <BasicPage titulo="Grupo Familiar" >
                        <div className="row">
                            <div className="col-md-6">
                                <AccountEditor idAccount={account} onChange={(e) => { setAccount(e.IDAccount) }} label="Usuario" />
                            </div>
                            <div className="col-md-4 mt-4">
                                <BasicButton icon="ri-search-line" eventClick={getLista} >Buscar</BasicButton>
                            </div>
                            <div className="col-md-12">
                                <BasicTable listButtons={listaBotones()} head={head} body={lista} rowSet={setBean} iconButtons={iconButtonsItem} />
                            </div>
                        </div>
                    </BasicPage>
                }
            </div>

            <div className="col-md-12">
                <ModalConfirm modal={modalC} updateModal={setModalC} eventModal={deleteItem}>
                    <strong>Eliminar Informacion de {bean.IDAccount}</strong>
                </ModalConfirm>
            </div>

            <div className="col-md-12">
                <Modal modal={modal} updateModal={setModal} eventModal={editaItem}>
                    <div className="row">
                        {!dashedMenu &&
                            <div className="col-md-6">
                                <AccountEditor idAccount={bean.IDAccount} label="Empleado" onChange={({ IDAccount }) => { setBean({ ...bean, "IDAccount": IDAccount }) }} />
                            </div>
                        }
                        <div className="col-md-6">
                            <label>Nombres y Aprellidos</label>
                            <input type="text" className="form-control" name="Name" value={bean.Name} onChange={handleChange} />
                        </div>
                        <div className="col-md-6">
                            <SelectTHConstantsValue code={106} value={bean.IDRelationship} onChange={(e) => { setBean({ ...bean, "IDRelationship": e }) }} label="Relación Familiar" />
                        </div>
                        <div className="col-md-6">
                            <InputDate notDate label="Fecha de Nacimiento" name="BornDate" value={bean.BornDate} setDate={(e) => { setBean({ ...bean, "BornDate": e }) }} />
                        </div>
                        <div className="col-md-6">
                            <SelectTHConstantsValue code={107} value={bean.IDDocumentType} onChange={(e) => { setBean({ ...bean, "IDDocumentType": e }) }} label="Tipo de Documento" />
                        </div>
                        <div className="col-md-6">
                            <label>Numero de Documento</label>
                            <input className="form-control" name="Nit" value={bean.Nit} onChange={handleChange} type="number" />
                        </div>
                        <div className="col-md-6">
                            <label>Télefono</label>
                            <input className="form-control" name="Phone" value={bean.Phone} onChange={handleChange} type="text" />
                        </div>
                        <div className="col-md-6">
                            <label>Correo Electronico</label>
                            <input className="form-control" name="Email" value={bean.Email} onChange={handleChange} type="text" />
                        </div>
                        <div className="col-md-6">
                            <label>¿Dependiente Economicamente?</label>
                            <BasicSwitch estado={bean.EconomicDependence} eventChange={(e) => { setBean({ ...bean, "EconomicDependence": e }) }} />
                        </div>
                    </div>
                </Modal>
            </div>
        </div>
    )
}



