import { useState } from "react";
import { SelectBudgetID } from "../../admin/configuracion/BudgetID/SelectBudgetID";
import { PrintJson } from "../../theme/Component/PrintJson";
import { BasicButton } from "../../theme/Component/BasicButton";

interface Props {
    data: any;
    event: any;
}


export const BudgetIDSelectoRender = ({ data, event }: Props) => {
    const [budget, setBudget] = useState<any>({})

    const returnvalue = () => {
        let row = data;
        row.JsonCode = budget.JsonCode;
        event(row)
    }

    return (
        <>
            <SelectBudgetID tipoPresupuesto={0} jsonCode={budget.JsonCode} edit={false} onChange={(e: any) => { setBudget(e) }} />
            <BasicButton icon="" eventClick={returnvalue} >Seleccionar</BasicButton>
        </>
    )
}