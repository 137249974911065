import { useEffect, useState } from "react";
import { PrintJson } from "../../theme/Component/PrintJson";
import { GenericSelect } from "../../RemoteAbstractAction/editors/GenericSelect";
import { AbstractStep } from "./AbstractStep";
import { _single } from "../../../core/services/dataBean/EntityCatalog";

interface Props {
    row: any;
}
export const AbstractStepItem = ({ row }: Props) => {


    const [step, setStep] = useState<any>([]);
    const [campos, setCampos] = useState<any>([]);
    const [table, setTable] = useState<any>([]);

    useEffect(() => {
        let tmp = { ...row };
        if (tmp.TableStructureObject) {
            setTable(tmp.TableStructureObject);
        }

        delete tmp['Options'];
        delete tmp['TableStructureObject'];

        const rows = Object.keys(tmp);
        

        setStep(tmp);
    }, [])


    return (
        <div className="row">
            <div className="col-md-12">
                
                {step &&
                    <>
                    {Object.keys(step).map( fila => {
                        return(
                            <div className="row">
                                <div className="col-md-4"><strong>{fila}</strong></div>
                                <div className="col-md-4">{`${step[fila]}`}</div>
                                <hr />
                            </div>
                        )
                    })}
                    </>
                }
            </div>
            <div className="col-md-12">
                {row.Options &&
                    <>
                        <label><strong>Opciones</strong></label>
                        <GenericSelect idSelect={0} lista={row.Options} onChange={(e) => { }} name={row.IDProject} />
                    </>
                }
                {row.TableStructureObject &&
                    <div className="card">
                        <div className="card-header">
                            <div className="card-title">
                                <h6>Campos de la tabla</h6>
                            </div>
                        </div>
                        <div className="card-body">
                            <AbstractStep fieldList={row.TableStructureObject} />
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}