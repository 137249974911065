import { DataBean } from "../DataBean";


export class TaxCode extends DataBean {

    public async getTaxCodeCatalog(idTaxCodeScheme: number | null): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_getTaxCodeCatalog_Number", [idTaxCodeScheme]);
    }

    public async getTaxCodeCatalogPaginador(idTaxCodeScheme: number | null, code: any, name: any, page: any, recordsPerPage: any): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "com.advantage.shared.Report_getTaxCodeCatalog_Number_String_String_Number_Number", [idTaxCodeScheme, code, name, page, recordsPerPage]);
    }


    public async getTaxCodeCatalogByTaxSchemeValue(idTaxCodeScheme: number | null): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_getTaxCodeCatalogByTaxSchemeValue_Number", [idTaxCodeScheme]);
    }

    public async importTaxCodeSchemeCatalog(idTaxCodeScheme: number, fileName: string, directory: string, dataStore: string | null): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_importTaxCodeSchemeCatalog_Number_String_String_String", [idTaxCodeScheme, fileName, directory, dataStore]);
    }

    public async addTaxCode(idTaxCodeScheme: number, code: string, name: string, description: string): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "com.quickdataerp.bean.tax.TaxCode_addTaxCode_Number_String_String_String", [idTaxCodeScheme, code, name, description]);
    }

}