import { useState } from 'react'
import check from '../../../../../assets/dashboard/success.svg'
import { Singleton } from '../../../../../core/services/Singleton'
import { PrintJson } from '../../../../theme/Component/PrintJson'

interface Props {
    title: string
    message: string
    affiliate?: any
    exportMap: Function
    credit?: any
    updateCredit: Function
    closeModal?: Function
}
export const SaveToken = ({ title, exportMap, affiliate, credit, updateCredit, closeModal }: Props) => {

    const [viewer, setViewer] = useState(0);
    const single = Singleton.getInstance();

    const updateToken = (conditional: boolean) => {
        if (conditional == true) {
            let x = { ...credit, TokenDate: single.getCurrenDate(), State: 6 }
           //console.log(x)
            updateCredit(x);
        } else {
            let x = { ...credit, TokenDate: single.getCurrenDate(), State: 7 }
           //console.log(x)
            updateCredit(x);
        }

        exportMap({
            APROBO: `${conditional}`,
            GRABADO: `${conditional}`,
        });
    }

    return (
        <>
            {viewer === 0 &&
                <div className='d-flex justify-content-center align-items-center flex-column gap-3'>
                    <div className='my-3'>
                        <strong>{title}</strong>
                    </div>
                    {credit && affiliate &&
                        <div className='text-muted' style={{ width: '80%' }}>
                            <small>
                                Esta seguro que desea Grabar el token: <br />
                                <strong>{credit.TokenCredit}</strong><br />
                                Aprovado el: <br />
                                <strong>{credit.TokenDate ?? single.getCurrenDate()}</strong><br />
                                Con el valor de la cuotas: <br />
                                <strong>{credit.FeeValue}</strong><br />
                                Por un valor total de: <br />
                                <strong>{(credit.FeeValue * credit.QuotaCredit)}</strong><br />
                                Al afiliado:<br />
                                <strong> {affiliate.NAME1 + " " + affiliate.NAME2 + " " + affiliate.SURNAME1 + " " + affiliate.SURNAME2}</strong><br />
                                Con numero de identificaión: <br />
                                <strong>{affiliate.NIT}</strong>
                            </small>
                        </div>
                    }

                    <div className='w-50 d-flex justify-content-between my-3'>
                        <button type="button" className="btn btn-light" data-bs-dismiss="modal" onClick={() => { setViewer(2); updateToken(false) }}>
                            NO
                        </button>
                        <button type="button" className="btn btn-primary " onClick={() => { setViewer(1); updateToken(true) }}>
                            SI
                        </button>
                    </div>
                </div>
            }
            {viewer === 1 &&
                <div className="animate__animated animate__fadeIn">
                    <div style={{ height: 80, width: 80, position: 'relative', margin: 'auto' }}>
                        <img className='animate__animated animate__tada' src={check} alt="success_gif" />
                    </div>
                    <div className="mx-5 mt-5 alert alert-success">
                        Se ha <strong className='text-success'>APROBADO</strong> la solicitud éxitosamente!
                    </div>
                </div>
            }
            {viewer === 2 &&
                <div className="animate__animated animate__fadeIn">
                    <div style={{ height: 80, width: 80, position: 'relative', margin: 'auto' }}>
                        <img className='animate__animated animate__tada' src={check} alt="success_gif" />
                    </div>
                    <div className="mx-5 mt-5 alert alert-success">
                        Se ha <strong className='text-success'>RECHAZADO</strong> la solicitud éxitosamente!
                    </div>
                </div>
            }
        </>
    )
}
