import { useEffect, useState } from "react";

import useDataBean from "../../../core/services/dataBean/useDataBean";
import { CashReimbursementServices } from "../cellEditor/CashDesk/CashReimbursement/CashReimbursement-service";
import { toast } from "react-toastify";
import { Singleton } from "../../../core/services/Singleton";
import { ListMenu } from "../../theme/Component/ListMenu";
import { BasicTable } from "../../theme/Component/BasicTable";
import { BasicPage } from "../../theme/Component/BasicPage";
import { Modal } from "../../theme/Component/Modal";
import { SelectAccountantTree } from "../../contabilidad/catalogo-cuentas/AccountantTree/SelectAccountantTree";
import { PrintJson } from "../../theme/Component/PrintJson";
import { ModalConfirm } from "../../theme/Component/ModalConfirm";
import { _AccountantConcept, _FiscalResponsabilityType } from "../../../core/services/dataBean/EntityCatalog";
import { FiscalResponsability } from "../FiscalResponsability/FiscalResponsability";
import { InputDate } from "../../theme/Input/InputDate";



export const FiscalResponsabilityType = () => {

    const FiscalResponsabilityType = useDataBean(_FiscalResponsabilityType);

    const single = Singleton.getInstance();
    const service = new CashReimbursementServices();
    const [selectedItems, setSelectedItems] = useState([]);
    const [lista, setLista] = useState<any>([]);

    const [listadocs, setlistadocs] = useState([]);
    const [modal, setModal] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalC, setModalC] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });

    useEffect(() => {
        getLista();
        getListaDocs();
    }, [])


    const getLista = () => {

        FiscalResponsabilityType.instance.getFiscalResponsabilityTypeCatalog(null).then(
            (resp: any) => {
                if (resp) {
                    setLista(resp)
                   //console.log(resp)
                }
            }
        )

    }

    const create = () => {
        FiscalResponsabilityType.setBean({});

        setModal({ ...modal, "view": true });
    }


    const deleteItem = () => {
        FiscalResponsabilityType.deleteItemVoid(FiscalResponsabilityType.bean.IDAccountantConcept).then(
            (resp: any) => {
                getLista();
                setModalC({ ...modalC, "view": false });
            }
        )

    }
    const editaItem = () => {
       //console.log(FiscalResponsabilityType.bean);
        FiscalResponsabilityType.update().then(
            (resp: any) => {
                getLista();
                setModal({ ...modal, "view": false });
            }
        ).catch(err => {
            toast.error(err);
        })

    }

    const head = [
        { title: 'ID', property: 'IDFiscalResponsabilityType', visible: true },
        { title: 'Nombre', property: 'Name', visible: true },
        { title: 'Descripción', property: 'Description', visible: true },
        { title: 'Código', property: 'Code', visible: true },

    ]

    const iconButtonsItem = [
        { nombre: 'Agregar', visible: single.canCreate(), icono: 'ri-file-add-line', evento: create }
    ]
    const listaBotones = () => {
        const botones = [

            { titulo: 'Editar', icono: 'ri-edit-2-line', visible: single.canEdit(), evento: () => { setModal({ ...modal, "view": true }) } },
            { titulo: 'Eliminar', icono: 'ri-delete-bin-line', visible: single.canDelete(), evento: () => { setModalC({ ...modalC, "view": true }) } },

        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }

    // getDocumentFactoryCatalog


    const getListaDocs = () => {

        single.spinner(true);
        service.getDocumentFactoryCatalog(null).subscribe(
            (resp: any) => {

                if (resp.DataBeanProperties.ObjectValue) {
                    setlistadocs(resp.DataBeanProperties.ObjectValue);
                }
                single.spinner(false);
            }
        );
    }


    return (
        <BasicPage titulo="Tipos de Responsabilidad Fiscal">
            <div className="row">
                <div className="col-md-12">

                    <BasicTable body={lista} head={head} rowSet={FiscalResponsabilityType.setBean} listButtons={listaBotones()} iconButtons={iconButtonsItem}></BasicTable>
                    <Modal modal={modal} updateModal={setModal} eventModal={editaItem}>
                        <>
                            <div className="row">
                                <div className="col-md-6">
                                    <label>Nombre</label>
                                    <input type="text" name="Name" className="form-control" value={FiscalResponsabilityType.bean.Name} onChange={FiscalResponsabilityType.handleChange} />

                                </div>
                                <div className="col-md-6">
                                    <label>Código</label>
                                    <input type="text" name="Code" className="form-control" value={FiscalResponsabilityType.bean.Code} onChange={FiscalResponsabilityType.handleChange} />
                                </div>
                                <div className="col-md-12">
                                    <label>Descripción</label>
                                    <textarea className="form-control" name="Description" value={FiscalResponsabilityType.bean.Description} onChange={FiscalResponsabilityType.handleChange} />

                                </div>

                            </div>

                        </>
                    </Modal>
                    <ModalConfirm modal={modalC} updateModal={setModalC} eventModal={deleteItem}>
                        <strong>Eliminar item</strong>
                    </ModalConfirm>
                </div>

            </div>
        </BasicPage>
    )
}