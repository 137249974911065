import { useState, useEffect } from 'react';
import useDataBean from '../../../../../core/services/dataBean/useDataBean';
import { _PositionObjective } from '../../../../../core/services/dataBean/EntityCatalog';
import { PrintJson } from '../../../../theme/Component/PrintJson';
import { Singleton } from '../../../../../core/services/Singleton';


interface Props {
    idSelector: number;
    onChange: any;
    label?: string
}
export const PerformanceObjectivesEditor = ({ idSelector, onChange, label }: Props) => {

    const positionObj = useDataBean(_PositionObjective)
    const [lista, setLista] = useState([]);
    const [id, setID] = useState(null);

    useEffect(() => {
        setID(idSelector);
        getLista();
    }, [])

    useEffect(() => {
        onChange(id);
    }, [id])

    const setValue = (e: any) => {
        setID(parseInt(e));
        onChange(id);
    }

    const getLista = () => {
        positionObj.instance.getYearListForPerformanceObjectives().then(
            (resp: any) => {
                
                
                setLista(resp);
                if(resp)
                    if(resp.length > 0 )
                        setValue(resp[0]);
            }
        );
    }

    return (
        <>
            <label>{label ?? "Año a Evaluar Desempeño"}</label>
            <select  className="form-select" value={id} onChange={(e) => { setValue(e.target.value) }}>
                
                {lista &&
                    lista.map((item: any, index: number) => {
                        return (
                            <>
                                <option key={index} value={item} >{item}</option>
                            </>)
                    })
                }
            </select>
        </>
    )
}