import { useEffect, useState } from "react";
import { AccountEditor } from "../../editor/AccountEditor";
import useDataBean from "../../../core/services/dataBean/useDataBean";
import { _AdquisitionContract, _single } from "../../../core/services/dataBean/EntityCatalog";
import { toast } from "react-toastify";
import { BasicButton } from "../../theme/Component/BasicButton";
import { BasicTable, MASK_MONEY, MASK_NUMBER } from "../../theme/Component/BasicTable";
import { InputDate } from "../../theme/Input/InputDate";

interface Props {
    idAdquisitionContract?: number;
    idAccountSupplier?: number;
    onChange: Function;
}
export const AdquisitionContractEditor = ({ idAdquisitionContract, idAccountSupplier, onChange }: Props) => {

    const { lista, setLista, instance, bean, setBean } = useDataBean(_AdquisitionContract);

    const [response, setResponse] = useState<any>({
        AccDate: _single.getCurrenDate(),

    });
    const [account, setAccont] = useState<any>({});

    useEffect(() => {
        let obje = {
            AccDate: response.AccDate,
            Consecutive: response.Consecutive,
            Description: response.Description,
            Contract: bean,
        }
        onChange(obje);
    }, [bean, response])

    const buscarContrato = () => {
        let param = {
            IDAccount: account.IDAccount,
            State: 1
        }
        instance.getAdquisitionContractRender(null, null, param, null, null).then(
            (resp: any) => {
                setLista(resp)
            }
        ).catch(err => toast.error(err))
    }

    const head = [
        { title: 'ID', property: 'IDAdquisitionContract', visible: true, mask: MASK_NUMBER },
        { title: 'Proveedor', property: 'Name1', visible: true },
        { title: 'Valor Contratado', property: 'ContractValue', visible: true, mask: MASK_MONEY },
        { title: 'Fecha Contrato', property: 'AdquisitionContractDate', visible: true },
        { title: 'Objeto', property: 'Description', visible: true },
    ]

    return (
        <div className="row">
            <div className="col-md-6">
                <label >Fecha de Registro</label>
                <InputDate name="" setDate={(e) => { setResponse({ ...response, "AccDate": e }) }} value={response.AccDate} />
            </div>
            <div className="col-md-6">
                <label>Numero de Consecutivo Externo</label>
                <input type="number" value={response.Consecutive} onChange={(e) => { setResponse({ ...response, "Consecutive": parseInt(e.target.value) }) }} className="form-control" />
            </div>
            <div className="col-md-12">
                <label>Descripción</label>
                <textarea className="form-control" value={response.Description} onChange={(e) => { setResponse({ ...response, "Description": e.target.value }) }} />
            </div>
            <div className="col-md-6">
                <AccountEditor idAccount={idAccountSupplier} label="Tercero" onChange={setAccont} />
            </div>

            <div className="col-md-6 mt-4">
                <BasicButton icon="" eventClick={buscarContrato}>Buscar</BasicButton>
            </div>
            <div className="col-md-12">
                <BasicTable head={head} body={lista} rowSet={setBean}></BasicTable>
                {/* <PrintJson json={lista}/> */}
            </div>

        </div>
    )
}