import { AdminService } from "../../../core/services/AdminService";
import { Singleton } from "../../../core/services/Singleton";
import { BasicTable } from "../../theme/Component/BasicTable";
import { useState, useEffect } from 'react';
import { ListMenu } from "../../theme/Component/ListMenu";
import { Modal } from "../../theme/Component/Modal";
import { ActivateAccount } from "../configuracion/ActivateAccount";
import { ModalConfirm } from "../../theme/Component/ModalConfirm";
import { useDashboardStore } from "../../pages/hooks/useDashboardStore";

interface Props {
    IDRol: number;
}
export const UserRol = ({ IDRol }: Props) => {

    const single = Singleton.getInstance()
    const { setLoading } = useDashboardStore();
    const service = new AdminService();
    const [lista, setLista] = useState<any>([]);
    const [listaF, setListaF] = useState<any>([]);
    const [bean, setBean] = useState<any>({});
    const [state, setState] = useState<number>(0);
    const [modal, setModal] = useState<any>({ name: 'Agregar', tabIndex: -1, view: false, btnClose: true, btnSubmit: false, format: 'modal-lg' });
    const [modalE, setModalE] = useState<any>({ name: 'Retirar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });
    const [modalF, setModalF] = useState<any>({ name: 'Funciones del Usuario en el Rol', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });

    const idBean = 'bean_tabla';
    const head = [
        { title: 'ID', property: 'IDAccount', visible: true },
        { title: 'Identificación', property: 'AccountID', visible: true },
        { title: 'Nombre', property: 'AccountName', visible: true },
        { title: 'Desde', property: 'Since', visible: true },
        { title: 'Removido', property: 'RemovedDate', visible: true },

        { title: 'Registrado por', property: 'EmployeeNameRequest', visible: true },
        { title: 'Cancelado por', property: 'EmployeeNameRemoved', visible: true },
        { title: 'Estado', property: 'StateName', visible: true },
    ]

    useEffect(() => {
        // getAccountsByRole();
        getAccountRoleByRole();
    }, [IDRol, state])

    const getAccountRoleByRole = () => {
        setLoading(true);
        service.getAccountRoleByRole(IDRol, state).subscribe(
            (resp: any) => {
                setLoading(false);
                if (resp.DataBeanProperties.ObjectValue) {
                    setLista(resp.DataBeanProperties.ObjectValue)
                    setModal({ ...modal, "view": false })
                }
            }
        )
    }

    const getAccountsByRole = () => {
        setLoading(true);
        service.getAccountsByRole(IDRol).subscribe(
            (resp: any) => {
                setLoading(false);
                if (resp.DataBeanProperties.ObjectValue) {
                    setLista(resp.DataBeanProperties.ObjectValue)
                }
            }
        )
    }
    const getUserMenuRoles = () => {
        setLoading(true);
        service.getUserMenuRoles(bean.IDAccount, IDRol).subscribe(
            (resp: any) => {

                if (resp.DataBeanProperties.ObjectValue) {
                    let f = resp.DataBeanProperties.ObjectValue;
                    let arr: any = [];

                    f.forEach((fu: any) => {
                        let ob: any = { DataBeanProperties: { menu: '', funciones: '' } };
                        ob.DataBeanProperties.menu = fu.DataBeanProperties.Name;
                        let h = fu.DataBeanProperties.Hijos;
                        let listaHijo: any = [];
                        let arrString = '';
                        h.forEach((hijo: any) => {

                            listaHijo.push(hijo.Name);
                            let permiso = JSON.parse(hijo.IDLnPermissions || '{}');
                            let lisP = '';
                            if (permiso) {
                                Object.keys(permiso).forEach((p: any) => {
                                    if (permiso[p] === true)
                                        lisP += " " + p;
                                })
                            }
                            arrString += hijo.Name + " " + " - ";
                            // arrString+=hijo.Name+" ["+lisP+"]"+" <br/> ";
                        });


                        ob.DataBeanProperties.funciones = arrString.substring(0, arrString.length - 2);
                        arr.push(ob);
                    });


                    // setListaF(resp.DataBeanProperties.ObjectValue);
                    setListaF(arr);
                }
                setLoading(false);
            }
        )
    }


    const agregarItem = () => {



    }
    const removeAccountToRole = () => {

        let b = bean;
        b.RoleID = 0;
        setLoading(true);
        service.removeAccountToRole(b.IDAccount, single.getAccountID(), b.IDRole).subscribe(
            (resp: any) => {
                setLoading(false);
                if (resp.DataBeanProperties.ObjectValue) {
                    single.delete(b.IDAccountRole, b);
                }
                getAccountRoleByRole();
                setModalE({ ...modalE, 'view': false })
            });

    }


    const listaBotones = () => {
        const botones = [
            { titulo: 'Retirar', icono: 'ri-edit-2-line', visible: true, evento: () => { setModalE({ ...modalE, 'view': true }) } },
            { titulo: 'Ver Funciones', icono: 'ri-file-list-2-line', visible: true, evento: () => { getUserMenuRoles(); setModalF({ ...modalF, 'view': true }) } },
        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }
    const iconButtonsItem = [

        { nombre: 'Agregar', visible: true, icono: 'ri-file-add-line', evento: () => { setModal({ ...modal, "view": true }) } }

    ]

    const headF = [

        { title: 'Menú', property: 'menu', visible: true },
        { title: 'Sub-Menú', property: 'funciones', visible: true }
    ]

    const headBotton = () => {
        return (
            <div className="row">
                <div className="col-md-6">
                    <label>Estado</label>
                    <select className="form-select" value={state} onChange={(e) => { setState(parseInt(e.target.value)) }}>
                        <option value={0}>ACTIVO</option>
                        <option value={1}>INACTIVO</option>
                    </select>
                </div>
            </div>
        )
    }

    return (
        <>

            <BasicTable headButtons={headBotton()} iconButtons={iconButtonsItem} listButtons={listaBotones()} head={head} rowSet={setBean} body={lista}></BasicTable>
            <Modal modal={modal} updateModal={setModal} eventModal={agregarItem}>
                <div className="row">
                    <div className="col">
                        <ActivateAccount idAccount={0} idRole={IDRol} account={{}} updateRol={getAccountRoleByRole} />
                    </div>
                </div>
            </Modal>
            <Modal modal={modalF} updateModal={setModalF} eventModal={() => { }}>
                <h6>Funciones del Usuario</h6>
                <BasicTable body={listaF} head={headF} rowSet={() => { }}></BasicTable>
                {/* <PrintJson json={listaF}/> */}
            </Modal>
            <ModalConfirm modal={modalE} updateModal={setModalE} eventModal={removeAccountToRole} >
                <p>Esta seguro de eliminar el funcionario del rol.</p>
            </ModalConfirm>
        </>
    )
}