import { DataBean } from "../DataBean";

export class CashResquestItem extends DataBean {

    public async getCashRequestItemCatalog(idCashRequest: number, cashRequestDate: any): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_getCashRequestItemCatalog_Number_java.util.Date", [idCashRequest, cashRequestDate]);
    }

    public async deleteCashRequestItem(idCashRequestItem: number, since: any, idEmployee: number): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "void_deleteCashRequestItem_Number_java.util.Date_Number", [idCashRequestItem, since, idEmployee]);
    }

    public async updateCashRequestItem(bean: any): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "com.quickdataerp.bean.cashreimbursement.CashRequestItem_updateCashRequestItem_com.quickdataerp.bean.cashreimbursement.CashRequestItem", [
            {
                "DataBeanProperties": bean,
                "DataBeanName": "com.quickdataerp.bean.cashreimbursement.CashRequestItem"
            }
        ]);
    }

    public async processCashRequestItem(idCashRequestItem: number, cashRequestDate: any, idEmployee: number, observations: string): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "com.quickdataerp.bean.cashreimbursement.CashRequestItem_processCashRequestItem_Number_java.util.Date_Number_String", [idCashRequestItem, cashRequestDate, idEmployee, observations]);
    }

    public async rejectCashRequestItem(idCashRequestItem: number, cashRequestDate: any, idEmployee: number, observations: string): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "com.quickdataerp.bean.cashreimbursement.CashRequestItem_rejectCashRequestItem_Number_java.util.Date_Number_String", [idCashRequestItem, cashRequestDate, idEmployee, observations]);
    }

    public async addUBLInvoiceToCashRequest(idUBLInvoice: number, issueDate: any, idCashRequest: number, cashReimbursementDate: any, observations: string): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "com.quickdataerp.bean.cashreimbursement.CashRequestItem_addUBLInvoiceToCashRequest_Number_java.util.Date_Number_java.util.Date_String", [idUBLInvoice, issueDate, idCashRequest, cashReimbursementDate, observations]);
    }

}
