import { useState, useEffect } from 'react';
import { BpmService } from "../../../../core/services/BpmService";
import { Singleton } from "../../../../core/services/Singleton";
import { BasicPage } from "../../../theme/Component/BasicPage"
import { BasicTable } from "../../../theme/Component/BasicTable"
import { ModalConfirm } from '../../../theme/Component/ModalConfirm';
import { Modal } from '../../../theme/Component/Modal';
import { ListMenu } from '../../../theme/Component/ListMenu';
import { useDashboardStore } from '../../../pages/hooks/useDashboardStore';

export const RequirementSST = () => {

    const single = Singleton.getInstance()
    const { setLoading } = useDashboardStore();
    const service = new BpmService();

    const [lista, setLista] = useState<any>([]);
    const [bean, setBean] = useState<any>({});
    const [modalConfirm, setModalConfirm] = useState<any>({ name: 'confirmar', tabIndex: -1, view: false, btnCLose: true, btnSubmit: true, format: 'modal-lg' });
    const [modal, setModal] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });

    const idBean = 'IDRequirementSST';

    useEffect(() => {
        getLista();
    }, [])

    const setRowBean = (bean: any) => {
       //console.log("Row : ", bean);
        setBean(bean);
    }

    const head = [
        { title: 'ID', property: idBean },
        { title: 'Nombre', property: 'Name' },
        // { title: 'Estado', property: 'State'},
    ]

    const getLista = () => {
        setLoading(true);
        service.getRequirementSSTCatalogPorPropiedad(null, null, null).subscribe(
            (resp: any) => {
                setLoading(false);
                if (resp.DataBeanProperties.ObjectValue) {
                    setLista(resp.DataBeanProperties.ObjectValue)
                }
            }
        );
    }

    const eliminarItem = () => {
        setLoading(true);
        service.deleteRequirementSST(bean[idBean]).subscribe(
            (resp: any) => {
                setLoading(false);
                if (resp) {
                    getLista();
                }
                single.delete(bean[idBean], bean);
            }
        );
        setModalConfirm({ ...modalConfirm, ['view']: false });
    }

    const updateItem = () => {
        setLoading(true);
        service.updateRequirementSST(bean).subscribe(
            (resp: any) => {
                setLoading(false);
                if (resp.DataBeanProperties.ObjectValue) {
                    getLista();
                }
                if (bean[idBean]) {
                    single.update(bean);
                } else {
                    single.create(bean);
                }
            }
        );
        setModal({ ...modal, ['view']: false });
    }

    const agregarItem = () => {
        let b = {
            Name: "",
            State: 1,
        }
        setBean(b);
        setModal({ ...modal, ["view"]: true, name: "Agregar" });
    }

    const listaBotones = () => {
        const botones = [
            { titulo: 'Editar', icono: 'ri-file-list-line', visible: single.canEdit(), evento: () => { setModal({ ...modal, "view": true, 'name': 'Editar' }) } },
            { titulo: 'Eliminar', icono: 'ri-file-user-line', visible: single.canDelete(), evento: () => { setModalConfirm({ ...modalConfirm, "view": true }) } },
        ]
        return (
            <ListMenu listaBotones={botones}></ListMenu>
        )
    }

    const iconButtonsItem = [
        { nombre: 'Agregar', visible: single.canCreate(), icono: 'ri-file-add-line', evento: agregarItem }
    ]

    return (
        <>
            <BasicPage titulo="Requerimientos SST">

                <div className="row">
                    <div className="col-md-12">
                        <BasicTable listButtons={listaBotones()} iconButtons={iconButtonsItem} head={head} body={lista} rowSet={setRowBean}></BasicTable>
                    </div>
                </div>
            </BasicPage>
            <ModalConfirm modal={modalConfirm} updateModal={setModalConfirm} eventModal={eliminarItem}>
                {bean.Name}
            </ModalConfirm>
            <Modal modal={modal} updateModal={setModal} eventModal={updateItem}>
                <div className="row">
                    <div className="col-md-12">
                        <label className="form-label">Nombre</label>
                        <input type="text" className="form-control" value={bean.Name} onChange={(e) => { setBean({ ...bean, ['Name']: e.target.value }) }} />
                    </div>
                </div>
            </Modal>
        </>
    )
}