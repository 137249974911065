import { DataBean } from "../DataBean";


export class EmployeeContractHistory extends DataBean {

    public async getEmployeeContractHistoryCatalog(idContractHistory: number): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_getEmployeeContractHistoryCatalog_Number", [idContractHistory]);
    }

    public async updateAppointmentForEmployeeContractHistory(idContractHistory: number, idAppointment: number, idEmployee: number): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "employees.EmployeeContractHistory_updateAppointmentForEmployeeContractHistory_Number_Number_Number", [idContractHistory, idAppointment, idEmployee]);
    }

    public async updateSalaryForEmployeeContractHistory(idContractHistory: number, salary: number, idEmployee: number): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "employees.EmployeeContractHistory_updateSalaryForEmployeeContractHistory_Number_Number_Number", [idContractHistory, salary, idEmployee]);
    }

    public async createEmployeeContractHistory(idContract: number, idAppointment: number | null, from: Date, upto: Date, salary: number | null, idEmployee: number): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "employees.EmployeeContractHistory_createEmployeeContractHistory_Number_Number_java.util.Date_java.util.Date_Number_Number", [idContract, idAppointment, from, upto, salary, idEmployee]);
    }

}