import { useEffect, useState } from "react";
import { env } from "../../../env";

interface Props{
    json:any;
}
export const PrintJson=({json}:Props)=>{

    const print = env.PRINT_LOG ?? false;
    const [miJson,setJson] = useState<any>();

    useEffect(()=>{

            try {
                setJson(JSON.parse(json));    
            } catch (error) {
                setJson(json);
            }
            
        return ()=>{
            setJson(null);
        }

    },[json])

    return(
        <>
        {print &&
            <div><pre>{JSON.stringify(miJson, null, 2) }</pre></div>            
        }
        </>
        
    )

}