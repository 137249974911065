import { DataBean } from "../DataBean";

export class PaymentRequirement extends DataBean {
    
    

    public async getPaymentRequirementCatalogPorPropiedades(properties:any,maxRows:any): Promise<any> {
        return this.getCustomService("FinanceCore", "java.util.List_getPaymentRequirementCatalogPorPropiedades_java.util.Map_Number", [properties,maxRows]);
    }
    public async getPaymentRequirementCatalogPorPropiedadesRender(properties:any,maxRows:any): Promise<any> {
        return this.getCustomService("FinanceCore", "java.util.List_getPaymentRequirementCatalogPorPropiedadesRender_java.util.Map_Number", [properties,maxRows]);
    }

    public async getHiringModalityRender(rows:any,props): Promise<any> {
        return this.getCustomService("FinanceCore", "java.util.List_getHiringModalityRender_Number_java.util.Map", [rows,props]);
    }

}