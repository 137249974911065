import { DataBean } from "../DataBean";


export class TaxScheme extends DataBean {

    public async getTaxSchemeCatalog(idTaxSchemeContext: number, taxSchemeType: number): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_getTaxSchemeCatalog_Number_Number", [idTaxSchemeContext, taxSchemeType]);
    }

    public async getTaxSchemeTypeConstants(arg: number | null): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_getTaxSchemeTypeConstants_Number", [arg]);
    }

    public async getTaxSchemeContextStateConstants(arg: number | null): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_getTaxSchemeContextStateConstants_Number", [arg]);
    }

    public async importTaxSchemeValueCatalog(idTaxScheme: number, filename: string, directory: string, dataStore: null): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_importTaxSchemeValueCatalog_Number_String_String_String", [idTaxScheme, filename, directory, dataStore]);
    }
}