import { useEffect, useState } from "react";
import { Singleton } from "../../../../../../core/services/Singleton";
import { BasicTable, MASK_MONEY } from "../../../../../theme/Component/BasicTable";
import { ListMenu } from "../../../../../theme/Component/ListMenu";
import { Modal } from "../../../../../theme/Component/Modal";
import useDataBean from "../../../../../../core/services/dataBean/useDataBean";
import { _single, _UBLInvoice, _UBLItemInvoice } from "../../../../../../core/services/dataBean/EntityCatalog";
import { SelectAdquisitionArticleConstants } from "../../../../select/SelectAdquisitionArticleConstants";
import { MeasurementUnitCodeEditor } from "../../../../../erp/MeasurementUnitCode/MeasurementUnitCode.editor";
import { SelectSiteID } from "../../../../../admin/configuracion/SiteID/SelectSiteID";
import { SelectAccountantTree } from "../../../../../contabilidad/catalogo-cuentas/AccountantTree/SelectAccountantTree";
import { SelectBudgetID } from "../../../../../admin/configuracion/BudgetID/SelectBudgetID";
import { GoodTypesConstantsEditor } from "./GoodTypesConstants.editor";
import { TaxSchemeValueRadioSelectorByUBLItemInvoice } from "./TaxSchemeValueRadioSelectorByUBLItemInvoice";
import { toast } from "react-toastify";
import { EDITOR_ARTICLE } from "../../../../../editor/Editors";
import { ModalConfirm } from "../../../../../theme/Component/ModalConfirm";
import { InputMoney } from "../../../../../theme/Input/InputMoney";
import { DropDown } from "../../../../../theme/Component/DropDown";
import { CashReimbursementConceptEditor } from "../../../../../Talentohumano/CajasMenoresConceptos/CashReimbursementConcept.editor";
import { BasicButton } from "../../../../../theme/Component/BasicButton";
import { PrintJson } from "../../../../../theme/Component/PrintJson";


interface Props {
    idUBLInvoice: number;
    issueDate: Date;
    _onChange?: Function;
    _data?: any;
    updateFunction?: Function;
}

export const CreateUBLInvoiceItems = ({ idUBLInvoice, issueDate, _data, _onChange, updateFunction }: Props) => {

    const single = Singleton.getInstance();
    const _ublItem = useDataBean(_UBLItemInvoice);
    const _ublInvoice = useDataBean(_UBLInvoice)

    const [missingFields, setMissingFields] = useState<string[]>([]);
    const [taxApply, setTaxApply] = useState<any>([]);
    const [idConceptReimbursement, setIDConceptReimbursement] = useState<number>(NaN);
    const [invoice, setInvoice] = useState<any>({});
    const [observaciones, setObservaciones] = useState<string>("");
    const [errorList, setErrorList] = useState<any>([])

    const [modalC, setModalC] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });
    const [modal, setModal] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-md' });
    const [modalImpuestos, setModalImpuestos] = useState<any>({ name: 'Ver Impuestos', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalAddUBLReimbursment, setModalAddUBLReimbursment] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-md' });
    const [modalReleaseUBLReimbursment, setModalReleaseUBLReimbursment] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });
    const [modalValidateAccounting, setModalValidateAccounting] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-md' });
    const [modalErrorList, setModalErrorList] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-md' });
    const [modalSend, setModalSend] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-md' });

    // useEffect(() => {
    //     getLista();
    //     getInvoice();
    // }, [_data])

    useEffect(() => {
        if (!idUBLInvoice) {
            toast.error("Error alcargar la factura")
        } else if (!issueDate) {
            toast.error("Error alcargar la factura")
        } else {
            getLista();
            getInvoice();
        }
    }, [idUBLInvoice])


    //servicios Basicos UBLInvoiceItem

    const getLista = () => {
        _ublItem.instance.getUBLItemInvoiceCatalog(idUBLInvoice, issueDate).then(
            (resp) => {

                let tmp = resp;
                tmp.forEach((element: any) => {
                    element.DataBeanProperties.Orden = _data ?? {};
                    element.DataBeanProperties.BudgetName = element.DataBeanProperties.BudgetJsconCode + " " + element.DataBeanProperties.BudgetName;
                });
                _ublItem.setLista(tmp)
            }
        )
    }

    const getInvoice = () => {
        _ublInvoice.instance.getUblInvoice(idUBLInvoice, issueDate).then(
            (resp) => {
                setInvoice(resp)
            }
        ).catch(
            err => {
                toast.error(err)
            }
        )
    }

    const create = () => {
        _ublItem.setBean({
            IDUBLInvoice: idUBLInvoice,
            IssueDate: issueDate,
        })
        setModal({ ...modal, "view": true, "name": "Crear Item de Factura" })
    }

    const updateTaxItem = () => {
        _ublItem.instance.parseTaxSchemaValuesForUBLInvoiceItem(_ublItem.bean.IDUBLItemInvoice, issueDate, taxApply).then(
            (resp) => {
                setModalImpuestos({ ...modalImpuestos, "view": false });
                getLista();
            }
        )
    }

    const createItem = () => {
        _ublItem.instance.updateUBLItemInvoice(_ublItem.bean).then(
            (resp) => {
                getLista();
                if (_onChange) {
                    _onChange();
                }
                setModal({ ...modal, "view": false })
            }
        )
    }

    const delteItem = () => {
        _ublItem.instance.deleteUBLItemInvoice(_ublItem.bean.IssueDate, _ublItem.bean.IDUBLItemInvoice).then(
            (resp) => {
                getLista();
                setModalC({ ...modalC, "view": false });

            }
        )
    }

    const validateData = () => {
        if (validateForm() > 0) {
            toast.error("Faltan campos obligatorios por completar");
            return;
        } else {
            createItem();
        }
    }

    const validateForm = () => {
        const requiredFields = [
            "Description", "MeasurementUnitCode", "Quantity", "TotalItemAmount", "IDLnSiteID",
        ];
        let missing = [];
        for (let field of requiredFields) {
            if (!_ublItem.bean[field]) {
                missing.push(field);
            }
        }
        setMissingFields(missing);
        return missing.length
    };


    //fin Servicios UBLInvoiceItem Contrac

    //Inicio Servicios UBLInvoiceItem Cajas Menores
    const addUBLInvoiceItemToReimbusrment = () => {
        _ublItem.instance.addUBLItemInvoiceToCashReimbursementConcept(_data.CashReimbursementDate, _data.IDCashReimbursementItem, _ublItem.bean.IDUBLItemInvoice, idConceptReimbursement).then(
            (resp) => {
                getLista();
                setModalAddUBLReimbursment({ ...modalAddUBLReimbursment, "view": false })
            }
        ).catch(
            err => {
                toast.error(err)
            }
        )
    }

    const deleteUBLInvoiceItemToReimbusrment = () => {
        _ublItem.instance.releaseUBLItemInvoiceToCashReimbursementConcept(_data.CashReimbursementDate, _data.IDCashReimbursementItem, _ublItem.bean.IDUBLItemInvoice).then(
            (resp) => {
                getLista();
                setModalReleaseUBLReimbursment({ ...modalReleaseUBLReimbursment, "view": false })
            }
        ).catch(
            err => {
                toast.error(err)
            }
        )
    }
    //Fin Servicios UBLInvoiceItem Cajas Menores



    const head = [
        { title: 'ID', property: 'IDUBLItemInvoice', visible: true },
        { title: 'Tipo de Bien/Servicio', property: 'AdquisitionArticleTypeName', visible: true },
        { title: 'Nombre del Bien o Servicio', property: 'Description', visible: true },
        { title: 'Unidades', property: 'MeasurementUnitCodeName', visible: true },
        { title: 'Valor Unitario', property: 'UnitaryValue', visible: true, mask: MASK_MONEY },
        { title: 'Cantidad', property: 'Quantity', visible: true },
        { title: 'Subtotal', property: 'PriceValue', visible: true, mask: MASK_MONEY },
        { title: 'Subtotal Impuestos', property: 'TaxAmount', visible: true, mask: MASK_MONEY },
        { title: 'Valor Total', property: 'TotalItemAmount', visible: true, mask: MASK_MONEY },
        { title: 'Ciudad de Bien/Servicio', property: 'SiteIDName', visible: true },
        { title: 'Cuenta Contable', property: 'AccountantIDName', visible: true },
        { title: 'Rubro Presupuestal', property: 'BudgetName', visible: true },
        { title: 'Tipo de Bien', property: 'AssetTypeName', visible: true },
        { title: 'Artículo', property: 'IDAdquisitionArticle', visible: (_data?.IDSupplierPaymentOrder ? true : false && (invoice.ProcessType === 7)), cellEditor: _data ? EDITOR_ARTICLE : undefined },
        { title: 'Rembolso', property: 'IDCashReimbursementConcept', visible: (_data?.IDCashReimbursementItem ? true : false) },
    ]

    const iconButtonsItem = [
        { nombre: 'Agregar', visible: (invoice.SourceType === 7 && (!invoice.ProcessType) && (!_data?.IDSupplierPaymentOrder) && (!_data?.IDCashReimbursementItem)), icono: 'ri-file-add-line', evento: () => { create() } }
    ]

    const listaBotones = () => {
        const botones = [
            { titulo: 'Agregar/Eliminar Impuestos', icono: 'ri-file-list-2-line', visible: (invoice.SourceType === 7 && (!_data?.IDSupplierPaymentOrder) && (!_data?.IDCashReimbursementItem)), evento: () => { setModalImpuestos({ ...modalImpuestos, "view": true }) } },
            { titulo: 'Editar Item de la Factura', icono: 'ri-edit-line', visible: (single.canEdit && (!invoice.ProcessType) && (!_data?.IDSupplierPaymentOrder) && (!_data?.IDCashReimbursementItem)), evento: () => { setModal({ ...modal, "name": `Editar Item ${_ublItem.bean.Description}`, "view": true }) } },
            { titulo: 'Eliminar Item de la Factura', icono: 'ri-delete-bin-2-line', visible: (invoice.SourceType === 7 && (!invoice.ProcessType) && (!_data?.IDSupplierPaymentOrder) && (!_data?.IDCashReimbursementItem)), evento: () => { setModalC({ ...modalC, "name": `Eliminar Item ${_ublItem.bean.Description}`, "view": true }) } },
            { titulo: 'Definir Concepto de Causación', icono: 'ri-file-add-line', visible: (_data?.IDCashReimbursementItem), evento: () => { setModalAddUBLReimbursment({ ...modalAddUBLReimbursment, "name": `Definir Concepto de Causación`, "view": true }) } },
            { titulo: 'Eliminar Concepto de Causación', icono: 'ri-file-reduce-line', visible: (_data?.IDCashReimbursementItem), evento: () => { setModalReleaseUBLReimbursment({ ...modalReleaseUBLReimbursment, "name": `Eliminar Concepto de Causación`, "view": true }) } },
        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }

    const validateInvoice = () => {
        _ublInvoice.instance.requestUBLInvoiceToAccounting(idUBLInvoice, issueDate, single.getAccountID(), observaciones).then(
            (resp) => {
                if (resp.ErrorList.length == 0) {
                    toast.success("Factura correcta");
                    setModalSend({ ...modalSend, "view": true, "name": "Definir envio" })
                } else {
                    setErrorList(resp.ErrorList)
                    setModalErrorList({ ...modalErrorList, "view": true, "name": "Se encontraron las siguientes incidencias con la factura" });
                    toast.warning("Factura con problemas");
                }

                setModalValidateAccounting({ ...modalValidateAccounting, "view": false });
            }
        ).catch(
            err => {
                toast.error(err, { autoClose: false });
            }
        )
    }

    const actionsDropDown = [
        { id: 1, name: 'Validar Contabilización', permiso: null, activo: true },
    ];

    const eventChange = (e: any) => {
        if (e.id === 1) {
            setModalValidateAccounting({ ...modalValidateAccounting, "name": `Validar Contabilización`, "view": true })
        }
    }

    const buttons = () => {
        if (_data?.IDCashReimbursementItem) {
            return (
                <div className="row">
                    <div className="col-md-12">
                        <DropDown lista={actionsDropDown} eventClick={eventChange}></DropDown>
                    </div>
                </div>
            )
        }
    }

    const sendToAccounting = () => {
        _ublItem.instance.sendCashReimbursementItemToAccounting(_data.IDCashReimbursementItem, _data.CashReimbursementDate, "", single.getAccountID()).then(
            (resp) => {
                toast.success("Accion realizada");
                setModalSend({ ...modalSend, "view": false })
                if (updateFunction) {
                    updateFunction();
                }
            }
        ).catch(
            err => {
               console.error(err);
                toast.error(err, { autoClose: false })
            }
        )
    }

    const sendToStore = () => {
        _ublItem.instance.sendCashReimbursementItemToStore(_data.IDCashReimbursementItem, _data.CashReimbursementDate, "", single.getAccountID()).then(
            (resp) => {
                toast.success("Accion realizada");
                setModalSend({ ...modalSend, "view": false })
                if (updateFunction) {
                    updateFunction();
                }
            }
        ).catch(
            err => {
               console.error(err);
                toast.error(err, { autoClose: false })
            }
        )
    }

    return (
        <>
            <div className="row">
                <div className="col-md-6">
                    <strong>Proveedor </strong><small>{invoice.AccountSupplierName}</small>
                </div>
                <div className="col-md-6">
                    <strong>Idenficación </strong><small>{invoice.AccountSupplierNit}</small>
                </div>
                <div className="col-md-6">
                    <strong>Valor </strong><small>{_single.formatMoney(invoice.TotalInvoiceAmount)}</small>
                </div>
                <div className="col-md-6">
                    <strong>Concepto </strong><small>{invoice.AccountantConceptName}</small>
                </div>
                <div className="col-md-6">
                    <strong>Proceso </strong><small>{invoice.ProcessTypeName}</small>
                </div>
                <div className="col-md-6">
                    <strong>ID Factura </strong><small>{invoice.IDUBLInvoice}</small>
                </div>
                <div className="col-md-6">
                    <strong>Fecha Factura </strong><small>{invoice.IssueDate}</small>
                </div>
            </div>
            {(invoice.ProcessType === 7 && _data) ?
                <>
                    {/* Tabla para contratos? */}
                    <BasicTable iconButtons={iconButtonsItem} listButtons={listaBotones()} body={_ublItem.lista} head={head} rowSet={_ublItem.setBean} callBack={getLista} />
                </>
                :
                <>
                    {/* Tabla de InvoiceItems rembolso-normal */}
                    <BasicTable iconButtons={iconButtonsItem} headButtons={buttons()} listButtons={listaBotones()} body={_ublItem.lista} head={head} rowSet={_ublItem.setBean} />
                </>
            }


            {/* Tabla del contrato */}




            {/* Modales basicos de UBLInvoice Items */}
            {modal.view &&
                <Modal modal={modal} updateModal={setModal} eventModal={validateData} >
                    <div className="row">
                        <div className="col-12">
                            <label className={`${missingFields.includes('Description') ? 'has-error' : ''}`}>Nombre del Bien o Servicio</label>
                            <input type="text" disabled={invoice.SourceType !== 7} name="Description" className="form-control" value={_ublItem.bean.Description} onChange={_ublItem.handleChange} />
                        </div>
                        <div className="col-12">
                            <label className={`${missingFields.includes('MeasurementUnitCode') ? 'has-error' : ''}`}>Unidades</label>
                            <MeasurementUnitCodeEditor label=" " idSelector={_ublItem.bean.MeasurementUnitCode} onChange={(e) => { _ublItem.setBean({ ..._ublItem.bean, "MeasurementUnitCode": e }) }} />
                        </div>
                        <div className="col-12">
                            <label>Valor Unitario</label>
                            <input type="number" name="UnitaryValue" className="form-control" value={_ublItem.bean.UnitaryValue} onChange={_ublItem.handleChange} disabled />
                        </div>
                        <div className="col-12">
                            <label className={`${missingFields.includes('Quantity') ? 'has-error' : ''}`}>Cantidad</label>
                            <input disabled={invoice.SourceType !== 7} type="number" name="Quantity" className="form-control" value={_ublItem.bean.Quantity} onChange={_ublItem.handleChange} />
                        </div>
                        <div className="col-12">
                            <label>Subtotal</label>
                            <input type="number" name="PriceValue" className="form-control" value={_ublItem.bean.PriceValue} onChange={_ublItem.handleChange} disabled />
                        </div>
                        <div className="col-12">
                            <label>Subtotal Impuestos</label>
                            <input type="number" name="TaxAmount" className="form-control" value={_ublItem.bean.TaxAmount} onChange={_ublItem.handleChange} disabled />
                        </div>
                        <div className="col-12">
                            <label className={`${missingFields.includes('TotalItemAmount') ? 'has-error' : ''}`}>Valor Total</label>
                            <InputMoney readonly={invoice.SourceType !== 7} id={0} name='TotalValue' value={_ublItem.bean.TotalItemAmount} onChange={(e: any) => { _ublItem.setBean({ ..._ublItem.bean, 'TotalItemAmount': parseInt(e) }) }} />
                        </div>
                        <div className="col-12">
                            <label className={`${missingFields.includes('AdquisitionArticleType') ? 'has-error' : ''}`}>Tipo de Articulo</label>
                            <SelectAdquisitionArticleConstants label=" " idSelector={_ublItem.bean.AdquisitionArticleType} onChange={(e) => { _ublItem.setBean({ ..._ublItem.bean, "AdquisitionArticleType": e }) }} />
                        </div>
                        {_ublItem.bean.AdquisitionArticleType === 0 &&
                            <div className="col-12">
                                <GoodTypesConstantsEditor label="Tipo de Bien" idSelector={_ublItem.bean.AssetType} onChange={(e) => { _ublItem.setBean({ ..._ublItem.bean, "AssetType": e }) }} />
                            </div>
                        }
                        <div className="col-12">
                            <label className={`${missingFields.includes('IDLnSiteID') ? 'has-error' : ''}`}>Ciudad de Bien/Servicio</label>
                            <SelectSiteID label=" " edit onChange={(e) => { _ublItem.setBean({ ..._ublItem.bean, "IDLnSiteID": e.IDLn }) }} idLnSite={_ublItem.bean.IDLnSiteID} />
                        </div>
                        {/* <div className="col-12">
                            <SelectAccountantTree label="Cuenta Contable" renderValue={_ublItem.bean.AccountantIDName} onChange={(e) => { _ublItem.setBean({ ..._ublItem.bean, "AccountantID": e.JsonCode }) }} />
                        </div>
                        <div className="col-12">
                            <SelectBudgetID label="Rubro Presupuestal" edit={false} tipoPresupuesto={0} onChange={(e) => { _ublItem.setBean({ ..._ublItem.bean, "BudgetID": e.JsonCode }) }} jsonCode={_ublItem.bean.BudgetID} />
                        </div> */}
                    </div>
                </Modal>
            }

            {modalC.view &&
                <ModalConfirm eventModal={delteItem} modal={modalC} updateModal={setModalC}>
                    <p>Eliminar Item</p>
                </ModalConfirm>
            }

            {(modalImpuestos.view && invoice.SourceType) &&
                <Modal modal={modalImpuestos} updateModal={setModalImpuestos} eventModal={() => { updateTaxItem() }} >
                    <>
                        <TaxSchemeValueRadioSelectorByUBLItemInvoice _UBLinvsourceType={invoice.SourceType} idUBLItem={_ublItem.bean.IDUBLItemInvoice} issueDate={issueDate} onChange={(e) => { setTaxApply(e) }} />
                    </>
                </Modal>
            }

            {modalAddUBLReimbursment.view &&
                <Modal modal={modalAddUBLReimbursment} updateModal={setModalAddUBLReimbursment} eventModal={() => { addUBLInvoiceItemToReimbusrment() }} >
                    <>
                        <CashReimbursementConceptEditor idSelector={idConceptReimbursement} onChange={(e) => { setIDConceptReimbursement(e) }} label="Conceptos" />
                    </>
                </Modal>
            }

            {modalReleaseUBLReimbursment.view &&
                <Modal modal={modalReleaseUBLReimbursment} updateModal={setModalReleaseUBLReimbursment} eventModal={() => { deleteUBLInvoiceItemToReimbusrment() }} >
                    <>
                        Esta seguro que desea Eliminar el concepto de Causación
                    </>
                </Modal>
            }

            {modalValidateAccounting.view &&
                <Modal modal={modalValidateAccounting} onSubmitLabel="Validar" updateModal={setModalValidateAccounting} eventModal={() => { validateInvoice() }} >
                    <>
                        <p>
                            Confirme si desea continuar con la validacion de la factura para Contabilización
                        </p>
                    </>
                </Modal>
            }

            {modalSend.view &&
                <Modal modal={modalSend} updateModal={setModalSend}  >
                    <>
                        <div className="row">
                            <div className="col-12">
                                <label>Observaciones</label>
                                <textarea value={observaciones} rows={4} onChange={(e) => { setObservaciones(e.target.value) }} className="form-control" />
                            </div>
                            <div className="col-md-12">
                                <div className="d-flex justify-content-between p-2">
                                    <BasicButton icon="" eventClick={sendToStore}>Enviarlo a Almacen</BasicButton>
                                    <BasicButton icon="" eventClick={sendToAccounting}>Enviarlo a Contabilización</BasicButton>
                                </div>
                            </div>
                        </div>
                    </>
                </Modal>
            }



            {modalErrorList.view &&
                <Modal modal={modalErrorList} updateModal={setModalErrorList}  >
                    <div className="col-md-12">
                        <hr />
                        <ul className="list-group">
                            {
                                errorList.map((item: any, index: number) => {
                                    return (
                                        <li className="list-group-item">
                                            {index + 1} - {item}
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                </Modal>
            }


        </>
    )
}