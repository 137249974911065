import { useState, useEffect } from 'react';
import useAccountantTree, { VIEW_EDITOR } from './useAccountantTree';
import { Singleton } from '../../../core/services/Singleton';
import useModal, { MODAL_LG, MODAL_SM } from '../../theme/Component/hooks/useModal';
import { toast } from 'react-toastify';
import { Modal } from '../../theme/Component/Modal';
import { SelectBudgetID } from '../../admin/configuracion/BudgetID/SelectBudgetID';
import { BasicButton } from '../../theme/Component/BasicButton';
import { ModalConfirm } from '../../theme/Component/ModalConfirm';
import { ArticleClassCode } from '../../../core/services/dataBean/contabilidad/ArticleClassCode.Entity';
import useDataBean from '../../../core/services/dataBean/useDataBean';
import { PrintJson } from '../../theme/Component/PrintJson';
import { SelectAccountantTree } from '../catalogo-cuentas/AccountantTree/SelectAccountantTree';
import { SelectUnspscID } from '../../admin/configuracion/UnspscID/SelectUnspscID';
import { _single } from '../../../core/services/dataBean/EntityCatalog';


interface Props {
    id: number;
    idAccountantIDType: number;
    bean: any;
    updateEvent: any;

}
export const ButtonArticleClassCodeTree = ({ id, idAccountantIDType, bean, updateEvent }: Props) => {

    const single = Singleton.getInstance();
    const { makeList, makeCodeFormat, createAccountantID, cargarChilds, accountantID } = useAccountantTree(VIEW_EDITOR);
    const articleClassCode = new ArticleClassCode("QuickDataERPCore", "com.quickdataerp.bean.supplier.ArticleClassCode");
    const tree = useDataBean(articleClassCode);


    const [miBean, setMibean] = useState<any>({});
    const [cuenta, setCuenta] = useState<number>(1);
    const [nombre, setNombre] = useState<string>('');

    const [cuentaFormat, setCuentaFomat] = useState<string>('');
    const [goodConstants, setGoodConstants] = useState<any>([]);

    const modalCrear = useModal("Crear cuenta", MODAL_LG);
    const confirmDelete = useModal("Cuenta contable", MODAL_SM);


    useEffect(() => {
        setCuenta(bean.ArticleClassCode);
        setNombre(bean.Name);
        setMibean(bean);
        getGoodTypesConstants();
    }, [bean])
    useEffect(() => {
        setMibean({ ...miBean, "Name": nombre });
    }, [nombre])



    useEffect(() => {


        if (cuenta >= 1) {
            let format = makeCodeFormat(cuenta);
            if (format)
                setCuentaFomat(format);
            else {
                setCuentaFomat("##");
                // toast.warning("La cuenta es muy larga");
            }

        }
        setMibean({ ...miBean, "ArticleClassCode": cuenta });
    }, [cuenta])



    const createAccountant = () => {
        let lista = makeList(cuenta);
       //console.log(lista);
        if (lista.length > 0 && nombre != '') {

            createAccountantID(idAccountantIDType, lista, nombre).then((resp: any) => {
               //console.log("Nuevo hijo ", resp);
                updateEvent(resp);

            })
        } else {
            toast.error("Datos incompletos");
        }

    }
    const updateAccountant = () => {

        let b = miBean;
        accountantID.updateCustonBean(b).then(
            (resp: any) => {
                updateEvent();
            }
        )

    }
    const deleteAccountant = (idln: number) => {
        accountantID.deleteItem(idln).then(
            (resp: any) => {
                updateEvent(true);
            }
        )

    }
    const getGoodTypesConstants = () => {
        let tmp = _single.getCookie('gootTypes');
        if (tmp) {
            setGoodConstants(JSON.parse(tmp));

        } else {
            tree.instance.getGoodTypesConstants(null).then(
                (resp: any) => {
                    setGoodConstants(resp);
                    _single.setCookie('goodTypes', JSON.stringify(resp), 1);
                }
            ).catch(err =>console.error(err))

        }

    }

    return (
        <>

            {single.canCreate() &&
                <i className="ri-file-add-line cursor btnicon_sm" title="Agregar Hijo" onClick={() => {
                    modalCrear.open();
                    setMibean({});
                }}></i>
            }
            {(single.canDelete() && bean.IDLn) &&
                <i className="ri-file-reduce-line cursor btnicon_sm" title="Eliminar" onClick={() => { confirmDelete.open(); }}></i>
            }
            {(single.canEdit() && bean.IDLn) &&
                <i className="ri-edit-2-line cursor btnicon_sm" title="Editar" onClick={() => {
                    modalCrear.open();
                    setMibean(bean);
                }}></i>
            }

            <Modal modal={modalCrear.modal} updateModal={modalCrear.setModal}>
                <div className="row">

                    <div className="col-md-6">
                        <label>Código : <strong>{cuentaFormat}</strong> </label>
                        <input type="number" className="form-control" value={cuenta} onChange={(e) => { setCuenta(parseInt(e.target.value)) }} />
                    </div>
                    <div className="col-md-6">
                        <label> Nombre cuenta </label>
                        <input type="text" className="form-control" value={nombre} onChange={(e) => { setNombre(e.target.value) }} />
                    </div>
                    {miBean.IDLn &&
                        <>
                            <div className="col-md-12">
                                <label>Descripción</label>
                                <textarea name="Description" className='form-control' rows={3} value={miBean.Description}></textarea>
                            </div>
                            <div className="col-md-6">
                                <label>Código</label>
                                <input type="text" name="JsonCode" className='form-control' readOnly value={miBean.JsonCode} />
                            </div>
                            <div className="col-md-6">
                                <SelectAccountantTree jsonCode={miBean.AccountantIDStr} label='Cuenta Contable' onChange={(e) => { setMibean({ ...bean, "AccountantIDStr": e.JsonCode }) }} />
                            </div>
                            <div className="col-md-6">
                                <SelectBudgetID edit={false} tipoPresupuesto={0} jsonCode={miBean.BudgetIDStr} onChange={(e) => { setMibean({ ...bean, "BudgetIDStr": e.JsonCode }) }} />
                            </div>
                            <div className="col-md-6">
                                <SelectUnspscID renderValue={''} onChange={(e) => { setMibean({ ...bean, "IDLnUnspscID": e.IDLn }) }} />
                            </div>
                            {miBean.Type === 0 &&
                                <div className="col-md-6">
                                    <label>Tipo de Activo</label>
                                    <select name="AssetType" className='form-select' value={miBean.AssetType} onChange={(e) => { setMibean({ ...bean, "AssetType": e.target.value }) }}>
                                        {goodConstants.map((item: any, index: number) => {
                                            return (
                                                <option value={item.Value}>{item.Property}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                            }
                        </>
                    }


                    {miBean.IDLn ?
                        <div className="col-md-12">
                            <BasicButton disable={(cuenta === 0 && nombre === '')} icon="ri-edit-line" eventClick={updateAccountant}>Editar</BasicButton>
                        </div>
                        :
                        <div className="col-md-12">
                            <BasicButton disable={(cuenta === 0 && nombre === '')} icon="ri-edit-line" eventClick={createAccountant}>Crear</BasicButton>
                        </div>
                    }
                    <PrintJson json={miBean} />
                </div>
            </Modal>
            <ModalConfirm modal={confirmDelete.modal} updateModal={confirmDelete.setModal} eventModal={deleteAccountant}>
                <strong>Eliminar cuenta {bean.Name}</strong>
            </ModalConfirm>




        </>
    )
}