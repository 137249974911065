import { useEffect, useState } from "react";

import useDataBean from "../../../core/services/dataBean/useDataBean";
import { CashReimbursementServices } from "../cellEditor/CashDesk/CashReimbursement/CashReimbursement-service";
import { toast } from "react-toastify";
import { Singleton } from "../../../core/services/Singleton";
import { ListMenu } from "../../theme/Component/ListMenu";
import { BasicTable, MASK_DATE, MASK_NUMBER } from "../../theme/Component/BasicTable";
import { BasicPage } from "../../theme/Component/BasicPage";
import { Modal } from "../../theme/Component/Modal";
import { SelectAccountantTree } from "../../contabilidad/catalogo-cuentas/AccountantTree/SelectAccountantTree";
import { PrintJson } from "../../theme/Component/PrintJson";
import { ModalConfirm } from "../../theme/Component/ModalConfirm";
import { _AccountantConcept, _FiscalResponsability, _FiscalResponsabilityType } from "../../../core/services/dataBean/EntityCatalog";
import { InputDate } from "../../theme/Input/InputDate";
import { BasicSwitch } from "../../theme/Component/BasicSwitch";
import { AccountEditor } from "../../editor/AccountEditor";
import { FiscalEditor } from "./FiscalEditor";



export const FiscalResponsability = () => {

    const FiscalResponsability = useDataBean(_FiscalResponsability);

    const single = Singleton.getInstance();
    const service = new CashReimbursementServices();

    const [lista, setLista] = useState<any>([]);
    const [listadocs, setlistadocs] = useState([]);
    const [IdAcoount, setIdAcoount] = useState<number>(null)
    const [modal, setModal] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalC, setModalC] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });

    useEffect(() => {
        getLista();

    }, [IdAcoount])


    const getLista = () => {

        FiscalResponsability.instance.getFiscalResponsabilityCatalog(IdAcoount).then(
            (resp: any) => {
                if (resp) {
                    setLista(resp)
                   //console.log(resp)
                }
            }
        )

    }

    const create = () => {
        FiscalResponsability.setBean({
            IDFiscalResponsabilityType: FiscalResponsability.bean.IDFiscalResponsabilityType,
            IDAccount: IdAcoount,
            UptoDate: single.getCurrenDate(),
            FromDate: single.getCurrenDate(),
            isActive: undefined

        });

        setModal({ ...modal, "view": true });
    }


    const deleteItem = () => {
        FiscalResponsability.deleteItemVoid(FiscalResponsability.bean.IDFiscalResponsability).then(
            (resp: any) => {
                getLista();
                setModalC({ ...modalC, "view": false });
            }
        )

    }
    const editaItem = () => {
       //console.log(FiscalResponsability.bean);
        FiscalResponsability.update().then(
            (resp: any) => {
                getLista();
                setModal({ ...modal, "view": false });
            }
        ).catch(err => {
            toast.error(err);
        })

    }

    const head = [
        { title: 'ID', property: 'IDFiscalResponsability', visible: true, mask: MASK_NUMBER },
        { title: 'Nombre de Responsabilidad', property: 'Name', visible: true },
        { title: 'Código Responsabilidad Fiscal', property: 'Code', visible: true },
        { title: 'Fecha Inicio', property: 'FromDate', visible: true, mask: MASK_DATE },
        { title: 'Fin de responsabilidad fiscal', property: 'UptoDate', visible: true, mask: MASK_DATE },
        // { title: 'Tercero que  Edita', property: 'IDAccount', visible: true }


    ]

    const iconButtonsItem = [
        { nombre: 'Agregar', visible: single.canCreate(), icono: 'ri-file-add-line', evento: create }
    ]
    const listaBotones = () => {
        const botones = [

            { titulo: 'Editar', icono: 'ri-edit-2-line', visible: single.canEdit(), evento: () => { setModal({ ...modal, "view": true }) } },
            { titulo: 'Eliminar', icono: 'ri-delete-bin-line', visible: single.canDelete(), evento: () => { setModalC({ ...modalC, "view": true }) } },

        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }



    return (
        <BasicPage titulo="Configurar Tipos de Responsabilidad por Tercero">
            <div className="row">
                <div className="col-md-12">
                    <AccountEditor label="Asignar responsabilidades fiscales a un tercero" idAccount={IdAcoount} onChange={(e) => { setIdAcoount(e.IDAccount) }} />
                </div>
                <div className="col-md-12">

                    <BasicTable body={lista} head={head} rowSet={FiscalResponsability.setBean} listButtons={listaBotones()} iconButtons={iconButtonsItem}></BasicTable>
                    <Modal modal={modal} updateModal={setModal} eventModal={editaItem}>
                        <>
                            <div className="row">
                                {/* <AccountEditor label="Tercero que esta editando" idAccount={FiscalResponsability.bean.IDAccount} onChange={(e: any) => { FiscalResponsability.setBean({ ...FiscalResponsability.bean, "IDAccount": e.IDAccount }) }} /> */}
                                <div className="col-md-12">
                                    <FiscalEditor idSelector={FiscalResponsability.bean.IDFiscalResponsabilityType} onChange={(e) => { FiscalResponsability.setBean({ ...FiscalResponsability.bean, "IDFiscalResponsabilityType": e }) }} />
                                </div>
                                <div className="col-md-6">
                                    <label>fecha inicial</label>
                                    <InputDate label="Desde" name="FromDate" value={FiscalResponsability.bean.FromDate} setDate={(e: any) => { FiscalResponsability.setBean({ ...FiscalResponsability.bean, 'FromDate': e }) }} ></InputDate>

                                </div>
                                <div className="col-md-6">
                                    <label>fecha Final</label>
                                    <InputDate label="Desde" name="UptoDate" value={FiscalResponsability.bean.UptoDate} setDate={(e: any) => { FiscalResponsability.setBean({ ...FiscalResponsability.bean, 'UptoDate': e }) }} ></InputDate>

                                </div>
                                <div className="col-md-6">
                                    <label>Estado</label>
                                    <BasicSwitch estado={FiscalResponsability.bean.isActive} label="isActive" eventChange={(e: any) => {
                                        FiscalResponsability.setBean({ ...FiscalResponsability.bean, "isActive": e })
                                    }}></BasicSwitch>
                                </div>
                            </div>

                        </>
                    </Modal>

                    <ModalConfirm modal={modalC} updateModal={setModalC} eventModal={deleteItem}>
                        <strong>Eliminar item</strong>
                    </ModalConfirm>
                </div>

            </div>
        </BasicPage>
    )
}