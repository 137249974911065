import { useState, useEffect } from 'react';
import { FinanceService } from '../../../../core/services/FinanceService';
import { _single } from '../../../../core/services/dataBean/EntityCatalog';
import useDataBean from '../../../../core/services/dataBean/useDataBean';
import { _Product } from '../../../../core/services/dataBean/EntityCatalogVdos';
import { Modal } from '../../../theme/Component/Modal';



interface Props {
    idSelector: number;
    onChange: any;
    titulo?: string;
}

export const SelectorUmeasurement = ({ titulo, idSelector, onChange }: Props) => {

    const service = new FinanceService();
    const ProductEditor = useDataBean(_Product);
    const [lista, setLista] = useState([]);
    const [id, setID] = useState(0);
    const [modalUnit, setModalUnit] = useState<any>({ name: "Agregar Unidad", tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-md' });

    useEffect(() => {
        getLista();
    }, [idSelector])

    useEffect(() => {
        onChange(id);
    }, [id])

    const setValue = (e: any) => {
        setID(e);
        onChange(id);
    }

    const getLista = () => {
        let tmp = _single.getCacheItem('unit');
        if (tmp) {
            setLista(tmp);
        } else {
            service.getMeasurementUnitCatalog(null, null).subscribe(
                (resp: any) => {
                    if (resp.DataBeanProperties.ObjectValue.DataBeanProperties.List) {
                        let temp = resp.DataBeanProperties.ObjectValue.DataBeanProperties.List;
                        temp = temp.reverse();
                        setLista(temp);
                        _single.setCacheItem('unit', temp);
                    }
                }
            );
        }

    }
    const Unidad = () => {
        let b = {
            Name: '',
            Code: '',
            Since: _single.getCurrenDate(),
        }
        ProductEditor.setBean(b);

        setModalUnit({ ...modalUnit, "view": true });

    }
    const AgregarUnidad = () => {

        _single.setCacheItem('unit',null);
        _single.spinner(true);
        service.updateMeasurementUnit(ProductEditor.bean).subscribe(
            (resp: any) => {
                
                setModalUnit({ ...modalUnit, "view": false });
                getLista();
                _single.spinner(false);
            }
        )


    }

    return (
        <>
            <label>Unidades de Medida</label>
            <div className="input-group">

                <select className="form-select" value={idSelector} onChange={(e) => { setValue(parseInt(e.target.value)) }}>
                    <option value={null}>...</option>
                    {lista &&
                        lista.map((item: any) => {
                            return (
                                <option key={item.DataBeanProperties.IDMeasurementUnit} value={item.DataBeanProperties.IDMeasurementUnit} >{item.DataBeanProperties.Name}</option>
                            )
                        })
                    }
                </select>

                <button className="btn btn-outline-primary shadow-none" type="button" onClick={Unidad}>+</button>
            </div>
            <Modal modal={modalUnit} updateModal={setModalUnit} eventModal={AgregarUnidad}>
                <div className="row">
                    <div className="col-md-12">
                        <label className="form-label">Código</label>
                        <input type="text" className="form-control" value={ProductEditor.bean.Code} onChange={(e) => { ProductEditor.setBean({ ...ProductEditor.bean, ['Code']: e.target.value }) }} />
                    </div>
                    <div className="col-md-12">
                        <label className="form-label">Nombre</label>
                        <input type="text" className="form-control" value={ProductEditor.bean.Name} onChange={(e) => { ProductEditor.setBean({ ...ProductEditor.bean, ['Name']: e.target.value }) }} />
                    </div>


                </div>
            </Modal>
        </>
    )
}