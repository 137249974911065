import React, { useEffect, useRef, useState } from 'react';
import { FileService } from '../../../core/services/FileService';
import { PrintJson } from './PrintJson';
import { _single } from '../../../core/services/dataBean/EntityCatalog';

interface Props {
    uploadFile: any;
    directory:string;
}
const CameraCapture = ({ uploadFile,directory }: Props) => {
    const videoRef = useRef<HTMLVideoElement | null>(null);
    const canvasRef = useRef<HTMLCanvasElement | null>(null);
    const [imageFile, setImageFile] = useState<File | null>(null);
    const [stream, setStream] = useState<MediaStream | null>(null);
    const [fileMap,setFileMap] = useState<any>({});

    const fileService = new FileService();

    useEffect(()=>{

        if(fileMap.Media)
        {
            uploadFile(fileMap);
        }
    },[fileMap])

    // Iniciar la cámara
    const startCamera = async () => {
        try {
            const mediaStream = await navigator.mediaDevices.getUserMedia({ video: true });
            if (videoRef.current) {
                videoRef.current.srcObject = mediaStream;
                setStream(mediaStream); // Guardamos el stream para poder detenerlo más tarde
            }
        } catch (error) {
            console.error("Error al acceder a la cámara:", error);
        }
    };

    // Detener la cámara
    const stopCamera = () => {
        if (stream) {
            stream.getTracks().forEach((track) => track.stop()); // Detenemos todas las pistas del stream
        }
    };

    // Capturar imagen y detener la cámara después
    const captureImage = () => {
        if (videoRef.current && canvasRef.current) {
            const context = canvasRef.current.getContext('2d');
            if (context) {
                canvasRef.current.width = videoRef.current.videoWidth;
                canvasRef.current.height = videoRef.current.videoHeight;
                context.drawImage(videoRef.current, 0, 0, canvasRef.current.width, canvasRef.current.height);

                // Convertir el canvas a Blob y luego a File
                canvasRef.current.toBlob((blob) => {
                    if (blob) {
                        const file = new File([blob], "photo.jpg", { type: "image/jpeg" });
                        setImageFile(file);
                        _single.spinner(true);
                        fileService.postFile(file, directory).subscribe(
                            (resp: any) => {
                                if(resp.DataBeanProperties.ObjectValue)
                                    {
                                        setFileMap(resp.DataBeanProperties.ObjectValue.DataBeanProperties);
                                        
                                    }
                                    _single.spinner(false);
                                
                            }
                        )
                    }
                }, 'image/jpeg');

                stopCamera(); // Detener la cámara después de capturar la imagen
            }
        }
    };

    // Subir imagen al servidor
    const uploadImage = async () => {
        if (!imageFile) return;

        const formData = new FormData();
        formData.append("file", imageFile);

        try {
            const response = await fetch('https://tu-servidor.com/upload', {
                method: 'POST',
                body: formData,
            });

            if (response.ok) {
               //console.log("Imagen subida con éxito");
            } else {
                console.error("Error al subir la imagen");
            }
        } catch (error) {
            console.error("Error en la solicitud:", error);
        }
    };

    // Detener la cámara cuando el componente se desmonte
    useEffect(() => {
        return () => {
            stopCamera();
        };
    }, [stream]); // Se ejecutará cuando el stream cambie o cuando el componente se desmonte

    return (
        <div className='row'>
            <div className="col-md-6">
                <video style={{maxWidth:'350px'}} ref={videoRef} autoPlay />
            </div>
            <div className="col-md-6">
                {fileMap.Media &&
                <img style={{maxWidth:'350px'}} src={fileMap.URL} alt="Imagen usuario" className='img'/>
                }
                {/* <PrintJson json={fileMap}/> */}
            </div>
            <div className="col-md-12">
                <div className='d-flex flex-row'>
                    <button className='btn btn promary' onClick={startCamera}>Iniciar Cámara</button>
                    <button className='btn btn promary' onClick={captureImage}>Capturar Imagen</button>
                    {/* <button className='btn btn promary' onClick={uploadImage} disabled={!imageFile}>Subir Imagen</button> */}
                </div>
            </div>
            <canvas ref={canvasRef} style={{ display: 'none' }} />
        </div>
    );
};

export default CameraCapture;
