import { useEffect, useState } from 'react';
import { BasicButton } from '../../../theme/Component/BasicButton';
import { BasicPage } from '../../../theme/Component/BasicPage';
import { BasicTable } from '../../../theme/Component/BasicTable';
import { ListMenu } from '../../../theme/Component/ListMenu';
import { Modal } from '../../../theme/Component/Modal';
import { ModalConfirm } from '../../../theme/Component/ModalConfirm';
import { AccountantBookEditor } from '../AccountantBook/AccountantBookEditor';
import './Company-css.css';
import { CompanyService } from './Company-service';
import { Singleton } from '../../../../core/services/Singleton';
import { useDashboardStore } from '../../../pages/hooks/useDashboardStore';
import { AccountEditor } from '../../../editor/AccountEditor';
import { FileInput } from '../../../theme/Component/FileInput';

export const Company = () => {
    const [bean, setBean] = useState<any>({});
    const [lista, setLista] = useState([]);
    const [modalcaracttramites, setModalcaracttramites] = useState<any>({ name: "Caracterizaciones Para Prueba", tabIndex: -1, view: false, btnClose: true, btnSubmit: false, format: "modal-md", });
    const [modala, setModala] = useState<any>({ name: "Agregar Item", tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: "modal-lg" });
    const [meliminar, setMeliminar] = useState<any>({ name: "Confirmar", tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: "modal-sm" });
    const single = Singleton.getInstance()
    const { setLoading } = useDashboardStore();
    const service = new CompanyService();

    const idBean = "IDCompany";

    const setRowBean = (bean: any) => {
        setBean(bean);
       //console.log(bean)
    };

    useEffect(() => {
        getCompanyCatalog();
    }, [])

    useEffect(() => {
        if (bean.IDCompany) {
            bean.DocTemplateJsonProperties ? setBean({ ...bean, "DocTemplateJsonProperties": JSON.stringify(bean.DocTemplateJsonProperties) }) : setBean({ ...bean, "HeaderImageJsonProperties": "" });
            bean.FooterImageJsonProperties ? setBean({ ...bean, "FooterImageJsonProperties": JSON.stringify(bean.FooterImageJsonProperties) }) : setBean({ ...bean, "HeaderImageJsonProperties": "" });
            bean.HeaderImageJsonProperties ? setBean({ ...bean, "HeaderImageJsonProperties": JSON.stringify(bean.HeaderImageJsonProperties) }) : setBean({ ...bean, "HeaderImageJsonProperties": "" });
        }
    }, [bean.IDCompany])

    const agregarItem = () => {
        let b = {
            Name: "",
            Description: "",
            // IDBusinessClass: idModule,
            Code: 0,
            IDCompany: "",
            CountryCode: 0,
            IDAccount: 6139,
            IDAccountRepresentative: 6139,
            IDAccountantBook: 1,
            HeaderReportName: "",
            DocTemplateMedia: null,
            DocTemplateMediaContext: null,
            HeaderImageMedia: null,
            HeaderImageMediaContext: null,
            FooterImageMedia: null,
            FooterImageMediaContext: null,
            FontMediaContext: null,
            FontMedia: "",
            HeaderImageJsonProperties: "",
            FooterImageJsonProperties: "",
            DocTemplateJsonProperties: "",
        };
        setBean(b);
        setModala({ ...modala, "view": true });
    };

    const getCompanyCatalog = () => {
        setLoading(true);
        service
            .getCompanyCatalog(null)
            .subscribe((resp: any) => {
                if (resp.DataBeanProperties.ObjectValue) {
                    setLista(resp.DataBeanProperties.ObjectValue);
                }
                setLoading(false);
            });
    };

    const caracterizaciontramites = () => {
        setModalcaracttramites({ ...modalcaracttramites, 'view': true });
    }

    const eliminarItem = () => {
        setLoading(true);
        service.deleteCompany(bean[idBean]).subscribe(
            (resp: any) => {
                setLoading(false);
                if (resp) {
                    getCompanyCatalog();
                }
                single.delete(bean[idBean], bean);
            }
        );
        setMeliminar({ ...meliminar, 'view': false });
    }

    const updateItem = () => {
        setModala({ ...modala, 'view': false });
        setLoading(true);
        service.updateCompany(bean).subscribe((resp: any) => {
            setLoading(false);
            if (resp.DataBeanProperties.ObjectValue) {
                getCompanyCatalog();
            }
            single.update(bean);
        });
    };


    const head = [
        { title: "ID ", property: idBean },
        // { title: "ID compañia", property: "IDCompany" },
        { title: "Código País", property: "CountryCode" },
        { title: "Nombre de la Compañía", property: "Name" },
        { title: "Descripción", property: "Description" },
        { title: "Tercero de la Empresa", property: "IDAccount" },
        { title: "Código de la Compañía", property: "Code" },
        { title: "Tercero Representante Legal", property: "IDAccountRepresentative" },
        { title: "Cargar archivo Plantilla", property: "DocTemplateMedia" },
        { title: "Imágen Header Reportes", property: "HeaderImageMedia" },
        { title: "Imágen Footer Reportes", property: "FooterImageMedia" },
        { title: "Título Reportes", property: "HeaderReportName" },
        { title: "Fuente de Reportes", property: "FontMedia" },
        { title: "Json Header", property: "HeaderImageJsonProperties" },
        { title: "Json Footer", property: "FooterImageJsonProperties" },
        { title: "Json Configurar Reportes", property: "DocTemplateJsonProperties" },
        { title: "Fecha de Creación", property: "Since" },

    ];

    const renderButton = () => {
        return (
            <>
                <div className="row">
                    <div className="col-md-12">
                        <BasicButton
                            value="Agregar"
                            icon="ri-file-add-line"
                            eventClick={agregarItem}
                        ></BasicButton>
                        {bean[idBean] && (
                            <>
                                <BasicButton
                                    value="Editar"
                                    icon="ri-edit-2-line"
                                    eventClick={''}
                                ></BasicButton>
                                <BasicButton
                                    value="Eliminar"
                                    icon="ri-eraser-line"
                                    eventClick={() => {
                                        setMeliminar({ ...meliminar, ["view"]: true });
                                    }}
                                ></BasicButton>
                                <BasicButton
                                    value="Caracterizaciones"
                                    icon="ri-edit-2-line"
                                    eventClick={caracterizaciontramites}
                                ></BasicButton>
                            </>
                        )}
                    </div>
                </div>
            </>
        );
    };

    const listaBotones = () => {
        const botones = [
            {
                titulo: "Editar",
                icono: "ri-file-list-line",
                visible: true,
                evento: () => {
                    setModala({ ...modala, view: true, name: 'Editar' });
                },
            },
            {
                titulo: "Eliminar",
                icono: "ri-file-user-line",
                visible: true,
                evento: () => {
                    setMeliminar({ ...meliminar, view: true });
                },
            },

        ];
        return <ListMenu listaBotones={botones} />;
    };

    const iconButtonsItem = [
        {
            nombre: "Agregar",
            visible: single.canCreate(),
            icono: "ri-file-add-line",
            evento: agregarItem,
        },
    ];

    return (
        <>
            <BasicPage titulo="Compañias">
                <div className="row">
                    <div className="col-md-12 p-2">
                        <BasicTable listButtons={listaBotones()} iconButtons={iconButtonsItem} head={head} body={lista} rowSet={setRowBean}></BasicTable>
                    </div>
                </div>
            </BasicPage>
            <ModalConfirm modal={meliminar} updateModal={setMeliminar} eventModal={eliminarItem}>
                {bean.name}
            </ModalConfirm>

            <Modal modal={modala} updateModal={setModala} eventModal={updateItem}>
                <div className="row">
                    <div className="col-md-6">
                        <label className="form-label">Código de la Compañía</label>
                        <input type="text" className="form-control" value={bean.Code} onChange={(e) => { setBean({ ...bean, 'Code': e.target.value }) }} />
                    </div>
                    <div className="col-md-6">
                        <label className="form-label">Código País</label>
                        <input type="text" className="form-control" value={bean.CountryCode} onChange={(e) => { setBean({ ...bean, 'CountryCode': e.target.value }) }} />
                    </div>
                    <div className="col-md-6">
                        <label className="form-label">Nombre de la Compañía</label>
                        <input type="text" className="form-control" value={bean.Name} onChange={(e) => { setBean({ ...bean, 'Name': e.target.value }) }} />
                    </div>

                    <div className="col-md-12">
                        <label className="form-label">Descripción</label>
                        <textarea className="form-control" value={bean.Description} onChange={(e) => { setBean({ ...bean, 'Description': e.target.value }) }}></textarea>
                    </div>
                    <div className="col-md-12">
                        <label className="form-label">Título Reportes</label>
                        <textarea className="form-control" value={bean.HeaderReportName} onChange={(e) => { setBean({ ...bean, 'HeaderReportName': e.target.value }) }}></textarea>
                    </div>
                    <div className="col-md-6">
                        <label className="form-label">Tercero de la Empresa</label>
                        <AccountEditor idAccount={bean.IDAccount} label="Funcionario" onChange={({ IDAccount }: any) => { setBean({ ...bean, "IDAccount": IDAccount }) }} />
                    </div>
                    <div className="col-md-6">
                        <label className="form-label">Tercero Representante Legal</label>
                        <AccountEditor idAccount={bean.IDAccountRepresentative} label="Funcionario" onChange={({ IDAccount }: any) => { setBean({ ...bean, "IDAccountRepresentative": IDAccount }) }} />
                    </div>
                    <div className="col-md-6">
                        <label className="form-label">Libro Contable</label>
                        <AccountantBookEditor id={bean.IDAccountantBook} onchange={(e: any) => { setBean({ ...bean, 'IDAccountantBook': e }) }}></AccountantBookEditor>
                    </div>
                    <div className="col-md-6">
                        <label className="form-label"></label>
                        <FileInput
                            Media={bean.DocTemplateMedia}
                            MediaContext={bean.DocTemplateMediaContext}
                            directory='Template'
                            label='Cargar archivo Plantilla'

                            onCreate={(Filemap: any) => { setBean({ ...bean, "DocTemplateMedia": Filemap.Media, "DocTemplateMediaContext": Filemap.MediaContext }); }}
                        ></FileInput>
                    </div>
                    <div className="col-md-6">
                        <label className="form-label"></label>
                        <FileInput
                            Media={bean.HeaderImageMedia}
                            MediaContext={bean.HeaderImageMediaContext}
                            directory='Template'
                            label='Imágen de Header para Reporte'

                            onCreate={(Filemap: any) => { setBean({ ...bean, "HeaderImageMedia": Filemap.Media, "HeaderImageMediaContext": Filemap.MediaContext }); }}
                        ></FileInput>
                    </div>
                    <div className="col-md-6">
                        <label className="form-label"></label>
                        <FileInput
                            Media={bean.FooterImageMedia}
                            MediaContext={bean.FooterImageMediaContext}
                            directory='Template'
                            label='Imágen de Footer para Reportes'

                            onCreate={(Filemap: any) => { setBean({ ...bean, "FooterImageMedia": Filemap.Media, "FooterImageMediaContext": Filemap.MediaContext }); }}
                        ></FileInput>
                    </div>
                    <div className="col-md-6">
                        <label className="form-label"></label>
                        <FileInput
                            Media={bean.FontMedia}
                            MediaContext={bean.FontMediaContext}
                            directory='Template'
                            label='Fuente de Reportes'

                            onCreate={(Filemap: any) => { setBean({ ...bean, "FontMedia": Filemap.Media, "FontMediaContext": Filemap.MediaContext }); }}
                        ></FileInput>
                    </div>
                    <div className="col-md-12">
                        <label className="form-label">Json para Configurar imágen de Header</label>
                        <textarea className="form-control" value={bean.HeaderImageJsonProperties} onChange={(e) => { setBean({ ...bean, 'HeaderImageJsonProperties': e.target.value }) }}></textarea>
                    </div>
                    <div className="col-md-12">
                        <label className="form-label"> Json para Configurar imágen de Footer</label>
                        <textarea className="form-control" value={bean.FooterImageJsonProperties} onChange={(e) => { setBean({ ...bean, 'FooterImageJsonProperties': e.target.value }) }}></textarea>
                    </div>
                    <div className="col-md-12">
                        <label className="form-label"> Json para Configurar Reportes</label>
                        <textarea className="form-control" value={bean.DocTemplateJsonProperties} onChange={(e) => { setBean({ ...bean, 'DocTemplateJsonProperties': e.target.value }) }}></textarea>
                    </div>
                </div>
            </Modal>

        </>
    )
}


