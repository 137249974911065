import { Modal } from "../../../theme/Component/Modal";
import { useState, useEffect } from 'react';
import { FinanceService } from '../../../../core/services/FinanceService';
import { ModalConfirm } from '../../../theme/Component/ModalConfirm';
import { Singleton } from "../../../../core/services/Singleton";
import { _single } from "../../../../core/services/dataBean/EntityCatalog";

interface Props {
    id: number;
    bean: any;
    updateEvent: any;
    idType: number;
}
export const ButtonBudgetID = ({ idType, id, bean, updateEvent }: Props) => {

    const single = Singleton.getInstance()
    const service = new FinanceService();
    const [modal, setModal] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-md' });
    const [confirm, setConfirm] = useState<any>({ name: 'Confirmación', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });
    const [miBean, setMibean] = useState<any>({});

    useEffect(() => {
        setMibean(bean);
    }, [bean])


    useEffect(() => {
    }, [miBean.IDLn])

    const updateBean = () => {
        _single.deleteCacheItem("budgetChild_");
        _single.deleteCacheItem("treeBusget_");
        service.updateBudgetID(miBean).subscribe(
            (resp: any) => {
                console.log("Update : ", resp);
                updateEvent();
                setModal({ ...modal, 'view': false });
                single.update(miBean);
            }
        );

    }

    const deleteID = () => {
        service.deleteBudgetID(bean.IDLn).subscribe(
            (resp: any) => {
                updateEvent();
                setConfirm({ ...confirm, ['view']: false });
                single.delete(bean.IDLn, miBean);
            }
        )

    }

    return (
        <>
            {single.canDelete() &&
                <i className="ri-file-reduce-line cursor btnicon_sm" title="Eliminar" onClick={() => setConfirm({ ...confirm, ['view']: true })}></i>
            }

            {single.canEdit() &&
                <i className="ri-edit-2-line cursor btnicon_sm" title="Editar" onClick={() => {
                    setModal({ ...modal, 'view': true });
                }}></i>
            }

            <Modal modal={modal} updateModal={setModal} eventModal={updateBean}>
                <div className="row">
                    {/* <div className="col-md-6">
                        <label>Código</label>
                        <input type="number" className="form-control" value={miBean.Code} onChange={(e) => { setMibean({ ...miBean, ['Code']: e.target.value }) }} />
                    </div> */}
                    <div className="col-md-12">
                        <label>Nombre</label>
                        <textarea className="form-control" value={miBean.Name} onChange={(e) => { setMibean({ ...miBean, 'Name': e.target.value }) }}></textarea>

                    </div>
                </div>
            </Modal>

            <ModalConfirm modal={confirm} updateModal={setConfirm} eventModal={deleteID}>
                Eliminar el item
            </ModalConfirm>
        </>
    )
}