import { useState, useEffect } from 'react';
import useDataBean from '../../../../core/services/dataBean/useDataBean';
import { _ContractArticleItem } from '../../../../core/services/dataBean/EntityCatalogVdos';
import { _single } from '../../../../core/services/dataBean/EntityCatalog';



interface Props {
    idAdquisitionContract?: number;
    idSelector: number;
    onChange: any;
    label?: string;
}

export const ContractArticleItemEditor = ({ idSelector, idAdquisitionContract, onChange, label }: Props) => {

    const ContractArticleItemEditor = useDataBean(_ContractArticleItem)
    const [lista, setLista] = useState<any>([])
    const [id, setID] = useState(null);

    useEffect(() => {
        if (idSelector >= 0) {
            setID(idSelector)
        }
    }, [])

    useEffect(() => {
        getLista();
    }, [idAdquisitionContract])

    useEffect(() => {
        if(id>0)
        {
            let items = lista.filter((it:any)=>it.DataBeanProperties.IDAdquisitionArticle === id)
            
            if(items.length>0)
                onChange(items[0].DataBeanProperties);
        }
    }, [id])

    const setValue = (e: any) => {
        setID(parseInt(e));
        
    }

    const getLista = () => {
        ContractArticleItemEditor.instance.getAdquisitionArticleByIDAdquisitionContract(idAdquisitionContract).then(
            (resp) => {
               //console.log(resp);
                
                setLista(resp)
            }
        )
    }

    return (
        <>
            <label>{label ?? "Articulo"}</label>
            <select className="form-select" value={id} onChange={(e) => { setValue(e.target.value) }}>
                <option value={null}>...</option>

                {lista &&
                    lista.map((item: any, index: number) => {
                        return (
                            <option key={index} value={item.DataBeanProperties.IDAdquisitionArticle} >{item.DataBeanProperties.Description}-{_single.formatMoney(item.DataBeanProperties.ContractedValue)}</option>
                        )
                    })
                }
            </select>
        </>
    )
}