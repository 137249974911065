import { useEffect, useState } from "react";
import { Singleton } from "../../../../../core/services/Singleton";
import { ForpoService } from "../service/ForpoService";
import { useDashboardStore } from "../../../../pages/hooks/useDashboardStore";
import { FileService } from "../../../../../core/services/FileService";

export const useInternalMenu = () => {
    const single = Singleton.getInstance()
    const { setLoading } = useDashboardStore();
    const service = new ForpoService();
    const [lista, setLista] = useState([]);
    const [bean, setBean] = useState<any>({});
    const fileService = new FileService();
    const [modalConfirm, setModalConfirm] = useState<any>({ name: "Confirmar", tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: "modal-sm", });
    const [modal, setModal] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: "modal-lg", });
    const [modalMenu, setModalMenu] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: "modal-xl", });

    useEffect(() => {
        getLista();
    }, []);


    const idBean = "IDInternalMenu";

    const setRowBean = (bean: any) => {
        setBean(bean);
    };

    const getLista = () => {
        setLoading(true);
        service.getInternalMenuCatalogPorPropiedad("State", 1, null).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue) {
                    let menusList = resp.DataBeanProperties.ObjectValue;
                    menusList.forEach(menu => {
                        if (menu.DataBeanProperties.ImageCover) {
                            let media = JSON.parse(menu.DataBeanProperties.ImageCover);
                            menu.DataBeanProperties.URLmedia = ` <a href="${fileService.getUrlFile(media.MediaContext, media.Media)}" target='_blank'>${media.Name ?? "Archivo Descargable"}</a>`;
                            menu.DataBeanProperties.ObjMedia = JSON.parse(menu.DataBeanProperties.ImageCover);
                        } else {
                            menu.DataBeanProperties.URLmedia = "Sin Archivo";
                        }
                    });
                    setLista(menusList);
                }
                setLoading(false);
            });
    };

    const head = [
        { title: "ID", property: idBean },
        { title: "Menu", property: "Menu" },
        { title: "Descripción", property: "Description" },
        { title: "Imagen", property: "URLmedia" },
    ];

    const agregarItem = () => {
        let b = {
            Menu: "",
            Description: "",
            State: 1,
            IDEmployee: single.getAccountID(),
            ImageCover: "",
        };
        setBean(b);
        setModal({ ...modal, "view": true, name: "Agregar categoria" });
    };

    const editarItem = () => {
        setModal({ ...modal, "view": true, name: 'Editar categoria' });
    };

    const eliminarItem = () => {
        bean.State = 2;
       //console.log(bean);
        updateItem();
        setModalConfirm({ ...modalConfirm, 'view': false });
    }

    const updateItem = () => {
        setLoading(true);
        service.updateInternalMenu(bean).subscribe((resp: any) => {
            setLoading(false);
            if (resp.DataBeanProperties.ObjectValue) {
                getLista();
            }
            if (bean.idBean > 0) {
                single.update(bean);
            } else {
                single.create(bean);
            }
        });
        setModal({ ...modal, "view": false });
    };

    const iconButtonsItem = [
        { nombre: "Agregar", visible: single.canCreate(), icono: "ri-file-add-line", evento: agregarItem, },
    ];

    return {
        single,
        fileService,
        service,
        lista,
        setLista,
        bean,
        setBean,
        modal,
        setModal,
        modalConfirm,
        setModalConfirm,
        idBean,
        updateItem,
        head,
        iconButtonsItem,
        editarItem,
        agregarItem,
        eliminarItem,
        setRowBean,
        modalMenu,
        setModalMenu
    };
};
