import { useEffect, useState } from "react";
import { _AdquisitionContract, _BudgetRPFactory, _single } from "../../../core/services/dataBean/EntityCatalog";
import { InputDate } from "../../theme/Input/InputDate";
import { AccountEditor } from "../../editor/AccountEditor";
import useDataBean from "../../../core/services/dataBean/useDataBean";
import { BasicTable, MASK_NUMBER } from "../../theme/Component/BasicTable";
import { toast } from "react-toastify";

interface Props {
    onChange: Function;
}

export const BudgetObligationForm = ({ onChange }: Props) => {

    const { lista, setLista, instance, bean, setBean } = useDataBean(_BudgetRPFactory);

    const [response, setResponse] = useState<any>({
        RegisterDate: _single.getCurrenDate(),
    });

    const head = [
        { title: 'ID', property: 'IDDocument', visible: true, mask: MASK_NUMBER },
        { title: 'Mnemonic', property: 'Mnemonic', visible: true },
        { title: 'Consecutivo', property: 'Consecutive', visible: true },
        { title: 'Valor del Registro Presupuestal', property: 'Value', visible: true },
        { title: 'Valor Obligado', property: 'OBValue', visible: true },
    ]

    useEffect(() => {

        let obje = {
            RegisterDate: response.RegisterDate,
            Beneficiary: response.IDAccount,
            DocumentRP: bean,
            Description: response.Description,
            Consecutive: response.Consecutive,
        }
        onChange(obje);
    }, [response, bean])

    useEffect(() => {
        if (response.IDAccount && response.RegisterDate) {
            getCDPConsecutive();
        }
    }, [response.IDAccount, response.RegisterDate])

    const getCDPConsecutive = () => {
        instance.getRPDocuments(response.IDAccount, response.RegisterDate).then(
            (resp) => {
                setLista(resp);
            }
        ).catch(
            err => { toast.error(err) }
        )
    }

    return (
        <div className="row">
            <div className="col-md-3">
                <InputDate name="" label="Fecha de Registro" setDate={(e) => { setResponse({ ...response, "RegisterDate": e }) }} value={response.RegisterDate} />
            </div>
            <div className="col-md-5">
                <AccountEditor idAccount={response.IDAccount} label="Beneficiario" onChange={(e) => { setResponse({ ...response, "IDAccount": e.IDAccount }) }} />
            </div>
            <div className="col-md-4">
                <label>Numero de Consecutivo Externo</label>
                <input type="number" value={response.Consecutive} onChange={(e) => { setResponse({ ...response, "Consecutive": parseInt(e.target.value) }) }} className="form-control" />
            </div>
            <div className="col-md-12">
                <label>Descripción</label>
                <textarea className="form-control" value={response.Description} onChange={(e) => { setResponse({ ...response, "Description": e.target.value }) }}></textarea>
            </div>
            <div className="col-md-12">
                <BasicTable body={lista} head={head} rowSet={setBean} />
            </div>
        </div>
    )
}