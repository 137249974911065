import { useEffect, useState } from "react";
import { _Ability } from "../../../../../core/services/dataBean/EntityCatalog";
import useDataBean from "../../../../../core/services/dataBean/useDataBean";
import { Singleton } from "../../../../../core/services/Singleton";
import { ListMenu } from "../../../../theme/Component/ListMenu";
import { BasicTable } from "../../../../theme/Component/BasicTable";
import { Modal } from "../../../../theme/Component/Modal";
import { ModalConfirm } from "../../../../theme/Component/ModalConfirm";
import { toast } from "react-toastify";
import { AbilityTypeEditor } from "../../AbilityType/AbilityType.editor";
import { PrintJson } from "../../../../theme/Component/PrintJson";

interface PropAbility {
    _IDAppointManual: number;
}

export const Ability = ({ _IDAppointManual }: PropAbility) => {

    const ability = useDataBean(_Ability);
    const single = Singleton.getInstance();
    const [abiType, setAbiType] = useState<number>(null);
    const [lista, setLista] = useState<any>([]);
    const [modal, setModal] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-md' });
    const [modalC, setModalC] = useState<any>({ name: 'Eliminar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });

    useEffect(() => {
        if (_IDAppointManual >= 0) {
            getLista();
        }
    }, [_IDAppointManual, abiType])

    const getLista = () => {

        ability.instance.getAbilityCatalog(_IDAppointManual, abiType).then(
            (resp: any) => {
                if (resp) {
                    setLista(resp)
                   //console.log(resp)
                }
            }
        )

    }

    const create = () => {
        ability.setBean({
            IDAppointmentManual: _IDAppointManual,
            IDAbilityType: abiType
        });
        setModal({ ...modal, "name": "Agregar Habilidades del Cargo", "view": true });
    }

    const deleteItem = () => {
        ability.instance.deleteAbility(ability.bean).then(
            (resp: any) => {
                getLista();
                setModalC({ ...modalC, "view": false });
            }
        )

    }

    const editaItem = () => {
        ability.update().then(
            (resp: any) => {
                getLista();
                setModal({ ...modal, "view": false });
            }
        ).catch(err => {
            toast.error(err);
        })

    }

    const head = [
        { title: 'ID', property: 'IDAbility', visible: true },
        { title: 'Nombre', property: 'AbilityTypeName', visible: true },
        { title: 'Nombre', property: 'AbilityTypeName', visible: true },

    ]

    const iconButtonsItem = [
        { nombre: 'Agregar', visible: single.canCreate(), icono: 'ri-file-add-line', evento: create }
    ]
    const listaBotones = () => {
        const botones = [
            { titulo: 'Editar', icono: 'ri-edit-2-line', visible: single.canEdit(), evento: () => { setModal({ ...modal, "name": `Editar Habilidades del Cargo`, "view": true }) } },
            { titulo: 'Eliminar', icono: 'ri-delete-bin-line', visible: single.canDelete(), evento: () => { setModalC({ ...modalC, "view": true }) } },

        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }


    return (
        <div className="row">
            <div className="col-6">
                <AbilityTypeEditor idSelector={abiType} onChange={(e: any) => { setAbiType(e) }} />
            </div>
            <div className="col-md-12">
                <BasicTable body={lista} head={head} rowSet={ability.setBean} listButtons={listaBotones()} iconButtons={iconButtonsItem}></BasicTable>
                <Modal modal={modal} updateModal={setModal} eventModal={editaItem}>
                    <div className="row">
                        {/* <PrintJson json={ability.bean}></PrintJson> */}
                        <div className="col-12">
                            <AbilityTypeEditor idSelector={ability.bean.IDAbilityType} onChange={(e: any) => { ability.setBean({ ...ability.bean, "IDAbilityType": e }) }} />
                        </div>
                        <div className="col-12">
                            <label >Nombre:</label>
                            <input type="text" name="Name" className="form-control" value={ability.bean.Name} onChange={ability.handleChange} />
                        </div>
                        <div className="col-12">
                            <label>Descripción: </label>
                            <textarea name="Description" className="form-control" value={ability.bean.Description} onChange={ability.handleChange} />
                        </div>
                    </div>
                </Modal>
                <ModalConfirm modal={modalC} updateModal={setModalC} eventModal={deleteItem}>
                    <strong>Eliminar item {ability.bean.IDKnowledge}</strong>
                </ModalConfirm>
            </div>
        </div>
    )
}