import { useEffect, useState } from "react";
import { ContractService } from "../../../../../core/services/ContractService";
import { Singleton } from "../../../../../core/services/Singleton";
import { PrintJson } from "../../../../theme/Component/PrintJson";
import { UBLParam, UBLCONTRACT, UBLSUPPLIER } from '../ContracInterface';
import { InputDate } from "../../../../theme/Input/InputDate";
import { BasicButton } from "../../../../theme/Component/BasicButton";
import { BasicTable } from "../../../../theme/Component/BasicTable";
import { DataBeanProperties } from '../../../../../core/services/model/server-response.interface';
import { FileService } from "../../../../../core/services/FileService";
import { ListMenu } from "../../../../theme/Component/ListMenu";
import { Modal } from "../../../../theme/Component/Modal";
import { UBLInvoiceItems } from "./UBLInvoiceItems";
import { ModalConfirm } from "../../../../theme/Component/ModalConfirm";
import { ImportUBLInvoice } from "./ImportUBLInvoice";
import { toast } from "react-toastify";
import { PdfDocuments } from "../../../../documents/PdfDocuments";
import { UBLInvoiceTax } from "../TaxShema/UBLInvoiceTax";
import { UBLInvoiceTaxImpuesto } from "../TaxShema/UBLInvoiceTaxImpuesto";
import { GenerarContabilizacion } from "./GenerarContabilizacion";
import { GenerarCXP } from "./GenerarCXP";
import { ReporteProcesosFacturados } from "./ReporteProcesosFacturados";
import useModal, { MODAL_LG } from "../../../../theme/Component/hooks/useModal";

interface Props {
    type: number; // 1 proveedor, 2 contrato
    formData: UBLParam;
    contract?: any;
    importUBL: boolean;
}
export const UBLInvoiceList = ({ type, formData, contract, importUBL }: Props) => {

    const single = Singleton.getInstance();
    const contracService = new ContractService();
    const fileService = new FileService();

    const [row, setRow] = useState<any>({});
    const [lista, setLista] = useState<any>([]);
    const [observation, setObservation] = useState<any>('');
    const [listaConsolidado, setListaConsolidado] = useState<any>([]);
    const [obs, setObs] = useState<string>('');
    const [form, setForm] = useState<UBLParam>(formData);
    const [modal, setModal] = useState<any>({ name: 'Items de la factura', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-xl' });
    const [modalResumen, setModalResumen] = useState<any>({ name: 'Resumen factura', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-xl' });
    const [modalImport, setModalImport] = useState<any>({ name: 'Importar factura', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-xl' });
    const [modalRetirar, setModalRetirar] = useState<any>({ name: 'Retirar Factura', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalCausa, setModalCausa] = useState<any>({ name: 'Mostrar Causación', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalEntrada, setModalEntrada] = useState<any>({ name: 'Mostrar Entrada almacen', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalImpuesto, setModalImpuesto] = useState<any>({ name: 'Retenciones', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalImpuesto1, setModalImpuesto1] = useState<any>({ name: 'Impuestos', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalContabilizacion, setModalContabilizacion] = useState<any>({ name: 'Generar Contabilización', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalCuentas, setModalCuentas] = useState<any>({ name: 'Generar Cuenta por Pagar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalProcesosFactura, setModalProcesosFactura] = useState<any>({ name: 'Ver Reporte de Proceso de Factura', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });


    const modalADDUBL = useModal("Factura", MODAL_LG);

    useEffect(() => {

        getListaUBLInvoice();

    }, [contract])
    useEffect(() => {
        return () => { setLista([]); setObs('') }
    }, [])


    const getListaUBLInvoice = () => {
        if (type === UBLCONTRACT) // proveedor
        {
            getUBLInvoiceContractCatalog();

        }
        else if (type === UBLSUPPLIER)// por contrato
        {
            getUblInvoiceCatalog();
        }
    }


    const getUBLInvoiceContractCatalog = () => {
        single.spinner(true);
        // contracService.getUblInvoiceCatalogByAdquisitionContract(form.IDAccountSupplier, form.IDAdquisitionContract, form.From, formData.Upto, form.State).subscribe(
        contracService.getUBLInvoiceContractCatalog(form.IDAdquisitionContract, contract.Since).subscribe(
            (resp: any) => {

                if (resp.DataBeanProperties.ObjectValue) {

                    let raiz: any = resp.DataBeanProperties.ObjectValue;

                    raiz.forEach((item: any) => {
                        Object.assign(item.DataBeanProperties, item.DataBeanProperties.UBLInvoice.DataBeanProperties);
                        Object.assign(item.DataBeanProperties, contract);

                    });
                   //console.log(raiz);


                    setLista(formatURL(raiz));
                }
                single.spinner(false);
            }
        )
    }
    const getUblInvoiceCatalog = () => {
        single.spinner(true);
        contracService.getUblInvoiceCatalog(form.IDAccountSupplier, form.From, form.Upto, form.State).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue) {
                    setLista(formatURL(resp.DataBeanProperties.ObjectValue));
                }
                single.spinner(false);
            }
        )
    }
    const releaseUBLInvoiceFromAdquisitionContract = () => {
        single.spinner(true);
        contracService.releaseUBLInvoice(row.IDUBLInvoice, row.IssueDate).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue) {
                    getUblInvoiceCatalog();
                    setModalRetirar({ ...modalRetirar, "view": false });
                }
                single.spinner(false);
            }
        )
    }


    const formatURL = (list: any) => {

        list.forEach((element: any) => {
            if (element.DataBeanProperties.Media) {
                element.DataBeanProperties.URL = `<a href=${fileService.getUrlFile(element.DataBeanProperties.MediaContext, element.DataBeanProperties.Media)} target='_blank'>Ver</a>`
               //console.log(element.DataBeanProperties.URL);

            }
        });
        return list;
    }
    const verResumenContrato = () => {
        setModalResumen({ ...modalResumen, "view": true });
        getUBLItemArticleConsolidaded();
    }
    const getUBLItemArticleConsolidaded = () => {
        single.spinner(true);
        contracService.getUBLItemArticleConsolidaded(row.IDAdquisitionContract, row.AdquisitionContractDate).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue) {
                    setListaConsolidado(resp.DataBeanProperties.ObjectValue);
                }
                else if (resp.DataBeanProperties.ErrorMessage) {
                    toast.error(resp.DataBeanProperties.ErrorMessage);
                }
                single.spinner(false);
            }
        );
    }
    const addUBLInvoiceToAdquisitionContract = () => {
        single.spinner(true);


        contracService.addUBLInvoiceToAdquisitionContract(row.IDUBLInvoice, row.IssueDate, contract.IDAdquisitionContract, contract.Since, formData.IDAccountSupplier, observation, single.getAccountID()).subscribe(
            (resp: any) => {

               //console.log(resp);

                if (resp.DataBeanProperties.ObjectValue) {
                    toast.success("Factura registrada exitosamente.");
                    modalADDUBL.close();
                    setObservation('');
                }
                else if (resp.DataBeanProperties.ErrorMessage) {
                    toast.error(resp.DataBeanProperties.ErrorMessage);
                }

                single.spinner(false);
            }
        )
    }

    const tieneContrato = () => {
        let tiene = false;

        if (contract) {
            if (contract.IDAdquisitionContract) {
                tiene = true;
            }
        }
        return tiene;
    }


    const head = [
        { title: 'ID Factura', property: 'InvoiceID', visible: true },
        { title: 'Procesar', property: 'isValid', visible: false },
        { title: 'Nit Proveedor', property: 'AccountSupplierNit', visible: true },
        { title: 'Proveedor', property: 'AccountSupplierName', visible: true },
        { title: 'No Factura', property: 'InvoiceID', visible: true },
        { title: 'Líneas Factura', property: 'LineCountNumeric', visible: true },
        { title: 'Valor Total', property: 'TotalInvoiceAmount', visible: true },
        // { title: 'Impuestos', property: 'TaxAmount', visible: true },
        { title: 'Descuentos', property: 'DiscountAmount', visible: true },
        { title: 'Fecha Factura', property: 'IssueDate', visible: true },
        { title: 'Entrada Almacén', property: 'EAFMnemonic', visible: true },
        { title: 'Consecutivo Entrada', property: 'EAFConsecutive', visible: true },
        { title: 'Fecha Entrada', property: 'EAFDate', visible: true },
        { title: 'Fecha Procesamiento', property: 'Since', visible: true },
        { title: 'Estado Factura', property: 'DocumentStateName', visible: true },
        { title: 'Tipo Factura', property: 'DocumentTypeName', visible: true },
        { title: 'Estado EAF', property: 'EAFDocumentStateName', visible: true },
        { title: 'Estado Salidas', property: 'OutputCostStateName', visible: true },
        { title: 'Documento Soporte', property: 'IsDocumentSupport', visible: true },
        { title: 'Abrir Archivo', property: 'URL', visible: true },
    ]

    const listaBotones = () => {
        const botones = [
            { titulo: 'Ver Items', icono: 'ri-file-list-line', visible: true, evento: () => { setModal({ ...modal, "view": true }) } },
            { titulo: 'Retenciones', icono: 'ri-percent-line', visible: row.IDAdquisitionContract, evento: () => { setModalImpuesto({ ...modalImpuesto, "view": true }) } },
            { titulo: 'Impuestos', icono: 'ri-percent-line', visible: row.IDAdquisitionContract, evento: () => { setModalImpuesto1({ ...modalImpuesto1, "view": true }) } },
            { titulo: 'Generar Contabilización', icono: 'ri-newspaper-line', visible: row.IDAdquisitionContract, evento: () => { setModalContabilizacion({ ...modalContabilizacion, "view": true }) } },
            { titulo: 'Generar Cuenta por Pagar', icono: 'ri-inbox-unarchive-fill', visible: row.IDAdquisitionContract, evento: () => { setModalCuentas({ ...modalCuentas, "view": true }) } },
            { titulo: 'Ver Reporte de Proceso de Factura', icono: 'ri-profile-line', visible: row.IDAdquisitionContract, evento: () => { setModalProcesosFactura({ ...modalProcesosFactura, "view": true }) } },
            { titulo: 'Mostrar Causación', icono: 'ri-refund-2-fill', visible: row.IDAdquisitionContract, evento: () => { setModalCausa({ ...modalCausa, "view": true }) } },
            { titulo: 'Mostrar Entrada de Almacén', icono: 'ri-folder-upload-line', visible: row.IDAdquisitionContract, evento: () => { setModalEntrada({ ...modalEntrada, "view": true }) } },
            { titulo: 'Resumen Contrato', icono: 'ri-pie-chart-line', visible: row.IDAdquisitionContract, evento: () => { verResumenContrato() } },
            { titulo: 'Retirar Factura', icono: 'ri-delete-back-2-line', visible: (single.canDelete() && row.IDAdquisitionContract), evento: () => { setModalRetirar({ ...modalRetirar, "view": true }) } },
            { titulo: 'Registrar a Contrato', icono: 'ri-file-list-line', visible: tieneContrato, evento: () => { modalADDUBL.open() } },
        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }
    const iconButtonsItem = [
        { nombre: 'Importar', visible: importUBL, icono: 'ri-file-add-line', evento: () => { setModalImport({ ...modalImport, "view": true }) } }
    ]
    const headConsolidado = [

        { title: 'Artículo', property: 'IDAdquisitionArticle', visible: true },
        { title: 'Descripción', property: 'Description', visible: true },
        { title: 'Rubro Presupuestal', property: 'BudgetID', visible: true },
        { title: 'Valor Facturado', property: 'Value', visible: true },
        { title: 'Valor del Artículo', property: 'ArticleValue', visible: true },
        { title: 'Valor Pendiente por Facturar', property: 'LeftValue', visible: true },
        { title: 'Error en facturación', property: 'Error', visible: true },


    ]

    return (
        <div className="row">
            <div className="col-md-12">


                <div className="row">
                    {type === UBLSUPPLIER &&
                        <>
                            <div className="col-md-4">
                                <InputDate name="from" label="Fecha Inicial" value={form.From ?? null} setDate={(e: any) => { setForm({ ...form, "From": e }) }} />
                            </div>
                            <div className="col-md-4">
                                <InputDate name="upto" label="Fecha Final" value={form.Upto ?? null} setDate={(e: any) => { setForm({ ...form, "Upto": e }) }} />
                            </div>
                            <div className="col-md-4 mt-3">
                                <BasicButton icon="" eventClick={getListaUBLInvoice}>Buscar</BasicButton>
                            </div>
                        </>

                    }
                    {/* {type == UBLCONTRACT &&
                        <div className="col-md-4 mt-3">
                            <BasicButton icon="" eventClick={getUBLInvoiceContractCatalog}>Buscar</BasicButton>
                        </div>
                    } */}
                </div>

            </div>
            <div className="col-md-12">

                <BasicTable iconButtons={iconButtonsItem} body={lista} head={head} rowSet={setRow} ></BasicTable>
            </div>
            <div className="col-md-12">
                {/* <Modal modal={modalImport} updateModal={setModalImport}>
                    
                    {type === UBLCONTRACT &&
                        <ImportUBLInvoice idAccountSupplier={contract.IDAccountSupplier} contract={contract} />
                    }

                </Modal> */}
                <Modal modal={modal} updateModal={setModal}>
                    <UBLInvoiceItems idUBLInvoice={row.IDUBLInvoice} issueDate={row.IssueDate} contract={row} />
                </Modal>
                <Modal modal={modalContabilizacion} updateModal={setModalContabilizacion}>
                    <GenerarContabilizacion ublInvoice={row} />
                </Modal>
                <Modal modal={modalCuentas} updateModal={setModalCuentas}>
                    <GenerarCXP ublInvoice={row} />
                </Modal>
                <Modal modal={modalProcesosFactura} updateModal={setModalProcesosFactura}>
                    <ReporteProcesosFacturados ublInvoice={row} />
                </Modal>
                <Modal modal={modalImpuesto} updateModal={setModalImpuesto}>
                    <UBLInvoiceTax ublInvoice={row.UBLInvoice} />
                </Modal>
                <Modal modal={modalImpuesto1} updateModal={setModalImpuesto1}>
                    <UBLInvoiceTaxImpuesto ublInvoice={row.UBLInvoice} />
                </Modal>
                <Modal modal={modalResumen} updateModal={setModalResumen}>
                    <h6>Resumen del contrato</h6>
                    <BasicTable body={listaConsolidado} head={headConsolidado} rowSet={(e: any) => { }}></BasicTable>
                </Modal>
                <ModalConfirm modal={modalRetirar} updateModal={setModalRetirar} eventModal={releaseUBLInvoiceFromAdquisitionContract}>
                    <div className="row">
                        <div className="col">
                            <label>Observaciones</label>
                            <textarea className="form-control" value={obs} onChange={(e) => { setObs(e.target.value) }}></textarea>
                        </div>
                    </div>
                </ModalConfirm>
                <Modal modal={modalCausa} updateModal={setModalCausa}>
                    <div className="row">
                        <div className="col">
                            {modalCausa.view &&
                                <PdfDocuments documentLocation={row.FCIDDocument} mnemonic={row.FCMnemonic} date={row.FCDate} />
                            }

                        </div>
                    </div>
                </Modal>
                <Modal modal={modalEntrada} updateModal={setModalEntrada}>
                    <div className="row">
                        <div className="col">
                            {modalEntrada.view &&
                                <PdfDocuments documentLocation={row.IDDocument} mnemonic={row.EAFMnemonic} date={row.EAFDate} />
                            }

                        </div>
                    </div>
                </Modal>
                <Modal modal={modalADDUBL.modal} updateModal={modalADDUBL.setModal}>
                    <div className="row">
                        <div className="col-md-12">
                            <PrintJson json={contract} />
                            <label>Observaciones</label>
                            <textarea className="form-control" value={observation} onChange={(e) => { setObservation(e.target.value) }}></textarea>
                        </div>
                        <div className="col-md-6">
                            <BasicButton icon="ri-save-2-line" eventClick={addUBLInvoiceToAdquisitionContract}>Registrar</BasicButton>
                        </div>
                    </div>
                </Modal>
            </div>
        </div>
    )
}