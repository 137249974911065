import { toast } from "react-toastify";
import { _AdquisitionContractCDP, _AdquisitionContractRP, _RecordBudget, _single } from "../../../../../core/services/dataBean/EntityCatalog";
import useDataBean from "../../../../../core/services/dataBean/useDataBean";
import { BasicTable, MASK_DATE, MASK_NUMBER } from "../../../../theme/Component/BasicTable";
import { useEffect, useState } from "react";
import { Modal } from "../../../../theme/Component/Modal";
import { ListMenu } from "../../../../theme/Component/ListMenu";
import { ModalConfirm } from "../../../../theme/Component/ModalConfirm";
import { InputDate } from "../../../../theme/Input/InputDate";
import { DocumentStorageEditor } from "../../../../contabilidad/bodegaDocumentos/DocumentStorageEditor";
import { PrintJson } from "../../../../theme/Component/PrintJson";
import { DateInput } from "../../../../../core/shared/inputs/DateInput";
import { DocumentHeaderEditor } from "../../../../contabilidad/bodegaDocumentos/DocumentHeaderEditor";

interface Props {
    adquisitionContract: any;
}

export const AdquisitionContractRP = ({ adquisitionContract }: Props) => {

    const { lista, setLista, instance, bean, setBean } = useDataBean(_RecordBudget);

    const [chargeDate, setChargeDate] = useState<any>(_single.getCurrenDate());

    const [modal, setModal] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });
    const [modalC, setModalC] = useState<any>({ name: "Eliminar Item", tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });

    useEffect(() => {
        if (adquisitionContract.IDAdquisitionContract) {
            getLista();
        }
    }, [adquisitionContract])

    const getLista = () => {
        instance.getBudgetRecordForAdquisitionContract(adquisitionContract.IDAdquisitionContract, adquisitionContract.AdquisitionContractDate, chargeDate).then(
            (resp) => {
                setLista(resp)
            }
        ).catch(
            err => {
                toast.error(err);
            }
        )
    }

    const createCDP = () => {
        setBean({
            IDAdquisitionContract: adquisitionContract.IDAdquisitionContract,
            AdquisitionContractDate: adquisitionContract.AdquisitionContractDate,
        });
        setModal({ ...modal, "view": true, "name": "CDP" });
    }

    const updateItem = () => {
        instance.setAdquisitionContractToRP(bean.IDAdquisitionContract, bean.AdquisitionContractDate, bean.IDDocument, bean.DocumentDate, _single.getAccountID(), false).then(
            (resp) => {
                console.log(resp)
                getLista();
                setModal({ ...modal, "view": false })
            }
        ).catch(err => { toast.error(err) })
    }

    const deleteObject = () => {
        instance.releaseRecordBudgetFromAdquisitionContract(bean.IDDocument, bean.IDDocumentDate, _single.getAccountID()).then(
            (resp) => {
                getLista()
                console.log(resp)
            }
        ).catch(
            err => { toast.error(err) }
        )
    }

    const head = [
        { title: 'ID', property: 'IDAdquisitionContractRP', visible: true, mask: MASK_NUMBER },
        { title: 'RP', property: 'RPCode', visible: true },
        { title: 'Fecha RP', property: 'RPDate', visible: true, mask: MASK_DATE },
        { title: 'Fecha Documento', property: 'DocumentDate', visible: true, mask: MASK_DATE },
        { title: 'Mnemonic', property: 'Mnemonic', visible: true },
        { title: 'Consecutivo', property: 'Consecutive', visible: true },
        { title: 'Estado del Documento', property: 'DocumentStateName', visible: true },
    ]

    const iconButtonsItem = [
        { nombre: 'Agregar', visible: _single.canCreate(), icono: 'ri-file-add-line', evento: () => { createCDP() } }
    ]

    const listaBotones = () => {
        const botones = [
            { titulo: 'Eliminar', icono: 'ri-file-user-line', visible: _single.canDelete(), evento: () => { setModalC({ ...modalC, "view": true, "name": "Eliminar Item" }) } },
        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }

    return (
        <div className="row">
            {/* <PrintJson json={adquisitionContract} /> */}
            <div className="col-md-6">
                <InputDate name="" value={chargeDate} setDate={(e) => { setChargeDate(e) }} label="Fecha Actual" />
            </div>
            <div className="col-md-12">
                <BasicTable iconButtons={iconButtonsItem} listButtons={listaBotones()} body={lista} head={head} rowSet={setBean} />
            </div>
            <div className="col-md-12">
                <Modal modal={modal} updateModal={setModal} eventModal={updateItem}>
                    <div className="row">
                        <div className="col-md-12">
                            <DocumentHeaderEditor label="RP Seleccionado" onChange={(e) => { setBean({ ...bean, "IDDocument": e.IDDocument, "DocumentDate": e.Since, "DocumentState": e.DocumentState, "Consecutive": e.Consecutive, "Mnemonic": e.Mnemonic, }) }} />
                        </div>
                    </div>
                </Modal>
            </div>
            <div className="col-md-12">
                <ModalConfirm modal={modalC} updateModal={setModalC} eventModal={deleteObject}>
                    <p>Esta seguro que desea eliminar el item {`${bean.Mnemonic} - ${bean.Consecutive}`}</p>
                </ModalConfirm>
            </div>
        </div>
    )

}