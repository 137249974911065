import { DataBean } from "../DataBean";

export class CodeUnscp extends DataBean {

    public async getUnscpCodesRender(IDLn:any): Promise<any> {
        return this.getCustomService("FinanceCore", "java.util.List_getUnscpCodesRender_Number", [IDLn]);
    }
    public async addUnscpCodesByFunctionalID(IDLnFunctionalID:any,idEmployee:any,codes:any): Promise<any> {
        return this.getCustomService("FinanceCore", "java.util.List_addUnscpCodesByFunctionalID_Number_Number_java.util.List", [IDLnFunctionalID,idEmployee,codes]);
    }



}