import { useState, useEffect } from 'react';
import useDataBean from '../../../../../core/services/dataBean/useDataBean';
import { _ImportedPayrollConcept } from '../../../../../core/services/dataBean/EntityCatalog';


interface Props {
    idSelector: number;
    onChange: any;
    label?: string;
}

export const AbsenteeismClassSelector = ({ idSelector, onChange, label }: Props) => {

    const { bean, setBean, lista, setLista, instance } = useDataBean(_ImportedPayrollConcept)
    const [id, setID] = useState(0);

    useEffect(() => {
        setID(idSelector);
    }, [idSelector])

    useEffect(() => {
        getLista();
    }, [])

    const setValue = (e: any) => {
        setID(parseInt(e));
        onChange(e);
    }

    const getLista = () => {

        instance.getAbsenteeismClassConstants(null).then(
            (resp: any) => {
                if (resp) {
                    setLista(resp)
                   //console.log(resp)
                }
            }
        )

    }

    return (
        <>
            <label>{label ?? "Clase de Ausentismo"}</label>
            <select className="form-select" value={id} onChange={(e) => { setValue(parseInt(e.target.value)) }}>
                <option>...</option>
                {lista &&
                    lista.map((item: any, index: number) => {
                        return (
                            <option key={index} value={item.DataBeanProperties.Value} >{item.DataBeanProperties.Property}</option>
                        )
                    })
                }
            </select>
        </>
    )
}