import { DataBean } from "../DataBean"

export class BudgetMovementFactory extends DataBean {

    public async createBudgetRowForCreditContraCredit(idBudgetType: number, budgetIDStr: string, creditBudgetIDStr: string, value: number, description: string): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "budget.Budget_createBudgetRowForCreditContraCredit_Number_String_String_Number_String", [idBudgetType, budgetIDStr, creditBudgetIDStr, value, description]);
    }

    public async createCreditContraCreditBudget(idBudgetType: number, accountantDate: any, items: any, description: string, idEmployee: number, createPdf: boolean): Promise<any> {
        return this.getCustomService(
            "QuickDataERPCore",
            "com.quickdataerp.bean.accountant.AccountantDocument_createCreditContraCreditBudget_Number_java.util.Date_java.util.List_String_Number_Boolean",
            [idBudgetType, accountantDate, items, description, idEmployee, createPdf]
        );
    }

}