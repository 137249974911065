import { useEffect, useState } from "react";
import { BasicButton } from "../../theme/Component/BasicButton";
import { DocumentViewer } from "../../theme/Component/DocumentViewer";
import { ErrorList } from "../../theme/Component/ErrorList";
import useDataBean from "../../../core/services/dataBean/useDataBean";
import { _BudgetRPFactory, _single } from "../../../core/services/dataBean/EntityCatalog";
import { toast } from "react-toastify";
import { PrintJson } from "../../theme/Component/PrintJson";
import { _BudgetCDPreductionFactory } from "../../../core/services/dataBean/EntityCatalogVdos";

interface Props {

    formData?: any;
    List: any;
    onChange: Function;
}

export const BudgetCDPReductionFactoryDoc = ({ List, formData, onChange }: Props) => {

    const _budgetRDFactory = useDataBean(_BudgetCDPreductionFactory);

    const [documents, setDocuments] = useState<any>([]);
    const [msgList, setMsgList] = useState<any>([]);
    const [tap, setTap] = useState<number>(0);

    const crearDocumento = () => {
        _budgetRDFactory.instance.createCDPReduction(formData.FormDate,formData.Consecutive,List,formData.Description,_single.getAccountID(),true).then(
            (resp: any) => {
               //console.log(resp)
                setDocuments(resp.DocumentURL);
                setMsgList(resp.MsgList);
                setTap(1)
            }
        ).catch(
            err => {
                toast.error(err, { autoClose: false })
            }
        )
    }

    useEffect(() => {
        onChange({
            Documents: documents,
            MSGList: msgList,
        })
    }, [documents, msgList])

    //pude declarar variables pero no declarar mi amor por ella...

    return (
        <div className="row">

            {tap === 0 &&
                <>
                <div className="col-md-6">
                <BasicButton icon="ri-article-line" eventClick={crearDocumento}>Generar Documento</BasicButton>
                </div>
                    
                </>
            }
            {tap === 1 &&
                <div className="col-md-6">
                    {msgList?.length > 0 &&
                        <ErrorList lista={msgList} />

                    }
                    {msgList?.length === 0 && documents.length > 0 &&
                        <DocumentViewer key={documents} list={documents} />
                    }
                </div>
            }
        </div>
    )
}