import { ReactNode, useEffect, useState } from 'react';
import { FiEdit } from "react-icons/fi";
import { camelize, getGreeting } from '../../../../../utils/helpers';
import { PrintJson } from '../../../../theme/Component/PrintJson';

interface Props {
    user: any;
    imgProfile: string;
    children?: ReactNode;
    changeProfileImg: () => void;
}

export const ProfileDashed = ({ children, changeProfileImg, imgProfile, user }: Props) => {

    return (
        <>
            <div className='profile_box'>
                <div className="bg_blur">
                    <div className='greetings_box'>
                        <span><strong>{getGreeting()}</strong></span>
                    </div>
                </div>
                <div className="profile_circle" onClick={changeProfileImg}>
                    <img className='profile_img' src={imgProfile} alt='profile_img' />
                    <span className='icon_edit' title='Cambiar foto'>
                        <FiEdit />
                    </span>

                    <div className="profile_info">
                        <h2>{`${camelize(user.Name1)} ${camelize(user.Surname1)}`}</h2>
                    </div>
                </div>
                <div className='dymanic_side' style={{ zIndex: '10', display: 'flex', justifyContent: 'end', width: '100%' }}>
                    {children}
                </div>
            </div>
        </>
    )
}
