import { useState, useEffect } from 'react';
import useDataBean from '../../../../../../core/services/dataBean/useDataBean';
import { _UBLInvoice } from '../../../../../../core/services/dataBean/EntityCatalog';

interface Props {
    idSelector: number;
    onChange: any;
}

export const SelectAccountantConceptForCashDesk = ({ idSelector, onChange }: Props) => {

    const service = useDataBean(_UBLInvoice);
    const [lista, setLista] = useState([]);
    const [id, setID] = useState(idSelector ?? NaN);

    useEffect(() => {
        setID(idSelector);
        getLista();
    }, [])

    useEffect(() => {
        onChange(id);
    }, [id])

    const setValue = (e: any) => {
        setID(parseInt(e));
        onChange(id);
    }

    const getLista = () => {
        service.instance.getAccountantConceptCatalogForCashDesk(null).then(
            (resp: any) => {

                if (resp) {
                    setLista(resp);
                }
            }
        );
    }

    return (
        <>
            <label>Concepto de Ingreso de Facturación</label>
            <select className="form-select" value={id} onChange={(e) => { setValue(e.target.value) }}>
                <option value={NaN}>...</option>
                {lista &&
                    lista.map((item: any, _i) => {
                        return (
                            <>
                                <option key={_i} value={item.DataBeanProperties.IDAccountantConcept} >{item.DataBeanProperties.Name}</option>
                            </>)
                    })
                }
            </select>
        </>
    )
}