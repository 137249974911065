
import { VisorAnyDocument } from "../documents/VisorAnyDocument";

interface Props {
    data: any;
    event: any;
}
export const DocumentVisualicer = ({ data, event }: Props) => {

    const getFileType = (mediaName: string) => {
        let fileType = mediaName.split('.').pop()?.toLowerCase();
        return fileType;
    }

    return (
        <div className="row">
            <div className="col-md-12">
                <VisorAnyDocument type={getFileType(data.Media)} url={data.DocUrl} />
            </div>
        </div>
    )
}