import { Singleton } from '../../../core/services/Singleton';
import { BasicButton } from '../../theme/Component/BasicButton';
import { BasicPage } from '../../theme/Component/BasicPage';
import { BasicTable } from '../../theme/Component/BasicTable';
import { Modal } from '../../theme/Component/Modal';
import { ModalConfirm } from '../../theme/Component/ModalConfirm';
import { useState, useEffect } from 'react';
import { FinanceService } from '../../../core/services/FinanceService';
import { ListMenu } from '../../theme/Component/ListMenu';
import { useDashboardStore } from '../../pages/hooks/useDashboardStore';

export const DeliveryType = () => {

    const single = Singleton.getInstance()
    const { setLoading } = useDashboardStore();
    const service = new FinanceService();
    const [lista, setLista] = useState([]);
    const [bean, setBean] = useState<any>({});
    const [modal, setModal] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalConfirm, setModalConfirm] = useState<any>({ name: 'Confirmar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });

    const idBean = 'IDDeliveryType';

    const head = [
        { title: 'ID', property: 'idBean' },
        { title: 'Nombre', property: 'Name' },
        { title: 'Descripción', property: 'Description' },
        { title: 'Fecha', property: 'Since' },
    ]

    useEffect(() => {
        getLista();
    }, [])

    useEffect(() => {
        setModal({ ...modal, 'view': false });
    }, [lista])

    const getLista = () => {

        setLoading(true);
        service.getDeliveryTypeCatalog(null, null).subscribe(
            (resp: any) => {
                setLista([]);
               //console.log("Lista", resp);
                if (resp.DataBeanProperties.ObjectValue.DataBeanProperties.List) {
                    setLista(resp.DataBeanProperties.ObjectValue.DataBeanProperties.List);
                }
                setLoading(false);
            }
        );

    }

    const agregarItem = () => {
        let b = {
            Name: '',
            Since: single.getCurrenDate(),
            Description: '',
        }
        setBean(b);
        setModal({ ...modal, 'view': true });

    }
    const editarItem = () => {

        setModal({ ...modal, 'view': true });

    }

    const eliminarItem = () => {

        setLoading(true);
        service.deleteDeliveryType(bean[idBean]).subscribe(
            (resp: any) => {
                setLoading(false);
                if (resp) {
                    getLista();
                }
                single.delete(bean[idBean], bean);
            }
        );
        setModalConfirm({ ...modalConfirm, 'view': false });

    }

    const updateItem = () => {

        setModal({ ...modal, 'view': false });
        setLoading(true);
        service.updateDeliveryType(bean).subscribe(
            (resp: any) => {
                setLoading(false);
                if (resp.DataBeanProperties.ObjectValue) {
                    getLista();
                }
                if (bean[idBean]) {

                    single.update(bean);

                }
                else {
                    single.create(bean);
                }
            });

    }

    const setRowBean = (bean: any) => {
       //console.log("Row : ", bean);
        setBean(bean);

    }

    const iconButtonsItem = [
        { nombre: 'Agregar', visible: single.canCreate(), icono: 'ri-file-add-line', evento: agregarItem }
    ]

    const listaBotones = () => {
        const botones = [
            { titulo: 'Editar', icono: 'ri-edit-2-line', visible: single.canEdit(), evento: () => { setModal({ ...modal, "view": true }); } },
            { titulo: 'Eliminar', icono: 'ri-eraser-line', visible: single.canDelete(), evento: () => { setModalConfirm({ ...modalConfirm, "view": true }); } },
        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }

    return (
        <>

            <BasicPage titulo='Tipos de Entrega'>

                <div className="row">

                    <div className="col-md-12 p-2">
                        <BasicTable iconButtons={iconButtonsItem} listButtons={listaBotones()} head={head} body={lista} rowSet={setRowBean}></BasicTable>
                    </div>
                </div>
                <ModalConfirm modal={modalConfirm} updateModal={setModalConfirm} eventModal={eliminarItem}>
                    {bean.Name}
                </ModalConfirm>

                <Modal modal={modal} updateModal={setModal} eventModal={updateItem}>
                    <div className="row">
                        <div className="col-md-6">
                            <label className="form-label">Nombre</label>
                            <input type="text" className="form-control" value={bean.Name} onChange={(e) => { setBean({ ...bean, 'Name': e.target.value }) }} />
                        </div>
                        <div className="col-md-6">
                            <label className="form-label">Descripción</label>
                            <input type="text" className="form-control" value={bean.Description} onChange={(e) => { setBean({ ...bean, 'Description': e.target.value }) }} />
                        </div>
                    </div>
                </Modal>

            </BasicPage>

        </>
    );
}