import { useEffect, useState } from "react";
import { FinanceService } from "../../core/services/FinanceService";
import { BasicPage } from "../theme/Component/BasicPage";
import { Singleton } from "../../core/services/Singleton";
import { DropDown } from "../theme/Component/DropDown";
import { HiringProcessCatalog } from "./HiringProcessCatalog";
import { ConfigHiringProcess } from "./etapa-contrato/ConfigHiringProcess";
import { rolFuncionario } from "../../constans/SupplierConstants"
import { Alert } from '../theme/Component/Alert';
import { useDashboardStore } from "../pages/hooks/useDashboardStore";
import { PrintJson } from "../theme/Component/PrintJson";

interface Props {
  rol: number;
}
export const MisPlanesContratar = ({ rol }: Props) => {

  const actionsDropDown = [
    { id: 0, name: '...', permisos: null, activo: true },
    { id: 1, name: 'Aprobadas', permisos: null, activo: [1].includes(rol) },
    { id: 2, name: 'Preparación Necesidad', permisos: null, activo: [1, 2, 3, 4].includes(rol) },
    { id: 3, name: 'Contratada', permisos: null, activo: [1, 2, 3, 4].includes(rol) },
    { id: 4, name: 'Liquidación', permisos: null, activo: [1, 2, 3, 4].includes(rol) },
  ]

  const serviceFinance = new FinanceService();
  const single = Singleton.getInstance()
  const { setLoading } = useDashboardStore();
  const [lista, setLista] = useState<any>([]);
  const [listaFun, setListaFun] = useState<any>([]);
  const [idPlan, setIDPlan] = useState(0);
  const [idArea, setIDArea] = useState(0);
  const [view, setIDView] = useState(0);
  const [bean, setBean] = useState<any>({});
  const [dropDown, setDropDown] = useState<any>({});
  const [modal, setModal] = useState<any>({
    name: "Planes",
    tabIndex: -1,
    view: false,
    btnClose: true,
    btnSubmit: false,
    format: "modal-xl",
  });

  function getRol(id: number) {
    const resultado = rolFuncionario.filter(item => item.id === id);
    return resultado.length > 0 ? resultado[0].name : null;
  }

  useEffect(() => {
    getAdquisitionPlanOnHiringState();

    return () => {
      setIDView(0);
    }
  }, [rol]);

  useEffect(() => {
    getAdquisitionPlanFunctionalIDCatalog();
    setBean({});
  }, [idPlan]);

  useEffect(() => {
    setIDView(0);

    listaFun.forEach((element: any) => {
      if (element.DataBeanProperties.IDAdquisitionPlanFunctionalID === idArea) {
        setBean(element.DataBeanProperties);
        return;
      }
    });

  }, [idArea])

  const getAdquisitionPlanOnHiringState = () => {
    setLoading(true);
    serviceFinance
      .getAdquisitionPlanOnHiringState(single.getAccountID())
      .subscribe((resp: any) => {
        if (resp.DataBeanProperties.ObjectValue) {

          let temp: any = resp.DataBeanProperties.ObjectValue;
          //console.log(temp);


          setLista(temp);
        }
        setLoading(false);
      });
  };
  const getAdquisitionPlanFunctionalIDCatalog = () => {


    if (rol === 4) {
      setLoading(true);
      serviceFinance.getAdquisitionPlanFunctionalIDCatalog(idPlan)
        .subscribe((resp: any) => {
          if (resp.DataBeanProperties.ObjectValue) {
            let list = resp.DataBeanProperties.ObjectValue;
            if (list.length > 0)
              setIDArea(list[0].DataBeanProperties.IDAdquisitionPlanFunctionalID);
            setListaFun(list);
          }
          setLoading(false);
        });
    } else if (rol === 2) {
      setLoading(true);
      serviceFinance.getAdquisitionPlanFunctionalIDForPreparer(idPlan, single.getAccountID())
        .subscribe((resp: any) => {
          if (resp.DataBeanProperties.ObjectValue) {
            let list = resp.DataBeanProperties.ObjectValue;
            if (list.length > 0)
              setIDArea(list[0].DataBeanProperties.IDAdquisitionPlanFunctionalID);
            setListaFun(list);
          }
          setLoading(false);
        });
    }
    else {
      setLoading(true);
      serviceFinance.getAdquisitionPlanFunctionalIDByIDAccount(idPlan, single.getAccountID())
        .subscribe((resp: any) => {
          if (resp.DataBeanProperties.ObjectValue) {
            let list = resp.DataBeanProperties.ObjectValue;
            list.forEach((ele: any) => {

              ele.DataBeanProperties.AccountName =
                (ele.DataBeanProperties.Name1 ?? '') + ' ' +
                (ele.DataBeanProperties.Name2 ?? '') + ' ' +
                (ele.DataBeanProperties.Surname1 ?? '') + ' ' +
                (ele.DataBeanProperties.Surname2 ?? '');

            });

            if (list.length > 0)
              setIDArea(list[0].DataBeanProperties.IDAdquisitionPlanFunctionalID);
            setListaFun(list);

          }
          setLoading(false);
        });
    }


  };

  const head = [
    { title: "ID", property: "IDAdquisitionPlanFunctionalID", visible: false },
    { title: "Oficina", property: "IDLnFunctionalIDName" },
    { title: "Funcionario Responsable", property: "AccountName" },
    { title: "Observaciones", property: "Observations" },
    { title: "Valor Estimado", property: "EstimatedValue" },
    { title: "Valor Estructurado", property: "TotalValueEstimate" },
    { title: "Limitar Plan al Valor Estimado", property: "CloseToEstimatedValue", visible: false },
    { title: "Etapa del Plan", property: "PhaseStateName" },
  ];

  const setRow = (item: any) => {
    setBean(item);
    ////console.log(bean);

  };


  const setActionDrop = (item: any) => {
    //console.log(item);
    setDropDown(item);
    setModal({ ...modal, "view": true, "name": item.name });

  }
  const areaButtons = () => {
    return (
      <>
        <div className="row">

          <div className="col-md-6">
            {bean.IDAdquisitionPlanFunctionalID > 0 &&
              <div className="row mt-4">
                <div className="col">
                  {/* <strong>Areas Funcionales</strong> */}
                  <DropDown lista={actionsDropDown} eventClick={setActionDrop} ></DropDown>
                </div>
              </div>
            }
          </div>

        </div>

      </>
    )
  }

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <BasicPage titulo={`Mis Planes de Compra por Contratar / ${getRol(rol)}`}>

            <div className="row ">
              <div className="col-md-12">

              </div>
              <div className="col-md-12 ">
                <div className="row">

                  <div className="col-md-4">
                    <label>Plan de compras</label>
                    <select name="IDAdquisitionPlan"
                      className="form-select"
                      value={idPlan}
                      onChange={(e) => {
                        setIDPlan(parseInt(e.target.value));
                      }}
                    >
                      <option value="0">...</option>
                      {lista.map((item: any, index: number) => {
                        return (
                          <option key={index} value={item.DataBeanProperties.IDAdquisitionPlan}>
                            {item.DataBeanProperties.Description}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="col-md-4">
                    <label>Área / Oficina</label>
                    <select className="form-select" name="IDAdquisitionPlanFunctionalID" value={idArea} id="IDAdquisitionPlanFunctionalID"
                      onChange={(e) => { setIDArea(parseInt(e.target.value)) }}
                    >
                      {listaFun.map((item: any, index: number) => {
                        return (
                          <option key={index} value={item.DataBeanProperties.IDAdquisitionPlanFunctionalID}>
                            {item.DataBeanProperties.IDLnFunctionalIDName ? item.DataBeanProperties.IDLnFunctionalIDName : item.DataBeanProperties.Name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="col-md-4">
                    <label>Estado Necesidad</label>
                    <select name="tiponecesidad" className="form-select" value={view} onChange={(e) => { setIDView(parseInt(e.target.value)) }}>
                      {
                        actionsDropDown.map((item: any, index: number) => {
                          return (
                            <option key={index} value={item.id}>{item.name}</option>
                          )
                        })
                      }
                    </select>
                  </div>
                </div>

              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                {view === 0 &&
                  <Alert clase="primary">
                    <strong>Necesidades : </strong> Seleccione una opción
                  </Alert>
                }
                {view === 1 &&
                  <ConfigHiringProcess rol={rol} key={bean.IDAdquisitionItem} beanH={bean} beanOficina={bean} updateHiring={() => { }} />
                }
                {view === 2 &&
                  <HiringProcessCatalog _idArea={idArea} office={bean} idAdquisitionPlanFunctionalID={bean.IDAdquisitionPlanFunctionalID} phaseState={17} rol={rol} />
                }
                {view === 3 &&
                  <p>
                    Procesos contratados
                  </p>
                  // <HiringProcessCatalog office={bean} idAdquisitionPlanFunctionalID={bean.IDAdquisitionPlanFunctionalID} phaseState={11} />
                }
                {view === 4 &&
                  <p>
                    Procesos Liquidados
                  </p>
                  // <HiringProcessCatalog office={bean} idAdquisitionPlanFunctionalID={bean.IDAdquisitionPlanFunctionalID} phaseState={11} />
                }
              </div>
            </div>
          </BasicPage>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
        </div>
      </div>
    </>
  );
};
