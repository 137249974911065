import { NotFound } from "./theme/NotFound";
import { useParams } from "react-router-dom";
import { Characterization } from "./bpm/config/Characterization";
import { CustomerType } from "./bpm/config/CustomerType";
import { BusinessClass } from "./bpm/config/BusinessClass";
import { BusinessProcess } from "./bpm/config/BusinessProcess";
import { Office } from "./bpm/config/Office";
import { JsonServiceClass } from "./bpm/config/JsonServiceClass";
import { FormClass } from "./bpm/config/FormClass";
import { Procedure } from "./bpm/config/Procedure";
import { JsonService } from "./bpm/config/hooks/JsonService/JsonService";
import { Formularios } from "./bpm/config/Formularios";
import { TipoNovedad } from "./Talentohumano/TipoNovedad";
import { Novedades } from "./Talentohumano/Novedades";
import { InboxBpm } from "./bpm/inbox/InboxBpm";
import { Formreactform } from "./bpm/config/Componentes/Formreactform";
import { Parametricasth } from "./bpm/config/Componentes/Parametricasth";
import { SelectionTestType } from "./bpm/config/Componentes/SelectionTestType";
import { AuditAccount } from "./bpm/config/AuditAccount";
import { Historialdetramite } from "./bpm/config/Historialtramites";
import { RequestProcedure } from "./bpm/inbox/RequestProcedure";
import { FactorMatrix } from "./bpm/config/Componentes/FactorMatrix";
import { ModuleSSTT } from "./Talentohumano/ModuleSSTT";
import { RolesSST } from "./Talentohumano/RolesSST";
import { Tiposcomite } from "./Talentohumano/Tiposcomite";
import { PlanSGSST } from "./Talentohumano/PlanSGSST";
import { CommiteSST } from "./Talentohumano/CommiteSST";
import { YearPlan } from "./Talentohumano/YearPlan";
import { Danger } from "./pages/ParametersSST/Danger";
import { LegalMatrix } from "./pages/ParametersSST/LegalMatrix";
import { MatrixRisk } from "./pages/ParametersSST/MatrixRisk";
import { Disclaimer } from "./bpm/config/Disclaimer/Disclaimer";
import { MessageTemplate } from "./bpm/config/MessageTemplate/MessageTemplate";
import { Course } from "./Talentohumano/CurseConfig/Course";
import { Test } from "./Talentohumano/ConfigTest/Test";
import { Proof } from "./Talentohumano/TestProof/Proof";
import { PositionObjetive } from "./pages/Dashed/components/ContracsDashed/ComponentContract/PositionObjetive";
import { AppointmentManualUse } from "./pages/Dashed/components/ContracsDashed/ComponentContract/AppointmentManualUse";
import { EmployeeContract } from "./Talentohumano/GestionTH/EmployeeContract/EmployeeContract";
import { Contratoslaborales } from "./Talentohumano/Contratoslaborales/Contratoslaborales";
import { EmployeeContractWithoutFA } from "./Talentohumano/GestionTH/EmployeeContractWithoutFA/EmployeeContractWithoutFA";
import { ThirdBankingAccount } from "./Talentohumano/ParametrosTalentoHumano/ThirdBankingAccount/ThirdBankingAccount";
import { FiscalResponsability } from "./erp/FiscalResponsability/FiscalResponsability";
import { EmployeeRevenue } from "./Talentohumano/EmployeeRevenue/EmployeeRevenue";
import { CreateEmployeeContract } from "./Talentohumano/GestionTH/CreateEmployeeContract/CreateEmployeeContract";
import { EmployeeNovelty } from "./Talentohumano/GestionTH/EmployeeNovelty/EmployeeNovelty";
import { EmployeeDiscount } from "./Talentohumano/GestionTH/EmployeeDiscount/EmployeeDiscount";
import { ScheduledDiscount } from "./Talentohumano/GestionTH/Contribution/ScheduledDiscount";
import { EmployeeIncapacity } from "./Talentohumano/GestionTH/EmployeeIncapacity/EmployeeIncapacity";
import { ImportVariousConcepts } from "./Talentohumano/GestionTH/ImportVariousConcepts/ImportVariousConcepts";


export const RouterBpm = (props: any) => {
  const { view }: any = useParams();

  const renderSwitch = (url: string) => {
    switch (url) {
      case "request-procedure":
        return <RequestProcedure />;
      case "inbox":
        return <InboxBpm />;
      case "caracterizacion":
        return <Characterization />;
      case "ingresos-laborales":
        return <EmployeeRevenue />;

      case "tipodecliente":
        return <CustomerType />;

      case "clasedetramite":
        return <BusinessClass></BusinessClass>;

      case "process":
        return <BusinessProcess />;
      case "procedure":
        return <Procedure />;
      case "sucursales":
        return <Office />;
      case 'egresos-laborales':
        return <EmployeeDiscount />;

      case "historialdetramite":
        return <Historialdetramite />;

      case "Matrizdefactores":
        return <FactorMatrix />;

      case "tipodeservicios":
        return <JsonServiceClass />;

      case "servicios":
        return <JsonService />;

      case "Tipoevaluacion":
        return <Course />



      case "tiposdeformularios":
        return <FormClass />;

      case "formularios":
        return <Formularios />;

      case "tiponovedad":
        return <TipoNovedad />;

      case "novedad":
        return <Novedades />;

      case "parametricastalentohumano":
        return <Parametricasth />;

      case "rolessst":
        return <RolesSST />;

      case "tipodanger":
        return <Danger />;

      case "matriz-legal":
        return <LegalMatrix />;

      case "matriz-de-riesgos":
        return <MatrixRisk />;

      case "tipocomite":
        return <Tiposcomite />;

      case "formpruebas":
        return <Formreactform />;

      case "tiposdepruebaseleccion":
        return <SelectionTestType />;

      case "auditaccount":
        return <AuditAccount />;
      case "module-sstt":
        return <ModuleSSTT />;
      case "plan-sgsst":
        return <PlanSGSST />;
      case "commitee-SST":
        return <CommiteSST />;
      case "plan-anual":
        return <YearPlan />;
      case "disclaimers":
        return <Disclaimer />;
      case "message-templates":
        return <MessageTemplate />;

      case "test":
        return <Test />;

      case "cuentas-terceros":
        return <ThirdBankingAccount />;
      case "contratos-laborales":
        return <Contratoslaborales />;

      case "historial-contratos-trabajo":
        return <EmployeeContract />;

      case "importar-diversos-conceptos":
        return <ImportVariousConcepts />;

      case "crear-contratos-trabajo":
        return <CreateEmployeeContract />;

      case "aportes-descuentos-programados":
        return <ScheduledDiscount />;

      case "ingresar-incapacidades-laborales":
        return <EmployeeIncapacity />;

      case "ingresar-novedades-laborales":
        return <EmployeeNovelty />;

      case "historial-contratos-no-area":
        return <EmployeeContractWithoutFA />;
      case "proof":
        return <Proof />;

      case "objetivos-desempeno":
        return <PositionObjetive dashedMenu={false} />;

      case "ver-manuales":
        return <AppointmentManualUse dashedMenu={false} />;

      default:
        return <NotFound />;
    }
  };

  return (
    <div className="px-5" >
      {renderSwitch(view)}
    </div>
  );
};
