import { useEffect, useState } from "react";
import useDataBean from "../../../core/services/dataBean/useDataBean";
import { _RemoteAbstractAction, _single } from "../../../core/services/dataBean/EntityCatalog";
import { toast } from "react-toastify";
import { RemoteForm } from "./RemoteForm";
import { RemoteJsonForm } from "./RemoteJsonForm";
import { RemoteComponentProxy } from "./RemoteComponentProxy";
import { ExecBackAction } from "./ExecBackAction";

interface Props {
    remoteAbstractAction: any;
}
export const ExcecuteRemoteAbstract = ({ remoteAbstractAction }: Props) => {

    const [remote, setRemote] = useState<any>({});
    const [fire, setFire] = useState<any>({});
    const [slide, setSlide] = useState<any>({});
    const [response, setResponse] = useState<any>({});
    const [types, setTypes] = useState<any>([]);
    const { bean, lista, setBean, setLista, instance } = useDataBean(_RemoteAbstractAction);

    useEffect(() => {
        setRemote(remoteAbstractAction);
    }, [remoteAbstractAction])
    useEffect(() => {
        fireProcess();
    }, [remote])
    useEffect(() => {
        getNextStatge();
    }, [fire.IDApplicationInvoker])
    useEffect(() => {
        validateStep();
    }, [response])
    useEffect(() => {
        if (slide.TYPES)
            setTypes(slide.TYPES);

        _single.printOut("GetNextStep");
        _single.printOut(slide);

    }, [slide])

    useEffect(() => {
        if (response.MESSAGE) {
            if (response.MESSAGE != '')
                toast.success(response.MESSAGE);
        }
        if (response.RESPONSE_ID > 0)
            getNextStatge();

    }, [response])

    const fireProcess = () => {
        if (remote.IDRemoteAbstractAction) {
            instance.fireRemoteAbstractAction(remote.IDRemoteAbstractAction, _single.getAccountID()).then(
                (resp: any) => {
                    setFire(resp);
                }
            ).catch(err => toast.error(err))
        }
    }
    const getNextStatge = () => {

        if (fire.IDApplicationInvoker) {

            instance.getRemoteAbstractActionNextStep(fire.IDApplicationInvoker, _single.getAccountID()).then(
                (resp: any) => {
                    setSlide(resp);
                }
            ).catch(err => toast.error(err))

        }
    }

    const sendData = (e: any) => {
        if (e) {
            setBean(e);

            instance.setRemoteAbstractActionProperties(fire.IDApplicationInvoker, e, _single.getAccountID()).then(
                (resp: any) => {
                    setResponse(resp);

                    _single.printOut("RESPONSE => ");
                    _single.printOut(resp);


                }
            ).catch(err => { toast.error(err); })
        } else {
            toast.error("Sin datos para enviar!");
        }
    }
    const validateStep = () => {

        if (response.MESSAGE)
            if (response.MESSAGE != '')
                toast.success(response.MESSAGE);

        if (types.includes(1)) {
            sendData(bean);
        }
        if (response.RESPONSE_ID === 1)
            getNextStatge();

    }
    return (
        <div className="card">
            <div className="card-header">
                <h6 className="fs-15 mb-0"> {slide.NAME ? `${slide.NAME}` : ''}
                    {/* {fire.IDApplicationInvoker} */}
                </h6>
            </div>
            <div className="card-body">
                <div className="row">
                    <div className="col">

                        {slide.TYPES?.includes(0) ?
                            <>                            
                                {slide.COMPONENT_NAME ?
                                    // renderizar un componente echo a mano 
                                    <>
                                        <RemoteComponentProxy idApplicationInvoker={fire.IDApplicationInvoker} slide={slide} setData={sendData} />
                                    </>
                                    :
                                    <>
                                        {/* renderizar un componente dinamicamente */}
                                        {Object.keys(slide.PROPERTIES_STRUCTURE ?? {}).length > 0 ?
                                            <>                                                
                                                <RemoteForm useAsForm={false} idApplicationInvoker={fire.IDApplicationInvoker} fieldList={slide.PROPERTIES_STRUCTURE} data={slide.PROPERTIES} setData={sendData} />
                                            </>
                                            :
                                            <>                                                
                                                <RemoteJsonForm idApplicationInvoker={fire.IDApplicationInvoker} fieldList={slide.PROPERTIES_STRUCTURE} data={slide.PROPERTIES} setData={sendData} />
                                            </>
                                        }
                                    </>
                                }
                            </>
                            :
                            <>
                                <p>Cargando ...</p>
                            </>
                        }
                        {slide.TYPES?.includes(1) &&
                            <ExecBackAction invoke={fire} isComplete={(e) => { getNextStatge() }} />
                        }
                    </div>                 
                </div>
            </div>
        </div>
    )
}