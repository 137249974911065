import { useState } from "react"
import { StepWizard, Wizard } from "../../bpm/config/Componentes/wizard/Wizard"
import { BasicPage } from "../../theme/Component/BasicPage"
import { BudgetObligationForm } from "./BudgetObligationForm"
import { RegisterObligation } from "./RegisterObligation"
import { CreateObligationDocument } from "./CreateObligationDocument"
import { PrintJson } from "../../theme/Component/PrintJson"

export const BudgetObligationFactory = () => {

    const [rpDocument, setRPDocument] = useState<any>({})
    const [obligationList, setObligationList] = useState<any>({});

    const validarContrato = () => {

    }

    const steps: StepWizard[] = [
        {
            keyID: "0",
            name: 'PASO 1. Formulario',
            onEvent: validarContrato,
            children: <BudgetObligationForm onChange={setRPDocument} />,
            validButton: rpDocument?.DocumentRP?.IDDocument,
            canReturn: false
        },
        {
            keyID: "1",
            name: 'PASO 2. Obligaciónes Presupuestales a Obligar',
            onEvent: validarContrato,
            children: <RegisterObligation onChange={setObligationList} rpDocument={rpDocument.DocumentRP} />,
            validButton: obligationList.length > 0,
            canReturn: true
        },
        {
            keyID: "2",
            name: 'PASO 3. Crear Obligación Presupuestal',
            onEvent: validarContrato,
            children: <CreateObligationDocument obligationList={obligationList} rpDocument={rpDocument} />,
            validButton: false,
            canReturn: false
        }
    ]
    return (
        <BasicPage titulo="Generar Obligación Presupuestal">
            <Wizard title="Certificado" listStep={steps} />
        </BasicPage>
    )
}