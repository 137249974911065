import { DataBean } from "../DataBean";

export class TaxCodeScheme extends DataBean {


    public async getTaxCodeSchemeCatalog(): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_getTaxCodeSchemeCatalog_Number", []);
    }


}