import { useEffect, useState } from "react";
import { _TaxSchemeContextCode, _TaxSchemeValue } from "../../../../../core/services/dataBean/EntityCatalog";
import useDataBean from "../../../../../core/services/dataBean/useDataBean";
import { BasicTable, MASK_MONEY, MASK_NUMBER, MASK_TEXT_NOWRAP } from "../../../../theme/Component/BasicTable";
import { BasicPage } from "../../../../theme/Component/BasicPage";
import { AccountEditor } from "../../../../editor/AccountEditor";
import { BasicButton } from "../../../../theme/Component/BasicButton";
import { SelectUBLInvoice } from "../../../../contract/etapa-contrato/contrac/ublinvoice/SelectUBLInvoice";
import { Modal } from "../../../../theme/Component/Modal";
import { TaxCodeAccount } from "../../../../Talentohumano/ParametrosTalentoHumano/TaxCodeAccount/TaxCodeAccount";
import { SelectSiteID } from "../../../configuracion/SiteID/SelectSiteID";
import { toast } from "react-toastify";
import { TaxCodeMultiEditor } from "../../TaxCode/TaxCode.multiEditor";
import { TaxableArticleTypesEditor } from "./TaxableArticleTypes.editor";
import { TaxFilerTypesEditor } from "./TaxFilerTypes.editor";
import { TaxSchemeContextEditor } from "../../TaxSchemeCode/TaxSchemeContext.editor";
import { EDITOR_TAXBASE } from "../../../../editor/Editors";
import { ListMenu } from "../../../../theme/Component/ListMenu";
import { BasicMultiSelectTable } from "../../../../theme/Component/BasicMultiSelecTable";

interface Props {
    menuComponent: boolean;
    idAccount?: number;
    _invoice?: any;
    _onChange?: Function;
}

export const TaxSchemeSimulator = ({ menuComponent, idAccount, _invoice, _onChange }: Props) => {

    const { setBean, bean, lista, setLista, instance } = useDataBean(_TaxSchemeValue);
    const [value, setValue] = useState<number>(1);

    const [scheme, setScheme] = useState<number>(null)
    const [listaScheme, setListaScheme] = useState<any>([])
    const [listaResult, setListaResult] = useState<any>([])
    const [account, setAccount] = useState<number | null>(null);
    const [siteList, setSiteList] = useState<number[]>([]);
    const [codeList, setCodeList] = useState<number[]>([]);

    const [articlesType, setArticlesTypes] = useState<number>(null);
    const [taxFiler, setTaxFiler] = useState<number>(null);
    const [schemeContext, setSchemeContext] = useState<number>(null);

    const [modal, setModal] = useState<any>({ name: 'Buscar', tabIndex: -1, view: false, btnClose: true, btnSubmit: false, format: 'modal-md' });
    const [modalList, setModalList] = useState<any>({ name: 'Seleccione los Impuestos deseados', tabIndex: -1, view: false, btnClose: true, btnSubmit: false, format: 'modal-lg' });
    const [modalTaxable, setModalTaxable] = useState<any>({ name: 'Configuracion de Base', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-md' });

    const headResult = [
        { title: 'ID', property: 'IDTaxSchemeValue', visible: true, mask: MASK_NUMBER },
        { title: 'Tipo de Impuesto', property: 'TaxSchemeName', visible: true, },
        { title: 'Valor', property: 'TaxValueName', visible: true, mask: MASK_TEXT_NOWRAP },
        { title: 'Tipo', property: 'ValueTypeName', visible: true },
        { title: 'Descripción', property: 'Description', visible: true },
        { title: 'Base', property: 'TaxableBase', visible: true, cellEditor: EDITOR_TAXBASE, mask: MASK_MONEY },
    ]

    useEffect(() => {
        if (idAccount >= 0) {
            setAccount(idAccount);
        }
    }, [idAccount])

    useEffect(() => {
        if (_onChange) {
            _onChange(listaResult)
        }
    }, [listaResult])

    const getTaxSchemeValueCatalogWithTaxHolding = () => {
        instance.getTaxSchemeValueCatalogWithTaxHolding(codeList.length > 0 ? codeList : null, siteList.length > 0 ? siteList : null, account, null).then(
            (resp) => {
                setLista(resp);
               //console.log(resp);
            }
        ).catch(
            err => {
               console.error(err);
                toast.error("Algo salio Mal");
            }
        )
    }

    const getTaxSchemeCatalogWithHoldingTax = () => {
        instance.getTaxSchemeCatalogWithHoldingTax(schemeContext, articlesType, taxFiler).then(
            (resp) => {
               //console.log(resp)
                setListaScheme(resp)
            }
        ).catch(
            err => {
                toast.error(err);

            }
        )
    }

    const getTaxSchemeValueCatalogAvailable = () => {
        instance.getTaxSchemeValueCatalogAvailable(scheme, articlesType, taxFiler).then(
            (resp) => {
               //console.log(resp)
                setLista(resp)
            }
        ).catch(
            err => {
                toast.error(err);
            }
        )
    }

    useEffect(() => {
        getTaxSchemeCatalogWithHoldingTax();
    }, [schemeContext, articlesType, taxFiler])

    const toggleTaxScheme = (item: any) => {
        setBean(item)
        const isAlreadyInList = listaResult.some((scheme: any) => scheme.DataBeanProperties.IDTaxSchemeValue === item.IDTaxSchemeValue);

        let updatedList;
        if (isAlreadyInList) {
            updatedList = listaResult.filter((scheme: any) => scheme.DataBeanProperties.IDTaxSchemeValue !== item.IDTaxSchemeValue);
        } else {
            updatedList = [...listaResult, { "DataBeanProperties": { ...item, TaxableBase: 0 } }];
        }

        setListaResult(updatedList);
    };

    const listaBotones = () => {
        const botones = [
            { titulo: 'Eliminar', icono: 'ri-delete-bin-line', visible: true, evento: () => { toggleTaxScheme(bean) } },
        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }

    const updateTaxSchemeInList = (e: any) => {
        const updatedList = listaResult.map((item: any) => {
            if (item.DataBeanProperties.IDTaxSchemeValue === e.IDTaxSchemeValue) {
                return {
                    ...item,
                    DataBeanProperties: {
                        ...item.DataBeanProperties,
                        TaxableBase: e.TaxableBase,
                    },
                };
            }
            return item;
        });

        setListaResult(updatedList);
        setModalTaxable({ ...modalTaxable, "view": false })
    };

    const head = [
        { title: 'ID', property: 'IDTaxSchemeValue' },
        { title: 'Tipo de Impuestos', property: 'TaxSchemeName' },
        { title: 'Valor', property: 'TaxValueName' },
        { title: 'Tipo', property: 'ValueTypeName' },
        { title: 'Descripción', property: 'Description' },
    ]

    return (
        <>
            {menuComponent ?
                <BasicPage titulo='Simulador de Retenciones'>
                    <>
                        <div className="row">
                            <div className="col-6">

                                <label>Buscar Impuestos por</label>
                                <select className="form-select" value={value} onChange={(e) => { setValue(parseInt(e.target.value)) }}>
                                    <option value={1}>Códigos de Clasificación y/o Tercero</option>
                                    <option value={2}>Buscar Tipo de Impuesto</option>
                                </select>
                            </div>
                            <div className="col-6">
                                <SelectUBLInvoice _onChange={(e) => { localStorage.setItem("SimulatorInvoice", JSON.stringify(e)); }} />
                            </div>
                        </div>
                        <hr />
                        {value === 1 &&
                            <>
                                <div className="row d-flex justify-content-between">
                                    <div className="col-8">
                                        <AccountEditor label="Tercero" idAccount={account} onChange={(e) => { setAccount(e.IDAccount) }} />
                                    </div>
                                    {account &&
                                        <div className="col-4 d-flex align-items-end">
                                            <BasicButton icon="ri-search-line" eventClick={() => { setModal({ ...modal, "view": true, "name": "Consultar/Modificar Códigos del Tercero" }) }} >Consultar/Modificar Códigos del Tercero</BasicButton>
                                        </div>
                                    }

                                    <div className="col-12">
                                        <SelectSiteID label="Ubicación Geográfica" multiSelect edit onChange={(e) => {
                                            const idlns = e.map((cities: any) => cities.IDLn);
                                            setSiteList(idlns);
                                           //console.log(idlns);
                                        }} />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <TaxCodeMultiEditor _onChange={(e) => {
                                            const codes = e.map((schemes) => schemes.DataBeanProperties.IDTaxCode);
                                            setCodeList(codes);
                                           //console.log(codes);
                                        }} />
                                    </div>
                                </div>
                            </>
                        }
                        {value === 2 &&
                            <>
                                <div className="row">
                                    <div className="col-6">
                                        <TaxableArticleTypesEditor idSelector={articlesType} onChange={(e) => { setArticlesTypes(e) }} />
                                    </div>
                                    <div className="col-6">
                                        <TaxFilerTypesEditor idSelector={taxFiler} onChange={(e) => { setTaxFiler(e) }} />
                                    </div>
                                    <div className="col-6">
                                        <TaxSchemeContextEditor idSelector={schemeContext} onChange={(e) => { setSchemeContext(e) }} />
                                    </div>
                                    <div className="col-6">
                                        {listaScheme.length > 0 &&
                                            <>
                                                <label>Tipos de Impuesto</label>
                                                <select className="form-select" value={scheme} onChange={(e) => { setScheme(parseInt(e.target.value)) }}>
                                                    <option value={null}>...</option>
                                                    {listaScheme &&
                                                        listaScheme.map((item: any, index: number) => {
                                                            return (
                                                                <option key={index} value={item.DataBeanProperties.IDTaxScheme} >{item.DataBeanProperties.Code} - {item.DataBeanProperties.Name}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </>
                                        }
                                    </div>
                                </div>
                            </>
                        }

                        <div className="row mb-3">
                            <div className="col-2">
                                <BasicButton icon="ri-search-line" eventClick={() => {
                                    setModalList({ ...modalList, "view": true })
                                    if (value === 1) {
                                        getTaxSchemeValueCatalogWithTaxHolding()
                                    } else if (value === 2) {
                                        getTaxSchemeValueCatalogAvailable()
                                    }
                                }} >Buscar</BasicButton>
                            </div>
                        </div>

                        <BasicTable listButtons={listaBotones()} body={listaResult} head={headResult} rowSet={setBean} callBack={(e) => { updateTaxSchemeInList(e) }} />
                    </>
                </BasicPage>
                :
                <>
                    <div className="row">
                        <div className="col-12">
                            <div className="row">
                                <div className="col-6">

                                    <label>Buscar Impuestos por</label>
                                    <select className="form-select" value={value} onChange={(e) => { setValue(parseInt(e.target.value)) }}>
                                        <option value={1}>Códigos de Clasificación y/o Tercero</option>
                                        <option value={2}>Buscar Tipo de Impuesto</option>
                                    </select>
                                </div>
                                <div className="col-6">
                                    <SelectUBLInvoice disabled={_invoice.IDUBLInvoice} _invoice={_invoice} _onChange={(e) => { localStorage.setItem("SimulatorInvoice", JSON.stringify(e)); }} />
                                </div>
                            </div>
                            <hr />
                            {value === 1 &&
                                <>
                                    <div className="row d-flex justify-content-between">
                                        <div className="col-8">
                                            <AccountEditor disabled={idAccount >= 0} label="Tercero" idAccount={account} onChange={(e) => { setAccount(e.IDAccount) }} />
                                        </div>
                                        {account &&
                                            <div className="col-4 d-flex align-items-end">
                                                <BasicButton icon="ri-search-line" eventClick={() => { setModal({ ...modal, "view": true, "name": "Consultar/Modificar Códigos del Tercero" }) }} >Consultar/Modificar Códigos del Tercero</BasicButton>
                                            </div>
                                        }

                                        <div className="col-12">
                                            <SelectSiteID label="Ubicación Geográfica" multiSelect edit onChange={(e) => {
                                                const idlns = e.map((cities: any) => cities.IDLn);
                                                setSiteList(idlns);
                                               //console.log(idlns);
                                            }} />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <TaxCodeMultiEditor _onChange={(e) => {
                                                const codes = e.map((schemes) => schemes.DataBeanProperties.IDTaxCode);
                                                setCodeList(codes);
                                               //console.log(codes);
                                            }} />
                                        </div>
                                    </div>
                                </>
                            }
                            {value === 2 &&
                                <>
                                    <div className="row">
                                        <div className="col-6">
                                            <TaxableArticleTypesEditor idSelector={articlesType} onChange={(e) => { setArticlesTypes(e) }} />
                                        </div>
                                        <div className="col-6">
                                            <TaxFilerTypesEditor idSelector={taxFiler} onChange={(e) => { setTaxFiler(e) }} />
                                        </div>
                                        <div className="col-6">
                                            <TaxSchemeContextEditor idSelector={schemeContext} onChange={(e) => { setSchemeContext(e) }} />
                                        </div>
                                        <div className="col-6">
                                            {listaScheme.length > 0 &&
                                                <>
                                                    <label>Tipos de Impuesto</label>
                                                    <select className="form-select" value={scheme} onChange={(e) => { setScheme(parseInt(e.target.value)) }}>
                                                        <option value={null}>...</option>
                                                        {listaScheme &&
                                                            listaScheme.map((item: any, index: number) => {
                                                                return (
                                                                    <option key={index} value={item.DataBeanProperties.IDTaxScheme} >{item.DataBeanProperties.Code} - {item.DataBeanProperties.Name}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </>
                            }

                            <div className="row mb-3">
                                <div className="col-2">
                                    <BasicButton icon="ri-search-line" eventClick={() => {
                                        setModalList({ ...modalList, "view": true })
                                        if (value === 1) {
                                            getTaxSchemeValueCatalogWithTaxHolding()
                                        } else if (value === 2) {
                                            getTaxSchemeValueCatalogAvailable()
                                        }
                                    }} >Buscar</BasicButton>
                                </div>
                            </div>

                            <BasicTable listButtons={listaBotones()} body={listaResult} head={headResult} rowSet={setBean} callBack={(e) => { updateTaxSchemeInList(e) }} />
                        </div>
                    </div>
                </>
            }

            {modal.view &&
                <Modal modal={modal} updateModal={setModal} >
                    <TaxCodeAccount _idAccount={account} isModal />
                </Modal>
            }

            {modalList.view &&
                <Modal modal={modalList} updateModal={setModalList} >
                    <BasicMultiSelectTable keyId="IDTaxSchemeValue" bodyResult={listaResult} head={head} body={lista} updateValues={({ bodyResult }) => { setListaResult(bodyResult) }} />
                </Modal>
            }
        </>
    )
}