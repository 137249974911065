import { Alert } from "../../../modules/theme/Component/Alert";
import { BasicButton } from "../../../modules/theme/Component/BasicButton";
import { BasicPage } from "../../../modules/theme/Component/BasicPage"
import { ReadInputFile } from "./ReadInputFile"
import { useState } from 'react';
import * as openpgp from 'openpgp';
import { readKey, Key } from 'openpgp';
import { BasicSwitch } from "../../../modules/theme/Component/BasicSwitch";
import { Singleton } from "../../services/Singleton";
import { PrintObjectJson } from "../../../modules/theme/Component/PrintObjectJson";


export const PgpEncrypt = () => {

    const single = Singleton.getInstance()
    const [infoKey, setInfoKey] = useState<any>({});
    const [publicKey, setPublicKey] = useState<string>('');
    const [privateKey, setPrivateKey] = useState<any>('');
    const [file, setFile] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');
    const [encryptedText, setEncryptedText] = useState<any>('');
    const [showFile, setShowFile] = useState(false);


    const cryptText = async ({ pubKey, priKey, pass, file, sing }: any) => {

        try {

            // public key
            const publicArmoredKey: any = pubKey;
            const publicKey = await openpgp.readKey({ armoredKey: publicArmoredKey });

            const text = file

            const cryptOptions: any = {
                message: await openpgp.createMessage({ text }),
                encryptionKeys: publicKey,
            };

            // if signing options are enabled
            if (sing === true) {
                const privateArmoredKey = priKey;

                const privateKey = await openpgp.decryptKey({
                    privateKey: await openpgp.readPrivateKey({ armoredKey: privateArmoredKey }),
                    passphrase: pass
                });

                cryptOptions.signingKeys = privateKey;

            }

            setEncryptedText(await openpgp.encrypt(cryptOptions));
            setMessage("Archivo Generado Exitosamente.");

            single.makeAcction("Se ha encriptado un archivo", {
                encriptado: 'si',
                firmado: sing ? 'si' : 'no'
            });

        } catch (err: any) {
           console.error(err);
            alert(err.message);
            // setMessage(err);
        }
    };

    const downloadTextAsFile = () => {
        const element = document.createElement("a");
        const file = new Blob([encryptedText], { type: "text/plain" });
        element.href = URL.createObjectURL(file);
        element.download = "export.pgp";
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    };


    const getPublicKeyInfo = async (publicK: any) => {
        try {
            const publicKey: Key = await readKey({ armoredKey: publicK });
            if (publicKey) {


                let info: any = {

                };

                const userIDs = publicKey.getUserIDs();
                const fingerprint = publicKey.getFingerprint();
                // const bitSize = publicKey.getBitSize();
                const created: any = publicKey.getCreationTime();
                const final: any = publicKey.getExpirationTime().then((data) => {

                    info.ID = userIDs;
                    info.Fingerprint = fingerprint;
                    info.Created = single.parseDate(new Date(created));
                    info.ExpiredDate = single.parseDate(data);

                   //console.log(info);
                    setInfoKey(info);
                })
                    .catch((error) => {
                        console.error('Error al obtener datos:', error.message);
                        info.vencimiento = "Indefinido";
                    });;

            } else {
               //console.log('No se pudo leer la clave pública.');
            }
        } catch (error: any) {
            console.error('Error al leer la clave pública:', error.message);
        }
    };


    return (
        <BasicPage titulo="Encriptación">
            <div className="row">
                <div className="col-md-4">
                    <label>Archivo</label>
                    <ReadInputFile onChange={setFile} />
                </div>
                <div className="col-md-4">
                    <label>Llave Publica</label>
                    <ReadInputFile onChange={setPublicKey} />
                </div>
                <div className="col-md-4">
                    <label>Llave Privada</label>
                    <ReadInputFile onChange={setPrivateKey} />
                </div>
                <div className="col-md-6">
                    <label>Contraseña</label>
                    <input type="password" className="form-control" value={password} onChange={(e) => { setPassword(e.target.value) }} />
                </div>
                <div className="col-md-6 mt-4">
                    <BasicButton disable={publicKey == ''} icon="ri-file-info-line" eventClick={() => { getPublicKeyInfo(publicKey) }}>Información</BasicButton>
                    <BasicButton icon="ri-git-repository-private-line"
                        disable={!(file != '' && publicKey != '')}
                        eventClick={() => {
                            let valores = {
                                pubKey: publicKey,
                                priKey: privateKey,
                                pass: password,
                                file: file,
                                sing: false
                            }
                            cryptText(valores);
                        }}>Encriptar</BasicButton>
                    <BasicButton icon="ri-pencil-fill"
                        disable={!(file != '' && publicKey != '' && privateKey != '' && password != '')}
                        eventClick={() => {
                            let valores = {
                                pubKey: publicKey,
                                priKey: privateKey,
                                pass: password,
                                file: file,
                                sing: true
                            }
                            cryptText(valores);
                        }}>Firmar y encriptar</BasicButton>



                </div>
                <div className="col-md-6">
                    <BasicButton
                        disable={encryptedText == ''}
                        icon="ri-file-download-line" eventClick={downloadTextAsFile}>Descargar</BasicButton>


                </div>
                <div className="col-md-6">
                    <BasicSwitch label="Mostrar Archivo" estado={showFile} eventChange={setShowFile} />
                </div>
            </div>
            <div className="row ">
                <div className="col">
                    {message != '' &&
                        <Alert clase="primary">
                            <strong>{message}</strong>
                        </Alert>
                    }
                </div>

                {showFile &&
                    <div className="col-md-12">
                        <textarea className="form-control" value={encryptedText} readOnly></textarea>
                    </div>
                }
                {/* <PrintJson json={infoKey}/> */}
                <PrintObjectJson json={infoKey} />
            </div>
        </BasicPage>
    )
}