import { useEffect, useState } from "react";
import { FinanceService } from "../../core/services/FinanceService";
import { BasicButton } from "../theme/Component/BasicButton";
import { Alert } from "../theme/Component/Alert";
import { useDashboardStore } from "../pages/hooks/useDashboardStore";
import { Singleton } from "../../core/services/Singleton";

interface Props {
    bean: any;
    handleUpdate?: any;
}
export const AdquisitionPhase = ({ bean, handleUpdate }: Props) => {


    const serviceFinance = new FinanceService();
    const single = Singleton.getInstance()
    const { setLoading } = useDashboardStore();

    const [phase, setPhase] = useState([]);
    const [idEstado, setEstado] = useState(0);
    const [obs, setObs] = useState('');
    const [up, setUp] = useState(0);
    const [message, setMessage] = useState('');

    useEffect(() => {
        setMessage('');
        setUp(0);
        getAdquisitionPlanPhaseConstants();
    }, [])


    const getAdquisitionPlanPhaseConstants = () => {

        setLoading(true);
        serviceFinance.getAdquisitionPlanPhaseConstants(null).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue) {
                    setPhase(resp.DataBeanProperties.ObjectValue);
                }
                setLoading(false);
            }
        )

    }

    const update = () => {

        setLoading(true);
        serviceFinance.setPhaseToAdquisitionPlan(bean.IDAdquisitionPlan, idEstado, single.getAccountID(), obs).subscribe(
            (resp: any) => {

               //console.log(resp);

                if (resp.DataBeanProperties.ObjectValue) {
                    setUp(0);
                    handleUpdate();
                }
                setLoading(false);
            }
        )
    }

    return (
        <>
            <div className="row">

                <div className="col-md-12">
                    <label>Estado</label>
                    <select className="form-control" value={idEstado} onChange={(e) => { setEstado(parseInt(e.target.value)) }}>
                        {phase.length > 0 &&
                            phase.map((item: any) => {
                                return (
                                    <option value={item.DataBeanProperties.Value}>{item.DataBeanProperties.Property}</option>
                                )
                            })
                        }
                    </select>
                </div>
                <div className="col-md-12">
                    <label>Observaciones</label>
                    <textarea className="form-control" value={obs} onChange={(e) => { setObs(e.target.value) }}></textarea>
                </div>

                <div className="col-md-12">
                    <BasicButton value="Actualizar" icon="ri-file-add-line" eventClick={update}></BasicButton>
                </div>

                <div className="col-md-12">
                    {message !== '' &&
                        <Alert clase="warning" >{message}</Alert>
                    }
                </div>

            </div>
        </>
    )
}