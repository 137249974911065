import { useEffect, useState } from "react";
import { _single } from "../../../../../../../core/services/dataBean/EntityCatalog";
import { SelectBudgetType } from "../../../../../../admin/configuracion/select/SelectBudgetType";
import { InputDate } from "../../../../../../theme/Input/InputDate";


interface Props {
    onChange: Function;
}

export const CreateCDPForm = ({ onChange }: Props) => {

    const [response, setResponse] = useState<any>({
        Date: _single.getCurrenDate(),
    });

    useEffect(() => {
        onChange(response)
    }, [response])

    return (
        <div className="row">
            <div className="col-md-4">
                <SelectBudgetType idSelector={response.BudgetIDType} onChange={(e: any) => { setResponse({ ...response, "BudgetIDType": e }) }} />

            </div>
            <div className="col-md-4">
                <InputDate label='Fecha de Registro' name='accountantDate' value={response.Date} setDate={(e: any) => { setResponse({ ...response, "Date": e }) }} />
            </div>
            <div className="col-md-4">
                <label>Consecutivo Externo</label>
                <input type="number" name="Value" className="form-control" value={response.Consecutive} onChange={(e) => { setResponse({ ...response, "Consecutive": parseInt(e.target.value) }) }} />
            </div>
            <div className="col-md-12">
                <label>Descripción</label>
                <textarea name="Description" className="form-control" value={response.Description} onChange={(e) => { setResponse({...response,"Description":e.target.value}) }} />
            </div>
        </div>
    )
}