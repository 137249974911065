import { useEffect, useState } from "react";
import { Singleton } from '../../core/services/Singleton';
import { ContractService } from "../../core/services/ContractService";
import { Alert } from "../theme/Component/Alert";
import { VisorPDF } from "./VisorPDF";
import { CardTaps } from "../theme/Component/CardTaps";
import { FileService } from "../../core/services/FileService";


interface Props {
    documentLocation: number;
    mnemonic: string;
    date: Date;

}
export const PdfDocuments = ({ documentLocation, mnemonic, date }: Props) => {

    const single = Singleton.getInstance();
    const contracService = new ContractService();
    const file = new FileService();

    const [indexTap, setIndexTap] = useState(0);
    const [row, setRow] = useState<any>({});

    const [modal, setModal] = useState<any>({ name: 'Visor', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-xl' });
    const [message, setMessage] = useState('');
    const [lista, setLista] = useState<any>([
      
    ]);

    let taps = [
            { id: 0, name: 'Documentos', disable: false },
            { id: 1, name: 'Visor', disable: lista.length===0 }
        ];

   
    useEffect(() => {
        return () => { setMessage(''); setLista([]) }
    }, [])

    useEffect(() => {
        getPdfAccountantDocument();
    }, [documentLocation])

    const getPdfAccountantDocument = () => {
        if (documentLocation && mnemonic && date) {
            single.spinner(true);
            contracService.getPdfAccountantDocument(documentLocation, mnemonic, date).subscribe(
                (resp: any) => {


                    if (resp.DataBeanProperties.ObjectValue) {
                        setLista(resp.DataBeanProperties.ObjectValue);
                    }
                    single.spinner(false);

                }
            );

        } else {
            setMessage("No hay documentos para visualizar");
        }
    }

    const vierDocument = (item: any) => {
        setRow(item);
        setIndexTap(1);
    }


    return (
        <div className="row">
            <div className="col-md-12">
                <CardTaps taps={taps} index={indexTap} updateIndex={setIndexTap}>
                    {indexTap === 0 &&
                        <>
                            {lista.length === 0 ?
                                <Alert clase="warning">
                                    <p>No hay documentos para mostrar</p>
                                </Alert>
                                :
                                // <PrintJson json={lista} />
                                <>
                                    <div className="list-group">
                                        {lista.map((item: any, index: number) => {
                                            return (
                                                <button onClick={() => { vierDocument(item.DataBeanProperties) }} key={index} type="button" className="list-group-item list-group-item-action" aria-current="true">
                                                    <i className="ri-article-line fs-2 align-middle me-2 cursor"></i>
                                                    {item.DataBeanProperties.Filename}
                                                </button>
                                            )
                                        })}
                                    </div>
                                </>
                            }
                        </>
                    }
                    {indexTap === 1 &&
                        <div className="row">
                            <div className="col-md-12">

                            </div>
                            <div className="col-md-12">

                                <VisorPDF url={row.URLLink} type={row.Type} />
                            </div>
                        </div>
                    }
                </CardTaps>
            </div>
         

        </div>
    )
}