import { useEffect, useState } from "react";
import useDataBean from "../../../../../core/services/dataBean/useDataBean";
import { _ConfigAdmin } from "../../../../../core/services/dataBean/EntityCatalogVdos";
import { Singleton } from "../../../../../core/services/Singleton";
import useModal, { MODAL_md, MODAL_SM } from "../../../../theme/Component/hooks/useModal";
import { toast } from "react-toastify";
import { ListMenu } from "../../../../theme/Component/ListMenu";
import { BasicPage } from "../../../../theme/Component/BasicPage";
import { BasicTable } from "../../../../theme/Component/BasicTable";
import { Modal } from "../../../../theme/Component/Modal";
import { ModalConfirm } from "../../../../theme/Component/ModalConfirm";
import { AccountEditor } from "../../../../editor/AccountEditor";
import { BasicSwitch } from "../../../../theme/Component/BasicSwitch";
import { InputDate } from "../../../../theme/Input/InputDate";
interface Props {
    functionaID: any;
}

export const ListPersonal = ({ functionaID }: Props) => {

    const ListPersonal = useDataBean(_ConfigAdmin);
    const [bean, setbean] = useState<any>({})
    const single = Singleton.getInstance();
    const [lista, setLista] = useState<any>([]);
    const [Modality, setModality] = useState<any>({});
    const [modal, setModal] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-md' });
    const [modalC, setModalC] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });
    const modalConfirm = useModal("Orden", MODAL_SM);
    const modalEditar = useModal("Orden", MODAL_md);

    useEffect(() => {

        getLista()

    }, [functionaID])

    const getLista = () => {

        ListPersonal.instance.getFunctionalAreaByFunctionalID(functionaID.IDLn, null).then(
            (resp: any) => {
                if (resp) {
                    setLista(resp)
                  
                }
            }
        ).catch(err =>console.error(err))

    }

    const create = () => {

        modalEditar.open();

        ListPersonal.setBean({
            // State: 1,


        });


    }






    const editaItem = () => {
       //console.log(ListPersonal.bean);
        ListPersonal.update().then(
            (resp: any) => {
                getLista();
                modalEditar.close();

            }
        ).catch(err => {
            toast.error(err);
        })

    }





    const head = [

        { title: 'ID', property: 'IDLn', visible: true },
        { title: 'Nombre', property: 'Name', visible: true },
        { title: 'Empleado', property: 'AccountName', visible: true },
        { title: 'Estado del Contrato', property: 'ContractStateName', visible: true },
        { title: 'Estado del Cargo', property: 'StateName', visible: true },
        { title: 'Inicio Contrato', property: 'InitContract', visible: true },
        { title: 'Fin Contrato', property: 'UptoContract', visible: true },
        { title: 'Manual de Funciones', property: 'AppointmentManualName', visible: true },
        { title: 'Foto', property: 'Photo', visible: true },



    ]




    return (

        <div className="row">
            <BasicPage titulo="Lista de Personal en el Área">

                <div className="col-md-12">

                    <BasicTable body={lista} head={head} rowSet={ListPersonal.setBean} ></BasicTable>

                    <Modal modal={modalEditar.modal} updateModal={modalEditar.setModal} eventModal={editaItem}>
                        <div className="row">
                            <div className="col-md-12">
                                <label>Nombre</label>
                                <input type="string" className="form-control" name="Name" value={ListPersonal.bean.Name} onChange={ListPersonal.handleChange} />
                            </div>
                            <div className="col-6">
                                <AccountEditor label="Empleado" idAccount={ListPersonal.bean.IDAccount} onChange={(e: any) => { ListPersonal.setBean({ ...ListPersonal.bean, "IDAccount": e.IDAccount }) }} />
                            </div>
                            <div className="col-6">
                                <BasicSwitch label="Estado del Cargo" estado={ListPersonal.bean.StateName} eventChange={(e) => { ListPersonal.setBean({ ...ListPersonal.bean, "StateName": e }) }} />
                            </div>
                            <div className="col-md-6">
                                <InputDate notDate label="Inicio Contrato" name="InitDate" value={ListPersonal.bean.InitContract} setDate={(e) => { ListPersonal.setBean({ ...ListPersonal.bean, "InitContract": e }) }} />
                            </div>
                            <div className="col-md-6">
                                <InputDate notDate label="Fin Contrato" name="accountantDate" value={ListPersonal.bean.UptoContract} setDate={(e) => { ListPersonal.setBean({ ...ListPersonal.bean, "UptoContract": e }) }} />
                            </div>


                        </div>
                    </Modal>

                </div>
            </BasicPage>


        </div>

    )
}