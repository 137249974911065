import { useEffect, useState } from "react";

import useDataBean from "../../../core/services/dataBean/useDataBean";
import { CashReimbursementServices } from "../cellEditor/CashDesk/CashReimbursement/CashReimbursement-service";
import { toast } from "react-toastify";
import { Singleton } from "../../../core/services/Singleton";
import { ListMenu } from "../../theme/Component/ListMenu";
import { BasicTable, MASK_DATE, MASK_MONEY, MASK_NUMBER } from "../../theme/Component/BasicTable";
import { BasicPage } from "../../theme/Component/BasicPage";
import { Modal } from "../../theme/Component/Modal";

import { ModalConfirm } from "../../theme/Component/ModalConfirm";
import { _AccountantConcept, _FiscalResponsability, _FiscalResponsabilityType } from "../../../core/services/dataBean/EntityCatalog";

import { _AdquisitionProject, _ArticleClassType, _MeasurementUnitCode, _Process, _Project } from "../../../core/services/dataBean/EntityCatalogVdos";
import { SelectAdquisitionArticleConstants } from "../../contract/select/SelectAdquisitionArticleConstants";
import useModal, { MODAL_md, MODAL_SM } from "../../theme/Component/hooks/useModal";
import { InputDate } from "../../theme/Input/InputDate";
import { AccountEditor } from "../../editor/AccountEditor";
import { AdquisitionBeneficiary } from "../AdquisitionBeneficiary/AdquisitionBeneficiary";
import { PaymentMethodSelect } from "../../Talentohumano/PaymentMethod/PaymentMethodSelect";
import { BasicSwitch } from "../../theme/Component/BasicSwitch";
import { SelectProjectID } from "../../admin/configuracion/ProjectID/SelectProjectID";
import { SelectorProcessTypeConstants } from "../../bpm/config/Componentes/SelectorProcessTypeConstants";
import { ProcessStep } from "../ProcessStep/ProcessStep";
import { ProveedorDocumentos } from "../ProveedorDoc/ProveedorDoc";




export const Process = () => {

    const process = useDataBean(_Process);

    const single = Singleton.getInstance();
    const [lista, setLista] = useState<any>([]);
    const [proveedormodal, setproveedormodal] = useState<any>({ name: 'Proveedor', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [beneficiario, setbeneficiario] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modal, setModal] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalC, setModalC] = useState<any>({ name: '', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });
    const [swich, setSwich] = useState<boolean>(null)
    const modalConfirm = useModal("Orden", MODAL_SM);
    const modalEditar = useModal("Agregar Proyecto", MODAL_md);


    useEffect(() => {
        getLista()

    }, [])


    const getLista = () => {

        process.instance.getProcessCatalogPorPropiedades({ State: 1 }, null).then(
            (resp: any) => {
                if (resp) {
                    setLista(resp)
                }

            }
        )

    }



    const create = () => {
        process.setBean({
            State: 1,
            IDEmployee: single.getAccountID(),
        });
        setSwich(false);
        modalEditar.setModal({ ...modalEditar.modal, "view": true });
    }

    const editaItem = () => {
        //console.log(process.bean);
        process.update().then(
            (resp: any) => {
                getLista();
                modalEditar.setModal({ ...modalEditar.modal, "view": false });
            }
        ).catch(err => {
            toast.error(err);
        })

    }
    const deleteItem = () => {
        process.bean.State = 2;
        //console.log(process.bean);
        editaItem();
        modalConfirm.close();
    }




    const head = [
        { title: 'ID', property: 'IDProcess', visible: true, mask: MASK_NUMBER },
        { title: 'Nombre', property: 'Name', visible: true },
        { title: 'Descripción', property: 'Description', visible: true },
        { title: 'Código', property: 'CodeProcess', visible: true },
        { title: 'Tipo Proceso', property: 'ProcessType', visible: true },



    ]

    const iconButtonsItem = [
        { nombre: 'Agregar', visible: single.canCreate(), icono: 'ri-file-add-line', evento: create }
    ]
    const listaBotones = () => {
        const botones = [

            { titulo: 'Editar', icono: 'ri-edit-2-line', visible: single.canEdit(), evento: () => { modalEditar.setModal({ ...modalEditar.modal, "view": true }) } },
            { titulo: 'Eliminar', icono: 'ri-delete-bin-line', visible: single.canDelete(), evento: () => { setModalC({ ...modalC, "view": true }) } },
            { titulo: 'Pasos', icono: 'ri-edit-2-line', visible: single.canDelete(), evento: () => { setbeneficiario({ ...beneficiario, "view": true }) } },
            // { titulo: 'Proveedor/Docs', icono: 'ri-edit-2-line', visible: single.canDelete(), evento: () => { setproveedormodal({ ...proveedormodal, "view": true }) } },

        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }



    return (
        <BasicPage titulo="Configuración procesos">
            <div className="row">

                <div className="col-md-12">

                    <BasicTable body={lista} head={head} rowSet={process.setBean} listButtons={listaBotones()} iconButtons={iconButtonsItem}></BasicTable>
                    <Modal modal={modalEditar.modal} updateModal={modalEditar.setModal} eventModal={editaItem}>
                        <>
                            <div className="row">
                                <div className="col-md-12">
                                    <SelectorProcessTypeConstants idSelector={process.bean.ProcessType} onChange={(e: any) => { process.setBean({ ...process.bean, "ProcessType": e }); }} />
                                </div>
                                <div className="col-md-6">
                                    <label>Nombre</label>
                                    <input type="text" name="Name" className="form-control" value={process.bean.Name} onChange={process.handleChange} />

                                </div>
                                <div className="col-md-6">
                                    <label>Código</label>
                                    <input type="text" name="CodeProcess" className="form-control" value={process.bean.CodeProcess} onChange={process.handleChange} />

                                </div>
                                <div className="col-md-12">
                                    <label>Descripción</label>
                                    <textarea name="Description" className="form-control" value={process.bean.Description} onChange={process.handleChange} />
                                </div>

                            </div>

                        </>
                    </Modal>
                    <Modal modal={beneficiario} updateModal={setbeneficiario} >
                        <>
                            <ProcessStep IDProcess={process.bean.IDProcess} />

                        </>
                    </Modal>
                    <Modal modal={proveedormodal} updateModal={setproveedormodal} >
                        <>
                            <ProveedorDocumentos />
                        </>
                    </Modal>


                    <ModalConfirm modal={modalC} updateModal={setModalC} eventModal={deleteItem}>
                        <strong>Eliminar item</strong>
                    </ModalConfirm>
                </div>

            </div>
        </BasicPage>
    )
}