import { useState, useEffect } from 'react';
import useDataBean from '../../../../core/services/dataBean/useDataBean';
import { _Appointment } from '../../../../core/services/dataBean/EntityCatalog';
import { PrintJson } from '../../../theme/Component/PrintJson';
import { Singleton } from '../../../../core/services/Singleton';

interface Props {
    idSelector: number;
    onChange: any;
    label?: string;
    seeMoney?: boolean
}

export const AppointmentEditor = ({ idSelector, onChange, label, seeMoney }: Props) => {

    const single = Singleton.getInstance();
    const appointment = useDataBean(_Appointment);
    const [lista, setLista] = useState<any[]>([]);
    const [id, setID] = useState<number | null>(null);
    const [object, setObject] = useState<any>({});

    useEffect(() => {
        getLista();
    }, []);

    useEffect(() => {
        if (idSelector != id) {
            setID(idSelector);
            const selectedObject = lista.find(item => item.DataBeanProperties.IDAppointment === idSelector);
            setObject(selectedObject);
        }
    }, [idSelector])

    const setValue = (e: any) => {
        const selectedID = parseInt(e);
        onChange(selectedID);
    };

    const getLista = () => {
        appointment.instance.getAppointmentCatalog().then(
            (resp) => {
                const selectedObject = resp.find(item => item.DataBeanProperties.IDAppointment === idSelector);
                setObject(selectedObject);
                setLista(resp);
            }
        );
    };

    return (
        <>
            <label>{label ?? "Nivel de Empleo"}</label>
            <select className="form-select" value={id} onChange={(e) => { setValue(e.target.value) }}>
                <option value={null}>...</option>
                {lista &&
                    lista.map((item: any, index: number) => {
                        return (
                            <option key={index} value={item.DataBeanProperties.IDAppointment}>
                                {item.DataBeanProperties.Name}
                            </option>
                        )
                    })
                }
            </select>

            {seeMoney === true && object && object.DataBeanProperties && (
                <>
                    <label>Salario</label>
                    <input
                        type="text"
                        className="form-control"
                        value={`$ ${single.formatMoney(object.DataBeanProperties.Salary)}`}
                        disabled
                    />
                </>
            )}

        </>
    )
}
