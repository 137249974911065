import { useState, useEffect } from "react";
import { Singleton } from "../../../../core/services/Singleton";
import { BasicPage } from "../../../theme/Component/BasicPage";
import { BasicTable } from "../../../theme/Component/BasicTable";
import { ModalConfirm } from "../../../theme/Component/ModalConfirm";
import { Modal } from "../../../theme/Component/Modal";
import { ListMenu } from "../../../theme/Component/ListMenu";
import { AdminService } from "../../../../core/services/AdminService";
import { useDashboardStore } from "../../../pages/hooks/useDashboardStore";

interface Props {
  IDFormatType: number;
}

export const FormatTypeproperties = ({ IDFormatType }: Props) => {

  const single = Singleton.getInstance()
  const { setLoading } = useDashboardStore();
  const service = new AdminService();
  const [lista, setLista] = useState([]);
  const [bean, setBean] = useState<any>({});
  const [modal, setModal] = useState<any>({
    name: "Editar",
    tabIndex: -1,
    view: false,
    btnClose: true,
    btnSubmit: true,
    format: "modal-lg",
  });
  const [modalpropertie, setModalformapropertie] = useState<any>({
    name: "Editar",
    tabIndex: -1,
    view: false,
    btnClose: true,
    btnSubmit: true,
    format: "modal-lg",
  });
  const [modalforma, setModalforma] = useState<any>({
    name: "Editar",
    tabIndex: -1,
    view: false,
    btnClose: true,
    btnSubmit: true,
    format: "modal-lg",
  });
  const [modalConfirm, setModalConfirm] = useState<any>({
    name: "Confirmar",
    tabIndex: -1,
    view: false,
    btnClose: true,
    btnSubmit: true,
    format: "modal-sm",
  });
  const [user, setUser] = useState<any>({});

  // const {bean,setBean,setLista,lista,modal,modalConfirm,modalforma,modalpropertie,service,setModal,single,setModalConfirm,setModalforma,setModalformapropertie,setUser,user}= useFormatTypepropertie()
  const idBean = "IDFormatProperties";

  const head = [
    { title: "ID", property: idBean },
    { title: "Nombre", property: "KeyProperty" },
    { title: "Descripción", property: "SystemValue" },
  ];

  useEffect(() => {
    setUser(single.getAccountID());
    getLista();
  }, [IDFormatType]);

  const setRowBean = (bean: any) => {
   //console.log("Row : ", bean);
    setBean(bean);
  };

  const getLista = () => {
    setLoading(true);
    service
      .getFormatPropertiesCatalogPorPropiedad(
        "IDFormatType",
        IDFormatType,
        null
      )
      .subscribe((resp: any) => {
        if (resp.DataBeanProperties.ObjectValue) {
          setLista(resp.DataBeanProperties.ObjectValue);
        }

        setLoading(false);
      });
  };

  const agregarItem = () => {
    let b = {
      IDFormatType: IDFormatType,
      KeyProperty: "",
      SystemValue: "",
      State: 1,
    };

    setBean(b);
    setModal({ ...modal, ["view"]: true });
  };
  const editarItem = () => {
    setModal({ ...modal, ["view"]: true });
  };

  const eliminaritem = () => {
    bean.State = 2;
   //console.log(bean);
    updateItem();
    setModalConfirm({ ...modalConfirm, ["view"]: false });
  };
  const updateItem = () => {
    setModal({ ...modal, ["view"]: false });

    setLoading(true);
    service.updateFormatProperties(bean).subscribe((resp: any) => {
      setLoading(false);
      if (resp.DataBeanProperties.ObjectValue) {
        getLista();
      }
      single.update(bean);
    });
  };

  const listaBotones = () => {
    const botones = [
      {
        titulo: "Editar",
        icono: "ri-file-list-line",
        visible: true,
        evento: () => {
          setModal({ ...modal, view: true });
        },
      },
      {
        titulo: "Eliminar",
        icono: "ri-file-user-line",
        visible: true,
        evento: () => {
          setModalConfirm({ ...modalConfirm, view: true });
        },
      },
      {
        titulo: "Formato",
        icono: "ri-file-user-line",
        visible: true,
        evento: () => {
          setModalforma({ ...modalforma, view: true });
        },
      },
      {
        titulo: "Propiedades",
        icono: "ri-file-user-line",
        visible: true,
        evento: () => {
          setModalformapropertie({ ...modalpropertie, view: true });
        },
      },
    ];
    return <ListMenu listaBotones={botones} />;
  };

  const iconButtonsItem = [
    {
      nombre: "Agregar",
      visible: single.canCreate(),
      icono: "ri-file-add-line",
      evento: agregarItem,
    },
  ];

  return (
    <>
      <BasicPage titulo="Formatos propiedades">
        <div className="row">
          <div className="col-md-12 p-2">
            <BasicTable
              listButtons={listaBotones()}
              iconButtons={iconButtonsItem}
              head={head}
              body={lista}
              rowSet={setRowBean}
            ></BasicTable>
          </div>
        </div>
      </BasicPage>

      <ModalConfirm
        modal={modalConfirm}
        updateModal={setModalConfirm}
        eventModal={eliminaritem}
      >
        {bean.Name}
      </ModalConfirm>

      <Modal modal={modal} updateModal={setModal} eventModal={updateItem}>
        <>
          <div className="row">
            <div className="col-md-6">
              <label>Nombre</label>
              <input
                type="text"
                className="form-control"
                value={bean.KeyProperty}
                onChange={(e) => {
                  setBean({ ...bean, ["KeyProperty"]: e.target.value });
                }}
              />
            </div>
            <div className="col-md-6">
              <label>Descripción</label>
              <input
                type="text"
                className="form-control"
                value={bean.SystemValue}
                onChange={(e) => {
                  setBean({ ...bean, ["SystemValue"]: e.target.value });
                }}
              />
            </div>
          </div>
        </>
      </Modal>
    </>
  );
};
