import { DataBean } from "../DataBean";

export class GenericCompetition extends DataBean {


    public async getGenericCompetitionCatalog(ID: number | null): Promise<any> {
        return this.getCustomService("PeopleArt", "java.util.List_getGenericCompetitionCatalog_Number", [ID]);
    }

    public async deleteGenericCompetition(bean): Promise<any> {
        return this.getCustomService("PeopleArt", "void_deleteGenericCompetition_com.peopleart.bean.behavioral.GenericCompetition", [
            {
                "DataBeanProperties": bean,
                "DataBeanName": "com.peopleart.bean.behavioral.GenericCompetition"
            }
        ]);
    }
}