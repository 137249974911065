import { ApplicationID } from "./ApplicationID";
import { ApplicationType } from "./ApplicationType";
import { AdminService } from '../../../core/services/AdminService';
import { useState, useEffect } from 'react';
import { Application } from "./Application";
import { BasicPage } from "../../theme/Component/BasicPage";

interface Props{
    superUser?:true;
}
export const MenuUsuario = ({superUser}:Props) => {

    const adminService = new AdminService();
    const [tipoVista, settipoVista] = useState<any>(1);

    return (
        <>
            <BasicPage titulo="Menú">
                <div className="row">
                    <div className="col-xxl-12">
                        <div className="card ">
                            <div className="card-header">
                                <ul className="nav nav-tabs-custom rounded card-header-tabs border-bottom-0" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <a className="nav-link active" data-bs-toggle="tab" href="#app" role="tab" aria-selected="true" onClick={() => { settipoVista(1) }}>
                                            <i className="fas fa-home"></i> <p className="color-tab"><strong>Tipo Aplicación</strong></p>
                                        </a>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <a className="nav-link" data-bs-toggle="tab" href="#tipoaplicacion" role="tab" aria-selected="false" onClick={() => { settipoVista(2) }}>
                                            <i className="fas fa-home"></i> <p className="color-tab"><strong>Aplicación</strong></p>
                                        </a>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <a className="nav-link" data-bs-toggle="tab" href="#menu1" role="tab" aria-selected="false" onClick={() => { settipoVista(3) }}>
                                            <i className="far fa-user"></i> <p className="color-tab"><strong>Menú</strong></p>
                                        </a>
                                    </li>

                                </ul>
                            </div>

                            <div className="card-body p-4">
                                <div className="tab-content">
                                    <div className="tab-pane active show" id="app" role="tabpanel">
                                        {tipoVista === 1 && <Application service={adminService} />}
                                    </div>
                                    <div className="tab-pane active show" id="tipoaplicacion" role="tabpanel">
                                        {tipoVista === 2 && <ApplicationType service={adminService} />}
                                    </div>
                                    <div className="tab-pane" id="menu1" role="tabpanel">
                                        {tipoVista === 3 && <ApplicationID />}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </BasicPage>
        </>
    );
}