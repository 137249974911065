import { DataBean } from "../DataBean";

export class AdquisitionItem extends DataBean{


    public   IN_PREPARATION = 30;
	public   PREPARED = 31;
	public   CREATED = 3;
	public   STRUCTURED = 4;
	public   REVIEWED = 5;
	public   APPROVED = 6;
	public   REJECTED = 7;
	public   CANCELLED = 8;
	public   INCOMPLETED = 9;
	public   VALUE_MISMATCH = 27;
	public   IN_PRECONTRACTUAL = 40;
	public   IN_CONTRACTET = 41;
	public   IN_LIQUIDATE = 42;
	public   IN_EDIT = 43;

    public async getAdquisitionItem(idAdquisitionItem:number): Promise<any> {
        return this.getCustomService("FinanceCore", "com.orange.bean.supplier.AdquisitionItem_getAdquisitionItem_Number", [idAdquisitionItem]);
    }
    public async getOfferEmployeeCatalogRender(idAdquisitionItem:number): Promise<any> {
        return this.getCustomService("FinanceCore", "java.util.List_getOfferEmployeeCatalogRender_Number", [idAdquisitionItem]);
    }
    public async getAdquisitionItemInboxProcessAssigned(rows:number,page:number,idEmployee:number): Promise<any> {
        return this.getCustomService("SupplierERP", "java.util.List_getAdquisitionItemInboxProcessAssigned_Number_Number_Number", [rows,page,idEmployee]);
    }
    public async getAdquisitionItemInboxProcessForManagement(rows:number,page:number,idEmployee:number): Promise<any> {
        return this.getCustomService("SupplierERP", "java.util.List_getAdquisitionItemInboxProcessForManagement_Number_Number_Number", [rows,page,idEmployee]);
    }
    public async getAdquisitionItemForPreviusEstudy(idAdquisitionItem:number): Promise<any> {
        return this.getCustomService("SupplierERP", "com.orange.bean.supplier.AdquisitionItem_getAdquisitionItemForPreviusEstudy_Number", [idAdquisitionItem]);
    }
}