import { useEffect, useState } from "react";
import { BasicButton } from "../../theme/Component/BasicButton";
import { toast } from "react-toastify";
import { PrintJson } from "../../theme/Component/PrintJson";
import useDataBean from "../../../core/services/dataBean/useDataBean";
import { _RemoteAbstractAction } from "../../../core/services/dataBean/EntityCatalog";

interface Props {    
    idApplicationInvoker: any;
    fieldList: any;
    data: any;
    setData: any;
}
export const RemoteJsonForm= ({ idApplicationInvoker, fieldList, data, setData }: Props) => {

    const [jsonData,setJsonData] = useState<string>('');
    const [valido,setValido] = useState(false);
    const { bean, setBean, instance } = useDataBean(_RemoteAbstractAction);
    const [required, setRequired] = useState<any>([]);
    const [formValid, setFormValid] = useState<boolean>(true);
    const [campos, setCampos] = useState<any>([]);

    useEffect(() => {

        if (fieldList) {
            const rows = Object.keys(fieldList);
            let filas = [];
            rows.forEach(fila => {
                filas.push(
                    {
                        ...fieldList[fila], "IDProperty": fila ,"LineNumber": fieldList[fila].LineNumber ?? 1
                    }
                )
            });

            // obtener las propiedades obligatorias
            setRequired(
                filas.filter((item) => item.Required).map((item) => item.IDProperty)
            );
            //asignar en el bean las propiedades que tiene valor por defecto
            let obj =  filas
            .filter((item) => item.IDProperty)
            .reduce((acc, item) => {
                acc[item.IDProperty] = item.DefaultValue;
                return acc;
            }, {});
            setBean(obj);
            setJsonData(JSON.stringify(obj));
            //ordenar y asignar 
            setCampos(filas.sort((a, b) => a.LineNumber - b.LineNumber));
        }

    }, [fieldList])
  

    const validateData=()=>{
        
        try {
            let jj = JSON.parse(jsonData);
            setJsonData(JSON.stringify(jj,null,2));
            setValido(true);
        } catch (error) {
            toast.error("Formato Json invalido");
            setValido(false);
        }

        
    }   
    const enviar=()=>{
        validateData();
        let dato = JSON.parse(jsonData);
        console.table(dato);
        setData(dato);
        
    }

    return(
        <div className="row">
            <div className="col-md-12">
                <textarea name="json" className="form-control" rows={10} value={jsonData} onChange={(e)=>{setJsonData(e.target.value)}}></textarea>
            </div>
            <div className="col-md-12 mt-4">
                <BasicButton icon=" ri-braces-line"  eventClick={validateData}>Validar</BasicButton>
                <BasicButton icon="ri-play-line" disable={!valido} eventClick={enviar}>Siguiente</BasicButton>
            </div>
            <div className="col-md-12">
                <PrintJson json={bean}/>
            </div>
        </div>
    )

}