import { Singleton } from "../Singleton";
import { CustomService } from "./CustomService";


export interface CustomProps{
    Service:string;
    Hash:String
}

export class DataBean {

    ServiceName: string;
    className: string;
    name: string;
    primaryKey: string;
    dataBeanList: any;
    bean: any;
    // spinner:boolean;
    customFunctions:CustomProps|any;
    private services: CustomService;
    single = Singleton.getInstance();

    
    constructor(serviceName: string, className: string) {
        this.customFunctions=[];
        // this.spinner = false;
        this.primaryKey = '';
        this.bean = {};
        this.services = new CustomService(serviceName);
        this.name = '';
        this.dataBeanList = [];
        this.ServiceName = serviceName;
        this.className = className;
        this.nameClass();
    }

    private nameClass() {
        const cls = this.className.split(".");
        this.name = cls[cls.length - 1];
        this.primaryKey = `ID${this.name}`;
    }

    registerCustomFunction(ServiceName:string,MethodHash:string)
    {
        let item:CustomProps =undefined;
        this.customFunctions.push(
            item = {
                Service:ServiceName,
                Hash:MethodHash
            }
        )
    }
    getRegisterFunction(methodHash:string)
    {
        let fun:CustomProps = undefined;

        this.customFunctions.forEach((element:CustomProps) => {
                if(element.Hash === methodHash)
                    fun = element;
        });

        return fun;
    }

    setCustomPrimaryKey(key: string) {
        this.primaryKey = key;
    }

    getName() {
        return this.name;
    }

    getProperties() {
        return this.bean;
    }
    setProperties(props: any) {
        this.bean = props;
    }


    size() {
        return this.dataBeanList.length;
    }
    async update(): Promise<any> {

      return  new Promise((resolve, reject) => {
            this.single.spinner(true);
            const methodHash = `${this.className}_update${this.name}_${this.className}`;
            this.services.postService(methodHash, [
                {
                    DataBeanProperties: this.bean,
                    DataBeanName: this.className
                }
            ]).subscribe(
                (resp: any) => {
                    if (resp.DataBeanProperties.ErrorMessage) {
                        reject(resp.DataBeanProperties.ErrorMessage);
                    }
                    else if (resp.DataBeanProperties.ObjectValue) {
                        this.bean = resp.DataBeanProperties.ObjectValue;
                        resolve(this.bean);
                    }
                    else {
                        this.bean = {};
                        resolve(this.bean);
                    }
                    this.single.spinner(false);
                }
            );
        });

    }
    async updateCustonBean(miBean:any): Promise<any> {

      return  new Promise((resolve, reject) => {
        this.single.spinner(true);
            const methodHash = `${this.className}_update${this.name}_${this.className}`;
            this.services.postService(methodHash, [
                {
                    DataBeanProperties: miBean,
                    DataBeanName: this.className
                }
            ]).subscribe(
                (resp: any) => {
                    if (resp.DataBeanProperties.ErrorMessage) {
                        reject(resp.DataBeanProperties.ErrorMessage);
                    }
                    else if (resp.DataBeanProperties.ObjectValue) {
                        this.bean = resp.DataBeanProperties.ObjectValue;
                        resolve(this.bean);
                    }
                    else {
                        this.bean = {};
                        resolve(this.bean);
                    }
                    this.single.spinner(false);
                }
            );
        });

    }

    async delete(idItem: number): Promise<any> {
        return new Promise((resolve) => {
            this.single.spinner(true);
            let isDelete = false;
            const methodHash = `boolean_delete${this.name}_Number`;
            this.services.postService(methodHash, [
                idItem
            ]).subscribe(
                (resp: any) => {

                    if (resp.DataBeanProperties.ErrorMessage) {
                        resolve(resp.DataBeanProperties.ErrorMessage);
                    } else {
                        isDelete = true;
                        resolve(isDelete);
                    }
                    this.single.spinner(false);
                }
            );
        }
        );
    }
    async deleteVoid(idItem: number): Promise<any> {
        return new Promise((resolve) => {
            this.single.spinner(true);
            let isDelete = false;
            const methodHash = `void_delete${this.name}_Number`;
            this.services.postService(methodHash, [
                idItem
            ]).subscribe(
                (resp: any) => {

                    if (resp.DataBeanProperties.ErrorMessage) {
                        resolve(resp.DataBeanProperties.ErrorMessage);
                    } else {
                        isDelete = true;
                        resolve(isDelete);
                    }
                    this.single.spinner(false);
                }
            );
        }
        );
    }


    async getList(propsArray: any, rows: number | null): Promise<any> {
        return new Promise((resolve, reject) => {
            this.single.spinner(true);
            const methodHash = `java.util.List_get${this.name}CatalogPorPropiedades_java.util.Map_Number`;

            this.services.postService(methodHash, [propsArray, rows]).subscribe(
                (resp: any) => {
                    if (resp.DataBeanProperties.ErrorMessage) {

                        reject(resp.DataBeanProperties.ErrorMessage);
                    } else {
                        this.dataBeanList = this.simpleRender(resp.DataBeanProperties.ObjectValue);

                        resolve(this.dataBeanList);
                    }
                    this.single.spinner(false);
                }
            );
        });

    }
    async getListLike(propsArray: any, rows: number | null): Promise<any> {
        return new Promise((resolve, reject) => {
            this.single.spinner(true);
            const methodHash = `java.util.List_get${this.name}CatalogPorPropiedadesLike_java.util.Map_Number`;

            this.services.postService(methodHash, [propsArray, rows]).subscribe(
                (resp: any) => {
                    if (resp.DataBeanProperties.ErrorMessage) {

                        reject(resp.DataBeanProperties.ErrorMessage);
                    } else {
                        this.dataBeanList = this.simpleRender(resp.DataBeanProperties.ObjectValue);

                        resolve(this.dataBeanList);
                    }
                    this.single.spinner(false);
                }
            );
        });

    }
    async getCustomService(service: string, hash: string, propsArray: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.single.spinner(true);
            this.services.postServiceCustom(service, hash, propsArray).subscribe(
                (resp: any) => {                    
                    
                    if (typeof resp === 'object' && resp !== null) {
                        if (resp.DataBeanProperties.ErrorMessage) {
                            reject(resp.DataBeanProperties.ErrorMessage);
                        } else {

                            if (!resp.DataBeanProperties.ObjectValue) {
                                resolve(resp.DataBeanProperties)
                            }
                            else if (Array.isArray(resp.DataBeanProperties.ObjectValue)) {
                                this.dataBeanList = this.simpleRender(resp.DataBeanProperties.ObjectValue);
                                resolve(this.dataBeanList);
                            } else if (resp.DataBeanProperties.ObjectValue.DataBeanProperties) {
                                resolve(resp.DataBeanProperties.ObjectValue.DataBeanProperties);
                            } else {
                                resolve(resp.DataBeanProperties.ObjectValue)
                            }

                        }
                    } else {
                        reject("ERROR INESPERADO");
                    }
                    this.single.spinner(false);

                },
                (error: any) => {
                    reject(error);
                }

            );
        });

    }

    private simpleRender(lista: any) {
        const temp: any = [];

        // if (lista) {
        //     lista.forEach((element: any) => {
        //         temp.push(element.DataBeanProperties);
        //     });
        // }

        return lista;
    }
    public simpleRenderList(lista: any) {
        const temp: any = [];

        if (lista) {
            lista.forEach((element: any) => {
                temp.push(element.DataBeanProperties);
            });
        }

        return lista;
    }

     




}