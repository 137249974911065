import { Singleton } from "../../../core/services/Singleton";
import { SupplierService } from "../../../core/services/SupplierService";
import { BasicButton } from "../../theme/Component/BasicButton";
import { PrintJson } from "../../theme/Component/PrintJson";
import { useEffect, useState } from "react";
import { useForm } from 'react-hook-form';
import { Selectjuridica } from "./Selectjuridica";
import { useDashboardStore } from "../../pages/hooks/useDashboardStore";

interface Props {
    supplier: any;
    updateEvent: Function;
}
export const UpdateSupplier = ({ supplier, updateEvent }: Props) => {

    const service = new SupplierService();
    const single = Singleton.getInstance()
    const { setLoading } = useDashboardStore();
    const [supp, setsupp] = useState<any>({});
    const [legalNature, setLegalNature] = useState<any>({});
    const [account, setaccount] = useState<any>({});
    const { register, handleSubmit } = useForm();

    useEffect(() => {
        setsupp(supplier);
        setaccount(supplier);
        ////console.log(supplier)
    }, [supplier])

    const updateAcc = (beanA: any) => {
        setLoading(true);
        service.updateAbstractAccount(beanA).subscribe(
            (resp: any) => {
                setLoading(false);
                if (resp.DataBeanProperties.ObjectValue) {
                   //console.log(beanA)
                }
            }
        )
    }

    const updateSupp = (beanS: any) => {
        setLoading(true);
        service.updateSupplier(beanS).subscribe(
            (resp: any) => {
                setLoading(false);
                if (resp.DataBeanProperties.ObjectValue) {
                   //console.log(beanS)
                }
            }
        )
    }

    const onSubmit = () => {

        let editaccount = {
            IDAccount: account.IDAccount,
            Tel: account.Tel,
            eMail: supp.eMail,
            Address: supp.Address,

        }

        let suppli = {
            IDSupplier: supp.IDSupplier,
            NameLegalRep: supp.NameLegalRep,
            ContactName: supp.ContactName,
        }


        updateAcc(editaccount);
        updateSupp(suppli);
        updateEvent();
    }

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="conatiner">
                    <div className="row">
                        <div className="col-md-6">
                            <label className="form-label">Nit</label>
                            <input type="text" className="form-control" value={supp.nit} readOnly />
                        </div>
                        <div className="col-md-6">
                            <label className="form-label">Empresa</label>
                            <input type="text" className="form-control" value={supp.Name1} readOnly />
                        </div>
                        <div className="col-md-6">
                            <label className="form-label">Representante Legal</label>
                            <input type="text" className="form-control" value={supp.NameLegalRep} onChange={(e) => { setsupp({ ...supp, ['NameLegalRep']: e.target.value }) }} />
                        </div>
                        <div className="col-md-6">
                            <label className="form-label">Persona de Contacto</label>
                            <input type="text" className="form-control" value={supp.ContactName} onChange={(e) => { setsupp({ ...supp, ['ContactName']: e.target.value }) }} />
                        </div>
                        <div className="col-md-6">
                            <label className="form-label">Correo</label>
                            <input type="text" className="form-control" value={supp.eMail} onChange={(e) => { setsupp({ ...supp, ['eMail']: e.target.value }) }} />
                        </div>
                        <div className="col-md-6">
                            <label className="form-label">Direccion</label>
                            <input type="text" className="form-control" value={supp.Address} onChange={(e) => { setsupp({ ...supp, ['Address']: e.target.value }) }} />
                        </div>
                        <div className="col-md-6">
                            <label className="form-label">Telefono</label>
                            <input type="number" className="form-control" value={account.Tel} onChange={(e) => { setaccount({ ...account, ['Tel']: e.target.value }) }} />
                        </div>
                        <div className="col-md-6">
                            <label className="form-label">Tipo de Nauraleza Juridica</label>
                            <Selectjuridica code={2} value={legalNature} onChange={(e: any) => { setLegalNature(e); }} />
                        </div>
                        <div className="col-md-6">
                            <label className="form-label">Tamaño de empresa</label>
                            <Selectjuridica code={1} value={legalNature} onChange={(e: any) => { setLegalNature(e); }} />
                        </div>
                        <div className="col-md-6">
                            <label className="form-label">%SG - SST</label>
                            <Selectjuridica code={4} value={legalNature} onChange={(e: any) => { setLegalNature(e); }} />
                        </div>

                    </div>
                </div>
            </form>

            <div className="row">
                <div className="col-md-12 offset-4 mt-3">
                    <BasicButton disable={!single.canEdit()} icon="ri-file-user-line" eventClick={onSubmit}>Actualizar</BasicButton>
                </div>
            </div>

            {/* <PrintJson json={supplier}></PrintJson> */}


        </>
    )
}