import { useState, useEffect } from 'react';
import { Singleton } from '../../../core/services/Singleton';
import { useDashboardStore } from '../hooks/useDashboardStore';
import { BpmService } from '../../../core/services/BpmService';
import { ListMenu } from '../../theme/Component/ListMenu';
import { BasicTable } from '../../theme/Component/BasicTable';
import { BasicPage } from '../../theme/Component/BasicPage';
import { ModalConfirm } from '../../theme/Component/ModalConfirm';
import { Modal } from '../../theme/Component/Modal';
import { SelectorDangertypes } from '../../bpm/config/Componentes/SelectorDangertypes';
import { Selecttipodanger } from '../../bpm/config/Componentes/Selecttipodanger';



export const Danger = () => {

    const single = Singleton.getInstance()
    const { setLoading } = useDashboardStore();
    const service = new BpmService();
    const [lista, setLista] = useState([]);
    const [bean, setBean] = useState<any>({});
    const [modal, setModal] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalTHC, setModalTHC] = useState<any>({ name: 'Parametricas', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalConfirm, setModalConfirm] = useState<any>({ name: 'Confirmar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });
    const [user, setUser] = useState<any>({});
    const [idSelector, setIDSelector] = useState(0);


    const idBean = 'IDDanger';

    const head = [
        { title: 'ID', property: idBean },
        { title: 'Tipo de clasificacion', property: 'IDClassificationType' },
        { title: 'Descripción', property: 'Description' },

    ]

    useEffect(() => {
        setUser(single.getAccountID());
        getLista();
    }, [idBean])

    const setRowBean = (bean: any) => {
       //console.log("Row : ", bean);
        setBean(bean);
    }

    const getLista = () => {

        setLoading(true);
        service.getDangerCatalogPorPropiedad('State', 1, null).subscribe(
            (resp: any) => {

                if (resp.DataBeanProperties.ObjectValue) {
                    setLista(resp.DataBeanProperties.ObjectValue);
                }
                setLoading(false);
            }
        );
    }

    const agregarItem = () => {
        let b = {
            // IDEmployee: single.getAccountID(),
            IDClassificationType: 0,
            Description: '',
            since: single.getCurrenDate(),
            State: 1,
        }
        setBean(b);
        setModal({ ...modal, ['view']: true });

    }

    const eliminarItem = () => {
        bean.State = 2;
       //console.log(bean);
        updateItem();
        setModalConfirm({ ...modalConfirm, ['view']: false });
    }

    const updateItem = () => {
        setModal({ ...modal, ['view']: false });
        setLoading(true);
        service.updateDanger(bean).subscribe(
            (resp: any) => {
                setLoading(false);
                if (resp.DataBeanProperties.ObjectValue) {
                    getLista();
                }
                single.update(bean);
            }
        );
    }


    const listaBotones = () => {
        const botones = [

            { titulo: 'Editar', icono: 'ri-file-list-line', visible: true, evento: () => { setModal({ ...modal, "view": true }) } },
            { titulo: 'Eliminar', icono: 'ri-file-user-line', visible: true, evento: () => { setModalConfirm({ ...modalConfirm, "view": true }) } },


        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }

    const iconButtonsItem = [
        { nombre: 'Agregar', visible: single.canCreate(), icono: 'ri-file-add-line', evento: agregarItem }
    ]


    return (
        <>
            <BasicPage titulo='Categorias de Daños'>
                <div className="row">
                    <div className="col-md-12 p-2">

                        {/* <Selecttipodanger titulo="Categorias de Riesgo" idSelector={idSelector} onChange={(e: any) => { setIDSelector(e) }} /> */}
                        <BasicTable listButtons={listaBotones()} iconButtons={iconButtonsItem} head={head} body={lista} rowSet={setRowBean}></BasicTable>
                    </div>
                </div>
            </BasicPage>

            <ModalConfirm modal={modalConfirm} updateModal={setModalConfirm} eventModal={eliminarItem}>
                {bean.Name}
            </ModalConfirm>

            <Modal modal={modal} updateModal={setModal} eventModal={updateItem}>
                <div className="row">
                    <div className="col-md-12">

                        <SelectorDangertypes titulo="Tipos de daño" idSelector={bean.IDClassificationType} onChange={(e: any) => { setBean({ ...bean, 'IDClassificationType': e }) }} />
                    </div>

                    <div className="col-md-12">
                        <label className="form-label">Descripción</label>
                        <textarea className="form-control" value={bean.Description} onChange={(e) => { setBean({ ...bean, ['Description']: e.target.value }) }}></textarea>
                    </div>

                </div>
            </Modal>
        </>
    )
}