import { BasicPage } from '../../../theme/Component/BasicPage';
import './InboxCash-service';
import { BasicTable, MASK_MONEY, MASK_NUMBER } from '../../../theme/Component/BasicTable';
import { InboxCashService } from './InboxCash-service';
import { useEffect, useState } from 'react';
import { CompanyEditor } from '../Company/CompanyEditor';
import { toast } from 'react-toastify';
import { OfficeEditor } from '../Office/OfficeEditor';
import { ListMenu } from '../../../theme/Component/ListMenu';
import { Modal } from '../../../theme/Component/Modal';
import { CashReimbursementHistory } from './CashReimbursement/CashReimbursementHistory';

export const InboxCash = () => {
    const service = new InboxCashService();

    const [bean, setBean] = useState<any>({});
    const [lista, setLista] = useState<any>([]);
    const [conpany, setCompany] = useState<number>(null);
    const [office, setOffice] = useState<number>(null);
    const idBean = 'IDCashDeskAccount';

    const [modalHistory, setModalHistory] = useState<any>({ name: "Historial", tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: "modal-xl", });

    const head = [
        { title: 'ID', property: idBean, mask: MASK_NUMBER },
        { title: 'Nombre', property: 'Name' },
        { title: 'Responsable de la Caja', property: 'AccountName' },
        { title: 'Valor de la Base', property: 'BaseValue', mask: MASK_MONEY },
        { title: 'Caja Destino', property: 'DestinityCash' },
        { title: 'Cuenta Contable', property: 'AccountantIDName' },
        { title: 'Creado por', property: 'EmployeeName' },
        { title: 'Oficina a la que pertenece', property: 'OfficeName' },
    ]

    const setRowBean = (bean: any) => {
        setBean(bean);
    }

    useEffect(() => {
        setBean({})
        if (office != null && !isNaN(office)) {
            getLista();
        }
    }, [office])

    const getLista = () => {
        service.spinON();
        service.getCashDeskAccountCatalog(office, service.idAccount()).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ErrorMessage) {
                    toast.error(resp.DataBeanProperties.ErrorMessage);
                }
                else if (resp.DataBeanProperties.ObjectValue) {
                    setLista(resp.DataBeanProperties.ObjectValue);
                }
                service.spinOFF();
            }
        )
    }

    const listaBotones = () => {
        const botones = [
            { titulo: "Mostrar reembolsos de Caja", visible: true, icono: "ri-history-line", evento: () => { setModalHistory({ ...modalHistory, "view": true, "name": `Mostrar reembolsos de Caja de ${bean.Name}` }) } },
        ];
        return <ListMenu listaBotones={botones} />;
    };



    return (
        <>
            <BasicPage titulo='Mis Cajas Menores-Puntos de Venta'>
                <div className="row mb-2">
                    <div className="col">
                        <CompanyEditor id={conpany} onchange={(e: any) => { setCompany(e) }}></CompanyEditor>
                    </div>
                    <div className="col">
                        <OfficeEditor idconpany={conpany} id={office} onchange={(e: any) => { setOffice(e) }}></OfficeEditor>
                    </div>
                </div>
                <BasicTable listButtons={listaBotones()} body={lista} head={head} rowSet={setRowBean} ></BasicTable>
            </BasicPage>

            {modalHistory.view &&
                <Modal modal={modalHistory} updateModal={setModalHistory} >
                    <CashReimbursementHistory idCashDeskA={bean.IDCashDeskAccount} />
                </Modal>
            }
        </>
    )
}