import { useEffect, useState } from "react";
import { Singleton } from "../../../../../core/services/Singleton";
import { ForpoService } from "../service/ForpoService";
import { useDashboardStore } from "../../../../pages/hooks/useDashboardStore";

export const usePolicy = () => {
  const { setLoading } = useDashboardStore();
  const single = Singleton.getInstance()
  const service = new ForpoService();
  const [lista, setLista] = useState([]);
  const [modalConfirm, setModalConfirm] = useState<any>({ name: "Confirmar", tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: "modal-sm", });
  const [modal, setModal] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: "modal-xl", });
  const [bean, setBean] = useState<any>({
    Name: "",
    Descripcion: "",
    State: 1,
    IDEmployee: single.getAccountID(),
    IsActive: false
  });

  useEffect(() => {
    getLista();
  }, []);

  const idBean = "IDPolicy";

  const head = [
    { title: "ID", property: idBean },
    { title: "Nombre", property: "Name" },
    { title: "Descripción", property: "Descripcion" },
    { title: "¿Es Activa?", property: "IsActive" },
  ];

  const setRowBean = (bean: any) => {
    setBean(bean);
   //console.log(bean)
  };

  const getLista = () => {
    setLoading(true);
    service.getPolicyCatalogPorPropiedad('State', 1, null).subscribe(
      (resp: any) => {
        if (resp.DataBeanProperties.ObjectValue) {
          setLista(resp.DataBeanProperties.ObjectValue);
        }
        setLoading(false);
      });
  };


  const agregarItem = () => {
    let b = {
      Name: "",
      Description: "",
      State: 1,
      IDEmployee: single.getAccountID(),
      IsActive: false,
    };
    setBean(b);
    setModal({ ...modal, "view": true, name: "Agregar" });
  };

  const editarItem = () => {
    setModal({ ...modal, "view": true, name: 'Editar' });
  };

  const eliminarItem = () => {
    bean.State = 2;
   //console.log(bean);
    updateItem();
    setModalConfirm({ ...modalConfirm, 'view': false });
  }

  const updateItem = () => {
    setLoading(true);
    service.updatePolicy(bean).subscribe((resp: any) => {
      setLoading(false);
      if (resp.DataBeanProperties.ObjectValue) {
        getLista();
      }
      if (bean.idBean > 0) {
        single.update(bean);
      } else {
        single.create(bean);
      }
    });
    setModal({ ...modal, "view": false });
  };

  const mostrar = () => {
   //console.log(bean);
  }

  const iconButtonsItem = [
    { nombre: "Agregar", visible: single.canCreate(), icono: "ri-file-add-line", evento: agregarItem, },
  ];

  return {
    single,
    service,
    lista,
    setLista,
    bean,
    setBean,
    modal,
    setModal,
    modalConfirm,
    setModalConfirm,
    idBean,
    updateItem,
    head,
    iconButtonsItem,
    editarItem,
    agregarItem,
    eliminarItem,
    setRowBean,
    mostrar,
  };
};
