import { DataBean } from "../DataBean";

export class BudgetAccountantID extends DataBean {


    public async getBudgetAccountantIDCatalogByIDLn(idLnBudgetID:any): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_getBudgetAccountantIDCatalogByIDLn_Number", [idLnBudgetID]);
    }

    public async getBudgetAccountantIDCatalog(accountantIDStr:any,budgetIDStr:any,code:any,name:any): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_getBudgetAccountantIDCatalog_String_String_String_String", [accountantIDStr,budgetIDStr,code,name]);
    }
    public async addBudgetAccountantID(budgetIDStr:any,accountantIDStr:any,code:any): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "admin.BudgetAccountantID_addBudgetAccountantID_String_String_String", [budgetIDStr,accountantIDStr,code]);
    }


    

}