import { useEffect, useState } from "react";
import { CardTaps } from "./CardTaps";
import { Alert } from "./Alert";
import { VisorPDF } from "../../documents/VisorPDF";
import { PrintJson } from "./PrintJson";
import { VisorAnyDocument } from "../../documents/VisorAnyDocument";

export interface DocumentPDF {
    MediaContext: string;
    Media: string;
    Filename: string;
    Type: string;
    URLLink: string | null;
}
export interface DocumentList {
    list: DocumentPDF[];
}
export const DocumentViewer = (listDoc: DocumentList) => {

    const [indexTap, setIndexTap] = useState(0);
    const [lista,setLista] = useState<any>([]);
    const [row, setRow] = useState<DocumentPDF>(undefined);
    let taps = [
        { id: 0, name: 'Documentos', disable: false },
        { id: 1, name: 'Visor', disable: listDoc.list ? listDoc.list.length === 0 :0}
    ];

    useEffect(()=>{
        if(listDoc)
        {
            let temp = [];
            listDoc?.list?.forEach((element:any )=> {
                if(element.DataBeanProperties)
                {
                    temp.push(element.DataBeanProperties);
                }else{
                    temp.push(element);
                }
            });
            setLista(temp);
        }
    },[listDoc])

    const vierDocument = (item: any) => {
        setRow(item);
        setIndexTap(1);
    }

    return (
        <div className="row" >
            <div className="col">
                <CardTaps  taps={taps} index={indexTap} updateIndex={setIndexTap}>
                    {indexTap === 0 &&
                        <>
                            {lista.length === 0 ?
                                <Alert clase="warning">
                                    <p>No hay documentos para mostrar</p>
                                </Alert>
                                :
                                <>
                                    <div className="list-group">
                                        {lista.map((item: any, index: number) => {
                                            return (
                                                <button onClick={() => { vierDocument(item) }} key={index} type="button" className="list-group-item list-group-item-action" aria-current="true">
                                                    <i className="ri-article-line fs-2 align-middle me-2 cursor"></i>
                                                    {item.Filename}
                                                </button>
                                            )
                                        })}
                                    </div>
                                </>
                            }
                        </>
                    }
                    {indexTap === 1 &&
                        <div className="row">
                            <div className="col-md-12">
                                <VisorAnyDocument url={row?.URLLink} type={row?.Type}/>
                                {/* <VisorPDF url={row.URLLink} type={row.Type} /> */}
                            </div>
                        </div>
                    }
                </CardTaps>
            </div>
        </div>
    )
}