import { useEffect, useState } from "react";
import { _TaxCodeValue } from "../../../../../core/services/dataBean/EntityCatalog";
import useDataBean from "../../../../../core/services/dataBean/useDataBean";
import { Singleton } from "../../../../../core/services/Singleton";
import { ListMenu } from "../../../../theme/Component/ListMenu";
import { BasicTable } from "../../../../theme/Component/BasicTable";
import { Modal } from "../../../../theme/Component/Modal";
import { ModalConfirm } from "../../../../theme/Component/ModalConfirm";
import { TaxCodeEditorTable } from "../../TaxCode/TaxCode.editor";

interface Props {
    _IDTaxSchemeValue: number;
    _onChange?: Function;
}

export const TaxSchemeCodeValue = ({ _IDTaxSchemeValue, _onChange }: Props) => {

    const taxSchemeCode = useDataBean(_TaxCodeValue);

    const single = Singleton.getInstance();
    const [lista, setLista] = useState<any>([]);
    const [modal, setModal] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalC, setModalC] = useState<any>({ name: 'Eliminar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });

    useEffect(() => {
        getLista();
    }, [_IDTaxSchemeValue])

    const getLista = () => {
        taxSchemeCode.instance.getTaxCodeValueCatalog(_IDTaxSchemeValue).then(
            (resp: any) => {
                if (resp) {
                    setLista(resp)
                   //console.log(resp)
                }
            }
        )
    }

    const create = () => {
        taxSchemeCode.setBean({

        });
        setModal({ ...modal, "view": true, "name": "Agregar Código de Actividad" })
    }

    const createItem = () => {
        taxSchemeCode.instance.addTaxCodeToTaxSchemeValue(_IDTaxSchemeValue, taxSchemeCode.bean.IDTaxCode).then(
            (resp) => {
               //console.log(resp)
                getLista();
                setModal({ ...modal, "view": false });
                single.create(taxSchemeCode.bean);
                _onChange()
            }
        )
    }

    const deleteItem = () => {
        taxSchemeCode.deleteItemVoid(taxSchemeCode.bean.IDTaxCodeValue).then(
            (resp) => {
                single.delete(taxSchemeCode.bean.IDTaxCodeValue, taxSchemeCode.bean)
                getLista();
                setModalC({ ...modalC, "view": false });
            }
        )
    }

    const head = [
        { title: 'ID', property: 'IDTaxCodeValue', visible: true },
        { title: 'Código de la Actividad', property: 'TaxCodeCode', visible: true },
        { title: 'Descripción de la Actividad', property: 'TaxCodeName', visible: true },
    ]

    const iconButtonsItem = [
        { nombre: 'Agregar', visible: single.canCreate(), icono: 'ri-file-add-line', evento: create }
    ]
    const listaBotones = () => {
        const botones = [
            { titulo: 'Eliminar', icono: 'ri-delete-bin-line', visible: single.canDelete(), evento: () => { setModalC({ ...modalC, "view": true }) } },
        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }


    return (
        <>
            <div className="row">
                <div className="col-md-12">
                    <BasicTable body={lista} head={head} rowSet={taxSchemeCode.setBean} listButtons={listaBotones()} iconButtons={iconButtonsItem}></BasicTable>
                </div>
            </div>

            <Modal modal={modal} updateModal={setModal} eventModal={createItem} >
                <div className="row">
                    <div className="col-12">
                        <TaxCodeEditorTable onChange={(e) => { taxSchemeCode.setBean({ ...taxSchemeCode.bean, "IDTaxCode": e.IDTaxCode }) }} label="Código de Actividad" />
                    </div>
                </div>
            </Modal>

            <ModalConfirm modal={modalC} updateModal={setModalC} eventModal={deleteItem}>
                <strong>Eliminar item {taxSchemeCode.bean.Code}</strong>
            </ModalConfirm>
        </>
    )
}