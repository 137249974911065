import { useState, useEffect } from 'react';
import { BpmService } from '../../../../core/services/BpmService';
import { AdminService } from '../../../../core/services/AdminService';

interface Props {
    idSelector: number;
    onChange: any;
    lable?: string
}

export const SelectProcessTypeConstants = ({ idSelector, onChange, lable }: Props) => {

    const service = new AdminService();
    const [lista, setLista] = useState([]);
    const [id, setID] = useState(idSelector ?? null);

    useEffect(() => {
        if (idSelector >= 0) {
            setID(idSelector);
        }
    }, [])

    useEffect(() => {
        getLista();
    }, [])

    const setValue = (e: any) => {
        onChange(parseInt(e));
        setID(e)
    }

    const getLista = () => {
        service.getProcessTypeConstants().subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue) {
                    let list = resp.DataBeanProperties.ObjectValue;
                    list.push({ DataBeanProperties: { Value: null, Property: 'TODO' } });
                    setLista(list);

                    if (resp.DataBeanProperties.ObjectValue.length > 0) {
                        setID(parseInt(resp.DataBeanProperties.ObjectValue[0].DataBeanProperties.Value));
                    }
                }
            }
        )
    }
    return (
        <>
            <label>{lable ?? "Tipo Aplicación"}</label>
            <select className="form-select" value={id} onChange={(e) => { setValue(parseInt(e.target.value)) }}>
                <option value={0}>...</option>
                {/* {lista.map((item: any, index: number) => {
                    return (
                        <option key={index} value={item.DataBeanProperties.Value}>{item.DataBeanProperties.Property}</option>
                    )
                })} */}



                {lista.length > 0 &&
                    lista.map((item: any, index: number) => {
                        return (
                            <option key={index} value={item.DataBeanProperties.Value}>{item.DataBeanProperties.Property}</option>
                        )
                    })
                }
            </select>
        </>
    )
}