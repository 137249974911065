import { BasicPage } from "../../theme/Component/BasicPage"
import { useEffect, useState } from "react"
import { ModalConfirm } from "../../theme/Component/ModalConfirm";
import { Modal } from "../../theme/Component/Modal";
import { Singleton } from "../../../core/services/Singleton";
import { BasicTable, MASK_DATE, MASK_DATE_DDMMMYYYY, MASK_MONEY_ROUND, MASK_NUMBER } from "../../theme/Component/BasicTable";
import { ListMenu } from "../../theme/Component/ListMenu";
import { InputDate } from "../../theme/Input/InputDate";
import { GarantiasService } from "../../../core/services/GarantiasService";
import { AccountEditor } from "../../editor/AccountEditor";
import { InputMoney } from "../../theme/Input/InputMoney";
import { RealesCV } from "./RealesCV";
import { SelectSiteID } from "../../admin/configuracion/SiteID/SelectSiteID";
import { RealesCVDocs } from "./RealesCVDocs";
import { DetailGuarantee } from "./DetailGuarantee";
import usePaginator from "../../theme/Component/usePaginator";
import { BasicButton } from "../../theme/Component/BasicButton";
import { useDashboardStore } from "../../pages/hooks/useDashboardStore";
import { toast } from "react-toastify";
import { DropDown } from "../../theme/Component/DropDown";
import { ReportCredit } from "./ReportCredit";


interface Props {
    type?: number;
    pageTitle?: string;
}
export const RealesCredit = ({ type, pageTitle }: Props) => {

    const single = Singleton.getInstance()
    const { setLoading } = useDashboardStore();
    const service = new GarantiasService();
    const [lista, setLista] = useState([]);
    const [modal, setModal] = useState<any>({ name: 'Inmuebles', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalConfirm, setModalConfirm] = useState<any>({ name: 'Confirmar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });
    const [bean, setBean] = useState<any>({});
    const [filtro, setFiltro] = useState<any>({});
    const [beanRCV, setBeanRCV] = useState<any>({});
    const [modalForm, setModalForm] = useState<any>({ name: 'hoja de vida del inmueble', tabIndex: -1, view: false, btnClose: true, btnSubmit: false, format: 'modal-lg' });
    const [modalDocs, setModalDocs] = useState<any>({ name: 'Documentos del inmueble', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalVal, setModalVal] = useState<any>({ name: 'Detalle', tabIndex: -1, view: false, btnClose: true, btnSubmit: false, format: 'modal-lg' });
    const [modalCSV, setModalCSV] = useState<any>({ name: 'Detallado inmubles', tabIndex: -1, view: false, btnClose: true, btnSubmit: false, format: 'modal-md' });
    const [modalUnSubscribe, setModalUnSubscribe] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-md' });
    const [reporte, setReporte] = useState<any>({});
    const paginador = usePaginator();


    const [modalidad, setModalidad] = useState<number>(1);
    const [tipoArchivo] = useState<any>([
        { id: 1, name: 'Hipotecario' },
        { id: 2, name: 'Leasing' }
    ]);

    const [month] = useState([
        { id: null, name: "NA" },
        { id: 0, name: "Enero" },
        { id: 1, name: "Febrero" },
        { id: 2, name: "Marzo" },
        { id: 3, name: "Abril" },
        { id: 4, name: "Mayo" },
        { id: 5, name: "Junio" },
        { id: 6, name: "Julio" },
        { id: 7, name: "Agosto" },
        { id: 8, name: "Septiembre" },
        { id: 9, name: "Octubre" },
        { id: 10, name: "Noviembre" },
        { id: 11, name: "Diciembre" }
    ]);

    const head = [

        { title: 'Radicado', property: 'IDProcedure', mask: MASK_NUMBER },
        { title: 'Documento', property: 'Nit' },
        { title: 'Nombre', property: 'AccountName' },
        { title: 'Apellidos', property: 'AccountSurname' },
        { title: 'Ciudad ', property: 'CiuMun' },
        { title: 'Fecha Inicial', property: 'StartDate', mask: MASK_DATE_DDMMMYYYY },
        { title: 'Fecha Final', property: 'EndDate', mask: MASK_DATE_DDMMMYYYY },
        { title: 'Valor Inmueble ', property: 'PropertyValue', mask: MASK_MONEY_ROUND },
        { title: 'Vr. Ajuste', property: 'FixedValueAcu', mask: MASK_MONEY_ROUND },
        { title: 'Ajuste Garantía', property: 'AjusteGarantia', mask: MASK_MONEY_ROUND },
        { title: 'Chip', property: 'ChipProperty', visible: false },
        { title: 'Cédula catastral', property: 'NitProperty', visible: false },
        { title: 'Matrícula Inmobiliaria del Inmueble', property: 'TuitionProperty', visible: false },
        { title: 'Matrícula Inmobiliaria del parqueadero', property: 'ParkingProperty', visible: false },
        { title: 'Dirección del inmueble', property: 'AddressProperty', visible: false },
    ]

    // useEffect(() => {
    //     getLista();
    // }, [])
    useEffect(() => {
        getLista();
    }, [paginador.page, modalidad])


    const getLista = () => {

        setLoading(true);

        let params: any = {
            State: 1,
            TypeGuarantee: type ? type : modalidad
        };
        if (filtro.IDAccount)
            params.IDAccount = filtro.IDAccount;
        if (filtro.IDProcedure)
            params.IDProcedure = filtro.IDProcedure;

        service.getRealesCreditRender(paginador.page, 15, params).subscribe(
            (resp: any) => {

                if (resp.DataBeanProperties.ObjectValue) {
                    let rec = resp.DataBeanProperties.ObjectValue;
                   //console.log(resp);

                    if (Array.isArray(rec)) {
                        let list = rec[1];
                        if (rec.length > 0) {
                            paginador.setPages(rec[0].Pages);
                            paginador.setRecords(rec[0].Pages);
                        } else {
                           //console.log("error en el paginador")
                        }


                        if (Array.isArray(list)) {
                            list.forEach((ele: any) => {

                                try {
                                    ele.DataBeanProperties.AccountName =
                                        (ele.DataBeanProperties.Name1 ?? '') + " " +
                                        (ele.DataBeanProperties.Name2 ?? '') + " ";

                                    ele.DataBeanProperties.AccountSurname =
                                        (ele.DataBeanProperties.Surname1 ?? '') + " " +
                                        (ele.DataBeanProperties.Surname2 ?? '');

                                    ele.DataBeanProperties.TypeGuaranteeName =
                                        ele.DataBeanProperties.TypeGuarantee === 1 ? 'Hipotecario' : 'Leasing';

                                    ele.DataBeanProperties.CiuMun = ele.DataBeanProperties.Name + " / " + ele.DataBeanProperties.Departamento;


                                    ele.DataBeanProperties.AjusteGarantia =
                                        parseFloat(ele.DataBeanProperties.PropertyValue ?? 0) +
                                        parseFloat(ele.DataBeanProperties.FixedValueAcu ?? 0);
                                } catch (error) {

                                }

                            });
                            setLista(list);
                        } 
                    }
                }
                setLoading(false);
            }
        );
    }

    const agregarItem = () => {

        let b = {
            IDProcedure: 0,
            IDAccount: 0,
            SiteIDLn: 0,
            PropertyValue: 0,
            FixedValue: 0,
            State: 1,
            nit: 0,
            TypeGuarantee: type,
        }
        setBean(b);
        setModal({ ...modal, 'view': true });
    }

    const updateItem = (e: any) => {
        if (bean.StartDate) {
            let d = new Date(bean.StartDate);
            bean.MonthCal = d.getMonth();
            bean.DayCal = d.getDate();
        }
        setModal({ ...modal, 'view': false });
        setLoading(true);
        service.updateRealesCredit(bean).subscribe(
            (resp: any) => {
                setLoading(false);
                if (resp.DataBeanProperties.ObjectValue) {
                    getLista();
                }
                single.update(bean);
            });
    }

    const deletItem = () => {
        let e = bean;
        e.State = 2;
        updateItem(e);
        setModalConfirm({ ...modalConfirm, 'view': false });
    }

    const unSubscribe = () => {
        if (bean.Observation != '' && bean.Observation != undefined && bean.Observation != null) {
            let item = bean;
            item.State = 3;
            item.IDEmployee = single.getAccountID();
            item.UpdateDate = single.getCurrenDate();
            updateItem(item);
            setModalUnSubscribe({ ...modalUnSubscribe, "view": false })
        }
        else {
            toast.error('Debe de agrear una obseración')
        }
    }

    const listaBotones = () => {
        const botones = [
            { titulo: 'Editar', icono: 'ri-file-list-line', visible: single.canEdit(), evento: () => { setModal({ ...modal, "view": true }) } },
            { titulo: 'Eliminar', icono: 'ri-delete-bin-6-line', visible: true, evento: () => { setModalConfirm({ ...modalConfirm, "view": true }) } },
            { titulo: 'Detalle Valorización', icono: ' ri-bar-chart-2-line', visible: true, evento: () => { setModalVal({ ...modalVal, "view": true }) } },
            { titulo: 'Dar de Baja', icono: 'ri-forbid-line', visible: single.canDelete(), evento: () => { setModalUnSubscribe({ ...modalUnSubscribe, "view": true, "name": "Dar de baja el inmueble" }) } },
        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }
    const actionsDropDown = [
        { id: 1, name: 'Descargar Detallado', permiso: null, activo: true },

    ];
    const buttons = () => {
        return (
            <div className="row">
                <div className="col-md-12">
                    {single.canExport() &&
                        <DropDown lista={actionsDropDown} eventClick={actions}></DropDown>
                    }

                </div>
            </div>
        )
    }

    const actions = (e: any) => {

        if (e.id === 1) {

            setModalCSV({ ...modalCSV, "view": true });
        }
    }

    const iconButtonsItem = [
        { nombre: 'Agregar', visible: single.canCreate(), icono: 'ri-file-add-line', evento: agregarItem }
    ]

    const limpiarFiltro = () => {
        setFiltro({});
        getLista();
    }

    const limpiarNumero = (cadena: any) => {
        let cadenaSinPuntosYComas = cadena.replace(/[.,]/g, '');
        if (!isNaN(cadenaSinPuntosYComas)) {
            return parseFloat(cadenaSinPuntosYComas);
        } else {
            return null;
        }

    }

    return (
        <>
            <BasicPage titulo={pageTitle ? pageTitle : "Inmuebles"}>

                <div className="row">
                    <div className="col">
                        <div className="row">
                            <div className="col-md-6">
                                {!type &&
                                    <>
                                        <label>Modalidad</label>
                                        <select key={tipoArchivo.id} name="tipo" className='form-select mt-2' value={modalidad} onChange={(e) => { setModalidad(parseInt(e.target.value)) }}>
                                            {tipoArchivo.map((item: any) => {
                                                return (
                                                    <option value={item.id}>{item.name}</option>
                                                )
                                            })
                                            }
                                        </select>
                                    </>
                                }
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <AccountEditor idAccount={filtro.IDAccount ?? 0} label="Afiliado" onChange={({ IDAccount }: any) => { setFiltro({ ...filtro, "IDAccount": IDAccount }) }} />
                            </div>
                            <div className="col-md-6">
                                <label>Radicado</label>
                                <input type="number" className="form-control" name="IDProcedure" value={filtro.IDProcedure ?? ''} onChange={(e) => { setFiltro({ ...filtro, "IDProcedure": limpiarNumero(e.target.value) }) }}></input>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex gap-2 my-1 w-100">
                        <BasicButton icon="ri-user-search-line" clase="primary" eventClick={getLista}>Buscar</BasicButton>
                        <BasicButton icon="ri-brush-2-line" clase="warning" eventClick={limpiarFiltro}>Limpiar</BasicButton>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 p-2">
                        <BasicTable headButtons={buttons()} listButtons={listaBotones()} iconButtons={iconButtonsItem} head={head} body={lista} rowSet={setBean}
                            pageTop={paginador.page} next={paginador.next} back={paginador.back} records={paginador}
                        ></BasicTable>
                    </div>
                </div>
            </BasicPage>

            {modalConfirm.view &&
                <ModalConfirm modal={modalConfirm} updateModal={setModalConfirm} eventModal={deletItem}>
                    {bean.Name}
                </ModalConfirm>
            }
            {modal.view &&
                <Modal modal={modal} updateModal={setModal} eventModal={updateItem}>
                    <div className="row">
                        <div className="col-md-6">
                            <label >Modalidad</label>
                            <select key={tipoArchivo.id} name="tipo" className='form-select ' value={bean.TypeGuarantee} onChange={(e) => { setBean({ ...bean, "TypeGuarantee": parseInt(e.target.value) }) }}>
                                {tipoArchivo.map((item: any) => {
                                    return (
                                        <option value={item.id}>{item.name}</option>
                                    )
                                })
                                }
                            </select>
                        </div>
                        <div className="col-md-6">
                            <label >Radicado </label>
                            <input className="form-control" value={bean.IDProcedure} onChange={(e) => { setBean({ ...bean, 'IDProcedure': e.target.value }) }}></input>
                        </div>
                        <div className="col-md-6">
                            <AccountEditor canCreate idAccount={bean.IDAccount} label="Afiliado/Propietario" onChange={({ IDAccount }: any) => { setBean({ ...bean, "IDAccount": IDAccount }) }} />
                        </div>
                        <div className="col-md-6">
                            <label >Valor Inmueble  </label>
                            <InputMoney id={0} name="valinm" value={bean.PropertyValue} onChange={(e: any) => { setBean({ ...bean, "PropertyValue": e }) }}></InputMoney>
                        </div>
                        <div className="col-md-6">
                            <SelectSiteID edit={false} onChange={(e: any) => { setBean({ ...bean, "SiteIDLn": e.IDLn }) }} idLnSite={bean.SiteIDLn} />
                        </div>
                        <div className="col-md-6">

                            <InputDate label="Fecha apertura" name="Desde" value={bean.StartDate} setDate={(e: any) => { setBean({ ...bean, 'StartDate': e }) }} ></InputDate>
                        </div>
                        <div className="col-md-6">
                            <InputDate label="Fecha Terminación" name="Desde" value={bean.EndDate} setDate={(e: any) => { setBean({ ...bean, 'EndDate': e }) }} ></InputDate>
                        </div>
                        <div className="col-md-6">
                            <label >Chip</label>
                            <input type="text" className="form-control" value={bean.ChipProperty} onChange={(e) => { setBean({ ...bean, 'ChipProperty': e.target.value }) }}></input>
                        </div>
                        <div className="col-md-6">
                            <label >Cédula catastral</label>
                            <input type="text" className="form-control" value={bean.NitProperty} onChange={(e) => { setBean({ ...bean, 'NitProperty': e.target.value }) }}></input>
                        </div>
                        <div className="col-md-6">
                            <label >Matrícula Inmobiliaria del Inmueble</label>
                            <input type="text" className="form-control" value={bean.TuitionProperty} onChange={(e) => { setBean({ ...bean, 'TuitionProperty': e.target.value }) }}></input>
                        </div>
                        <div className="col-md-6">
                            <label >Matrícula Inmobiliaria del parqueadero</label>
                            <input type="text" className="form-control" value={bean.ParkingProperty} onChange={(e) => { setBean({ ...bean, 'ParkingProperty': e.target.value }) }}></input>
                        </div>
                        <div className="col-md-6">
                            <label >Dirección del inmueble</label>
                            <input type="text" className="form-control" value={bean.AddressProperty} onChange={(e) => { setBean({ ...bean, 'AddressProperty': e.target.value }) }}></input>
                        </div>
                    </div>
                </Modal>
            }
            <Modal modal={modalForm} updateModal={setModalForm} >
                {beanRCV &&
                    <RealesCV lista={beanRCV} idRealesCredit={bean.IDRealesCredit} />
                }

            </Modal>

            <Modal modal={modalDocs} updateModal={setModalDocs} >
                {beanRCV &&
                    <RealesCVDocs _obj={beanRCV} idRealesCredit={bean.IDRealesCredit}></RealesCVDocs>
                }
            </Modal>

            <Modal modal={modalVal} updateModal={setModalVal} >
                <>
                    {modalVal.view &&
                        <DetailGuarantee idRealesCredit={bean.IDRealesCredit} />
                    }
                </>
            </Modal>

            <ModalConfirm modal={modalUnSubscribe} updateModal={setModalUnSubscribe} eventModal={unSubscribe}>
                {modalUnSubscribe.view &&
                    <div className="col-md-12">
                        <textarea className="form-control" value={bean.Observation} placeholder={`Indique la razon para dar de baja el inmueble del radicado ${bean.IDProcedure} del usuario ${bean.AccountName}`} rows={3} onChange={(e) => { setBean({ ...bean, 'Observation': e.target.value }) }}></textarea>
                    </div>
                }
            </ModalConfirm>
            <Modal modal={modalCSV} updateModal={setModalCSV}>
                <div className="row">
                    <div className="col">
                        <ReportCredit typeGuarantee={type} />
                    </div>
                </div>
            </Modal>

        </>


    )
}