import { useEffect, useState } from "react";
import { _UBLInvoice } from "../../../../../core/services/dataBean/EntityCatalog";
import useDataBean from "../../../../../core/services/dataBean/useDataBean";
import { BasicTable } from "../../../../theme/Component/BasicTable";

interface Props {
    _idUBLInvoice: number;
    _isseDate: string;
}

export const HistoryUBLInvoice = ({ _idUBLInvoice, _isseDate }: Props) => {

    const _ubl = useDataBean(_UBLInvoice);
    const [lista, setLista] = useState<any>([]);

    useEffect(() => {
        getLista();
    }, [_idUBLInvoice])

    const getLista = () => {
        _ubl.instance.getUBLEventHistoryCatalog(_idUBLInvoice, _isseDate).then(
            (resp) => {
               //console.log(resp)
                _ubl.setLista(resp)
            }
        )
    }

    const head = [
        { title: 'ID', property: 'IDUBLEventHistory', visible: true },
        { title: 'Usuario', property: 'AccountName', visible: true },
        { title: 'Estado', property: 'StateName', visible: true },
        { title: 'Observaciones', property: 'Observations', visible: true },
        { title: 'Fecha de Evento', property: 'Since', visible: true },
    ]

    return (
        <div className="row">
            <BasicTable body={_ubl.lista} head={head} rowSet={_ubl.setLista} />
        </div>
    )

}