import { useEffect, useState } from "react";
import { useDashboardStore } from "../pages/hooks/useDashboardStore";
import { Singleton } from "../../core/services/Singleton";
import { ListMenu } from "../theme/Component/ListMenu";
import { BasicPage } from "../theme/Component/BasicPage";
import { BasicTable } from "../theme/Component/BasicTable";
import { ModalConfirm } from "../theme/Component/ModalConfirm";
import { Modal } from "../theme/Component/Modal";
import { BpmService } from "../../core/services/BpmService";
import { YearSelector } from "../../core/shared/inputs/YearSelector";
import { RenderForm } from "../theme/Component/RenderForm";
import { AccountEditor } from "../editor/AccountEditor";
import { SelectorPlanSGSST } from "../bpm/config/Componentes/SelectorPlanSGSST";

export const PlanSGSST = () => {

    const { setLoading } = useDashboardStore();
    const service = new BpmService();
    const single = Singleton.getInstance();
    const [bean, setBean] = useState<any>({});
    const [lista, setLista] = useState<any>([]);
    const [modal, setModal] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-md' });
    const [modalConfirm, setModalConfirm] = useState<any>({ name: 'Confirmar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });

    useEffect(() => {
        getLista();
    }, [])

    const idBean = 'IDPlanSGSST';

    const head = [
        { title: 'ID', property: idBean },
        { title: 'Año  del plan', property: "PlanYear", visible: false },
        { title: 'Responsable', property: "ResponsibleName" },
        { title: 'Descripción', property: "Description" },
        { title: 'Ultima Actualización', property: "LastUpdate" },
    ]

    const getLista = () => {
        setLoading(true);
        service.getPlanSGSSTCatalogPorPropiedad("State", 1, null).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue) {
                    setLista(resp.DataBeanProperties.ObjectValue);
                }
                setLoading(false);
            }
        );
    }


    const setRowBean = (bean: any) => {
        setBean(bean);
    }

    const agregarItem = () => {
        let b = {
            PlanYear: single.getCurrenYear(),
            State: 1,
            IDEmployee: single.getAccountID(),
            LastUpdate: single.getCurrenDate(),
            JSONData: undefined,
            Description: "",
            IDResponsible: 0,
            ResponsibleName: "",
        }

        setBean(b);
        setModal({ ...modal, 'view': true, "name": "Agregar Categoria" });
    }

    const eliminarItem = () => {
        bean.State = 2;
        updateItem();
        setModalConfirm({ ...modalConfirm, 'view': false });
    }

    const updateItem = () => {
        ////console.log(bean);
        setModal({ ...modal, 'view': false });
        setLoading(true);
        service.updatePlanSGSST(bean).subscribe(
            (resp: any) => {
                setLoading(false);
                if (resp.DataBeanProperties.ObjectValue) {
                    getLista();
                }
                if (bean.IDGroupTemplate) {
                    single.update(bean);
                } else {
                    single.create(bean);
                }
            });
    }

    const listaBotones = () => {
        const botones = [

            { titulo: 'Editar', icono: 'ri-file-list-line', visible: single.canEdit(), evento: () => { setModal({ ...modal, "view": true, "name": "Editar categoria" }) } },
            { titulo: 'Eliminar', icono: 'ri-file-user-line', visible: single.canDelete(), evento: () => { setModalConfirm({ ...modalConfirm, "view": true }) } },
        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }

    const iconButtonsItem = [
        { nombre: 'Agregar', visible: single.canCreate(), icono: 'ri-file-add-line', evento: agregarItem }
    ]

    return (
        <>
            <BasicPage titulo="Plan SGSST">
                <div className="row">
                    <div className="col-md-12 p-2">
                        <BasicTable listButtons={listaBotones()} iconButtons={iconButtonsItem} head={head} body={lista} rowSet={setRowBean}></BasicTable>
                    </div>
                </div>
            </BasicPage>

            <ModalConfirm modal={modalConfirm} updateModal={setModalConfirm} eventModal={eliminarItem}>
                {bean.Name}
            </ModalConfirm>

            <Modal modal={modal} updateModal={setModal} eventModal={updateItem}>
                <div className="row">
                    <div className="col-md-12">
                        <YearSelector tittle="Año del plan" value={bean.PlanYear} onChange={(e) => { setBean({ ...bean, "PlanYear": e }) }}></YearSelector>
                    </div>
                    <div className="col-md-12">
                        <AccountEditor idAccount={bean.IDResponsible} label="Responsable" onChange={({ IDAccount, EntityName }: any) => { setBean({ ...bean, "IDResponsible": IDAccount, "ResponsibleName": EntityName }) }}></AccountEditor>
                    </div>
                    <div className="col-md-12">
                        <label >Descripción</label>
                        <textarea className="form-control" value={bean.Description} onChange={(e: any) => { setBean({ ...bean, "Description": e.target.value }) }}></textarea>
                    </div>
                    {/* <div className="col-md-12">
                        <RenderForm data={bean.JSONData ? JSON.parse(bean.JSONData) : {}} idFormType={501} handleUpdate={(e: any) => { setBean({ ...bean, "JSONData": JSON.stringify(e) }) }}></RenderForm>
                    </div> */}
                </div>
            </Modal>
        </>
    )
}