import { Modal } from "../../../theme/Component/Modal";
import { useState, useEffect } from 'react';
import { Alert } from "../../../theme/Component/Alert";
import { ModalConfirm } from '../../../theme/Component/ModalConfirm';
import { AdminService } from "../../../../core/services/AdminService";
import { Singleton } from "../../../../core/services/Singleton";

interface Props {
    id: number;
    bean: any;
    updateEvent: any;
}
export const ButtonSideID = ({ id, bean, updateEvent }: Props) => {

    const single = Singleton.getInstance()
    const service = new AdminService();
    const [modal, setModal] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [confirm, setConfirm] = useState<any>({ name: 'Confirmación', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });
    const [modalAdd, setModalAdd] = useState<any>({ name: 'Agregar Item', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });
    const [miBean, setMibean] = useState<any>({});
    const [code, setCode] = useState('');
    const [nombre, setNombre] = useState('');
    const [message, setMessage] = useState('');

    useEffect(() => {
        setMibean(bean);
    }, [])
    useEffect(() => {
    }, [miBean.IDLn])


    const updateBean = () => {


        service.updateSiteID(miBean).subscribe(
            (resp: any) => {
               //console.log("Update : ", resp);

                updateEvent();
                setModal({ ...modal, ['view']: false });
                single.update(miBean);
            }
        );

    }

    const createBean = () => {
        if (code != '' && nombre != '') {
            let separador = localStorage.getItem('separador') || '-';

            let hijo = {
                Code: code,
                Name: nombre,
                IDLn_1: miBean.IDLn
            }

            service.createSiteID(hijo, miBean).subscribe(
                (resp: any) => {
                   //console.log("Crear Item ", resp);
                    updateEvent();
                    single.create(miBean);
                }
            );
            setModalAdd({ ...modalAdd, ['view']: false });
        } else {
            setMessage('Código y Nombre son obligatorios');
        }
    }

    const deleteID = () => {
       //console.log("bean ", bean);

        service.deleteSiteID(bean).subscribe(
            (resp: any) => {
                updateEvent();
                setConfirm({ ...confirm, ['view']: false });
                single.delete(bean.IDLn, miBean);
            }
        )

    }

    return (
        <>
            {single.canCreate() &&
                <i className="ri-file-add-line cursor btnicon_sm" title="Agregar Hijo" onClick={() => {
                    setCode(bean.TrdViewCode);
                    setModalAdd({ ...modalAdd, ['view']: true });
                }}></i>
            }

            {single.canDelete() && miBean.IDLn &&
                <i className="ri-file-reduce-line cursor btnicon_sm" title="Eliminar" onClick={() => setConfirm({ ...confirm, ['view']: true })}></i>
            }

            {single.canEdit() && miBean.IDLn &&
                <i className="ri-edit-2-line cursor btnicon_sm" title="Editar" onClick={() => {
                    setModal({ ...modal, ['view']: true });
                }}></i>
            }

            {modal.view &&
                <Modal modal={modal} updateModal={setModal} eventModal={updateBean}>
                    <div className="row">
                        <div className="col-md-6">
                            <label>Código</label>
                            <input type="number" className="form-control" value={miBean.Code} onChange={(e) => { setMibean({ ...miBean, ['Code']: e.target.value }) }} />
                        </div>

                        <div className="col-md-12">
                            <label>Nombre</label>
                            <textarea className="form-control" value={miBean.Name} onChange={(e) => { setMibean({ ...miBean, ['Name']: e.target.value }) }}></textarea>
                        </div>
                    </div>
                </Modal>
            }

            {modalAdd.view &&
                <Modal modal={modalAdd} updateModal={setModalAdd} eventModal={createBean}>
                    <div className="row">
                        <div className="col-md-12">
                            <label htmlFor="code">Código
                                <input type="text" className="form-control" value={code} onChange={(e) => { setCode(e.target.value) }} />
                            </label>
                        </div>

                        <div className="col-md-12">

                            <label htmlFor="code">Nombre
                                <input type="text" className="form-control" value={nombre} onChange={(e) => { setNombre(e.target.value) }} />
                            </label>
                        </div>
                        <div className="col-md-12">
                            {message != '' &&
                                <Alert clase="warning">
                                    <strong>Parametros</strong> {message}
                                </Alert>}
                        </div>
                    </div>
                </Modal>
            }

            {confirm.view &&
                <ModalConfirm modal={confirm} updateModal={setConfirm} eventModal={deleteID}>
                    <h4>Eliminar el item ?</h4>
                </ModalConfirm>
            }
        </>
    )
}