import { useState } from "react";
import { BasicSwitch } from "../theme/Component/BasicSwitch";
import { FileInput } from "../theme/Component/FileInput";
import { PrintJson } from "../theme/Component/PrintJson";
import useDataBean from "../../core/services/dataBean/useDataBean";
import { _DaoInfo } from "../../core/services/dataBean/EntityCatalog";
import { BasicButton } from "../theme/Component/BasicButton";

interface Props {
    data: any;
}

export const ImportUtil = ({ data }: Props) => {

    const service = useDataBean(_DaoInfo);
    const [borrar, setBorrar] = useState<boolean>(false);
    const [file, setFile] = useState<any>({});
    const [response, setResponse] = useState<any>({});

   
    const importJsonDataBase=()=>{
        service.instance.importJsonDataBase(file.Media,file.MediaContext,null,borrar).then(
            (resp:any)=>{
                setResponse(resp);
                
            }
        )
    }


    return (
        <div className="row">
            <div className="col-md-4">
                <FileInput onCreate={setFile} directory="tmp" label="Archivo .zip o .txt" />
            </div>
            <div className="col-md-4 mt-4">
                <BasicSwitch label="Borrar Tablas antes de Importar" estado={borrar} eventChange={setBorrar} />
            </div>
            <div className="col-md-4 mt-4">
                <BasicButton icon="ri-upload-cloud-2-line" disable={!file.Media} eventClick={importJsonDataBase}>Subir</BasicButton>
            </div>
            <div className="col-md-12 mt-4">
                <PrintJson  json={response}/>
            </div>
        </div>
    )
}