import { useState, useEffect } from 'react';
import useDataBean from '../../../../core/services/dataBean/useDataBean';
import { _TaxScheme } from '../../../../core/services/dataBean/EntityCatalog';

interface Props {
    idSelector: number;
    onChange: any;
    label?: string;
}

export const TaxSchemeContextStateEditor = ({ idSelector, onChange, label }: Props) => {

    const taxScheme = useDataBean(_TaxScheme)
    const [lista, setLista] = useState<any>([])
    const [id, setID] = useState(null);

    useEffect(() => {
        if (idSelector >= 0) {
            setID(idSelector);
        }
        getLista();
    }, [])

    useEffect(() => {
        onChange(id);
    }, [id])

    const setValue = (e: any) => {
        setID(parseInt(e));
        onChange(id);
    }

    const getLista = () => {
        taxScheme.instance.getTaxSchemeContextStateConstants(null).then(
            (resp) => {
                setLista(resp)
                // if (resp.length > 0 && !idSelector) {
                //     setID(resp[0].DataBeanProperties.IDAppointment)
                // }
            }
        )
    }

    return (
        <>
            <label>{label ?? "Estado del Impuesto"}</label>
            <select className="form-select" value={id} onChange={(e) => { setValue(e.target.value) }}>
                <option value={null}>...</option>
                {lista &&
                    lista.map((item: any, index: number) => {
                        return (
                            <option key={index} value={item.DataBeanProperties.Value} >{item.DataBeanProperties.Property}</option>
                        )
                    })
                }
            </select>
        </>
    )
}