import { useState, useEffect } from 'react';
import useDataBean from '../../../../../core/services/dataBean/useDataBean';
import { _CashReimbursementItem } from '../../../../../core/services/dataBean/EntityCatalog';

interface Props {
    idSelector: number;
    onChange: any;
    label?: string;
}

export const CashReimbursementStateConstantsEditor = ({ idSelector, onChange, label }: Props) => {

    const constantState = useDataBean(_CashReimbursementItem)
    const [lista, setLista] = useState<any>([])
    const [id, setID] = useState(idSelector ?? null);

    useEffect(() => {
        setID(idSelector);
        getLista();
    }, [])

    useEffect(() => {
        onChange(id);
    }, [id])

    const setValue = (e: any) => {
        setID(parseInt(e));
        onChange(id);
    }

    const getLista = () => {
        constantState.instance.getCashReimbursementStateConstants(null).then(
            (resp) => {
                setLista(resp)
            }
        )
    }

    return (
        <>
            <label>{label ?? "Estado de la Factura"}</label>
            <select className="form-select" value={id} onChange={(e) => { setValue(e.target.value) }}>
                <option value={null} >...</option>
                {lista &&
                    lista.map((item: any, index: number) => {
                        return (
                            <option key={index} value={item.DataBeanProperties.Value} >{item.DataBeanProperties.Property}</option>
                        )
                    })
                }
            </select>
        </>
    )
}