import { DataBean } from "../DataBean";

export class RemoteAbstractAction extends DataBean{


    public async getRemoteAbstractAction(id:number): Promise<any> {
        return this.getCustomService("OrangeCore", "com.advantage.bean.dev.RemoteAbstractAction_getRemoteAbstractAction_Number", [id]);
    }
    public async getApplicationTypeConstants(): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_getApplicationTypeConstants_Number", [null]);
    }
    public async getApplicationCatalog(): Promise<any> {
        return this.getCustomService("OrangeBase", "java.util.List_getApplicationCatalog_Number", [null]);
    }
    public async getApplicationTypeCatalogByMenuType(menuType:number,idApplication:number): Promise<any> {
        return this.getCustomService("OrangeBase", "java.util.List_getApplicationTypeCatalogByMenuType_Number_Number", [menuType,idApplication]);
    }
    public async getRemoteAbstractActionByApplicationType(idApplicationType:number,state:number): Promise<any> {
        return this.getCustomService("OrangeBase", "java.util.List_getRemoteAbstractActionByApplicationType_Number_Number", [idApplicationType,state]);
    }
    public async registerRemoteAbstractAccount(idApplication:number,idApplicationType:number,classForName:string): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "com.advantage.bean.dev.RemoteAbstractAction_registerRemoteAbstractAccount_Number_Number_String", [idApplication,idApplicationType,classForName]);
    }
    public async getAbstractStepCatalog(idRemoteAbstractAction:number): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_getAbstractStepCatalog_Number", [idRemoteAbstractAction]);
    }
    public async registerComponentForAbstractStep(classForName:string,componentName:string): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_getAbstractStepCatalog_Number", [classForName,componentName]);
    }
    public async fireRemoteAbstractAction(idRemoteAbstractAction:number,idEmployee:number): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "com.advantage.bean.dev.ApplicationInvoker_fireRemoteAbstractAction_Number_Number", [idRemoteAbstractAction,idEmployee]);
    }
    public async getRemoteAbstractActionNextStep(idApplicationInvoker:number,idEmployee:number): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "com.quickdataerp.bean.admin.AbstractStepInfo_getRemoteAbstractActionNextStep_Number_Number", [idApplicationInvoker,idEmployee]);
    }
    public async getGenericCellEditorCatalogByType(): Promise<any> {
        return this.getCustomService("OrangeCore", "java.util.List_getGenericCellEditorCatalogByType_String", [null]);
    }
    public async setRemoteAbstractActionProperties(idApplicationInvoker:number,properties:any,idEmployee:number): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "com.quickdataerp.bean.admin.AbstractStepInfo_setRemoteAbstractActionProperties_Number_java.util.Map_Number", [idApplicationInvoker,properties,idEmployee]);
    }
    public async getMonthConstantsList(): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_getMonthConstantsList_Number",
             [null]);
    }
    public async getAccountantIDLevelConstants(): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_getAccountantIDLevelConstants_Number",
             [null]);
    }
    public async fireAbstractStepEvent(idApplicationInvoker:number,eventName:string,properties:any): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.Map_fireAbstractStepEvent_Number_String_java.util.Map",
             [idApplicationInvoker,eventName,properties]);
    }
    public async getProjectCatalogPorPropiedadesRender(props:any): Promise<any> {
        return this.getCustomService("FinanceCore", "java.util.List_getProjectCatalogPorPropiedadesRender_java.util.Map_Number",
             [props,null]);
    }
    public async getApplicationInvokerCatalogByType(idAccount:number): Promise<any> {
        return this.getCustomService("OrangeCore", "java.util.List_getApplicationInvokerCatalogByType_Number",
             [idAccount]);
    }
    public async validateAbstractStepProperties(idApplicationInvoker:number,properties:any,idEmployee:number): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "com.advantage.shared.FormValidationResponse_validateAbstractStepProperties_Number_java.util.Map_Number",
             [idApplicationInvoker,properties,idEmployee]);
    }
    public async validateAbstractStepTableRow(idApplicationInvoker:number,propertyName:string,mapProperties:any,row:number,idEmployee:number): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "com.advantage.shared.FormValidationResponse_validateAbstractStepTableRow_Number_String_java.util.Map_Number_Number",
             [idApplicationInvoker,propertyName,mapProperties,row,idEmployee]);
    }


}