import { useState, useEffect } from 'react';
import { Singleton } from "../../../../core/services/Singleton";
import { BasicPage } from "../../../theme/Component/BasicPage";
import { BasicTable } from "../../../theme/Component/BasicTable";
import { ModalConfirm } from "../../../theme/Component/ModalConfirm";
import { Modal } from "../../../theme/Component/Modal";
import { BpmService } from "../../../../core/services/BpmService";
import { ListMenu } from "../../../theme/Component/ListMenu";
import { THConstantsValue } from "./THConstantsValue";
import { useDashboardStore } from '../../../pages/hooks/useDashboardStore';
import { DropDown } from '../../../theme/Component/DropDown';
import { toast } from 'react-toastify';

export const Parametricasth = () => {

    const single = Singleton.getInstance()
    const { setLoading } = useDashboardStore();
    const service = new BpmService();
    const [lista, setLista] = useState([]);
    const [bean, setBean] = useState<any>({});
    const [modal, setModal] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalTHC, setModalTHC] = useState<any>({ name: 'Parametricas', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalConfirm, setModalConfirm] = useState<any>({ name: 'Confirmar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });
    const [user, setUser] = useState<any>({});

    const idBean = 'IDTHConstants';

    const head = [
        { title: 'ID', property: idBean },
        { title: 'Nombre', property: 'Name' },
        { title: 'Descripción', property: 'Description' },
        { title: 'Código', property: 'Code' },
    ]

    useEffect(() => {
        setUser(single.getAccountID());
        getLista();
    }, [idBean])

    const setRowBean = (bean: any) => {
        //console.log("Row : ", bean);
        setBean(bean);
    }

    const getLista = () => {

        setLoading(true);
        service.getTHConstantsCatalogPorPropiedad('State', 1, null).subscribe(
            (resp: any) => {

                if (resp.DataBeanProperties.ObjectValue) {
                    setLista(resp.DataBeanProperties.ObjectValue);
                }
                setLoading(false);
            }
        );
    }

    const agregarItem = () => {
        let b = {
            Name: '',
            Description: '',
            Code: 1,
            State: 1,
        }
        setBean(b);
        setModal({ ...modal, ['view']: true });

    }

    const eliminarItem = () => {
        bean.State = 2;
        //console.log(bean);
        updateItem();
        setModalConfirm({ ...modalConfirm, ['view']: false });
    }

    const updateItem = () => {
        setModal({ ...modal, ['view']: false });
        setLoading(true);
        service.updateTHConstants(bean).subscribe(
            (resp: any) => {
                setLoading(false);
                if (resp.DataBeanProperties.ObjectValue) {
                    getLista();
                }
                single.update(bean);
            }
        );
    }

    const eventChange = (e: any) => {

        if (e.id === 1) {
            service.cleanSuppConstantsValues(0).subscribe(
                (resp: any) => {
                    toast.success("Cahce actualizada.");
                }
            )
        }

    }

    const listaBotones = () => {
        const botones = [

            { titulo: 'Editar', icono: 'ri-file-list-line', visible: true, evento: () => { setModal({ ...modal, "view": true }) } },
            { titulo: 'Eliminar', icono: 'ri-file-user-line', visible: true, evento: () => { setModalConfirm({ ...modalConfirm, "view": true }) } },
            { titulo: 'Parametricas', icono: 'ri-file-user-line', visible: true, evento: () => { setModalTHC({ ...modalTHC, "view": true }) } },

        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }

    const iconButtonsItem = [
        { nombre: 'Agregar', visible: single.canCreate(), icono: 'ri-file-add-line', evento: agregarItem }
    ]

    const actionsDropDown = [
        { id: 1, name: 'Borrar Cache', permiso: null, activo: true },
    ];
    const buttons = () => {
        return (
            <div className="row">
                <div className="col-md-12">
                    <DropDown lista={actionsDropDown} eventClick={eventChange}></DropDown>
                </div>
            </div>
        )
    }

    return (
        <>
            <BasicPage titulo='Parametricas Talento Humano'>
                <div className="row">
                    <div className="col-md-12 p-2">
                        <BasicTable headButtons={buttons()} listButtons={listaBotones()} iconButtons={iconButtonsItem} head={head} body={lista} rowSet={setRowBean}></BasicTable>
                    </div>
                </div>
            </BasicPage>

            <ModalConfirm modal={modalConfirm} updateModal={setModalConfirm} eventModal={eliminarItem}>
                {bean.Name}
            </ModalConfirm>

            <Modal modal={modal} updateModal={setModal} eventModal={updateItem}>
                <div className="row">
                    <div className="col-md-6">
                        <label className="form-label">Nombre</label>
                        <input type="text" className="form-control" value={bean.Name} onChange={(e) => { setBean({ ...bean, ['Name']: e.target.value }) }} />
                    </div>

                    <div className="col-md-6">
                        <label className="form-label">Code</label>
                        <input type="text" className="form-control" value={bean.Code} onChange={(e) => { setBean({ ...bean, ['Code']: e.target.value }) }} />
                    </div>
                    <div className="col-md-12">
                        <label className="form-label">Descripción</label>
                        <textarea className="form-control" value={bean.Description} onChange={(e) => { setBean({ ...bean, ['Description']: e.target.value }) }}></textarea>
                    </div>

                </div>
            </Modal>

            <Modal modal={modalTHC} updateModal={setModalTHC}>
                <div className="row">
                    <div className="col-md-12">
                        <THConstantsValue idTHConstants={bean.IDTHConstants} />
                    </div>
                </div>
            </Modal>
        </>
    )
}