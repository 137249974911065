import { DataBean } from "../DataBean";


export class AbilityType extends DataBean {

    public async getAbilityTypeCatalog(idAbilityType: number | null): Promise<any> {
        return this.getCustomService("PeopleArt", "java.util.List_getAbilityTypeCatalog_Number", [idAbilityType]);
    }

    public async deleteAbilityType(bean): Promise<any> {
        return this.getCustomService("PeopleArt", "void_deleteAbilityType_com.peopleart.bean.appointment.AbilityType", [
            {
                "DataBeanProperties": bean,
                "DataBeanName": "com.peopleart.bean.appointment.AbilityType"
            }
        ]);
    }
}