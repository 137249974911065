import { useState, useEffect } from 'react';
import { FinanceService } from '../../../../core/services/FinanceService';



interface Props {
    idSelector: number;
    onChange: any;
    label?: string;
}

export const SelectStateConst = ({ idSelector, onChange, label }: Props) => {

    const service = new FinanceService();
    const [lista, setLista] = useState<any>([])
    const [id, setID] = useState(0);
    const [listaType, setListaType] = useState([]);
    useEffect(() => {
        if (idSelector >= 0) {
            setID(idSelector);
        }
    }, [])

    useEffect(() => {
        getListaType();
    }, [])


    useEffect(() => {
        onChange(id);
    }, [id])

    const setValue = (e: any) => {
        setID(parseInt(e));
        onChange(id);
    }

    // const getLista = () => {
    //     macroProcess.instance.getMacroProcessCatalog().then(
    //         (resp) => {
    //             setLista(resp)
    //             if (resp.length > 0 && !idSelector) {
    //                 setID(resp[0].DataBeanProperties.IDMacroProcess)
    //             }
    //         }
    //     )
    // }

    const getListaType = () => {

        service.getPaymentAccountOperationStateConstants(null).subscribe((resp: any) => {

            if (resp.DataBeanProperties.ObjectValue) {


                setListaType(resp.DataBeanProperties.ObjectValue);

            }
        });
    };

    return (
        <>
            <label>{label ?? ":"}</label>
            <select className="form-select" value={id} onChange={(e) => { setValue(parseInt(e.target.value)) }}>
                <option value={0}>...</option>
                {listaType.length > 0 &&
                    listaType.map((item: any) => {
                        return (
                            <option value={item.DataBeanProperties.Value}>{item.DataBeanProperties.Property}</option>
                        )
                    })
                }
            </select>
        </>
    )
}