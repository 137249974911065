import { DataBean } from "../DataBean";

export class EmployeeIncapacity extends DataBean {

    public async getEmployeeIncapacities(accountID: number | null, year: number, month: number, type: number | null): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_getEmployeeIncapacities_Number_Number_Number_Number_Number", [accountID, year, month, null, type]);
    }

    public async getIncapacityTypeConstants(arg: number | null): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_getIncapacityTypeConstants_Number", [arg]);
    }

    public async getLiquidateMonthConstants(arg: number | null): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_getLiquidateMonthConstants_Number", [arg]);
    }

    public async deleteEmployeeIncapacity(idIncapacity: number): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "Integer_deleteEmployeeIncapacity_Number", [idIncapacity]);
    }

}