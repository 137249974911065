import { DataBean } from "../DataBean";

export class TechnicalCriteria extends DataBean {


    public async getTechnicalCriteriaCatalog(idTechnicalSkill: number | null): Promise<any> {
        return this.getCustomService("PeopleArt", "java.util.List_getTechnicalCriteriaCatalog_Number", [idTechnicalSkill]);
    }

    public async deleteTechnicalCriteria(bean): Promise<any> {
        return this.getCustomService("PeopleArt", "void_deleteTechnicalCriteria_Number", [
            {
                "DataBeanProperties": bean,
                "DataBeanName": "com.peopleart.bean.appointment.TechnicalCriteria"
            }
        ]);
    }
}