import { DataBean } from "../DataBean";


export class Role extends DataBean {

    public async getRoleCatalog(idRole: number | null): Promise<any> {
        return this.getCustomService("OrangeBase", "java.util.List_getRoleCatalog_Number", [idRole]);
    }

    public async updateRole(bean: any): Promise<any> {
        return this.getCustomService("OrangeBase", "com.advantage.bean.securitymanager.Role_updateRole_com.advantage.bean.securitymanager.Role", [
            {
                DataBeanProperties: bean,
                DataBeanName: 'com.advantage.bean.securitymanager.Role'
            }
        ]);
    }

    public async deleteRole(bean: any): Promise<any> {
        return this.getCustomService("OrangeBase", "void_deleteRole_com.advantage.bean.securitymanager.Role", [
            {
                DataBeanProperties: bean,
                DataBeanName: "com.advantage.bean.securitymanager.Role"
            }
        ]);
    }

}