import { useState } from "react";
import { AdminService } from "../../../core/services/AdminService";
import { Singleton } from "../../../core/services/Singleton";
import { BasicPage } from "../../theme/Component/BasicPage"
import { BasicButton } from "../../theme/Component/BasicButton";
import { Alert } from "../../theme/Component/Alert";
import { useDashboardStore } from "../../pages/hooks/useDashboardStore";

export const ThirdPartyUser = () => {

    const admin = new AdminService();
    const single = Singleton.getInstance()
    const { setLoading } = useDashboardStore();
    const [acc, setAcc] = useState<any>({});
    const [step, setStep] = useState(1);
    const [find, setFind] = useState<boolean>(false);
    const [nit, setNit] = useState<number | undefined>();
    const [message, setMessage] = useState<string>('');

    const getAccountByNit = () => {

        setLoading(true);
        admin.getAccountByNit(nit ? nit : 0).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue) {
                    let d = resp.DataBeanProperties.ObjectValue;
                   //console.log(resp.DataBeanProperties.ObjectValue);
                    if (d.length > 0) {
                        setAcc(d[0].DataBeanProperties);
                        setStep(2);
                        setMessage('Usuario encontrado. verifique y actualice la información.');
                        setFind(true);
                    } else {
                        setMessage('No se encontro ningun usuario con esa identificación.');
                        setStep(2);
                        setAcc({
                            "Nit": 0,
                            "Name1": "",
                            "Name2": "",
                            "Surname1": "",
                            "Surname2": "",
                            "Tel": "",
                            "eMail": "",
                        });
                        setFind(false);
                    }


                } else {
                    setAcc({});
                    setMessage('No respondio el servicio correctamente, recargue la pagina nuevamente');
                }
                setLoading(false);
            }
        )

    }

    const updateAccount = () => {
        let acc1 = acc;
        if (!acc1.IDAccount) {
            admin.createAbstractAccount(acc1).subscribe(
                (resp: any) => {
                   //console.log(resp);
                    if (resp.DataBeanProperties) {
                        setMessage('Usuario registrado correctamente, Intente ingresar Nuevamente.')
                    }

                }
            )
           //console.log(acc1);
        }

        if (acc1.IDAccount) {
            admin.updateAbstractAccount(acc1).subscribe(
                (resp: any) => {
                   //console.log(resp);
                    if (resp.DataBeanProperties) {
                        setMessage('Usuario Actualizado correctamente, Intente ingresar Nuevamente.')
                    }

                }
            )
        }
    }

    const Regresar = () => {
        setStep(1); setNit(undefined); setMessage('')
    }

    return (
        <>
            <BasicPage titulo="Usuarios Terciarios">
                <>
                    <div className="row">
                        <div className="col-md-12">
                            {step === 1 &&
                                <div className="row">
                                    <div className="col">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col">
                                                        <label htmlFor="user">Identificación</label>
                                                        <input type="number" className="form-control" id="user" value={nit} onChange={(e) => {
                                                            setNit(parseInt(e.target.value));
                                                        }} />
                                                    </div>
                                                    <div className="col mt-4">
                                                        <BasicButton icon="ri-file-search-line" eventClick={getAccountByNit}>Consultar</BasicButton>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            {step === 2 &&
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="row">
                                            <h2>{message}</h2>
                                            {!find &&
                                                <h3 className="text-center mt-4">Registro de nuevo usuario</h3>
                                            }
                                            {acc.IDAccount &&
                                                <div className="col-md-12">
                                                    <label className="form-label">Identificacón</label>
                                                    <input type="text" className="form-control" value={acc.Nit} readOnly />
                                                </div>
                                            }
                                            {!acc.IDAccount &&
                                                <div className="col-md-12">
                                                    <label className="form-label">Identificacón</label>
                                                    <input type="text" className="form-control" value={acc.Nit} onChange={(e) => { setAcc({ ...acc, "Nit": e.target.value }) }} />
                                                </div>
                                            }
                                            <div className="col-md-6">
                                                <label className="form-label">Primer Nombre</label>
                                                <input type="text" className="form-control" value={acc.Name1} onChange={(e) => { setAcc({ ...acc, "Name1": e.target.value }) }} />
                                            </div>
                                            <div className="col-md-6">
                                                <label className="form-label">Segundo Nombre</label>
                                                <input type="text" className="form-control" value={acc.Name2} onChange={(e) => { setAcc({ ...acc, "Name2": e.target.value }) }} />
                                            </div>
                                            <div className="col-md-6">
                                                <label className="form-label">Primer Apellido</label>
                                                <input type="text" className="form-control" value={acc.Surname1} onChange={(e) => { setAcc({ ...acc, "Surname1": e.target.value }) }} />
                                            </div>
                                            <div className="col-md-6">
                                                <label className="form-label">Segundo Apellido</label>
                                                <input type="text" className="form-control" value={acc.Surname2} onChange={(e) => { setAcc({ ...acc, "Surname2": e.target.value }) }} />
                                            </div>
                                            <div className="col-md-6">
                                                <label className="form-label">Correo Electronico</label>
                                                <input type="text" className="form-control" value={acc.eMail} onChange={(e) => {
                                                    setAcc({ ...acc, "eMail": e.target.value })
                                                }} />
                                            </div>
                                            <div className="col-md-6">
                                                <label className="form-label">Telefono</label>
                                                <input type="text" className="form-control" value={acc.Tel} onChange={(e) => { setAcc({ ...acc, "Tel": e.target.value }) }} />
                                            </div>
                                            <div className="col-md-12">
                                                <div className="row">
                                                    {acc.IDAccount ?
                                                        <div className="col-md-4">
                                                            <BasicButton icon="ri-send-plane-fill" eventClick={updateAccount}>Actualizar</BasicButton>
                                                        </div> :
                                                        <div className="col-md-4">
                                                            <BasicButton icon="ri-send-plane-fill" eventClick={updateAccount}>Crear</BasicButton>
                                                        </div>
                                                    }
                                                    <div className="col-md-4">
                                                        <BasicButton icon="ri-send-plane-fill" eventClick={Regresar}>Regresar</BasicButton>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                        <div className="col-md-12">
                            {message != '' &&
                                <Alert clase="primary">{message}</Alert>
                            }
                        </div>
                    </div>
                </>
            </BasicPage>
        </>
    )
}