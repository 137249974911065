import { useEffect, useState } from "react";
import { _AppointmentGroupManual } from "../../../../core/services/dataBean/EntityCatalog";
import useDataBean from "../../../../core/services/dataBean/useDataBean";
import { Singleton } from "../../../../core/services/Singleton";
import { ListMenu } from "../../../theme/Component/ListMenu";
import { BasicPage } from "../../../theme/Component/BasicPage";
import { BasicTable } from "../../../theme/Component/BasicTable";
import { Modal } from "../../../theme/Component/Modal";
import { ModalConfirm } from "../../../theme/Component/ModalConfirm";
import { toast } from "react-toastify";
import { PrintJson } from "../../../theme/Component/PrintJson";
import { SelectFunctionalID } from "../../../admin/configuracion/functionalID/SelectFunctionalID";

export const AppointmentGroupManual = () => {

    const appointmentGroupManual = useDataBean(_AppointmentGroupManual);
    const single = Singleton.getInstance();

    const [lista, setLista] = useState<any>([]);
    const [modal, setModal] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalC, setModalC] = useState<any>({ name: 'Eliminar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });

    useEffect(() => {
        getLista();
    }, [])


    const getLista = () => {

        appointmentGroupManual.instance.getAppointmentGroupManualCatalog().then(
            (resp: any) => {
                if (resp) {
                    setLista(resp)
                }
            }
        )

    }

    const create = () => {
        appointmentGroupManual.setBean({});
        setModal({ ...modal, "view": true });
    }
    const deleteItem = () => {
        appointmentGroupManual.instance.deleteAppointmentGroupManual(appointmentGroupManual.bean).then(
            (resp: any) => {
                getLista();
                setModalC({ ...modalC, "view": false });
            }
        )

    }
    const editaItem = () => {
        ////console.log(appointmentGroupManual.bean);
        appointmentGroupManual.update().then(
            (resp: any) => {
                getLista();
                setModal({ ...modal, "view": false });
            }
        ).catch(err => {
            toast.error(err);
        })

    }

    const head = [
        { title: 'ID', property: 'IDAppointmentGroupManual', visible: true },
        { title: 'Nombre', property: 'Name', visible: true },
        { title: 'Descripción', property: 'Description', visible: true },
        { title: 'Área', property: 'IDLnFunctionalID', visible: false },
    ]

    const iconButtonsItem = [
        { nombre: 'Agregar', visible: single.canCreate(), icono: 'ri-file-add-line', evento: create }
    ]
    const listaBotones = () => {
        const botones = [
            { titulo: 'Editar', icono: 'ri-edit-2-line', visible: single.canEdit(), evento: () => { setModal({ ...modal, "view": true }) } },
            { titulo: 'Eliminar', icono: 'ri-delete-bin-line', visible: single.canDelete(), evento: () => { setModalC({ ...modalC, "view": true }) } },

        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }


    return (
        <BasicPage titulo="Tipos de Manual de Funciones">
            <div className="row">
                <div className="col-md-12">
                    <BasicTable body={lista} head={head} rowSet={appointmentGroupManual.setBean} listButtons={listaBotones()} iconButtons={iconButtonsItem}></BasicTable>
                    <Modal modal={modal} updateModal={setModal} eventModal={editaItem}>
                        <div className="row">
                            <div className="col-md-6">
                                <label>Nombre</label>
                                <input type="text" name="Name" className="form-control" value={appointmentGroupManual.bean.Name} onChange={appointmentGroupManual.handleChange} />
                            </div>
                            <div className="col-md-6">
                                <SelectFunctionalID functionalIDLn={appointmentGroupManual.bean.IDLnFunctionalID} onChange={(e) => appointmentGroupManual.setBean({ ...appointmentGroupManual.bean, "IDLnFunctionalID": e.IDLn })} />
                                {/* <EntailmentEditor _name="IDEntailment" idSelector={appointmentGroupManual.bean.IDEntailment} onChange={(e) => appointmentGroupManual.setBean({ ...appointmentGroupManual.bean, "IDEntailment": e })} /> */}
                            </div>
                            <div className="col-md-12">
                                <label>Descripción</label>
                                <textarea name="Description" className="form-control" value={appointmentGroupManual.bean.Description} onChange={appointmentGroupManual.handleChange}></textarea>
                            </div>
                            {/* <PrintJson json={appointmentGroupManual.bean} /> */}
                        </div>
                    </Modal>
                    <ModalConfirm modal={modalC} updateModal={setModalC} eventModal={deleteItem}>
                        <strong>Eliminar item {appointmentGroupManual.bean.Name}</strong>
                    </ModalConfirm>
                </div>
            </div>
        </BasicPage>
    )
}