import { useEffect, useState } from "react";
import { PrintJson } from "../theme/Component/PrintJson";
import { TaxableBase } from "../admin/ConfImpuestos/TaxScheme/FuncionesTaxScheme/TaxableBase";
import { BasicButton } from "../theme/Component/BasicButton";
import { CardTaps } from "../theme/Component/CardTaps";

interface Props {
    data: any;
    event: any;
}
export const TaxBaseEditor = ({ data, event }: Props) => {

    const [invoice, setInvoice] = useState<any>({});
    const [bean, setBean] = useState<any>({});

    useEffect(() => {
        setBean({ ...data });
        let x = JSON.parse(localStorage.getItem("SimulatorInvoice")) || {};
       //console.log(x)
        setInvoice(x);
    }, [data])

    let taps = [
        { id: 0, name: 'Seleccionar Base', disable: false },
        { id: 1, name: 'Escribir Base', disable: false },
    ];

    const [indexTap, setIndexTap] = useState(0);

    const updateClick = (value: any) => {
        let uBean = bean;
        uBean.TaxableBase = value;
        event(uBean);
    }

    return (
        <div className="row">
            <div className="col-md-12">
                {/* <PrintJson json={data}/> */}
                {invoice.IDUBLInvoice ?
                    <CardTaps index={indexTap} updateIndex={setIndexTap} taps={taps}>
                        {indexTap === 0 &&
                            <TaxableBase invoice={invoice} _returnValue={(e) => { updateClick(e) }} />
                        }
                        {indexTap === 1 &&
                            <div className="row">
                                <div className="col-12">
                                    <label >
                                        Base
                                    </label>
                                </div>
                                <div className="col-12">
                                    <input type="text" className="form-control" value={bean.TaxableBase} onChange={(e) => { setBean({ ...bean, "TaxableBase": parseInt(e.target.value) }) }} />
                                </div>
                                <div className="col-md-12">
                                    <BasicButton icon="" eventClick={() => { event(bean) }}>Guardar</BasicButton>
                                </div>
                            </div>
                        }
                    </CardTaps>
                    :
                    <div className="row">
                        <div className="col-12">
                            <label >
                                Base
                            </label>
                        </div>
                        <div className="col-12">
                            <input type="text" className="form-control" value={bean.TaxableBase} onChange={(e) => { setBean({ ...bean, "TaxableBase": parseInt(e.target.value) }) }} />
                        </div>
                        <div className="col-md-12">
                            <BasicButton icon="" eventClick={() => { event(bean) }}>Guardar</BasicButton>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}