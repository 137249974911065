import { DataBean } from "../DataBean";

export class Product extends DataBean {


    public async getInventoryCatalog(prefix:any,loadRecipes:any,idStore:any): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_getInventoryCatalog_String_Boolean_Number", [prefix,loadRecipes,idStore]);
    }

 
}