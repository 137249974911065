import { useEffect, useState } from 'react';
interface Props {
    value?: string;
    icon: string;
    eventClick: any;
    clase?: string;
    children?: any;
    disable?: boolean;
}
export const BasicButton = ({ value, icon, eventClick, clase, children, disable }: Props) => {


    const [dis, setDisable] = useState(false);

    useEffect(() => {

        setDisable(disable ? disable : false);

    }, [disable])

    return (
        <button type="button" className={clase ? `btn btn-${clase} ml-2` : 'btn btn-primary ml-2'} onClick={eventClick} disabled={dis}>
            <i className={`${icon} label-icon align-middle `}></i> {value}{children}
        </button>
    )
}