import { toast } from "react-toastify";
import { _single, _UBLInvoice, _UBLItemInvoice, _UBLTaxWithHoldingTax } from "../../../../../core/services/dataBean/EntityCatalog";
import useDataBean from "../../../../../core/services/dataBean/useDataBean";
import { PrintJson } from "../../../../theme/Component/PrintJson";
import { useEffect, useState } from "react";
import { BasicTable, MASK_DATE, MASK_MONEY, MASK_NUMBER } from "../../../../theme/Component/BasicTable";
import { Modal } from "../../../../theme/Component/Modal";
import useModal, { MODAL_LG, MODAL_md, MODAL_SM, MODAL_XL } from "../../../../theme/Component/hooks/useModal";
import { ListMenu } from "../../../../theme/Component/ListMenu";
import { SelectAccountantTree } from "../../../../contabilidad/catalogo-cuentas/AccountantTree/SelectAccountantTree";
import { CostingCenterSelect } from "../../../../erp/cellEditor/CostingCenter/CostingCenterSelect";
import { DropDown } from "../../../../theme/Component/DropDown";
import { Alert } from "../../../../theme/Component/Alert";
import { TaxSchemeSimulator } from "../../../../admin/ConfImpuestos/TaxScheme/FuncionesTaxScheme/TaxSchemeSimulator";
import { BasicButton } from "../../../../theme/Component/BasicButton";
import { InputDate } from "../../../../theme/Input/InputDate";
import { ModalConfirm } from "../../../../theme/Component/ModalConfirm";
import { DocumentList, DocumentPDF, DocumentViewer } from "../../../../theme/Component/DocumentViewer";


interface Props {
    ublInvoice: any;
}
export const AccountingUBL = ({ ublInvoice }: Props) => {

    const { instance, lista, setLista, bean, setBean } = useDataBean(_UBLItemInvoice);
    const ublService = useDataBean(_UBLInvoice);
    const [pending, setPending] = useState<any>([]);
    const [recordDate, setRecordDate] = useState<any>(_single.getCurrenDate());
    const [listTax, setListTax] = useState<any>([]);
    const [document, setDocument] = useState<any>([]);
    const [documentError, setDocumentError] = useState<any>([]);
    const [beanTax, setBeanTax] = useState<any>({});
    const tax = useDataBean(_UBLTaxWithHoldingTax);
    const modalEditar = useModal("Editar Item", MODAL_md);
    const modalAddImpuestos = useModal("Impuestos", MODAL_LG);
    const modalContabilizar = useModal("Contabilizar", MODAL_md);
    const modalDelete = useModal("Confirmación", MODAL_SM);
    const modalVisor = useModal("Visor", MODAL_XL);

    useEffect(() => {
        updaleList();
    }, [ublInvoice])

    const updaleList = () => {

        getUBLItemInvoiceCatalog();

    }

    const getUBLItemInvoiceCatalog = () => {
        instance.getUBLItemInvoiceCatalog(ublInvoice.IDUBLInvoice, ublInvoice.IssueDate).then(
            (resp: any) => {
                setLista(resp);
                getUBLTaxWithHoldingTaxCatalog();
            }
        ).catch(err => toast.error(err));
    }
    const getUBLTaxWithHoldingTaxCatalog = () => {
        tax.instance.getUBLTaxWithHoldingTaxCatalog(ublInvoice.IDUBLInvoice, ublInvoice.IssueDate).then(
            (resp: any) => {
                tax.setLista(resp);
            }
        ).catch(err => toast.error(err));
    }

    const head = [
        { title: 'ID Factura', property: 'IDUBLInvoice', visible: false, mask: MASK_NUMBER },
        { title: 'Tipo Articulo', property: 'AdquisitionArticleTypeName', visible: true },
        { title: 'Nombre del Bien o Servicio', property: 'Description', visible: true },
        { title: 'Valor Unitario', property: 'UnitaryValue', visible: true, mask: MASK_MONEY },
        { title: 'Cantidad', property: 'Quantity', visible: true, mask: MASK_NUMBER },
        { title: 'Subtotal', property: 'PriceValue', visible: true, mask: MASK_MONEY },
        { title: 'Subtotal Impuestos', property: 'TaxAmount', visible: true, mask: MASK_MONEY },
        { title: 'Valor Total', property: 'TotalItemAmount', visible: true, mask: MASK_MONEY },
        { title: 'Presupuesto', property: 'BudgetName', visible: true },
        { title: 'Cuenta Contable', property: 'AccountantIDName', visible: true },
        { title: 'Centro de Costo', property: 'CostingCenterName', visible: true },

    ]
    const headTax = [
        { title: 'ID', property: 'IDTaxSchemeValue', visible: true, mask: MASK_NUMBER },
        { title: 'Tipo de Impuesto', property: 'TaxCategoryID', visible: true },
        { title: 'Impuesto', property: 'TaxCategoryName', visible: true },
        { title: 'TaxCategoryPercent', property: 'Tarifa', visible: true },
        { title: 'Unidades', property: 'ValueTypeName', visible: true },
        { title: 'Base de Impuesto', property: 'TaxableAmount', visible: true },
        { title: 'Valor Retención', property: 'TaxAmount', visible: true },
    ]


    const listaBotones = () => {
        const botones = [
            { titulo: 'Editar', icono: 'ri-edit-2-line', visible: _single.canEdit(), evento: () => modalEditar.open() },
        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }
    const listaBotonesImpuesto = () => {
        const botones = [
            { titulo: 'Eliminar', icono: 'ri-delete-bin-line', visible: _single.canEdit(), evento: () => modalDelete.open() },
        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }
    const update = () => {
        instance.updateCustonBean(bean).then(
            (resp: any) => {
                updaleList();
                modalEditar.close();
            }
        ).catch(err => toast.error(err));
    }
    const addTax = () => {
        let pending = [];
        lista.forEach(element => {
            if (
                (element.DataBeanProperties.AccountantID == undefined || element.DataBeanProperties.AccountantID == null)
                ||
                (element.DataBeanProperties.CostingCenter == undefined || element.DataBeanProperties.CostingCenter == null)
            )
                pending.push(element.DataBeanProperties);
        });
        setPending(pending);
        modalAddImpuestos.open();
    }
    const createAccountantDocumentForUBLInvoice = () => {
        if (recordDate != '') {
            ublService.instance.createAccountantDocumentForUBLInvoice(ublInvoice.IDUblInvoice, ublInvoice.IssueDate, recordDate, _single.getAccountID())
                .then(
                    (resp: any) => {
                       //console.log(resp);
                        if (resp.MsgList) {
                            setDocumentError(resp.MsgList);
                        }
                        if (resp.DocumentURL) {
                            let docs: DocumentPDF[] = [];
                            resp.DocumentURL.forEach(element => {
                                docs.push({
                                    Filename: element.DataBeanProperties.Filename,
                                    Media: element.DataBeanProperties.Media,
                                    MediaContext: element.DataBeanProperties.MediaContext,
                                    Type: element.DataBeanProperties.Type,
                                    URLLink: element.DataBeanProperties.URLLink
                                })
                            });
                            setDocument(docs);
                        }
                        modalVisor.open();
                        modalContabilizar.close();
                    }
                ).catch(err => toast.error(err));

        } else {
            toast.error("Fecha y alamcen son obligatorios");
        }
    }
    const parseTaxSchemaValuesWithHoldingTaxForUBLInvoice = () => {

        if (listTax.length > 0) {
            let tax = [];
            let cero = 0;
            listTax.forEach(element => {
                if (element.DataBeanProperties.TaxableBase == undefined)
                    cero++;

                tax.push({
                    DataBeanProperties: {
                        IDTaxSchemeValue: element.DataBeanProperties.IDTaxSchemeValue,
                        TaxBase: element.DataBeanProperties.TaxableBase,
                    },
                    DataBeanName: "com.quickdataerp.bean.admin.TaxSchemeValue"
                });
            });
            if (cero === 0) {
               //console.log(tax);

                ublService.instance.parseTaxSchemaValuesWithHoldingTaxForUBLInvoice(ublInvoice.IDUblInvoice, ublInvoice.IssueDate, tax)
                    .then(
                        (resp: any) => {
                           //console.log(resp);
                            getUBLTaxWithHoldingTaxCatalog();
                        }
                    ).catch(err => toast.error(err));

            } else {
                toast.error("No ha definido un valor base para cada impuesto");
            }
        } else {
            toast.error("No ha seleccionado ningun impuesto");

        }


    }

    // const iconButtonsTax = [
    //     { nombre: 'Agregar/Editar Impuestos', visible: _single.canCreate(), icono: 'ri-file-add-line', evento: addTax }
    // ]
    const actionsDropDown = [
        { id: 1, name: 'Contabilizar Factura', permiso: null, activo: true },
        { id: 2, name: 'Agregar/Editar Impuestos', permiso: null, activo: true },

    ];
    const buttons = () => {
        return (
            <div className="row">
                <div className="col-md-12">
                    <DropDown lista={actionsDropDown} eventClick={eventButton}></DropDown>
                </div>
            </div>
        )
    }
    const eventButton = (event: any) => {
        if (event.id === 1) {
            let pending = [];
            lista.forEach(element => {
                if (
                    (element.DataBeanProperties.AccountantID == undefined || element.DataBeanProperties.AccountantID == null)
                    ||
                    (element.DataBeanProperties.CostingCenter == undefined || element.DataBeanProperties.CostingCenter == null)
                )
                    pending.push(element.DataBeanProperties);
            });
            if (pending.length === 0) {
                modalContabilizar.open();

            } else {
                toast.error("Tiene elementos pendientes por validar");
            }
        }
        else if (event.id === 2) {
            addTax();
        }


    }
    const deleteUBLTaxWithHoldingTax = () => {
       
        ublService.instance.deleteUBLTaxWithHoldingTax(ublInvoice.IDUBLInvoice, ublInvoice.IssueDate, beanTax.IDUBLTaxWithHoldingTax).then(
            (resp: any) => {
                getUBLTaxWithHoldingTaxCatalog();
            }
        ).catch(err =>console.error(err));

    }

    return (
        <div className="row">
            <div className="col-md-12">
                <BasicTable body={lista} head={head} headButtons={buttons()} rowSet={setBean} listButtons={listaBotones()} />
            </div>
            <div className="col-md-12">
                <hr />
                <h6>Retenciones a Título de Impuestos</h6>
                <BasicTable body={tax.lista} head={headTax} rowSet={setBeanTax} listButtons={listaBotonesImpuesto()} />
            </div>
            <div className="col-md-12">
                <Modal modal={modalEditar.modal} updateModal={modalEditar.setModal} eventModal={update}>
                    {/* editar el item de la factura para agregar cuenta contable y centro de costo */}
                    <div className="row">
                        {bean.AdquisitionArticleType ? (
                            <div className="col-12">
                                <SelectAccountantTree
                                    label="Cuenta Contable"
                                    jsonCode={bean.AccountantID}
                                    onChange={(e) => { setBean({ ...bean, "AccountantID": e.JsonCode }) }}
                                />
                            </div>
                        ) : null}
                        <div className="col-12">
                            <CostingCenterSelect idSelector={bean.CostingCenter} label="Centro de Costo" onChange={(e) => { setBean({ ...bean, "CostingCenter": e }) }} />
                        </div>

                    </div>
                </Modal>
                <Modal modal={modalAddImpuestos.modal} updateModal={modalAddImpuestos.setModal} eventModal={parseTaxSchemaValuesWithHoldingTaxForUBLInvoice}>
                    {/* abrir el simulador y agregar impuestos a la factura electronica  */}
                    <div className="row">
                        {pending.length > 0 ?
                            <div className="col-md-12">
                                <Alert clase="primary" >
                                    <strong>Pendientes</strong> Tiene {pending.length} items pendientes por validar la cuenta contable y el centro de costo


                                </Alert>
                                <ul className="list-group">
                                    {pending.map((element: any, index: number) => {
                                        return (
                                            <li className="list-group-item">{element.Description}</li>
                                        )
                                    })}
                                </ul>
                            </div>
                            :
                            <>
                                <div className="col-md-12">
                                    <TaxSchemeSimulator _invoice={ublInvoice} menuComponent={false} idAccount={ublInvoice.IDAccountSupplier} _onChange={setListTax} />
                                </div>
                            </>
                        }
                    </div>
                </Modal>
                <Modal modal={modalContabilizar.modal} updateModal={modalContabilizar.setModal} eventModal={createAccountantDocumentForUBLInvoice} onSubmitLabel="Procesar">
                    {/* contabilizar la factura electronica */}
                    <div className="row">
                        <div className="col-md-12">
                            <InputDate label="Fecha de registro contable" name="date" value={recordDate} setDate={setRecordDate} />
                        </div>
                    </div>
                </Modal>

                <Modal modal={modalVisor.modal} updateModal={modalVisor.setModal} >
                    {/* visor de documentos */}
                    <div className="row">
                        <div className="col-md-12">
                            {documentError.length > 0 ?
                                // <PrintJson json={documentError} />
                                <ul className="group-list">
                                    {documentError.map((item: any, index: number) => {
                                        return (
                                            <li key={index} className="group-list-item">{item}</li>
                                        )
                                    })}
                                </ul>
                                :
                                <>
                                    {document.length > 0 &&
                                        <DocumentViewer list={document} />
                                    }
                                </>
                            }
                        </div>
                    </div>
                </Modal>

                <ModalConfirm modal={modalDelete.modal} updateModal={modalDelete.setModal} eventModal={deleteUBLTaxWithHoldingTax}>
                    {/* eliminar impuesto de la factura electronica */}
                    <p>Eliminar impuesto</p>
                </ModalConfirm>
            </div>

        </div>
    )
}