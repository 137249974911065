import { DataBean } from "../DataBean";

export class DaoInfo extends DataBean{

    public async getDaoInfoCatalog(idDaoInfo:number|null):Promise<any>
    {
        return this.getCustomService("OrangeCore","java.util.List_getDaoInfoCatalog_Number",[idDaoInfo]);
    }
    public async getDataBeanList(daoClassForName:string|null):Promise<any>
    {
        return this.getCustomService("OrangeCore","java.util.List_getDataBeanList_String",[daoClassForName]);
    }

    public async exportDataBaseAsJson(classForName:string,charset:string,classForNameList:any):Promise<any>
    {
        return this.getCustomService("OrangeCore","com.advantage.shared.Report_exportDataBaseAsJson_String_String_java.util.List",[classForName,charset,classForNameList]);
    }

    public async importJsonDataBase(media:string,mediaContext:string,dataStore:string|null,deleteData:boolean):Promise<any>
    {
        return this.getCustomService("OrangeCore","java.util.List_importJsonDataBase_String_String_String_boolean",[media,mediaContext,dataStore,deleteData]);
    }

    public async exportDataBaseAsJsonFromScriptFile(media:string,mediaContext:string,dataStore:string|null):Promise<any>
    {
        return this.getCustomService("OrangeCore","com.advantage.shared.Report_exportDataBaseAsJsonFromScriptFile_String_String_String",[media,mediaContext,dataStore]);
    }
    
    public async rebuildDaoInfo(listaDao:any):Promise<any>
    {
        return this.getCustomService("OrangeCore","Integer_rebuildDaoInfo_java.util.List",[listaDao]);
    }
    public async rebuildAppDeplyment(appDeploymentList:any):Promise<any>
    {
        return this.getCustomService("OrangeCore","Integer_rebuildAppDeplyment_java.util.List",[appDeploymentList]);
    }
    public async getWebServiceInfoCatalog(idWebServiceInfo:number):Promise<any>
    {
        return this.getCustomService("OrangeCore","java.util.List_getWebServiceInfoCatalog_Number",[idWebServiceInfo]);
    }


    
}