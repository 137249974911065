import { useEffect, useState } from "react";
import { _single, _WorkIncentives } from "../../../../core/services/dataBean/EntityCatalog";
import useDataBean from "../../../../core/services/dataBean/useDataBean";
import { BasicPage } from "../../../theme/Component/BasicPage";
import { ListMenu } from "../../../theme/Component/ListMenu";
import { BasicTable, MASK_DATE, MASK_MONEY } from "../../../theme/Component/BasicTable";
import { ModalConfirm } from "../../../theme/Component/ModalConfirm";
import { Modal } from "../../../theme/Component/Modal";
import { AccountEditor } from "../../../editor/AccountEditor";
import { InputDate } from "../../../theme/Input/InputDate";
import { FileService } from "../../../../core/services/FileService";
import { SelectTHConstantsValue } from "../../../bpm/config/Componentes/SelectTHConstantsValue";
import { BasicSwitch } from "../../../theme/Component/BasicSwitch";
import { BasicButton } from "../../../theme/Component/BasicButton";
import { PrintJson } from "../../../theme/Component/PrintJson";

interface Props {
    isUserAdmin?: boolean;
}

export const WorkIncentives = ({ isUserAdmin }: Props) => {

    const { lista, setLista, bean, setBean, instance, update, handleChange } = useDataBean(_WorkIncentives);
    const fileService = new FileService();

    const [account, setAccount] = useState<number>(NaN);

    const [modal, setModal] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalC, setModalC] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });
    const [modalA, setModalA] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });

    useEffect(() => {
        if (account) {
            getLista()
        }
    }, [account])

    const getLista = () => {
        const Props = {
            State: 1 && 3,
            IDAccount: account,
        }
        instance.getWorkIncentivesCatalogPorPropiedades(Props, null).then(
            (rta: any) => {
                rta.forEach((element) => {
                    if (element.DataBeanProperties.JobReferenceMedia) {
                        let doc = JSON.parse(element.DataBeanProperties.JobReferenceMedia);
                        if (doc.Media) {
                            element.DataBeanProperties.RespDoc = `<a href="${fileService.getUrlFile(element.DataBeanProperties.MediaContext, element.DataBeanProperties.Media)}" target='_blank'>${doc.Name ?? "Ver Documento"}</a>`
                        }
                    } else {

                    }
                });
                setLista(rta)
            }
        ).catch(
            err => { console.log(err) }
        )
    }

    const editaItem = () => {
        update().then(
            (resp) => {
                setModal({ ...modal, "view": false });
                setModalC({ ...modalC, "view": false });
                setModalA({ ...modalA, "view": false });
                getLista();
            }
        ).catch(
            err => { console.log(err) }
        )
    }

    const deleteItem = () => {
        let objet = bean;
        objet.State = 2;
        objet.IDEmployeeDelete = _single.getAccountID();
        objet.DeleteDate = _single.getCurrenDate();
        setBean(objet);

        editaItem();
    }

    const head = [
        { title: 'ID', property: 'IDWorkIncentives', visible: true },
        { title: 'Usuario', property: 'IDAccount', visible: true },
        { title: 'Tipo de Incentivo', property: 'IDAccount', visible: true },
        { title: 'Incentivo', property: 'IDTypeincentive', visible: true },
        { title: 'Fecha del Incentivo', property: 'DateIncentive', visible: true, mask: MASK_DATE },
        { title: 'Descripción del Incentivo', property: 'Description', visible: true },
        { title: 'Empleado que Registra', property: 'IDEmployeeRegister', visible: true },
        { title: 'Empleado que Otorga', property: 'IDEmployeeGrants', visible: true },
        { title: 'Estado del Incentivo', property: 'State', visible: true },

    ]

    const create = () => {
        setBean({
            State: 1,
            IDEmployeeRegister: _single.getAccountID(),
            EconomicDependence: false,
        });
        setModal({ ...modal, "view": true, "name": "Agregar Incentivo Laboral" });
    }

    const iconButtonsItem = [
        { nombre: 'Agregar', visible: _single.canCreate(), icono: 'ri-file-add-line', evento: create }
    ]

    const listaBotones = () => {
        const botones = [
            { titulo: 'Editar', icono: 'ri-edit-2-line', visible: _single.canEdit(), evento: () => { setModal({ ...modal, "view": true, "name": "Editar Incentivo Laboral" }) } },
            { titulo: 'Eliminar', icono: 'ri-delete-bin-line', visible: (_single.canDelete() && !bean.IsApproved), evento: () => { setModalC({ ...modalC, "view": true, "name": `Eliminar Incentivo Laboral` }) } },
            { titulo: 'Validar', icono: 'ri-check-line', visible: (_single.canDelete() && !bean.IsApproved), evento: () => { setModalA({ ...modalA, "view": true, "name": "Aprobar Información Academica" }) } },
        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }

    const renderName = (code: number) => {
        switch (code) {
            case 109:
                return ("Incentivos Monetarios");
            case 110:
                return ("Incentivos no Monetarios");
            case 111:
                return ("Incentivos Colectivos");
            case 112:
                return ("Incentivos Emocionales");
            case 113:
                return ("Incentivos en Especie");
            default:
                return ("N/A")
        }
    }

    const renderIncentiveType = (code: number) => {
        let title = renderName(code);

        return (
            <div className="col-md-6">
                <SelectTHConstantsValue code={code} value={bean.IDTypeincentive} onChange={(e) => { setBean({ ...bean, "IDTypeincentive": e }) }} idSelect="IDTHConstantsValue" label={`${title}`} returnType="ID" />
            </div>
        )
    }

    const validateItem = () => {
        let objet = bean;
        objet.State = 3;
        objet.IDEmployeeGrants = _single.getAccountID();
        setBean(objet);

        editaItem();
    }

    return (
        <BasicPage titulo="Incentivos Laborales" >
            <div className="row">
                <div className="col-md-6">
                    <AccountEditor idAccount={account} onChange={(e) => { setAccount(e.IDAccount) }} label="Usuario" />
                </div>
                <div className="col-md-4 mt-4">
                    <BasicButton icon="ri-search-line" eventClick={getLista} >Buscar</BasicButton>
                </div>
                <div className="col-md-12">
                    <BasicTable listButtons={listaBotones()} head={head} body={lista} rowSet={setBean} iconButtons={iconButtonsItem} />
                </div>

                <div className="col-md-12">
                    <ModalConfirm modal={modalC} updateModal={setModalC} eventModal={deleteItem}>
                        <strong>Eliminar Informacion de {bean.IDAccount}</strong>
                    </ModalConfirm>
                </div>

                <div className="col-md-12">
                    <Modal modal={modal} updateModal={setModal} eventModal={editaItem}>
                        <div className="row">
                            <div className="col-md-6">
                                <AccountEditor idAccount={bean.IDAccount} label="Empleado" onChange={({ IDAccount }) => { setBean({ ...bean, "IDAccount": IDAccount }) }} />
                            </div>
                            <div className="col-md-6">
                                <InputDate name="DateIncentive" label="Fecha del Incentivo" value={bean.DateIncentive} setDate={(e) => { setBean({ ...bean, "DateIncentive": e }) }} />
                            </div>
                            <div className="col-md-6">
                                <SelectTHConstantsValue code={108} value={bean.IDRelationshipType} onChange={(e) => { setBean({ ...bean, "IDRelationshipType": e }) }} idSelect="CodeID" label="Tipo de Incentivo" returnType="ID" />
                            </div>
                            {bean.IDRelationshipType ?
                                renderIncentiveType(bean.IDRelationshipType)
                                :
                                null
                            }
                            <div className="col-md-12">
                                <label>Descripción</label>
                                <textarea value={bean.Description} className="form-control" onChange={(e) => { setBean({ ...bean, "Description": e.target.value }) }}></textarea>
                            </div>
                        </div>
                    </Modal>
                </div>

                <div className="col-md-12">
                    <ModalConfirm modal={modalA} updateModal={setModalA} eventModal={validateItem}>
                        <strong>Aprobar Otorgar Incentivo {bean.IDTypeincentive}</strong>
                    </ModalConfirm>
                </div>

            </div>
        </BasicPage>
    )
}



