import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { _FixedAsset, _UBLItemInvoice } from "../../../core/services/dataBean/EntityCatalog";
import useDataBean from "../../../core/services/dataBean/useDataBean";
import { GoodTypesConstantsEditor } from "../../contract/etapa-contrato/contrac/ublinvoice/CreateUBLInvoice/GoodTypesConstants.editor";
import { Alert } from "../../theme/Component/Alert";
import { toast } from "react-toastify";
import { FixedAssetEditor } from "../fixedAssets/FixedAssetEditor";
import { ProductEditor } from "../../theme/Component/ProductEditor";

interface Props {
    _itemInvoice: any;
    _onChange?: Function;
}


export const AssetClassfierEditor = forwardRef(({ _itemInvoice, _onChange }: Props, ref) => {

    const fixedAssets = useDataBean(_FixedAsset);

    let [typeClass, setTypeClass] = useState<number>(null);
    const [asset, setAsset] = useState<any>({})

    const assetEditor = useRef<any>(null);

    useEffect(() => {
        if (_itemInvoice.AssetType) {
            setTypeClass(_itemInvoice.AssetType);
        }
    }, [_itemInvoice])

    useEffect(() => {
        if (typeClass === 5) {
            if (_itemInvoice.IDFixedAsset) {
                getLista();
            } else {
                getFixedAsset();
            }
        } else if (typeClass == 6) {

        } else if (typeClass == 32) {

        }
    }, [typeClass]);

    const getLista = () => {
        fixedAssets.instance.getFixedAsset(_itemInvoice.IDFixedAsset).then(
            (resp) => {
                setAsset(resp)
            }
        ).catch(
            err => {
               console.error(err);
                toast.error("Aglo Salio Mal");
            }
        )
    }

    const getFixedAsset = () => {
       //console.log(_itemInvoice)
        fixedAssets.instance.getFixedAssetByUBLInvoice(_itemInvoice.IDUBLInvoice, _itemInvoice.IssueDate, _itemInvoice.ItemID).then(
            (resp) => {
                setAsset(resp);
            }
        ).catch(
            err => {
               console.error(err);
                toast.error("Hubo un Inconveniente");

            }
        )
    }

    const updateItemProduct = (selected: any) => {
        let item = _itemInvoice;

        if (selected.IDProduct) {
            if (typeClass && typeClass === 6) {
                item.IDProduct = selected.IDProduct;
                item.AssetType = typeClass;
            }
            _onChange(item)
        } else {
            toast.error("No se pudo seleccionar el objeto correctamente");
        }
    }

    useImperativeHandle(ref, () => ({
        typeClass,
        validateData: () => {
            if (assetEditor.current && assetEditor.current.bean.IDFixedAsset) {
                return assetEditor.current.validateData();
            } else {
                return false;
            }
        },
        updateObject: () => {
            if (assetEditor.current && assetEditor.current.bean && assetEditor.current.bean.IDFixedAsset) {
                let updatedItem = { ..._itemInvoice };
                updatedItem.IDFixedAsset = assetEditor.current.bean.IDFixedAsset;
                updatedItem.AssetType = typeClass;

                return updatedItem;
            } else {
                toast.error("No se pudo obtener el activo fijo seleccionado correctamente.");
                return null;
            }
        },
    }));


    return (
        <div className="row">
            <div className="col-12">
                <GoodTypesConstantsEditor label="Clasificar Bien Como:" idSelector={typeClass} onChange={(e) => { setTypeClass(parseInt(e)) }} />
            </div>

            <div className="col-12">
                {typeClass == 5 &&
                    <>
                        <hr />
                        <FixedAssetEditor fixedAsset={asset} ref={assetEditor} _onChange={(e) => {  }} />
                    </>
                }
                {typeClass == 6 &&
                    <>
                        <label >Buscar y Asociar Producto de Inventario</label>
                        <ProductEditor UBLItemInvoice={_itemInvoice} _onChange={(e) => { updateItemProduct(e) }} />
                    </>
                }
                {typeClass == 32 &&
                    <Alert clase="danger text-center" >Opcion no disponible</Alert>
                }
            </div>
        </div>
    );
});