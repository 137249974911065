import { useEffect, useState } from "react";
import { _BudgetMovementFactory, _BudgetObligationFactory, _single } from "../../../core/services/dataBean/EntityCatalog";
import useDataBean from "../../../core/services/dataBean/useDataBean";
import { toast } from "react-toastify";
import { BasicTable } from "../../theme/Component/BasicTable";
import { ListMenu } from "../../theme/Component/ListMenu";
import { Modal } from "../../theme/Component/Modal";
import { InputMoney } from "../../theme/Input/InputMoney";
import { SelectBudgetID } from "../../admin/configuracion/BudgetID/SelectBudgetID";
import { MASK_MONEY } from "../../theme/Component/BasicTable2";
import { PrintJson } from "../../theme/Component/PrintJson";

interface Props {
    formData: Record<string, any>;
    onChange: Function;
}

export const RegisterBudgetReductionRPValue = ({ onChange, formData }: Props) => {

    const { bean, setBean, lista, setLista, instance } = useDataBean(_BudgetObligationFactory);
    const [initBean, setInitBean] = useState<any>({});
    const [missingFields, setMissingFields] = useState<string[]>([]);

    const [modal, setModal] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });

    useEffect(() => {
        getLista();
    }, [formData.RPSelected])

    const getLista = () => {
        instance.getRPBudgetForObligation(formData.RPSelected.IDDocument, formData.RPSelected.DocumentDate).then(
            (resp) => {
                setLista(resp);
            }
        ).catch(
            err => { toast.error(err) }
        )
    }

    const head = [
        { title: 'Rubro Presupuestal', property: 'BudgetName', visible: true },
        { title: 'Recurso Presupuestal', property: 'BudgetResourceName', visible: true },
        { title: 'Vr Compromiso', property: 'RPValue', visible: true },
        { title: 'Vr Obligado', property: 'OBValue', visible: true },
        { title: 'Saldo x Obligar', property: 'LeftValue', visible: true },
        { title: 'Observaciones', property: 'Description', visible: true },
        { title: 'Valor a Reducir', property: 'Value', visible: true },
    ]

    useEffect(() => {
        onChange(lista);
    }, [lista])

    const updateItem = () => {

        const updatedLista = lista.filter((item: any) =>
            JSON.stringify(item.DataBeanProperties) !== JSON.stringify(initBean)
        );

        let temp = [...updatedLista, {
            DataBeanProperties: bean,
            DataBeanName: 'budget.Budget'
        }];
        setLista(temp);
        setBean({});
        setInitBean({});
        setModal({ ...modal, "view": false })
    }

    const listaBotones = () => {
        const botones = [
            { titulo: 'Editar', icono: 'ri-edit-line', visible: _single.canEdit(), evento: () => { setModal({ ...modal, "view": true, "name": "Editar" }) } },
        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }

    const setRowBean = (object: any) => {
        setBean(object);
        setInitBean(object);
    }

    return (
        <div className="row">
            <div className="col-md-12">
                <BasicTable body={lista} head={head} rowSet={(e) => { setRowBean(e) }} listButtons={listaBotones()} />
            </div>

            <div className="col-md-12">
                <Modal modal={modal} updateModal={setModal} eventModal={updateItem}>
                    <div className="row">
                        <div className="col-md-6">
                            <label>Valor a Reducir</label>
                            <InputMoney id={0} name="Value" value={bean.Value} onChange={(e) => { setBean({ ...bean, "Value": e }) }} />
                        </div>
                        <div className="col-md-12">
                            <label>Observaciones</label>
                            <textarea className="form-control" value={bean.Description} onChange={(e) => { setBean({ ...bean, "Description": e.target.value }) }} />
                        </div>
                    </div>
                </Modal>
            </div>
        </div>
    )
}