import { useEffect, useState } from "react";
import { _SupplierPaymentOrder } from "../../../../core/services/dataBean/EntityCatalogVdos"
import useDataBean from "../../../../core/services/dataBean/useDataBean"
import { YearSelector } from "../../../../core/shared/inputs/YearSelector";
import { AccountEditor } from "../../../editor/AccountEditor";
import { BasicButton } from "../../../theme/Component/BasicButton";
import { BasicPage } from "../../../theme/Component/BasicPage"
import { PrintJson } from "../../../theme/Component/PrintJson";
import { _single } from "../../../../core/services/dataBean/EntityCatalog";
import { BasicTable, MASK_MONEY, MASK_NUMBER } from "../../../theme/Component/BasicTable";

export const OrderByReview =()=>{

    const {bean,setBean,instance,handleChange,lista,setLista} =  useDataBean(_SupplierPaymentOrder);
    const [row,setRow] = useState<any>({});
    
    useEffect(()=>{
        setBean({
            Year:_single.getCurrenYear()
        })
    },[])

    const getSupplierPaymentOrderCatalogForHiring=()=>{
        instance.getSupplierPaymentOrderCatalogForHiring(bean.IDAccount,bean.Year).then(
            (resp:any)=>{
                setLista(resp);
            }
        )
    }
    const head = [
        {title:'ID',property:'IDSupplierPaymentOrder',visible:true,mask:MASK_NUMBER},
        {title:'Fecha Orden',property:'SupplierPaymentOrderDate',visible:true},
        {title:'Contratante',property:'ContractName',visible:true}    ,    
        {title:'No. Contrato',property:'InternalCode',visible:true}   ,     
        {title:'Fecha Contrato',property:'AdquisitionContractDate',visible:true}  ,      
        {title:'Objeto del Contrato',property:'Justification',visible:true}  ,      
        {title:'Valor del Contrato',property:'ContractValue',visible:true,mask:MASK_MONEY}  ,      
        {title:'Valor de la Orden de Pago',property:'Value',visible:true,mask:MASK_MONEY}  ,      
        {title:'Posición de la Orden',property:'OrderStateName',visible:true}  ,      
        {title:'Estado de la Orden',property:'StateName',visible:true}  ,      
        {title:'Empleado',property:'EmployeeName',visible:true}  ,      
    ]
    return(
       <BasicPage titulo="Ordenes de pago por gestionar">
         <div className="row">
            <div className="col-md-4">
                <AccountEditor idAccount={bean.IDAccount} label="Proveedor" onChange={({IDAccount}:any)=>{setBean({...bean,"IDAccount":IDAccount})}}/>
            </div>
            <div className="col-md-4">
                <YearSelector onChange={(e)=>{setBean({...bean,"Year":e})}}/>
            </div>
            <div className="col-md-4 mt-4">
                <BasicButton icon="" eventClick={getSupplierPaymentOrderCatalogForHiring}>Buscar</BasicButton>
            </div>
            <div className="col-md-12">                
                <BasicTable body={lista} head={head} rowSet={setRow}></BasicTable>
            </div>
        </div>
       </BasicPage>
    )
}