import { FinanceService } from "../../core/services/FinanceService";
import { useState, useEffect } from 'react';
import { BasicTable, MASK_MONEY, MASK_NUMBER } from "../theme/Component/BasicTable";
import { BasicButton } from "../theme/Component/BasicButton";
import { ModalConfirm } from "../theme/Component/ModalConfirm";
import { Modal } from "../theme/Component/Modal";
import { SelectBudgetID } from "../admin/configuracion/BudgetID/SelectBudgetID";
import { ListMenu } from "../theme/Component/ListMenu";
import { useDashboardStore } from "../pages/hooks/useDashboardStore";
import { Singleton } from "../../core/services/Singleton";
import { BudgetResourceEditor } from "../admin/configuracion/BudgetResource/BudgetResource.Editor";
import { _single } from "../../core/services/dataBean/EntityCatalog";

interface Props {
    adquisitionItem: any;
    iDBudgetIDType: number;
    CanModify?: number;
    estimateValue?: number;
    onChange?:any;
}
export const AdquisitionItemBudgetID = ({ adquisitionItem, iDBudgetIDType, CanModify, estimateValue ,onChange}: Props) => {


    const service = new FinanceService();
    const single = Singleton.getInstance()
    const { setLoading } = useDashboardStore();
    const [lista, setLista] = useState([]);
    const [bean, setBean] = useState<any>({});

    const [modal, setModal] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalConfirm, setModalConfirm] = useState<any>({ name: 'Confirmar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });

    const idBean = "IDAdquisitionItemBudgetID";

    useEffect(() => {

        getLista();
    }, [adquisitionItem.IDAdquisitionItem])

    const getLista = () => {
        _single.spinner(true);
        service.getAdquisitionItemBudgetIDCatalog(adquisitionItem.IDAdquisitionItem).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue) {
                    setLista(resp.DataBeanProperties.ObjectValue);
                   
                }
                _single.spinner(false);
            }
        );
    }
    const setRow = (b: any) => {
       //console.log(b);

        setBean(b);
    }
    const agregarItem = () => {
        let b = {
            IDLnBudgetID: 0,

            EstimatedValue: estimateValue ?? 0,
            Description: '',
            IDEmployee: single.getAccountID(),
            Since: single.getCurrenDate(),
            IDAdquisitionItem: adquisitionItem.IDAdquisitionItem,
        }
        setBean(b);
        setModal({ ...modal, ['view']: true, 'name': 'Agregar Rubro' });
    }
    const updateItem = () => {

        setModal({ ...modal, ['view']: false });

        setLoading(true);
        service.updateAdquisitionItemBudgetID(bean).subscribe(
            (resp: any) => {
                setLoading(false);
                if (resp.DataBeanProperties.ObjectValue) {
                    getLista();
                }
                if(onChange)
                    onChange(true);
            }
        );

    }
    const editarItem = () => {
        setModal({ ...modal, ['view']: true, 'name': 'Editar Rubro' });

    }
    const eliminarItem = () => {
        setLoading(true);
        service.deleteAdquisitionItemBudgetID(bean[idBean]).subscribe(
            (resp: any) => {
                setLoading(false);
                if (resp) {
                    getLista();
                }
                single.delete(bean[idBean], bean);
            }
        );
        setModalConfirm({ ...modalConfirm, ['view']: false });
    }

    const head = [
        { title: 'ID', property: idBean, mask: MASK_NUMBER },
        { title: 'Rubro Presupuestal', property: 'IDLnBudgetIDName' },
        { title: 'Recurso', property: 'BudgetResourceName' },
        { title: 'Valor Estimado', property: 'EstimatedValue', mask: MASK_MONEY },
        // { title: 'Valor Estructurado', property: 'StructuredValue', mask: MASK_MONEY },
        { title: 'Funcionario', property: 'StructurerAccountName',visible:false },
        { title: 'Observaciones', property: 'Description' },
    ]

    const renderButton = () => {
        return (
            <>
                <div className="row">
                    <div className="col-md-12">
                        <BasicButton value="Agregar" icon="ri-file-add-line" eventClick={agregarItem} ></BasicButton>
                        {bean[idBean] &&
                            <>
                                <BasicButton value="Editar" icon="ri-edit-2-line" eventClick={editarItem} ></BasicButton>
                                <BasicButton value="Eliminar" icon="ri-eraser-line" eventClick={() => { setModalConfirm({ ...modalConfirm, ['view']: true }); }} ></BasicButton>
                            </>
                        }
                    </div>
                </div>
            </>
        )
    }

    const iconButtons = [
        { nombre: 'Agregar', visible: (single.canCreate()), icono: 'ri-file-add-line', evento: agregarItem }
        // { nombre: 'Agregar', visible: (single.canCreate() && (CanModify === 30 || CanModify === 7 || CanModify == null)) , icono: 'ri-file-add-line', evento: agregarItem }
    ]
    const listaBotones = () => {
        const botones = [
            { titulo: 'Editar', icono: 'ri-edit-2-line', visible: (single.canEdit() && (CanModify === 30 || CanModify === 7 || CanModify == null)), evento: editarItem },
            {
                titulo: 'Eliminar', icono: 'ri-eraser-line', visible: single.canDelete()
                // && (CanModify === 30 || CanModify === 7 || CanModify == null)
                , evento: () => { setModalConfirm({ ...modalConfirm, ['view']: true }) }
            },
        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }

    return (
        <>

            <div className="row mt-4">

                <div className="col-md-12">
                    <BasicTable iconButtons={iconButtons} listButtons={listaBotones()} head={head} body={lista} rowSet={setRow}></BasicTable>

                    <ModalConfirm modal={modalConfirm} updateModal={setModalConfirm} eventModal={eliminarItem}>
                        {bean.Description}
                    </ModalConfirm>

                    <Modal modal={modal} updateModal={setModal} eventModal={updateItem}>
                        <div className="row">
                            <div className="col-md-12">
                                <SelectBudgetID edit={false} idBudgetID={bean.IDLnBudgetID} tipoPresupuesto={iDBudgetIDType} onChange={(e: any) => {
                                    setBean({ ...bean, "IDLnBudgetID": e.IDLn });
                                }} />
                            </div>
                            <div className="col-md-6">
                                <label>Valor Estimado</label>
                                {/* <InputMoney name="" id={null} onChange={(e) => { setBean({ ...bean, "EstimatedValue": e.target.value }) }} value={bean.EstimatedValue} /> */}
                                <input type="number" className="form-control" value={bean.EstimatedValue} onChange={(e) => { setBean({ ...bean, "EstimatedValue": e.target.value }) }} />
                            </div>
                            <div className="col-md-6">
                                <BudgetResourceEditor label="Tipo de Recurso" idSelector={bean.IDBudgetResource} onChange={(e) => { setBean({ ...bean, "IDBudgetResource": e }) }} />
                            </div>
                            <div className="col-md-12">
                                <label className="form-label">Observaciones</label>
                                <textarea className="form-control" value={bean.Description} onChange={(e) => { setBean({ ...bean, ['Description']: e.target.value }) }}></textarea>
                            </div>
                        </div>
                    </Modal>
                </div>
            </div>
        </>
    )
}