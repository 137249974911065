import { useEffect, useState } from "react";
import useDataBean from "../../../../core/services/dataBean/useDataBean";
import { Singleton } from "../../../../core/services/Singleton";
import { toast } from "react-toastify";
import { ListMenu } from "../../../theme/Component/ListMenu";
import { _FunctionalArea } from "../../../../core/services/dataBean/EntityCatalog";
import { BasicPage } from "../../../theme/Component/BasicPage";
import { SelectFunctionalID } from "../functionalID/SelectFunctionalID";
import { AppointmentEditor } from "../../../Talentohumano/ParametrosTalentoHumano/Appointment/Appointment.editor";
import { ConstractStateEditor } from "../../../Talentohumano/GestionTH/EmployeeContract/ComponentsContract/EditorsEmployeContrac/ConstractState.editor";
import { FunctionalAreeaStateEditor } from "./FunctionalAreeaState.editor";
import { BasicTable, MASK_DATE, MASK_NUMBER } from "../../../theme/Component/BasicTable";
import { BasicButton } from "../../../theme/Component/BasicButton";
import { Modal } from "../../../theme/Component/Modal";
import { ModalConfirm } from "../../../theme/Component/ModalConfirm";
import { SelectSiteID } from "../SiteID/SelectSiteID";
import { AppointmentManualEditor } from "../../../Talentohumano/ParametrosTalentoHumano/AppointmentManual/AppointmentManual.editor";
import { AppointmentGroupManualEditor } from "../../../Talentohumano/ParametrosTalentoHumano/AppointmentGroupManual/AppointmentGroupManual.editor";
import { ARPRiskEditor } from "../../../Talentohumano/GestionTH/EmployeeContract/ComponentsContract/EditorsEmployeContrac/ARPRisk.editor";
import { EmploymentTypeEditor } from "../../../Talentohumano/GestionTH/EmployeeContract/ComponentsContract/EditorsEmployeContrac/EmploymentType.editor";
import { EmployeeContractTypeEditor } from "../../../Talentohumano/GestionTH/EmployeeContract/ComponentsContract/EditorsEmployeContrac/EmployeeContractType.editor";
import { CostingCenterSelect } from "../../../erp/cellEditor/CostingCenter/CostingCenterSelect";

export const FunctionalArea = () => {

    const functionalArea = useDataBean(_FunctionalArea);
    const single = Singleton.getInstance();
    const [lista, setLista] = useState<any>([]);
    const [modal, setModal] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalC, setModalC] = useState<any>({ name: 'Eliminar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });

    const [group, setGroup] = useState<number>(null);
    const [state, setState] = useState<number>(3);
    const [contracState, setcontracState] = useState<number>(null);
    const [appointment, setAppointment] = useState<number>(null);
    const [funtional, setFuntional] = useState<number>(null);
    const [name, setName] = useState<string>(null);
    const [missingFields, setMissingFields] = useState<string[]>([]);


    const getLista = () => {
        functionalArea.instance.getFunctionalAreaCatalog(state, name, funtional, appointment, contracState).then(
            (resp) => {
               //console.log(resp.length)

                setLista(resp)

            }
        )
    }

    const create = () => {
        functionalArea.setBean({
            State: 3,
        })
        setModal({ ...modal, "name": "Agregar Plaza Laboral", "view": true })
    }

    const deleteItem = () => {
        functionalArea.deleteItemVoid(functionalArea.bean.IDLn).then(
            (resp) => {
               //console.log(resp)
                setModalC({ ...modalC, "view": false })
                getLista();
            }
        )
    }

    const editaItem = () => {
        let x = functionalArea.bean;
        x.IDEmployeeLastUpdate = single.getAccountID();
       //console.log(x)
        functionalArea.update().then(
            (resp) => {
               //console.log(resp)
                getLista()
                setModal({ ...modal, "view": false })
            }
        )
    }

    const head = [
        { title: 'ID', property: 'IDLn', mask: MASK_NUMBER, visible: true },
        { title: 'Código', property: 'Code', mask: MASK_NUMBER, visible: true },
        { title: 'Nombre', property: 'Name', visible: true },
        { title: 'Descripción', property: 'Description', visible: true },
        { title: 'Nivel Jerárquico', property: 'EntailmentName', visible: true },
        { title: 'Nivel Salarial', property: 'AppointmentName', visible: true },
        { title: 'Ubicación Geográfica de la Plaza', property: 'SiteIDName', visible: true },
        { title: 'Manual de Perfil y Funciones del Cargo', property: 'AppointmentManualName', visible: true },
        { title: 'Centro de Costo', property: 'CostingCenterCode', visible: true },
        { title: 'Riesgo Laboral', property: 'ARPRiskName', visible: true },
        { title: 'Unidad Organizacional', property: 'FunctionalIDName', visible: true },
        { title: 'Estado', property: 'StateName', visible: true },
        { title: 'IDContrato Laboral', property: 'IDContract', mask: MASK_NUMBER, visible: true },
        { title: 'Empleado', property: 'AccountName', visible: true },
        { title: 'Fecha Inicio', property: 'InitContract', visible: true },
        { title: 'Fin del Contrato', property: 'UptoContract', visible: true },
        { title: 'Estado del Contrato', property: 'ContractStateName', visible: true },
        { title: 'Creado por', property: 'EmployeeName', visible: true },
        { title: 'Fecha de Creación', property: 'Since', mask: MASK_DATE, visible: true },
        { title: 'Última actualización', property: 'LastUpdateEmployeeName', visible: true },
        { title: 'Fecha Última Actualización', property: 'LastUpdate', visible: true },
    ]

    const iconButtonsItem = [
        { nombre: 'Agregar', visible: single.canCreate(), icono: 'ri-file-add-line', evento: create }
    ]
    const listaBotones = () => {
        const botones = [
            { titulo: 'Editar', icono: 'ri-edit-2-line', visible: single.canEdit(), evento: () => { setModal({ ...modal, "name": `Editar Plaza Laboral`, "view": true }) } },
            { titulo: 'Eliminar', icono: 'ri-delete-bin-line', visible: single.canDelete(), evento: () => { setModalC({ ...modalC, "view": true }) } },

        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }

    const validateData = () => {
        if (validateForm() > 0) {
            toast.error("Faltan campos obligatorios por completar");
            return;
        } else {
            editaItem();
        }
    }

    const validateForm = () => {
        const requiredFields = [
            "Name", "IDAppointment", "IDLnSiteID", "IDLnFunctionalID",
        ];

        let missing = [];
        for (let field of requiredFields) {
            if (!functionalArea.bean[field]) {
                missing.push(field);
            }
        }

        //console.log(missing)
        setMissingFields(missing);
        return missing.length
    };

    return (
        <>
            <BasicPage titulo="Plazas/Puestos Laborales">
                <div className="row">
                    <div className="col-4">
                        <FunctionalAreeaStateEditor label="Estado" idSelector={state} onChange={(e) => { setState(e) }} />
                    </div>
                    <div className="col-4">
                        <label>Nombre de la plaza</label>
                        <input type="text" className="form-control" value={name} onChange={(e) => { setName(e.target.value) }} />
                    </div>
                    <div className="col-4">
                        <SelectFunctionalID label="Unidad Organizacional" onChange={(e) => { setFuntional(e.IDLn) }} />
                    </div>
                    <div className="col-4">
                        <AppointmentEditor label="Nivel Salarial" idSelector={appointment} onChange={(e) => { setAppointment(e) }} />
                    </div>
                    <div className="col-4">
                        <ConstractStateEditor idSelector={contracState} onChange={(e) => { setcontracState(e) }} />
                    </div>
                    <div className="col-12">
                        <BasicButton icon="" eventClick={getLista}>Buscar</BasicButton>
                    </div>
                </div>
                <div className="row">
                    <BasicTable iconButtons={iconButtonsItem} listButtons={listaBotones()} body={lista.reverse()} head={head} rowSet={functionalArea.setBean} />
                </div>
            </BasicPage>

            {modal.view &&
                <Modal modal={modal} updateModal={setModal} eventModal={validateData} >
                    <div className="row">
                        <div className="col-6">
                            <label>Código</label>
                            <input type="number" name="Code" className="form-control" value={functionalArea.bean.Code} onChange={functionalArea.handleChange} />
                        </div>
                        <div className="col-6">
                            <label className={`${missingFields.includes('Name') ? 'has-error' : ''}`}>Nombre</label>
                            <input type="text" name="Name" className="form-control" value={functionalArea.bean.Name} onChange={functionalArea.handleChange} />
                        </div>
                        <div className="col-12">
                            <label>Descripción</label>
                            <textarea name="Description" className="form-control" value={functionalArea.bean.Description} onChange={functionalArea.handleChange} />
                        </div>
                        <div className="col-6">
                            <label className={`${missingFields.includes('IDAppointment') ? 'has-error' : ''}`}>Nivel Salarial</label>
                            <AppointmentEditor seeMoney label=" " idSelector={functionalArea.bean.IDAppointment} onChange={(e) => { functionalArea.setBean({ ...functionalArea.bean, "IDAppointment": e }) }} />
                        </div>
                        <div className="col-6">
                            <label className={`${missingFields.includes('IDLnSiteID') ? 'has-error' : ''}`}>Ciudad</label>
                            <SelectSiteID edit idLnSite={functionalArea.bean.IDLnSiteID} label=" " onChange={(e) => { functionalArea.setBean({ ...functionalArea.bean, "IDLnSiteID": e.IDLn }) }} />
                        </div>
                        <div className="col-6">
                            <AppointmentGroupManualEditor idSelector={group} onChange={(e) => { setGroup(e) }} />
                        </div>
                        <div className="col-6">
                            <AppointmentManualEditor appoGroup={group} idSelector={functionalArea.bean.IDAppointmentManual} onChange={(e) => { functionalArea.setBean({ ...functionalArea.bean, "IDAppointmentManual": e }) }} />
                        </div>
                        <div className="col-6">
                            <CostingCenterSelect label="Centro de Costo" idSelector={functionalArea.bean.CostingCenterCode} onChange={(e) => { functionalArea.setBean({ ...functionalArea.bean, "CostingCenterCode": e }) }} />
                        </div>
                        <div className="col-6">
                            <label className={`${missingFields.includes('IDLnFunctionalID') ? 'has-error' : ''}`}>Área Funcional</label>
                            <SelectFunctionalID label=" " functionalIDLn={functionalArea.bean.IDLnFunctionalID} onChange={(e) => { functionalArea.setBean({ ...functionalArea.bean, "IDLnFunctionalID": e.IDLn }) }} />
                        </div>
                        <div className="col-6">
                            <ARPRiskEditor idSelector={functionalArea.bean.ARPRisk} onChange={(e) => { functionalArea.setBean({ ...functionalArea.bean, "ARPRisk": e }) }} />
                        </div>
                        <div className="col-6">
                            <EmploymentTypeEditor idSelector={functionalArea.bean.IDEmploymentType} onChange={(e) => { functionalArea.setBean({ ...functionalArea.bean, "IDEmploymentType": e }) }} />
                        </div>
                        <div className="col-6">
                            <EmployeeContractTypeEditor idSelector={functionalArea.bean.IDEmployeeContractType} onChange={(e) => { functionalArea.setBean({ ...functionalArea.bean, "IDEmployeeContractType": e }) }} />
                        </div>
                        {/* <PrintJson json={functionalArea.bean} /> */}
                    </div>
                </Modal>
            }

            {modalC.view &&
                <ModalConfirm modal={modalC} updateModal={setModalC} eventModal={deleteItem}>
                    Esta seguro de eliminar {functionalArea.bean.Name}
                </ModalConfirm>
            }
        </>
    )
}