import { BasicPage } from '../../../theme/Component/BasicPage';
import { BasicTable, MASK_MONEY, MASK_NUMBER } from '../../../theme/Component/BasicTable';
import { useEffect, useState } from 'react';
import { Singleton } from '../../../../core/services/Singleton';
import { Modal } from '../../../theme/Component/Modal';
import { ListMenu } from '../../../theme/Component/ListMenu';
import { MyEditor } from '../../../theme/Component/MyEditor';
import { CompanyEditor } from '../Company/CompanyEditor';
import { OfficeEditor } from '../Office/OfficeEditor';
import { BankService } from './CashDeskAccount-Service';
import { toast } from 'react-toastify';
import { AccountEditor } from '../../../editor/AccountEditor';
import { ModalConfirm } from '../../../theme/Component/ModalConfirm';
import { SelectAccountantTree } from '../../../contabilidad/catalogo-cuentas/AccountantTree/SelectAccountantTree';
import { PrintJson } from '../../../theme/Component/PrintJson';
import { BakingEntityEditor } from '../BankingEntity/BankingEntitySelect';
import { CashDeskAccountSelector } from './CashDeskAccountSelector';


export const CashDeskAccount = () => {

    const single = Singleton.getInstance()
    const [bean, setBean] = useState<any>({});
    const [lista, setLista] = useState<any>([]);
    const [conpany, setCompany] = useState<number>(null);
    const [office, setOffice] = useState<number>(null);
    const [modal, setModal] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: "modal-lg", });
    const [modalConfirm, setModalConfirm] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: "modal-sm", });
    const service = new BankService();
    const idBean = 'IDCashDeskAccount';

    const head = [
        { title: 'ID', property: idBean, mask: MASK_NUMBER },
        { title: 'Nombre', property: 'Name' },
        { title: 'Responsable de la Caja', property: 'AccountName' },
        { title: 'Valor de la Base', property: 'BaseValue', mask: MASK_MONEY },
        { title: 'Caja Destino', property: 'DestinityCash' },
        { title: 'Cuenta Contable', property: 'AccountantIDName' },
        { title: 'Creado por', property: 'EmployeeName' },
        { title: 'Oficina a la que pertenece', property: 'OfficeName' },
    ]

    const setRowBean = (bean: any) => {
        setBean(bean);
       //console.log(bean)
    }

    // useEffect(() => {
    //     setBean({ ...bean, 'IDOffice': office })

    //     if (office != null && !isNaN(office)) {
    //         ////console.log(office);
    //         getLista();
    //     }
    // }, [conpany])
    useEffect(() => {
        setBean({ ...bean, 'IDOffice': office })

        if (office != null && !isNaN(office)) {
            ////console.log(office);
            getLista();
        }
    }, [office])

    const getLista = () => {
        service.spinON();
        service.getCashDeskAccountCatalog(office, null).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ErrorMessage) {
                    toast.error(resp.DataBeanProperties.ErrorMessage);
                }
                else if (resp.DataBeanProperties.ObjectValue) {
                    setLista(resp.DataBeanProperties.ObjectValue);
                }
                service.spinOFF();
            }
        )
    }

    const eliminarItem = () => {
        service.spinON();
       //console.log(bean.IDCashDeskAccount)
        service.deleteCashDeskAccount(bean.IDCashDeskAccount).subscribe(
            (resp: any) => {
                service.spinOFF();
                if (resp) {
                    getLista();
                }
                single.delete(bean.IDCashDeskAccount, bean);
            }
        );
        setModalConfirm({ ...modal, "view": false });
    }

    const updateItem = () => {
        service.spinON();
        service.updateCashDeskAccount(bean).subscribe((resp: any) => {
            service.spinOFF();
            if (resp.DataBeanProperties.ObjectValue) {
                getLista();
            }
            single.update(bean);
        });
        setModal({ ...modal, "view": false });
        ////console.log(bean);
    };

    const agregarItem = () => {
        let b = {
            BaseValue: 0,
            IDOffice: office,
            IDEmployee: single.getAccountID(),
        };
        setBean(b);
        setModal({ ...modal, "view": true, name: "Agregar" });
    };

    const iconButtonsItem = [
        { nombre: "Agregar", visible: single.canCreate(), icono: "ri-file-add-line", evento: agregarItem },
    ];

    const listaBotones = () => {
        const botones = [
            { titulo: "Editar", icono: "ri-file-list-line", visible: single.canEdit(), evento: () => { setModal({ ...modal, view: true, name: "Editar" }); }, },
            { titulo: "Eliminar", icono: "ri-file-user-line", visible: single.canDelete(), evento: () => { setModalConfirm({ ...modalConfirm, view: true, name: `Eliminar ${bean.Name}` }); }, },
        ];
        return <ListMenu listaBotones={botones} />;
    };

    return (
        <BasicPage titulo='Cajas Menores'>
            <div className="row">
                <div className="col-6">
                    <CompanyEditor id={conpany} onchange={(e: any) => { setCompany(e);  }}></CompanyEditor>
                </div>
                <div className="col-6">
                    <OfficeEditor key={conpany} id={office} idconpany={conpany} onchange={(e: any) => { setOffice(e) }}></OfficeEditor>
                </div>
            </div>
            <div className="row mt-3">
                {office ?
                    <>
                        <div className="col">
                            <BasicTable listButtons={listaBotones()} iconButtons={iconButtonsItem} body={lista} head={head} rowSet={setRowBean} ></BasicTable>
                        </div>
                    </>
                    :
                    <>
                        <div className="alert alert-warning text-center">
                            Debe seleccionar una oficina
                        </div>
                    </>
                }
            </div>

            <ModalConfirm modal={modalConfirm} updateModal={setModalConfirm} eventModal={eliminarItem}>
                {bean.Name}
            </ModalConfirm>

            <Modal modal={modal} updateModal={setModal} eventModal={updateItem}>
                <div className="row">
                    <div className="col-md-6">
                        <label >Nombre:</label>
                        <input type="text" className="form-control" value={bean.Name} onChange={(e) => { setBean({ ...bean, "Name": e.target.value }); }} />
                    </div>
                    <div className="col-md-6">
                        <SelectAccountantTree label='Cuenta Débito' jsonCode={bean.AccountantIDStr} onChange={(e) => { setBean({ ...bean, "AccountantIDStr": e.JsonCode }) }} />
                    </div>
                    <div className="col-md-6">
                        <AccountEditor idAccount={bean.IDAccount} label='Responsable de la Caja:' onChange={({ IDAccount }: any) => { setBean({ ...bean, "IDAccount": IDAccount }) }} />
                    </div>
                    <div className="col-md-6">
                        <label >Valor de la Base:</label>
                        <input type="number" className="form-control" value={bean.BaseValue} onChange={(e) => { setBean({ ...bean, "BaseValue": e.target.value }); }} />
                    </div>
                    <div className="col-md-6">
                        {/* <input type="number" className="form-control" value={bean.DestinityCash} onChange={(e) => { setBean({ ...bean, "DestinityCash": e.target.value }); }} /> */}
                        <CashDeskAccountSelector id={bean.DestinityCash} onchange={(e) => { setBean({ ...bean, "DestinityCash": e }) }} label='Caja Destino' />
                    </div>
                    <div className="col-md-6">
                        <BakingEntityEditor label='Banco de destino' idSelector={bean.DestinityBank} onChange={(e) => { setBean({ ...bean, "DestinityBank": e }); }} />
                    </div>
                </div>
            </Modal>
        </BasicPage>
    )
}