import { BpmService } from "../../../../core/services/BpmService";

import { useState, useEffect } from 'react';
import { useDashboardStore } from "../../../pages/hooks/useDashboardStore";
interface Props {
    label?: string;
    code: number;
    value: number | undefined;
    onChange: Function;
    returnType?: "ID" | "Object";
    idSelect?: "IDTHConstantsValue" | "CodeID"
}

export const SelectTHConstantsValue = ({ label, code, value, onChange, returnType = "ID", idSelect = "IDTHConstantsValue" }: Props) => {

    const { setLoading } = useDashboardStore();
    const service = new BpmService();
    const [lista, setLista] = useState<any>([]);
    const [id, setId] = useState<number>(NaN);

    useEffect(() => {
        if (value) {
            setId(value);
        }
    }, [value])

    useEffect(() => {
        getLista();
    }, [code])

    const getLista = () => {
        setLoading(true);
        service.getTHConstantsValuesByCode(0, [code]).subscribe(
            (resp: any) => {
                ////console.log(resp);
                if (resp.DataBeanProperties.ObjectValue) {
                    let lista = resp.DataBeanProperties.ObjectValue;
                    setLista(lista['Code_' + code]);
                }
                setLoading(false);
            }
        )
    }

    const returnValue = (e: number) => {
        console.log(e)
        const selectedId = e;
        setId(selectedId);
        if (onChange) {
            const selectedItem = lista.find(item => item.DataBeanProperties[idSelect] == e);
            onChange(returnType === "Object" ? selectedItem?.DataBeanProperties : selectedId);
        }
    }

    return (
        <>
            <label>{label ? label : ''} </label>
            <select className="form-select" id={`THCode_${code}`} value={id} onChange={(e) => { returnValue(parseInt(e.target.value)) }} >
                <option value={NaN}>...</option>
                {lista.map((item: any, index: number) => {
                    return (
                        <option key={index} value={item.DataBeanProperties[idSelect]}>{item.DataBeanProperties.Name}</option>
                    )
                })}
            </select>
        </>
    )
}