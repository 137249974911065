import { useEffect, useState } from "react";
import { PrintJson } from "../../../../theme/Component/PrintJson";
import { Singleton } from "../../../../../core/services/Singleton";
import { ContractService } from "../../../../../core/services/ContractService";
import { DataBeanProperties, ObjectValue } from '../../../../../core/services/model/server-response.interface';
import { BasicButton } from "../../../../theme/Component/BasicButton";

interface Props {
    ublInvoice: any;
    onChange: any;
}
export const AddUBLInvoiceTax = ({ ublInvoice, onChange }: Props) => {

    const single = Singleton.getInstance();
    const service = new ContractService();

    const [contribuyente, setContribuyente] = useState<any>([]);
    const [tipoArticulo, setTipoArticulo] = useState<any>([]);
    const [nivelImpuesto, setNivelImpuesto] = useState<any>([]);
    const [tipoImpuesto, setTipoImpuesto] = useState<any>([]);
    const [tarifa, setTarifa] = useState<any>([]);

    const [bean, setBean] = useState<any>({
        idTaxFilerTypes: 0,
        idTaxableArticleTypes: 0,
        idTaxSchemeContext: 0,
        idTaxScheme: 0,
        idTaxSchemeValue: 0
    });

    useEffect(() => {
        getTaxFilerTypes();
        getTaxableArticleTypes();
    }, [])

    const handleChange = (e: any) => {
        setBean(single.handleFormChange(bean, e));
    }

    const getTaxFilerTypes = () => {
        single.spinner(true);
        service.getTaxFilerTypes().subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue)
                    setContribuyente(resp.DataBeanProperties.ObjectValue);
                let tmp = resp.DataBeanProperties.ObjectValue;
                if (tmp.length > 0) {
                    setBean({ ...bean, "idTaxFilerTypes": tmp[0].DataBeanProperties.Value });
                }
                single.spinner(false);
            }
        );
    }
    const getTaxableArticleTypes = () => {
        single.spinner(true);
        service.getTaxableArticleTypes().subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue)
                    setTipoArticulo(resp.DataBeanProperties.ObjectValue);
                let tmp = resp.DataBeanProperties.ObjectValue;
                if (tmp.length > 0) {
                    setBean({ ...bean, "idTaxableArticleTypes": tmp[0].DataBeanProperties.Value });
                }
                single.spinner(false);
            }
        );
    }

    useEffect(() => {

        getTaxSchemeContextWithHoldingTaxCatalog();
       
    }, [bean.idTaxFilerTypes, bean.idTaxableArticleTypes])

    const getTaxSchemeContextWithHoldingTaxCatalog = () => {
        single.spinner(true);
        service.getTaxSchemeContextWithHoldingTaxCatalog(parseInt(bean.idTaxableArticleTypes), parseInt(bean.idTaxFilerTypes)).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue)
                    setNivelImpuesto(resp.DataBeanProperties.ObjectValue);
                let tmp = resp.DataBeanProperties.ObjectValue;
                if (tmp.length > 0) {
                    setBean({ ...bean, "idTaxSchemeContext": tmp[0].DataBeanProperties.IDTaxSchemeContext });
                }else{
                    setTipoImpuesto([]);
                    setTarifa([]);
                }
                single.spinner(false);
            }
        );
    }

    useEffect(() => {
        getTaxSchemeCatalogWithHoldingTax();
    }, [bean.idTaxSchemeContext,bean.idTaxFilerTypes, bean.idTaxableArticleTypes])

    const getTaxSchemeCatalogWithHoldingTax = () => {
        single.spinner(true);
        service.getTaxSchemeCatalogWithHoldingTax(parseInt(bean.idTaxSchemeContext), parseInt(bean.idTaxableArticleTypes), parseInt(bean.idTaxFilerTypes)).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue)
                    setTipoImpuesto(resp.DataBeanProperties.ObjectValue);
                let tmp = resp.DataBeanProperties.ObjectValue;
                if (tmp.length > 0) {
                    setBean({ ...bean, "idTaxScheme": tmp[0].DataBeanProperties.IDTaxScheme });
                }
                single.spinner(false);
            }
        );
    }

    useEffect(()=>{
        getTaxSchemeValueCatalogAvailable();
    },[bean.idTaxScheme,bean.idTaxFilerTypes, bean.idTaxableArticleTypes])

    const getTaxSchemeValueCatalogAvailable = () => {
        single.spinner(true);
        service.getTaxSchemeValueCatalogAvailable(parseInt(bean.idTaxScheme), parseInt(bean.idTaxableArticleTypes), parseInt(bean.idTaxFilerTypes)).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue)
                    setTarifa(resp.DataBeanProperties.ObjectValue);
                let tmp = resp.DataBeanProperties.ObjectValue;
                if (tmp.length > 0) {
                    setBean({ ...bean, "idTaxSchemeValue": tmp[0].DataBeanProperties.IDTaxSchemeValue });
                }
                single.spinner(false);
            }
        );
    }
    const addTaxWithHoldingTaxToUBLInvoice = () => {
        single.spinner(true);
        service.addTaxWithHoldingTaxToUBLInvoice(ublInvoice.DataBeanProperties.IDUblInvoice,ublInvoice.DataBeanProperties.IssueDate,parseInt(bean.idTaxSchemeValue),parseInt(bean.idTaxableArticleTypes)).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue)                  
                    onChange(resp.DataBeanProperties.ObjectValue);
                  
                single.spinner(false);
            }
        );
    }


    return (
        <div className="row">
            <div className="col-md-6">
                <label>Tipo de Contribuyente</label>
                <select name="idTaxFilerTypes" className="form-select" value={bean.idTaxFilerTypes} onChange={handleChange}>
                    {contribuyente.map((item: any, index: number) => {
                        return (
                            <option value={item.DataBeanProperties.Value}>{item.DataBeanProperties.Property}</option>
                        )
                    })}
                </select>
            </div>
            <div className="col-md-6">
                <label>Tipo de Artículos a Aplicar</label>
                <select name="idTaxableArticleTypes" className="form-select" value={bean.idTaxableArticleTypes} onChange={handleChange}>
                    {tipoArticulo.map((item: any, index: number) => {
                        return (
                            <option value={item.DataBeanProperties.Value}>{item.DataBeanProperties.Property}</option>
                        )
                    })}
                </select>
            </div>
            <div className="col-md-6">
                <label>Nivel de Impuestos</label>
                <select name="idTaxSchemeContext" className="form-select" value={bean.idTaxSchemeContext} onChange={handleChange}>
                    {/* <option value={0}>...</option> */}
                    {nivelImpuesto.map((item: any, index: number) => {
                        return (
                            <option value={item.DataBeanProperties.IDTaxSchemeContext}>{item.DataBeanProperties.Code} {item.DataBeanProperties.Name}</option>
                        )
                    })}
                </select>
            </div>
            <div className="col-md-6">
                <label>Tipo de Impuesto</label>
                <select name="idTaxScheme" className="form-select" value={bean.idTaxScheme} onChange={handleChange}>
                    {tipoImpuesto.map((item: any, index: number) => {
                        return (
                            <option value={item.DataBeanProperties.IDTaxScheme}>{item.DataBeanProperties.Code} - {item.DataBeanProperties.Description}</option>
                        )
                    })}
                </select>
            </div>
            <div className="col-md-12">
                <label>Tarifa</label>
                <select name="idTaxSchemeValue" className="form-select" value={bean.idTaxSchemeValue} onChange={handleChange}>
                {tarifa.map((item: any, index: number) => {
                        return (
                            <option value={item.DataBeanProperties.IDTaxSchemeValue}>{item.DataBeanProperties.TaxValue} ({item.DataBeanProperties.ValueTypeName}) {item.DataBeanProperties.Description}</option>
                        )
                    })}
                </select>
            </div>
            <div className="col-md-12">
                    <BasicButton icon="ri-file-add-line" eventClick={addTaxWithHoldingTaxToUBLInvoice} >Agregar</BasicButton>
            </div>
            {/* <PrintJson json={ublInvoice}/> */}
        </div>
    )

}