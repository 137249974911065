import { DataBean } from "../DataBean";

export class TransversalSkillLevel extends DataBean {


    public async getTransversalSkillLevelCatalog(idType: number | null): Promise<any> {
        return this.getCustomService("PeopleArt", "java.util.List_getTransversalSkillLevelCatalog_Number", [idType]);
    }

    public async deleteTransversalSkillLevel(bean): Promise<any> {
        return this.getCustomService("PeopleArt", "void_deleteTransversalSkillLevel_Number", [
            {
                "DataBeanProperties": bean,
                "DataBeanName": "com.peopleart.bean.appointment.TransversalSkillLevel"
            }
        ]);
    }
}