import { useEffect, useState } from "react";
import { _BankAccount, _CompetitionType, _Entailment, _MainTheme, _PaymentType, _StrategicObjetive, _ThirdBankingAccount } from "../../../../core/services/dataBean/EntityCatalog";
import { Singleton } from "../../../../core/services/Singleton";
import { toast } from "react-toastify";
import { ListMenu } from "../../../theme/Component/ListMenu";
import { BasicPage } from "../../../theme/Component/BasicPage";
import { BasicTable } from "../../../theme/Component/BasicTable";
import { Modal } from "../../../theme/Component/Modal";
import { ModalConfirm } from "../../../theme/Component/ModalConfirm";
import useDataBean from "../../../../core/services/dataBean/useDataBean";

import { AccountEditor } from "../../../editor/AccountEditor";
import { BasicSwitch } from "../../../theme/Component/BasicSwitch";
import { CompanyEditor } from "../Company/CompanyEditor";
import { BakingEntityEditor } from "../BankingEntity/BankingEntitySelect";
import { BankingAccountEditor } from "../../../Talentohumano/ParametrosTalentoHumano/ThirdBankingAccount/BankingAccountSelect";
import { SelectAccountantTree } from "../../../contabilidad/catalogo-cuentas/AccountantTree/SelectAccountantTree";

export const BankAccountCatalog = () => {

    const BankAccountCatalog = useDataBean(_BankAccount);
    const single = Singleton.getInstance();
    const [lista, setLista] = useState<any>([]);
    const [idCompany, setidCompany] = useState<number>(null)
    const [modal, setModal] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalC, setModalC] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });

    useEffect(() => {
        getLista();
    }, [idCompany])

    const getLista = () => {

        BankAccountCatalog.instance.getBankingAccountCatalog(idCompany).then(
            (resp: any) => {
                if (resp) {
                    let List = resp;
                    List.forEach((e: any) => {

                        e.DataBeanProperties.BankingNameCode = (e.DataBeanProperties.BankingEntityCode ?? '') +
                            (e.DataBeanProperties.BankingEntityName ?? '')


                    })
                    setLista(List);
                }
            }
        )

    }

    const create = () => {
        BankAccountCatalog.setBean({
            IDCompany: idCompany
        });
        setModal({ ...modal, "view": true });
    }
    const deleteItem = () => {
        BankAccountCatalog.instance.deleteBankingAccount(BankAccountCatalog.bean).then(
            (resp: any) => {
                getLista();
                setModalC({ ...modalC, "view": false });
            }
        )

    }
    const editaItem = () => {
       //console.log(BankAccountCatalog.bean);
        BankAccountCatalog.instance.updateBankingAccount(BankAccountCatalog.bean).then(
            (resp: any) => {
                getLista();
                setModal({ ...modal, "view": false });
            }
        ).catch(err => {
            toast.error(err);
        })

    }

    // const editaItem = () => {
    //    //console.log(BankAccountCatalog.bean);
    //     BankAccountCatalog.update().then(
    //         (resp: any) => {
    //             getLista();
    //             setModal({ ...modal, "view": false });
    //         }
    //     ).catch(err => {
    //         toast.error(err);
    //     })

    // }

    const head = [
        { title: 'ID', property: 'IDBankingAccount', visible: true },
        { title: 'Nombre', property: 'Name', visible: true },
        { title: 'Entidad Bancaria', property: 'BankingNameCode', visible: true },
        { title: 'Sucursal', property: 'IDBranch', visible: true },
        { title: 'Tipo Cuenta', property: 'AccountTypeName', visible: true },
        { title: 'Número Cuenta', property: 'AccountNumber', visible: true },
        { title: 'Cuenta Débito', property: 'AccountantIDName', visible: true },



    ]

    const iconButtonsItem = [
        { nombre: 'Agregar', visible: single.canCreate(), icono: 'ri-file-add-line', evento: create }
    ]
    const listaBotones = () => {
        const botones = [

            { titulo: 'Editar', icono: 'ri-edit-2-line', visible: single.canEdit(), evento: () => { setModal({ ...modal, "view": true }) } },
            { titulo: 'Eliminar', icono: 'ri-delete-bin-line', visible: single.canDelete(), evento: () => { setModalC({ ...modalC, "view": true }) } },

        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }


    return (
        <BasicPage titulo="Cuentas Bancarias de la Organización">
            <div className="row">
                <CompanyEditor id={idCompany} onchange={(e) => { setidCompany(e) }} />


            </div>
            <div className="row">
                <div className="col-md-12">

                    <BasicTable body={lista} head={head} rowSet={BankAccountCatalog.setBean} listButtons={listaBotones()} iconButtons={iconButtonsItem}></BasicTable>
                    <Modal modal={modal} updateModal={setModal} eventModal={editaItem}>
                        <div className="row">



                            <div className="col-md-6">

                                <BakingEntityEditor label="Entidad Bancaria" idSelector={BankAccountCatalog.bean.IDBankingEntity} onChange={(e) => { BankAccountCatalog.setBean({ ...BankAccountCatalog.bean, "IDBankingEntity": e }) }} />
                            </div>
                            <div className="col-md-6">
                                <SelectAccountantTree label="Cuenta Débito" jsonCode={BankAccountCatalog.bean.AccountantIDStr} onChange={(e) => { BankAccountCatalog.setBean({ ...BankAccountCatalog.bean, "AccountantIDStr": e.JsonCode }) }} />
                            </div>
                            <div className="col-md-6">

                                <label>Nombre</label>
                                <input type="text" name="Name" className="form-control" value={BankAccountCatalog.bean.Name} onChange={BankAccountCatalog.handleChange} />
                            </div>
                            <div className="col-md-6">

                                <label>Sucursal</label>
                                <input type="text" name="IDBranch" className="form-control" value={BankAccountCatalog.bean.IDBranch} onChange={BankAccountCatalog.handleChange} />
                            </div>

                            <div className="col-md-6">
                                <BankingAccountEditor label="Tipo de Cuenta" idSelector={BankAccountCatalog.bean.AccountType} onChange={(e) => { BankAccountCatalog.setBean({ ...BankAccountCatalog.bean, "AccountType": e }) }} />

                            </div>
                            <div className="col-md-6">

                                <label>Número Cuenta</label>
                                <input type="text" name="AccountNumber" className="form-control" value={BankAccountCatalog.bean.AccountNumber} onChange={BankAccountCatalog.handleChange} />
                            </div>



                        </div>
                    </Modal>
                    <ModalConfirm modal={modalC} updateModal={setModalC} eventModal={deleteItem}>
                        <strong>Eliminar item</strong>
                    </ModalConfirm>
                </div>

            </div>
        </BasicPage>
    )
}