import { DataBean } from "../DataBean";


export class Function extends DataBean {


    public async getFunctionCatalog(idLn: number | null, idAppoManual: number, type: number | null): Promise<any> {
        return this.getCustomService("PeopleArt", "java.util.List_getFunctionCatalog_Number_Number_Number", [idLn, idAppoManual, type]);
    }

    public async deleteFunction(bean): Promise<any> {
        return this.getCustomService("PeopleArt", "void_deleteFunction_com.peopleart.bean.appointment.Function", [
            {
                "DataBeanProperties": bean,
                "DataBeanName": "com.peopleart.bean.appointment.Function"
            }
        ]);
    }
}