import { useEffect, useRef, useState } from "react";
import { _single } from "../../../../core/services/dataBean/EntityCatalog";
import useDataBean from "../../../../core/services/dataBean/useDataBean";
import { BasicPage } from "../../../theme/Component/BasicPage";
import { BasicTable } from "../../../theme/Component/BasicTable";
import { ListMenu } from "../../../theme/Component/ListMenu";
import { Modal } from "../../../theme/Component/Modal";
import { Singleton } from '../../../../core/services/Singleton';
import { toast } from "react-toastify";
import { _BudgetSource } from "../../../../core/services/dataBean/EntityCatalogVdos";
import { SelectBudgetType } from "../select/SelectBudgetType";
import { InputDate } from "../../../theme/Input/InputDate";
import { BasicButton } from "../../../theme/Component/BasicButton";
import { StepWizard, Wizard } from "../../../bpm/config/Componentes/wizard/Wizard";
import useModal, { MODAL_md } from "../../../theme/Component/hooks/useModal";
import { SelectBudgetID } from "../BudgetID/SelectBudgetID";
import { SelectBudgetResource } from "../../../contract/select/SelectBudgetResource";
import { InputMoney } from "../../../theme/Input/InputMoney";
import { DocumentViewer } from "../../../theme/Component/DocumentViewer";
import { ErrorList } from "../../../theme/Component/ErrorList";


export const BudgetCDPFactory = () => {

    const BudgetCDPFactory = useDataBean(_BudgetSource);
    const single = Singleton.getInstance();
    const [lista, setLista] = useState<any>([]);
    const [BudgetID, setBudgetID] = useState<number>(null);
    const [Date, setDate] = useState<any>(_single.getCurrenDate());
    const [Consecutive, setConsecutive] = useState<number>(null);
    const [Description, setDescription] = useState<string>(null);

    const [bean, setBean] = useState<any>({});
    const [actualBudget, setActuaBudget] = useState<any>({});

    const [keyID, setkeyID] = useState<any>(NaN);
    const [documents, setDocuments] = useState<any>([]);
    const [msgList, setMsgList] = useState<any>([]);
    const validForm1 = useRef<boolean>(false);

    const modalBudget = useModal('Presupuesto', MODAL_md);


    const head = [
        { title: 'Rubro Presupuestal', property: 'BudgetName', visible: true },
        { title: 'Saldo del Rubro', property: 'LeftValue', visible: true },
        { title: 'Valor de la Apropiación', property: 'Value', visible: true },
        { title: 'Presupuesto', property: 'BudgetTypeName', visible: true },
        { title: 'Recurso Presupuestal', property: 'BudgetResourceName', visible: true },
        { title: 'Descripción', property: 'Description', visible: true },
    ]


    const iconButtonsItem = [
        { nombre: 'Agregar', visible: single.canCreate(), icono: 'ri-file-add-line', evento: () => { modalBudget.open() } }
    ]

    useEffect(() => {
        getActualBudgetIDType();
    }, [])


    const getActualBudgetIDType = () => {
        BudgetCDPFactory.instance.getActualBudgetIDType().then(
            (resp: any) => {
                if (resp) {
                    setActuaBudget(resp);
                }
            }
        )
    }

    const eliminarItem = (objeto) => {
        const updatedLista = lista.filter((item: any) =>
            JSON.stringify(item.DataBeanProperties) !== JSON.stringify(bean)
        );

        setBean({});
        setLista(updatedLista);
    }

    const listaBotones = () => {
        const botones = [
            { titulo: 'Editar', icono: 'ri-edit-2-line', visible: single.canEdit(), evento: () => { modalBudget.open() } },
            { titulo: 'Eliminar', icono: 'ri-delete-bin-line', visible: single.canDelete(), evento: () => { eliminarItem(bean) } },

        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }


    useEffect(() => {
        validaParametros();
    }, [BudgetID, Date])

    const parametros = () => {
        return (
            <>
                <div className="row">
                    <div className="col-md-4">
                        <SelectBudgetType idSelector={BudgetID} onChange={(e: any) => { setBudgetID(e) }} />

                    </div>
                    <div className="col-md-4">
                        <InputDate label='Fecha de Registro' name='accountantDate' value={Date} setDate={(e: any) => { setDate(e) }} />
                    </div>
                    <div className="col-md-4">
                        <label>Consecutivo Externo</label>
                        <input type="number" name="Value" className="form-control" value={Consecutive} onChange={(e) => { setConsecutive(parseInt(e.target.value)) }} />
                    </div>
                    <div className="col-md-12">
                        <label>Descripción</label>
                        <textarea name="Description" className="form-control" value={Description} onChange={(e) => { setDescription(e.target.value) }} />
                    </div>
                </div>

            </>
        )
    }

    const [form1, setForm1] = useState<boolean>(false);

    const validaParametros = () => {
        let valido = false;

        if (!BudgetID || !Date) {

        } else {
            valido = true;
        }
        validForm1.current = valido;
        setForm1(valido);

        return valido;
    }

    const createBudgetRowCDP = () => {
        BudgetCDPFactory.instance.createBudgetRowCDP(Date, BudgetID, bean.JsonCode, bean.IDBudgetResource, bean.Value, bean.Obs).then(
            (resp: any) => {
                let temp = lista;
                temp.push({
                    DataBeanProperties: resp,
                    DataBeanName: 'budget.Budget'
                });
                setLista(temp);
                modalBudget.close();
                setBean({});
            }
        ).catch(err => toast.error(err))
    }
    const tablaRegistros = () => {
        return (
            <>
                <div className="row">
                    <div className="col-md-12">
                        <BasicTable key={lista} iconButtons={iconButtonsItem} body={lista} head={head} rowSet={setBean} listButtons={listaBotones()} />

                        <Modal modal={modalBudget.modal} updateModal={modalBudget.setModal} eventModal={createBudgetRowCDP} onSubmitLabel="Agregar" >
                            <div className="row">
                                <div className="col-md-12">
                                    <SelectBudgetID onChange={({ JsonCode }) => { setBean({ ...bean, "JsonCode": JsonCode }) }} jsonCode={bean.JsonCode} edit={false} tipoPresupuesto={actualBudget.IDBudgetIDType} />
                                </div>
                                <div className="col-md-6">
                                    <InputMoney id={_single.getRandomInt} _label='Valor a solicitar' name="valor" value={bean.Value} onChange={(e: any) => { setBean({ ...bean, ['Value']: parseInt(e) }) }} />
                                </div>
                                <div className="col-md-6">
                                    <SelectBudgetResource idSelector={bean.IDBudgetResource} onChange={(e) => { setBean({ ...bean, "IDBudgetResource": e }) }} />
                                </div>
                                <div className="col-md-12">
                                    <label>Descripción</label>
                                    <textarea name="Description" className="form-control" value={bean.Obs} onChange={(e) => { setBean({ ...bean, "Obs": e.target.value }) }} />
                                </div>
                            </div>
                        </Modal>
                    </div>
                </div>
            </>
        )
    }

    const crearCDP = () => {
        const crearDocumento = () => {
            BudgetCDPFactory.instance.createCDP(BudgetID, Date, Consecutive, lista, Description, null, _single.getAccountID(), true).then(
                (resp: any) => {
                    setDocuments(resp.DocumentURL);
                    setMsgList(resp.MsgList);
                }
            )

        }


        return (
            <div className="row">
                <div className="col-md-12">
                    <BasicButton disable={documents.length > 0} icon="ri-article-line" eventClick={crearDocumento}>Generar Documento CDP</BasicButton>
                </div>
                <div className="col">
                    {msgList.length > 0 &&
                        <ErrorList lista={msgList} />

                    }
                    {msgList.length === 0 && documents.length > 0 &&
                        <DocumentViewer key={documents} list={documents} />
                    }
                    {/* <PrintJson json={documents} /> */}
                </div>
            </div>
        )
    }

    useEffect(() => {

        if (keyID == '1') {
            setDocuments([]);
            setMsgList([]);
        }

    }, [keyID])

    const steps: StepWizard[] = [
        {
            keyID: "0",
            name: 'PASO 1. Parámetros',
            onEvent: validaParametros,
            children: parametros(),
            validButton: form1,
            canReturn: true
        },
        {
            keyID: "1",
            name: 'PASO 2. Rubros Presupuestales',
            onEvent: () => {   },
            children: tablaRegistros(),
            validButton: lista.length > 0,
            canReturn: true
        },
        {
            keyID: "2",
            name: 'PASO 3. Crear CDP',
            onEvent: () => {   },
            children: crearCDP(),
            validButton: false,
            canReturn: documents.length === 0
        },
    ];

    return (
        <BasicPage titulo="Certificado de Disponibilidad Presupuestal">
            <Wizard title="Certificado" listStep={steps} onStep={setkeyID} />
        </BasicPage>
    )
}