import { useEffect, useState } from 'react'
import { BasicPage } from '../theme/Component/BasicPage'
import { ModalConfirm } from '../theme/Component/ModalConfirm'
import { Singleton } from '../../core/services/Singleton'
import { ContractService } from '../../core/services/ContractService'
import { toast } from 'react-toastify'
import { Modal } from '../theme/Component/Modal'
import { ListMenu } from '../theme/Component/ListMenu'
import { BasicTable } from '../theme/Component/BasicTable'
import { BasicSwitch } from '../theme/Component/BasicSwitch';

const _contractService = new ContractService()
const _single = Singleton.getInstance()

export interface ModuleSSTT {
    IDModuleSST?: number
    Name: string
    Description: string
    UseLegal: boolean
    UseRisk: boolean
    IDEmployee: number
    State: number
}

export const ModuleSSTT = () => {

    const [modal, setModal] = useState({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-md' });
    const [modalConfirm, setModalConfirm] = useState<any>({ name: 'Confirmar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });
    const [modules, setModules] = useState([])

    const constructor = {
        Name: '',
        Description: '',
        UseLegal: false,
        UseRisk: false,
        IDEmployee: _single.getAccountID(),
        State: 1
    }

    const [bean, setBean] = useState<ModuleSSTT>(constructor)

    useEffect(() => {
        setBean(constructor)
        getLista()
    }, [])

    const getLista = () => {
        _single.spinner(true)
        _contractService.getModuleSSTCatalogPorPropiedades()
            .subscribe((resp: any) => {
                _single.spinner(false)
               //console.log(resp);
                if (resp.DataBeanProperties.ObjectValue) {
                    setModules(resp.DataBeanProperties.ObjectValue)
                } else {
                    toast.error(resp.DataBeanProperties.ErrorMessage)
                }
            })
    }

    const updateModuleSSTT = (IDModuleSST: number | null, State: number = 1) => {
        _single.spinner(true)
        _contractService.updateModuleSST({ ...bean, IDModuleSST, State })
            .subscribe((resp: any) => {
                _single.spinner(false)
               //console.log(resp);
                if (resp.DataBeanProperties.ObjectValue) {
                    setModal({ ...modal, view: false })
                    toast.success("Se completo la acción correctamente!")
                    getLista()
                } else {
                    toast.error(resp.DataBeanProperties.ErrorMessage)
                }
            })
    }

    const deleteModuleSSTT = () => {
        updateModuleSSTT(bean.IDModuleSST, 2)
        setModalConfirm({ ...modalConfirm, view: false })
    }

    const setRowBean = (bean: any) => {
       //console.log("Row : ", bean);
        setBean(bean);
    }

    const head = [
        { title: 'ID', property: 'IDModuleSST' },
        { title: 'Nombre', property: 'Name' },
        { title: 'Descripción', property: 'Description' },
        { title: 'Usa Legal', property: 'UseLegal' },
        { title: 'Usa Riesgo', property: 'UseRisk' },
    ]

    const listaBotones = () => {
        const botones = [
            { titulo: 'Editar', icono: 'ri-file-list-line', visible: true, evento: () => { setModal({ ...modal, "view": true }) } },
            { titulo: 'Eliminar', icono: 'ri-file-user-line', visible: true, evento: () => { setModalConfirm({ ...modalConfirm, "view": true }) } },
        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }

    const iconButtonsItem = [
        { nombre: 'Agregar', visible: _single.canCreate(), icono: 'ri-file-add-line', evento: () => { setBean(undefined); setModal({ ...modal, view: true }) } }
    ]

    return (
        <>
            <BasicPage titulo="Módulos SSTT">
                <div className="row">
                    <div className="col-md-12 p-2">
                        <BasicTable listButtons={listaBotones()} iconButtons={iconButtonsItem} head={head} body={modules} rowSet={setRowBean}></BasicTable>
                    </div>
                </div>
            </BasicPage>

            {modalConfirm.view &&
                <ModalConfirm modal={modalConfirm} updateModal={setModalConfirm} eventModal={deleteModuleSSTT}>
                </ModalConfirm>
            }

            {modal.view &&
                <Modal modal={modal} updateModal={setModal} eventModal={() => { updateModuleSSTT(bean.IDModuleSST, 1) }}>
                    <div className="row">
                        <div className="col-md-12">
                            <label className="form-label">Nombre</label>
                            <input type="text" className="form-control" value={bean?.Name} onChange={(e) => { setBean({ ...bean, Name: e.target.value }) }} />
                        </div>
                        <div className="col-md-12">
                            <label className="form-label">Descripción</label>
                            <textarea className="form-control" value={bean?.Description} onChange={(e) => { setBean({ ...bean, Description: e.target.value }) }}></textarea>
                        </div>
                        <div className="col-md-6 mt-3">
                            <BasicSwitch
                                label='Usar Matriz Legal'
                                estado={bean.UseLegal}
                                eventChange={(e) => setBean({ ...bean, UseLegal: e })}
                            />
                        </div>
                        <div className="col-md-6 mt-3">

                            <BasicSwitch
                                label='Usar Matriz Peligros'
                                estado={bean.UseRisk}
                                eventChange={(e) => setBean({ ...bean, UseRisk: e })}
                            />
                        </div>
                    </div>
                    <div></div>
                </Modal>
            }
        </>
    )
}
