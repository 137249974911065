import { useEffect, useState } from "react";

import useDataBean from "../../../core/services/dataBean/useDataBean";
import { toast } from "react-toastify";
import { Singleton } from "../../../core/services/Singleton";
import { ListMenu } from "../../theme/Component/ListMenu";
import { BasicTable, MASK_DATE, MASK_MONEY, MASK_NUMBER } from "../../theme/Component/BasicTable";
import { BasicPage } from "../../theme/Component/BasicPage";
import { Modal } from "../../theme/Component/Modal";
import { ModalConfirm } from "../../theme/Component/ModalConfirm";
import { _AccountantConcept, _FiscalResponsability, _FiscalResponsabilityType } from "../../../core/services/dataBean/EntityCatalog";
import { _AdquisitionProject, _ArticleClassType, _MeasurementUnitCode, _Process, _Project } from "../../../core/services/dataBean/EntityCatalogVdos";
import useModal, { MODAL_md, MODAL_SM } from "../../theme/Component/hooks/useModal";
import { AccountEditor } from "../../editor/AccountEditor";
import { FileInput } from "../../theme/Component/FileInput";

export const ProveedorDocumentos = () => {

    // const process = useDataBean(_Process);

    const single = Singleton.getInstance();
    const [lista, setLista] = useState<any>([]);
    const [bean, setBean] = useState<any>({ JSONTemplate: {}, IDAccount: null });
    const [acc, setAcc] = useState<number>(null);
    const [modalC, setModalC] = useState<any>({ name: '', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });
    const modalConfirm = useModal(".", MODAL_SM);
    const modalEditar = useModal("Agregar", MODAL_md);




    const create = () => {
        setBean({
            State: 1,
        });
        modalEditar.setModal({ ...modalEditar.modal, "view": true });
    }

    const editaItem = () => {
        const updatedItem = {
            ...bean,
            IDAccount: acc,
            JSONTemplate: JSON.parse(bean.JSONTemplate || '{}'), // Aseguramos que JSONTemplate sea un objeto
        };
        setLista((prevLista: any) => [...prevLista, updatedItem]);
        modalEditar.setModal({ ...modalEditar.modal, view: false });
        setBean({ ...bean, IDAccount: acc, JSONTemplate: JSON.parse(bean.JSONTemplate || '{}') });

    };
    const deleteItem = () => {
        setLista((prevLista) => prevLista.filter((item) => item?.IDAccount !== bean?.IDAccount));
        modalConfirm.close();
    };




    const head = [
        { title: 'ID', property: 'IDAccount', visible: true, mask: MASK_NUMBER },
        { title: 'Nombre Proveedor', property: 'IDAccount', visible: true },
        {
            title: 'Documento',
            property: 'JSONTemplate',
            visible: true,
        },



    ]

    const iconButtonsItem = [
        { nombre: 'Agregar', visible: single.canCreate(), icono: 'ri-file-add-line', evento: create }
    ]
    const listaBotones = () => {
        const botones = [

            { titulo: 'Editar', icono: 'ri-edit-2-line', visible: single.canEdit(), evento: () => { modalEditar.setModal({ ...modalEditar.modal, "view": true }) } },
            {
                titulo: "Eliminar",
                icono: "ri-delete-bin-line",
                visible: single.canDelete(),
                evento: () => {
                    setBean(bean); // Establecer el bean actual
                    modalConfirm.open();
                },
            },

        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }



    return (
        <BasicPage titulo="Proveedor / Documuentos">
            <div className="row">

                <div className="col-md-12">

                    <BasicTable body={lista || []} head={head} rowSet={setBean} listButtons={listaBotones()} iconButtons={iconButtonsItem}></BasicTable>
                    <Modal modal={modalEditar.modal} updateModal={modalEditar.setModal} eventModal={editaItem}>
                        <>
                            <div className="row">
                                <div className="col-md-12">
                                    <AccountEditor label="Proveedor" idAccount={acc} onChange={(e) => { setAcc(e.IDAccount) }}></AccountEditor>
                                </div>
                                <div className="col-md-12">
                                    <FileInput
                                        Media={bean.Media}
                                        MediaContext={bean.MediaContext}
                                        directory="requirement"
                                        label="Cargar un archivo"
                                        onCreate={({ MediaContext, Media }: any) => {
                                            setBean((prevBean) => ({
                                                ...prevBean,
                                                JSONTemplate: JSON.stringify({ MediaContext, Media }), // Guarda el JSON como string
                                            }));
                                        }}
                                    />
                                </div>


                            </div>

                        </>
                    </Modal>
                    <ModalConfirm modal={modalConfirm.modal} updateModal={modalConfirm.setModal} eventModal={deleteItem}>
                        <strong>Eliminar item</strong>
                    </ModalConfirm>
                </div>

            </div>
        </BasicPage>
    )
}