import { useEffect, useState } from "react";
import { _single } from "../../../core/services/dataBean/EntityCatalog";
import { SelectBudgetType } from "../../admin/configuracion/select/SelectBudgetType";
import { InputDate } from "../../theme/Input/InputDate";

interface Props {
    onChange: Function;
}

export const BudgetMovementFactoryForm = ({ onChange }: Props) => {

    const [response, setResponse] = useState<any>({
        DateForm: _single.getCurrenDate(),

    });

    useEffect(() => {
        onChange(response)
    }, [response])

    return (
        <div className="row">
            <div className="col-md-6">
                <InputDate name="DateForm" value={response.DateForm} setDate={(e) => { setResponse({ ...response, "DateForm": e }) }} label="Fecha de Registro" />
            </div>
            <div className="col-md-6">
                <SelectBudgetType idSelector={response.IDBudgetType} onChange={(e) => { setResponse({ ...response, "IDBudgetType": e }) }} />
            </div>
            <div className="col-md-12">
                <label>Descripción</label>
                <textarea className="form-control" value={response.Description} onChange={(e) => { setResponse({ ...response, "Description": e.target.value }) }} />
            </div>
        </div>
    )
}