import { DataBean } from "../DataBean";

export class CashResquestMoney extends DataBean {

    public async getCashRequestMoneyCatalog(idCashRequest: number, cashRequestDate: any): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_getCashRequestMoneyCatalog_Number_java.util.Date", [idCashRequest, cashRequestDate]);
    }

    public async getPdfAccountantDocument(documentLocation: number, mnemonic: string, date: any, idAccount: number): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "com.quickdataerp.bean.accountant.AccountantDocument_getPdfAccountantDocument_Number_String_java.util.Date_Number", [documentLocation, mnemonic, date, idAccount]);
    }

}
