import { useEffect, useState } from "react";
import { Singleton } from "../../../../../../core/services/Singleton";
import { BpmService } from "../../../../../../core/services/BpmService";
import { BasicButton } from "../../../../../theme/Component/BasicButton";
import { ListMenu } from "../../../../../theme/Component/ListMenu";
import { BasicPage } from "../../../../../theme/Component/BasicPage";
import { SelectorTramites } from "../../SelectorTramites";
import { BasicTable } from "../../../../../theme/Component/BasicTable";
import { ModalConfirm } from "../../../../../theme/Component/ModalConfirm";
import { Modal } from "../../../../../theme/Component/Modal";
import { ForpoService } from "../../../../../custom/forpo/components/service/ForpoService";
import { useDashboardStore } from "../../../../../pages/hooks/useDashboardStore";
import { AccountEditor } from "../../../../../editor/AccountEditor";


export const Comiteroles = () => {
    const [bean, setBean] = useState<any>({});
    const [lista, setLista] = useState([]);

    const [modal, setModal] = useState<any>({ name: 'Inmuebles', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalConfirm, setModalConfirm] = useState<any>({ name: 'Confirmar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });
    const [modalcaracttramites, setModalcaracttramites] = useState<any>({
        name: "Caracterizaciones Para Prueba",
        tabIndex: -1,
        view: false,
        btnClose: true,
        btnSubmit: false,
        format: "modal-md",
    });
    const [modala, setModala] = useState<any>({
        name: "Agregar Item",
        tabIndex: -1,
        view: false,
        btnClose: true,
        btnSubmit: true,
        format: "modal-lg",
    });
    const [meliminar, setMeliminar] = useState<any>({
        name: "Confirmar",
        tabIndex: -1,
        view: false,
        btnClose: true,
        btnSubmit: true,
        format: "modal-sm",
    });
    const single = Singleton.getInstance()
    const { setLoading } = useDashboardStore();
    const service = new ForpoService();

    // useEffect(()=>{
    //     setUser(single.getAccountID());
    // },[])

    const idBean = "IDRoleCommittee";

    const setRowBean = (bean: any) => {
        setBean(bean);
       //console.log("aqui")
    };

    useEffect(() => {
        getLista();
    }, [])


    const agregarItem = () => {
        let b = {
            Name: "",
            Description: "",
            State: 1,

        };
        setBean(b);
        setModala({ ...modala, "view": true });
    };

    const getLista = () => {
        setLoading(true);
        service
            .getRoleCommitteeCatalogPorPropiedad("State", 1, null)
            .subscribe((resp: any) => {
                if (resp.DataBeanProperties.ObjectValue) {
                    setLista(resp.DataBeanProperties.ObjectValue);
                }
                setLoading(false);
            });
    };

    const caracterizaciontramites = () => {

        setModalcaracttramites({ ...modalcaracttramites, 'view': true });

    }

    // const updateItem = () => {
    //     setModala({ ...modala, ['view']: false });

    //     setLoading(true);
    //     service.updateRoleCommittee(bean).subscribe(
    //         (resp: any) => {
    //             setLoading(false);
    //             if (resp.DataBeanProperties.ObjectValue) {
    //                 getLista();
    //             }
    //             single.update(bean);
    //         });
    // }

    const updateItem = () => {
        setLoading(true);
        service.updateRoleCommittee(bean).subscribe(
            (resp: any) => {
                setLoading(false);
                if (resp.DataBeanProperties.ObjectValue) {
                    getLista();
                }
                if (bean.idBean > 0) {
                    single.update(bean);
                } else {
                    single.create(bean);
                }
            });
        setModala({ ...modala, "view": false });
    };

    const deletItem = () => {
        bean.State = 2;
       //console.log(bean);
        updateItem();

        setModalConfirm({ ...modalConfirm, ['view']: false });
    }

    const head = [
        { title: "ID ", property: idBean },
        { title: "Rol Crédito", property: "Name" },
        { title: "Usuario Inforpo", property: "UserInforpo" },
        { title: "Código Usuario", property: "CodeUser" },
        { title: "Descripción", property: "Description" },

    ];

    const renderButton = () => {
        return (
            <>
                <div className="row">
                    <div className="col-md-12">
                        <BasicButton
                            value="Agregar"
                            icon="ri-file-add-line"
                            eventClick={agregarItem}
                        ></BasicButton>
                        {bean[idBean] && (
                            <>
                                <BasicButton
                                    value="Editar"
                                    icon="ri-edit-2-line"
                                    eventClick={''}
                                ></BasicButton>
                                <BasicButton
                                    value="Eliminar"
                                    icon="ri-eraser-line"
                                    eventClick={() => {
                                        setModalConfirm({ ...modalConfirm, ["view"]: true });
                                    }}
                                ></BasicButton>
                                <BasicButton
                                    value="Caracterizaciones"
                                    icon="ri-edit-2-line"
                                    eventClick={caracterizaciontramites}
                                ></BasicButton>
                            </>
                        )}
                    </div>
                </div>
            </>
        );
    };

    const listaBotones = () => {
        const botones = [
            {
                titulo: "Editar",
                icono: "ri-file-list-line",
                visible: true,
                evento: () => {
                    setModala({ ...modala, view: true, name: 'Editar' });
                },
            },
            {
                titulo: "Eliminar",
                icono: "ri-file-user-line",
                visible: true,
                evento: () => {
                    setModalConfirm({ ...modalConfirm, view: true });
                },
            },

        ];
        return <ListMenu listaBotones={botones} />;
    };

    const iconButtonsItem = [
        {
            nombre: "Agregar",
            visible: single.canCreate(),
            icono: "ri-file-add-line",
            evento: agregarItem,
        },
    ];

    return (
        <>
            <BasicPage titulo="Usuario del comite">
                <div className="row">

                    <div className="col-md-12 p-2">

                        <BasicTable listButtons={listaBotones()} iconButtons={iconButtonsItem} head={head} body={lista} rowSet={setRowBean}></BasicTable>

                    </div>
                </div>
            </BasicPage>
            <ModalConfirm modal={modalConfirm} updateModal={setMeliminar} eventModal={deletItem}>
                {bean.name}
            </ModalConfirm>

            <Modal modal={modala} updateModal={setModala} eventModal={updateItem}>
                <div className="row">

                    <div className="col-md-6">
                        <label >Nombre Rol</label>
                        <input type="text" className="form-control" value={bean.Name} onChange={(e) => { setBean({ ...bean, 'Name': e.target.value }) }} />
                    </div>
                    <div className="col-md-6">

                        <AccountEditor idAccount={bean.IDEmployee} label="Empleado" onChange={({ IDAccount }: any) => { setBean({ ...bean, "IDEmployee": IDAccount }) }} />
                    </div>
                    <div className="col-md-6">
                        <label >Usuario Inforpo</label>
                        <input type="text" className="form-control" value={bean.UserInforpo} onChange={(e) => { setBean({ ...bean, 'UserInforpo': e.target.value }) }} />
                    </div>
                    <div className="col-md-6">
                        <label >Código usuario Inforpo</label>
                        <input type="text" className="form-control" value={bean.CodeUser} onChange={(e) => { setBean({ ...bean, 'CodeUser': e.target.value }) }} />
                    </div>

                    <div className="col-md-12">
                        <label >Descripción</label>
                        <textarea className="form-control" value={bean.Description} onChange={(e) => { setBean({ ...bean, 'Description': e.target.value }) }}></textarea>
                    </div>

                </div>
            </Modal>

        </>
    )
}