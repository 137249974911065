import { PrintJson } from "../../theme/Component/PrintJson";
import { Singleton } from '../../../core/services/Singleton';
import { ContractService } from '../../../core/services/ContractService';
import { useState } from 'react';
import { useEffect } from 'react';
import { BasicTable } from "../../theme/Component/BasicTable";
import { ListMenu } from "../../theme/Component/ListMenu";
import { Modal } from "../../theme/Component/Modal";
import { DataBeanProperties, ObjectValue } from '../../../core/services/model/server-response.interface';
import { BasicSwitch } from "../../theme/Component/BasicSwitch";
import { SupplierService } from "../../../core/services/SupplierService";
import { rolHiring } from '../../../constans/SupplierConstants'
import { useDashboardStore } from "../../pages/hooks/useDashboardStore";
import { SelectRol } from "../../admin/select/SelectRol";
import { SelectRolOffer } from "./OfferEmployee/SelectRolOffer";

interface Props {
    IDHiringModality: number;
    IDHiringModalityStage: number;
    updateStage: Function;
    rolesCodes:any;
}
export const DocStage = ({ IDHiringModality, IDHiringModalityStage, updateStage,rolesCodes }: Props) => {

    const single = Singleton.getInstance()
    const { setLoading } = useDashboardStore();
    const service = new ContractService();
    const service_supplier = new SupplierService();
    const [lista, setLista] = useState([]);
    const [tipo, setTipo] = useState(1);
    const [bean, setBean] = useState<any>({});
    const [roles, setRoles] = useState<any>([]);
    
    const [listaDocs, setListaDocs] = useState([]);
    const [listaForms, setListaForms] = useState([]);
    const [modal, setModal] = useState<any>({ name: 'Agregar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });

    const head = [
        { title: 'Tipo', property: 'Type', visible: true },
        { title: 'Documento', property: 'NameType', visible: true },
        { title: 'Responsable', property: 'ResponsibleName', visible: true },
        { title: 'Creado', property: 'Since', visible: true },
        { title: 'Opcional', property: 'Optional', visible: true },

    ]

    

    useEffect(()=>{
        
       
            
    },[])

    useEffect(() => {
        
        getListaDocumentos();
        getDocumentosActivos();
        getFormulariosActivos();
       

    }, [IDHiringModality, IDHiringModalityStage])


   

    //lista de documentos de la modalidad seleccionada
    const getListaDocumentos = () => {

        setLoading(true);
        service.getHiringModalityDocumentCatalog(null, null, null).subscribe(
            (resp: any) => {

               

                if (resp.DataBeanProperties.ObjectValue.DataBeanProperties.List) {
                    let tmp:any = resp.DataBeanProperties.ObjectValue.DataBeanProperties.List;
                   
                    setLista(tmp);
                }

                setLoading(false);
            }
        );
    }

    //lista de documetnos activos
    const getDocumentosActivos = () => {

        setLoading(true);

        service.renderHiringDocumentStage(IDHiringModalityStage, 1).subscribe(
            (resp: any) => {


                if (resp.DataBeanProperties.ObjectValue) {
                    let tmp = resp.DataBeanProperties.ObjectValue;
                  
                    setListaDocs(tmp);
                }

                setLoading(false);
            }
        );
    }
    //lista de formualrios activos
    const getFormulariosActivos = () => {

        setLoading(true);

        service_supplier.getFormTypeCatalogPorPropiedad("State", 1, null).subscribe(
            (resp: any) => {

               //console.log("formularios ", resp.DataBeanProperties.ObjectValue);

                if (resp.DataBeanProperties.ObjectValue) {
                    setListaForms(resp.DataBeanProperties.ObjectValue);
                }

                setLoading(false);
            }
        );
    }

    //agregar documento
    const addDocument = () => {
        setLoading(true);


        bean.TypeRequirement = tipo;

       //console.log(bean);

        service.updateHiringDocumentStage(bean).subscribe(
            (resp: any) => {
                setLoading(false);
                if (resp.DataBeanProperties.ObjectValue) {
                    getDocumentosActivos();
                }
                setModal({ ...modal, "view": false });
            }
        )

    }
    //eliminar documento
    const deleteDocument = () => {
        setLoading(true);
        bean.State = 3;
        service.updateHiringDocumentStage(bean).subscribe(
            (resp: any) => {
                setLoading(false);
                if (resp.DataBeanProperties.ObjectValue) {
                    getDocumentosActivos();
                }
                setModal({ ...modal, "view": false });
            }
        )
    }

    // Ejemplo uso lista de botones 
    const listaBotones = () => {
        const botones = [
            { titulo: 'Editar', icono: 'ri-file-list-line', visible: single.canEdit(), evento: ()=>{setModal({...modal,"view":true})} },
            { titulo: 'Eliminar', icono: 'ri-eraser-line', visible: single.canDelete(), evento: deleteDocument },
            

        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }

    // Ejemplo de uso botones en la cabecera de la la tabla 
    const iconButtonsItem = [
        {
            nombre: 'Agregar', visible: single.canCreate(), icono: 'ri-file-add-line', evento: () => {
                setModal({ ...modal, "view": true });
                setBean({
                    IDHiringModalityDocument: 0,
                    Optional: false,
                    Responsible: 20,
                    State: 1,
                    IDHiringModalityStage: IDHiringModalityStage
                })
            }
        }
    ]


    return (
        <>
            <div className="row">
                <div className="col-md-12">

                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <BasicTable body={listaDocs} head={head} rowSet={setBean} iconButtons={iconButtonsItem} listButtons={listaBotones()} />
                </div>
            </div>
            <Modal modal={modal} updateModal={setModal} eventModal={addDocument}>
                <div className="row">
                    <div className="col-md-6">
                        <BasicSwitch label="Es formulario" estado={tipo === 1 ? true : false} eventChange={(e: any) => { setTipo(e === true ? 1 : 0) }} />
                    </div>
                    <div className="col-md-12">
                        {tipo === 0 ?
                            <>
                                <label>Documento</label>
                                <select className="form-select" value={bean.IDHiringModalityDocument} onChange={(e) => { setBean({ ...bean, "IDHiringModalityDocument": parseInt(e.target.value) }) }}>
                                    {

                                        lista.map((item: any, index: number) => {
                                            <option value="0">...</option>
                                            return (
                                                <option key={index} value={item.DataBeanProperties.IDHiringModalityDocument}>{item.DataBeanProperties.Name}</option>
                                            )
                                        })

                                    }
                                </select>
                            </> :
                            <>
                                <label>Formulario</label>
                                <select className="form-select" value={bean.IDFormType} onChange={(e) => { setBean({ ...bean, "IDFormType": parseInt(e.target.value) }) }}>
                                    {

                                        listaForms.map((item: any, index: number) => {
                                            <option value="0">...</option>
                                            return (
                                                <option key={index} value={item.DataBeanProperties.IDFormType}>{item.DataBeanProperties.Name}</option>
                                            )
                                        })

                                    }
                                </select>
                            </>
                        }
                    </div>

                    <div className="col-md-12">
                        <label>Rol Responsable</label>
                        {/* <select className="form-select" value={bean.Responsible} onChange={(e) => { setBean({ ...bean, "Responsible": parseInt(e.target.value) }) }}>
                            {rolesCodes.map((item: any) => {
                                return (
                                    <option value={item.id}>{item.name}</option>
                                )
                            })}
                        </select> */}
                        
                            <SelectRolOffer titulo="Rol" idSelector={bean.Responsible} onChange={(e)=>{setBean({...bean,"Responsible":e})}}/>
                    </div>
                    <div className="col-md-6 mt-4">
                        <BasicSwitch label="Revision de un Colega" estado={bean.PeerReview} eventChange={(e: any) => { setBean({ ...bean, "PeerReview": e }) }} />
                    </div>
                    <div className="col-md-6 mt-4">
                        <BasicSwitch label="Es Opcional" estado={bean.Optional} eventChange={(e: any) => { setBean({ ...bean, "Optional": e }) }} />
                    </div>
                </div>
            </Modal>


        </>
    )
}