import { useEffect, useState } from "react";

import { Singleton } from "../../../../core/services/Singleton";
import { AdminService } from "../../../../core/services/AdminService";
import { IconButton } from "../../../../core/shared/buttons/IconButton";
import { useDashboardStore } from "../../../pages/hooks/useDashboardStore";
import { toast } from "react-toastify";
import { YearSelector } from "../../../../core/shared/inputs/YearSelector";
import { QuickAction } from "../../../pages/Dashed/components/MenuDashed/MenuDashed";
import { PiSuitcaseSimple } from "react-icons/pi";
import { MenuButton } from "../../../pages/Dashed/components/MenuButton/MenuButton";
import { NoResults } from "../../../../core/shared/NoResults";
import { LuFileBadge, } from "react-icons/lu";
import { TbPigMoney } from "react-icons/tb";

export const EmployeeContract = () => {

    const certificateTabs: QuickAction[] = [
        {
            label: 'Laborales',
            icon: <PiSuitcaseSimple />,
            event: () => { setActiveTab(0) }

        },
        {
            label: 'Tributarios',
            icon: <TbPigMoney />,
            event: () => { setActiveTab(1) }

        },
    ]
    const single = Singleton.getInstance()
    const { setLoading } = useDashboardStore();
    const service = new AdminService();
    const [props, setProps] = useState<any>(
        {
            downloading: false,
            includeSalary: false,
            listFortnight: [],
            listDocuments: [],
            url: '#',
        }
    );
    const [typeName, setTypeName] = useState<any>('')
    const [typeCetificate, setTypeCetificate] = useState<any>('1')
    const [certificateTypes, setCertificateTypes] = useState([])
    const [activeTab, setActiveTab] = useState(0)
    const [year, setYear] = useState(single.getCurrenYear())

    useEffect(() => {
        getDocumentTemplateCatalog()
    }, [activeTab])

    useEffect(() => {
        searchList();
    }, [typeCetificate, activeTab])


    const searchList = () => {
        setLoading(true);
        service.getEmployeeContract(single.getAccountID()).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue) {
                    let listFortnight = resp.DataBeanProperties.ObjectValue;
                    setProps({ ...props, "listFortnight": listFortnight });
                }
                setLoading(false);
            }
        )
    }

    const getDocumentParsed = (idContract: number) => {
        setProps({ ...props, downloading: true })
        const docProps = {
            Year: year,
            IDAccount: single.getAccountID()
        }
        service.getDocumentTemplateParsed(typeCetificate, activeTab === 0 ? { IDContract: idContract } : docProps, 'DOCX').subscribe(
            (resp: any) => {
                let listFortnight = resp.DataBeanProperties.ObjectValue;
                if (resp.DataBeanProperties.ObjectValue.length > 0) {
                    setProps({
                        ...props,
                        listDocuments: listFortnight,
                        downloading: false,
                        url: listFortnight[0].DataBeanProperties.URLLink
                    });
                } else {
                   //console.log(resp);
                    toast.error('Something went wrong')
                    setProps({ ...props, downloading: false })
                }
            })
    }

    const getTicketReport = (idContract: number) => {
        setProps({ ...props, downloading: true })
        service.getEmployeeContractCertificate(idContract, props.includeSalary).subscribe(
            (resp: any) => {
                let listFortnight = resp.DataBeanProperties.ObjectValue;
                if (resp.DataBeanProperties.ObjectValue) {
                    setProps({
                        ...props,
                        listDocuments: listFortnight,
                        downloading: false,
                        url: listFortnight[0].DataBeanProperties.URLLink
                    });
                } else {
                    setProps({ ...props, downloading: false })
                }
            }
        )
    }

    const getDocumentTemplateCatalog = () => {
        single.spinner(true);
        service.getDocumentTemplateCatalog(4, activeTab === 0 ? 'PAYROLL_CERTS' : 'PAYROLL_DIAN', true).subscribe(
            (resp: any) => {
                single.spinner(false);
                if (resp.DataBeanProperties.ObjectValue) {
                    setCertificateTypes(resp.DataBeanProperties.ObjectValue)
                    setTypeCetificate(resp.DataBeanProperties.ObjectValue[0].DataBeanProperties.IDDocumentTemplate)
                }
            })
    }

    return (
        <div className="d-flex w-100 flex-column align-items-center">
            <div className="col-10 my-3 mx-auto ">
                <div className="d-flex gap-5 justify-content-between">
                    {certificateTabs.map((e, i) =>
                        <MenuButton key={i} item={e} labelSize={16} active={activeTab === i} />
                    )}
                </div>
            </div>
            <div className="d-flex gap-2 justify-content-between align-items-end mt-3"
            // style={{ width: '80%' }}
            >
                <div className="mt-3">
                    Tipo de certificado
                    <select className="form-select form-select-sm" value={typeCetificate} onChange={(e) => {
                        setTypeCetificate(e.target.value);
                    }}>
                        {certificateTypes.map((_e, index) =>
                            <option key={index}
                                value={_e.DataBeanProperties.IDDocumentTemplate}
                                onClick={() => setTypeName(_e.DataBeanProperties.Name)}
                            >
                                {_e.DataBeanProperties.Name}
                            </option>
                        )}
                    </select>
                </div>
                {activeTab === 1 &&
                    <div className="w-50">
                        <YearSelector size="sm" value={year} onChange={setYear} />
                    </div>
                }
            </div>
            <div className="col-12">
                {props.listFortnight.length > 0 ?
                    <div className="row">
                        <div className="col-md-12 ">
                            <div className="">
                                <ul className="p-0 m-0 overflow-y-hidden mh-100">
                                    {
                                        props.listFortnight.map((_e: any, _i: number) => (
                                            <li key={_i} className="d-flex mb-4 pb-3 border-bottom ">
                                                <div className="avatar flex-shrink-0 me-3">
                                                    <div style={{ color: '#006699', fontSize: '60px' }}>
                                                        <LuFileBadge />
                                                    </div>
                                                </div>
                                                <div className="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
                                                    <div className="me-2">
                                                        <small className="text-muted d-block mb-1">{_e.DataBeanProperties.Period}</small>
                                                        <h6 className="mb-0">{`Certificación ${activeTab === 0 ? 'Laboral' : 'Tributaria'}`}</h6>
                                                        <small className="text-success">{typeName}</small>
                                                    </div>
                                                    <div className="d-flex align-items-center gap-1">
                                                        <IconButton
                                                            icon="ri-file-download-line"
                                                            loading={props.downloading}
                                                            event={() => getDocumentParsed(_e.DataBeanProperties.IDContract)}
                                                        />
                                                    </div>
                                                </div>
                                            </li>
                                        ))}
                                </ul>
                                {props.listDocuments.length > 0 &&
                                    <div key={123546} className="m-5 alert alert-success">
                                        Su certíficado se ha generado exitósamente, para descargar haga clic
                                        <a href={props.url} target="_blank" rel="noreferrer" onClick={() => setProps({ ...props, "listDocuments": [] })}>
                                            &nbsp;aquí!
                                        </a>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    : <NoResults className="mt-5 pt-3" />
                }
            </div>
        </div>
    )
}