import { useEffect, useState } from "react";
import { _BudgetRPFactory } from "../../../core/services/dataBean/EntityCatalog";
import useDataBean from "../../../core/services/dataBean/useDataBean";
import { toast } from "react-toastify";
import { BasicTable } from "../../theme/Component/BasicTable";
import { Modal } from "../../theme/Component/Modal";
import { SelectBudgetID } from "../../admin/configuracion/BudgetID/SelectBudgetID";
import { SelectBudgetResource } from "../../contract/select/SelectBudgetResource";
import { CDPsEditor } from "./CDPsEditor";
import { InputMoney } from "../../theme/Input/InputMoney";
import { ListMenu } from "../../theme/Component/ListMenu";
import { Singleton } from "../../../core/services/Singleton";
import { PrintJson } from "../../theme/Component/PrintJson";

interface Props {
    contract: any;
    onChange: Function;
}
export const RegisterRFContract = ({ contract, onChange }: Props) => {

    const _budgetRDFactory = useDataBean(_BudgetRPFactory);
    const single = Singleton.getInstance();

    const [lista, setLista] = useState<any>([]);
    const [bean, setBean] = useState<any>({});
    const [initBean, setInitBean] = useState<any>({});

    const [modal, setModal] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });

    useEffect(() => {
        _budgetRDFactory.instance.getBudgetIDListForAdquisitionContract(contract.IDAdquisitionContract, contract.AdquisitionContractDate).then(
            (resp: any) => {
                setLista(resp);
               //console.log("Resouetsa del servicio", resp)
            }
        ).catch(err => toast.error(err))
    }, [contract])

    useEffect(() => {
        if (lista.length > 0) {
            onChange(lista)
        }
       //console.log(lista)
    }, [lista])

    const head = [
        // { title: 'ID', property: 'IDAccountProject', visible: true },
        { title: 'Rubro Presupuestal', property: 'BudgetName', visible: true },
        { title: 'Certificado de Disponibilidad Presupuestal', property: 'CDPDocument', visible: true },
        { title: 'Vr Certificado de Disponibilidad', property: 'CDPValue', visible: true },
        { title: 'Vr CDP por Comprometer', property: 'LeftValue', visible: true },
        { title: 'Valor a Comprometer', property: 'Value', visible: true },
        { title: 'Recurso Presupuestal', property: 'BudgetResourceName', visible: true },
        { title: 'Descripción', property: 'Description', visible: true },
    ]

    const createItems = () => {

       //console.log(bean)

        const updatedLista = lista.filter((item: any) =>
            JSON.stringify(item.DataBeanProperties) !== JSON.stringify(initBean)
        );

        _budgetRDFactory.instance.createBudgetRowForRP(bean.JsonCode, bean.IDBudgetResource, bean.IDDocument, bean.DocDate, bean.Description, bean.Value).then(
            (resp) => {
                let temp = [...updatedLista, {
                    DataBeanProperties: resp,
                    DataBeanName: 'budget.Budget'
                }];
                setLista(temp);
                setBean({});
                setModal({ ...modal, "view": false })
            }
        ).catch(
            err => {
                toast.error(err)
            }
        )
    }



    const iconButtonsItem = [
        { nombre: 'Agregar', visible: !contract.IDAdquisitionContract, icono: 'ri-file-add-line', evento: () => { setModal({ ...modal, "view": true, "name": "Agregar" }) } }
    ]

    const listaBotones = () => {
        const botones = [
            { titulo: 'Editar', icono: 'ri-edit-line', visible: single.canEdit(), evento: () => { setModal({ ...modal, "view": true }) } },
        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }

    const setRowBean = (objeto: any) => {
        setBean(objeto);
        setInitBean(objeto)
    }

    return (
        <div className="row">
            <div className="col">
                <BasicTable iconButtons={iconButtonsItem} listButtons={listaBotones()} head={head} body={lista} rowSet={(e) => { setRowBean(e) }} />
                {/* <PrintJson json={lista} /> */}
            </div>

            <div className="col-md-12">
                <Modal modal={modal} updateModal={setModal} eventModal={createItems}>
                    <div className="row">
                        <div className={`col-md-${contract.IDAdquisitionContract ? "12" : "6"}`}>
                            <CDPsEditor contrat={contract} onChange={(e) => { setBean({ ...bean, "IDDocument": e.IDDocument, "DocDate": e.Since }) }} />
                        </div>
                        <div className="col-md-12">
                            <label>Descripción</label>
                            <textarea className="form-control" value={bean.Description} onChange={(e) => { setBean({ ...bean, "Description": e.target.value }) }} />
                        </div>
                    </div>
                </Modal>
            </div>
        </div>
    )
}