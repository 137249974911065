import { useEffect, useState } from "react";
import useDataBean from "../../../../../core/services/dataBean/useDataBean";
import { _EmploymentBonusValue } from "../../../../../core/services/dataBean/EntityCatalog";
import { Singleton } from "../../../../../core/services/Singleton";
import { BasicTable, MASK_MONEY, MASK_NUMBER } from "../../../../theme/Component/BasicTable";
import { ListMenu } from "../../../../theme/Component/ListMenu";
import { Modal } from "../../../../theme/Component/Modal";
import { ModalConfirm } from "../../../../theme/Component/ModalConfirm";
import { AppointmentEditor } from "../../Appointment/Appointment.editor";

interface Props {
    idEmploymentBonus: number;
}

export const AppointMentByEmploymentBonu = ({ idEmploymentBonus }: Props) => {

    const appointment = useDataBean(_EmploymentBonusValue);
    const single = Singleton.getInstance();

    const [lista, setLista] = useState<any>([]);
    const [bean, setBean] = useState<any>({});

    const [modal, setModal] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalC, setModalC] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });
    const [modalActivate, setModalActivate] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });

    useEffect(() => {
        getLista();
    }, [idEmploymentBonus])


    const getLista = () => {
        appointment.instance.getAppointmentCatalogByEmploymentBonus(idEmploymentBonus).then(
            (resp) => {
                let bonuses = resp;
                bonuses.forEach((bonus) => {
                    bonus.DataBeanProperties.EntailmentSelf = (bonus.DataBeanProperties.EntailmentCode ?? "") + " " + (bonus.DataBeanProperties.EntailmentName ?? "")
                    bonus.DataBeanProperties.ApointmentSelf = (bonus.DataBeanProperties.Code ?? "") + " " + (bonus.DataBeanProperties.Name ?? "")
                });
                setLista(bonuses)
            }
        )
    }

    const create = () => {
        setBean({});
        setModal({ ...modal, "name": "Activar Beneficio", "view": true });
    }

    const deleteItem = () => {
        appointment.instance.deleteAppointmentBonus(appointment.bean.IDAppointment, idEmploymentBonus, single.getAccountID()).then(
            (resp) => {
               //console.log(resp);
                getLista();
                single.delete(appointment.bean.IDAccount, appointment.bean)
                setModalC({ ...modalC, "view": false })
            }
        )
    }

    const createItem = () => {
        appointment.instance.createAppointmentBonus(appointment.bean.IDAppointment, idEmploymentBonus, single.getAccountID()).then(
            (resp) => {
               //console.log(resp);
                setModal({ ...modal, "view": false })
                setModalActivate({ ...modalActivate, "view": false })
                single.create(bean);
                getLista();
            }
        )
    }

    const head = [
        { title: 'ID', property: 'IDAppointmentBonus', visible: true, mask: MASK_NUMBER },
        { title: 'Activado', property: 'ApplyBonus', visible: true },
        { title: 'Nivel Jerarquico', property: 'EntailmentSelf', visible: true },
        { title: 'Nivel Salarial', property: 'ApointmentSelf', visible: true },
        { title: 'Salario', property: 'Salary', visible: true, mask: MASK_MONEY },
    ]

    const iconButtonsItem = [
        // { nombre: 'Agregar', visible: single.canCreate(), icono: 'ri-file-add-line', evento: create }
    ]
    const listaBotones = () => {
        const botones = [
            { titulo: 'Activar Beneficio', icono: 'ri-edit-2-line', visible: single.canEdit(), evento: () => { setModalActivate({ ...modalActivate, "name": "Activar Beneficio", "view": true }) } },
            { titulo: 'Desactivar Beneficio', icono: 'ri-delete-bin-line', visible: single.canDelete(), evento: () => { setModalC({ ...modalC, "name": 'Desactivar Beneficio', "view": true }) } },
        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }


    return (
        <>
            <BasicTable body={lista} head={head} rowSet={appointment.setBean} listButtons={listaBotones()} iconButtons={iconButtonsItem} />

            {/* {modal.view &&
                <Modal modal={modal} updateModal={setModal} eventModal={createItem}>
                    <div className="row">
                        <div className="col-12">
                            <AppointmentEditor idSelector={bean.IDAppointment} onChange={(e) => { setBean({ ...bean, "IDAppointment": e }) }} />
                        </div>
                    </div>
                </Modal>
            } */}

            {modalActivate.view &&
                <ModalConfirm modal={modalActivate} updateModal={setModalActivate} eventModal={createItem}>
                    <strong>Activar Beneficio</strong>
                </ModalConfirm>
            }

            {modalC.view &&
                <ModalConfirm modal={modalC} updateModal={setModalC} eventModal={deleteItem}>
                    <strong>Desactivar Beneficio</strong>
                </ModalConfirm>
            }
        </>
    )
}