import { useEffect, useState } from "react"
import { BasicPage } from "../theme/Component/BasicPage"
import { Singleton } from "../../core/services/Singleton";
import { ContractService } from "../../core/services/ContractService";
import { PrintJson } from "../theme/Component/PrintJson";
import { DataBeanProperties } from '../../core/services/model/server-response.interface';
import { BasicTable } from "../theme/Component/BasicTable";
import { Modal } from "../theme/Component/Modal";
import { DateInput } from "../../core/shared/inputs/DateInput";
import { InputDate } from "../theme/Input/InputDate";
import { ListMenu } from "../theme/Component/ListMenu";
import { ListParticipantes } from "./ListParticipantes";
import { ListDocuments } from "./ListDocuments";
import { DropDown } from "../theme/Component/DropDown";
import { PlanApproval } from "../approval/PlanApproval";
import { approvalPlan } from "../../constans/SupplierConstants";
import { useDashboardStore } from "../pages/hooks/useDashboardStore";


export const PlanTable = () => {

    const single = Singleton.getInstance()
    const { setLoading } = useDashboardStore();
    const contract = new ContractService();
    const [ano, setAno] = useState<number>(0);
    const [idPlan, setIDPlan] = useState<number>(0);
    const [listaP, setLista] = useState<any>([]);
    const [listaM, setListaM] = useState<any>([]);
    const [bean, setBean] = useState<any>({});
    const [action, setAccion] = useState<any>({});
    const [modal, setModal] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalP, setModalP] = useState<any>({ name: 'Participantes', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalDoc, setModalDoc] = useState<any>({ name: 'Adjuntos', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalAprovador, setModalAprobador] = useState<any>({ name: 'Aprobaciónes', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });

    useEffect(() => {
        setAno(single.getCurrenYear());
        setLista([]);
    }, [])
    useEffect(() => {

        if (action.id === 1) {
            setModalAprobador({ ...modalAprovador, "view": true, btnSubmit: false });
        }

    }, [action])

    useEffect(() => {
       //console.log("Año actualizado");
        getPlanTableCatalogPorPropiedad();
    }, [idPlan])

    const getAdquisitionPlanByYear = () => {
        setLoading(true);
        contract.getAdquisitionPlanByYearSearch(ano).subscribe(
            (resp: any) => {
               //console.log(resp);
                if (resp.DataBeanProperties.ObjectValue) {
                    setLista(resp.DataBeanProperties.ObjectValue);
                    if (resp.DataBeanProperties.ObjectValue.length > 0) {
                        let item = resp.DataBeanProperties.ObjectValue[0];
                        setIDPlan(item.DataBeanProperties.IDAdquisitionPlan);
                    }
                }
                setLoading(false);
            }
        )

    }
    const getPlanTableCatalogPorPropiedad = () => {
        setLoading(true);
        contract.getPlanTableCatalogPorPropiedad("IDAdquisitionPlan", idPlan, null).subscribe(
            (resp: any) => {
               //console.log(resp);
                if (resp.DataBeanProperties.ObjectValue) {
                    setListaM(resp.DataBeanProperties.ObjectValue);
                   //console.log(resp.DataBeanProperties.ObjectValue);

                }
                setLoading(false);
            }
        )

    }
    const updateItem = () => {

        setModal({ ...modal, ['view']: false });
        setModalDoc({ ...modalDoc, ['view']: false });
        setModalP({ ...modalP, ['view']: false });

        setLoading(true);
        contract.updatePlanTable(bean).subscribe(
            (resp: any) => {
                setLoading(false);
                if (resp.DataBeanProperties.ObjectValue) {
                    getPlanTableCatalogPorPropiedad();
                }
                single.update(bean);
            });

    }

    const agregarItem = () => {

        let d = new Date();
        let b = {
            IDAdquisitionPlan: idPlan,
            TableDate: single.getCurrenDate(),
            Observation: '',
            Participants: '[]',
            StatePlan: 1
        }

        setBean(b);
        setModal({ ...modal, ['view']: true });

    }
    const updateLista = (lista: any) => {

        bean.Participants = JSON.stringify(lista);

    }

    const head = [
        { title: 'ID', property: 'IDPlanTable', visible: true },
        { title: 'Observaciones', property: 'Observation', visible: true },
        { title: 'Fecha', property: 'TableDate', visible: true },
    ]

    const iconButtonsItem = [

        { nombre: 'Crear Mesa', visible: single.canCreate(), icono: 'ri-file-add-line', evento: agregarItem }

    ]
    const listaBotones = () => {
        const botones = [

            { titulo: 'Editar', icono: 'ri-file-list-line', visible: single.canEdit(), evento: () => { setModal({ ...modal, "view": true }) } },
            { titulo: 'Participantes', icono: 'ri-file-user-line', visible: true, evento: () => { setModalP({ ...modalP, "view": true }) } },
            { titulo: 'Adjuntos', icono: 'ri-attachment-line', visible: true, evento: () => { setModalDoc({ ...modalDoc, "view": true }) } },
        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }


    const eventButtons = (btn: any) => {



    }

    // ejemplo de uso con dropdown
    const actionsDropDown = [
        { id: 1, name: 'Solicitud de aprobación', permiso: null, activo: true },
    ];
    const acctionbuttons = () => {
        return (
            <div className="row">
                <div className="col-md-12">
                    {idPlan > 0 &&
                        <DropDown key={single.getRandomInt()} lista={actionsDropDown} eventClick={setAccion} />
                    }
                </div>
            </div>
        )
    }

    return (

        <BasicPage titulo="Mesas de Trabajo">
            <div className="row">
                <div className="col-md-4">
                    <div className="input-group">
                        <input type="text" className="form-control" aria-label="Año del plan de compras" value={ano} onChange={(e) => { setAno(parseInt(e.target.value)) }} />
                        <button className="btn btn-primary shadow-none" type="button" id="button-addon2" onClick={getAdquisitionPlanByYear}>Buscar</button>
                    </div>
                </div>
                <div className="col-md">

                    <select className="form-select" value={idPlan} onChange={(e) => setIDPlan(parseInt(e.target.value))}>
                        {listaP.map((item: any, index: number) => {
                            return (
                                <option key={index} value={item.DataBeanProperties.IDAdquisitionPlan}>{item.DataBeanProperties.Description}</option>
                            )
                        })}
                    </select>

                </div>
            </div>

            <div className="row">
                <div className="col">
                    <BasicTable body={listaM} head={head} headButtons={acctionbuttons()} rowSet={setBean} iconButtons={iconButtonsItem} listButtons={listaBotones()}></BasicTable>
                    <Modal modal={modal} updateModal={setModal} eventModal={updateItem} >
                        <div className="row">
                            <div className="col-md-12">
                                <InputDate label="Fecha de la Mesa" name="DateTable" value={bean.TableDate} setDate={(e: any) => { setBean({ ...bean, "TableDate": e }) }}></InputDate>
                            </div>
                            <div className="col-md-12">
                                <label>Tema del Plan</label>
                                <textarea className="form-control" value={bean.Observation} onChange={(e) => { setBean({ ...bean, "Observation": e.target.value }) }}></textarea>
                            </div>
                        </div>
                    </Modal>
                    <Modal modal={modalP} updateModal={setModalP} eventModal={updateItem} >
                        <div className="row">
                            <div className="col-md-12">
                                {bean.IDPlanTable &&
                                    <ListParticipantes planBean={bean} updateLista={updateLista} />
                                }


                            </div>
                        </div>
                    </Modal>
                    <Modal modal={modalDoc} updateModal={setModalDoc} eventModal={updateItem} >
                        <div className="row">
                            <div className="col-md-12">
                                <ListDocuments ListDocuments={bean.Attach} updateList={(e: any) => { setBean({ ...bean, "Attach": JSON.stringify(e) }) }} />
                            </div>
                        </div>
                    </Modal>
                    <Modal modal={modalAprovador} updateModal={setModalAprobador}>
                        <PlanApproval type={10} idbean={idPlan} />
                    </Modal>
                    {/* <PrintJson json={approvalPlan}/> */}
                </div>
            </div>
        </BasicPage>
    )
}