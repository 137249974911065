import { useEffect, useState } from 'react';
import { Selectortipodecliente } from '../../bpm/config/Componentes/Selectortipodecliente';
import { SelectoCustomerType } from '../../bpm/config/Componentes/SelectoCustomerType';
import { BasicTable } from './BasicTable';
import { Singleton } from "../../../core/services/Singleton";
import { BpmService } from "../../../core/services/BpmService";
import { BasicButton } from './BasicButton';
import { SelectoCustomerTypeDepe } from '../../bpm/config/Componentes/SelectoCustomerTypeDepe';
import { ModalConfirm } from './ModalConfirm';
import { ListMenu } from './ListMenu';
import { SelectCharacterization } from '../../bpm/config/Componentes/SelectCharacterization';
import { useDashboardStore } from '../../pages/hooks/useDashboardStore';

interface Props {
    id: number;
}

// const [modal, setModal] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
export const CaracterizacionDocRequeridos = ({ id }: Props) => {

    const [selector2, setSelector2] = useState(0);
    const [lista, setLista] = useState([]);
    const [bean, setBean] = useState<any>({});
    const [magregar, setMagregar] = useState<any>({ name: 'Confirmar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });
    const [meliminar, setMeliminar] = useState<any>({ name: 'Confirmar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });
    const single = Singleton.getInstance()
    const { setLoading } = useDashboardStore();
    const service = new BpmService();

    const idBean = 'IDDocumentCharacterization';

    const setRowBean = (bean: any) => {
        setBean(bean);
    }

    useEffect(() => {
        getLista();
    }, [id])

    const head = [
        { title: 'ID', property: idBean },
        { title: 'Nombre', property: 'CharacterizationName' },
    ]

    const getLista = () => {
        setLoading(true);
        service.getDocumentCharacterizationCatalog(id).subscribe(
            (resp: any) => {

                if (resp.DataBeanProperties.ObjectValue) {
                    setLista(resp.DataBeanProperties.ObjectValue);
                }
                setLoading(false)
            }
        );
    }

    const updateItem = () => {

        setLoading(true);
        service.addDocumentCharacterization(id, selector2).subscribe(
            (resp: any) => {
                setLoading(false);
                if (resp.DataBeanProperties.ObjectValue) {
                    getLista();
                }
            });
        setMagregar({ ...magregar, 'view': false });
    }

    const eliminarItem = () => {
        setLoading(true);
        service.deleteDocumentCharacterization(bean[idBean]).subscribe(
            (resp: any) => {
                setLoading(false);
                if (resp) {
                    getLista();
                }
            }
        );
        setMeliminar({ ...meliminar, ['view']: false });
    }

    const iconButtonsItem = [
        {
            nombre: "Agregar",
            visible: single.canCreate(),
            icono: "ri-file-add-line",
            evento: () => { setMagregar({ ...magregar, 'view': true }) },
        },
    ];

    const listaBotones = () => {
        const botones = [
            {
                titulo: "Eliminar",
                icono: "ri-file-user-line",
                visible: single.canDelete(),
                evento: () => {
                    setMeliminar({ ...meliminar, 'view': true });
                },
            },
        ];
        return <ListMenu listaBotones={botones} />;
    };

    const mostrar = () => {
       //console.log(lista)
    }

    return (
        <>
            <div className='col-md-12'>
                <BasicTable iconButtons={iconButtonsItem} listButtons={listaBotones()} body={lista} head={head} rowSet={setRowBean}></BasicTable>
                <BasicButton eventClick={mostrar} icon=''></BasicButton>
            </div>

            <ModalConfirm modal={magregar} updateModal={setMagregar} eventModal={updateItem}>
                <div className="col-md-12">
                    {/* <SelectoCustomerTypeDepe titulo="Caracterizaciones a agregar" idSelector={selector2} onChange={(a: any) => { setSelector2(a) }} ></SelectoCustomerTypeDepe> */}
                    <SelectCharacterization idSelector={selector2} onChange={(a: any) => { setSelector2(a) }} titulo='Caractterizaciones a agregar'></SelectCharacterization>
                </div>
            </ModalConfirm>
            <ModalConfirm modal={meliminar} updateModal={setMeliminar} eventModal={eliminarItem}>
                Esta seguro que desea eliminar el siguiente item <strong>{bean.Characterization}</strong>
            </ModalConfirm>
        </>
    )
}