import { useEffect, useState } from "react";

interface Props {
    json: any;
}
export const PrintObjectJson = ({ json }: Props) => {


    const [miJson, setJson] = useState<any>();
    useEffect(() => {

        if (json) {
            try {
                setJson(JSON.parse(json));
            } catch (error) {
                setJson(json);
            }

        } else {
            setJson({});
        }

    }, [json])

    return (

        <div className="row">
            <div className="col">
                {miJson &&
                    <div className="table table-responsive" >
                        <table className="table table-striped">
                            <tbody>
                                {
                                    Object.keys(miJson).map((item: any) => {
                                        return (
                                            <tr>
                                                <td>
                                                    {item}
                                                </td>
                                                <td>
                                                    <pre>{JSON.stringify(miJson[item], null, 2)}</pre>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                }
            </div>
        </div>

    )

}