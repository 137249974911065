import { useEffect, useState } from 'react';
import { Modal } from '../../../../theme/Component/Modal';
import useDataBean from '../../../../../core/services/dataBean/useDataBean';
import { _EmployeeContract } from '../../../../../core/services/dataBean/EntityCatalog';

interface Props {
    label?: string;
    onChange: Function;
    values?: any;
}

export const MultiEmploymentTypeEditor = ({ onChange, label, values }: Props) => {

    const [modal, setModal] = useState<any>({ name: 'Lista de Grupos Laborales', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });
    const [lista, setLista] = useState<any>([]);
    const employmentType = useDataBean(_EmployeeContract);
    const [selectedEmployeType, setSelectedEmployeType] = useState([]);
    const [selectedEmployeTypeNames, setSelectedEmployeTypeName] = useState([]);

    useEffect(() => {
        if (Array.isArray(values)) {
            setSelectedEmployeType(values);
        } else if (typeof values === 'string') {
            let x = JSON.parse(values);
            setSelectedEmployeType(x);
        } else {
            console.error(`Error: Se esperaba un array o una cadena, pero se recibió ${typeof values}`);
        }
        getLista();
    }, [values]);

    useEffect(() => {
        const selectedMonthNames = lista
            .filter(month => selectedEmployeType.includes(month.DataBeanProperties.IDEmploymentType))
            .map(month => month.DataBeanProperties.Code);

        setSelectedEmployeTypeName(selectedMonthNames);
    }, [selectedEmployeType, lista]);

    const getItem = () => {
        onChange(selectedEmployeType);
        setModal({ ...modal, "view": false });
    }

    const getLista = () => {
        employmentType.instance.getEmploymentTypeCatalog(null).then(
            (resp) => {
                setLista(resp);
                if (!values) {
                    setSelectedEmployeType(resp.map(userTypes => userTypes.DataBeanProperties.IDEmploymentType));
                }
            }
        );
    }

    const handleCheckboxChange = (value) => {
        const updatedSelectedMonths = selectedEmployeType.includes(value)
            ? selectedEmployeType.filter(month => month !== value)
            : [...selectedEmployeType, value];

        setSelectedEmployeType(updatedSelectedMonths);
    };

    return (
        <>
            <label>{label ?? "Lista de Grupos Laborales"}</label>
            <span
                className="form-control text-wrap"
                style={{ minHeight: '35px' }}
                onClick={() => setModal({ ...modal, view: true })}
            >
                {selectedEmployeTypeNames.length ? selectedEmployeTypeNames.join(', ') : "Grupos Laborales Seleccionados"}
            </span>

            <Modal modal={modal} updateModal={setModal} eventModal={getItem}>
                <>
                    <div className="row">
                        {lista.map((employeType) => (
                            <div className='d-flex' key={employeType.DataBeanProperties.IDEmploymentType}>
                                <input
                                    type="checkbox"
                                    value={employeType.DataBeanProperties.IDEmploymentType}
                                    checked={selectedEmployeType.includes(employeType.DataBeanProperties.IDEmploymentType)}
                                    onChange={() => handleCheckboxChange(employeType.DataBeanProperties.IDEmploymentType)}
                                />
                                <div className='p-1'>
                                    {employeType.DataBeanProperties.Code}-{employeType.DataBeanProperties.Name}
                                </div>
                            </div>
                        ))}
                    </div>
                </>
            </Modal>
        </>
    );
}
