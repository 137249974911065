
import { useEffect, useState } from 'react';
import { roleApproval } from '../../constans/SupplierConstants';
import { ContractService } from '../../core/services/ContractService';
import { AccountEditor } from '../editor/AccountEditor';
import { BasicButton } from '../theme/Component/BasicButton';
import { BasicTable } from '../theme/Component/BasicTable';
import { ListMenu } from '../theme/Component/ListMenu';
import { useDashboardStore } from '../pages/hooks/useDashboardStore';
interface Props {
    IDPlanApproval: number;
}
export const ApprovalUser = ({ IDPlanApproval }: Props) => {


    const { setLoading } = useDashboardStore();
    const service = new ContractService();
    const [lista, setLista] = useState<any>([]);
    const [account, setAccount] = useState<any>({});
    const [bean, setBean] = useState<any>({});
    const [idRol, setIDRol] = useState<number>(0);

    useEffect(() => {
        getLista();
        setIDRol(roleApproval[0].id);
    }, [IDPlanApproval])

    const getLista = () => {
        setLoading(true);
        let props: any = {
            IDPlanApproval: IDPlanApproval
        };
        service.ApprovalUserRender(IDPlanApproval).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue) {
                    let lista: any = resp.DataBeanProperties.ObjectValue;
                    lista.forEach((element: any) => {
                        if (element.DataBeanProperties.State === 1) {
                            element.DataBeanProperties.StateName = 'Aprobado';
                        }
                        if (element.DataBeanProperties.State === 2) {
                            element.DataBeanProperties.StateName = 'Rechazado';
                        }
                        if (element.DataBeanProperties.State === 0) {
                            element.DataBeanProperties.StateName = 'Pendiente';
                        }
                    });
                    setLista(resp.DataBeanProperties.ObjectValue);
                }
                setLoading(false);
            }
        )
    }
    const getLista2 = () => {
        setLoading(true);
        let props: any = {
            IDPlanApproval: IDPlanApproval
        };
        service.getApprovalUserCatalogPorPropiedades(props, null).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue) {

                    setLista(resp.DataBeanProperties.ObjectValue);
                }
                setLoading(false);
            }
        )
    }

    const agregarFuncionario = () => {
       //console.log(account, idRol);
        setLoading(true);
        let b = {
            IDPlanApproval: IDPlanApproval,
            State: 0,
            TypeRole: idRol,
            IDAccount: account.IDAccount
        }
        if (idRol > 0)
            service.updateApprovalUser(b).subscribe(
                (resp: any) => {

                    setLoading(false);
                    if (resp.DataBeanProperties.ObjectValue) {
                        setBean(resp.DataBeanProperties.ObjectValue);
                    }
                    getLista();
                }
            )
    }
    const eliminarUser = () => {
       //console.log(bean);
        setLoading(true);
        service.deleteApprovalUser(bean.IDApprovalUser).subscribe(
            (resp: any) => {
                getLista();
            }
        )

    }

    const head = [
        { title: 'ID', property: 'IDApprovalUser', visible: true },
        { title: 'Funcionario', property: 'EntityName', visible: true },
        { title: 'Rol', property: 'RoleName', visible: true },
        // {title:'Aprobación',property:'StateName',visible:true},        
    ]

    const listaBotones = () => {
        const botones = [
            { titulo: 'Eliminar', icono: 'ri-file-add-line', visible: true, evento: eliminarUser },
        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }

    return (
        <>
            <div className="row">
                <div className="col-md-6">
                    <AccountEditor idAccount={account.IDAccount} label='Funcionario' onChange={(e: any) => { setAccount(e) }} />
                </div>
                <div className="col-md-4">
                    <label>Rol en el proceso</label>
                    <select className='form-select' value={idRol} onChange={(e) => { setIDRol(parseInt(e.target.value)) }}>
                        {roleApproval.map((item: any, index: number) => {
                            return (
                                <option value={item.id}>{item.value}</option>
                            )
                        })}
                    </select>
                </div>
                <div className="col-md-2 mt-4">
                    <BasicButton disable={!(idRol > 0 && account.IDAccount > 0)} icon='ri-file-user-line' eventClick={agregarFuncionario} clase='warning'>Agregar</BasicButton>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <BasicTable listButtons={listaBotones()} body={lista} head={head} rowSet={setBean}></BasicTable>
                </div>
            </div>
            {/* <PrintJson json={roleApproval}/> */}
        </>
    )
}