import { useEffect, useState } from "react";
import { _EmployeeNovelty } from "../../../../core/services/dataBean/EntityCatalog";
import useDataBean from "../../../../core/services/dataBean/useDataBean";
import { Singleton } from "../../../../core/services/Singleton";
import { ListMenu } from "../../../theme/Component/ListMenu";
import { BasicPage } from "../../../theme/Component/BasicPage";
import { BasicTable, MASK_DATE, MASK_NUMBER } from "../../../theme/Component/BasicTable";
import { Modal } from "../../../theme/Component/Modal";
import { AccountEditor } from "../../../editor/AccountEditor";
import { YearSelector } from "../../../../core/shared/inputs/YearSelector";
import { PayrollMonthsConstantsEditor } from "../../ParametrosTalentoHumano/EmployeeBonus/Editors/PayrollMonthsConstants.editor";
import { ModalConfirm } from "../../../theme/Component/ModalConfirm";
import { toast } from "react-toastify";
import { AbsenteeismTypeConstantsEditor } from "./NoveltyEditors/AbsenteeismTypeConstants.editor";
import { InputDate } from "../../../theme/Input/InputDate";
import { BasicSwitch } from "../../../theme/Component/BasicSwitch";
import { BasicButton } from "../../../theme/Component/BasicButton";

export const EmployeeNovelty = () => {

    const employeeNovelty = useDataBean(_EmployeeNovelty);
    const single = Singleton.getInstance();

    const [IdAcoount, setIdAcoount] = useState<number>(null)
    const [montedit, setMontedit] = useState<number>(null)
    const [year, setYear] = useState<number>(single.getCurrenYear())
    const [lista, setLista] = useState<any>([])
    const [rows, setRows] = useState<number>(15);

    const [modal, setModal] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalC, setModalC] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });

    const getLista = () => {
        employeeNovelty.instance.getEmployeeNovelties(IdAcoount, year, montedit).then(
            (resp: any) => {
                if (resp) {
                    let List = resp;
                    List.forEach((e: any) => {
                        const fromDate = e.DataBeanProperties.FromDate ? new Date(e.DataBeanProperties.FromDate) : null;
                        const uptoDate = e.DataBeanProperties.UptoDate ? new Date(e.DataBeanProperties.UptoDate) : null;


                        if (fromDate && uptoDate) {
                            const diffTime = Math.abs(uptoDate.getTime() - fromDate.getTime());
                            const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
                            e.DataBeanProperties.FechaTotalDays = diffDays + 1;
                        } else {

                            e.DataBeanProperties.FechaTotalDays = 0;
                        }
                    });

                   //console.log("Lista con días calculados:", List);

                    setLista(List);
                }
            }
        )
    }

    const create = () => {
        employeeNovelty.setBean({
            IDEmployee: single.getAccountID(),
        });
        setModal({ ...modal, "view": true });
    }

    const deleteItem = () => {
        employeeNovelty.instance.deleteEmployeeNovelty(employeeNovelty.bean.IDNovelty).then(
            (resp: any) => {
                getLista();
                setModalC({ ...modalC, "view": false });
            }
        )
    }

    // const editaItem = () => {
    //     employeeNovelty.update().then(
    //         (resp: any) => {
    //             getLista();
    //             setModal({ ...modal, "view": false });
    //         }
    //     ).catch(err => {
    //         toast.error(err);
    //     })

    // }

    const editaItem = () => {
        if (!employeeNovelty.bean.Description) {
            toast.error("El campo 'Observaciones' es obligatorio.");
            return;
        }

        employeeNovelty.update().then(
            (resp: any) => {
                getLista();
                setModal({ ...modal, "view": false });
            }
        ).catch(err => {
            toast.error(err);
        });
    }

    const validateGetLista = () => {
        if (!year) {
            toast.error("Año no valido")
        } else if (isNaN(montedit)) {
            toast.error("Mes no valido")
        } else if (isNaN(rows) || rows <= 0) {
            toast.error("El número de registros debe ser mayor a 0");
        } else {
            getLista();
        }
    }

    const head = [
        { title: 'ID', property: 'IDNovelty', visible: true, mask: MASK_NUMBER },
        { title: 'Empleado', property: 'AccountName', visible: true },
        { title: 'Tipo', property: 'TypeName', visible: true },
        { title: 'Inicio en', property: 'FromDate', visible: true, mask: MASK_DATE },
        { title: 'Terminación a', property: 'UptoDate', visible: true, mask: MASK_DATE },
        { title: 'Liquidar en el mes', property: 'Extending', visible: true },
        { title: 'Observaciones', property: 'Description', visible: true },
        { title: 'Funcionario', property: 'EmployeeName', visible: true },
        { title: 'Total Días', property: 'FechaTotalDays', visible: false },
    ]

    const listaBotones = () => {
        const botones = [
            {
                titulo: 'Editar Novedad Laboral', icono: 'ri-file-list-line', visible: true, evento: () => {
                    setModal({ ...modal, "name": "Editar Novedad Laboral", "view": true })
                    employeeNovelty.setBean({
                        ...employeeNovelty.bean,
                        IDEmployee: single.getAccountID(),
                    });
                }
            },
            { titulo: 'Eliminar', icono: 'ri-delete-bin-line', visible: single.canDelete(), evento: () => { setModalC({ ...modalC, "view": true }) } },
        ]

        return (
            <ListMenu listaBotones={botones} />
        )
    }

    const iconButtonsItem = [
        { nombre: 'Agregar', visible: single.canCreate(), icono: 'ri-file-add-line', evento: create }
    ]

    return (
        <>
            <BasicPage titulo="Ingresar Novedades Laborales">
                <div className="row">
                    <div className="col-md-4">
                        <AccountEditor label="Empleado" idAccount={IdAcoount} onChange={(e) => { setIdAcoount(e.IDAccount) }} />
                    </div>
                    <div className="col-md-3">
                        <YearSelector tittle="Año vigencia" value={year} onChange={setYear} />
                    </div>
                    <div className="col-md-3">
                        <PayrollMonthsConstantsEditor label="Mes" idSelector={montedit} onChange={(e) => { setMontedit(e) }} />
                    </div>
                    <div className="col-2">
                        <label># de registros por trabla</label>
                        <input className="form-control" value={rows} onChange={(e) => { setRows(parseInt(e.target.value)) }} />
                    </div>
                    <div className="col-2 mt-2">

                        <BasicButton icon="ri-search-line" eventClick={validateGetLista} >Buscar</BasicButton>

                    </div>
                </div>


                <BasicTable customRows={rows} iconButtons={iconButtonsItem} listButtons={listaBotones()} head={head} body={lista} rowSet={employeeNovelty.setBean} />
            </BasicPage>

            {modal.view &&
                <Modal modal={modal} updateModal={setModal} eventModal={() => { editaItem() }} >
                    <div className="row">
                        <div className="col-md-6">
                            <AccountEditor label="Empleado" idAccount={employeeNovelty.bean.IDAccount} onChange={(e) => { employeeNovelty.setBean({ ...employeeNovelty.bean, "IDAccount": e.IDAccount }) }} />
                        </div>
                        <div className="col-6">
                            <AbsenteeismTypeConstantsEditor idSelector={employeeNovelty.bean.Type} onChange={(e) => { employeeNovelty.setBean({ ...employeeNovelty.bean, "Type": e }) }} label="Tipo de Ausentismo" />
                        </div>
                        <div className="col-6">
                            <InputDate label="Inicio en" name="FromDate" value={employeeNovelty.bean.FromDate} setDate={(e: any) => { employeeNovelty.setBean({ ...employeeNovelty.bean, 'FromDate': e }) }} />
                        </div>
                        <div className="col-6">
                            <InputDate label="Terminación a" name="FromDate" value={employeeNovelty.bean.UptoDate} setDate={(e: any) => { employeeNovelty.setBean({ ...employeeNovelty.bean, 'UptoDate': e }) }} />
                        </div>
                        <div className="col-6">
                            <BasicSwitch label="Liquidar en el mes" estado={employeeNovelty.bean.Extending} eventChange={(e) => { employeeNovelty.setBean({ ...employeeNovelty.bean, "Extending": e }) }} />
                        </div>
                        <div className="col-12">
                            <label>Observaciones:</label>
                            <textarea name="Description" className="form-control" value={employeeNovelty.bean.Description} onChange={employeeNovelty.handleChange} />
                        </div>
                    </div>
                </Modal>
            }

            <ModalConfirm modal={modalC} updateModal={setModalC} eventModal={deleteItem}>
                <strong>Eliminar item</strong>
            </ModalConfirm>
        </>
    )
}