import { DataBean } from "../DataBean";

export class BudgetReductionRPFactory extends DataBean {
  public async createBudgetRowForCreditContraCredit(
    idBudgetType: number,
    budgetIDStr: string,
    creditBudgetIDStr: string,
    value: number,
    description: string
  ): Promise<any> {
    return this.getCustomService(
      "QuickDataERPCore",
      "budget.Budget_createBudgetRowForCreditContraCredit_Number_String_String_Number_String",
      [idBudgetType, budgetIDStr, creditBudgetIDStr, value, description]
    );
  }

  public async getRPDocuments(idAccount: number, date: any): Promise<any> {
    return this.getCustomService(
      "QuickDataERPCore",
      "java.util.List_getRPDocuments_Number_java.util.Date",
      [idAccount, date]
    );
  }

  public async createrRPReduction(
    rpIDDocument: number,
    rpDocumentDate: any,
    accountantDate: any,
    externalConsecutive: number,
    items: any,
    description: string,
    idEmployee: number,
    createPdf: boolean
  ): Promise<any> {
    return this.getCustomService(
      "QuickDataERPCore",
      "com.quickdataerp.bean.accountant.AccountantDocument_createrRPReduction_Number_java.util.Date_java.util.Date_Number_java.util.List_String_Number_Boolean",
      [
        rpIDDocument,
        rpDocumentDate,
        accountantDate,
        externalConsecutive,
        items,
        description,
        idEmployee,
        createPdf,
      ]
    );
  }
}
