import { useEffect, useState } from "react";
import { _CompetitionType, _Entailment, _FunctionalCompetence, _MacroProcess } from "../../../../core/services/dataBean/EntityCatalog";

import { Singleton } from "../../../../core/services/Singleton";
import { toast } from "react-toastify";
import { ListMenu } from "../../../theme/Component/ListMenu";
import { BasicPage } from "../../../theme/Component/BasicPage";
import { BasicTable } from "../../../theme/Component/BasicTable";
import { Modal } from "../../../theme/Component/Modal";
import { ModalConfirm } from "../../../theme/Component/ModalConfirm";
import useDataBean from "../../../../core/services/dataBean/useDataBean";
import { PrintJson } from "../../../theme/Component/PrintJson";
import { PrintObjectJson } from "../../../theme/Component/PrintObjectJson";
import { CompetitionTypeEditor } from "../CompetitionType/CompetitionType.editor";
import { MainThemeEditor } from "../MainTheme/MainTheme.editor";
import { EntailmentEditor } from "../Entailment/Entailment.editor";
import { FuncionalsFamilyEditor } from "../FunctionalsFamily/FuncionalsFamily.editor";
import { Alert } from "../../../theme/Component/Alert";
import { PerformanceCriteria } from "./PerformanceCriteria";




export const FuncionalCompetence = () => {

    const FuncionalCompetence = useDataBean(_FunctionalCompetence);

    const single = Singleton.getInstance();
    const [compMainTheme, setMainThemeCompType] = useState<number>(null)
    const [Entailment, setEntailment] = useState<number>(null)
    const [funcionals, setfuncionals] = useState<number>(null)
    const [lista, setLista] = useState<any>([]);
    const [modalcriterios, setModalcriterios] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modal, setModal] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalC, setModalC] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });

    useEffect(() => {
        getLista();

    }, [compMainTheme, Entailment, funcionals])

    const getLista = () => {

        FuncionalCompetence.instance.getFunctionalCompetenceCatalog(compMainTheme, Entailment, funcionals).then(
            (resp: any) => {
                if (resp) {
                    setLista(resp)
                   //console.log(resp)
                }
            }
        )

    }

    const create = () => {
        FuncionalCompetence.setBean({
            IDMainTheme: compMainTheme,
            IDEntailment: Entailment,
            IDFamily: funcionals


        });
        setModal({ ...modal, "view": true });
    }
    const deleteItem = () => {
        FuncionalCompetence.deleteItemVoid(FuncionalCompetence.bean.IDFunctionalCompetence).then(
            (resp: any) => {
                getLista();
                setModalC({ ...modalC, "view": false });
            }
        )

    }
    const editaItem = () => {
       //console.log(FuncionalCompetence.bean);
        FuncionalCompetence.update().then(
            (resp: any) => {
                getLista();
                setModal({ ...modal, "view": false });
            }
        ).catch(err => {
            toast.error(err);
        })

    }

    const head = [
        { title: 'ID', property: 'IDFunctionalCompetence', visible: true },
        { title: 'Nombre', property: 'Name', visible: true },
        { title: 'Descripción', property: 'Description', visible: true },
        { title: 'Código', property: 'Code', visible: true },
        { title: 'Fecha', property: 'Since', visible: true },


    ]

    const iconButtonsItem = [
        { nombre: 'Agregar', visible: single.canCreate(), icono: 'ri-file-add-line', evento: create }
    ]
    const listaBotones = () => {
        const botones = [
            { titulo: 'Criterios de Desempeño', icono: 'ri-file-user-line', visible: true, evento: () => { setModalcriterios({ ...modalcriterios, "view": true }) } },
            { titulo: 'Editar', icono: 'ri-edit-2-line', visible: single.canEdit(), evento: () => { setModal({ ...modal, "view": true }) } },
            { titulo: 'Eliminar', icono: 'ri-delete-bin-line', visible: single.canDelete(), evento: () => { setModalC({ ...modalC, "view": true }) } },

        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }


    return (
        <BasicPage titulo="Diccionario de Competencias Funcionales ">
            <div className="row">
                <div className="col-md-4"> <MainThemeEditor idSelector={compMainTheme} onChange={(e) => { setMainThemeCompType(e) }} /></div>
                <div className="col-md-4">   <EntailmentEditor idSelector={Entailment} onChange={(e) => { setEntailment(e) }} /></div>
                <div className="col-md-4"><FuncionalsFamilyEditor idSelector={funcionals} onChange={(e) => { setfuncionals(e) }} /></div>

            </div>
            <div className="row">
                <div className="col-md-12">

                    <BasicTable body={lista} head={head} rowSet={FuncionalCompetence.setBean} listButtons={listaBotones()} iconButtons={iconButtonsItem}></BasicTable>
                    <Modal modal={modal} updateModal={setModal} eventModal={editaItem}>
                        <div className="row">
                            <div className="col-md-6">
                                <label>Nombre</label>
                                <input type="text" name="Name" className="form-control" value={FuncionalCompetence.bean.Name} onChange={FuncionalCompetence.handleChange} />

                            </div>

                            <div className="col-md-6">
                                <label>Código</label>
                                <input type="number" name="Code" className="form-control" value={FuncionalCompetence.bean.Code} onChange={FuncionalCompetence.handleChange} />
                            </div>
                            <div className="col-md-12">
                                <label>Description</label>
                                <input type="text" name="Description" className="form-control" value={FuncionalCompetence.bean.Description} onChange={FuncionalCompetence.handleChange} />
                            </div>

                        </div>
                    </Modal>

                    <Modal modal={modalcriterios} updateModal={setModalcriterios} eventModal={editaItem}>
                        <PerformanceCriteria IDFunctionalCompetence={FuncionalCompetence.bean.IDFunctionalCompetence} />
                    </Modal>

                    <ModalConfirm modal={modalC} updateModal={setModalC} eventModal={deleteItem}>
                        <strong>Eliminar item</strong>
                    </ModalConfirm>
                </div>

            </div>
        </BasicPage>
    )
}