import { useEffect, useState } from "react";
import { AdminService } from "../../../../core/services/AdminService";
import { BsClockHistory, BsFileEarmarkPersonFill, BsFilePerson, BsFillArrowDownCircleFill, BsFillArrowRightCircleFill, BsSend, BsSendCheckFill } from "react-icons/bs";
import { Singleton } from "../../../../core/services/Singleton";
import { toast } from "react-toastify";
import { Modal } from "../../../theme/Component/Modal";
import { AccountEditor } from "../../../editor/AccountEditor";
import { BasicTable } from "../../../theme/Component/BasicTable";


interface Props {
    IDAccount: number,
}

export const Bandeja = ({ IDAccount }: Props) => {

    const service = new AdminService();
    const single = Singleton.getInstance();
    const [lista, setLista] = useState<any>([]);
    const [filteredLista, setFilteredLista] = useState(lista);
    const [listahist, setListahist] = useState<any>([]);
    const [bean, setBean] = useState<any>({});
    const [beandos, setBeandos] = useState<any>({});
    const [beantres, setBeantres] = useState<any>({});
    const [showTextarea, setShowTextarea] = useState(false);
    const [activeIndex, setActiveIndex] = useState<number | null>(null);
    const [isOnlineRed, setIsOnlineRed] = useState(false); // State to track color change
    const [modalConfirm, setModalConfirm] = useState<any>({ name: 'Confirmar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });
    const [modal, setModal] = useState<any>({ name: 'Mensaje', tabIndex: -1, view: false, btnClose: true, btnSubmit: false, format: 'modal-lg' });
    const [modalsend, setModalsend] = useState<any>({ name: 'Enviar Mensaje', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalhist, setModalhist] = useState<any>({ name: 'Historico de Mensajes', tabIndex: -1, view: false, btnClose: true, btnSubmit: false, format: 'modal-lg' });
    const [modalbusqueda, setModalBusqueda] = useState(false);
    const [filtro, setFiltro] = useState('');
    const [mbody, setMbody] = useState<any>([]);
    const [original, setOriginal] = useState<any>([]);
    const [selectedMessage, setSelectedMessage] = useState<any>(null); // Nuevo estado para mensaje seleccionado
    const [search, setSearch] = useState("");
    const [activeTab, setActiveTab] = useState('tab1');
    const idBean = 'IDMessage';

    const head = [
        { title: 'ID', property: idBean },
        { title: 'fecha', property: 'Since' },
        { title: 'Mensaje', property: 'Message' },
        { title: 'Tipo', property: 'MessageType' },

    ]

    useEffect(() => {
        if (search === "") {
            setFilteredLista(lista);
        } else {
            const filtered = lista.filter(mensaje =>
                mensaje.DataBeanProperties.Name1From.toLowerCase().includes(search) ||
                mensaje.DataBeanProperties.Message.toLowerCase().includes(search)
            );
            setFilteredLista(filtered);
        }
    }, [lista, search]);

    const setRowBean = (bean: any) => {
        setBean(bean);
    }

    useEffect(() => {

    }, [search])

    useEffect(() => {
        getLista()

    }, [
        selectedMessage
    ])



    const handleSearchChange = (e) => {
        const searchValue = e.target.value.toLowerCase();
        setSearch(searchValue);

        if (searchValue === "") {
            setFilteredLista(lista);
        } else {
            const filtered = lista.filter(mensaje =>
                mensaje.DataBeanProperties.Name1From.toLowerCase().includes(searchValue) ||
                mensaje.DataBeanProperties.Message.toLowerCase().includes(searchValue)
            );
            setFilteredLista(filtered);
        }
    };



    const getLista = () => {
        single.spinner(true);
        service.getMessageCatalogRender(null, null, { "IDAccountTo": IDAccount }).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue) {
                    setLista(resp.DataBeanProperties.ObjectValue);

                   //console.log(resp.DataBeanProperties.ObjectValue)
                }
                single.spinner(false)
            }
        );
    }

    const getListahistorial = (id: number) => {
        single.spinner(true);
        service.getMessageThreadCatalogRender(null, null, id).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue) {
                    setListahist(resp.DataBeanProperties.ObjectValue);
                }
                single.spinner(false);
            }
        );
    }

    useEffect(() => {
       //console.log(IDAccount);
        getLista();
    }, [])

    const abrirmodal = (mensaje: any) => {
        setModal({ ...modal, "view": true });
        setBean(mensaje)
       //console.log(IDAccount, mensaje.IDMessage);
        service.markReadMessage(IDAccount, mensaje.IDMessage).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue) {
                    toast.success("se ha marcado como visto el mensaje")

                   //console.log(resp)
                }

            }
        );
    }

    const handleViewMessage = (message: any) => {
        setSelectedMessage(message); // Guardar el curso seleccionado
        setActiveTab('tab2'); // Cambiar a la pestaña 'tab2'
        setBean(message)
       //console.log(IDAccount, message.IDMessage);
        service.markReadMessage(IDAccount, message.IDMessage).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue) {
                    toast.success("se ha marcado como visto el mensaje")

                   //console.log(resp)
                }

            }
        );

    };

    const abrirmodalenviar = () => {
        setModalsend({ ...modalsend, "view": true });

    }

    const handleNewButtonClick = () => {
        setShowTextarea(true);
    };

    const handleenviarmessage = () => {
        service.replyMessage(beandos.Message, bean.IDEmployee, bean.IDMessage).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue) {
                    toast.success("Mensaje enviado exitosamente.")

                    setModal({ ...modal, "view": false });
                }

            }
        );
        setShowTextarea(false);
    }

    const handleenviarabandeja = () => {
        ////console.log(beantres.IDAccountTo, single.getAccountID());
        setModalsend({ ...modalsend, "view": false });
        service.putMessage(beantres.IDAccountTo, single.getAccountID(), beantres.Message, single.getAccountID()).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue) {
                    toast.success("Enviado exitosamente.")
                    setModalsend({ ...modalsend, "view": false });
                }
            }
        );
    }

    const Historial = (idmensaje: any) => {
        getListahistorial(idmensaje)
        setModalhist({ ...modalhist, "view": true });
    }

    return (
        <>


            <div>
                <ul className="nav nav-tabs">
                    <li className="nav-item">
                        <button
                            className={`nav-link ${activeTab === 'tab1' ? 'active' : ''}`}
                            onClick={() => setActiveTab('tab1')}
                        >
                            <strong>MENSAJES</strong>

                        </button>
                    </li>
                    <li className="nav-item">
                        <button
                            className={`nav-link ${activeTab === 'tab2' ? 'active' : ''}`}
                            onClick={() => setActiveTab('tab2')}
                        ><strong>LEER MENSAJE</strong>

                        </button>
                    </li>

                </ul>

                <div className="tab-content mt-3">
                    {activeTab === 'tab1' && <div>
                        <div className="container right-side">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="row">
                                        <div className="col-md-8">

                                            <button className="new button" onClick={abrirmodalenviar}>
                                                <BsSendCheckFill />



                                            </button>

                                        </div>
                                        <div className="col-md-4">
                                            <input
                                                type="text"
                                                placeholder="Buscar mensajes..."
                                                className="form-control mb-3"
                                                value={search}
                                                onChange={handleSearchChange}
                                            />
                                        </div>

                                    </div>
                                </div>
                                <div className="col-md-12">


                                    <div className="scroll-cards">

                                    </div>
                                </div>
                                <div className="col-md-12">
                                    {Array.isArray(filteredLista) && filteredLista.map((mensaje) => {
                                        return (
                                            <div className="cardbandeja" key={mensaje.DataBeanProperties.IDMessage}>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="">
                                                            {/* <BsFileEarmarkPersonFill /> */}

                                                            <div className="">
                                                                {mensaje.DataBeanProperties.Name1From}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        {mensaje.DataBeanProperties.Since}
                                                    </div>
                                                    <div className="col-md-1">
                                                        <div className="top-bar-items">
                                                            <div className="notif">
                                                                <div className={`online ${mensaje.DataBeanProperties.IDMessageRead ? 'online-red' : ''}`}>
                                                                    {/* Add the conditional class here */}
                                                                </div>
                                                                <img src="https://i.ibb.co/VJm73Hz/notifications-button.png" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-1">
                                                        <div className="bottom-info">
                                                            {/* <button className="new button" onClick={() => { abrirmodal(mensaje.DataBeanProperties) }}>
                                                                <BsFillArrowRightCircleFill />
                                                            </button> */}
                                                            <button className="new button" onClick={() => { handleViewMessage(mensaje.DataBeanProperties) }}>
                                                                <BsFillArrowRightCircleFill />
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>


                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div> </div>}
                    {activeTab === 'tab2' && selectedMessage && (
                        <div>
                            <div className="right-side">
                                <div className="right-header">
                                    <div className="top-bar">
                                    </div>
                                    <div className="right-bottom">
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="row">
                                                <label>Para:</label>
                                                <div className="col-md-12">
                                                    <strong>{bean.Surname1to} </strong></div>
                                                <label>Fecha:</label>
                                                <div className="col-md-12"> <strong>{bean.Since}</strong></div>
                                                <label>Mensaje:</label>
                                                <div className="col-md-12"> <strong>{bean.Message}</strong></div>
                                            </div>
                                        </div>

                                        <div className="col-md-12 mb-3">
                                            <label>RESPONDER:</label>
                                            <textarea className='form-control' placeholder={"Nuevo Mensaje"} onChange={(e) => setBeandos({ ...beandos, "Message": e.target.value })}></textarea>

                                        </div>
                                        <div className="row">
                                            <div className="col-md-11"></div>
                                            <div className="col-md-1">
                                                <button className="new button" onClick={() => handleenviarmessage()}>

                                                    <BsSend />
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                    )}

                </div>
            </div>

            <Modal modal={modal} updateModal={setModal} >
                <div className="right-side">
                    <div className="right-header">
                        <div className="top-bar">
                        </div>
                        <div className="right-bottom">
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="row">
                                    <label>Para:</label>
                                    <div className="col-md-12">
                                        <strong>{bean.Surname1to} </strong></div>
                                    <label>Fecha:</label>
                                    <div className="col-md-12"> <strong>{bean.Since}</strong></div>
                                    <label>Mensaje:</label>
                                    <div className="col-md-12"> <strong>{bean.Message}</strong></div>
                                </div>
                            </div>
                            {/* <div className="col-md-12 mt-3">
                                <button className="new button" onClick={handleNewButtonClick}>
                                    <BsFillArrowDownCircleFill />



                                </button>

                            </div> */}
                            <div className="col-md-12 mb-3">
                                <label>RESPONDER:</label>
                                <textarea className='form-control' placeholder={"Nuevo Mensaje"} onChange={(e) => setBeandos({ ...beandos, "Message": e.target.value })}></textarea>
                                {/* value={beandos.Message} */}
                                {/* <input type="text-area" className='form-control' value={beandos.Message} onChange={(e) => setBeandos({ ...beandos, "Message": e.target.value })} /> */}
                            </div>
                            <div className="row">
                                <div className="col-md-11"></div>
                                <div className="col-md-1">
                                    <button className="new button" onClick={() => handleenviarmessage()}>

                                        <BsSend />
                                    </button>
                                </div>
                            </div>
                        </div>
                        {/* {showTextarea && (
                            <>
                              


                            </>

                        )} */}
                    </div>

                </div>
            </Modal>
            <Modal modal={modalsend} updateModal={setModalsend} eventModal={handleenviarabandeja}>
                <div className="row">
                    <div className="col-md-12">
                        <AccountEditor idAccount={beantres.IDAccountTo} label="Nombre del Funcionario" onChange={({ IDAccount }: any) => { setBeantres({ ...beantres, "IDAccountTo": IDAccount }) }} />
                    </div>

                    <div className="col-md-12 mt-2 mb-4">
                        <label>Mensaje</label>
                        <textarea className='form-control' placeholder={"Nuevo Mensaje"} onChange={(e) => setBeantres({ ...beantres, "Message": e.target.value })}></textarea>
                        {/* <input type="text-area" className='form-control' value={beantres.Message} onChange={(e) => setBeantres({ ...beantres, "Message": e.target.value })} /> */}
                    </div>
                </div>
                {/* <button className="new button" onClick={() => handleenviarabandeja()}>

                    <FaArrowRightFromBracket />
                </button> */}


            </Modal>
            <Modal modal={modalhist} updateModal={setModalhist} >

                <div className="row">


                    <div className="col-md-2 mt-4">
                        {/* <BasicButton value="Buscar" icon="ri-search-line" eventClick={getListahistorial} /> */}
                    </div>
                    <div className="col-md-12 p-2">
                        <BasicTable head={head} body={listahist} rowSet={setRowBean}></BasicTable>
                    </div>
                </div>
            </Modal>
        </>
    )
}