/* eslint-disable @typescript-eslint/no-unused-vars */
import { ProfileDashed } from './components/ProfileDashed/ProfileDashed'
import { EventItem, NextEvents } from './components/NextEvents/NextEnvents'
import { MenuDashed, QuickAction } from './components/MenuDashed/MenuDashed'
import { MenuButton } from './components/MenuButton/MenuButton'
import { BsInboxes } from 'react-icons/bs'
import { IoGitPullRequestOutline } from "react-icons/io5";
import './dashed.scss'
import './components/ProfileDashed/profiledashed.scss'
import { BannerDashed } from './components/BannerDashed/BannerDashed'
import useDashed from './hooks/useDashed'
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { Modal } from '../../theme/Component/Modal'
import { FileInput } from '../../theme/Component/FileInput'
import { Singleton } from '../../../core/services/Singleton'
import { FileService } from '../../../core/services/FileService'
import { PrintJson } from '../../theme/Component/PrintJson'
import useDataBean from '../../../core/services/dataBean/useDataBean'
import { _AbstractAccount } from '../../../core/services/dataBean/EntityCatalog'
import { VisorAnyDocument } from '../../documents/VisorAnyDocument'


export interface DashData {
    greetings: string,
    name: string
}


export const events: EventItem[] = []

export const Dashed = () => {
    const absAcco = useDataBean(_AbstractAccount);
    const [user, setUser] = useState<any>(Singleton.getInstance().getAccount())
    const fileService = new FileService();
    const [image, setImage] = useState<string>(null)
    const navigate = useNavigate();
    const { renderSwitch, example, activeQA, setActiveQA } = useDashed();
    const [modal, setModal] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-md' });

    useEffect(() => {
        const x = Singleton.getInstance().getAccount();
        if (x.Photo) {
            setImage(fileService.getUrlFile("photos", x.Photo));
        } else {
            setImage("assets/images/core/avatar.png")
        }
    }, [modal.view])



    const updatePhoto = () => {
        absAcco.instance.updateAbstractAccount(user).then(
            (resp) => {
               //console.log(resp);
                setModal({ ...modal, "view": false })
                Singleton.getInstance().startSesion(user)
                window.location.reload();
            }
        )
    }


    return (
        <>
            <div className='container-fluid'>
                <div className="row">
                    <div className="col-md-8">
                        <div className="main_info animate__animated animate__fadeInDown">
                            <ProfileDashed user={user} imgProfile={image} changeProfileImg={() => { setModal({ ...modal, "view": true, "name": "Cambio de Imagen de Perfil" }) }}>
                                <div className='d-flex gap-2 w-100'>
                                    <MenuButton item={{
                                        label: 'Bandeja',
                                        icon: <BsInboxes />,
                                        event: () => { navigate("/app/admin/user-inbox") }
                                    }} iconSize={50} labelSize={16} />
                                    <MenuButton item={{
                                        label: 'Mis Solicitudes',
                                        icon: <IoGitPullRequestOutline />,
                                        event: () => { }
                                    }} iconSize={50} labelSize={16} />
                                </div>
                            </ProfileDashed>
                        </div>
                    </div>
                    <div className="col-md-4">

                        <div className="calendar animate__animated animate__fadeInRight">
                            <NextEvents events={events} />
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="">
                            <MenuDashed quick_actions={example} />
                        </div>
                    </div>
                    <div className="col-md-8">
                        <div className="">
                            {activeQA &&
                                <>
                                    <BannerDashed quickAction={activeQA} />
                                    <div className="row">
                                        <div className="col-md-12">
                                            {renderSwitch()}
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>


            {modal.view &&
                <Modal modal={modal} updateModal={setModal} eventModal={() => { updatePhoto() }} >
                    <>
                        <div className="row d-flex justify-content-center">
                            <div className="col-8">
                                <FileInput
                                    Media={user.Photo}
                                    MediaContext={'photos'}
                                    directory='photos'
                                    previsualization
                                    label='Nueva Imagen'
                                    onCreate={(e) => { setUser({ ...user, "Photo": e.Media });  }}
                                    docAcept="image/jpeg, image/png, image/gif, image/bmp, image/tiff, image/svg+xml" />
                            </div>
                        </div>
                    </>
                </Modal>
            }
        </>
    )
}
