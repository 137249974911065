import { useEffect, useRef, useState } from "react"
import { AccountEditor } from "../../../../../editor/AccountEditor"
import { BasicPage } from "../../../../../theme/Component/BasicPage"
import { toast } from "react-toastify"
import useDataBean from "../../../../../../core/services/dataBean/useDataBean"
import { _UBLInvoice } from "../../../../../../core/services/dataBean/EntityCatalog"
import { Singleton } from "../../../../../../core/services/Singleton"
import { BasicButton } from "../../../../../theme/Component/BasicButton"
import { BasicTable, MASK_DATE, MASK_MONEY, MASK_NUMBER, MASK_TEXT_NOWRAP } from "../../../../../theme/Component/BasicTable"
import { ListMenu } from "../../../../../theme/Component/ListMenu"
import { Modal } from "../../../../../theme/Component/Modal"
import usePaginator from "../../../../../theme/Component/usePaginator"
import { ManageUBLInvoiceItems } from "./ManageUBLInvoiceItems"

export const ManageFixedAssetsUBL = () => {

    const { bean, setBean, lista, setLista, instance } = useDataBean(_UBLInvoice)
    const single = Singleton.getInstance();
    const paginador = usePaginator();

    const [account, setAccount] = useState<any>({});
    const [year, setYear] = useState<number>(single.getCurrenYear());
    const [register, setRegister] = useState<number>(15);

    const [modal, setModal] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-xl' });

    const getLista = () => {
        instance.getUblInvoiceCatalogForStore(account.IDAccount, year, paginador.page, register).then(
            (resp) => {
                setModal({ ...modal, "view": false });
               //console.log(resp)

                let newList = resp.List;
                newList.forEach((invoice) => {
                    invoice.DataBeanProperties.SupplierEntity = (invoice.DataBeanProperties.AccountSupplierNit ?? "") + " -" + (invoice.DataBeanProperties.AccountSupplierName ?? "");
                });

                if (newList.length > 0) {
                    paginador.setPages(resp.NumberOfPages);
                    paginador.setRecords(resp.NumberOfPages * register);
                    paginador.setRegiPerPage(register)
                } else {
                   //console.log("error en el paginador")
                }

                setLista(newList)
            }
        ).catch(
            err => {
               console.error(err)
                toast.error("Algo salio mal")
            }
        )
    }



    const head = [
        { title: 'ID Factura', property: 'IDUBLInvoice', visible: true, mask: MASK_NUMBER },
        { title: 'No Factura', property: 'InvoiceID', visible: true },
        { title: 'Nit - Proveedor', property: 'SupplierEntity', visible: true },
        { title: 'Líneas Factura', property: 'LineCountNumeric', visible: true },
        { title: 'Valor Total', property: 'TotalInvoiceAmount', visible: true, mask: MASK_MONEY },
        // { title: 'Impuestos', property: 'TaxAmount', visible: true },
        { title: 'Descuentos', property: 'DiscountAmount', visible: true },
        { title: 'Fecha Factura', property: 'IssueDate', visible: true, mask: MASK_DATE },
        { title: 'Entrada Almacén', property: 'EAFMnemonic', visible: false, mask: MASK_TEXT_NOWRAP },
        { title: 'Consecutivo Entrada', property: 'EAFConsecutive', visible: false },
        { title: 'Fecha Entrada', property: 'EAFDate', visible: false },
        { title: 'Fecha Procesamiento', property: 'Since', visible: true },
        { title: 'Estado Factura', property: 'DocumentStateName', visible: true },
        { title: 'Tipo Factura', property: 'SourceTypeName', visible: true },
        // { title: 'Tipo Factura', property: 'DocumentTypeName', visible: true },
        { title: 'Estado EAF', property: 'EAFDocumentStateName', visible: true },
        { title: 'Estado Salidas', property: 'OutputCostStateName', visible: true },
        { title: 'Documento Soporte', property: 'IsDocumentSupport', visible: true },
        { title: 'Concepto de Ingreso', property: 'AccountantConceptName', visible: true },
        { title: 'Cuenta Contable', property: 'AccountantIDName', visible: true },
        { title: 'Cuenta Puente Ingreso Bienes', property: 'CreditAccountantIDName', visible: true },
        { title: 'Pertenece a Proceso', property: 'ProcessTypeName', visible: true },
    ]

    const listaBotones = () => {
        const botones = [
            { titulo: 'Clasificar Activos y Productos', icono: 'ri-list-ordered', visible: true, evento: () => { setModal({ ...modal, "view": true, "name": `Clasificar Activos y Productos de la Factura ${bean.IDUblInvoice}` }) } },
        ]

        return (
            <ListMenu listaBotones={botones} />
        )
    }

    const finalizar = (modalview: boolean) => {
       //console.log(modalview)
        if (modalview) {
            setModal({ ...modal, "view": false });
            getLista();
        }
    }

    return (
        <>
            <BasicPage titulo="Generar Entrada de Activos Fijos">
                <div className="row">
                    <div className="col-lg-6">
                        <AccountEditor label="Proveedor" idAccount={account.IDAccount} onChange={(e) => { setAccount(e) }} />
                    </div>
                    <div className="col-lg-3">
                        <label>Año</label>
                        <input type="number" className="form-control" value={year} onChange={(e) => { setYear(parseInt(e.target.value)) }} />
                    </div>
                    <div className="col-lg-3">
                        <label>Registros por Página</label>
                        <input type="number" className="form-control" value={register} onChange={(e) => { setRegister(parseInt(e.target.value)) }} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <BasicButton eventClick={() => { getLista() }} icon="ri-search-line" >Buscar</BasicButton>
                    </div>
                </div>

                <BasicTable
                    listButtons={listaBotones()}
                    head={head} body={lista}
                    rowSet={setBean}
                    pageTop={paginador.page}
                    next={paginador.next}
                    back={paginador.back}
                    records={paginador}
                    customRows={register}
                />
            </BasicPage>

            {modal.view &&
                <Modal modal={modal} updateModal={setModal}>
                    <ManageUBLInvoiceItems _idUBLInvoice={bean.IDUBLInvoice} _onChange={(e) => { finalizar(e); }} _issueDate={bean.IssueDate} _invoice={bean} />
                </Modal>
            }
        </>
    )
}