import { useState, useEffect } from 'react';
import { Singleton } from '../../../core/services/Singleton';
import { useDashboardStore } from '../../pages/hooks/useDashboardStore';
import { BpmServiceall } from '../../../core/services/BpmServiceall';
import { ListMenu } from '../../theme/Component/ListMenu';
import { BasicTable } from '../../theme/Component/BasicTable';
import { ModalConfirm } from '../../theme/Component/ModalConfirm';
import { Modal } from '../../theme/Component/Modal';
import { SelectorPlanSGSST } from '../../bpm/config/Componentes/SelectorPlanSGSST';
import { SelectTypeTest } from '../../bpm/config/Componentes/SelectTypeTest';
import { FileInput } from '../../theme/Component/FileInput';
import { FileService } from '../../../core/services/FileService';


interface Props {
    IDCourseTopic: number;
}


export const TopicResource = ({ IDCourseTopic }: Props) => {

    const single = Singleton.getInstance()
    const { setLoading } = useDashboardStore();
    const fileService = new FileService();
    const service = new BpmServiceall();
    const [lista, setLista] = useState([]);
    const [itemDoc, setItemDoc] = useState<any>({});
    const [bean, setBean] = useState<any>({});
    // const [beanselect, setBeanselect] = useState({ Type: undefined });
    const [test, setTest] = useState<number>(0);
    const [modaljson, setModaljson] = useState<any>({ name: 'Caracterizaciones', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalresource, setModalresource] = useState<any>({ name: 'TopicResource', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modal, setModal] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalConfirm, setModalConfirm] = useState<any>({ name: 'Confirmar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });
    const [user, setUser] = useState<any>({});

    const idBean = 'IDTopicResource';

    const head = [
        { title: 'ID', property: idBean },
        { title: 'Nombre', property: 'Name' },
        { title: 'Archivo /  Ruta', property: 'Type' },

    ]


    const total = () => {
       //console.log(bean);
        updateItem();
    }

    useEffect(() => {
        setUser(single.getAccountID());
        getLista();
    }, [IDCourseTopic])


    const setRowBean = (bean: any) => {
       //console.log("Row : ", bean);
        setBean(bean);

    }

    const getLista = () => {
        let x = {
            IDCourseTopic: IDCourseTopic,
        }
        setLoading(true);
        service.getTopicResourceCatalogPorPropiedades(x, null).subscribe(
            (resp: any) => {

                if (resp.DataBeanProperties.ObjectValue) {
                    setLista(resp.DataBeanProperties.ObjectValue);
                }

                setLoading(false);
            }
        );
    }


    // const getLista = () => {
    //     let x = {
    //         IDCourseTopic: IDCourseTopic,

    //     }
    //     setLoading(true);
    //     service.getTopicResourceCatalogPorPropiedades(x, null).subscribe(
    //         (resp: any) => {
    //            //console.log(resp);

    //             if (resp.DataBeanProperties.ObjectValue) {
    //                 let tmp = resp.DataBeanProperties.ObjectValue;
    //                 tmp.forEach((element: any) => {
    //                     let Doc = element.DataBeanProperties.JSONData
    //                     let File: any = {}
    //                     if (Doc != null && Doc != '') {
    //                         File = JSON.parse(Doc)
    //                        //console.log(File)
    //                     }

    //                     element.DataBeanProperties.Doc = ` <a href="${fileService.getUrlFile(File.MediaContext, element.DataBeanProperties.Media)}" target='_blank'>Ver</a>`
    //                     // if (element.DataBeanProperties.MediaContext) {
    //                     //     element.DataBeanProperties.URL = ` <a href="${fileService.getUrlFile(element.DataBeanProperties.MediaContext, element.DataBeanProperties.Media)}" target='_blank'>Ver</a>`
    //                     // } else {
    //                     //     element.DataBeanProperties.URL = "";
    //                     // }
    //                 });
    //                 setLista(tmp);
    //             } else {
    //                 setLista([]);
    //             }

    //             setLoading(false);
    //         }
    //     );
    // }




    const agregarItem = () => {

        let b = {
            Name: '',
            Type: 0,
            JSONData: '',
            IDCourseTopic: IDCourseTopic,

        }
        setBean(b);
        setModal({ ...modal, ['view']: true });

    }

    const veracciones = () => {
        setModalresource({ ...modalresource, ['view']: true });
    }
    const eliminarItem = () => {
        bean.State = 2;
       //console.log(bean);
        updateItem();
        setModalConfirm({ ...modalConfirm, ['view']: false });
    }
    const updateItem = () => {

        setModal({ ...modal, ['view']: false });
        bean.JSONData = JSON.stringify(itemDoc);
        setLoading(true);
        service.updateTopicResource(bean).subscribe(
            (resp: any) => {
                setLoading(false);
                if (resp.DataBeanProperties.ObjectValue) {
                    getLista();
                }
                single.update(bean);
            });

    }

    const listaBotones = () => {
        const botones = [

            { titulo: 'Editar', icono: 'ri-file-list-line', visible: single.canEdit(), evento: () => { setModal({ ...modal, "view": true }) } },
            { titulo: 'Eliminar', icono: 'ri-file-user-line', visible: true, evento: () => { setModalConfirm({ ...modalConfirm, "view": true }) } },

            { titulo: 'Recurso', icono: 'ri-file-user-line', visible: true, evento: () => { setModalresource({ ...modalresource, "view": true }) } },
        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }

    const iconButtonsItem = [
        { nombre: 'Agregar', visible: single.canCreate(), icono: 'ri-file-add-line', evento: agregarItem }
    ]

    const addFile = (e: any) => {
        
        setItemDoc({ media: e.Media, mediaContext: e.MediaContext })
    }




    return (
        <>
            <div className="row">
                <div className="row">

                </div>
                <div className="col-md-12 p-2">
                    <BasicTable listButtons={listaBotones()} iconButtons={iconButtonsItem} head={head} body={lista} rowSet={setRowBean}></BasicTable>
                </div>
            </div>

            <ModalConfirm modal={modalConfirm} updateModal={setModalConfirm} eventModal={eliminarItem}>
                {bean.Name}
            </ModalConfirm>

            <Modal modal={modal} updateModal={setModal} eventModal={total}>
                <div className="row">
                    <div className="col-md-6">
                        <label className="form-label">Nombre</label>
                        <input type="text" className="form-control" value={bean.Name} onChange={(e) => { setBean({ ...bean, ['Name']: e.target.value }) }} />
                    </div>

                    <div className="col-md-6">
                        <label className="form-label">Tipo de Prueba</label>
                        <select className='form-select' value={bean.Type} onChange={(e) => { setBean({ ...bean, "Type": parseInt(e.target.value) }) }}>
                            <option value={undefined}>...</option>
                            <option value={1}>Url</option>
                            <option value={2}>Document</option>
                            <option value={3}>Media</option>
                        </select>

                    </div>
                    <div className="col-md-6">
                        {bean.Type == 1 && (
                            <div>
                                <label className="form-label">Ingrese la URL</label>
                                <input type="text" className="form-control" value={bean.JSONData} onChange={(e) => { setBean({ ...bean, ['JSONData']: e.target.value }) }} />
                            </div>
                        )}

                        {bean.Type == 2 && (
                            <div>
                                <label className="form-label">Ingrese el Documento</label>

                                <FileInput
                                    Media={itemDoc.fileMap ? itemDoc.fileMap?.Media : null}
                                    MediaContext={itemDoc.fileMap ? itemDoc.fileMedia?.MediaContext : null}
                                    directory={'DocsCourse'}
                                    onCreate={addFile}
                                    docAcept="application/pdf , image/jpeg, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                />
                            </div>
                        )}

                        {bean.Type == 3 && (
                            <div>
                                <label className="form-label">¿Es media?</label>
                                <FileInput
                                    Media={itemDoc.fileMap ? itemDoc.fileMap?.Media : null}
                                    MediaContext={itemDoc.fileMap ? itemDoc.fileMedia?.MediaContext : null}
                                    directory={'DocsCourse'}
                                    onCreate={addFile}
                                    docAcept="application/pdf , image/jpeg, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                />
                            </div>
                        )}
                    </div>
                </div>
            </Modal>

        </>
    )
}