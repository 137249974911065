import { useState, useEffect } from 'react';
import useDataBean from '../../../core/services/dataBean/useDataBean';
import { _DocumentTypeC } from '../../../core/services/dataBean/EntityCatalogVdos';



interface Props {
    idSelector: number;
    onChange: any;
    label?: string;
}

export const DocumentTypeEditor = ({ idSelector, onChange, label }: Props) => {

    const DocumentType = useDataBean(_DocumentTypeC)
    const [lista, setLista] = useState<any>([])
    const [id, setID] = useState(0);

    useEffect(() => {
        if (idSelector >= 0) {
            setID(idSelector);
        }
    }, [])

    useEffect(() => {
        getLista();
    }, [])


    useEffect(() => {
        onChange(id);
    }, [id])

    const setValue = (e: any) => {
        setID(parseInt(e));
        onChange(id);
    }

    const getLista = () => {

        DocumentType.instance.getDocumentTypeCatalogPorPropiedades({ State: 1 }, null).then(
            (resp: any) => {
                if (resp) {
                    setLista(resp)
                }

            }
        )

    }


    return (
        <>
            <label>{label ?? ""}</label>
            <select className="form-select" value={id} onChange={(e) => { setValue(e.target.value) }}>
                <option>...</option>
                {lista &&
                    lista.map((item: any, index: number) => {
                        return (
                            <option key={index} value={item.DataBeanProperties.IDDocumentType} >{item.DataBeanProperties.Name}</option>
                        )
                    })
                }
            </select>
        </>
    )
}