import { DataBean } from "../DataBean";

export class CashReimbursementBudget extends DataBean {

    public async getCashReimbursementBudgetCatalog(idCashReimbursement: number, cashReimbursementDate: any): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_getCashReimbursementBudgetCatalog_Number_java.util.Date", [idCashReimbursement, cashReimbursementDate]);
    }

    public async addCashReimbursementBudget(idCashReimbursement: number, cashReimbursementDate: any, idCashReimbursementConcept: number, value: number, idEmployee: number): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "com.quickdataerp.bean.accountant.CashReimbursementBudget_addCashReimbursementBudget_Number_java.util.Date_Number_Number_Number", [idCashReimbursement, cashReimbursementDate, idCashReimbursementConcept, value, idEmployee]);
    }

    public async deleteCashReimbursementBudget(idCashReimbursementBudget: number, cashReimbursementDate: any, idEmployee: number, obs: string | null): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "void_deleteCashReimbursementBudget_Number_java.util.Date_Number_String", [idCashReimbursementBudget, cashReimbursementDate, idEmployee, obs]);
    }

}