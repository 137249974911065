interface Props{
    lista:any;
}
export const ErrorList=({lista}:Props)=>{
    return(
        <div className="row">
            <div className="col">
                <ul className="list-group">
                    {lista.map((item:string,index:number)=>{
                        return(
                            <li key={index} className="list-group-item">
                                {item}
                            </li>
                        )
                    })}
                </ul>
            </div>
        </div>
    )
}