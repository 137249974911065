import { useEffect, useState } from "react";
import useDataBean from "../../../core/services/dataBean/useDataBean";
import { Singleton } from "../../../core/services/Singleton";
import { toast } from "react-toastify";
import { ListMenu } from "../../theme/Component/ListMenu";
import { BasicTable } from "../../theme/Component/BasicTable";
import { Modal } from "../../theme/Component/Modal";
import { ModalConfirm } from "../../theme/Component/ModalConfirm";
import { BasicPage } from "../../theme/Component/BasicPage";
import { _CashReimbursementConcept } from "../../../core/services/dataBean/EntityCatalog";
import { SelectBudgetID } from "../../admin/configuracion/BudgetID/SelectBudgetID";
import { SelectAccountantTree } from "../../contabilidad/catalogo-cuentas/AccountantTree/SelectAccountantTree";
import { BasicSwitch } from "../../theme/Component/BasicSwitch";

export const CashReimbursementConcept = () => {

    const reimbursementConcept = useDataBean(_CashReimbursementConcept);
    const single = Singleton.getInstance();

    const [year, setYear] = useState<number>(single.getCurrenYear());
    const [lista, setLista] = useState<any>([]);
    const [modal, setModal] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalC, setModalC] = useState<any>({ name: 'Eliminar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });

    useEffect(() => {
        getLista();
    }, [])

    const getLista = () => {
        reimbursementConcept.instance.getCashReimbursementConceptTypeCatalog(year).then(
            (resp) => {
               //console.log(resp);
                setLista(resp)
            }
        )
    }

    const create = () => {
        reimbursementConcept.setBean({
            // Year: single.getCurrenYear(),
            ApplyBudget: false,
            IDEmployee: single.getAccountID(),
        });
        setModal({ ...modal, "name": "Agregar Concepto", "view": true });
    }

    const deleteItem = () => {
        reimbursementConcept.instance.deleteCashReimbursementConceptType(reimbursementConcept.bean.IDCashReimbursementConcept).then(
            (resp) => {
                getLista();
                setModalC({ ...modalC, "view": false });
            }
        ).catch(
            err => {
                toast.error(err)
            }
        )
    }

    const editaItem = () => {
        reimbursementConcept.instance.updateCashReimbursementConceptType(reimbursementConcept.bean).then(
            (resp: any) => {
                getLista();
                setModal({ ...modal, "view": false });
            }
        ).catch(err => {
            toast.error(err);
        })
    }

    const head = [
        { title: 'ID', property: 'IDCashReimbursementConcept', visible: true },
        { title: 'Código', property: 'Code', visible: true },
        { title: 'Nombre', property: 'Name', visible: true },
        { title: 'Cuenta Débito', property: 'AccountantIDName', visible: true },
        { title: 'Rubro Presupuestal', property: 'BudgetName', visible: true },
        { title: 'Aplicar Presupuesto', property: 'ApplyBudget', visible: true },
    ]

    const iconButtonsItem = [
        { nombre: 'Agregar', visible: single.canCreate(), icono: 'ri-file-add-line', evento: create }
    ]
    const listaBotones = () => {
        const botones = [
            { titulo: 'Editar', icono: 'ri-edit-2-line', visible: single.canEdit(), evento: () => { setModal({ ...modal, "name": `Editar Habilidades del Cargo`, "view": true }) } },
            { titulo: 'Eliminar', icono: 'ri-delete-bin-line', visible: single.canDelete(), evento: () => { setModalC({ ...modalC, "view": true }) } },
        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }


    return (
        <>
            <BasicPage titulo="Conceptos de Caja Menor">
                <div className="row">
                    <div className="col-12">
                        <BasicTable body={lista} head={head} rowSet={reimbursementConcept.setBean} listButtons={listaBotones()} iconButtons={iconButtonsItem}></BasicTable>
                    </div>
                </div>
            </BasicPage>

            {modal.view &&
                <Modal modal={modal} updateModal={setModal} eventModal={editaItem}>
                    <div className="row">
                        <div className="col-lg-6">
                            <label >Código</label>
                            <input type="text" className="form-control" name="Code" value={reimbursementConcept.bean.Code} onChange={reimbursementConcept.handleChange} />
                        </div>
                        <div className="col-lg-6">
                            <label >Nombre</label>
                            <input type="text" className="form-control" name="Name" value={reimbursementConcept.bean.Name} onChange={reimbursementConcept.handleChange} />
                        </div>
                        <div className="col-lg-6">
                            <label>Cuenta Débito</label>
                            <SelectAccountantTree label=" " jsonCode={reimbursementConcept.bean.AccountantIDStr} onChange={(e) => { reimbursementConcept.setBean({ ...reimbursementConcept.bean, "AccountantIDStr": e.JsonCode }) }} />
                        </div>
                        <div className="col-lg-6">
                            <label>Rubro Presupuestal</label>
                            <SelectBudgetID label=" " tipoPresupuesto={null} edit={false} onChange={(e) => { reimbursementConcept.setBean({ ...reimbursementConcept.bean, "BudgetIDStr": e.JsonCode }) }} jsonCode={reimbursementConcept.bean.BudgetIDStr} />
                        </div>
                        <div className="col-lg-6">
                            <label>Aplicar Presupuesto</label>
                            <BasicSwitch label=" " estado={reimbursementConcept.bean.ApplyBudget} eventChange={(e) => { reimbursementConcept.setBean({ ...reimbursementConcept.bean, "ApplyBudget": e }) }}></BasicSwitch>
                        </div>
                    </div>
                </Modal>
            }

            {modalC.view &&
                <ModalConfirm modal={modalC} updateModal={setModalC} eventModal={deleteItem}>
                    <strong>Eliminar item {reimbursementConcept.bean.IDKnowledge}</strong>
                </ModalConfirm>
            }
        </>
    )
}