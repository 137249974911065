import { useEffect, useState } from "react";
import { _single } from "../../../core/services/dataBean/EntityCatalog";
import { YearSelector } from "../../../core/shared/inputs/YearSelector";
import { SelectBudgetType } from "../../admin/configuracion/select/SelectBudgetType";
import { SelectBudgetIDType } from "../../admin/configuracion/select/SelectBudgetIDType";
import { SelectExpensiveBudgetIDType } from "../../admin/configuracion/select/SelectExpensiveBudgetIDType";

interface Props {
    onChange: Function;
}

export const BudgetSheetFactoryForm = ({ onChange }: Props) => {

    const [response, setResponse] = useState<any>({
        Year: _single.getCurrenYear(),

    });

    useEffect(() => {
        onChange(response)
    }, [response])

    return (
        <div className="row">
            <div className="col-md-4">
                <YearSelector value={response.Year} tittle="Año Presupuesto" onChange={(e) => { setResponse({ ...response, "Year": e }) }} />
            </div>
            <div className="col-md-4">
                <SelectBudgetType idSelector={response.IDBudgetType} onChange={(e) => { setResponse({ ...response, "IDBudgetType": e }) }} label="Tipo de Presupuesto" />
            </div>
            <div className="col-md-4">
                <SelectBudgetIDType idSelector={response.IDBudgetIDType} onChange={(e) => { setResponse({ ...response, "IDBudgetIDType": e }) }} label="Tipo de Catálogo Presupuestal" />
            </div>
            <div className="col-md-12">
                <label>Descripción</label>
                <textarea className="form-control" value={response.Description} onChange={(e) => { setResponse({ ...response, "Description": e.target.value }) }} />
            </div>
        </div>
    )
}