import { useEffect, useState } from "react";
import { BasicButton } from "../../theme/Component/BasicButton";
import { DocumentViewer } from "../../theme/Component/DocumentViewer";
import { ErrorList } from "../../theme/Component/ErrorList";
import useDataBean from "../../../core/services/dataBean/useDataBean";
import { _BudgetMovementFactory, _single } from "../../../core/services/dataBean/EntityCatalog";
import { toast } from "react-toastify";

interface Props {
    formData: any;
    objectList: any;
    onChange: Function;
}

export const CreateBudgetMovementFactoryDocument = ({ objectList, formData, onChange }: Props) => {

    const { instance } = useDataBean(_BudgetMovementFactory);

    const [documents, setDocuments] = useState<any>([]);
    const [msgList, setMsgList] = useState<any>([]);
    const [tap, setTap] = useState<number>(0);

    const crearDocumento = () => {
        instance.createCreditContraCreditBudget(formData.IDBudgetType, formData.DateForm, objectList, formData.Description, _single.getAccountID(), true).then(
            (resp: any) => {
                setDocuments(resp.DocumentURL);
                setMsgList(resp.MsgList);
                setTap(1)
            }
        ).catch(
            err => {
                toast.error(err, { autoClose: false })
            }
        )
    }

    useEffect(() => {
        onChange({
            Documents: documents,
            MSGList: msgList,
        })
    }, [documents, msgList])

    return (
        <div className="row">
            {tap === 0 &&
                <div className="col-md-6">
                    <BasicButton disable={!formData.DateForm || !formData.IDBudgetType} icon="ri-article-line" eventClick={crearDocumento}>Crear Crédito/Contracrédito</BasicButton>
                </div>
            }
            {tap === 1 &&
                <div className="col">
                    {msgList.length > 0 &&
                        <ErrorList lista={msgList} />
                    }
                    {msgList.length === 0 && documents.length > 0 &&
                        <DocumentViewer key={documents} list={documents} />
                    }
                </div>
            }
        </div>
    )
}