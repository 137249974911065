

import { useState, useEffect } from 'react';
import { Singleton } from '../../core/services/Singleton';
import { ContractService } from '../../core/services/ContractService';
import { BasicTable, MASK_MONEY, MASK_NUMBER } from '../theme/Component/BasicTable';
import { ModalConfirm } from '../theme/Component/ModalConfirm';
import { Modal } from '../theme/Component/Modal';
import { SelectFunctionalID } from '../admin/configuracion/functionalID/SelectFunctionalID';
import { AccountEditor } from '../editor/AccountEditor';
import { FunctionalIDDropDown } from './FunctionalIDDropDown';
import { AdminService } from '../../core/services/AdminService';
import { ListMenu } from '../theme/Component/ListMenu';
import { InputMoney } from '../theme/Input/InputMoney';
import { useDashboardStore } from '../pages/hooks/useDashboardStore';
import { AdquisitionItem } from './adquisition-setting/AdquisitionItem';
import { FunctionalIDPreparer } from './FunctionalIDPreparer';
import useModal, { MODAL_md } from '../theme/Component/hooks/useModal';
import { ManagementType } from './ManagementType';
import useDataBean from '../../core/services/dataBean/useDataBean';
import { _ManagementType, _single } from '../../core/services/dataBean/EntityCatalog';
import { toast } from 'react-toastify';
import { CodesByFunctionalID } from '../system/CodesByFunctionalID';
interface Props {
    bean: any;
    role: number;
}
export const AdquisitionPlanFunctionalID = ({ bean, role }: Props) => {

    const { instance } = useDataBean(_ManagementType);
    const serviceContract = new ContractService();
    const single = Singleton.getInstance()
    const { setLoading } = useDashboardStore();

    const [adquisition, setAdquisition] = useState<any>({});
    const [lista, setLista] = useState<any>([]);
    const [user, setUser] = useState<any>({});
    const [permisos, setPermisos] = useState([]);
    const [listPermisos, setListPermisos] = useState([]);

    const [modal, setModal] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalItems, setModalItems] = useState<any>({ name: 'Items / Necesidades', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-xl' });
    const [modalPreparer, setModalPreparer] = useState<any>({ name: 'Preparadores', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-xl' });
    const [modalConfirm, setModalConfirm] = useState<any>({ name: 'Confirmar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });
    const [modalMetods, setModalMetods] = useState<any>({ name: "Tipos de Gestión", tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-md' });

    const idBean = 'IDAdquisitionPlanFunctionalID';

    const modalBudget = useModal("Rubros", MODAL_md);
    const modalUnscp = useModal("Códigos UNSPSC", MODAL_md);

    const head = [
        { title: 'ID', property: idBean, mask: MASK_NUMBER, visible: false },
        { title: 'Área Funcional', property: 'IDLnFunctionalIDName' },
        { title: 'Responsable en el área funcional', property: 'AccountName' },
        { title: 'Observaciones', property: 'Observations', visible: false },
        // { title: 'Cerrar Plan De Adquisiciones Del Área Al Valor Estimado', property: 'CloseToEstimatedValue' },
        { title: 'Valor Estimado', property: 'EstimatedValue', mask: MASK_MONEY },
        { title: 'Valor Necesidades', property: 'TotalValue', mask: MASK_MONEY },
        // { title: 'Estado de Estructuración', property: 'StructuringStateName' },
        // { title: 'Etapa', property: 'PhaseStateName', visible: false }
    ]
    //Se implementaron los servicos AdquisitionPlanFunctionalID

    useEffect(() => {
        setUser(single.getAccount());
        // getApiPermissionsByRole();
        setAdquisition(bean);
        getAdquisitionPlanFunctionalIDCatalog(bean.IDAdquisitionPlan);
    }, [bean.IDAdquisitionPlan])




    const getAdquisitionPlanFunctionalIDCatalog = (idAdquisitionPlan: number) => {

        setLoading(true);
        serviceContract.getAdquisitionPlanFunctionalIDCatalog(idAdquisitionPlan).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue) {
                    let list = resp.DataBeanProperties.ObjectValue;
                    setLista(list);
                }
                setLoading(false);
            }
        );

    }




    const agregarItem = () => {
        setModal({ ...modal, "view": true });
        let b = {
            IDAdquisitionPlan: bean.IDAdquisitionPlan,
            IDLnFunctionalID: 0,
            IDAccount: 0,
            Observations: '',
            CloseToEstimatedValue: false,
            TotalValue: 0
        }
        setAdquisition(b)
    }

    const eliminarItem = () => {
        setModalConfirm({ ...modalConfirm, "view": false });
       //console.log(adquisition);

        serviceContract.removeFunctionalIDFromAdquisitionPlan(bean.IDAdquisitionPlan, adquisition.IDLnArea).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue) {
                    getAdquisitionPlanFunctionalIDCatalog(bean.IDAdquisitionPlan);
                }
                if (resp.DataBeanProperties.ErrorMessage) {
                    toast.error(resp.DataBeanProperties.ErrorMessage);
                }
            }
        )
    }

    const updatePermisions = () => {

        instance.addManagementTypeListForIDLn(adquisition.IDLnFunctionalID, listPermisos).then(
            (resp) => {
               //console.log(resp);
                setModalMetods({ ...modalMetods, "view": false });
            }
        )
    }

    const updateItem = () => {

        setLoading(true);
       //console.log(adquisition, user);

        serviceContract.addFunctionalIDToAdquisitionPlan(
            adquisition.IDAdquisitionPlan,
            adquisition.IDLnFunctionalID,
            adquisition.IDAccount,
            single.getAccountID(),
            adquisition.Observations,
            adquisition.CloseToEstimatedValue == 'true' ? true : false,
            adquisition.TotalValue ? parseInt(adquisition.TotalValue) : 0).subscribe(
                (resp: any) => {
                    setLoading(false);
                   //console.log(resp);
                    getAdquisitionPlanFunctionalIDCatalog(bean.IDAdquisitionPlan);

                }
            )

        setModal({ ...modal, "view": false });
    }


    const renderButtons = () => {
        return (
            <div className="row">
                <div className="col-md-6">
                    {adquisition[idBean] &&
                        <FunctionalIDDropDown bean={adquisition} permisos={permisos} updateEvent={() => { }}></FunctionalIDDropDown>
                    }
                </div>
            </div>
        )
    }

    const listaBotones = () => {
        const botones = [
            { titulo: 'Editar', icono: 'ri-edit-2-line', visible: single.canEdit(), evento: () => { setModal({ ...modal, "view": true }) } },
            { titulo: 'Eliminar', icono: 'ri-delete-bin-line', visible: single.canDelete() && (adquisition.TotalStructured == null || adquisition.TotalStructured === 0), evento: () => { setModalConfirm({ ...modalConfirm, "view": true }) } },
            { titulo: 'Ver Items', icono: 'ri-list-ordered', visible: single.canEdit(), evento: () => { setModalItems({ ...modalItems, "view": true }) } },
            { titulo: 'Preparadores', icono: 'ri-user-2-line', visible: single.canEdit(), evento: () => { setModalPreparer({ ...modalPreparer, "view": true }) } },
            { titulo: 'Rubros', icono: 'ri-file-list-line', visible: single.canEdit(), evento: () => { modalBudget.open() } },
            { titulo: 'Códigos UNSPSC', icono: 'ri-file-list-line', visible: single.canEdit(), evento: () => { modalUnscp.open() } },
            { titulo: 'Tipos de Gestión', icono: 'ri-file-settings-line', visible: true, evento: () => { setModalMetods({ ...modalMetods, "view": true }) } },
        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }

    const iconButtonsItem = [

        { nombre: 'Agregar', visible: single.canCreate(), icono: 'ri-file-add-line', evento: agregarItem }

    ]

    return (
        <>

            <div className="row">
                <div className="col-md-12">
                    <h4>{adquisition.Description}</h4>
                </div>
                <div className="col-md-12">
                    <BasicTable iconButtons={iconButtonsItem} listButtons={listaBotones()} headButtons={renderButtons()} head={head} body={lista} rowSet={setAdquisition}></BasicTable>
                </div>
            </div>

            {modal.view &&
                <Modal modal={modal} updateModal={setModal} eventModal={updateItem}>
                    <div className="row">
                        <div className="col-md-12">
                            <SelectFunctionalID functionalIDLn={adquisition.IDLnFunctionalID} onChange={(e: any) => { setAdquisition({ ...adquisition, "IDLnFunctionalID": e.IDLn }) }} />
                        </div>
                        <div className="col-md-6">
                            <AccountEditor key={adquisition.IDAccount} idAccount={adquisition.IDAccount} label='Funcionario Responsable de Estructurar la Necesidad'
                                onChange={(e: any) => { setAdquisition({ ...adquisition, "IDAccount": e.IDAccount }) }} />
                        </div>
                        <div className="col-md-6">
                            <label>Valor Estimado</label>
                            <InputMoney id={0} name='Valor Estimado' value={adquisition.TotalValue} onChange={(e: any) => { setAdquisition({ ...adquisition, ['TotalValue']: parseInt(e) }) }} />
                            {/* <input type="number" className="form-control mt-2"  value={adquisition.TotalValue} onChange={(e) => { setAdquisition({ ...adquisition, ['TotalValue']: parseInt(e.target.value) }) }} /> */}
                        </div>
                        <div className="col-md-12">
                            <label>Observaciones</label>
                            <textarea className='form-control mt-2' value={adquisition.Observations} onChange={(e) => { setAdquisition({ ...adquisition, "Observations": e.target.value }) }}></textarea>
                        </div>
                        <div className="col-md-12">
                            <label>Limitar Plan al Valor Estimado</label>
                            <select className='form-control mt-2' value={adquisition.CloseToEstimatedValue} onChange={(e) => { setAdquisition({ ...adquisition, "CloseToEstimatedValue": e.target.value }) }}>
                                <option value="false">NO</option>
                                <option value="true">SI</option>
                            </select>
                        </div>
                    </div>
                </Modal>
            }

            {modalConfirm.view &&
                <ModalConfirm modal={modalConfirm} updateModal={setModalConfirm} eventModal={eliminarItem}>
                    {adquisition.IDLnFunctionalIDName}
                </ModalConfirm>
            }


            <Modal modal={modalItems} updateModal={setModalItems}>
                <AdquisitionItem idAdquisitionPlanFunctionalID={adquisition.IDAdquisitionPlanFunctionalID} rol={role} beanOficina={adquisition} phaseState={adquisition.PhaseState} />
            </Modal>


            {modalPreparer.view &&
                <Modal modal={modalPreparer} updateModal={setModalPreparer}>
                    <FunctionalIDPreparer IDArea={adquisition.IDAdquisitionPlanFunctionalID} />
                </Modal>
            }

            {modalMetods.view &&
                <Modal modal={modalMetods} updateModal={setModalMetods} eventModal={() => { updatePermisions() }}>
                    <ManagementType idLn={adquisition.IDLnFunctionalID} _onChange={(e) => { setListPermisos(e) }} />
                </Modal>
            }

            <Modal modal={modalBudget.modal} updateModal={modalBudget.setModal} >
                <CodesByFunctionalID IDLn={adquisition.IDLnFunctionalID} typeCode={1} />
            </Modal>

            <Modal modal={modalUnscp.modal} updateModal={modalUnscp.setModal} >
                <CodesByFunctionalID IDLn={adquisition.IDLnFunctionalID} typeCode={2} />
            </Modal>

        </>
    )
}