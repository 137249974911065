import { useState, useEffect } from 'react';
import useDataBean from '../../../../../../core/services/dataBean/useDataBean';
import { _EmployeeContract } from '../../../../../../core/services/dataBean/EntityCatalog';


interface Props {
    entity: number,
    idSelector: number;
    onChange: any;
    label?: string;
}

export const WelfareEntityEditor = ({ entity, idSelector, onChange, label }: Props) => {

    const employeeContract = useDataBean(_EmployeeContract)
    const [lista, setLista] = useState([]);
    const [id, setID] = useState<number>(null);

    // public final static int EPS = 0;
    // public final static int ARP = 1;
    // public final static int CCF = 2;
    // public final static int AFP = 3;
    // public final static int SENA = 4;
    // public final static int ICBF = 5;
    // public final static int SOLIDARY_AFP = 6;
    // public final static int SUBSISTENCE_AFP = 7;
    // public final static int OTHERS = 8;
    // public final static int FCE = 9;
    // public final static int COOPERATIVE = 10;


    useEffect(() => {
        setID(idSelector);
    }, [])

    useEffect(() => {
        getLista();
    }, [entity])

    const setValue = (e: any) => {

        if (e != null) {
            setID(e);
            onChange(e);
        } else {
            onChange(null)
            setID(null)
        }
    }

    const getLista = () => {

        employeeContract.instance.getWelfareEntityCatalogByType(entity).then(
            (resp: any) => {
                if (resp) {
                    setLista(resp)
                }
            }
        )

    }

    return (
        <>
            <label>{label ?? "Entidad de Bienestar"}</label>
            <select className="form-select" value={id} onChange={(e) => { setValue(parseInt(e.target.value)) }}>
                <option value={null}>Ninguno</option>
                {lista &&
                    lista.map((item: any, index: number) => {
                        return (

                            <option key={index} value={item.DataBeanProperties.IDWelfareEntity} >{(item.DataBeanProperties.Code ?? "") + " - " + (item.DataBeanProperties.AccountName ?? "")} </option>
                        )
                    })
                }
            </select>
        </>
    )
}