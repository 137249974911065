import { useEffect, useState } from "react";
import { _AppointmentManual } from "../../../../core/services/dataBean/EntityCatalog";
import useDataBean from "../../../../core/services/dataBean/useDataBean";
import { Singleton } from "../../../../core/services/Singleton";
import { ListMenu } from "../../../theme/Component/ListMenu";
import { BasicPage } from "../../../theme/Component/BasicPage";
import { BasicTable } from "../../../theme/Component/BasicTable";
import { Modal } from "../../../theme/Component/Modal";
import { ModalConfirm } from "../../../theme/Component/ModalConfirm";
import { toast } from "react-toastify";
import { SelectFunctionalID } from "../../../admin/configuracion/functionalID/SelectFunctionalID";
import { AppointmentGroupManualEditor } from "../AppointmentGroupManual/AppointmentGroupManual.editor";
import { AppointmentEditor } from "../Appointment/Appointment.editor";
import { EntailmentEditor } from "../Entailment/Entailment.editor";
import { AppointmentManualEditor } from "./AppointmentManual.editor";
import { SelectSiteID } from "../../../admin/configuracion/SiteID/SelectSiteID";
import { AppointmentManualConstant } from "./AppointmentManualConstant.editor";
import { MarcroProccesEditor } from "../MacroProcess/MacroProccesEditors/MacroProcess.editor";
import { SubMarcroProccesEditor } from "../MacroProcess/MacroProccesEditors/SubMarcroProcces.editor";
import { Function } from "./AppointmentManualComponents/Function";
import { Experience } from "./AppointmentManualComponents/Experience";
import { Ability } from "./AppointmentManualComponents/Ability";
import { Knowledge } from "./AppointmentManualComponents/Knowledge";
import { TechnicalSkill } from "./AppointmentManualComponents/TechnicalSkill/TechnicalSkill";
import { Competition } from "./AppointmentManualComponents/Competition";
import { Training } from "./AppointmentManualComponents/Training";

export const AppointmentManual = () => {

    const appointmentManual = useDataBean(_AppointmentManual);
    const single = Singleton.getInstance();

    const [appoGroup, setAppoGroup] = useState<number>(null);
    const [lista, setLista] = useState<any>([]);
    const [modal, setModal] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalC, setModalC] = useState<any>({ name: 'Eliminar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });
    const [modalFunciones, setModalFunciones] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalConocimientos, setModalConocimientos] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalFormacion, setModalFormacion] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalExperiencia, setModalExperiencia] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalHabilitiesBy, setModalHabilitiesBy] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalCompetencia, setModalCompetencia] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalProduct, setModalProduct] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });

    useEffect(() => {
        if (appoGroup > 0) {
            getLista();
        }
    }, [appoGroup])


    const getLista = () => {
        appointmentManual.instance.getAppointmentManualCatalog(appoGroup).then(
            (resp: any) => {
                if (resp) {
                    setLista(resp)
                }
            }
        )

    }

    const create = () => {
        appointmentManual.setBean({
            IDAppointmentGroupManual: appoGroup,
        });
        setModal({ ...modal, "name": "Agregar Manual de Función", "view": true });
    }
    const deleteItem = () => {
        appointmentManual.instance.deleteAppointmentManual(appointmentManual.bean).then(
            (resp: any) => {
                getLista();
                setModalC({ ...modalC, "view": false });
            }
        )
    }

    const editaItem = () => {
        appointmentManual.update().then(
            (resp: any) => {
                getLista();
                setModal({ ...modal, "view": false });
            }
        ).catch(err => {
            toast.error(err);
        })

    }

    const head = [
        { title: 'ID', property: 'IDAppointmentManual', visible: true },
        { title: 'Código', property: 'Code', visible: true },
        { title: 'Nombre', property: 'Name', visible: true },
        { title: 'Descripción', property: 'Description', visible: true },
        { title: 'Fecha de creacion', property: 'Since', visible: true },
        { title: 'Propósito General', property: 'GeneralPurpose', visible: false },
        { title: 'Observaciones', property: 'Observations', visible: false },
        { title: 'Ciudad', property: 'IDLnSiteID', visible: false },
        { title: 'Área Funcional', property: 'IDLnFunctionalID', visible: false },
        { title: 'MacroProceso', property: 'IDMacroProcess', visible: false },
        { title: 'Proceso', property: 'IDSubProcess', visible: false },
        { title: 'Nivel de Empleo', property: 'IDAppointment', visible: false },
        { title: 'Nivel Jerárquico de Empleo', property: 'IDEntailment', visible: false },
        { title: 'Complejidad', property: 'Complexity', visible: false },
        { title: 'Pagina', property: 'Page', visible: false },
        { title: 'Cantidad de Empleos', property: 'Quantity', visible: false },
        { title: 'Estado', property: 'State', visible: false },
    ]

    const iconButtonsItem = [
        { nombre: 'Agregar', visible: single.canCreate(), icono: 'ri-file-add-line', evento: create }
    ]

    const listaBotones = () => {
        const botones = [
            { titulo: 'Editar', icono: 'ri-edit-2-line', visible: single.canEdit(), evento: () => { setModal({ ...modal, "view": true }) } },
            { titulo: 'Eliminar', icono: 'ri-delete-bin-line', visible: single.canDelete(), evento: () => { setModalC({ ...modalC, "view": true }) } },
            { titulo: 'Funciones del Cargo', icono: 'ri-function-line', visible: single.canDelete(), evento: () => { setModalFunciones({ ...modalFunciones, "view": true, "name": `Funciones del cargo ${appointmentManual.bean.Name}` }) } },
            { titulo: 'Conocimientos del Cargo', icono: 'ri-shield-user-line', visible: single.canDelete(), evento: () => { setModalConocimientos({ ...modalConocimientos, "view": true, "name": `Conocimientos del Cargo ${appointmentManual.bean.Name}` }) } },
            { titulo: 'Formación Academica del Cargo', icono: 'ri-book-read-line', visible: single.canDelete(), evento: () => { setModalFormacion({ ...modalFormacion, "view": true, "name": `Formación Academica del Cargo ${appointmentManual.bean.Name}` }) } },
            { titulo: 'Experiencia para el Cargo', icono: 'ri-folders-line', visible: single.canDelete(), evento: () => { setModalExperiencia({ ...modalExperiencia, "view": true, "name": `Experiencia para el Cargo ${appointmentManual.bean.Name}` }) } },
            { titulo: 'Habilidades del Cargo', icono: 'ri-file-edit-line', visible: single.canDelete(), evento: () => { setModalHabilitiesBy({ ...modalHabilitiesBy, "view": true, "name": `Habilidades del Cargo ${appointmentManual.bean.Name}` }) } },
            { titulo: 'Competencia Comportamental del cargo', icono: 'ri-contacts-book-line', visible: single.canDelete(), evento: () => { setModalCompetencia({ ...modalCompetencia, "view": true, "name": `Competencia Comportamental del cargo ${appointmentManual.bean.Name}` }) } },
            { titulo: 'Productos de Habilidades Tecnicas', icono: 'ri-numbers-line', visible: single.canDelete(), evento: () => { setModalProduct({ ...modalProduct, "view": true, "name": `Productos de Habilidades Tecnicas ${appointmentManual.bean.Name}` }) } },

        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }


    return (
        <>
            <BasicPage titulo="Manuales de Funciones">
                <div className="row">
                    <div className="col-6">
                        <AppointmentGroupManualEditor idSelector={appoGroup} onChange={(e) => { setAppoGroup(e) }} />
                    </div>
                    <div className="col-md-12">
                        <BasicTable body={lista} head={head} rowSet={appointmentManual.setBean} listButtons={listaBotones()} iconButtons={iconButtonsItem}></BasicTable>
                    </div>
                </div>
            </BasicPage>

            {modal.view &&
                <Modal modal={modal} updateModal={setModal} eventModal={editaItem}>
                    <div className="row">
                        <div className="col-md-6">
                            <AppointmentEditor idSelector={appointmentManual.bean.IDAppointment} onChange={(e) => appointmentManual.setBean({ ...appointmentManual.bean, "IDAppointment": e })} />
                        </div>
                        <div className="col-md-6">
                            <EntailmentEditor label="Nivel Jerárquico de Empleo:" idSelector={appointmentManual.bean.IDEntailment} onChange={(e) => appointmentManual.setBean({ ...appointmentManual.bean, "IDEntailment": e })} />
                        </div>
                        <div className="col-6">
                            <AppointmentManualEditor appoGroup={appoGroup} idSelector={appointmentManual.bean.IDBossAppointmentManual} onChange={(e) => { appointmentManual.setBean({ ...appointmentManual.bean, "IDBossAppointmentManual": e }) }} />
                        </div>
                        <div className="col-6">
                            <AppointmentManualConstant label="Estado:" idSelector={appointmentManual.bean.State} onChange={(e) => { appointmentManual.setBean({ ...appointmentManual.bean, "State": e }) }} />
                        </div>
                        <div className="col-6">
                            <SelectSiteID idLnSite={appointmentManual.bean.IDLnSiteID} onChange={(e) => { appointmentManual.setBean({ ...appointmentManual.bean, "IDLnSiteID": e.IDLn }) }} edit />
                        </div>
                        <div className="col-6">
                            <SelectFunctionalID functionalIDLn={appointmentManual.bean.IDLnFunctionalID} onChange={(e) => { appointmentManual.setBean({ ...appointmentManual.bean, "IDLnFunctionalID": e.IDLn }) }} />
                        </div>
                        <div className="col-6">
                            <MarcroProccesEditor idSelector={appointmentManual.bean.IDMacroProcess} onChange={(e) => { appointmentManual.setBean({ ...appointmentManual.bean, "IDMacroProcess": e }) }} />
                        </div>
                        <div className="col-6">
                            <SubMarcroProccesEditor macroProcess={appointmentManual.bean.IDMacroProcess} idSelector={appointmentManual.bean.IDSubProcess} onChange={(e) => { appointmentManual.setBean({ ...appointmentManual.bean, "IDSubProcess": e }) }} />
                        </div>
                        <div className="col-md-6">
                            <label>Código del Manual:</label>
                            <input type="text" name="Code" className="form-control" value={appointmentManual.bean.Code} onChange={appointmentManual.handleChange} />
                        </div>
                        <div className="col-6">
                            <label>Nombre:</label>
                            <input type="text" name="Name" className="form-control" value={appointmentManual.bean.Name} onChange={appointmentManual.handleChange} />
                        </div>
                        <div className="col-md-12">
                            <label>Descripción:</label>
                            <textarea name="Description" className="form-control" value={appointmentManual.bean.Description} onChange={appointmentManual.handleChange}></textarea>
                        </div>
                        <div className="col-md-6">
                            <label>Cantidad de Empleos:</label>
                            <input type="text" name="Quantity" className="form-control" value={appointmentManual.bean.Quantity} onChange={appointmentManual.handleChange} />
                        </div>
                        <div className="col-md-6">
                            <label>Complejidad:</label>
                            <input type="number" name="Complexity" className="form-control" value={appointmentManual.bean.Complexity} onChange={appointmentManual.handleChange} />
                        </div>
                        <div className="col-md-12">
                            <label>Propósito General del Cargo:</label>
                            <textarea name="GeneralPurpose" className="form-control" value={appointmentManual.bean.GeneralPurpose} onChange={appointmentManual.handleChange}></textarea>
                        </div>
                        <div className="col-md-12">
                            <label>Observaciones del Cargo: </label>
                            <textarea name="Observations" className="form-control" value={appointmentManual.bean.Observations} onChange={appointmentManual.handleChange}></textarea>
                        </div>
                        <div className="col-md-6">
                            <label>Pagina:</label>
                            <input type="number" name="Page" className="form-control" value={appointmentManual.bean.Page} onChange={appointmentManual.handleChange} />
                        </div>
                    </div>
                </Modal>
            }

            {modalC.view &&
                <ModalConfirm modal={modalC} updateModal={setModalC} eventModal={deleteItem}>
                    <strong>Eliminar item {appointmentManual.bean.Name}</strong>
                </ModalConfirm>
            }

            {modalFunciones.view &&
                <Modal modal={modalFunciones} updateModal={setModalFunciones}>
                    <Function _IDAppointManual={appointmentManual.bean.IDAppointmentManual} />
                </Modal>
            }

            {modalConocimientos.view &&
                <Modal modal={modalConocimientos} updateModal={setModalConocimientos}>
                    <Knowledge _IDAppointManual={appointmentManual.bean.IDAppointmentManual} />
                </Modal>
            }

            {modalFormacion.view &&
                <Modal modal={modalFormacion} updateModal={setModalFormacion}>
                    <Training _IDAppointManual={appointmentManual.bean.IDAppointmentManual} />
                </Modal>
            }

            {modalExperiencia.view &&
                <Modal modal={modalExperiencia} updateModal={setModalExperiencia}>
                    <Experience _IDAppointManual={appointmentManual.bean.IDAppointmentManual} />
                </Modal>
            }

            {modalHabilitiesBy.view &&
                <Modal modal={modalHabilitiesBy} updateModal={setModalHabilitiesBy}>
                    <Ability _IDAppointManual={appointmentManual.bean.IDAppointmentManual} />
                </Modal>
            }

            {modalCompetencia.view &&
                <Modal modal={modalCompetencia} updateModal={setModalCompetencia}>
                    <Competition _IDAppointManual={appointmentManual.bean.IDAppointmentManual} />
                </Modal>
            }

            {modalProduct.view &&
                <Modal modal={modalProduct} updateModal={setModalProduct}>
                    <TechnicalSkill _IDAppointManual={appointmentManual.bean.IDAppointmentManual} />
                </Modal>
            }

        </>
    )
}