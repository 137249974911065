import { useEffect, useState } from "react";
import { Singleton } from "../../../../../core/services/Singleton";
import { ModalConfirm } from "../../../../theme/Component/ModalConfirm";
import { MyEditor } from "../../../../theme/Component/MyEditor";
import { Modal } from "../../../../theme/Component/Modal";
import { BpmService } from "../../../../../core/services/BpmService";
import { toast } from "react-toastify";
import { CardTaps } from "../../../../theme/Component/CardTaps";
import { FaBan, FaCheckCircle, FaClock } from "react-icons/fa";
import { ProcedureActionInbox } from "./ProcedureActionInbox";
import { PersonalHistory } from "./PersonalHistory";
import { BasicButton } from "../../../../theme/Component/BasicButton";
import useDataBean from "../../../../../core/services/dataBean/useDataBean";
import { _ItemFlow } from "../../../../../core/services/dataBean/EntityCatalog";


interface Procedure {
    IDProcedureImp: number;
    AlphaCode: string;
}

export const PerosnalInbox = () => {

    const _single = Singleton.getInstance();
    const _flows = useDataBean(_ItemFlow)
    const service = new BpmService();
    const [lista, setLista] = useState<any>();
    const [bean, setBean] = useState<Procedure>({ IDProcedureImp: 0, AlphaCode: "" });
    const [description, setDescription] = useState<string>("");
    const [modalHistory, setModalHistory] = useState<any>({ name: 'Historial', tabIndex: -1, view: false, btnClose: true, btnSubmit: false, format: 'modal-lg' });
    const [modalConfirm, setModalConfirm] = useState<any>({ name: 'Cancelar Procedimiento', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-md' });

    useEffect(() => {
        getLista()
    }, [])

    const getLista = () => {
        _single.spinner(true)
        service.getProcedureImpByAccount(_single.getAccountID()).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue) {
                    setLista(resp.DataBeanProperties.ObjectValue);
                    _single.spinner(false)
                } else if (resp.DataBeanProperties.ErrorMessage) {
                    toast.error(resp.DataBeanProperties.ErrorMessage)
                }
                else {
                    toast.success("No tiene tareas pendientes")
                }
            }
        )
    }

    // const deleteProcedureImp = () => {
    //     service.cancelProcedureImp(bean.IDProcedureImp, _single.getAccountID(), description).subscribe(
    //         (resp: any) => {
    //             if (resp) {
    //                 toast.success("Se ha realizado la accion");
    //                 getLista();
    //                 setModalConfirm({ ...modalConfirm, "view": false });
    //             }
    //         }
    //     )
    // }

    const deleteProcedureItemFlow = () => {
        _flows.instance.cancelBusinessProcesByIDItemFlow(bean.IDProcedureImp, _single.getAccountID()).then(
            (resp: any) => {
                console.log(resp)
                getLista();
                setModalConfirm({ ...modalConfirm, "view": false });
            }
        ).catch(err => { console.log(err) })
    }

    const taps = [
        { id: 0, name: 'Solicitudes' },
        { id: 1, name: 'Pendientes' }
    ]
    const [indexTap, setIndexTap] = useState(0);

    return (
        <>
            <CardTaps taps={taps} index={indexTap} updateIndex={setIndexTap} >
                {indexTap === 0 &&
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">Procedimiento</th>
                                <th scope="col">Estado</th>
                                <th scope="col">Proceso</th>
                                <th scope="col">Fecha</th>
                                <th scope="col">Acciones</th>
                            </tr>
                        </thead>
                        {lista &&
                            <>
                                <tbody>
                                    {lista.map((e: any, i: number) =>
                                        <tr
                                            key={i}
                                        >
                                            <th scope="row">{e.DataBeanProperties.AlphaCode}</th>
                                            <td><strong>{e.DataBeanProperties.StateName}</strong></td>
                                            <td>{e.DataBeanProperties.ProcedureName}</td>
                                            <td>{e.DataBeanProperties.Since}</td>
                                            <td>
                                                <div className="d-flex justify-content-center gap-2 mt-3 fs-2 text-warning">
                                                    <FaCheckCircle title='Gestionar' className="pointer" onClick={() => { setIndexTap(1); setBean(e.DataBeanProperties) }} />
                                                    <FaClock title='Historial' className="pointer" onClick={() => { setModalHistory({ ...modalHistory, "view": true }); setBean(e.DataBeanProperties) }} />
                                                    <FaBan title='Cancelar' className="pointer" onClick={() => { setModalConfirm({ ...modalConfirm, "view": true }); setBean(e.DataBeanProperties) }} />
                                                </div>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </>
                        }
                    </table>
                }
                {indexTap === 1 &&
                    <>
                        <h3>{bean.AlphaCode}</h3>
                        <ProcedureActionInbox requestLista={() => { setIndexTap(0); getLista(); }} Procedure={bean} />
                        <BasicButton icon="ri-arrow-left-fill" eventClick={() => { setIndexTap(0); getLista(); }} >Volver</BasicButton>
                    </>
                }
            </CardTaps>

            <Modal modal={modalHistory} updateModal={setModalHistory}>
                <PersonalHistory IDProcedureImp={bean.IDProcedureImp} />
            </Modal>
            <ModalConfirm modal={modalConfirm} updateModal={setModalConfirm} eventModal={deleteProcedureItemFlow}>
                <p>¿Porque razon quieres cancelar el proceso?</p>
                <MyEditor val={description} onChange={(e: any) => { setDescription(e) }}></MyEditor>
            </ModalConfirm>
        </>
    )
}