import { useEffect, useState } from "react";
import { _TaxSchemeContextCode, _TaxSchemeValue } from "../../../../../core/services/dataBean/EntityCatalog";
import useDataBean from "../../../../../core/services/dataBean/useDataBean";
import { Singleton } from "../../../../../core/services/Singleton";
import { toast } from "react-toastify";
import { ListMenu } from "../../../../theme/Component/ListMenu";
import { BasicTable, MASK_NUMBER, MASK_TEXT_NOWRAP } from "../../../../theme/Component/BasicTable";
import { Modal } from "../../../../theme/Component/Modal";
import { ModalConfirm } from "../../../../theme/Component/ModalConfirm";
import { TaxSchemeContextStateEditor } from "../TaxSchemeContextState.editor";
import { SelectAccountantTree } from "../../../../contabilidad/catalogo-cuentas/AccountantTree/SelectAccountantTree";
import { TaxSchemeValueConstantsEditor } from "./TaxSchemeValueConstants.editor";
import { TaxableArticleTypesEditor } from "./TaxableArticleTypes.editor";
import { TaxFilerTypesEditor } from "./TaxFilerTypes.editor";
import { TaxSchemeCodeValue } from "./TaxSchemeCodeValue";
import { CardTaps } from "../../../../theme/Component/CardTaps";


interface Props {
    _IDTaxScheme: number;
}

export const TaxSchemeValue = ({ _IDTaxScheme }: Props) => {

    const taxSchemeValue = useDataBean(_TaxSchemeValue);
    const single = Singleton.getInstance();
    const [lista, setLista] = useState<any>([]);
    const [modal, setModal] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true });
    const [modalAdd, setModalAdd] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true });
    const [modalC, setModalC] = useState<any>({ name: 'Eliminar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });
    const [modalAplicar, setModalAplicar] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });

    useEffect(() => {
        getLista();
    }, [_IDTaxScheme])

    const getLista = () => {
        taxSchemeValue.instance.getTaxSchemeValueCatalog(_IDTaxScheme).then(
            (resp: any) => {
                if (resp) {
                    setLista(resp)
                   //console.log(resp)
                }
            }
        )
    }

    const create = () => {
        taxSchemeValue.setBean({
            IDTaxScheme: _IDTaxScheme
        });
        setIndexTap(0);
        setModalAdd({ ...modalAdd, "format": 'modal-md', "name": "Agregar Tarifa de Impuesto", "view": true });
    }

    const deleteItem = () => {
       //console.log(taxSchemeValue.bean.IDTaxSchemeValue)
        taxSchemeValue.deleteItemVoid(taxSchemeValue.bean.IDTaxSchemeValue).then(
            (resp: any) => {
                getLista();
                setModalC({ ...modalC, "view": false });
            }
        )
    }

    const editaItem = () => {
        taxSchemeValue.update().then(
            (resp: any) => {
                getLista();
                setModal({ ...modal, "view": false });
            }
        ).catch(err => {
            toast.error(err);
        })
    }

    const validateAdd = () => {
        if (indexTap == 0) {
            addSchemeValue()
        } else if (indexTap == 1) {
            addTaxSchemeValueWithDescription();
        }
    }

    const addSchemeValue = () => {
        taxSchemeValue.instance.addTaxSchemeValue(_IDTaxScheme, taxSchemeValue.bean.TaxValue).then(
            (resp) => {
                getLista();
                setModalAdd({ ...modalAdd, "view": false });
            }
        ).catch(
            err => {
               console.error(err)
            }
        )
    }

    const addTaxSchemeValueWithDescription = () => {
        taxSchemeValue.instance.addTaxSchemeValueWithDescription(_IDTaxScheme, taxSchemeValue.bean.TaxValue, taxSchemeValue.bean.Description).then(
            (resp) => {
                getLista();
                setModalAdd({ ...modalAdd, "view": false });
            }
        ).catch(
            err => {
               console.error(err)
            }
        )
    }

    const head = [
        { title: 'ID', property: 'IDTaxSchemeValue', visible: true, mask: MASK_NUMBER },
        { title: 'Tipo de Impuesto', property: 'TaxSchemeName', visible: true, },
        { title: 'Valor', property: 'TaxValueName', visible: true, mask: MASK_TEXT_NOWRAP },
        { title: 'Tipo de valor', property: 'ValueTypeName', visible: true },
        { title: 'Tipo de Bien', property: 'AdquisitionArticleTypeName', visible: true },
        { title: 'Tipo de Contribuyente', property: 'TaxFilerTypeName', visible: true },
        { title: 'Cuenta Contable Retención/Descuento', property: 'AccountantIDName', visible: true },
        { title: 'Cuenta Contable Vr Agregado', property: 'AccountantIDAddedValueName', visible: true },
        { title: 'Estado', property: 'StateName', visible: true },
        { title: 'Códigos de Clasificación', property: 'TaxCodeList', visible: true, Style: { maxWidth: '100vh', wordWrap: 'break-word', whiteSpace: 'normal' } },
        { title: 'Descripción', property: 'Description', visible: true },
    ]

    const iconButtonsItem = [
        { nombre: 'Agregar', visible: single.canCreate(), icono: 'ri-file-add-line', evento: create }
    ]
    const listaBotones = () => {
        const botones = [
            { titulo: 'Editar', icono: 'ri-edit-2-line', visible: single.canEdit(), evento: () => { setModal({ ...modal, "name": "Editar Tarifa de Impuesto", "format": 'modal-lg', "view": true }) } },
            { titulo: 'Eliminar', icono: 'ri-delete-bin-line', visible: single.canDelete(), evento: () => { setModalC({ ...modalC, "view": true }) } },
            { titulo: 'Aplicar Tipos de Actividad a la Tarifa de Impuesto', icono: 'ri-checkbox-line', visible: single.canDelete(), evento: () => { setModalAplicar({ ...modalAplicar, "name": "Aplicar Tipos de Actividad a la Tarifa de Impuesto seleccionada", "view": true }) } },
        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }

    const [indexTap, setIndexTap] = useState(0);

    const taps = [
        { id: 1, name: 'Indicar solo tarifa' },
        { id: 2, name: 'Indicar tarifa y descripción' }
    ]

    return (
        <>
            <div className="row">
                <div className="col-md-12">
                    <BasicTable body={lista} head={head} rowSet={taxSchemeValue.setBean} listButtons={listaBotones()} iconButtons={iconButtonsItem}></BasicTable>
                </div>
            </div>

            {modal.view &&
                <Modal modal={modal} updateModal={setModal} eventModal={editaItem}>
                    <div className="row">
                        <div className="col-md-6">
                            <SelectAccountantTree label="Cuenta Contable Retención/Descuento" jsonCode={taxSchemeValue.bean.AccountantIDStr} onChange={(e) => taxSchemeValue.setBean({ ...taxSchemeValue.bean, "AccountantIDStr": e.JsonCode })} />
                        </div>
                        <div className="col-md-6">
                            <SelectAccountantTree label="Cuenta Contable Vr Agregado" jsonCode={taxSchemeValue.bean.AccountantIDAddedValueStr} onChange={(e) => taxSchemeValue.setBean({ ...taxSchemeValue.bean, "AccountantIDAddedValueStr": e.JsonCode })} />
                        </div>
                        <div className="col-md-6">
                            <TaxableArticleTypesEditor label="Tipo de Acticulo Adquirido" idSelector={taxSchemeValue.bean.AdquisitionArticleType} onChange={(e) => taxSchemeValue.setBean({ ...taxSchemeValue.bean, "AdquisitionArticleType": e })} />
                        </div>
                        <div className="col-md-6">
                            <TaxFilerTypesEditor idSelector={taxSchemeValue.bean.TaxFilerType} onChange={(e) => taxSchemeValue.setBean({ ...taxSchemeValue.bean, "TaxFilerType": e })} />
                        </div>
                        <div className="col-md-6">
                            <TaxSchemeContextStateEditor idSelector={taxSchemeValue.bean.State} onChange={(e) => taxSchemeValue.setBean({ ...taxSchemeValue.bean, "State": e })} />
                        </div>
                        <div className="col-md-6">
                            <TaxSchemeValueConstantsEditor label="Tipo de Valor" idSelector={taxSchemeValue.bean.ValueType} onChange={(e) => { taxSchemeValue.setBean({ ...taxSchemeValue.bean, "ValueType": e }) }} />
                        </div>
                        <div className="col-md-6">
                            <label>Valor de la Tarifa</label>
                            <input type="number" name="TaxValue" className="form-control" value={taxSchemeValue.bean.TaxValue} onChange={taxSchemeValue.handleChange} />
                        </div>
                        <div className="col-md-12">
                            <label>Descripción</label>
                            <textarea name="Description" className="form-control" value={taxSchemeValue.bean.Description} onChange={taxSchemeValue.handleChange} />
                        </div>
                    </div>
                </Modal>
            }

            {modalAdd.view &&
                <Modal modal={modalAdd} updateModal={setModalAdd} eventModal={validateAdd}>
                    <CardTaps index={indexTap} taps={taps} updateIndex={setIndexTap}>
                        {indexTap === 0 &&
                            <div className="row">
                                <div className="col-md-12">
                                    <label>Valor de la Tarifa:</label>
                                    <input type="number" name="TaxValue" className="form-control" value={taxSchemeValue.bean.TaxValue} onChange={taxSchemeValue.handleChange} />
                                </div>
                            </div>
                        }
                        {indexTap === 1 &&
                            <div className="row">
                                <div className="col-md-12">
                                    <label>Valor de la Tarifa:</label>
                                    <input type="number" name="TaxValue" className="form-control" value={taxSchemeValue.bean.TaxValue} onChange={taxSchemeValue.handleChange} />
                                </div>
                                <div className="col-md-12">
                                    <label>Descripción</label>
                                    <textarea name="Description" rows={5} className="form-control" value={taxSchemeValue.bean.Description} onChange={taxSchemeValue.handleChange} />
                                </div>
                            </div>

                        }
                    </CardTaps>
                </Modal>
            }

            {modalAplicar.view &&
                <Modal modal={modalAplicar} updateModal={setModalAplicar}>
                    <TaxSchemeCodeValue _onChange={() => { getLista(); }} _IDTaxSchemeValue={taxSchemeValue.bean.IDTaxSchemeValue} />
                </Modal>
            }

            <ModalConfirm modal={modalC} updateModal={setModalC} eventModal={deleteItem}>
                <strong>Eliminar item con el ID {taxSchemeValue.bean.IDTaxSchemeValue}</strong>
            </ModalConfirm>
        </>
    )
}