import { DataBean } from "../DataBean";


export class FunctionalArea extends DataBean {

    public async getFunctionalAreaCatalog(state: number, name: string | null, idLnFunctionalID: number | null, idAppointment: number | null, contractState: number | null): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_getFunctionalAreaCatalog_Number_String_Number_Number_Number", [state, name, idLnFunctionalID, idAppointment, contractState]);
    }

    public async getFunctionalAreaStateConstants(arg: null): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_getFunctionalAreaStateConstants_Number", [arg]);
    }

}