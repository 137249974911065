import { useEffect, useState } from "react";
import { AdminService } from "../../../core/services/AdminService";
import { Singleton } from "../../../core/services/Singleton";
import { PadreMenu } from "./PadreMenu";
import { Link, useNavigate } from "react-router-dom";
import { useDashboardStore } from "../../pages/hooks/useDashboardStore";
import { toast } from "react-toastify";
import { FileService } from "../../../core/services/FileService";


export const MenuModulo = () => {
  const adminService = new AdminService();
  const single = Singleton.getInstance()
  const { setLoading } = useDashboardStore();
  const fileService = new FileService();
  const [menu, setMenu] = useState<any>([]);
  const [idRol, setIDRol] = useState(0);
  const [rolName, setRolName] = useState("");
  const [account, setAccount] = useState<any>({});
  const [roles, setListaRoles] = useState<any>([]);
  const [image, setImage] = useState<string>(null)


  const navigate = useNavigate();

  useEffect(() => {
    let acc = single.getAccount() || {};
    ////console.log("MENU MODULO => ", acc);
    if (acc.IDAccount) {
      setAccount(acc);
      
    }
    else {
      logout();
    }
  }, []);

  useEffect(() => {
    if (account.IDAccount) {
      getAccountRoleByAccount();
    }
    if (account.Photo) {
      setImage(fileService.getUrlFile("photos", account.Photo));
    }
  }, [account])


  useEffect(() => {
    getUserMenu();
    roles.forEach((rol: any) => {
      if (rol.DataBeanProperties.IDRole === idRol) {
        setRolName(rol.DataBeanProperties.RoleName);
      }
    });
  }, [idRol]);

  const getAccountRoleByAccount = () => {
    setLoading(true);
    let id: number = account.IDAccount;
    adminService.getAccountRoleByAccount(id, 0).subscribe((resp: any) => {
      if (resp.DataBeanProperties.ObjectValue) {
        let rol = resp.DataBeanProperties.ObjectValue;
        if (rol.length > 0) {
          setIDRol(rol[0].DataBeanProperties.IDRole);
        }
        setListaRoles(resp.DataBeanProperties.ObjectValue);
      }
      setLoading(false);

    });
  };


  const getUserMenu = () => {
    let id: number = account.IDAccount;
    if (idRol > 0) {
      single.spinner(true);
      adminService.getUserMenuRolesTypes(id, idRol, 0).subscribe(
        (resp: any) => {
          if (resp.DataBeanProperties.ErrorMessage) {
            
            toast.error("Token de acceso invalido ");
          }
          else if (resp.DataBeanProperties.ObjectValue) {
            let m = resp.DataBeanProperties.ObjectValue;
            let permisos: any = [];
            m.forEach((item: any) => {
             

              if (item.DataBeanProperties.Hijos) {
                item.DataBeanProperties.Hijos.forEach((hijo: any) => {
                  permisos.push(hijo);
                });
              }
            });
            setMenu(resp.DataBeanProperties.ObjectValue);
            single.setPermision(permisos);
            single.spinner(false);
          }
        }
      );
    };
  }

  const logout = () => {
    Singleton.getInstance().closeSesion();
    navigate("/login");
  };

  return (
    <div className="containermenu pl-2" style={{ maxWidth: "inherit" }}>
      <div className="dropdown header-item ">
        <button
          type="button"
          className=" btn_user"
          id="page-header-user-dropdown"
          data-bs-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <span className="d-flex align-items-center">
            <img
              className="rounded-circle header-profile-user"
              src={image ?? "assets/images/core/avatar.png"}
              alt="Header Avatar"
            />
            <span className="text-start d-flex flex-column mx-2" style={{ whiteSpace: 'nowrap', overflow: "hidden" }}>
              <span
                style={{ color: "white", whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}
                className="d-xl-inline-block ms-1 fw-medium user-name-text"
              >
                {account.EntityName}
              </span>
              <span style={{ color: "rgba(255,255,255,0.9)" }} className="d-xl-block ms-1 fs-12 text-muted user-name-sub-text">
                {account.LdapUser}
              </span>
            </span>
          </span>
        </button>
        <div className="dropdown-menu dropdown-menu-end">
          <h6 className="dropdown-header">Tu cuenta</h6>

          <Link key={100} className="dropdown-item" to="/app/admin/perfil">
            <i className="mdi mdi-account-circle text-muted fs-16 align-middle me-1"></i>
            <span className="align-middle">Perfil</span>
          </Link>
          <Link key={101} className="dropdown-item" to="/app/messages/51">
            <i className="mdi mdi-message-text-outline text-muted fs-16 align-middle me-1"></i>{" "}
            <span className="align-middle">Mensajes</span>
          </Link>
          <Link key={102} className="dropdown-item" to="/app/dashboard">
            <i className="mdi mdi-calendar-check-outline text-muted fs-16 align-middle me-1"></i>{" "}
            <span className="align-middle">Dashboard</span>
          </Link>
          <Link key={103} className="dropdown-item" to="/" onClick={logout}>
            <i className="mdi mdi-logout text-muted fs-16 align-middle me-1"></i>{" "}
            <span className="align-middle" data-key="t-logout">
              Salir
            </span>
          </Link>
        </div>
      </div>

      {roles.length > 1 && (
        <div className="btn-group mb-4">
          <button
            style={{ borderColor: "#fb8e00" }}
            className="btn btn-outline-warning dropdown-toggle colorad"
            type="button"
            id="droprole"
            data-bs-toggle="dropdown"
          >
            {rolName}
          </button>
          <ul className="dropdown-menu w-[100%]" aria-labelledby="droprole">
            {roles.map((item: any, index: number) => (
              <li key={index}>
                <span
                  className="dropdown-item cursor"
                  onClick={() => {
                    setIDRol(item.DataBeanProperties.IDRole);
                  }}
                >
                  <strong>
                    {item.DataBeanProperties.RoleName}{" "}
                    <small>({item.DataBeanProperties.IDRole})</small>
                  </strong>
                </span>
              </li>
            ))}
          </ul>
        </div>
      )}

      {menu.map((elemento: any, index: number) => (

        <div key={index}>

          {elemento.DataBeanProperties.Hijos.length > 0 && (
            <ul
              
              className="nav nav-sm flex-column"
              style={{ color: "white" }}
            >
              <PadreMenu key={index} DataBeanProperties={elemento.DataBeanProperties}></PadreMenu>
            </ul>
          )}
        </div>


      ))}
    </div>
  );
};
