import { DataBean } from "../DataBean";

export class ContractArticleItem extends DataBean {

    public async getContractArticleItem(idcontractarticleitem:any): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_getContractArticleItem_Number", [idcontractarticleitem]);
    }

    public async getAdquisitionArticleByIDAdquisitionContract(idAdquisitionContract:any): Promise<any> {
        return this.getCustomService("FinanceCore", "java.util.List_getAdquisitionArticleByIDAdquisitionContract_Number", [idAdquisitionContract]);
    }
    public async getSupplierPaymentOrderItemCatalogForAdquisitionContract(idSupplierPaymentOrder:number,supplierPaymentOrderDate:any): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_getSupplierPaymentOrderItemCatalogForAdquisitionContract_Number_java.util.Date", [idSupplierPaymentOrder,supplierPaymentOrderDate]);
    }




}