import { NotFound } from './theme/NotFound';
import { useParams } from 'react-router-dom';
import { AcademicInformation } from './Talentohumano/GestionTH/AcademicInformation/AcademicInformation';
import { WorkExperience } from './Talentohumano/GestionTH/WorkExperience/WorkExperience';
import { FamilyGroup } from './Talentohumano/GestionTH/FamilyGroup/FamilyGroup';
import { WorkIncentives } from './Talentohumano/GestionTH/WorkIncentives/WorkIncentives';

export const RouterHumanResources = (props: any) => {

    const { module, view }: any = useParams();

    const renderSwitch = (url: string) => {

        switch (url) {
            case 'informacion-academica':
                return <AcademicInformation />;
            case 'experiencia-laboral':
                return <WorkExperience />;
            case 'grupo-familiar':
                return <FamilyGroup />;
            case 'incentivo-laboral':
                return <WorkIncentives />;
            default:
                return <NotFound />;
        }
    }

    return (
        <div className='px-5'>
            {renderSwitch(view)}
        </div>
    );
}