import { DataBean } from "../DataBean";

export class EmployeeDiscountType extends DataBean {


    public async getEmployeeDiscountTypeCatalog(): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_getEmployeeDiscountTypeCatalog_Number", [null]);
    }

    public async updateEmployeeDiscountType(bean: any): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "employees.EmployeeDiscountType_updateEmployeeDiscountType_employees.EmployeeDiscountType", [
            {
                "DataBeanProperties": bean,
                "DataBeanName": "employees.EmployeeDiscountType"
            }
        ]);
    }

    public async deleteEmployeeDiscountType(bean): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "void_deleteEmployeePaymentsType_Number", [
            {
                "DataBeanProperties": bean,
                "DataBeanName": "employees.EmployeeDiscountType"
            }
        ]);
    }
}