import { useEffect, useState } from "react";
import useDataBean from "../../../../core/services/dataBean/useDataBean";
import { Singleton } from "../../../../core/services/Singleton";
import { BasicPage } from "../../../theme/Component/BasicPage";
import { _EmployeeContract } from "../../../../core/services/dataBean/EntityCatalog";
import { SelectFunctionalArea } from "../../../admin/configuracion/FunctionalArea/SelectFunctionalArea";
import { Modal } from "../../../theme/Component/Modal";
import { BasicButton } from "../../../theme/Component/BasicButton";
import { ContractTypeEditor } from "../EmployeeContract/ComponentsContract/EditorsEmployeContrac/ContractType.editor";
import { SelectSiteID } from "../../../admin/configuracion/SiteID/SelectSiteID";
import { InputDate } from "../../../theme/Input/InputDate";
import { WelfareEntityEditor } from "../EmployeeContract/ComponentsContract/EditorsEmployeContrac/WelfareEntity.editor";
import { RevenueTypeEditor } from "../EmployeeContract/ComponentsContract/EditorsEmployeContrac/RevenueType.editor";
import { BasicSwitch } from "../../../theme/Component/BasicSwitch";
import { ARPRiskEditor } from "../EmployeeContract/ComponentsContract/EditorsEmployeContrac/ARPRisk.editor";
import { ContributorTypeEditor } from "../EmployeeContract/ComponentsContract/EditorsEmployeContrac/ContributorType.editor";
import { ContributorSubTypeEditor } from "../EmployeeContract/ComponentsContract/EditorsEmployeContrac/ContributorSubType.editor";
import { ConstractStateEditor } from "../EmployeeContract/ComponentsContract/EditorsEmployeContrac/ConstractState.editor";
import { EmployeeContractTypeEditor } from "../EmployeeContract/ComponentsContract/EditorsEmployeContrac/EmployeeContractType.editor";
import { EmploymentTypeEditor } from "../EmployeeContract/ComponentsContract/EditorsEmployeContrac/EmploymentType.editor";
import { EmployeeLiquidateEditor } from "../EmployeeContract/ComponentsContract/EditorsEmployeContrac/EmployeeLiquidate.editor";
import { AccountEditor } from "../../../editor/AccountEditor";
import { toast } from "react-toastify";
import { CostingCenterSelect } from "../../../erp/cellEditor/CostingCenter/CostingCenterSelect";
import { CardTaps } from "../../../theme/Component/CardTaps";


export const CreateEmployeeContract = () => {

    const employeeContract = useDataBean(_EmployeeContract);
    const single = Singleton.getInstance();
    const [plaza, setPlaza] = useState<any>({});
    const [bean, setBean] = useState<any>({});
    const [missingFields, setMissingFields] = useState<string[]>([]);
    const [modal, setModal] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-md' });
    const [modalUpdate, setModalUpdate] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });

    useEffect(() => {
        if (plaza.IDLn) {
            if (plaza.IDContract != null) {
                setModal({ ...modal, "view": true, "name": "Advertencia" })
            } else { }
            setBean({
                ...bean,
                IDAppointment: plaza.IDAppointment,
                IDEntailment: plaza.IDEntailment,
                // AppointmentName: plaza.AppointmentName,
                // EntailmentName: plaza.EntailmentName,
                Revenue: plaza.Salary,
                IDAccount: plaza.IDAccount,
                IDEmployee: single.getAccountID(),
            })
        }
    }, [plaza.IDLn])

    const createContrac = () => {
        employeeContract.instance.updateEmployeeContract(bean).then(
            (resp) => {
               //console.log(resp);
                toast.success("Contrato Creado Exitosamente");
                setModalUpdate({ ...modalUpdate, "view": false })
            }
        )
    }

    const update = () => {
        if (validateForm() > 0) {
            toast.error("Faltan campos obligatorios por completar");
            return;
        } else {
            setModalUpdate({ ...modalUpdate, "view": true, "name": "Crear Nuevo Contrato Laboral" });
            setModal({ ...modal, "view": false })
        }
    };

    const validateForm = () => {
        const requiredFields = [
            "InitContract", "IDAccount",
        ];

        const requiredFieldsPlaza = [
            "IDLn"
        ]


        let missing = [];
        for (let field of requiredFields) {
            if (!bean[field]) {
                missing.push(field);
            }
        }
        for (let field of requiredFieldsPlaza) {
            if (!plaza[field]) {
                missing.push(field)
            }
        }
        //console.log(missing)
        setMissingFields(missing);
        return missing.length
    };

    const taps = [
        { id: 0, name: 'Información Contrato' },
        { id: 1, name: 'Información interna' },
        { id: 2, name: 'Seguridad Social y Aportes' },
    ]

    const [indexTap, setIndexTap] = useState(0);

    return (
        <>
            <BasicPage titulo="Crear Nuevo Contrato de Trabajo">
                <>
                    <div className="row">
                        <div className="col-4">
                            <label className={`${missingFields.includes('IDLn') ? 'has-error' : ''}`} >Plaza Laboral</label>
                            <SelectFunctionalArea value={plaza} label=" " onChange={(e) => { setPlaza(e) }} />
                        </div>
                        <div className="col-3">
                            <label className={`${missingFields.includes('IDAccount') ? 'has-error' : ''}`} >Empleado</label>
                            <AccountEditor label=" " idAccount={bean.IDAccount} onChange={(e) => { setBean({ ...bean, "IDAccount": e.IDAccount }) }} />
                        </div>
                        <div className="col-3">
                            <label className={`${missingFields.includes('InitContract') ? 'has-error' : ''}`}>Fecha de Inicio de Contrato</label>
                            <InputDate name="InitContract" value={bean.InitContract} notDate={true} setDate={(e) => { setBean({ ...bean, "InitContract": e }) }} />
                        </div>
                        <div className="col-2 d-flex">
                            <div className="row d-flex align-content-end">
                                <BasicButton icon="ri-search-line" eventClick={update} >Crear</BasicButton>
                            </div>
                        </div>
                    </div>
                </>
            </BasicPage>

            {modal.view &&
                <Modal modal={modal} updateModal={setModal}  >
                    <div >
                        <label>
                            La Plaza actual esta ocupada por: <br />
                            {plaza.Nit} - {plaza.Name1 ?? ""} {plaza.Name2 ?? ""} {plaza.Surname1 ?? ""} {plaza.Surname2 ?? ""} <br />
                            Hasta la fecha: {plaza.UptoContract ? plaza.UptoContract.slice(0, 10) : ""}
                        </label>
                        <div className="col-12">
                            <label className={`${missingFields.includes('InitContract') ? 'has-error' : ''}`} >Empleado</label>
                            <AccountEditor label=" " idAccount={bean.IDAccount} onChange={(e) => { setBean({ ...bean, "IDAccount": e.IDAccount }) }} />
                        </div>
                        <div className="col-12">
                            <label className={`${missingFields.includes('InitContract') ? 'has-error' : ''}`}>Fecha de Inicio de Contrato</label>
                            <InputDate name="InitContract" value={bean.InitContract} notDate={true} setDate={(e) => { setBean({ ...bean, "InitContract": e }) }} />
                        </div>
                        <div className="row mt-2">
                            <div className="col-6">
                                <BasicButton icon="" clase="success" eventClick={() => { update() }} >Continuar</BasicButton>
                            </div>
                            <div className="col-6 d-flex justify-content-end">
                                <BasicButton eventClick={() => { setPlaza({}); setModal({ ...modal, "view": false }) }} icon="" clase="danger"  >Cancelar</BasicButton>
                            </div>
                        </div>
                    </div>
                </Modal>
            }

            {modalUpdate.view &&
                <Modal modal={modalUpdate} updateModal={setModalUpdate} eventModal={() => { createContrac() }}  >
                    <CardTaps index={indexTap} taps={taps} updateIndex={setIndexTap}>
                        {indexTap == 0 &&
                            <div className="row">
                                <div className="col-md-6">
                                    <ContractTypeEditor idSelector={bean.ContractType} onChange={(e) => { setBean({ ...bean, "ContractType": e }) }} />
                                </div>
                                <div className="col-md-6">
                                    <EmployeeContractTypeEditor idSelector={bean.IDEmployeeContractType} onChange={(e) => { setBean({ ...bean, "IDEmployeeContractType": e }) }} />
                                </div>
                                <div className="col-md-6">
                                    <label>Tiempo de Prueba</label>
                                    <input type="text" className="form-control" name="JobTestTime" value={bean.JobTestTime} onChange={(e) => { setBean({ ...bean, "JobTestTime": e.target.value }) }} />
                                </div>
                                <div className="col-md-6">
                                    <EmployeeLiquidateEditor idSelector={bean.IDEmployeeLiquidate} onChange={(e) => { setBean({ ...bean, "IDEmployeeLiquidate": e }) }} />
                                </div>
                                <div className="col-md-6">
                                    <label>Fecha de Inicio en Nación/Grupo Empresarial</label>
                                    <InputDate name="NationInit" value={bean.NationInit} setDate={(e) => { setBean({ ...bean, "NationInit": e }) }} />
                                </div>
                                <div className="col-md-6">
                                    <ConstractStateEditor idSelector={bean.ContractState} onChange={(e) => { setBean({ ...bean, "ContractState": e }) }} />
                                </div>
                                <div className="col-md-6">
                                    <label>Fecha de Inicio de Contrato</label>
                                    <InputDate name="InitContract" value={bean.InitContract} setDate={(e) => { setBean({ ...bean, "InitContract": e }) }} />
                                </div>
                                <div className="col-md-6">
                                    <RevenueTypeEditor label="Tipo de Salario" idSelector={bean.RevenueType} onChange={(e) => { setBean({ ...bean, "RevenueType": e }) }} />
                                </div>
                                <div className="col-md-6">
                                    <label>Fecha de Renuncia</label>
                                    <InputDate name="EndingDate" value={bean.EndingDate} setDate={(e) => { setBean({ ...bean, "EndingDate": e }) }} />
                                </div>
                                <div className="col-md-6">
                                    <label>Fecha de Liquidación de Contrato</label>
                                    <InputDate name="UptoContract" value={bean.UptoContract} setDate={(e) => { setBean({ ...bean, "UptoContract": e }) }} />
                                </div>
                            </div>
                        }
                        {indexTap == 1 &&
                            <div className="row">
                                <div className="col-md-6">
                                    <label >Actividad Economicá (CIIU)</label>
                                    <input type="number" className="form-control" value={bean.CIIUCode} onChange={(e) => { setBean({ ...bean, "CIIUCode": e.target.value }) }} />
                                </div>
                                <div className="col-md-6">
                                    <EmploymentTypeEditor idSelector={bean.IDEmploymentType} onChange={(e) => { setBean({ ...bean, "IDEmploymentType": e }) }} />
                                </div>
                                <div className="col-md-6">
                                    <SelectSiteID label="Ciudad de Ejecución de Labores" edit idLnSite={bean.IDLnSiteID} onChange={(e) => { setBean({ ...bean, "IDLnSiteID": e.IDLn }) }} />
                                </div>
                                <div className="col-md-6 d-flex-wrap align-content-center">
                                    <BasicSwitch label=" Definir CDPs para este contrato" estado={bean.UseCreatedCDP} eventChange={(e) => { setBean({ ...bean, "UseCreatedCDP": e }) }} />
                                </div>
                                <div className="col-md-6 d-flex-wrap align-content-center">
                                    <BasicSwitch label="Definir Centro de Costo" estado={bean.UseOwnCostingCenter} eventChange={(e) => { setBean({ ...bean, "UseOwnCostingCenter": e }) }} />
                                </div>
                                {bean.UseOwnCostingCenter &&
                                    <div className="col-md-6">
                                        <label>Centro de Costo</label>
                                        <CostingCenterSelect onChange={(e) => { setBean({ ...bean, "CostingCenter": e }) }} idSelector={bean.CostingCenter} label="Centro de Costo" />
                                        {/* <input className="form-control" type="number" value={bean.CostingCenter} onChange={(e) => { setBean({ ...bean, "CostingCenter": e.target.value }) }} /> */}
                                    </div>
                                }
                                <div className="col-md-6 d-flex-wrap align-content-center">
                                    <BasicSwitch label="Definir UVTs máximas para Retefuente" estado={bean.UseUVTMaxRetefuente} eventChange={(e) => { setBean({ ...bean, "UseUVTMaxRetefuente": e }) }} />
                                </div>
                                {bean.UseUVTMaxRetefuente &&
                                    <div className="col-md-6">
                                        <label>UVTs Máximas de Descuentos</label>
                                        <input className="form-control" type="number" value={bean.UVTMaxRetefuente} onChange={(e) => { setBean({ ...bean, "UVTMaxRetefuente": e.target.value }) }} />
                                    </div>
                                }
                            </div>
                        }
                        {indexTap == 2 &&
                            <div className="row">
                                <div className="col-md-6">
                                    <WelfareEntityEditor entity={0} idSelector={bean.EPS} onChange={(e) => { setBean({ ...bean, "EPS": e }) }} label="Entidad Promotora de Salud" />
                                </div>
                                <div className="col-md-6">
                                    <WelfareEntityEditor entity={1} idSelector={bean.ARP} onChange={(e) => { setBean({ ...bean, "ARP": e }) }} label="Aseguradora de Riesgos Profesionales" />
                                </div>
                                <div className="col-md-6">
                                    <WelfareEntityEditor entity={2} idSelector={bean.CCF} onChange={(e) => { setBean({ ...bean, "CCF": e }) }} label="Caja de Compensación Familiar" />
                                </div>
                                <div className="col-md-6">
                                    <WelfareEntityEditor entity={3} idSelector={bean.FP} onChange={(e) => { setBean({ ...bean, "FP": e }) }} label="Fondo de Pensiones" />
                                </div>
                                <div className="col-md-6">
                                    <WelfareEntityEditor entity={9} idSelector={bean.FCE} onChange={(e) => { setBean({ ...bean, "FCE": e }) }} label="Fondo de Cesantias" />
                                </div>

                                <div className="col-md-6" />
                                <div className="col-md-6">
                                    <ContributorTypeEditor idSelector={bean.EmployeeType} onChange={(e) => { setBean({ ...bean, "EmployeeType": e }) }} />
                                </div>
                                <div className="col-md-6">
                                    <ContributorSubTypeEditor idSelector={bean.EmployeeSubtype} onChange={(e) => { setBean({ ...bean, "EmployeeSubtype": e }) }} />
                                </div>
                                <div className="col-md-6">
                                    <ARPRiskEditor idSelector={bean.ARPRisk} onChange={(e) => { setBean({ ...bean, "ARPRisk": e }) }} />
                                </div>
                                <div className="col-md-6">
                                    <label>Subtipo de Riesgo Laboral</label>
                                    <input type="number" className="form-control" value={bean.ARPRiskSubtype} onChange={(e) => { setBean({ ...bean, "ARPRiskSubtype": e.target.value }) }} />
                                </div>
                                <div className="col-md-6">
                                    <label>Fecha de Ultimo Exámen Medico/Ocupacional</label>
                                    <InputDate name="HealthTestDate" value={bean.HealthTestDate} setDate={(e) => { setBean({ ...bean, "HealthTestDate": e }) }} />
                                </div>
                                <div className="col-md-6">
                                    <label>Fecha de Ultimas Vacaciones</label>
                                    <InputDate name="LastVacDate" value={bean.LastVacDate} setDate={(e) => { setBean({ ...bean, "LastVacDate": e }) }} />
                                </div>
                                <div className="col-md-6">
                                    <label>Fecha de Ultima Dotación</label>
                                    <InputDate name="LastEndowmentDate" value={bean.LastEndowmentDate} setDate={(e) => { setBean({ ...bean, "LastEndowmentDate": e }) }} />
                                </div>

                            </div>
                        }
                    </CardTaps>
                </Modal>
            }
        </>
    )
}