import { useEffect, useState } from "react";
import { useDashboardStore } from "../pages/hooks/useDashboardStore";
import { Singleton } from "../../core/services/Singleton";
import { ListMenu } from "../theme/Component/ListMenu";
import { BasicPage } from "../theme/Component/BasicPage";
import { BasicTable } from "../theme/Component/BasicTable";
import { ModalConfirm } from "../theme/Component/ModalConfirm";
import { Modal } from "../theme/Component/Modal";
import { BpmService } from "../../core/services/BpmService";
import { SelectorPlanSGSST } from "../bpm/config/Componentes/SelectorPlanSGSST";
import { SelectTipocomite } from "../bpm/config/Componentes/SelectTipocomite";
import { MyEditor } from "../theme/Component/MyEditor";
import { FileInput } from "../theme/Component/FileInput";
import { InputDate } from "../theme/Input/InputDate";
import { SelectorModulesst } from "../bpm/config/Componentes/SelectorModulesst";
import { FileService } from "../../core/services/FileService";

export const YearPlan = () => {

    const { setLoading } = useDashboardStore();
    const service = new BpmService();
    const single = Singleton.getInstance();
    const fileService = new FileService();
    const [bean, setBean] = useState<any>({});
    const [planSST, setPlanSST] = useState<number>(0);
    const [lista, setLista] = useState<any>([]);
    const [modal, setModal] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalConfirm, setModalConfirm] = useState<any>({ name: 'Confirmar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });

    useEffect(() => {
        getLista(planSST);
    }, [planSST])

    const idBean = 'IDYearPlan';

    const head = [
        { title: 'ID', property: idBean },
        { title: 'Comité', property: 'NameComite' },
        { title: 'Módulo', property: 'NameModule' },
        { title: 'Tarea/Actividad', property: 'ActionTask' },
        { title: 'Objetivo', property: 'Objetive' },
        { title: 'Recurso economico', property: 'Resource' },
        { title: 'Guia para la actividad', property: "URL" },
        { title: 'Fecha Inicial', property: 'InitDate' },
        { title: 'Fecha Final', property: 'EndDate' },
    ]

    const getLista = (plan: number) => {
        setLoading(true);
        service.getYearPlanCatalogPorPropiedadesRender(
            {
                State: 1,
                IDPlanSGSST: plan,
            }
        ).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue) {
                    let tmp = resp.DataBeanProperties.ObjectValue;
                    tmp.forEach((element: any) => {
                        if (element.DataBeanProperties.JSONGuide) {
                            let data = JSON.parse(element.DataBeanProperties.JSONGuide)
                           //console.log(data);
                            element.DataBeanProperties.URL = `<a href='${data.url}' target='_blank'>${data.Media}</a>`
                        } else {
                            element.DataBeanProperties.URL = "No presenta Guia"
                        }
                    });
                    setLista(tmp);
                }
                setLoading(false);
            }
        );
    }


    const setRowBean = (bean: any) => {
        setBean(bean);
    }

    const agregarItem = () => {
        let b = {
            IDModuleSST: 0,
            IDCommitteeType: 0,
            Objetive: "",
            Resource: 0.0,
            ActionTask: "",
            JSONGuide: "",
            InitDate: single.getCurrenDate(),
            EndDate: single.getCurrenDate(),
            State: 1,
            IDEmploye: single.getAccountID(),
            IDPlanSGSST: planSST,
        }

        setBean(b);
        setModal({ ...modal, 'view': true, "name": "Agregar Plan" });
    }

    const eliminarItem = () => {
        bean.State = 2;
        updateItem();
        setModalConfirm({ ...modalConfirm, 'view': false });
    }

    const updateItem = () => {
        setModal({ ...modal, 'view': false });
        setLoading(true);
        service.updateYearPlan(bean).subscribe(
            (resp: any) => {
                setLoading(false);
                if (resp.DataBeanProperties.ObjectValue) {
                    getLista(planSST);
                }
                if (bean.IDGroupTemplate) {
                    single.update(bean);
                } else {
                    single.create(bean);
                }
            });
    }

    const listaBotones = () => {
        const botones = [
            { titulo: 'Editar', icono: 'ri-file-list-line', visible: single.canEdit(), evento: () => { setModal({ ...modal, "view": true, "name": "Editar categoria" }) } },
            { titulo: 'Eliminar', icono: 'ri-file-user-line', visible: single.canDelete(), evento: () => { setModalConfirm({ ...modalConfirm, "view": true }) } },
        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }

    const iconButtonsItem = [
        { nombre: 'Agregar', visible: single.canCreate(), icono: 'ri-file-add-line', evento: agregarItem }
    ]

    return (
        <>
            <BasicPage titulo="Plan Anual SGSST">
                <div className="row">
                    <div className="col-md-6">
                        <SelectorPlanSGSST idSelector={planSST} onChange={(plan) => { setPlanSST(plan) }}></SelectorPlanSGSST>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 p-2">
                        <BasicTable listButtons={listaBotones()} iconButtons={iconButtonsItem} head={head} body={lista} rowSet={setRowBean}></BasicTable>
                    </div>
                </div>
            </BasicPage>

            <ModalConfirm modal={modalConfirm} updateModal={setModalConfirm} eventModal={eliminarItem}>
                {bean.Name}
            </ModalConfirm>

            <Modal modal={modal} updateModal={setModal} eventModal={updateItem}>
                <div className="row">
                    <div className="col-md-6">
                        <SelectorModulesst idSelector={bean.IDModuleSST} onChange={(module) => { setBean({ ...bean, "IDModuleSST": module }) }} titulo="Selector de modulo"></SelectorModulesst>
                    </div>
                    <div className="col-md-6">
                        <SelectTipocomite idSelector={bean.IDCommitteeType} onChange={(comite) => { setBean({ ...bean, "IDCommitteeType": parseInt(comite) }) }} titulo="Selector de tipo de comite"></SelectTipocomite>
                    </div>
                    <div className="col-md-12">
                        <label>Objetivo</label>
                        <MyEditor val={bean.Objetive} onChange={(e: any) => { setBean({ ...bean, 'Objetive': e }) }} ></MyEditor>
                    </div>
                    <div className="col-md-12">
                        <label>Tarea/Actividad</label>
                        <MyEditor val={bean.ActionTask} onChange={(e: any) => { setBean({ ...bean, 'ActionTask': e }) }}></MyEditor>
                    </div>
                    <div className="col-md-6">
                        <FileInput
                            directory='PlanYear'
                            label='Guia para ejecutar la tarea'
                            docAcept='application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/pdf'
                            onCreate={({ Media, MediaContext }: any) => {
                                const url = fileService.getUrlFile(MediaContext, Media);
                                let guia = JSON.stringify({ url, Media })
                                setBean({ ...bean, "JSONGuide": guia })
                            }}
                        ></FileInput>
                    </div>
                    <div className="col-md-6">
                        <label>Recursos</label>
                        <input className="form-control mt-2" value={bean.Resource} onChange={(resource) => { setBean({ ...bean, "Resource": resource.target.value }) }} type="number"></input>
                    </div>
                    <div className="col-md-6">
                        <InputDate name="Fecha Inicial" label="Fecha Inicial" value={bean.InitDate} setDate={(e: any) => { setBean({ ...bean, "InitDate": e }) }} />
                    </div>
                    <div className="col-md-6">
                        <InputDate name="Fecha Final" label="Fecha Final" value={bean.EndDate} setDate={(e: any) => { setBean({ ...bean, "EndDate": e }) }} />
                    </div>
                </div>
            </Modal>
        </>
    )
}