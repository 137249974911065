import { useEffect, useState } from "react";
import { _Entailment } from "../../../../core/services/dataBean/EntityCatalog"
import useDataBean from "../../../../core/services/dataBean/useDataBean"
import { BasicPage } from "../../../theme/Component/BasicPage"
import { DataBeanProperties, ObjectValue } from '../../../../core/services/model/server-response.interface';
import { PrintJson } from "../../../theme/Component/PrintJson";
import { BasicTable } from "../../../theme/Component/BasicTable";
import { ListMenu } from "../../../theme/Component/ListMenu";
import { Modal } from "../../../theme/Component/Modal";
import { Singleton } from '../../../../core/services/Singleton';
import { ModalConfirm } from "../../../theme/Component/ModalConfirm";
import { toast } from "react-toastify";
import { _CodeUnscp } from "../../../../core/services/dataBean/EntityCatalogVdos";
import { FinanceService } from "../../../../core/services/FinanceService";

interface Props {
    IDLnFunctional: any;
}


export const CodigosUnscpFuntionalID = ({ IDLnFunctional }: Props) => {

    const CodeUnscp = useDataBean(_CodeUnscp);
    const single = Singleton.getInstance();
    const service = new FinanceService();
    const [listaUnscp, setlistaUnscp] = useState([]);
    const [lista, setLista] = useState<any>([]);
    const [Group, setGroup] = useState<any>([])
    const [modal, setModal] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalC, setModalC] = useState<any>({ name: "Eliminar", tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });

    useEffect(() => {
        getLista();
        getListaUnspsc();
    }, [IDLnFunctional])

    const getLista = () => {

        CodeUnscp.instance.getUnscpCodesRender(IDLnFunctional.IDLn).then(
            (resp: any) => {
                if (resp) {
                    setLista(resp)
                }
            }
        )

    }



    const getListaUnspsc = () => {

        single.spinner(true);
        service.getTreeForUnspscID().subscribe(
            (resp: any) => {

                // if (resp.DataBeanProperties.ObjectValue) {
                //     setlistaUnscp(resp.DataBeanProperties.ObjectValue);
                // }
                // single.spinner(false);
               //console.log(resp.DataBeanProperties.ObjectValue.Childs)
                setlistaUnscp(resp.DataBeanProperties.ObjectValue.Childs)
            }
        );
    }

    const create = () => {
        CodeUnscp.setBean({

        });
        setModal({ ...modal, "view": true });
    }
    const deleteItem = () => {
        CodeUnscp.deleteItemVoid(CodeUnscp.bean.IDEntailment).then(
            (resp: any) => {
                getLista();
                setModalC({ ...modalC, "name": `Agregar Nivel Jerárquico de Empleo`, "view": false });
            }
        )

    }
    // const editaItem = () => {
    //    //console.log(CodeUnscp.bean);
    //     CodeUnscp.update().then(
    //         (resp: any) => {
    //             getLista();
    //             setModal({ ...modal, "view": false });
    //         }
    //     ).catch(err => {
    //         toast.error(err);
    //     })

    // }


    const editaItem = () => {


        CodeUnscp.instance.addUnscpCodesByFunctionalID(IDLnFunctional.IDLn, single.getAccountID(), Group).then(
            (resp: any) => {
                if (resp) {
                   //console.log(resp)
                    getLista();
                    setModal({ ...modal, "view": false });
                }

            }
        ).catch(err =>console.error(err))

    }

    const head = [
        { title: 'ID', property: 'UnscpFunctionalID', visible: true },
        { title: 'Código', property: 'Code', visible: true },
        { title: 'Nombre', property: 'Name', visible: true },



    ]

    const iconButtonsItem = [
        { nombre: 'Agregar', visible: single.canCreate(), icono: 'ri-file-add-line', evento: create }
    ]
    const listaBotones = () => {
        const botones = [
            { titulo: 'Editar', icono: 'ri-edit-2-line', visible: single.canEdit(), evento: () => { setModal({ ...modal, "name": `Editar ${CodeUnscp.bean.Name}`, "view": true }) } },
            { titulo: 'Eliminar', icono: 'ri-delete-bin-line', visible: single.canDelete(), evento: () => { setModalC({ ...modalC, "view": true }) } },

        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }


    return (
        <BasicPage titulo="Codigos UNSPSC">
            <div className="row">
                <div className="col-md-12">
                    <BasicTable body={lista} head={head} rowSet={CodeUnscp.setBean} listButtons={listaBotones()} iconButtons={iconButtonsItem}></BasicTable>
                    <Modal modal={modal} updateModal={setModal} eventModal={editaItem}>
                        <div className="row">
                            <div className="col-md-12 table-limit mt-3">



                                <div className="table-responsive">
                                    <table className="table" >
                                        <thead >
                                            <tr className="trtable">
                                                <th className="thtable">Seleccionar</th>
                                                <th className="thtable">Unspsc Code</th>
                                                <th className="thtable">Nombre</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {Array.isArray(listaUnscp) && listaUnscp.map((li: any, index: number) => {

                                                return (
                                                    <tr className="trtable" key={index}>
                                                        <td className="tdtable">
                                                            <input
                                                                type="checkbox"
                                                                name="JsonList"

                                                                value={CodeUnscp.bean.IDLn}
                                                                onChange={(e) => {
                                                                    CodeUnscp.handleChange(e);

                                                                }}
                                                            />
                                                        </td>
                                                        <td className="tdtable">{li.EnvolvedObject.DataBeanProperties.UnspscViewCode}</td>
                                                        <td className="tdtable">{li.EnvolvedObject.DataBeanProperties.Name}</td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>

                            </div>

                        </div>
                    </Modal>
                    <ModalConfirm modal={modalC} updateModal={setModalC} eventModal={deleteItem}>
                        <strong>Eliminar item {CodeUnscp.bean.Name}</strong>
                    </ModalConfirm>
                </div>

            </div>
        </BasicPage>
    )
}