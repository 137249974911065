import { useEffect, useState } from "react";
import { _AccountantID } from "../../../../core/services/dataBean/EntityCatalog";
import useDataBean from "../../../../core/services/dataBean/useDataBean";
import { DataBeanProperties } from '../../../../core/services/model/server-response.interface';

export const VIEW_EDITOR = 1;
export const VIEW_SELECTOR = 2;

function useAccountantTree(view: number) {

    const accountantID = useDataBean(_AccountantID);

    const [lista, setLista] = useState<any>([]);
    const [listaTipo, setListaTipo] = useState<any>([]);
    const [miga, setMiga] = useState<any>([]);
    const [hijos, setHijos] = useState<any>([]);
    const [childrenCount, setChildrenCount] = useState<number>(0);
    const [currentIDLn, setCurrentIDLn] = useState<number>(0);



    const [idTipo, setIDTipo] = useState<number>(0);


    useEffect(() => {
        
        listaTipo.forEach((element: any) => {
            if (element.DataBeanProperties.IDAccountantIDType === idTipo) {
                localStorage.setItem("CodeSeparator", element.DataBeanProperties.CodeSeparator);
                localStorage.setItem("CodeFormat", element.DataBeanProperties.CodeFormat);
                cargarRoot();
            }
        });

    }, [idTipo])

   

    const addMiga = (item: any) => {

    }

    const cargarTipoCatalogo = () => {
        accountantID.instance.getAccountantIDTypeCatalog().then(
            (resp: any) => {
                setListaTipo(resp);
                if (resp.length > 0) {

                    let primero = resp[0].DataBeanProperties;
                    setIDTipo(primero.IDAccountantIDType);
                    
                }
            }
        );
    }
    const cargarRoot = () => {
        if(idTipo>0)
        accountantID.instance.getTreeForAccountantID(idTipo).then(
            (resp: any) => {
                setLista([resp.EnvolvedObject]);
                setHijos(resp.Childs);
                setChildrenCount(resp.ChildrenCount);
            }
        );
    }
    const cargarChilds = (item: any) => {
        accountantID.instance.loadAccountantIDTree(item.IDLn).then(
            (resp: any) => {
                setLista(resp.EnvolvedObject);
                setHijos(resp.Childs);
                setChildrenCount(resp.ChildrenCount);
            }
        );
    }
  
    const makeList = (cuenta: number) => {
        let codeFormat = localStorage.getItem("CodeFormat");
        let codeSeparator = localStorage.getItem("CodeSeparator");

        let arr = formatNumber(cuenta, codeFormat, codeSeparator);
        return arr;

    }
    const makeCodeFormat = (cuenta: number) => {
        let codeFormat = localStorage.getItem("CodeFormat");
        let codeSeparator = localStorage.getItem("CodeSeparator");

        let arr = '';
        if (codeFormat && codeSeparator) {
            arr = formatNumberString(cuenta, codeFormat, codeSeparator);

        }

        return arr;


    }
    const formatNumber = (input: number, format: string, separator: string): number[] => {
        const inputStr = input.toString();
        const formatParts = format.split(separator);
        let currentIndex = 0;
        const result: number[] = [];

        for (const part of formatParts) {
            const partLength = part.length;
            const segment = inputStr.slice(currentIndex, currentIndex + partLength);
            if (segment) {
                result.push(parseInt(segment, 10));
                currentIndex += partLength;
            } else {
                break;
            }
        }

        if (currentIndex < inputStr.length) {
            return undefined;
        }

        return result;
    }
    const formatNumberString = (input: number, format: string, separator: string): string => {
        const inputStr = input.toString();
        const formatParts = format.split(separator);
        let currentIndex = 0;
        const result: string[] = [];

        for (const part of formatParts) {
            const partLength = part.length;
            const segment = inputStr.slice(currentIndex, currentIndex + partLength);
            if (segment) {
                result.push(segment);
                currentIndex += partLength;
            } else {
                break;
            }
        }

        if (currentIndex < inputStr.length) {
            return undefined
        }

        return result.join(separator);
    }
    const createAccountantID = (idAccountantIDType: number, items: any, name: string):Promise<any> => {
        return accountantID.instance.createAccountantID(idAccountantIDType, items, name);
    }



    return {
        lista,
        listaTipo,
        hijos,
        childrenCount,
        idTipo,
        currentIDLn,
        accountantID,
        setLista,
        setCurrentIDLn,
        addMiga,
        cargarRoot,
        cargarChilds,
        setIDTipo,
        cargarTipoCatalogo,
        makeList,
        makeCodeFormat,
        createAccountantID,
        setHijos
    }
}
export default useAccountantTree;