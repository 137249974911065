import { useEffect, useState } from "react";
import { ButtonSideID } from "./ButtonSideID";
import { AdminService } from "../../../../core/services/AdminService";
import { useDashboardStore } from "../../../pages/hooks/useDashboardStore";
import { _single } from "../../../../core/services/dataBean/EntityCatalog";




interface Props {
    idChild: number;
    editBean: Function;
    edit: boolean;
}
export const SiteIDChilds = ({ idChild, editBean, edit }: Props) => {

    const service = new AdminService();
    const { setLoading } = useDashboardStore();
    const [lista, setLista] = useState([]);
    const [name, setName] = useState('');
    const [bean, setBean] = useState({});
    const [showchild, setShowchild] = useState(false);
    const [idchild, setIDchild] = useState(0);

    useEffect(() => {
        if (idchild != null && idchild != undefined)
            getListatree(idChild);
        else
            setLista([]);
    }, [])

    const getListatree = (idArbol: any) => {

        let tmp = _single.getCacheItem('treeSiteID_' + idArbol);
        if (tmp) {
            setLista(tmp);
        } else {

            setLoading(true);
            service.getSiteIDTreeChilds(idArbol).subscribe((resp: any) => {

                if (resp.DataBeanProperties.ObjectValue) {
                    if (resp.DataBeanProperties.ObjectValue.length > 0) {
                        let arbol: any = resp.DataBeanProperties.ObjectValue;
                        setLista(arbol);
                        _single.setCacheItem('treeSiteID_' + idArbol, arbol);
                    }
                }
                setLoading(false);
            });
        }

    };

    return (
        <>
            {/* <PrintJson  json={lista}/> */}
            <ul className="list-group">
                {
                    lista.length > 0 &&
                    lista.map((item: any) => {
                        return (
                            <li className="list-group-item itemh" style={{ border: '0' }} key={item.DataBeanProperties.IDLn}>

                                {edit &&
                                    <ButtonSideID
                                        id={item.DataBeanProperties.IDLn}
                                        bean={item.DataBeanProperties}
                                        updateEvent={() => { getListatree(item.DataBeanProperties.IDLn); }}
                                    />
                                }
                                <strong className={idchild === item.DataBeanProperties.IDLn ? 'cursor treeselect' : 'cursor'} onClick={() => {
                                    localStorage.setItem('SiteID', JSON.stringify(item.DataBeanProperties));
                                    setIDchild(item.DataBeanProperties.IDLn);


                                }}>
                                    <strong style={{ marginLeft: '15px' }}> <small>({item.DataBeanProperties.IDLn})</small> {item.DataBeanProperties.Code} </strong>
                                    <small>{item.DataBeanProperties.Name}</small>

                                </strong>

                                {(idchild === item.DataBeanProperties.IDLn) &&
                                    <SiteIDChilds edit={edit} idChild={item.DataBeanProperties.IDLn} editBean={(item: any) => { setBean(item) }} />
                                }
                            </li>
                        )
                    })
                }
            </ul>
        </>
    )
}