import { useEffect, useState } from "react";
import { ContractService } from "../../../../../core/services/ContractService";
import { Singleton } from "../../../../../core/services/Singleton";
import { PrintJson } from "../../../../theme/Component/PrintJson";
import { DataBeanProperties, ObjectValue } from '../../../../../core/services/model/server-response.interface';
import { BasicTable } from "../../../../theme/Component/BasicTable";
import { ListMenu } from "../../../../theme/Component/ListMenu";
import { Modal } from "../../../../theme/Component/Modal";
import { TaxSchemeValueList } from "./TaxSchemeValueList";
import { EDITOR_ARTICLE } from "../../../../editor/Editors";
import { toast } from "react-toastify";
import { SCHEMA_IMPUESTO, SCHEMA_RETENCION } from "../ContracInterface";

interface Props {
    idUBLInvoice: number;
    issueDate: Date;
    contract: any;
}
export const UBLInvoiceItems = ({ idUBLInvoice, issueDate, contract }: Props) => {

    const single = Singleton.getInstance();
    const contracService = new ContractService();
    const [lista, setLista] = useState<any>([]);
    const [listaConsolidado, setListaConsolidado] = useState<any>([]);
    const [ublItem, setUblItem] = useState<any>({});
    const [pendientes, setPendientes] = useState<number>(0);
    const [schema, setSchema] = useState<number>(0);
    const [modal, setModal] = useState<any>({ name: 'Ver Impuestos', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });

    useEffect(() => {
        getUBLItemInvoiceCatalog();
        // getUBLItemArticleConsolidaded();
        
    }, [idUBLInvoice])

    useEffect(() => {
        
        getUBLItemArticleConsolidaded();
    }, [lista])
    useEffect(() => {
        
        getUBLItemInvoicePendingForAssign();
    }, [listaConsolidado])


    const getUBLItemInvoiceCatalog = () => {
        single.spinner(true);
        contracService.getUBLItemInvoiceCatalog(idUBLInvoice, issueDate).subscribe(
            (resp: any) => {

                if (resp.DataBeanProperties.ObjectValue) {

                    let tmp = resp.DataBeanProperties.ObjectValue;
                    tmp.forEach((element: any) => {


                        element.DataBeanProperties.IDAdquisitionContract = contract.IDAdquisitionContract;
                        element.DataBeanProperties.AdquisitionContractDate = contract.AdquisitionContractDate;

                    });
                    setLista(tmp);
                }

                single.spinner(false);
            }
        )
    }
    const getUBLItemInvoicePendingForAssign = () => {
        single.spinner(true);
        contracService.getUBLItemInvoicePendingForAssign(contract.IDUBLInvoice, contract.IssueDate).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue) {
                    setPendientes(resp.DataBeanProperties.ObjectValue);
                }
                else if (resp.DataBeanProperties.ErrorMessage) {
                    setPendientes(0);
                    toast.error(resp.DataBeanProperties.ErrorMessage);
                }
                single.spinner(false);
            }
        );
    }
    const getUBLItemArticleConsolidaded = () => {
        single.spinner(true);
        contracService.getUBLItemArticleConsolidaded(contract.IDAdquisitionContract, contract.AdquisitionContractDate).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue) {
                    setListaConsolidado(resp.DataBeanProperties.ObjectValue);
                }
                else if (resp.DataBeanProperties.ErrorMessage) {
                    toast.error(resp.DataBeanProperties.ErrorMessage);
                }
                single.spinner(false);
            }
        );
    }



    const head = [
        { title: 'ID', property: 'IDUBLItemInvoice', visible: true },
        { title: 'Tipo Artículo', property: 'FixedAssetType', visible: true },
        { title: 'Nombre del Bien o Servicio', property: 'Description', visible: true },
        { title: 'Valor Unitario', property: 'UnitaryValue', visible: true },
        { title: 'Cantidad', property: 'Quantity', visible: true },
        { title: 'Subtotal', property: 'PriceValue', visible: true },
        { title: 'Subtotal Impuestos', property: 'TaxAmount', visible: true },
        { title: 'Valor Total', property: 'TotalItemAmount', visible: true },
        // {title:'Producto Destino',property:'ProductDescription',visible:true},
        { title: 'Artículo', property: 'IDAdquisitionArticle', visible: true, cellEditor: contract ? EDITOR_ARTICLE : undefined },

    ]
    const headConsolidado = [

        { title: 'Artículo', property: 'IDAdquisitionArticle', visible: true },
        { title: 'Descripción', property: 'Description', visible: true },
        { title: 'Rubro Presupuestal', property: 'BudgetID', visible: true },
        { title: 'Valor Facturado', property: 'Value', visible: true },
        { title: 'Valor del Artículo', property: 'ArticleValue', visible: true },
        { title: 'Valor Pendiente por Facturar', property: 'LeftValue', visible: true },
        { title: 'Error en facturación', property: 'Error', visible: true },


    ]
    const listaBotones = () => {
        const botones = [
            { titulo: 'Ver Impuestos de Valor Agregado', icono: 'ri-file-list-line', visible: true, evento: () => { setSchema(SCHEMA_IMPUESTO); setModal({ ...modal, "view": true }) } },
            { titulo: 'Ver Retenciones', icono: 'ri-file-list-line', visible: true, evento: () => {setSchema(SCHEMA_RETENCION); setModal({ ...modal, "view": true }) } },
        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }
    const headerTable = () => {
        return (
            
            <button type="button" className="btn btn-outline-secondary shadow-none">
                Pendientes <span className={pendientes != 0 ? 'badge bg-danger ms-2' : 'badge bg-success ms-2'}>{pendientes}</span>
            </button>
        )
    }


    return (
        <div className="row">
            <div className="col-md-12">
                <BasicTable headButtons={headerTable()} listButtons={listaBotones()} body={lista} head={head} rowSet={setUblItem} callBack={getUBLItemInvoiceCatalog}></BasicTable>
            </div>           
            <div className="col-md-12">
                <Modal modal={modal} updateModal={setModal}>
                    <TaxSchemeValueList idTaxScheme={null} idUBLItemInvoice={ublItem.IDUBLInvoice} issueDate={ublItem.IssueDate}  TypeSheme={schema}/>
                </Modal>
            </div>
        </div>
    )
}