import { useEffect, useState } from "react";
import { FileInput } from "../../../../theme/Component/FileInput";
import { PrintJson } from "../../../../theme/Component/PrintJson";
import { Singleton } from "../../../../../core/services/Singleton";
import { ContractService } from "../../../../../core/services/ContractService";
import { DataBeanProperties, ObjectValue } from '../../../../../core/services/model/server-response.interface';
import { BasicButton } from "../../../../theme/Component/BasicButton";
import { toast } from "react-toastify";
import { BasicTable, MASK_DATE, MASK_MONEY, MASK_NUMBER } from "../../../../theme/Component/BasicTable";
import { ListMenu } from "../../../../theme/Component/ListMenu";
import { Modal } from "../../../../theme/Component/Modal";
import { UBLInvoiceItems } from "./UBLInvoiceItems";
import { SelectAccountantConcept } from "./SelectAccountantConcept";
import { AccountEditor } from "../../../../editor/AccountEditor";
import { CreateUBLInvoiceItems } from "./CreateUBLInvoice/CreateUBLInvoiveItem";
import { HistoryUBLInvoice } from "./HistoryUBLInvoice";
import { FileService } from "../../../../../core/services/FileService";
import useDataBean from "../../../../../core/services/dataBean/useDataBean";
import { _UBLInvoice } from "../../../../../core/services/dataBean/EntityCatalog";
import { SelectAccountantConceptForCashDesk } from "./CreateUBLInvoice/SelectAccountantConceptForCashDesk";

interface Props {
    _onChange2?: Function;
    _multipleUBLs?: boolean;
    entry?: "Accounting" | "CashReimbursement";

}

export const ImportUBLInvoice = ({ _onChange2, _multipleUBLs, entry }: Props) => {


    const single = Singleton.getInstance();
    const contracService = new ContractService();
    const _ublInvoice = useDataBean(_UBLInvoice);
    const fileService = new FileService();

    const [idconcept, setIDConcep] = useState<number>(0);
    const [file, setFile] = useState<any>({});
    const [UBL, setUBL] = useState<any>({});
    const [observation, setObservation] = useState<any>('');
    const [observationAdd, setObservationAdd] = useState<any>('');
    const [fileType, setFileType] = useState<string>(null);
    const [donwloadURL, setDonwloadURL] = useState<string>(null);
    const [urlFile, setUrlFile] = useState<string | null>(null);

    const [lista, setLista] = useState<any>([]);
    const [modal, setModal] = useState<any>({ name: 'Items de la factura', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalB, setModalB] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: false, format: 'modal-lg' });
    const [modalC, setModalC] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalRegis, setModalRegis] = useState<any>({ name: 'Registrar factura a contrato', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });

    useEffect(() => {
        setFileType(UBL.MediaContext?.type || UBL.Media?.split('.').pop())
        setDonwloadURL(fileService.getUrlFile(UBL.MediaContext, UBL.Media))
    }, [UBL])

    const importUBLInvoice = () => {
        single.spinner(true);
        contracService.importUBLInvoice(idconcept, file.Media, file.MediaContext, null, single.getCurrenDate(), null, single.getAccountID(), observationAdd).subscribe(
            (resp: any) => {

               //console.log(resp);

                if (resp.DataBeanProperties.ObjectValue) {
                    let temp = [];
                    temp.push(resp.DataBeanProperties.ObjectValue);

                    setLista(temp);
                } else {
                    toast.error("Factura no pudo ser importada");
                }
                setFile({});

                single.spinner(false);
            }
        )
    }

    const importUBLInvoiceContainer = () => {
        single.spinner(true);
        contracService.importUBLInvoiceContainer(idconcept, file.Media, file.MediaContext, null, single.getCurrenDate(), null, single.getAccountID(), observationAdd).subscribe(
            (resp: any) => {
               //console.log(resp);

                // if (resp.DataBeanProperties.ObjectValue) {
                //     let temp = [];
                //     temp.push(resp.DataBeanProperties.ObjectValue);

                //     setLista(temp);
                // } else {
                //     toast.error("Factura no pudo ser importada");
                // }
                setFile({});

                single.spinner(false);
            }
        )
    }

    useEffect(() => {
        if (donwloadURL) {
            const fetchPdf = async () => {
                try {
                    const data = await fileService.getPdfAsBase642(donwloadURL);
                    setUrlFile(data);
                } catch (error) {
                    console.error('Error loading PDF:', error);
                }
            };

            fetchPdf();
        }
    }, [donwloadURL]);

    const head = [
        { title: 'ID', property: 'IDUblInvoice', visible: true, mask: MASK_NUMBER },
        { title: 'Procesar', property: 'isValid', visible: true },
        { title: 'Nit Proveedor', property: 'AccountSupplierNit', visible: true },
        { title: 'Proveedor', property: 'AccountSupplierName', visible: true },
        { title: 'No Factura', property: 'InvoiceID', visible: true },
        { title: 'Líneas Factura', property: 'LineCountNumeric', visible: true },
        { title: 'Valor Total', property: 'TotalInvoiceAmount', visible: true, mask: MASK_MONEY },
        { title: 'Impuesto', property: 'TaxAmount', visible: true },
        { title: 'Descuentos', property: 'DiscountAmount', visible: true },
        { title: 'Fecha Factura', property: 'IssueDate', visible: true, mask: MASK_DATE },
        { title: 'Entrada Almacén', property: 'EAFMnemonic', visible: true, mask: MASK_DATE },
        { title: 'Consecutivo Entrada', property: 'EAFConsecutive', visible: true },
        { title: 'Fecha Entrada', property: 'EAFDate', visible: true, mask: MASK_DATE },
        { title: 'Fecha Procesamiento', property: 'Since', visible: true, mask: MASK_DATE },
        { title: 'Estado Factura', property: 'DocumentStateName', visible: true },
        { title: 'Tipo Factura', property: 'SourceTypeName', visible: true },
        { title: 'Estado EAF', property: 'EAFDocumentStateName', visible: true },
        { title: 'Estado Salidas', property: 'OutputCostStateName', visible: true },
        { title: 'Documento Soporte', property: 'IsDocumentSupport', visible: true },
        { title: 'Concepto de Ingreso', property: 'AccountantConceptName', visible: true },
        { title: 'Cuenta Contable', property: 'AccountantIDName', visible: true },
        { title: 'Cuenta Puente Ingreso Bienes', property: 'CreditAccountantIDName', visible: true },
        { title: 'Pertenece a Proceso', property: 'ProcessTypeName', visible: true },

    ]

    const listaBotones = () => {
        const botones = [
            { titulo: 'Abrir Factura', icono: 'ri-file-list-line', visible: true, evento: () => { setModal({ ...modal, "name": "Abrir archivo", "view": true, "format": `${fileType !== "pdf" && "jpg" && "png" ? "modal-sm" : "modal-xl"}` }) } },
            { titulo: 'Ver eventos en la Factura', icono: 'ri-history-line', visible: true, evento: () => { setModalC({ ...modalC, "view": true, "name": "Ver eventos en la Factura" }) } },
            { titulo: 'ver Items de la Factura', icono: 'ri-list-ordered', visible: true, evento: () => { setModalB({ ...modalB, "view": true, "name": `Items de la Factura ${UBL.IDUblInvoice}` }) } },
        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }

    const setRowBean = (bean: any) => {
        if (_onChange2) {
            _onChange2(bean)
        }
        setUBL(bean)
    }

    return (
        <>
            <div className="row">
                <div className="col-md-6">
                    {entry == "CashReimbursement" ?
                        <SelectAccountantConceptForCashDesk idSelector={idconcept} onChange={setIDConcep} />
                        :
                        <SelectAccountantConcept idSelector={idconcept} onChange={setIDConcep} />
                    }
                </div>

                <div className="col-md-6">
                    <FileInput directory="files" onCreate={(e) => { setFile(e) }} />
                </div>

                <div className="col-md-12 mt-3">
                    {file.Media &&
                        <div className="row">
                            <div className="col-md-12">
                                <label>Observaciones</label>
                                <textarea name="obs" className="form-control" value={observationAdd} onChange={(e) => { setObservationAdd(e.target.value) }} ></textarea>
                            </div>
                        </div>
                    }

                    {_multipleUBLs ?
                        <div className="col-md-6">
                            <BasicButton icon="ri-contacts-book-upload-line" eventClick={importUBLInvoiceContainer}>Importar Facturas</BasicButton>
                        </div>
                        :
                        <div className="col-md-6">
                            <BasicButton icon="ri-contacts-book-upload-line" eventClick={importUBLInvoice}>Importar Factura</BasicButton>
                        </div>
                    }

                </div>

                <div className="col-md-12">
                    <BasicTable listButtons={listaBotones()} body={lista} head={head} rowSet={(e) => { setRowBean(e) }} />
                </div>
            </div>

            {modalB.view &&
                <Modal modal={modalB} updateModal={setModalB} >
                    <CreateUBLInvoiceItems idUBLInvoice={UBL.IDUBLInvoice} issueDate={UBL.IssueDate} />
                </Modal>
            }

            {modalRegis.view &&
                <Modal modal={modalRegis} updateModal={setModalRegis}>
                    <div className="row">
                        <div className="col-md-12">
                            {/* <PrintJson json={contract}/> */}
                            <label>Observaciones</label>
                            <textarea className="form-control" value={observation} onChange={(e) => { setObservation(e.target.value) }}></textarea>
                        </div>
                    </div>
                </Modal>
            }

            {modalC.view &&
                <Modal modal={modalC} updateModal={setModalC} >
                    <HistoryUBLInvoice _idUBLInvoice={UBL.IDUBLInvoice} _isseDate={UBL.IssueDate} />
                </Modal>
            }

            {modal.view &&
                <Modal modal={modal} updateModal={setModal} eventModal={() => { }} >
                    <div className="row">
                        <div className="col-md-12">
                            <div className="iframe-container">
                                {fileType ?
                                    <>
                                        {fileType === 'pdf' && (
                                            urlFile ? (
                                                <iframe name="pdfviwer" src={urlFile} onError={(e) => console.error('Error loading PDF:', e)}></iframe>
                                            ) : (
                                                <p>Cargando PDF...</p>
                                            )
                                        )}
                                        {fileType === "jpg" || fileType === "png" &&
                                            (
                                                <img src={urlFile} alt="Image Preview" width="100%" />
                                            )
                                        }
                                        {fileType !== "pdf" && "jpg" && "png" &&
                                            <>
                                                <a href={donwloadURL} target='_blank'>Clic aquí para descargar el archivo {UBL.Media}</a>
                                            </>
                                        }
                                    </>
                                    :
                                    <>
                                        No se pudo cargar el Documento
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </Modal>
            }
        </>
    )

}