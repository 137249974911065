import { useState, useEffect } from 'react';
import { Singleton } from '../../../core/services/Singleton';
import { useDashboardStore } from '../../pages/hooks/useDashboardStore';
import { BpmServiceall } from '../../../core/services/BpmServiceall';
import { ListMenu } from '../../theme/Component/ListMenu';
import { BasicTable } from '../../theme/Component/BasicTable';
import { ModalConfirm } from '../../theme/Component/ModalConfirm';
import { Modal } from '../../theme/Component/Modal';
import { SelectorPlanSGSST } from '../../bpm/config/Componentes/SelectorPlanSGSST';
import { SelectTypeTest } from '../../bpm/config/Componentes/SelectTypeTest';
import { TopicResource } from './TopicResource';


interface Props {
    IDCourse: number;
}


export const CourseTopic = ({ IDCourse }: Props) => {

    const single = Singleton.getInstance()
    const { setLoading } = useDashboardStore();
    const service = new BpmServiceall();
    const [lista, setLista] = useState([]);
    const [bean, setBean] = useState<any>({});
    const [test, setTest] = useState<number>(0);
    const [modaljson, setModaljson] = useState<any>({ name: 'Caracterizaciones', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalresource, setModalresource] = useState<any>({ name: 'TopicResource', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modal, setModal] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalConfirm, setModalConfirm] = useState<any>({ name: 'Confirmar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });
    const [user, setUser] = useState<any>({});

    const idBean = 'IDCourseTopic';

    const head = [
        { title: 'ID', property: idBean },
        { title: 'Nombre', property: 'Name' },
        { title: 'Descripción', property: 'Description' },

    ]


    const total = () => {
       //console.log(bean);
        updateItem();
    }

    useEffect(() => {
        setUser(single.getAccountID());
        getLista();
    }, [IDCourse, test])


    const setRowBean = (bean: any) => {
       //console.log("Row : ", bean);
        setBean(bean);

    }

    const getLista = () => {
        let x = {
            IDCourse: IDCourse,
            // IDTest: test
        }
        setLoading(true);
        service.getCourseTopicCatalogPorPropiedades(x, null).subscribe(
            (resp: any) => {

                if (resp.DataBeanProperties.ObjectValue) {
                    setLista(resp.DataBeanProperties.ObjectValue);
                }

                setLoading(false);
            }
        );
    }

    const agregarItem = () => {

        let b = {
            Name: '',
            Description: '',
            State: 1,
            IDCourse: IDCourse,
            HaveTest: '',
            IDTest: test,
        }
        setBean(b);
        setModal({ ...modal, ['view']: true });

    }

    const veracciones = () => {
        setModalresource({ ...modalresource, ['view']: true });
    }
    const eliminarItem = () => {
        bean.State = 2;
       //console.log(bean);
        updateItem();
        setModalConfirm({ ...modalConfirm, ['view']: false });
    }
    const updateItem = () => {

        setModal({ ...modal, ['view']: false });

        setLoading(true);
        service.updateCourseTopic(bean).subscribe(
            (resp: any) => {
                setLoading(false);
                if (resp.DataBeanProperties.ObjectValue) {
                    getLista();
                }
                single.update(bean);
            });

    }

    const listaBotones = () => {
        const botones = [

            { titulo: 'Editar', icono: 'ri-file-list-line', visible: single.canEdit(), evento: () => { setModal({ ...modal, "view": true }) } },
            { titulo: 'Eliminar', icono: 'ri-file-user-line', visible: true, evento: () => { setModalConfirm({ ...modalConfirm, "view": true }) } },

            { titulo: 'Recursos', icono: 'ri-file-user-line', visible: true, evento: () => { setModalresource({ ...modalresource, "view": true }) } },
        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }

    const iconButtonsItem = [
        { nombre: 'Agregar', visible: single.canCreate(), icono: 'ri-file-add-line', evento: agregarItem }
    ]




    return (
        <>
            <div className="row">

                <div className="col-md-12 p-2">
                    <BasicTable listButtons={listaBotones()} iconButtons={iconButtonsItem} head={head} body={lista} rowSet={setRowBean}></BasicTable>
                </div>
            </div>

            <ModalConfirm modal={modalConfirm} updateModal={setModalConfirm} eventModal={eliminarItem}>
                {bean.Name}
            </ModalConfirm>

            <Modal modal={modal} updateModal={setModal} eventModal={total}>
                <div className="row">
                    <div className="col-md-12">
                        <SelectTypeTest idSelector={bean.test} onChange={(e: any) => { setTest(e) }} />


                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <label className="form-label">Nombre</label>
                        <input type="text" className="form-control" value={bean.Name} onChange={(e) => { setBean({ ...bean, ['Name']: e.target.value }) }} />
                    </div>

                    <div className="col-md-12">
                        <label className="form-label">Descripción</label>
                        <textarea className="form-control" value={bean.Description} onChange={(e) => { setBean({ ...bean, ['Description']: e.target.value }) }}></textarea>
                    </div>
                    <div className="col-md-6">

                    </div>


                </div>
            </Modal>


            <Modal modal={modalresource} updateModal={setModalresource} eventModal={veracciones}>
                {/* <Tablaaccionesformularios IDform={bean.IDForm} /> */}
                <>
                    <TopicResource IDCourseTopic={bean[idBean]} />

                </>
            </Modal>
        </>
    )
}