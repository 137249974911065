import { useEffect, useState } from "react";
import { _AbilityType } from "../../../../core/services/dataBean/EntityCatalog"
import useDataBean from "../../../../core/services/dataBean/useDataBean"
import { BasicPage } from "../../../theme/Component/BasicPage";
import { BasicTable } from "../../../theme/Component/BasicTable";
import { ListMenu } from "../../../theme/Component/ListMenu";
import { Modal } from "../../../theme/Component/Modal";
import { Singleton } from '../../../../core/services/Singleton';
import { ModalConfirm } from "../../../theme/Component/ModalConfirm";
import { toast } from "react-toastify";


export const AbilityType = () => {

    const abilityType = useDataBean(_AbilityType);
    const single = Singleton.getInstance();

    const [lista, setLista] = useState<any>([]);
    const [modal, setModal] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-md' });
    const [modalC, setModalC] = useState<any>({ name: 'Eliminar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });

    useEffect(() => {
        getLista();
    }, [])

    const getLista = () => {
        abilityType.instance.getAbilityTypeCatalog(null).then(
            (resp: any) => {
                if (resp) {
                    setLista(resp)
                }
            }
        )
    }

    const create = () => {
        abilityType.setBean({});
        setModal({ ...modal, "name": `Agregar Tipo de Habilidad`, "view": true });
    }

    const deleteItem = () => {
        abilityType.instance.deleteAbilityType(abilityType.bean).then(
            (resp: any) => {
                getLista();
                setModalC({ ...modalC, "view": false });
            }
        )
    }

    const editaItem = () => {
       //console.log(abilityType.bean);
        abilityType.update().then(
            (resp: any) => {
                getLista();
                setModal({ ...modal, "view": false });
            }
        ).catch(err => {
            toast.error(err);
        })
    }

    const head = [
        { title: 'ID', property: 'IDAbilityType', visible: true },
        { title: 'Nombre', property: 'Name', visible: true },
        { title: 'Descripción', property: 'Description', visible: true },
    ]

    const iconButtonsItem = [
        { nombre: 'Agregar', visible: single.canCreate(), icono: 'ri-file-add-line', evento: create }
    ]

    const listaBotones = () => {
        const botones = [
            { titulo: 'Editar', icono: 'ri-edit-2-line', visible: single.canEdit(), evento: () => { setModal({ ...modal, "name": `Editar ${abilityType.bean.Name}`, "view": true }) } },
            { titulo: 'Eliminar', icono: 'ri-delete-bin-line', visible: single.canDelete(), evento: () => { setModalC({ ...modalC, "view": true }) } },
        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }

    return (
        <BasicPage titulo="Tipos de Habilidades">
            <div className="row">
                <div className="col-md-12">
                    <BasicTable body={lista} head={head} rowSet={abilityType.setBean} listButtons={listaBotones()} iconButtons={iconButtonsItem}></BasicTable>
                    <Modal modal={modal} updateModal={setModal} eventModal={editaItem}>
                        <div className="row">
                            <div className="col-md-12">
                                <label>Nombre</label>
                                <input type="text" name="Name" className="form-control" value={abilityType.bean.Name} onChange={abilityType.handleChange} />
                            </div>
                            <div className="col-md-12">
                                <label>Descripción: </label>
                                <textarea name="Description" className="form-control" value={abilityType.bean.Description} onChange={abilityType.handleChange} />
                            </div>
                        </div>
                    </Modal>
                    <ModalConfirm modal={modalC} updateModal={setModalC} eventModal={deleteItem}>
                        <strong>Eliminar item {abilityType.bean.Name}</strong>
                    </ModalConfirm>
                </div>

            </div>
        </BasicPage>
    )
}