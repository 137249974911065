import { DataBean } from "../DataBean"

export class BudgetCDPReductionFactory extends DataBean {

    public async createBudgetRowForCDPReduction(budgetIDStr: string, idBudgetResource: number, idDocument: number,date:any,description:string,value:number): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "budget.Budget_createBudgetRowForCDPReduction_String_Number_Number_java.util.Date_String_Number", [budgetIDStr,idBudgetResource,idDocument,date, description,value]);
    }
    
    public async createCDPReduction(accountantDate: any, externalConsecutive: number, items: any,description:string,idEmployee:number,createPdf:boolean): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "com.quickdataerp.bean.accountant.AccountantDocument_createCDPReduction_java.util.Date_Number_java.util.List_String_Number_Boolean", [accountantDate,externalConsecutive,items,description,idEmployee,createPdf]);
    }

}