import { useState, useEffect } from 'react';
import { ContractService } from '../../../core/services/ContractService';
import { Singleton } from '../../../core/services/Singleton';
interface Props {
    idSelector: number;
    onChange: any;
    _year?: number;
}
export const SelectAdquisitionPlan = ({ idSelector, onChange, _year }: Props) => {

    const single = Singleton.getInstance();
    const service = new ContractService();
    const [lista, setLista] = useState([]);
    const [id, setID] = useState(undefined);
    const [year, setYear] = useState(single.getCurrenYear());


    useEffect(() => {
        setID(idSelector);
    }, [])

    useEffect(() => {
        getLista();
    }, [_year])

    const setValue = (e: any) => {
        setID(parseInt(e));
        onChange(e);
    }

    const getLista = () => {
       //console.log("Plan de adquisicion : ", _year ?? year);
        service.getAdquisitionPlanCatalog(_year ?? year, _year ?? year, 2, null, null).subscribe(
            (resp: any) => {
                // setLista([]);
                if (resp.DataBeanProperties.ObjectValue.DataBeanProperties.List) {
                    setLista(resp.DataBeanProperties.ObjectValue.DataBeanProperties.List);
                }
            }
        );
    }

    return (
        <>
            <label>Plan de Adquisición</label>
            <select className="form-control" value={id} onChange={(e) => { setValue(e.target.value) }}>
                <option value={undefined}>...</option>
                {lista &&
                    lista.map((item: any) => {
                        return (
                            <option key={item.DataBeanProperties.IDAdquisitionPlan} value={item.DataBeanProperties.IDAdquisitionPlan} >{item.DataBeanProperties.Description}</option>
                        )
                    })
                }
            </select>
        </>
    )
}