import { DataBean } from "../DataBean";


export class AppointmentGroupManual extends DataBean {


    public async getAppointmentGroupManualCatalog(): Promise<any> {
        return this.getCustomService("PeopleArt", "java.util.List_getAppointmentGroupManualCatalog_Number", [null]);
    }

    public async deleteAppointmentGroupManual(bean): Promise<any> {
        return this.getCustomService("PeopleArt", "void_deleteAppointmentGroupManual_com.peopleart.bean.appointment.AppointmentGroupManual", [
            {
                "DataBeanProperties": bean,
                "DataBeanName": "com.peopleart.bean.appointment.AppointmentGroupManual"
            }
        ]);
    }
}