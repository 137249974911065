import { useState, useEffect } from 'react';
import { ContractService } from '../../../core/services/ContractService';
import { Singleton } from '../../../core/services/Singleton';
interface Props {
    label?: string;
    idSelector: number;
    onChange: any;
    idAdquisitionItem?: number;
}

export const SelectBudgetIDList = ({ idSelector, onChange, label, idAdquisitionItem }: Props) => {

    const service = new ContractService();
    const [lista, setLista] = useState([]);
    const [id, setID] = useState(0);

    useEffect(() => {
        if (idSelector) {
            setID(idSelector)
        }
    }, [idSelector])

    useEffect(() => {
        getLista();
    }, [])

    const setValue = (e: any) => {
        setID(parseInt(e));
        let tmp = lista.find(item => item.DataBeanProperties.IDLn == e);

        if (tmp) {
            onChange(tmp.DataBeanProperties);
        } else {
            onChange({
                IDLn: null,
                IDAdquisitionItemBudgetID: null,
            })
        }
    }

    const getLista = () => {
        service.getBudgetIDList(idAdquisitionItem ? idAdquisitionItem : null).subscribe((resp: any) => {
            if (resp.DataBeanProperties.ObjectValue) {
                //console.log(resp.DataBeanProperties.ObjectValue);
                setLista(resp.DataBeanProperties.ObjectValue);
                if (resp.DataBeanProperties.ObjectValue.length > 0) {
                    let temp: any = resp.DataBeanProperties.ObjectValue;
                    temp = temp.filter(item => item.DataBeanProperties.IDAdquisitionItemBudgetID == idSelector)
                    if (temp.length > 0) {
                        setID(temp[0].DataBeanProperties.IDLn);
                    }
                }
            }
        });
    };

    return (

        <>
            <label>{label ?? "Rubro"}</label>
            <select className="form-select" value={id} onChange={(e) => { setValue(e.target.value) }}>
                <option value={null}>...</option>
                {lista &&
                    lista.map((item: any, index: number) => {
                        return (
                            <option key={index} value={item.DataBeanProperties.IDLn} >{item.DataBeanProperties.BudgetViewCode} - {item.DataBeanProperties.Name}</option>
                        )
                    })
                }
            </select>
        </>


    )
}