import { useEffect, useState } from "react";
import { _CompetitionType, _Entailment, _MainTheme, _PaymentType, _StrategicObjetive, _ThirdBankingAccount } from "../../../../core/services/dataBean/EntityCatalog";
import { Singleton } from "../../../../core/services/Singleton";
import { toast } from "react-toastify";
import { ListMenu } from "../../../theme/Component/ListMenu";
import { BasicPage } from "../../../theme/Component/BasicPage";
import { BasicTable } from "../../../theme/Component/BasicTable";
import { Modal } from "../../../theme/Component/Modal";
import { ModalConfirm } from "../../../theme/Component/ModalConfirm";
import useDataBean from "../../../../core/services/dataBean/useDataBean";

import { AccountEditor } from "../../../editor/AccountEditor";
import { BasicSwitch } from "../../../theme/Component/BasicSwitch";

export const PaymentType = () => {

    const PaymentType = useDataBean(_PaymentType);
    const single = Singleton.getInstance();
    const [lista, setLista] = useState<any>([]);
    const [modal, setModal] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalC, setModalC] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });

    useEffect(() => {
        getLista();
    }, [])

    const getLista = () => {

        PaymentType.instance.getPaymentTypeCatalog().then(
            (resp: any) => {
                if (resp) {
                    setLista(resp)
                   //console.log(resp)
                }
            }
        )

    }

    const create = () => {
        PaymentType.setBean({

        });
        setModal({ ...modal, "view": true });
    }
    const deleteItem = () => {
        PaymentType.instance.deletePaymentType(PaymentType.bean).then(
            (resp: any) => {
                getLista();
                setModalC({ ...modalC, "view": false });
            }
        )

    }
    const editaItem = () => {
       //console.log(PaymentType.bean);
        PaymentType.instance.updatePaymentType(PaymentType.bean).then(
            (resp: any) => {
                getLista();
                setModal({ ...modal, "view": false });
            }
        ).catch(err => {
            toast.error(err);
        })

    }

    const head = [
        { title: 'ID', property: 'IDPaymentType', visible: true },
        { title: 'Código', property: 'Code', visible: true },
        { title: 'Nombre', property: 'Name', visible: true },
        { title: 'Crear Archivo de Bancos', property: 'CreateBankingFile', visible: true },
        { title: 'Código Tipo de Archivo Banco', property: 'BankingFileTypeCode', visible: true },



    ]

    const iconButtonsItem = [
        { nombre: 'Agregar', visible: single.canCreate(), icono: 'ri-file-add-line', evento: create }
    ]
    const listaBotones = () => {
        const botones = [

            { titulo: 'Editar', icono: 'ri-edit-2-line', visible: single.canEdit(), evento: () => { setModal({ ...modal, "view": true }) } },
            { titulo: 'Eliminar', icono: 'ri-delete-bin-line', visible: single.canDelete(), evento: () => { setModalC({ ...modalC, "view": true }) } },

        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }


    return (
        <BasicPage titulo="Tipo de Pagos a Terceros">
            <div className="row">


            </div>
            <div className="row">
                <div className="col-md-12">

                    <BasicTable body={lista} head={head} rowSet={PaymentType.setBean} listButtons={listaBotones()} iconButtons={iconButtonsItem}></BasicTable>
                    <Modal modal={modal} updateModal={setModal} eventModal={editaItem}>
                        <div className="row">

                            <div className="col-md-12">

                                <label>Código</label>
                                <input type="text" name="Code" className="form-control" value={PaymentType.bean.Code} onChange={PaymentType.handleChange} />
                            </div>
                            <div className="col-md-12">

                                <label>Nombre</label>
                                <input type="text" name="Name" className="form-control" value={PaymentType.bean.Name} onChange={PaymentType.handleChange} />
                            </div>
                            <div className="col-md-12">

                                <label>Crear Archivo de Bancos</label>
                                <BasicSwitch label="" estado={PaymentType.bean.CreateBankingFile} eventChange={(e) => { PaymentType.setBean({ ...PaymentType.bean, "CreateBankingFile": e.CreateBankingFile }) }}></BasicSwitch>
                            </div>
                            <div className="col-md-12">

                                <label>Código Tipo de Archivo Banco</label>
                                <input type="text" name="BankingFileTypeCode" className="form-control" value={PaymentType.bean.BankingFileTypeCode} onChange={PaymentType.handleChange} />
                            </div>


                        </div>
                    </Modal>
                    <ModalConfirm modal={modalC} updateModal={setModalC} eventModal={deleteItem}>
                        <strong>Eliminar item</strong>
                    </ModalConfirm>
                </div>

            </div>
        </BasicPage>
    )
}