import { DataBean } from "../DataBean";

export class SupplierPaymentOrder extends DataBean {

    public static DRAFT: number = 5;
    public static SUPPLIER_SIDE: number = 6;
    public static PENDING_FOR_SUPERVISOR_APPROVAL: number = 7;
    public static APPROVED: number = 8;
    public static PAIDED: number = 9;
    public static REJECTED: number = 10;
    public static INVOICE_DRAFT: number = 12;
    public static PENDING_FOR_HIRING_APPROVAL: number = 13;

    public async deleteSupplierPaymentOrder(idSupplierPaymentOrder: any, SupplierPaymentOrderDate: any): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "void_deleteSupplierPaymentOrder_Number_java.util.Date", [idSupplierPaymentOrder, SupplierPaymentOrderDate]);
    }

    public async getSupplierPaymentOrderCatalogForAdquisitionContract(idAdquisitionContract: any, contractDate: any): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_getSupplierPaymentOrderCatalogForAdquisitionContract_Number_java.util.Date", [idAdquisitionContract, contractDate]);
    }

    public async getSupplierPaymentOrder(idSupplierPaymentOrder: number, contractDate: any): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "com.quickdataerp.bean.supplier.SupplierPaymentOrder_getSupplierPaymentOrder_Number_java.util.Date", [idSupplierPaymentOrder, contractDate]);
    }
    public async postSupplierPaymentOrderToSupplier(idSupplierPaymentOrder: any, supplierPaymentOrderDate: any, idEmployee: any, observations: any): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "com.quickdataerp.bean.supplier.SupplierPaymentOrder_postSupplierPaymentOrderToSupplier_Number_java.util.Date_Number_String", [idSupplierPaymentOrder, supplierPaymentOrderDate, idEmployee, observations]);
    }
    public async rejectSupplierPaymentOrder(idSupplierPaymentOrder: any, supplierPaymentOrderDate: any, idEmployee: any, observations: any): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "com.quickdataerp.bean.supplier.SupplierPaymentOrder_rejectSupplierPaymentOrder_Number_java.util.Date_Number_String", [idSupplierPaymentOrder, supplierPaymentOrderDate, idEmployee, observations]);
    }

    public async addUBLInvoiceToAdquisitionContract(idSupplierPaymentOrder: any, supplierPaymentOrderDate: any, idUBLInvoice: any, issueDate: any, observations: any, idAccountFiled: any, idThirdBankingAccount: number): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "com.quickdataerp.bean.ubl.UBLInvoice_addUBLInvoiceToAdquisitionContract_Number_java.util.Date_Number_java.util.Date_String_Number_Number", [idSupplierPaymentOrder, supplierPaymentOrderDate, idUBLInvoice, issueDate, observations, idAccountFiled, idThirdBankingAccount]);
    }


    public async exportSupplierPaymentOrder(idSupplierPaymentOrder: any, supplierPaymentOrderDate: any): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_exportSupplierPaymentOrder_Number_java.util.Date", [idSupplierPaymentOrder, supplierPaymentOrderDate]);
    }

    public async updateSupplierPaymentOrder(bean: any): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "com.quickdataerp.bean.supplier.SupplierPaymentOrder_updateSupplierPaymentOrder_com.quickdataerp.bean.supplier.SupplierPaymentOrder", [
            {
                "DataBeanProperties": bean,
                "DataBeanName": "com.quickdataerp.bean.supplier.SupplierPaymentOrder"
            }
        ]);
    }
    public async getSupplierPaymentOrderConstants(): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_getSupplierPaymentOrderConstants_Number", [null]);
    }
    public async getSupplierPaymentOrderCatalogForSupervisor(idAccount: number, year: number, state: number | null): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_getSupplierPaymentOrderCatalogForSupervisor_Number_Number_Number", [idAccount, year, state]);
    }
    public async releaseUBLInvoiceFromAdquisitionContract(idSupplierPaymentOrder: number, supplierPaymentOrderDate: any, idAccountFiled: number | null, obsDeleted: string): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "com.quickdataerp.bean.ubl.UBLInvoice_releaseUBLInvoiceFromAdquisitionContract_Number_java.util.Date_Number_String", [idSupplierPaymentOrder, supplierPaymentOrderDate, idAccountFiled, obsDeleted]);
    }
    public async rejectSupplierPaymentOrderFromSupervisor(idSupplierPaymentOrder: number, supplierPaymentOrderDate: any, obs: string, idAccountFiled: number | null): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "com.quickdataerp.bean.supplier.SupplierPaymentOrder_rejectSupplierPaymentOrderFromSupervisor_Number_java.util.Date_String_Number", [idSupplierPaymentOrder, supplierPaymentOrderDate, obs, idAccountFiled]);
    }
    public async postSupplierPaymentOrderToHiring(idSupplierPaymentOrder: number, supplierPaymentOrderDate: any, obs: string, idAccountFiled: number | null): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "com.quickdataerp.bean.supplier.SupplierPaymentOrder_postSupplierPaymentOrderToHiring_Number_java.util.Date_String_Number", [idSupplierPaymentOrder, supplierPaymentOrderDate, obs, idAccountFiled]);
    }
    public async rejectSupplierPaymentOrderFromHiring(idSupplierPaymentOrder: number, supplierPaymentOrderDate: any, obs: string, idAccountFiled: number | null): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "com.quickdataerp.bean.supplier.SupplierPaymentOrder_rejectSupplierPaymentOrderFromHiring_Number_java.util.Date_String_Number", [idSupplierPaymentOrder, supplierPaymentOrderDate, obs, idAccountFiled]);
    }
    public async getSupplierPaymentOrderCatalogForHiring(idAccountSupplier: null | null, year: number): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_getSupplierPaymentOrderCatalogForHiring_Number_Number", [idAccountSupplier, year]);
    }
    public async sendSupplierPaymentOrderToAccounting(idSupplierPaymentOrder: number, supplierPaymentOrderDate: any, obs: string, idAccountFiled: number | null): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "com.quickdataerp.bean.supplier.SupplierPaymentOrder_sendSupplierPaymentOrderToAccounting_Number_java.util.Date_String_Number",
            [idSupplierPaymentOrder, supplierPaymentOrderDate, obs, idAccountFiled]);
    }
    public async sendSupplierPaymentOrderToStore(idSupplierPaymentOrder: number, supplierPaymentOrderDate: any, obs: string, idAccountFiled: number | null): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "com.quickdataerp.bean.supplier.SupplierPaymentOrder_sendSupplierPaymentOrderToStore_Number_java.util.Date_String_Number",
            [idSupplierPaymentOrder, supplierPaymentOrderDate, obs, idAccountFiled]);
    }

    public async getAccountantDocumentList(idUBLInvoice: number, issueDate: any, idAccount: any): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_getAccountantDocumentList_Number_java.util.Date_Number",
            [idUBLInvoice, issueDate, idAccount]);
    }



}