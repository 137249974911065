import { useEffect, useState } from "react";
import { _Committe } from "../../../../../core/services/dataBean/EntityCatalog";
import useDataBean from "../../../../../core/services/dataBean/useDataBean";
import { DataBeanProperties } from '../../../../../core/services/model/server-response.interface';
import { Singleton } from "../../../../../core/services/Singleton";
import { MultiSelect } from "../../../../theme/Component/MultiSelect";
import { BasicButton } from "../../../../theme/Component/BasicButton";
import { toast } from "react-toastify";

interface Props{
    lote:number;
    callBack:any;
}
export const MakeCommitte =({lote,callBack}:Props)=>{

    const single = Singleton.getInstance();
    const committe = useDataBean(_Committe);

    const [lista,setLista] = useState<any>([]);
    
    const [seleccion,setSeleecion] = useState<any>([]);

    

    useEffect(()=>{
        committe.instance.getRoleCommitteeUser(null).then(
            (resp:any)=>{
                if(resp)
                {
                    let tmp = [];
                    resp.forEach(element => {
                            tmp.push(
                                {
                                    id:element.DataBeanProperties.IDEmployee,
                                    Name:element.DataBeanProperties.Name +" - " +single.getEntityName(element)

                                }
                            )
                    });
                    setLista(tmp);
                }
            }
        )
        return ()=>{setSeleecion([])}
    },[lote])

    const makeCommite=()=>{
        committe.instance.makeCommite(single.getAccountID(),seleccion).then(
            (resp:any)=>{
               //console.log(resp);
                if(resp.Lote)
                {
                    toast.success("se ha credo el comite de crédito "+resp.Lote);
                }
                else{
                    toast.error("No se pudo crear el comite");
                }
                callBack(true);
            }
        ).catch(err=>{toast.error(err)})
    }

    return(
        <div className="row">
            <div className="col-md-12">
                <MultiSelect lista={lista} getLista={setSeleecion}/>
            </div>
            <div className="col-md-12">
                <BasicButton icon=" ri-loader-2-line" disable={seleccion.length===0} eventClick={makeCommite} >Crear comite</BasicButton>
            </div>
        </div>
    )
}