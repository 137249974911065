import { useEffect, useState } from "react";
import { Singleton } from "../../../../../../core/services/Singleton";
import { SelectAdquisitionPlan } from "../../../../select/SelectAdquisitionPlan";
import { SelectAdquisitionPlanFunctionalIDByPlan } from "../../../../select/SelectAdquisitionPlanFunctionalIDByPlan";
import { AdquisitionItem } from "../../../../adquisition-setting/AdquisitionItem";
import { BasicPage } from "../../../../../theme/Component/BasicPage";

interface Props {
    filterState?: number;
    _onChange?: Function;
    menuComponent?: boolean;
}
export const ManageAdquisitionItem = ({ filterState, _onChange, menuComponent }: Props) => {

    const single = Singleton.getInstance();

    const [year, setYear] = useState<number>(single.getCurrenYear())
    const [plan, setPlan] = useState<number>(null)
    const [area, setArea] = useState<number>(null)
    const [fase, setFase] = useState<number>(null)

    const setRowBean = (bean) => {
       //console.log(bean);

        if (_onChange) {
            _onChange(bean);
        }
    }

    return (
        <>
            {menuComponent ?
                <BasicPage titulo="Nuevas Necesidades" >
                    <div className="row">
                        <div className="col-md-3">
                            <label>Año del Plan de Compras</label>
                            <input type="number" className="form-control" value={year} onChange={(e) => { setYear(parseInt(e.target.value)) }} />
                        </div>
                        <div className="col-md-4">
                            <SelectAdquisitionPlan idSelector={plan} onChange={(e) => { setPlan(e) }} _year={year} />
                        </div>
                        <div className="col-md-5">
                            <SelectAdquisitionPlanFunctionalIDByPlan _returnObject={true} _idPlan={plan} idSelector={area} onChange={(e) => { setArea(e.IDAdquisitionPlanFunctionalID); setFase(e.PhaseState) }} />
                        </div>
                    </div>

                    <div className="row mt-2">
                        <div className="col-12">
                            {area != null && area >= 0 ?
                                <AdquisitionItem key={area} idAdquisitionPlanFunctionalID={area} rol={1} beanOficina={{}} selectRow={(e) => { setRowBean(e) }} phaseState={fase} filterState={filterState} />
                                :
                                <div className="alert alert-warning text-center">
                                    Debe seleccionar un Área Funcional
                                </div>
                            }
                        </div>
                    </div>
                </BasicPage>
                :
                <>
                    <div className="row">
                        <div className="col-md-4">
                            <label>Año del Plan de Compras</label>
                            <input type="number" className="form-control" value={year} onChange={(e) => { setYear(parseInt(e.target.value)) }} />
                        </div>
                        <div className="col-md-4">
                            <SelectAdquisitionPlan idSelector={plan} onChange={(e) => { setPlan(e) }} _year={year} />
                        </div>
                        <div className="col-md-4">
                            <SelectAdquisitionPlanFunctionalIDByPlan _returnObject={true} _idPlan={plan} idSelector={area} onChange={(e) => { setArea(e.IDAdquisitionPlanFunctionalID); setFase(e.PhaseState) }} />
                        </div>
                    </div>

                    <div className="row mt-2">
                        <div className="col-12">
                            {area != null && area >= 0 ?
                                <AdquisitionItem key={area} idAdquisitionPlanFunctionalID={area} rol={1} beanOficina={{}} selectRow={(e) => { setRowBean(e) }} phaseState={fase} filterState={filterState} />
                                :
                                <div className="alert alert-warning text-center">
                                    Debe seleccionar un Área Funcional
                                </div>
                            }
                        </div>
                    </div>
                </>
            }
        </>
    )
}