import { useEffect, useState } from "react";
import { AdminService } from "../../../core/services/AdminService";
import { Singleton } from "../../../core/services/Singleton";
import { BasicPage } from "../../theme/Component/BasicPage";
import { SelectRoleGroup } from "../RolesGroup/SelectRoleGroup.component";
import { SystemRoles } from "../Roles/SystemRoles";

export const RoleArea = () => {

  const single = Singleton.getInstance()
  const service = new AdminService();
  const [office, setOffice] = useState(undefined)
  const [lista, setLista] = useState([]);

  useEffect(() => {
    if (office?.IDRoleGroups >= 0) {
      getlista()
    }
  }, [office])

  const getlista = () => {
    let x = {
      State: 1,
      IDRoleGroups: office.IDRoleGroups,
    };

    single.spinner(true);

    service.getRoleGroupsItemsCatalogPorPropiedades(x, null).subscribe(
      (resp: any) => {
        if (resp.DataBeanProperties.ObjectValue.length > 0) {
         //console.log(resp.DataBeanProperties.ObjectValue);
          const idRoleGroupsArray = resp.DataBeanProperties.ObjectValue.map(
            (item: any) => item.DataBeanProperties.IDRole
          );
          setLista(idRoleGroupsArray);
         //console.log(idRoleGroupsArray)
        } else {
          setLista([])
         //console.log(lista)
        }
        single.spinner(false);
      }
    );
  }
  return (
    <>
      <BasicPage titulo="Grupos de Roles a Cargo">
        <div className="row">
          <div className="col-md-12">
            <SelectRoleGroup _user={single.getAccountID()} _value={office} _onChange={(e) => { setOffice(e) }} titulo="Oficinas a cargo" />
          </div>
          {office?.IDRoleGroups >= 0 ?
            <div className="col-12">
              <SystemRoles _onChange={getlista} groupRole={office} roleList={lista} />
            </div>
            :
            <div className="div alert alert-warning mt-2 text-center">Debe seleccionar una Oficina</div>
          }
        </div>
      </BasicPage>
    </>
  );
};
