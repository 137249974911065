import { useState } from "react";
import { BasicButton } from "../../theme/Component/BasicButton";
import { DocumentViewer } from "../../theme/Component/DocumentViewer";
import { ErrorList } from "../../theme/Component/ErrorList";
import useDataBean from "../../../core/services/dataBean/useDataBean";
import { _BudgetRPFactory, _single } from "../../../core/services/dataBean/EntityCatalog";
import { toast } from "react-toastify";

interface Props {
    contrato?: any;
    formData?: any;
    rpList: any;
}

export const CreateRFDocument = ({ contrato, rpList, formData }: Props) => {

    const _budgetRDFactory = useDataBean(_BudgetRPFactory);

    const [documents, setDocuments] = useState<any>([]);
    const [msgList, setMsgList] = useState<any>([]);
    const [tap, setTap] = useState<number>(0);

    const crearDocumento = () => {
        _budgetRDFactory.instance.createRP(contrato?.Contract?.IDAdquisitionContract ?? null, contrato?.Contract?.AdquisitionContractDate ?? null, contrato ? contrato.AccDate : formData?.AccDate, contrato ? contrato.Consecutive : formData.Consecuitive, rpList, contrato ? contrato.Description : formData.Description, contrato ? contrato?.Contract?.IDAccountSupplier : formData.IdAccount, _single.getAccountID(), true).then(
            (resp: any) => {
               //console.log(resp)
                setDocuments(resp.DocumentURL);
                setMsgList(resp.MsgList);
                setTap(1)
            }
        ).catch(
            err => {
                toast.error(err, { autoClose: false })
            }
        )
    }

    //pude declarar variables pero no declarar mi amor por ella...

    return (
        <div className="row">
            {tap === 0 &&
                <>
                    {contrato ?
                        <>
                            <div className="col-md-6 mt-4">
                                <BasicButton disable={!contrato.AccDate} icon="ri-article-line" eventClick={crearDocumento}>Generar Registro Presupuestal</BasicButton>
                            </div>
                        </>
                        :
                        <div className="col-md-6">
                            <BasicButton disable={!formData?.AccDate && !formData?.IdAccount && !formData?.Description} icon="ri-article-line" eventClick={crearDocumento}>Generar Registro Presupuestal</BasicButton>
                        </div>
                    }
                </>
            }
            {tap === 1 &&
                <div className="col">
                    {msgList.length > 0 &&
                        <ErrorList lista={msgList} />

                    }
                    {msgList.length === 0 && documents.length > 0 &&
                        <DocumentViewer key={documents} list={documents} />
                    }
                </div>
            }
        </div>
    )
}