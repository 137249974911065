import { useEffect, useState } from "react";
import { ContractService } from "../../../../core/services/ContractService"
import { BasicPage } from "../../../theme/Component/BasicPage"
import { Singleton } from '../../../../core/services/Singleton';
import { BasicTable } from "../../../theme/Component/BasicTable";
import { ListMenu } from "../../../theme/Component/ListMenu";
import { Modal } from "../../../theme/Component/Modal";
import { UBLInvoice } from "./ublinvoice/UBLInvoice";
import { UBLInvoiceList } from "./ublinvoice/UBLInvoiceList";
import { UBLCONTRACT, UBLParam, UBLSUPPLIER } from "./ContracInterface";
import { PrintJson } from "../../../theme/Component/PrintJson";
import useModal, { MODAL_LG } from "../../../theme/Component/hooks/useModal";
import { SupplierPaymentOrder } from "../../../Talentohumano/GestionTH/SupplierPaymentOrder/SupplierPaymentOrder";
import { AdquisitionContractCDP } from "./adquisitionContractCDP/AdquisitionContractCDP";
import { AdquisitionContractRP } from "./adquisitionContractRP/AdquisitionContractRP";



export const Contracts = () => {

    const single = Singleton.getInstance();
    const contracService = new ContractService();
    const [bean, setBean] = useState<any>({});
    const [row, setRow] = useState<any>({});

    const [modalPC, setModalPC] = useState<any>({ name: 'Gestión de Ordenes de Pago', tabIndex: -1, view: false, btnClose: true, btnSubmit: false, format: 'modal-lg' });
    const [modal, setModal] = useState<any>({ name: 'Facturas', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-xl' });
    const [modalConf, setModalConf] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });
    const [modalCDP, setModalCDP] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalRP, setModalRP] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const modalBuscar = useModal("Buscar Factura", MODAL_LG);

    const [params, setParams] = useState<UBLParam>(
        {
            IDAccountSupplier: row.IDAccountSupplier,
            IDAdquisitionContract: row.IDAdquisitionContract,
            From: single.getCurrenDate(),
            Upto: single.getCurrenDate(),
            State: null
        }
    );

    const idBean = 'IDAdquisitionContract';

    useEffect(() => {

        getAdquisitionContractRender();

    }, [])

    useEffect(() => {
        setParams({ ...params, "IDAccountSupplier": row.IDAccountSupplier, "IDAdquisitionContract": row.IDAdquisitionContract });

    }, [row])


    const getAdquisitionContractRender = () => {
        single.spinner(true);
        contracService.getAdquisitionContractRenderByIDAccount(null, null, { State: 1 }, single.getAccountID()).subscribe(
            (resp: any) => {

                if (resp.DataBeanProperties.ObjectValue) {
                    setLista(resp.DataBeanProperties.ObjectValue);
                }
                setModalConf({ ...modalConf, "view": false });
                single.spinner(false);
            }
        )
    }


    const [lista, setLista] = useState<any>([]);

    const head = [
        { title: 'ID', property: 'IDAdquisitionContract', visible: false },
        { title: 'Valor Contrato', property: 'ContractValue', visible: true },
        { title: 'Valor CDP', property: 'CDPValue', visible: true },
        { title: 'Fecha Contrato', property: 'SeleccionDate', visible: true },
        { title: 'Dodo radicado', property: 'Code', visible: false },
        { title: 'Código contrato', property: 'InternalCode', visible: true },
        { title: 'Proveedor', property: 'Name1', visible: true },
        { title: 'Descripción', property: 'Description', visible: true },
        { title: 'No CDP', property: 'NoCDP', visible: false },
        { title: 'Fecha CDP', property: 'DateCDP', visible: false },
        { title: 'No registro presupuestal', property: 'NoRegistroPresupuestal', visible: false },
        { title: 'Fecha registro presupuestal', property: 'DateRegPresupuestal', visible: false },
    ]

    const listaBotones = () => {
        const botones = [
            { titulo: 'Facturas contrato', icono: 'ri-file-zip-line', visible: true, evento: () => { setModal({ ...modal, "view": true }) } },
            { titulo: 'Buscar facturas', icono: 'ri-file-search-fill', visible: true, evento: () => { modalBuscar.open() } },
            { titulo: 'Gestión de Ordenes de Pago', icono: 'ri-file-zip-line', visible: true, evento: () => { setModalPC({ ...modalPC, "view": true }) } },
            // { titulo: 'CDP Asociados', icono: 'ri-file-list-line', visible: true, evento: () => { setModalCDP({ ...modalCDP, "view": true, "name": "CDP Asociados" }) } },
            { titulo: 'RP Asociados', icono: 'ri-file-list-line', visible: true, evento: () => { setModalRP({ ...modalRP, "view": true, "name": "RP Asociados" }) } },
        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }

    return (
        <BasicPage titulo="Mis Contratos">

            <div className="row">
                <div className="col-md-12">
                    <BasicTable head={head} body={lista} rowSet={setRow} listButtons={listaBotones()}></BasicTable>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <Modal modal={modal} updateModal={setModal} >
                        <UBLInvoiceList type={UBLCONTRACT} formData={params} contract={row} importUBL={true} />

                    </Modal>
                    <Modal modal={modalBuscar.modal} updateModal={modalBuscar.setModal} >
                        <div className="row">
                            <div className="col">
                                <UBLInvoiceList type={UBLSUPPLIER} contract={row} formData={
                                    {
                                        IDAccountSupplier: row.IDAccount,
                                        IDAdquisitionContract: row.IDAdquisitionContract,
                                        From: "01-01-" + single.getCurrenYear() + " 00:00:00",
                                        Upto: single.getCurrenDate(),
                                        State: null

                                    }
                                } importUBL={false} />
                            </div>
                        </div>
                    </Modal>
                    <Modal modal={modalPC} updateModal={setModalPC}>
                        <>
                            <SupplierPaymentOrder _AdquisitionContrac={row} />
                        </>
                    </Modal>

                    <Modal modal={modalCDP} updateModal={setModalCDP}>
                        <AdquisitionContractCDP adquisitionContract={row} />
                    </Modal>

                    <Modal modal={modalRP} updateModal={setModalRP}>
                        <AdquisitionContractRP adquisitionContract={row} />
                    </Modal>
                </div>
            </div>

        </BasicPage>
    )
}