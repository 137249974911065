import { DataBean } from "../DataBean";


export class EmployeeBonus extends DataBean {

    public async getEmployeeBonusCatalog(arg: any | null): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_getEmployeeBonusCatalog_Number", [arg]);
    }

    public async getEmployeeBonusTypeConstants(arg: any | null): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_getEmployeeBonusTypeConstants_Number", [arg]);
    }

    public async getWelfareEntityTypeCatalog(arg: any | null): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_getWelfareEntityTypeCatalog_Number", [arg]);
    }

    public async addEmployeeBonusToAllAppointments(idEmploymentBonus: number, idEmployee: number): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "boolean_addEmployeeBonusToAllAppointments_Number_Number", [idEmploymentBonus, idEmployee]);
    }

    public async getPayrollMonthsConstants(arg: any | null): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_getPayrollMonthsConstants_Number", [arg]);
    }

    public async createEmployeeBonus(idEmploymentBonus: number, idAccount: number, bonusValue: number, idEmployee: number, isTemporary: boolean, from: any, upto: any): Promise<any> {
        return this.getCustomService(
            "QuickDataERPCore",
            "employees.EmployeeBonusValue_createEmployeeBonus_Number_Number_Number_Number_Boolean_java.util.Date_java.util.Date",
            [idEmploymentBonus, idAccount, bonusValue, idEmployee, isTemporary, from, upto]
        );
    }

}