import React, { useEffect, useRef, useState } from "react";
import { ButtonArticleClassCodeTree } from "./ButtonArticleClassCodeTree";
import useDataBean from "../../../core/services/dataBean/useDataBean";
import { _ArticleClassCode, _single } from "../../../core/services/dataBean/EntityCatalog";
import { ArticleClassCode } from "../../../core/services/dataBean/contabilidad/ArticleClassCode.Entity";

interface Tree {
    idChild: number;
    edit?: boolean;
    idAccountantIDType: number;
}

const ArticleClassCodeTreeChilds = ({ idChild, idAccountantIDType, edit }: Tree) => {
    const articleClassCode = new ArticleClassCode("QuickDataERPCore", "com.quickdataerp.bean.supplier.ArticleClassCode");
    const tree = useDataBean(articleClassCode);

    const [visible, setVisible] = useState<boolean>(false);
    const [lista, setLista] = useState([]);
    const [idchild, setIDchild] = useState(0);
    const padre = useRef<number>(idChild);
    const idp = useRef<number>(idAccountantIDType);
    const ed = useRef<boolean>(edit);
    const hijo = useRef<number>(0);

    useEffect(() => {
        getListatree(padre.current);
        return () => { hijo.current = 0 }
    }, []);




    const getListatree = (idArbol: any) => {
        if (idArbol > 0) {

            let tmp = _single.getCacheItem('treeciuu_'+idArbol);
            if (tmp) {
              setLista(tmp);
            }else{
                tree.instance.loadArticleClassCodeTree(idArbol).then(
                    (resp: any) => {
                        ////console.log(resp.Childs);
                        setLista(resp.Childs);
                        _single.setCacheItem('treeciuu_'+idArbol,resp.Childs);

                    }
                ).catch(err => console.error(err));

            }

        }
    };
    const handleClick = (event, item) => {
        event.stopPropagation();
        localStorage.setItem('articleclasscodeTree', JSON.stringify(item.EnvolvedObject.DataBeanProperties));
        setIDchild(item.EnvolvedObject.DataBeanProperties.IDLn === idchild ? 0 : item.EnvolvedObject.DataBeanProperties.IDLn);
        hijo.current = item.EnvolvedObject.DataBeanProperties.IDLn === idchild ? 0 : item.EnvolvedObject.DataBeanProperties.IDLn;
    }

    return (
        <>


            <ul className="list-group" key={_single.getRandomInt()}>
                {
                    lista &&
                    lista.map((item: any, index: number) => {
                        return (
                            <li className="list-group-item itemh" style={{ border: '0' }} key={item.EnvolvedObject.DataBeanProperties.IDLn} >
                                <div className={idchild === item.EnvolvedObject.DataBeanProperties.IDLn ? 'cursor treeselect d-flex ' : 'cursor d-flex '}
                                >
                                    <div>
                                        {edit &&
                                            <ButtonArticleClassCodeTree
                                                id={item.EnvolvedObject.DataBeanProperties.IDLn}
                                                idAccountantIDType={idAccountantIDType}
                                                bean={item.EnvolvedObject.DataBeanProperties}
                                                updateEvent={() => { getListatree(padre.current); }}
                                            />
                                        }
                                    </div>
                                    <div>
                                        {/* {hijo.current} - {item.EnvolvedObject.DataBeanProperties.IDLn} */}
                                        <strong><span className="d-inline-block text-truncate" style={{ maxWidth: '40vw', fontSize: '0.7rem' }}
                                            onClick={(event) => {
                                                handleClick(event, item);

                                            }}>
                                            {item.EnvolvedObject.DataBeanProperties.ArticleClassCode} - {item.EnvolvedObject.DataBeanProperties.Name}
                                        </span> </strong>
                                    </div>
                                    <div>
                                        <span className="badge bg-success ms-1">{item.ChildrenCount}</span>
                                    </div>
                                </div>

                                {(hijo.current === item.EnvolvedObject.DataBeanProperties.IDLn) &&

                                    <ArticleClassCodeTreeChilds idAccountantIDType={idp.current} edit={ed.current} idChild={hijo.current} />

                                }
                            </li>
                        )
                    })
                }
            </ul>

        </>
    )
};

// Exportando el componente con React.memo para evitar re-renderizados innecesarios
export default React.memo(ArticleClassCodeTreeChilds);
