import { useState } from "react";
import { BudgetResourceEditor } from "../../admin/configuracion/BudgetResource/BudgetResource.Editor";
import { BasicButton } from "../../theme/Component/BasicButton";
import { toast } from "react-toastify";

interface Props {
    data: any;
    event: any;
}

export const ResourcePresupuestalRender = ({ data, event }: Props) => {
    const [budgettype, setBudgettype] = useState<number>(null)
    const valdatecampos = () => {
        if (!budgettype) {
            toast.error("Recurso no valido")
        }
        else {
            let row = data;
            row.IDBudgetResource = budgettype;
            event(row)
        }
    }
    return (
        <>
            <div className="col-md-12">
                <BudgetResourceEditor label="Tipo de Recurso" idSelector={budgettype} onChange={(e) => { setBudgettype(e) }} />
            </div>

            <div className="col-md-6 mt-3">

                <BasicButton icon="ri-search-line" eventClick={valdatecampos} >Selecionar</BasicButton>

            </div>
        </>
    )
}