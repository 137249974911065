import { useEffect, useState } from "react";
import { _AccountantConcept, _CompetitionType, _Entailment, _MainTheme } from "../../../../core/services/dataBean/EntityCatalog";

import { Singleton } from "../../../../core/services/Singleton";
import { toast } from "react-toastify";
import { ListMenu } from "../../../theme/Component/ListMenu";
import { BasicPage } from "../../../theme/Component/BasicPage";
import { BasicTable } from "../../../theme/Component/BasicTable";
import { Modal } from "../../../theme/Component/Modal";
import { ModalConfirm } from "../../../theme/Component/ModalConfirm";
import useDataBean from "../../../../core/services/dataBean/useDataBean";
import { PrintJson } from "../../../theme/Component/PrintJson";
import { PrintObjectJson } from "../../../theme/Component/PrintObjectJson";
import { CashReimbursementServices } from "../CashDesk/CashReimbursement/CashReimbursement-service";
import { SelectAccountantTree } from "../../../contabilidad/catalogo-cuentas/AccountantTree/SelectAccountantTree";
import { MASK_DATE } from "../../../theme/Component/BasicTable2";
import { BasicSwitch } from "../../../theme/Component/BasicSwitch";



export const AccountantConcept = () => {

    const AccountantConcept = useDataBean(_AccountantConcept);

    const single = Singleton.getInstance();
    const service = new CashReimbursementServices();
    const [selectedItems, setSelectedItems] = useState([]);
    const [lista, setLista] = useState<any>([]);
    const [listadocs, setlistadocs] = useState([]);
    const [modal, setModal] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalC, setModalC] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });

    useEffect(() => {
        getLista();
        getListaDocs();
    }, [])

    const handleCheckboxChange = (event, item) => {
        const isChecked = event.target.checked;

        if (isChecked) {
            setSelectedItems(prevItems => [...prevItems, item.DocumentFactory]);
        } else {
            setSelectedItems(prevItems => prevItems.filter(selected => selected.DocumentFactory !== item.DocumentFactory));
        }
    };

    useEffect(() => {
        // Solo actualizar el AccountantConcept cuando selectedItems cambia
        if (selectedItems.length > 0) {
           //console.log(JSON.stringify(selectedItems))
            AccountantConcept.setBean({ ...AccountantConcept.bean, "JsonList": JSON.stringify(selectedItems) });
        }
    }, [selectedItems]);

    const getLista = () => {

        AccountantConcept.instance.getAccountantConceptCatalog(null).then(
            (resp: any) => {
                if (resp) {
                    setLista(resp)
                   //console.log(resp)
                }
            }
        )

    }

    const create = () => {
        AccountantConcept.setBean({
            ApplyForCashDesk: false,
        });
        setSelectedItems([]);

        // AccountantConcept.update().then(
        //     (resp: any) => {
        //        //console.log(resp);
        //     }
        // );
        setModal({ ...modal, "view": true });
    }


    const deleteItem = () => {
        AccountantConcept.deleteItemVoid(AccountantConcept.bean.IDAccountantConcept).then(
            (resp: any) => {
                getLista();
                setModalC({ ...modalC, "view": false });
            }
        )

    }
    const editaItem = () => {
       //console.log(AccountantConcept.bean);
        AccountantConcept.update().then(
            (resp: any) => {
                getLista();
                setModal({ ...modal, "view": false });
            }
        ).catch(err => {
            toast.error(err);
        })

    }
    const head = [
        { title: 'ID', property: 'IDAccountantConcept', visible: true },
        { title: 'Nombre', property: 'Name', visible: true },
        { title: 'Cuenta Contable del Concepto ', property: 'AccountantIDName', visible: true },
        { title: 'Cuenta Puente Contabilización', property: 'CreditAccountantIDName', visible: true },
        { title: 'Aplica a Cajas Menores', property: 'ApplyForCashDesk', visible: true },
        { title: 'Lista de Documentos a los que Aplica', property: 'JsonListName', visible: true },
        { title: 'Fecha de Creación', property: 'Since', visible: true, mask: MASK_DATE },
    ]

    const iconButtonsItem = [
        { nombre: 'Agregar', visible: single.canCreate(), icono: 'ri-file-add-line', evento: create }
    ]
    const listaBotones = () => {
        const botones = [

            { titulo: 'Editar', icono: 'ri-edit-2-line', visible: single.canEdit(), evento: () => { setModal({ ...modal, "view": true }) } },
            { titulo: 'Eliminar', icono: 'ri-delete-bin-line', visible: single.canDelete(), evento: () => { setModalC({ ...modalC, "view": true }) } },

        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }


    const getListaDocs = () => {

        single.spinner(true);
        service.getDocumentFactoryCatalog(null).subscribe(
            (resp: any) => {

                if (resp.DataBeanProperties.ObjectValue) {
                    setlistadocs(resp.DataBeanProperties.ObjectValue);
                }
                single.spinner(false);
            }
        );
    }


    return (
        <BasicPage titulo="Catálogo de Conceptos Contables">
            <div className="row">
                <div className="col-md-12">

                    <BasicTable body={lista} head={head} rowSet={AccountantConcept.setBean} listButtons={listaBotones()} iconButtons={iconButtonsItem}></BasicTable>
                    <Modal modal={modal} updateModal={setModal} eventModal={editaItem}>
                        <div className="row">

                            <div className="col-md-12">
                                <label>Nombre</label>
                                <input type="text" name="Name" className="form-control" value={AccountantConcept.bean.Name} onChange={AccountantConcept.handleChange} />

                            </div>
                            <div className="col-md-12 table-limit mt-3">



                                <div className="table-responsive">
                                    <table className="table" >
                                        <thead >
                                            <tr className="trtable">
                                                <th className="thtable">Seleccionar</th>
                                                <th className="thtable">Mnemonic</th>
                                                <th className="thtable">Nombre</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {Array.isArray(listadocs) && listadocs.map((Docs: any, index: number) => {

                                                // Verifica si JsonList contiene el DocumentFactory de este ítem
                                                const isChecked = AccountantConcept.bean.JsonList
                                                    ? JSON.parse(AccountantConcept.bean.JsonList).some((item: any) => item === Docs.DataBeanProperties.DocumentFactory)
                                                    : false;

                                                return (
                                                    <tr className="trtable" key={index}>
                                                        <td className="tdtable">
                                                            <input
                                                                type="checkbox"
                                                                name="JsonList"
                                                                checked={isChecked} // Marca el checkbox si está en JsonList
                                                                value={AccountantConcept.bean.JsonList}
                                                                onChange={(e) => {
                                                                    AccountantConcept.handleChange(e);
                                                                    handleCheckboxChange(e, Docs.DataBeanProperties);
                                                                }}
                                                            />
                                                        </td>
                                                        <td className="tdtable">{Docs.DataBeanProperties.Mnemonic}</td>
                                                        <td className="tdtable">{Docs.DataBeanProperties.Name}</td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>

                            </div>
                            <div className="col-md-6">
                                <SelectAccountantTree label="Cuenta Contable del Concepto" idAccountant={AccountantConcept.bean.AccountantID} onChange={(e) => { AccountantConcept.setBean({ ...AccountantConcept.bean, "AccountantID": e.AccountantID }) }} />
                            </div>
                            <div className="col-md-6">
                                <SelectAccountantTree label="Cuenta Puente Contabilización" idAccountant={AccountantConcept.bean.CreditAccountantID} onChange={(e) => { AccountantConcept.setBean({ ...AccountantConcept.bean, "CreditAccountantID": e.AccountantID }) }} />
                            </div>
                            <div className="col-md-6">
                                <BasicSwitch estado={AccountantConcept.bean.ApplyForCashDesk} eventChange={(e) => { AccountantConcept.setBean({ ...AccountantConcept.bean, "ApplyForCashDesk": e }) }} label="Aplica a Cajas Menores" />
                            </div>
                        </div>
                    </Modal>
                    <ModalConfirm modal={modalC} updateModal={setModalC} eventModal={deleteItem}>
                        <strong>Eliminar item</strong>
                    </ModalConfirm>
                </div>

            </div>
        </BasicPage>
    )
}