import { useEffect, useState } from "react";
import useDataBean from "../../../core/services/dataBean/useDataBean";
import { Singleton } from "../../../core/services/Singleton";
import { toast } from "react-toastify";
import { ListMenu } from "../../theme/Component/ListMenu";
import { BasicPage } from "../../theme/Component/BasicPage";
import { BasicTable } from "../../theme/Component/BasicTable";
import { Modal } from "../../theme/Component/Modal";
import { AppointmentGroupManualEditor } from "../ParametrosTalentoHumano/AppointmentGroupManual/AppointmentGroupManual.editor";
import { AppointmentManualEditor } from "../ParametrosTalentoHumano/AppointmentManual/AppointmentManual.editor";
import { ModalConfirm } from "../../theme/Component/ModalConfirm";
import { _PaymentMethod } from "../../../core/services/dataBean/EntityCatalogVdos";



export const PaymentMethod = () => {

    const PaymentMethod = useDataBean(_PaymentMethod);
    const single = Singleton.getInstance();

    const [lista, setLista] = useState<any>([]);
    const [modal, setModal] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalC, setModalC] = useState<any>({ name: "Eliminar", tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });
    const [modalConfirm, setModalConfirm] = useState<any>({ name: 'Confirmar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });

    useEffect(() => {
        getLista();
    }, [])

    const getLista = () => {

        PaymentMethod.instance.getPaymentMethodCatalogPorPropiedad('State', 1, null).then(
            (resp: any) => {
                if (resp) {
                    setLista(resp)
                   //console.log(resp)
                }
            }
        )

    }




    const create = () => {
        PaymentMethod.setBean({
            State: 1
        });
        setModal({ ...modal, "view": true });
    }

    const eliminarItem = () => {
        PaymentMethod.bean.State = 2;
       //console.log(PaymentMethod.bean);
        editaItem();
        setModalC({ ...modalC, ['view']: false });
    }

    const editaItem = () => {
       //console.log(PaymentMethod.bean);
        PaymentMethod.update().then(
            (resp: any) => {
                getLista();
                setModal({ ...modal, "view": false });
            }
        ).catch(err => {
            toast.error(err);
        })

    }

    const head = [
        { title: 'ID', property: 'IDPaymentMethod', visible: true },

        { title: 'Nombre', property: 'Name', visible: true },
        { title: 'Descripción', property: 'Description', visible: true },
        { title: 'Fecha', property: 'Since', visible: true },

    ]

    const iconButtonsItem = [
        { nombre: 'Agregar', visible: single.canCreate(), icono: 'ri-file-add-line', evento: create }
    ]
    const listaBotones = () => {
        const botones = [
            { titulo: 'Editar', icono: 'ri-edit-2-line', visible: single.canEdit(), evento: () => { setModal({ ...modal, "name": `Editar ${PaymentMethod.bean.Name}`, "view": true }) } },
            { titulo: 'Eliminar', icono: 'ri-delete-bin-line', visible: single.canDelete(), evento: () => { setModalC({ ...modalC, "view": true }) } },

        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }


    return (
        <BasicPage titulo="Metodos de pago">
            <div className="row">
                <div className="col-md-12">
                    <BasicTable body={lista} head={head} rowSet={PaymentMethod.setBean} listButtons={listaBotones()} iconButtons={iconButtonsItem}></BasicTable>
                    <Modal modal={modal} updateModal={setModal} eventModal={editaItem}>
                        <div className="row">

                            <div className="col-md-12">
                                <label>Nombre</label>
                                <input type="text" name="Name" className="form-control" value={PaymentMethod.bean.Name} onChange={PaymentMethod.handleChange} />

                            </div>
                            <div className="col-md-12">
                                <label>Description</label>
                                <textarea name="Description" className="form-control" value={PaymentMethod.bean.Description} onChange={PaymentMethod.handleChange} />
                            </div>
                        </div>
                    </Modal>
                    <ModalConfirm modal={modalC} updateModal={setModalC} eventModal={eliminarItem}>
                        <strong>Eliminar item {PaymentMethod.bean.Name}</strong>
                    </ModalConfirm>
                </div>

            </div>
        </BasicPage>
    )
}