import { DataBean } from "../DataBean";

export class PaymentMethod extends DataBean {


    public async getPaymentMethodCatalogPorPropiedad(nombrePropiedad:any,value:any,maxRows:any): Promise<any> {
        return this.getCustomService("FinanceCore", "java.util.List_getPaymentMethodCatalogPorPropiedad_String_Object_Number", [nombrePropiedad,value,maxRows]);
    }

    public async getPaymentMethodCatalogPorPropiedades(properties:any,maxRows:any): Promise<any> {
        return this.getCustomService("FinanceCore", "java.util.List_getPaymentMethodCatalogPorPropiedades_java.util.Map_Number", [properties,maxRows]);
    }

 
}