import { DataBean } from "../DataBean";

export class UBLItemInvoice extends DataBean {

    public async getUBLItemInvoiceCatalog(idUBLInvoice: number, issueDate: any): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_getUBLItemInvoiceCatalog_Number_java.util.Date", [idUBLInvoice, issueDate]);
    }

    public async getUBLItemInvoicePendingForAssign(idUBLInvoice: number, issueDate: any): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "double_getUBLItemInvoicePendingForAssign_Number_java.util.Date", [idUBLInvoice, issueDate]);
    }

    public async getUBLItemArticleConsolidaded(idAdquisitionContract: number, adquisitionContractDate: Date): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_getUBLItemArticleConsolidaded_Number_java.util.Date", [idAdquisitionContract, adquisitionContractDate]);
    }

    public async addUBLItemInvoiceToCashReimbursementConcept(cashReimbursementDate: any, idCashReimbursementItem: number, idUBLItemInvoice: number, idCashReimbursementBudget: number): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "com.quickdataerp.bean.ubl.UBLItemInvoice_addUBLItemInvoiceToCashReimbursementConcept_java.util.Date_Number_Number_Number", [cashReimbursementDate, idCashReimbursementItem, idUBLItemInvoice, idCashReimbursementBudget]);
    }

    public async releaseUBLItemInvoiceToCashReimbursementConcept(cashReimbursementDate: any, idCashReimbursementItem: number, idUBLItemInvoice: number): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "com.quickdataerp.bean.ubl.UBLItemInvoice_releaseUBLItemInvoiceToCashReimbursementConcept_java.util.Date_Number_Number", [cashReimbursementDate, idCashReimbursementItem, idUBLItemInvoice]);
    }

    public async deleteUBLItemInvoice(issueDate: any, idUblItemInvoice: number): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "void_deleteUBLItemInvoice_java.util.Date_Number", [issueDate, idUblItemInvoice]);
    }


    public async updateUBLItemInvoice(bean): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "com.quickdataerp.bean.ubl.UBLItemInvoice_updateUBLItemInvoice_com.quickdataerp.bean.ubl.UBLItemInvoice", [
            {
                "DataBeanProperties": bean,
                "DataBeanName": "com.quickdataerp.bean.ubl.UBLItemInvoice"
            }
        ]);
    }

    public async getGoodTypesConstants(arg: null): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_getGoodTypesConstants_Number", [null]);
    }

    public async getTaxSchemaValuesForUBLInvoiceItem(idUblItemInvoice: number, issueDate: any): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_getTaxSchemaValuesForUBLInvoiceItem_Number_Number_java.util.Date", [null, idUblItemInvoice, issueDate]);
    }

    public async parseTaxSchemaValuesForUBLInvoiceItem(idUblItemInvoice: number, issueDate: any, taxSchemeValueList: any): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "double_parseTaxSchemaValuesForUBLInvoiceItem_Number_java.util.Date_java.util.List", [idUblItemInvoice, issueDate, taxSchemeValueList]);
    }

    public async getUblInvoiceCatalogForAccountingApproval(idAccountSupplier: number | null, year: number, page: number, rows: number): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "com.advantage.shared.Report_getUblInvoiceCatalogForAccountingApproval_Number_Number_Number_Number",
            [idAccountSupplier, year, page, rows]);
    }

    public async setThirdBankingAccountToUBLInvoice(idUBLInvoice: number, issueDate: any, idThirdBankingAccount: number, idEmployee: number): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "com.quickdataerp.bean.ubl.UBLInvoice_setThirdBankingAccountToUBLInvoice_Number_java.util.Date_Number_Number", [idUBLInvoice, issueDate, idThirdBankingAccount, idEmployee]);
    }

    public async getUblInvoiceCatalogForCashReimbursementAccountingApproval(idAccountSupplier: number | null, year: number, page: number, rows: number): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "com.advantage.shared.Report_getUblInvoiceCatalogForCashReimbursementAccountingApproval_Number_Number_Number_Number", [idAccountSupplier, year, page, rows]);
    }

    public async sendCashReimbursementItemToAccounting(idCashReimbursementItem: number, cashReimbursementDate: any, observations: string, idAccountFiled: number): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "com.quickdataerp.bean.accountant.CashReimbursementItem_sendCashReimbursementItemToAccounting_Number_java.util.Date_String_Number", [idCashReimbursementItem, cashReimbursementDate, observations, idAccountFiled]);
    }

    public async sendCashReimbursementItemToStore(idCashReimbursementItem: number, cashReimbursementDate: any, observations: string, idAccountFiled: number): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "com.quickdataerp.bean.accountant.CashReimbursementItem_sendCashReimbursementItemToStore_Number_java.util.Date_String_Number", [idCashReimbursementItem, cashReimbursementDate, observations, idAccountFiled]);
    }


}