import React, { useState, useEffect } from 'react';
import { BpmService } from '../../../../core/services/BpmService';

interface Props {
    idSelector: number;
    onChange: any;
    idForm: number;
}

export const SelectorForm = ({ idForm, onChange, idSelector }: Props) => {
    const service = new BpmService();
    const [lista, setLista] = useState<any[]>([]);
    const [id, setID] = useState<number>(0);

    useEffect(() => {
        getLista();
        if(idSelector>=0){
           //console.log(idSelector)
            setID(idSelector) 
        }
    }, [idForm]);

    // useEffect(() => {
    //     onChange(id);
    // }, [id]);

    const setValue = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedId = parseInt(e.target.value, 10);
        setID(selectedId);
        onChange(selectedId);
    };

    const getLista = () => {
        service.getFormCatalog(idForm).subscribe(
            (resp: any) => {
                setLista([]);
                if (resp.DataBeanProperties.ObjectValue) {
                    setLista(resp.DataBeanProperties.ObjectValue);
                }
            }
        );
    };

    return (
        <>
            <label className='mb-2'>Formularios</label>
            <select className="form-control" value={id} onChange={setValue}>
                <option value={null}>...</option>
                {lista &&
                    lista.map((item: any) => (
                        <option key={item.DataBeanProperties.IDForm} value={item.DataBeanProperties.IDForm}>{item.DataBeanProperties.Name}</option>
                    ))
                }
            </select>
        </>
    );
};
