import { useEffect, useState } from "react";
import { _BudgetReductionRPFactory, _single } from "../../../core/services/dataBean/EntityCatalog";
import { InputDate } from "../../theme/Input/InputDate";
import { AccountEditor } from "../../editor/AccountEditor";
import useDataBean from "../../../core/services/dataBean/useDataBean";
import { BasicTable, MASK_MONEY, MASK_NUMBER } from "../../theme/Component/BasicTable";
import { toast } from "react-toastify";

interface Props {
    onChange: Function;
}

export const BudgetReductionRPFactoryForm = ({ onChange }: Props) => {

    const { instance, lista, setLista, bean, setBean } = useDataBean(_BudgetReductionRPFactory)

    const [response, setResponse] = useState<any>({
        DateForm: _single.getCurrenDate(),
    });

    useEffect(() => {
        onChange(response)
    }, [response])

    const head = [
        { title: 'ID', property: 'IDDocument', visible: true, mask: MASK_NUMBER },
        { title: 'Mnemonic', property: 'Mnemonic', visible: true },
        { title: 'Consecutivo', property: 'Consecutive', visible: true },
        { title: 'Valor del Registro Presupuestal', property: 'Value', visible: true, mask: MASK_MONEY },
        { title: 'Valor Obligado', property: 'Valor Obligado', visible: true, mask: MASK_MONEY },
    ]

    useEffect(() => {
        if (response.IDAccount && response.DateForm) {
            getLista();
        }
    }, [response.IDAccount, response.DateForm])

    const getLista = () => {
        instance.getRPDocuments(response.IDAccount, response.DateForm).then(
            (resp) => {
                setLista(resp);
            }
        ).catch(
            err => { toast.error(err) }
        )
    }

    useEffect(() => {
        if (bean.IDDocument) {
            setResponse({ ...response, "RPSelected": bean })
        }
    }, [bean])

    return (
        <div className="row">
            <div className="col-md-6">
                <InputDate name="DateForm" value={response.DateForm} setDate={(e) => { setResponse({ ...response, "DateForm": e }) }} label="Fecha de Registro" />
            </div>
            <div className="col-md-6">
                <AccountEditor label="Beneficiario" idAccount={response.IDAccount} onChange={(e) => { setResponse({ ...response, "IDAccount": e.IDAccount }) }} />
            </div>
            <div className="col-md-6">
                <label>Número Consecutivo Externo</label>
                <input type="number" className="form-control" value={response.ExternalConsecutive} onChange={(e) => { setResponse({ ...response, "ExternalConsecutive": e.target.value }) }} />
            </div>
            <div className="col-md-12">
                <label>Descripción</label>
                <textarea value={response.Description} onChange={(e) => { setResponse({ ...response, "Description": e.target.value }) }} className="form-control" />
            </div>
            <div className="col-md-12">
                <BasicTable head={head} body={lista} rowSet={setBean} />
            </div>
        </div>
    )
}