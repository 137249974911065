import { DataBean } from "../DataBean";

export class Macroprocess extends DataBean {


    public async getMacroProcessCatalog(): Promise<any> {
        return this.getCustomService("PeopleArt", "java.util.List_getMacroProcessCatalog_Number", [null]);
    }

    public async deleteMacroProcess(bean): Promise<any> {
        return this.getCustomService("PeopleArt", "void_deleteMacroProcess_com.peopleart.bean.appointment.MacroProcess", [
            {
                "DataBeanProperties": bean,
                "DataBeanName": "com.peopleart.bean.appointment.MacroProcess"
            }
        ]);
    }
}