import { DataBean } from "../DataBean";

export class PerformanceCriteria extends DataBean {


    public async getPerformanceCriteriaCatalog(ID: number | null): Promise<any> {
        return this.getCustomService("PeopleArt", "java.util.List_getPerformanceCriteriaCatalog_Number", [ID]);
    }

    public async deletePerformanceCriteria(bean): Promise<any> {
        return this.getCustomService("PeopleArt", "void_deletePerformanceCriteria_com.peopleart.bean.functionals.PerformanceCriteria", [
            {
                "DataBeanProperties": bean,
                "DataBeanName": "com.peopleart.bean.functionals.PerformanceCriteria"
            }
        ]);
    }
}