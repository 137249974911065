import { useState, useEffect } from 'react';
import useDataBean from '../../../core/services/dataBean/useDataBean';
import { _AdquisitionBeneficiary, _AdquisitionProject, _PaymentMethod } from '../../../core/services/dataBean/EntityCatalogVdos';
import { PrintJson } from '../../theme/Component/PrintJson';
import { _single } from '../../../core/services/dataBean/EntityCatalog';



interface Props {
    idAdquisitionProject:number;
    idSelector: number;
    onChange: any;
    label?: string;
}
export const AdquisitionBeneficiarySelect = ({ idAdquisitionProject,idSelector, onChange, label }: Props) => {

    const service = useDataBean(_AdquisitionBeneficiary)
    const [lista, setLista] = useState<any>([])
    const [id, setID] = useState(0);
    useEffect(() => {
        getLista();
    }, [])

    useEffect(() => {
        if (idSelector >= 0) {
            setID(idSelector);
        }
    }, [])




    const setValue = (e: any) => {
        setID(parseInt(e));
        onChange(e);
    }



    const getLista = () => {

        const x = {
            "IDProject":idAdquisitionProject,
            "State": 1
        }
        
         {
            service.instance.getProjectBeneficiaryCatalogPorPropiedadesRender(x, null).then(
                (resp: any) => {
                    if (resp) {
                       //console.log(resp);
                        
                        setLista(resp)
                        _single.setCacheItem('beneficiary',resp);
                    }
                }
            )
        }

    }


    return (
        <>

            <label>{label ?? "Beneficiario"}</label>
            <select className="form-select" value={id} onChange={(e) => { setValue(e.target.value) }}>
                <option>...</option>
                {lista &&
                    lista.map((item: any, index: number) => {
                        return (
                            <option key={index} value={item.DataBeanProperties.IDProjectBeneficiary} >{item.DataBeanProperties.Description}</option>
                        )
                    })
                }
            </select>
        </>
    )
}