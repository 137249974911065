import { useEffect, useState } from "react";
import { BasicPage } from "../../theme/Component/BasicPage"
import { BasicTable } from "../../theme/Component/BasicTable"
import { Singleton } from "../../../core/services/Singleton";
import useDataBean from "../../../core/services/dataBean/useDataBean";
import { _AccountantIDType } from '../../../core/services/dataBean/EntityCatalog';
import { PrintJson } from "../../theme/Component/PrintJson";
import { ListMenu } from "../../theme/Component/ListMenu";
import { Modal } from "../../theme/Component/Modal";
import { DataBean } from "../../../core/services/dataBean/DataBean";
import { ModalConfirm } from "../../theme/Component/ModalConfirm";



export const AccountantIDType = () => {

    const single = Singleton.getInstance();
    const [modal, setModal] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalC, setModalC] = useState<any>({ name: 'Confirmación', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });

    const service = useDataBean(_AccountantIDType);

    const [lista, setLista] = useState<any>([]);

    useEffect(() => {

        getLista();

    }, [])


    const getLista = () => {


        service.instance.getAccountantIDTypeCatalog().then(
            (resp: any) => {
                setLista(resp);

            }
        )

    }

    const updateAccountantIDType = () => {
       //console.log(service.bean);
        service.update().then(
            (resp: any) => {
               //console.log(resp);
                getLista();
                setModal({ ...modal, "view": false });
            }
        )

    }


    const head = [
        { title: 'ID', property: 'IDAccountantIDType', visible: true },
        { title: 'Nombre', property: 'Name', visible: true },
        { title: 'Descripción', property: 'Description', visible: true },
        { title: 'Estructura del Código Contable', property: 'CodeFormat', visible: true },
        { title: 'Separador de Segmentos del Código', property: 'CodeSeparator', visible: true },
    ]
    const create = () => {
        setModal({ ...modal, "view": true });
        service.setBean({});
    }
    const deleteItem = () => {
        setModalC({ ...modalC, "view": false });
        service.deleteItemVoid(service.bean.IDAccountantIDType).then(
            (resp: any) => {
                getLista();
            }
        );

    }

    const listaBotones = () => {
        const botones = [
            { titulo: 'Agregar', icono: 'ri-file-add-line', visible: single.canCreate(), evento: create },
            { titulo: 'Editar', icono: 'ri-edit-2-line', visible: single.canEdit(), evento: () => { setModal({ ...modal, "view": true }) } },
            { titulo: 'Eliminar', icono: 'ri-delete-bin-line', visible: single.canDelete(), evento: () => { setModalC({ ...modalC, "view": true }) } },

        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }

    const iconButtonsItem = [
        { nombre: 'Agregar', visible: single.canCreate(), icono: 'ri-file-add-line', evento: create }
    ]

    return (
        <BasicPage titulo="Tipos de Catálogo de Cuentas Contables">
            <div className="row">
                <div className="col-md-12">
                    {/* <PrintJson json={service.instance.dataBeanList} /> */}
                    <BasicTable body={lista} head={head} rowSet={service.setBean} listButtons={listaBotones()} iconButtons={iconButtonsItem}></BasicTable>
                    <Modal modal={modal} updateModal={setModal} eventModal={updateAccountantIDType}>
                        <div className="row">
                            <div className="col-md-12">
                                <label>Nombre</label>
                                <input type="text" name="Name" className="form-control" value={service.bean.Name} onChange={service.handleChange} />
                            </div>
                            <div className="col-md-6">
                                <label>Estructura del Código Contable</label>
                                <input type="text" name="CodeFormat" className="form-control" value={service.bean.CodeFormat} onChange={service.handleChange} />
                            </div>
                            <div className="col-md-6">
                                <label>Separador de Segmentos del Código</label>
                                <input type="text" name="CodeSeparator" className="form-control" value={service.bean.CodeSeparator} onChange={service.handleChange} />
                            </div>
                            <div className="col-md-12">
                                <label>Descripción</label>
                                <textarea name="Description" className="form-control" value={service.bean.Description} onChange={service.handleChange}></textarea>
                            </div>
                        </div>
                    </Modal>
                    <ModalConfirm modal={modalC} updateModal={setModalC} eventModal={deleteItem}>
                        <strong>Eliminar elemento <br />{service.bean.Name}</strong>
                    </ModalConfirm>
                </div>
            </div>
        </BasicPage>
    )
}