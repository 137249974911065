/* eslint-disable @typescript-eslint/no-explicit-any */

import { env } from "../../../env";
import http from  "../api/api";


export class CustomService {

    private endPoint = env.REACT_APP_ENDPOINT + env.REACT_APP_DEPLOY + env.REACT_APP_SERVICE;
    private serviceName = '';

    constructor(serviceName: string) {
        this.serviceName = serviceName;
    }


    public securitymanager() {
        const dataObj = {
            ServiceName: "OrangeCore",
            MethodHash: "com.advantage.bean.securitymanager.PublicKeyInfo_getPublicKeyForApiRest_String",
            ArgumentList: [null],
        };
        const data = JSON.stringify(dataObj);
        return http.post(this.endPoint, data);
    }

    public postService(hash: string, argumentList: any) {

        const json = {
            ServiceName: this.serviceName,
            MethodHash: hash,
            ArgumentList: argumentList
        }
        const data = JSON.stringify(json);
        return http.post(this.endPoint, data);

    }
    public postServiceCustom(service: string, hash: string, argumentList: any) {
        const json = {
            ServiceName: service,
            MethodHash: hash,
            ArgumentList: argumentList
        }
        const data = JSON.stringify(json);

        return http.post(this.endPoint, data);

    }


}