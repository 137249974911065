import { useState, useEffect } from 'react';
import useDataBean from '../../../../core/services/dataBean/useDataBean';
import { _ConfigAdmin, _CostingCenter } from '../../../../core/services/dataBean/EntityCatalogVdos';
import { _Project } from '../../../../core/services/dataBean/EntityCatalog';
import { toast } from 'react-toastify';



interface Props {
    idSelector: number;
    onChange: any;
    label?: string;
    returnType?: "ID" | "Object";
}

export const SelectProject = ({ idSelector, onChange, label, returnType = "ID" }: Props) => {

    const { instance, setLista, lista } = useDataBean(_Project)
    const [id, setID] = useState(NaN);

    useEffect(() => {
        if (idSelector >= 0) {
            setID(idSelector)
        }
    }, [])

    useEffect(() => {
        getLista();
    }, [])

    const setValue = (e: string) => {
        const selectedId = parseInt(e);
        setID(selectedId);
        if (onChange) {
            const selectedItem = lista.find(item => item.DataBeanProperties.IDProject == e);
            onChange(returnType === "Object" ? selectedItem?.DataBeanProperties : selectedId);
        }
    };

    const getLista = () => {
        instance.getProjectCatalog(null).then(
            (resp: any) => {
                if (resp) {
                    setLista(resp);
                }
            }
        ).catch(err => { toast.error(err) })
    }

    return (
        <>
            <label>{label ?? "Proyecto"}</label>
            <select className="form-select" value={id} onChange={(e) => { setValue(e.target.value) }}>
                <option value={NaN}>...</option>

                {lista &&
                    lista.map((item: any, index: number) => {
                        return (
                            <option key={index} value={item.DataBeanProperties.IDProject} >{item.DataBeanProperties.Code} - {item.DataBeanProperties.Name}</option>
                        )
                    })
                }
            </select>
        </>
    )
}