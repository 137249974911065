import React, { useEffect, useState } from 'react'
import { BasicPage } from '../theme/Component/BasicPage'
import { BasicTable } from '../theme/Component/BasicTable'
import { ContractService } from '../../core/services/ContractService'
import { Singleton } from '../../core/services/Singleton'
import { toast } from 'react-toastify'
import { ListMenu } from '../theme/Component/ListMenu'
import { ModalConfirm } from '../theme/Component/ModalConfirm'
import { Modal } from '../theme/Component/Modal'
import { SelectTipocomite } from '../bpm/config/Componentes/SelectTipocomite'
import { SelectrolesSST } from '../bpm/config/Componentes/SelectrolesSST'
import { SelectorPlanSGSST } from '../bpm/config/Componentes/SelectorPlanSGSST'
import { AccountEditor } from '../editor/AccountEditor'

const _contractService = new ContractService()
const _single = Singleton.getInstance()

export interface CommitteSST {
    IDCommitteeSST?: number
    IDAccount: number,
    IDCommitteeType: number,
    IDEmployee: number,
    IDPlanSGSST: number,
    IDRoleSST: number,
    State: number

}

export const CommiteSST = () => {

    const [modal, setModal] = useState({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });
    const [modalConfirm, setModalConfirm] = useState<any>({ name: 'Confirmar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });
    const [comites, setComites] = useState([])
    const [typeCommite, setTypeCommite] = useState(0)
    const [rolSST, setRolSST] = useState(0)
    const [planSST, setPlanSST] = useState(0)
    const [idAccount, setIdAccount] = useState(0)

    const constructor: CommitteSST = {
        IDAccount: null,
        IDCommitteeType: null,
        IDPlanSGSST: null,
        IDRoleSST: null,
        IDEmployee: _single.getAccountID(),
        State: 1
    }

    const [bean, setBean] = useState(constructor)

    useEffect(() => {
        getLista()
    }, [])

    useEffect(() => {
        getLista()
    }, [typeCommite, rolSST, planSST])

    const getLista = () => {
        _single.spinner(true)
        _contractService.getCommitteeSSTCatalogPorPropiedadesRender({
            IDCommitteeType: typeCommite,
            IDPlanSGSST: planSST
        })
            .subscribe((resp: any) => {
                _single.spinner(false)
               //console.log(resp);
                if (resp.DataBeanProperties.ObjectValue) {
                    let tmp = resp.DataBeanProperties.ObjectValue
                    tmp.forEach((element: any) => {
                        element.DataBeanProperties.Names = (
                            element.DataBeanProperties.Name1 + " " +
                            element.DataBeanProperties.Name2 + " " +
                            element.DataBeanProperties.Surname1 + " " +
                            element.DataBeanProperties.Surname2)
                    });

                    setComites(tmp)
                } else {
                    toast.error(resp.DataBeanProperties.ErrorMessage)
                }
            })
    }

    const updateModuleSSTT = (IDCommitteeSST: number | null, State: number = 1) => {
        if (idAccount !== null) {
            _single.spinner(true)
            _contractService.updateCommitteeSST({
                ...bean, IDCommitteeSST, State,
                IDAccount: idAccount,
                IDCommitteeType: typeCommite,
                IDRoleSST: rolSST,
                IDPlanSGSST: planSST
            })
                .subscribe((resp: any) => {
                    _single.spinner(false)
                   //console.log(resp);
                    if (resp.DataBeanProperties.ObjectValue) {
                        setModal({ ...modal, view: false })
                        toast.success("Se completo la acción correctamente!")
                        getLista()
                    } else {
                        toast.error(resp.DataBeanProperties.ErrorMessage)
                    }
                })
        } else {
            toast.error('Debe seleccionar un encargado')
        }
    }

    const deleteModuleSSTT = () => {
        updateModuleSSTT(bean.IDCommitteeSST, 2)
        setModalConfirm({ ...modalConfirm, view: false })
    }

    const setRowBean = (bean: any) => {
       //console.log("Row : ", bean);
        setBean(bean);
    }

    const head = [
        { title: 'ID', property: 'IDCommitteeSST' },
        { title: 'Encargado', property: 'Names' },
        { title: 'Rol', property: 'RoleName' },
    ]

    const listaBotones = () => {
        const botones = [
            { titulo: 'Editar', icono: 'ri-file-list-line', visible: true, evento: () => { setModal({ ...modal, "view": true }) } },
            { titulo: 'Eliminar', icono: 'ri-file-user-line', visible: true, evento: () => { setModalConfirm({ ...modalConfirm, "view": true }) } },
        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }

    function calcCanCreate(): boolean {
        if (_single.canCreate &&
            planSST !== null &&
            rolSST !== null &&
            typeCommite !== null) {
            return true
        }
        return false
    }

    const iconButtonsItem = [
        { nombre: 'Agregar', visible: calcCanCreate(), icono: 'ri-file-add-line', evento: () => { setBean(undefined); setIdAccount(null); setModal({ ...modal, view: true }) } }
    ]

    return (
        <>
            <BasicPage titulo="Comité SSTT">
                <div className="row">
                    <div className="col-12 d-flex gap-3">
                        <div className='w-100'>
                            <SelectorPlanSGSST idSelector={planSST}
                                onChange={(e: any) => { setPlanSST(e) }} />

                        </div>

                        <div className='w-100'>
                            <SelectTipocomite titulo="Tipos de comite" idSelector={typeCommite}
                                onChange={(e: any) => { setTypeCommite(e) }} />
                        </div>
                    </div>
                    <div className="col-md-12 p-2">
                        <BasicTable listButtons={listaBotones()} iconButtons={iconButtonsItem} head={head} body={comites} rowSet={setRowBean}></BasicTable>
                    </div>
                </div>
            </BasicPage>

            {modalConfirm.view &&
                <ModalConfirm modal={modalConfirm} updateModal={setModalConfirm} eventModal={deleteModuleSSTT}>
                </ModalConfirm>
            }

            {modal.view &&
                <Modal modal={modal} updateModal={setModal} eventModal={() => { updateModuleSSTT(bean?.IDCommitteeSST, 1) }}>
                    <div className="row">
                        <div className="col-md-12">
                            <AccountEditor label='Encargado' idAccount={idAccount} onChange={(e) => setIdAccount(e.IDAccount)} />
                        </div>
                        <div className="col-md-12">
                            <SelectrolesSST titulo="Roles SST" idSelector={rolSST}
                                onChange={(e: any) => { setRolSST(e) }} />
                        </div>
                    </div>
                    <div></div>
                </Modal>
            }
        </>
    )
}
