import { useEffect, useState } from "react";

import useDataBean from "../../../core/services/dataBean/useDataBean";
import { CashReimbursementServices } from "../cellEditor/CashDesk/CashReimbursement/CashReimbursement-service";
import { toast } from "react-toastify";
import { Singleton } from "../../../core/services/Singleton";
import { ListMenu } from "../../theme/Component/ListMenu";
import { BasicTable, MASK_DATE, MASK_MONEY, MASK_NUMBER } from "../../theme/Component/BasicTable";
import { BasicPage } from "../../theme/Component/BasicPage";
import { Modal } from "../../theme/Component/Modal";

import { ModalConfirm } from "../../theme/Component/ModalConfirm";
import { _AccountantConcept, _FiscalResponsability, _FiscalResponsabilityType } from "../../../core/services/dataBean/EntityCatalog";

import { _AdquisitionProject, _ArticleClassType, _MeasurementUnitCode, _Project } from "../../../core/services/dataBean/EntityCatalogVdos";
import { SelectAdquisitionArticleConstants } from "../../contract/select/SelectAdquisitionArticleConstants";
import useModal, { MODAL_md, MODAL_SM } from "../../theme/Component/hooks/useModal";
import { InputDate } from "../../theme/Input/InputDate";
import { AccountEditor } from "../../editor/AccountEditor";
import { AdquisitionBeneficiary } from "../AdquisitionBeneficiary/AdquisitionBeneficiary";
import { PaymentMethodSelect } from "../../Talentohumano/PaymentMethod/PaymentMethodSelect";
import { ManagementTypeSelect } from "./ManagementTypeSlector";
import { IDAdquisitionProject } from "./AdquisitionProjectSelect";
import { BasicSwitch } from "../../theme/Component/BasicSwitch";
import { SelectProjectID } from "../../admin/configuracion/ProjectID/SelectProjectID";




export const AdquisitionProject = () => {

    const Project = useDataBean(_Project);

    const single = Singleton.getInstance();
    const [lista, setLista] = useState<any>([]);
    const [beneficiario, setbeneficiario] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modal, setModal] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalC, setModalC] = useState<any>({ name: '', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });
    const [swich, setSwich] = useState<boolean>(null)
    const modalConfirm = useModal("Orden", MODAL_SM);
    const modalEditar = useModal("Agregar Proyecto", MODAL_md);


    useEffect(() => {
        getLista()

    }, [])


    const getLista = () => {

        Project.instance.getProjectCatalogPorPropiedadesRender({ State: 1 }, null).then(
            (resp: any) => {
                if (resp) {
                    setLista(resp)
                }

            }
        )

    }



    const create = () => {
        Project.setBean({
            State: 1,
        });
        setSwich(false);
        modalEditar.setModal({ ...modalEditar.modal, "view": true });
    }

    const editaItem = () => {
       //console.log(Project.bean);
        Project.update().then(
            (resp: any) => {
                getLista();
                modalEditar.setModal({ ...modalEditar.modal, "view": false });
            }
        ).catch(err => {
            toast.error(err);
        })

    }
    const deleteItem = () => {
        Project.bean.State = 2;
       //console.log(Project.bean);
        editaItem();
        modalConfirm.close();
    }




    const head = [
        { title: 'ID', property: 'IDProject', visible: true, mask: MASK_NUMBER },
        { title: 'Nombre', property: 'Name', visible: true },
        { title: 'Fecha Inicial', property: 'InitDate', visible: true },
        { title: 'Fecha Final', property: 'UptoDate', visible: true },
        { title: 'Tercero', property: 'AccountName', visible: true },
        { title: 'Valor', property: 'TotalValue', visible: true, mask: MASK_MONEY },
        { title: 'Objeto', property: 'ObjectText', visible: true },
        { title: 'Descripción', property: 'Description', visible: true },
        { title: 'Porcentaje de Administración', property: 'AdminFee', visible: true },
        { title: 'Metodo de pago', property: 'PaymentMethodName', visible: false },



    ]

    const iconButtonsItem = [
        { nombre: 'Agregar', visible: single.canCreate(), icono: 'ri-file-add-line', evento: create }
    ]
    const listaBotones = () => {
        const botones = [

            { titulo: 'Editar', icono: 'ri-edit-2-line', visible: single.canEdit(), evento: () => { modalEditar.setModal({ ...modalEditar.modal, "view": true }) } },
            { titulo: 'Eliminar', icono: 'ri-delete-bin-line', visible: single.canDelete(), evento: () => { setModalC({ ...modalC, "view": true }) } },
            { titulo: 'Beneficiarios', icono: 'ri-edit-2-line', visible: single.canDelete(), evento: () => { setbeneficiario({ ...beneficiario, "view": true }) } },

        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }



    return (
        <BasicPage titulo="Proyectos">
            <div className="row">

                <div className="col-md-12">

                    <BasicTable body={lista} head={head} rowSet={Project.setBean} listButtons={listaBotones()} iconButtons={iconButtonsItem}></BasicTable>
                    <Modal modal={modalEditar.modal} updateModal={modalEditar.setModal} eventModal={editaItem}>
                        <>
                            <div className="row">
                                <div className="col-md-6">
                                    <ManagementTypeSelect idSelector={Project.bean.ManagementType} onChange={(e) => { Project.setBean({ ...Project.bean, "ManagementType": e }) }} />
                                </div>
                                <div className="col-md-6">
                                    <label>Nombre</label>
                                    <input type="text" name="Name" className="form-control" value={Project.bean.Name} onChange={Project.handleChange} />

                                </div>
                                <div className="col-md-6">
                                    <label>Valor</label>
                                    <input type="text" name="TotalValue" className="form-control" value={Project.bean.TotalValue} onChange={Project.handleChange} />
                                </div>
                                <div className="col-md-6">
                                    <AccountEditor label="Tercero" idAccount={Project.bean.IDAccount} onChange={(e: any) => { Project.setBean({ ...Project.bean, "IDAccount": e.IDAccount }) }}></AccountEditor>
                                </div>

                                <div className="col-md-12">
                                    <label>Objeto</label>
                                    <textarea name="ObjectText" className="form-control" value={Project.bean.ObjectText} onChange={Project.handleChange} />
                                </div>
                                <div className="col-md-12">
                                    <label>Descripción</label>
                                    <textarea name="Description" className="form-control" value={Project.bean.Description} onChange={Project.handleChange} />
                                </div>
                                <div className="col-md-6">
                                    <InputDate notDate label="Fecha Inicial" name="InitDate" value={Project.bean.InitDate} setDate={(e) => { Project.setBean({ ...Project.bean, "InitDate": e }) }} />
                                </div>
                                <div className="col-md-6">
                                    <InputDate notDate label="Fecha Final" name="accountantDate" value={Project.bean.UptoDate} setDate={(e) => { Project.setBean({ ...Project.bean, "UptoDate": e }) }} />
                                </div>
                                <div className="col-md-6">
                                    <label>Porcentaje de Administración</label>
                                    <input type="number" name="AdminFee" className="form-control" value={Project.bean.AdminFee} onChange={Project.handleChange} />
                                </div>
                                <div className="col-6">
                                    <PaymentMethodSelect idSelector={Project.bean.IDPaymentMethod} onChange={(e) => { Project.setBean({ ...Project.bean, "IDPaymentMethod": e }) }} />
                                </div>
                                <div className="col-md-12">
                                    <SelectProjectID projectIDLn={Project.bean.IDLnProjectID} edit={false} onChange={(e: any) => { Project.setBean({ ...Project.bean, "IDLnProjectID": e.IDLn }) }} />
                                </div>

                                <div className="col-md-6 ">
                                    <label>Agregar Proyecto Padre ?</label>
                                    <BasicSwitch estado={swich} eventChange={(e) => { setSwich(e) }} />
                                </div>
                                {swich && (
                                    <div className="col-md-12">
                                        <IDAdquisitionProject
                                            idSelector={Project.bean.IDAdquisitionProjectFather}
                                            onChange={(e) => {
                                                Project.setBean({
                                                    ...Project.bean,
                                                    "IDAdquisitionProjectFather": e
                                                })
                                            }}
                                        />
                                    </div>
                                )}



                                {/* <div className="col-md-12">
                                    <label>Justificación</label>
                                    <textarea name="Justification" className="form-control" value={AdquisitionProject.bean.Justification} onChange={AdquisitionProject.handleChange} />
                                </div> */}

                            </div>

                        </>
                    </Modal>
                    <Modal modal={beneficiario} updateModal={setbeneficiario} >
                        <>
                            <AdquisitionBeneficiary IDProject={Project.bean.IDProject} />

                        </>
                    </Modal>


                    <ModalConfirm modal={modalC} updateModal={setModalC} eventModal={deleteItem}>
                        <strong>Eliminar item</strong>
                    </ModalConfirm>
                </div>

            </div>
        </BasicPage>
    )
}