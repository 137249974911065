import { useEffect, useState } from "react";
import useDataBean from "../../../core/services/dataBean/useDataBean";
import { Singleton } from "../../../core/services/Singleton";
import { _ConfigAdmin } from "../../../core/services/dataBean/EntityCatalogVdos";
import { toast } from "react-toastify";
import { BasicPage } from "../../theme/Component/BasicPage";
import { BsFillArrowRightCircleFill } from "react-icons/bs";
import { Modal } from "../../theme/Component/Modal";
import { SelectonfigAdminbycargo } from "../../admin/configuracion/functionalID/Planta/SelectconfigAdminbycargo";
import { FunctionalAreaListSelect } from "./FunctionalAreaListSelect";
import { BasicTable, MASK_DATE, MASK_NUMBER } from "../../theme/Component/BasicTable";
import { ListMenu } from "../../theme/Component/ListMenu";
import { BasicButton } from "../../theme/Component/BasicButton";
import { _FunctionalArea } from "../../../core/services/dataBean/EntityCatalog";
import { FunctionalAreaComp } from "./FunctionalAreaComp";
import { SelectFunctionalID } from "../../admin/configuracion/functionalID/SelectFunctionalID";







export const FunctionalAreaVdos = () => {

    const FunctionalArea = useDataBean(_ConfigAdmin);
    const FunctionalAreabyfilter = useDataBean(_FunctionalArea);
    const single = Singleton.getInstance();
    const [lista, setLista] = useState<any>([]);
    const [name, setName] = useState<string>(null);
    const [modal, setModal] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalC, setModalC] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [state, setState] = useState<number>(3);
    const [contracState, setcontracState] = useState<number>(null);
    const [appointment, setAppointment] = useState<number>(null);
    const [funtional, setFuntional] = useState<number>(null);
    const [idFunctionalID, setIDFunctionalID] = useState<any>(0);

    useEffect(() => {

    }, [])




    const getLista = () => {
        FunctionalAreabyfilter.instance.getFunctionalAreaCatalog(state, name, funtional, appointment, contracState).then(
            (resp) => {
               //console.log(resp.length)

                setLista(resp)

            }
        )
    }

    const head = [
        { title: 'ID', property: 'IDLn', mask: MASK_NUMBER, visible: true },
        { title: 'Código', property: 'Code', mask: MASK_NUMBER, visible: true },
        { title: 'Nombre', property: 'Name', visible: true },
        { title: 'Descripción', property: 'Description', visible: true },
        { title: 'Nivel Jerárquico', property: 'EntailmentName', visible: true },
        { title: 'Nivel Salarial', property: 'AppointmentName', visible: true },
        { title: 'Ubicación Geográfica de la Plaza', property: 'SiteIDName', visible: true },
        { title: 'Manual de Perfil y Funciones del Cargo', property: 'AppointmentManualName', visible: true },
        { title: 'Centro de Costo', property: 'CostingCenterCode', visible: true },
        { title: 'Riesgo Laboral', property: 'ARPRiskName', visible: true },
        { title: 'Unidad Organizacional', property: 'FunctionalIDName', visible: true },
        { title: 'Estado', property: 'StateName', visible: true },
        { title: 'IDContrato Laboral', property: 'IDContract', mask: MASK_NUMBER, visible: true },
        { title: 'Empleado', property: 'AccountName', visible: true },
        { title: 'Estado del Contrato', property: 'ContractStateName', visible: true },
        { title: 'Creado por', property: 'EmployeeName', visible: true },
        { title: 'Fecha de Creación', property: 'Since', mask: MASK_DATE, visible: true },
        { title: 'Última actualización', property: 'LastUpdateEmployeeName', visible: true },
        { title: 'Fecha Última Actualización', property: 'LastUpdate', visible: true },
    ]


    const editaItem = () => {
        FunctionalArea.instance.updateFunctionalAreaTreeToFunctionalID(FunctionalArea.bean.IDFunctionalArea, idFunctionalID).then(
            (resp: any) => {

                setModal({ ...modal, "view": false });
               //console.log(resp)
            }
        ).catch(err => {
            toast.error(err);
        })
    }

    const create = () => {

        setModal({ ...modal, "view": true });
    }


    const listaBotones = () => {
        const botones = [
            { titulo: 'Migrar Plazas Laborales a Areas Funcionales', icono: 'ri-file-list-line', visible: single.canEdit(), evento: () => { setModal({ ...modal, "view": true }) } },

        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }


    return (
        <>

            <BasicPage titulo="Migrar Plazas Laborales a Areas Funcionales">
                <div className="row">
                    <div className="col-md-6">
                        <label>Nombre de la plaza</label>
                        <input type="text" className="form-control" value={name} onChange={(e) => { setName(e.target.value) }} /></div>
                    <div className="col-6 mt-4">
                        <BasicButton icon="" eventClick={getLista}>Buscar</BasicButton>
                    </div>


                    <div className="col-md-12">



                        <BasicTable body={lista} head={head} rowSet={FunctionalArea.setBean} listButtons={listaBotones()} ></BasicTable>


                        <Modal modal={modal} updateModal={setModal} eventModal={editaItem}>
                            <>


                                {FunctionalArea.bean.IDFunctionalArea} - {idFunctionalID}
                                <SelectFunctionalID label="Área Funcioal" onChange={(e) => { setIDFunctionalID(e.IDLn) }} />


                                {/* <FunctionalAreaComp functionaIDArea={FunctionalArea.bean.IDFunctionalArea} /> */}



                            </>
                        </Modal>

                    </div>

                </div>
            </BasicPage>

        </>

    )
}