import { DataBean } from "../DataBean";


export class EmployeeRevenue extends DataBean {

    public async getEmployeeRevenue(accountID:any,year: any,month:any,profile:any): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_getEmployeeRevenue_Number_Number_Number_Number", [accountID,year,month,profile]);
    }

    public async deleteEmployeeRevenue(idRevenue:any): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "Integer_deleteEmployeeRevenue_Number", [idRevenue]);
    }

}