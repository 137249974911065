import { DataBean } from "../../DataBean";



export class ContractRole extends DataBean {


    public async getContractRoleCatalogPorPropiedad(nombrePropiedad: any, value: any, maxRows: any): Promise<any> {
        return this.getCustomService("FinanceCore", "java.util.List_getContractRoleCatalogPorPropiedad_String_Object_Number", [nombrePropiedad, value, maxRows]);
    }
    public async getContractRolesRender(maxRows: number,props:any): Promise<any> {
        return this.getCustomService("FinanceCore", "java.util.List_getContractRolesRender_Number_java.util.Map", [maxRows,props]);
    }
    public async deleteContractRole(bean): Promise<any> {
        return this.getCustomService("FinanceCore", "boolean_deleteContractRole_Number", [
            {
                "DataBeanProperties": bean,
                "DataBeanName": "com.orange.control.FinanceCore"
            }
        ]);
    }
}