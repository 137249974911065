import { useEffect, useState } from "react";
import { _EmployeeIncapacity } from "../../../../core/services/dataBean/EntityCatalog";
import useDataBean from "../../../../core/services/dataBean/useDataBean";
import { Singleton } from "../../../../core/services/Singleton";
import { ListMenu } from "../../../theme/Component/ListMenu";
import { BasicPage } from "../../../theme/Component/BasicPage";
import { BasicTable, MASK_BOOLEAN_ICON, MASK_DATE, MASK_NUMBER, MASK_TEXT_NOWRAP } from "../../../theme/Component/BasicTable";
import { Modal } from "../../../theme/Component/Modal";
import { AccountEditor } from "../../../editor/AccountEditor";
import { YearSelector } from "../../../../core/shared/inputs/YearSelector";
import { PayrollMonthsConstantsEditor } from "../../ParametrosTalentoHumano/EmployeeBonus/Editors/PayrollMonthsConstants.editor";
import { ModalConfirm } from "../../../theme/Component/ModalConfirm";
import { toast } from "react-toastify";
import { InputDate } from "../../../theme/Input/InputDate";
import { BasicSwitch } from "../../../theme/Component/BasicSwitch";
import { IncapacityTypeConstantsEditor } from "./IncapacityEditors/IncapacityTypeConstants.editor";
import { LiquidateMonthConstantsEditor } from "./IncapacityEditors/LiquidateMonthConstants.editor copy";
import { BasicButton } from "../../../theme/Component/BasicButton";

export const EmployeeIncapacity = () => {

    const employeeIncapacity = useDataBean(_EmployeeIncapacity);
    const single = Singleton.getInstance();
    const [IdAcoount, setIdAcoount] = useState<number>(null);
    const [montedit, setMontedit] = useState<number>(null);
    const [year, setYear] = useState<number>(single.getCurrenYear());
    const [type, setType] = useState<number>(null);
    const [lista, setLista] = useState<any>([]);
    const [modal, setModal] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalC, setModalC] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });
    const [missingFields, setMissingFields] = useState<string[]>([]);
    const [rows, setRows] = useState<number>(15);

    // useEffect(() => {
    //     if (year > 0 && montedit && montedit >= 0) {
    //         getLista();
    //     }

    // }, [IdAcoount, year, montedit, type])

    const getLista = () => {
        employeeIncapacity.instance.getEmployeeIncapacities(IdAcoount, year, montedit, type).then(
            (resp: any) => {
                if (resp) {
                    let List = resp;
                    List.forEach((e: any) => {
                        const fromDate = e.DataBeanProperties.FromDate ? new Date(e.DataBeanProperties.FromDate) : null;
                        const uptoDate = e.DataBeanProperties.UptoDate ? new Date(e.DataBeanProperties.UptoDate) : null;


                        if (fromDate && uptoDate) {
                            const diffTime = Math.abs(uptoDate.getTime() - fromDate.getTime());
                            const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
                            e.DataBeanProperties.FechaTotalDays = diffDays + 1;
                        } else {

                            e.DataBeanProperties.FechaTotalDays = 0;
                        }
                    });

                   //console.log("Lista con días calculados:", List);

                    setLista(List);
                }
            }
        )
    }

    const create = () => {
        employeeIncapacity.setBean({
            IDEmployee: single.getAccountID(),
            Year: single.getCurrenYear(),
            Days_90: false,
            Days_180: false,
        });
        setModal({ ...modal, "view": true });
    }

    const deleteItem = () => {
        employeeIncapacity.instance.deleteEmployeeIncapacity(employeeIncapacity.bean.IDIncapacity).then(
            (resp: any) => {
                getLista();
                setModalC({ ...modalC, "view": false });
            }
        )
    }

    // const editaItem = () => {
    //     employeeIncapacity.update().then(
    //         (resp: any) => {
    //             getLista();
    //             setModal({ ...modal, "view": false });
    //         }
    //     ).catch(err => {
    //         toast.error(err);
    //     })

    // }


    const editaItem = () => {
        if (!employeeIncapacity.bean.Description) {
            toast.error("El campo 'Diagnóstico' es obligatorio.");
            return;
        }

        employeeIncapacity.update().then(
            (resp: any) => {
                getLista();
                setModal({ ...modal, "view": false });
            }
        ).catch(err => {
            toast.error(err);
        });
    }


    const head = [
        { title: 'ID', property: 'IDIncapacity', visible: true, mask: MASK_NUMBER },
        { title: 'Empleado', property: 'AccountName', visible: true },
        { title: 'Tipo', property: 'TypeName', visible: true },
        { title: 'Inicio en', property: 'FromDate', visible: true, mask: MASK_DATE },
        { title: 'Terminación en', property: 'UptoDate', visible: true, mask: MASK_DATE },
        { title: 'Año a aplicar', property: 'Year', visible: true, mask: MASK_NUMBER },
        { title: 'Mes a aplicar', property: 'MonthName', visible: true },
        { title: 'A Cargo de', property: 'Third', visible: true },
        { title: 'Diagnóstico', property: 'Description', visible: true, },
        { title: 'Prorroga', property: 'Extending', visible: true },
        { title: 'Hospitalización', property: 'Hospitalization', visible: true },
        { title: '+90 Días', property: '90Days', visible: true },
        { title: '+180 Días', property: '180Days', visible: true },
        { title: 'Definir IBC', property: 'ApplyIBC', visible: true },
        { title: 'IBC', property: 'Ibc', visible: true },
        { title: 'IBC a Aplicar', property: 'IbcToApply', visible: true },
        { title: 'Funcionario', property: 'EmployeeName', visible: true },
        { title: 'Duración Incapacidad', property: 'FechaTotalDays', visible: false },
    ]

    const validateYear = () => {
        ////console.log(employeeIncapacity.bean.Year)
        if (employeeIncapacity.bean.Year >= single.getCurrenYear()) {
            validateData()
        } else {
            toast.error("El Año a aplicar es inferior al actual")
        }
    }

    const validateData = () => {
        if (validateForm() > 0) {
            toast.error("Faltan campos obligatorios por completar");
            return;
        } else {
            editaItem();
        }
    }

    const validateForm = () => {
        const requiredFields = [
            "Month", "IDAccount", "Year", "Type", "FromDate", "UptoDate"
        ];

        let missing = [];
        for (let field of requiredFields) {
            if (!employeeIncapacity.bean[field]) {
                missing.push(field);
            }
        }

        //console.log(missing)
        setMissingFields(missing);
        return missing.length
    };

    const validateGetLista = () => {
        if (!year) {
            toast.error("Año no valido")
        } else if (isNaN(montedit)) {
            toast.error("Mes no valido")
        } else if (isNaN(rows) || rows <= 0) {
            toast.error("El número de registros debe ser mayor a 0");
        } else {
            getLista();
        }
    }

    const listaBotones = () => {
        const botones = [
            {
                titulo: 'Editar Incapacidad', icono: 'ri-file-list-line', visible: true, evento: () => {
                    setModal({ ...modal, "name": "Editar Incapacidad Laboral", "view": true })
                    employeeIncapacity.setBean({
                        ...employeeIncapacity.bean,
                        IDEmployee: single.getAccountID(),
                    });
                }
            },
            { titulo: 'Eliminar Incapacidad', icono: 'ri-delete-bin-line', visible: single.canDelete(), evento: () => { setModalC({ ...modalC, "view": true }) } },
        ]

        return (
            <ListMenu listaBotones={botones} />
        )
    }

    const iconButtonsItem = [
        { nombre: 'Agregar', visible: single.canCreate(), icono: 'ri-file-add-line', evento: create }
    ]

    return (
        <>
            <BasicPage titulo="Ingresar Incapacidades Laborales">
                <div className="row mb-2">
                    <div className="col-md-3">
                        <AccountEditor label="Empleado" idAccount={IdAcoount} onChange={(e) => { setIdAcoount(e.IDAccount) }} />
                    </div>
                    <div className="col-md-2">
                        <YearSelector value={year} onChange={setYear} />
                    </div>
                    <div className="col-md-2">
                        <LiquidateMonthConstantsEditor label="Mes" idSelector={montedit} onChange={(e) => { setMontedit(e) }} />
                    </div>
                    <div className="col-3">
                        <IncapacityTypeConstantsEditor idSelector={type} onChange={(e) => { setType(e) }} label="Tipo de Incapacidad" />
                    </div>
                    <div className="col-2">
                        <label># de registros por trabla</label>
                        <input className="form-control" value={rows} onChange={(e) => { setRows(parseInt(e.target.value)) }} />
                    </div>
                    <div className="col-2 d-flex">
                        <div className="row d-flex align-content-end">
                            <BasicButton icon="ri-search-line" eventClick={validateGetLista} >Buscar</BasicButton>
                        </div>
                    </div>
                </div>

                <BasicTable customRows={rows} iconButtons={iconButtonsItem} listButtons={listaBotones()} head={head} body={lista} rowSet={employeeIncapacity.setBean} />
            </BasicPage>

            {modal.view &&
                <Modal modal={modal} updateModal={setModal} eventModal={() => { validateYear() }} >
                    <div className="row">
                        <div className="col-md-6">
                            <label className={`${missingFields.includes('IDAccount') ? 'has-error' : ''}`}>Empleado</label>
                            <AccountEditor label=" " idAccount={employeeIncapacity.bean.IDAccount} onChange={(e) => { employeeIncapacity.setBean({ ...employeeIncapacity.bean, "IDAccount": e.IDAccount }) }} />
                        </div>
                        <div className="col-6">
                            <label className={`${missingFields.includes('Type') ? 'has-error' : ''}`}>Tipo de Incapacidad</label>
                            <IncapacityTypeConstantsEditor idSelector={employeeIncapacity.bean.Type} onChange={(e) => { employeeIncapacity.setBean({ ...employeeIncapacity.bean, "Type": e }) }} label=" " />
                        </div>
                        <div className="col-6">
                            <label className={`${missingFields.includes('FromDate') ? 'has-error' : ''}`}>Inicio en</label>
                            <InputDate label=" " name="FromDate" value={employeeIncapacity.bean.FromDate} setDate={(e: any) => { employeeIncapacity.setBean({ ...employeeIncapacity.bean, 'FromDate': e }) }} />
                        </div>
                        <div className="col-6">
                            <label className={`${missingFields.includes('UptoDate') ? 'has-error' : ''}`}>Terminación en</label>
                            <InputDate label=" " name="UptoDate" value={employeeIncapacity.bean.UptoDate} setDate={(e: any) => { employeeIncapacity.setBean({ ...employeeIncapacity.bean, 'UptoDate': e }) }} />
                        </div>
                        <div className="col-6">
                            <label className={`${missingFields.includes('Year') ? 'has-error' : ''}`} >Año a Aplicar</label>
                            <input type="number" className="form-control" name="Year" value={employeeIncapacity.bean.Year} onChange={employeeIncapacity.handleChange} />
                        </div>
                        <div className="col-6">
                            <label className={`${missingFields.includes('Month') ? 'has-error' : ''}`} >Mes a Aplicar</label>
                            <LiquidateMonthConstantsEditor label=" " idSelector={employeeIncapacity.bean.Month} onChange={(e) => { employeeIncapacity.setBean({ ...employeeIncapacity.bean, "Month": e }) }} />
                        </div>

                        <div className="col-6">
                            <AccountEditor label="A Cargo de" idAccount={employeeIncapacity.bean.Third} onChange={(e) => { employeeIncapacity.setBean({ ...employeeIncapacity.bean, "Third": e.IDAccount }) }} />
                        </div>
                        <div className="col-6 d-flex">
                            <div className="row d-flex align-content-center">
                                <BasicSwitch label="Prorroga" estado={employeeIncapacity.bean.Extending} eventChange={(e) => { employeeIncapacity.setBean({ ...employeeIncapacity.bean, "Extending": e }) }} />
                            </div>
                        </div>
                        <div className="col-6 d-flex">
                            <div className="row d-flex align-content-center">
                                <BasicSwitch label="Hospitalización" estado={employeeIncapacity.bean.Hospitalization} eventChange={(e) => { employeeIncapacity.setBean({ ...employeeIncapacity.bean, "Hospitalization": e }) }} />
                            </div>
                        </div>
                        <div className="col-6 d-flex">
                            <div className="row d-flex align-content-center">
                                <BasicSwitch label="+90 Días" estado={employeeIncapacity.bean.Days_90} eventChange={(e) => { employeeIncapacity.setBean({ ...employeeIncapacity.bean, "Days_90": e }) }} />
                            </div>
                        </div>
                        <div className="col-6 d-flex">
                            <div className="row d-flex align-content-center">
                                <BasicSwitch label="+180 Días" estado={employeeIncapacity.bean.Days_180} eventChange={(e) => { employeeIncapacity.setBean({ ...employeeIncapacity.bean, "Days_180": e }) }} />
                            </div>
                        </div>
                        <div className="col-6">
                            <label >IBC</label>
                            <input type="number" className="form-control" name="Ibc" value={employeeIncapacity.bean.Ibc} onChange={employeeIncapacity.handleChange} />
                        </div>
                        <div className="col-6 d-flex">
                            <div className="row d-flex align-content-center">
                                <BasicSwitch label="Definir IBC" estado={employeeIncapacity.bean.ApplyIBC} eventChange={(e) => { employeeIncapacity.setBean({ ...employeeIncapacity.bean, "ApplyIBC": e }) }} />
                            </div>
                        </div>
                        {employeeIncapacity.bean.ApplyIBC &&
                            <div className="col-6">
                                <label >IBC a Aplicar</label>
                                <input type="number" className="form-control" name="IbcToApply" value={employeeIncapacity.bean.IbcToApply} onChange={employeeIncapacity.handleChange} />
                            </div>
                        }


                        <div className="col-12">
                            <label>Diagnóstico:</label>
                            <textarea name="Description" className="form-control" value={employeeIncapacity.bean.Description} onChange={employeeIncapacity.handleChange} />
                        </div>
                    </div>
                </Modal>
            }

            <ModalConfirm modal={modalC} updateModal={setModalC} eventModal={deleteItem}>
                <strong>Eliminar Incapacidad de {employeeIncapacity.bean.AccountName}</strong>
            </ModalConfirm>
        </>
    )
}