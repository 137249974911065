import { useEffect, useState } from "react";
import { ContractService } from "../../../../../core/services/ContractService";
import { Singleton } from "../../../../../core/services/Singleton";
import { InputDate } from "../../../../theme/Input/InputDate";
import { PrintJson } from "../../../../theme/Component/PrintJson";
import { DataBeanProperties, ObjectValue } from '../../../../../core/services/model/server-response.interface';
import { BasicButton } from "../../../../theme/Component/BasicButton";

interface Props{
    ublInvoice:any;
}
export const GenerarContabilizacion =({ublInvoice}:Props)=>{

    const single = Singleton.getInstance();
    const contracService = new ContractService();
    const [lista, setLista] = useState<any>([]);
    const [fecha,setFecha] = useState<any>(single.getCurrenDate());

    useEffect(()=>{

    },[])

    const createAccountantDocumentForUBLInvoice=()=>{
        single.spinner(true);
        contracService.createAccountantDocumentForUBLInvoice(ublInvoice.IDUBLInvoice,ublInvoice.IssueDate,fecha,single.getAccountID()).subscribe(
            (resp:any)=>{
               //console.log(resp);
                if(resp.DataBeanProperties.ObjectValue)
                {
                    setLista(resp.DataBeanProperties.ObjectValue);
                }
                single.spinner(false);
            }
        );
    }

    return(
        <div className="row">
            <div className="col-md-6">
                
                <label>Fecha de Registro Contable</label>
                <InputDate value={fecha} setDate={setFecha} name="fecha"/>
            </div>
            <div className="col-md-6 mt-3">
                <BasicButton icon=" ri-play-line" eventClick={createAccountantDocumentForUBLInvoice}>Generar</BasicButton>
            </div>
            <div className="col-md-12">
                <PrintJson json={lista}/>
            </div>
        </div>
    )
}