import { DataBean } from "../DataBean";

export class ThirdBankingAccount extends DataBean {


    public async getThirdBankingAccountCatalogByIDAccount(idAccount: any, processType: any): Promise<any> {
        return this.getCustomService("OrangeBase", "java.util.List_getThirdBankingAccountCatalogByIDAccount_Number_Number", [idAccount, processType]);
    }
    
    public async getThirdBankingAccount(idThirdBankingAccount:any): Promise<any> {
        return this.getCustomService("OrangeBase", "com.orange.bean.account.ThirdBankingAccount_getThirdBankingAccount_Number", [idThirdBankingAccount]);
    }

    public async updateThirdBankingAccount(bean: any): Promise<any> {
        return this.getCustomService("OrangeBase", "com.orange.bean.account.ThirdBankingAccount_updateThirdBankingAccount_com.orange.bean.account.ThirdBankingAccount", [
            {
                "DataBeanProperties": bean,
                "DataBeanName": "com.orange.bean.account.ThirdBankingAccount"
            }
        ]);
    }

    public async deleteThirdBankingAccount(bean): Promise<any> {
        return this.getCustomService("OrangeBase", "void_deleteThirdBankingAccount_Number", [
            {
                "DataBeanProperties": bean,
                "DataBeanName": "com.orange.bean.account.ThirdBankingAccount"
            }
        ]);
    }
}