import { useEffect, useState } from "react";
import { BasicButton } from "../../../theme/Component/BasicButton"
import { MyEditor } from "../../../theme/Component/MyEditor";
import { ContractService } from "../../../../core/services/ContractService";
import { AdquisitionArticle } from "../../adquisition-setting/AdquisitionArticle";
import { useDashboardStore } from "../../../pages/hooks/useDashboardStore";
import { RiskMatrix } from "./RiskMatrix";
import { CheckradioParam } from "../../../theme/Component/ChcekradioParam";
import { DescRecruiment } from "./DescRecruiment.component";
import { Modal } from "../../../theme/Component/Modal";
import { PDFNatural } from "./PDFNatutal";
import { RiskMatrixData } from "../../interfaces";
import { ListEmployee } from "../../FlowHiring/ListEmployee";
import { OfferEmployee } from "../../FlowHiring/OfferEmployee/OfferEmployee";
import { PrintJson } from "../../../theme/Component/PrintJson";

interface Props {
    adquisitioItem: any;
    rol: number;
    _item: object;
    _Study: object;
    riskMatrixData: RiskMatrixData;
    onChange?: Function;
}
export const EstudioPrevioNatural = ({ adquisitioItem, rol, _Study, _item, riskMatrixData, onChange }: Props) => {

    const { setLoading } = useDashboardStore();
    const service = new ContractService();
    const [item, setItem] = useState<any>(_item);
    const [estudio, setEstudio] = useState<any>(_Study);
    const [step, setStep] = useState<number>(1);
    const [modal, setModal] = useState<any>({ name: 'Resumen estudio previo', tabIndex: -1, view: false, btnClose: true, btnSubmit: false, format: 'modal-xl' });

    const steps = [
        { id: 1, title: "Tipo de Solicitud", rol: [1, 4] },
        { id: 2, title: "Justificacíon de la contratación", rol: [1, 4] },
        { id: 3, title: "Descripción de la contratación", rol: [1, 4] },
        { id: 4, title: "Bien/Servicio del contrato", rol: [1, 4] },
        { id: 5, title: "Obligaciones Principales", rol: [1, 4] },
        { id: 6, title: "Plazo del contrato", rol: [1, 4] },
        { id: 7, title: "Matriz de Riesgos", rol: [1, 4] },
        { id: 8, title: "Supervisor/es", rol: [1, 4] },
    ]
    useEffect(() => {
        setEstudio(_Study);
    }, [_Study])


    useEffect(() => {
        onChange();
    }, [step])

    const update = () => {
        setLoading(true);
        let est = estudio;
        est.Body = JSON.stringify(item);
        service.updatePreviousStudy(est).subscribe(
            (resp: any) => {

                if (resp.DataBeanProperties.ObjectValue) {
                    let item = resp.DataBeanProperties.ObjectValue;
                    setEstudio(item);
                    setItem(JSON.parse(item.DataBeanProperties.Body));
                }

                setLoading(false);
            }
        )
    }

    const updateListaEmpleados = (e: any) => {
        setItem({ ...item, "EmployeeList": e ? JSON.stringify(e) : '[]' });
    }

    const SeeModal = () => {
        setModal({ ...modal, "view": true })
    }

    return (
        <>
            <div className="row">
                <div className="col-md-4">
                    <div className="row">
                        <div className="col-md-12 mt-3">
                            <ul className="list-group">
                                {steps.map((item: any, index: number) => {
                                    return (
                                        <div key={item.id}>
                                            {item.rol.includes(rol) ?
                                                <li key={index} className="list-group-item cursor"
                                                    style={{
                                                        backgroundColor: item.id === step ? '#043072d9' : '',
                                                        color: item.id === step ? 'white' : ''
                                                    }}
                                                    onClick={() => { setStep(item.id) }}>{item.title}</li>
                                                : ''
                                            }
                                        </div>
                                    )
                                })}
                            </ul>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <BasicButton icon="ri-save-line" clase="success" eventClick={() => { update() }}>Guardar</BasicButton>
                            </div>
                            <div className="col-md-6">
                                <BasicButton icon="ri-save-line" clase="success" eventClick={SeeModal}>Visualizar</BasicButton>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-8">
                    {step == 1 &&
                        <>
                            <div className="row">
                                <div className="col-md-12">
                                    <CheckradioParam code={15} updateLista={(e: any) => { setItem({ ...item, "requestType": e.Name }); }} value={item.requestType} byID={false}></CheckradioParam>
                                </div>
                            </div>
                        </>
                    }
                    {step == 2 &&
                        <div className="row">
                            <div className="col-md-12">
                                <p>
                                    <h4>Justificación</h4> <small>(Si requiere ampliar y/o actualizar la justificación )</small> <hr />
                                    <MyEditor val={item.Justification ?? adquisitioItem.Justification} onChange={(e: any) => { setItem({ ...item, "Justification": e }) }}></MyEditor>
                                </p>
                            </div>
                            <div className="col-md-12">
                                <p>
                                    <h4>Objecto Contractual</h4>
                                    <MyEditor val={item.ObjectContract ?? adquisitioItem.Description} onChange={(e: any) => { setItem({ ...item, "ObjectContract": e }) }}></MyEditor>
                                </p>
                            </div>

                        </div>
                    }
                    {step === 3 &&
                        <>
                            <div className="row">
                                <div className="col-md-12">
                                    <DescRecruiment desRecruitment={item.Recruitment} setDesRecruitment={(e: any) => { setItem({ ...item, 'Recruitment': e }) }}></DescRecruiment>
                                </div>
                            </div>
                        </>
                    }
                    {step === 4 &&
                        <div className="row">
                            <div className="col-md-12">
                                <div className="col-md-12">
                                    <AdquisitionArticle viewHiringProcces={true} adquisitionItem={adquisitioItem} rol={4} beanItem={adquisitioItem} />
                                </div>
                            </div>
                        </div>
                    }
                    {step === 5 &&
                        <div className="row">
                            <div className="col-md-12">
                                <div className="col-md-12">
                                    <h4>Obligaciones Principales</h4> <hr />
                                    <MyEditor val={item.ObligationText} onChange={(e: any) => { setItem({ ...item, "ObligationText": e }) }}></MyEditor>
                                </div>
                            </div>
                        </div>
                    }
                    {step === 6 &&
                        <div className="row">
                            <div className="col-md-12">
                                <div className="col-md-12">
                                    <h4>Plazo del contrato</h4> <small>(Indique el plazo del contrato)</small> <hr />
                                    <MyEditor val={item.Term} onChange={(e: any) => { setItem({ ...item, "Term": e }) }}></MyEditor>
                                </div>
                            </div>
                        </div>
                    }
                    {step === 7 &&
                        <RiskMatrix value={{ risks: riskMatrixData.risks }} onChange={(e) => {
                            setItem({ ...item, 'RiskMatrix': e.risks });
                        }} />
                    }
                    {step === 8 &&
                        <OfferEmployee IDAdquisitionItem={adquisitioItem.IDAdquisitionItem}></OfferEmployee>
                    }
                </div>
                {/* <PrintJson json={item} /> */}
            </div>
            <Modal modal={modal} updateModal={setModal}>
                <>
                    <PDFNatural _AdquisitionItem={adquisitioItem} _BeanN={item}></PDFNatural>
                </>
            </Modal>
        </>
    )
}