import { DataBean } from "../DataBean";

export class AccountantIDType extends DataBean{


    public initBean()
    {
       return this.bean = {
            Name:'',
            Description:'',
            CodeFormat:'',
            CodeSeparator:''
        }
    }

    
    public async getAccountantIDTypeCatalog():Promise<any>
    {
        return this.getCustomService("QuickDataERPCore","java.util.List_getAccountantIDTypeCatalog_Number",[null]);
    }
    public async getBudgetIDTypeCatalog():Promise<any>
    {
        return this.getCustomService("QuickDataERPCore","java.util.List_getBudgetIDTypeCatalog_Number",[null]);
    }   
    public async importAccountantIDCatalog(idAccountantIDType:number,media:string,mediaContext:string,dataStore:string|null):Promise<any>
    {
        return this.getCustomService("QuickDataERPCore","java.util.List_importAccountantIDCatalog_Number_String_String_String",[idAccountantIDType,media,mediaContext,dataStore]);
    }
    public async importBudgetIDCatalog(idBudgetIDType:number,media:string,mediaContext:string,dataStore:string|null):Promise<any>
    {
        return this.getCustomService("QuickDataERPCore","java.util.List_importBudgetIDCatalog_Number_String_String_String",[idBudgetIDType,media,mediaContext,dataStore]);
    }
    public async importCostingCenterCatalog(filename:string,directory:string,dataStore:string|null):Promise<any>
    {
        return this.getCustomService("QuickDataERPCore","java.util.List_importCostingCenterCatalog_String_String_String",[filename,directory,dataStore]);
    }
    public async importArticleClassTypeCatalog(idArticleClassType:number,filename:string,directory:string,dataStore:string|null):Promise<any>
    {
        return this.getCustomService("QuickDataERPCore","java.util.List_importArticleClassTypeCatalog_Number_String_String_String",[idArticleClassType,filename,directory,dataStore]);
    }
    public async exportAccountantIDCatalog(idBudgetIDType:number):Promise<any>
    {
        return this.getCustomService("QuickDataERPCore","java.util.List_exportAccountantIDCatalog_Number",[idBudgetIDType]);
    }
    public async exportBudgetIDCatalog(idBudgetIDType:number):Promise<any>
    {
        return this.getCustomService("QuickDataERPCore","java.util.List_exportBudgetIDCatalog_Number",[idBudgetIDType]);
    }
    public async deleteBudgetIDCatalog(id:number):Promise<any>
    {
        return this.getCustomService("QuickDataERPCore","boolean_deleteBudgetIDCatalog_Number",[id]);
    }
    public async deleteAccountantIDCatalog(id:number):Promise<any>
    {
        return this.getCustomService("QuickDataERPCore","boolean_deleteAccountantIDCatalog_Number",[id]);
    }
    public async deleteCostingCenterCatalog(id:number):Promise<any>
    {
        return this.getCustomService("QuickDataERPCore","boolean_deleteCostingCenterCatalog_Number",[id]);
    }
    public async deleteArticleClassTypeCatalog(id:number):Promise<any>
    {
        return this.getCustomService("QuickDataERPCore","boolean_deleteArticleClassTypeCatalog_Number",[id]);
    }
    public async exportBudgetIDTemplateXlsxFile(id:number|null):Promise<any>
    {
        return this.getCustomService("QuickDataERPCore","java.util.List_exportBudgetIDTemplateXlsxFile_Number",[id]);
    }
    public async exportAccountantIDXlsxFile(id:number|null):Promise<any>
    {
        return this.getCustomService("QuickDataERPCore","java.util.List_exportAccountantIDXlsxFile_Number",[id]);
    }
    public async exportCostingCenterTemplateXlsxFile(id:number|null):Promise<any>
    {
        return this.getCustomService("QuickDataERPCore","java.util.List_exportCostingCenterTemplateXlsxFile_Number",[id]);
    }
    public async exportArticleClassTypeCatalog(idArticleClassType:number|null):Promise<any>
    {
        return this.getCustomService("QuickDataERPCore","java.util.List_exportArticleClassTypeCatalog_Number",[idArticleClassType]);
    }
    public async exportArticleClassTypeXlsxFile(idArticleClassType:number|null):Promise<any>
    {
        return this.getCustomService("QuickDataERPCore","java.util.List_exportArticleClassTypeXlsxFile_Number",[idArticleClassType]);
    }
  
}