import { DataBean } from "../DataBean";

export class TechnicalSkillLevel extends DataBean {


    public async getTechnicalSkillLevelCatalog(idTechnicalSkillType: number | null): Promise<any> {
        return this.getCustomService("PeopleArt", "java.util.List_getTechnicalSkillLevelCatalog_Number", [idTechnicalSkillType]);
    }

    public async deleteTechnicalSkillLevel(bean): Promise<any> {
        return this.getCustomService("PeopleArt", "vvoid_deleteTechnicalSkillLevel_Number", [
            {
                "DataBeanProperties": bean,
                "DataBeanName": "com.peopleart.bean.appointment.TechnicalSkillLevel"
            }
        ]);
    }
}