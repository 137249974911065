import { useState, useEffect } from 'react';
import { BasicPage } from "../../theme/Component/BasicPage";
import { BpmService } from "../../../core/services/BpmService";
import { BasicTable } from '../../theme/Component/BasicTable';
import { useDashboardStore } from '../../pages/hooks/useDashboardStore';

interface Props {
    IDProcedure: number;
}

export const ActualProcedure = ({ IDProcedure }: Props) => {

    const { setLoading } = useDashboardStore();
    const service = new BpmService();
    const [listaH, setListaH] = useState([]);
    const [bean, setBean] = useState<any>();

    useEffect(() => {
        getHistorial();
    }, [IDProcedure])

    const setRow = (bean: any) => {
       //console.log(bean)
        setBean(bean)
    }

    const getHistorial = () => {
        setLoading(true);
        service.getActualProcedureActionByProcedureImp(IDProcedure).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue) {
                    setListaH(resp.DataBeanProperties.ObjectValue);
                   //console.log(resp)
                }
                setLoading(false);
            }
        );
    }

    const head = [
        { title: 'ID del Tramite', property: 'IDAction' },
        { title: 'Fecha de creación', property: 'Since' },
        { title: 'Trámite', property: 'BusinessProcessName' },
        { title: 'No. Radicado', property: 'AlphaCode' },
        { title: 'ID Procedimiento', property: 'IDProcedure' },
        { title: 'Procedimiento', property: 'ProcedureName' },
        { title: 'Solicitante', property: 'AccountNameProcedureImp' },
        { title: 'Estado', property: 'StateName' },
        { title: 'Funcionario/Ciudadano (Encargado)', property: 'AccountName' },
        { title: 'Tipo de interacción', property: 'ActivityTypeName' },
        { title: 'Tipo Doc', property: 'DocumentTypeName' },
        // { title: 'Fecha de respuesta de la tarea', property: 'IDProcedureImp' },
        // { title: 'Doc/RTA Formulario', property: 'IDProcedureImp' },
        // { title: 'Respuesta recibida', property: 'IDProcedureImp' },
        { title: 'Msg enviado (Servicio)', property: 'InvokedMsgJsonValue' },
        // { title: 'Msg recibido (Servicio)', property: 'IDProcedureImp' },
        { title: 'Fecha de invocación (Servicio)', property: 'InvokedDate' },
        { title: 'Respuesta (servicio)', property: 'HttpURLResponseCode' },
    ]

    return (
        <>
            <BasicPage titulo="¿Dónde está el Trámite?">
                <>
                    <BasicTable body={listaH} head={head} rowSet={setRow} ></BasicTable>
                </>
            </BasicPage>
        </>
    )
}