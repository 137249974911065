import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Modal } from "../../../theme/Component/Modal";
import { CompanyEditor } from "../Company/CompanyEditor";
import { OfficeEditor } from "../Office/OfficeEditor";
import { BasicTable } from "../../../theme/Component/BasicTable";
import useDataBean from "../../../../core/services/dataBean/useDataBean";
import { _CaskDeskAccount } from "../../../../core/services/dataBean/EntityCatalog";

interface Props {
    id?: number;
    onChange: Function;
    label?: string;
}

export const CashDeskAccountEditor = ({ id, onChange, label }: Props) => {

    const { instance, lista, setLista, bean, setBean } = useDataBean(_CaskDeskAccount)

    const [valor, setValor] = useState('');
    const [conpany, setCompany] = useState<number>(null);
    const [office, setOffice] = useState<number>(null);

    const [modal, setModal] = useState<any>({ name: 'Códigos TRD', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });

    useEffect(() => {
        if (id) {
            getID();
        }
    }, [id])

    const getID = () => {
        instance.getCashDeskAccount(id).then(
            (resp) => {
                setValor(`${resp.IDCashDeskAccount ?? ""} - ${resp.Name ?? ""} - ${resp.AccountName ?? ""}`)
                onChange(resp)
            }
        )
    }

    useEffect(() => {
        if (office) {
            getLista();
        }
    }, [office])

    const getLista = () => {
        instance.getCashDeskAccountCatalog(office, null).then(
            (resp) => {
                setLista(resp)
            }
        ).catch(
            err => {
               console.error(err)
            }
        )
    }

    const head = [
        { title: 'ID', property: "IDCashDeskAccount" },
        { title: 'Nombre', property: 'Name' },
        { title: 'Responsable de la Caja', property: 'AccountName' },
        { title: 'Cuenta Contable', property: 'AccountantIDName' },
        // { title: 'Valor de la Base', property: 'BaseValue' },
        // { title: 'Caja Destino', property: 'DestinityCash' },
        // { title: 'Creado por', property: 'EmployeeName' },
        // { title: 'Oficina a la que pertenece', property: 'OfficeName' },
    ]

    const assingValue = () => {
        if (bean.IDCashDeskAccount) {
            setModal({ ...modal, "view": false });
            setValor(`${bean.IDCashDeskAccount ?? ""} - ${bean.Name ?? ""} - ${bean.AccountName ?? ""}`)
            onChange(bean)
        } else {
            toast.error("No se pudo realizar la seleccion")
        }
    }

    return (
        <>
            <label>{label ?? "Caja Menor"}</label>
            <div className="form-control text-wrap mt-2" style={{ minHeight: '35px' }} onClick={() => setModal({ ...modal, "view": true })}>
                {valor ?
                    <span>{valor}</span>
                    :
                    <span>Seleccionar....</span>
                }
            </div>

            <Modal onSubmitLabel='Seleccionar' modal={modal} updateModal={setModal} eventModal={assingValue}>
                <div className="row">
                    <div className="col-6">
                        <CompanyEditor id={conpany} onchange={(e: any) => { setCompany(e) }} />
                    </div>
                    <div className="col-6">
                        <OfficeEditor key={conpany} id={office} idconpany={conpany} onchange={(e: any) => { setOffice(e) }} />
                    </div>
                </div>
                <div className="row mt-3">
                    {office ?
                        <>
                            <div className="col">
                                <BasicTable body={lista} head={head} rowSet={setBean} />
                            </div>
                        </>
                        :
                        <>
                            <div className="alert alert-warning text-center">
                                Debe seleccionar una oficina
                            </div>
                        </>
                    }
                </div>
            </Modal>
        </>
    )
}