import { DataBean } from "../DataBean";


export class TaxCodeValue extends DataBean {

    public async getTaxCodeValueCatalog(idTaxSchemeValue: number | null): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_getTaxCodeValueCatalog_Number", [idTaxSchemeValue]);
    }

    public async addTaxCodeToTaxSchemeValue(idTaxSchemeValue: number, idTaxCode: number): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "com.quickdataerp.bean.tax.TaxCodeValue_addTaxCodeToTaxSchemeValue_Number_Number", [idTaxSchemeValue, idTaxCode]);
    }


}