import { useEffect, useState } from "react";
import { GarantiasService } from "../../../core/services/GarantiasService";
import { Singleton } from "../../../core/services/Singleton";
import { RenderForm } from "../../theme/Component/RenderForm";
import { useDashboardStore } from "../../pages/hooks/useDashboardStore";
import { toast } from "react-toastify";
import { DataBeanProperties } from '../../../core/services/model/server-response.interface';


interface Props {
  idRealesCredit: number;
  lista: any;
}

export const RealesCV = ({ idRealesCredit, lista }: Props) => {

  const single = Singleton.getInstance()
  const { setLoading } = useDashboardStore();
  const service = new GarantiasService();
  const [bean, setBean] = useState<any>({});
  const [user, setUser] = useState<any>({});

  const idbean = 'IDRealesCV';

  useEffect(() => {
    setUser(single.getAccount());
    getRealesCV();
   //console.log(lista);

  }, [idRealesCredit])

  const updateForm = (e: any) => {
    setLoading(true);
    service.updateRealesCV(e).subscribe(
      (resp: any) => {
        setLoading(false);
        if (resp.DataBeanProperties.ObjectValue) {

          let cv = resp.DataBeanProperties.ObjectValue;


         //console.log(cv);

          setBean(cv);
        }
        single.update(bean);
      });
  }

  const getRealesCV = () => {


    setLoading(true);
    service.getRealesCVCatalogPorPropiedades({ IDRealesCredit: idRealesCredit }, null).subscribe(
      (resp: any) => {

        let data:any = {};
        if (resp.DataBeanProperties.ObjectValue) {

          if (resp.DataBeanProperties.ObjectValue) {
            let original = resp.DataBeanProperties.ObjectValue;
            if (original.length > 0) {
              data = original[0].DataBeanProperties;
              data = data.BasicData ? JSON.parse(data.BasicData) : {};
            }

          } 

        } else {
          toast.error(resp.DataBeanProperties.ErrorMessage);
        }
        
        
        setBean(data);
        setLoading(false);
      });

  }

  const updateData = (form: any) => {

   //console.log(form);

    let b = {
      IDRealesCredit: idRealesCredit,
      IDEmployee: user.IDAccount,
      LastUpdate: single.getCurrenDate(),
      Attach: lista?.DataBeanProperties?.Attach,
      BasicData: form ? JSON.stringify(form) : "{}",
    }

    updateForm(b);

  }


  return (
    <>
      <RenderForm  idFormType={5} handleUpdate={updateData} data={bean} />
    </>
  )
}