import { useEffect, useState } from "react";
import useDataBean from "../../../../core/services/dataBean/useDataBean";
import { Singleton } from "../../../../core/services/Singleton";
import { toast } from "react-toastify";
import { ListMenu } from "../../../theme/Component/ListMenu";
import { BasicPage } from "../../../theme/Component/BasicPage";
import { BasicTable } from "../../../theme/Component/BasicTable";
import { Modal } from "../../../theme/Component/Modal";
import { ModalConfirm } from "../../../theme/Component/ModalConfirm";
import { AccountEditor } from "../../../editor/AccountEditor";
import { PrintJson } from "../../../theme/Component/PrintJson";
import { _UBLTaxSchemeContextCode } from "../../../../core/services/dataBean/EntityCatalog";
import { TaxSchemeContextEditor } from "../TaxSchemeCode/TaxSchemeContext.editor";

import { InputDate } from "../../../theme/Input/InputDate";
import { TaxSchemeContextCodeEditor } from "../TaxSchemeCode/TaxSchemeContextCode/TaxSchemeContextCode.editor";


export const UBLTaxSchemeContextCode = () => {

    const UBLtaxScheme = useDataBean(_UBLTaxSchemeContextCode);
    const single = Singleton.getInstance();
    const [acc, setAcc] = useState<number>(null)
    const [esquema, setEsquema] = useState<number>(null)
    const [lista, setLista] = useState<any>([]);
    const [modal, setModal] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-md' });
    const [modalCode, setModalCode] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalC, setModalC] = useState<any>({ name: "Eliminar", tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });

    useEffect(() => {
        if (acc) {
            getLista();
        }
    }, [acc])

    const getLista = () => {

        UBLtaxScheme.instance.getUBLTaxSchemeContextCodeCatalogByAccount(acc).then(
            (resp: any) => {
                if (resp) {
                    setLista(resp)
                }
            }
        )

    }

    const create = () => {
        UBLtaxScheme.setBean({
            IDAccountFiler: single.getAccountID(),
            IDAccount: acc,
        });
        setModal({ ...modal, "view": true, "name": `Agregar Códigos de Actividad de Impuestos por Tercero` });
    }

    const deleteItem = () => {
        UBLtaxScheme.deleteItemVoid(UBLtaxScheme.bean.IDUBLTaxSchemeContextCode).then(
            (resp: any) => {
                getLista();
                setModalC({ ...modalC, "view": false });
            }
        )

    }
    const editaItem = () => {
       //console.log(UBLtaxScheme.bean);
        UBLtaxScheme.update().then(
            (resp: any) => {
                getLista();
                setModal({ ...modal, "view": false });
            }
        ).catch(err => {
            toast.error(err);
        })
    }


    const head = [
        { title: 'ID', property: 'IDUBLTaxSchemeContextCode', visible: true },
        { title: 'Código', property: 'Code', visible: true },
        { title: 'Descipcion', property: 'Description', visible: true },
        { title: 'Entidad Recaudadora de Impuestos', property: 'TaxSchemeContextDescription', visible: true },
        { title: 'Fecha de Inicio', property: 'FromDate', visible: true },
        { title: 'Fecha de Finalización', property: 'UptoDate', visible: true },
        { title: 'Usuario que agrega', property: 'IDAccountFiler', visible: true },
    ]

    const iconButtonsItem = [
        { nombre: 'Agregar', visible: single.canCreate(), icono: 'ri-file-add-line', evento: create }
    ]
    const listaBotones = () => {
        const botones = [
            { titulo: 'Editar', icono: 'ri-edit-2-line', visible: single.canEdit(), evento: () => { setModal({ ...modal, "name": `Editar Código de Actividad de Impuestos por Tercero`, "view": true }) } },
            { titulo: 'Eliminar', icono: 'ri-delete-bin-line', visible: single.canDelete(), evento: () => { setModalC({ ...modalC, "view": true }) } },
        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }


    return (
        <>
            <BasicPage titulo="Tipos de Esquemas de Impuestos por Entidad Recaudadora">
                <div className="row">
                    <div className="col-12">
                        <AccountEditor label="Proveedor que está configurando" idAccount={acc} onChange={(e) => { setAcc(e.IDAccount) }}></AccountEditor>
                    </div>
                    {acc &&
                        <div className="col-md-12">
                            <BasicTable body={lista} head={head} rowSet={UBLtaxScheme.setBean} listButtons={listaBotones()} iconButtons={iconButtonsItem}></BasicTable>
                        </div>
                    }
                </div>
            </BasicPage>
            <Modal modal={modal} updateModal={setModal} eventModal={editaItem}>
                <div className="row">
                    <div className="col-12">
                        <TaxSchemeContextEditor idSelector={esquema} onChange={(e) => setEsquema(e)} />
                    </div>
                    <div className="col-12">
                        <TaxSchemeContextCodeEditor schema={esquema} idSelector={UBLtaxScheme.bean.IDTaxSchemeContextCode} onChange={(e) => { UBLtaxScheme.setBean({ ...UBLtaxScheme.bean, "IDTaxSchemeContextCode": e }) }} />
                    </div>
                    <div className="col-6">
                        <InputDate name="desde" label="Desde" value={UBLtaxScheme.bean.FromDate} setDate={(e: any) => { UBLtaxScheme.setBean({ ...UBLtaxScheme.bean, "FromDate": e }) }} />
                    </div>
                    <div className="col-6">
                        <InputDate name="desde" label="Hasta" value={UBLtaxScheme.bean.UptoDate} setDate={(e: any) => { UBLtaxScheme.setBean({ ...UBLtaxScheme.bean, "UptoDate": e }) }} />
                    </div>
                </div>
            </Modal>

            <ModalConfirm modal={modalC} updateModal={setModalC} eventModal={deleteItem}>
                <strong>Eliminar item {UBLtaxScheme.bean.Name}</strong>
            </ModalConfirm>
        </>
    )
}