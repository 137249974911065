import { DataBean } from "../DataBean";

export class TransversalSkill extends DataBean {


    public async getTransversalSkillCatalog(idTransversalSkillType: number | null): Promise<any> {
        return this.getCustomService("PeopleArt", "java.util.List_getTransversalSkillCatalog_Number", [idTransversalSkillType]);
    }

    public async deleteTransversalSkill(bean): Promise<any> {
        return this.getCustomService("PeopleArt", "void_deleteTransversalSkill_com.peopleart.bean.appointment.TransversalSkill", [
            {
                "DataBeanProperties": bean,
                "DataBeanName": "com.peopleart.bean.appointment.TransversalSkill"
            }
        ]);
    }
}