import { BasicButton } from '../theme/Component/BasicButton';
import { BasicTable, MASK_NUMBER } from '../theme/Component/BasicTable';
import { Singleton } from '../../core/services/Singleton';
import { ContractService } from '../../core/services/ContractService';
import { useState, useEffect } from 'react';
import { ModalConfirm } from '../theme/Component/ModalConfirm';
import { Modal } from '../theme/Component/Modal';
import { SelectModality } from "./select/SelectModality";
import { SelectRequirementType } from "./select/SelectRequirementType";
import { BasicSwitch } from "../theme/Component/BasicSwitch";
import { SelectLegalTypeTermsConstants } from "./select/SelectLegalTypeTermsConstants";
import { SelectTrdsID } from "../admin/configuracion/TrdID/SelectTrdsID";
import { ListMenu } from "../theme/Component/ListMenu";
import { useDashboardStore } from "../pages/hooks/useDashboardStore";
import { FileInput } from '../theme/Component/FileInput';
import { BasicPage } from '../theme/Component/BasicPage';
import { PrintJson } from '../theme/Component/PrintJson';

interface Props {
    idModality?: number;
}
export const HiringModalityDocument = ({ idModality }: Props) => {

    const { setLoading } = useDashboardStore();
    const single = Singleton.getInstance()
    const service = new ContractService();
    const [lista, setLista] = useState([]);
    const [listaType, setListaType] = useState([]);
    const [listaTypeLegal, setListaTypeLegal] = useState([]);
    const [idTipo, setIDTipo] = useState(0);
    const [idTipoLegal, setIDTipoLegal] = useState(0);
    const [bean, setBean] = useState<any>({});
    const [itemDoc, setItemDoc] = useState<any>({});
    const [file, setFile] = useState<any>({});
    const [modal, setModal] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalConfirm, setModalConfirm] = useState<any>({ name: 'Confirmar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });

    const [permisos, setPermisos] = useState<any>(single.getLinkPermisions())

    const idBean = 'IDHiringModalityDocument';

    const head = [
        { title: 'ID', property: idBean, mask: MASK_NUMBER, visible: false },
        { title: 'Tipo Requerimiento', property: 'LegalRequirementTypeName' },
        { title: 'Requerimiento', property: 'RequirementTypeName' },
        { title: 'Nombre', property: 'Name' },
        { title: 'Descripción', property: 'Description' },
        { title: '¿El Documento Tiene Vencimiento?', property: 'DocumentDefeat', visible: false },
        { title: 'Término en días', property: 'TermInDays' },
        { title: 'Tipo de Término Legal', property: 'TermTypeName' },
        { title: 'Tabla de Retención Documental', property: 'IDLnTrdName' },
    ]

    useEffect(() => {
        if (idModality)
            setIDTipo(idModality);
        getLegalRequirementTypeConstants();
    }, [idModality])




    const setRowBean = (bean: any) => {

        setBean(bean);

    }



    const getLegalRequirementTypeConstants = () => {

        setLoading(true);
        service.getLegalRequirementTypeConstants().subscribe(
            (resp: any) => {



                if (resp.DataBeanProperties.ObjectValue) {
                    setListaTypeLegal(resp.DataBeanProperties.ObjectValue);
                }
                getLista();

                setLoading(false);
            }
        );
    }
    const getLista = () => {

        single.spinner(true);
        service.getHiringModalityDocumentCatalog(null, null, null).subscribe(
            (resp: any) => {
                setLista([]);

                if (resp.DataBeanProperties.ObjectValue.DataBeanProperties.List) {
                    setLista(resp.DataBeanProperties.ObjectValue.DataBeanProperties.List);
                }
                single.spinner(false);

            }
        );
    }

    const agregarItem = () => {

        let b = {
            IDHiringModality: idTipo,
            IDRequirementType: 0,
            Name: '',
            Description: '',
            DocumentDefeat: false,
            TermInDays: 0,
            TermType: 0,
            IDLnTrd: 0,
            JsonMedia: "",
            Since: single.getCurrenDate(),
            LegalRequirementType: 0,
            ItemOrder: 1
        }

        setBean(b);
        setModal({ ...modal, ['view']: true });

    }
    const editarItem = () => {

        setModal({ ...modal, ['view']: true });

    }
    const eliminarItem = () => {

        setLoading(true);
        service.deleteHiringModalityDocument(bean[idBean]).subscribe(
            (resp: any) => {
                setLoading(false);
                if (resp) {
                    getLista();
                }
                single.delete(bean[idBean], bean);
            }
        );
        setModalConfirm({ ...modalConfirm, ['view']: false });

    }
    const updateItem = () => {

        setModal({ ...modal, ['view']: false });
        bean.JsonMedia = JSON.stringify(itemDoc);
        setLoading(true);
        service.updateHiringModalityDocument(bean).subscribe(
            (resp: any) => {
                setLoading(false);
                if (resp.DataBeanProperties.ObjectValue) {
                    getLista();
                }
                if (bean[idBean]) {

                    single.update(bean);

                }
                else {
                    single.create(bean);
                }
            });

    }

    const renderButton = () => {
        return (
            <>
                <div className="row">
                    <div className="col-md-12">
                        {idTipo > 0 &&
                            <BasicButton value="Agregar" icon="ri-file-add-line" eventClick={agregarItem} ></BasicButton>
                        }
                        {bean[idBean] &&
                            <>
                                <BasicButton value="Editar" icon="ri-edit-2-line" eventClick={editarItem} ></BasicButton>
                                <BasicButton value="Eliminar" icon="ri-eraser-line" eventClick={() => { setModalConfirm({ ...modalConfirm, ['view']: true }); }} ></BasicButton>
                            </>
                        }
                    </div>
                </div>
            </>
        )
    }

    const addFile = (e: any) => {
        
        setItemDoc({ media: e.Media, mediaContext: e.MediaContext })
    }


    const listaBotones = () => {


        const botones = [
            { titulo: 'Editar', icono: 'ri-file-list-line', visible: single.canEdit(), evento: () => { setModal({ ...modal, "view": true }) } },
            { titulo: 'Eliminar', icono: 'ri-file-user-line', visible: single.canDelete(), evento: () => { setModalConfirm({ ...modalConfirm, "view": true }) } },
        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }

    const iconButtonsItem = [
        { nombre: 'Agregar', visible: single.canCreate(), icono: 'ri-file-add-line', evento: agregarItem }
    ]

    return (
        <>

            <BasicPage titulo="Requisitos Modalidades de Selección">

                <div className="row">
                    {/* <div className="col-md-12">
                    {!idModality &&
                        <SelectModality idSelector={idTipo} onChange={(e: any) => { setIDTipo(e) }}></SelectModality>
                    }
                </div> */}
                    <div className="col-md-12 p-2">
                        <BasicTable iconButtons={iconButtonsItem} listButtons={listaBotones()} head={head} body={lista} rowSet={setRowBean}></BasicTable>
                    </div>
                </div>
            </BasicPage>

            <ModalConfirm modal={modalConfirm} updateModal={setModalConfirm} eventModal={eliminarItem}>
                {bean.Name}
            </ModalConfirm>

            <Modal modal={modal} updateModal={setModal} eventModal={updateItem}>
                <div className="row">
                    <div className="col-md-12">
                        <label className="form-label">Nombre</label>
                        <input type="text" className="form-control" value={bean.Name} onChange={(e) => { setBean({ ...bean, ['Name']: e.target.value }) }} />
                    </div>
                    <div className="col-md-12">
                        <label className="form-label">Descripción</label>
                        <textarea className="form-control" value={bean.Description} onChange={(e) => { setBean({ ...bean, ['Description']: e.target.value }) }}></textarea>
                    </div>


                    <div className="col-md-6">
                        <label>Tipo Requerimiento Legal</label>
                        <select className="form-control mt-2" value={bean.LegalRequirementType} onChange={(e: any) => { setBean({ ...bean, "LegalRequirementType": e.target.value }) }}>
                            <option value={0}>...</option>
                            {listaTypeLegal.map((item: any, index: number) => {
                                return (
                                    <option key={index} value={item.DataBeanProperties.Value}>{item.DataBeanProperties.Property}</option>
                                )
                            })}
                        </select>
                    </div>

                    <div className="col-md-6">
                        <SelectRequirementType idPadre={bean.LegalRequirementType} idSelector={bean.IDRequirementType} onChange={(e: any) => {
                            setBean({ ...bean, "IDRequirementType": e })
                        }}></SelectRequirementType>
                    </div>

                    <div className="col-md-12">
                        <SelectTrdsID edit={false} trdIDLn={bean.IDLnTrd} onChange={(e: any) => {
                            setBean({ ...bean, "IDLnTrd": e.IDLn });
                        }}></SelectTrdsID>
                    </div>
                    <div className="col-md-12">
                        <div className="form-group mt-4">
                            <label>¿El Documento Tiene Vencimiento?</label>
                            <BasicSwitch label="" estado={bean.DocumentDefeat} eventChange={(e: any) => { setBean({ ...bean, "DocumentDefeat": e }) }}></BasicSwitch>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <label >Término en días</label>
                        <input type="number" className="form-control mt-2" value={bean.TermInDays} disabled={!bean.DocumentDefeat}
                            onChange={(e) => { setBean({ ...bean, ['TermInDays']: e.target.value }) }} />
                    </div>
                    <div className="col-md-6">
                        <SelectLegalTypeTermsConstants idSelector={bean.TermType} onChange={(e: any) => { setBean({ ...bean, "TermType": e }) }}></SelectLegalTypeTermsConstants>
                    </div>
                    <div className="col-md-6">
                        <FileInput
                            label='Plantilla / Formato'
                            Media={itemDoc.fileMap ? itemDoc.fileMap?.Media : null}
                            MediaContext={itemDoc.fileMap ? itemDoc.fileMedia?.MediaContext : null}
                            directory={'PlantillasDocs'}
                            onCreate={addFile}
                            docAcept="application/pdf , image/jpeg, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                        />
                        <PrintJson json={bean} />
                    </div>
                </div>
            </Modal>
        </>
    )
}