import { useEffect, useState } from "react";
import { _CashReimbursementItem, _CashRequest, _CashRequestItem } from "../../../../core/services/dataBean/EntityCatalog"
import useDataBean from "../../../../core/services/dataBean/useDataBean"
import { Singleton } from "../../../../core/services/Singleton";
import { BasicTable } from "../../../theme/Component/BasicTable";
import { ListMenu } from "../../../theme/Component/ListMenu";
import { ModalConfirm } from "../../../theme/Component/ModalConfirm";
import { Modal } from "../../../theme/Component/Modal";
import { InputMoney } from "../../../theme/Input/InputMoney";
import { FileInput } from "../../../theme/Component/FileInput";
import { FileService } from "../../../../core/services/FileService";
import { MakeUBLInvoice } from "../../../contract/etapa-contrato/contrac/ublinvoice/MakeUBLInvoice";
import { toast } from "react-toastify";
import { PrintJson } from "../../../theme/Component/PrintJson";
import { CashReibursementSelector } from "../CashDesk/CashReimbursement/AdquisitionItemEditor/CashReibursementSelector";
import { ThirdBankingAccountEditor } from "../../../Talentohumano/ParametrosTalentoHumano/ThirdBankingAccount/ThirdBanking.editor";
import { SeeInvoice } from "./SeeInovoice";

interface Props {
    idCashRequest: number;
    cashRequestDate: any;
    entry?: "Request" | "Manage";
    idAccount?: number;
    updateFunction?: Function;
    _idCashAccount?: number;
}

export const CashRequestItem = ({ idCashRequest, cashRequestDate, entry = "Request", idAccount, updateFunction, _idCashAccount }: Props) => {

    const { lista, setLista, bean, setBean, instance, handleChange } = useDataBean(_CashRequestItem);
    const cashItem = useDataBean(_CashReimbursementItem);
    const single = Singleton.getInstance();
    const file = new FileService();

    const [obs, setObs] = useState<string>("");
    const [invoice, setInvoice] = useState<any>({});
    const [response, setResponse] = useState<any>({});

    const [modal, setModal] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: "modal-lg", });
    const [modalConfirm, setModalConfirm] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: "modal-sm", });
    const [modalProcess, setModalProcess] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: "modal-md", });
    const [modalReject, setModalReject] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: "modal-md", });
    const [modalInvoice, setModalInvoice] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: "modal-xl", });
    const [modalConfirmInvoice, setModalConfirmInvoice] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: "modal-md", });
    const [modalAddToCashReimbursement, setModalAddToCashReimbursement] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: "modal-md", });
    const [modalSeeInvoice, setModalSeeInvoice] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: "modal-lg", });

    useEffect(() => {
        getLista();
    }, [idCashRequest])

    const getLista = () => {
        instance.getCashRequestItemCatalog(idCashRequest, cashRequestDate).then(
            (resp) => {
                let x = resp;
                x.forEach(element => {
                    if (element.DataBeanProperties.Media) {
                        const doc = file.getUrlFile(element.DataBeanProperties.MediaContext, element.DataBeanProperties.Media);
                        element.DataBeanProperties.EntityMedia = "<a target='_blank' href='" + doc + "'>" + element.DataBeanProperties.Media + "</a>";
                    } else {
                        element.DataBeanProperties.EntityMedia = "Sin Adjunto"
                    }
                });
                setLista(x)
            }
        ).catch(
            err => {
               console.error(err)
            }
        )
    }

    const createItem = () => {
        setBean({
            IDEmployee: single.getAccountID(),
            IDCashRequest: idCashRequest,
            CashRequestDate: cashRequestDate,
            IsUBLDocument: false,
        });
        setModal({ ...modal, "view": true, "name": "Crear una Solicitud de Pago" })
    }

    const updateItem = () => {
        instance.updateCashRequestItem(bean).then(
            (resp) => {
                getLista();
                setModal({ ...modal, "view": false });
                if (updateFunction) {
                    updateFunction();
                }
            }
        ).catch(
            err => {
               console.error(err);
            }
        )
    }

    const eliminarItem = () => {
        instance.deleteCashRequestItem(bean.IDCashRequestItem, single.getCurrenDate(), single.getAccountID()).then(
            (resp) => {
                setModalConfirm({ ...modalConfirm, "view": false });
                getLista();
            }
        ).catch(
            err => {
               console.error(err)
            }
        )
    }

    const procesItem = () => {
        instance.processCashRequestItem(bean.IDCashRequestItem, cashRequestDate, single.getAccountID(), obs).then(
            (resp) => {
                setModalProcess({ ...modalProcess, "view": false });
                getLista();
            }
        ).catch(
            err => {
               console.error(err);
            }
        )
    }

    const rejectItem = () => {
        instance.rejectCashRequestItem(bean.IDCashRequestItem, cashRequestDate, single.getAccountID(), obs).then(
            (resp) => {
                setModalReject({ ...modalReject, "view": false });
                getLista();
            }
        ).catch(
            err => {
               console.error(err);
            }
        )
    }

    const addInvoice = () => {
        instance.addUBLInvoiceToCashRequest(invoice.IDUBLInvoice, invoice.IssueDate, idCashRequest, cashRequestDate, obs).then(
            (resp) => {
                setModalConfirmInvoice({ ...modalConfirmInvoice, "view": false });
                getLista();
            }
        ).catch(
            err => {
                toast.error(err, { autoClose: false });
            }
        )
    }

    const addUBLInvoiceToCashReimbursementFromCashRequestItem = () => {
       //console.log(bean.IDCashRequestItem, cashRequestDate, response.CashReimbID, response.CashReimbDate, response.Bank, single.getAccountID())
        cashItem.instance.addUBLInvoiceToCashReimbursementFromCashRequestItem(bean.IDCashRequestItem, cashRequestDate, response.CashReimbID, response.CashReimbDate, response.Bank, single.getAccountID()).then(
            (resp) => {
                setModalAddToCashReimbursement({ ...modalAddToCashReimbursement, "view": false });
                getLista();
            }
        ).catch(
            err => {
                toast.error(err, { autoClose: false });
            }
        )
    }

    const head = [
        { title: 'ID', property: 'IDCashRequestItem' },
        { title: 'Descripción', property: 'Description' },
        { title: 'Archivo de Factura/Solicitud', property: 'EntityMedia' },
        { title: 'Valor Solicitado', property: 'PaymentValue' },
        { title: 'Proveedor', property: 'IDAccountSupplier' },
        { title: 'Factura Electrónica', property: 'IsUBLDocument' },
        { title: 'Factura Electrónica', property: 'InvoiceName' },
        { title: 'Respuesta de la Caja', property: 'CashResponse' },
        { title: 'Responsable de Caja', property: 'EmployeeName' },
        { title: 'Documento de Rembolso', property: 'AdvanceDocumentConsecutive' },
    ]

    const iconButtonsItem = [
        { nombre: "Agregar", visible: (single.canCreate() && (entry == "Request")), icono: "ri-file-add-line", evento: createItem },
        { nombre: "Agregar Factura Electrónica", visible: (single.canCreate() && (entry == "Request")), icono: "ri-folder-download-line", evento: () => { setModalInvoice({ ...modalInvoice, "view": true, "name": "Seleccione una Factura" }) } },
    ];

    const listaBotones = () => {
        const botones = [
            { titulo: "Editar", icono: "ri-edit-2-line", visible: (single.canEdit() && ((entry == "Request"))), evento: () => { setModal({ ...modal, view: true, name: "Editar" }); }, },
            { titulo: "Eliminar", icono: "ri-delete-bin-line", visible: (single.canDelete() && ((entry == "Request"))), evento: () => { setModalConfirm({ ...modalConfirm, view: true, name: `Eliminar ${bean.Description}` }); }, },


            { titulo: "Ver Documentos", icono: "ri-eye-line", visible: bean.IsUBLDocument, evento: () => { setModalSeeInvoice({ ...modalSeeInvoice, view: true, name: `Factura Asociada al Item ${bean.Description}` }); }, },

            { titulo: "Agregar a Reembolso de Caja Menor", icono: "ri-folder-download-line", visible: (entry == "Manage" && bean.IDUBLInvoice), evento: () => { setModalAddToCashReimbursement({ ...modalAddToCashReimbursement, view: true, name: `Agregar a Reembolso de Caja Menor` }); }, },
            { titulo: "Marcar Solicitud Procesada", icono: "ri-check-line", visible: (entry == "Manage"), evento: () => { setModalProcess({ ...modalProcess, view: true, name: "Marcar Solicitud Procesada" }); }, },
            { titulo: "Rechazar Solicitud", icono: "ri-close-line", visible: (entry == "Manage"), evento: () => { setModalReject({ ...modalReject, view: true, name: `Rechazar Solicitud` }); }, },
        ];
        return <ListMenu listaBotones={botones} />;
    };

    const setRowBean = (objeto: any) => {
        setBean(objeto);
    }

    useEffect(() => {
        setObs("");
    }, [modalProcess, modalReject, modalConfirmInvoice]);

    const closeModalAdd = () => {
        if (!invoice.IDUBLInvoice) {
            toast.error("Debe seleccionar una factura valida")
        } else {
            setModalInvoice({ ...modalInvoice, "view": false });
            setModalConfirmInvoice({ ...modalConfirmInvoice, "view": true, "name": "Confirmar Agregar la Factura Seleccionada" });
        }
    }

    return (
        <>
            <BasicTable listButtons={listaBotones()} iconButtons={iconButtonsItem} body={lista} head={head} rowSet={(e) => { setRowBean(e) }} />

            <ModalConfirm modal={modalConfirm} updateModal={setModalConfirm} eventModal={eliminarItem}>
                {bean.Description}
            </ModalConfirm>



            <Modal modal={modal} updateModal={setModal} eventModal={updateItem}>
                <div className="row">
                    {!bean.IsUBLDocument &&
                        <>
                            <div className="col-md-6">
                                <FileInput
                                    Media={bean.Media}
                                    MediaContext={bean.MediaContext}
                                    label="Archivo de Factura/Solicitud"
                                    onCreate={(e: any) => setBean({ ...bean, 'Media': e.Media, 'MediaContext': e.MediaContext })}
                                />
                            </div>
                            <div className="col-md-6">
                                <InputMoney _label="Valor Solicitado" id={1} name="PaymentValue" value={bean.PaymentValue} onChange={(e) => { setBean({ ...bean, "PaymentValue": e }) }} />
                            </div>
                        </>
                    }
                    <div className="col-12">
                        <label>Descipción</label>
                        <textarea value={bean.Description} name="Description" className="form-control" onChange={handleChange} rows={4} />
                    </div>
                </div>
            </Modal>

            <Modal modal={modalProcess} updateModal={setModalProcess} eventModal={procesItem} >
                <div className="col-12">
                    <label>Observaciones</label>
                    <textarea value={obs} name="Observations" className="form-control" onChange={(e) => { setObs(e.target.value) }} rows={4} />
                </div>
            </Modal>

            <Modal modal={modalReject} updateModal={setModalReject} eventModal={rejectItem} >
                <div className="col-12">
                    <label>Observaciones</label>
                    <textarea value={obs} name="Observations" className="form-control" onChange={(e) => { setObs(e.target.value) }} rows={4} />
                </div>
            </Modal>

            <Modal modal={modalInvoice} updateModal={setModalInvoice} eventModal={closeModalAdd} onSubmitLabel="Seleccionar" >
                <MakeUBLInvoice _entry="CashReimbursement" _onChange={(e) => { setInvoice(e); }} />
            </Modal>

            <Modal modal={modalConfirmInvoice} updateModal={setModalConfirmInvoice} eventModal={addInvoice} >
                <p>Desea Agregar la Factura {invoice.InvoiceID} de {invoice.AccountSupplierName} </p>
                <div className="col-12">
                    <label>Observaciones</label>
                    <textarea value={obs} name="Observations" className="form-control" onChange={(e) => { setObs(e.target.value) }} rows={4} />
                </div>
            </Modal>

            <Modal modal={modalAddToCashReimbursement} updateModal={setModalAddToCashReimbursement} eventModal={addUBLInvoiceToCashReimbursementFromCashRequestItem} >
                <div className="row">
                    <div className="col-12">
                        <CashReibursementSelector _idCashAccount={_idCashAccount} onChage={(e) => { setResponse({ ...response, "CashReimbID": e.IDCashReimbursement, "CashReimbDate": e.Since }) }} />
                    </div>
                    <div className="col-12">
                        <ThirdBankingAccountEditor idSelector={response.Bank} onChange={(e) => { setResponse({ ...response, "Bank": e }) }} idselectorAccount={idAccount} />
                    </div>
                </div>
            </Modal>

            <Modal modal={modalSeeInvoice} updateModal={setModalSeeInvoice} >
                <>
                    <SeeInvoice idInvoice={bean.IDUBLInvoice} issueDate={bean.IssueDate} />
                </>
            </Modal>
        </>
    )
}