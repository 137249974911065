import { ContractService } from '../../core/services/ContractService';
import { Singleton } from '../../core/services/Singleton';
import { Alert } from '../theme/Component/Alert';
import { BasicButton } from '../theme/Component/BasicButton';
import { useEffect, useState } from 'react';
import { ApprovalUser } from './ApprovalUser';
import { ModalConfirm } from '../theme/Component/ModalConfirm';
import { useDashboardStore } from '../pages/hooks/useDashboardStore';
interface Props {
    type: number;
    idbean: number;
}
export const PlanApproval = ({ type, idbean }: Props) => {

    const single = Singleton.getInstance()
    const { setLoading } = useDashboardStore();
    const service = new ContractService();
    const [approval, setApproval] = useState<any>({});
    const [descripction, setDescription] = useState<any>('');
    const [message, setMessage] = useState<any>('');

    const [modal, setModal] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });

    useEffect(() => {
        getPlanApprovalCatalogPorPropiedades();
    }, [idbean])

    const getPlanApprovalCatalogPorPropiedades = () => {
        let properties = {
            TypeApproval: type,
            IDBean: idbean
        }
        setLoading(true);
        service.getPlanApprovalCatalogPorPropiedades(properties, null).subscribe(
            (resp: any) => {
               //console.log(resp);
                setLoading(false);
                if (resp.DataBeanProperties.ObjectValue) {
                   //console.log(resp.DataBeanProperties.ObjectValue);
                    if (resp.DataBeanProperties.ObjectValue.length > 0) {
                        setApproval(resp.DataBeanProperties.ObjectValue[0].DataBeanProperties);

                    }

                }
            }
        )
    }
    const crearAprocacion = () => {
        let b = {
            IDBean: idbean,
            IDEmployee: single.getAccountID(),
            LastUpdate: single.getCurrenDate(),
            State: 1,
            RulesJson: {},
            TypeApproval: type,
            Theme: descripction
        }
        setLoading(true);
        service.updatePlanApproval(b).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue) {
                    setApproval(resp.DataBeanProperties.ObjectValue.DataBeanProperties);
                }
                setLoading(false);
            }
        )
    }

    const enviarNotificaciones = () => {

        setLoading(true);
        service.requestApprovalUser(approval.IDPlanApproval).subscribe(
            (resp: any) => {


                if (resp.DataBeanProperties.ObjectValue) {

                   //console.log(resp.DataBeanProperties.ObjectValue);
                    let approval = resp.DataBeanProperties.ObjectValue.DataBeanProperties;
                    setMessage("Las solicitudes de aprobación fueron enviadas " + approval.Theme);

                }

                setLoading(false);
            }
        )
    }

    return (
        <>
            <div className="row">
                <div className="col-md-12">
                    {!approval?.IDPlanApproval &&
                        <div className="row">
                            <div className="col">
                                <Alert clase='warning'>
                                    <strong>Aprobaciones: </strong>No se ha creado un registro de aprobaciones desea iniciar uno ?

                                </Alert>
                                <label>Descripción</label>
                                <textarea className='form-control' value={descripction} onChange={(e) => { setDescription(e.target.value) }} placeholder='Describa la necesidad de la solicitud de aprobación.' ></textarea>
                                <br />
                                <BasicButton disable={descripction == ''} icon='ri-mail-send-line' eventClick={crearAprocacion}>Crear</BasicButton>
                            </div>
                        </div>
                    }
                    {approval?.IDPlanApproval > 0 &&
                        <div className="row">
                            <div className="col-md-12">
                                {approval.Theme}
                            </div>
                            <div className="col-md-12">
                                <ApprovalUser IDPlanApproval={approval.IDPlanApproval} />
                            </div>
                        </div>
                    }
                </div>
                <div className="col-md-12">
                    <BasicButton icon='ri-mail-check-line' eventClick={() => { setModal({ ...modal, "view": true }) }}>Solicitar aprobación</BasicButton>

                    <ModalConfirm modal={modal} updateModal={setModal} eventModal={enviarNotificaciones}>

                        <strong>Enviar Notificaciónes al equipo autorizador </strong><br />
                        <small>Tenga en cuenta que no se podran realizar mas mesas de trabajo ni modificaciones en el plan de compras</small>
                        {message != '' &&

                            <Alert clase='warning'>
                                {message}
                            </Alert>
                        }
                    </ModalConfirm>
                </div>
            </div>
        </>
    )
}   