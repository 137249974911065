import { useEffect, useState } from "react";
export const MODAL_LG="modal-lg";
export const MODAL_XL="modal-xl";
export const MODAL_SM="modal-sm";
export const MODAL_md="modal-md";
function useModal(title:string,size:string){    

    const [submitLabel,setSumbmitLabel] = useState<string>('');
    const [modal, setModal] = useState<any>({ name: title, tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: MODAL_md });

    useEffect(()=>{
        setModal({ name: title, tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: size });
    },[])
    useEffect(()=>{
        setModal({ name: title, tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: size });
    },[title])


    const open=()=>{
        setModal({...modal,"view":true});
    }
    const close=()=>{
        setModal({...modal,"view":false});
    }
    
    const setTitle=(title:string)=>{
        setModal({...modal,"name":title});
    }
   
    

    return{
        modal,
        submitLabel,
        setSumbmitLabel,
        setModal,
        open,
        close,
        setTitle
    }


}
export default useModal;