import { useState, useEffect } from 'react';
import { useDashboardStore } from '../../pages/hooks/useDashboardStore';
import { AdminService } from '../../../core/services/AdminService';
import { BasicButton } from './BasicButton';
import { FaCheck, FaMinusCircle, FaPlus, FaPlusCircle, FaPlusSquare } from 'react-icons/fa';
import { toast } from 'react-toastify';
interface Props {
    label?: string;
    code: number;
    _value: any;
    updateLista: any;
}
export const MultiSelectParam = ({ code, label, _value, updateLista }: Props) => {

    const service = new AdminService();
    const [origen, setOrigen] = useState([]);
    const [destino, setDestino] = useState([]);
    const [adding, setAdding] = useState(false)
    const [otherOpc, setOtherOpc] = useState('')

    const [cont, setCont] = useState(0);
    const { setLoading } = useDashboardStore();

    useEffect(() => {
        try {
            setDestino(JSON.parse(_value ?? '[]'));
        } catch (error) {
           //console.log('no se pudo cargar la lista')
        }
    }, [_value])

    useEffect(() => {
        getListaParam();
    }, [code])

    const add = (ele: any) => {
        setCont(destino.length + 1);
        setDestino([ele, ...destino])
        setOrigen(origen.filter(e => e.id !== ele.id))
        updateLista(JSON.stringify([ele, ...destino]));
    }

    const remove = (ele: any) => {
        let d: any = destino;
        if (d.indexOf(ele) >= 0) {
            d.splice(d.indexOf(ele), 1);
        }
        setCont(d.length);
        setDestino(d);
        setOrigen([ele, ...origen])
        updateLista(JSON.stringify(d));
    }

    const addMore = () => {
        if (otherOpc.length > 0) {
            setOrigen([{ id: -1, Name: otherOpc }, ...origen])
            setOtherOpc('')
            setAdding(false)
        } else toast.warning('Debe introducir una opción')
    }

    const getListaParam = () => {
        setLoading(true);
        service.getSuppConstantsValuesByCode(0, [code]).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue) {
                    setLoading(false);
                    let lista = resp.DataBeanProperties.ObjectValue[`Code_${code}`];
                    let tmp = [];
                    lista.forEach((element: any) => {
                        tmp.push(
                            { id: element.DataBeanProperties.IDSuppConstantsValue, Name: element.DataBeanProperties.Name }
                        )
                    });
                    setOrigen(tmp);
                }
            }
        )
    }

    const overFlowSt = { maxHeight: '50vh', overflow: 'auto' }

    return (
        <>
            <div className="row">
                <div className="col-md-12">
                    <p>{label}</p>
                    <div className="row px-2">
                        <div className="col-md-6">
                            <div className="d-flex justify-content-between mb-2">
                                <h5>Opciones</h5>
                                <div className='mx-2'>
                                    <FaPlus className='pointer primario' title='Añadir Opción' onClick={() => setAdding(prev => !prev)} />
                                </div>
                            </div>
                            <div className="list-group list-group-fill-success" style={overFlowSt}>
                                {adding &&
                                    <label className="list-group-item pointer">
                                        <div className="d-flex justify-content-between">
                                            <FaMinusCircle className='fs-5 me-3 mb-2 text-danger' title='Cancelar'
                                                onClick={() => setAdding(false)}
                                            />
                                            <small>Otra opción</small>
                                        </div>
                                        <textarea className='form-control' style={{
                                            resize: 'none',
                                            maxHeight: 150
                                        }} value={otherOpc} onChange={(e) => setOtherOpc(e.target.value)} />
                                        <button className='my-2 mt-3 btn btn-sm' style={{ backgroundColor: '#294f87', color: 'white' }}
                                            onClick={addMore}
                                        >
                                            Añadir
                                        </button>
                                    </label>
                                }
                                {
                                    origen.map((ele: any, i) => {
                                        return (
                                            <label key={i} className="list-group-item pointer  bg-active"
                                                title='Seleccionar'
                                                onClick={() => { add(ele) }}
                                            >
                                                <FaPlusCircle className='fs-5 me-3 mb-1 primario' />
                                                <span className='fs-7'>{ele.Name}</span>
                                            </label>
                                        )
                                    })
                                }
                                {origen.length === 0 &&
                                    <span className="list-group-item list-group-item-action">
                                        <i className=" ri-file-reduce-fill align-middle me-2"></i>Niguna Opción disponible
                                    </span>
                                }
                            </div >
                        </div>
                        <div className="col-md-6">
                            <div className="d-flex justify-content-between mb-2">
                                <h5>Seleccionado</h5>
                                <FaCheck fontSize={16} className='text-success mx-2' />
                            </div>
                            <div className="list-group list-group-fill-success" style={overFlowSt}>
                                {
                                    destino.map((ele: any, i) => {
                                        return (
                                            <label key={i} className="list-group-item pointer  bg-active"
                                                title='Remover'
                                                onClick={() => { remove(ele) }}
                                            >
                                                <FaMinusCircle className='fs-5 me-3 mb-1 text-danger' />
                                                <span className='fs-7'>{ele.Name}</span>
                                            </label>
                                        )
                                    })
                                }
                                {destino.length === 0 &&
                                    <span className="list-group-item list-group-item-action">
                                        <i className=" ri-file-reduce-fill align-middle me-2"></i>Niguna Opción seleccionada
                                    </span>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-12 my-3">
                    <BasicButton icon='' eventClick={() => { updateLista(JSON.stringify(destino)) }}>Guardar Seleccion</BasicButton>
                </div>
            </div>
        </>
    )
}