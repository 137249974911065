import { useState, useEffect } from 'react';
import { Singleton } from "../../../../core/services/Singleton";
import useModal, { MODAL_LG, MODAL_SM } from '../../../theme/Component/hooks/useModal';
import { Modal } from '../../../theme/Component/Modal';
import { toast } from 'react-toastify';
import { BasicButton } from '../../../theme/Component/BasicButton';
import { ModalConfirm } from '../../../theme/Component/ModalConfirm';
import { SelectBudgetID } from '../../../admin/configuracion/BudgetID/SelectBudgetID';
import { BasicSwitch } from '../../../theme/Component/BasicSwitch';
import { AccountEditor } from '../../../editor/AccountEditor';
import useAccountantTree, { VIEW_EDITOR } from './useAccountantTree';


interface Props {
    id: number;
    idAccountantIDType: number;
    bean: any;
    updateEvent: any;

}

export const ButtonAccountantTree = ({ id, idAccountantIDType, bean, updateEvent }: Props) => {

    const single = Singleton.getInstance();
    const { makeList, makeCodeFormat, createAccountantID, cargarChilds, accountantID } = useAccountantTree(VIEW_EDITOR);


    const [miBean, setMibean] = useState<any>({});
    const [cuenta, setCuenta] = useState<number>(1);
    const [nombre, setNombre] = useState<string>('');
    const [idBudgetType, setIDBudgetType] = useState<number>(0);
    const [cuentaFormat, setCuentaFomat] = useState<string>('');

    const modalCrear = useModal("Crear cuenta", MODAL_LG);
    const confirmDelete = useModal("Cuenta contable", MODAL_SM);


    useEffect(() => {
        setCuenta(bean.AccountantID);
        setNombre(bean.Name);
        setMibean(bean);
    }, [bean])
    useEffect(() => {
        setMibean({ ...miBean, "Name": nombre });
    }, [nombre])



    useEffect(() => {


        if (cuenta >= 1) {
            let format = makeCodeFormat(cuenta);
            if (format)
                setCuentaFomat(format);
            else {
                setCuentaFomat("##");
                toast.warning("La cuenta es muy larga");
            }

        }
        setMibean({ ...miBean, "AccountantID": cuenta });
    }, [cuenta])



    const createAccountant = () => {
        let lista = makeList(cuenta);
        //console.log(lista);
        if (lista.length > 0 && nombre != '') {

            createAccountantID(idAccountantIDType, lista, nombre).then((resp: any) => {
                //console.log("Nuevo hijo ", resp);
                updateEvent(resp);

            })
        } else {
            toast.error("Datos incompletos");
        }

    }
    const updateAccountant = () => {

        let b = miBean;
        accountantID.updateCustonBean(b).then(
            (resp: any) => {
                updateEvent();
            }
        )

    }
    const deleteAccountant = (idln: number) => {
        accountantID.deleteItem(idln).then(
            (resp: any) => {
                updateEvent(true);
            }
        )

    }

    return (
        <>

            {single.canCreate() &&
                <i className="ri-file-add-line cursor btnicon_sm" title="Agregar Hijo" onClick={() => {
                    modalCrear.open();
                    setMibean({});
                }}></i>
            }
            {(single.canDelete() && bean.IDLn) &&
                <i className="ri-file-reduce-line cursor btnicon_sm" title="Eliminar" onClick={() => { confirmDelete.open(); }}></i>
            }
            {(single.canEdit() && bean.IDLn) &&
                <i className="ri-edit-2-line cursor btnicon_sm" title="Editar" onClick={() => {
                    modalCrear.open();
                    setMibean(bean);
                }}></i>
            }

            <Modal modal={modalCrear.modal} updateModal={modalCrear.setModal}>
                <div className="row">

                    <div className="col-md-6">
                        <label>Código : <strong>{cuentaFormat}</strong> </label>
                        <input type="number" className="form-control" value={cuenta} onChange={(e) => { setCuenta(parseInt(e.target.value)) }} />
                    </div>
                    <div className="col-md-6">
                        <label> Nombre cuenta </label>
                        <input type="text" className="form-control" value={nombre} onChange={(e) => { setNombre(e.target.value) }} />
                    </div>

                    {miBean.IDLn &&
                        <>
                            <div className="col-md-12">
                                <SelectBudgetID tipoPresupuesto={0} edit={false} idBudgetID={miBean.IDBudgetID} onChange={(e: any) => { setMibean({ ...miBean, "IDBudgetID": e.IDLn }) }} />
                            </div>
                            <hr className='mt-2 mb-2' />
                            <div className="col-md-4">
                                <BasicSwitch label='Requiere Centro de Costo' estado={miBean.RequiresCostingCenter} eventChange={(e: any) => { setMibean({ ...miBean, "RequiresCostingCenter": e }) }} />
                            </div>
                            <div className="col-md-4">
                                <BasicSwitch label='Código Otro PUC' estado={miBean.OtherPUCCode} eventChange={(e: any) => { setMibean({ ...miBean, "OtherPUCCode": e }) }} />
                            </div>
                            <div className="col-md-4">
                                <BasicSwitch label='Requiere Tercero' estado={miBean.RequiresThird} eventChange={(e: any) => { setMibean({ ...miBean, "RequiresThird": e }) }} />
                            </div>
                            <div className="col-md-4">
                                <BasicSwitch label='Requiere Área Funcional' estado={miBean.RequiresArea} eventChange={(e: any) => { setMibean({ ...miBean, "RequiresArea": e }) }} />
                            </div>
                            <div className="col-md-4">
                                <BasicSwitch label='Requiere Proyecto' estado={miBean.RequiresProject} eventChange={(e: any) => { setMibean({ ...miBean, "RequiresProject": e }) }} />
                            </div>

                            <div className="col-md-4">
                                <BasicSwitch label='Requiere Sucursal' estado={miBean.RequiresSucursal} eventChange={(e: any) => { setMibean({ ...miBean, "RequiresSucursal": e }) }} />
                            </div>
                            <div className="col-md-4">
                                <BasicSwitch label='Utilizar Archivo de Cuentas por Pagar' estado={miBean.PaymentFile} eventChange={(e: any) => { setMibean({ ...miBean, "PaymentFile": e }) }} />
                                {miBean.PaymentFile &&

                                    <>
                                        <label>Archivo de Cuentas por Pagar</label>
                                        <input type="number" className='form-control' value={miBean.IDPaymentFile} onChange={(e) => { setMibean({ ...miBean, "IDPaymentFile": parseInt(e.target.value) }) }} />
                                    </>


                                }
                            </div>

                            <div className="col-md-4">
                                <BasicSwitch label='Consolidar Terceros en una Cuenta por Pagar' estado={miBean.RequiresConsolidateThird} eventChange={(e: any) => { setMibean({ ...miBean, "RequiresConsolidateThird": e }) }} />
                                {miBean.RequiresConsolidateThird &&
                                    <>
                                        <AccountEditor idAccount={miBean.IDAccountThird} label='Tercero que consolida' onChange={({ IDAccount }) => { setMibean({ ...miBean, "IDAccountThird": IDAccount }) }} />
                                    </>



                                }
                            </div>

                            <hr className='mt-2 mb-2' />
                            <div className="col-md-4">
                                <label>Área por Defecto</label>
                                <input type="text" className='form-control' value={miBean.DefaultArea} onChange={(e) => { setMibean({ ...miBean, "DefaultArea": e.target.value }) }} />
                            </div>
                            <div className="col-md-4">
                                <label>Proyecto por Defecto</label>
                                <input type="text" className='form-control' value={miBean.DefaultArea} onChange={(e) => { setMibean({ ...miBean, "DefaultProject": e.target.value }) }} />
                            </div>
                            <div className="col-md-4">
                                <label>Sucursal por Defecto</label>
                                <input type="text" className='form-control' value={miBean.DefaultSucursal} onChange={(e) => { setMibean({ ...miBean, "DefaultSucursal": e.target.value }) }} />
                            </div>
                            <div className="col-md-4">
                                <label>Centro de Costo por Defecto</label>
                                <input type="text" className='form-control' value={miBean.DefaultCostingCenter} onChange={(e) => { setMibean({ ...miBean, "DefaultCostingCenter": e.target.value }) }} />
                            </div>
                            <div className="col-md-4">
                                <label>Código de Operación por Defecto</label>
                                <input type="text" className='form-control' value={miBean.OperationTypeCode} onChange={(e) => { setMibean({ ...miBean, "OperationTypeCode": e.target.value }) }} />
                            </div>
                            <div className="col-md-4">
                                <label>Código de Producto por Defecto</label>
                                <input type="text" className='form-control' value={miBean.ProductCode} onChange={(e) => { setMibean({ ...miBean, "ProductCode": e.target.value }) }} />
                            </div>
                            <div className="col-md-4">
                                <label>Código de Producto Destino</label>
                                <input type="text" className='form-control' value={miBean.DestinityProduct} onChange={(e) => { setMibean({ ...miBean, "DestinityProduct": e.target.value }) }} />
                            </div>
                            <div className="col-md-4">
                                <label>Código Catálogo Externo 1</label>
                                <input type="text" className='form-control' value={miBean.OtherPUCCode1} onChange={(e) => { setMibean({ ...miBean, "OtherPUCCode1": e.target.value }) }} />
                            </div>
                            <div className="col-md-4">
                                <label>Código Catálogo Externo 2</label>
                                <input type="text" className='form-control' value={miBean.OtherPUCCode2} onChange={(e) => { setMibean({ ...miBean, "OtherPUCCode2": e.target.value }) }} />
                            </div>
                            <div className="col-md-4">
                                <label>Código Catálogo Externo 3</label>
                                <input type="text" className='form-control' value={miBean.OtherPUCCode3} onChange={(e) => { setMibean({ ...miBean, "OtherPUCCode3": e.target.value }) }} />
                            </div>
                            <div className="col-md-4">
                                <label>Requiere Área de Negocio ?</label>
                                <BasicSwitch label="No / Si" estado={miBean.RequiresBusinessCore} eventChange={(e: any) => { setMibean({ ...miBean, "RequiresBusinessCore": e }) }}></BasicSwitch>
                            </div>
                        </>
                    }

                    {miBean.IDLn ?
                        <div className="col-md-12 mt-2">
                            <BasicButton disable={(cuenta === 0 && nombre === '')} icon="ri-edit-line" eventClick={updateAccountant}>Editar</BasicButton>
                        </div>
                        :
                        <div className="col-md-12">
                            <BasicButton disable={(cuenta === 0 && nombre === '')} icon="ri-edit-line" eventClick={createAccountant}>Crear</BasicButton>
                        </div>
                    }
                    {/* <PrintJson json={miBean} /> */}
                </div>
            </Modal>
            <ModalConfirm modal={confirmDelete.modal} updateModal={confirmDelete.setModal} eventModal={deleteAccountant}>
                <strong>Eliminar cuenta {bean.Name}</strong>
            </ModalConfirm>




        </>
    )
}