import { DataBean } from "../DataBean";


export class ImportVariousConcepts extends DataBean {

    public async importEmployeeScheduleDiscounts(idType: number, filename: string, directory: string, dataStore: string): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_importEmployeeScheduleDiscounts_Number_String_String_String", [idType, filename, directory, dataStore]);
    }

    public async importEmployeeDiscounts(idType: number, filename: string, directory: string, dataStore: string): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_importEmployeeDiscounts_Number_String_String_String", [idType, filename, directory, dataStore]);
    }

    public async importEmployeeIncapacities( filename: string, directory: string, dataStore: string): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_importEmployeeIncapacities_String_String_String", [ filename, directory, dataStore]);
    }

    public async importEmployeeNovelties( filename: string, directory: string, dataStore: string): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_importEmployeeNovelties_String_String_String", [ filename, directory, dataStore]);
    }

    public async importEmployeePayments(idEmploymentBonus: number, filename: string, directory: string, dataStore: string): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_importEmployeePayments_Number_String_String_String", [idEmploymentBonus, filename, directory, dataStore]);
    }

}