import { DataBean } from "../DataBean";

export class TransversalCriteria extends DataBean {


    public async getTransversalCriteriaCatalog(idTransversalSkill: number | null): Promise<any> {
        return this.getCustomService("PeopleArt", "java.util.List_getTransversalCriteriaCatalog_Number", [idTransversalSkill]);
    }

    // public async deleteTransversalCriteria(bean): Promise<any> {
    //     return this.getCustomService("PeopleArt", "void_deleteTransversalCriteria_Number", [
    //         {
    //             "DataBeanProperties": bean,
    //             "DataBeanName": "com.peopleart.bean.appointment.TransversalCriteria"
    //         }
    //     ]);
    // }
}