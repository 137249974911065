interface Props {
    listaBotones: any
}
export const ListMenu = ({ listaBotones: items }: Props) => {


    return (
        <div className='menulist cursor'>
            <ul className="list-group">
                {items.length === 0 && (
                    <li className='list-group-item'>Sin opciones</li>
                )}
                {items.map((boton: any, index: number) => (
                    boton.visible && (
                        <li key={index} className='list-group-item' onClick={boton.evento}>
                            <i className={boton.icono ? `${boton.icono} icont` : 'ri-subtract-line'}></i>
                            {boton.titulo}
                        </li>
                    )
                ))}
            </ul>
        </div>
    )
}
