import { useEffect, useState } from "react";
import { FinanceService } from '../../../../core/services/FinanceService';
import { Singleton } from '../../../../core/services/Singleton';
import { ButtonBudgetID } from './ButtonBudgetID';
import { useDashboardStore } from "../../../pages/hooks/useDashboardStore";
import { _single } from "../../../../core/services/dataBean/EntityCatalog";

interface Props {
    idChild: number;
    editBean: Function;
    edit: boolean;
    _type: number;
}

export const BudgetIDChilds = ({ _type, idChild, editBean, edit }: Props) => {

    const service = new FinanceService();
    const single = Singleton.getInstance()
    const { setLoading } = useDashboardStore();
    const [lista, setLista] = useState([]);
    const [name, setName] = useState('');
    const [bean, setBean] = useState({});
    const [idchild, setIDchild] = useState(0);



    useEffect(() => {
        getListatree(idChild);
    }, [idChild])

    const getListatree = (idArbol: any) => {
        let tmp = _single.getCacheItem('budgetChild_' + idChild);
        if (tmp) {
            setLista(JSON.parse(tmp));
        } else {

            setLoading(true);

            service.loadBudgetIDTree(idArbol).subscribe((resp: any) => {

                if (resp.DataBeanProperties.ObjectValue) {
                    let arbol: any = resp.DataBeanProperties.ObjectValue.EnvolvedObject.DataBeanProperties;

                    single.mapBudgetID[idArbol] = resp.DataBeanProperties.ObjectValue;

                    setName(arbol.Name);
                    setLista(resp.DataBeanProperties.ObjectValue.Childs);
                    _single.setCacheItem('budgetChild_' + idChild, JSON.stringify(resp.DataBeanProperties.ObjectValue.Childs))
                }
                setLoading(false);

            });


        }


    };

    return (
        <>

            <ul className="list-group">
                {
                    lista.length > 0 &&
                    lista.map((item: any) => {
                        return (
                            <li className="list-group-item itemh" style={{ border: '0' }} key={item.EnvolvedObject.DataBeanProperties.IDLn}>

                                {edit &&
                                    <ButtonBudgetID
                                        idType={_type}
                                        id={item.EnvolvedObject.DataBeanProperties.IDLn}
                                        bean={item.EnvolvedObject.DataBeanProperties}
                                        updateEvent={() => { getListatree(idChild); }}
                                    />
                                }
                                <strong className={idchild === item.EnvolvedObject.DataBeanProperties.IDLn ? 'cursor treeselect' : 'cursor'} onClick={() => {
                                    setIDchild(item.EnvolvedObject.DataBeanProperties.IDLn);
                                    localStorage.setItem('BudgetID', JSON.stringify(item.EnvolvedObject.DataBeanProperties));
                                }}>
                                    {/* <strong style={{marginLeft:'15px'}}>{item.EnvolvedObject.DataBeanProperties.BudgetViewCode} </strong>  */}
                                    <strong style={{ marginLeft: '15px' }}>{item.EnvolvedObject.DataBeanProperties.JsonCode} </strong>
                                    <small>{item.EnvolvedObject.DataBeanProperties.Name}</small>

                                </strong>
                                <span className="badge bg-success ms-1">{item.ChildrenCount}</span>
                                {(idchild === item.EnvolvedObject.DataBeanProperties.IDLn) &&
                                    <BudgetIDChilds _type={_type} edit={edit} idChild={idchild} editBean={(item: any) => { setBean(item) }} />
                                }
                            </li>
                        )
                    })
                }
            </ul>
        </>
    )
}