import { useState, useEffect } from 'react';
import useDataBean from '../../../../core/services/dataBean/useDataBean';
import { _CompetitionType, _Entailment, _MainTheme } from '../../../../core/services/dataBean/EntityCatalog';

interface Props {
    idSelector: number;
    onChange: any;
}

export const MainThemeEditor = ({ idSelector, onChange }: Props) => {

    const Maintheme = useDataBean(_MainTheme)
    const [lista, setLista] = useState([]);
    const [id, setID] = useState(0);

    useEffect(() => {
        setID(idSelector);
        getLista();
    }, [])

    useEffect(() => {
        onChange(id);
    }, [id])

    const setValue = (e: any) => {
        setID(parseInt(e));
        onChange(id);
    }

    const getLista = () => {

        Maintheme.instance.getMainThemeCatalog().then(
            (resp: any) => {
                if (resp) {
                    setLista(resp)
                   //console.log(resp)
                }
            }
        )

    }

    return (
        <>
            <label>Temáticos Competencias Funcionales:</label>
            <select className="form-select" value={id} onChange={(e) => { setValue(e.target.value) }}>
                <option>...</option>
                {lista &&
                    lista.map((item: any, index: number) => {
                        return (
                            <option key={index} value={item.DataBeanProperties.IDMainTheme} >{item.DataBeanProperties.Name}</option>
                        )
                    })
                }
            </select>
        </>
    )
}