import { useEffect, useState } from "react"
import { BasicPage } from "../../theme/Component/BasicPage"
import { InputDate } from "../../theme/Input/InputDate"
import { AccountEditor } from "../../editor/AccountEditor"
import { BasicButton } from "../../theme/Component/BasicButton"
import { BasicTable } from "../../theme/Component/BasicTable"
import { Singleton } from "../../../core/services/Singleton"
import { AuditService } from "../../../core/services/AuditService"
import { DropDown } from "../../theme/Component/DropDown"
import { Modal } from "../../theme/Component/Modal"
import { PrintJson } from "../../theme/Component/PrintJson"
import { BasicSwitch } from "../../theme/Component/BasicSwitch"
import { useDashboardStore } from "../../pages/hooks/useDashboardStore"
import { MONTHS } from "../../Constants"
import usePaginator from "../../theme/Component/usePaginator"
import { PrintObjectJson } from "../../theme/Component/PrintObjectJson"
import { DataBeanProperties } from '../../../core/services/model/server-response.interface';
import useModal, { MODAL_LG, MODAL_md } from "../../theme/Component/hooks/useModal"


export const AuditLog = () => {

    const single = Singleton.getInstance()
    const { setLoading } = useDashboardStore();
    const audit = new AuditService();

    const [year, setYear] = useState<number>(single.getCurrenYear());
    const [month, setMonth] = useState<number>(1);
    const [account, setAccount] = useState<number>(null);
    const [data, setData] = useState<any>({});
    const [dataBean, setDataBean] = useState<any>({});

    const paginador = usePaginator();

    const [filtro, setFiltro] = useState<any>({
        year: single.getCurrenYear(),
        month: 1,
        idAccount: null
    });

    const [lista, setLista] = useState<any>([]);
    const [bean, setBean] = useState<any>({});
    const [action, setActions] = useState<any>({
        url: false,
        crear: true,
        editar: true,
        eliminar: true,
        xslx: true
    });
    const [filas, setFilas] = useState(100);
    const [btn, setBTN] = useState<any>({});
    const [modal, setModal] = useState<any>({ name: 'Argumentos', tabIndex: -1, view: false, btnClose: true, btnSubmit: false, format: 'modal-lg' });

    const modalJSON = useModal("Request API",MODAL_LG);

    const head = [
        { title: 'ID', property: 'IDAuditFunction', visible: true },
        { title: 'Fecha', property: 'Since', visible: true },
        { title: 'Nombre', property: 'EntityName', visible: true },
        { title: 'Servicio', property: 'MethodName', visible: true },
        { title: 'Clase', property: 'DataBeanClass', visible: true },
        { title: 'Api', property: 'Args', visible: false },
    ]

    useEffect(() => {

        return () => {
            setBean({});
        }
    }, [])

    useEffect(() => {
        setBean({});
        setLista([]);
    }, [year, month])

    useEffect(() => {
        let js = undefined;
        try {
            js = JSON.parse(bean.JsonArgs ?? '[]');
           //console.log(js);            
            setData(js);            
        } catch (error) {
        }
        if(js)
        {
            if(js.length>0)
            {
                let db = js[0];
                if(db?.DataBeanProperties)
                {
                    setDataBean(db.DataBeanProperties);                    
                }
                else
                    setDataBean(undefined);

            }
        }
    }, [bean])

    const getLista = () => {

        setBean({});

        let ac = [];
        if (action.url) ac.push(3);
        if (action.accion) ac.push(5);
        if (action.crear) ac.push(1);
        if (action.editar) ac.push(2);
        if (action.eliminar) ac.push(2);
        if (action.xslx) ac.push(4);

        let props: any = {};
        if (account > 0)
            props.IDAccount = account;
        single.spinner(true);
        audit.getAuditForYearMonth(parseInt(year + "" + (month < 10 ? '0' + month : month + '')), 1, filas, props, ac).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue) {
                    let lista = resp.DataBeanProperties.ObjectValue;

                    lista.forEach((ele: any) => {
                        ele.DataBeanProperties.EntityName =
                            (ele.DataBeanProperties.Name1 ?? ' ') + " " +
                            (ele.DataBeanProperties.Namw2 ?? ' ') + " " +
                            (ele.DataBeanProperties.Surname1 ?? ' ') + " " +
                            (ele.DataBeanProperties.Surname2 ?? ' ');
                        if (ele.DataBeanProperties.JsonArgs)
                            ele.DataBeanProperties.Args = ele.DataBeanProperties.JsonBefore.length > 100 ? ele.DataBeanProperties.JsonBefore.substring(0, 99) + "..." : '';
                        if (ele.DataBeanProperties.IDAction === 3) {
                            ele.DataBeanProperties.Args = ele.DataBeanProperties.Observations;
                            ele.DataBeanProperties.MethodName = "Navegación";
                        }
                        if (ele.DataBeanProperties.IDAction === 4) {
                            ele.DataBeanProperties.Args = ele.DataBeanProperties.Observations;
                            ele.DataBeanProperties.MethodName = "Exportar";
                        }

                    });
                    setLista(lista);

                }
                single.spinner(false);
            }
        )
    }

    const actions = [
        { id: 1, name: 'Mostrar Argumentos', activo: bean.JsonArgs != null },
        { id: 2, name: 'Mostrar Request', activo: bean.JsonBefore != null },
        // { id: 2, name: 'Restarurar', activo: (bean.IDAction == 0) },
        // { id: 3, name: 'Revertir Cambios', activo: (bean.IDAction == 2) },
    ]

    const handleEvent = (item: any) => {

        setBTN(item);
        if (item.id === 1) {
            setModal({ ...modal, "view": true });
        }
        if (item.id === 2) {
            modalJSON.open();
        }
    }




    return (
        <BasicPage titulo="Auditoria">
            <div className="row">
                <div className="col-md-2">
                    <label>Filas</label>
                    <input type="number" className="form-control " value={filas} onChange={(e) => setFilas(parseInt(e.target.value))} />
                </div>
                <div className="col-md-3">
                    <label>Año</label>
                    <input type="number" name="year" className="form-control" value={year} onChange={(e) => setYear(parseInt(e.target.value))} />
                </div>
                <div className="col-md-3">
                    <label>Mes</label>
                    <select name="month" className="form-select" value={month} onChange={(e) => setMonth(parseInt(e.target.value))}>
                        {MONTHS.map((item: any) => {
                            return (
                                <option value={item.id}>{item.name}</option>
                            )
                        })}
                    </select>
                </div>
                <div className="col-md-3">
                    <AccountEditor idAccount={account} label="Funcionario" onChange={({ IDAccount }: any) => { setAccount(IDAccount) }} />
                </div>
                <div className="col-md-8">
                    <div className="row mt-2 mb-2">
                        <div className="col">
                            <BasicSwitch label="URL" estado={action.url} eventChange={(e: any) => { setActions({ ...action, "url": e }) }}></BasicSwitch>
                        </div>
                        {/* <div className="col">
                            <BasicSwitch label="Accion" estado={action.accion} eventChange={(e: any) => { setActions({ ...action, "accion": e }) }}></BasicSwitch>
                        </div> */}
                        <div className="col">
                            <BasicSwitch label="Servicio" estado={action.crear} eventChange={(e: any) => { setActions({ ...action, "crear": e }) }}></BasicSwitch>
                        </div>
                        {/* <div className="col">
                            <BasicSwitch label="Editar" estado={action.editar} eventChange={(e: any) => { setActions({ ...action, "editar": e }) }}></BasicSwitch>
                        </div>
                        <div className="col">
                            <BasicSwitch label="Eliminar" estado={action.eliminar} eventChange={(e: any) => { setActions({ ...action, "eliminar": e }) }}></BasicSwitch>
                        </div> */}
                        <div className="col">
                            <BasicSwitch label="Exportar" estado={action.xslx} eventChange={(e: any) => { setActions({ ...action, "xslx": e }) }}></BasicSwitch>
                        </div>
                    </div>
                </div>
                <div className="col-md-12">
                    <BasicButton icon="ri-search-line" eventClick={getLista}>Buscar</BasicButton>
                    {(bean.IDAccount && bean.JsonBefore) &&
                        <DropDown eventClick={handleEvent} lista={actions} ></DropDown>
                    }
                </div>

            </div>
            <div className="row">
                <BasicTable head={head} body={lista} rowSet={setBean} ></BasicTable>
            </div>

            <Modal modal={modal} updateModal={setModal} >
                <div className="row">
                    <div className="col-md-12">
                    <h5>{bean.MethodName}</h5>
                    </div>
                    <div className="col">
                        
                            <>
                               
                                <hr />
                                {dataBean === undefined && <PrintObjectJson json={bean.JsonArgs} />}
                                {dataBean && <PrintObjectJson json={dataBean} />}
                                
                            </>                        
                    </div>
                </div>
            </Modal>
            <Modal modal={modalJSON.modal} updateModal={modalJSON.setModal} >
                    <div className="row">
                        <div className="col">
                        <PrintObjectJson json={bean.JsonBefore}/>
                        </div>
                    </div>
            </Modal>
        </BasicPage>
    )
}