import { DataBean } from "../DataBean";

export class CashDeskAccount extends DataBean {

    public async getCashDeskAccountCatalog(officeID: number, accountID: number): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_getCashDeskAccountCatalog_Number_Number", [officeID, accountID]);
    }

    public async getCashDeskAccount(idCashDeskAccount): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "eBankTransfer.CashDeskAccount_getCashDeskAccount_Number", [idCashDeskAccount]);
    }

}
