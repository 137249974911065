import { useState, useEffect } from 'react';
import useDataBean from '../../../../core/services/dataBean/useDataBean';
import { _EmployeePayments } from '../../../../core/services/dataBean/EntityCatalog';

interface Props {
    idSelector: number;
    onChange: any;
    label?: string;
}

export const EmployeePaymentsEditor = ({ idSelector, onChange, label }: Props) => {
    const TaxHold = useDataBean(_EmployeePayments);
    const [lista, setLista] = useState<any>([]);
    const [selectedItem, setSelectedItem] = useState<any>(null);

    useEffect(() => {
        if (idSelector >= 0) {
            const item = lista.find((item: any) => item.DataBeanProperties.IDEmploymentBonus === idSelector);
            if (item) {
                setSelectedItem(item);
            }
        }
    }, [idSelector, lista]);

    useEffect(() => {
        getLista();
    }, []);

    const setValue = (e: any) => {
        const selectedID = e.target.value === "" ? null : parseInt(e.target.value);
        const selected = lista.find((item: any) => item.DataBeanProperties.IDEmploymentBonus === selectedID); // Encontrar el objeto completo
        setSelectedItem(selected);
        onChange(selected?.DataBeanProperties || null); // Enviar el objeto completo o null
    };

    const getLista = () => {
        TaxHold.instance.getEmployeePaymentsTypeCatalog().then(
            (resp) => {
                setLista(resp);
            }
        );
    };

    return (
        <>
            <label>{label ?? "Proceso"}</label>
            <select className="form-select" value={selectedItem?.DataBeanProperties.IDEmploymentBonus || ""} onChange={setValue}>
                <option value="">...</option>
                {lista &&
                    lista.map((item: any, index: number) => {
                        return (
                            <option key={index} value={item.DataBeanProperties.IDEmploymentBonus}>
                                {item.DataBeanProperties.Code} - {item.DataBeanProperties.Name}
                            </option>
                        );
                    })
                }
            </select>
        </>
    );
};
