import { useEffect, useState } from "react";
import { _RemoteAbstractAction, _single } from "../../../../../../../core/services/dataBean/EntityCatalog";
import { PrintJson } from "../../../../../../theme/Component/PrintJson";
import { SelectAccountantTree } from "../../../../../../contabilidad/catalogo-cuentas/AccountantTree/SelectAccountantTree";
import useDataBean from "../../../../../../../core/services/dataBean/useDataBean";
import { Slide, toast } from "react-toastify";
import { GenericSelect } from "../../../../../editors/GenericSelect";
import { InputDate } from "../../../../../../theme/Input/InputDate";
import { SelectorDocumentFactory } from "../../../../../../contabilidad/bodegaDocumentos/SelectorDocumentFactory";
import { CostingCenterSelect } from "../../../../../../erp/cellEditor/CostingCenter/CostingCenterSelect";
import { AccountEditor } from "../../../../../../editor/AccountEditor";
import { SelectFunctionalID } from "../../../../../../admin/configuracion/functionalID/SelectFunctionalID";
import { ProjectSelect } from "../../../../../editors/ProjectSelect";
import { SelectBusinessCore } from "../../../../../../erp/cellEditor/BusinessCore/SelectBusinessCore";
import { EmptyInput } from "../../../../../editors/EmptyInput";
import { BasicButton } from "../../../../../../theme/Component/BasicButton";
import '../../../../../css/DataFilter5.css';

interface Props {
    idApplicationInvoker: number,
    properties: any;
    data: any;
    setData: any;
    lastStep:boolean;
}
export const DataFilter5 = ({ idApplicationInvoker, properties,lastStep, data, setData }: Props) => {

    const { instance } = useDataBean(_RemoteAbstractAction);

    const [form, setForm] = useState<any>({});
    const [required, setRequired] = useState<any>([]);

    useEffect(() => {
        if (properties) {
            let mapa = {};
            let req = [];
            for (const key in properties) {
                let obj = properties[key];


                mapa[key] = obj.DefaultValue;
                if (obj.Required == true) {
                    req.push(key);
                }


            }
            setRequired(req);
            console.table(mapa);
           //console.log(req);
            setForm(mapa);
        }
    }, [properties])

    const handleChange = (e: any) => {
        setForm(_single.handleFormChange(form, e));
    }
    const fireEvent = (e: any, nameEvent) => {

        instance.fireAbstractStepEvent(idApplicationInvoker, nameEvent, form).then(
            (resp: any) => {
               //console.log(resp);

                setForm(resp);
            }
        ).catch(err => toast.error(err));

    }
    const submitForm = () => {
        if (setData)
            setData(form);
    }



    return (

        <div className="row">
            {/* <div className="col-md-12">
                <PrintJson json={properties}/>
            </div> */}
            <div className="col-md-12">
                {/* table-nowrap table-striped-columns mb-0  */}
                <table className="table tab">
                    <thead className="">
                        <tr>
                            <th style={{ width: '10%', textAlign: 'center' }}>Parámetro</th>
                            <th style={{ width: '20%' }}>Desde</th>
                            <th style={{ width: '20%' }}>Hasta</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="alingitems"><strong>Vigencia</strong></td>
                            <td><input type="number" value={form.YearFrom} name="YearFrom" id="YearFrom" className="form-control" onChange={handleChange} onBlur={(e) => { fireEvent(e, "YEAR_MONTH_EVT") }} /></td>
                            <td><input type="number" value={form.YearUpto} name="YearUpto" id="YearUpto" className="form-control" onChange={handleChange} onBlur={(e) => { fireEvent(e, "YEAR_MONTH_EVT") }} /></td>
                        </tr>
                        <tr>
                            <td className="alingitems"><strong>Mes</strong></td>
                            <td>
                                <GenericSelect idSelect={form.MonthFrom} name="MonthFrom" onChange={handleChange} lista={properties.MonthFrom?.Options} onBlurEvent={(e) => { fireEvent(e, "YEAR_MONTH_EVT") }} />
                            </td>
                            <td>
                                <GenericSelect idSelect={form.MonthUpto} name="MonthUpto" onChange={handleChange} lista={properties.MonthUpto?.Options} onBlurEvent={(e) => { fireEvent(e, "YEAR_MONTH_EVT") }} />
                            </td>
                        </tr>
                        <tr>
                            <td className="alingitems"><strong>Fecha</strong></td>
                            <td>
                                <InputDate label="" name="FromDate" value={form.FromDate} setDate={(e) => { setForm({ ...form, "FromDate": e }) }} />
                            </td>
                            <td>
                                <InputDate label="" name="UptoDate" value={form.UptoDate} setDate={(e) => { setForm({ ...form, "UptoDate": e }) }} />
                            </td>
                        </tr>
                        <tr>
                            <td className="alingitems"><strong>Nivel</strong></td>
                            <td>
                                <GenericSelect idSelect={form.Level} name="Level" onChange={handleChange} lista={properties.Level?.Options} />
                            </td>
                            <td><EmptyInput /></td>
                        </tr>
                        <tr>
                            <td className="alingitems"><strong>Cuenta</strong></td>
                            <td>
                                <SelectAccountantTree label="" onChange={(e) => { setForm({ ...form, "AccountantIDFrom": e.JsonCode }) }} />
                            </td>
                            <td>
                                <SelectAccountantTree label="" onChange={(e) => { setForm({ ...form, "AccountantIDUpto": e.JsonCode }) }} />
                            </td>
                        </tr>
                        <tr>
                            <td className="alingitems"><strong>Tipo de Documento</strong></td>
                            <td>
                                <SelectorDocumentFactory label="" onChange={(e) => { setForm({ ...form, "DocumentFactoryClass": e.DocumentFactory }) }} />
                            </td>
                            <td><EmptyInput /></td>
                        </tr>
                        <tr>
                            <td className="alingitems"><strong>Centro de Costo</strong> </td>
                            <td>
                                <CostingCenterSelect label="" idSelector={form.CostingCenterCode} returnType={'Object'} onChange={(e) => { setForm({ ...form, "CostingCenterCode": e.Code }) }} />
                            </td>
                            <td><EmptyInput /></td>
                        </tr>
                        <tr>
                            <td className="alingitems"><strong>Tipo Proceso</strong> </td>
                            <td>
                                <GenericSelect idSelect={form.ProcessType} name="ProcessType" onChange={handleChange} lista={properties.ProcessType?.Options} />
                            </td>
                            <td><EmptyInput /></td>
                        </tr>
                        <tr>
                            <td className="alingitems"><strong>Organización</strong></td>
                            <td>
                                <GenericSelect idSelect={form.IDCompany} name="IDCompany" onChange={handleChange} lista={properties.IDCompany?.Options} />
                            </td>
                            <td><EmptyInput /></td>
                        </tr>
                        <tr>
                            <td className="alingitems"><strong>Libro Contable</strong></td>
                            <td>
                                <GenericSelect idSelect={form.IDAccountantBook} name="IDAccountantBook" onChange={handleChange} lista={properties.IDAccountantBook?.Options} />
                            </td>
                            <td><EmptyInput /></td>
                        </tr>
                        <tr>
                            <td className="alingitems"><strong>Tercero</strong></td>
                            <td>
                                <AccountEditor idAccount={form.IDAccount} label="" onChange={(e) => { setForm({ ...form, "IDAccount": e.IDAccount }) }} />
                            </td>
                            <td><EmptyInput /></td>
                        </tr>
                        <tr>
                            <td className="alingitems"><strong>Proceso</strong></td>
                            <td>
                                <input type="number" value={form.IDProcess} name="IDProcess" id="IDProcess" className="form-control" onChange={handleChange} />
                            </td>
                            <td><EmptyInput /></td>
                        </tr>
                        <tr>
                            <td className="alingitems"><strong>Área Funcional</strong></td>
                            <td>
                                <SelectFunctionalID label="" onChange={(e) => { setForm({ ...form, "IDFunctionalID": e.IDLn }) }} />
                            </td>
                            <td><EmptyInput /></td>
                        </tr>
                        <tr>
                            <td className="alingitems"><strong>Proyecto</strong></td>
                            <td>
                                <ProjectSelect label="" idSelect={form.IDProject} name="IDProject" onChange={handleChange} />
                            </td>
                            <td><EmptyInput /></td>
                        </tr>
                        <tr>
                            <td className="alingitems"><strong>Área de Negocio</strong></td>
                            <td>
                                <SelectBusinessCore label="" idSelector={form.IDBusinessCore} onChange={(e) => { setForm({ ...form, "IDBusinessCore": e }) }} />
                            </td>
                            <td><EmptyInput /></td>
                        </tr>
                        <tr>
                            <td className="alingitems"><strong>Tipo de Operación</strong></td>
                            <td>
                                <GenericSelect idSelect={form.OperationType} name="OperationType" onChange={handleChange} lista={properties.OperationType?.Options} />
                            </td>
                            <td><EmptyInput /></td>
                        </tr>
                        <tr>
                            <td className="alingitems"><strong>Formato Archivo</strong></td>
                            <td>
                                <GenericSelect idSelect={form.FileFormatType ?? properties.FileFormatType.DefaultValue} name="FileFormatType" onChange={handleChange} lista={properties.FileFormatType?.Options} />
                            </td>
                            <td>
                                <EmptyInput />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            {!lastStep &&
                <div className="col-md-12">
                    <BasicButton icon="ri-send-plane-2-line" clase="primary" eventClick={submitForm}>Siguiente</BasicButton>
                </div>
            }
                {/* <PrintJson json={form} /> */}
        </div>
    )

}