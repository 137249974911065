import { env } from "../../env";
import api from "./api/api";


export class BpmServiceall {
  private url: string = env.REACT_APP_ENDPOINT + env.REACT_APP_DEPLOY + env.REACT_APP_SERVICE;
  //THconstantstvalue
  public getTHConstantsValueCatalogPorPropiedad(IDSuppConstants: string | null, object: number | null, value: any) {
    const parametros = {
      ServiceName: "TalentERP",
      MethodHash: "java.util.List_getTHConstantsValueCatalogPorPropiedad_String_Object_Number",
      ArgumentList: [IDSuppConstants, object, value],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public updateTHConstantsValue(bean: any) {
    const parametros = {
      ServiceName: "TalentERP",
      MethodHash: "com.orange.people.talent.bean.THConstantsValue_updateTHConstantsValue_com.orange.people.talent.bean.THConstantsValue",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: 'com.orange.people.talent.bean.THConstantsValue'
        }
      ],
    };

    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  //
  //TYPO CURSO
  public getCourseTopicCatalogPorPropiedades(Map: any, maxRows: null) {
    const parametros = {
      ServiceName: "CourseERP",
      MethodHash: "java.util.List_getCourseTopicCatalogPorPropiedades_java.util.Map_Number",
      ArgumentList: [Map, maxRows],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public updateCourseTopic(bean: any) {
    const parametros = {
      ServiceName: "CourseERP",
      MethodHash: "com.orange.people.course.bean.CourseTopic_updateCourseTopic_com.orange.people.course.bean.CourseTopic",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: 'com.orange.people.course.bean.CourseTopic'
        }
      ],
    };

    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  //FINAL CURSO
  //Agregar curso a mi bandeja

  public addAccountToCourse(idAccount: any, idCourse:any) {
    const parametros = {
      ServiceName: "CourseERP",
      MethodHash: "com.orange.people.course.bean.CourseAccount_addAccountToCourse_Number_Number",
      ArgumentList: [ idAccount, idCourse],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  //TIPO RESOURCE
  public getTopicResourceCatalogPorPropiedades(Map: any, maxRows: null) {
    const parametros = {
      ServiceName: "CourseERP",
      MethodHash: "java.util.List_getTopicResourceCatalogPorPropiedades_java.util.Map_Number",
      ArgumentList: [Map, maxRows],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public updateTopicResource(bean: any) {
    const parametros = {
      ServiceName: "CourseERP",
      MethodHash: "com.orange.people.course.bean.TopicResource_updateTopicResource_com.orange.people.course.bean.TopicResource",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: 'com.orange.people.course.bean.TopicResource'
        }
      ],
    };

    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  //FIN RESOURCE
  //Riskitem
  public getRiskItemCatalogPorPropiedad(IDSuppConstants: string | null, object: number | null, value: any) {
    const parametros = {
      ServiceName: "SupplierERP",
      MethodHash: "java.util.List_getRiskItemCatalogPorPropiedad_String_Object_Number",
      ArgumentList: [IDSuppConstants, object, value],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public updateRiskItem(bean: any) {
    const parametros = {
      ServiceName: "SupplierERP",
      MethodHash: "com.orange.finance.supplier.bean.RiskItem_updateRiskItem_com.orange.finance.supplier.bean.RiskItem",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: 'com.orange.finance.supplier.bean.RiskItem'
        }
      ],
    };

    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public deleteRiskItem(IDForm: number | null) {
    const parametros = {
      ServiceName: "SupplierERP",
      MethodHash: "boolean_deleteRiskItem_Number",
      ArgumentList: [IDForm],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  //
  public deleteTHConstantsValue(IDForm: number | null) {
    const parametros = {
      ServiceName: "TalentERP",
      MethodHash: "boolean_deleteTHConstantsValue_Number",
      ArgumentList: [IDForm],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public getSuppConstantsValueCatalogPorPropiedad(IDSuppConstants: string | null, object: number | null, value: any) {
    const parametros = {
      ServiceName: "SupplierERP",
      MethodHash: "java.util.List_getSuppConstantsValueCatalogPorPropiedad_String_Object_Number",
      ArgumentList: [IDSuppConstants, object, value],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public updateSuppConstantsValue(bean: any) {
    const parametros = {
      ServiceName: "SupplierERP",
      MethodHash: "com.orange.finance.supplier.bean.SuppConstantsValue_updateSuppConstantsValue_com.orange.finance.supplier.bean.SuppConstantsValue",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: 'com.orange.finance.supplier.bean.SuppConstantsValue'
        }
      ],
    };

    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public deleteSuppConstantsValue(IDForm: number | null) {
    const parametros = {
      ServiceName: "SupplierERP",
      MethodHash: "boolean_deleteSuppConstantsValue_Number",
      ArgumentList: [IDForm],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }


  //servicios formularios.tsx

  public getFormCatalog(IDForm: number | null) {
    const parametros = {
      ServiceName: "BpmService",
      MethodHash: "java.util.List_getFormCatalog_Number",
      ArgumentList: [IDForm],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public deleteForm(IDForm: number | null) {
    const parametros = {
      ServiceName: "BpmService",
      MethodHash: "Integer_deleteForm_Number",
      ArgumentList: [IDForm],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }


  public updateForm(bean: any) {
    const parametros = {
      ServiceName: "BpmService",
      MethodHash: "com.quickbpm.bean.Form_updateForm_com.quickbpm.bean.Form",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: 'com.quickbpm.bean.Form'
        }
      ],
    };

    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public updateFormClass(bean: any) {
    const parametros = {
      ServiceName: "BpmService",
      MethodHash: "com.quickbpm.bean.FormClass_updateFormClass_com.quickbpm.bean.FormClass",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: 'com.quickbpm.bean.FormClass'
        }
      ],
    };

    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public getResponseValueForForm(IDResponseValue: number | null) {
    const parametros = {
      ServiceName: "BpmService",
      MethodHash: "java.util.List_getResponseValueForForm_Number",
      ArgumentList: [IDResponseValue],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public getResponseValue(IDResponseValue: number | null) {
    const parametros = {
      ServiceName: "BpmService",
      MethodHash: "com.quickbpm.bean.ResponseValue_getResponseValue_Number",
      ArgumentList: [IDResponseValue],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public deleteResponseValue(IDResponseValue: number | null) {
    const parametros = {
      ServiceName: "BpmService",
      MethodHash: "Integer_deleteResponseValue_Number",
      ArgumentList: [IDResponseValue],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }


  public updateResponseValue(bean: any) {
    const parametros = {
      ServiceName: "BpmService",
      MethodHash: "com.quickbpm.bean.ResponseValue_updateResponseValue_com.quickbpm.bean.ResponseValue",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: 'com.quickbpm.bean.ResponseValue'
        }
      ],
    };

    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public getWorkGroupMemberCatalog(idLnFunctional: number | null, idOffice: number | null) {
    const parametros = {
      ServiceName: "BpmService",
      MethodHash: "java.util.List_getWorkGroupMemberCatalog_Number_Number_Number",
      ArgumentList: [idLnFunctional, idOffice, 0],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public addWorkGroupMember(idLn: number | null, idAccount: number | null, idOffice: number | null) {
    const parametros = {
      ServiceName: "BpmService",
      MethodHash: "com.quickbpm.bean.WorkGroupMember_addWorkGroupMember_Number_Number_Number",
      ArgumentList: [idLn, idAccount, idOffice],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public removeWorkGroupMember(idLn: number | null, idAccount: number | null, idOffice: number | null) {
    const parametros = {
      ServiceName: "BpmService",
      MethodHash: "com.quickbpm.bean.WorkGroupMember_removeWorkGroupMember_Number_Number_Number",
      ArgumentList: [idLn, idAccount, idOffice],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public assignProcedureImpToGroupMember(idProcedureImp: number | null, idAccount: number | null, idAllocator: number | null) {
    const parametros = {
      ServiceName: "BpmService",
      MethodHash: "Number_assignProcedureImpToGroupMember_Number_Number_Number",
      ArgumentList: [idProcedureImp, idAccount, idAllocator],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

}
