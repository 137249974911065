import { useEffect, useState } from "react"
import { AccountEditor } from "../../../../../editor/AccountEditor"
import useDataBean from "../../../../../../core/services/dataBean/useDataBean"
import { _UBLInvoice } from "../../../../../../core/services/dataBean/EntityCatalog"
import { InputDate } from "../../../../../theme/Input/InputDate"
import { BasicButton } from "../../../../../theme/Component/BasicButton"
import { BasicTable, MASK_DATE, MASK_MONEY, MASK_NUMBER } from "../../../../../theme/Component/BasicTable"
import { ListMenu } from "../../../../../theme/Component/ListMenu"
import { Modal } from "../../../../../theme/Component/Modal"
import { FileService } from "../../../../../../core/services/FileService"
import { HistoryUBLInvoice } from "../HistoryUBLInvoice"
import { Singleton } from "../../../../../../core/services/Singleton"
import { CreateUBLInvoiceItems } from "./CreateUBLInvoiveItem"
import { toast } from "react-toastify"

interface Props {
    _onChange2?: Function;
    _idAccount?: number;
}

export const SearchUBLInovice = ({ _onChange2, _idAccount }: Props) => {

    const _ubl = useDataBean(_UBLInvoice);
    const fileService = new FileService();
    const single = Singleton.getInstance();

    const [idAccount, setIDAccount] = useState<number>(NaN);
    const [fromDate, setFromDate] = useState<string>(null);
    const [uptoDate, setUptoDate] = useState<string>(null);
    const [row, setRow] = useState<any>({});
    const [fileType, setFileType] = useState<string>(null);
    const [donwloadURL, setDonwloadURL] = useState<string>(null);
    const [urlFile, setUrlFile] = useState<string | null>(null);

    const [modal, setModal] = useState<any>({ name: 'Items de la factura', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalA, setModalA] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: false, format: 'modal-lg' });
    const [modalB, setModalB] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: false, format: 'modal-lg' });

    useEffect(() => {
        setFileType(row.MediaContext?.type || row.Media?.split('.').pop())
        setDonwloadURL(fileService.getUrlFile(row.MediaContext, row.Media))
    }, [row])

    useEffect(() => {
        if (donwloadURL) {
            const fetchPdf = async () => {
                try {
                    const data = await fileService.getPdfAsBase642(donwloadURL);
                    setUrlFile(data);
                } catch (error) {
                    console.error('Error loading PDF:', error);
                }
            };

            fetchPdf();
        }
    }, [donwloadURL]);

    const head = [
        { title: 'ID Factura', property: 'IDUBLInvoice', visible: true, mask: MASK_NUMBER },
        { title: 'Nit', property: 'AccountSupplierNit', visible: true },
        { title: 'Proveedor', property: 'AccountSupplierName', visible: true },
        { title: 'No Factura', property: 'InvoiceID', visible: true },
        { title: 'Líneas Factura', property: 'LineCountNumeric', visible: true },
        { title: 'Valor Total', property: 'TotalInvoiceAmount', visible: true, mask: MASK_MONEY },
        { title: 'Impuesto', property: 'TaxAmount', visible: true },
        { title: 'Descuentos', property: 'DiscountAmount', visible: true },
        { title: 'Fecha Factura', property: 'IssueDate', visible: true, mask: MASK_DATE },
        { title: 'Entrada Almacén', property: 'EAFMnemonic', visible: true, mask: MASK_DATE },
        { title: 'Consecutivo Entrada', property: 'EAFConsecutive', visible: true },
        { title: 'Fecha Entrada', property: 'EAFDate', visible: true, mask: MASK_DATE },
        { title: 'Fecha Procesamiento', property: 'Since', visible: true, mask: MASK_DATE },
        { title: 'Estado Factura', property: 'DocumentStateName', visible: true },
        { title: 'Tipo Factura', property: 'SourceTypeName', visible: true },
        { title: 'Estado EAF', property: 'EAFDocumentStateName', visible: true },
        { title: 'Estado Salidas', property: 'OutputCostStateName', visible: true },
        { title: 'Documento Soporte', property: 'IsDocumentSupport', visible: true },
        { title: 'Concepto de Ingreso', property: 'AccountantConceptName', visible: true },
        { title: 'Cuenta Contable', property: 'AccountantIDName', visible: true },
        { title: 'Cuenta Puente Ingreso Bienes', property: 'CreditAccountantIDName', visible: true },
        { title: 'Pertenece a Proceso', property: 'ProcessTypeName', visible: true },
    ]

    const getLista = () => {
        _ubl.instance.getUblInvoiceCatalog(_idAccount ?? idAccount, fromDate, uptoDate, null).then(
            (resp) => {
               //console.log(resp)
                _ubl.setLista(resp)
            }
        ).catch(
            err => {
               console.error(err)
            }
        )
    }

    const validateData = () => {
        if (_idAccount ? !_idAccount : !idAccount) {
            toast.error("Debe seleccionar un proveedor")
        } else if (!fromDate) {
            toast.error("Fecha inicial no valida")
        } else if (!uptoDate) {
            toast.error("Fecha final no valida")
        } else {
            getLista();
        }
    }

    const listaBotonesItems = () => {
        const botones = [
            { titulo: 'Ver eventos en la Factura', icono: 'ri-history-line', visible: true, evento: () => { setModalA({ ...modalA, "view": true, "name": "Eventos de la Factura" }) } },
            { titulo: 'ver Items de la Factura', icono: 'ri-list-ordered', visible: true, evento: () => { setModalB({ ...modalB, "view": true, "name": "Items de la Factura" }) } },
        ]

        return (
            <ListMenu listaBotones={botones} />
        )
    }

    const setRowBean = (bean: any) => {
        if (_onChange2) {
            _onChange2(bean)
        }
        setRow(bean)
    }

    return (
        <>
            <div className="row mb-2">
                <div className="col-md-4">
                    <AccountEditor disabled={(typeof _idAccount === "number" && _idAccount >= 0)} idAccount={_idAccount ?? _ubl.bean.IDAccount} label="Proveedor" onChange={({ IDAccount }: any) => { setIDAccount(IDAccount) }} />
                </div>
                <div className="col-md-3">
                    <InputDate notDate label="Desde" name="issueDate" value={""} setDate={(e) => { setFromDate(e) }} />
                </div>
                <div className="col-md-3">
                    <InputDate notDate label="Hasta" name="accountantDate" value={""} setDate={(e) => { setUptoDate(e) }} />
                </div>
                <div className="col-md-2 d-flex align-items-end">
                    <BasicButton icon="ri-search-2-line" eventClick={validateData}>Buscar</BasicButton>
                </div>
            </div>
            <BasicTable listButtons={listaBotonesItems()} body={_ubl.lista} head={head} rowSet={(e) => { setRowBean(e) }} />

            {modalA.view &&
                <Modal modal={modalA} updateModal={setModalA} >
                    <HistoryUBLInvoice _idUBLInvoice={row.IDUBLInvoice} _isseDate={row.IssueDate} />
                </Modal>
            }

            {modalB.view &&
                <Modal modal={modalB} updateModal={setModalB} >
                    <CreateUBLInvoiceItems idUBLInvoice={row.IDUBLInvoice} issueDate={row.IssueDate} _onChange={() => { getLista() }} />
                </Modal>
            }

            {modal.view &&
                <Modal modal={modal} updateModal={setModal} eventModal={() => { }} >
                    <div className="row">
                        <div className="col-md-12">
                            <div className="iframe-container">
                                {fileType ?
                                    <>
                                        {fileType === 'pdf' && (
                                            urlFile ? (
                                                <iframe name="pdfviwer" src={urlFile} onError={(e) => console.error('Error loading PDF:', e)}></iframe>
                                            ) : (
                                                <p>Cargando PDF...</p>
                                            )
                                        )}
                                        {fileType === "jpg" || fileType === "png" &&
                                            (
                                                <img src={urlFile} alt="Image Preview" width="100%" />
                                            )
                                        }
                                        {fileType !== "pdf" && "jpg" && "png" &&
                                            <>
                                                <a href={donwloadURL} target='_blank'>Clic aquí para descargar el archivo {row.Media}</a>
                                            </>
                                        }
                                    </>
                                    :
                                    <>
                                        No se pudo cargar el Documento
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </Modal>
            }
        </>
    )
}