import { useEffect, useState } from "react";
import { PrintJson } from "../theme/Component/PrintJson";
import useDataBean from "../../core/services/dataBean/useDataBean";
import { _RemoteAbstractAction } from "../../core/services/dataBean/EntityCatalog";
import { toast } from "react-toastify";
import { ExcecuteRemoteAbstract } from "../system/remoteAbstractAction/ExcecuteRemoteAbstract";

interface Props{
    remoteAction:any;
}

export const RenderRemoteAction =({remoteAction}:Props)=>{

    const service = useDataBean(_RemoteAbstractAction);
    const [remoteAbstractAction,setRemote] =useState<any>({});
    
    useEffect(()=>{
        if(remoteAction){
            service.instance.getRemoteAbstractAction(remoteAction.IDRemoteAbstractAction).then(
                (resp:any)=>{
                    setRemote(resp)
                }
            ).catch(err=>toast.error(err));
        }
    },[remoteAction])

    return(
        <div className="row">
            <div className="col">
                {/* <PrintJson json={remoteAbstractAction}/> */}
                <ExcecuteRemoteAbstract remoteAbstractAction={remoteAbstractAction}/>
            </div>
        </div>
    )
}