import { useState, useEffect } from 'react';
import useDataBean from '../../../../../core/services/dataBean/useDataBean';
import { _SubMacroProcess } from '../../../../../core/services/dataBean/EntityCatalog';
import { _ConfigAdmin } from '../../../../../core/services/dataBean/EntityCatalogVdos';


interface Props {
    Name?: number;
    idSelector?: number;
    onChange?: any;
    label?: string;
}

export const SelectonfigAdminbycargo = ({ idSelector, Name, onChange, label }: Props) => {

    const SelectconfigAdmin = useDataBean(_ConfigAdmin)
    const [lista, setLista] = useState<any>([])
    const [id, setID] = useState(null);

    useEffect(() => {
        if (idSelector >= 0) {
            setID(idSelector)
        }
    }, [])

    useEffect(() => {
        getLista();
    }, [Name])

    useEffect(() => {
        onChange(id);
    }, [id])

    const setValue = (e: any) => {
        setID(parseInt(e));
        onChange(id);
    }

    const getLista = () => {
        SelectconfigAdmin.instance.getFunctionalAreaByName(Name, null).then(
            (resp) => {
                setLista(resp)
            }
        )
    }

    return (
        <>
            <label>{label ?? "Proceso"}</label>
            <select className="form-select" value={id} onChange={(e) => { setValue(e.target.value) }}>
                <option value={null}>...</option>

                {lista &&
                    lista.map((item: any, index: number) => {
                        return (
                            <option key={index} value={item.DataBeanProperties.IDLn} >{item.DataBeanProperties.Name}</option>
                        )
                    })
                }
            </select>
        </>
    )
}