import { DataBean } from "../DataBean";



export class BudgetResource extends DataBean {

    public async getBudgetResourceCatalog(idBudgetResource: number | null): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_getBudgetResourceCatalog_Number", [idBudgetResource]);
    }

    public async deleteBudgetResource(idBudgetResource: number | null): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "void_deleteBudgetResource_Number", [idBudgetResource]);
    }

}