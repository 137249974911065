import { useEffect, useState } from "react";
import { _AbilityType, _CashReimbursement, _CashReimbursementItem } from "../../../../../core/services/dataBean/EntityCatalog";
import useDataBean from "../../../../../core/services/dataBean/useDataBean";
import { Singleton } from "../../../../../core/services/Singleton";
import { BasicTable, MASK_DATE, MASK_MONEY } from "../../../../theme/Component/BasicTable";
import { ListMenu } from "../../../../theme/Component/ListMenu";
import { ModalConfirm } from "../../../../theme/Component/ModalConfirm";
import { CashReimbursementStateConstantsEditor } from "./CashReimbursementStateConstants.editor";
import { Modal } from "../../../../theme/Component/Modal";
import { CreateUBLInvoiceItems } from "../../../../contract/etapa-contrato/contrac/ublinvoice/CreateUBLInvoice/CreateUBLInvoiveItem";
import { MakeUBLInvoice } from "../../../../contract/etapa-contrato/contrac/ublinvoice/MakeUBLInvoice";
import { toast } from "react-toastify";
import { BankingAccountEditor } from "../../../../Talentohumano/ParametrosTalentoHumano/ThirdBankingAccount/BankingAccountSelect";
import { ThirdBankingAccountEditor } from "../../../../Talentohumano/ParametrosTalentoHumano/ThirdBankingAccount/ThirdBanking.editor";
import { AccountEditor } from "../../../../editor/AccountEditor";



interface Props {
    _idCashReimbursement: number;
    _cashReimbursementDate: any;
    _bean: any;
}

export const CashReimbursementItem = ({ _cashReimbursementDate, _idCashReimbursement, _bean }: Props) => {

    const BORRADOR = 5;
    const APROBADA = 8;


    const single = Singleton.getInstance();
    const _cashItem = useDataBean(_CashReimbursementItem);
    const cashReimbursment = useDataBean(_CashReimbursement);

    const [obs, setObs] = useState<string>(null);
    const [state, setState] = useState<number>(0);
    const [accountBank, setAccountBank] = useState<number>(null);
    const [obsAdd, setObsAdd] = useState<string>("");
    const [fileNum, setFileNum] = useState<string>("");
    const [selectedUBL, setSelectedUBL] = useState<any>({});


    const [modalAddUBL, setModalAddUBL] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: "modal-xl", });
    const [modalConfAdd, setModalConfAdd] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: "modal-md", })
    const [modalC, setModalC] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-md' });
    const [modal, setModal] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });

    useEffect(() => {
        if (!isNaN(state)) {
            getLista();
        }
    }, [state, _idCashReimbursement])

    const getLista = () => {
        _cashItem.instance.getCashReimbursementItemCatalog(_idCashReimbursement, _cashReimbursementDate, state).then(
            // _cashItem1.instance.getAbilityTypeCatalog(null).then(
            (resp) => {
                let x = resp;
                x.forEach(ubls => {
                    ubls.DataBeanProperties.DocumentFE = (ubls.DataBeanProperties.DocMnemonic ?? "") + " " + (ubls.DataBeanProperties.DocConsecutive ?? "");
                    ubls.DataBeanProperties.CDPDocumentFE = (ubls.DataBeanProperties.CDPMnemonic ?? "") + " " + (ubls.DataBeanProperties.CDPConsecutive ?? "");
                    ubls.DataBeanProperties.RPMDocumentFE = (ubls.DataBeanProperties.RPMnemonic ?? "") + " " + (ubls.DataBeanProperties.RPConsecutive ?? "");
                    ubls.DataBeanProperties.ObligationFE = (ubls.DataBeanProperties.OBMnemonic ?? "") + " " + (ubls.DataBeanProperties.OBConsecutive ?? "");
                });


                _cashItem.setLista(resp)
            }
        )
    }

    const deleItem = () => {
        _cashItem.instance.releaseUBLInvoiceFromCashReimbursement(_cashItem.bean.IDUBLInvoice, _cashItem.bean.IssueDate, _idCashReimbursement, _cashReimbursementDate, single.getAccountID(), obs).then(
            (resp) => {
                getLista();
                setModalC({ ...modalC, "view": false })
            }
        )
    }

    const head = [
        { title: 'ID', property: 'IDCashReimbursementItem', visible: true },
        { title: 'No de Factura', property: 'InvoiceID', visible: true },
        { title: 'Fecha Factura', property: 'IssueDate', visible: true, mask: MASK_DATE },
        { title: 'Valor Factura', property: 'UBLInvoiceValue', visible: true, mask: MASK_MONEY },
        { title: 'Proveedor', property: 'SupplierName', visible: true },
        { title: 'Estado', property: 'StateName', visible: true },
        { title: 'Documento Contabilización', property: 'DocumentFE', visible: true, },
        { title: 'CDP', property: 'CDPDocumentFE', visible: true },
        { title: 'Registro Presupuestal/Compromiso', property: 'RPMDocumentFE', visible: true },
        { title: 'Obligación', property: 'ObligationFE', visible: true },
        { title: 'Estado del Proceso', property: 'OrderStateName', visible: true },
        { title: 'Creado por', property: 'EmployeeName', visible: true },
        { title: 'Fecha Creación', property: 'Since', visible: true },
        { title: 'Cuenta Bancaria del Tercero', property: 'IDThirdBankingAccount', visible: true },
    ]

    const listaBotones = () => {
        const botones = [
            { titulo: 'Contabilizar Factura', icono: 'ri-file-list-line', visible: (_cashItem.bean.OrderState == BORRADOR), evento: () => { setModal({ ...modal, "name": `Contabilizar Factura`, "view": true }) } },
            { titulo: 'Eliminar Factura', icono: 'ri-delete-bin-line', visible: (_cashItem.bean.OrderState == BORRADOR), evento: () => { setModalC({ ...modalC, "view": true, "name": "Eliminar Factura" }) } },
        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }

    const iconButtonsItem = [
        { nombre: 'Agregar Factura', visible: true, icono: 'ri-file-add-line', evento: () => { setModalAddUBL({ ...modalAddUBL, "view": true }) } }
    ]

    const addUBLInvoice = () => {
       //console.log(selectedUBL.IDUBLInvoice, selectedUBL.IssueDate, _bean.IDCashReimbursement, _bean.Since, obs, single.getAccountID(), fileNum, accountBank)
        cashReimbursment.instance.addUBLInvoiceToCashReimbursement(selectedUBL.IDUBLInvoice, selectedUBL.IssueDate, _bean.IDCashReimbursement, _bean.Since, obs, single.getAccountID(), fileNum, accountBank).then(
            (resp) => {
                setModalConfAdd({ ...modalConfAdd, "view": false });
                getLista();
                setSelectedUBL({});
            }
        ).catch(
            err => {
                toast.error(err)
            }
        )
    }

    const closeModalAdd = () => {
        if (!selectedUBL.IDUBLInvoice) {
            toast.error("Debe seleccionar una factura valida")
        } else {
            setFileNum("");
            setObsAdd("");
            setModalAddUBL({ ...modalAddUBL, "view": false });
            setModalConfAdd({ ...modalConfAdd, "view": true, "name": "Confirmar Agregar Factura" });
        }
    }

    const validateData = () => {
        if (accountBank === null || accountBank === undefined) {
            toast.error("Debe seleccionar una Cuenta Bancaria de Tercero");
            return false;
        } else {
            addUBLInvoice();
            return true;
        }
    }

    return (
        <>
            <div className="row">
                <div className="col-12">
                    <CashReimbursementStateConstantsEditor label="Estado de la Facturas" idSelector={state} onChange={(e) => { setState(e) }} />
                </div>
            </div>
            <BasicTable iconButtons={iconButtonsItem} listButtons={listaBotones()} body={_cashItem.lista} head={head} rowSet={_cashItem.setBean} />

            {modalC.view &&
                <ModalConfirm modal={modalC} updateModal={setModalC} eventModal={() => { deleItem() }} >
                    <div className="row">
                        <div className="col-12">
                            Esta Seguro que desea eliminar el item <br />
                            <label >Observaciones</label>
                            <textarea value={obs} onChange={(e) => { setObs(e.target.value) }} className="form-control" />
                        </div>
                    </div>
                </ModalConfirm>
            }

            {modal.view &&
                <Modal modal={modal} updateModal={setModal} >
                    <CreateUBLInvoiceItems updateFunction={() => { getLista() }} _data={_cashItem.bean} idUBLInvoice={_cashItem.bean.IDUBLInvoice} issueDate={_cashItem.bean.IssueDate} />
                </Modal>
            }

            {modalAddUBL.view &&
                <Modal modal={modalAddUBL} updateModal={setModalAddUBL} onSubmitLabel='Vincular Factura' eventModal={() => { closeModalAdd() }}>
                    <>
                        <MakeUBLInvoice _entry="CashReimbursement" _onChange={(e) => { setSelectedUBL(e); }} />
                    </>
                </Modal>
            }

            {modalConfAdd.view &&
                <ModalConfirm modal={modalConfAdd} updateModal={setModalConfAdd} eventModal={validateData} >
                    <p>Esta seguro de agregar la Facrtua con ID {selectedUBL.IDUBLInvoice} <br /> del proveedor {selectedUBL.AccountSupplierNit} - {selectedUBL.AccountSupplierName}</p>

                    <div className="row">
                        <div className="col-12">
                            <label>No de Radicado</label>
                            <input type='text' value={fileNum} className='form-control' onChange={(e) => { setFileNum(e.target.value) }} />
                        </div>
                        <div className="col-12">
                            <label>Observaciones</label>
                            <textarea value={obsAdd} className='form-control' onChange={(e) => { setObsAdd(e.target.value) }} />
                        </div>
                        <div className="col-12">
                            <ThirdBankingAccountEditor idselectorAccount={selectedUBL.IDAccountSupplier} label="Cuenta Bancaria del Tercero" idSelector={accountBank} onChange={(e) => { setAccountBank(e);  }} />
                        </div>
                    </div>
                </ModalConfirm>
            }
        </>
    )
}