import { useEffect, useState } from "react";
import { AdminService } from "../../../core/services/AdminService";
import { Singleton } from "../../../core/services/Singleton";
import { Modal } from "../../theme/Component/Modal";
import { BasicTable } from "../../theme/Component/BasicTable";
import { BasicPage } from "../../theme/Component/BasicPage";
import { ListMenu } from "../../theme/Component/ListMenu";
import { ModalConfirm } from "../../theme/Component/ModalConfirm";
import { BasicSwitch } from "../../theme/Component/BasicSwitch";
import { useDashboardStore } from "../../pages/hooks/useDashboardStore";
import { RoleGroupItem } from "./RoleGroupItem";
import { AccountEditor } from "../../editor/AccountEditor";
import { SelectFunctionalID } from "../configuracion/functionalID/SelectFunctionalID";
import { SelectRol } from "../select/SelectRol";
import useModal, { MODAL_md } from "../../theme/Component/hooks/useModal";
import { MultiSelect } from "../../theme/Component/MultiSelect";
import { BasicButton } from "../../theme/Component/BasicButton";
import { PrintJson } from "../../theme/Component/PrintJson";

export const RoleGroup = () => {

  const single = Singleton.getInstance()
  const adminService = new AdminService();
  const { setLoading } = useDashboardStore();
  const [lista, setLista] = useState([]);
  const [bean, setBean] = useState<any>({});
  const [modal, setModal] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-md' });
  const [modalConfirm, setModalConfirm] = useState<any>({ name: 'Confirmar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });
  const [modalItem, setModalItem] = useState<any>({ name: 'Usuarios', tabIndex: -1, view: false, btnClose: true, btnSubmit: false, format: 'modal-md' });
  const service: AdminService = new AdminService();
  const [types, setTypes] = useState<any[]>([]);
  const [listaID, setListaID] = useState<any>([]);

  useEffect(() => {

    getProcessTypeConstants();
  }, [])

  const getLista = () => {
    let prop = {
      State: 1,
    }

    setLoading(true);
    adminService.getRoleGroupsCatalogRender(null, prop).subscribe(
      (resp: any) => {

        if (resp.DataBeanProperties.ObjectValue) {
          let tmp = resp.DataBeanProperties.ObjectValue;
          tmp.forEach((element: any) => {
            element.DataBeanProperties.EntityName = single.getEntityName(element);

            element.DataBeanProperties.JsonProcess = element.DataBeanProperties.JsonProcess ? JSON.parse(element.DataBeanProperties.JsonProcess) : [];
            element.DataBeanProperties.ProcessNames = getProcessNames(element.DataBeanProperties.JsonProcess);

          });
          setLista(tmp);
        }
        setLoading(false);
      }
    );
  };

  const updateItem = () => {
   //console.log(bean)
    setLoading(true);
    adminService.updateRoleGroups(bean).subscribe(
      (resp: any) => {
        setLoading(false);
        if (resp.DataBeanProperties.ObjectValue) {
          getLista();
          setModal({ ...modal, 'view': false });
        }
      }
    );
  }

  const deleteItem = () => {
    bean.State = 2;
   //console.log(bean);
    updateItem();
    setModalConfirm({ ...modalConfirm, 'view': false });
  }

  const agregarItem = () => {
    let b = {
      Name: '',
      State: 1,
      IDEmployee: single.getAccountID(),
      IDAccount: undefined,
      IDFunctionalID: undefined,
    }
    setBean(b);
    setModal({ ...modal, 'view': true });
  }

  const head = [
    { title: 'ID', property: 'IDRoleGroups' },
    { title: 'Nombre', property: 'Name' },
    { title: 'Funcionario', property: 'EntityName' },
    { title: 'Área', property: 'NameArea' },
    { title: 'Proceso', property: 'ProcessNames' },
    { title: 'Fecha de registro', property: 'Since' },
  ]

  const listaBotones = () => {
    const botones = [
      { titulo: 'Editar', icono: 'ri-edit-2-line', visible: single.canEdit(), evento: () => { setModal({ ...modal, "view": true }) } },
      { titulo: 'Eliminar', icono: 'ri-delete-back-2-line', visible: single.canDelete(), evento: () => { setModalConfirm({ ...modalConfirm, "view": true }) } },
      { titulo: 'Roles del Grupo', icono: 'ri-file-list-line', visible: true, evento: () => { setModalItem({ ...modalItem, "view": true, "name": `Roles del grupo ${bean.Name}` }) } },
    ]
    return (
      <ListMenu listaBotones={botones} />
    )
  }

  const iconButtonsItem = [
    { nombre: 'Agregar', visible: single.canCreate(), icono: 'ri-file-add-line', evento: agregarItem }
  ]
  const getProcessTypeConstants = () => {
    service.getProcessTypeConstants().subscribe((resp: any) => {
     //console.log("Application", resp);
      if (resp.DataBeanProperties.ObjectValue) {
        let temp = resp.DataBeanProperties.ObjectValue;
        let lista = [];
        let ids = [];
        temp.forEach((element: any) => {
          lista.push(
            {
              id: element.DataBeanProperties.Value,
              Name: element.DataBeanProperties.Property
            }
          )
          ids.push(element.DataBeanProperties.Value);
        });
        setTypes(lista);
        setListaID(ids);
        getLista();
      }
    });
  };
  const getProcessNames = (item: any[]) => {

   //console.log(item);

    let texto = '';
    types.forEach((ele: any) => {
      if (item.includes(ele.id)) {
       //console.log(ele.Name);

        texto += " - " + ele.Name;
      }
    })

    return texto;

  }
  return (
    <>

      <BasicPage titulo="Grupos de Roles">
        <div className="row">
          <div className="col-md-12">
            <BasicTable listButtons={listaBotones()} iconButtons={iconButtonsItem} head={head} body={lista} rowSet={setBean}></BasicTable>
          </div>
        </div>
      </BasicPage>

      {modal.view &&
        <Modal modal={modal} updateModal={setModal} eventModal={updateItem}>
          <div className="row">
            <div className="col-md-12">
              <label className="form-label">Nombre</label>
              <input type="text" className="form-control" value={bean.Name} onChange={(e) => { setBean({ ...bean, 'Name': e.target.value }) }} />
            </div>
            <div className="col-12">
              <AccountEditor label="Jefe del Grupo" idAccount={bean.IDAccount} onChange={({ IDAccount }) => { setBean({ ...bean, "IDAccount": IDAccount }) }}></AccountEditor>
            </div>
            <div className="col-12">
              <SelectFunctionalID functionalIDLn={bean.IDFunctionalID} onChange={(e: any) => setBean({ ...bean, 'IDFunctionalID': e.IDLn })} />
            </div>
            <div className="col-md-12">
              <SelectRol idSelector={bean.IDDefaultRole} onChange={(e) => { setBean({ ...bean, "IDDefaultRole": e }) }} />
            </div>
            <div className="col-md-12">
              <MultiSelect label="Proceso" lista={types} selectedID={bean.JsonProcess} getLista={(e) => { setBean({ ...bean, "JsonProcess": JSON.stringify(e) }) }} />
            </div>
          </div>
        </Modal>
      }

      {modalConfirm.view &&
        <ModalConfirm modal={modalConfirm} updateModal={setModalConfirm} eventModal={deleteItem}>
          <p>Eliminar grupo de Roles {bean.Name}</p>
        </ModalConfirm>
      }
      {modalItem.view &&
        <Modal modal={modalItem} updateModal={setModalItem}>
          <RoleGroupItem idRoleGroup={bean.IDRoleGroups}></RoleGroupItem>
        </Modal>
      }

    </>
  );
};
