import { useEffect, useState } from "react";
import { FileService } from "../../core/services/FileService";

interface Props {
    url: string;
    type: string;
}

export const VisorAnyDocument = ({ url, type }: Props) => {

    const file = new FileService();

    const [urlFile, setUrlFile] = useState<string | null>(null);

    useEffect(() => {
        if (url) {
            const fetchPdf = async () => {
                try {
                    const data = await file.getPdfAsBase642(url);
                    setUrlFile(data);
                } catch (error) {
                    console.error('Error loading file:', error);
                }
            };

            fetchPdf();
        }
    }, [url]);

    const renderSwitch = (type: string) => {
        switch (type) {
            case 'pdf':
                return urlFile ? (
                    <iframe
                        name="pdfviewer"
                        src={urlFile}
                        onError={(e) => console.error('Error loading PDF:', e)}
                    ></iframe>
                ) : (
                    <p>Cargando PDF...</p>
                );

            case 'jpeg':
            case 'png':
            case 'jpg':
            case 'svg':
                return (
                    <img
                        src={urlFile ?? url}
                        alt="Image Preview"
                        width="100%"
                    />
                );

            default:
                return (

                    <>
                        <ul className="list-group">
                            <li className="group-item">
                                <a className="btn btn-outline-success" href={url} target='_blank'>Descargar Archivo</a>
                            </li>
                        </ul>
                    </>
                );
        }
    };

    return (
        <div className="row">
            <div className="col-md-12">
                <div className="iframe-container">
                    {renderSwitch(type)}
                </div>
            </div>
        </div>
    );
};
