import { DataBean } from "../DataBean";


export class ConstServices extends DataBean {


    public async getAppointmentManualConstants(): Promise<any> {
        return this.getCustomService("PeopleArt", "java.util.List_getAppointmentManualConstants_Number", [null]);
    }

}