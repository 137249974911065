import { useEffect, useState } from "react";
import { BasicTable } from "../../../../theme/Component/BasicTable";
import { Singleton } from "../../../../../core/services/Singleton";
import { BpmService } from "../../../../../core/services/BpmService";
import { MyEditor } from "../../../../theme/Component/MyEditor";
import { ListMenu } from "../../../../theme/Component/ListMenu";
import { Modal } from "../../../../theme/Component/Modal";
import { ModalConfirm } from "../../../../theme/Component/ModalConfirm";

interface Props {
    IDLnFunctional: number;
}

interface obj {
    Description?: string,
    IDLnFunctionalID?: number,
    State?: number,
    IDBusinessRole?: number,
    Name?: string;
}

export const BusinessRoleCatalog = ({ IDLnFunctional }: Props) => {

    const single = Singleton.getInstance();
    const [lista, setLista] = useState([]);
    const [bean, setBean] = useState<obj>();
    const service = new BpmService();
    const [modal, setModal] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-md' });
    const [modalConfirm, setModalConfirm] = useState<any>({ name: 'Atención!', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });


    useEffect(() => {
        if (IDLnFunctional > 0) {
            getLista();
        }
    }, [IDLnFunctional])

    const head = [
        { title: 'ID', property: 'IDBusinessRole' },
        { title: 'Nombre', property: 'Name' },
        { title: 'Descripción', property: 'Description' },
    ]

    const getLista = () => {
        single.spinner(true)
        service.getBusinessRoleCatalog(IDLnFunctional).subscribe(
            (resp: any) => {
                single.spinner(false)
                if (resp) {
                    setLista(resp.DataBeanProperties.ObjectValue)
                } else {
                   //console.log(resp.DataBeanProperties.ErrorMessage)
                }
            }
        )
    }

    const agregarItem = () => {
        let b = {
            Description: "",
            State: 0,
            IDLnFunctionalID: IDLnFunctional,
            Name: "",
        }
        setBean(b);
        setModal({ ...modal, 'view': true, 'name': 'Agregar Rol' });
    }

    const updateItem = () => {
        setModal({ ...modal, 'view': false });
        single.spinner(true);
        service.updateBusinessRole(bean).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue) {
                    getLista();
                }
                if (bean.IDBusinessRole) {
                    single.update(bean);
                }
                else {
                    single.create(bean);
                }
                single.spinner(false);
            }
        );

    }

    const setRow = (b: any) => {
       //console.log(b);
        setBean(b);
    }

    const eliminarItem = () => {
        single.spinner(true);
        service.deleteBusinessRole(bean.IDBusinessRole).subscribe(
            (resp: any) => {
                if (resp) {
                    getLista();
                }
                single.delete(bean.IDBusinessRole, bean);
                single.spinner(false);
            }
        );
        setModalConfirm({ ...modalConfirm, 'view': false });
    }

    const listaBotones = () => {
        const botones = [

            { titulo: 'Editar', icono: 'ri-file-list-line', visible: single.canEdit(), evento: () => { setModal({ ...modal, 'view': true, 'name': 'Editar Rol' }) } },
            { titulo: 'Eliminar', icono: 'ri-file-user-line', visible: single.canDelete(), evento: () => { setModalConfirm({ ...modalConfirm, "view": true }) } },
        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }

    const iconButtonsItem = [
        { nombre: 'Agregar', visible: single.canCreate(), icono: 'ri-file-add-line', evento: agregarItem }
    ]

    return (
        <>
            {modal.view &&
                <Modal modal={modal} updateModal={setModal} eventModal={updateItem}>
                    <>
                        <div className="row">
                            <div className="col-12">
                                <label >Nombre:</label>
                                <input type="text" className="form-control" value={bean.Name} onChange={(e) => { setBean({ ...bean, "Name": e.target.value }) }} />
                            </div>
                            <div className="col-12">
                                <label >Descripción:</label>
                                <MyEditor val={bean.Description} onChange={(e) => { setBean({ ...bean, "Description": e }) }}></MyEditor>
                            </div>
                        </div>
                    </>
                </Modal>
            }
            <div className="row">

                <BasicTable listButtons={listaBotones()} iconButtons={iconButtonsItem} body={lista} head={head} rowSet={setRow} ></BasicTable>


                {modalConfirm.view &&
                    <ModalConfirm modal={modalConfirm} updateModal={setModalConfirm} eventModal={eliminarItem}>
                        <>
                            {bean.Name}
                        </>
                    </ModalConfirm>
                }
            </div>
        </>
    )
}