import { DataBean } from "../DataBean";

export class AdquisitionProject extends DataBean {
    
    

    public async getAdquisitionProjectCatalogPorPropiedadesRender(props:any,rows:any): Promise<any> {
        return this.getCustomService("FinanceCore", "java.util.List_getAdquisitionProjectCatalogPorPropiedadesRender_java.util.Map_Number", [props,rows]);
    }

    public async getAdquisitionProjectCatalogPorPropiedades(properties:any,maxRows:any): Promise<any> {
        return this.getCustomService("FinanceCore", "java.util.List_getAdquisitionProjectCatalogPorPropiedades_java.util.Map_Number", [properties,maxRows]);
    }
  

}