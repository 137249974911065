import { useEffect, useState } from "react";
import { SelectRol } from "../select/SelectRol";
import { Alert } from "../../theme/Component/Alert";
import { BasicButton } from "../../theme/Component/BasicButton";
import { Singleton } from "../../../core/services/Singleton";
import { BpmService } from "../../../core/services/BpmService";
import { AdminService } from "../../../core/services/AdminService";
import { AccountEditor } from "../../editor/AccountEditor";
import { useDashboardStore } from "../../pages/hooks/useDashboardStore";
import { env } from "../../../env";
import { PrintJson } from "../../theme/Component/PrintJson";


interface Props {
    idRole: number;
    idAccount: number;
    account: any;
    updateRol: any;
}
export const ActivateAccount = ({ idRole, account, idAccount, updateRol }: Props) => {

    const single = Singleton.getInstance()
    const { setLoading } = useDashboardStore();
    const service = new BpmService();
    const serviceAdmin = new AdminService();
    const [bean, setBean] = useState<any>({});
    const [message, setMessage] = useState('');
    const [idRol, setRol] = useState(null);

    // 1 LDAP
    // 2 local
    // const loginType = env.LOGIN_TYPE;
    const [loginType, setLoginType] = useState(1);


    useEffect(() => {

        setBean(account);
        setRol(idRole);
        setMessage('');
    }, [account])


    const update = () => {

        let b = bean;
        b.RoleID = idRol;
       //console.log(b);

        // updateItemUser(b);
        addAccountToRole();
    }

    const addAccountToRole = () => {
        setLoading(true);
        serviceAdmin.addAccountToRole(bean.IDAccount, single.getAccountID(), idRol).subscribe(
            (resp: any) => {

                if (resp.DataBeanProperties.ObjectValue) {
                    single.update(resp.DataBeanProperties.ObjectValue);
                    if (loginType === 2) {
                        serviceAdmin.activateUserAccount(bean.IDAccount, bean.eMail, bean.pws).subscribe(
                            (resp: any) => {
                               //console.log(resp);
                            }
                        )
                    }
                }
                updateRol(bean);
                setLoading(false);
            }
        )



    }

    const updateItemUser = (b: any) => {


        setLoading(true);
        serviceAdmin.desactivateUserAccount(b.IDAccount).subscribe(
            (resp: any) => {
                serviceAdmin.activateUserAccount(b.IDAccount, b.eMail, single.getRandomInt() + "").subscribe(
                    (resp: any) => {
                        setMessage('Usuario Activado Exitosamente');
                        setLoading(false);


                        service.updateAbstractAccount(bean).subscribe(
                            (resp: any) => {
                                setLoading(false);
                                if (resp.DataBeanProperties.ObjectValue) {

                                }
                                updateRol(b);
                            });

                    }
                )
            }
        )
    }


    return (
        <div className="row">
            <div className="col-md-12">
                <div className="row">
                    {/* <PrintJson json={env.LOGIN_TYPE} /> */}
                    <div className="col-12">
                        <label>
                            Tipo de Login
                        </label>
                        <select id="inputState" className="form-select" name="tipo" onChange={(e) => { setLoginType(parseInt(e.target.value)); }}>
                            <option value={1} >Directorio Activo</option>
                            <option value={2} >Usuario Local</option>
                        </select>
                    </div>

                    {idRole === 0 &&
                        <div className="col-md-12">
                            <SelectRol idSelector={idRol} onChange={(e: number) => { setRol(e); }} />
                        </div>
                    }

                    {idAccount === 0 ?
                        <>
                            <div className="col-md-12">
                                <AccountEditor idAccount={idAccount} label="Funcionario" onChange={(acc: any) => {
                                    setBean({ ...bean, "IDAccount": acc.IDAccount, "eMail": acc.eMail });
                                }} />
                            </div>
                            <div className="col-md-12">

                                {loginType === 1 ?
                                    <div className="row">
                                        <div className="col">
                                            <label>Usuario</label>
                                            <input type="text" className="form-control" readOnly value={bean.eMail} />
                                        </div>
                                    </div>
                                    :
                                    <>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label>Usuario</label>
                                                <input type="text" className="form-control" value={bean.eMail} onChange={(e) => { setBean({ ...bean, "eMail": e.target.value }) }} />
                                            </div>
                                            <div className="col-md-6">
                                                <label >Contraseña</label>
                                                <input type="password" className="form-control" value={bean.pws} onChange={(e) => { setBean({ ...bean, "pws": e.target.value }) }} />
                                            </div>
                                        </div>
                                    </>
                                }
                            </div>
                        </>
                        :
                        <>
                            {loginType === 1 ?
                                <div className="row">
                                    <div className="col">
                                        <label>Usuario</label>
                                        <input type="text" className="form-control" readOnly value={bean.eMail} />
                                    </div>
                                </div>
                                :
                                <>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <label>Usuario</label>
                                            <input type="text" className="form-control" value={bean.eMail} onChange={(e) => { setBean({ ...bean, "eMail": e.target.value }) }} />
                                        </div>
                                        <div className="col-md-6">
                                            <label >Contraseña</label>
                                            <input type="password" className="form-control" value={bean.pws} onChange={(e) => { setBean({ ...bean, "pws": e.target.value }) }} />
                                        </div>
                                    </div>
                                </>
                            }
                        </>
                    }
                    <div className="col-md-12">
                        <BasicButton disable={!idRol} icon="ri-folder-add-line" eventClick={update}>Activar Funcionario</BasicButton>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        {message != '' &&
                            <Alert clase="warning">{message}</Alert>
                        }
                    </div>
                </div>
            </div>
        </div>
    )

}