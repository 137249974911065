import { Singleton } from "../../../core/services/Singleton";
import { SupplierService } from "../../../core/services/SupplierService";
import { useDashboardStore } from "../../pages/hooks/useDashboardStore";
import { BasicButton } from "../../theme/Component/BasicButton";
import { PrintJson } from "../../theme/Component/PrintJson";
import { useState, useEffect } from 'react';

interface Props {
    supplier: any;
    updateEvent: any;
}

export const UpdateStatus = ({ supplier, updateEvent }: Props) => {
    const service = new SupplierService();
    const { setLoading } = useDashboardStore();
    const single = Singleton.getInstance()

    const [selState, setSelState] = useState(0);
    const [obsValue, setObsValue] = useState('');
    const [idEmployee, setIdEmployee] = useState(0);
    const [idSupplier, setIdSupplier] = useState(0);

    useEffect(() => {
        setIdEmployee(single.getAccountID());
        setIdSupplier(supplier.IDSupplier);
    }, [])

    const Actualizar = () => {
        updateItem();
        updateEvent();
    }

    const mostrar = () => {
       //console.log(idSupplier);
       //console.log(supplier.idBean);
    }


    const updateItem = () => {
        setLoading(true);
        service.updateSupplierStatus(idSupplier, selState, obsValue, idEmployee).subscribe()
    }

    return (
        <>
            <div className="conatiner">
                <div className="row">
                    <div className="col-md-6">
                        <label>Tipo de actividad:</label>
                        <select className="form-control" value={selState} onChange={(e: any) => { setSelState(e.target.value) }} >
                            <option value={5}>EN VALIDACION</option>
                            <option value={1}>REGISTRADO</option>
                        </select>
                    </div>
                    <div className="col-md-12">
                        <label className="form-label">Observaciones</label>
                        <input type="text" className="form-control" value={obsValue} onChange={(e: any) => { setObsValue(e.target.value) }} />
                    </div>
                </div>
            </div>
            <BasicButton disable={!single.canEdit()} eventClick={Actualizar} icon="ri-contacts-book-line" >Actualizar Estado</BasicButton>
            {/* <PrintJson json={supplier}></PrintJson> */}
        </>
    )
}