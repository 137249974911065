import { useEffect, useState } from "react"
import { TalentService } from "../../../core/services/TalentService"
import { Singleton } from "../../../core/services/Singleton";
import { BasicPage } from "../../theme/Component/BasicPage";
import { BasicTable } from "../../theme/Component/BasicTable";
import { Modal } from "../../theme/Component/Modal";
import { ModalConfirm } from "../../theme/Component/ModalConfirm";
import { TestQuestion } from "./TestQuestion";
import { ListMenu } from "../../theme/Component/ListMenu";
import { MyEditor } from "../../theme/Component/MyEditor";

export const Test = () => {

    const services = new TalentService();
    const single = Singleton.getInstance();
    const [lista, setLista] = useState([]);
    const [bean, setBean] = useState<any>({});
    const [modal, setModal] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalQuestion, setModalQuestion] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalConfirm, setModalConfirm] = useState<any>({ name: 'Confirmar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });

    useEffect(() => {
        getLista()
    }, [])

    const idBean = 'IDTest';

    const head = [
        { title: 'ID', property: idBean },
        { title: 'Nombre', property: 'Name' },
        { title: 'Descripción', property: 'Description' },
        { title: 'Grupo', property: 'Group' },
        { title: 'Tipo', property: 'TypeName' },
    ]

    const setRowBean = (bean: any) => {
       //console.log("Row : ", bean);
        setBean(bean);
    }

    const renderName = (value: number) => {
        let type = '';
        if (value === 1) {
            type = 'Respuesta Unica'
        } else if (value === 2) {
            type = 'Respuestas Ponderadas'
        }
        return type;
    }

    const getLista = () => {
        const x = {
            "State": 1
        }
        single.spinner(true);
        services.getTestCatalogPorPropiedades(x, null).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue) {
                    let x = resp.DataBeanProperties.ObjectValue;
                    x.forEach((test: any) => {
                        test.DataBeanProperties.TypeName = renderName(test.DataBeanProperties.TestType)
                    });
                    setLista(x)
                }
                single.spinner(false)
            }
        )
    }

    const deleteItem = () => {
        bean.State = 2;
        updateItem();
        setModalConfirm({ ...modalConfirm, 'view': false });
    }

    const updateItem = () => {
        setModal({ ...modal, "view": false })
        single.spinner(true);
        services.updateTest(bean).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue) {
                    getLista();
                }
                single.spinner(false)
                if (bean.IDTest) {
                    single.update(bean);
                } else {
                    single.create(bean)
                }
            }
        )
    }

    const agregarItem = () => {
        let b = {
            Name: '',
            Description: '',
            IDEmployee: single.getAccountID(),
            State: 1,
            Group: '',
            TestType: undefined,
        }
        setBean(b);
        setModal({ ...modal, 'view': true });

    }

    const listaBotones = () => {
        const botones = [

            { titulo: 'Editar', icono: 'ri-file-list-line', visible: single.canEdit(), evento: () => { setModal({ ...modal, "view": true }) } },
            { titulo: 'Eliminar', icono: 'ri-file-user-line', visible: single.canDelete(), evento: () => { setModalConfirm({ ...modalConfirm, "view": true }) } },
            { titulo: 'Preguntas de la Prueba', icono: 'ri-file-user-line', visible: true, evento: () => { setModalQuestion({ ...modalQuestion, "name": `Preguntas de ${bean.Name}`, "view": true }) } },

        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }

    const iconButtonsItem = [
        { nombre: 'Agregar', visible: single.canCreate(), icono: 'ri-file-add-line', evento: agregarItem }
    ]

    return (
        <BasicPage titulo="Configuración de Pruebas">
            <div className="row">
                <div className="col-12">
                    <BasicTable body={lista} head={head} rowSet={setRowBean} iconButtons={iconButtonsItem} listButtons={listaBotones()} />
                </div>
            </div>

            {modal.view &&
                <Modal modal={modal} updateModal={setModal} eventModal={updateItem}>
                    <div className="row">
                        <div className="col-md-6">
                            <label className="form-label">Nombre</label>
                            <input type="text" className="form-control" value={bean.Name} onChange={(e) => { setBean({ ...bean, 'Name': e.target.value }) }} />
                        </div>
                        <div className="col-md-6">
                            <label className="form-label">Grupo</label>
                            <input type="text" className="form-control" value={bean.Group} onChange={(e) => { setBean({ ...bean, 'Group': e.target.value }) }} />
                        </div>
                        <div className="col-md-12">
                            <label className="form-label">Descripción</label>
                            <MyEditor val={bean.Description} onChange={(e: any) => { setBean({ ...bean, "Description": e }) }} />
                        </div>
                        <div className="col-md-6">
                            <label className="form-label">Tipo de Prueba</label>
                            <select className='form-select' value={bean.TestType} onChange={(e) => { setBean({ ...bean, "TestType": e.target.value }) }}>
                                <option value={undefined}>...</option>
                                <option value={1}>Respueta Unica</option>
                                <option value={2}>Respuesta Ponderadas</option>
                            </select>
                        </div>
                    </div>
                </Modal>
            }
            {modalQuestion.view &&
                <Modal modal={modalQuestion} updateModal={setModalQuestion} eventModal={""}>
                    <TestQuestion idTest={bean.IDTest} />
                </Modal>
            }
            {modalConfirm.view &&
                <ModalConfirm modal={modalConfirm} updateModal={setModalConfirm} eventModal={deleteItem}>
                    {bean.Name}
                </ModalConfirm>
            }
        </BasicPage>
    )
}