import { DataBean } from "../DataBean";

export class AdquisitionContract extends DataBean{

    public async importDataContract(type:number,data:any,idAdquisitionPlan): Promise<any> {
        return this.getCustomService("SupplierERP", "java.util.List_importDataContract_Number_String_Number", [type,data,idAdquisitionPlan]);
    }
    
    public async getAdquisitionContractRender(page:number|null,rows:number|null,props:any,fechaIni:any,fechaFin:any): Promise<any> {
        return this.getCustomService("FinanceCore", "java.util.List_getAdquisitionContractRender_Number_Number_java.util.Map_java.util.Date_java.util.Date", 
            [page,rows,props,fechaIni,fechaFin]);
    }

   
}