import { MenuUsuario } from "./menu/MenuUsuario";
import { useParams } from "react-router-dom";
import { Projects } from "../devops/Projects";
import { StepDevops } from "../devops/StepDevops";
import { RolDevops } from "../devops/RolDevops";
import { AccountProject } from "../devops/AccountProject";
import { History } from "../devops/History";
import { BudgetStructure } from "./configuracion/BudgetStructure";
import { FunctionalID } from "./configuracion/functionalID/FunctionalID";
import { Components } from "../devops/Components";
import { MatrizSeguimiento } from "../devops/MatrizSeguimiento";
import { ProjectID } from "./configuracion/ProjectID/ProjectID";
import { UnspscID } from "./configuracion/UnspscID/UnspscID";
import { BudgetID } from "./configuracion/BudgetID/BudgetID";
import { BasicPage } from "../theme/Component/BasicPage";
import { ApiModule } from "./api/ApiModule";
import { ApiRole } from "./api/ApiRole";
import { SystemRoles } from "./Roles/SystemRoles";
import { TrdID } from "./configuracion/TrdID/TrdID";
import { RequirementType } from "../contract/RequirementType";
import { HiringModalityDocument } from "../contract/HiringModalityDocument";
import { HiringModalityStage } from "../contract/HiringModalityStage";
import { BudgetIDType } from "./configuracion/BudgetIDType";
import { BudgetType } from "./configuracion/BudgetType";
import { SystemPropertie } from "./configuracion/SystemProperties";
import { AbstractAccount } from "./configuracion/AbstractAccount";
import { StructuringDocument } from "../contract/StructuringDocument";
import { MeasurementUnit } from "./configuracion/MeasurementUnit";
import { DeliveryType } from "./configuracion/DeliveryType";
import { AuditLog } from "./audit/AuditLog";
import { PgpEncrypt } from "../../core/secure/Pgp/PgpEncrypt";
import { SuppConstants } from "../bpm/config/Componentes/SuppConstants";
import { RequirementSST } from "../bpm/config/Componentes/RequirementSST";
import { Matrizriesgos } from "../bpm/config/Componentes/Matrizriesgos";
import { DocSupplierConfig } from "../bpm/config/Componentes/DocSupplierConfig";
import { MisApprovals } from "../approval/MisApprovals";
import { Typeformaservice } from "../bpm/config/Componentes/Typeformaservice";
import { FestiveDay } from "./utils/FestiveDay";
import { SiteID } from "./configuracion/SiteID/SiteID";
import { ApplicationGroup } from "./applicationGroup/ApplicationGroup";
import { Formatos } from "../bpm/config/Componentes/Formatos";
import { FactorMatrix } from "../bpm/config/Componentes/FactorMatrix";
import { Auditorianueva } from "../contract/auditoria/Auditorianueva";
import { ErrorsSql } from "../contract/auditoria/ErrorrsSql";
import { ThirdPartyUser } from "./ThirdPartyUser/ThirdPartyUser";
import { Afiliate } from "./Afiliados/Afiliate";
import { SalaryInquiry } from "../adminServices/SalaryInquiry";
import { DocumentTemplate } from "./plantillas/DocumentTemplate";
import { GenericCellEditor } from "./generic-editor/GenericCellEditor";
import { ExportMenu } from './menu/ExportMenu';
import { EducationalSupport } from "../Talentohumano/payRoll/EducationalSupport";
import { EnjoyedVacationERP } from "../Talentohumano/payRoll/EnjoyedVacationERP";
import { Dashed } from "../pages/Dashed/Dashed";
import { GroupTemplate } from "../bpm/config/Componentes/GroupTemplate";
import { Historialdetramite } from "../bpm/config/Historialtramites";
import { VisualBandeja } from "./perfil/VisualBandeja";
import { ReaderUHF } from "./configuracion/UHFReader/ReaderUHF";
import { Company } from "../erp/cellEditor/Company/Company-component";
import { AccountantBook } from "../erp/cellEditor/AccountantBook/AccountantBook-component";
import { Office } from "../erp/cellEditor/Office/Office-component";
import { Currency } from "../erp/cellEditor/Currency/Currency-component";
import { InboxCash } from "../erp/cellEditor/CashDesk/InboxCash-component";
import { FormType } from "../custom/forpo/components/GenericForms&Constants/FormType.advantage";
import { CashDeskAccount } from "../erp/cellEditor/CashDeskAccount/CashDeskAccount-component";
import { PerosnalInbox } from "../pages/Dashed/components/PersonaInbox/Personalnbox";
import { Miscursos } from "../Talentohumano/CurseConfig/Miscursos";
import { RoleGroup } from "./RolesGroup/RoleGroup";
import { RoleArea } from "./RolesArea/RoleArea";
import { AccountantIDType } from "../contabilidad/catalogo-cuentas/AccountantIDType";
import { ImportarCuentasContables } from "../contabilidad/catalogo-cuentas/ImportarCuentasContables";
import { AccountantTree } from "../contabilidad/catalogo-cuentas/AccountantTree/AccountantTree";
import { ExportarCuentasContables } from "../contabilidad/catalogo-cuentas/ExportarCuentasContables";
import { CashReimbursementHistory } from "../erp/cellEditor/CashDesk/CashReimbursement/CashReimbursementHistory";
import { TaxSchemeContext } from "./ConfImpuestos/TaxSchemeCode/TaxSchemeContext";
import { UBLTaxSchemeContextCode } from "./ConfImpuestos/UBLTaxScheme/UBLTaxSchemeContextCode";
import { AccountantConcept } from "../erp/cellEditor/AccountantConcept/AccountantConcept";
import { FiscalResponsabilityType } from "../erp/FiscalResponsabilityType/FiscalResponsabilityType";
import { FiscalResponsability } from "../erp/FiscalResponsability/FiscalResponsability";
import { TaxScheme } from "./ConfImpuestos/TaxScheme/TaxScheme";
import { useEffect } from "react";
import { Singleton } from "../../core/services/Singleton";
import { ContractRole } from "./configuracion/ContractRole";
import { RequestTypeBpm } from "./configuracion/RequestTypeBpm";
import { FunctionalArea } from "./configuracion/FunctionalArea/FunctionalArea";
import { PaymentType } from "../erp/cellEditor/PaymentType/PaymentType";
import { BankingEntity } from "../erp/cellEditor/BankingEntity/BankingEntity";
import { ThirdBankingAccount } from "../Talentohumano/ParametrosTalentoHumano/ThirdBankingAccount/ThirdBankingAccount";
import { BankAccountCatalog } from "../erp/cellEditor/BankAccount/BankAccount";
import { TaxCodeAccount } from "../Talentohumano/ParametrosTalentoHumano/TaxCodeAccount/TaxCodeAccount";
import { CashReimbursementConcept } from "../Talentohumano/CajasMenoresConceptos/CashReimbursementConcept";
import { TaxCodeScheme } from "./ConfImpuestos/TaxCodeScheme/TaxCodeScheme";
import { TaxCode } from "./ConfImpuestos/TaxCode/TaxCode";
import { AbstractAccountAdminGestion } from "../GestionTerceros/AbstractAccount/AbstractAccountAdminGestion";
import { MeasurementUnitCode } from "../erp/MeasurementUnitCode/MeasurementUnitCode";
import { CostingCenter } from "../erp/cellEditor/CostingCenter/CostingCenter";
import { ArticleClassType } from "../erp/ArticleClassType/ArticleClassType";
import { PaymentRequirement } from "./configuracion/PaymentRequirement";
import { ContractType } from "./configuracion/ContractType";
import { ArticleClassCodeTree } from "../contabilidad/catalogo-bienes/ArticleClassCodeTree";
import { AdquisitionProject } from "../erp/AdquisitionProject/AdquisitionProject";
import { TaxSchemeSimulator } from "./ConfImpuestos/TaxScheme/FuncionesTaxScheme/TaxSchemeSimulator";
import { TabsCourse } from "../Talentohumano/CurseConfig/TabsCourse";
import { FunctionalAreaVdos } from "../erp/FunctionalArea/FunctionalArea";
import { BudgetAccountantID } from "../erp/BudgetAccountantID/BudgetAccountantID";
import { PaymentAccount } from "../Talentohumano/GestionTH/PaymentAccount/PaymentAccount";
import { PaymentMethod } from "../Talentohumano/PaymentMethod/PaymentMethod";
import { BudgetResource } from "./configuracion/BudgetResource/BudgetResource";
import { CashRequest } from "../erp/cellEditor/CashRequest/CahsRequest";
import { ManageCahsRequest } from "../erp/cellEditor/CashRequest/ManageCahsRequest";
import { BudgetSource } from "./configuracion/BudgetSource/BudgetSource";
import { BudgetCDPFactory } from "./configuracion/BudgetCDPFactory/BudgetCDPFactory";
import { BudgetRPFactory } from "../contabilidad/budgetRPFactory/BudgetRPFactory";
import { BodegaDocumentos } from "../contabilidad/bodegaDocumentos/BodegaDocumentos";
import { BudgetObligationFactory } from "../contabilidad/budgetObligationFactory/BudgetObligationFactory";
import { BudgetRPFactoryWhitoutContract } from "../contabilidad/budgetRPFactoryWhitoutContract/budgetRPFactoryWhitoutContract";
import { BudgetSheetFactory } from "../contabilidad/BudgetSheetFactory/BudgetSheetFactory";
import { BudgetAdditionFactory } from "../contabilidad/BudgetAdditionFactory/BudgetAdditionFactory";
import { BudgetReductionFactory } from "../contabilidad/BudgetReductionFactory/BudgetReductionFactory";
import { BudgetCDPReductionFactory } from "../contabilidad/BudgetCDPReductionFactory/BudgetCDPReductionFactory";
import { BudgetMovementFactory } from "../contabilidad/BudgetMovementFactory/BudgetMovementFactory";
import { BudgetReductionRPFactory } from "../contabilidad/BudgetReductionRPFactory/BudgetReductionRPFactory";
import { BudgetAccountantOperation } from "../erp/cellEditor/BudgetAccountantOperation/BudgetAccountantOperation";
import { BusinessCore } from "../erp/cellEditor/BusinessCore/BusinessCore";
import { DocumentType } from "../erp/DocumentType/DocumentType";
import { Process } from "../erp/Process/Process";




export const RouterAdmin = (props: any) => {

  const single = Singleton.getInstance();
  useEffect(() => {

   //console.log(single.getAccount());


  }, [])

  const { module, view }: any = useParams();

  const renderSwitch = (url: string) => {
    switch (url) {
      case "perfil":
        /* return (<Perfil />); */
        return <Dashed />;
      case "user-inbox":
        return <PerosnalInbox />;
      case "miscursos":
        return <Miscursos />;
      case "curso":
        return <TabsCourse />
      case "menu":
        return <MenuUsuario />;
      case "perfil-usuario":
        return <SystemRoles />;
      case "roles-group":
        return <RoleGroup />;
      case "roles-area":
        return <RoleArea />;
      case "software-project":
        return <Projects />;
      case "etapas-devops":
        return <StepDevops />;
      case "certificado-disponibilidad":
        return <BudgetCDPFactory />
      case "rol-devops":
        return <RolDevops />;
      case "usuario-proyecto":
        return <AccountProject />;
      case "configurar-proceso":
        return <Process />;
      case "historia-proyecto":
        return <History />;
      case "flujo-contrato":
        return <RequestTypeBpm />
      case "roles-proceso":
        return <ContractRole />;
      case "budget-structure":
        return <BudgetStructure />;
      case "budget-idtype":
        return <BudgetIDType />;
      case "tipo-documento":
        return <DocumentType />;
      case "budget-type":
        return <BudgetType />;
      case "class-bienoservicio":
        return <ArticleClassType />;
      case "proyectos":
        return <AdquisitionProject />
      case "metodos-pago":
        return <PaymentMethod />;
      case "measurement-unit":
        return <MeasurementUnit />;
      case "requisitos-pago":
        return <PaymentRequirement />;
      case "tipo-contratacion":
        return <ContractType />
      case "delivery-type":
        return <DeliveryType />;
      case "cuentas-pagar":
        return <PaymentAccount />;
      case "historialdetramite":
        return <Historialdetramite />;
      case "componentes":
        return <Components />;
      case "matriz-trazabilidad":
        return <MatrizSeguimiento />;
      case "third-party-user":
        return <ThirdPartyUser />;
      case "afiliate":
        return <Afiliate />;
      case "segmento-contable":
        return <BudgetAccountantID />;
      case "functional-area":
        return <FunctionalArea />;
      case "functionalid":
        return (
          <BasicPage titulo="Estructura Organizacional">
            <FunctionalID id={null} edit={true} />
          </BasicPage>
        );
      case "projectid":
        return (
          <BasicPage titulo="Árbol Proyectos">
            <ProjectID id={null} edit={true} />
          </BasicPage>
        );
      case "unspscid":
        return (
          <BasicPage titulo="Árbol Clasificador de Bienes y Servicios Unspsc">
            <UnspscID id={null} edit={true} />
          </BasicPage>
        );
      case "trdid":
        return (
          <BasicPage titulo="Tabla de Retenciones TRD">
            <TrdID id={null} edit={true} />
          </BasicPage>
        );

      case "budgetid":
        return (
          <BasicPage titulo="Catálogo de Rubros Presupuestales">
            <BudgetID tipoPresupuesto={null} id={null} edit={true} />
          </BasicPage>
        );

      case "apimodule":
        return <ApiModule />;
      case "api-role":
        return <ApiRole />;
      case "requirement-type":
        return <RequirementType />;
      case "hiring-modality-document":
        return <HiringModalityDocument />;
      case "hiring-modality-stage":
        return <HiringModalityStage />;
      case "systempropertie":
        return <SystemPropertie />;
      case "abstractaccounts":
        return <AbstractAccount />;
      case "structuring-document":
        return <StructuringDocument />;
      case "codigos-clasificacion":
        return <TaxCodeAccount />;
      case "cuenta-tercero-gestion":
        return <AbstractAccountAdminGestion />;
      case "cuentas-terceros":
        return <ThirdBankingAccount />;
      case "auditoria":
        return <Auditorianueva />;
      case "audit":
        return <AuditLog />;
      case "encrypt-file":
        return <PgpEncrypt />;
      case "salary-inquiry":
        return <SalaryInquiry />;
      case "responsabilidad-fiscal":
        return <FiscalResponsability />;
      case "suppConstants":
        return <SuppConstants />;
      case "requirement-sst":
        return <RequirementSST />;
      case "formatos":
        return <Formatos />;
      case "Matrizdefactores":
        return <FactorMatrix />;
      case "sqlerrors":
        return <ErrorsSql />;
      case "bandeja-mensajes":
        return <VisualBandeja />;
      case "matrizriesgos":
        return <Matrizriesgos />;
      case "doc-supplier-config":
        return <DocSupplierConfig />;
      case "mis-aprobaciones":
        return <MisApprovals />;
      case "formulariocompras":
        return <Typeformaservice />;
      case "festivos":
        return <FestiveDay />;
      case "generic-editor-erp":
        return <GenericCellEditor />;
      case "apoyo-educativo":
        return <EducationalSupport />;
      case "disfrute-vacaciones":
        return <EnjoyedVacationERP rol={1} />;
      case "monitor-almacen":
        return <ReaderUHF />;
      case "siteid":
        return (
          <BasicPage titulo="Ciudades">
            <SiteID id={null} edit={true} />
          </BasicPage>
        );
      case "app-quicdata-erp":
        return <ApplicationGroup />;
      case "grupos":
        return <BasicPage titulo="Ciudades"> </BasicPage>;
      case "plantilla-documento":
        return <DocumentTemplate />;
      case "export-menu":
        return <ExportMenu />;
      case "group-template":
        return <GroupTemplate />;
      case "catalogo-compania":
        return <Company />;
      case "migrar-plazas":
        return <FunctionalAreaVdos />;
      case "unidades-medida":
        return <MeasurementUnitCode />;
      case "centro-costo":
        return <CostingCenter />;
      case "rembolsos-cajas":
        return <CashReimbursementHistory menuComponent />;
      case "cuentas-banco":
        return <BankAccountCatalog />;
      case "entidad-fianciera":
        return <BankingEntity />;
      case "tipos-pagos":
        return <PaymentType />;
      case "cuentas-cajamenor":
        return <PaymentAccount CASH_REIMBURSEMENT={15} />;
      case "cajas-menores":
        return <CashDeskAccount />;
      case "catalogo-libros":
        return <AccountantBook />;
      case 'catalogo-conceptoscontables':
        return <AccountantConcept />;
      case "catalogo-oficinas":
        return <Office />;
      case "catalogo-servicios-contable-presupuestales":
        return <BudgetAccountantOperation />;
      case "catalogo-core-negocio":
        return <BusinessCore />;
      case "conceptos-caja":
        return <CashReimbursementConcept />;
      case "catalogo-monedas":
        return <Currency />;
      case "inbox-cash":
        return <InboxCash />;
      case "tipos-esquemas-impuestos":
        return <TaxSchemeContext />;
      case "tipos-codigos-clasificacion-impuestos":
        return <TaxCodeScheme />;
      case "codigos-clasificacion-impuestos":
        return <TaxCode />;
      case "Codigos-actividad-impuestos":
        return <UBLTaxSchemeContextCode />;
      case "tipos-impuestos":
        return <TaxScheme />;
      case "simulador-retenciones":
        return <TaxSchemeSimulator menuComponent />;
      case "formularios-quick":
        return <FormType />;
      case "fuentes-financimiento":
        return <BudgetSource />;
      case "fiscal-responsabilidad":
        return <FiscalResponsability />
      case "responsabilidad-fiscal-type":
        return <FiscalResponsabilityType />
      case "catalogo-cuentas-contrables":
        return <AccountantIDType />;
      case "importar-cuentas-contables-catalogo-presupuestal":
        return <ImportarCuentasContables />;
      case "exportar-cuentas-contables-catalogo-presupuestal":
        return <ExportarCuentasContables />
      case "plan-cuentas-contable":
        return <AccountantTree edit={true} id={view} />
      case "catalogo-clasificacion-bienes":
        return <ArticleClassCodeTree edit={true} id={view} />
      case "tipo-recursos":
        return <BudgetResource />
      case "solicitud-pago-cajamenor":
        return <CashRequest />
      case "solicitudes-recibidas-cajas":
        return <ManageCahsRequest />
      case "registro-presupuestal-contrato":
        return <BudgetRPFactory />
      case "credito-rubros-presupuestales":
        return <BudgetMovementFactory />
      case "reduccion-registro-presupuestal":
        return <BudgetReductionRPFactory />
      case "registro-presupuestal":
        return <BudgetRPFactoryWhitoutContract />
      case "presupuesto-inicial":
        return <BudgetSheetFactory />
      case "adicion-presupuestal":
        return <BudgetAdditionFactory />
      case "reduccion-presupuestal":
        return <BudgetReductionFactory />
      case "cdp-reductionfactory":
        return <BudgetCDPReductionFactory />
      case "configurar-segmentocontable":
        return <BudgetCDPReductionFactory />
      case "obligacion-presupuestal":
        return <BudgetObligationFactory />
      case "bodega-documentos":
        return <BodegaDocumentos />
      default:
        // return <ProfileDashboard />;
        return <Dashed />;
    }
  };

  return <div className="px-5">{renderSwitch(view)}</div>;
};
