import { DataBean } from "../DataBean";


export class Knowledge extends DataBean {


    public async getKnowledgeCatalog(idLn: number | null, idAppoManual: number, type: number | null): Promise<any> {
        return this.getCustomService("PeopleArt", "java.util.List_getKnowledgeCatalog_Number_Number_Number", [idLn, idAppoManual, type]);
    }

    public async deleteKnowledge(bean): Promise<any> {
        return this.getCustomService("PeopleArt", "void_deleteKnowledge_com.peopleart.bean.appointment.Knowledge", [
            {
                "DataBeanProperties": bean,
                "DataBeanName": "com.peopleart.bean.appointment.Knowledge"
            }
        ]);
    }
}