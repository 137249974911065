import { useEffect, useState } from "react";
import { Modal } from "../../theme/Component/Modal";
import { ModalConfirm } from "../../theme/Component/ModalConfirm";
import { TalentService } from "../../../core/services/TalentService";
import { useDashboardStore } from "../../pages/hooks/useDashboardStore";
import { Singleton } from "../../../core/services/Singleton";
import { BasicTable } from "../../theme/Component/BasicTable";
import { ListMenu } from "../../theme/Component/ListMenu";
import { InputDate } from "../../theme/Input/InputDate";

import { _years } from "../../admin/perfil/elements/PayrollProcess";

import { toast } from "react-toastify";

interface Props {
    _vacation: {
        Name: string,
        accountID: number,
        IDDocument: number,
        DocumentDate: string,
    };
    _user: number;
    _rol: number;
    _lista: any;
}

export const AnticipatedVacation = ({ _vacation, _user, _rol, _lista }: Props) => {
    const single = Singleton.getInstance();
    const employeeID = single.getAccountID();
    const { setLoading } = useDashboardStore();
    const service = new TalentService();
    const [bean, setBean] = useState<any>({});
    const [beanAnt, setBeanAnt] = useState<any>({});
    const [lista, setLista] = useState([]);
    const [modalConfirm, setModalConfirm] = useState<any>({ name: "Confirmar", tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: "modal-sm", });
    const [modalAnti, setModalAnti] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: "modal-lg", });
    const [modalRelation, setModalRelation] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: "modal-lg", });
    const [modalRelease, setModalRelease] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: "modal-lg", });
    const [selectVacation, setSelectVacation] = useState<any>({})

    const idBean = 'IDEnjoyedVacation';

    const setRowBean = (bean: any) => {
        setBean(bean);
       //console.log(bean)
    };

    useEffect(() => {
        getLista();
    }, [_vacation])

    const getLista = () => {
        setLoading(true);
        service.getActiveEnjoyedVacationAnticipated(_user, true).subscribe((resp: any) => {
            setLoading(false);
            if (resp.DataBeanProperties.ObjectValue) {
               //console.log(resp);
                setLista(resp.DataBeanProperties.ObjectValue);
            }
        })
    }

    const eliminarItem = () => {
        setLoading(true);
       //console.log(bean.IDEnjoyedVacation, employeeID)
        service.deleteEnjoyedVacation(bean[idBean], employeeID).subscribe((resp: any) => {
            setLoading(false);
            if (resp) {
                getLista();
            }
            single.delete(bean.idBean, bean);
        });
        setModalConfirm({ ...modalConfirm, "view": false });
    };

    const agregarAV = () => {
        let b = {
            fromDate: single.getCurrenDate(),
            uptoDate: single.getCurrenDate(),
            Descripcion: '',
        };
        setBeanAnt(b);
        setModalAnti({ ...modalAnti, "view": true, name: "Agregar Vacaciones Anticipadas" });
    };

    const agregarVAnticipated = () => {
       //console.log(_user, beanAnt.fromDate, beanAnt.uptoDate, single.getAccountID(), beanAnt.Descripcion)
        setModalAnti({ ...modalAnti, "view": false });
        service.addAnticipatedEnjoyedVacation(_user, beanAnt.fromDate, beanAnt.uptoDate, single.getAccountID(), beanAnt.Descripcion).subscribe((resp: any) => {
            setLoading(false);
            if (resp.DataBeanProperties.ObjectValue) {
               //console.log("hecho");
                if (resp.DataBeanProperties.ObjectValue) {
                    toast.success('Registro almacenado');
                    getLista();
                }
                else if (resp.DataBeanProperties.ErrorMessage) {
                    toast.error(resp.DataBeanProperties.ErrorMessage);

                }
            }
        })
    }

    const relationVacation = () => {
        setModalRelation({ ...modalRelation, "view": false });
        setLoading(true);
        service.addAnticipatedEnjoyedVacationToVacation(bean.IDEnjoyedVacation, selectVacation.IDDocument, selectVacation.DocumentDate, _user).subscribe(
            (resp: any) => {
                setLoading(false);
                if (resp.DataBeanProperties.ObjectValue) {
                    getLista();
                }
                else if (resp.DataBeanProperties.ErrorMessage) {
                    toast.error(resp.DataBeanProperties.ErrorMessage);
                }
            })

    }

    const releaseVacation = () => {
        setModalRelease({ ...modalRelease, "view": false });
        setLoading(true);
        service.releaseEnjoyedVacation(bean.IDEnjoyedVacation, single.getAccountID()).subscribe((resp: any) => {
            setLoading(false);
            if (resp.DataBeanProperties.ObjectValue) {
            }
        })
        getLista();
    }

    const head = [
        { title: "ID", property: idBean },
        { title: "Descripción", property: "Description" },
        { title: "Número de días", property: "Units" },
        { title: "Desde", property: "FromDate" },
        { title: "Hasta", property: "UptoDate" },
        { title: "¿Son vacaciones Anticipadas?", property: "IsAnticipated" },
        { title: "Agregadas por", property: "EmployeeName" },
    ];

    const iconButtonsItem = [
        { nombre: "Agregar Vacaciones Anticipadas", visible: ((_rol === 1) && (single.canCreate())), icono: "ri-calendar-2-fill", evento: agregarAV, },
    ];

    const listaBotones = () => {
        const botones = [
            { titulo: "Anular registro", icono: "ri-file-list-line", visible: true, evento: () => { setModalConfirm({ ...modalConfirm, "view": true }) } },
            { titulo: "Relacionar vacaciones anticipadas a pagas", icono: "ri-file-list-line", visible: ((_rol === 1) && (single.canCreate())), evento: () => { setModalRelation({ ...modalRelation, "view": true, "name": "Relacionar unas vacaciones anticipadas a un perído de vacaciones pago" }) } },
            // { titulo: "Liberar Vacaciones", icono: "ri-file-list-line", visible: ((_rol === 1) && (single.canDelete())), evento: () => { setModalRelease({ ...modalRelease, "view": true, "name": "Liberar registro de vacaciones anticipadas" }) } },
        ];
        return <ListMenu listaBotones={botones} />;
    };



    const handleSelection = (idDocument: number) => {
       //console.log(idDocument);

        const buscado = _lista.find((e) => e.DataBeanProperties.IDDocument === idDocument)
        if (idDocument) {
            setSelectVacation(buscado.DataBeanProperties)
        }
    }

    return (
        <>
            <div className="row">
                <>
                    <BasicTable body={lista} head={head} rowSet={setRowBean} iconButtons={iconButtonsItem} listButtons={listaBotones()}></BasicTable>
                </>
            </div>

            {modalConfirm &&
                <ModalConfirm modal={modalConfirm} eventModal={eliminarItem} updateModal={setModalConfirm}>
                    <>
                        Anular registro: {bean.IDEnjoyedVacation}
                    </>
                </ModalConfirm>
            }

            {modalAnti &&
                <Modal modal={modalAnti} updateModal={setModalAnti} eventModal={agregarVAnticipated}>
                    <>
                        <div className="row">
                            <div className="col-md-12">
                                <InputDate name="desde" label="Fechas de inicio" value={beanAnt.fromDate} setDate={(e: any) => { setBeanAnt({ ...beanAnt, "fromDate": e }) }} />
                            </div>
                            <div className="col-md-12">
                                <InputDate name="desde" label="Fecha de Finalización" value={beanAnt.uptoDate} setDate={(e: any) => { setBeanAnt({ ...beanAnt, "uptoDate": e }) }} />
                            </div>
                            <div className="col-md-12">
                                <label className="form-label">Descripción</label>
                                <input className="form-control" type="text" value={beanAnt.Descripcion} onChange={(e) => { setBeanAnt({ ...beanAnt, 'Descripcion': e.target.value }) }}></input>
                            </div>
                        </div>
                    </>
                </Modal>
            }

            {modalRelation &&
                <Modal modal={modalRelation} updateModal={setModalRelation} eventModal={relationVacation}>
                    <label className='mb-2'>Seleccione una vacacion destinataria</label>
                    <select className="form-control" onChange={(e) => handleSelection(parseInt(e.target.value))}>
                        <option value={null}>...</option>
                        {_lista?.map((item: any) =>
                            <option value={item.DataBeanProperties.IDDocument}
                            >{item.DataBeanProperties.NoveltyTypeName}-{item.DataBeanProperties.Name}-{item.DataBeanProperties.DocumentDate}
                            </option>
                        )}
                    </select>
                </Modal>
            }

            {modalRelease &&
                <ModalConfirm eventModal={releaseVacation} modal={modalRelease} updateModal={setModalRelease}>
                    Liberar registro: {bean.IDEnjoyedVacation}
                </ModalConfirm>
            }
        </>
    )
}