import { useEffect, useState } from "react";
import { FinanceService } from '../../../../core/services/FinanceService';
import { ButtonBudgetID } from './ButtonBudgetID';
import { BudgetIDChilds } from './BudgetIDChilds';
import { BasicButton } from "../../../theme/Component/BasicButton";
import { useDashboardStore } from "../../../pages/hooks/useDashboardStore";
import { _single } from "../../../../core/services/dataBean/EntityCatalog";
import { SelectExpensiveBudgetIDType } from "../select/SelectExpensiveBudgetIDType";
import { Modal } from "../../../theme/Component/Modal";
import { toast } from "react-toastify";


interface Props {
  tipoPresupuesto: number | null;
  id: number | null;
  edit: boolean;
  isExpensive?: boolean;
  year?: number;
}
export const BudgetID = ({ id, edit, tipoPresupuesto, isExpensive, year }: Props) => {
  const service = new FinanceService();
  const { setLoading } = useDashboardStore();
  const [lista, setLista] = useState([]);
  const [listaType, setListaType] = useState([]);
  const [txt, setTxt] = useState<string>('');
  const [bean, setBean] = useState<any>({});
  const [type, setType] = useState(0);

  const [modalAdd, setModalAdd] = useState<any>({ name: 'Agregar Item', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-md' });

  const [idchild, setIDchild] = useState(0);

  useEffect(() => {

    if (tipoPresupuesto) {
      setType(tipoPresupuesto);
    }
    getSeparator();
    getListaType();
  }, []);

  useEffect(() => {
    console.log(_single.defaultCache)
  }, [type])

  const createBean = () => {
    if (bean.Code && bean.Name) {
      setLoading(true);
      service.createBudgetIDString(type, bean.Code, bean.Name).subscribe(
        (resp: any) => {
          getListatree();
          _single.create(bean);
          setIDchild(0)
          _single.deleteCacheItem("budgetChild_")
          _single.deleteCacheItem("treeBusget_")
          setLoading(false);
        }
      );
      setModalAdd({ ...modalAdd, 'view': false });
    } else {
      toast.error('Código y Nombre son obligatorios');
    }
  }

  const getListaType = () => {
    let lis = _single.getCacheItem('TypeBudget');
    if (lis)
      setListaType(JSON.parse(lis));
    else {
      setLoading(true);
      service.getBudgetIDTypeCatalog(null).subscribe((resp: any) => {
        if (resp.DataBeanProperties.ObjectValue) {
          let arbol: any = resp.DataBeanProperties.ObjectValue;

          setListaType(resp.DataBeanProperties.ObjectValue);
          _single.setCacheItem('TypeBudget', JSON.stringify(resp.DataBeanProperties.ObjectValue));
        }
        setLoading(false);
      });
    }
  };
  const getListatree = () => {
    let tmp = _single.getCacheItem('treeBusget_' + type);
    if (tmp)
      setLista(JSON.parse(tmp));
    else {
      setLoading(true);
      service.getTreeForBudgetID(type).subscribe((resp: any) => {

        if (resp.DataBeanProperties.ObjectValue) {
          let arbol: any = resp.DataBeanProperties.ObjectValue.EnvolvedObject.DataBeanProperties;
          setLista(resp.DataBeanProperties.ObjectValue.Childs);
          _single.setCacheItem('treeBusget_' + type, JSON.stringify(resp.DataBeanProperties.ObjectValue.Childs));
        }
        setLoading(false);
      });

    }
  };

  const getListatreebyName = () => {
    setLoading(true);
    service.getBudgetIDByName(type, txt).subscribe((resp: any) => {
      //console.log(resp);

      if (resp.DataBeanProperties.ObjectValue) {
        let arbol: any = resp.DataBeanProperties.ObjectValue;
        let lis = resp.DataBeanProperties.ObjectValue;

        lis.forEach((element: any) => {

          element.EnvolvedObject = element;
        });
        //console.log(lis);

        setLista(lis);
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  };

  const getSeparator = () => {

    localStorage.setItem('separador', '-');
    // service.getBudgetIDSeparator().subscribe(
    //   (resp: any) => {
    //     let separador = resp.DataBeanProperties.ObjectValue;
    //     localStorage.setItem('separador', separador);
    //   }
    // );

  }


  useEffect(() => {
    getListatree();
  }, [type])

  return (
    <>
      <div className="row mb-4">
        {isExpensive ?
          <div className="col-md-6">
            <SelectExpensiveBudgetIDType year={year} idSelector={type} onChange={(e) => { setType(parseInt(e)) }} />
          </div>
          :
          <div className="col-md-6">
            <label> Tipo Presupuesto</label>
            <select disabled={tipoPresupuesto ? true : false} className="form-select" value={type} onChange={(e) => { setType(parseInt(e.target.value)) }}>
              <option value={0}>...</option>
              {listaType.length > 0 &&
                listaType.map((item: any) => {
                  return (
                    <option value={item.DataBeanProperties.IDBudgetIDType}>{item.DataBeanProperties.Name}</option>
                  )
                })
              }
            </select>
          </div>
        }
        {type > 0 &&
          <>
            <div className="col-md-4">
              <label>Nombre</label>
              <input type="text" name="name" className="form-control" value={txt} onChange={(e) => { setTxt(e.target.value) }} />
            </div>
            <div className="col-md-2 mt-3">
              <BasicButton icon="" clase="success" eventClick={getListatreebyName}>Buscar</BasicButton>
            </div>
          </>

        }

        {type && edit ?
          <div className="row">
            <div className="col-md-12 d-flex justify-content-end">
              <button title={"Agregar Rubro Presupuestal"} type="button" className="btn btn-icon " onClick={() => { setBean({}); setModalAdd({ ...modalAdd, "view": true }) }}>
                <i className={"ri-file-add-line"} style={{ fontSize: "15px" }} ></i>
              </button>
            </div>
          </div>
          :
          null
        }

      </div>
      <div className="row">
        <div className="col">
          {lista.length === 0 && type != 0 &&
            <ButtonBudgetID
              idType={type}
              id={0}
              bean={{}}
              updateEvent={() => {
                getListatree();
              }}
            />
          }
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <ul className="list-group" key={'root-budget'}>
            {lista.length > 0 &&
              lista.map((item: any) => {
                return (
                  <li className="list-group-item itemh" onClick={() => { setBean(item.EnvolvedObject.DataBeanProperties) }} key={item.EnvolvedObject.DataBeanProperties.IDLn}>
                    {edit &&
                      <ButtonBudgetID
                        idType={type}
                        id={item.EnvolvedObject.DataBeanProperties.IDLn}
                        bean={item.EnvolvedObject.DataBeanProperties}
                        updateEvent={() => {
                          getListatree();
                        }}
                      />
                    }
                    <strong className={idchild === item.EnvolvedObject.DataBeanProperties.IDLn ? 'cursor treeselect' : 'cursor'} onClick={() => {
                      setIDchild(item.EnvolvedObject.DataBeanProperties.IDLn);
                      localStorage.setItem('BudgetID', JSON.stringify(item.EnvolvedObject.DataBeanProperties));
                    }}>
                      {/* <strong style={{marginLeft:'15px'}}>{item.EnvolvedObject.DataBeanProperties.BudgetViewCode} </strong>  */}
                      <strong style={{ marginLeft: '15px' }}>{item.EnvolvedObject.DataBeanProperties.JsonCode} </strong>
                      <small>{item.EnvolvedObject.DataBeanProperties.Name}</small>
                    </strong>

                    <span className="badge bg-success ms-1">{item.ChildrenCount}</span>

                    {(idchild === item.EnvolvedObject.DataBeanProperties.IDLn) &&
                      <BudgetIDChilds _type={type} idChild={idchild} edit={edit} editBean={(item: any) => { setBean(item) }}
                      />
                    }
                  </li>
                );
              })}
          </ul>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <Modal modal={modalAdd} updateModal={setModalAdd} eventModal={createBean}>
            <div className="row">
              <div className="col-md-12">
                <label>Código</label>
                <input type="text" className="form-control" value={bean.Code} onChange={(e) => { setBean({ ...bean, "Code": e.target.value }) }} />
              </div>
              <div className="col-md-12">
                <label >Nombre</label>
                <textarea className="form-control" value={bean.Name} onChange={(e) => { setBean({ ...bean, "Name": e.target.value }) }} />
              </div>
            </div>
          </Modal>
        </div>
      </div>
    </>
  );
};
