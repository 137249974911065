import { useEffect, useState } from "react";


import { toast } from "react-toastify";
import { BankService } from "./CashDeskAccount-Service";


interface Props {
    id: number;
    onchange: Function;
    office?: number;
    label?: string;
    idAccount?: number;
}

export const CashDeskAccountSelector = ({ id, onchange, office, label, idAccount }: Props) => {


    const service = new BankService();
    const [lista, setLista] = useState<any>([]);
    const [idLista, setIDLista] = useState<number>(0);


    useEffect(() => {
        getCashDeskAccountCatalog();
    }, [])

    useEffect(() => {
        if (id) {
            setIDLista(id);
        }
    }, [id])

    const setValue = (e: any) => {
        onchange(parseInt(e));
        setIDLista(e);
    }

    const getCashDeskAccountCatalog = () => {
        service.spinON();
        service.getCashDeskAccountCatalog(office ?? null, idAccount ?? null).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ErrorMessage) {
                    toast.error(resp.DataBeanProperties.ErrorMessage);
                }
                else if (resp.DataBeanProperties.ObjectValue) {
                    setLista(resp.DataBeanProperties.ObjectValue);
                }
                service.spinOFF();
            }
        )
    }


    return (
        <>
            <label>{label ?? "Caja Destino"}</label>
            <select className="form-select" value={idLista} onChange={(e) => setValue(e.target.value)}>
                <option value={null}>....</option>
                {lista.map((item: any, index: number) => {
                    return (
                        <option key={index} value={item.DataBeanProperties.IDCashDeskAccount}>{item.DataBeanProperties.IDCashDeskAccount} - {item.DataBeanProperties.Name}</option>
                    )
                })}
            </select>
        </>
    )
}