import { DataBean } from "../DataBean";

export class TechnicalSkillType extends DataBean {


    public async getTechnicalSkillTypeCatalog(idTechnicalSkill: number | null): Promise<any> {
        return this.getCustomService("PeopleArt", "java.util.List_getTechnicalSkillTypeCatalog_Number", [idTechnicalSkill]);
    }

    public async deleteTechnicalSkillType(bean): Promise<any> {
        return this.getCustomService("PeopleArt", "void_deleteTechnicalSkillType_Number", [
            {
                "DataBeanProperties": bean,
                "DataBeanName": "com.peopleart.bean.appointment.TechnicalSkillType"
            }
        ]);
    }
}