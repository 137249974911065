import { DataBean } from "../DataBean";

export class TransversalSkillProduct extends DataBean {


    public async getTransversalSkillProductCatalog(idTransversalSkill: number | null): Promise<any> {
        return this.getCustomService("PeopleArt", "java.util.List_getTransversalSkillProductCatalog_Number", [idTransversalSkill]);
    }

    public async deleteTransversalSkillProduct(bean): Promise<any> {
        return this.getCustomService("PeopleArt", "void_deleteTransversalSkillProduct_Number", [
            {
                "DataBeanProperties": bean,
                "DataBeanName": "com.peopleart.bean.appointment.TransversalSkillProduct"
            }
        ]);
    }
}