import { useState, useEffect } from 'react';
import { Singleton } from '../../core/services/Singleton';
import { ContractService } from '../../core/services/ContractService';
import { BasicTable } from '../theme/Component/BasicTable';
import { DropDown } from '../theme/Component/DropDown';
import { Modal } from '../theme/Component/Modal';
import { ListMenu } from '../theme/Component/ListMenu';
import { AdquisitionItemBudgetID } from './AdquisitionItemBudgetID';
import { AdqusitionArticle } from './AdqusitionArticle';
import { useDashboardStore } from '../pages/hooks/useDashboardStore';
interface Props {
    idFunctionalIDPreparer: number;
}
export const ItemsPreparador = ({ idFunctionalIDPreparer }: Props) => {

    const single = Singleton.getInstance()
    const { setLoading } = useDashboardStore();
    const service = new ContractService();
    const [obs, setObs] = useState<string>('');
    const [drop, setDrop] = useState<any>({});
    const [bean, setBean] = useState<any>({});
    const [lista, setLista] = useState([]);
    const [listaHistorico, setHistorico] = useState<any>([]);
    const [modal, setModal] = useState<any>({ name: 'Acciones', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalRubro, setModalRubro] = useState<any>({ name: 'Rubros', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    // const [modalObligacion, setModalObligacion] = useState<any>({ name: 'Obligaciones', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalBienes, setModalBienes] = useState<any>({ name: 'Bienes y/o Servicios', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });

    useEffect(() => {
        getAdquisitionItemOnPreparedState();
    }, [idFunctionalIDPreparer, drop])

    const getAdquisitionItemOnPreparedState = () => {

        setObs('');
        setLoading(true);
        service.getAdquisitionItemOnPreparedState(idFunctionalIDPreparer, null, null).subscribe(
            (resp: any) => {
               //console.log(resp);
                if (resp.DataBeanProperties.ObjectValue) {
                    setLista(resp.DataBeanProperties.ObjectValue.DataBeanProperties.List);
                }
                setLoading(false);
            }
        )

    }

    const idBean = 'IDAdquisitionItem';
    const head = [
        { title: 'ID', property: idBean },
        { title: 'Necesidad', property: 'Description' },
        { title: 'Valor total estimado', property: 'TotalValue' },
        { title: 'Valor total artículos', property: 'StructuredValue' },
        { title: 'Tipo de Presupuesto', property: 'BudgetTypeName' },
        { title: 'Fecha Estimada Inicio Proceso', property: 'MonthName' },
        { title: 'Duracion estimada del contrato (Meses)', property: 'EstimatedMonths', visible: false },
        { title: 'Modalidad de seleccion u Otro si de Contrato ', property: 'IDHiringModalityName', visible: false },
        { title: 'Fuente de los recursos', property: 'IDBudgetResourceName', visible: false },
        { title: 'Rubro Presupuestal', property: 'IDLnBudgetIDNameList', visible: false },
        { title: 'Iniciativa Estratégica/Proyecto', property: 'IDLnProjectIDName', visible: false },
        { title: 'Etapa', property: 'PhaseStateName', visible: false },
        { title: 'Dependencia Solicitante', property: 'IDLnFunctionalIDName' },
        { title: 'Estado preparador', property: 'PreparationStateName' },
        { title: 'Preparador', property: 'PreparerName' },
    ]


    const actionsDropDown = [
        { id: 1, name: 'Devolver a Preparador', permiso: null, activo: true },
        { id: 2, name: 'Aprobar preparación', permiso: null, activo: true },
        { id: 3, name: 'Historico Aprobacion', permiso: null, activo: true },
    ];



    const eventDrow = (item: any) => {
       //console.log(item);
        setDrop(item);
        setModal({ ...modal, "view": true, "name": item.name });
        if (drop.id === 3) {

            setLoading(true);
            service.getAdquisitionItemApprovalNoteCatalog(bean.IDAdquisitionItem, null, null, null).subscribe(
                (resp: any) => {
                   //console.log(resp);
                    if (resp.DataBeanProperties.ObjectValue.DataBeanProperties.List) {
                        setHistorico(resp.DataBeanProperties.ObjectValue.DataBeanProperties.List);

                    }
                    setLoading(false);
                    single.makeAcction("El usuario consultó el histórico de aprobaciones del ítem " + (bean[idBean]), bean);
                }
            )
        }
    }

    const updateEventModal = () => {

        if (drop.id === 1) {
            setLoading(true);
            service.setAdquisitionItemOnRejectedPreparedState(bean.IDAdquisitionItem, single.getAccountID(), obs).subscribe(

                (resp: any) => {
                    setLoading(false);
                   //console.log(resp);
                    single.makeAcction("El usuario devolvió el ítem " + (bean[idBean]) + " al preparador " + (bean.PreparerName), bean)
                    setBean({})
                    getAdquisitionItemOnPreparedState();
                    setModal({ ...modal, "view": false });
                }
                
            )
        }
        else if (drop.id === 2) {
            setLoading(true);
            service.setAdquisitionItemOnApprovedPreparedState(bean.IDAdquisitionItem, single.getAccountID(), obs).subscribe(
                
                (resp: any) => {
                    setLoading(false);
                   //console.log(resp);
                    getAdquisitionItemOnPreparedState();
                    setBean({})
                    setModal({ ...modal, "view": false });
                    single.makeAcction("El usuario aprobó el ítem " + (bean[idBean]), bean)

                }

            )
        }


    }

    const renderButtons = () => {
        return (
            <>
                {bean[idBean] &&
                    <DropDown key={single.getRandomInt()} lista={actionsDropDown} eventClick={eventDrow}></DropDown>
                }

            </>
        )
    }
    const headHistorico = [
        { title: 'Fecha', property: 'EventDate' },
        { title: 'Identificación', property: 'AccountID' },
        { title: 'Nombre', property: 'AccountName' },
        { title: 'Aprobado', property: 'Approved' },
        { title: 'Descripción', property: 'Description' }
    ]

    const listaBotonesItems = () => {
        const botones = [
            { titulo: 'Rubros', icono: 'ri-git-branch-line', visible: true, evento: () => { setModalRubro({ ...modalRubro, "view": true }) } },
            // { titulo: 'Obligaciones', icono: 'ri-list-ordered', visible: true, evento: () => { setModalObligacion({ ...modalObligacion, "view": true }) } },
            { titulo: 'Bien/Servicio', icono: 'ri-file-list-line', visible: true, evento: () => { setModalBienes({ ...modalBienes, "view": true }) } },
        ]
        return (
            <ListMenu listaBotones={botones}></ListMenu>
        )
    }

    return (
        <>
            <BasicTable listButtons={listaBotonesItems()} headButtons={renderButtons()} head={head} body={lista} rowSet={setBean} />
            <Modal modal={modal} updateModal={setModal} eventModal={updateEventModal}>
                {drop.id != 3 &&
                    <div className="row">
                        <div className="col">
                            <label>Observaciones</label>
                            <textarea className='form-control' value={obs} onChange={(e) => { setObs(e.target.value) }}></textarea>
                        </div>
                    </div>
                }
                {drop.id == 3 &&
                    <BasicTable head={headHistorico} body={listaHistorico} rowSet={() => { }}></BasicTable>
                }
            </Modal>
            {/* rubros de la necesidad  */}
            <Modal modal={modalRubro} updateModal={setModalRubro} eventModal={() => { }}>
                <div className="row">
                    <div className="col">
                        <AdquisitionItemBudgetID CanModify={bean.PreparationState} adquisitionItem={bean} iDBudgetIDType={bean.IDBudgetIDType} />
                    </div>
                </div>
            </Modal>

            {/* modal obligaciones  */}
            {/* <Modal modal={modalObligacion} updateModal={setModalObligacion} eventModal={() => {}}>
                <Obligation CanModify={bean.PreparationState} IDAdquisitionItem={bean.IDAdquisitionItem} />
            </Modal> */}

            {/* modal Bienes y o servicios  */}
            <Modal modal={modalBienes} updateModal={setModalBienes} eventModal={() => { }}>
                <AdqusitionArticle IDAdquisitionItem={bean.IDAdquisitionItem}></AdqusitionArticle>
            </Modal>
        </>
    )
}