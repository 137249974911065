import { useEffect, useState } from "react";
import { _CashReimbursementItem, _CashRequest } from "../../../../core/services/dataBean/EntityCatalog"
import useDataBean from "../../../../core/services/dataBean/useDataBean"
import { BasicPage } from "../../../theme/Component/BasicPage"
import { Singleton } from "../../../../core/services/Singleton";
import { BasicTable, MASK_DATE, MASK_MONEY, MASK_NUMBER } from "../../../theme/Component/BasicTable";
import { YearSelector } from "../../../../core/shared/inputs/YearSelector";
import { ListMenu } from "../../../theme/Component/ListMenu";
import { Modal } from "../../../theme/Component/Modal";
import { CashRequestItem } from "../CashResquestItems/CahsRequestItems";
import { AccountEditor } from "../../../editor/AccountEditor";
import { CashRequestConstantSelector } from "./CashRequestConstantSelector";
import { InputMoney } from "../../../theme/Input/InputMoney";
import { CashDeskAccountSelector } from "../CashDeskAccount/CashDeskAccountSelector";
import { CashRequestMoney } from "../CashRequestMoney/CashRequestMoney";
import { CashRequestRefundConstantsSelector } from "./CashRequestRefundConstantsSelector";
import { PrintJson } from "../../../theme/Component/PrintJson";
import { toast } from "react-toastify";



export const ManageCahsRequest = () => {

    const { lista, setLista, bean, setBean, instance, handleChange } = useDataBean(_CashRequest);
    const single = Singleton.getInstance();

    const [year, setYear] = useState<number>(single.getCurrenYear());
    const [account, setAccount] = useState<number>(NaN);
    const [state, setState] = useState<number>(3);
    const [cashID, setCashID] = useState<number>(NaN);
    const [obs, setObs] = useState<string>("");
    const [response, setResponse] = useState<any>({});
    const [refund, setRefund] = useState<any>({});
    const [refundResponse, setRefundResponse] = useState<any>();

    const [modal, setModal] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: "modal-lg", });
    const [modalItems, setModalItems] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: "modal-lg", });
    const [modalConfirm, setModalConfirm] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: "modal-md", });
    const [modalAdvance, setModalAdvance] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: "modal-lg", });
    const [modalRefund, setModalRefund] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: "modal-lg", });
    const [modalHisotry, setModalHistory] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: "modal-lg", });
    const [modalVisor, setModalVisor] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: "modal-lg", });

    useEffect(() => {
        if (state && cashID) {
            getLista();
        }
    }, [state, cashID])

    const getLista = () => {
        instance.getCashRequestCatalogForState(single.getAccountID(), year, state, cashID).then(
            (resp) => {
               //console.log(resp)
                setLista(resp)
            }
        ).catch(
            err => {
               console.error(err)
            }
        )
    }

    const processRequest = () => {
        instance.processCashRequest(bean.IDCashRequest, bean.CashRequestDate, single.getAccountID(), obs).then(
            (resp) => {
                setModal({ ...modal, "view": false });
                getLista();
            }
        ).catch(
            err => {
               console.error(err);
            }
        )
    }

    const rejectRequest = () => {
        instance.rejectCashRequest(bean.IDCashRequest, bean.CashRequestDate, single.getAccountID(), obs).then(
            (resp) => {
                setModalConfirm({ ...modalConfirm, "view": false });
                getLista();
            }
        ).catch(
            err => {
               console.error(err);
            }
        )

    }

    const payAdvance = () => {
        instance.addAdvanceValueToCashRequest(bean.IDCashRequest, bean.CashRequestDate, response.Obs, parseInt(response.Value), single.getAccountID()).then(
            (resp) => {
                setModalAdvance({ ...modalAdvance, "view": false });
                getLista();
                setResponse({})
            }
        ).catch(
            err => {
               console.error(err);
            }
        )
    }

    const payRefund = () => {
       //console.log(bean.IDCashRequest, bean.CashRequestDate, refund.Type, null, refund.Obs, parseInt(refund.Value), single.getAccountID())
        instance.getCashReceiptToCashRequest(bean.IDCashRequest, bean.CashRequestDate, refund.Type, null, refund.Obs, parseInt(refund.Value), single.getAccountID()).then(
            (resp) => {
               //console.log(resp)
                setModalRefund({ ...modalRefund, "view": false });
                getLista();
                setRefund({});
                setRefundResponse(resp);
                setModalVisor({ ...modalVisor, "view": true });
            }
        ).catch(
            err => {
                toast.error(err)
            }
        )
    }

    const head = [
        { title: 'ID', property: 'IDCashRequest', mask: MASK_NUMBER },
        { title: 'Fecha Solicitud', property: 'CashRequestDate' },
        { title: 'Solicitado por', property: 'ApplicantName' },
        { title: 'Descripción', property: 'Description' },
        { title: 'Estado de la Operación', property: 'OrderStateName' },
        { title: 'Estado', property: 'StateName' },
        { title: 'Solicitante', property: 'AccountName' },
        { title: 'Requiere Anticipo', property: 'RequiresAdvance' },
        { title: 'Anticipo Solicitado', property: 'AdvanceValue', mask: MASK_MONEY },
        { title: 'Anticipo Pagado', property: 'AdvanceApprovedValue', mask: MASK_MONEY },
        { title: 'Documento', property: 'AdvanceDocumentConsecutive' },
        { title: 'Valor Rembolsado', property: 'Value', mask: MASK_MONEY },
        { title: 'Caja Solicitada', property: 'CashDeskAccountName' },
        { title: 'Responsable de Caja', property: 'IDEmployeeName' },
    ]

    const listaBotones = () => {
        const botones = [
            { titulo: "Marcar solicitud Procesada", icono: "ri-check-line", visible: (bean.OrderState !== 4 && bean.OrderState !== 5), evento: () => { setModal({ ...modal, view: true, name: "Marcar solicitud Procesada" }); }, },
            { titulo: "Realizar Anticipo a Solicitud de Caja Menor", icono: "ri-money-dollar-box-line", visible: (bean.OrderState !== 4 && bean.OrderState !== 5), evento: () => { setModalAdvance({ ...modalAdvance, view: true, name: `Realizar Anticipo` }); }, },
            { titulo: "Realizar Reintegro", icono: "ri-exchange-dollar-line", visible: (bean.OrderState !== 4 && bean.OrderState !== 5), evento: () => { setModalRefund({ ...modalRefund, view: true, name: `Realizar Reintegro` }); }, },
            { titulo: "Ver Anticipos y Reintegros", icono: "ri-eye-line", visible: (bean.OrderState !== 4 && bean.OrderState !== 5), evento: () => { setModalHistory({ ...modalHisotry, view: true, name: `Anticipos y Reintegros` }); }, },
            { titulo: "Ver Items de la solicitud", icono: "ri-file-list-line", visible: single.canEdit(), evento: () => { setModalItems({ ...modalItems, "name": "Items de la solicitud de Pago", "view": true }) }, },
            { titulo: "Rechazar Solicitud", icono: "ri-close-line", visible: (bean.OrderState !== 4 && bean.OrderState !== 5), evento: () => { setModalConfirm({ ...modalConfirm, view: true, name: `Rechazar Solicitud` }); }, },
        ];
        return <ListMenu listaBotones={botones} />;
    };

    useEffect(() => {
        setObs("");
    }, [modal, modalConfirm])

    const [missingFields, setMissingFields] = useState<string[]>([]);

    const validatePayAdvance = () => {
        if (validateForm("advance") > 0) {
            toast.error("Faltan campos obligatorios por completar");
            return;
        } else {
            payAdvance();
        }
    }

    const validatePayRefund = () => {
        if (validateForm("refund") > 0) {
            toast.error("Faltan campos obligatorios por completar");
            return;
        } else {
            payRefund();
        }
    }

    const validateForm = (type: "refund" | "advance") => {
        let missing = [];

        if (type == "refund") {
            const requiredFields = [
                "Value", "Obs", "Type"
            ];

            for (let field of requiredFields) {
                if (!refund[field]) {
                    missing.push(field);
                }
            }
        } else if (type == "advance") {
            const requiredFields = [
                "Value", "Obs"
            ];

            for (let field of requiredFields) {
                if (!response[field]) {
                    missing.push(field);
                }
            }
        }

        //console.log(missing)
        setMissingFields(missing);
        return missing.length
    };

    return (
        <>
            <BasicPage titulo="Solicitudes Recibidas para Caja Menor">
                <div className="row">
                    <div className="col-3">
                        <AccountEditor idAccount={account} label="" onChange={({ IDAccount }) => { setAccount(IDAccount) }} />
                    </div>
                    <div className="col-2">
                        <YearSelector onChange={(e) => { setYear(e) }} value={year} />
                    </div>
                    <div className="col-4">
                        <CashRequestConstantSelector idSelector={state} onChange={(e) => setState(e)} />
                    </div>
                    <div className="col-md-3">
                        <CashDeskAccountSelector id={cashID} onchange={(e) => { setCashID(e) }} idAccount={single.getAccountID()} />
                    </div>
                </div>
                <BasicTable listButtons={listaBotones()} body={lista} head={head} rowSet={setBean} />
            </BasicPage>

            <Modal modal={modal} updateModal={setModal} eventModal={processRequest} >
                <div className="col-12">
                    <label>Observaciones</label>
                    <textarea value={obs} name="Observations" className="form-control" onChange={(e) => { setObs(e.target.value) }} rows={4} />
                </div>
            </Modal>

            <Modal modal={modalConfirm} updateModal={setModalConfirm} eventModal={rejectRequest} >
                <div className="col-12">
                    <label>Observaciones</label>
                    <textarea value={obs} name="Observations" className="form-control" onChange={(e) => { setObs(e.target.value) }} rows={4} />
                </div>
            </Modal>

            <Modal modal={modalAdvance} updateModal={setModalAdvance} eventModal={validatePayAdvance} >
                <div className="row">
                    {/* <div className="col-md-4">
                        <CashDeskAccountSelector id={response.CashReimbID} onchange={(e) => { setResponse({ ...response, "CashReimbID": e }) }} idAccount={single.getAccountID()} />
                    </div> */}
                    <div className="col-md-6">
                        <label>Valor Solicitado</label>
                        <input type="number" value={bean.AdvanceValue} disabled className="form-control" />
                    </div>
                    <div className="col-md-6">
                        <label className={`${missingFields.includes('Value') ? 'has-error' : ''}`}>Valor a Anticipar</label>
                        <InputMoney id={1} name="value" value={response.Value} onChange={(e) => { setResponse({ ...response, "Value": e }) }} />
                    </div>
                    <div className="col-12">
                        <label className={`${missingFields.includes('Obs') ? 'has-error' : ''}`}>Observaciones</label>
                        <textarea value={response.Obs} name="Observations" className="form-control" onChange={(e) => { setResponse({ ...response, "Obs": e.target.value }) }} rows={4} />
                    </div>
                </div>
            </Modal>

            <Modal modal={modalRefund} updateModal={setModalRefund} eventModal={validatePayRefund} >
                <div className="row">
                    <div className="col-md-6">
                        <label className={`${missingFields.includes('Type') ? 'has-error' : ''}`}>Tipo de Reintegro</label>
                        <CashRequestRefundConstantsSelector label=" " idSelector={refund.Type} onChange={(e) => { setRefund({ ...refund, "Type": e }) }} />
                    </div>
                    <div className="col-md-6">
                        <label className={`${missingFields.includes('Value') ? 'has-error' : ''}`}>Valor a Anticipar</label>
                        <InputMoney id={2} name="Value" onChange={(e) => { setRefund({ ...refund, "Value": e }) }} value={refund.Value} />
                    </div>
                    <div className="col-12">
                        <label className={`${missingFields.includes('Obs') ? 'has-error' : ''}`}>Observaciones</label>
                        <textarea value={refund.Obs} name="Observations" className="form-control" onChange={(e) => { setRefund({ ...refund, "Obs": e.target.value }) }} rows={4} />
                    </div>
                </div>
            </Modal>

            <Modal modal={modalItems} updateModal={setModalItems} >
                <CashRequestItem _idCashAccount={cashID} entry="Manage" idCashRequest={bean.IDCashRequest} cashRequestDate={bean.CashRequestDate} idAccount={bean.IDAccount} />
            </Modal>

            <Modal modal={modalHisotry} updateModal={setModalHistory} >
                <CashRequestMoney cashRequestDate={bean.CashRequestDate} idCashRequest={bean.IDCashRequest} />
            </Modal>

            <Modal modal={modalVisor} updateModal={setModalVisor} >
                <PrintJson json={refundResponse} />
            </Modal>
        </>
    )
}