import { DataBean } from "../DataBean";

export class ProductLine extends DataBean {


    public async getProductCatalogLine(args:any): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_getProductCatalogLine_Number", [args]);
    }

 
}