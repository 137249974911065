import { toast } from "react-toastify";
import { BasicPage } from "../../../theme/Component/BasicPage";
import { BasicTable, MASK_DATE, MASK_NUMBER } from "../../../theme/Component/BasicTable";
import { ListMenu } from "../../../theme/Component/ListMenu";
import { Modal } from "../../../theme/Component/Modal";
import { ModalConfirm } from "../../../theme/Component/ModalConfirm";
import { useEffect, useState } from "react";
import { Singleton } from "../../../../core/services/Singleton";
import useDataBean from "../../../../core/services/dataBean/useDataBean";
import { _TaxCodeScheme } from "../../../../core/services/dataBean/EntityCatalog";

export const TaxCodeScheme = () => {

    const taxCodeScheme = useDataBean(_TaxCodeScheme);
    const single = Singleton.getInstance();
    const [lista, setLista] = useState<any>([]);
    const [modal, setModal] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalC, setModalC] = useState<any>({ name: "Eliminar", tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });

    useEffect(() => {
        getLista();
    }, [])

    const getLista = () => {

        taxCodeScheme.instance.getTaxCodeSchemeCatalog(null).then(
            (resp: any) => {
                if (resp) {
                    setLista(resp)
                }
            }
        )

    }

    const create = () => {
        taxCodeScheme.setBean({});
        setModal({ ...modal, "view": true, "name": `Agregar Tipos de Códigos de Clasificación de Impuestos` });
    }

    const deleteItem = () => {
        taxCodeScheme.deleteItemVoid(taxCodeScheme.bean.IDTaxCodeScheme).then(
            (resp: any) => {
                getLista();
                setModalC({ ...modalC, "view": false });
                single.delete(taxCodeScheme.bean.IDTaxCodeScheme, taxCodeScheme.bean)
            }
        )

    }
    const editaItem = () => {
       //console.log(taxCodeScheme.bean);
        taxCodeScheme.update().then(
            (resp: any) => {
                getLista();
                setModal({ ...modal, "view": false });
                if (taxCodeScheme.bean.IDTaxCodeScheme) {
                    single.update(taxCodeScheme.bean)
                } else {
                    single.create(taxCodeScheme.bean)
                }
            }
        ).catch(err => {
            toast.error(err);
        })
    }

    const head = [
        { title: 'ID', property: 'IDTaxCodeScheme', mask: MASK_NUMBER, visible: true },
        { title: 'Código', property: 'Code', visible: true },
        { title: 'Nombre', property: 'Name', visible: true },
        { title: 'Descipcion', property: 'Description', visible: true },
        { title: 'Desde', property: 'Since', mask: MASK_DATE, visible: true },
    ]

    const iconButtonsItem = [
        { nombre: 'Agregar', visible: single.canCreate(), icono: 'ri-file-add-line', evento: create }
    ]
    const listaBotones = () => {
        const botones = [
            { titulo: 'Editar', icono: 'ri-edit-2-line', visible: single.canEdit(), evento: () => { setModal({ ...modal, "name": `Editar ${taxCodeScheme.bean.Name}`, "view": true }) } },
            { titulo: 'Eliminar', icono: 'ri-delete-bin-line', visible: single.canDelete(), evento: () => { setModalC({ ...modalC, "view": true }) } },
        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }


    return (
        <>
            <BasicPage titulo="Tipos de Códigos de Clasificación de Impuestos">
                <div className="col-md-12">
                    <BasicTable body={lista} head={head} rowSet={taxCodeScheme.setBean} listButtons={listaBotones()} iconButtons={iconButtonsItem}></BasicTable>
                </div>
            </BasicPage>

            {modal.view &&
                <Modal modal={modal} updateModal={setModal} eventModal={editaItem}>
                    <div className="row">
                        <div className="col-6">
                            <label>Código</label>
                            <input type="text" name="Code" className="form-control" value={taxCodeScheme.bean.Code} onChange={taxCodeScheme.handleChange} />
                        </div>
                        <div className="col-6">
                            <label>Nombre</label>
                            <input type="text" name="Name" className="form-control" value={taxCodeScheme.bean.Name} onChange={taxCodeScheme.handleChange} />
                        </div>
                        <div className="col-12">
                            <label>Descripción</label>
                            <textarea name="Description" className="form-control" value={taxCodeScheme.bean.Description} onChange={taxCodeScheme.handleChange} />
                        </div>
                    </div>
                </Modal>
            }

            {modalC.view &&
                <ModalConfirm modal={modalC} updateModal={setModalC} eventModal={deleteItem}>
                    <strong>Eliminar item {taxCodeScheme.bean.Name}</strong>
                </ModalConfirm>
            }
        </>
    )
}