import { useState } from "react";
import { Alert } from "../../../../../theme/Component/Alert";
import { BasicButton } from "../../../../../theme/Component/BasicButton";
import { FileInput } from "../../../../../theme/Component/FileInput";
import { Modal } from "../../../../../theme/Component/Modal";
import { toast } from "react-toastify";

interface Props {
    exportMap: Function;
}

export const UploadQuotes = ({ exportMap }: Props) => {

    const [lista, setLista] = useState([]);
    const [document, setDocument] = useState<any>({});

    const [modal, setModal] = useState<any>({ name: "Agregar Cotizacion", tabIndex: -1, view: false, btnClose: true, btnSubmit: false, format: 'modal-md' });

    const onExportMap = () => {
        if (lista.length > 0) {
            exportMap({
                JSONResponse: JSON.stringify(lista)
            });
        } else {
            toast.error("Debe cargar al menos una cotización")
        }
    }

    const handleAddDocument = () => {
        setModal({ ...modal, view: true });
    };

    const handleSaveDocument = (document) => {

        if (document.Name) {
            setLista((prevLista) => [...prevLista, document]);
            setDocument({});
            setModal({ ...modal, view: false });
        }
    };

    const handleRemoveDocument = (index: number) => {
        setLista((prevLista) => prevLista.filter((_, i) => i !== index));
    };

    return (
        <>
            <div className="row">
                <div className="col-md-12 mb-2 ">
                    <button title={"Agregar"} type="button" className="btn btn-icon" onClick={() => { setModal({ ...modal, "view": true }) }}>
                        <i className={"ri-file-add-line"}></i>
                    </button>
                </div>
                <div className="col-md-12 d-flex justify-content-center mb-2">
                    <div className="row w-75">
                        {lista.length === 0 ? (
                            <Alert clase="warning">
                                <p>No hay documentos para mostrar</p>
                            </Alert>
                        ) : (
                            <div className="list-group">
                                {lista.map((item: any, index: number) => (
                                    <div key={index} className="list-group-item d-flex justify-content-between align-items-center" >
                                        <span style={{ width: "80%", overflow: "hidden", textWrap: "nowrap" }}>
                                            <i className="ri-article-line fs-2 align-middle me-2 cursor"></i>
                                            {index + 1} - {item.Name}
                                        </span>
                                        <button
                                            type="button"
                                            className="btn btn-danger btn-sm"
                                            onClick={() => handleRemoveDocument(index)}
                                        >
                                            <i className="ri-close-line"></i>
                                        </button>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </div>

                <div className="col-md-12 justify-content-end">
                    {lista.length > 0 &&
                        <div className="col-md-4">
                            <BasicButton eventClick={onExportMap} icon="ri-send-plane-2-line" >Enviar</BasicButton>
                        </div>
                    }
                </div>

                <div className="col-md-12">
                    <Modal modal={modal} updateModal={setModal} >
                        <FileInput
                            label="Documento"
                            onCreate={({ Media, MediaContext, Name }) => {
                                handleSaveDocument({
                                    Media,
                                    MediaContext,
                                    Name,
                                });
                            }}
                        />
                    </Modal>
                </div>
            </div>
        </>
    );
};