import { useEffect, useState } from "react"
import { _ImportVariousConcepts } from "../../../../core/services/dataBean/EntityCatalog"
import useDataBean from "../../../../core/services/dataBean/useDataBean"
import { BasicPage } from "../../../theme/Component/BasicPage"
import { Modal } from "../../../theme/Component/Modal"
import { toast } from "react-toastify"
import { FileInput } from "../../../theme/Component/FileInput"
import { EmployeeDiscountTypeEditor } from "../../ParametrosTalentoHumano/EmployeeDiscountType/EmployeeDiscountType.editor"
import { EmployeePaymentsEditor } from "../../ParametrosTalentoHumano/EmployeePayments/EmployyePayment.editor.select"

export const ImportVariousConcepts = () => {

    const { instance } = useDataBean(_ImportVariousConcepts)
    const [modal, setModal] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [bean, setBean] = useState<any>({});

    const [step, setStep] = useState<any>({});

    const importDPN = () => {
       //console.log(bean.IDType, bean.FileUpload.Media, bean.FileUpload.MediaContext, null);
        instance.importEmployeeScheduleDiscounts(bean.IDType, bean.FileUpload.Media, bean.FileUpload.MediaContext, null).then(
            (resp) => {
                setModal({ ...modal, "view": false });
                toast.success("Se Realizo la accion correctamente");
                setBean({})
            }
        ).catch(
            err => { toast.error(err) }
        )
    }

    const importDMN = () => {
       //console.log(bean.IDType, bean.FileUpload?.Media, bean.FileUpload?.MediaContext, null);
        instance.importEmployeeDiscounts(bean.IDType, bean.FileUpload.Media, bean.FileUpload.MediaContext, null).then(
            (resp) => {
                setModal({ ...modal, "view": false });
                toast.success("Se Realizo la accion correctamente");
                setBean({})
            }
        ).catch(
            err => { toast.error(err) }
        )
    }

    const importIL = () => {
        ////console.log(bean.FileUpload?.Media, bean.FileUpload?.MediaContext, null);
        instance.importEmployeeIncapacities(bean.FileUpload.Media, bean.FileUpload.MediaContext, null).then(
            (resp) => {
                setModal({ ...modal, "view": false });
                toast.success("Se Realizo la accion correctamente");
                setBean({})
            }
        ).catch(
            err => { toast.error(err) }
        )
    }

    const importINL = () => {
        ////console.log(bean.FileUpload?.Media, bean.FileUpload?.MediaContext, null)
        instance.importEmployeeNovelties(bean.FileUpload.Media, bean.FileUpload.MediaContext, null).then(
            (resp) => {
                setModal({ ...modal, "view": false });
                toast.success("Se Realizo la accion correctamente");
                setBean({})
            }
        ).catch(
            err => { toast.error(err) }
        )
    }

    const importOIN = () => {
        ////console.log(bean.IDEmploymentBonus, bean.FileUpload?.Media, bean.FileUpload?.MediaContext, null)
        instance.importEmployeePayments(bean.IDEmploymentBonus, bean.FileUpload.Media, bean.FileUpload.MediaContext, null).then(
            (resp) => {
                setModal({ ...modal, "view": false });
                toast.success("Se Realizo la accion correctamente");
                setBean({})
            }
        ).catch(
            err => { toast.error(err) }
        )
    }

    const opentAndSetItem = (item: any) => {
        setStep(item);
        setModal({ ...modal, "view": true, "name": item.name })
    }

    const [card, setCard] = useState<any[]>([
        { id: 1, name: `Importar Descuentos Programados de Nómina`, descripcion: ``, boton: 'Importar', action: () => { importDPN() } },
        { id: 2, name: "Importar Descuentos Mensuales de Nómina", descripcion: "", boton: 'Importar', action: () => { importDMN() } },
        { id: 3, name: "Importar Incapacidades Laborales", descripcion: "", boton: 'Importar', action: () => { importIL() } },
        { id: 4, name: "Importar Novedades Laborales (Vacaciones. Ausentismos, etc.)", descripcion: "", boton: 'Importar', action: () => { importINL() } },
        { id: 5, name: "Importar Otros Ingresos de Nómina", descripcion: "", boton: 'Importar', action: () => { importOIN() } },
    ]);

    return (
        <BasicPage titulo="Importar Diversos Conceptos/Novedades Laborales">
            <div className="row">
                {card.map((item: any) => {
                    return (
                        <div className="col-xxl-4 col-md-6">
                            <div className="card card-body">
                                <div className="flex-grow-1 ms-2">
                                    <h5>{item.name}</h5>
                                    <p>{item.descripcion}</p>
                                    <a href="javascript:void(0)" className="btn btn-primary btn-sm" onClick={() => { opentAndSetItem(item) }}>{item.boton}</a>
                                </div>
                            </div>
                        </div>
                    )
                })}


                {modal.view &&
                    <div className="col-md-12">
                        <Modal modal={modal} updateModal={setModal} eventModal={() => {
                            if (step.id == 1 && bean.FileUpload?.Media && bean.IDType) {
                                importDPN();
                            } else if (step.id == 2 && bean.FileUpload?.Media && bean.IDType) {
                                importDMN();
                            } else if (step.id == 3 && bean.FileUpload?.Media) {
                                importIL();
                            } else if (step.id == 4 && bean.FileUpload?.Media) {
                                importINL();
                            } else if (step.id == 5 && bean.IDEmploymentBonus && bean.FileUpload?.Media) {
                                importOIN();
                            } else {
                                toast.error("Datos invalidos")
                            }
                        }} >
                            <div className="row">
                                {(step.id == 1 || step.id == 2) &&
                                    <div className="col-md-12">
                                        <EmployeeDiscountTypeEditor idSelector={bean.IDType} onChange={(e) => { setBean({ ...bean, "IDType": e.IDType }) }} />
                                    </div>
                                }
                                {step.id == 5 &&
                                    <div className="col-md-12">
                                        <EmployeePaymentsEditor label="Concepto de Ingreso" idSelector={bean.IDEmploymentBonus} onChange={({ IDEmploymentBonus }) => { setBean({ ...bean, "IDEmploymentBonus": IDEmploymentBonus }) }} />
                                    </div>
                                }
                                <div className="col-md-12">
                                    <FileInput directory="DocsImport" Media={bean.FileUpload?.Media} MediaContext={bean.FileUpload?.MediaContext} onCreate={(e: any) => {
                                        setBean({ ...bean, "FileUpload": e });
                                    }} />
                                </div>
                            </div>
                        </Modal>
                    </div>
                }
            </div>
        </BasicPage>
    )
}