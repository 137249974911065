import { DataBean } from "../DataBean";

export class CashReimbursement extends DataBean {

    public async addUBLInvoiceToCashReimbursement(idUBLInvoice: number, issueDate: any, idCashReimbursement: number, cashReimbursementDate: any, observations: string | null, idAccountFiled: number, idFileNumber: string, idThirdBankingAccount: number): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "com.quickdataerp.bean.accountant.CashReimbursementItem_addUBLInvoiceToCashReimbursement_Number_java.util.Date_Number_java.util.Date_String_Number_String_Number", [idUBLInvoice, issueDate, idCashReimbursement, cashReimbursementDate, observations, idAccountFiled, idFileNumber, idThirdBankingAccount]);
    }

    public async getCashReimbursementCatalog(year: number, idCashDeskAccount: number, state: number): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_getCashReimbursementCatalog_Number_Number_Number", [year, idCashDeskAccount, state]);
    }

    public async deleteCashReimbursement(idCashReimbursement: number, date: any): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "void_deleteCashReimbursement_Number_java.util.Date", [idCashReimbursement, date]);
    }

    public async updateCashReimbursement(beanReimbursement: any): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "com.quickdataerp.bean.accountant.CashReimbursement_updateCashReimbursement_com.quickdataerp.bean.accountant.CashReimbursement", [
            {
                "DataBeanProperties": beanReimbursement,
                "DataBeanName": "com.quickdataerp.bean.accountant.CashReimbursement"
            }
        ]);
    }

    public async createCashReimbursementMoneyBase(idAccountantConcept: number, idCashReimbursement: number, cashReimbursementDate: any, values: any, accountantDate: any, description: string, idEmployee: number): Promise<any> {
        return this.getCustomService(
            "QuickDataERPCore",
            "com.quickdataerp.bean.accountant.AccountantDocument_createCashReimbursementMoneyBase_Number_Number_java.util.Date_java.util.List_java.util.Date_String_Number",
            [idAccountantConcept, idCashReimbursement, cashReimbursementDate, values, accountantDate, description, idEmployee]
        );
    }

}