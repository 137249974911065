import { useState, useEffect } from 'react';
import { Singleton } from '../../../core/services/Singleton';
import { useDashboardStore } from '../../pages/hooks/useDashboardStore';
import { BpmServiceall } from '../../../core/services/BpmServiceall';
import { BsFillArrowRightCircleFill, BsFillBagCheckFill, BsSpellcheck, BsUiChecks } from 'react-icons/bs';
import { toast } from 'react-toastify';
import useDataBean from '../../../core/services/dataBean/useDataBean';
import { _CourseAccount } from '../../../core/services/dataBean/EntityCatalogVdos';

interface Props {
    IDCourse: number;
}


export const CursoOverview = ({ IDCourse }: Props) => {
    const CourseAccount = useDataBean(_CourseAccount);
    const single = Singleton.getInstance()
    const { setLoading } = useDashboardStore();
    const service = new BpmServiceall();
    const [lista, setLista] = useState([]);
    // const [isActive, setIsActive] = useState(false);
    const [activeIndex, setActiveIndex] = useState<number | null>(null);
    const [bean, setBean] = useState<any>({});
    const [test, setTest] = useState<number>(0);
    const [modaljson, setModaljson] = useState<any>({ name: 'Caracterizaciones', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalresource, setModalresource] = useState<any>({ name: 'TopicResource', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modal, setModal] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalConfirm, setModalConfirm] = useState<any>({ name: 'Confirmar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });
    const [user, setUser] = useState<any>({});

    const idBean = 'IDCourseTopic';




    useEffect(() => {
        setUser(single.getAccountID());
        getLista();
    }, [IDCourse, test])


    const getLista = () => {
       //console.log(IDCourse)
        let x = {
            IDCourse: IDCourse,

        }
        setLoading(true);
        service.getCourseTopicCatalogPorPropiedades(x, null).subscribe(
            (resp: any) => {

                if (resp.DataBeanProperties.ObjectValue) {
                    setLista(resp.DataBeanProperties.ObjectValue);

                }
               //console.log(resp);
                setLoading(false);
            }
        );
    }

    const toggleAccordion = (index: number) => {
        setActiveIndex(activeIndex === index ? null : index);
    };










    return (
        <>

            <div className="container-fluid">
                <div className="row">



                    {Array.isArray(lista) && lista.map((propiedadcurso: any, index: number) => {
                        return (
                            <>
                                <div className="col-md-12" key={index}>

                                    <div className="accordion-item" >
                                        <div className="div" key={propiedadcurso.DataBeanProperties.idBean}>
                                            <button
                                                className={`accordion ${activeIndex === index ? 'on' : ''}`}
                                                onClick={() => toggleAccordion(index)}
                                            >
                                                {propiedadcurso.DataBeanProperties.Name}
                                            </button>
                                            <div className={`panel ${activeIndex === index ? 'show' : ''}`}>
                                                {propiedadcurso.DataBeanProperties.Description}
                                            </div>
                                        </div>

                                    </div>


                                </div>
                            </>

                        )
                    })
                    }




                </div>

            </div>




        </>
    )
}