import { useEffect, useState } from "react";
import { _CompetitionType, _Entailment, _MacroProcess, _TechnicalSkillType, _TransversalSkillType } from "../../../../core/services/dataBean/EntityCatalog";

import { Singleton } from "../../../../core/services/Singleton";
import { toast } from "react-toastify";
import { ListMenu } from "../../../theme/Component/ListMenu";
import { BasicPage } from "../../../theme/Component/BasicPage";
import { BasicTable } from "../../../theme/Component/BasicTable";
import { Modal } from "../../../theme/Component/Modal";
import { ModalConfirm } from "../../../theme/Component/ModalConfirm";
import useDataBean from "../../../../core/services/dataBean/useDataBean";
import { TransversalSkillLevel } from "../TransversalSkillLevel/TransversalSkillLevel";
import { TechnicalSkillLevel } from "../TechnicalSkillLevel/TechnicalSkillLevel";
// import { Submacroprocces } from "./Submacroprocces";



export const TechnicalSkillType = () => {

    const TechnicalSkillType = useDataBean(_TechnicalSkillType);

    const single = Singleton.getInstance();

    const [lista, setLista] = useState<any>([]);
    const [modalsubprocces, setModalsubprocces] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modal, setModal] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-md' });
    const [modalC, setModalC] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });

    useEffect(() => {
        getLista();
    }, [])

    const getLista = () => {

        TechnicalSkillType.instance.getTechnicalSkillTypeCatalog(null).then(
            (resp: any) => {
                if (resp) {
                    setLista(resp)
                   //console.log(resp)
                }
            }
        )

    }

    const create = () => {
        TechnicalSkillType.setBean({

        });
        setModal({ ...modal, "name": "Niveles Jerárquicos", "view": true });
    }

    const deleteItem = () => {
        TechnicalSkillType.instance.deleteTechnicalSkillType(TechnicalSkillType.bean).then(
            (resp: any) => {
                getLista();
                setModalC({ ...modalC, "view": false });
            }
        )

    }

    const editaItem = () => {
       //console.log(TechnicalSkillType.bean);
        TechnicalSkillType.update().then(
            (resp: any) => {
                getLista();
                setModal({ ...modal, "view": false });
            }
        ).catch(err => {
            toast.error(err);
        })

    }

    const head = [
        { title: 'ID', property: 'IDTechnicalSkillType', visible: true },
        { title: 'Nombre', property: 'Name', visible: true },
        { title: 'Descripción', property: 'Description', visible: true },

    ]

    const iconButtonsItem = [
        { nombre: 'Agregar', visible: single.canCreate(), icono: 'ri-file-add-line', evento: create }
    ]
    const listaBotones = () => {
        const botones = [
            { titulo: 'Ver Niveles Jerárquicos', icono: 'ri-file-user-line', visible: true, evento: () => { setModalsubprocces({ ...modalsubprocces, "name": `${TechnicalSkillType.bean.Name} `, "view": true }) } },
            { titulo: 'Editar', icono: 'ri-edit-2-line', visible: single.canEdit(), evento: () => { setModal({ ...modal, "view": true }) } },
            { titulo: 'Eliminar', icono: 'ri-delete-bin-line', visible: single.canDelete(), evento: () => { setModalC({ ...modalC, "view": true }) } },

        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }


    return (
        <BasicPage titulo="  Tipo de Competencias Técnicas">
            <div className="row">
                <div className="col-md-12">

                    <BasicTable body={lista} head={head} rowSet={TechnicalSkillType.setBean} listButtons={listaBotones()} iconButtons={iconButtonsItem}></BasicTable>
                    <Modal modal={modal} updateModal={setModal} eventModal={editaItem}>
                        <div className="row">
                            <div className="col-md-12">
                                <label>Nombre</label>
                                <input type="text" name="Name" className="form-control" value={TechnicalSkillType.bean.Name} onChange={TechnicalSkillType.handleChange} />
                                {/* <SelectFunctionalID renderValue="" onChange={()=>{}}/> */}
                            </div>
                            <div className="col-md-12">
                                <label>Description</label>
                                <textarea name="Description" className="form-control" value={TechnicalSkillType.bean.Description} onChange={TechnicalSkillType.handleChange} />
                            </div>

                        </div>
                    </Modal>
                    <Modal modal={modalsubprocces} updateModal={setModalsubprocces} eventModal={editaItem}>
                        <TechnicalSkillLevel IDTechnicalSkillType={TechnicalSkillType.bean.IDTechnicalSkillType} />


                    </Modal>
                    <ModalConfirm modal={modalC} updateModal={setModalC} eventModal={deleteItem}>
                        <strong>Eliminar item</strong>
                    </ModalConfirm>
                </div>

            </div>
        </BasicPage>
    )
}