import { DataBean } from "../DataBean";


export class AppointmentManual extends DataBean {


    public async getAppointmentManualCatalog(id: number): Promise<any> {
        return this.getCustomService("PeopleArt", "java.util.List_getAppointmentManualCatalog_Number", [id]);
    }

    public async getAppointmentManual(idContrac: number): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_getAppointmentManual_Number", [idContrac]);
    }

    public async deleteAppointmentManual(bean): Promise<any> {
        return this.getCustomService("PeopleArt", "void_deleteAppointmentManual_com.peopleart.bean.appointment.AppointmentManual", [
            {
                "DataBeanProperties": bean,
                "DataBeanName": "com.peopleart.bean.appointment.AppointmentManual"
            }
        ]);
    }
}