/* eslint-disable array-callback-return */
import { useEffect, useRef, useState } from 'react';
import { Singleton } from '../../../core/services/Singleton';
import "./basictable.css";
import { env } from '../../../env';
import { HeadMultiSelectTable } from './basicMultiSelectTable/HeadMultiSelectTable';
import { TableMultiSelectCell } from './basicMultiSelectTable/TableMultiSelectCell';
import { PrintJson } from './PrintJson';


export const MASK_NUMBER = 1;
export const MASK_NUMBER_ROUND = 11;
export const MASK_MONEY = 2;
export const MASK_MONEY_ROUND = 22;
export const MASK_TEXT = 3;
export const MASK_TEXT_NOWRAP = 33;
export const MASK_MYEDITOR = 4;
export const MASK_DATE = 5;
export const MASK_DATE_DDMMMYYYY = 6;
export const MASK_BOOLEAN_ICON = 7;

interface Props {
    head: any,
    body: any;
    bodyResult: any;
    keyId: string;
    pageTop?: number;
    records?: { page, pages, records, regiPerPage };
    next?: any;
    back?: any;
    registersPerPage?: number;
    cssTable?: string;
    customRows?: number;
    _returnCellValue?: Function;
    updateValues?: (data: { bodyResult: any[] }) => void;
}
export const BasicMultiSelectTable = ({ head, body, keyId, bodyResult, pageTop, records, next, back, registersPerPage, cssTable, customRows, _returnCellValue, updateValues }: Props) => {

    const [rowData, setRowData] = useState<any>({});
    const [mbody, setMbody] = useState<any>([]);
    const [mbodyResult, setMbodyResult] = useState<any>([]);
    const [titulo, setTitulo] = useState<any>([]);
    const single = Singleton.getInstance()
    const [currentIndex, setCurrentIndex] = useState(0);

    //paginador
    const [page, setPage] = useState<number>(1);
    const [rows, setRows] = useState<number>(15);

    const printLog = env.PRINT_LOG ?? false;

    useEffect(() => {
        if (customRows) {
            setRows(customRows);
            paginar();
        }
    }, [customRows]);

    useEffect(() => {
        if (updateValues && !deepEqual(bodyResult, mbodyResult)) { // Compara solo si es diferente
            updateValues({ bodyResult: [...mbodyResult] });
        }
    }, [mbodyResult, updateValues, bodyResult]);

    // deepEqual es una función para comparación profunda
    const deepEqual = (a, b) => JSON.stringify(a) === JSON.stringify(b);

    useEffect(() => {
        if (registersPerPage > 0 && body.length > 0) {
            setRows(registersPerPage)
        }
    }, [registersPerPage]);

    useEffect(() => {
        if (page > 0) {
            paginar();
            let t = single.currentURI;
        }
    }, [page, rows]);

    useEffect(() => {
        if (body) {
            updateTitulo();
            paginar();
        }
    }, [body]);


    const updateTitulo = () => {
        if (head) {
            head.forEach((element: any) => {
                if (element.visible === undefined) {
                    element.visible = true;
                }
            });
            setTitulo(head);
        }
    }

    useEffect(() => {
        if (Array.isArray(bodyResult) && bodyResult.length > 0) {
            setMbodyResult(bodyResult);
        }
    }, [bodyResult]);

    const paginar = () => {
        try {
            let sup = (page <= 1 ? rows : page * rows);
            let inf = sup - rows;

            setMbody(body.slice(inf, sup));
        } catch (error) {
        }
    }

    const onClickCell = (value: any) => {
        if (_returnCellValue) {
            _returnCellValue(value);
        }
    }

    const removeItem = (id: number) => {
        const updatedMultiValue = mbodyResult.filter(
            (item: any) => item.DataBeanProperties[keyId] !== id
        );
        setMbodyResult(updatedMultiValue);
    };

    const onChangeCheckbox = (bean) => {
        const id = bean.DataBeanProperties[keyId];
        setMbodyResult((prev) => {
            const isSelected = prev.some((item) => item.DataBeanProperties[keyId] === id);
            if (isSelected) {
                return prev.filter(item => item.DataBeanProperties[keyId] !== id);
            }
            return [...prev, { DataBeanProperties: { ...bean.DataBeanProperties } }];
        });
    };


    return (
        <>
            <div className={cssTable ?? ""}>
                <table key={single.getRandomInt()} className="table table-striped-columns" id="tabla"  >
                    <HeadMultiSelectTable titulo={titulo} />
                    <tbody >
                        {Array.isArray(mbody) &&
                            mbody.map((bean: any, index: number) => {
                                const isSelected = mbodyResult.some(
                                    (item: any) => item.DataBeanProperties[keyId] === bean.DataBeanProperties[keyId]
                                );
                                return (
                                    <tr key={index}
                                        className={`cursor ${currentIndex === index ? "table-primary text-primary" : ""}`}
                                        onClick={() => {
                                            setCurrentIndex(index);
                                        }}
                                    >
                                        <td>
                                            <input
                                                type="checkbox"
                                                id={`checkbox-${bean.DataBeanProperties[keyId]}`}
                                                checked={isSelected}
                                                onChange={() => onChangeCheckbox(bean)}
                                            />
                                        </td>
                                        {titulo.map((item: any, index: number) => {
                                            return (
                                                <>
                                                    {item.visible &&
                                                        <td key={index} onClick={() => { }}>
                                                            <TableMultiSelectCell head={item} cell={bean.DataBeanProperties} returnValue={(e) => { onClickCell(e) }} />
                                                        </td>
                                                    }
                                                </>
                                            )
                                        })
                                        }
                                    </tr>
                                )
                            })
                        }
                        {Array.isArray(mbody) && mbody.length === 0 &&
                            <tr>
                                <td colSpan={head.length}>
                                    No hay datos para mostrar.
                                </td>
                            </tr>
                        }
                    </tbody>
                </table>
            </div>

            <div className="row">
                <div className="col-md-12">
                    <div className="paginador">
                        {records ?
                            <>
                                <div className="row">
                                    <div className="col">
                                        {(back && records.page > 1) &&
                                            <button type="button" className="btn bot-clas waves-effect waves-light shadow-none" onClick={back}>Anterior</button>
                                        }
                                        <span style={{ padding: '5px' }}>
                                            Pagina {pageTop} de {records.pages}

                                        </span>
                                        {(next && records.page < records.pages) &&
                                            <button type="button" className="btn bot-clas waves-effect waves-light shadow-none" onClick={next}>Siguiente</button>
                                        }
                                    </div>
                                </div>
                            </>
                            :
                            <>
                                {body?.length > 0 &&
                                    <div className="row">
                                        <div className="col">
                                            {page > 1 &&
                                                <button type="button" className="btn bot-clas waves-effect waves-light shadow-none" onClick={() => { setPage(page - 1) }}>Anterior</button>
                                            }
                                            <span style={{ padding: '5px' }}>
                                                Pagina {page} de {
                                                    Math.ceil(body.length / rows)
                                                }
                                            </span>

                                            {Math.floor(page * rows) < (body.length) &&
                                                <button type="button" className="btn bot-clas waves-effect waves-light shadow-none" onClick={() => { setPage(page + 1) }}>Siguiente</button>
                                            }
                                        </div>
                                        <div className="col">
                                            <div className='position-relative'>
                                                <div className='position-absolute end-0'>
                                                    {records && typeof records.records === 'number' ? (
                                                        <>
                                                            {`${records.records} Registros`}
                                                        </>
                                                    ) : (
                                                        <span style={{ padding: '5px' }}>
                                                            {body.length} Registros
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </>
                        }
                    </div>
                </div>
            </div>
        </>

    )
}
