import { Singleton } from "../../../../core/services/Singleton";

interface Props {
    head: any;
    cell: any;
    returnValue?: Function;
}

export const TableMultiSelectCell = ({ head, cell, returnValue }: Props) => {
    const single = Singleton.getInstance();

    return (
        <div className="row">
            <div className="col" >
                <div onClick={() => { returnValue(cell[head.property]) }} style={{ ...head.Style, wordWrap: 'break-word', whiteSpace: 'normal' }}
                    dangerouslySetInnerHTML={{ __html: single.formatFieldMask(cell[head.property], head.mask) }}>
                </div>
            </div>
        </div>
    );
};
