/* eslint-disable array-callback-return */
import { useEffect, useState } from 'react';
import { Singleton } from '../../../core/services/Singleton';
import { ExportXLSL } from './ExportXLSX';
import "./basictable.css";
import { TableCell } from './TableCell';

export const MASK_NUMBER = 1;
export const MASK_NUMBER_ROUND = 11;
export const MASK_MONEY = 2;
export const MASK_MONEY_ROUND = 22;
export const MASK_TEXT = 3;
export const MASK_TEXT_NOWRAP = 33;
export const MASK_MYEDITOR = 4;
export const MASK_DATE = 5;

interface Props {
    head: any,
    body: any;
    rowSet: any;
    headButtons?: any;
    listButtons?: any;
    iconButtons?: any;
    pageTop?: number;
    records?: { page, pages, records };
    next?: any;
    back?: any;
    callBack?: any;
}
export const BasicTable2 = ({ head, body, rowSet, headButtons, listButtons, iconButtons, pageTop, records, next, back, callBack }: Props) => {

    const [row, setRow] = useState(-1);
    const [rowMenu, setRowMenu] = useState(-1);
    const [rowData, setRowData] = useState<any>({});
    const [mbody, setMbody] = useState<any>([]);
    const [titulo, setTitulo] = useState<any>([]);
    const single = Singleton.getInstance()
    const [search, setSearch] = useState("");
    const [original, setOriginal] = useState<any>([]);
    const [filtro, setFiltro] = useState('');
    const [modalbusqueda, setModalBusqueda] = useState(false);
    const [modalColumna, setModalColumna] = useState(false);
    const [verMas, setVerMas] = useState(false);


    //paginador
    const [page, setPage] = useState<number>(0);
    const [rows, setRows] = useState<number>(15);



    useEffect(() => {
        setPage(1);

    }, [body])
    useEffect(() => {
       //console.log("pagina ", pageTop);
    }, [pageTop])


    useEffect(() => {
        paginar();
        let t = single.currentURI;
    }, [page])

    useEffect(() => {

        // setPage(1);

        updateTitulo();
        if (body) {
            paginar();
            // setMbody(body.slice(0,rows));
            // setMbody(Array.isArray(body) ? body : []);
            setOriginal(Array.isArray(body) ? body : []);
        }
        return () => {
            setOriginal([]);
        }

    }, [body])



    useEffect(() => {
        filter();
    }, [search])

    const paginar = () => {

        try {
            let sup = (page <= 1 ? rows : page * rows);
            let inf = sup - rows;

            setMbody(body.slice(inf, sup));
        } catch (error) {

        }
    }

    // const [lista,setLista]=useState<any>([]);
    // const idBean='bean_tabla';
    // const head = [
    //     {title:'ID',property:'IDAccountProject',visible:true,mask:},
    //     {title:'Nombre',property:'Name',visible:true},
    //     {title:'Descripcion',property:'Description',visible:true}        
    // ]

    // Ejemplo uso lista de botones 
    // const listaBotones =()=>{
    //     const botones = [
    //         {titulo:'Agregar',icono:'ri-file-add-line',visible:true,evento:agregarItem},
    //         {titulo:'Editar',icono:'ri-edit-2-line',visible:bean[idBean],evento:editarItem},
    //         {titulo:'Ver Items',icono:'ri-file-list-line',visible:bean[idBean],evento:() => { }},
    //         {titulo:'Ver Areas',icono:'ri-git-merge-line',visible:bean[idBean],evento:() => { }},

    //     ]
    //     return(
    //         <ListMenu listaBotones={botones}/>
    //     )
    // }

    // Ejemplo de uso botones en la cabecera de la la tabla 
    // const iconButtonsItem = [
    //     { nombre: 'Agregar',visible:single.canCreate(), icono: 'ri-file-add-line', evento: agregarItem }
    // ]

    // ejemplo de uso con dropdown
    // const actionsDropDown = [
    // {id:1,name:'Actualizar Estado',permiso:null,activo:true},
    // {id:2,name:'Ver Documentos',permiso:null,activo:false},  
    // ];
    // const buttons = () => {
    //     return (
    //         <div className="row">
    //             <div className="col-md-12">                    
    //                    <DropDown lista={actionsDropDown} eventClick={()=>{}}></DropDown>                   
    //             </div>
    //         </div>
    //     )
    // }

    useEffect(() => {
        tboriginal();
    }, [])

    const tboriginal = () => {
        setOriginal(original)
    }

    const updateTitulo = () => {
        if (head) {
            head.forEach((element: any) => {
                if (element.visible === undefined) {
                    element.visible = true;
                }
            });
            setTitulo(head);
        }
    }


    const updateTitle = (item: any) => {
        let t = titulo;
        t.forEach((element: any) => {
            if (element.property == item.property) {
                element.visible = element.visible === true ? false : true;
            }
        });
        setTitulo(t);
    }

    const filter = () => {
        let temporal: any = [];
        let tmp = '';
        original.forEach((item: any) => {
            tmp = item.DataBeanProperties[filtro];

            if (typeof tmp === 'number') {
                if (item.DataBeanProperties[filtro] == search) {
                    temporal.push(item);
                }
            }
            else if (typeof tmp === 'string') {
                if (item.DataBeanProperties[filtro].toLocaleLowerCase().includes(search.toLocaleLowerCase())) {
                    temporal.push(item);
                }
            }
            else {
                temporal.push(item);
            }
        });
        setMbody(temporal);
    }

    const listaColumnas = () => {
        return (
            <>
                <div className="alert alert-primary alert-dismissible alert-outline shadow fade show" role="alert">
                    <div className="row mt-4" >
                        <div className="col">
                            <div className="form-group">
                                {titulo.map((item: any, index: number) => {
                                    return (
                                        <div key={index} className="form-check">
                                            <input className="form-check-input" defaultChecked={item.visible} type="checkbox" value={item.title} id={item.property}
                                                onClick={() => { updateTitle(item) }}
                                            />
                                            <label style={{ marginTop: '-5px' }} htmlFor={item.property}>
                                                {item.title}
                                            </label>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                    <button type="button" className="btn-close" onClick={() => { setModalColumna(false) }}   ></button>
                </div>
            </>
        )
    }
    const countTitle = () => {
        let i = 1;
        titulo.forEach((element: any) => {
            if (element.visible) {
                i++;
            }
        });
        return i;
    }

    return (
        <>

            <div className="row">
                <div className="col">
                    <div className='bg_table'>
                        <div className="row nmt-4">
                            <div className="col-md-12">
                                {headButtons}
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12">
                                {/* botones auxiliares a la tabla */}

                                <div className="tablerelative position-relative">
                                    <div className='position-absolute top-0 end-0'>
                                        {iconButtons && iconButtons.map((btn: any, index: number) => {
                                            return (
                                                <>
                                                    {btn.visible &&
                                                        <button key={index} title={btn.nombre} type="button" className="btn btn-icon " onClick={btn.evento}>
                                                            <i className={btn.icono}></i>
                                                        </button>
                                                    }
                                                </>


                                            )
                                        })

                                        }
                                        <button title='Buscar' type="button" className="btn btn--primary btn-icon" onClick={() => { setModalBusqueda(true) }}>
                                            <i className=" ri-file-search-line icontable"></i>
                                        </button>

                                        <button disabled={!rowData.hasOwnProperty(head[0].property)} title='Ver' type="button" className="btn btn--primary btn-icon" onClick={() => { setVerMas(true) }}>
                                            <i className=" ri-eye-line icontable"></i>
                                        </button>

                                        <button title='Columnas' type="button" className="btn btn--primary btn-icon " onClick={() => { setModalColumna(true) }}>
                                            <i className="ri-layout-grid-line icontable icontable"></i>
                                        </button>
                                        <ExportXLSL title={head} data={body} onExport={(name: string) => {
                                            single.download("formato xlsx [" + name + "] con " + body.length + " registros");
                                        }} />


                                    </div>


                                    {modalbusqueda &&
                                        <div className="busquedatable " tabIndex={-1}>
                                            <div className="alert alert-primary alert-dismissible alert-outline shadow fade show" role="alert">
                                                <div className="row">
                                                    <div className="col">
                                                        <label>Columna</label>
                                                        <select className="form-select mb-3" onChange={(e) => { setFiltro(e.target.value); setMbody(original); setSearch('') }}>
                                                            <option value="0">...</option>
                                                            {titulo.map((item: any, index: number) => {
                                                                return (
                                                                    <option key={index} value={item.property}>{item.title}</option>
                                                                )
                                                            })}
                                                        </select>
                                                    </div>
                                                    <div className="col">
                                                        <label>Búsqueda</label>
                                                        <input type="text" onChange={(e) => setSearch(e.target.value)} className="form-control inputBuscar " placeholder="Filtrar datos" />
                                                    </div>
                                                </div>
                                                <button type="button" className="btn-close" onClick={() => { setModalBusqueda(false) }}   ></button>
                                            </div>
                                        </div>
                                    }

                                    {modalColumna &&
                                        <div className="busquedatable" tabIndex={-1}>
                                            {listaColumnas()}
                                        </div>
                                    }
                                    {verMas &&
                                        <div className="fixed-top">
                                            <div className="verFilatabla">
                                                <div className="alert alert-primary alert-dismissible alert-outline shadow fade show" role="alert">
                                                    <div className="row">
                                                        <div className="col">
                                                            <table className='table table-striped mt-4'>
                                                                <tbody className="text-justify">
                                                                    {head.map((item: any, index: number) =>
                                                                    (
                                                                        <tr key={index}>
                                                                            <td>{item.title}</td>
                                                                            <td>{
                                                                                <div className='h6' dangerouslySetInnerHTML={{ __html: single.formatField(rowData[item.property]) }}></div>
                                                                            }</td>

                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                    <button type="button" className="btn-close" onClick={() => { setVerMas(false) }}   ></button>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div className="table-responsive ">
                                    <table key={single.getRandomInt()} className="table  table-striped-columns  mb-0" id="tabla"  >
                                        {/* <table className="table  table-wrap table-striped  mb-0" id="tabla"  > */}
                                        <thead >
                                            <tr>
                                                {listButtons &&
                                                    <th></th>
                                                }
                                                {titulo.length > 0 &&
                                                    titulo.map((item: any, index: number) => {
                                                        return (
                                                            <>
                                                                {item.visible &&
                                                                    <th key={index} className='align-middle'>{item.title}</th>
                                                                }
                                                            </>
                                                        )
                                                    })
                                                }
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Array.isArray(mbody) &&
                                                mbody.map((bean: any, index: number) => {
                                                    return (
                                                        <tr key={index}

                                                            className={`cursor ${row === index ? "table-primary text-primary" : ""}`}
                                                            id={`row_${index}`}
                                                            onClick={() => {
                                                                setRow(index);
                                                                rowSet(bean.DataBeanProperties);
                                                                setRowData(bean.DataBeanProperties);
                                                               //console.log("FILA=> ", bean.DataBeanProperties);
                                                            }}
                                                        >
                                                            {listButtons &&
                                                                <td className='border-end-dashed' >
                                                                    <div className="position-relative">
                                                                        <i className={index === rowMenu ? 'ri-close-line m2' : 'ri-more-2-line m2'} onClick={() => { setRowMenu(index === rowMenu ? -1 : index) }} ></i>
                                                                        <div className={index === rowMenu ? 'position-absolute listMenu' : 'd-none'}>
                                                                            <div
                                                                                onClick={() => { setRowMenu(-1) }}
                                                                                onMouseLeave={() => { setRowMenu(-1) }}
                                                                            >
                                                                                {listButtons}
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </td>
                                                            }
                                                            {titulo.map((item: any, index: number) => {
                                                                return (
                                                                    <>
                                                                        {item.visible &&
                                                                            <td key={index} >
                                                                                {/* <div className='h6  mt-1 mb-0' dangerouslySetInnerHTML={{ __html: single.formatField(bean.DataBeanProperties[item.property]) }}></div> */}

                                                                                <TableCell head={item} cell={bean.DataBeanProperties} callBack={(e: any) => {
                                                                                    if (callBack) {
                                                                                        callBack(e);
                                                                                    }
                                                                                }} />

                                                                            </td>
                                                                        }
                                                                    </>
                                                                )
                                                            })
                                                            }
                                                        </tr>
                                                    )
                                                })
                                            }
                                            {Array.isArray(mbody) && mbody.length === 0 &&
                                                <tr>
                                                    <td colSpan={head.length}>
                                                        No hay datos para mostrar.
                                                    </td>
                                                </tr>
                                            }
                                        </tbody>

                                    </table>

                                </div>
                                <div className="paginador">



                                    {(records) ?
                                        <>
                                            <div className="row">
                                                <div className="col">

                                                    {(back && records.page + 1 > 1) &&
                                                        <button type="button" className="btn bot-clas waves-effect waves-light shadow-none" onClick={back}>Anterior</button>
                                                    }


                                                    <span style={{ padding: '5px' }}>
                                                        Pagina {pageTop + 1} de {records.records}
                                                    </span>


                                                    {(next && records.page + 1 < records.pages) &&
                                                        <button type="button" className="btn bot-clas waves-effect waves-light shadow-none" onClick={next}>Siguiente</button>
                                                    }
                                                </div>
                                                <div className="col">

                                                    <div className='position-relative'>
                                                        <div className='position-absolute  end-0'>


                                                            {/* {records.records}  Registros */}


                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </>
                                        :
                                        <>
                                            {body.length > 0 &&
                                                <div className="row">
                                                    <div className="col">

                                                        {page > 1 &&
                                                            <button type="button" className="btn bot-clas waves-effect waves-light shadow-none" onClick={() => { setPage(page - 1) }}>Anterior</button>
                                                        }

                                                        <span style={{ padding: '5px' }}>
                                                            Pagina {page} de {
                                                                Math.ceil(body.length / rows)
                                                            }
                                                        </span>

                                                        {Math.floor(page * rows) < (body.length) &&
                                                            <button type="button" className="btn bot-clas waves-effect waves-light shadow-none" onClick={() => { setPage(page + 1) }}>Siguiente</button>
                                                        }
                                                    </div>
                                                    <div className="col">

                                                        <div className='position-relative'>
                                                            <div className='position-absolute  end-0'>
                                                                {records ?
                                                                    <>
                                                                        {records} Registros
                                                                    </>
                                                                    :
                                                                    <span style={{ padding: '5px' }}>
                                                                        {body.length} Registros
                                                                    </span>
                                                                }

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </>
                                    }


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>

    )
}
