import { DataBean } from "../DataBean";

export class Project extends DataBean {

    public async getProjectByCode(code: string): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "com.quickdataerp.bean.accountant.Project_getProjectByCode_String", [code]);
    }

    public async getProjectCatalog(code: Number): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_getProjectCatalog_Number", [code]);
    }

}
