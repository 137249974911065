import { toast } from "react-toastify";
import { _TaxableBase, _TaxSchemeValue } from "../../../../../core/services/dataBean/EntityCatalog";
import useDataBean from "../../../../../core/services/dataBean/useDataBean";
import { BasicTable } from "../../../../theme/Component/BasicTable";
import { useEffect, useState } from "react";

interface Props {
    invoice: any;
    _onChange?: Function;
    _returnValue?: Function;
}

export const TaxableBase = ({ invoice, _onChange, _returnValue }: Props) => {

    const { setBean, bean, lista, setLista, instance } = useDataBean(_TaxableBase);

    useEffect(() => {
        if (invoice.IDUBLInvoice) {
            getLista()
        }
    }, [invoice])

    const getLista = () => {
        instance.getTaxableAmountForHoldingTax(invoice.IDUBLInvoice, invoice.IssueDate, null).then(
            (resp) => {
                setLista(resp)
            }
        ).catch(
            err => {
                toast.error(err)
            }
        )
    }

    useEffect(() => {
        if (_onChange) {
            _onChange(bean)
        }
    }, [bean])

    const head = [
        { title: 'Tipo Artículo', property: 'ArticleType', visible: true },
        { title: 'Base Impositiva', property: 'TaxableAmount', visible: true, },
        { title: 'Impuesto', property: 'TaxAmount', visible: true },
        { title: 'Total', property: 'TotalItemAmount', visible: true },
        { title: 'Base', property: 'Base', visible: true },
    ]

    return (
        <div className="row">
            <div className="col-12">
                <BasicTable body={lista} head={head} rowSet={setBean} _returnCellValue={(e) => { _returnValue(e) }} />
            </div>
        </div>
    )
}