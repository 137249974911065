import { useEffect, useState } from "react";
import { Singleton } from "../../../../../../core/services/Singleton";
import { StateReimbursementEditor } from "../StateReimbursementEditor";
import { BasicButton } from "../../../../../theme/Component/BasicButton";
import { BasicTable, MASK_DATE } from "../../../../../theme/Component/BasicTable";
import { toast } from "react-toastify";
import useDataBean from "../../../../../../core/services/dataBean/useDataBean";
import { _CashReimbursement } from "../../../../../../core/services/dataBean/EntityCatalog";
import { CashDeskAccountSelector } from "../../../CashDeskAccount/CashDeskAccountSelector";
import { YearSelector } from "../../../../../../core/shared/inputs/YearSelector";

interface Props {
    _onChange?: Function;
    idcashAccount?: number;
}
export const ManageCashReibursementSelector = ({ _onChange, idcashAccount }: Props) => {

    const single = Singleton.getInstance();
    const { setBean, lista, setLista, instance } = useDataBean(_CashReimbursement);

    const [state, setState] = useState<number>(0)
    const [year, setYear] = useState<number>(single.getCurrenYear())
    const [cashDesk, setCashDesk] = useState<number>(null);

    const getLista = () => {
        instance.getCashReimbursementCatalog(year, cashDesk, state).then(
            (resp) => {
                const filteredList = resp.filter(item => item.DataBeanProperties.IDCashDeskAccount === `${cashDesk}`);
                setLista(filteredList);
            }
        ).catch(
            err => {
                toast.error(err);
            }
        )
    }

    const setRowBean = (bean) => {
        setBean(bean);
        if (_onChange) {
            _onChange(bean);
        }
    }

    useEffect(() => {
        if (idcashAccount) {
            setCashDesk(idcashAccount)
        }
    }, [idcashAccount])

    const validateGetLista = () => {
        if (!year) {
            toast.error("año no valido");
        } else if (isNaN(state)) {
            toast.error("Estado no valido");
        } else if (!cashDesk) {
            toast.error("Caja no valida");
        } else {
            getLista()
        }
    }

    const head = [
        { title: 'ID', property: "IDCashReimbursement" },
        { title: 'Caja Dependiente', property: 'IDCashDeskAccount' },
        { title: 'Estado del Proceso', property: 'StateName' },
        { title: 'Estado del rembolso', property: 'ReimbursementStateName' },
        { title: 'No Externo de Referencia', property: 'SecuenceName' },
        { title: 'No CDP', property: 'CDPMnemonic' },
        { title: 'Fecha de CDP', property: 'CDPDate' },
        { title: 'Documento Contable', property: 'DocMnemonic' },
        { title: 'Fecha de Documento Contable', property: 'DocDate' },
        { title: 'Valor Autorizado', property: 'CDPValue' },
        { title: 'Valor Utilizado', property: 'TotalValue' },
        { title: '% de Uso', property: 'UsePercent' },
        { title: 'Creado por', property: 'EmployeeName' },
        { title: 'Fecha de Creación', property: 'Since', mask: MASK_DATE },
    ]

    return (
        <>
            {idcashAccount}
            <div className="row mb-3">
                <div className="col-2">
                    <YearSelector tittle="Año" onChange={(e) => { setYear(parseInt(e)) }} value={year} />
                </div>
                {!idcashAccount &&
                    <div className="col-5">
                        <CashDeskAccountSelector id={cashDesk} onchange={(e) => { setCashDesk(e) }} idAccount={single.getAccountID()} />
                    </div>
                }
                <div className="col-3">
                    <StateReimbursementEditor idSelector={state} onChange={(e) => setState(parseInt(e))} />
                </div>
                <div className="col-2 d-flex align-items-end">
                    <BasicButton icon='ri-search-line' eventClick={() => { validateGetLista() }} >Buscar</BasicButton>
                </div>
            </div>
            <BasicTable body={lista} head={head} rowSet={setRowBean} />
        </>
    )
}