import { DataBean } from "../DataBean";

export class MeasurementUnitCode extends DataBean {


    public async getMeasurementUnitCode(filter:any): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_getMeasurementUnitCode_String", [filter]);
    }



}