import { useEffect, useState } from "react";
import useDataBean from "../../../../../../../core/services/dataBean/useDataBean";
import { _BudgetSource } from "../../../../../../../core/services/dataBean/EntityCatalogVdos";
import { toast } from "react-toastify";
import { BasicButton } from "../../../../../../theme/Component/BasicButton";
import { ErrorList } from "../../../../../../theme/Component/ErrorList";
import { DocumentViewer } from "../../../../../../theme/Component/DocumentViewer";
import { PrintJson } from "../../../../../../theme/Component/PrintJson";
import { _single } from "../../../../../../../core/services/dataBean/EntityCatalog";

interface Props {
    formData: any;
    objectList: any;
    onChange: Function;
}

export const CreateCDPFactoryDocument = ({ objectList, formData, onChange }: Props) => {

    const { instance } = useDataBean(_BudgetSource);

    const [documents, setDocuments] = useState<any>([]);
    const [response, setResponse] = useState<any>({});
    const [msgList, setMsgList] = useState<any>([]);
    const [tap, setTap] = useState<number>(0);

    const crearDocumento = () => {
        instance.createCDP(formData.BudgetIDType, formData.Date, formData.Consecutive ?? null, objectList, formData.Description ?? "", null, _single.getAccountID(), true).then(
            (resp: any) => {
                setDocuments(resp.DocumentURL);
                setResponse(resp)
                setMsgList(resp.MsgList);
                setTap(1)
            }
        ).catch(
            err => {
                toast.error(err, { autoClose: false })
            }
        )
    }

    useEffect(() => {
        onChange({
            Response: response,
            Documents: documents,
            MSGList: msgList,
        })
    }, [documents, msgList])

    return (
        <div className="row">
            {tap === 0 &&
                <div className="col-md-12">
                    <BasicButton disable={!formData.Date || !formData.BudgetIDType} icon="ri-article-line" eventClick={crearDocumento}>Crear Certificado de Disponibilidad Presupuestal</BasicButton>
                </div>
            }
            {tap === 1 &&
                <div className="col">
                    {msgList.length > 0 &&
                        <ErrorList lista={msgList} />
                    }
                    {msgList.length === 0 && documents.length > 0 &&
                        <DocumentViewer key={documents} list={documents} />
                    }
                </div>
            }
        </div>
    )
}