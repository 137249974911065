import { env } from "../../env";
import api from "./api/api";


export class GarantiasService {
  private url: string = env.REACT_APP_ENDPOINT + env.REACT_APP_DEPLOY + env.REACT_APP_SERVICE;
  //FromParam
  public getFormParamCatalogPorPropiedad(name: string | null, value: number | null, number: null) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "java.util.List_getFormParamCatalogPorPropiedad_String_Object_Number",
      ArgumentList: [name, value, number],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public updateFormParam(bean: any) {
    const parametros = {
      ServiceName: "FinanceCore",
      MethodHash: "com.orange.bean.FormParam_updateFormParam_com.orange.bean.FormParam",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: 'com.orange.bean.FormParam'
        }
      ],
    };

    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  //
  public getSiteIDRateRender(nombrePropiedad: any | null, value: any | null, map: object | null) {
    const parametros = {
      ServiceName: "GuaranteeErp",
      MethodHash: "java.util.List_getSiteIDRateRender_Number_Number_java.util.Map",
      ArgumentList: [nombrePropiedad, value, map],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public getSiteIDRateCatalogPorPropiedadesLike(map: any | null, rows: any | null) {
    const parametros = {
      ServiceName: "GuaranteeErp",
      MethodHash: "java.util.List_getSiteIDRateCatalogPorPropiedadesLike_java.util.Map_Number",
      ArgumentList: [map, rows],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public updateSiteIDRate(bean: any) {
    const parametros = {
      ServiceName: "GuaranteeErp",
      MethodHash: "com.orange.guarantee.bean.SiteIDRate_updateSiteIDRate_com.orange.guarantee.bean.SiteIDRate",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: 'com.orange.guarantee.bean.SiteIDRate'
        }
      ],
    };

    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public completeCities(year: number, baseValue: number, idEmployee: number) {
    const parametros = {
      ServiceName: "GuaranteeErp",
      MethodHash: "Boolean_completeCities_Number_Double_Number",
      ArgumentList: [year, baseValue, idEmployee],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  //
  public getRealesCreditCatalogPorPropiedad(nombrePropiedad: string | null, value: any | null, maxRows: number | null) {
    const parametros = {
      ServiceName: "GuaranteeErp",
      MethodHash: "java.util.List_getRealesCreditCatalogPorPropiedad_String_Object_Number",
      ArgumentList: [nombrePropiedad, value, maxRows],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public getRealesCreditRender(page: number | null, rows: number | null, props: any) {
    const parametros = {
      ServiceName: "GuaranteeErp",
      MethodHash: "java.util.List_getRealesCreditRender_Number_Number_java.util.Map",
      ArgumentList: [page, rows, props],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public getCreditMonthCSV(page: number | null, rows: number | null, props: any) {
    const parametros = {
      ServiceName: "GuaranteeErp",
      MethodHash: "com.advantage.bean.filemanagement.FileMap_getCreditMonthCSV_Number_Number_java.util.Map",
      ArgumentList: [page, rows, props],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public getRealesCVCatalogPorPropiedades(props: any, rows: number | null) {
    const parametros = {
      ServiceName: "GuaranteeErp",
      MethodHash: "java.util.List_getRealesCVCatalogPorPropiedades_java.util.Map_Number",
      ArgumentList: [props, rows],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }


  public updateRealesCredit(bean: any) {
    const parametros = {
      ServiceName: "GuaranteeErp",
      MethodHash: "com.orange.guarantee.bean.RealesCredit_updateRealesCredit_com.orange.guarantee.bean.RealesCredit",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: 'com.orange.guarantee.bean.RealesCredit'
        }
      ],
    };

    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public updateRealesCV(bean: any) {
    const parametros = {
      ServiceName: "GuaranteeErp",
      MethodHash: "com.orange.guarantee.bean.RealesCV_updateRealesCV_com.orange.guarantee.bean.RealesCV",
      ArgumentList: [
        {
          DataBeanProperties: bean,
          DataBeanName: 'com.orange.guarantee.bean.RealesCV'
        }
      ],
    };

    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public deleteRealesCV(idRealesCV: number) {
    const parametros = {
      ServiceName: "GuaranteeErp",
      MethodHash: "boolean_deleteRealesCV_Number",
      ArgumentList: [idRealesCV],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

  public getCreditMonthRender(page: number | null, rows: number | null, props: any) {
    const parametros = {
      ServiceName: "GuaranteeErp",
      MethodHash: "java.util.List_getCreditMonthRender_Number_Number_java.util.Map",
      ArgumentList: [page, rows, props],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public getAllCreditMonthRender(Year: number | null, Month: number | null, state: number, typeGuarantee: number) {
    const parametros = {
      ServiceName: "GuaranteeErp",
      MethodHash: "java.util.List_getAllCreditMonthRender_Number_Number_Number_Number",
      ArgumentList: [Year, Month, state, typeGuarantee],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public runGuaranteeByMonth(year: number | null, month: number | null, props: any, idEmployee: number, typeGuarantee: number) {
    const parametros = {
      ServiceName: "GuaranteeErp",
      MethodHash: "java.util.List_runGuaranteeByMonth_Number_Number_java.util.Map_Number_Number",
      ArgumentList: [year, month, props, idEmployee, typeGuarantee],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }
  public eliminarValorizacionbyHash(hash: number, typeGuarantee) {
    const parametros = {
      ServiceName: "GuaranteeErp",
      MethodHash: "com.orange.guarantee.bean.CreditMonth_eliminarValorizacionbyHash_Number_Number",
      ArgumentList: [hash, typeGuarantee],
    };
    const data = JSON.stringify(parametros);
    return api.post(this.url, data);
  }

}
