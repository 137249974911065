import { NotFound } from './theme/NotFound';
import { useParams } from 'react-router-dom';
import { Entailment } from './Talentohumano/ParametrosTalentoHumano/Entailment/Entailment';
import { Appointment } from './Talentohumano/ParametrosTalentoHumano/Appointment/Appointment';
import { CompetitionType } from './Talentohumano/ParametrosTalentoHumano/CompetitionType/CompetitionType';
import { MacroProcess } from './Talentohumano/ParametrosTalentoHumano/MacroProcess/MacroProcess';
import { FunctionalsFamily } from './Talentohumano/ParametrosTalentoHumano/FunctionalsFamily/FunctionalsFamily';
import { AppointmentGroupManual } from './Talentohumano/ParametrosTalentoHumano/AppointmentGroupManual/AppointmentGroupManual';
import { CompetitionLevel } from './Talentohumano/ParametrosTalentoHumano/CompetitionLevel/CompetitionLevel';
import { MainTheme } from './Talentohumano/ParametrosTalentoHumano/MainTheme/MainTheme';
import { StrategicObjetive } from './Talentohumano/ParametrosTalentoHumano/StrategicObjetive/StrategicObjective';
import { FuncionalCompetence } from './Talentohumano/ParametrosTalentoHumano/FuncionalCompetence/FuncionalCompetence';
import { AppointmentManual } from './Talentohumano/ParametrosTalentoHumano/AppointmentManual/AppointmentManual';
import { GenericCompetition } from './Talentohumano/ParametrosTalentoHumano/GenericCompetition/GenericCompetition';
import { AbilityType } from './Talentohumano/ParametrosTalentoHumano/AbilityType/AbilityType';
import { TransversalSkillType } from './Talentohumano/ParametrosTalentoHumano/TransversalSkillType/TransversalSkillType';
import { TransversalSkill } from './Talentohumano/ParametrosTalentoHumano/TransversalSkill/TransversalSkill';
import { TechnicalSkillType } from './Talentohumano/ParametrosTalentoHumano/TechnicalSkillType/TechnicalSkillType';
import { AccountantConcept } from './erp/cellEditor/AccountantConcept/AccountantConcept';
import { EmployeePayments } from './Talentohumano/ParametrosTalentoHumano/EmployeePayments/EmployeePayments';
import { ViaticumPaymentsTypeType } from './Talentohumano/ParametrosTalentoHumano/ViaticumPaymentsType/ViaticumPaymentsType';
import { EmployeeBonus } from './Talentohumano/ParametrosTalentoHumano/EmployeeBonus/EmployeeBonus';
import { EmployeeDiscountType } from './Talentohumano/ParametrosTalentoHumano/EmployeeDiscountType/EmployeeDiscountType';
import { EmployeeDiscount } from './Talentohumano/GestionTH/EmployeeDiscount/EmployeeDiscount';
import { ImportedPayrollConcept } from './Talentohumano/ParametrosTalentoHumano/ImportedPayrollConcept/ImportedPayrollConcept';
import { ImportedPayrollBeneficiary } from './Talentohumano/ParametrosTalentoHumano/ImportedPayrollBeneficiary/ImportedPayrollBeneficiary';



export const RouterResources = (props: any) => {

    const { module, view }: any = useParams();

    const renderSwitch = (url: string) => {

        switch (url) {
            case 'tipos-asignacion':
                return (<Entailment key={url} />);
            case 'competition-type':
                return (<CompetitionType key={url} />);
            case 'macro-proceso':
                return (<MacroProcess key={url} />);
            case 'conceptospago':
                return (<EmployeePayments key={url} />);
            case 'viaticos-type':
                return (<ViaticumPaymentsTypeType key={url} />);
            case 'niveles-empleo':
                return <Appointment key={url} />;
            case 'functionals-family':
                return <FunctionalsFamily key={url} />
            case 'tipos-manual-funciones':
                return <AppointmentGroupManual key={url} />;
            case 'niveles-competencias':
                return <CompetitionLevel key={url} />;
            case 'tema-principal':
                return <MainTheme key={url} />;
            case 'egresos-laborales':
                return <EmployeeDiscount />;
            case 'tipos-aporte':
                return <EmployeeDiscountType />;
            case 'objetivo-estrategia':
                return <StrategicObjetive key={url} />;
                case 'beneficiarios-nominasimport':
                    return <ImportedPayrollBeneficiary key={url} />;
            case 'funcional-competence':
                return <FuncionalCompetence key={url} />
            case 'manuales-funciones':
                return <AppointmentManual key={url} />;
            case 'generic-competition':
                return <GenericCompetition key={url} />
            case 'tipos-habilidades':
                return <AbilityType key={url} />;
            case 'competencias-transversales':
                return <TransversalSkillType />;
            case 'primas-bonificaciones':
                return <EmployeeBonus />;
            case 'competencias':
                return <TransversalSkill />;
            case 'competencias-tecnicas':
                return <TechnicalSkillType />;

            case 'concepto-nominas-importar':
                return <ImportedPayrollConcept />;

            default: return <NotFound />;
        }
    }

    return (
        <div className='px-5'>
            {renderSwitch(view)}
        </div>
    );
}