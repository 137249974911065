import { useEffect, useState } from "react";
import { AdminService } from "../../../core/services/AdminService";
import { Singleton } from "../../../core/services/Singleton";
import { Modal } from "../../theme/Component/Modal";
import { BasicTable } from "../../theme/Component/BasicTable";
import { ModalConfirm } from "../../theme/Component/ModalConfirm";
import { ListMenu } from "../../theme/Component/ListMenu";
import { SelectorMenuTypeConstants } from "../../bpm/config/Componentes/SelectorMenuTypeConstants";
import { SelectorAplication } from "../../bpm/config/Componentes/SelectorAplication";
import { SelectorAplicationMenu } from "../../bpm/config/Componentes/SelectorAplicationMenu";
import { CardTaps } from "../../theme/Component/CardTaps";
import { MultiSelect } from "../../theme/Component/MultiSelect";
import { BasicButton } from "../../theme/Component/BasicButton";
import { toast } from "react-toastify";
import useModal, { MODAL_LG, MODAL_md, MODAL_SM } from "../../theme/Component/hooks/useModal";
import { DropDown } from "../../theme/Component/DropDown";
import { ImportXLSX } from "../../theme/Component/ImportXLSX";
import { _single } from "../../../core/services/dataBean/EntityCatalog";


export const ApplicationID = () => {

    const single = Singleton.getInstance()
    const service: AdminService = new AdminService();
    const [lista, setLista] = useState([]);
    const [listaRemote, setListaRemote] = useState([]);
    const [listImport, setListImport] = useState([]);
    const [beanID, setBeanID] = useState<any>({});
    const [idType, setIDtype] = useState<number | null>(null);
    const [idApplication, setIDApplication] = useState(0);
    const [idApp, setIDApp] = useState(0);
    const [idTiporemote, setIDType] = useState(0);
    const [modalConfirm, setModalConfirm] = useState<any>({ name: 'Confirmar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });
    const [modal1, setModal1] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [valueMenu, setValueMenu] = useState<number>(0);
    const [listTypes, setListTypes] = useState<any[]>([]);
    const [types, setTypes] = useState<any[]>([]);
    const modalImport = useModal("Importar", MODAL_md);

    const modalProcess = useModal("Procesos", MODAL_md);

    useEffect(() => {
        getProcessTypeConstants();
        getTreeApplicationId(idApp);
        getRemoteAction(idApp);
    }, [idApp])

    const getTreeApplicationId = (id: number | null) => {

        setIDtype(id);
        Singleton.getInstance().spinner(true);
        service.getTreeApplicationId(id).subscribe(
            (resp: any) => {
                //console.log("Application ID ", resp);
                if (resp.DataBeanProperties.ObjectValue) {
                    setLista(resp.DataBeanProperties.ObjectValue);
                }
                Singleton.getInstance().spinner(false);
            }
        );
    }
    const getRemoteAction = (id: number | null) => {

        setIDtype(id);
        Singleton.getInstance().spinner(true);
        service.getRemoteAbstractActionByApplicationType(id, null).subscribe(
            (resp: any) => {
                //console.log("Application ID ", resp);
                if (resp.DataBeanProperties.ObjectValue) {
                    setListaRemote(resp.DataBeanProperties.ObjectValue);
                }
                Singleton.getInstance().spinner(false);
            }
        );
    }

    const updateApplicationID = () => {
        //console.log(beanID)
        setModal1({ ...modal1, ['view']: false });

        if (indexTap == 0) {
            Singleton.getInstance().spinner(true);
            service.updateApplicationID(beanID).subscribe(
                (resp: any) => {
                    Singleton.getInstance().spinner(false);
                    if (resp.DataBeanProperties.ObjectValue) {
                        getTreeApplicationId(idType);

                    }
                }
            );
        } else {
            service.updateRemoteAbstractAction(beanID).subscribe(
                (resp: any) => {
                    Singleton.getInstance().spinner(false);
                    if (resp.DataBeanProperties.ObjectValue) {
                        getRemoteAction(idType);

                    }
                }
            );
        }

    }

    const eliminarItem = () => {

        Singleton.getInstance().spinner(true);
        service.deleteApplicationID(beanID.IDLn).subscribe(
            (resp: any) => {
                Singleton.getInstance().spinner(false);
                if (resp) {
                    getTreeApplicationId(idType);
                }
            }
        );
        setModalConfirm({ ...modalConfirm, ['view']: false });

    }

    const setNuevoMenu = () => {
        let bean = {
            IDApplicationType: idType,
            IDLn_1: 0,
            Name: '',
            Description: '',
            URL: '',
            Code: 1,
            LnLevel: 0,
            State: 0,
            Type: 3,
            IDApplicationID: idApplication,
        }
        setBeanID(bean);
        setModal1({ ...modal1, 'view': true, name: 'Agregar' });
    }

    const head = [
        { title: 'ID', property: 'IDApplicationType' },
        { title: 'Código', property: 'Code' },
        { title: 'Nombre', property: 'Name' },
        { title: 'Descripción', property: 'Description' },
        { title: 'URL', property: 'URL' },
        { title: 'Estado', property: 'StateName', visible: true },
        { title: 'Tipo Acceso', property: 'AccessTypeName', visible: true },
    ]
    const headRemote = [
        { title: 'ID', property: 'IDRemoteAbstractAction' },
        { title: 'Nombre', property: 'Name' },
        { title: 'ClassForName', property: 'ClassForName' },
        { title: 'Estado', property: 'StateName', visible: true },
        { title: 'Tipo Acceso', property: 'AccessTypeName', visible: true },
        // { title: 'Descripción', property: 'Description' },
        // { title: 'URL', property: 'URL' }
    ]

    const rowSet = (b: any) => {
        setBeanID({ ...b, "IDApplicationID": idApplication, });

    }

    const listaBotones = () => {
        const botones = [
            { titulo: 'Editar', icono: 'ri-file-list-line', visible: true, evento: () => { setModal1({ ...modal1, "view": true, name: 'Editar' }) } },            
            { titulo: 'Procesos', icono: 'ri-file-copy-2-line', visible: true, evento: () => { modalProcess.open() } },
            { titulo: 'Eliminar', icono: 'ri-file-user-line', visible: true && indexTap === 0, evento: () => { setModalConfirm({ ...modalConfirm, "view": true }) } },
        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }
    const getProcessTypeConstants = () => {
        service.getProcessTypeConstants().subscribe((resp: any) => {
            //console.log("Application", resp);
            if (resp.DataBeanProperties.ObjectValue) {
                let temp = resp.DataBeanProperties.ObjectValue;
                let lista = [];
                temp.forEach((element: any) => {
                    lista.push(
                        {
                            id: element.DataBeanProperties.Value,
                            Name: element.DataBeanProperties.Property
                        }
                    )
                });
                setTypes(lista);
            }
        });
    };

    const taps = [
        { id: 0, name: 'Menu Aplicación' },
        { id: 1, name: 'Menu Acción' }
    ]
    const [indexTap, setIndexTap] = useState(0);

    const iconButtonsItem = [
        { nombre: 'Agregar', visible: (true && indexTap === 0), icono: 'ri-file-add-line', evento: setNuevoMenu }
    ]


    const addProcessTypeToApplicationID = () => {

        if (indexTap === 0) {

            single.spinner(true);
            service.addProcessTypeToApplicationID(beanID.IDLn, listTypes).subscribe(
                (resp: any) => {
                    if (resp.DataBeanProperties.ErrorMessage) {
                        toast.error(resp.DataBeanProperties.ErrorMessage);
                    }
                    else if (resp.DataBeanProperties.ObjectValue) {
                        toast.success("Procesos agregados");
                    }
                    single.spinner(false);
                }
            )
        } else {
            single.spinner(true);
            service.addProcessTypeToRemoteAbstractAction(beanID.IDRemoteAbstractAction, listTypes).subscribe(
                (resp: any) => {

                    if (resp.DataBeanProperties.ErrorMessage) {
                        toast.error(resp.DataBeanProperties.ErrorMessage);
                    }
                    else if (resp.DataBeanProperties.ObjectValue) {
                        toast.success("Procesos agregados");
                    }
                    single.spinner(false);
                }
            )
        }

    }
    const actionsDropDown = [
        { id: 1, name: 'Importar menu', permiso: null, activo: true },

    ];
    const buttons = () => {
        return (
            <div className="row">
                <div className="col-md-12">
                    <DropDown lista={actionsDropDown} eventClick={eventHeader}></DropDown>
                </div>
            </div>
        )
    }
    const eventHeader = (e) => {


        if (e.id === 1) {
            modalImport.open();
        }
    }
    const importMenu = () => {
        //console.log(listImport);
        let existe = [];
        lista.forEach(element => {
            existe.push(element.DataBeanProperties.URL.trim());
        });
        //console.log(existe);
        listImport.forEach((row: any, index: number) => {
            if (index > 0)
                if (!existe.includes(row[4])) {
                    //console.log("Importar => ",row[4]);
                    let bean = {
                        IDLn_1: 0,
                        LnLevel: 0,
                        IDApplicationID: idApplication,
                        IDApplicationType: idType,
                        Name: row[2],
                        Description: row[6],
                        URL: row[4],
                        Code: row[1],
                        State: 0,
                        Type: 3,
                    }
                    service.updateApplicationID(bean).subscribe(
                        (resp: any) => {
                            if (resp.DataBeanProperties.ObjectValue)
                                toast.success("Menú => " + resp.DataBeanProperties.ObjectValue.DataBeanProperties.Name);
                        }
                    );
                }
        })
        modalImport.close();
        _single.spinner(true);
        setTimeout(() => {
            getTreeApplicationId(idType);

        }, 4000);
    }


    return (
        <>
            <div className="row">
                <div className="col-md-4">
                    <SelectorMenuTypeConstants idSelector={valueMenu} onChange={(e: any) => { setValueMenu(parseInt(e)) }}></SelectorMenuTypeConstants>
                </div>
                <div className="col-md-4">
                    <SelectorAplication idSelector={idApplication} onChange={(e: any) => { setIDApplication(parseInt(e)) }}></SelectorAplication>
                </div>

                <div className="col-md-4">
                    <SelectorAplicationMenu _MenuType={valueMenu} _idAplication={idApplication} idSelector={idApp} onChange={(e) => { setIDApp(parseInt(e)); }}></SelectorAplicationMenu>
                </div>
            </div>
            <div className="row">

                <div className="col-md-12 mt-2">
                    <CardTaps taps={taps} index={indexTap} updateIndex={setIndexTap}>
                        {indexTap == 0 ?
                            <BasicTable headButtons={buttons()} listButtons={listaBotones()} iconButtons={iconButtonsItem} head={head} body={lista} rowSet={rowSet}></BasicTable>
                            :
                            <BasicTable listButtons={listaBotones()} head={headRemote} body={listaRemote} rowSet={rowSet}></BasicTable>
                        }
                    </CardTaps>
                </div>
            </div>
            <Modal modal={modal1} updateModal={setModal1} eventModal={updateApplicationID}>
                <>
                    {indexTap == 1 ?
                        <div className="row">
                            <div className="col-md-12">
                                <label>Tipo Acceso</label>
                                <select className="form-select" value={beanID.Type ?? undefined} onChange={(e) => { setBeanID({ ...beanID, 'Type': e.target.value }) }}>
                                    <option value={2}>TODOS LOS USUARIOS</option>
                                    <option value={3}>EN ROL DE USUARIO</option>
                                </select>
                            </div>
                        </div>
                        :
                        <div className="row">
                            {valueMenu != 1 &&
                                <>
                                    <div className="col-md-6">
                                        <label className="form-label">Código</label>
                                        <input type="text" className="form-control" value={beanID.Code} onChange={(e) => { setBeanID({ ...beanID, 'Code': e.target.value }) }} />
                                    </div>
                                    <div className="col-md-6">
                                        <label className="form-label">Nombre</label>
                                        <input type="text" className="form-control" value={beanID.Name} onChange={(e) => { setBeanID({ ...beanID, 'Name': e.target.value }) }} />
                                    </div>

                                    <div className="col-md-6">
                                        <label className="form-label">URL</label>
                                        <input type="text" className="form-control" value={beanID.URL} onChange={(e) => { setBeanID({ ...beanID, 'URL': e.target.value }) }} />
                                    </div>
                                    <div className="col-md-12">
                                        <label className="form-label">Descripción</label>
                                        <textarea className="form-control" value={beanID.Description} onChange={(e) => { setBeanID({ ...beanID, 'Description': e.target.value }) }}></textarea>
                                    </div>
                                </>
                            }


                            <div className="col-md-6">
                                <label>Estado</label>
                                <select className="form-select" value={beanID.State ?? undefined} onChange={(e) => { setBeanID({ ...beanID, 'State': e.target.value }) }}>
                                    <option value={0}>ACTIVO</option>
                                    <option value={1}>INACTIVO</option>
                                </select>
                            </div>
                            <div className="col-md-6">
                                <label>Tipo Acceso</label>
                                <select className="form-select" value={beanID.Type ?? undefined} onChange={(e) => { setBeanID({ ...beanID, 'Type': e.target.value }) }}>
                                    <option value={2}>TODOS LOS USUARIOS</option>
                                    <option value={3}>EN ROL DE USUARIO</option>
                                </select>
                            </div>
                        </div>
                    }
                </>
            </Modal>
            <Modal modal={modalProcess.modal} updateModal={modalProcess.setModal}>
                <div className="row">
                    <div className="col-md-12">
                        <MultiSelect label="Proceso" lista={types} getLista={setListTypes} />
                        <BasicButton icon="" eventClick={addProcessTypeToApplicationID}>Actualizar procesos</BasicButton>
                    </div>
                </div>
            </Modal>
            <ModalConfirm modal={modalConfirm} updateModal={setModalConfirm} eventModal={eliminarItem}>
                <strong>Eliminar Menú {beanID.Name}</strong>
            </ModalConfirm>
            <Modal modal={modalImport.modal} updateModal={modalImport.setModal} eventModal={importMenu} onSubmitLabel="Importar">
                <div className="row">
                    <div className="col">
                        <ImportXLSX lodaData={setListImport} />
                    </div>
                </div>
            </Modal>

        </>
    );
}