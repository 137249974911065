import { useEffect, useState } from "react";
import { Singleton } from "../../../core/services/Singleton";
import { ContractService } from "../../../core/services/ContractService";
import { BasicTable } from "../../theme/Component/BasicTable";
import { DropDown } from "../../theme/Component/DropDown";
import { Modal } from "../../theme/Component/Modal";
import { Alert } from "../../theme/Component/Alert";
import { FindSupplier } from "../FindSupplier";
import { BasicButton } from "../../theme/Component/BasicButton";
import { ListMenu } from "../../theme/Component/ListMenu";
import { useDashboardStore } from "../../pages/hooks/useDashboardStore";

interface Props {
    idAdquisitionItem: number;
    idHiringProcess: number;
}
export const RequestoQuote = ({ idAdquisitionItem, idHiringProcess }: Props) => {

    const single = Singleton.getInstance()
    const { setLoading } = useDashboardStore();
    const service = new ContractService();
    const [lista, setLista] = useState<any>([]);
    const [bean, setBean] = useState<any>({});
    const [supp, setSupp] = useState<any>({});
    const [action, setAction] = useState<any>({});
    const [message, setMessage] = useState<any>('');
    const [modal, setModal] = useState<any>({ name: 'Cotizaciones', tabIndex: -1, view: false, btnClose: true, btnSubmit: false, format: 'modal-lg' });

    useEffect(() => {
        getRequestQuoteRenderByIDHiringProcess();
    }, [idHiringProcess])



    useEffect(() => {
        setMessage('');
    }, [bean])


    const cancelRequestQuote = () => {

        setLoading(true);

        service.cancelRequestQuote(bean.IDRequestQuote, single.getAccountID()).subscribe(
            (resp: any) => {
                setLoading(false);
               //console.log(resp);
                getRequestQuoteRenderByIDHiringProcess();
                setMessage('Invitación Anulada');
            }
        )


    }

    const agregarEmpresa = () => {

        setLoading(true);
        let b = {
            IDSupplier: bean.IDSupplier,
            IDEmployee: single.getAccountID(),
            State: 1,
            IDHiringProcess: idHiringProcess
        }
        service.addSupplierToRequestQuote(idHiringProcess, supp.IDSupplier, single.getAccountID()).subscribe(
            (resp: any) => {
                setLoading(false);
               //console.log(resp);
                getRequestQuoteRenderByIDHiringProcess();
                setMessage('Invitación Enviada');
            }
        )


    }

    const getRequestQuoteRenderByIDHiringProcess = () => {
        setLoading(true);
        const map = {
            State: 4
        }
        service.getRequestQuoteRenderByIDHiringProcess(idHiringProcess).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue) {
                    setLista(resp.DataBeanProperties.ObjectValue);
                }
                setLoading(false);
            }
        )
    }


    const head = [
        { title: 'ID', property: 'IDSupplier', visible: false },
        // {title:'Identificación',property:'nit',visible:true},
        { title: 'Proveedor', property: 'NameSupplier', visible: true },
        { title: 'Correo', property: 'eMail', visible: true },
        { title: 'Solicitado Por', property: 'NameEmployee', visible: true },
        { title: 'Fecha Solicitud', property: 'Since', visible: true },
        { title: 'Estado', property: 'StateName', visible: true },
    ]

    const actionsDropDown = [

        { id: 1, name: 'Ver Cotizaciones', permiso: null, activo: true },

    ];
    const buttons = () => {
        return (
            <div className="row">
                <div className="col-md-12">
                    <DropDown lista={actionsDropDown} eventClick={setAction}></DropDown>
                </div>
            </div>
        )
    }
    const iconButtonsItem = [
        { nombre: 'Agregar', visible: single.canCreate(), icono: 'ri-file-add-line', evento: () => { setModal({ ...modal, "view": true }); } }
    ]
    const listaBotones = () => {
        const botones = [
            { titulo: 'Cancelar Invitación', icono: 'ri-file-add-line', visible: true, evento: cancelRequestQuote },
        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }


    return (
        <>

            {message != '' &&
                <Alert clase="primary" >
                    {message}
                </Alert>
            }

            <BasicTable headButtons={buttons()} iconButtons={iconButtonsItem} listButtons={listaBotones()} body={lista} head={head} rowSet={setBean} ></BasicTable>
            <Modal modal={modal} updateModal={setModal} eventModal={agregarEmpresa}>

                <FindSupplier idSupplier={0} handleEvent={(e: any) => { setSupp(e) }} />
                <BasicButton clase="success" disable={supp.IDSupplier === undefined} icon=" ri-check-double-fill" eventClick={agregarEmpresa}>Invitar</BasicButton>
            </Modal>
        </>
    )
}