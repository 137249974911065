import { useEffect, useState } from "react";

import useDataBean from "../../../core/services/dataBean/useDataBean";
import { CashReimbursementServices } from "../cellEditor/CashDesk/CashReimbursement/CashReimbursement-service";
import { toast } from "react-toastify";
import { Singleton } from "../../../core/services/Singleton";
import { ListMenu } from "../../theme/Component/ListMenu";
import { BasicTable, MASK_DATE, MASK_MONEY, MASK_NUMBER } from "../../theme/Component/BasicTable";
import { BasicPage } from "../../theme/Component/BasicPage";
import { Modal } from "../../theme/Component/Modal";

import { ModalConfirm } from "../../theme/Component/ModalConfirm";
import { _AccountantConcept, _FiscalResponsability, _FiscalResponsabilityType } from "../../../core/services/dataBean/EntityCatalog";

import { _AdquisitionBeneficiary, _AdquisitionProject, _ArticleClassType, _MeasurementUnitCode, _ProjectBeneficiary } from "../../../core/services/dataBean/EntityCatalogVdos";
import { SelectAdquisitionArticleConstants } from "../../contract/select/SelectAdquisitionArticleConstants";
import useModal, { MODAL_md, MODAL_SM } from "../../theme/Component/hooks/useModal";
import { InputDate } from "../../theme/Input/InputDate";
import { AccountEditor } from "../../editor/AccountEditor";
import { SelectFunctionalID } from "../../admin/configuracion/functionalID/SelectFunctionalID";


interface Props {
    IDProject: number;
}

export const AdquisitionBeneficiary = ({ IDProject }: Props) => {

    const ProjectBeneficiary = useDataBean(_ProjectBeneficiary);

    const single = Singleton.getInstance();
    const [lista, setLista] = useState<any>([]);
    const [beneficiario, setbeneficiario] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modal, setModal] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalC, setModalC] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const modalConfirm = useModal("Orden", MODAL_SM);
    const modalEditar = useModal("Orden", MODAL_md);


    useEffect(() => {
        getLista()

    }, [IDProject])


    const getLista = () => {

        ProjectBeneficiary.instance.getProjectBeneficiaryCatalogPorPropiedadesRender({ IDProject: IDProject, State: 1 }, IDProject).then(
            (resp: any) => {
                if (resp) {
                    setLista(resp)
                   //console.log(resp)

                }

            }
        )

    }



    const create = () => {
        ProjectBeneficiary.setBean({

            State: 1,
            IDProject: IDProject
        });

        modalEditar.setModal({ ...modalEditar.modal, "view": true });
    }


    // const deleteItem = () => {
    //     AdquisitionProject.deleteItemVoid(AdquisitionProject.bean.IDMeasurementUnitCode).then(
    //         (resp: any) => {
    //             getLista();
    //             setModalC({ ...modalC, "view": false });
    //         }
    //     )

    // }


    const editaItem = () => {
       //console.log(ProjectBeneficiary.bean);
        ProjectBeneficiary.update().then(
            (resp: any) => {
                getLista();
                modalEditar.setModal({ ...modalEditar.modal, "view": false });
            }
        ).catch(err => {
            toast.error(err);
        })

    }
    const deleteItem = () => {
        ProjectBeneficiary.bean.State = 2;
       //console.log(ProjectBeneficiary.bean);
        editaItem();
        modalConfirm.close();
    }


    // const editaItem = () => {
    //     MeasurementUnitCode.instance.updateMeasurementUnitCode(MeasurementUnitCode.bean.IDMeasurementUnitCode).then(
    //         (resp: any) => {
    //             getLista();
    //             setModal({ ...modal, "view": false });
    //         }
    //     ).catch(err => {
    //         toast.error(err);
    //     })

    // }

    const head = [
        { title: 'ID', property: 'IDProjectBeneficiary', visible: false, mask: MASK_NUMBER },
        { title: 'Tipo', property: 'Type', visible: false },
        { title: 'Tercero', property: 'AccountName', visible: true },
        { title: 'Área Funcional', property: 'FunctionalIDName', visible: true },
        { title: 'Valor', property: 'TotalValue', visible: true, mask: MASK_MONEY },
        { title: 'Descripción', property: 'Description', visible: true },
        { title: 'Fecha', property: 'Since', visible: true }



    ]

    const iconButtonsItem = [
        { nombre: 'Agregar', visible: single.canCreate(), icono: 'ri-file-add-line', evento: create }
    ]
    const listaBotones = () => {
        const botones = [

            { titulo: 'Editar', icono: 'ri-edit-2-line', visible: single.canEdit(), evento: () => { modalEditar.setModal({ ...modalEditar.modal, "view": true }) } },
            { titulo: 'Eliminar', icono: 'ri-delete-bin-line', visible: single.canDelete(), evento: () => { setModalC({ ...modalC, "view": true }) } },


        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }



    return (
        <BasicPage titulo="Beneficiarios">
            <div className="row">

                <div className="col-md-12">

                    <BasicTable body={lista} head={head} rowSet={ProjectBeneficiary.setBean} listButtons={listaBotones()} iconButtons={iconButtonsItem}></BasicTable>
                    <Modal modal={modalEditar.modal} updateModal={modalEditar.setModal} eventModal={editaItem}>
                        <>
                            <div className="row">

                                <div className="col-md-6">
                                    <label>Tipo</label>

                                    <select className="form-control" value={ProjectBeneficiary.bean.Type} onChange={(e) => { ProjectBeneficiary.setBean({ ...ProjectBeneficiary.bean, 'Type': e.target.value }) }}>
                                        <option value={0}>...</option>
                                        <option value={10}>Tercero</option>
                                        <option value={11}>Área Funcional</option>
                                    </select>
                                </div>
                                <div className="col-md-6">
                                    <AccountEditor label="Tercero" idAccount={ProjectBeneficiary.bean.IDAccount} onChange={(e) => { ProjectBeneficiary.setBean({ ...ProjectBeneficiary.bean, "IDAccount": e.IDAccount }) }}></AccountEditor>
                                </div>

                                <div className="col-md-6">
                                    <SelectFunctionalID functionalIDLn={ProjectBeneficiary.bean.IDLnFunctionalID} onChange={(e: any) => { ProjectBeneficiary.setBean({ ...ProjectBeneficiary.bean, "IDLnFunctionalID": e.IDLn }) }} />
                                </div>

                            </div>
                            <div className="col-md-12">
                                <label>Valor</label>
                                <input type="text" name="TotalValue" className="form-control" value={ProjectBeneficiary.bean.TotalValue} onChange={ProjectBeneficiary.handleChange} />

                            </div>
                            <div className="col-md-12">
                                <label>Descripción</label>
                                <textarea name="Description" className="form-control" value={ProjectBeneficiary.bean.Description} onChange={ProjectBeneficiary.handleChange} />
                            </div>


                        </>
                    </Modal>

                    <ModalConfirm modal={modalC} updateModal={setModalC} eventModal={deleteItem}>
                        <strong>Eliminar item</strong>
                    </ModalConfirm>
                </div>

            </div>
        </BasicPage>
    )
}