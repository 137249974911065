import { DataBean } from "../DataBean";

export class FixedAssetLine extends DataBean {

    public async getFixedAssetLineCatalog(idLine: number): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_getFixedAssetLineCatalog_Number", [idLine]);
    }

    public async getBrandListForFixedAssetLine(idLine: number): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_getBrandListForFixedAssetLine_Number", [idLine]);
    }

}
