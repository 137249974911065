import { useState, useEffect } from 'react';
import { Singleton } from "../../../../../core/services/Singleton";
import { BasicPage } from "../../../../theme/Component/BasicPage";
import { BasicTable } from "../../../../theme/Component/BasicTable";
import { ModalConfirm } from "../../../../theme/Component/ModalConfirm";
import { Modal } from "../../../../theme/Component/Modal";
import { BpmService } from "../../../../../core/services/BpmService";
import { ListMenu } from "../../../../theme/Component/ListMenu";
import { VarResponseJson } from './VarResponseJson.component';
import { ValidateInvJson } from './ValidateInvJson.component';
import { JsonPrototype } from './JsonPrototype.component';
import { SelectURLRequestType } from '../../Componentes/SelectURLRequestType';
import { JsonPropertie } from './JsonPropertie.component';
import { InvokeJsonServiceInPeriod } from './InvokeJsonServiceInPeriod.component';
import { SelectorJsonClass } from '../../Componentes/SelectorJsonClass';
import { useDashboardStore } from '../../../../pages/hooks/useDashboardStore';

export const JsonService = () => {

    const single = Singleton.getInstance()
    const { setLoading } = useDashboardStore();
    const service = new BpmService();
    const [lista, setLista] = useState([]);
    const [bean, setBean] = useState<any>({});
    const [idModule, setIDModule] = useState(0);
    const [user, setUser] = useState<any>({});
    const [modal, setModal] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalJSON, setModalJSON] = useState<any>({ name: 'Prototipo JSON', tabIndex: -1, view: false, btnClose: true, btnSubmit: false, format: 'modal-lg' });
    const [modalVarResp, setModalVarResp] = useState<any>({ name: 'Editar Variables de Respuesta', tabIndex: -1, view: false, btnClose: true, btnSubmit: false, format: 'modal-lg' });
    const [modalVIJbyDate, setModalVIJbyDate] = useState<any>({ name: 'Validar Propiedades de invocacion del servicio por fecha', tabIndex: -1, view: false, btnClose: true, btnSubmit: false, format: 'modal-lg' });
    const [modalPSI, setModalPSI] = useState<any>({ name: 'Propiedades del Servicio de Invocación', tabIndex: -1, view: false, btnClose: true, btnSubmit: false, format: 'modal-lg' });
    const [modalVIJ, setModalVIJ] = useState<any>({ name: 'Validacion de Invocación de JSON', tabIndex: -1, view: false, btnClose: true, btnSubmit: false, format: 'modal-md' });
    const [modalConfirm, setModalConfirm] = useState<any>({ name: 'Confirmar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });
    const [modalAddPSI, setModalAddPSI] = useState<any>({ name: 'Confirmar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });


    const idBean = 'IDJsonService';

    const head = [
        { title: 'ID', property: idBean },
        { title: 'Nombre', property: 'Name' },
        { title: 'Descripción', property: 'Description' },
        // { title: 'Fecha', property: 'Since' },
        { title: 'URL de servico', property: 'URLService' },
    ]

    useEffect(() => {
        setUser(single.getAccountID());
        getLista();
       //console.log(single.getAccount())
    }, [idModule])

    const setRowBean = (bean: any) => {
       //console.log("Row : ", bean);
        setBean(bean);
    }


    const getLista = () => {

        setLoading(true);
        service.getJsonServiceCatalog(idModule).subscribe(
            (resp: any) => {
                setLista([]);
                if (resp.DataBeanProperties.ObjectValue) {
                    setLista(resp.DataBeanProperties.ObjectValue);
                }

                setLoading(false);
            }
        );
    }

    const agregarItem = () => {

        let b = {
            Name: '',
            Description: '',
            IDJsonServiceClass: idModule,
            URLRequestType: 0,
            InputJsonValue: '{}',
            URLService: '',
            State: 0,
            Code: 0,
            IDEmployee: user.IDAccount,
            Since: single.getCurrenDate()
        }

        setBean(b);
        setModal({ ...modal, ['view']: true });

    }

    const eliminarItem = () => {

        setLoading(true);
        service.deleteJsonService(bean[idBean]).subscribe(
            (resp: any) => {
                setLoading(false);
                if (resp) {
                    getLista();
                }
                single.delete(bean[idBean], bean);
            }
        );
        setModalConfirm({ ...modalConfirm, ['view']: false });

    }

    const updateItem = () => {

        setModal({ ...modal, ['view']: false });

        setLoading(true);
        service.updateJsonService(bean).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue) {
                    getLista();
                    setLoading(false);
                }
                single.update(bean);
            });

    }

    const updateProperties = () => {

        setModalAddPSI({ ...modalAddPSI, ['view']: false });

        setLoading(true);
        service.updateJsonPropertiesFromJsonService(bean.IDJsonService).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue) {
                    getLista();
                    setLoading(false);
                }
                single.update(bean);
            });

    }


    const listaBotones = () => {
        const botones = [

            { titulo: 'Editar', icono: 'ri-file-list-line', visible: true, evento: () => { setModal({ ...modal, "view": true }) } },
            { titulo: 'Eliminar', icono: 'ri-file-user-line', visible: true, evento: () => { setModalConfirm({ ...modalConfirm, "view": true }) } },
            { titulo: 'Agregar propiedades del servicio', icono: 'ri-shield-keyhole-line', visible: true, evento: () => { setModalAddPSI({ ...modalAddPSI, "view": true }) } },
            { titulo: 'Propiedades del servicio', icono: 'ri-settings-5-fill', visible: true, evento: () => { setModalPSI({ ...modalPSI, "view": true }) } },
            { titulo: 'Variables de respuesta', icono: 'ri-code-s-slash-line', visible: true, evento: () => { setModalVarResp({ ...modalVarResp, "view": true }) } },

        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }

    const renderButton = () => {
        return (
            <>
                <div className="row">
                    {bean[idBean] &&
                        <>
                            <div className="dropdown mt-2">
                                <button className="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    Otras acciones
                                </button>
                                <ul className="dropdown-menu">
                                    <li>
                                        <i className="dropdown-item ri-braces-line" onClick={() => { setModalJSON({ ...modalJSON, "view": true }) }} >
                                            <strong>  Prototipo JSON</strong>
                                        </i>
                                    </li>
                                    <li><hr className="dropdown-divider" /></li>
                                    <li>
                                        <a className="dropdown-item ri-swap-box-line" onClick={() => { setModalVIJ({ ...modalVIJ, "view": true }) }} >
                                            <strong>  Validar Invocación</strong>
                                        </a>
                                    </li>
                                    <li><hr className="dropdown-divider" /></li>
                                    <li>
                                        <a className="dropdown-item ri-calendar-todo-fill" onClick={() => { setModalVIJbyDate({ ...modalVIJbyDate, "view": true }) }} >
                                            <strong>  Validar Invocación por fecha</strong>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </>
                    }
                </div>
            </>
        )
    }

    const iconButtonsItem = [
        { nombre: 'Agregar', visible: single.canCreate(), icono: 'ri-file-add-line', evento: agregarItem }
    ]

    return (
        <>

            <BasicPage titulo="Servicios">
                <SelectorJsonClass idSelector={idModule} onChange={(e: any) => { setIDModule(e) }}></SelectorJsonClass>
                <div className="row">
                    <div className="col-md-12 p-2">
                        <BasicTable headButtons={renderButton()} listButtons={listaBotones()} iconButtons={iconButtonsItem} head={head} body={lista} rowSet={setRowBean}></BasicTable>
                    </div>
                </div>
            </BasicPage>

            <ModalConfirm modal={modalConfirm} updateModal={setModalConfirm} eventModal={eliminarItem}>
                {bean.Name}
            </ModalConfirm>

            <Modal modal={modal} updateModal={setModal} eventModal={updateItem}>
                <div className="row">
                    <div className="col-md-6">
                        <label className="form-label">Nombre</label>
                        <input type="text" className="form-control" value={bean.Name} onChange={(e) => { setBean({ ...bean, ['Name']: e.target.value }) }} />
                    </div>
                    <div className="col-md-6">
                        <SelectURLRequestType idSelector={bean.URLRequestType} onChange={(e: any) => { setBean({ ...bean, 'URLRequestType': e }) }}></SelectURLRequestType>
                    </div>
                    <div className="col-md-12">
                        <label className="form-label">URL del Servicio</label>
                        <input type="text" className="form-control" value={bean.URLService} onChange={(e) => { setBean({ ...bean, ['URLService']: e.target.value }) }} />
                    </div>
                    <div className="col-md-12">
                        <label className="form-label">Descripción</label>
                        <textarea className="form-control" value={bean.Description} onChange={(e) => { setBean({ ...bean, ['Description']: e.target.value }) }}></textarea>
                    </div>
                    <div className="col-md-12">
                        <label className="form-label">JSON</label>
                        <textarea className="form-control" value={bean.InputJsonValue} onChange={(e) => { setBean({ ...bean, ['InputJsonValue']: e.target.value }) }}></textarea>
                    </div>

                </div>
            </Modal>

            {modalJSON.view &&
                <>
                    <Modal modal={modalJSON} updateModal={setModalJSON} eventModal={updateItem}>
                        <JsonPrototype idJasonS={bean.IDJsonService} response={bean.ResponseJsonValue} />
                    </Modal>
                </>
            }
            {modalPSI.view &&
                <>
                    <Modal modal={modalPSI} updateModal={setModalPSI} eventModal={updateItem}>
                        <JsonPropertie IDJService={bean.IDJsonService}></JsonPropertie>
                    </Modal>
                </>
            }
            {modalAddPSI.view &&
                <>
                    <Modal modal={modalAddPSI} updateModal={setModalAddPSI} eventModal={updateProperties}>
                        <h5>Agregar propiedades del servicio de invocacion</h5>
                    </Modal>
                </>
            }
            {modalVIJ.view &&
                <>
                    <Modal modal={modalVIJ} updateModal={setModalVIJ} eventModal={updateItem}>
                        <ValidateInvJson idJasonS={bean.IDJsonService} />
                    </Modal>
                </>
            }
            {modalVIJbyDate.view &&
                <>
                    <Modal modal={modalVIJbyDate} updateModal={setModalVIJbyDate} eventModal={updateItem}>
                        <InvokeJsonServiceInPeriod idJasonS={bean.IDJsonService}></InvokeJsonServiceInPeriod>
                    </Modal>
                </>
            }
            {modalVarResp.view &&
                <>
                    <Modal modal={modalVarResp} updateModal={setModalVarResp} eventModal={updateItem}>
                        <VarResponseJson JsonName={bean.Name} idJasonS={bean.IDJsonService} />
                    </Modal>
                </>
            }
        </>
    )
}