import { useEffect, useState } from "react";
import { _ApplicationID, _single } from "../../core/services/dataBean/EntityCatalog";
import useDataBean from "../../core/services/dataBean/useDataBean";
import { toast } from "react-toastify";
import { BasicTable, MASK_NUMBER } from "../theme/Component/BasicTable";
import { Modal } from "../theme/Component/Modal";
import useModal, { MODAL_LG, MODAL_md, MODAL_SM } from "../theme/Component/hooks/useModal";
import { BasicMultiSelectTable } from "../theme/Component/BasicMultiSelecTable";
import { Singleton } from "../../core/services/Singleton";
import { SelectBudgetID } from "../admin/configuracion/BudgetID/SelectBudgetID";
import { ModalConfirm } from "../theme/Component/ModalConfirm";
import { ListMenu } from "../theme/Component/ListMenu";
import { PrintJson } from "../theme/Component/PrintJson";

interface Props {
    typeCode: number;// 1 budget, 2 UNSPSC
    IDLn: number;
}
export const CodesByFunctionalID = ({ typeCode, IDLn }: Props) => {

    const { instance, bean, setBean, handleChange, lista, setLista } = useDataBean(_ApplicationID);
    const single = Singleton.getInstance();

    const [listIDLn, setListIDLn] = useState<any[]>([]);
    const [listaResult, setListaResult] = useState<any[]>([]);
    const [row, setRow] = useState<any>({});
    const [type, setType] = useState<number>(NaN);
    const [listaType, setListaType] = useState<any>([]);

    const modalADD = useModal("Agregar", MODAL_LG);
    const modalADDBudget = useModal("Agregar", MODAL_LG);
    const modalConfirm = useModal("Eliminar Código", MODAL_md);
    const modalConfirmBudget = useModal("Eliminar Presupuesto", MODAL_md);

    useEffect(() => {
        if (typeCode === 1) {
            getBudgetCodesRender();
            getTreeForBudgetID()
        }
        else if (typeCode === 2) {
            getUnscpCodesRender();
        }
    }, [IDLn])

    const getTreeForBudgetID = () => {
        instance.getBudgetIDTypeCatalog().then(
            (resp: any) => {
                setListaType(resp);
            }
        ).catch(err => toast.error(err));
    }

    const getTreeForUnspscID = () => {
        instance.getTreeForUnspscID().then(
            (resp: any) => {
                if (resp.Childs) {
                    let tmp = [];
                    resp.Childs.forEach(element => {
                        tmp.push({
                            DataBeanProperties: element.EnvolvedObject.DataBeanProperties
                        })
                    });
                    setListIDLn(tmp);
                   //console.log(tmp);
                }


            }
        ).catch(err => toast.error(err));
    }

    const getBudgetCodesRender = () => {
        instance.getBudgetCodesRender(IDLn).then(
            (resp: any) => {
               //console.log("respuesta de tabla", resp)


                if (resp.Childs) {
                    let tmp = [];
                    resp.Childs.forEach(element => {
                        tmp.push({
                            DataBeanProperties: element.EnvolvedObject.DataBeanProperties
                        })
                    });
                    setLista(tmp);
                   //console.log(tmp);
                } else {
                    setLista(resp)
                }

            }
        ).catch(err => toast.error(err));
    }

    const getUnscpCodesRender = () => {
        instance.getUnscpCodesRender(IDLn).then(
            (resp: any) => {
               //console.log(resp);
                setListaResult(resp)
                setLista(resp);
            }
        ).catch(err => toast.error(err));
    }

    const createUNCSPCode = () => {
        let codes = listaResult.map(item => item.DataBeanProperties.IDLn);
        instance.addUnscpCodesByFunctionalID(IDLn, single.getAccountID(), codes).then(
            (resp) => {
                getUnscpCodesRender();
                modalADD.close();
            }
        ).catch(
            err => {
               console.error(err)
            }
        )
    }

    const addBudgetCodesByFunctionalID = () => {
        let codes = listaResult;
       //console.log(IDLn, type, single.getAccountID(), codes)
        instance.addBudgetCodesByFunctionalID(IDLn, bean.type, single.getAccountID(), codes).then(
            (resp) => {
                getBudgetCodesRender();
                modalADDBudget.close();
                setListaResult([])
            }
        ).catch(
            err => {
               console.error(err)
            }
        )
    }

    const deleteItem = () => {
        instance.deleteUnscpFunctionalID(row.UnscpFunctionalID).then(
            (resp) => {
                modalConfirm.close();
                getUnscpCodesRender();
            }
        ).catch(
            err => {
               console.error(err)
            }
        )
    }

    const deleteBudget = () => {
        instance.deleteBudgetFunctionalID(row.BudgetFunctionalID).then(
            (resp) => {
                modalConfirmBudget.close();
                getBudgetCodesRender();
            }
        ).catch(
            err => {
               console.error(err)
            }
        )
    }

    const opneModal = () => {
        if (typeCode === 1) {
            // getTreeForBudgetID();
            modalADDBudget.open();
        }

        if (typeCode === 2) {
            getTreeForUnspscID();
            modalADD.open();
        }
    }

    const head = [
        { title: 'ID', property: typeCode === 1 ? 'BudgetFunctionalID' : 'UnscpFunctionalID', visible: true, mask: MASK_NUMBER },
        { title: 'Nombre', property: 'Name', visible: true },

    ]
    const headTree = [
        { title: 'ID', property: 'IDLn', visible: true, mask: MASK_NUMBER },
        // { title: 'Código', property: 'JsonCode', visible: true, mask: MASK_NUMBER },
        { title: 'Nombre', property: 'Name', visible: true },

    ]
    const iconButtonsItem = [
        { nombre: 'Agregar', visible: _single.canCreate(), icono: 'ri-file-add-line', evento: opneModal }
    ]

    const listaBotones = () => {
        const botones = [
            {
                titulo: 'Eliminar', icono: 'ri-delete-bin-line', visible: single.canDelete(), evento: () => {
                    if (typeCode === 1) {
                        modalConfirmBudget.open();
                    } else if (typeCode === 2) {
                        modalConfirm.open();
                    }
                }
            },
        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }

    const validateItems = () => {
        if (listaResult.length > 0) {
            return true;
        } else {
            return false
        }
    }

    return (
        <>
            <div className="row">
                <div className="col">
                    <BasicTable listButtons={listaBotones()} body={lista} head={head} rowSet={setRow} iconButtons={iconButtonsItem}></BasicTable>
                </div>
            </div>

            <Modal modal={modalADDBudget.modal} updateModal={modalADDBudget.setModal} eventModal={() => { addBudgetCodesByFunctionalID() }}>
                <div className="row">
                    <div className="col-12">
                        {typeCode == 1 &&
                            <>
                                <label> Tipo Presupuesto</label>
                                <select disabled={validateItems()} className="form-select" value={type} onChange={(e) => { setType(parseInt(e.target.value)) }}>
                                    <option value={NaN}>...</option>
                                    {listaType.length > 0 &&
                                        listaType.map((item: any) => {
                                            return (
                                                <option value={item.DataBeanProperties.IDBudgetIDType}>{item.DataBeanProperties.Name}</option>
                                            )
                                        })
                                    }
                                </select>
                            </>
                        }
                    </div>
                    <div className="col-12">
                        <SelectBudgetID multiSelect edit={false} onChange={(e) => {
                            let x = e.map(item => item.IDLn)
                            setListaResult(x)
                        }} tipoPresupuesto={type} />
                    </div>
                </div>

            </Modal>

            <Modal modal={modalADD.modal} updateModal={modalADD.setModal} eventModal={() => { createUNCSPCode() }}>
                <div className="row">
                    <div className="col-12">
                        <BasicMultiSelectTable keyId="IDLn" body={listIDLn} bodyResult={listaResult} head={headTree}
                            updateValues={({ bodyResult }) => {
                                setListaResult(bodyResult);
                            }}

                        />
                    </div>
                </div>
            </Modal>

            <ModalConfirm modal={modalConfirm.modal} updateModal={modalConfirm.setModal} eventModal={() => { deleteItem() }}  >
                <p>¿Esta Seguro que desea eliminar el item seleccionado?</p>
                <strong>{row.Name}</strong>
            </ModalConfirm>

            <ModalConfirm modal={modalConfirmBudget.modal} updateModal={modalConfirmBudget.setModal} eventModal={() => { deleteBudget() }} >
                <p>¿Esta Seguro que desea eliminar el rubro ?</p>
                <strong>{row.Name}</strong>
            </ModalConfirm>
        </>
    )

}