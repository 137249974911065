import { DataBean } from "../DataBean";

export class Store extends DataBean {

    public async getStoresCatalog(arg: number | null): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_getStoresCatalog_Number", [arg]);
    }

    public async getStore(idStore: number): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "inventory.Store_getStore_Number", [idStore]);
    }

}