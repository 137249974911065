import { DataBean } from "../DataBean"

export class BudgetRPFactory extends DataBean {

    public async getCashReimbursementCatalog(year: number, idCashDeskAccount: number, state: number): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_getCashReimbursementCatalog_Number_Number_Number", [year, idCashDeskAccount, state]);
    }

    public async getRPDocuments(accountID: number, date: any): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_getRPDocuments_Number_java.util.Date", [accountID, date]);
    }

    public async getBudgetIDListForAdquisitionContract(idAdquisitionContract: number, adquisitionContractDate: any): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_getBudgetIDListForAdquisitionContract_Number_java.util.Date",
            [idAdquisitionContract, adquisitionContractDate]);
    }

    public async createRP(idAdquisitionContract: number, adquisitionContractDate: any, accountantDate: any, externalConsecutive: number, items: any, description: string, idAccount: number, idEmployee: number, createPdf: boolean): Promise<any> {
        return this.getCustomService("QuickDataERPCore",
            "com.quickdataerp.bean.accountant.AccountantDocument_createRP_Number_java.util.Date_java.util.Date_Number_java.util.List_String_Number_Number_Boolean",
            [idAdquisitionContract, adquisitionContractDate, accountantDate, externalConsecutive, items, description, idAccount, idEmployee, createPdf]);
    }

    public async createAdditionToInitialBudgetCatalog(idBudgetType: number, accountantDate: any,  items: any, description: string, idEmployee: number, createPdf: boolean): Promise<any> {
        return this.getCustomService("QuickDataERPCore",
            "com.quickdataerp.bean.accountant.AccountantDocument_createAdditionToInitialBudgetCatalog_Number_java.util.Date_java.util.List_String_Number_Boolean",
            [idBudgetType, accountantDate, items, description, idEmployee, createPdf]);
    }

    public async createBudgetRowForRP(budgetIDStr: string, idBudgetResource: number, idDocument: number, date: any, description: string, value: number): Promise<any> {
        return this.getCustomService(
            "QuickDataERPCore",
            "budget.Budget_createBudgetRowForRP_String_Number_Number_java.util.Date_String_Number",
            [budgetIDStr, idBudgetResource, idDocument, date, description, value]
        );
    }

    public async getCDPByConsecutive(year: number, consecutive: number, accountID: number | null): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_getCDPByConsecutive_Number_Number_Number", [year, consecutive, accountID]);
    }

    public async getCDPsByAdquisitionContract(idAdquisitionContract: number, adquisitionContractDate: any): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_getCDPsByAdquisitionContract_Number_java.util.Date", [idAdquisitionContract, adquisitionContractDate]);
    }
}