import { useEffect, useState } from "react";
import { Singleton } from "../../../core/services/Singleton";
import { TalentService } from "../../../core/services/TalentService";
import { BasicTable } from "../../theme/Component/BasicTable";
import { ListMenu } from "../../theme/Component/ListMenu";
import { Modal } from "../../theme/Component/Modal";
import { ModalConfirm } from "../../theme/Component/ModalConfirm";
import { ProofAnswer } from "./ProofAnswer";
import { AccountEditor } from "../../editor/AccountEditor";
import { PrintJson } from "../../theme/Component/PrintJson";



interface Props {
    idProof: number;
}

export const ProofAccounts = ({ idProof }: Props) => {

    const services = new TalentService();
    const single = Singleton.getInstance();
    const [lista, setLista] = useState([]);
    const [bean, setBean] = useState<any>({});
    const [modal, setModal] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalAnswer, setModalAnswer] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalConfirm, setModalConfirm] = useState<any>({ name: 'Confirmar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });

    useEffect(() => {
        if (idProof >= 0) {
            getLista()
        }
    }, [idProof])

    const idBean = 'IDProofAccounts';

    const head = [
        { title: 'ID', property: idBean },
        { title: 'Usuario', property: "InfoUser" },
    ]

    const setRowBean = (bean: any) => {
       //console.log("Row : ", bean);
        setBean(bean);
    }

    const getLista = () => {
        let x = {
            State: 1,
            IDProof: idProof
        }
        single.spinner(true);
        services.getProofAccountsCatalogPorPropiedadesRender(x, null).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue) {
                    let x = resp.DataBeanProperties.ObjectValue;
                    x.forEach((user) => {
                        user.DataBeanProperties.InfoUser = (user.DataBeanProperties.Name1 ?? "") + " " + (user.DataBeanProperties.Name2 ?? "") + " " + (user.DataBeanProperties.Surname1 ?? "") + " " + (user.DataBeanProperties.Surname2 ?? "")
                    });
                    setLista(x)
                }
                single.spinner(false)
            }
        )
    }

    const deleteItem = () => {
        bean.State = 2;
       //console.log(bean);
        updateItem();
        setModalConfirm({ ...modalConfirm, 'view': false });
    }

    const updateItem = () => {
        setModal({ ...modal, "view": false })
        single.spinner(true)
        services.updateProofAccounts(bean).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue) {
                    getLista()
                    if (bean.IDProofAccounts) {
                        single.update(bean)
                    } else {
                        single.create(bean)
                    }
                }
                single.spinner(false)
            }
        )
    }

    const agregarItem = () => {
        let b = {
            IDProof: idProof,
            IDAccount: undefined,
            State: 1,
        }
        setBean(b);
        setModal({ ...modal, 'view': true });
    }

    const listaBotones = () => {
        const botones = [
            { titulo: 'Respuestas del usuario', icono: 'ri-file-list-line', visible: true, evento: () => { setModalAnswer({ ...modalAnswer, "name": `Respuesta del usuario `, "view": true }) } },
            { titulo: 'Editar', icono: 'ri-file-list-line', visible: single.canEdit(), evento: () => { setModal({ ...modal, "view": true }) } },
            { titulo: 'Eliminar', icono: 'ri-file-user-line', visible: single.canDelete(), evento: () => { setModalConfirm({ ...modalConfirm, "view": true }) } },
        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }

    const iconButtonsItem = [
        { nombre: 'Agregar', visible: single.canCreate(), icono: 'ri-file-add-line', evento: agregarItem }
    ]

    return (
        <>
            <div className="row">
                <div className="col-12">
                    <BasicTable body={lista} head={head} rowSet={setRowBean} iconButtons={iconButtonsItem} listButtons={listaBotones()} />
                </div>
            </div>

            {modal.view &&
                <Modal modal={modal} updateModal={setModal} eventModal={updateItem}>
                    <div className="row">
                        <div className="col-12">
                            <AccountEditor label="Usuario seleccionado" idAccount={bean.IDAccount} onChange={({ IDAccount }) => { setBean({ ...bean, "IDAccount": IDAccount }) }}></AccountEditor>
                        </div>
                    </div>
                </Modal>
            }
            {modalAnswer.view &&
                <Modal modal={modalAnswer} updateModal={setModalAnswer} eventModal={""}>
                    <ProofAnswer idProofAcc={bean.IDProofAccounts} />
                </Modal>
            }
            {modalConfirm.view &&
                <ModalConfirm modal={modalConfirm} updateModal={setModalConfirm} eventModal={deleteItem}>
                    {bean.Name}
                </ModalConfirm>
            }
        </>
    )
}