import { useState, useEffect } from 'react';
import { TalentService } from '../../../../../core/services/TalentService';


interface Props {
    idSelector: any;
    onChange: any;
    label?: string;
}

export const StateReimbursementEditor = ({ idSelector, onChange, label }: Props) => {

    const service = new TalentService();
    const [lista, setLista] = useState<any[]>([]);
    const [id, setID] = useState(0);

    useEffect(() => {
        setID(idSelector);
        getLista();
    }, [])

    useEffect(() => {
        onChange(id);
    }, [id])

    const setValue = (e: any) => {
        setID(e);
        onChange(e);
    }

    const getLista = () => {
        service.getCashReimbursementProcessConstants(null).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue) {
                    const x = resp.DataBeanProperties.ObjectValue;
                    setLista(x);
                    if (x > 0) {
                        setID(x[0].DataBeanProperties.value)
                    }
                } else {
                   //console.log('Error en la carga de los seleccionables')
                }
            }
        );
    }

    return (
        <>
            <label>{label ?? "Estado"}</label>
            <select className="form-control" value={id} onChange={(e) => { setValue(parseInt(e.target.value)) }}>
                {lista.map((item: any) => (
                    <option key={item.DataBeanProperties.Value} value={item.DataBeanProperties.Value} >{item.DataBeanProperties.Property}</option>
                ))}
            </select>
        </>
    )
}
