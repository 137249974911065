import { useEffect, useState } from "react";
import { FileService } from "../../core/services/FileService";

interface Props {
    url: string;
    type: string;
}
export const VisorPDF = ({ url, type }: Props) => {

    const file = new FileService();

    const [urlFile, setUrlFile] = useState<string | null>(null);

    useEffect(() => {
        if (url) {
            const fetchPdf = async () => {
                try {
                    const data = await file.getPdfAsBase642(url);
                    setUrlFile(data);
                } catch (error) {
                    console.error('Error loading PDF:', error);
                }
            };

            fetchPdf();
        }
    }, [url]);

    return (
        <div className="row">
            <div className="col-md-12">
                <div className="iframe-container">
                    {type === 'pdf' ? (
                        urlFile ? (
                            <iframe name="pdfviwer" src={urlFile} onError={(e) => console.error('Error loading PDF:', e)}></iframe>
                        ) : (
                            <p>Cargando PDF...</p>
                        )
                    ) : (
                        <h6>Formato desconocido</h6>
                    )}
                </div>
            </div>
        </div>
    )
}
