
import { toast } from "react-toastify";
import useDataBean from "../../../../../../core/services/dataBean/useDataBean";
import { _PositionObjective } from "../../../../../../core/services/dataBean/EntityCatalog";
import { Singleton } from "../../../../../../core/services/Singleton";
import { useEffect, useState } from "react";
import { BasicPage } from "../../../../../theme/Component/BasicPage";
import { BasicTable } from "../../../../../theme/Component/BasicTable";
import { Modal } from "../../../../../theme/Component/Modal";
import { ModalConfirm } from "../../../../../theme/Component/ModalConfirm";
import { ListMenu } from "../../../../../theme/Component/ListMenu";
import { ContractEditor } from "../../../../../erp/cellEditor/ContractEditor";
import { PerformanceObjectivesEditor } from "../../Selects/PerformanceObjetives.editor";
import { MarcroProccesEditor } from "../../../../../Talentohumano/ParametrosTalentoHumano/MacroProcess/MacroProccesEditors/MacroProcess.editor";
import { StrategicObjectiveEditor } from "../../../../../Talentohumano/ParametrosTalentoHumano/StrategicObjetive/StrategicObjective.editor";
import { SubMarcroProccesEditor } from "../../../../../Talentohumano/ParametrosTalentoHumano/MacroProcess/MacroProccesEditors/SubMarcroProcces.editor";

interface PositionProps {
    dashedMenu: boolean;
}

export const PositionObjetive = ({ dashedMenu }: PositionProps) => {
    const positionObjective = useDataBean(_PositionObjective);
    const single = Singleton.getInstance();
    const [lista, setLista] = useState<any>([]);
    const [contrac, setContrac] = useState<number>(null);
    const [year, setYear] = useState<number>(null);
    const [modal, setModal] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalGestion, setModalGestion] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalC, setModalC] = useState<any>({ name: 'Eliminar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });

    useEffect(() => {
        if (contrac && year) {
            getLista();
        }
    }, [contrac, year])

    const getLista = () => {
        positionObjective.instance.getPositionObjectiveCatalog(contrac, year).then(
            (resp: any) => {
                if (resp) {
                    const listened = resp.filter((objetos: any) => objetos.DataBeanProperties.DeletedDate == null);

                    setLista(listened)

                }
            }
        )
    }

    const create = () => {
        positionObjective.setBean({
            IDContract: contrac,
            Year: year
        });
        setModal({ ...modal, "name": `Agregar Objetivos de Desempeño`, "view": true });
    }

    const deleteItem = () => {
        positionObjective.instance.deletePositionObjective(positionObjective.bean.IDPositionObjective, single.getAccountID(), "").then(
            (resp: any) => {
                getLista();
                setModalC({ ...modalC, "view": false });
            }
        )
    }

    useEffect(() => {
        positionObjective.setBean({
            ...positionObjective.bean,
            "IDAccountApproved": single.getAccountID(),
            "ApprovedDate": single.getCurrenDate()
        })
    }, [positionObjective.bean.ApprovedObservations])

    const editaItem = () => {
       //console.log(positionObjective.bean)
        positionObjective.update().then(
            (resp: any) => {
                getLista();
                setModal({ ...modal, "view": false });
                setModalGestion({ ...modalGestion, "view": false });
                if (positionObjective.bean.IDPositionObjective) {
                    single.update(positionObjective.bean)
                } else {
                    single.create(positionObjective.bean)
                }
            }
        ).catch(err => {
            toast.error(err);
        })
    }

    // const aprobarItem = () => {
    //     positionObjective.setBean({ ...positionObjective.bean, "positionObjective.bean": single.getAccountID(), "ApprovedDate": single.getCurrenDate() })
    //     setTimeout(() => {
    //         editaItem();
    //     }, 10)
    // }

    const head = [
        { title: 'ID', property: 'IDPositionObjective', visible: false },
        { title: 'Año', property: 'Year', visible: false },
        { title: 'Proceso Macro', property: 'MacroProcessName', visible: true },
        { title: 'Proceso', property: 'SubProcessName', visible: true },
        { title: 'Objetivo Estrategico', property: 'StrategicObjectiveName', visible: true },
        { title: 'Descripción', property: 'Description', visible: true },
        { title: 'Fecha de Aprobación', property: 'ApprovedDate', visible: false },
        { title: 'Fecha de Eliminacion', property: 'DeletedDate', visible: false },
    ]

    const iconButtonsItem = [
        { nombre: 'Agregar', visible: dashedMenu ? dashedMenu : single.canCreate(), icono: 'ri-file-add-line', evento: create }
    ]

    const listaBotones = () => {
        const botones = [
            { titulo: 'Editar', icono: 'ri-edit-2-line', visible: dashedMenu ? dashedMenu : single.canEdit(), evento: () => { setModal({ ...modal, "name": `Editar Objetivo de Desempeño`, "view": true }) } },
            { titulo: 'Eliminar', icono: 'ri-delete-bin-line', visible: dashedMenu ? dashedMenu : single.canDelete(), evento: () => { setModalC({ ...modalC, "view": true }) } },
            { titulo: 'Gestionar Posicion Objetiva', icono: 'ri-user-settings-line', visible: !dashedMenu, evento: () => { setModalGestion({ ...modalGestion, "name": "Aprobar Objetivo de desempeño", "view": true }) } },
        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }

    return (
        <>

            {dashedMenu ?
                <div className="row">
                    <div className="col-4">
                        <PerformanceObjectivesEditor idSelector={year} onChange={(e) => { setYear(e) }} />
                    </div>
                    <div className="col-8">
                        <ContractEditor idAccount={single.getAccountID()} onChange={(e) => { setContrac(e) }} />
                    </div>
                    {contrac ?
                        <div className="col-md-12 ">
                            <BasicTable body={lista} head={head} rowSet={positionObjective.setBean} listButtons={listaBotones()} iconButtons={iconButtonsItem} />
                        </div>
                        :
                        <div className="col-12 alert alert-warning shadow mt-2 text-center" role="alert">
                            <strong>Seleccione un contrato vigente</strong>
                        </div>
                    }
                </div>
                :
                <BasicPage titulo="Objetivos de Desempeño">
                    <div className="row">
                        <div className="col-3">
                            <PerformanceObjectivesEditor idSelector={year} onChange={(e) => { setYear(e) }} />
                        </div>
                        <div className="col-9">
                            <ContractEditor onChange={(e) => { setContrac(e) }} />
                        </div>
                        {contrac ?
                            <div className="col-md-12 ">
                                <BasicTable body={lista} head={head} rowSet={positionObjective.setBean} listButtons={listaBotones()} iconButtons={iconButtonsItem} />
                            </div>
                            :
                            <div className="alert alert-warning shadow" role="alert">
                                <strong>Seleccione un contrato vigente</strong>
                            </div>
                        }
                    </div>
                </BasicPage>
            }

            {modal.view &&
                <Modal modal={modal} updateModal={setModal} eventModal={editaItem}>
                    <div className="row">
                        <div className="col-6">
                            <MarcroProccesEditor idSelector={positionObjective.bean.IDMacroProcess} onChange={(e) => { positionObjective.setBean({ ...positionObjective.bean, "IDMacroProcess": e }) }} />
                        </div>
                        <div className="col-6">
                            <SubMarcroProccesEditor macroProcess={positionObjective.bean.IDMacroProcess} idSelector={positionObjective.bean.IDSubProcess} onChange={(e) => { positionObjective.setBean({ ...positionObjective.bean, "IDSubProcess": e }) }} />
                        </div>
                        <div className="col-6">
                            <StrategicObjectiveEditor macroProcess={positionObjective.bean.IDMacroProcess} subProcess={positionObjective.bean.IDSubProcess} idSelector={positionObjective.bean.IDStrategicObjective} onChange={(e) => { positionObjective.setBean({ ...positionObjective.bean, "IDStrategicObjective": e }) }} />
                        </div>
                        <div className="col-md-12">
                            <label>Descripción del Objetivo: </label>
                            <textarea name="Description" className="form-control" value={positionObjective.bean.Description} onChange={positionObjective.handleChange} />
                        </div>
                        <div className="col-md-12">
                            <label>Evidencias/Productos del Objetivo: </label>
                            <textarea name="Evidence" className="form-control" value={positionObjective.bean.Evidence} onChange={positionObjective.handleChange} />
                        </div>
                        {/* <PrintJson json={positionObjective.bean}></PrintJson> */}
                    </div>
                </Modal>
            }

            {modalC.view &&
                <ModalConfirm modal={modalC} updateModal={setModalC} eventModal={deleteItem}>
                    <strong>Eliminar item {positionObjective.bean.IDPositionObjective}</strong>
                </ModalConfirm>
            }

            {modalGestion.view &&
                <Modal modal={modalGestion} updateModal={setModalGestion} eventModal={editaItem}>
                    <>
                        <div className="col-md-12">
                            <label>Observaciones: </label>
                            <textarea name="ApprovedObservations" className="form-control" value={positionObjective.bean.ApprovedObservations} onChange={positionObjective.handleChange} />
                        </div>
                    </>
                </Modal>
            }
        </>
    )
}