import { useState, useEffect } from 'react';
import { Singleton } from "../../../../core/services/Singleton";
import { BasicPage } from "../../../theme/Component/BasicPage";
import { BasicButton } from "../../../theme/Component/BasicButton";
import { BasicTable } from "../../../theme/Component/BasicTable";
import { Modal } from "../../../theme/Component/Modal";
import { BpmService } from "../../../../core/services/BpmService";
import { ListMenu } from "../../../theme/Component/ListMenu";
import { BasicSwitch } from "../../../theme/Component/BasicSwitch";
import { Selectorconstcode } from "./Selectorconstcode";
import { RenderForm } from "../../../theme/Component/RenderForm";
import { useDashboardStore } from "../../../pages/hooks/useDashboardStore";

interface Props {
    idSelector: number;
    CodePar: number;
}

export const Typeformservice = ({ idSelector, CodePar }: Props) => {

    const single = Singleton.getInstance()
    const { setLoading } = useDashboardStore();
    const service = new BpmService();
    const [lista, setLista] = useState([]);
    const [bean, setBean] = useState<any>({});
    const [modaljson, setModaljson] = useState<any>({ name: 'Caracterizaciones', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalacciones, setModalacciones] = useState<any>({ name: 'Caracterizaciones', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });

    const [modal, setModal] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalConfirm, setModalConfirm] = useState<any>({ name: 'Confirmar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });
    const [idModule, setIDModule] = useState(0);
    const [user, setUser] = useState<any>({});
    const [idForm, setIDForm] = useState<any>(0);

    const idBean = 'ID';

    const head = [
        { title: 'ID', property: idBean },
        // { title: 'Tamaño columnas', property: 'ColumnSize' },
        // { title: 'Tipo de campo', property: 'FieldType' },
        { title: 'Nombre de la propiedad', property: 'PropertyName' },
        { title: 'Etiqueta del campo', property: 'Label' },
        // { title: 'Campo Requerido', property: 'Required' },
        // { title: 'Código Tabla Parametrica', property: 'CodePar' },
    ]

    useEffect(() => {
        setUser(single.getAccountID());

        setIDForm(idSelector);

        getLista();


    }, [idSelector])


    const setRowBean = (bean: any) => {
       //console.log("Row : ", bean);
        setBean(bean);

    }

    const getLista = () => {

        setLoading(true);
        service.getFormParamCatalogPorPropiedad('IDFormType', idSelector, null).subscribe(
            (resp: any) => {


                if (resp.DataBeanProperties.ObjectValue) {

                    setLista(resp.DataBeanProperties.ObjectValue);
                   //console.log(lista)
                }

                setLoading(false);
            }
        );
    }

    const agregarItem = () => {

        let b = {
            PropertyName: '',
            ColumnSize: '',
            State: 1,
            Label: '',
            IDFormType: idSelector,
            FieldType: 0,
            Required: false,
            CodePar: 0,




        }

        setBean(b);
        setModal({ ...modal, ['view']: true });

    }
    const editarItem = () => {

        setModal({ ...modal, ['view']: true });

    }

    const editarjson = () => {

        setModaljson({ ...modaljson, ['view']: true });

    }




    const veracciones = () => {

        setModalacciones({ ...modalacciones, ['view']: true });

    }

    const updateItem = () => {

        setModal({ ...modal, ['view']: false });

        setLoading(true);
        service.updateFormParam(bean).subscribe(
            (resp: any) => {
                setLoading(false);
                if (resp.DataBeanProperties.ObjectValue) {
                    getLista();
                }
                single.update(bean);
            });

    }

    const renderButton = () => {
        return (
            <>
                <div className="row">
                    <div className="col-md-12">
                        <BasicButton value="Agregar" icon="ri-file-add-line" eventClick={agregarItem} ></BasicButton>
                        {bean[idBean] &&
                            <>
                                <BasicButton value="Editar" icon="ri-edit-2-line" eventClick={editarItem} ></BasicButton>
                                <BasicButton value="Eliminar" icon="ri-eraser-line" eventClick={() => { setModalConfirm({ ...modalConfirm, ['view']: true }); }} ></BasicButton>
                                <BasicButton value="Json" icon="ri-edit-2-line" eventClick={editarjson} ></BasicButton>
                                <BasicButton value="Acciones" icon="ri-edit-2-line" eventClick={veracciones} ></BasicButton>
                            </>
                        }
                    </div>
                </div>
            </>
        )
    }


    const listaBotones = () => {
        const botones = [

            { titulo: 'Editar', icono: 'ri-file-list-line', visible: single.canEdit(), evento: () => { setModal({ ...modal, "view": true }) } },
            { titulo: 'Eliminar', icono: 'ri-file-user-line', visible: true, evento: () => { setModalConfirm({ ...modalConfirm, "view": true }) } },


        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }

    const iconButtonsItem = [

        // {nombre:'Agregar',icono:'ri-file-add-line',evento:agregarItem}
        { nombre: 'Agregar', visible: single.canCreate(), icono: 'ri-file-add-line', evento: agregarItem }
    ]

    const mostrar = () => {
       //console.log(lista);
       //console.log('');
    }


    return (
        <>

            <BasicPage titulo="Formularios">
                <div className="row">
                    <div className="col-md-12">

                    </div>
                    <div className="col-md-12 p-2">
                        <BasicTable listButtons={listaBotones()} iconButtons={iconButtonsItem} head={head} body={lista} rowSet={setRowBean}></BasicTable>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">

                        <RenderForm idFormType={CodePar} handleUpdate={(e: any) => { }} modeTest={true} />
                    </div>
                </div>

            </BasicPage>
            <Modal modal={modal} updateModal={setModal} eventModal={updateItem}>
                <div className="row">
                    <div className="col-md-12">
                        <label className="form-label">Etiqueta del campo</label>
                        <input type="text" className="form-control" value={bean.Label} onChange={(e) => { setBean({ ...bean, ['Label']: e.target.value }) }} />

                    </div>
                    <div className="col-md-6">
                        <label className="form-label">Nombre de la propiedad</label>
                        <input type="text" className="form-control" value={bean.PropertyName} onChange={(e) => { setBean({ ...bean, ['PropertyName']: e.target.value }) }} />
                    </div>
                    <div className="col-md-6">
                        <label className="form-label">Tamaño de columnas</label>

                        <select className="form-control" value={bean.ColumnSize} onChange={(e) => { setBean({ ...bean, ['ColumnSize']: e.target.value }) }}>
                            <option value={0}>...</option>
                            <option value={1}>1</option>
                            <option value={2}>2</option>
                            <option value={3}>3</option>
                            <option value={4}>4</option>
                            <option value={5}>5</option>
                            <option value={6}>6</option>
                            <option value={7}>7</option>
                            <option value={8}>8</option>
                            <option value={9}>9</option>
                            <option value={10}>10</option>
                            <option value={11}>11</option>
                            <option value={12}>12</option>


                        </select>
                    </div>

                    <div className="col-md-6">
                        <label className="form-label">Tipo de campo</label>
                        <select className="form-control" value={bean.FieldType} onChange={(e) => { setBean({ ...bean, ['FieldType']: e.target.value }) }}>
                            <option value={0}>...</option>
                            <option value={1}>Texto</option>
                            <option value={2}>Numerico</option>
                            <option value={3}>Boolean</option>
                            <option value={4}>Text Area</option>
                            <option value={5}>Tabla Parametrica</option>
                            <option value={6}>Fecha</option>

                        </select>
                        {bean.FieldType == 5 && <div className="col-md-12"> <Selectorconstcode idSelector={idModule} value={bean.CodePar} onChange={(e: any) => { setBean({ ...bean, ['CodePar']: e }) }} />  </div>}
                    </div>
                    {/* <div className="col-md-6">                    
                        <label className="form-label">Código Tabla Parametrica</label>
                        <input type="text" className="form-control" value={bean.CodePar } onChange={(e)=>{setBean({...bean,['CodePar']:e.target.value})}}/>
                    </div>  */}
                    <div className="col-md-6">
                        <label className="form-label">Campo Requerido</label>
                        <BasicSwitch label="" estado={bean.Required} eventChange={(e: any) => {
                            // setMiswitch(e);
                            setBean({ ...bean, "Required": e });
                        }} />

                    </div>


                </div>
            </Modal>
        </>
    )
}