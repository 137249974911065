import { useEffect, useState } from "react"
import { StepWizard, Wizard } from "../../bpm/config/Componentes/wizard/Wizard"
import { BasicPage } from "../../theme/Component/BasicPage"
import { CreateRFDocument } from "../budgetRPFactory/CreateRFDocument";
import { CreateAdditionToInitialBudgeDocument } from "../budgetRPFactory/CreateAdditionToInitialBudge";
import { PrintJson } from "../../theme/Component/PrintJson";
import { FormBudgetIDStr } from "../BudgetAdditionFactory/FormBudgetIDStr ";
import { FormBudgetIDStrAgregar } from "../BudgetAdditionFactory/FormBudgetIDStrAgregar";
import { BudgetCDPReductionForm } from "./BudgetCDPReductionForm";
import { FormBudgetCDPreductionAgregar } from "./FormBudgetCDPreductionAgregar";
import { BudgetCDPReductionFactoryDoc } from "./BudgetCDPReductionFactoryDoc";


export const BudgetCDPReductionFactory = () => {

    const [formData, setFormData] = useState<any>({});
    const [budgetList, setBudgetList] = useState<any>([]);
    const [allPerfect, setAllPerfect] = useState<boolean>(false);
    const [responseDocument, setResponseDocument] = useState<any>({});
    const [canDiscard, setCanDiscard] = useState<boolean>(false);
    const validarContrato = () => {

    }

    useEffect(() => {
        if (responseDocument?.MSGList?.length > 0) {
            setCanDiscard(true)
        } else {
            setCanDiscard(false)
        }
    }, [responseDocument])


    const steps: StepWizard[] = [
        {
            keyID: "0",
            name: 'PASO 1. Formulario',
            onEvent: validarContrato,
            children: <BudgetCDPReductionForm onChange={setFormData} />,
            validButton: (formData?.FormDate),
            canReturn: false
        },
        {
            keyID: "1",
            name: 'PASO 2.Rubros Presupuestales a Reducir',
            onEvent: validarContrato,
            children: <FormBudgetCDPreductionAgregar sheetForm={formData} onChange={(e) => { setBudgetList(e) }} />,
            validButton: budgetList.length > 0,
            canReturn: true
        },
        {
            keyID: "2",
            name: 'PASO 3. Crear Documento',
            onEvent: validarContrato,
            children: <BudgetCDPReductionFactoryDoc List={budgetList} formData={formData} onChange={(e) => { setResponseDocument(e) }} />,
            validButton: false,
            canReturn: false,
            canDiscard: canDiscard
        }
    ]
    return (
        <BasicPage titulo="Generar Reducción de CDP">

            <Wizard title="Certificado" listStep={steps} />
        </BasicPage>
    )
}