import JSEncrypt from "jsencrypt";
import { Singleton } from "../core/services/Singleton";
import { env } from "../env";

export function eliminarCookies() {
  document.cookie.split(";").forEach(function (c) {
    document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
  });
}

const encryp_method = env.ENCRYP_METHOD ?? false;
const encrypter = new JSEncrypt();


export const encrypt = (yek: any, toEncrypt: string): string => {
  let encrypted: string | false | any = '';

  const yek2 = Singleton.getInstance().encrypKey;

  encrypter.setPublicKey(yek2);

  const data = {
    text: toEncrypt,
    textLength: 0,
    encrypted: '',
    encryptSize: 0,
    sinEncrypt: ''
  }

  data.text = toEncrypt;
  data.textLength = getStringSizeInBytes(toEncrypt);


  if (yek2 !== '') {

    if (encryp_method === true) {
      encrypted = encrypter.encrypt(toEncrypt);
      data.encrypted = encrypted;
      data.sinEncrypt = atob(encrypted);
      data.encryptSize = getStringSizeInBytes(atob(encrypted));
    }
    else {
      encrypted = toEncrypt;
    }




  } else {
    encrypted = toEncrypt;
  }
  

  return encrypted;
}
function getStringSizeInBytes(str) {
  let bytes = 0;

  for (let i = 0; i < str.length; i++) {
    const charCode = str.charCodeAt(i);

    if (charCode < 0x80) {
      bytes += 1; // 1 byte para caracteres en el rango ASCII (0x00 - 0x7F)
    } else if (charCode < 0x800) {
      bytes += 2; // 2 bytes para caracteres en el rango (0x80 - 0x7FF)
    } else if (charCode < 0x10000) {
      bytes += 3; // 3 bytes para caracteres en el rango (0x800 - 0xFFFF)
    } else if (charCode < 0x200000) {
      bytes += 4; // 4 bytes para caracteres en el rango (0x10000 - 0x1FFFFF)
    }
  }

  return bytes;
}

// Ejemplo de uso
const mensaje = "Este es un mensaje secreto.";
const tamañoEnBytes = getStringSizeInBytes(mensaje);




export const encryptData = (toEncrypt: string): string => {

  let encrypted: string | false | any = '';
  const encrypter = new JSEncrypt();
  const yek2 = Singleton.getInstance().encrypKey;

  encrypter.setPublicKey(yek2);

  toEncrypt = toEncrypt.replace(/\s/g, '');

  if (yek2 !== '') {
    encrypted = encrypter.encrypt(toEncrypt);
  } else {
    encrypted = toEncrypt;
  }

  return encrypted;
}

export function camelize(str: string) {
  let msg: string = "";
  let first: string = "";
  if (str !== null) {
    if (str !== undefined) {
      let aux: string[] = str.split(" ");
      first = aux[0]
        .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
          return index === 0 ? word.toUpperCase() : word.toLowerCase();
        })
        .replace(/\s+/g, "");
      aux.map((word, index) => {
        if (index !== 0) {
          msg += " " + word.toLowerCase();
        }
      });
    }
  }
  return first + msg;
}

const MORNING = '🌞 Buenos Días'
const EVENING = '☕ Buenas Tardes'
const NIGHT = '🌙 Buenas Noches'

export const getGreeting = () => {
  const currentTime = new Date()
  const hour = currentTime.getHours()
  if (hour >= 0 && hour < 12) {
    return MORNING
  } else if (hour >= 12 && hour < 19) {
    return EVENING
  } else {
    return NIGHT
  }
}
export const today = new Date()

export const formatDate = (toFormat: Date) => {
  return `${toFormat.getDate().toString().padStart(2, '0')}/
  ${(toFormat.getMonth() + 1).toString().padStart(2, '0')}/
  ${toFormat.getFullYear()}`
}

export function currencyFormatter(value: number) {
  const currency = 'USD'
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    minimumFractionDigits: 2,
    currency
  })
  return formatter.format(value)
} 