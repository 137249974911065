import { useEffect, useState } from "react";

import useDataBean from "../../../core/services/dataBean/useDataBean";
import { CashReimbursementServices } from "../cellEditor/CashDesk/CashReimbursement/CashReimbursement-service";
import { toast } from "react-toastify";
import { Singleton } from "../../../core/services/Singleton";
import { ListMenu } from "../../theme/Component/ListMenu";
import { BasicTable, MASK_DATE, MASK_NUMBER } from "../../theme/Component/BasicTable";
import { BasicPage } from "../../theme/Component/BasicPage";
import { Modal } from "../../theme/Component/Modal";
import { SelectAccountantTree } from "../../contabilidad/catalogo-cuentas/AccountantTree/SelectAccountantTree";
import { PrintJson } from "../../theme/Component/PrintJson";
import { ModalConfirm } from "../../theme/Component/ModalConfirm";
import { _AccountantConcept, _FiscalResponsability, _FiscalResponsabilityType } from "../../../core/services/dataBean/EntityCatalog";
import { InputDate } from "../../theme/Input/InputDate";
import { BasicSwitch } from "../../theme/Component/BasicSwitch";
import { AccountEditor } from "../../editor/AccountEditor";
import { _ArticleClassType, _MeasurementUnitCode } from "../../../core/services/dataBean/EntityCatalogVdos";
import { SelectAdquisitionArticleConstants } from "../../contract/select/SelectAdquisitionArticleConstants";




export const ArticleClassType = () => {

    const ArticleClassType = useDataBean(_ArticleClassType);

    const single = Singleton.getInstance();
    const [lista, setLista] = useState<any>([]);
    const [filtro, setFiltro] = useState(''); // Filtro para la búsqueda
    const service = new CashReimbursementServices();
    const [original, setOriginal] = useState<any>([]);
    const [filteredLista, setFilteredLista] = useState(lista); // Lista filtrada
    const [listadocs, setlistadocs] = useState([]);
    const [search, setSearch] = useState("");
    const [IdAcoount, setIdAcoount] = useState<number>(null)
    const [modal, setModal] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalC, setModalC] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });



    useEffect(() => {
        getLista()

    }, [])


    const getLista = () => {

        ArticleClassType.instance.getArticleClassTypeCatalog(null).then(
            (resp: any) => {
                if (resp) {
                    setLista(resp)

                   //console.log(resp)
                    toast.success('Completado')
                } else {
                    toast.error('NO hay datos')
                }

            }
        )

    }



    const create = () => {
        ArticleClassType.setBean({


        });

        setModal({ ...modal, "view": true });
    }


    const deleteItem = () => {
        ArticleClassType.deleteItemVoid(ArticleClassType.bean.IDMeasurementUnitCode).then(
            (resp: any) => {
                getLista();
                setModalC({ ...modalC, "view": false });
            }
        )

    }


    const editaItem = () => {
       //console.log(ArticleClassType.bean);
        ArticleClassType.update().then(
            (resp: any) => {
                getLista();
                setModal({ ...modal, "view": false });
            }
        ).catch(err => {
            toast.error(err);
        })

    }


    // const editaItem = () => {
    //     MeasurementUnitCode.instance.updateMeasurementUnitCode(MeasurementUnitCode.bean.IDMeasurementUnitCode).then(
    //         (resp: any) => {
    //             getLista();
    //             setModal({ ...modal, "view": false });
    //         }
    //     ).catch(err => {
    //         toast.error(err);
    //     })

    // }

    const head = [
        { title: 'ID', property: 'IDArticleClassType', visible: true, mask: MASK_NUMBER },
        { title: 'Nombre', property: 'Name', visible: true },
        { title: 'Tipo', property: 'TypeName', visible: true },
        { title: 'Descripción', property: 'Description', visible: true },
        { title: 'Formato del Código', property: 'CodeFormat', visible: true },
        { title: 'Separador de Código', property: 'CodeSeparator', visible: true },
        { title: 'País', property: 'Country', visible: true },
        { title: 'Fecha', property: 'Since', visible: true }


    ]

    const iconButtonsItem = [
        { nombre: 'Agregar', visible: single.canCreate(), icono: 'ri-file-add-line', evento: create }
    ]
    const listaBotones = () => {
        const botones = [

            { titulo: 'Editar', icono: 'ri-edit-2-line', visible: single.canEdit(), evento: () => { setModal({ ...modal, "view": true }) } },
            { titulo: 'Eliminar', icono: 'ri-delete-bin-line', visible: single.canDelete(), evento: () => { setModalC({ ...modalC, "view": true }) } },

        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }



    return (
        <BasicPage titulo="Catálogos de Clasificación de Bienes y Servicios">
            <div className="row">

                <div className="col-md-12">

                    <BasicTable body={lista} head={head} rowSet={ArticleClassType.setBean} listButtons={listaBotones()} iconButtons={iconButtonsItem}></BasicTable>
                    <Modal modal={modal} updateModal={setModal} eventModal={editaItem}>
                        <>
                            <div className="row">

                                <div className="col-md-6">
                                    <label>Nombre</label>
                                    <input type="text" name="Name" className="form-control" value={ArticleClassType.bean.Name} onChange={ArticleClassType.handleChange} />

                                </div>
                                <div className="col-md-6">
                                    <SelectAdquisitionArticleConstants label="Tipo" idSelector={ArticleClassType.bean.Type} onChange={(e) => { ArticleClassType.setBean({ ...ArticleClassType.bean, "Type ": e }) }} />
                                </div>
                                <div className="col-md-6">
                                    <label>Formato del Código</label>
                                    <input type="text" name="CodeFormat" className="form-control" value={ArticleClassType.bean.CodeFormat} onChange={ArticleClassType.handleChange} />

                                </div>
                                <div className="col-md-6">
                                    <label>Separador de Código</label>
                                    <input type="text" name="CodeSeparator" className="form-control" value={ArticleClassType.bean.CodeSeparator} onChange={ArticleClassType.handleChange} />

                                </div>
                                <div className="col-md-6">
                                    <label>País</label>
                                    <input type="text" name="Country" className="form-control" value={ArticleClassType.bean.Country} onChange={ArticleClassType.handleChange} />

                                </div>

                                <div className="col-md-12">
                                    <label>Description</label>
                                    <textarea name="Description" className="form-control" value={ArticleClassType.bean.Description} onChange={ArticleClassType.handleChange} />
                                </div>
                                {/* <div className="col-md-12">
                                    <label>Description</label>
                                    <textarea name="Description" className="form-control" value={ArticleClassType.bean.Description} onChange={ArticleClassType.handleChange} />
                                </div> */}

                            </div>

                        </>
                    </Modal>

                    <ModalConfirm modal={modalC} updateModal={setModalC} eventModal={deleteItem}>
                        <strong>Eliminar item</strong>
                    </ModalConfirm>
                </div>

            </div>
        </BasicPage>
    )
}