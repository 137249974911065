import { useState } from "react";
import { BasicButton } from "../../theme/Component/BasicButton";
import { DocumentViewer } from "../../theme/Component/DocumentViewer";
import { ErrorList } from "../../theme/Component/ErrorList";
import useDataBean from "../../../core/services/dataBean/useDataBean";
import { _BudgetObligationFactory, _single } from "../../../core/services/dataBean/EntityCatalog";
import { toast } from "react-toastify";

interface Props {
    rpDocument: any;
    obligationList: any;
}

export const CreateObligationDocument = ({ obligationList, rpDocument }: Props) => {

    const { instance } = useDataBean(_BudgetObligationFactory);

    const [documents, setDocuments] = useState<any>([]);
    const [msgList, setMsgList] = useState<any>([]);
    const [tap, setTap] = useState<number>(0);

    const crearDocumento = () => {
        instance.createOB(rpDocument.DocumentRP.IDDocument, rpDocument.DocumentRP.DocumentDate, rpDocument.RegisterDate, rpDocument.Consecutive, obligationList, rpDocument.Description, _single.getAccountID(), true).then(
            (resp: any) => {
               //console.log(resp)
                setDocuments(resp.DocumentURL);
                setMsgList(resp.MsgList);
                setTap(1)
            }
        ).catch(
            err => {
                toast.error(err, { autoClose: false })
            }
        )
    }

    //pude declarar variables pero no declarar mi amor por ella x2 ...

    return (
        <div className="row">
            {tap === 0 &&
                <div className="col-md-6">
                    <BasicButton disable={!rpDocument.RegisterDate} icon="ri-article-line" eventClick={crearDocumento}>Generar Documento Obligación Presupuestal</BasicButton>
                </div>
            }
            {tap === 1 &&
                <div className="col">
                    {msgList.length > 0 &&
                        <ErrorList lista={msgList} />

                    }
                    {msgList.length === 0 && documents.length > 0 &&
                        <DocumentViewer key={documents} list={documents} />
                    }
                </div>
            }
        </div>

    )
}