import { DataBean } from "../DataBean";

export class AccountantID extends DataBean {

    public ACTIVE = 3;

    public async getAccountantIDTypeCatalog(): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_getAccountantIDTypeCatalog_Number", [null]);
    }
    public async getTreeForAccountantID(idAccountantIDType: number | null): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "com.advantage.shared.Tree_getTreeForAccountantID_Number", [idAccountantIDType]);
    }
    public async loadAccountantIDTree(idLn: number): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "com.advantage.shared.Tree_loadAccountantIDTree_Number", [idLn]);
    }
    public async createAccountantID(idAccountantIDType: number, items: any, name: string): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "com.quickdataerp.bean.accountant.AccountantID_createAccountantID_Number_java.util.List_String", [idAccountantIDType, items, name]);
    }

    public async getAccountantID(idAccountant: number): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "com.quickdataerp.bean.accountant.AccountantID_getAccountantID_Number", [idAccountant]);
    }

    public async getAccountantIDByKey(idlnAccountant: number): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "com.quickdataerp.bean.accountant.AccountantID_getAccountantIDByKey_Number", [idlnAccountant]);
    }

    public async getAccountantIDByCode(jsonCode: string): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "com.quickdataerp.bean.accountant.AccountantID_getAccountantIDByCode_String", [jsonCode]);
    }

}