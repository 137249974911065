import { DataBean } from "../DataBean";

export class DocumentFactory extends DataBean {
    public async getDocuments(year: number, mnemonic: string, consecutive: number, accountaid: any): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_getDocuments_Number_String_Number_Number",
            [year, mnemonic, consecutive, accountaid]
        );
    }

    public async getDocumentHeaderByConsecutive(year: number, mnemonic: string, consecutive: number): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_getDocumentHeaderByConsecutive_Number_String_Number",
            [year, mnemonic, consecutive]
        );
    }

    public async getPdfAccountantDocument(documentLocation: number, mnemonic: string, date: any, idAccount: number | null): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "com.quickdataerp.bean.accountant.AccountantDocument_getPdfAccountantDocument_Number_String_java.util.Date_Number",
            [documentLocation, mnemonic, date, idAccount]
        );
    }
}