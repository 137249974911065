import { useState, useEffect } from 'react';
import { Singleton } from "../../../core/services/Singleton";
import { BasicTable } from "../../theme/Component/BasicTable";
import { ModalConfirm } from "../../theme/Component/ModalConfirm";
import { Modal } from "../../theme/Component/Modal";
import { BpmService } from "../../../core/services/BpmService";
import { SelectorProcedure } from './Componentes/SelectorProcedure';
import { ListMenu } from '../../theme/Component/ListMenu';
import { ModalGeneric } from '../../../interfaces/global.interface';
import { useDashboardStore } from '../../pages/hooks/useDashboardStore';
import { SelectorFlowControlOperators } from './Componentes/SelectorFlowControlOperators';
import { SelectorProcedureDcoumentWithJsonFormAndJsonService } from './Componentes/SelectorProcedureDcoumentWithJsonFormAndJsonService';
import { SelectorResponseValueForDocument } from './Componentes/SelectorResponseValueForDocument';
import { SelectorConditionalOperators } from './Componentes/SelectorConditionalOperators';
import { toast } from 'react-toastify';

interface Props {
    _idProcedure?: number;
    refresh: () => void;
    _idBussinesProcces?: number;
}

interface ConditionStatement {
    idProcedure: number,
    idProcedureDestinity: number,
    flowControl: string,
}

export const ConditionStatement = ({ _idProcedure, refresh, _idBussinesProcces }: Props) => {

    const single = Singleton.getInstance()
    const { setLoading } = useDashboardStore();
    const service = new BpmService();
    const [lista, setLista] = useState([]);
    const [bean, setBean] = useState<any>({
        idProcedure: _idProcedure,
        idProcedureDestinity: 0,
        flowControl: '',
    });
    const [modal, setModal] = useState<ModalGeneric>({ name: 'Control de Flujo a Procedimientos', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [flowModal, setFlowModal] = useState({ name: 'Orden en el flujo a Procedimientos', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });
    const [meliminar, setMeliminar] = useState<any>({ name: 'Confirmar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });

    const idBean = 'IDConditionStatement';

    const head = [
        { title: 'ID', property: idBean },
        { title: 'Evaluación de Condición', property: 'ConditionStatement' },
        { title: 'Procedimiento', property: 'ProcedureName' },
        { title: 'Orden de ejecución', property: 'ConditionalRow' },
    ]

    useEffect(() => {
        getLista();
    }, [_idProcedure])


    const setRowBean = (bean: any) => {
       //console.log("Row : ", bean);
        setBean(bean);
    }

    const getLista = () => {
        setLoading(true);
        service.getConditionStatementCatalog(_idProcedure).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue) {
                    setLista(resp.DataBeanProperties.ObjectValue);
                }
                setLoading(false);
            }
        );
    }

    const agregar = () => {
        setBean({
            idProcedure: _idProcedure,
            idProcedureDestinity: 0,
            flowControl: '',
        })
        setModal({ ...modal, "view": true });
    }

    const updateItem = () => {
        setLoading(true);
        service.addFlowControlStatement(bean.IDConditionStatement ?? null, _idProcedure, bean.IDProcedureDestination, bean.IDProcedureSource, bean.FlowControlOperator, bean.ConditionalOperator, bean.IDDocument, bean.IDResponseValue, bean.ConditionalValue).subscribe(
            (resp: any) => {
                setLoading(false);
                if (resp.DataBeanProperties.ObjectValue) {
                    getLista();
                    setModal({ ...modal, 'view': false });
                    refresh();
                }
            });
        setModal({ ...modal, 'view': false })
    }

    const deleteConditionalStament = () => {
        setLoading(true);
        service.deleteConditionStatement(bean.IDConditionStatement).subscribe(
            (resp: any) => {
                if (resp) {
                    getLista();
                    refresh();
                    setMeliminar({ ...meliminar, 'view': false });
                }
                setLoading(false);
            });
    }

    const changeFlow = () => {
        single.spinner(true);
        service.moveConditionStatementToPosition(bean.IDConditionStatement, bean.ConditionalRow).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ErrorMessage) {
                    toast.error(resp.DataBeanProperties.ErrorMessage)
                } else {
                    toast.success("Cambio realizado");
                    getLista();
                    setFlowModal({ ...flowModal, "view": false });
                }
                single.spinner(false);
            }
        )
    }

    const iconButtonsItem = [
        { nombre: 'Agregar', visible: single.canCreate(), icono: 'ri-file-add-line', evento: agregar }
    ]


    const listaBotones = () => {
        const botones = [
            { titulo: 'Editar', icono: 'ri-file-list-line', visible: true, evento: () => { setModal({ ...modal, "view": true }) } },
            { titulo: 'Eliminar', icono: 'ri-file-forbid-line', visible: true, evento: () => { setMeliminar({ ...meliminar, "view": true }) } },
            { titulo: 'Cambiar Flujo', icono: 'ri-file-settings-line', visible: true, evento: () => { setFlowModal({ ...flowModal, "view": true }) } },
        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }



    return (
        <>
            <BasicTable head={head} body={lista} rowSet={setRowBean} iconButtons={iconButtonsItem} listButtons={listaBotones()} />
            {modal.view &&
                <Modal modal={modal} updateModal={setModal} eventModal={() => updateItem()}>
                    <div className="col-md-12 mt-3">
                        <SelectorFlowControlOperators titulo='Seleccione una condición' idSelector={bean.FlowControlOperator} onChange={(e: any) => { setBean({ ...bean, 'FlowControlOperator': e }) }}></SelectorFlowControlOperators>
                    </div>
                    {(bean.FlowControlOperator === 'IF' || bean.FlowControlOperator === 'IF NOT') &&
                        <>
                            <div className="row mt-3">
                                <div className="col-md-4">
                                    <SelectorProcedure idBussinessProcess={_idBussinesProcces} idProcedure={_idProcedure} idSelector={bean.IDProcedureSource} onChange={(e: any) => { setBean({ ...bean, 'IDProcedureSource': e }) }} titulo='Seleccione un procedimiento'></SelectorProcedure>
                                </div>
                                {bean.IDProcedureSource &&
                                    <div className="col-md-4">
                                        <SelectorProcedureDcoumentWithJsonFormAndJsonService _idProcedure={bean.IDProcedureSource} idSelector={bean.IDDocument} onChange={(e: any) => { setBean({ ...bean, 'IDDocument': e }) }} titulo='Seleccione un Documento'></SelectorProcedureDcoumentWithJsonFormAndJsonService>
                                    </div>
                                }
                                {bean.IDDocument &&
                                    <div className="col-md-4">
                                        <SelectorResponseValueForDocument _idDocument={bean.IDDocument} idSelector={bean.IDResponseValue} onChange={(e: any) => { setBean({ ...bean, 'IDResponseValue': e }) }} titulo='Seleccione una variable'></SelectorResponseValueForDocument>
                                    </div>
                                }
                            </div>

                            <div className="col-md-12">
                                <SelectorConditionalOperators _flowControl={bean.FlowControlOperator} idSelector={bean.ConditionalOperator} onChange={(e: any) => { setBean({ ...bean, 'ConditionalOperator': e }) }} titulo='Seleccione una condicion'></SelectorConditionalOperators>
                            </div>
                            <div className="row mb-3 mt-3">
                                <div className="col-md-4">
                                    <label className="form-label">Valor a comparar:</label>
                                </div>
                                <div className="col-md-8">
                                    <input type="string" className="form-control" value={bean.ConditionalValue} onChange={(e) => { setBean({ ...bean, 'ConditionalValue': e.target.value }) }} />
                                </div>
                            </div>
                        </>
                    }
                    <div className="col-md-12 mt-3">
                        <SelectorProcedure idProcedure={_idProcedure} titulo='Seleccione destino' idSelector={bean.IDProcedureDestination} onChange={(e: any) => { setBean({ ...bean, IDProcedureDestination: e }) }}></SelectorProcedure>
                    </div>
                </Modal>
            }

            {meliminar.view &&
                <ModalConfirm modal={meliminar} updateModal={setMeliminar} eventModal={deleteConditionalStament}>
                    <>{bean.idBean}</>
                </ModalConfirm>
            }

            {flowModal.view &&
                <Modal modal={flowModal} updateModal={setFlowModal} eventModal={changeFlow}>
                    <div className="col-12">
                        <label >Orden en el Flujo:</label>
                        <input type="number" className='form-control' value={bean.ConditionalRow} onChange={(e) => { setBean({ ...bean, "ConditionalRow": parseInt(e.target.value) }) }} />
                    </div>
                </Modal>
            }
        </>
    )
}