import { useEffect, useState } from 'react'
import { RiskFactor } from './RiskFactor';
import { useFieldArray, useForm } from 'react-hook-form';
import { RiskMatrixData } from '../../interfaces';
import { Singleton } from '../../../../core/services/Singleton';
import { BpmService } from '../../../../core/services/BpmService';

interface Props {
    value: RiskMatrixData
    onChange: (nValue: RiskMatrixData) => void
}
export const RiskMatrix = ({ value, onChange }: Props) => {

    

    const _snigle = Singleton.getInstance()
    const _service = new BpmService()
    const [item, setItem] = useState<any>({});

    const [idRiskCategory, setIdRiskCategory] = useState(0)

    const { control, register, handleSubmit, getValues, setValue } = useForm<RiskMatrixData>({ defaultValues: value })
    const { fields } = useFieldArray({
        control,
        name: 'risks'
    })

    const onSubmit = (data: RiskMatrixData) => {        
        onChange(data)
    }

    
    const getMatrixFactorsTemplete = () => {
        _snigle.spinner(true)
        _service.getFactorMatrizTemplate()
            .subscribe((resp: any) => {
                _snigle.spinner(false)
                if (resp.DataBeanProperties.ObjectValue) {
                    const x = JSON.parse(resp.DataBeanProperties.ObjectValue[0]);
                    if (item.factorsCheck?.length > 0) {
                        setItem({ ...item, factorsCheck: item.factorsCheck })
                    } else {
                        setItem({ ...item, factorsCheck: x })
                    }
                }
            })
    }

    return (
        <div className='row'>
            <div className='px-3 py-2 rounded border'>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="d-flex flex-column">
                        <small>Seleccione Categoría</small>
                        <select className="form-select" aria-label="Large select example"
                            onChange={(e) => { setIdRiskCategory(parseInt(e.target.value)) }}
                        >
                            {fields.map((e, i) =>
                            (<option key={e.IDRiskCategory} value={i}>
                                {e.Description}
                            </option>))}
                        </select>
                    </div>
                    <hr />
                    {fields[idRiskCategory]?.Childs?.map((_e, _i) =>
                        <div key={_e.IDRiskItem}>
                            <div className='row my-3'>
                                <div className="col-5">
                                    <p><strong>{_e.Risk}</strong></p>
                                    <p><small>{_e.RiskDescription}</small></p>
                                </div>
                                <div className="col-7">
                                    <RiskFactor key={_e.IDRiskItem}
                                        riskCategoryIndex={idRiskCategory}
                                        riskFactorIndex={_i}
                                        register={register}
                                        setValue={setValue}
                                        value={getValues(`risks.${idRiskCategory}.Childs.${_i}`)}
                                        onChange={() => {
                                            onSubmit({ risks: getValues('risks') })
                                        }} />
                                </div>
                            </div>
                            <hr />
                        </div>
                    )}
                </form>
            </div>
        </div>
    )
}
