import { useEffect, useState } from 'react';

// export const ModalDefauld = ({ children, name, tabIndex, view, eventModal }: any) => {
interface Props {
    modal: any;
    updateModal: any;
    eventModal: any;
    children?: any
}
export const ModalConfirm = ({ children, modal, eventModal, updateModal }: Props) => {

    const [ver, setVer] = useState('none');

    useEffect(() => {
        setVer(modal.view ? 'block' : 'none');
    }, [modal.view])


    return (
        <>
            <div className={`modal fade show ${modal.format}`} tabIndex={modal.tabIndex} style={{ display: ver }}>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="myModalLabel">{modal.name}</h5>
                            <button type="button" className="btn-close" aria-label="Close" onClick={() => { updateModal({ ...modal, ['view']: false }) }}> </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col">
                                {children}
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            {modal.btnClose &&
                                <button type="button" className="btn btn-light" data-bs-dismiss="modal" onClick={() => { updateModal({ ...modal, 'view': false }) }}>Cancelar</button>
                            }
                            {modal.btnSubmit &&
                                <button type="button" className="btn btn-primary " onClick={eventModal}>Aceptar</button>
                            }
                        </div>
                    </div>
                </div>

            </div>

            {ver === 'block' &&
                <div className="modal-backdrop fade show"></div>
            }

        </>
    )
}