import { DataBean } from "../DataBean";

export class TransversalSkillType extends DataBean {


    public async getTransversalSkillTypeCatalog(idTransversalSkillType: number | null): Promise<any> {
        return this.getCustomService("PeopleArt", "java.util.List_getTransversalSkillTypeCatalog_Number", [idTransversalSkillType]);
    }

    public async deleteTransversalSkillType(bean): Promise<any> {
        return this.getCustomService("PeopleArt", "void_deleteTransversalSkillType_Number", [
            {
                "DataBeanProperties": bean,
                "DataBeanName": "com.peopleart.bean.appointment.TransversalSkillType"
            }
        ]);
    }
}