import { BasicPage } from "../../theme/Component/BasicPage"
import { BasicButton } from '../../theme/Component/BasicButton';
import { BasicTable } from '../../theme/Component/BasicTable';
import { Singleton } from '../../../core/services/Singleton';
import { ContractService } from '../../../core/services/ContractService';
import { useState, useEffect } from 'react';
import { AdminService } from "../../../core/services/AdminService";
import { useForm } from "react-hook-form";
import { ListMenu } from "../../theme/Component/ListMenu";
import { BpmService } from "../../../core/services/BpmService";
import { InputDate } from "../../theme/Input/InputDate";
import { AccountEditor } from "../../editor/AccountEditor";
import { useDashboardStore } from "../../pages/hooks/useDashboardStore";




export const Historialdetramite = () => {

    const single = Singleton.getInstance()
    const { setLoading } = useDashboardStore();
    const service = new ContractService();
    const adminService = new AdminService();
    const bpmService = new BpmService();
    const [lista, setLista] = useState([]);
    const [listaType, setListaType] = useState([]);
    const [permisos, setPermisos] = useState([]);
    const [indexTap, setIndexTap] = useState(0);
    const [fromYear, setFromYear] = useState('');

    const [nit, setNit] = useState(0);
    const [uptoYear, setUptoYear] = useState('');
    const [vista, setvista] = useState(0);
    const { register, formState: { errors }, handleSubmit } = useForm();
    const [bean, setBean] = useState<any>({});
    const [modal, setModal] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modal1, setModal1] = useState<any>({ name: 'Configuración Plan', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-xl' });

    const [modalConfirm, setModalConfirm] = useState<any>({ name: 'Confirmar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });


    const idBean = 'IDProcedureImp';


    const head = [
        { title: 'ID', property: idBean },
        { title: 'fecha', property: 'Since' },
        { title: 'Nombre Proceso', property: 'BusinessProcessName' },
        { title: 'Nombre ususario', property: 'AccountName' },
        { title: 'Tipo Caracterización', property: 'CharacterizationName' },
        { title: 'Estado', property: 'StateName' },

        // { title: 'Estado', property: 'Estados' },
    ]

    // Se realiza la impelmentacion de la historia de usuario HU04 componente 67


    //cambio para la capacitacion
    const setRowBean = (bean: any) => {

        setBean(bean);

    }

    const getListaBN = () => {
        setLoading(true);
        service.getAccountByNit(nit).subscribe(
            (resp: any) => {

                if (resp.DataBeanProperties.ObjectValue) {
                    setLista(resp.DataBeanProperties.ObjectValue);
                }
                setLoading(false);
            }
        );
    }

    const getLista = () => {
        setLoading(true);
        service.getProcedureImpByAccount(null, bean.IDAccount, bean.RequiresFromDate, bean.RequiresUptoDate, null, 0, null).subscribe(
            (resp: any) => {

                if (resp.DataBeanProperties.ObjectValue) {
                    setLista(resp.DataBeanProperties.ObjectValue.DataBeanProperties.List);
                }
                setLoading(false)
            }
        );
    }



    const agregarItem = () => {
        let date = new Date();
        let b = {
            Year: date.getFullYear(),
            Description: '',
            TotalValue: 0,
            IDBudgetType: 0
        }

        setBean(b);
        setModal({ ...modal, ['view']: true });

    }
    const editarItem = () => {

        setModal({ ...modal, ['view']: true });

    }


    const updateItemModal = () => {

        setModal1({ ...modal1, "view": false });

        getLista();

    }











    const iconButtons = [
        { nombre: 'Agregar', visible: single.canCreate(), icono: 'ri-file-add-line', evento: agregarItem }
    ]
    const listaBotones = () => {
        const botones = [

            { titulo: 'Editar', icono: 'ri-file-list-line', visible: true, evento: () => { setModal({ ...modal, "view": true }) } },
            { titulo: 'Eliminar', icono: 'ri-file-user-line', visible: true, evento: () => { setModalConfirm({ ...modalConfirm, "view": true }) } },


        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }



    return (
        <>
            <BasicPage titulo="HISTORIAL DE TRAMITES">


                <>
                    <div className="row">
                        <div className="col-md-2">
                            <label>fecha inicial</label>
                            <InputDate label="Desde" name="Desde" value={bean.RequiresFromDate} setDate={(e: any) => { setBean({ ...bean, 'RequiresFromDate': e }) }} ></InputDate>

                        </div>
                        <div className="col-md-2">
                            <label>fecha Final</label>
                            <InputDate label="Desde" name="Desde" value={bean.RequiresUptoDate} setDate={(e: any) => { setBean({ ...bean, 'RequiresUptoDate': e }) }} ></InputDate>

                        </div>
                        <div className="col-md-6">
                            <label>Usuario</label>
                            {/* <input type="text" className="form-control" value={bean.Name} onChange={(e)=>{setBean({...bean,['Name']:e.target.value})}}/> */}
                            <AccountEditor idAccount={bean.IDAccount} label="Funcionario" onChange={({ IDAccount }: any) => { setBean({ ...bean, "IDAccount": IDAccount }) }} />
                            {/* <input type="text" onChange={(e: any) => setNit(e.target.value)} className="form-control inputNit " placeholder="Filtrar por Cedula / Nit" /> */}
                        </div>

                        <div className="col-md-2 mt-4">
                            <BasicButton value="Buscar" icon="ri-search-line" eventClick={getLista} />
                        </div>
                        <div className="col-md-12 p-2">
                            <BasicTable iconButtons={iconButtons} listButtons={listaBotones()} head={head} body={lista} rowSet={setRowBean}></BasicTable>
                        </div>
                    </div>
                </>



            </BasicPage>








        </>
    )
}