import { useEffect, useRef, useState } from "react";
import { _RemoteAbstractAction, _single } from "../../../core/services/dataBean/EntityCatalog";
import useDataBean from "../../../core/services/dataBean/useDataBean";
import { AccountEditor } from "../../editor/AccountEditor";
import { BasicSwitch } from "../../theme/Component/BasicSwitch";
import { FileInput } from "../../theme/Component/FileInput";
import { PrintJson } from "../../theme/Component/PrintJson";
import { InputDate } from "../../theme/Input/InputDate";
import { BasicButton } from "../../theme/Component/BasicButton";
import { toast } from "react-toastify";
import { GenericSelect } from "../../RemoteAbstractAction/editors/GenericSelect";
import { ProjectSelect } from "../../RemoteAbstractAction/editors/ProjectSelect";
import { SelectAccountantTree } from "../../contabilidad/catalogo-cuentas/AccountantTree/SelectAccountantTree";
import { SelectorDocumentFactory } from "../../contabilidad/bodegaDocumentos/SelectorDocumentFactory";
import { CostingCenterSelect } from "../../erp/cellEditor/CostingCenter/CostingCenterSelect";
import { SelectFunctionalID } from "../../admin/configuracion/functionalID/SelectFunctionalID";
import { SelectBusinessCore } from "../../erp/cellEditor/BusinessCore/SelectBusinessCore";
import useModal, { MODAL_LG, MODAL_md } from "../../theme/Component/hooks/useModal";
import { Modal } from "../../theme/Component/Modal";
import { env } from "../../../env";
import { AbstractStep } from "./AbstractStep";
import { RemoteTable } from "./RemoteTable";
import { Alert } from "../../theme/Component/Alert";


interface Props {
    idApplicationInvoker: any;
    fieldList: any;
    data: any;
    setData: any;
    useAsForm: boolean;
    propertyName?: string;
}
export const RemoteForm = ({ idApplicationInvoker, fieldList, data, propertyName, setData, useAsForm }: Props) => {

    const { instance } = useDataBean(_RemoteAbstractAction);
    const [bean, setBean] = useState<any>([]);
    const [required, setRequired] = useState<any>([]);
    const [events, setEvents] = useState<any>([]);
    const [errList, setErrList] = useState<any>([]);
    const [formValid, setFormValid] = useState<boolean>(true);
    const [campos, setCampos] = useState<any>([]);
    const containerRef = useRef<HTMLDivElement>(null);
    const modalStructure = useModal("Estructura", MODAL_LG);
    const modalDatos = useModal("Datos", MODAL_md);
    const _printLog = env.PRINT_LOG;


    useEffect(() => {

        if (fieldList) {
            const rows = Object.keys(fieldList);
            let filas = [];
            rows.forEach(fila => {
                filas.push(
                    {
                        ...fieldList[fila], "IDProperty": fila, "LineNumber": fieldList[fila].LineNumber ?? 1
                    }
                )
            });

            // obtener las propiedades obligatorias
            if (useAsForm) {
                setRequired(
                    filas.filter((item) => item.Required && item.VisibleOnTableForm).map((item) => item.IDProperty)
                );

            } else {

                setRequired(
                    filas.filter((item) => item.Required).map((item) => item.IDProperty)
                );
            }
            let eve = {};
            filas.forEach(element => {
                if (element.EventName) {
                    eve[element.IDProperty] = element.EventName;
                }

            });


            setEvents(eve);


            //ordenar y asignar 
            setCampos(filas.sort((a, b) => a.LineNumber - b.LineNumber));
        }
        return ()=>{setBean({})}

    }, [])

    useEffect(() => {
        //asignar en el bean las propiedades que tiene valor por defecto
        setBean(
            campos
                .filter((item) => item.DefaultValue)
                .reduce((acc, item) => {
                    acc[item.IDProperty] = item.DefaultValue;
                    return acc;
                }, {})
        );
    }, [campos])

    const fireEvent = (evento: string, data: any) => {

        instance.fireAbstractStepEvent(idApplicationInvoker, evento, data).then(
            (resp: any) => {
                if (resp) {
                    setBean({ ...data, ...resp });

                }
            }
        ).catch(err => toast.error(err));

    }


    const submitForm = () => {

        let pendiente = 0;
        let cam = '';
        required.forEach(element => {
            //console.log(element, bean[element]);
            if (bean[element] === undefined || bean[element] === null) {
                cam +=' ,'+element;
                pendiente++;
            }
        });
        if (pendiente > 0)
            toast.error("Faltan Campos por diligenciar "+cam);
        else {
            //mover aqui las validaciones cuando se validen campos
        }
        const userConfirmed = window.confirm("¿Estás seguro de que deseas continuar esta acción? faltan campos : "+cam);
        if(userConfirmed)
        if (useAsForm) {
            instance.validateAbstractStepTableRow(idApplicationInvoker, propertyName, bean, 0, _single.getAccountID()).then(
                (resp: any) => {
                    if (resp.Validated) {
                        setData(bean);
                        // setFormValid(true);
                    } else if (resp.MsgList) {
                        setErrList(resp.MsgList);
                    }
                }
            ).catch(err => toast.error(err))
        }else{
            instance.validateAbstractStepProperties(idApplicationInvoker, bean,  _single.getAccountID()).then(
                (resp: any) => {
                    if (resp.Validated) {
                        setData(bean);
                        // setFormValid(true);
                    } else if (resp.MsgList) {
                        setErrList(resp.MsgList);
                    }
                }
            ).catch(err => toast.error(err))

        }

    }



    const handleChange = (e) => {

        let copy = bean;
        copy[e.target.name] = _single.getValueInput(e);

        if (events[e.target.name]) {
            fireEvent(events[e.target.name], copy);
        } else {
            setBean(copy);
        }




        // setBean((prev) => _single.handleFormChange(prev, e));
    };

    const renderInput = (editor: any) => {

        let caso = editor.EditorObjectClassForName ? editor.EditorObjectClassForName : editor.ReturnClassForName;

        switch (caso) {
            case 'java.util.List':
                return (
                    <RemoteTable propertyName={editor.IDProperty} idApplicationInvoker={idApplicationInvoker} fieldList={editor.TableStructureObject} data={{}} setData={(e) => { setBean({ ...bean, [editor.IDProperty]: e }) }} />
                )
            case 'com.quickdataerp.bean.accountant.BusinessCore':
                return (
                    <SelectBusinessCore returnType="Object" idSelector={bean[editor.IDProperty]} label={editor.Name} onChange={(e) => { setBean({ ...bean, [editor.IDProperty]: e[editor.EditorObjectPropertyName] }) }} />
                )
            case 'com.orange.bean.functional.FunctionalID':
                return (
                    <SelectFunctionalID label={editor.Name} onChange={(e) => { setBean({ ...bean, [editor.IDProperty]: e[editor.EditorObjectPropertyName] }) }} />
                )
            case 'com.quickdataerp.bean.accountant.CostingCenter':
                return (
                    <CostingCenterSelect returnType="Object" label={editor.Name} onChange={(e) => { setBean({ ...bean, [editor.IDProperty]: e[editor.EditorObjectPropertyName] }) }} />
                )
            case 'documents.DocumentFactory':
                return (
                    <SelectorDocumentFactory label={editor.Name} onChange={(e) => { setBean({ ...bean, [editor.IDProperty]: e[editor.EditorObjectPropertyName] }) }} />
                )
            case 'com.quickdataerp.bean.accountant.AccountantID':
                return (
                    <SelectAccountantTree label={editor.Name} onChange={(e) => { setBean({ ...bean, [editor.IDProperty]: e[editor.EditorObjectPropertyName] }) }} />
                )
            case 'com.orange.bean.Project':
                return (
                    <ProjectSelect label={editor.Name} idSelect={bean[editor.IDProject]} name={editor.IDProperty} onChange={handleChange} />
                )
            case 'com.advantage.bean.account.AbstractAccount':
                return (
                    <AccountEditor idAccount={bean[editor.IDProperty]} label={editor.Name} onChange={(e) => { setBean({ ...bean, [editor.IDProperty]: e[editor.EditorObjectPropertyName] }) }} />
                )
            case 'java.lang.Boolean':
                return (
                    <BasicSwitch label={editor.Name} estado={bean[editor.IDProperty]} eventChange={(e) => { setBean({ ...bean, [editor.Name]: e }) }} />
                )
            case 'java.util.Date':
                return (
                    <InputDate label={editor.Name} name={editor.IDProperty} value={bean[editor.IDProperty]} setDate={(e) => { setBean({ ...bean, [editor.IDProperty]: e }) }} />
                )
            case 'java.io.File':
                return (
                    <FileInput directory="temp" label={editor.Name} onCreate={({ Media }: any) => { setBean({ ...bean, [editor.IDProperty]: Media }) }} />
                )
            case 'java.lang.Double':
                return (
                    <>
                    <label>{editor.Name} </label>
                    <input type="number" className="form-control" name={editor.IDProperty} value={bean[editor.IDProperty]} onChange={handleChange} />
                    </>
                )
            case 'java.lang.Number':
                return (
                    <>
                        {editor.Options ?
                            <>
                                <label>{editor.Name} </label>
                                <GenericSelect idSelect={bean[editor.IDProperty]} name={editor.IDProperty}
                                    onChange={handleChange}
                                    // onChange={(e)=>{
                                    //     handleChange(e);
                                    //     if(editor.EventName)
                                    //     {
                                    //         setTimeout(() => {
                                    //             fireEvent(editor.EventName,bean);
                                    //         }, 500);
                                    //     }
                                    // }
                                    // }
                                    lista={editor.Options} />
                            </>
                            :
                            <>
                                <label>{editor.Name} </label>
                                <input type="number" className="form-control" name={editor.IDProperty} value={bean[editor.IDProperty]} onChange={handleChange} />
                            </>
                        }
                    </>
                )
            default:
                return (
                    <>
                        <label>{editor.Name}</label>
                        <input type="text" className="form-control" name={editor.IDProperty} value={bean[editor.IDProperty]} onChange={handleChange} />
                    </>
                )
        }
    }



    return (
        <div className="container">
            <div className="row">
                {/* <PrintJson json={bean} /> */}
                {_printLog &&
                    <div className="col-md-12">
                        <button type="button" title="Estructura" className="btn btn-outline-primary btn-icon waves-effect waves-light shadow-none" onClick={() => { modalStructure.open() }}><i className="ri-layout-5-fill"></i></button>
                        <button type="button" title="Datos" className="btn btn-outline-primary btn-icon waves-effect waves-light shadow-none" onClick={() => { modalDatos.open() }}><i className="ri-code-fill"></i></button>
                    </div>
                }
                <div className="col-md-12">
                    {errList.length == 0 ?
                        <>
                            <div className="row" ref={containerRef}>
                                {campos.map((campo: any, index: number) => {
                                    return (
                                        <div key={index} className={`col-md-${campo.GridSize ?? 6}`} lang={campo.ReturnClassForName}>
                                            {renderInput(campo)}
                                        </div>
                                    )
                                })}
                            </div>
                            <div className="row">
                                <div className="col-md-6 mt-4">
                                    {campos.length > 0 &&
                                        <BasicButton disable={!formValid} icon="ri-send-plane-2-line" clase="primary" eventClick={submitForm}>{!useAsForm ? 'Siguiente' : 'Guardar'}</BasicButton>
                                    }
                                </div>
                            </div>
                        </>
                        :
                        <div className="row">
                            <div className="col">
                                <Alert clase="warning" >
                                    <ul className="list-group">
                                        {errList.map((item: any) => {
                                            return (
                                                <li className="list-group-item">{item}</li>
                                            )
                                        })}
                                    </ul>
                                </Alert>
                            </div>
                        </div>
                    }
                </div>
                <div className="col-md-12">
                    <Modal modal={modalStructure.modal} updateModal={modalStructure.setModal}>
                        {/* <PrintJson json={campos} /> */}
                        <AbstractStep fieldList={fieldList} />
                        <hr />
                        
                    </Modal>
                    <Modal modal={modalDatos.modal} updateModal={modalDatos.setModal}>
                        <PrintJson json={bean} />
                    </Modal>
                </div>
            </div>
        </div>
    )
}