import { useEffect, useState } from "react";
import { AdminService } from "../../../core/services/AdminService";
import { Singleton } from "../../../core/services/Singleton";
import { Modal } from "../../theme/Component/Modal";
import { MenuPerfil } from "../perfil/MenuPerfil";
import { BasicTable } from "../../theme/Component/BasicTable";
import { ListMenu } from "../../theme/Component/ListMenu";
import { UserRol } from "../perfil/UserRol";
import { ModalConfirm } from "../../theme/Component/ModalConfirm";
import { BasicSwitch } from "../../theme/Component/BasicSwitch";
import { DropDown } from "../../theme/Component/DropDown";
import { toast } from "react-toastify";
import useDataBean from "../../../core/services/dataBean/useDataBean";
import { _Role, _RoleGroupItem } from "../../../core/services/dataBean/EntityCatalog";
import { BasicPage } from "../../theme/Component/BasicPage";


interface Props {
  groupRole?: any;
  roleList?: any;
  _onChange?: Function;
  _superU?:boolean;
}

export const SystemRoles = ({ roleList, groupRole, _onChange,_superU }: Props) => {

  const adminService = new AdminService();
  const single = Singleton.getInstance();
  const _role = useDataBean(_Role);
  const _roleGroupItem = useDataBean(_RoleGroupItem);

  const [groupJson, setGroupJson] = useState<[]>(null)

  const [modalConfirm, setModalConfirm] = useState<any>({ name: 'Confirmar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });
  const [modal1, setModal1] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
  const [modal2, setModal2] = useState<any>({ name: 'Menu', tabIndex: -1, view: false, btnClose: true, btnSubmit: false, format: 'modal-lg' });
  const [modalUsr, setModalUsr] = useState<any>({ name: 'Usuarios', tabIndex: -1, view: false, btnClose: true, btnSubmit: false, format: 'modal-lg' });

  useEffect(() => {
    if (!(groupRole?.IDRoleGroups))
      getRoleCatalog();
  }, [])

  useEffect(() => {
    if (roleList) {
      getRoleCatalog();
    }
  }, [roleList]);

  useEffect(() => {
    if (groupRole && groupRole.IDRoleGroups) {
      if (groupRole.JsonProcess) {
        setGroupJson(JSON.parse(groupRole.JsonProcess));
      } else {
        toast.error("No presenta permisos");
      }
    } else if (groupRole && !groupRole.IDRoleGroups) {
      toast.error("Hubo un inconveniente");
    }
  }, [groupRole]);

  const getRoleCatalog = () => {
    _role.instance.getRoleCatalog(null).then(
      (resp) => {
        let roles = resp;
       //console.log(roleList)
        if (roleList) {
          if (roleList.length >= 0) {
            const newList = roles.filter(item => roleList.includes(item.DataBeanProperties.IDRole));
            _role.setLista(newList)
          } else {
            _role.setLista([])
          }
        } else {
          _role.setLista(roles);
        }
      }
    ).catch(
      err => {
        toast.error(err)
      }
    )
  };

  const updateRoleFunction = (update: number) => {
    //1 editar - 2 eliminar
   //console.log(_role.bean)
    _role.instance.updateRole(_role.bean).then(
      (resp: any) => {
       //console.log("Respuesta del servicio", resp)
        setModal1({ ...modal1, 'view': false });
        setModalConfirm({ ...modalConfirm, 'view': false });
        if (groupRole?.IDRoleGroups && update === 1) {
          addRoleToRoleGroup(resp.IDRole);
        } else {
          getRoleCatalog();
        }
      }).catch(
        err => {
          toast.error(err)
        }
      );
  };

  const addRoleToRoleGroup = (idRole: number) => {
    _roleGroupItem.updateCustonBean({
      IDRoleGroups: groupRole.IDRoleGroups,
      IDEmployee: single.getAccountID(),
      State: 1,
      IDRole: idRole,
    }).then(
      (resp) => {
       //console.log(resp)
        _onChange();
      }
    ).catch(
      err => {
        toast.error(err)
      }
    )
  }

  const deleteRole = () => {
    _role.instance.deleteRole(_role.bean).then(
      (resp: any) => {
        setModalConfirm({ ...modalConfirm, 'view': false });
        getRoleCatalog();
      }
    ).catch(
      err => {
        toast.error(err)
      }
    );
  }

  const agregarItem = () => {
    _role.setBean({
      Name: '',
      Description: '',
      State: 1,
      Since: single.getCurrenDate(),
      IsOpenRole: false,
    })
    setModal1({ ...modal1, 'view': true });
  }

  const head = [
    { title: 'ID', property: 'IDRole' },
    { title: 'Nombre', property: 'Name' },
    { title: 'Descripción', property: 'Description' },
    { title: 'Por defecto', property: 'IsOpenRole' },
    { title: 'Estado', property: 'StateName' },
  ]

  const listaBotones = () => {
    const botones = [
      { titulo: 'Editar', icono: 'ri-edit-2-line', visible: single.canEdit() || _superU, evento: () => { setModal1({ ...modal1, "view": true }) } },
      { titulo: 'Eliminar', icono: 'ri-delete-back-2-line', visible: single.canDelete()  || _superU, evento: () => { setModalConfirm({ ...modalConfirm, "view": true }) } },
      { titulo: 'Configurar Funciones', icono: 'ri-file-list-line', visible: true, evento: () => { setModal2({ ...modal2, "view": true, "name": `Funciones de ${_role.bean.Name}` }); } },
      { titulo: 'Usuario en el Rol', icono: 'ri-file-user-line', visible: true, evento: () => { setModalUsr({ ...modalUsr, "view": true, "name": "Rol: " + _role.bean.Name }) } },
    ]
    return (
      <ListMenu listaBotones={botones} />
    )
  }

  const eventChange = (e: any) => {

    if (e.id === 1) {
      adminService.clearUserMenuCache(0).subscribe(
        (resp: any) => {
          toast.success("Cahce actualizada.");
        }
      )
    }

  }

  const iconButtonsItem = [
    { nombre: 'Agregar', visible: single.canCreate() || _superU, icono: 'ri-file-add-line', evento: agregarItem }
  ]

  const actionsDropDown = [
    { id: 1, name: 'Borrar Cache', permiso: null, activo: true },
  ];
  const buttons = () => {
    return (
      <div className="row">
        <div className="col-md-12">
          <DropDown lista={actionsDropDown} eventClick={eventChange}></DropDown>
        </div>
      </div>
    )
  }

  return (
    <>
      {roleList ?
        <div className="row mt-2">
          <BasicTable headButtons={buttons()} listButtons={listaBotones()} iconButtons={iconButtonsItem} head={head} body={_role.lista} rowSet={_role.setBean} />
        </div>
        :
        <BasicPage titulo="Roles de Sistema">
          <div className="row">
            <div className="col-md-12">
              <BasicTable headButtons={buttons()} listButtons={listaBotones()} iconButtons={iconButtonsItem} head={head} body={_role.lista} rowSet={_role.setBean}></BasicTable>
            </div>
          </div>
        </BasicPage>
      }

      {modal1.view &&
        <Modal modal={modal1} updateModal={setModal1} eventModal={() => { updateRoleFunction(1) }}>
          <div className="row">
            <div className="col-md-6">
              <label >Nombre</label>
              <input type="text" className="form-control" name="Name" value={_role.bean.Name} onChange={_role.handleChange} />
            </div>
            <div className="col-md-6">
              <label>Estado</label>
              <select className="form-select" value={_role.bean.State ?? undefined} onChange={(e) => { _role.setBean({ ..._role.bean, "State": e.target.value }) }}>
                <option value={0}>ACTIVO</option>
                <option value={1}>INACTIVO</option>
              </select>
            </div>
            <div className="col-md-12">
              <label >Descripción</label>
              <textarea name="Description" className="form-control" value={_role.bean.Description} onChange={_role.handleChange}></textarea>
            </div>
            {!(groupRole?.IDRoleGroups) &&
              <div className="col-md-6">
                <BasicSwitch estado={_role.bean.IsOpenRole ?? false} eventChange={(e) => { _role.setBean({ ..._role.bean, 'IsOpenRole': e }) }} label="Rol por defecto"></BasicSwitch>
              </div>
            }
          </div>
        </Modal>
      }

      {modalConfirm.view &&
        <ModalConfirm modal={modalConfirm} updateModal={setModalConfirm} eventModal={() => { deleteRole() }}>
          <p>Eliminar Rol {_role.bean.Name}</p>
        </ModalConfirm>
      }

      {modal2.view &&
        <Modal modal={modal2} updateModal={setModal2}>
          <div className="row">
            <div className="col">
              {groupJson ?
                <MenuPerfil _JsonProcess={groupJson} _role={_role.bean} />
                :
                <MenuPerfil _role={_role.bean} />
              }
            </div>
          </div>
        </Modal>
      }

      {modalUsr.view &&
        <Modal modal={modalUsr} updateModal={setModalUsr}>
          <UserRol IDRol={_role.bean.IDRole} />
        </Modal>
      }
    </>
  );
};
