import React, { useState, useEffect } from 'react';
import { BpmService } from '../../../../core/services/BpmService';

interface Props {
    idSelector: number;
    onChange: any;
}

export const Selectortipodeformulario = ({ idSelector, onChange }: Props) => {
    const service = new BpmService();
    const [lista, setLista] = useState<any[]>([]);
    const [id, setID] = useState<number | null>(null);

    useEffect(() => {
        getLista();
       //console.log(idSelector)
        if(idSelector>=0){
            setID(idSelector) 
        }
    }, [idSelector]);

    const setValue = (e: any) => {
        const selectedId = parseInt(e);
        setID(selectedId);
        onChange(selectedId);
    };

    const getLista = () => {
        service.getFormClassCatalog(null).subscribe(
            (resp: any) => {
                setLista([]);
                if (resp.DataBeanProperties.ObjectValue) {
                    setLista(resp.DataBeanProperties.ObjectValue);
                }
               //console.log("DataAcces", lista);
            }
        );
    };

    return (
        <>
            <label className='mb-2'>Tipos de Formularios</label>
            <select className="form-control" value={id || ''} onChange={(e) => setValue(e.target.value)}>
                <option value={null}>...</option>
                {lista &&
                    lista.map((item: any) => (
                        <option key={item.DataBeanProperties.IDFormClass} value={item.DataBeanProperties.IDFormClass}>{item.DataBeanProperties.Name}</option>
                    ))
                }
            </select>
        </>
    );
};
