import { BasicTable } from "../../../../theme/Component/BasicTable"
import { Singleton } from "../../../../../core/services/Singleton";
import { ListMenu } from "../../../../theme/Component/ListMenu";
import { useEffect, useState } from "react";
import { Modal } from "../../../../theme/Component/Modal";
import { BasicSwitch } from "../../../../theme/Component/BasicSwitch";
import { BpmService } from "../../../../../core/services/BpmService";
import { SelectJsonDataTypes } from "../../../../theme/Component/SelectJsonDataTypes";
import { useDashboardStore } from "../../../../pages/hooks/useDashboardStore";

interface Props {
    idJasonS: number,
    JsonName: string,
}

export const VarResponseJson = ({ idJasonS, JsonName }: Props) => {

    const { setLoading } = useDashboardStore();
    const single = Singleton.getInstance()
    const service = new BpmService();
    const [lista, setLista] = useState([]);
    const [bean, setBean] = useState<any>({});
    const [modalConfirm, setModalConfirm] = useState<any>({ name: 'Confirmar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });
    const [modal, setModal] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-md' });
    const idBean = 'IDResponseValue';

    const setRowBean = (bean: any) => {
       //console.log("Row : ", bean);
        setBean(bean);
    }

    useEffect(() => {
        getLista()
    }, [idJasonS])

    useEffect(() => {
       //console.log(bean.ResponseClass)
    }, [bean.ResponseClass])

    const agregarItem = () => {

        let b = {
            Description: "",
            ErrorMessage: "",
            IDJsonService: idJasonS,
            JsonServiceName: JsonName,
            LimitedValues: "",
            LimitedWithValues: false,
            Name: "",
            ResponseClass: "",
            State: 1,
            Validated: true,
        }
        setBean(b);
        setModal({ ...modal, ['view']: true });
    }

    const getLista = () => {
        setLoading(true);

        service.getResponseValueForJsonService(idJasonS).subscribe(
            (resp: any) => {
                setLoading(false);
                if (resp.DataBeanProperties.ObjectValue) {
                    setLista(resp.DataBeanProperties.ObjectValue)
                }
            }
        );
    }

    const updateItem = () => {
        setModal({ ...modal, ['view']: false });
        setLoading(true);
        service.addResponseValue(bean).subscribe(
            (resp: any) => {
                setLoading(false);
                if (resp.DataBeanProperties.ObjectValue) {
                    getLista();
                }
                single.update(bean);
            });
       //console.log(bean)
    }
    const eliminarItem = () => {
        setModal({ ...modal, ['view']: false });
    }

    const head = [
        { title: 'ID', property: idBean },
        { title: 'Nombre', property: 'Name' },
        { title: 'Tipo de respuesta', property: 'ResponseClass' },
        { title: 'Se limito la respuesta?', property: 'LimitedWithValues' },
        { title: 'Valores', property: 'LimitedValues' },
    ]


    const listaBotones = () => {
        const botones = [
            { titulo: 'Editar', icono: 'ri-file-list-line', visible: true, evento: () => { setModal({ ...modal, "view": true }) } },
            { titulo: 'Eliminar', icono: 'ri-file-user-line', visible: true, evento: () => { setModalConfirm({ ...modalConfirm, "view": true }) } },
        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }

    const iconButtonsItem = [
        { nombre: 'Agregar', visible: single.canCreate(), icono: 'ri-file-add-line', evento: agregarItem }
    ]

    return (
        <>
            <BasicTable listButtons={listaBotones()} iconButtons={iconButtonsItem} body={lista} head={head} rowSet={setRowBean} ></BasicTable>

            <Modal modal={modal} updateModal={setModal} eventModal={updateItem}>
                <div className="row">
                    <div className="col-md-12">
                        <label className="form-label">Nombre</label>
                        <input type="text" className="form-control" value={bean.Name} onChange={(e) => { setBean({ ...bean, ['Name']: e.target.value }) }} />
                    </div>
                    <div className="col-md-8">
                        <SelectJsonDataTypes label="Clase de respuesta"
                            _onChange={(e: any) => { setBean({ ...bean, ['ResponseClass']: e.target.value }) }}
                            valor={bean.ResponseClass}
                        ></SelectJsonDataTypes>
                    </div>
                    {(bean.ResponseClass == 'Number' || bean.ResponseClass == 'String') &&
                        <div className="col-md-4">
                            <label>Limitar valores</label>
                            <BasicSwitch estado={bean.LimitedWithValues} eventChange={(e: any) => {
                                setBean({ ...bean, "LimitedWithValues": e })
                            }}></BasicSwitch>
                        </div>
                    }
                    {bean.LimitedWithValues &&
                        <>
                            <div className="col-md-12">
                                <label className="form-label">Valores Cerrados</label>
                                <textarea className="form-control" value={bean.LimitedValues} onChange={(e) => { setBean({ ...bean, ['LimitedValues']: e.target.value }) }}></textarea>
                            </div>
                            <div className="col-md-12">
                                <label className="form-label">Formato</label>
                                <textarea className="form-control" value={bean.Description} onChange={(e) => { setBean({ ...bean, ['Description']: e.target.value }) }}></textarea>
                            </div>
                        </>
                    }
                </div>
            </Modal>
        </>
    )
}