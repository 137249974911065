import { useEffect, useState } from "react";
import { AdminService } from "../../../core/services/AdminService";
import { Singleton } from "../../../core/services/Singleton";
import { Modal } from "../../theme/Component/Modal";
import { BasicTable } from "../../theme/Component/BasicTable";
import { BasicPage } from "../../theme/Component/BasicPage";
import { ListMenu } from "../../theme/Component/ListMenu";
import { ModalConfirm } from "../../theme/Component/ModalConfirm";
import { BasicSwitch } from "../../theme/Component/BasicSwitch";
import { useDashboardStore } from "../../pages/hooks/useDashboardStore";
import { SelectRol } from "../select/SelectRol";

interface Props {
  idRoleGroup: number;
}

export const RoleGroupItem = ({ idRoleGroup }: Props) => {

  const single = Singleton.getInstance()
  const adminService = new AdminService();
  const { setLoading } = useDashboardStore();
  const [lista, setLista] = useState([]);
  const [bean, setBean] = useState<any>({});
  const [modal, setModal] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });
  const [modalConfirm, setModalConfirm] = useState<any>({ name: 'Confirmar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });

  useEffect(() => {
    if (idRoleGroup >= 0) {
      getLista();
    }
  }, [idRoleGroup])

  const getLista = () => {
    let prop = {
      IDRoleGroups: idRoleGroup,
      State: 1,
    }
    setLoading(true);
    adminService.getRoleGroupsItemsCatalogRender(null, prop).subscribe(
      (resp: any) => {
       //console.log(resp)
        if (resp.DataBeanProperties.ObjectValue) {
          setLista(resp.DataBeanProperties.ObjectValue);
        }
        setLoading(false);
      }
    );
  };

  const updateItem = () => {
    setLoading(true);
    adminService.updateRoleGroupsItems(bean).subscribe(
      (resp: any) => {
        if (resp.DataBeanProperties.ObjectValue) {
          getLista();
          if (bean.IDRoleGroupsItems) {
            single.update(bean)
          } else {
            single.create(bean)
          }
        }
        setModal({ ...modal, 'view': false });
        setLoading(false);
      }
    );
  }

  const deleteItem = () => {
    bean.State = 2;
   //console.log(bean);
    updateItem();
    setModalConfirm({ ...modalConfirm, 'view': false });
  }

  const agregarItem = () => {
    let b = {
      IDRoleGroups: idRoleGroup,
      IDRole: undefined,
      State: 1,
      IDEmployee: single.getAccountID(),
    }
    setBean(b);
    setModal({ ...modal, 'view': true });
  }

  const head = [
    { title: 'ID', property: 'IDRoleGroupsItems' },
    { title: 'Rol', property: 'Name' },
    { title: 'fecha de creacion', property: 'Since' },
  ]

  const listaBotones = () => {
    const botones = [
      { titulo: 'Editar', icono: 'ri-edit-2-line', visible: single.canEdit(), evento: () => { setModal({ ...modal, "view": true }) } },
      { titulo: 'Eliminar', icono: 'ri-delete-back-2-line', visible: single.canDelete(), evento: () => { setModalConfirm({ ...modalConfirm, "view": true }) } },
    ]
    return (
      <ListMenu listaBotones={botones} />
    )
  }

  const iconButtonsItem = [
    { nombre: 'Agregar', visible: single.canCreate(), icono: 'ri-file-add-line', evento: agregarItem }
  ]

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <BasicTable listButtons={listaBotones()} iconButtons={iconButtonsItem} head={head} body={lista} rowSet={setBean}></BasicTable>
        </div>
      </div>

      {modal.view &&
        <Modal modal={modal} updateModal={setModal} eventModal={updateItem}>
          <div className="row">
            <SelectRol idSelector={bean.IDRole} onChange={(e: any) => { setBean({ ...bean, "IDRole": e }) }}></SelectRol>
          </div>
        </Modal>
      }

      {modalConfirm.view &&
        <ModalConfirm modal={modalConfirm} updateModal={setModalConfirm} eventModal={deleteItem}>
          <p>¿Eliminar rol {bean.IDRole} del grupo?</p>
        </ModalConfirm>
      }
    </>
  );
};
