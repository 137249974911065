import { useState, useEffect } from 'react';
import { FinanceService } from '../../../../core/services/FinanceService';
import useDataBean from '../../../../core/services/dataBean/useDataBean';
import { _BudgetID } from '../../../../core/services/dataBean/EntityCatalog';

interface Props {
    idSelector: number;
    onChange: any;
    year: number;
    label?: string;
}
export const SelectExpensiveBudgetIDType = ({ idSelector, onChange, label, year }: Props) => {

    const { instance } = useDataBean(_BudgetID)

    const [obj, setObj] = useState<any>({})

    const [id, setID] = useState(NaN);

    useEffect(() => {


        setID(idSelector);
        getLista();

    }, [])

    const setValue = (e: any) => {
        setID(parseInt(e));
        // onChange(id);
    }

    const getLista = () => {
        instance.getExpensivesBudgetIDType(year ?? null).then(
            (resp: any) => {
                console.log(resp)
                onChange(resp.IDBudgetIDType);
                setObj(resp)
            }
        ).catch(err => { console.log(err) })
    }

    return (
        <>
            <label>{label ?? "Tipos de Catálogo Presupuestal"}</label>
            <div className="form-control text-wrap" style={{ minHeight: '35px' }}>
                <span >{`${obj.Name}`}</span>
            </div>
        </>
    )
}