import { useEffect, useState } from "react";
import { _single } from "../../../core/services/dataBean/EntityCatalog";
import { YearSelector } from "../../../core/shared/inputs/YearSelector";
import { SelectBudgetType } from "../../admin/configuracion/select/SelectBudgetType";
import { InputDate } from "../../theme/Input/InputDate";

interface Props {
    onChange: Function;
}

export const FormBudgetIDStr = ({ onChange }: Props) => {

    const [response, setResponse] = useState<any>({
        FormDate: _single.getCurrenDate(),

    });

    useEffect(() => {
        onChange(response)
    }, [response])

    return (
        <div className="row">
            <div className="col-md-6">
                <label>Fecha Inicial</label>
                <InputDate setDate={(e) => {
                    setResponse
                        ({ ...response, "FormDate": e })
                }} name="Fecha" value={response.FormDate} />
            </div>
            <div className="col-md-6">
                <SelectBudgetType idSelector={response.IDBudgetType} onChange={(e) => { setResponse({ ...response, "IDBudgetType": e }) }} />
            </div>
            <div className="col-md-12">
                <textarea className="form-control" value={response.Description} onChange={(e) => { setResponse({ ...response, ['Description']: e.target.value }) }}></textarea>
            </div>
        </div>
    )
}