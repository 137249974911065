import { useEffect, useState } from "react";
import { _CompetitionType, _EmployeeDiscountType, _EmployeePayments, _Entailment, _MacroProcess } from "../../../../core/services/dataBean/EntityCatalog";

import { Singleton } from "../../../../core/services/Singleton";
import { toast } from "react-toastify";
import { ListMenu } from "../../../theme/Component/ListMenu";
import { BasicPage } from "../../../theme/Component/BasicPage";
import { BasicTable } from "../../../theme/Component/BasicTable";
import { Modal } from "../../../theme/Component/Modal";
import { ModalConfirm } from "../../../theme/Component/ModalConfirm";
import useDataBean from "../../../../core/services/dataBean/useDataBean";
import { SelectAccountantTree } from "../../../contabilidad/catalogo-cuentas/AccountantTree/SelectAccountantTree";

import { SelectBudgetID } from "../../../admin/configuracion/BudgetID/SelectBudgetID";
import { TaxHoldeditor } from "../EmployeePayments/EmployeePayments.editor";
import { EmployeePaymentsUnits } from "../EmployeePayments/EmployeePaymentsUnits.editor";
import { AccountEditor } from "../../../editor/AccountEditor";
import { BasicSwitch } from "../../../theme/Component/BasicSwitch";
import { ThirdBankingAccountEditor } from "../ThirdBankingAccount/ThirdBanking.editor";




export const EmployeeDiscountType = () => {

    const EmployeeDiscountType = useDataBean(_EmployeeDiscountType);

    const single = Singleton.getInstance();

    const [lista, setLista] = useState<any>([]);
    const [modal, setModal] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-md' });
    const [modalC, setModalC] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });

    useEffect(() => {
        getLista();

    }, [])

    const getLista = () => {

        EmployeeDiscountType.instance.getEmployeeDiscountTypeCatalog().then(
            (resp: any) => {
                if (resp) {
                    setLista(resp)
                   //console.log(resp)
                }
            }
        )

    }

    const create = () => {
        EmployeeDiscountType.setBean({

        });
        setModal({ ...modal, "name": "Agregar", "view": true });
    }


    const deleteItem = () => {
        EmployeeDiscountType.deleteItemVoid(EmployeeDiscountType.bean.IDType).then(
            (resp: any) => {
                getLista();
                setModalC({ ...modalC, "view": false });
            }
        )

    }

    const editaItem = () => {
       //console.log(EmployeeDiscountType.bean);
        EmployeeDiscountType.instance.updateEmployeeDiscountType(EmployeeDiscountType.bean).then(
            (resp: any) => {
                getLista();
                setModal({ ...modal, "view": false });
            }
        ).catch(err => {
            toast.error(err);
        })

    }

    const head = [
        { title: 'ID', property: 'IDType', visible: true },
        { title: 'Nombre', property: 'Name', visible: true },
        { title: 'Código', property: 'Code', visible: true },
        { title: 'Descripción', property: 'Description', visible: true },
        { title: 'Beneficiario', property: 'BeneficiaryName', visible: true },
        { title: 'Cuenta Contable Beneficiario', property: 'AccountantIDName', visible: true },
        { title: 'Cuenta Control Débito', property: 'DebtAccountantIDName', visible: true },
        { title: 'Cuenta Control Crédito', property: 'CreditAccountantIDName', visible: true },
        { title: 'Formula', property: 'DiscountFormula', visible: true },
        { title: 'Código Descuento', property: 'IDBudget', visible: true },
        { title: 'Factor de Retención', property: 'TaxHoldName', visible: true },
        { title: 'Usar Beneficiario', property: 'UseBeneficiary', visible: true },
        { title: 'Definir Cuenta Beneficiario', property: 'ThirdBankingAccountName', visible: true },
        { title: 'Generar Archivo', property: 'DownloadFile', visible: true },
        { title: 'Class Interface', property: 'ClassForName', visible: true },


    ]

    const iconButtonsItem = [
        { nombre: 'Agregar', visible: single.canCreate(), icono: 'ri-file-add-line', evento: create }
    ]
    const listaBotones = () => {
        const botones = [

            { titulo: 'Editar', icono: 'ri-edit-2-line', visible: single.canEdit(), evento: () => { setModal({ ...modal, "view": true }) } },
            { titulo: 'Eliminar', icono: 'ri-delete-bin-line', visible: single.canDelete(), evento: () => { setModalC({ ...modalC, "view": true }) } },

        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }


    return (
        <BasicPage titulo="Tipos de Aportes y Descuentos Programados">
            <div className="row">
                <div className="col-md-12">

                    <BasicTable body={lista} head={head} rowSet={EmployeeDiscountType.setBean} listButtons={listaBotones()} iconButtons={iconButtonsItem}></BasicTable>
                    <Modal modal={modal} updateModal={setModal} eventModal={editaItem}>
                        <div className="row">
                            <div className="col-md-6">
                                <label>Nombre</label>
                                <input type="text" name="Name" className="form-control" value={EmployeeDiscountType.bean.Name} onChange={EmployeeDiscountType.handleChange} />

                            </div>
                            <div className="col-md-6">
                                <label>Código</label>
                                <input type="text" name="Code" className="form-control" value={EmployeeDiscountType.bean.Code} onChange={EmployeeDiscountType.handleChange} />
                            </div>
                            <div className="col-md-12">
                                <label>Description</label>
                                <textarea name="Description" className="form-control" value={EmployeeDiscountType.bean.Description} onChange={EmployeeDiscountType.handleChange} />
                            </div>
                            <div className="col-md-6">
                                <AccountEditor label="Beneficiario" idAccount={EmployeeDiscountType.bean.IDBeneficiary} onChange={(e) => { EmployeeDiscountType.setBean({ ...EmployeeDiscountType.bean, "IDBeneficiary": e.IDAccount }) }}></AccountEditor>
                            </div>
                            <div className="col-md-6">

                                <SelectAccountantTree label="Cuenta Contable Beneficiario" jsonCode={EmployeeDiscountType.bean.AccountantIDStr} onChange={(e) => { EmployeeDiscountType.setBean({ ...EmployeeDiscountType.bean, "AccountantIDStr": e.JsonCode }) }} />
                            </div>
                            <div className="col-md-6">

                                <SelectAccountantTree label="Cuenta Control Débito" jsonCode={EmployeeDiscountType.bean.DebtAccountantIDStr} onChange={(e) => { EmployeeDiscountType.setBean({ ...EmployeeDiscountType.bean, "DebtAccountantIDStr": e.JsonCode }) }} />
                            </div>
                            <div className="col-md-6">

                                <SelectAccountantTree label="Cuenta Control Crédito" jsonCode={EmployeeDiscountType.bean.CreditAccountantIDStr} onChange={(e) => { EmployeeDiscountType.setBean({ ...EmployeeDiscountType.bean, "CreditAccountantIDStr": e.JsonCode }) }} />
                            </div>
                            <div className="col-md-6">
                                <label>Formula</label>
                                <input type="text" name="DiscountFormula" className="form-control" value={EmployeeDiscountType.bean.DiscountFormula} onChange={EmployeeDiscountType.handleChange} />
                            </div>
                            <div className="col-md-6">
                                <label>Código Descuento</label>
                                <input type="text" name="IDBudget" className="form-control" value={EmployeeDiscountType.bean.IDBudget} onChange={EmployeeDiscountType.handleChange} />
                            </div>
                            <div className="col-md-6">
                                <label>Factor de Retención</label>
                                <TaxHoldeditor idSelector={EmployeeDiscountType.bean.IDTaxHold} onChange={(e) => { EmployeeDiscountType.setBean({ ...EmployeeDiscountType.bean, "IDTaxHold": e }) }} />

                            </div>

                            <div className="col-md-6">
                                <label>Usar Beneficiario</label>
                                <BasicSwitch label="" estado={EmployeeDiscountType.bean.UseBeneficiary} eventChange={(e) => { EmployeeDiscountType.setBean({ ...EmployeeDiscountType.bean, "UseBeneficiary": e }) }}></BasicSwitch>
                            </div>
                            <div className="col-md-6">
                                <label>Definir Cuenta Bancaria para Beneficiario</label>

                                <ThirdBankingAccountEditor idselectorAccount={EmployeeDiscountType.bean.IDBeneficiary} idSelector={EmployeeDiscountType.bean.IDThirdBankingAccount} onChange={(e) => { EmployeeDiscountType.setBean({ ...EmployeeDiscountType.bean, "IDThirdBankingAccount": e }) }} />
                                {/* <AccountEditor label="Cuenta Beneficiario" idAccount={EmployeeDiscountType.bean.IDThirdBankingAccount} onChange={(e) => { EmployeeDiscountType.setBean({ ...EmployeeDiscountType.bean, "IDThirdBankingAccount": e.IDAccount }) }}></AccountEditor> */}
                            </div>
                            <div className="col-md-6">
                                <label>Generar Archivo</label>
                                <BasicSwitch label="" estado={EmployeeDiscountType.bean.DownloadFile} eventChange={(e) => { EmployeeDiscountType.setBean({ ...EmployeeDiscountType.bean, "DownloadFile": e }) }}></BasicSwitch>
                            </div>
                            <div className="col-md-6">
                                <label>Interfaz</label>
                                <input type="text" name="ClassForName" className="form-control" value={EmployeeDiscountType.bean.ClassForName} onChange={EmployeeDiscountType.handleChange} />
                            </div>
                            <hr />
                        </div>
                    </Modal>
                    <ModalConfirm modal={modalC} updateModal={setModalC} eventModal={deleteItem}>
                        <strong>Eliminar item</strong>
                    </ModalConfirm>
                </div>

            </div>
        </BasicPage>
    )
}