import { AdquisitionArticleEditor } from "./AdquisitionArticleEditor";
import { BudgetIDSelectoRender } from "./CDPRenders/BudgetIDRender";

import { ResourcePresupuestalRender } from "./CDPRenders/ResourcePresupuestalRender";
import { ValueApropRender } from "./CDPRenders/ValueApropRender";
import { DocumentVisualicer } from "./DocumentVisualicer";
import { EDITOR_ARTICLE, EDITOR_TAXBASE, RESOURCE_APROP, RUB_PRESUPUESTAL, RUB_VALUE, SEE_DOCUMENT, VALUE_APROP } from "./Editors";
import { TaxBaseEditor } from "./TaxBaseEditor";

interface Props {
    cellEditor: string;
    data: any;
    callBack: any;
}
export const CellEditorRender = ({ cellEditor, data, callBack }: Props) => {



    const render = (view: string) => {

        switch (view) {
            case EDITOR_ARTICLE:
                return <AdquisitionArticleEditor data={data} event={callBack} />
            case EDITOR_TAXBASE:
                return <TaxBaseEditor data={data} event={callBack} />
            case SEE_DOCUMENT:
                return <DocumentVisualicer data={data} event={callBack} />
            case RUB_PRESUPUESTAL:
                return <BudgetIDSelectoRender data={data} event={callBack} />
            case VALUE_APROP:
                return <ValueApropRender data={data} event={callBack} />
            case RESOURCE_APROP:
                return <ResourcePresupuestalRender data={data} event={callBack} />
            default:
                return <h6>Editor no econtrado</h6>
        }

    }

    return (
        <div className="row" >
            <div className="col" id={cellEditor}>

                {render(cellEditor)}

            </div>
        </div>
    )
}