import { DataBean } from "../DataBean";

export class ScheduledDiscount extends DataBean {

    public async deleteEmployeeContribution(idContribution: number): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "void_deleteEmployeeContribution_Number", [idContribution]);
    }

    public async getEmployeeContributions(accountID: number | null, type: number, date: any, idTaxProfile: null): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_getEmployeeContributions_Number_Number_java.util.Date_Number", [accountID, type, date, idTaxProfile]);
    }

    public async updateEmployeeContribution(bean: any): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "employees.ScheduledDiscount_updateEmployeeContribution_employees.ScheduledDiscount", [
            {
                "DataBeanProperties": bean,
                "DataBeanName": "employees.ScheduledDiscount"
            }
        ]);
    }

}