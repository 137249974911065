import { DataBean } from "../DataBean";


export class FunctionalID extends DataBean {

    public async getFunctionalIDByKey(idlnsite: number): Promise<any> {
        return this.getCustomService("OrangeBase", "com.orange.bean.functional.FunctionalID_getFunctionalIDByKey_Number", [idlnsite]);
    }

    public async getFunctionalIDByCode(jsonCode: string): Promise<any> {
        return this.getCustomService("OrangeBase", "com.orange.bean.functional.FunctionalID_getFunctionalIDByCode_String", [jsonCode]);
    }

}