import { DataBean } from "../DataBean";

export class FixedAsset extends DataBean {

    public async getFixedAsset(idFixedAsset: number): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "fixedassets.FixedAsset_getFixedAsset_Number", [idFixedAsset]);
    }

    public async getFixedAssetByUBLInvoice(idUBLInvoice: number, issueDate: any, itemID: number): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "fixedassets.FixedAsset_getFixedAssetByUBLInvoice_Number_java.util.Date_Number", [idUBLInvoice, issueDate, itemID]);
    }

    public async updateFixedAsset(bean: any): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "fixedassets.FixedAsset_updateFixedAsset_fixedassets.FixedAsset", [
            {
                "DataBeanProperties": bean,
                "DataBeanName": "fixedassets.FixedAsset"
            }
        ]);
    }

}
