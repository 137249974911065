import { useEffect, useState } from "react";
import { Singleton } from "../../../../../core/services/Singleton";
import { ForpoService } from "../service/ForpoService";
import { useDashboardStore } from "../../../../pages/hooks/useDashboardStore";

interface Props {
    storeCategory?: number;
}

export const useStoreBranch = ({ storeCategory }: Props) => {
    const single = Singleton.getInstance()
    const { setLoading } = useDashboardStore();
    const service = new ForpoService();
    const [lista, setLista] = useState([]);
    const [modalConfirm, setModalConfirm] = useState<any>(
        { name: "Confirmar", tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: "modal-sm", });
    const [modal, setModal] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: "modal-lg", });
    const [bean, setBean] = useState<any>({
        State: 1,
        IDEmployee: single.getAccountID(),
        Schedule: '',
        Phone: 0,
        Address: '',
        SiteIDLN: 0,
        IDStoreCategory: storeCategory,
    });


    useEffect(() => {
        getLista();
    }, []);


    const idBean = "IDStoreBranch";

    const setRowBean = (bean: any) => {
        setBean(bean);
    };

    const getLista = () => {
        setLoading(true);
        let properties = {
            IDStoreCategory: storeCategory,
            State: 1,
        }
        service.getStoreBranchCatalogPorPropiedades(properties, null).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue) {
                    setLista(resp.DataBeanProperties.ObjectValue);
                }
                setLoading(false);
            });
    };

    const head = [
        { title: "ID", property: idBean },
        { title: "Sucursal", property: "SiteIDLN" },
        { title: "Fecha", property: "Schedule" },
        { title: "Telefono", property: "Phone" },
        { title: "Direccion", property: "Address" },
    ];

    const agregarItem = () => {
        let b = {
            State: 1,
            IDEmployee: single.getAccountID(),
            Schedule: '',
            Phone: 0,
            Address: '',
            SiteIDLN: 0,
            IDStoreCategory: storeCategory,
        };
        setBean(b);
        setModal({ ...modal, "view": true, name: "Agregar" });
    };

    const editarItem = () => {
        setModal({ ...modal, "view": true, name: 'Editar' });
    };

    const eliminarItem = () => {
        bean.State = 2;
        updateItem();
        setModalConfirm({ ...modalConfirm, "view": false });
    }

    const updateItem = () => {
        setLoading(true);
        service.updateStoreBranch(bean).subscribe((resp: any) => {
            setLoading(false);
            if (resp.DataBeanProperties.ObjectValue) {
                getLista();
                if (bean.idBean > 0) {
                    single.update(bean);
                } else {
                    single.create(bean);
                }
            }
        });
        setModal({ ...modal, "view": false });
        setModalConfirm({ ...modalConfirm, "view": false });
    };

    const mostrar = () => {
       //console.log(bean)
    }

    const iconButtonsItem = [
        { nombre: "Agregar", visible: single.canCreate(), icono: "ri-file-add-line", evento: agregarItem, },
    ];

    return {
        single,
        service,
        lista,
        setLista,
        bean,
        setBean,
        modal,
        setModal,
        modalConfirm,
        setModalConfirm,
        idBean,
        updateItem,
        head,
        iconButtonsItem,
        editarItem,
        agregarItem,
        eliminarItem,
        setRowBean,
        mostrar
    };
};
