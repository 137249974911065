import { useState, useEffect } from 'react';
import { ContractService } from '../../../core/services/ContractService';
import { Singleton } from '../../../core/services/Singleton';
interface Props {
    idSelector: number;
    onChange: any;
    label?: string;
}
export const SelectAdquisitionArticleConstants = ({ idSelector, onChange, label }: Props) => {

    const service = new ContractService();
    const [lista, setLista] = useState([]);
    const [id, setID] = useState<number>(NaN);

    useEffect(() => {

        setID(idSelector);
        getLista();

    }, [])
   

    const setValue = (e: any) => {
        setID(parseInt(e));
        onChange(parseInt(e));
    }

    const getLista = () => {

        let cache = Singleton.getInstance().getCacheItem("AdquisitionArticleConstants");
        if (cache) {
            setLista(cache);
        }
        else {
            service.getAdquisitionArticleConstants(null).subscribe(
                (resp: any) => {
                    
                    if (resp.DataBeanProperties.ObjectValue) {
                        setLista(resp.DataBeanProperties.ObjectValue);
                        Singleton.getInstance().setCacheItem("AdquisitionArticleConstants", resp.DataBeanProperties.ObjectValue);
                    }
                }
            );
        }
    }

    return (
        <>
            <label>{label ?? "Tipo"}</label>
            <select className="form-select" value={id} onChange={(e) => { setValue(e.target.value) }}>
                <option value={NaN}>...</option>
                {lista &&
                    lista.map((item: any) => {
                        return (

                            <option key={item.DataBeanProperties.Value} value={item.DataBeanProperties.Value} >{item.DataBeanProperties.Property}</option>
                        )
                    })
                }

            </select>
        </>
    )
}