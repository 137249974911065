import { useEffect, useState } from "react";
import { CashReimbursementServices } from "../../erp/cellEditor/CashDesk/CashReimbursement/CashReimbursement-service";
import { _single } from "../../../core/services/dataBean/EntityCatalog";
import { PrintJson } from "../../theme/Component/PrintJson";
import { BasicMultiSelectTable } from "../../theme/Component/BasicMultiSelecTable";
import useModal, { MODAL_md } from "../../theme/Component/hooks/useModal";
import { Modal } from "../../theme/Component/Modal";
import { BasicTable } from "../../theme/Component/BasicTable";

interface Props {
    label: string;
    onChange: Function;
}
export const SelectorDocumentFactory = ({ label, onChange }: Props) => {

    const service = new CashReimbursementServices();
    const [lista, setLista] = useState<any>([]);
    const [listaResultado, setListaResultado] = useState<any>([]);
    const modal = useModal("Tipo Documento", MODAL_md);
    const [option, setOption] = useState<any>({});

    useEffect(() => {
        getDocumentFactoryCatalog();

    }, [])
    useEffect(() => {
        onChange(option);
    }, [option])

    const getDocumentFactoryCatalog = () => {

        let temp = _single.getCacheItem('Mnemonic');
        if (temp) {
            setLista(temp);
        } else {
            _single.spinner(true);
            service.getDocumentFactoryCatalog(null).subscribe(
                (resp: any) => {

                    if (resp.DataBeanProperties.ObjectValue) {
                        setLista(resp.DataBeanProperties.ObjectValue);
                        _single.setCacheItem('Mnemonic', resp.DataBeanProperties.ObjectValue);
                    }
                    _single.spinner(false);
                }
            );
        }

    }

    const head = [
        // { title: 'ID', property: 'IDDocumentFactory', visible: false },
        { title: 'Mnemonic', property: 'Mnemonic', visible: true },
        { title: 'Nombre', property: 'Name', visible: true },
    ]

    return (
        <div className="row">
            <div className="col">
                {label != '' && <label>{label}</label>}
                <input type="text" className="form-control" value={option.Mnemonic} readOnly onClick={() => { modal.open() }} />
                <Modal modal={modal.modal} updateModal={modal.setModal}>
                    <h6>{label}</h6>
                    <BasicTable body={lista} head={head} rowSet={setOption}></BasicTable>
                    {/* <BasicMultiSelectTable keyId='Mnemonic' body={lista} bodyResult={listaResultado} head={head} updateValues={({ bodyResult }) => { setListaResultado(bodyResult) }} /> */}
                </Modal>
            </div>
        </div>
    )
}