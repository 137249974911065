import React, { useState, useEffect } from 'react';
import { ContractService } from '../../../../core/services/ContractService';
import useDataBean from '../../../../core/services/dataBean/useDataBean';
import { _ContractRole } from '../../../../core/services/dataBean/EntityCatalog';

interface Props {
    idSelector: number;
    onChange: any;
    titulo: string;
}

export const SelectRolOffer = ({ titulo, idSelector, onChange }: Props) => {

    const service = new ContractService();
    const [lista, setLista] = useState([]);
    const [id, setID] = useState(idSelector);
    const contractRole = useDataBean(_ContractRole);

    useEffect(() => {
        getLista();
    }, [])

    const setValue = (e: any) => {
        setID(parseInt(e));
        onChange(parseInt(e));
    }

    const getLista = () => {
        contractRole.instance.getContractRolesRender(null,{State:1}).then(
            (resp:any)=>{
                let arr: any = [];
                    let temp = resp;
                    temp.forEach((element: any) => {
                        arr.push({
                            id: element.DataBeanProperties.CodeRole,
                            name: element.DataBeanProperties.Name
                        })
                    });
                    setLista(arr);
            }
        )
        // service.getHiringConstants("HIRINGROLES").subscribe(
        //     (resp: any) => {
        //         if (resp.DataBeanProperties.ObjectValue) {
        //             let arr: any = [];
        //             let temp = resp.DataBeanProperties.ObjectValue;
        //             temp.forEach((element: any) => {
        //                 arr.push({
        //                     id: element.DataBeanProperties.Value,
        //                     name: element.DataBeanProperties.Property
        //                 })
        //             });
        //             setLista(arr);
        //         }

        //     }
        // );
    }

    return (
        <>
            <div className="col-md-12">
                <label>{titulo ? titulo : "Rol del Usuario"}</label>
                <select className='form-select' value={id} onChange={(e) => { setValue(parseInt(e.target.value)); }} >
                    <option value={null}>...</option>
                    {lista.map((item: any,index:number) => {
                        return (
                            <option key={index} value={item.id}>{item.name}</option>
                        )
                    })}
                </select>
            </div>
        </>
    );
};
