import { useEffect, useState } from "react";
import useDataBean from "../../../../core/services/dataBean/useDataBean";
import { _PaymentAccount } from "../../../../core/services/dataBean/EntityCatalogVdos";
import usePaginator from "../../../theme/Component/usePaginator";
import { BasicTable, MASK_DATE, MASK_MONEY, MASK_NUMBER } from "../../../theme/Component/BasicTable";
import { Singleton } from "../../../../core/services/Singleton";
import { BasicPage } from "../../../theme/Component/BasicPage";
import { YearSelector } from "../../../../core/shared/inputs/YearSelector";
import { SelectMonthPayment } from "./SelectMonthPayment";
import { SelectTypeProcees } from "./SelectTypeProcees";
import { AccountEditor } from "../../../editor/AccountEditor";
import { BakingEntityEditor } from "../../../erp/cellEditor/BankingEntity/BankingEntitySelect";
import { SelectStateConst } from "./SelectStateConst";
import { BasicButton } from "../../../theme/Component/BasicButton";
import { Modal } from "../../../theme/Component/Modal";
import { PaymentAccountItem } from "./PaymentAccountItem";
import { ListMenu } from "../../../theme/Component/ListMenu";
import { InputDate } from "../../../theme/Input/InputDate";
import { BankAccountSelect } from "../../../erp/cellEditor/BankAccount/BankAccountSelect";
import { toast } from "react-toastify";
import { DocumentPDF, DocumentViewer } from "../../../theme/Component/DocumentViewer";
import { CashDeskAccountSelector } from "../../../erp/cellEditor/CashDeskAccount/CashDeskAccountSelector";


interface Props {
    CASH_REIMBURSEMENT?: number;
}

export const PaymentAccount = ({ CASH_REIMBURSEMENT }: Props) => {

    const { bean, setBean, lista, setLista, handleChange, instance, deleteItemVoid, update } = useDataBean(_PaymentAccount);
    const paginador = usePaginator();
    const single = Singleton.getInstance();

    const [document, setDocument] = useState<any>([]);
    const [documentError, setDocumentError] = useState<any>([]);
    const [IdAcoount, setIdAcoount] = useState<any>(null);
    const [processType, setProcessType] = useState<any>(null);
    const [year, setyear] = useState<any>(null);
    const [month, setmonth] = useState<any>(null);
    const [state, setstate] = useState<any>(null);
    const [idBankingEntity, setidBankingEntity] = useState<any>(null);
    const [responseCash, setReponseCash] = useState<any>({});
    const [responseBank, setReponseBank] = useState<any>({});
    const [Register, SetRegister] = useState(15);
    const [missingFields, setMissingFields] = useState<string[]>([]);


    const [modalVisor, setModalVisor] = useState<any>({ name: "Documentos Generados", tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-xl' });
    const [modaItemsCuenta, setItemsCuenta] = useState<any>({ name: 'Ver Items de la Cuenta por Pagar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalEgresoBanco, setEgresoBanco] = useState<any>({ name: 'Realizar Egreso por Bancos', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalEgresoCaja, setEgresosCaja] = useState<any>({ name: 'Realizar Egreso por Caja', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });

    const head = [
        { title: 'ID', property: 'IDPaymentAccount', visible: true, mask: MASK_NUMBER },
        { title: 'Fuente de Cuenta', property: 'SourceTypeName', visible: true },
        { title: 'Tipo de Operación', property: 'OperationType', visible: true },
        { title: 'Fuente', property: 'SourceDocument', visible: true },
        { title: 'Fecha Cuenta', property: 'Since', visible: true, mask: MASK_DATE },
        { title: 'Cuenta Consolida Terceros', property: 'RequiresConsolidateThird', visible: true },
        { title: 'Beneficiario', property: 'AccountName', visible: true },
        { title: 'Valor Total a Girar', property: 'Value', visible: true, mask: MASK_MONEY },
        { title: 'Tipo de Proceso', property: 'TypeName', visible: true },
        { title: 'Tipo de Operación', property: 'PaymentTypeName', visible: true },
        { title: 'Estado de la Cuenta', property: 'StateName', visible: true },
        { title: 'Estado de la Operación', property: 'OperationStateName', visible: true },
        { title: 'Tipo de Cuenta Bancaria', property: 'AccountTypeName', visible: true },
        { title: 'Banco', property: 'EntityBanking', visible: true },
        { title: 'No Cuenta', property: 'AccountNumber', visible: true },
        { title: 'Tipo de Pago', property: 'EntityPayment', visible: true },
        { title: 'CDP', property: 'CDPDocument', visible: true },
        { title: 'RP', property: 'RPDocument', visible: true },
        { title: 'OB', property: 'OBDocument', visible: true },
        { title: 'CE', property: 'CEDocument', visible: true },
    ]



    useEffect(() => {
        getLista();
    }, [paginador.page])

    useEffect(() => {
        if (CASH_REIMBURSEMENT) {
            setProcessType(CASH_REIMBURSEMENT)
        }
        getLista();
    }, [CASH_REIMBURSEMENT])

    const getLista = () => {
        instance.getPaymentAccountList(processType, null, IdAcoount, single.getCurrenYear(), month, state, idBankingEntity, paginador.page, Register).then(
            (resp: any) => {
                if (resp) {
                   //console.log(resp.List)
                    let newList = resp.List;
                    if (newList.length > 0) {
                        newList.map((item) => {
                            item.DataBeanProperties.EntityBanking = ((item.DataBeanProperties.BankingEntityCode ?? "") + " - " + (item.DataBeanProperties.BankingEntityName ?? ""))
                            item.DataBeanProperties.EntityPayment = ((item.DataBeanProperties.PaymentTypeCode ?? "") + " - " + (item.DataBeanProperties.PaymentTypeName ?? ""))
                        })
                    }
                    setLista(newList)
                    paginador.setPages(resp.NumberOfPages);
                }
            }
        ).catch(
            err => {
               console.error(err)
            }
        )
    }

    const addCaja = () => {
        instance.createEgressDocumentByCashDeskAccount(responseCash.idCashDeskAccount, bean.IDPaymentAccount, responseCash.accountantDate, responseCash.Description, single.getAccountID(), null, null).then(
            (resp: any) => {
               //console.log(resp);
                if (resp.MsgList) {
                    setDocumentError(resp.MsgList);
                }
                if (resp.DocumentURL) {
                    let docs: DocumentPDF[] = [];
                    resp.DocumentURL.forEach(element => {
                        docs.push({
                            Filename: element.DataBeanProperties.Filename,
                            Media: element.DataBeanProperties.Media,
                            MediaContext: element.DataBeanProperties.MediaContext,
                            Type: element.DataBeanProperties.Type,
                            URLLink: element.DataBeanProperties.URLLink
                        })
                    });
                    setDocument(docs);
                }
                setModalVisor({ ...modalVisor, "view": true });
                setEgresosCaja({ ...modalEgresoCaja, "view": false });
            }
        ).catch(
            err => {
               console.error(err);
                toast.error("Algo salio mal")
            }
        )
    }



    const addBanco = () => {
        instance.createEgressDocumentByBankingAccount(responseBank.IDThirdBankingAccount, bean.IDPaymentAccount, responseBank.accountantDateBank, responseBank.Description, single.getAccountID(), null, null).then(
            (resp: any) => {
               //console.log(resp);
                if (resp.MsgList) {
                    setDocumentError(resp.MsgList);
                }
                if (resp.DocumentURL) {
                    let docs: DocumentPDF[] = [];
                    resp.DocumentURL.forEach(element => {
                        docs.push({
                            Filename: element.DataBeanProperties.Filename,
                            Media: element.DataBeanProperties.Media,
                            MediaContext: element.DataBeanProperties.MediaContext,
                            Type: element.DataBeanProperties.Type,
                            URLLink: element.DataBeanProperties.URLLink
                        })
                    });
                    setDocument(docs);
                }
                setModalVisor({ ...modalVisor, "view": true });
                setEgresoBanco({ ...modalEgresoBanco, "view": false })
            }
        ).catch(
            err => {
               console.error(err)
                toast.error("Algo salio mal")
            }
        )
    }

    const egresosOpenCahs = () => {
        setReponseCash({})
        setEgresosCaja({ ...modalEgresoCaja, "view": true });
    }

    const egresosOpenBank = () => {
        setReponseBank({})
        setEgresoBanco({ ...modalEgresoBanco, "view": true })
    }

    const validateDataCash = () => {
        if (validateFormCash() > 0) {
            toast.error("Faltan campos obligatorios por completar");
            return;
        } else {
            addCaja();
        }
    }

    const validateDataBank = () => {
        if (validateFormBank() > 0) {
            toast.error("Faltan campos obligatorios por completar");
            return;
        } else {
            addBanco();
        }
    }

    const validateFormCash = () => {
        const requiredFieldsCash = [
            "idCashDeskAccount", "accountantDate"
        ];


        let missing = [];

        for (let field of requiredFieldsCash) {
            if (!responseCash[field]) {
                missing.push(field);
            }
        }

        setMissingFields(missing);
        return missing.length
    };

    const validateFormBank = () => {

        const requiredFieldsBank = [
            "IDThirdBankingAccount", "accountantDateBank"
        ];

        let missing = [];

        for (let field of requiredFieldsBank) {
            if (!responseBank[field]) {
                missing.push(field);
            }
        }

        setMissingFields(missing);
        return missing.length
    };

    const listaBotones = () => {
        const botones = [
            { titulo: 'Ver Items de la Cuenta por Pagar', icono: 'ri-file-list-line', visible: single.canEdit(), evento: () => { setItemsCuenta({ ...modaItemsCuenta, "view": true }) } },
            { titulo: 'Realizar Egreso por Caja', icono: 'ri-upload-line', visible: single.canEdit(), evento: () => { egresosOpenCahs() } },
            { titulo: 'Realizar Egreso por Banco', icono: 'ri-upload-2-line', visible: (bean.Type != 15), evento: () => { egresosOpenBank() } },
        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }


    return (
        <>
            <BasicPage titulo="Cuentas por Pagar">
                <div className="row">
                    <div className="col-md-6">
                        <YearSelector value={year} onChange={setyear} />
                    </div>

                    <div className="col-md-6">
                        <SelectMonthPayment label="Mes" idSelector={month} onChange={(e) => { setmonth(e) }} />
                    </div>

                    <div className="col-md-6">
                        <SelectTypeProcees disable={CASH_REIMBURSEMENT ? true : false} label="Tipo de Proceso" idSelector={processType} onChange={(e) => setProcessType(e)} />
                    </div>

                    <div className="col-md-6">
                        <AccountEditor label="Beneficiario" idAccount={IdAcoount} onChange={(e) => { setIdAcoount(e.IDAccount) }} />
                    </div>
                    <div className="col-md-6">
                        <BakingEntityEditor label="Entidad Bancaria" idSelector={idBankingEntity} onChange={(e) => { setidBankingEntity(e) }} />

                    </div>

                    <div className="col-md-6">
                        <SelectStateConst label="Estado" idSelector={state} onChange={(e) => { setstate(e) }} />
                    </div>

                    <div className="col-md-6">
                        <label>Cantidad Registro por pagina</label>
                        <input type="number" name="Value" className="form-control" value={Register} onChange={(e) => { SetRegister(parseInt(e.target.value)) }} />
                    </div>
                    <div className="col-md-6 mt-4">
                        <BasicButton disable={!month} icon="ri-search-line" eventClick={getLista}>Buscar</BasicButton>
                    </div>
                </div>

                <BasicTable
                    customRows={Register}
                    body={lista}
                    head={head}
                    rowSet={setBean}
                    listButtons={listaBotones()}
                    pageTop={paginador.page} next={paginador.next} back={paginador.back} records={paginador}
                ></BasicTable>
            </BasicPage>

            <Modal modal={modaItemsCuenta} updateModal={setItemsCuenta}>
                <PaymentAccountItem IDPaymentAccount={bean.IDPaymentAccount} />
            </Modal>

            <Modal modal={modalEgresoCaja} updateModal={setEgresosCaja} eventModal={validateDataCash}>
                <div className="row">
                    <div className="col-md-6">
                        <label className={`${missingFields.includes('idCashDeskAccount') ? 'has-error' : ''}`}>Caja Menor</label>
                        <CashDeskAccountSelector id={responseCash.idCashDeskAccount} onchange={(e) => { setReponseCash({ ...responseCash, "idCashDeskAccount": e }) }} label=' ' />
                    </div>
                    <div className="col-md-6">
                        <label className={`${missingFields.includes('accountantDate') ? 'has-error' : ''}`}>Fecha de Registro Contable</label>
                        <InputDate notDate label=' ' name='accountantDate' value={responseCash.accountantDate} setDate={(e: any) => { setReponseCash({ ...responseCash, "accountantDate": e }) }} />
                    </div>
                    <div className="col-md-12">
                        <label>Description</label>
                        <textarea name="Description" className="form-control" value={responseCash.Description} onChange={(e) => { setReponseCash({ ...responseCash, "Description": String(e.target.value) }) }} />
                    </div>
                </div>
            </Modal>

            <Modal modal={modalEgresoBanco} updateModal={setEgresoBanco} eventModal={validateDataBank}>
                <div className="row">
                    <div className="col-md-6">
                        <label className={`${missingFields.includes('IDThirdBankingAccount') ? 'has-error' : ''}`}>Banco</label>
                        <BankAccountSelect idSelector={responseBank.IDThirdBankingAccount} onChange={(e) => { setReponseBank({ ...responseBank, "IDThirdBankingAccount": e }) }} label=' ' />
                    </div>
                    <div className="col-md-6">
                        <label className={`${missingFields.includes('accountantDateBank') ? 'has-error' : ''}`}>Fecha de Registro Contable</label>
                        <InputDate notDate label=' ' name='accountantDate' value={responseBank.accountantDateBank} setDate={(e: any) => { setReponseBank({ ...responseBank, "accountantDateBank": e }) }} />
                    </div>
                    <div className="col-md-12">
                        <label>Descripción</label>
                        <textarea name="Description" className="form-control" value={responseBank.Description} onChange={(e) => { setReponseBank({ ...responseBank, "Description": String(e.target.value) }) }} />
                    </div>
                </div>
            </Modal>

            <Modal modal={modalVisor} updateModal={setModalVisor} >
                <div className="row">
                    <div className="col-md-12">
                        {documentError.length > 0 ?
                            <>
                                <ul className="group-list">
                                    {documentError.map((item: any, index: number) => {
                                        return (
                                            <li key={index} className="group-list-item">{item}</li>
                                        )
                                    })}
                                </ul>
                                {document.length > 0 &&
                                    <DocumentViewer list={document} />
                                }
                            </>
                            :
                            <>
                                {document.length > 0 &&
                                    <DocumentViewer list={document} />
                                }
                            </>
                        }
                    </div>
                </div>
            </Modal>
        </>
    )
}