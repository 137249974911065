import { useEffect, useState } from "react";
import { Modal } from "../../../../theme/Component/Modal";
import { SearchUBLInovice } from "./CreateUBLInvoice/SearchUBLInovice";
import { toast } from "react-toastify";
import { PrintJson } from "../../../../theme/Component/PrintJson";
import { BasicButton } from "../../../../theme/Component/BasicButton";
import useDataBean from "../../../../../core/services/dataBean/useDataBean";
import { _UBLInvoice } from "../../../../../core/services/dataBean/EntityCatalog";

interface Props {
    label?: string;
    disabled?: boolean;
    _invoice?: any;
    _onChange: Function;
}

export const SelectUBLInvoice = ({ label, disabled, _onChange, _invoice = {} }: Props) => {
    const { instance } = useDataBean(_UBLInvoice);

    const [modal, setModal] = useState<any>({ name: 'Buscar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-xl' });
    const [invoice, setInvoice] = useState<any>({});

    useEffect(() => {
        localStorage.setItem("SimulatorInvoice", JSON.stringify({}));
    }, []);

    const validateUBL = (_invoice: any) => {
        if (_invoice?.IDUBLInvoice) {
            setInvoice(_invoice);
            setModal({ ...modal, "view": false });
            _onChange(_invoice);
        } else {
            toast.error("No se pudo seleccionar la Factura requerida");
        }
    };

    const bashFactura = () => {
        setInvoice({});
        _onChange({});
    };

    const getInvoice = () => {
        instance.getUblInvoice(_invoice?.IDUBLInvoice, _invoice?.IssueDate)
            .then((resp) => {
                setInvoice(resp);
                _onChange(resp);
            })
            .catch((error) => {
               //console.log(error);
                toast.error("Algo salio mal");
            });
    };

    useEffect(() => {
        if (_invoice?.IDUBLInvoice >= 0) {
            getInvoice();
        }
    }, [_invoice]);

    return (
        <>
            <div className="row">
                <div className={`${_invoice.IDUBLInvoice ? "col-12" : "col-10"}`}>
                    <label>{label ? label : 'Factura Electrónica'}</label>
                    <input
                        type="text"
                        className="form-control"
                        disabled={disabled}
                        placeholder="Buscar..."
                        value={invoice.IDUBLInvoice || invoice.IssueDate ? `ID: ${invoice.IDUBLInvoice || ''} - Fecha: ${invoice.IssueDate || ''}` : ''}
                        onClick={() => setModal({ ...modal, 'view': true })}
                        onChange={() => { }}
                    />
                </div>

                {!(_invoice.IDUBLInvoice) &&
                    <div className="col-2 d-flex align-items-end">
                        <BasicButton clase="danger" eventClick={bashFactura} icon="ri-delete-bin-line" />
                    </div>
                }
            </div>

            {modal.view &&
                <Modal modal={modal} updateModal={setModal} eventModal={() => validateUBL(invoice)} onSubmitLabel="Seleccionar">
                    <SearchUBLInovice _onChange2={(e) => setInvoice(e)} />
                </Modal>
            }
        </>
    );
};
