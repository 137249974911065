import { useEffect, useState } from "react";
import { _CompetitionType, _Entailment, _GenericCompetition, _MainTheme, _StrategicObjetive } from "../../../../core/services/dataBean/EntityCatalog";
import { Singleton } from "../../../../core/services/Singleton";
import { toast } from "react-toastify";
import { ListMenu } from "../../../theme/Component/ListMenu";
import { BasicPage } from "../../../theme/Component/BasicPage";
import { BasicTable } from "../../../theme/Component/BasicTable";
import { Modal } from "../../../theme/Component/Modal";
import { ModalConfirm } from "../../../theme/Component/ModalConfirm";
import useDataBean from "../../../../core/services/dataBean/useDataBean";
import { CompetitionTypeEditor } from "../CompetitionType/CompetitionType.editor";
import { Competitionleveleditor } from "../CompetitionLevel/Competitionlevel.editor";



export const GenericCompetition = () => {

    const GenericCompetition = useDataBean(_GenericCompetition);

    const single = Singleton.getInstance();

    const [lista, setLista] = useState<any>([]);

    const [IdCompetitionType, setIdCompetitionType] = useState<number>(null)
    const [modal, setModal] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalC, setModalC] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });

    useEffect(() => {
        getLista();
    }, [IdCompetitionType])

    const getLista = () => {

        GenericCompetition.instance.
            getGenericCompetitionCatalog(IdCompetitionType).then(
                (resp: any) => {
                    if (resp) {
                        setLista(resp)
                       //console.log(resp)
                    }
                }
            )

    }

    const create = () => {
        GenericCompetition.setBean({

            IDCompetitionType: IdCompetitionType
        });
        setModal({ ...modal, "view": true });
    }
    const deleteItem = () => {
        GenericCompetition.instance.deleteGenericCompetition(GenericCompetition.bean).then(
            (resp: any) => {
                getLista();
                setModalC({ ...modalC, "view": false });
            }
        )

    }
    const editaItem = () => {
       //console.log(GenericCompetition.bean);
        GenericCompetition.update().then(
            (resp: any) => {
                getLista();
                setModal({ ...modal, "view": false });
            }
        ).catch(err => {
            toast.error(err);
        })

    }

    const head = [
        { title: 'ID', property: 'IDGenericCompetition', visible: true },
        { title: 'Descripción', property: 'Description', visible: true },
        { title: 'Nombre', property: 'Name', visible: true },
        { title: 'Tipos de Competencias', property: 'IDCompetitionType', visible: true },
        { title: 'Niveles de las Competencias', property: 'IDCompetitionLevel', visible: true },
        { title: 'Porcentaje', property: 'ExpectedPercentLevel', visible: true }

    ]

    const iconButtonsItem = [
        { nombre: 'Agregar', visible: single.canCreate(), icono: 'ri-file-add-line', evento: create }
    ]
    const listaBotones = () => {
        const botones = [

            { titulo: 'Editar', icono: 'ri-edit-2-line', visible: single.canEdit(), evento: () => { setModal({ ...modal, "view": true }) } },
            { titulo: 'Eliminar', icono: 'ri-delete-bin-line', visible: single.canDelete(), evento: () => { setModalC({ ...modalC, "view": true }) } },

        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }


    return (
        <BasicPage titulo=" Competencias Genéricas">
            <div className="row">
                <div className="col-md-6">
                    <CompetitionTypeEditor idSelector={IdCompetitionType} onChange={(e) => { setIdCompetitionType(e) }} />
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">

                    <BasicTable body={lista} head={head} rowSet={GenericCompetition.setBean} listButtons={listaBotones()} iconButtons={iconButtonsItem}></BasicTable>
                    <Modal modal={modal} updateModal={setModal} eventModal={editaItem}>
                        <div className="row">
                            <div className="col-md-6">

                                <label>Nombre</label>
                                <input type="text" name="Name" className="form-control" value={GenericCompetition.bean.Name} onChange={GenericCompetition.handleChange} />
                            </div>
                            <div className="col-md-6">

                                <label>Nivel de Porcentaje</label>
                                <input type="text" name="ExpectedPercentLevel" className="form-control" value={GenericCompetition.bean.ExpectedPercentLevel} onChange={GenericCompetition.handleChange} />
                            </div>

                            <div className="col-md-6">
                                <label></label>

                                <CompetitionTypeEditor idSelector={GenericCompetition.bean.IDCompetitionType} onChange={(e) => { GenericCompetition.setBean({ ...GenericCompetition.bean, "IDCompetitionType": e }) }} />
                            </div>
                            <div className="col-md-6">
                                <label></label>
                                <Competitionleveleditor IDCompetitionType={GenericCompetition.bean.IDCompetitionType} idSelector={GenericCompetition.bean.IDCompetitionLevel} onChange={(e) => { GenericCompetition.setBean({ ...GenericCompetition.bean, "IDCompetitionLevel": e }) }} />
                            </div>


                            <div className="col-md-12">
                                <label>Descripción</label>

                                <textarea name="Description" className="form-control" value={GenericCompetition.bean.Description} onChange={GenericCompetition.handleChange} />
                            </div>
                        </div>
                    </Modal>
                    <ModalConfirm modal={modalC} updateModal={setModalC} eventModal={deleteItem}>
                        <strong>Eliminar item</strong>
                    </ModalConfirm>
                </div>

            </div>
        </BasicPage>
    )
}