import { useState, useEffect } from 'react';

import { _MeasurementUnitCode } from '../../../core/services/dataBean/EntityCatalogVdos';
import useDataBean from '../../../core/services/dataBean/useDataBean';

interface Props {
    idSelector: number;
    onChange: any;
    label?: string;
    disable?: boolean;
}

export const MeasurementUnitCodeEditor = ({ idSelector, onChange, label, disable }: Props) => {

    const unitCode = useDataBean(_MeasurementUnitCode);
    const [lista, setLista] = useState([]);
    const [id, setID] = useState(null);

    useEffect(() => {
        setID(idSelector);
        getLista();
    }, [])

    useEffect(() => {
        onChange(id);
    }, [id])

    const setValue = (e: any) => {
        setID(parseInt(e));
        onChange(id);
    }

    const getLista = () => {

        unitCode.instance.getMeasurementUnitCode(null).then(
            (resp: any) => {
                if (resp) {
                    setLista(resp)
                }
            }
        )

    }

    return (
        <>
            <label>{label ?? "Tipo de Habilidad:"}</label>
            <select disabled={disable} className="form-select" value={id} onChange={(e) => { setValue(e.target.value) }}>
                <option value={null}>...</option>
                {lista &&
                    lista.map((item: any, index: number) => {
                        return (
                            <option key={index} value={item.DataBeanProperties.IDMeasurementUnitCode} >{item.DataBeanProperties.Name}</option>
                        )
                    })
                }
            </select>
        </>
    )
}