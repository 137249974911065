import { DataBean } from "../DataBean";


export class ImportedPayrollConcept extends DataBean {

    public async updateImportedPayrollConcept(bean: any): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "com.quickdataerp.bean.employees.ImportedPayrollConcept_updateImportedPayrollConcept_com.quickdataerp.bean.employees.ImportedPayrollConcept", [    
            {
            "DataBeanProperties":bean,
            "DataBeanName": "com.quickdataerp.bean.employees.ImportedPayrollConcept"
          }
        ]);
    }

    public async getImportedPayrollConceptCatalog(idImportedPayrollConcept: number | null): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_getImportedPayrollConceptCatalog_Number", [idImportedPayrollConcept]);
    }

    public async getValueTypeConstants(arg: number | null): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_getValueTypeConstants_Number", [arg]);
    }

    public async getAccrualTypeConstants(arg: number | null): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_getAccrualTypeConstants_Number", [arg]);
    }

    public async getAbsenteeismClassConstants(arg: number | null): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_getAbsenteeismClassConstants_Number", [arg]);
    }

    public async getIncapacityLiquidateConstants(arg: number | null): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_getIncapacityLiquidateConstants_Number", [arg]);
    }

}