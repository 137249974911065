import { DataBean } from "../DataBean";


export class TechnicalSkill extends DataBean {


    public async getTechnicalSkillCatalog(idLn: number | null, idAppoManual: number, type: number | null): Promise<any> {
        return this.getCustomService("PeopleArt", "java.util.List_getTechnicalSkillCatalog_Number_Number_Number", [idLn, idAppoManual, type]);
    }

    public async deleteTechnicalSkill(bean): Promise<any> {
        return this.getCustomService("PeopleArt", "void_deleteTechnicalSkill_com.peopleart.bean.appointment.TechnicalSkill", [
            {
                "DataBeanProperties": bean,
                "DataBeanName": "com.peopleart.bean.appointment.TechnicalSkill"
            }
        ]);
    }
}