import { useState, useEffect } from 'react';
import { BasicPage } from "../../../theme/Component/BasicPage";
import { BasicTable } from "../../../theme/Component/BasicTable";
import { ModalConfirm } from "../../../theme/Component/ModalConfirm";
import { Modal } from "../../../theme/Component/Modal";
import { BpmService } from "../../../../core/services/BpmService";
import { ListMenu } from "../../../theme/Component/ListMenu";
import { SelectTHConstantsValue } from "./SelectTHConstantsValue";
import { useDashboardStore } from "../../../pages/hooks/useDashboardStore";
import { Singleton } from '../../../../core/services/Singleton';


export const SelectionTestType = () => {

    const single = Singleton.getInstance()
    const { setLoading } = useDashboardStore();
    const service = new BpmService();
    const [lista, setLista] = useState([]);
    const [bean, setBean] = useState<any>({});
    const [modal, setModal] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalConfirm, setModalConfirm] = useState<any>({ name: 'Confirmar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });
    const [user, setUser] = useState<any>({});

    const idBean = 'IDSelectionTestType';

    const head = [
        { title: 'ID', property: idBean },
        { title: 'Descripción', property: 'Description' },
        { title: 'Peso de la Prueba', property: 'PercentWeigthTest' },
        // { title: 'Tipo de prueba', property: 'IDTypeTest' },


    ]

    useEffect(() => {
        setUser(single.getAccountID());
        getLista();
    }, [idBean])

    const setRowBean = (bean: any) => {
       //console.log("Row : ", bean);
        setBean(bean);

    }

    const getLista = () => {

        setLoading(true);
        service.getSelectionTestTypeCatalogPorPropiedad(null, null, null).subscribe(
            (resp: any) => {

                if (resp.DataBeanProperties.ObjectValue) {
                    setLista(resp.DataBeanProperties.ObjectValue);
                }
                setLoading(false);
            }
        );
    }

    const agregarItem = () => {
        let b = {
            Description: '',
            IDTypeTest: 1,
            PercentWeigthTest: 0,
        }

        setBean(b);
       //console.log('aquiva el bean' + bean)
        setModal({ ...modal, ['view']: true, 'name': 'Agregar' });
    }

    const eliminaritem = () => {
        bean.State = 2;
       //console.log(bean);
        updateItem();
        setModalConfirm({ ...modalConfirm, ['view']: false });
    }

    const updateItem = () => {

       //console.log(bean);
        setLoading(true);
        service.updateSelectionTestType(bean).subscribe(
            (resp: any) => {
                setLoading(false);
                if (resp.DataBeanProperties.ObjectValue) {
                    getLista();
                }
                single.update(bean);
            });
        setModal({ ...modal, ['view']: false });
    }

    const listaBotones = () => {
        const botones = [

            { titulo: 'Editar', icono: 'ri-file-list-line', visible: true, evento: () => { setModal({ ...modal, "view": true }) } },
            { titulo: 'Eliminar', icono: 'ri-file-user-line', visible: true, evento: () => { setModalConfirm({ ...modalConfirm, "view": true }) } },

        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }

    const iconButtonsItem = [
        { nombre: 'Agregar', visible: single.canCreate(), icono: 'ri-file-add-line', evento: agregarItem }
    ]

    return (
        <>
            <BasicPage titulo="Tipos de Prueba de Selección">
                <div className="row">
                    <div className="col-md-12 p-2">
                        <BasicTable listButtons={listaBotones()} iconButtons={iconButtonsItem} head={head} body={lista} rowSet={setRowBean}></BasicTable>
                    </div>
                </div>
            </BasicPage>

            <ModalConfirm modal={modalConfirm} updateModal={setModalConfirm} eventModal={eliminaritem}>
                {bean.Name}
            </ModalConfirm>

            <Modal modal={modal} updateModal={setModal} eventModal={updateItem}>
                <div className="row">
                    <div className="col-md-12">
                        <label className="form-label">Descripción</label>
                        <textarea className="form-control" value={bean.Description} onChange={(e) => { setBean({ ...bean, ['Description']: e.target.value }) }}></textarea>
                    </div>

                    <div className="col-md-6">
                        <label className="form-label">Peso Prueba</label>
                        <input type="number" min={1} maxLength={5} className="form-control" value={bean.PercentWeigthTest} onChange={(e) => { setBean({ ...bean, ['PercentWeigthTest']: parseFloat(e.target.value) }) }} />
                    </div>
                    <div className="col-md-6">
                        <label className="form-label">Tipo de Prueba</label>
                        <SelectTHConstantsValue code={2} value={bean.IDTypeTest} onChange={(e: any) => { setBean({ ...bean, "IDTypeTest": e }) }}></SelectTHConstantsValue>
                    </div>
                </div>
            </Modal>
        </>
    )
}