import { toast } from "react-toastify";
import { _AdquisitionContractCDP } from "../../../../../core/services/dataBean/EntityCatalog";
import useDataBean from "../../../../../core/services/dataBean/useDataBean";
import { BasicTable, MASK_DATE, MASK_NUMBER } from "../../../../theme/Component/BasicTable";
import { useEffect, useState } from "react";
import { Modal } from "../../../../theme/Component/Modal";
import { CDPsEditor } from "../../../../contabilidad/budgetRPFactory/CDPsEditor";
import { PrintJson } from "../../../../theme/Component/PrintJson";
import { ListMenu } from "../../../../theme/Component/ListMenu";
import { Singleton } from "../../../../../core/services/Singleton";
import { ModalConfirm } from "../../../../theme/Component/ModalConfirm";

interface Props {
    adquisitionContract: any;
}

export const AdquisitionContractCDP = ({ adquisitionContract }: Props) => {

    const { lista, setLista, instance, bean, setBean, handleChange, update, deleteItem } = useDataBean(_AdquisitionContractCDP);
    const single = Singleton.getInstance();

    const [modal, setModal] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });
    const [modalC, setModalC] = useState<any>({ name: "Eliminar Item", tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });

    useEffect(() => {
        if (adquisitionContract.IDAdquisitionContract) {
            getLista();
        }
    }, [adquisitionContract])

    const getLista = () => {
        let props = {
            IDAdquisitionContract: adquisitionContract.IDAdquisitionContract,
        }
        instance.getAdquisitionContractCDPCatalogPorPropiedades(props, null).then(
            (resp) => {
                setLista(resp)
            }
        ).catch(
            err => {
                toast.error(err);
            }
        )
    }

    const createCDP = () => {
        setBean({
            IDAdquisitionContract: adquisitionContract.IDAdquisitionContract,
            AdquisitionContractDate: adquisitionContract.AdquisitionContractDate,
            IDAdquisitionItem: adquisitionContract.IDAdquisitionItem,
        });
        setModal({ ...modal, "view": true, "name": "Agregar CDP" });
    }

    const updateItem = () => {
        update().then(
            (resp) => {
                getLista();
                setModal({ ...modal, "view": false })
            }
        ).catch(
            err => { toast.error(err) }
        )
    }

    const deleteObject = () => {
        deleteItem(bean.IDAdquisitionContractCDP).then(
            (resp) => {
                getLista();
                setModalC({ ...modalC, "view": false })
            }
        ).catch(
            err => {
                toast.error(err)
            }
        )
    }

    const head = [
        { title: 'ID', property: 'IDDocument', visible: true, mask: MASK_NUMBER },
        { title: 'Fecha', property: 'DocumentDate', visible: true, mask: MASK_DATE },
        { title: 'Mnemonic', property: 'Mnemonic', visible: true },
        { title: 'Consecutivo', property: 'Consecutive', visible: true },
        { title: 'Estado del Documento', property: 'DocumentStateName', visible: true },
    ]

    const iconButtonsItem = [
        { nombre: 'Agregar', visible: single.canCreate(), icono: 'ri-file-add-line', evento: () => { createCDP() } }
    ]

    const listaBotones = () => {
        const botones = [
            { titulo: 'Eliminar', icono: 'ri-delete-bin-line', visible: single.canDelete(), evento: () => { setModalC({ ...modalC, "view": true }) } },
        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }

    return (
        <div className="row">
            <div className="col-md-12">
                {/* <PrintJson json={adquisitionContract} /> */}
                <BasicTable iconButtons={iconButtonsItem} listButtons={listaBotones()} body={lista} head={head} rowSet={setBean} />
            </div>
            <div className="col-md-12">
                <Modal modal={modal} updateModal={setModal} eventModal={updateItem}>
                    <div className="row">
                        <div className="col-md-12">
                            <CDPsEditor onChange={(e) => { setBean({ ...bean, "IDDocument": e.IDDocument, "DocumentDate": e.Since, "DocumentState": e.DocumentState, "Consecutive": e.Consecutive, "Mnemonic": e.Mnemonic, }) }} />
                        </div>
                    </div>
                </Modal>
            </div>
            <div className="col-md-12">
                <ModalConfirm modal={modalC} updateModal={setModalC} eventModal={deleteObject}>
                    <p>Esta seguro que desea eliminar el item {`${bean.Mnemonic} - ${bean.Consecutive}`}</p>
                </ModalConfirm>
            </div>
        </div>
    )

}