import { DataBean } from "../DataBean";

export class CostingCenter extends DataBean {


    public async getCostingCenterCatalog(idCostingCenter:any): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_getCostingCenterCatalog_Number", [idCostingCenter]);
    }



}