import { useEffect, useState } from "react";
import useDataBean from "../../../../../core/services/dataBean/useDataBean";
import { _ConfigAdmin, _CostingCenter } from "../../../../../core/services/dataBean/EntityCatalogVdos";
import { Singleton } from "../../../../../core/services/Singleton";
import { toast } from "react-toastify";
import { BasicTable, MASK_NUMBER } from "../../../../theme/Component/BasicTable";
import { BasicPage } from "../../../../theme/Component/BasicPage";
import { Modal } from "../../../../theme/Component/Modal";
import { ModalConfirm } from "../../../../theme/Component/ModalConfirm";
import { ListMenu } from "../../../../theme/Component/ListMenu";
import { SelectonfigAdminbyaccount } from "./SelectonfigAdminbyaccount";
import { CostingCenterSelect } from "../../../../erp/cellEditor/CostingCenter/CostingCenterSelect";
import { BsEyeFill, BsFillArrowRightCircleFill } from "react-icons/bs";
import { PrintJson } from "../../../../theme/Component/PrintJson";


interface Props {
    functionaID: any;
}



export const CostingCenter = ({ functionaID }: Props) => {

    const CostingCenter = useDataBean(_ConfigAdmin);

    const single = Singleton.getInstance();
    const [lista, setLista] = useState<any>([]);

    const [modal, setModal] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalC, setModalC] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });



    useEffect(() => {
        // getLista()
        CostingCenter.setBean(functionaID)
    }, [functionaID])




    const getLista = () => {

        // CostingCenter.instance.getCostingCenterCatalog(null).then(
        //     (resp: any) => {
        //         if (resp) {
        //             setLista(resp)

        //            //console.log(resp)
        //             toast.success('Completado')
        //         } else {
        //             toast.error('NO hay datos')
        //         }

        //     }
        // )

    }


    const editaItem = () => {
        CostingCenter.instance.updateFunctionalIDProperties(CostingCenter.bean).then(
            (resp: any) => {
                getLista();
                setModal({ ...modal, "view": false });
               //console.log(resp)
            }
        ).catch(err => {
            toast.error(err);
        })
    }

    const create = () => {

        setModal({ ...modal, "view": true });
    }


    return (
        <>
            {/* <PrintJson json={CostingCenter.bean} />
            <PrintJson json={functionaID} /> */}

            <BasicPage titulo="Modificar Centro de Costo">
                <div className="row">

                    <div className="col-md-12">

                        <label></label>
                        <button className="new button" onClick={() => {
                            create()
                        }}>
                            {/* <div className="row">
                                <div className="col-md-6"><strong>VER CENTROS DE COSTO</strong></div>
                                <div className="col-md-6"> <BsFillArrowRightCircleFill /></div>
                            </div> */}
                            <BsFillArrowRightCircleFill />
                        </button>


                        <Modal modal={modal} updateModal={setModal} eventModal={editaItem}>
                            <>
                                <div className="col-md-12">
                                    <CostingCenterSelect idSelector={CostingCenter.bean.CostingCenter} onChange={(e) => { CostingCenter.setBean({ ...CostingCenter.bean, "CostingCenter": e }) }} />
                                </div>

                            </>
                        </Modal>

                    </div>

                </div>
            </BasicPage>

        </>

    )
}