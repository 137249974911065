import { useEffect, useState } from "react";
import { _ContractType, _PaymentRequirement } from "../../../core/services/dataBean/EntityCatalogVdos";
import useModal, { MODAL_md, MODAL_SM } from "../../theme/Component/hooks/useModal";
import useDataBean from "../../../core/services/dataBean/useDataBean";
import { Singleton } from "../../../core/services/Singleton";
import { toast } from "react-toastify";
import { ModalConfirm } from "../../theme/Component/ModalConfirm";
import { Modal } from "../../theme/Component/Modal";
import { BasicTable } from "../../theme/Component/BasicTable";
import { ListMenu } from "../../theme/Component/ListMenu";
import { BasicPage } from "../../theme/Component/BasicPage";

export const ContractType = () => {

    const ContractType = useDataBean(_ContractType);
    const [bean, setbean] = useState<any>({})
    const single = Singleton.getInstance();
    const [lista, setLista] = useState<any>([]);
    const [Modality, setModality] = useState<any>({});
    const [modal, setModal] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-md' });
    const [modalC, setModalC] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });
    const modalConfirm = useModal("Orden", MODAL_SM);
    const modalEditar = useModal("Orden", MODAL_md);

    useEffect(() => {

        getLista()

    }, [])

    const getLista = () => {

        ContractType.instance.getContractTypeCatalogPorPropiedad('State', 1, null).then(
            (resp: any) => {
                if (resp) {
                    setLista(resp)
                   //console.log(resp)
                }
            }
        ).catch(err =>console.error(err))

    }

    const create = () => {

        modalEditar.open();

        ContractType.setBean({
            State: 1,


        });


    }


    const deleteItem = () => {
        ContractType.bean.State = 2;
       //console.log(bean);
        editaItem();
        modalConfirm.close();
    }



    const editaItem = () => {
       //console.log(ContractType.bean);
        ContractType.update().then(
            (resp: any) => {
                getLista();
                modalEditar.close();

            }
        ).catch(err => {
            toast.error(err);
        })

    }





    const head = [
        { title: 'ID', property: 'IDContractType', visible: true },
        { title: 'Nombre', property: 'Name', visible: true },

        { title: 'Descripción', property: 'Description', visible: true },



    ]

    const iconButtonsItem = [
        { nombre: 'Agregar', visible: single.canCreate(), icono: 'ri-file-add-line', evento: create }
    ]
    const listaBotones = () => {
        const botones = [

            { titulo: 'Editar', icono: 'ri-edit-2-line', visible: single.canEdit(), evento: () => { modalEditar.setModal({ ...modalEditar.modal, "view": true }) } },
            { titulo: 'Eliminar', icono: 'ri-delete-bin-line', visible: single.canDelete(), evento: () => { setModalC({ ...modalC, "view": true }) } },

        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }


    return (

        <div className="row">
            <BasicPage titulo="Tipos de contratación">

                <div className="col-md-12">

                    <BasicTable body={lista} head={head} rowSet={ContractType.setBean} listButtons={listaBotones()} iconButtons={iconButtonsItem}></BasicTable>

                    <Modal modal={modalEditar.modal} updateModal={modalEditar.setModal} eventModal={editaItem}>
                        <div className="row">
                            <div className="col-md-12">
                                <label>Nombre</label>
                                <input type="string" className="form-control" name="Name" value={ContractType.bean.Name} onChange={ContractType.handleChange} />
                            </div>



                            <div className="col-md-12">
                                <label>Descripción</label>
                                <textarea name="Description" className="form-control" rows={5} value={ContractType.bean.Description} onChange={ContractType.handleChange} />
                            </div>



                        </div>
                    </Modal>
                    <ModalConfirm modal={modalC} updateModal={setModalC} eventModal={deleteItem}>
                        <strong>Eliminar item</strong>
                    </ModalConfirm>
                </div>
            </BasicPage>


        </div>

    )
}