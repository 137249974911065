import { useEffect } from "react";
import { _RemoteAbstractAction, _single } from "../../../core/services/dataBean/EntityCatalog";
import useDataBean from "../../../core/services/dataBean/useDataBean";
import { PrintJson } from "../../theme/Component/PrintJson";
import { AbstractStepItem } from "./AbstractStepItem";

interface Props {
    fieldList:any;
}
export const AbstractStep = ({ fieldList }: Props) => {

    const { bean, lista, instance, setBean, setLista } = useDataBean(_RemoteAbstractAction);
    const accordionName = `accordionlefticon_${_single.getRandomInt()}`;


    useEffect(() => {
      
                if (fieldList) {
                    
                    const rows = Object.keys(fieldList);
                    let filas = [];
                    rows.forEach(fila => {
                        filas.push(
                            {
                                ...fieldList[fila], "IDProperty": fila, "LineNumber": fieldList[fila].LineNumber ?? 1,"Index":_single.getRandomInt()
                            }
                        )
                    });                    
                    setLista(filas.sort((a, b) => a.LineNumber - b.LineNumber));
                }
         
    }, [fieldList])

    const head = [
        { title: 'ID', property: 'IDAccountProject', visible: true },
        { title: 'Nombre', property: 'Name', visible: true },
        { title: 'Descripción', property: 'Description', visible: true },
    ]

    return (
        <div className="row">
            <div className="col-md-12">
                <div className="accordion lefticon-accordion custom-accordionwithicon accordion-border-box" id={accordionName}>
                    {lista.map((step: any,index:number) => {
                        return (
                            <div className="accordion-item shadow">
                                <h2 className="accordion-header" id={`${accordionName}-${step.Index}`}>
                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={`#accor_lefticonExamplecollapse${step.Index}`} aria-expanded="false" aria-controls={`accor_lefticonExamplecollapse${step.Index}`}>
                                        <strong>{step.IDProperty} </strong> <br /> <small> ({step.EditorObjectClassForName ? step.EditorObjectClassForName : step.ReturnClassForName})</small>
                                    </button>
                                </h2>
                                <div id={`accor_lefticonExamplecollapse${step.Index}`} className="accordion-collapse collapse" aria-labelledby={`${accordionName}-${step.Index}`} data-bs-parent={`#${accordionName}`}>
                                    <div className="accordion-body">                                        
                                        <AbstractStepItem row={step} />
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>                
            </div>
        </div>
    )
}