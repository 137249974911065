import { DataBean } from "../DataBean";

export class BudgetSource extends DataBean {


    public async getBudgetSourceCatalog(idBudgetSource:any): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_getBudgetSourceCatalog_Number", [idBudgetSource]);
    }

    public async getBudgetLeft(idBudgetType:any,budgetIDStr:any,Date:any): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "Number_getBudgetLeft_Number_String_java.util.Date", [idBudgetType,budgetIDStr,Date]);
    }

    public async createCDP(idBudgetType:any,accountantDate:any,externalConsecutive:any,items:any,description:any,idAccount:any,idEmployee:any,createPdf:any): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "com.quickdataerp.bean.accountant.AccountantDocument_createCDP_Number_java.util.Date_Number_java.util.List_String_Number_Number_Boolean", 
            [idBudgetType,accountantDate,externalConsecutive,items,description,idAccount,idEmployee,createPdf]);
    }
    public async createBudgetRowCDP(accountantDate:any,idBudgetType:number,budgetIDStr:string,idBudgetResource:number,value:number,description:string): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "budget.Budget_createBudgetRowCDP_java.util.Date_Number_String_Number_Number_String", 
            [accountantDate,idBudgetType,budgetIDStr,idBudgetResource,value,description]);
    }
    public async getActualBudgetIDType(): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "com.quickdataerp.bean.budget.BudgetIDType_getActualBudgetIDType_Number", 
            [null]);
    }

    public async getBudgetByArquisitionItem(idAdquisitionItem: number | null): Promise<any> {
        return this.getCustomService("FinanceCore", "java.util.List_getBudgetByArquisitionItem_Number", [idAdquisitionItem]);
    }
}