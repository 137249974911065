import { useState, useEffect } from 'react';
import useDataBean from '../../../../../core/services/dataBean/useDataBean';
import { _EmployeeBonus } from '../../../../../core/services/dataBean/EntityCatalog';

interface Props {
    idSelector: number;
    onChange: any;
    label?: string;
}

export const EmployeeBonusEditor = ({ idSelector, onChange, label }: Props) => {
    const { bean, setBean, lista, setLista, instance } = useDataBean(_EmployeeBonus);
    const [id, setID] = useState(0);

    useEffect(() => {
        setID(idSelector);
    }, [idSelector])

    useEffect(() => {
        getLista();
    }, [])

    const setValue = (e: any) => {
        const newValue = e === "" ? null : parseInt(e);
        setID(newValue);
        onChange(newValue);
    }

    const getLista = () => {
        instance.getEmployeeBonusCatalog(null).then(
            (resp: any) => {
                if (resp) {
                    setLista(resp)
                   //console.log(resp)
                }
            }
        )
    }

    return (
        <>
            <label>{label ?? "Tipo de Ingreso"}</label>
            <select className="form-select" value={id} onChange={(e) => { setValue(e.target.value) }}>
                <option value="">...</option>
                {lista &&
                    lista.map((item: any, index: number) => {
                        return (
                            <option key={index} value={item.DataBeanProperties.IDEmploymentBonus}>
                                {item.DataBeanProperties.Name}
                            </option>
                        )
                    })
                }
            </select>
        </>
    )
}
