
// import { SelectModality } from "./select/SelectModality";
// import { SelectLegalTypeTermsConstants } from "./select/SelectLegalTypeTermsConstants";
import { useState, useEffect } from 'react';
import { ContractService } from "../../../../core/services/ContractService";
import { Singleton } from "../../../../core/services/Singleton";
import { BasicPage } from '../../../theme/Component/BasicPage';
import { BasicTable } from '../../../theme/Component/BasicTable';
import { ModalConfirm } from '../../../theme/Component/ModalConfirm';
import { Modal } from '../../../theme/Component/Modal';
import { BasicButton } from '../../../theme/Component/BasicButton';
import { BasicSwitch } from '../../../theme/Component/BasicSwitch';
import { DataBeanProperties, ObjectValue } from '../../../../core/services/model/server-response.interface';
import { map } from 'rxjs';
import { SelectJsonDataTypes } from '../../../theme/Component/SelectJsonDataTypes';
import { ListMenu } from '../../../theme/Component/ListMenu';
import { useDashboardStore } from '../../../pages/hooks/useDashboardStore';

interface Props {
    IDform: number;


}

export const Tablaaccionesformularios = ({ IDform, }: Props) => {


    const single = Singleton.getInstance()
    const { setLoading } = useDashboardStore();
    const service = new ContractService();
    const [id, setID] = useState(0);
    const [lista, setLista] = useState([]);
    const [bean, setBean] = useState<any>({});
    const [modal, setModal] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-md' });
    const [modalConfirm, setModalConfirm] = useState<any>({ name: 'Confirmar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });


    const idBean = 'IDResponseValue';

    const head = [

        { title: 'ID', property: idBean },
        { title: ' Name', property: 'Name' },
        { title: 'ResponseClass', property: 'ResponseClass' },
        { title: 'LimitedWithValues', property: 'LimitedWithValues' },
    ]


    const [types, setTypes] = useState<any>([]);


    useEffect(() => {
        getLista();
        getJsonDataTypes();
    }, [IDform])


    const setRowBean = (bean: any) => {
        //console.log("Row : ", bean);
        setBean(bean);

    }

    const getLista = () => {

        setLoading(true);
        service.getResponseValueForForm(IDform).subscribe(
            (resp: any) => {

                if (resp.DataBeanProperties.ObjectValue) {
                    setLista(resp.DataBeanProperties.ObjectValue);
                }

                setLoading(false);
            }
        );
    }

    const getJsonDataTypes = () => {
        setLoading(true);
        service.getJsonDataTypes().subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue) {
                    setTypes(resp.DataBeanProperties.ObjectValue);
                }
            }
        )
    }


    const agregarItem = () => {

        let b = {

            Name: '',
            ResponseClass: "",
            LimitedValues: "",
            LimitedWithValues: false,
            Validated: false,
            IDForm: IDform,
            JsonServiceName: null,
            IDJsonService: null,
            Description: '',
        }

        setBean(b);
        setModal({ ...modal, 'view': true });

    }
    const editarItem = () => {

        setModal({ ...modal, ['view']: true });

    }

    const eliminarItem = () => {

        setLoading(true);
        service.deleteResponseValue(bean[idBean]).subscribe(
            (resp: any) => {
                setLoading(false);
                if (resp) {
                    getLista();
                }
            }
        );
        setModalConfirm({ ...modalConfirm, ['view']: false });
    }

    const updateItem = () => {

        setModal({ ...modal, ['view']: false });

        setLoading(true);
        service.addResponseValue(bean).subscribe(
            (resp: any) => {
                setLoading(false);
                if (resp.DataBeanProperties.ObjectValue) {
                    getLista();
                }
            });

        setModal({ ...modal, "view": false })
        //console.log(bean);

    }

    const setValue = (e: any) => {
        setID(parseInt(e));
        //  onChange(id);
    }

    const iconButtonsItem = [
        { nombre: 'Agregar', visible: single.canCreate(), icono: 'ri-file-add-line', evento: agregarItem }
    ]

    const listaBotones = () => {
        const botones = [
            { titulo: 'Editar', icono: 'ri-file-list-line', visible: true, evento: () => { setModal({ ...modal, "view": true }) } },
            { titulo: 'Eliminar', icono: 'ri-file-user-line', visible: true, evento: () => { setModalConfirm({ ...modalConfirm, "view": true }) } },
        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }

    return (
        <>

            <BasicTable iconButtons={iconButtonsItem} listButtons={listaBotones()} head={head} body={lista} rowSet={setRowBean}></BasicTable>


            <ModalConfirm modal={modalConfirm} updateModal={setModalConfirm} eventModal={eliminarItem}>
                {bean.Name}
            </ModalConfirm>

            <Modal modal={modal} updateModal={setModal} eventModal={updateItem}>
                <div className="row">
                    <div className="col-md-12">
                        <label className="form-label">Nombre</label>
                        <input type="text" className="form-control" value={bean.Name} onChange={(e) => { setBean({ ...bean, ['Name']: e.target.value }) }} />
                    </div>
                    <div className="col-md-8">
                        <SelectJsonDataTypes label="Clase de respuesta"
                            _onChange={(e: any) => { setBean({ ...bean, ['ResponseClass']: e.target.value }) }}
                            valor={bean.ResponseClass}
                        ></SelectJsonDataTypes>
                    </div>
                    {(bean.ResponseClass == 'Number' || bean.ResponseClass == 'String') &&
                        <div className="col-md-4">
                            <label>Limitar valores</label>
                            <BasicSwitch estado={bean.LimitedWithValues} eventChange={(e: any) => {
                                setBean({ ...bean, "LimitedWithValues": e })
                            }}></BasicSwitch>
                        </div>
                    }
                    {bean.LimitedWithValues &&
                        <>
                            <div className="col-md-12">
                                <label className="form-label">Valores Cerrados</label>
                                <textarea className="form-control" value={bean.LimitedValues} onChange={(e) => { setBean({ ...bean, ['LimitedValues']: e.target.value }) }}></textarea>
                            </div>
                            <div className="col-md-12">
                                <label className="form-label">Formato</label>
                                <textarea className="form-control" value={bean.Description} onChange={(e) => { setBean({ ...bean, ['Description']: e.target.value }) }}></textarea>
                            </div>
                        </>
                    }
                </div>
            </Modal>
        </>
    )
}