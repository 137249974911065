import { Observable, map, single } from 'rxjs';
import http from "../services/api/api";
import { env } from "../../env";
import { Singleton } from './Singleton';

export class FileService {


    private baseURL = env.REACT_APP_ENDPOINT + env.REACT_APP_DEPLOY + env.REACT_APP_FILE;
    private base: string = env.REACT_APP_ENDPOINT + env.REACT_APP_DEPLOY;

    private single = Singleton.getInstance();

    public getUrlFile(contextMedia: string, media: string): string {
        let url = "";

        if (contextMedia && media) {
           //console.log(this.baseURL);
            if (this.baseURL === '') {
                url = this.base + "/filedownload?ContextMedia@=" + contextMedia + "@@Media@=" + media;
            } else {
                url = this.base + "/filedownload?ContextMedia@=" + contextMedia + "@@Media@=" + media;
            }
        }
        return url;
    }

    public async getPdfAsBase64(url: string): Promise<string | null> {
        try {
            // Obtener el PDF usando Fetch
            const response = await fetch(url);
            const blob = await response.blob();

            // Convertir el blob a base64
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onloadend = () => {
                    const base64data = reader.result as string;
                    resolve(base64data.split(',')[1]); // Separar el encabezado base64
                };
                reader.onerror = reject;
                reader.readAsDataURL(blob);
            });
        } catch (error) {
            console.error('Error al obtener el PDF:', error);
            return null;
        }
    }

    async getPdfAsBase642(url: string): Promise<string> {                
        const response = await fetch(url);
        if (!response.ok) {
            throw new Error(`Failed to fetch PDF: ${response.statusText}`);
        }
        const blob = await response.blob();
        return new Promise<string>((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64data = reader.result as string;
                resolve(base64data);
            };
            reader.onerror = reject;
            reader.readAsDataURL(blob);
        });
    }

    public postFile(imagenParaSubir: File, directory?: string) {
        const data = {
            SessionID: 0,
            Zipped: 0,
            PartIndex: 0,
            DataStore: null,
            SerializerType: "json",
            Filename: imagenParaSubir.name,
            Directory: directory ?? "docserp",
            IDAccount: this.single.getAccountID(),
            Function: "upload",
        };
        const formData = new FormData();
        formData.append("FileUpload", imagenParaSubir, JSON.stringify(data));

        return http.post(this.baseURL, formData);
    }
}