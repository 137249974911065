import { useState } from 'react'
import { MenuButton } from '../MenuButton/MenuButton'
import { QuickAction } from '../MenuDashed/MenuDashed'
import { useNavigate } from 'react-router-dom'
import { PiArticleNyTimes, PiBagSimple, PiFilesFill, PiFolderOpenFill, PiStudent, PiUsersThree } from 'react-icons/pi'
import { PositionObjetive } from './ComponentContract/PositionObjetive'
import { BasicButton } from '../../../../theme/Component/BasicButton'
import { AppointmentManualUse } from './ComponentContract/AppointmentManualUse'
import { AcademicInformation } from '../../../../Talentohumano/GestionTH/AcademicInformation/AcademicInformation'
import { WorkExperience } from '../../../../Talentohumano/GestionTH/WorkExperience/WorkExperience'
import { FamilyGroup } from '../../../../Talentohumano/GestionTH/FamilyGroup/FamilyGroup'

export const ContracsDashedMenu = () => {

    const [activeTab, setActiveTab] = useState(0)
    const navigate = useNavigate();
    const [tap, setTap] = useState<number>(0)

    const certificateTabs: QuickAction[] = [
        {
            label: 'Objetivos de Desempeño',
            icon: <PiFolderOpenFill />,
            event: () => { setTap(1) }

        },
        {
            label: 'Ver Manual de Funciones y Competencias',
            icon: <PiFilesFill />,
            event: () => { setTap(2) }

        },
        {
            label: 'Información Académica',
            icon: <PiStudent />,
            event: () => { setTap(3) }

        },
        {
            label: 'Experiencia Laboral',
            icon: <PiBagSimple />,
            event: () => { setTap(4) }

        },
        {
            label: 'Grupo Familiar',
            icon: <PiUsersThree />,
            event: () => { setTap(5) }

        },
    ]

    return (
        <>
            {tap === 0 &&
                <div className="row m-2 d-flex justify-content-between">
                    {certificateTabs.map((e, i) =>
                        <div className="align-items-center col-6 my-3 mx-auto mb-3">
                            <MenuButton key={i} item={e} labelSize={16} />
                        </div>
                    )}
                </div>
            }
            {tap === 1 &&
                <div className="p-3">
                    <BasicButton icon='ri-arrow-left-line' eventClick={() => setTap(0)}>Volver</BasicButton>
                    <PositionObjetive dashedMenu={true} />
                </div>
            }
            {tap === 2 &&
                <div className="p-3">
                    <BasicButton icon='ri-arrow-left-line' eventClick={() => setTap(0)}>Volver</BasicButton>
                    <AppointmentManualUse dashedMenu={true} />
                </div>
            }
            {tap === 3 &&
                <div className="p-3">
                    <BasicButton icon='ri-arrow-left-line' eventClick={() => setTap(0)}>Volver</BasicButton>
                    <AcademicInformation dashedMenu />
                </div>
            }
            {tap === 4 &&
                <div className="p-3">
                    <BasicButton icon='ri-arrow-left-line' eventClick={() => setTap(0)}>Volver</BasicButton>
                    <WorkExperience dashedMenu />
                </div>
            }
            {tap === 5 &&
                <div className="p-3">
                    <BasicButton icon='ri-arrow-left-line' eventClick={() => setTap(0)}>Volver</BasicButton>
                    <FamilyGroup dashedMenu />
                </div>
            }
        </>
    )
}
