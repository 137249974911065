import { AdminService } from "../../core/services/AdminService";
import { BasicPage } from "../theme/Component/BasicPage"
import { useEffect, useState } from 'react';
import { BasicTable } from "../theme/Component/BasicTable";
import { BasicButton } from '../theme/Component/BasicButton';
import { Modal } from '../theme/Component/Modal';
import { ModalConfirm } from "../theme/Component/ModalConfirm";
import { AccountEditor } from "../editor/AccountEditor";
import { useDashboardStore } from "../pages/hooks/useDashboardStore";

export const AccountProject = () => {

    const { setLoading } = useDashboardStore();
    const service: AdminService = new AdminService();
    const [lista, setLista] = useState([]);
    const [idSoftwareProject, setIDSoftwareProject] = useState(0);
    const [listaProject, setListaProject] = useState([]);
    const [idRolDevops, setIDRolDevops] = useState(0);
    const [idAccount, setIDAccount] = useState(0);
    const [message, setMessage] = useState('');
    const [listaRol, setListaRol] = useState([]);
    const [bean, setBean] = useState<any>({});
    const [modal, setModal] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalConfirm, setModalConfirm] = useState<any>({ name: 'Confirmar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });

    const head = [
        { title: 'ID', property: 'IDAccountProject' },
        { title: 'IDAccount', property: 'IDAccount' },
        { title: 'IDSoftwareProject', property: 'IDSoftwareProject' },
        { title: 'Rol', property: 'Rol' }

    ]

    useEffect(() => {

        getSoftwareProjectCatalog();
        getRolDevopsCatalogPorPropiedad();
        getAccountProjectCatalogPorPropiedad();

    }, [])



    const getAccountProjectCatalogPorPropiedad = () => {

        setLoading(true);
        service.getAccountProjectCatalogPorPropiedad(null, null, null).subscribe(
            (resp: any) => {
                setLista([]);
               //console.log("Lista Accounts ", resp);
                if (resp.DataBeanProperties.ObjectValue) {
                    setLista(resp.DataBeanProperties.ObjectValue);
                }
                setLoading(false);
            }
        );

    }

    const agregarItem = () => {
        let b = {
            State: 1,
            IDAccount: 0,
            IDSoftwareProject: 0,
            Rol: ''
        }
        setBean(b);
        setModal({ ...modal, ['view']: true });

    }
    const editarItem = () => {

        setModal({ ...modal, ['view']: true });

    }
    const eliminarItem = () => {

        setLoading(true);
        service.deleteAccountProject(bean.IDAccountProject).subscribe(
            (resp: any) => {
                setLoading(false);
                if (resp) {
                    getAccountProjectCatalogPorPropiedad();
                }
            }
        );
        setModalConfirm({ ...modalConfirm, ['view']: false });

    }
    const updateItem = () => {


        let be = bean;
        be.IDSoftwareProject = idSoftwareProject;
        be.Rol = idRolDevops;


        if (idSoftwareProject > 0 && idRolDevops > 0 && be.IDAccount > 0) {
            setLoading(true);
            service.updateAccountProject(bean).subscribe(
                (resp: any) => {
                    setLoading(false);
                    if (resp.DataBeanProperties.ObjectValue) {
                        getAccountProjectCatalogPorPropiedad();
                        setModal({ ...modal, ['view']: false });
                    }

                });
        } else {
            setMessage("faltan campos en el formulario")
        }



    }

    const setRowBean = (bean: any) => {
       //console.log("Row : ", bean);

        setIDAccount(bean.IDAccount);
        setIDSoftwareProject(bean.IDSoftwareProject);
        setIDRolDevops(bean.Rol);
        setBean(bean);

    }


    //selectores
    const getSoftwareProjectCatalog = () => {

        setLoading(true);
        service.getSoftwareProjectCatalog().subscribe(
            (resp: any) => {

               //console.log("Application", resp);
                if (resp.DataBeanProperties.ObjectValue) {
                    setListaProject(resp.DataBeanProperties.ObjectValue);
                }
                setLoading(false);
            }
        );

    }
    const getRolDevopsCatalogPorPropiedad = () => {

        setLoading(true);
        service.getRolDevopsCatalogPorPropiedad(null, null, null).subscribe(
            (resp: any) => {

               //console.log("Application", resp);
                if (resp.DataBeanProperties.ObjectValue) {
                    setListaRol(resp.DataBeanProperties.ObjectValue);
                }
                setLoading(false);
            }
        );

    }

    const setBeanAccount = (b: any) => {
       //console.log("bean ", b);
        let ba = bean;
        bean.IDAccount = b.IDAccount;
        setBean(ba);
       //console.log("Actualizacion bean ", bean);

    }

    return (
        <>
            <BasicPage titulo="Usuario en Proyecto">
                <div className="row">
                    <div className="col-md-12">
                        <BasicButton value="Agregar" icon="ri-file-add-line" eventClick={agregarItem} ></BasicButton>
                        {bean.IDAccountProject &&
                            <>
                                <BasicButton value="Editar" icon="ri-edit-2-line" eventClick={editarItem} ></BasicButton>
                                <BasicButton value="Eliminar" icon="ri-eraser-line" eventClick={() => { setModalConfirm({ ...modalConfirm, ['view']: true }); }} ></BasicButton>
                            </>
                        }

                    </div>
                    <div className="col-md-12 p-2">
                        <BasicTable head={head} body={lista} rowSet={setRowBean}></BasicTable>
                    </div>
                </div>
            </BasicPage>

            <ModalConfirm modal={modalConfirm} updateModal={setModalConfirm} eventModal={eliminarItem}>
                {bean.Name}
            </ModalConfirm >

            <Modal modal={modal} updateModal={setModal} eventModal={updateItem}>
                <div className="row">
                    <div className="col-md-6">
                        <label className="form-label">Proyecto</label>
                        <select className="form-control" value={idSoftwareProject} onChange={(e) => { setIDSoftwareProject(parseInt(e.target.value)) }}>
                            <option value="0">...</option>
                            {listaProject.map((item: any) => {
                                return (
                                    <option value={item.DataBeanProperties.IDSoftwareProject}>{item.DataBeanProperties.Name}</option>
                                )
                            })}
                        </select>
                    </div>
                    <div className="col-md-6">
                        <label className="form-label">Rol</label>
                        <select className="form-control" value={idRolDevops} onChange={(e) => { setIDRolDevops(parseInt(e.target.value)) }}>
                            <option value="0">...</option>
                            {listaRol.map((item: any) => {
                                return (
                                    <option value={item.DataBeanProperties.IDRolDevops}>{item.DataBeanProperties.Name}</option>
                                )
                            })}
                        </select>
                    </div>
                    <div className="col-md-6">
                        <AccountEditor idAccount={bean.IDAccount} label="Usuario" onChange={setBeanAccount} />
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        {/* <MessageDialog show={message!='' ? true : false}>{message}</MessageDialog> */}
                    </div>
                </div>

            </Modal>
        </>
    )
}