import { useEffect, useState } from "react";
import { useDashboardStore } from "../../../pages/hooks/useDashboardStore";
import { ContractService } from "../../../../core/services/ContractService";
import { FileService } from "../../../../core/services/FileService";
import { Alert } from "../../../theme/Component/Alert";
import { Singleton } from "../../../../core/services/Singleton";
import { PrintJson } from "../../../theme/Component/PrintJson";

interface Props {
    _BeanJ: any;
    _AdquisitionItem: { IDAdquisitionItem, IDHiringProcess, Justification, Description };
}
export const PDFJuridico = ({ _BeanJ, _AdquisitionItem }: Props) => {

    const [renderCoverage, setrenderCoverage] = useState<any>({});
    const [renderObligation, setrenderObligation] = useState<any>({});
    const [renderSST, setrenderSST] = useState<any>({});
    const { setLoading } = useDashboardStore();
    const service = new ContractService();
    const fileService = new FileService();
    const [listaA, setListaA] = useState<any>([]);
    const [renderFactorMatrix, setRenderFactorMatrix] = useState([]);
    const [employee, setEmployee] = useState<any>([]);

    useEffect(() => {
        getAdquisitionArticleCatalog();
        getOfferEmployee();
        getHiringConfig();
        if (_BeanJ.Coverage) {
            setrenderCoverage(JSON.parse(_BeanJ.Coverage))
        } else { }
        if (_BeanJ.ObligationList) {
            setrenderObligation(JSON.parse(_BeanJ.ObligationList))
        } else { }
        if (_BeanJ.SST) {
            setrenderSST(JSON.parse(_BeanJ.SST))
        } else { }
    }, [_BeanJ])

    const getAdquisitionArticleCatalog = () => {

        setLoading(true);
        service.getAdquisitionArticleCatalog(_AdquisitionItem.IDAdquisitionItem, null, null, null).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue.DataBeanProperties.List) {
                    let tmp = resp.DataBeanProperties.ObjectValue.DataBeanProperties.List;
                    tmp.forEach((element: any) => {
                        if (element.DataBeanProperties.MediaContext) {
                            element.DataBeanProperties.URL = ` <a href="${fileService.getUrlFile(element.DataBeanProperties.MediaContext, element.DataBeanProperties.Media)}" target='_blank'>Ver</a>`
                        } else {
                            element.DataBeanProperties.URL = "";
                        }
                    });
                    setListaA(tmp);
                } else {
                    setListaA([]);
                }
                setLoading(false);
            }
        );
    }

    const getHiringConfig = () => {
        setLoading(true);
        service.getHiringProcessConfigCatalogPorPropiedad("IDHiringProcess", _AdquisitionItem.IDHiringProcess, null).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue) {
                    let obj = resp.DataBeanProperties.ObjectValue;
                    if (obj.length > 0) {
                        let c: any = obj[0].DataBeanProperties;
                        if (c.FactorMatrix) {
                            setRenderFactorMatrix(JSON.parse(c.FactorMatrix))
                        }
                    }
                }
                setLoading(false);
            }
        )
    }

    // const getOfferEmployee = () => {
    //     let propiedades = {
    //         State: 1,
    //         StateEmployee: 10,
    //     }
    //     setLoading(true);
    //     service.getOfferEmployeeCatalogPorPropiedades(propiedades, null).subscribe(
    //         (resp: any) => {
    //             if (resp.DataBeanProperties.ObjectValue) {
    //                 setEmployee(resp.DataBeanProperties.ObjectValue);
    //             }
    //             setLoading(false);
    //         }
    //     )
    // }

    const getOfferEmployee = () => {
        setLoading(true);
        service.getOfferEmployeeCatalogRender(_AdquisitionItem.IDAdquisitionItem).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue) {
                    setEmployee(resp.DataBeanProperties.ObjectValue);
                }
                setLoading(false);
            }
        )
    }

    return (
        <>
            <div className="row ">
                <div className="col-12 text-center color-celda celda">
                    <h5>ESTUDIO PREVIO PERSONA JURIDICA</h5>
                </div>
            </div>
            <div className="row ">
                <div className="col-12 color-celda">
                    <label className="titul-form celda-titles mb-2" >Radicado: </label>
                </div>
            </div>
            <div className="row ">
                <div className="col-12 text-center color-celda celda">
                    <h5>JUSTIFICACIÓN DE LA CONTRATACIÓN</h5>
                </div>
            </div>
            <div className="row">
                <div className="col-12 color-celda">
                    <label className="titul-form celda-titles" >Tipo de Solicitud: </label>
                    <div className="div" dangerouslySetInnerHTML={{ __html: Singleton.getInstance().formatField(_BeanJ.Justification ?? _AdquisitionItem.Justification) }}></div>
                </div>
                <div className="col-12 color-celda">
                    <label className="titul-form celda-titles" >Objeto de contrato: </label>
                    <div className="div" dangerouslySetInnerHTML={{ __html: Singleton.getInstance().formatField(_BeanJ.ObjectContract ?? _AdquisitionItem.Description) }}></div>
                </div>
            </div>
            <div className="row ">
                <div className="col-12 text-center color-celda celda">
                    <h5>DESCRIPCIÓN DEL BIEN/SERVICIO REQUERIDO</h5>
                </div>
            </div>

            <div className="row">
                {listaA.length > 0 &&
                    <>


                        {listaA.map((element: any, index: number) => {
                            return (
                                <>
                                    <div className="col-2 color-celda matriz-content">
                                        <h3>{index + 1}</h3>
                                    </div>
                                    <div className="col-10 color-celda">
                                        <p ><strong className="celda-titles">Articulo:  </strong>{element.DataBeanProperties.TechnicalSpecifications}</p>
                                        <p ><strong className="celda-titles">Rubro:  </strong>{element.DataBeanProperties.Quantity}</p>
                                        <p ><strong className="celda-titles">Articulo:  </strong>{element.DataBeanProperties.IDLnBudgetIDName}</p>
                                        <p ><strong className="celda-titles">Código unspsc:  </strong>{element.DataBeanProperties.IDLnUnspscIDName}</p>
                                    </div >
                                </>
                            )
                        })}
                    </>
                }
                {listaA.length == 0 &&
                    <div className="col-12 color-celda">
                        <label>No Presenta Articulos</label>
                    </div>
                }
            </div >
            <div className="row ">
                <div className="col-12 text-center color-celda celda">
                    <h5>COBERTURA</h5>
                </div>
            </div>
            <div className="row ">
                <div className="col-4 color-celda">
                    <label className="titul-form celda-titles" >Nombre: </label>
                    <p>{renderCoverage.Name}</p>
                </div>
                <div className="col-4 color-celda">
                    <label className="titul-form celda-titles" >Dirección: </label>
                    <p>{renderCoverage.Address}</p>
                </div>
                <div className="col-4 color-celda">
                    <label className="titul-form celda-titles" >Ciudad: </label>
                    <p>{renderCoverage.SiteIDLnName}</p>
                </div>
                <div className="col-12 color-celda">
                    <label className="titul-form celda-titles" >Dependencia encargada de la Recepción del bien/servicio en Caja Honor: </label>
                    <p>{_BeanJ.Dependence}</p>
                </div>
            </div>

            {renderObligation.length > 0 &&
                <>
                    <div className="row ">
                        <div className="col-12 text-center color-celda celda">
                            <h5>OBLIGACIONES PRINCIPALES</h5>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 color-celda">
                            {renderObligation.map((element: any, index: number) => {
                                return (

                                    <p>{index + 1} - {element.Name}</p>
                                )
                            })}
                        </div>
                    </div>
                </>
            }
            {renderObligation.length == 0 &&
                <div className="row">
                    <div className="col-12 color-celda">
                        <label>No Presenta Obligaciones</label>
                    </div>
                </div>
            }
            <div className="row">
                {renderSST.length > 0 &&
                    <>

                        <div className="col-12 text-center color-celda celda">
                            <h5> Requisitos del SG-SST que aplican bien y/o servicio solicitado:</h5>
                        </div>

                        <div className="col-12 color-celda">
                            {renderSST.map((element: any, index: number) => {
                                return (

                                    <p>{index + 1} - {element.Name}</p>
                                )
                            })}
                        </div>
                    </>
                }
                {renderSST.length == 0 &&
                    <div className="col-12 color-celda">
                        <label>No Presenta Requisitos del SG-SST</label>
                    </div>
                }
            </div>

            {/* {renderFactorMatrix &&
                <>
                    <div className="row ">
                        <div className="col-12 text-center color-celda celda">
                            <h5>Factores a tener en cuenta para seleccionar el proveedor:</h5>
                        </div>
                    </div>
                    {renderFactorMatrix.length > 0 &&
                        <>
                            <div className="row">
                                <div className="col-4 color-celda matriz-tittles">
                                    <p>Categoria</p>
                                </div>
                                <div className="col-8 color-celda">
                                    <div className="row" >
                                        <div className="col-8 color-celda matriz-tittles">
                                            <p>Factor</p>
                                        </div>
                                        <div className="col-4 color-celda matriz-tittles">
                                            <p>Aplica</p>
                                        </div>
                                    </div>
                                </div>
                                {renderFactorMatrix.map((element: any, index: number) => {
                                    return (
                                        <>
                                            <div key={index} className="col-4 color-celda matriz-content">
                                                <div className="col-12" style={{ textAlign: "center" }}>
                                                    <p>{element.Name}</p>
                                                </div>
                                            </div>
                                            {element.Child.length > 0 &&
                                                <>
                                                    <div className="col-8">
                                                        {element.Child.map((hijos: any) => {
                                                            return (
                                                                <>
                                                                    <div className="row">
                                                                        <div className="col-8 color-celda matriz-content">
                                                                            <p>{hijos.Description}</p>
                                                                        </div>
                                                                        <div className="col-4 color-celda matriz-content">
                                                                            <p>{hijos.isActive ? 'Si' : 'No'}</p>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            )
                                                        })}
                                                    </div>
                                                </>
                                            }
                                            {element.Child.length == 0 &&
                                                <>
                                                    <div className="col-8 color-celda matriz-content">
                                                        <p>No presenta facotres para esta categoria.</p>
                                                    </div>
                                                </>
                                            }
                                        </>
                                    )
                                })}
                            </div>
                        </>
                    }
                    {renderFactorMatrix.length == 0 &&
                        <div className="row ">
                            <Alert margin="m-0" clase="warning">
                                <strong>No Presenta ningun riesgo en la matriz de Riesgos</strong>
                            </Alert>
                        </div>
                    }

                </>
            } */}

            <div className="row ">
                <div className="col-12 text-center color-celda celda">
                    <h5>MATRIZ DE RIESGOS PARA LA CONTRATACIÓN DEL BIEN/SERVICIO</h5>
                </div>
            </div>
            {_BeanJ.RiskMatrix.length > 0 &&
                <>
                    <div className="row">
                        <div className="col-2 color-celda matriz-tittles">
                            <p>Categoria</p>
                        </div>
                        <div className="col-10 color-celda">
                            <div className="row" >
                                <div className="col-4 color-celda matriz-tittles">
                                    <p>Riesgo</p>
                                </div>
                                <div className="col-1 color-celda matriz-tittles">
                                    <p>Aplica</p>
                                </div>
                                <div className="col-1 color-celda matriz-tittles">
                                    <p>Probabilidad</p>
                                </div>
                                <div className="col-1 color-celda matriz-tittles">
                                    <p>Impacto</p>
                                </div>
                                <div className="col-1 color-celda matriz-tittles">
                                    <p>Prioridad 1-25</p>
                                </div>
                                <div className="col-2 color-celda matriz-tittles">
                                    <p>Valoración Riesgo</p>
                                </div>
                                <div className="col-2 color-celda matriz-tittles">
                                    <p>Responsable</p>
                                </div>
                            </div>
                        </div>
                        {_BeanJ.RiskMatrix.map((element: any, index: number) => {
                            return (
                                <>
                                    <div key={index} className="col-2 color-celda" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <div className="col-12" style={{ textAlign: "center" }}>
                                            <p>{element.Description}</p>
                                        </div>
                                    </div>
                                    <div className="col-10">
                                        {element.Childs.length > 0 &&
                                            <>
                                                {element.Childs.map((hijos: any, index: number) => {
                                                    return (
                                                        <>
                                                            <div className="row">
                                                                <div className="col-4 color-celda matriz-content">
                                                                    <p>{hijos.Risk}</p>
                                                                </div>
                                                                <div className="col-1 color-celda matriz-content">
                                                                    <p>{hijos.Apply ? 'Si' : 'No'}</p>
                                                                </div>
                                                                <div className="col-1 color-celda matriz-content">
                                                                    <p>{hijos.Probability}</p>
                                                                </div>
                                                                <div className="col-1 color-celda matriz-content">
                                                                    <p>{hijos.Impact}</p>
                                                                </div>
                                                                <div className="col-1 color-celda matriz-content">
                                                                    <p>{parseInt(hijos.Probability) * parseInt(hijos.Impact)}</p>
                                                                </div>
                                                                <div className="col-2 color-celda matriz-content">
                                                                    <p>{hijos.Apply == true ? hijos.Valuation : "No Aplica"}</p>
                                                                </div>
                                                                <div className="col-2 color-celda matriz-content" >
                                                                    <p>{hijos.Apply == true ? hijos.Responsible : "No Aplica"}</p>
                                                                </div>
                                                            </div>
                                                        </>
                                                    )
                                                })}
                                            </>
                                        }
                                    </div>
                                </>
                            )
                        })}
                    </div>
                </>
            }
            {_BeanJ.RiskMatrix.length == 0 &&
                <div className="row">
                    <Alert clase="warning">
                        <strong>No presenta ningun riesgo en la matriz de Riesgos</strong>
                    </Alert>
                </div>
            }
            <div className="row ">
                <div className="col-12 text-center color-celda celda">
                    <h5>Supervisores</h5>
                </div>
            </div>
            {employee.length > 0 &&
                <>
                    <div className="row">
                        <div className="col-1 color-celda matriz-tittles">
                            <p>#</p>
                        </div>
                        <div className="col-7 color-celda matriz-tittles">
                            <p>Usario</p>
                        </div>
                        <div className="col-4 color-celda matriz-tittles">
                            <p>Estado</p>
                        </div>
                    </div>
                    {employee.map((element: any, index: number) => {
                        return (
                            <div className="row">
                                <div className="col-1 color-celda matriz-content">
                                    <h4>{index + 1}</h4>
                                </div>
                                <div className="col-md-7 color-celda matriz-content">
                                    <p>{element.DataBeanProperties.IDOfferEmployee} - {element.DataBeanProperties.EntityName}</p>
                                </div>
                                <div className="col-md-4 color-celda matriz-content">
                                    <p>{element.DataBeanProperties.StateEmployeeName}</p>
                                </div>
                            </div>
                        )
                    })}
                </>
            }
            {employee.length == 0 &&
                <div className="row">
                    <Alert margin="m-0" clase="warning">
                        <strong>No presenta supervisores</strong>
                    </Alert>
                </div>
            }
        </>
    )
}