import { useEffect, useState } from "react";
import useDataBean from "../../../../../../core/services/dataBean/useDataBean";
import { _EmployeeContractHistory } from "../../../../../../core/services/dataBean/EntityCatalog";
import { Singleton } from "../../../../../../core/services/Singleton";
import { ListMenu } from "../../../../../theme/Component/ListMenu";
import { BasicTable, MASK_DATE, MASK_MONEY, MASK_NUMBER } from "../../../../../theme/Component/BasicTable";
import { Modal } from "../../../../../theme/Component/Modal";
import { InputDate } from "../../../../../theme/Input/InputDate";
import { AppointmentEditor } from "../../../../ParametrosTalentoHumano/Appointment/Appointment.editor";


interface PropAbility {
    _IDContract: number;
}

export const ContractHistory = ({ _IDContract }: PropAbility) => {

    const hisotryContract = useDataBean(_EmployeeContractHistory);
    const single = Singleton.getInstance();
    const [lista, setLista] = useState<any>([]);
    const [bean, setBean] = useState<any>({});
    const [modalAppointment, setModalAppointment] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-md' });
    const [modalSalary, setModalSalary] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-md' });

    useEffect(() => {
        if (_IDContract >= 0) {
            getLista();
        }
    }, [_IDContract])

    const getLista = () => {
        hisotryContract.instance.getEmployeeContractHistoryCatalog(_IDContract).then(
            (resp) => {
               //console.log(resp)
                setLista(resp)
            }
        )
    }

    const updateSalary = () => {
        hisotryContract.instance.updateSalaryForEmployeeContractHistory(bean.IDContractHistory, parseInt(bean.LastSalary), single.getAccountID()).then(
            (resp) => {
               //console.log(resp)
                single.update({ IDContractHistory: bean.IDContractHistory, LastSalary: bean.LastSalary, LastUpdateEmployee: single.getAccountID() })
                setModalSalary({ ...modalSalary, "view": false });
                setBean({})
            }
        )
    }

    const updateAppointment = () => {
        hisotryContract.instance.updateAppointmentForEmployeeContractHistory(bean.IDContractHistory, bean.IDAppointment, single.getAccountID()).then(
            (resp) => {
               //console.log(resp)
                single.update({ IDContractHistory: bean.IDContractHistory, IDAppointment: bean.IDAppointment, LastUpdateEmployee: single.getAccountID() })
                setModalAppointment({ ...modalAppointment, "view": false });
                setBean({})
            }
        )
    }

    const head = [
        { title: 'ID', property: 'IDContractHistory', mask: MASK_NUMBER, visible: true },
        { title: 'ID Plaza Laboral', property: 'IDLnFunctionalArea', mask: MASK_NUMBER, visible: true },
        { title: 'Plaza Laboral', property: 'FunctionalAreaName', visible: true },
        { title: 'Fecha Inicial', property: 'InitDate', mask: MASK_DATE, visible: true },
        { title: 'Fecha Final', property: 'UptoDate', mask: MASK_DATE, visible: true },
        { title: 'Nivel Salarial', property: 'AppointmentName', visible: true },
        { title: 'Nivel Jerárquico', property: 'EntailmentName', visible: true },
        { title: 'Tipo Vinculación', property: 'ContractTypeName', visible: true },
        { title: 'Tipo Contrato', property: 'EmployeeContractTypeName', visible: true },
        { title: 'Salario', property: 'LastSalary', mask: MASK_MONEY, visible: true },
        { title: 'Es Cambio de Asignación', property: 'IsAppointmentChange', visible: true },
        { title: 'Creado por', property: 'EmployeeName', visible: true },
        { title: 'Actualizado por', property: 'LastUpdateEmployeeName', visible: true },
        { title: 'Última Actualización', property: 'IDEmployeeLastUpdate', visible: true },

    ]

    const listaBotones = () => {
        const botones = [
            { titulo: 'Actualizar Nivel Salarial', icono: 'ri-edit-2-line', visible: single.canEdit(), evento: () => { setModalAppointment({ ...modalAppointment, "name": `Editar Nivel Salarial`, "view": true }) } },
            { titulo: 'Actualizar Valor del Salario', icono: 'ri-delete-bin-line', visible: single.canDelete(), evento: () => { setModalSalary({ ...modalSalary, "name": "Editar Valor del Salario", "view": true }) } },
        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }

    return (
        <>
            <div className="row">
                <div className="col-12">
                    <BasicTable listButtons={listaBotones()} head={head} body={lista} rowSet={(history) => { setBean(history) }} />
                </div>
            </div>



            {modalAppointment.view &&
                <Modal modal={modalAppointment} updateModal={setModalAppointment} eventModal={updateAppointment}>
                    <div className="row">
                        <div className="col-12">
                            <AppointmentEditor label="Nivel Salarial" idSelector={bean.IDAppointment} onChange={(e) => { setBean({ ...bean, "IDAppointment": e }) }} />
                        </div>
                    </div>
                </Modal>
            }

            {modalSalary.view &&
                <Modal modal={modalSalary} updateModal={setModalSalary} eventModal={updateSalary} >
                    <div className="row">
                        <div className="col-12">
                            <label>Valor del Salario</label>
                            <input type="number" className="form-control" value={bean.LastSalary} onChange={(e) => { setBean({ ...bean, "LastSalary": e.target.value }) }} />
                        </div>
                    </div>
                </Modal>
            }
        </>
    )
}