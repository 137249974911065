import { useEffect, useState } from "react";
import { _ProcedureImp } from "../../../../core/services/dataBean/EntityCatalog";
import useDataBean from "../../../../core/services/dataBean/useDataBean";
import { PrintJson } from "../../../theme/Component/PrintJson";
import { ProcedureAction } from "../../../bpm/inbox/components/ProcedureAction";
import { Alert } from "../../../theme/Component/Alert";

interface Props {
    idProcedureImp: number;
    updateEvent:any;
}
export const ProcedureForManage = ({ idProcedureImp,updateEvent }: Props) => {

    const _procedureImp = useDataBean(_ProcedureImp);
    const [action, setAction] = useState<any>({});
    const [step, setStep] = useState<number>(0);

    useEffect(() => {
        getActualProcedureActionByProcedureImp();
    }, [idProcedureImp])

    useEffect(() => {
       //console.log(action);

    }, [action])
   
    const getActualProcedureActionByProcedureImp = () => {
        _procedureImp.instance.getActualProcedureActionByProcedureImp(idProcedureImp).then(
            (resp: any) => {
                let tmp = [];
                resp.forEach(element => {
                    if (element.DataBeanProperties.DocumentTypeName == 'Formulario Json' || element.DataBeanProperties.DocumentTypeName == 'Documento para cargar') {
                        if (element.DataBeanProperties.State != 3) {
                            tmp.push(element.DataBeanProperties);
                        }
                    }
                });
                _procedureImp.setLista(tmp);
            }
        )
    }

    const updateView =()=>{
        setStep(0);
        getActualProcedureActionByProcedureImp();
        updateEvent();

    }

    return (
        <div className="row">
            {step === 0 ?
                <div className="col-md-12">
                    {_procedureImp.lista.length > 0 ?
                        <div className="list-group list-group-fill-success">
                            {_procedureImp.lista.map((item: any) => {
                                return (
                                    <div key={item.IDAction} className="d-flex align-items-start" onClick={() => { setAction(item) }} style={{ borderBottom: 'solid 1px gray', paddingBottom: '3px' }}>
                                        <div>
                                            <button type="button" className="btn btn-outline-primary btn-icon waves-effect waves-light shadow-none">
                                                {item.State === _procedureImp.instance.PENDING_FOR_INPUT ?
                                                    <i className="bx bx-list-check align-middle " title="Gestionar" style={{ fontSize: '1.1rem' }} onClick={() => { setStep(1) }}></i>
                                                    :
                                                    <i className="bx  bx-check-square align-middle text-success" title="Completado" style={{ fontSize: '1.1rem' }}></i>
                                                }
                                            </button>
                                        </div>
                                        <div className="p-2">
                                            <span className={action.IDAction === item.IDAction ? 'list-group list-group-fill-success active' : 'list-group list-group-fill-success'}>
                                                {/* {item.State} */}
                                                 {item.ProcedureActionName}
                                            </span>
                                        </div>
                                    </div>
                                )
                            })}
                            {/* <PrintJson json={_procedureImp.lista}/> */}
                        </div>
                        :
                        <Alert clase="success">
                            <strong>No hay documentos / formularios por gestionar</strong>
                        </Alert>
                    }
                </div>
                :
                <div className="col-md-12">
                    <ProcedureAction Procedure={action} _Procedure={null} credit={undefined} closeModal={updateView} />
                </div>
            }
        </div>
    )
}