import { AnyCatcher } from "rxjs/internal/AnyCatcher";
import { DataBean } from "../DataBean";

export class ConfigAdmin extends DataBean{

    
    public async getFunctionalIDProperties(idLn:any): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "com.orange.bean.functional.FunctionalID_getFunctionalIDProperties_Number", [idLn]);
    }
    
    public async getFunctionalAreaByFunctionalID(idLn:any,State:any): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_getFunctionalAreaByFunctionalID_Number_Number", [idLn,State]);
    }
    public async getFunctionalAreaByEmployee(idAccount:any,state:any): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_getFunctionalAreaByEmployee_Number_Number", [idAccount,state]);
    }

    public async getFunctionalAreaByName(name:any,state:any): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_getFunctionalAreaByName_String_Number", [name,state]);
    }

    public async updateFunctionalAreaTreeToFunctionalID(idLnFunctionalArea:any,idLnFunctionalID:any): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "boolean_updateFunctionalAreaTreeToFunctionalID_Number_Number", [idLnFunctionalArea,idLnFunctionalID]);
    }



    public async updateFunctionalIDProperties(bean: any): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "com.orange.bean.functional.FunctionalID_updateFunctionalIDProperties_com.orange.bean.functional.FunctionalID", [
            {
                DataBeanProperties:bean,
                DataBeanName:"com.orange.bean.functional.FunctionalID"
            }
        ]);
    }

    

}