import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import * as XLSX from 'xlsx';

interface Props {
    label?: string;
    lodaData: any;
}
export const ImportXLSX = ({ label, lodaData }: Props) => {

    const [file, setFile] = useState<any>();
    const [excelData, setExcelData] = useState<any>([]);

    useEffect(()=>{
        if(excelData.length>0)
        {
            toast.success("Archivo cargado con "+excelData.length+" filas");
           //console.log(excelData);
            lodaData(excelData);
            
        }
    },[excelData])

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];

        if (file) {
            const reader = new FileReader();

            reader.onload = (e) => {
                const data = new Uint8Array(e.target?.result as ArrayBuffer);
                const workbook = XLSX.read(data, { type: 'array', cellDates: true });
                const sheetName = workbook.SheetNames[0];
                const sheet = workbook.Sheets[sheetName];
                const sheetData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
                setExcelData(sheetData);
            };

            reader.readAsArrayBuffer(file);
        }
    };
    return (
        <div className="row">
            <div className="col">
                <label>{label ?? 'Archivo'}</label>
                <input type="file" className="form-control" name='archivo' accept=".xlsx" value={file} onChange={handleFileChange} />
            </div>
        </div>
    )
}