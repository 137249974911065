import { useEffect, useState } from "react"
import { BasicPage } from "../../theme/Component/BasicPage"
import { ContractService } from "../../../core/services/ContractService";
import { Singleton } from "../../../core/services/Singleton";
import { BasicTable, MASK_DATE, MASK_MONEY, MASK_NUMBER } from "../../theme/Component/BasicTable";
import { ListMenu } from "../../theme/Component/ListMenu";
import { Modal } from "../../theme/Component/Modal";
import { InboxActions } from "./InboxActions";
import { CardTaps } from "../../theme/Component/CardTaps";
import useDataBean from "../../../core/services/dataBean/useDataBean";
import { _AdquisitionItem } from "../../../core/services/dataBean/EntityCatalog";
import { PrintJson } from "../../theme/Component/PrintJson";

import useModal, { MODAL_LG, MODAL_XL } from "../../theme/Component/hooks/useModal";
import { ProcedureForManage } from "./bpm/ProcedureForManage";
import { BpmService } from "../../../core/services/BpmService";
import { useDashboardStore } from "../../pages/hooks/useDashboardStore";
import { ProcedureActionHistorial } from "./Historial-inboxEmployeeSupplier";
import { OfferEmployee } from "../FlowHiring/OfferEmployee/OfferEmployee";

export const InboxEmployeeSupplier = () => {

    const { setLoading } = useDashboardStore();
    const single = Singleton.getInstance();
    const _adquisitionItem = useDataBean(_AdquisitionItem);
    const service = new BpmService();
    const [inboxList, setInboxList] = useState<any>([]);
    const [inboxListManager, setInboxListManager] = useState<any>([]);
    const [modal, setModal] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-xl' });
    const [row, setRow] = useState<any>({});
    const modalGestion = useModal("Gestión", MODAL_XL);
    const modalRole = useModal("Analista", MODAL_LG);

    let taps = [
        { id: 0, name: 'Asignados', disable: false },
        { id: 1, name: 'Por Gestión', disable: false }
    ];

    const [indexTap, setIndexTap] = useState(0);




    useEffect(() => {
        getAdquisitionItemInboxProcessAssigned();
    }, [])
    useEffect(() => {
        onChangeTaps(indexTap);
    }, [indexTap])

    const getAdquisitionItemInboxProcessAssigned = () => {
        _adquisitionItem.instance.getAdquisitionItemInboxProcessAssigned(1000, 1, single.getAccountID()).then(
            (resp: any) => {
                setInboxList(resp);
            }
        )
    }
    const getAdquisitionItemInboxProcessForManagement = () => {
        _adquisitionItem.instance.getAdquisitionItemInboxProcessForManagement(1000, 1, single.getAccountID()).then(
            (resp: any) => {
                setInboxListManager(resp);
            }
        )
    }




    const onChangeTaps = (e) => {
        if (e === 0) {
            getAdquisitionItemInboxProcessAssigned();
        }
        else if (e === 1) {
            getAdquisitionItemInboxProcessForManagement();
        }

    }


    const head = [
        { title: 'ID', property: 'IDAdquisitionItemProcess', visible: false, mask: MASK_NUMBER },
        { title: 'Valor', property: 'TotalValue', visible: true, mask: MASK_MONEY },
        { title: 'Necesidad', property: 'Description', visible: true },
        { title: 'Área', property: 'Name', visible: true },
        { title: 'Solicitud', property: 'AlphaCode', visible: true },
        { title: 'Etapa', property: 'ProcedureNameStage', visible: true },
        { title: 'Rol', property: 'RoleName', visible: true },
        { title: 'Actualización', property: 'UpdateDate', visible: true },
    ]

    const listaBotones = () => {// asignados
        const botones = [
            { titulo: 'Historico', icono: 'ri-edit-2-line', visible: single.canEdit(), evento: () => { setModal({ ...modal, "view": true }) } },
            { titulo: 'Equipo de trabajo', icono: 'bx bxs-user-check', visible: true, evento: () => { modalRole.open() } },
            // { titulo: 'Gestionar', icono: ' ri-bar-chart-horizontal-line', visible: true, evento: getActionListByOfferEmployee },
        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }
    const listaBotonesManager = () => {
        const botones = [
            { titulo: 'Gestionar', icono: ' ri-bar-chart-horizontal-line', visible: true, evento: () => { modalGestion.open() } },
        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }
    const updateView = () => {
        setIndexTap(0);
        modalGestion.close();
    }


    return (
        <BasicPage titulo="Gestión Contratos">
            <div className="row">
                <div className="col-md-12">
                    <CardTaps index={indexTap} taps={taps} updateIndex={setIndexTap} >
                        {/* <PrintJson json={inboxList}/> */}
                        {indexTap === 0 &&
                            <div className="row">
                                <div className="col-md-12">
                                    <BasicTable body={inboxList} head={head} rowSet={setRow} listButtons={listaBotones()}  ></BasicTable>
                                </div>
                            </div>
                        }
                        {indexTap === 1 &&
                            <div className="row">
                                <div className="col-md-12">
                                    <BasicTable body={inboxListManager} head={head} rowSet={setRow} listButtons={listaBotonesManager()}  ></BasicTable>
                                </div>
                            </div>
                        }
                    </CardTaps>
                </div>
                <div className="col-md-12">
                    <Modal modal={modalGestion.modal} updateModal={modalGestion.setModal} >
                        <div className="row">
                            <div className="col">
                                <ProcedureForManage idProcedureImp={row.IDProcedureImp} updateEvent={updateView} />
                            </div>
                        </div>
                    </Modal>
                </div>
            </div>

            {modal.view &&
                <Modal modal={modal} updateModal={setModal}>
                    <>
                        <ProcedureActionHistorial IDProcedure={row.IDProcedureImp} />
                    </>
                </Modal>
            }

            <Modal modal={modalRole.modal} updateModal={modalRole.setModal}>
                <>
                    <OfferEmployee IDAdquisitionItem={row.IDAdquisitionItem} />
                </>
            </Modal>

        </BasicPage>
    )
}