import { useEffect, useState } from 'react';
import { AdminService } from '../../../core/services/AdminService';
import { Singleton } from '../../../core/services/Singleton';
import { DataBeanProperties, ObjectValue } from '../../../core/services/model/server-response.interface';
import { BasicButton } from '../../theme/Component/BasicButton';
interface Props {
    idDocumentTemplate: number;
}
export const DocumentTemplateParsed = ({ idDocumentTemplate }: Props) => {

    const single = Singleton.getInstance();
    const service = new AdminService();
    const [bean, setBean] = useState<any>({});

    const [lista, setLista] = useState<any>([]);
    const [listaTipos, setListaTipos] = useState<any>([]);
    const [listaProps, setListaProps] = useState<any>([]);

    const [options, setOptions] = useState<any>({
        Type: '',

    });

    useEffect(() => {
        getDocumentTemplateFileTypeList();
        getProperties();
    }, [idDocumentTemplate])

    const getDocumentTemplateFileTypeList = () => {
        single.spinner(true);
        service.getDocumentTemplateFileTypeList(idDocumentTemplate).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue) {
                    let list = resp.DataBeanProperties.ObjectValue;
                    if (list.length > 0) {
                        setOptions({ ...options, "Type": list[0] });
                    }
                    setListaTipos(resp.DataBeanProperties.ObjectValue);
                } else {
                    setListaTipos([]);
                }
                single.spinner(false);
            }
        );
    }
    const getProperties = () => {
        single.spinner(true);
        service.getProperties(idDocumentTemplate).subscribe(
            (resp: any) => {
               //console.log(resp);

                if (resp.DataBeanProperties.ObjectValue) {
                    let list = resp.DataBeanProperties.ObjectValue;


                    list.forEach((element: any) => {
                        element.DataBeanProperties.Prop = element.DataBeanProperties.PropertyName;
                    });
                   //console.log(list);
                    setListaProps(list);
                }
                else {
                    setListaProps([]);
                }
                single.spinner(false);
            }
        )
    }

    const getDocumentTemplateParsed = () => {

       //console.log(options);
        console.table(bean);
        single.spinner(true);
        service.getDocumentTemplateParsed(idDocumentTemplate,bean,options.Type).subscribe(
            (resp:any)=>{
                if(resp.DataBeanProperties.ObjectValue)
                {                    
                    setLista(resp.DataBeanProperties.ObjectValue)
                }
                single.spinner(false);
            }
        )


    }

    const fieldType = (tipo:string)=>{

        let field = "text";

        if(tipo.includes('java.lang.Number'))
        {
            field = 'number';
        }

        return field;

    }


    return (
        <div className="row">

            <div className="col-md-12">
                <div className="row">
                    {listaProps.map((item: any, index: number) => {
                        return (
                            <div className="col-md-6">
                                <label>{item.DataBeanProperties.PropertyName} <small>({item.DataBeanProperties.PropertyTag})</small></label>
                                <input type={fieldType(item.DataBeanProperties.PropertyClass)} className='form-control' name={item.DataBeanProperties.PropertyName} value={bean[`${item.DataBeanProperties.PropertyName}`]} onChange={(e) => {
                                    setBean({ ...bean, [item.DataBeanProperties.PropertyName]: e.target.value })
                                }} />
                            </div>
                        )
                    })}
                    <hr className='mt-2'/>
                    <div className="col-md-6">
                        <label>Formato Documento</label>
                        <select name="tipo" className="form-control" value={options.Type ?? undefined} onChange={(e) => 
                            { 
                            setOptions({ ...options, "Type": e.target.value }) 
                            }}>
                            {listaTipos.map((item: any, index: number) => {
                                return (
                                    <option key={index} value={item}>{item}</option>
                                )
                            })

                            }
                        </select>
                    </div>
                    <div className="col-md-6 mt-3">
                        <BasicButton icon='ri-play-fill' eventClick={getDocumentTemplateParsed}>Ejecutar</BasicButton>
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col">
                        <ul className='list-group'>
                            {lista.map((item:any)=>{
                                return(
                                    <li className='list-group-item'><a href={item.DataBeanProperties.URLLink} target='_blank'>{item.DataBeanProperties.Filename}</a></li>
                                )
                            })}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}