import { useEffect, useState } from "react";
import { ContractService } from "../../../../../core/services/ContractService";
import { Singleton } from "../../../../../core/services/Singleton";
import { InputDate } from "../../../../theme/Input/InputDate";
import { BasicButton } from "../../../../theme/Component/BasicButton";
import { PrintJson } from "../../../../theme/Component/PrintJson";
interface Props{
    ublInvoice:any;
}
export const ReporteProcesosFacturados =({ublInvoice}:Props)=>{
    const single = Singleton.getInstance();
    const contracService = new ContractService();
    const [lista, setLista] = useState<any>([]);
    const [fecha,setFecha] = useState<any>(single.getCurrenDate());

   useEffect(()=>{
    getProcessReport();
   },[])

    const getProcessReport=()=>{
        single.spinner(true);
        contracService.getProcessReport(ublInvoice.IDUBLInvoice,ublInvoice.IssueDate).subscribe(
            (resp:any)=>{
               //console.log(resp);
                if(resp.DataBeanProperties.ObjectValue)
                {
                    setLista(resp.DataBeanProperties.ObjectValue);
                }
                single.spinner(false);
            }
        );
    }

    return(
        <div className="row">
            
            <div className="col-md-12">
                <PrintJson json={lista}/>
            </div>
        </div>
    )
}