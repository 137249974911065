import { useEffect, useState } from "react";
import { _Experience, _TechnicalSkill } from "../../../../../../core/services/dataBean/EntityCatalog";
import useDataBean from "../../../../../../core/services/dataBean/useDataBean";
import { Singleton } from "../../../../../../core/services/Singleton";
import { ListMenu } from "../../../../../theme/Component/ListMenu";
import { BasicTable } from "../../../../../theme/Component/BasicTable";
import { Modal } from "../../../../../theme/Component/Modal";
import { ModalConfirm } from "../../../../../theme/Component/ModalConfirm";
import { toast } from "react-toastify";
import { TechnicalSkillProduct } from "./TechnicalSkillProduct";
import { TechnicalCriteria } from "./TechnicalCriteria";

interface PropTechnicalSkill {
    _IDAppointManual: number;
}

export const TechnicalSkill = ({ _IDAppointManual }: PropTechnicalSkill) => {

    const technicalSkill = useDataBean(_TechnicalSkill);
    const single = Singleton.getInstance();
    const [lista, setLista] = useState<any>([]);
    const [modal, setModal] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-md' });
    const [modalC, setModalC] = useState<any>({ name: 'Eliminar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });
    const [modalCriteria, setModalCriteria] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-md' });
    const [modalProduct, setModalProduct] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-md' });


    useEffect(() => {
        if (_IDAppointManual >= 0) {
            getLista();
        }
    }, [_IDAppointManual])

    const getLista = () => {

        technicalSkill.instance.getTechnicalSkillCatalog(null, _IDAppointManual, null).then(
            (resp: any) => {
                if (resp) {
                    setLista(resp)
                   //console.log(resp)
                }
            }
        )

    }

    const create = () => {
        technicalSkill.setBean({
            IDAppointmentManual: _IDAppointManual,
        });
        setModal({ ...modal, "name": "Agregar Competencias Técnicas", "view": true });
    }

    const deleteItem = () => {
        technicalSkill.instance.deleteTechnicalSkill(technicalSkill.bean).then(
            (resp: any) => {
                getLista();
                setModalC({ ...modalC, "view": false });
            }
        )

    }

    const editaItem = () => {
        technicalSkill.update().then(
            (resp: any) => {
                getLista();
                setModal({ ...modal, "view": false });
            }
        ).catch(err => {
            toast.error(err);
        })

    }

    const head = [
        { title: 'ID', property: 'IDTechnicalSkill', visible: true },
        { title: 'Producto/Criterio de Desempeño', property: 'PerformanceCriteria', visible: true },
        { title: 'Evidencia', property: 'Evidence', visible: true },
        { title: 'Descripción', property: 'Description', visible: true },
    ]

    const iconButtonsItem = [
        { nombre: 'Agregar', visible: single.canCreate(), icono: 'ri-file-add-line', evento: create }
    ]
    const listaBotones = () => {
        const botones = [
            { titulo: 'Editar', icono: 'ri-edit-2-line', visible: single.canEdit(), evento: () => { setModal({ ...modal, "name": `Editar Experiencia para el Cargo`, "view": true }) } },
            { titulo: 'Eliminar', icono: 'ri-delete-bin-line', visible: single.canDelete(), evento: () => { setModalC({ ...modalC, "view": true }) } },
            { titulo: 'Criterios de Desempeño', icono: 'ri-line-chart-line', visible: true, evento: () => { setModalCriteria({ ...modalCriteria, "name": "Criterios de Desempeño", "view": true }) } },
            { titulo: 'Productos del Desempeño Técnico', icono: 'ri-inbox-archive-line', visible: true, evento: () => { setModalProduct({ ...modalProduct, "name": "Productos del Desempeño Técnico", "view": true }) } },
        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }


    return (
        <>
            <div className="row">
                <div className="col-md-12">
                    <BasicTable body={lista} head={head} rowSet={technicalSkill.setBean} listButtons={listaBotones()} iconButtons={iconButtonsItem}></BasicTable>
                </div>
            </div>

            {modal.view &&
                <Modal modal={modal} updateModal={setModal} eventModal={editaItem}>
                    <div className="row">
                        <div className="col-md-12">
                            <label>Producto/Criterio de Desempeño:</label>
                            <input type="text" name="PerformanceCriteria" className="form-control" value={technicalSkill.bean.PerformanceCriteria} onChange={technicalSkill.handleChange} />
                        </div>
                        <div className="col-md-12">
                            <label>Evidencia</label>
                            <input type="text" name="Evidence" className="form-control" value={technicalSkill.bean.Evidence} onChange={technicalSkill.handleChange} />
                        </div>
                        <div className="col-md-12">
                            <label>Descripción</label>
                            <textarea name="Description" className="form-control" value={technicalSkill.bean.Description} onChange={technicalSkill.handleChange} />
                        </div>
                    </div>
                </Modal>
            }
            {modalC.view &&
                <ModalConfirm modal={modalC} updateModal={setModalC} eventModal={deleteItem}>
                    <strong>Eliminar item {technicalSkill.bean.IDExperience}</strong>
                </ModalConfirm>
            }

            {modalCriteria.view &&
                <Modal modal={modalCriteria} updateModal={setModalCriteria}>
                    <TechnicalCriteria _IDTSkill={technicalSkill.bean.IDTechnicalSkill} />
                </Modal>
            }

            {modalProduct.view &&
                <Modal modal={modalProduct} updateModal={setModalProduct}>
                    <TechnicalSkillProduct _IDTSkill={technicalSkill.bean.IDTechnicalSkill} />
                </Modal>
            }
        </>
    )
}