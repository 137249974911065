import { useEffect, useState } from 'react';
import useDataBean from '../../../../../../core/services/dataBean/useDataBean';
import { _UBLItemInvoice } from '../../../../../../core/services/dataBean/EntityCatalog';
import { PrintJson } from '../../../../../theme/Component/PrintJson';
import { Singleton } from '../../../../../../core/services/Singleton';

interface Props {
    onChange: Function;
    idUBLItem: number;
    issueDate: any;
    _UBLinvsourceType?: number;
}

export const TaxSchemeValueRadioSelectorByUBLItemInvoice = ({ onChange, idUBLItem, issueDate, _UBLinvsourceType }: Props) => {

    const single = Singleton.getInstance();
    const taxSchemeValue = useDataBean(_UBLItemInvoice);
    const [selectedSchemas, setSelectedSchemas] = useState<number[]>([]);

    useEffect(() => {
        getLista();
    }, [idUBLItem]);

    useEffect(() => {
        onChange(selectedSchemas);
    }, [selectedSchemas]);

    const getLista = () => {
        taxSchemeValue.instance.getTaxSchemaValuesForUBLInvoiceItem(idUBLItem, issueDate).then((resp) => {
            taxSchemeValue.setLista(resp);
            const initiallySelected = resp
                .filter((taxvalue) => taxvalue.DataBeanProperties.IsSelected)
                .map((taxvalue) => taxvalue.DataBeanProperties.IDTaxSchemeValue);

            setSelectedSchemas((prevSelected) => [...prevSelected, ...initiallySelected]);
        });
    };

    const handleCheckboxChange = (value: number) => {
        const updatedSelectedMonths = selectedSchemas.includes(value)
            ? selectedSchemas.filter((month) => month !== value)
            : [...selectedSchemas, value];

        setSelectedSchemas(updatedSelectedMonths);
    };

    //servicios Rembolso de Caja

    return (
        <>
            <table className="table mb-0">
                <thead>
                    <tr>
                        {!(_UBLinvsourceType == 6) &&
                            <th>Seleccionar</th>
                        }
                        <th>ID</th>
                        <th>Valor(%)</th>
                        <th>Unidades</th>
                        <th>Impuesto</th>
                        <th>Aplicado</th>
                    </tr>
                </thead>
                <tbody style={{ color: "#495057 !inportant" }} >
                    {taxSchemeValue.lista.map((taxvalues) => (
                        <tr key={taxvalues.DataBeanProperties.IDTaxSchemeValue} className=''>
                            {!(_UBLinvsourceType == 6) &&
                                <td>
                                    <input
                                        type="checkbox"
                                        value={taxvalues.DataBeanProperties.IDTaxSchemeValue}
                                        checked={selectedSchemas.includes(taxvalues.DataBeanProperties.IDTaxSchemeValue)}
                                        onChange={() => handleCheckboxChange(taxvalues.DataBeanProperties.IDTaxSchemeValue)}
                                    />
                                </td>
                            }
                            <td >{taxvalues.DataBeanProperties.IDTaxSchemeValue}</td>
                            <td>{taxvalues.DataBeanProperties.TaxValue}</td>
                            <td>{taxvalues.DataBeanProperties.ValueTypeName}</td>
                            <td>{taxvalues.DataBeanProperties.TaxSchemeName}</td>
                            <td>{single.formatFieldMask(taxvalues.DataBeanProperties.IsSelected, null)}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </>
    );
}
