import { DataBean } from "../DataBean";

export class BudgetAccountantOperation extends DataBean {

    public async getBudgetAccountantOperationCatalog(arg: number): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_getBudgetAccountantOperationCatalog_Number", [arg]);
    }

    public async getValidBudgetAccountantMovementTypeConstants(arg: number): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_getValidBudgetAccountantMovementTypeConstants_Number", [arg]);
    }

    public async getBudgetAccountantMovementJsonService(idBudgetAccountantOperation: number, idAccount: number, movementList: number, idEmployee: number): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "String_getBudgetAccountantMovementJsonService_Number_Number_java.util.List_Number", [idBudgetAccountantOperation, idAccount, movementList, idEmployee]);
    }

    public async invokeBudgetAccountantOperation(properties: any): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.Map_invokeBudgetAccountantOperation_java.util.Map", [properties]);
    }

    public async updateBudgetAccountantOperation(bean: any): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "com.quickdataerp.bean.admin.BudgetAccountantOperation_updateBudgetAccountantOperation_com.quickdataerp.bean.admin.BudgetAccountantOperation", [
            {
                "DataBeanProperties": bean,
                "DataBeanName": "com.quickdataerp.bean.admin.BudgetAccountantOperation"
            }
        ]);
    }

}
