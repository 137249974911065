import { useEffect, useState } from 'react';
import { Singleton } from '../../../core/services/Singleton';
import { useDashboardStore } from '../../pages/hooks/useDashboardStore';
interface Props {
    id: any;
    name: string;
    value: any;
    onChange: any;
    readonly?: any;
    _label?: string;
}
export const InputMoney = ({ id, name, value, onChange, readonly, _label }: Props) => {

    const single = Singleton.getInstance()
    const { setLoading } = useDashboardStore();
    const [input, setInpunt] = useState({ name: '', value: '', cover: '' })


    useEffect(() => {
        ////console.log("value ",value);

        let cove = single.formatMoney(value ?? 0.0);
        setInpunt({ ...input, "name": name, "value": value, "cover": cove });

    }, [value])

    
    
    const updateValue = (e: any) => {
        
        let v = e.target.value;
        v = v.replace(/[^0-9.]/g, '');
        
        let c = single.formatMoney(v);
        // //console.log(c);            
        setInpunt({ ...input, "value": v, "cover": c });
       // onChange(input.value);    
    }

    return (

        <>
            {_label &&
                <label>{_label}</label>
            }            
            <input readOnly={readonly} type="text" className="form-control " name={input.name} value={input.cover}
                onChange={updateValue}
                onBlur={(e) => {                    
                    onChange(parseInt(input.value));                       
                }}
            />
        </>

    )
}