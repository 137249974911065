import { useState, useEffect } from 'react';
import useDataBean from '../../../core/services/dataBean/useDataBean';
import { _FixedAssetLine } from '../../../core/services/dataBean/EntityCatalog';

interface Props {
    idSelector: number;
    onChange: Function;
    label?: string;
}

export const FixedAssetLineEditor = ({ idSelector, onChange, label }: Props) => {

    const { instance, bean, setBean, lista, setLista } = useDataBean(_FixedAssetLine)
    const [id, setID] = useState(null);

    useEffect(() => {
        if (idSelector) {
            setID(idSelector);
        }
    }, [idSelector])

    useEffect(() => {
        getLista();
    }, [])

    const setValue = (selectedId: number) => {
        setID(selectedId);
    }

    useEffect(() => {
        const selectedObject = lista.find((item: any) => item.DataBeanProperties.IDLine === id);
        if (selectedObject) {
            onChange(selectedObject.DataBeanProperties);
        }
    }, [id])

    const getLista = () => {
        instance.getFixedAssetLineCatalog(null).then(
            (resp) => {
                setLista(resp);
            }
        );
    }


    return (
        <>
            <label>{label ?? "Línea de Producto"}</label>
            <select className="form-select" value={id} onChange={(e) => { setValue(parseInt(e.target.value)) }}>
                <option value={null} >...</option>
                {lista &&
                    lista.map((item: any, index: number) => {
                        return (
                            <option key={index} value={item.DataBeanProperties.IDLine} >{item.DataBeanProperties.IDLine} - {item.DataBeanProperties.Description}</option>
                        )
                    })
                }
            </select>
        </>
    )
}