import { DataBean } from "../DataBean";


export class TaxSchemeContextCode extends DataBean {

    public async getTaxSchemeContextCodeCatalog(idTaxSchemeContext: number | null): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_getTaxSchemeContextCodeCatalog_Number", [idTaxSchemeContext]);
    }

    public async getTaxSchemeContextCodeCatalogByTaxScheme(idTaxScheme: number | null, code: string | null, description: string | null): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.java.util.List_getTaxSchemeContextCodeCatalogByTaxScheme_Number_String_String", [idTaxScheme, code, description]);
    }

    public async addTaxSchemeCode(idTaxScheme: number | null, idTaxSchemeContextCode: number | null): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "com.quickdataerp.bean.admin.TaxSchemeCode_addTaxSchemeCode_Number_Number", [idTaxScheme, idTaxSchemeContextCode]);
    }
}