import { useEffect, useState } from "react";
import { _BudgetResource, _CompetitionType, _Entailment } from "../../../../core/services/dataBean/EntityCatalog";
import { Singleton } from "../../../../core/services/Singleton";
import { toast } from "react-toastify";
import { ListMenu } from "../../../theme/Component/ListMenu";
import { BasicPage } from "../../../theme/Component/BasicPage";
import { BasicTable } from "../../../theme/Component/BasicTable";
import { Modal } from "../../../theme/Component/Modal";
import { ModalConfirm } from "../../../theme/Component/ModalConfirm";
import useDataBean from "../../../../core/services/dataBean/useDataBean";
import { PrintJson } from "../../../theme/Component/PrintJson";
import { BasicSwitch } from "../../../theme/Component/BasicSwitch";
import { SelectAccountantTree } from "../../../contabilidad/catalogo-cuentas/AccountantTree/SelectAccountantTree";
import { BudgetResourceEditor } from "./BudgetResource.Editor";

export const BudgetResource = () => {

    const { bean, setBean, lista, setLista, instance, handleChange, getLista, update, deleteItemVoid } = useDataBean(_BudgetResource);

    const single = Singleton.getInstance();

    const [modal, setModal] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalC, setModalC] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });

    useEffect(() => {
        getBudget();
    }, [])

    const getBudget = () => {
        instance.getBudgetResourceCatalog(null).then(
            (resp: any) => {
                if (resp) {
                    setLista(resp)
                   //console.log(resp)
                }
            }
        ).catch(
            err => {
                single.printOut(err);
            }
        )

    }

    const create = () => {
        setBean({
            ApplyAccountant: false,
            ApplyBudget: false,
            OverwriteExpensiveAccountantID: false,
        });
        setModal({ ...modal, "view": true, "name": "Crear tipo de Recurso" });
    }

    const deleteBudget = () => {
        instance.deleteBudgetResource(bean.IDBudgetResource).then(
            (resp: any) => {
                getBudget();
                setModalC({ ...modalC, "view": false });
            }
        ).catch(
            err => {
                single.printOut(err);
            }
        )
    }

    const editaItem = () => {
        update().then(
            (resp: any) => {
                getBudget();
                setModal({ ...modal, "view": false });
            }
        ).catch(err => {
            single.printOut(err);
        })
    }


    const head = [
        { title: 'ID', property: 'IDBudgetResource', visible: true },
        { title: 'Código', property: 'Code', visible: true },
        { title: 'Nombre', property: 'Name', visible: true },
        { title: 'Aplica contabilizacion', property: 'ApplyAccountant', visible: true },
        { title: 'Aplica Presupuesto', property: 'ApplyBudget', visible: true },
        { title: 'Cuenta credito', property: 'ControlCreditAccountantID', visible: true },
        { title: 'Cuenta Debito', property: 'ControlDebtAccountantID', visible: true },
        { title: 'depende de otro recurso', property: 'IDBudget', visible: true },
        { title: 'Otra cuenta contable', property: 'OverwriteExpensiveAccountantID', visible: true },
    ]

    const iconButtonsItem = [
        { nombre: 'Agregar', visible: single.canCreate(), icono: 'ri-file-add-line', evento: create }
    ]

    const listaBotones = () => {
        const botones = [
            { titulo: 'Editar', icono: 'ri-edit-2-line', visible: single.canEdit(), evento: () => { setModal({ ...modal, "view": true, "name": "Editar tipo de Recurso" }) } },
            { titulo: 'Eliminar', icono: 'ri-delete-bin-line', visible: single.canDelete(), evento: () => { setModalC({ ...modalC, "view": true, "name": "Eliminar tipo de Recurso" }) } },
        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }


    return (
        <>
            <BasicPage titulo="Recursos del Presupuesto">
                <BasicTable body={lista} head={head} rowSet={setBean} listButtons={listaBotones()} iconButtons={iconButtonsItem} />
            </BasicPage>

            <Modal modal={modal} updateModal={setModal} eventModal={() => { editaItem() }}>
                <div className="row">
                    <div className="col-md-6">
                        <label>Código</label>
                        <input type="number" name="Code" className="form-control" value={bean.Code} onChange={handleChange} />
                    </div>
                    <div className="col-md-6">
                        <label>Nombre</label>
                        <input type="text" name="Name" className="form-control" value={bean.Name} onChange={handleChange} />
                    </div>
                    <div className="col-md-6">
                        <label>Aplica contabilizacion</label>
                        <BasicSwitch label="" estado={bean.ApplyAccountant} eventChange={(e) => { setBean({ ...bean, "ApplyAccountant": e }) }} />
                    </div>
                    <div className="col-md-6">
                        <label>Aplica Presupuesto</label>
                        <BasicSwitch label="" estado={bean.ApplyBudget} eventChange={(e) => { setBean({ ...bean, "ApplyBudget": e }) }} />
                    </div>
                    <div className="col-md-6">
                        <SelectAccountantTree label="Cuenta credito" idlnAccountant={bean.ControlCreditAccountantID} onChange={(e) => { setBean({ ...bean, "ControlCreditAccountantID": e.IDLn }) }} />
                    </div>
                    <div className="col-md-6">
                        <SelectAccountantTree label="Cuenta Debito" idlnAccountant={bean.ControlDebtAccountantID} onChange={(e) => { setBean({ ...bean, "ControlDebtAccountantID": e.IDLn }) }} />
                    </div>
                    <div className="col-md-6">
                        <BudgetResourceEditor idSelector={bean.IDBudget} onChange={(e) => { setBean({ ...bean, "IDBudget": e }) }} />
                    </div>

                    <div className="col-md-6">
                        <label>Otra cuenta contable</label>
                        <BasicSwitch label="" estado={bean.OverwriteExpensiveAccountantID} eventChange={(e) => { setBean({ ...bean, "OverwriteExpensiveAccountantID": e }) }} />
                    </div>
                </div>
            </Modal>

            <ModalConfirm modal={modalC} updateModal={setModalC} eventModal={deleteBudget}>
                <span>Eliminar item <strong>{bean.IDBudgetResource} - {bean.Name}</strong></span>
            </ModalConfirm>
        </>
    )
}