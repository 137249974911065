import { useEffect, useState } from "react";
import { Singleton } from "../../../core/services/Singleton";
import { TalentService } from "../../../core/services/TalentService";
import { BasicTable } from "../../theme/Component/BasicTable";
import { ListMenu } from "../../theme/Component/ListMenu";
import { Modal } from "../../theme/Component/Modal";
import { ModalConfirm } from "../../theme/Component/ModalConfirm";
import { MyEditor } from "../../theme/Component/MyEditor";
import { QuestionItems } from "./QuestionItems";



interface Props {
    idTest: number;
}

export const TestQuestion = ({ idTest }: Props) => {

    const services = new TalentService();
    const single = Singleton.getInstance();
    const [lista, setLista] = useState([]);
    const [bean, setBean] = useState<any>({});
    const [modal, setModal] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalQuestion, setModalQuestion] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalConfirm, setModalConfirm] = useState<any>({ name: 'Confirmar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });

    useEffect(() => {
        if (idTest >= 0) {
            getLista()
        }
    }, [idTest])

    const idBean = 'IDTestQuestion';

    const head = [
        { title: 'ID', property: idBean },
        { title: 'Pregunta', property: 'Question' },
        { title: 'Tipo de Pregunta', property: 'TypeName' },
    ]

    const setRowBean = (bean: any) => {
       //console.log("Row : ", bean);
        setBean(bean);
    }

    const renderName = (value: number) => {
        let type = '';
        if (value === 1) {
            type = 'Respuesta Unica'
        } else if (value === 2) {
            type = 'Respuesta Multiple'
        } else if (value === 3) {
            type = 'Verdadero/Falso'
        }
        return type;
    }

    const getLista = () => {
        const x = {
            "IDTest": idTest,
            // State: 1
        }
        single.spinner(true);
        services.getTestQuestionCatalogPorPropiedades(x, null).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue) {
                    let x = resp.DataBeanProperties.ObjectValue;
                    x.forEach((test: any) => {
                        test.DataBeanProperties.TypeName = renderName(test.DataBeanProperties.QuestionType)
                    });
                    setLista(x)
                }
                single.spinner(false)
            }
        )
    }

    const deleteItem = () => {
        bean.State = 2;
        updateItem();
        setModalConfirm({ ...modalConfirm, 'view': false });
    }

    const updateItem = () => {
        setModal({ ...modal, "view": false })
        single.spinner(true);
        services.updateTestQuestion(bean).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue) {
                    getLista();
                }
                single.spinner(false)
                if (bean.IDTest) {
                    single.update(bean);
                } else {
                    single.create(bean)
                }
            }
        )
    }

    const agregarItem = () => {
        let b = {
            Question: "",
            QuestionType: undefined,
            IDTest: idTest,
            State: 1,
        }
        setBean(b);
        setModal({ ...modal, 'view': true });

    }

    const listaBotones = () => {
        const botones = [

            { titulo: 'Editar', icono: 'ri-file-list-line', visible: single.canEdit(), evento: () => { setModal({ ...modal, "view": true }) } },
            { titulo: 'Eliminar', icono: 'ri-file-user-line', visible: single.canDelete(), evento: () => { setModalConfirm({ ...modalConfirm, "view": true }) } },
            { titulo: 'Preguntas de la Prueba', icono: 'ri-file-user-line', visible: true, evento: () => { setModalQuestion({ ...modalQuestion, "name": `Preguntas de ${bean.Question}`, "view": true }) } },

        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }

    const iconButtonsItem = [
        { nombre: 'Agregar', visible: single.canCreate(), icono: 'ri-file-add-line', evento: agregarItem }
    ]

    return (
        <>
            <div className="row">
                <div className="col-12">
                    <BasicTable body={lista} head={head} rowSet={setRowBean} iconButtons={iconButtonsItem} listButtons={listaBotones()} />
                </div>
            </div>

            {modal.view &&
                <Modal modal={modal} updateModal={setModal} eventModal={updateItem}>
                    <div className="row">
                        <div className="col-md-6">
                            <label className="form-label">Tipo de Pregunta</label>
                            <select className='form-select' value={bean.QuestionType} onChange={(e) => { setBean({ ...bean, "QuestionType": e.target.value }) }}>
                                <option value={undefined}>...</option>
                                <option value={1}>Respueta Unica</option>
                                <option value={2}>Respuesta Ponderada</option>
                                <option value={3}>Verdadero o Flaso </option>
                            </select>
                        </div>
                        <div className="col-md-12">
                            <label className="form-label">Pregunta</label>
                            <MyEditor val={bean.Question} onChange={(e: any) => { setBean({ ...bean, "Question": e }) }} />
                        </div>
                    </div>
                </Modal>
            }
            {modalQuestion.view &&
                <Modal modal={modalQuestion} updateModal={setModalQuestion} eventModal={""}>
                    <QuestionItems QuestionTest={bean} _idTest={idTest} />
                </Modal>
            }
            {modalConfirm.view &&
                <ModalConfirm modal={modalConfirm} updateModal={setModalConfirm} eventModal={deleteItem}>
                    {bean.Name}
                </ModalConfirm>
            }
        </>
    )
}