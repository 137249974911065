import { AccountantID } from "./contabilidad/AccountantID.Entity";
import { AccountantIDType } from "./entityBean/AccountantIDType";
import { Appointment } from "./talentoHumano/Appointment";
import { Entailment } from "./talentoHumano/Entailment";
import { CompetitionType } from "./talentoHumano/CompetitionType"
import { Macroprocess } from "./talentoHumano/Macroprocess"
import { SubMacroProcess } from "./talentoHumano/SubMacroProcess"
import { FamilyFuncionals } from "./talentoHumano/FamilyFuncionals"
import { AppointmentGroupManual } from "./talentoHumano/AppointmentGroupManual";
import { CompetitionLevel } from "./talentoHumano/CompetitionLevel";
import { MainTheme } from "./talentoHumano/MainTheme";
import { FunctionalCompetence } from "./talentoHumano/FunctionalCompetence"
import { AppointmentManual } from "./talentoHumano/AppointmentManual";
import { ConstServices } from "./talentoHumano/ConstServices";
import { Function } from "./talentoHumano/Function";
import { PerformanceCriteria } from "./talentoHumano/PerformanceCriteria";
import { StrategicObjetive } from "./talentoHumano/StrategicObjetive"
import { GenericCompetition } from "./talentoHumano/GenericCompetition"
import { TechnicalSkill } from "./talentoHumano/TechnicalSkill";
import { Competition } from "./talentoHumano/Competition";
import { Ability } from "./talentoHumano/Ability";
import { Experience } from "./talentoHumano/Experience";
import { Knowledge } from "./talentoHumano/Knowledge";
import { Training } from "./talentoHumano/Training";
import { AbilityType } from "./talentoHumano/AbilityType";
import { TransversalSkillType } from "./talentoHumano/TransversalSkillType";
import { TransversalSkillLevel } from "./talentoHumano/TransversalSkillLevel";
import { PositionObjective } from "./adminservices/PositionObjective";
import { TransversalSkill } from "./talentoHumano/TransversalSkill";
import { TechnicalCriteria } from "./talentoHumano/TechnicalCriteria";
import { TransversalSkillProduct } from "./talentoHumano/TransversalSkillProduct";
import { TechnicalSkillType } from "./talentoHumano/TechnicalSkillType";
import { TechnicalSkillLevel } from "./talentoHumano/TechnicalSkillLevel";
import { TechnicalSkillProduct } from "./talentoHumano/TechnicalSkillProduct";
import { TransversalCriteria } from "./talentoHumano/TransversalCriteria";
import { TaxSchemeContext } from "./adminservices/TaxSchemeContext";
import { TaxSchemeContextCode } from "./adminservices/TaxSchemeContextCode";
import { UBLTaxSchemeContextCode } from "./adminservices/UBLTaxSchemeContextCode";
import { AccountantConcept } from "./talentoHumano/AccountantConcept";
import { FiscalResponsabilityType } from "./talentoHumano/FiscalResponsabilityType";
import { FiscalResponsability } from "./talentoHumano/FiscalResponsability";

import { TaxScheme } from "./adminservices/TaxScheme";
import { TaxSchemeValue } from "./adminservices/TaxSchemeValue";
import { DaoInfo } from "./system/DaoInfo.Entity";
import { CreateSupplier } from "./talentoHumano/CreateSupplier";
import { Credit } from "./credito/Credit.Entity";
import { Committe } from "./credito/Committe.Entity";
import { ContractRole } from "./talentoHumano/cofigpresupuestoycompras/ContractRole";
import { EmployeeContract } from "./talentoHumano/EmployeeContract";
import { EmployeeContractHistory } from "./talentoHumano/EmployeeContractHistory";
import { CDPAvailableForUse } from "./talentoHumano/CDPAvailableForUse";
import { EmployeePayments } from "./talentoHumano/EmployeePayments";
import { TaxHold } from "./talentoHumano/TaxHoldeditor";
import { FunctionalArea } from "./adminservices/FunctionalArea";
import { EmployeeBonus } from "./talentoHumano/EmployeeBonus";
import { ViaticumPaymentsTypeType } from "./talentoHumano/ViaticumPaymentsTypeType";
import { EmploymentBonusValue } from "./talentoHumano/EmploymentBonusValue";
import { EmployeeDiscountType } from "./talentoHumano/EmployeeDiscountType";
import { ThirdBankingAccount } from "./talentoHumano/ThirdBankingAccount";
import { PaymentType } from "./talentoHumano/PaymentType";
import { BankingEntity } from "./talentoHumano/BankingEntity";
import { BankingAccount } from "./talentoHumano/BankingAccount";
import { BankingAccountCatalog } from "./talentoHumano/BankingAccountCatalog";
import { CashReimbursementConcept } from "./talentoHumano/CashReimbursementConcept";
import { TaxCodeScheme } from "./adminservices/TaxCodeScheme";
import { TaxCode } from "./adminservices/TaxCode";
import { TaxCodeValue } from "./adminservices/TaxCodeValue";
import { OfferEmployee } from "./compras/OfferEmployee.Entity";
import { AdquisitionItem } from "./compras/AdquisitionItem.Entity";
import { AdquisitionItemProcess } from "./compras/AdquisitionItemProcess.Entity";
import { ProcedureImp } from "./bpm/ProcedureImp.Entity";
import { AbstractAccount } from "./quickDataErpCore/AbstractAccount";
import { RemoteAbstractAction } from "./quickDataErpCore/RemoteAbstractAction.Entity";
import { Singleton } from "../Singleton";
import { EmployeeDiscount } from "./talentoHumano/EmployeeDiscount";
import { EmployeeNovelty } from "./talentoHumano/EmployeeNovelty";
import { EmployeeIncapacity } from "./talentoHumano/EmployeeIncapacity";
import { AdquisitionContract } from "./compras/AdquisitionContract";
import { ScheduledDiscount } from "./talentoHumano/ScheduledDiscount";
import { AdquisitionPlan } from "./compras/AdquisitionPlan.Entity";
import { UBLInvoice } from "./contabilidad/UBLInvoice.Entity";
import { UBLItemInvoice } from "./contabilidad/UBLItemInvoice.Entity";
import { CashReimbursementItem } from "./contabilidad/CashReimbursementItem";
import { CashReimbursementBudget } from "./contabilidad/CashReimbursementBudget";
import { CashReimbursement } from "./contabilidad/CashReimbursement";
import { Role } from "./adminservices/Role.Entity";
import { RoleGroupItem } from "./adminservices/RoleGroupItem.Entity";
import { ArticleClassCode } from "./contabilidad/ArticleClassCode.Entity";
import { ApplicationID } from "./core/ApplicationID.Entity";
import { ArticleClassType } from "./entityBean/ArticleClassType.Entity";
import { TaxableBase } from "./adminservices/TaxableBase";
import { UBLTaxWithHoldingTax } from "./entityBean/UBLTaxWithHoldingTax.Entity";
import { Store } from "./contabilidad/Store.Entity";
import { FixedAsset } from "./quickDataErpCore/FixedAsset";
import { FixedAssetLine } from "./quickDataErpCore/FixedAssetLine";
import { ManagementType } from "./compras/ManagementType";
import { ImportedPayrollConcept } from "./talentoHumano/ImportedPayrollConcept";
import { SiteID } from "./orangeBase/SiteID";
import { BudgetID } from "./quickDataErpCore/BudgetID";
import { BudgetResource } from "./quickDataErpCore/BudgetResource";
import { CashRequest } from "./quickDataErpCore/CashResquest.Entity";
import { CashResquestItem } from "./quickDataErpCore/CashResquestItem.Entity";
import { FunctionalID } from "./orangeBase/FunctionalID";
import { ProjectID } from "./financeCore/ProjectID";
import { TrdID } from "./financeCore/TrdID";
import { CashDeskAccount } from "./quickDataErpCore/CashDeskAccount";
import { CashResquestMoney } from "./quickDataErpCore/CashResquestMoney.Entity";
import { BudgetRPFactory } from "./contabilidad/BudgetRPFactory.Entity";
import { AdquisitionContractCDP } from "./compras/AdquisitionContractCDP";
import { DocumentFactory } from "./contabilidad/DocumentFactory";
import { BudgetObligationFactory } from "./contabilidad/BudgetObligationFactory.Entity";
import { BudgetSheetFactory } from "./contabilidad/BudgetSheetFactory.Entity";
import { BudgetMovementFactory } from "./contabilidad/BudgetMovementFactory.Entity";
import { BudgetReductionRPFactory } from "./contabilidad/BudgetReductionRPFactory.Entity";
import { ImportVariousConcepts } from "./talentoHumano/ImportVariousConcepts";
import { BudgetAccountantOperation } from "./quickDataErpCore/BudgetAccountantOperation";
import { BudgetAccountantMovement } from "./quickDataErpCore/BudgetAccountantMovement";
import { AdquisitionContractRP } from "./compras/AdquisitionContractRP";
import { BusinessCore } from "./quickDataErpCore/BusinessCore";
import { Project } from "./quickDataErpCore/Project";
import { FamilyGroup } from "./talentoHumano/FamilyGroup";
import { AcademicInformation } from "./talentoHumano/AcademicInformation";
import { WorkExperience } from "./talentoHumano/WorkExperience";
import { WorkIncentives } from "./talentoHumano/WorkIncentives";
import { BusinessProcess } from "./bpm/BusinessProcess";
import { ItemFlow } from "./financeCore/ItemFlow";
import { RecordBudget } from "./quickDataErpCore/RecordBudget";

export const _single = Singleton.getInstance();

export const _AccountantIDType = new AccountantIDType("QuickDataERPCore", "com.quickdataerp.bean.accountant.AccountantIDType");
export const _Entailment = new Entailment("QuickDataERPCore", "admin.Entailment");
export const _AccountantID = new AccountantID("QuickDataERPCore", "com.quickdataerp.bean.accountant.AccountantID");
export const _CompetitionType = new CompetitionType("PeopleArt", "com.peopleart.bean.behavioral.CompetitionType");
export const _MacroProcess = new Macroprocess("PeopleArt", "com.peopleart.bean.appointment.MacroProcess");
export const _SubMacroProcess = new SubMacroProcess("PeopleArt", "com.peopleart.bean.appointment.SubProcess");
export const _FamilyFuncionals = new FamilyFuncionals("PeopleArt", "com.peopleart.bean.functionals.Family");
export const _MainTheme = new MainTheme("PeopleArt", "com.peopleart.bean.functionals.MainTheme");
export const _FunctionalCompetence = new FunctionalCompetence("PeopleArt", "com.peopleart.bean.functionals.FunctionalCompetence");
export const _PerformanceCriteria = new PerformanceCriteria("PeopleArt", "com.peopleart.bean.functionals.PerformanceCriteria");
export const _StrategicObjetive = new StrategicObjetive("PeopleArt", "com.peopleart.bean.appointment.StrategicObjective");
export const _GenericCompetition = new GenericCompetition("PeopleArt", "com.peopleart.bean.behavioral.GenericCompetition");
export const _TransversalSkillType = new TransversalSkillType("PeopleArt", "com.peopleart.bean.appointment.TransversalSkillType");
export const _TransversalSkillLevel = new TransversalSkillLevel("PeopleArt", "com.peopleart.bean.appointment.TransversalSkillLevel");
export const _TransversalSkill = new TransversalSkill("PeopleArt", "com.peopleart.bean.appointment.TransversalSkill");
export const _TechnicalCriteria = new TechnicalCriteria("PeopleArt", "com.peopleart.bean.appointment.TechnicalCriteria");
export const _TransversalSkillProduct = new TransversalSkillProduct("PeopleArt", "com.peopleart.bean.appointment.TransversalSkillProduct");
export const _TechnicalSkillType = new TechnicalSkillType("PeopleArt", "com.peopleart.bean.appointment.TechnicalSkillType");
export const _TechnicalSkillLevel = new TechnicalSkillLevel("PeopleArt", "com.peopleart.bean.appointment.TechnicalSkillLevel");
export const _TranversalCriteria = new TransversalCriteria("PeopleArt", "com.peopleart.bean.appointment.TransversalCriteria");
export const _AccountantConcept = new AccountantConcept("QuickDataERPCore", "account.AccountantConcept");
export const _FiscalResponsabilityType = new FiscalResponsabilityType("QuickDataERPCore", "com.quickdataerp.bean.admin.FiscalResponsabilityType");
export const _FiscalResponsability = new FiscalResponsability("QuickDataERPCore", "com.quickdataerp.bean.admin.FiscalResponsability");
export const _createSupplier = new CreateSupplier("SupplierERP", "com.orange.finance.supplier.bean.Supplier");
export const _ContractRole = new ContractRole("FinanceCore", "com.orange.control.FinanceCore");
export const _EmployeePayments = new EmployeePayments("QuickDataERPCore", "employees.EmployeePayments");
export const _TaxHold = new TaxHold("QuickDataERPCore", "admin.TaxHold");
export const _ViaticumPaymentsTypeType = new ViaticumPaymentsTypeType("QuickDataERPCore", "com.quickdataerp.bean.employees.ViaticumPaymentsType");
export const _EmployeeDiscountType = new EmployeeDiscountType("QuickDataERPCore", "employees.EmployeeDiscountType");
export const _ThirdBankingAccount = new ThirdBankingAccount("OrangeBase", "com.orange.bean.account.ThirdBankingAccount");
export const _PaymentType = new PaymentType("QuickDataERPCore", "admin.PaymentType");
export const _BankingEntity = new BankingEntity("QuickDataERPCore", "admin.BankingEntity");
export const _BankingAccount = new BankingAccount("QuickDataERPCore", "com.advantage.shared.Pair");
export const _BankAccount = new BankingAccountCatalog("QuickDataERPCore", "eBankTransfer.BankingAccount")
//DevelopAndres
export const _Appointment = new Appointment("QuickDataERPCore", "admin.Appointment");
export const _AppointmentGroupManual = new AppointmentGroupManual("PeopleArt", "com.peopleart.bean.appointment.AppointmentGroupManual");
export const _AppointmentManual = new AppointmentManual("PeopleArt", "com.peopleart.bean.appointment.AppointmentManual");
export const _CompetitionLevel = new CompetitionLevel("PeopleArt", "com.peopleart.bean.behavioral.CompetitionLevel");
export const _ConstServices = new ConstServices("PeopleArt", "com.peopleart.control.PeopleArt");
export const _Function = new Function("PeopleArt", "com.peopleart.bean.appointment.Function");
export const _Knowledge = new Knowledge("PeopleArt", "com.peopleart.bean.appointment.Knowledge");
export const _Training = new Training("PeopleArt", "com.peopleart.bean.appointment.Training");
export const _Experience = new Experience("PeopleArt", "com.peopleart.bean.appointment.Experience");
export const _Ability = new Ability("PeopleArt", "com.peopleart.bean.appointment.Ability");
export const _AbilityType = new AbilityType("PeopleArt", "com.peopleart.bean.appointment.AbilityType");
export const _Competition = new Competition("PeopleArt", "com.peopleart.bean.appointment.Competition");
export const _TechnicalSkill = new TechnicalSkill("PeopleArt", "com.peopleart.bean.appointment.TechnicalSkill");
export const _TechnicalSkillProduct = new TechnicalSkillProduct("PeopleArt", "com.peopleart.bean.appointment.TechnicalSkillProduct");
export const _PositionObjective = new PositionObjective("QuickDataERPCore", "com.quickdataerp.bean.employees.PositionObjective");
export const _TaxScheme = new TaxScheme("QuickDataERPCore", "com.quickdataerp.bean.admin.TaxScheme");
export const _TaxCodeValue = new TaxCodeValue("QuickDataERPCore", "com.quickdataerp.bean.tax.TaxCodeValue");
export const _TaxSchemeValue = new TaxSchemeValue("QuickDataERPCore", "com.quickdataerp.bean.admin.TaxSchemeValue");
export const _TaxSchemeContext = new TaxSchemeContext("QuickDataERPCore", "com.quickdataerp.bean.admin.TaxSchemeContext");
export const _TaxSchemeContextCode = new TaxSchemeContextCode("QuickDataERPCore", "com.quickdataerp.bean.admin.TaxSchemeContextCode");
export const _UBLTaxSchemeContextCode = new UBLTaxSchemeContextCode("QuickDataERPCore", "com.quickdataerp.bean.ubl.UBLTaxSchemeContextCode");
export const _EmployeeContract = new EmployeeContract("QuickDataERPCore", "employees.EmployeeContract");
export const _EmployeeContractWithoutFA = new EmployeeContract("QuickDataERPCore", "employees.EmployeeContract");
export const _EmployeeContractHistory = new EmployeeContractHistory("QuickDataERPCore", "employees.EmployeeContractHistory");
export const _CDPAvailableForUse = new CDPAvailableForUse("QuickDataERPCore", "com.quickdataerp.bean.employees.CDPAvailableForUse");
export const _FunctionalArea = new FunctionalArea("QuickDataERPCore", "admin.FunctionalArea");
export const _EmployeeBonus = new EmployeeBonus("QuickDataERPCore", "employees.EmployeeBonus");
export const _ImportVariousConcepts = new ImportVariousConcepts("QuickDataERPCore", "");
export const _EmploymentBonusValue = new EmploymentBonusValue("QuickDataERPCore", "employees.EmployeeBonusValue");
export const _CashReimbursementConcept = new CashReimbursementConcept("QuickDataERPCore", "com.quickdataerp.bean.accountant.CashReimbursementConcept");
export const _TaxCodeScheme = new TaxCodeScheme("QuickDataERPCore", "com.quickdataerp.bean.tax.TaxCodeScheme");
export const _TaxCode = new TaxCode("QuickDataERPCore", "com.quickdataerp.bean.tax.TaxCode");
export const _EmployeeDiscount = new EmployeeDiscount("QuickDataERPCore", "employees.EmployeeDiscount");
export const _EmployeeNovelty = new EmployeeNovelty("QuickDataERPCore", "employees.EmployeeNovelty");
export const _EmployeeIncapacity = new EmployeeIncapacity("QuickDataERPCore", "employees.EmployeeIncapacity");
export const _AbstractAccount = new AbstractAccount("QuickDataERPCore", "com.advantage.bean.account.AbstractAccount");
export const _ScheduledDiscount = new ScheduledDiscount("QuickDataERPCore", "employees.ScheduledDiscount");
export const _UBLItemInvoice = new UBLItemInvoice("QuickDataERPCore", "com.quickdataerp.bean.ubl.UBLItemInvoice");
export const _CashReimbursementItem = new CashReimbursementItem("QuickDataERPCore", "com.quickdataerp.bean.ubl.UBLItemInvoice");
export const _CashReimbursementBudget = new CashReimbursementBudget("QuickDataERPCore", "com.quickdataerp.bean.accountant.CashReimbursementBudget");
export const _CashReimbursement = new CashReimbursement("QuickDataERPCore", "com.quickdataerp.bean.accountant.CashReimbursement");
export const _Role = new Role("OrangeBase", "com.advantage.bean.securitymanager.Role");
export const _RoleGroupItem = new RoleGroupItem("CacheOptions", "com.advantage.cache.bean.RoleGroupsItems");
export const _TaxableBase = new TaxableBase("QuickDataERPCore", "java.util.List_getTaxableAmountForHoldingTax_Number_java.util.Date_Number");
export const _Store = new Store("QuickDataERPCore", "inventory.Store");
export const _FixedAsset = new FixedAsset("QuickDataERPCore", "fixedassets.FixedAsset");
export const _FixedAssetLine = new FixedAssetLine("QuickDataERPCore", "fixedassets.FixedAssetLine");
export const _ManagementType = new ManagementType("FinanceCore", "com.orange.bean.ManagementType");
export const _ImportedPayrollConcept = new ImportedPayrollConcept("QuickDataERPCore", "com.quickdataerp.bean.employees.ImportedPayrollConcept bean");
export const _SiteID = new SiteID("QuickDataERPCore", "com.orange.bean.site.SiteID");
export const _BudgetID = new BudgetID("QuickDataERPCore", "com.quickdataerp.bean.budget.BudgetID");
export const _BudgetResource = new BudgetResource("QuickDataERPCore", "com.quickdataerp.bean.budget.BudgetResource");
export const _CashRequest = new CashRequest("QuickDataERPCore", "com.quickdataerp.bean.cashreimbursement.CashRequest");
export const _CashRequestMoney = new CashResquestMoney("QuickDataERPCore", "com.quickdataerp.bean.cashreimbursement.CashRequest");
export const _CashRequestItem = new CashResquestItem("QuickDataERPCore", "com.quickdataerp.bean.cashreimbursement.CashRequest");
export const _FunctionalID = new FunctionalID("OrangeBase", "com.orange.bean.functional.FunctionalID");
export const _ProjectID = new ProjectID("FinanceCore", "com.orange.bean.project.ProjectID");
export const _TrdID = new TrdID("FinanceCore", "com.orange.bean.trd.TrdID");
export const _CaskDeskAccount = new CashDeskAccount("QuickDataERPCore", "eBankTransfer.CashDeskAccount");
export const _AdquisitionContractCDP = new AdquisitionContractCDP("FinanceCore", "com.orange.bean.AdquisitionContractCDP");
export const _AdquisitionContractRP = new AdquisitionContractRP("FinanceCore", "com.orange.bean.AdquisitionContractRP");
export const _RecordBudget = new RecordBudget("QuickDataERPCore", "com.quickdataerp.bean.budget.RecordBudget");
export const _BudgetObligationFactory = new BudgetObligationFactory("", "");
export const _BudgetSheetFactory = new BudgetSheetFactory("", "");
export const _BudgetMovementFactory = new BudgetMovementFactory("", "");
export const _BudgetReductionRPFactory = new BudgetReductionRPFactory("", "");
export const _BudgetAccountantOperation = new BudgetAccountantOperation("QuickDataERPCore", "com.quickdataerp.bean.admin.BudgetAccountantOperation");
export const _BudgetAccountantMovement = new BudgetAccountantMovement("QuickDataERPCore", "com.quickdataerp.bean.admin.BudgetAccountantMovement");
export const _BusinessCore = new BusinessCore("QuickDataERPCore", "com.quickdataerp.bean.accountant.BusinessCore");
export const _Project = new Project("", "");
export const _FamilyGroup = new FamilyGroup("TalentERP", "com.orange.people.talent.bean.FamilyGroup");
export const _AcademicInformation = new AcademicInformation("TalentERP", "com.orange.people.talent.bean.AcademicInformation");
export const _WorkExperience = new WorkExperience("TalentERP", "com.orange.people.talent.bean.WorkExperience");
export const _WorkIncentives = new WorkIncentives("TalentERP", "com.orange.people.talent.bean.WorkIncentives");
export const _BusinessProcess = new BusinessProcess("", "");
export const _ItemFlow = new ItemFlow("FinanceCore", "com.orange.bean.ItemFlow");

//german
export const _DaoInfo = new DaoInfo("OrangeCore", "com.advantage.bean.dev.DaoInfo");
export const _Credit = new Credit("ForpoBpm", "com.orange.bpm.forpo.bean.Credit");
export const _Committe = new Committe("ForpoBpm", "com.orange.bpm.forpo.bean.Committee");
export const _OfferEmployee = new OfferEmployee("FinanceCore", "com.orange.bean.OfferEmployee");
export const _AdquisitionPlan = new AdquisitionPlan("FinanceCore", "com.orange.bean.supplier.AdquisitionPlan");
export const _AdquisitionItem = new AdquisitionItem("FinanceCore", "com.orange.bean.supplier.AdquisitionItem");
export const _AdquisitionContract = new AdquisitionContract("FinanceCore", "com.orange.bean.AdquisitionContract");
export const _AdquisitionItemProcess = new AdquisitionItemProcess("FinanceCore", "com.orange.bean.AdquisitionItemProcess");
export const _ProcedureImp = new ProcedureImp("BpmService", "com.quickbpm.bean.ProcedureImp");
export const _RemoteAbstractAction = new RemoteAbstractAction("OrangeCore", "com.advantage.bean.dev.RemoteAbstractAction");
export const _UBLInvoice = new UBLInvoice("QuickDataERPCore", "com.quickdataerp.bean.ubl.UBLInvoice");
export const _ArticleClassCode = new ArticleClassCode("QuickDataERPCore", "com.quickdataerp.bean.supplier.ArticleClassCode");
export const _ApplicationID = new ApplicationID("OrangeBase", "com.orange.bean.application.ApplicationID");
export const _ArticleClassType = new ArticleClassType("QuickDataERPCore", "com.quickdataerp.bean.supplier.ArticleClassType");
export const _UBLTaxWithHoldingTax = new UBLTaxWithHoldingTax("QuickDataERPCore", "com.quickdataerp.bean.ubl.UBLTaxWithHoldingTax");
export const _BudgetRPFactory = new BudgetRPFactory("QuickDataERPCore", "com.quickdataerp.bean.ubl.UBLTaxWithHoldingTax");
export const _DocumentFactory = new DocumentFactory("QuickDataERPCore", "com.quickdataerp.control.QuickDataERPCore");

