import { DataBean } from "../DataBean";

export class Credit extends DataBean{

   
    public async crearComiteInforpo(idEmployee:number,loteCode:number):Promise<any>
    {
        return this.getCustomService("ForpoBpm","com.orange.bpm.forpo.bean.Credit_crearComiteInforpo_Number_Number",[idEmployee,loteCode]);
    }
    public async registrarUsuarioComiteInforpo(lote:number):Promise<any>
    {
        return this.getCustomService("ForpoBpm","com.orange.bpm.forpo.bean.Credit_registrarUsuarioComiteInforpo_Number",[lote]);
    }
    public async getOTPApproval(idApprovalCredit:number):Promise<any>
    {
        return this.getCustomService("ForpoBpm","com.orange.bpm.forpo.bean.ApprovalCredit_getOTPApproval_Number",[idApprovalCredit]);
    }
    public async responseOTPApproval(idApprovalCredit:number,otp:string,state:number,obs:string):Promise<any>
    {
        return this.getCustomService("ForpoBpm","com.orange.bpm.forpo.bean.ApprovalCredit_responseOTPApproval_Number_String_Number_String",[idApprovalCredit,otp,state,obs]);
    }

    
}
