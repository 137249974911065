import { useEffect, useState } from "react";
import { _AccountantIDType } from "../../../core/services/dataBean/EntityCatalog"
import useDataBean from "../../../core/services/dataBean/useDataBean"
import { BasicPage } from "../../theme/Component/BasicPage"
import { PrintJson } from "../../theme/Component/PrintJson";
import { DataBeanProperties } from '../../../core/services/model/server-response.interface';
import { FileInput } from "../../theme/Component/FileInput";
import { BasicButton } from "../../theme/Component/BasicButton";
import { toast } from "react-toastify";
import useModal, { MODAL_SM } from "../../theme/Component/hooks/useModal";
import { Modal } from "../../theme/Component/Modal";
import { ModalConfirm } from "../../theme/Component/ModalConfirm";
import { _ArticleClassType } from "../../../core/services/dataBean/EntityCatalogVdos";

export const ExportarCuentasContables = () => {
    const service = useDataBean(_AccountantIDType);
    const [idCatalog, setIDCatalog] = useState<any>(1);
    const [idAccountant, setIDAccountan] = useState<any>(undefined);
    const [idBudget, setIDBudget] = useState<any>(undefined);
    const [respuesta, setRespuesta] = useState<any>(undefined);
    const [idArticleClassType, setIDArticleClassType] = useState<any>(undefined);
    const [fileI, setFileI] = useState<any>({});
    const _articleClassType = useDataBean(_ArticleClassType);
    const [typeCatalog, setTypeCatalog] = useState<any>([
        {
            id: 1,
            name: "Plan de Cuentas Contable"
        },
        {
            id: 2,
            name: "Catálogo Presupuestal"
        },
        {
            id: 3,
            name: "Catálogo de Centros de Costo"
        },
        {
            id: 4,
            name: "Clasificador de Bienes y Servicios"
        },
    ]);
    const [accountantCatalog, setaccountantCatalog] = useState<any>([]);
    const [budgetID, setBudgetID] = useState<any>([]);
    const modalDelete = useModal("Cátalogo",MODAL_SM);

    useEffect(() => {
        setIDAccountan(undefined);
        setIDBudget(undefined);
        if (idCatalog === 1)
            service.instance.getAccountantIDTypeCatalog().then((resp: any) => {
                setaccountantCatalog(resp);
            });
        else if (idCatalog === 2)
            service.instance.getBudgetIDTypeCatalog().then((resp: any) => {
                setBudgetID(resp);
            });
            else if (idCatalog === 4)
                _articleClassType.instance.getArticleClassTypeCatalog(null).then((resp: any) => {
                    setBudgetID(resp);
                });
    }, [idCatalog])


    const exportarData = () => {


        if (idCatalog === 1) {
            service.instance.exportAccountantIDCatalog(idAccountant).then(
                (resp: any) => {
                   //console.log(resp);
                    setRespuesta(resp);
                    toast.success("Archivo descargado correctamente");
                }
            ).catch(errr => {
                toast.error(errr)
            })
        }
        else if (idCatalog === 2) {
            service.instance.exportAccountantIDXlsxFile(idAccountant).then(
                (resp: any) => {
                   //console.log(resp);
                    setRespuesta(resp);
                    toast.success("Archivo descargado correctamente");
                }
            ).catch(errr => {
                toast.error(errr)
            })
        }
        // else if (idCatalog === 3) {
        //     service.instance.exportBudgetIDCatalog(idBudget).then(
        //         (resp: any) => {
        //            //console.log(resp);
        //             setRespuesta(resp);
        //             toast.success("Archivo descargado correctamente");
        //         }
        //     ).catch(errr => {
        //         toast.error(errr)
        //     })
        // }
        // else if (idCatalog === 4) {
        //     service.instance.exportBudgetIDCatalog(idBudget).then(
        //         (resp: any) => {
        //            //console.log(resp);
        //             setRespuesta(resp);
        //             toast.success("Archivo descargado correctamente");
        //         }
        //     ).catch(errr => {
        //         toast.error(errr)
        //     })
        // }



    }
    const deleteCatalog = () => {

        if (idCatalog === 1) {
            service.instance.deleteAccountantIDCatalog(idAccountant).then(
                (resp: any) => {
                   //console.log(resp);
                    setRespuesta(resp);
                    toast.success("Catálogo eliminado correctamente");
                }
            ).catch(errr => {
                toast.error(errr)
            })
        }
        else if (idCatalog === 2) {
            service.instance.deleteBudgetIDCatalog(idBudget).then(
                (resp: any) => {
                   //console.log(resp);
                    setRespuesta(resp);
                    toast.success("Catálogo eliminado correctamente");
                }
            ).catch(errr => {
                toast.error(errr)
            })
        }
        else if (idCatalog === 3) {
            service.instance.deleteCostingCenterCatalog(null).then(
                (resp: any) => {                    
                    setRespuesta(resp);
                    toast.success("Catálogo eliminado correctamente");
                }
            ).catch(errr => {
                toast.error(errr)
            })
        }
        else if (idCatalog === 4) {
            service.instance.deleteArticleClassTypeCatalog(idArticleClassType).then(
                (resp: any) => {                    
                    setRespuesta(resp);
                    toast.success("Catálogo eliminado correctamente");
                }
            ).catch(errr => {
                toast.error(errr)
            })
        }
    }

    const cantDelete=()=>{

        let cant = false;
        if(idCatalog === 1)
            if(idAccountant>0)
                cant=true;
        else if(idCatalog === 2)
            if(idBudget>0)
                cant=true;
        else if(idCatalog === 4)
            if(idArticleClassType>0)
                cant=true;
        else if(idCatalog === 3)            
                cant=true;


        return cant;
    }



    return (
        <BasicPage titulo="Exportar Plan de Cuentas Contables y Catálogo Presupuestal">
            <div className="row">
                <div className="col-md-4">
                    <label>Tipo Exportación</label>
                    <select name="import" className="form-select" value={idCatalog} onChange={(e) => { setIDCatalog(parseInt(e.target.value)) }}>
                        {typeCatalog.map((item: any) => {
                            return (
                                <option value={item.id}>{item.name}</option>
                            )
                        })}
                    </select>
                </div>
                <div className="col-md-4">

                    {idCatalog === 1 &&
                        <>
                            <label>Catálogo</label>
                            <select name="import" className="form-select" value={idAccountant} onChange={(e) => { setIDAccountan(parseInt(e.target.value)) }}>
                                <option value={undefined}>...</option>
                                {accountantCatalog.map((item: any) => {
                                    return (
                                        <option value={item.DataBeanProperties.IDAccountantIDType}>{item.DataBeanProperties.Description}</option>
                                    )
                                })}
                            </select>
                        </>
                    }
                    {idCatalog === 2 &&
                        <>
                            <label>Catálogo</label>
                            <select name="import" className="form-select" value={idBudget} onChange={(e) => { setIDBudget(parseInt(e.target.value)) }}>
                                <option value={undefined}>...</option>
                                {budgetID.map((item: any) => {
                                    return (
                                        <option value={item.DataBeanProperties.IDBudgetIDType}>{item.DataBeanProperties.Description}</option>
                                    )
                                })}
                            </select>
                        </>
                    }
                     {idCatalog === 4 &&
                        <>
                            <label>Catálogo</label>
                            <select name="import" className="form-select" value={idArticleClassType} onChange={(e) => { setIDArticleClassType(parseInt(e.target.value)) }}>
                                <option value={undefined}>...</option>
                                {budgetID.map((item: any) => {
                                    return (
                                        <option value={item.DataBeanProperties.IDArticleClassType}>{item.DataBeanProperties.Description}</option>
                                    )
                                })}
                            </select>
                        </>
                    }
                </div>
                <div className="col-md-4">
                    
                </div>
                
                <div className="col-md-12">
                    <div className="d-flex flex-row p-1">
                    <BasicButton icon="ri-install-line"  eventClick={exportarData}>Exportar</BasicButton>
                    <BasicButton icon="ri-install-line"  clase="danger" eventClick={()=>{modalDelete.open()}}>Eliminar</BasicButton>
                    </div>
                </div>
                <div className="col-md-12">
                    <PrintJson json={respuesta} />
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <ModalConfirm modal={modalDelete.modal} eventModal={deleteCatalog} updateModal={modalDelete.setModal}>
                        <p>Seguro de eliminar el cátalogo</p>
                    </ModalConfirm>
                </div>
            </div>
        </BasicPage>
    )
}