import { useEffect, useState } from 'react';
import { FileService } from '../../../core/services/FileService';
import { toast } from 'react-toastify';

interface Props {
    directory?: string;
    onCreate: any;
    Media?: string;
    MediaContext?: string;
    label?: string;
    docAcept?: string;
    _size?: number;
    previsualization?: boolean;
    notDisplayInput?: boolean;
}

export const FileInput = ({ onCreate, directory, Media, MediaContext, docAcept, label, _size, previsualization, notDisplayInput }: Props) => {

    //tipos de fomatos para conocimiento
    // Microsoft Word
    //     doc: application/msword
    //     docx: application/vnd.openxmlformats-officedocument.wordprocessingml.document

    // Microsoft Excel:
    //     xls: application/vnd.ms-excel
    //     xlsx: application/vnd.openxmlformats-officedocument.spreadsheetml.sheet

    // Texto Plano:
    //     txt: text/plain
    //     csv: text/csv

    // Presentaciones:
    //     ppt: application/vnd.ms-powerpoint
    //     pptx: application/vnd.openxmlformats-officedocument.presentationml.presentation

    // RichText Format:
    //     rtf: application/rtf

    // Imágenes:
    //     JPEG: image/jpeg
    //     PNG: image/png 
    //     GIF: image/gif
    //     BMP: image/bmp
    //     TIFF: image/tiff
    //     SVG: image/svg+xml 

    // Audio:
    //     MP3: audio/mpeg
    //     WAV: audio/wav 
    //     OGG: audio/ogg
    //     MIDI: audio/midi

    // Video:
    //     MP4: video/mp4
    //     AVI: video/x-msvideo
    //     MOV: video/quicktime
    //     WMV: video/x-ms-wmv

    // Archivos Comprimidos
    //     ZIP: application/zip
    //     RAR: application/vnd.rar  (Archivo comprimido proprietario de RARLab)
    //     7z: application/x-7z-compressed (Archivo comprimido.)

    // Código Fuente y Texto Estructurado
    //     HTML: text/html
    //     CSS: text/css
    //     JavaScript: application/javascript
    //     XML: application/xml 
    //     JSON: application/json 
    //     PDF: application/pdf

    // Otros Tipos Comunes
    //     ISO: application/x-iso9660-image (Imagen de disco óptico.)
    //     EXE: application/vnd.microsoft.portable-executable  (Archivo ejecutable en Windows.)
    //     DMG: application/x-apple-diskimage (Imagen de disco de Apple.)
    //     APK: application/vnd.android.package-archive (Paquete de aplicación de Android.)   

    const fileService = new FileService();
    const [file, setFile] = useState<any>({});
    const [fileMap, setFileMap] = useState<any>({});
    const [upload, setUpload] = useState<boolean>(false);
    const [imagePreview, setImagePreview] = useState<string | null>(null);
    const [pdfPreview, setPDFPreview] = useState<string | null>(null);

    useEffect(() => {
        setFileMap({});
        setUpload(false);
        return () => { };
    }, []);

    const validateFileType = (fileType: any) => {
        const resultado = docAcept?.includes(fileType);
       //console.log(resultado)
        return resultado;
    }

    useEffect(() => {
        let media = {};
        if (Media && MediaContext) {
            if (Media != '' && MediaContext != '') {
                const urltmp = fileService.getUrlFile(MediaContext, Media);
               //console.log(urltmp)
                if (urltmp !== "") {
                    media = { URL: urltmp, Name: Media };
                }
                if (docAcept?.includes("image/")) {
                    setImagePreview(urltmp)
                } else if (docAcept?.includes("application/pdf")) {
                    setPDFPreview(urltmp)
                } else {
                    setImagePreview(null);
                    setPDFPreview(null);
                }
            }

        } else {

        }
        setFileMap(media);
    }, [Media]);

    const validateDocument = (e: any) => {
        if (e.target.files.length > 0) {
            let archivo = e.target.files[0];
            if (_size && archivo.size > _size) {
                toast.error(`El archivo no puede superar los ${_size} bytes`);
            } else {
                onChangeFile(e)
            }
        }
    }

    const onChangeFile = (e: any) => {
        const selectedFile = e.target.files[0];
        if (docAcept && !validateFileType(selectedFile.type)) {
            toast.error("Formato de archivo no válido" + selectedFile.type);
        } else {
            setFile(selectedFile);
            setUpload(true);

            if (selectedFile.type.startsWith('image/')) {
                const imageUrl = URL.createObjectURL(selectedFile);
               //console.log(imageUrl)
                setImagePreview(imageUrl);
            } else if (selectedFile.type.includes('application/pdf')) {
                setPDFPreview(selectedFile)
            } else {
                setImagePreview(null);
                setPDFPreview(null)
            }

            fileService.postFile(selectedFile, directory).subscribe(
                (resp: any) => {
                    if (resp.DataBeanProperties.ObjectValue) {
                        const uploadedFile = resp.DataBeanProperties.ObjectValue.DataBeanProperties;
                        setFileMap(uploadedFile);
                        onCreate(uploadedFile);
                    }
                    setUpload(false);
                    toast.success("Se cargó el archivo satisfactoriamente");
                },
                (error: any) => {
                    // Manejo de errores aquí
                    console.error('Error al subir el archivo:', error);
                    toast.error('Error al subir el archivo:');
                    setUpload(false);
                }
            );
        }
    };


    return (
        <>
            {(imagePreview && previsualization == true) && (
                <div className="image-preview mt-2" style={{ textAlign: 'center' }}>
                    <img
                        src={imagePreview || "assets/images/core/avatar.png"}
                        alt="Vista previa de la imagen"
                        style={{
                            width: '150px',
                            height: '150px',
                            borderRadius: '50%',
                            objectFit: 'cover'
                        }}
                    />
                </div>
            )}
            {!upload && (
                <>
                    <label >{label ?? 'Adjunto'}</label>
                    <input accept={docAcept ?? ''} type="file" className={notDisplayInput ? "d-none" : "form-control"} onChange={validateDocument} />
                </>
            )}
            {upload && (
                <>
                    <label>{file.name ?? 'file'}</label>
                    <div className="form-control">
                        <strong>
                            Cargando <i className="mdi mdi-atom-variant mdi-spin btnloading" />
                        </strong>
                    </div>
                </>
            )}
            {fileMap.URL &&
                <a className={notDisplayInput ? "d-none" : ""} href={fileMap.URL} target="_blank">{fileMap.Name}</a>
            }

        </>
    );
};