import { useState } from 'react'
import { BasicButton } from '../../../../theme/Component/BasicButton'

interface Props {
    exportMap: Function
}
export const GenerateCDP = ({ exportMap }: Props) => {

    const [bean, setBean] = useState<any>({APROBO:true})

    const onExportMap = () => {
        exportMap(bean);
       //console.log(bean)
    }
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <label>Vigencia Estimada del contrato</label>
                    <input className='form-control' type="text" value={bean.VIGENCIA_ESTIMADA_DEL_CONTRATO} onChange={(e)=>setBean({...bean, "VIGENCIA_ESTIMADA_DEL_CONTRATO": e.target.value})} />
                </div>
                <div className="col-12">
                    <label>Dependencia del solicitante</label>
                    <input className='form-control' type="text" value={bean.DEPENDENCIA_SOLICITANTE} onChange={(e)=>setBean({...bean, "DEPENDENCIA_SOLICITANTE": e.target.value})} />
                </div>
                <div className="col-12">
                    <label>OBJETIVOS ESTRATEGICOS</label>
                    <input className='form-control' type="text" value={bean.OBJETIVOS_ESTRATEGICOS} onChange={(e)=>setBean({...bean, "OBJETIVOS_ESTRATEGICOS": e.target.value})} />
                </div>
                <div className="col-12">
                    <label>INICIATIVA ESTRATEGICA PROYECTO</label>
                    <input className='form-control' type="text" value={bean.INICIATIVA_ESTRATEGICA_PROYECTO} onChange={(e)=>setBean({...bean, "INICIATIVA_ESTRATEGICA_PROYECTO": e.target.value})} />
                </div>
                <div className="col-12">
                    <label>DESCRIPCION DEL OBJETO A CONTRATAR</label>
                    <input className='form-control' type="text" value={bean.DESCRIPCION_DEL_OBJETO_A_CONTRATAR} onChange={(e)=>setBean({...bean, "DESCRIPCION_DEL_OBJETO_A_CONTRATAR": e.target.value})} />
                </div>
                <div className="col-12">
                    <label>JUSTIFICACION DE LA NECESIDAD</label>
                    <input className='form-control' type="text" value={bean.JUSTIFICACION_DE_LA_NECESIDAD} onChange={(e)=>setBean({...bean, "JUSTIFICACION_DE_LA_NECESIDAD": e.target.value})} />
                </div>
                <div className="col-12">
                    <label>FECHA ESTIMADA DE CELEBRACION DEL CONTRATO</label>
                    <input className='form-control' type="text" value={bean.FECHA_ESTIMADA_DE_CELEBRACION_DEL_CONTRATO} onChange={(e)=>setBean({...bean, "FECHA_ESTIMADA_DE_CELEBRACION_DEL_CONTRATO": e.target.value})} />
                </div>
                <div className="col-12">
                    <label>COBERTURA</label>
                    <input className='form-control' type="text" value={bean.COBERTURA} onChange={(e)=>setBean({...bean, "COBERTURA": e.target.value})} />
                </div>
                <div className="col-12">
                    <label>TIPO DE SOLICITUD</label>
                    <input className='form-control' type="text" value={bean.TIPO_DE_SOLICITUD} onChange={(e)=>setBean({...bean, "TIPO_DE_SOLICITUD": e.target.value})} />
                </div>
                <div className="col-12">
                    <label>RUBRO PARA AFECTAR</label>
                    <input className='form-control' type="text" value={bean.RUBRO_PARA_AFECTAR} onChange={(e)=>setBean({...bean, "RUBRO_PARA_AFECTAR": e.target.value})} />
                </div>
                <div className="col-12">
                    <BasicButton icon='ri-save-line' eventClick={()=>{onExportMap()}}>Guardar</BasicButton>
                </div>
            </div>
        </>
    )
}
