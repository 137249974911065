
import { useEffect, useState } from 'react';
import { Singleton } from '../../../../../core/services/Singleton';
import { BasicTable, MASK_MONEY } from '../../../../theme/Component/BasicTable';
import { ListMenu } from '../../../../theme/Component/ListMenu';
import { Modal } from '../../../../theme/Component/Modal';
import useDataBean from '../../../../../core/services/dataBean/useDataBean';
import { _CashReimbursementBudget } from '../../../../../core/services/dataBean/EntityCatalog';
import { toast } from 'react-toastify';
import { CashReimbursementConceptEditor } from '../../../../Talentohumano/CajasMenoresConceptos/CashReimbursementConcept.editor';
import { ModalConfirm } from '../../../../theme/Component/ModalConfirm';

interface Props {
    idCashReimbursement: number;
    cashReimbursementDate: any;
}

export const CashReimbursementBudget = ({ idCashReimbursement, cashReimbursementDate }: Props) => {

    const reimbursementBudget = useDataBean(_CashReimbursementBudget);
    const single = Singleton.getInstance();

    const [year, setYear] = useState<number>(single.getCurrenYear())
    const [obs, setObs] = useState<string>("")

    const [modalAdd, setModalAdd] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: "modal-sm", });
    const [modalDelete, setModalDelete] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: "modal-sm", });

    useEffect(() => {
        getLista()
    }, [idCashReimbursement])

    const head = [
        { title: 'ID', property: "IDCashReimbursementBudget" },
        { title: 'Nombre', property: 'Name' },
        { title: 'Rubro Presupuestal', property: 'BudgetName' },
        { title: 'Cuenta Contable', property: 'AccountantIDName' },
        { title: 'Valor', property: 'BudgetValue', mask: MASK_MONEY },
        { title: 'Creado Por', property: 'EmployeeName' },
        { title: 'Estado', property: 'StateName' },
    ]

    const getLista = () => {
        reimbursementBudget.instance.getCashReimbursementBudgetCatalog(idCashReimbursement, cashReimbursementDate).then(
            (resp) => {
                reimbursementBudget.setLista(resp);
            }
        )
    }

    const createBean = () => {
        reimbursementBudget.setBean({});
        setModalAdd({ ...modalAdd, "view": true, "name": "Agregar Concepto" })
    }

    const create = () => {
        reimbursementBudget.instance.addCashReimbursementBudget(idCashReimbursement, cashReimbursementDate, reimbursementBudget.bean.IDCashReimbursementConcept, reimbursementBudget.bean.BudgetValue, single.getAccountID()).then(
            (resp) => {
                getLista();
                setModalAdd({ ...modalAdd, "view": false });
            }
        ).catch(
            err => {
                toast.error(err)
            }
        )
    }

    const deleteItem = () => {
        reimbursementBudget.instance.deleteCashReimbursementBudget(reimbursementBudget.bean.IDCashReimbursementBudget, reimbursementBudget.bean.CashReimbursementDate, single.getAccountID(), obs).then(
            (resp) => {
                getLista();
                setModalDelete({ ...modalDelete, "view": false })
                setObs(null)
            }
        )
    }

    const iconButtonsItem = [
        { nombre: 'Agregar', visible: single.canCreate(), icono: 'ri-file-add-line', evento: createBean }
    ]

    const listaBotones = () => {
        const botones = [
            { titulo: "Eliminar Concepto de Gasto", icono: "ri-file-list-line", visible: true, evento: () => { setModalDelete({ ...modalDelete, "view": true, "name": "Eliminar Concepto de Caja" }) }, },
        ];
        return <ListMenu listaBotones={botones} />;
    };

    return (
        <>
            <BasicTable iconButtons={iconButtonsItem} listButtons={listaBotones()} body={reimbursementBudget.lista} head={head} rowSet={reimbursementBudget.setBean} ></BasicTable>

            {modalAdd.view &&
                <Modal modal={modalAdd} updateModal={setModalAdd} eventModal={create}>
                    <div className="row">
                        <div className="col-12">
                            <label>Año del Concepto de Gasto</label>
                            <input type="number" className='form-control' name="BudgetValue" value={year} onChange={(e) => { setYear(parseInt(e.target.value)) }} />
                        </div>
                        <div className="col-12">
                            <label>Concepto de Gasto</label>
                            <CashReimbursementConceptEditor label=' ' _year={year} idSelector={reimbursementBudget.bean.IDCashReimbursementConcept} onChange={(e) => { reimbursementBudget.setBean({ ...reimbursementBudget.bean, "IDCashReimbursementConcept": e }) }} />
                        </div>
                        <div className="col-12">
                            <label>Valor del Gasto</label>
                            <input type="number" className='form-control' name="BudgetValue" value={reimbursementBudget.bean.BudgetValue} onChange={reimbursementBudget.handleChange} />
                        </div>
                    </div>
                </Modal>
            }

            {modalDelete.view &&
                <ModalConfirm modal={modalDelete} updateModal={setModalDelete} eventModal={deleteItem} >
                    Eliminar el Item {reimbursementBudget.bean.Name}
                    <div className="row">
                        <div className="col-12">
                            <label>Observaciones</label>
                            <textarea value={obs} onChange={(e) => { setObs(e.target.value) }} className='form-control' />
                        </div>
                    </div>
                </ModalConfirm>
            }
        </>
    )
}