import { useEffect, useState } from "react";
import { Singleton } from "../../../../../core/services/Singleton";
import { ForpoService } from "../service/ForpoService";
import { useDashboardStore } from "../../../../pages/hooks/useDashboardStore";

export const useStoreItem = () => {
    const single = Singleton.getInstance()
    const { setLoading } = useDashboardStore();
    const service = new ForpoService();
    const [idStore, setIDStore] = useState<number>(0);
    const [lista, setLista] = useState([]);
    const [idinventori, setIDinventori] = useState(0);
    const [modalConfirm, setModalConfirm] = useState<any>({ name: "Confirmar", tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: "modal-sm", });
    const [modal, setModal] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: "modal-lg", });
    const [modalinventario, setModalinventario] = useState<any>({ name: "Inventario", tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: "modal-lg", });
    const [bean, setBean] = useState<any>({
        Name: "",
        Description: "",
        State: 1,
        IDEmployee: single.getAccountID(),
        MediaList: '',
        ExternalCode: '',
        FromDate: single.getCurrenDate(),
        IDStoreCategory: idStore,
        ItemPrice: 0.0,
        ItemTags: '',
        LinkExternalStore: '',
        UptoDate: single.getCurrenDate(),
    });


    useEffect(() => {
        getLista();
    }, [idStore]);




    const idBean = "IDStoreItem";

    const setRowBean = (bean: any) => {
        setBean(bean);
    };

    const getLista = () => {
        setLoading(true);
        service.getItemStoreRender(null, null, { "IDStoreCategory": idStore }).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue) {
                    setLista(resp.DataBeanProperties.ObjectValue);
                }
                setLoading(false);
            });
    };

    const head = [
        { title: "ID", property: idBean },
        { title: "Nombre", property: "Name" },
        { title: "Descripción", property: "Description" },
        { title: "Código externo", property: "ExternalCode" },
        { title: "Cantidad Maxima", property: "QuantityItem" },
        { title: "Precio", property: "ItemPrice" },
        { title: "Grupo", property: "GroupItem" },
    ];

    const agregarItem = () => {
        let b = {
            Name: "",
            Description: "",
            State: 1,
            IDEmployee: single.getAccountID(),
            MediaList: '',
            ExternalCode: '',
            QuantityItem: 1,
            FormDate: single.getCurrenDate(),
            IDStoreCategory: idStore,
            ItemPrice: 0.0,
            ItemTags: '',
            LinkExternalStore: '',
            UptoDate: single.getCurrenDate(),
        };
        setBean(b);
        setModal({ ...modal, "view": true, name: "Agregar" });
    };


    const editarItem = () => {
        setModal({ ...modal, "view": true, name: 'Editar' });
    };

    const eliminarItem = () => {
        bean.State = 0;
       //console.log(bean);
        updateItem();
        setModalConfirm({ ...modalConfirm, ['view']: false });
    }

    const updateItem = () => {
       //console.log(bean)
        setLoading(true);
        service.updateStoreItem(bean).subscribe((resp: any) => {
            setLoading(false);
            if (resp.DataBeanProperties.ObjectValue) {
                getLista();
                if (bean.idBean > 0) {
                    single.update(bean);
                } else {
                    single.create(bean);
                }
            }
        });
        setModal({ ...modal, "view": false });
    };


    const iconButtonsItem = [
        { nombre: "Agregar", visible: single.canCreate(), icono: "ri-file-add-line", evento: agregarItem, },
    ];

    return {
        idStore,
        setIDStore,
        single,
        service,
        lista,
        setLista,
        bean,
        setBean,
        modal,
        setModal,
        modalConfirm,
        setModalConfirm,
        idBean,
        updateItem,
        head,
        iconButtonsItem,
        modalinventario,
        idinventori,
        setIDinventori,

        setModalinventario,
        editarItem,
        agregarItem,
        eliminarItem,
        setRowBean,
    };
};
