import useDataBean from "../../../../../../core/services/dataBean/useDataBean";
import { _AppointmentManual } from "../../../../../../core/services/dataBean/EntityCatalog";
import { Singleton } from "../../../../../../core/services/Singleton";
import { useEffect, useState } from "react";
import { BasicPage } from "../../../../../theme/Component/BasicPage";
import { ContractEditor } from "../../../../../erp/cellEditor/ContractEditor";
import { PrintJson } from "../../../../../theme/Component/PrintJson";
import { LuFileSearch } from "react-icons/lu";
import { IconButton } from "../../../../../../core/shared/buttons/IconButton";

interface PositionProps {
    dashedMenu: boolean;
}

export const AppointmentManualUse = ({ dashedMenu }: PositionProps) => {
    const positionObjective = useDataBean(_AppointmentManual);
    const single = Singleton.getInstance();
    const [lista, setLista] = useState<any>([]);
    const [contrac, setContrac] = useState<number>(null);
    const [modal, setModal] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });

    useEffect(() => {
        if (contrac) {
            getLista();
        }
    }, [contrac])

    const getLista = () => {
        positionObjective.instance.getAppointmentManual(contrac).then(
            (resp: any) => {
                if (resp) {
                    setLista(resp)
                   //console.log(resp)
                }
            }
        )
    }

    return (
        <>
            {dashedMenu ?
                <div className="row">
                    <div className="col-12">
                        <ContractEditor idAccount={single.getAccountID()} onChange={(e) => { setContrac(e) }} />
                    </div>
                    <div className="col-md-12 ">
                        <ul>
                            {lista.map((item: any, idnex: number) => {
                                return (
                                    <li key={idnex} className="d-flex mb-4 pb-3 border-bottom" style={{ width: "90%" }}>
                                        <div className="avatar flex-shrink-0 me-3">
                                            <div style={{ color: '#006699', fontSize: '60px' }}>
                                                <LuFileSearch />
                                            </div>
                                        </div>
                                        <div className="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
                                            <div className="me-2">
                                                <small className="text-muted d-block mb-1">{item.DataBeanProperties.Type}</small>
                                                <h6 className="mb-0">{`${item.DataBeanProperties.Filename}`}</h6>
                                            </div>
                                            <div className="d-flex align-items-center gap-1">
                                                <IconButton
                                                    icon="ri-file-download-line"
                                                    event={() => window.open(`${item.DataBeanProperties.URLLink}`)}
                                                />
                                            </div>
                                        </div>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                </div>
                :
                <BasicPage titulo="Ver Manuales de Competencias y Funciones">
                    <div className="row">
                        <div className="col-12">
                            <ContractEditor onChange={(e) => { setContrac(e) }} />
                        </div>
                        <div className="col-md-12 ">
                            <ul>
                                {lista.map((item: any, idnex: number) => {
                                    return (
                                        <li key={idnex} className="d-flex mb-4 pb-3 border-bottom" style={{ width: "90%" }}>
                                            <div className="avatar flex-shrink-0 me-3">
                                                <div style={{ color: '#006699', fontSize: '60px' }}>
                                                    <LuFileSearch />
                                                </div>
                                            </div>
                                            <div className="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
                                                <div className="me-2">
                                                    <small className="text-muted d-block mb-1">{item.DataBeanProperties.Type}</small>
                                                    <h6 className="mb-0">{`${item.DataBeanProperties.Filename}`}</h6>
                                                </div>
                                                <div className="d-flex align-items-center gap-1">
                                                    <IconButton
                                                        icon="ri-file-download-line"
                                                        event={() => window.open(`${item.DataBeanProperties.URLLink}`)}
                                                    />
                                                </div>
                                            </div>
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                    </div>
                </BasicPage>
            }
        </>
    )
}