import { DataBean } from "../DataBean";

export class ImportedPayrollBeneficiary extends DataBean {

    public async getImportedPayrollBeneficiaryCatalog(idImportedPayrollBeneficiary: any): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_getImportedPayrollBeneficiaryCatalog_Number", [idImportedPayrollBeneficiary]);
    }

    public async getWelfareEntityTypeConstants(args: number | null): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_getWelfareEntityTypeConstants_Number", [args]);
    }
}