import { useEffect, useState } from "react";
import { ContractService } from "../../../../../../core/services/ContractService";
import { Singleton } from "../../../../../../core/services/Singleton";
import { Modal } from "../../../../../theme/Component/Modal";
import { ManageAdquisitionItem } from "./ManageAdquisitionItem";
import { PrintJson } from "../../../../../theme/Component/PrintJson";

interface Props {
    idAdquisitionItem?: number;
    onChage: Function;
    label?: string;
}
export const AdquisitionItemSelector = ({ idAdquisitionItem, onChage, label }: Props) => {

    const single = Singleton.getInstance();
    const service = new ContractService();

    const [idItem, setIDItem] = useState<number>(NaN);
    const [item, setItem] = useState<any>({});

    const [modal, setModal] = useState<any>({ name: "Necesidades por Plan de Compras", tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-xl' });

    useEffect(() => {
        if (idAdquisitionItem) {
            setIDItem(idAdquisitionItem);
        } else {
            setIDItem(NaN)
            setItem({})
        }
    }, [idAdquisitionItem])

    useEffect(() => {
        if (idItem > 0)
            getItem();
    }, [idItem])

    const getItem = () => {
        single.spinner(true);
        service.getAdquisitionItem(idItem).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue) {
                    let item = resp.DataBeanProperties.ObjectValue.DataBeanProperties;
                    setItem(item);
                    if (item.IDAdquisitionItem) {
                        onChage(item);
                    }
                }
                single.spinner(false);
            }
        )
    }

    const selecionarItem = () => {
        onChage(item)
        setModal({ ...modal, "view": false });
    }


    return (
        <>
            <div className="row">
                {/* <PrintJson json={item} /> */}
                <div className="col-lg-4">
                    {item.IDAdquisitionItem ?
                        <label>ID de la Necesidad</label>
                        :
                        <label>{label ?? "Buscar Necesidad"}</label>
                    }
                    <input type="text" className="form-control" aria-label="Recipient's username"
                        aria-describedby="button-addon2" placeholder='Buscar...'
                        value={item.IDAdquisitionItem}
                        onClick={() => { setModal({ ...modal, 'view': true }); }}
                    />
                </div>
                {item.IDAdquisitionItem &&
                    <>
                        {/* <PrintJson json={item} /> */}
                        <div className="col-lg-4">
                            <label>Valor de Artículos</label>
                            <input className="form-control" disabled value={item.TotalValue} />
                        </div>
                        <div className="col-lg-4">
                            <label>Oficina</label>
                            <input className="form-control" disabled value={item.IDLnFunctionalIDName} />
                        </div>
                        <div className="col-md-12">
                            <label>Descripción</label>
                            <textarea rows={5} className="form-control" value={item.Description} disabled />
                        </div>
                    </>
                }
            </div>

            {modal.view &&
                <Modal modal={modal} updateModal={setModal} eventModal={selecionarItem} onSubmitLabel="Seleccionar Necesidad" >
                    <ManageAdquisitionItem _onChange={(e) => { setItem(e) }} filterState={6} />
                </Modal>
            }
        </>
    )
}