import { GenericService } from "../../../../../core/services/GenericService";


export class CashReimbursementServices extends GenericService {

    public updateCashReimbursement(bean) {
        const parametros = {
            ServiceName: "QuickDataERPCore",
            MethodHash: "com.quickdataerp.bean.accountant.CashReimbursement_updateCashReimbursement_com.quickdataerp.bean.accountant.CashReimbursement",
            ArgumentList: [
                {
                    DataBeanProperties: bean,
                    DataBeanName: 'com.quickdataerp.bean.accountant.CashReimbursement'
                }
            ],
        };
        const data = JSON.stringify(parametros);
        return this.myApi.post(this.url, data);
    }

    public getCashDeskAccountCatalog(officeid: number | null, accountid: number | null) {
        const parametros = {
            ServiceName: "QuickDataERPCore",
            MethodHash: "java.util.List_getCashDeskAccountCatalog_Number_Number",
            ArgumentList: [officeid, accountid],
        };
        const data = JSON.stringify(parametros);
        return this.myApi.post(this.url, data);

    }

    public getDocumentFactoryCatalog(idDocumentFactory: number | null) {
        const parametros = {
            ServiceName: "QuickDataERPCore",
            MethodHash: "java.util.List_getDocumentFactoryCatalog_Number",
            ArgumentList: [idDocumentFactory],
        };
        const data = JSON.stringify(parametros);
        return this.myApi.post(this.url, data);

    }

    public getCashReimbursementCatalog(year: number, id: number, state: number) {
        const parametros = {
            ServiceName: "QuickDataERPCore",
            MethodHash: "java.util.List_getCashReimbursementCatalog_Number_Number_Number",
            ArgumentList: [year, id, state],
        };
        const data = JSON.stringify(parametros);
        return this.myApi.post(this.url, data);

    }

    public getCashReimbursementProcessConstants(process: null) {
        const parametros = {
            ServiceName: "",
            MethodHash: "",
            ArgumentList: [process],
        };
        const data = JSON.stringify(parametros);
        return this.myApi.post(this.url, data);

    }



}