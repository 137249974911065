import { DataBean } from "../DataBean";

export class BudgetAccountantMovement extends DataBean {

    public async getBudgetAccountantMovementCatalog(idBudgetAccountantOperation: number): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_getBudgetAccountantMovementCatalog_Number", [idBudgetAccountantOperation]);
    }

    public async getBudgetAccountantMovementTypeConstants(arg: number): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "java.util.List_getBudgetAccountantMovementTypeConstants_Number", [arg]);
    }

    public async updateBudgetAccountantMovement(bean: any): Promise<any> {
        return this.getCustomService("QuickDataERPCore", "com.quickdataerp.bean.admin.BudgetAccountantMovement_updateBudgetAccountantMovement_com.quickdataerp.bean.admin.BudgetAccountantMovement", [
            {
                "DataBeanProperties": bean,
                "DataBeanName": "com.quickdataerp.bean.admin.BudgetAccountantMovement"
            }
        ]);
    }

}
