import { GenericApproval } from './custom-forms/GenericApproval';

import { SaveToken } from './custom-forms/SaveToken';
import { TokenAssign } from './custom-forms/TokenAssign';
import { ValidationSIIF } from './custom-forms/ValidationSIIF';
import { Singleton } from '../../../../core/services/Singleton';
import { ForpoService } from '../../../custom/forpo/components/service/ForpoService';
import { useEffect, useState } from 'react';
import { useInboxStore } from '../hooks/useInboxStore';
import { RequestToken } from './custom-forms/RequestToken';
import { AproveOrder } from './custom-forms/AprobeOrder';
import { PrintJson } from '../../../theme/Component/PrintJson';
import { RenderForm } from '../../../theme/Component/RenderForm';
import { GenerateCDP } from './custom-forms/GenerateCDP';
import { FileInput } from '../../../theme/Component/FileInput';
import { BasicButton } from '../../../theme/Component/BasicButton';
import { EstudioPrevioBpm } from '../../../contract/bpm/contratos/EstudioPrevioBpm';
import { ConfigurarOfertaBpm } from '../../../contract/bpm/contratos/ConfigurarOfertaBpm';
import { UploadQuotes } from '../../../pages/Dashed/components/PersonaInbox/CustomForms/UploadQuotes';
import { ApprovalFlow } from '../../../pages/Dashed/components/PersonaInbox/CustomForms/ApprovalFlow';
import { CreateCDP } from '../../../pages/Dashed/components/PersonaInbox/CustomForms/CreateCDPFactory/CreateCDP';
import { PrevEstudyOBS } from '../../../pages/Dashed/components/PersonaInbox/CustomForms/PrevEstudyOBS';



interface Props {
    Procedure: any;
    _Procedure: any;
    credit: any | undefined;
    closeModal: any;
}

export const ProcedureAction = ({ Procedure, credit, closeModal, _Procedure }: Props) => {


    const single = Singleton.getInstance();
    const service = new ForpoService();
    const [afiliate, setAfiliate] = useState({});
    const [doc, setDoc] = useState<any>({});
    const { responseProcedureAction, } = useInboxStore();

    useEffect(() => {
        if (credit != undefined)
            if (credit.IDAffiliate >= 0) {
                getUser({ IDAffiliate: credit.IDAffiliate })
            }
    }, [credit])

    const update = (bean: any) => {
        single.spinner(true);
        service.updateCredit(bean).subscribe(
            (resp: any) => {
                if (resp) {
                    single.spinner(false);
                    single.update(bean);
                }
            }
        )
    }

    const getUser = (properties: object) => {
        single.spinner(true);
        //console.log(properties)
        service.getAffiliateRender(null, null, properties).subscribe(
            (resp: any) => {
                ////console.log(resp)
                if (resp.DataBeanProperties.ObjectValue.length > 0) {
                    //console.log("Render del afiliado?======>", resp.DataBeanProperties.ObjectValue)
                    setAfiliate(resp.DataBeanProperties.ObjectValue[0].DataBeanProperties)
                } else {
                    //console.log("No se encontro el usuario");
                }
                single.spinner(false)
            }
        )
    }

    const responseAction = (map: any) => {
        //console.log(Procedure.IDAction, map);
        responseProcedureAction(Procedure.IDAction, map);
        setTimeout(() => {
            closeModal();
        }, 2000);

    }

    const updateDoc = () => {
        //console.log("doc=======>", doc)
        responseAction(doc);
    }

    const renderSwicth = () => {
        switch (Procedure.FormCode) {


            // Render Forpo
            case 'TKP01':
                return (
                    <TokenAssign procedure={{}} exportMap={(data: any) => {
                        //console.log(data);
                        responseAction(data)
                    }} />
                )
            case 'APP01':
                return (
                    <AproveOrder affiliate={afiliate} credit={credit} title='Aprobación del Orden del gasto' message={""} exportMap={(data: any) => {
                        //console.log(data);
                        responseAction(data)
                    }} />
                )
            case 'PT001':
                return (
                    <RequestToken
                        updateCredit={(bean: any) => { update(bean) }}
                        _credit={credit}
                        title='Registrar token portal tercero'
                        message={"Ingrese el token generado"}
                        exportMap={(data: any) => {
                            //console.log(data);
                            responseAction(data)
                        }}
                        _user={afiliate}
                    />
                )
            case 'PT002':
                return (
                    <SaveToken
                        closeModal={() => { closeModal() }} updateCredit={(bean: any) => { update(bean) }}
                        affiliate={afiliate}
                        credit={credit}
                        title='Grabar token portal tercero'
                        message={"Ingrese a portal terceros y grabe el token generado"}
                        exportMap={(data: any) => {
                            //console.log(data);
                            responseAction(data)
                        }} />
                )
            case "PT003":
                return (
                    <ValidationSIIF procedure={{}} title='Validar Cuenta de Usuario en SIIF' message={"Validar si la cuenta del usuario que esta solicitando el credito se encuentra en SIIF"} exportMap={(data: any) => {
                        //console.log(data);
                        responseAction(data)
                    }} />
                )

            //Render QUICKBPM

            //estudio previo
            case "911":
                return (
                    <>
                        <EstudioPrevioBpm data={Procedure} exportMap={(data: any) => { responseAction(data) }} />

                    </>
                )
            case "903":
                return (
                    <>
                        <p>Solicitud contratar Necesidad</p>
                        <RenderForm idFormType={parseInt(Procedure.FormCode)} handleUpdate={(data: any) => { responseAction(data) }} />
                    </>
                )
            // Validacion Estudio Previo
            case "904":
                return (
                    <>
                        <p>Validacion estudio Previo</p>
                        <RenderForm idFormType={parseInt(Procedure.FormCode)} handleUpdate={(data: any) => { responseAction(data) }} />
                    </>
                )
            //Configurar Oferta
            case "905":
                return (
                    <>
                        <ConfigurarOfertaBpm data={Procedure} exportMap={(data: any) => { responseAction(data) }} />
                    </>
                )

            //aprobar Oferta (Diferentes roles)
            case "801":
                return (
                    <>
                        <p>Aprobación</p>
                        <RenderForm idFormType={901} handleUpdate={(data: any) => { responseAction(data) }} />
                    </>

                )

            //Publicar oferta
            case "906":
                return (
                    <>
                        <p>Publicar Oferta</p>
                        <RenderForm idFormType={901} handleUpdate={(data: any) => { responseAction(data) }} />
                    </>
                )

            //Cierre de Oferta
            case "907":
                return (
                    <>
                        <p>Cierre de Oferta</p>
                        <RenderForm idFormType={901} handleUpdate={(data: any) => { responseAction(data) }} />
                    </>
                )

            //Analisis de cuadro comparativo
            case "908":
                return (
                    <>
                        <p>Analisis del cuadro comparativo</p>
                        <RenderForm idFormType={901} handleUpdate={(data: any) => { responseAction(data) }} />
                    </>
                )

            // Seleccion del provedor
            case "909":
                return (
                    <>
                        <p>Seleccion del provedor</p>
                        <RenderForm idFormType={901} handleUpdate={(data: any) => { responseAction(data) }} />
                    </>
                )
            case "910":
                return (
                    <>
                        <p>formulario Generacion de contrato</p>
                        <RenderForm idFormType={901} handleUpdate={(data: any) => { responseAction(data) }} />
                    </>
                )

            //    
            case "CDP01":
                return (
                    <GenerateCDP exportMap={(data) => responseAction(data)}></GenerateCDP>
                )

            //    
            case "ARC01":
                return (
                    <div className="row">
                        <div className="col-md-12">
                            <FileInput label='Cargar Docuemento Requerido' onCreate={({ Media, MediaContext, Name }) => { setDoc({ ...doc, 'Media': Media, 'MediaContext': MediaContext, "Name": Name }) }} />
                        </div>
                        <div className="col-4">
                            <BasicButton icon="ri-send-plane-2-line" clase='primary' eventClick={() => updateDoc()}>Cargar</BasicButton>
                        </div>
                    </div>
                )

            case "EP01":
                return (
                    <div className="row">
                        <div className="col-md-12 d-flex justify-content-center">
                            <div className="row w-75">
                                <div className="col-md-12">
                                    <FileInput label='Cargar Docuemento Requerido' onCreate={({ Media, MediaContext, Name }) => { setDoc({ ...doc, 'Media': Media, 'MediaContext': MediaContext, "Name": Name }) }} />
                                </div>
                                <div className="col-4 mt-2">
                                    <BasicButton icon="ri-send-plane-2-line" clase='primary' eventClick={() => updateDoc()}>Cargar</BasicButton>
                                </div>
                            </div>
                        </div>
                    </div>
                )

            case "EP02":
                return (
                    <UploadQuotes exportMap={(data) => responseAction(data)} />
                )
            case "EP03":
                return (
                    <ApprovalFlow exportMap={(data) => responseAction(data)} />
                )
            case "EP04":
                return (
                    <CreateCDP procedure={Procedure} exportMap={(data) => responseAction(data)} />
                )
            case "EP05":
                return (
                    <PrevEstudyOBS procedure={Procedure} exportMap={(data) => { responseAction(data) }} />
                )

            default:
                return (
                    <>
                        <h4>Formulario generico</h4>
                        <RenderForm idFormType={901} handleUpdate={(data: any) => { responseAction(data) }} />
                    </>
                )
        }
    }

    return (
        <div className='row w-100 mx-auto'>
            {Procedure.FormCode ?
                <>
                    {renderSwicth()}
                </>
                :
                <>
                    <div className="row d-flex justify-content-center">
                        <h5>
                            {Procedure.FormCode} - {Procedure.Name}
                        </h5>
                        <FileInput label='Cargar Docuemento Requerido' onCreate={({ Media, MediaContext, Name }) => { setDoc({ ...doc, 'Media': Media, 'MediaContext': MediaContext, "name": Name }) }} />
                        {doc.Media &&
                            <div className="col-4">
                                <BasicButton icon="ri-send-plane-2-line" clase='primary' eventClick={() => updateDoc()}>Cargar</BasicButton>
                            </div>
                        }
                    </div>
                </>
            }
        </div>
    )
}
