import { useEffect, useState } from "react";
import { _CompetitionType, _EmployeePayments, _Entailment, _MacroProcess } from "../../../../core/services/dataBean/EntityCatalog";

import { Singleton } from "../../../../core/services/Singleton";
import { toast } from "react-toastify";
import { ListMenu } from "../../../theme/Component/ListMenu";
import { BasicPage } from "../../../theme/Component/BasicPage";
import { BasicTable, MASK_NUMBER } from "../../../theme/Component/BasicTable";
import { Modal } from "../../../theme/Component/Modal";
import { ModalConfirm } from "../../../theme/Component/ModalConfirm";
import useDataBean from "../../../../core/services/dataBean/useDataBean";
import { _TaxCode, _TaxCodeAccount } from "../../../../core/services/dataBean/EntityCatalogVdos";
import { AccountEditor } from "../../../editor/AccountEditor";
import { TaxCodeSchemeEditor } from "../../../admin/ConfImpuestos/TaxCodeScheme/TaxCodeScheme.editor";
import { BasicButton } from "../../../theme/Component/BasicButton";
import usePaginator from "../../../theme/Component/usePaginator";
import { register } from "module";

interface Props {
    isModal?: boolean;
    _idAccount?: number;
}

export const TaxCodeAccount = ({ isModal, _idAccount }: Props) => {

    const TaxCodeAccount = useDataBean(_TaxCodeAccount);
    const paginador = usePaginator();
    // const taxCode = useDataBean(_TaxCode);
    const single = Singleton.getInstance();
    const [IdAcoount, setIdAcoount] = useState<number>(null)
    const [IdTaxCodeScheme, setIdTaxCodeScheme] = useState<number>(null)
    const [lista, setLista] = useState<any>([]);
    const [Filter, SetFilter] = useState('');
    const [Register, SetRegister] = useState(15);
    const [Page, SetPage] = useState(1);
    const [totalRecords, setTotalRecords] = useState(0); // Para almacenar el total de registros
    const [Code, SetCode] = useState('');
    const [listataxcode, setListataxcode] = useState<any>([]);
    const [modal, setModal] = useState<any>({ name: 'Editor de Códigos de Clasificación', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-md' });
    const [modalC, setModalC] = useState<any>({ name: 'Editor de Códigos de Clasificación', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });

    useEffect(() => {
        getLista();
    }, [IdAcoount])

    // useEffect(() => {
    //     getListacode();
    // }, [IdTaxCodeScheme])

    useEffect(() => {
        if (_idAccount) {
            setIdAcoount(_idAccount);
        }
    }, [_idAccount])

    const getLista = () => {

        TaxCodeAccount.instance.getTaxCodeAccountCatalog(IdAcoount).then(
            (resp: any) => {
                if (resp) {
                    setLista(resp)
                   //console.log(resp)
                }
            }
        )
    }

    // const getListacode = () => {
    //     TaxCodeAccount.instance.getTaxCodeCatalog(IdTaxCodeScheme).then(
    //         (resp) => {
    //             setListataxcode(resp)
    //         }
    //     )
    // }
    useEffect(() => {
        getListacode();
    }, [paginador.page])

    const getListacode = () => {
        TaxCodeAccount.instance.getTaxCodeCatalogPaginador(IdTaxCodeScheme, Code == '' ? null : Code, Filter == '' ? null : Filter, paginador.page, Register).then(
            (resp) => {
                setListataxcode(resp.List)
                let paginas = resp.NumberOfPages;
                paginador.setPages(paginas);
               //console.log(resp)
            }
        )
    }



    const create = () => {
        TaxCodeAccount.setBean({
            IDAccount: IdAcoount,
            idTaxCode: TaxCodeAccount.bean.IDTaxCode
        });
        setModal({ ...modal, "view": true });
    }



    const deleteItem = () => {

        TaxCodeAccount.deleteItemVoid(TaxCodeAccount.bean.IDTaxCodeAccount).then(
            (resp: any) => {
                getLista();
                setModalC({ ...modalC, "view": false });
            }
        )
    }

    const editaItem = () => {
        if (TaxCodeAccount.bean.IDTaxCode) {
            TaxCodeAccount.instance.updateTaxCodeAccount(TaxCodeAccount.bean).then(
                (resp: any) => {
                    getLista();
                    setModal({ ...modal, "view": false });
                }
            ).catch(err => {
                toast.error(err);
            });
        } else {
            toast.error('Por favor, seleccione un código de clasificación de impuestos antes de guardar.');
        }
    };


    const head = [
        { title: 'ID', property: 'IDTaxCodeAccount', visible: false },
        { title: 'Código de la Actividad', property: 'TaxCodeCode', visible: true, mask: MASK_NUMBER },
        { title: 'Descripción de la Actividad', property: 'TaxCodeName', visible: true },

    ]

    const headtwo = [
        { title: 'ID', property: 'IDTaxCode', visible: false },
        { title: 'Código', property: 'Code', visible: true, mask: MASK_NUMBER },
        { title: 'Nombre', property: 'Name', visible: true },

    ]

    const iconButtonsItem = [
        { nombre: 'Agregar', visible: single.canCreate(), icono: 'ri-file-add-line', evento: create }
    ]
    const listaBotones = () => {
        const botones = [

            { titulo: 'Editar', icono: 'ri-edit-2-line', visible: single.canEdit(), evento: () => { setModal({ ...modal, "view": true }) } },
            { titulo: 'Eliminar', icono: 'ri-delete-bin-line', visible: single.canDelete(), evento: () => { setModalC({ ...modalC, "view": true }) } },

        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }


    return (
        <>
            {isModal ?
                <div className="row">
                    <div className="col-md-12">
                        <AccountEditor disabled={Number.isInteger(_idAccount)} label="Beneficiario" idAccount={IdAcoount} onChange={(e) => { setIdAcoount(e.IDAccount) }} />
                    </div>
                    <div className="col-md-12">
                        <BasicTable body={lista} head={head} rowSet={TaxCodeAccount.setBean} listButtons={listaBotones()} iconButtons={iconButtonsItem}></BasicTable>
                    </div>

                </div>
                :
                <>
                    <BasicPage titulo="Códigos de Clasificación de Impuestos por Tercero">
                        <div className="row">
                            <div className="col-md-12">
                                <AccountEditor disabled={Number.isInteger(_idAccount)} label="Beneficiario" idAccount={IdAcoount} onChange={(e) => { setIdAcoount(e.IDAccount) }} />
                            </div>
                            <div className="col-md-12">
                                <BasicTable body={lista} head={head} rowSet={TaxCodeAccount.setBean} listButtons={listaBotones()} iconButtons={iconButtonsItem}></BasicTable>
                            </div>

                        </div>
                    </BasicPage>
                </>
            }

            {modal.view &&
                <Modal modal={modal} updateModal={setModal} eventModal={editaItem}>
                    <div className="row">

                        <div className="col-12 mb-2">
                            <TaxCodeSchemeEditor idSelector={IdTaxCodeScheme} onChange={(e) => { setIdTaxCodeScheme(e) }} />
                        </div>
                        <div className="col-md-6">
                            <label>Filtrar por Nombre</label>
                            <input type="text" name="Filter" className="form-control" value={Filter} onChange={(e) => { SetFilter(e.target.value) }} />
                        </div>
                        <div className="col-md-6">
                            <label>Filtrar por Código</label>
                            <input type="text" name="Code" className="form-control" value={Code} onChange={(e) => { SetCode((e.target.value)) }} />
                        </div>
                        <div className="col-md-6">
                            <label>Cantidad Registro por pagina</label>
                            <input type="number" name="Value" className="form-control" value={Register} onChange={(e) => { SetRegister(parseInt(e.target.value)) }} />
                        </div>
                        <div className="col-md-6 mt-4">
                            <BasicButton icon={''} eventClick={getListacode}>Buscar</BasicButton>
                        </div>
                        {/* 
                        <div className="col-12">

                            <TaxCodeEditor _codeScheme={IdTaxCodeScheme} label="Buscador de códigos" idSelector={TaxCodeAccount.bean.IDTaxCode} onChange={(e) => { TaxCodeAccount.setBean({ ...TaxCodeAccount.bean, "IDTaxCode": e }) }} />
                        </div> */}
                        <div className="col-md-12">
                            <BasicTable
                                customRows={Register}
                                body={listataxcode}
                                head={headtwo}
                                rowSet={(selectedRow) => {
                                    // Al seleccionar una fila en la tabla, actualizamos el bean como lo hace el selector
                                    TaxCodeAccount.setBean({
                                        ...TaxCodeAccount.bean,
                                        IDTaxCode: selectedRow.IDTaxCode // Aquí seleccionamos el ID del código de la fila seleccionada
                                    });
                                }}
                                pageTop={paginador.page} next={paginador.next} back={paginador.back} records={paginador}
                            ></BasicTable>
                        </div>
                    </div>
                </Modal>
            }

            {modalC.view &&
                <ModalConfirm modal={modalC} updateModal={setModalC} eventModal={deleteItem}>
                    <strong>Eliminar item</strong>
                </ModalConfirm>
            }
        </>
    )
}