import { useState, useEffect } from 'react';
import { BpmService } from '../../../../core/services/BpmService';
import { Singleton } from '../../../../core/services/Singleton';

interface Props {
    idSelector: string;
    onChange: any;
}

export const SelectorBPMStructsForQuery = ({ idSelector, onChange }: Props) => {

    const service = new BpmService();
    const [lista, setLista] = useState<any[]>([]);
    const [id, setID] = useState('');

    useEffect(() => {
        setID(idSelector);
        getLista();
    }, [])

    useEffect(() => {
        onChange(id);
    }, [id])

    const setValue = (e: any) => {
        setID(e);
        onChange(e); // Corregí el argumento pasado a onChange
    }

    const getLista = () => {
        service.getBPMStructsForQuery(null).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue) {
                    setLista(resp.DataBeanProperties.ObjectValue);
                } else {
                   //console.log('Error en la carga de los seleccionables')
                }
            }
        );
    }

    return (
        <>
            <label>Tipo de método</label>
            <select className="form-control mt-2" value={id} onChange={(e) => { setValue(e.target.value) }}>
                <option value={0} key={Singleton.getInstance().getRandomInt()}>...</option>
                {lista.map((item: any) => (
                    <option key={item.DataBeanProperties.Value} value={item.DataBeanProperties.Value} >{item.DataBeanProperties.Property}</option>
                ))}
            </select>
        </>
    )
}
