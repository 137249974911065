import { useEffect, useState } from "react";
import { Miscursos } from "./Miscursos";
import { MisSubsCourse } from "./MisSubsCourse";




export const TabsCourse = () => {


    const [activeTab, setActiveTab] = useState('tab1');


    return (
        <>

            <div>
                <ul className="nav nav-tabs">
                    <li className="nav-item">
                        <button
                            className={`nav-link ${activeTab === 'tab1' ? 'active' : ''}`}
                            onClick={() => setActiveTab('tab1')}
                        >
                            <strong>CURSOS DISPONIBLES</strong>

                        </button>
                    </li>
                    <li className="nav-item">
                        <button
                            className={`nav-link ${activeTab === 'tab2' ? 'active' : ''}`}
                            onClick={() => setActiveTab('tab2')}
                        ><strong>MIS CURSOS</strong>

                        </button>
                    </li>

                </ul>

                <div className="tab-content mt-3">
                    {activeTab === 'tab1' && <div><Miscursos /></div>}
                    {activeTab === 'tab2' && <div><MisSubsCourse /></div>}

                </div>
            </div>

        </>
    )
}