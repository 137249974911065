import { useEffect, useState } from "react"
import { Singleton } from "../../../core/services/Singleton";
import { AdminService } from "../../../core/services/AdminService";
import { PrintJson } from "../../theme/Component/PrintJson";
import { InputDate } from "../../theme/Input/InputDate";
import { DataBeanProperties } from '../../../core/services/model/server-response.interface';
import { ContractEditor } from "../../erp/cellEditor/ContractEditor";
import { BasicButton } from '../../theme/Component/BasicButton';
import { YearSelector } from "../../../core/shared/inputs/YearSelector";
import { AccountEditor } from "../../editor/AccountEditor";

interface Props {
    idAccount: number;
    idDocumentTemplate: number;
    nameButton?: string;
    onChange: Function;
}
export const FormGenericCellEditor = ({ idDocumentTemplate, idAccount, nameButton, onChange }: Props) => {

    const service = new AdminService();
    const single = Singleton.getInstance();
    const [lista, setLista] = useState<any>([]);
    const [bean, setBean] = useState<any>({});



    useEffect(() => {
        setBean({});
    }, [])

    useEffect(() => {
        getDictionaryPropertyes();
        if (idAccount) {
            setBean({ ...bean, "IDAccount": idAccount });
        }
    }, [idDocumentTemplate])

    const getDictionaryPropertyes = () => {
        single.spinner(true);
        service.getProperties(idDocumentTemplate).subscribe(
            (resp: any) => {
               //console.log(resp);

                if (resp.DataBeanProperties.ObjectValue) {
                    let list = resp.DataBeanProperties.ObjectValue;

                    setLista(list);
                }
                else {
                    setLista([]);
                }
                single.spinner(false);
            }
        )
    }

    const handleChange = (e: any) => {

        setBean(single.handleFormChange(bean, e));

    }

    const updateForm = () => {
        onChange(bean);
    }

    const renderField = (item: any) => {

        let caso = item.PropertyEditor;

        switch (caso) {
            case 'YEAR_SELECT_EDITOR':
                return (
                    <>
                        <div className="col-md-6">

                            <YearSelector value={bean[item.PropertyName]} onChange={(e) => { setBean({ ...bean, [item.PropertyName]: e }) }} />
                        </div>

                    </>
                )
            case 'ACCOUNT_SELECT_EDITOR':
                return (
                    <>
                        <div className="col-md-6">

                            <AccountEditor idAccount={idAccount} label={item.PropertyTag} onChange={({ IDAccount }) => { setBean({ ...bean, [item.PropertyName]: IDAccount }) }} />
                            {/* <InputDate label={item.PropertyTag} name={item.PropertyName} setDate={(e: any) => setBean({ ...bean, [item.PropertyName]: e })} value={bean[item.PropertyName]} /> */}
                        </div>

                    </>
                )
            
            case 'EMPLOYEECONTRACT_SELECT_EDITOR':
                return (
                    <div className="col-md-6">
                        <ContractEditor idAccount={bean.IDAccount} onChange={(e: any) => { setBean({ ...bean, [item.PropertyName]: e }) }} />
                    </div>


                )
            case 'TEXTAREA_EDITOR':
                return (
                    <div className="col-md-12">
                        <label>{item.PropertyTag}</label>
                        <textarea className="form-control" value={bean[item.PropertyName]} name={item.PropertyName} onChange={handleChange}></textarea>
                    </div>


                )

            default:
                return (
                    <div className="col-md-6">
                        <label>{item.PropertyTag}</label>
                        <input type="text" className="form-control" value={bean[item.PropertyName]} name={item.PropertyName} onChange={handleChange} />
                    </div>
                )

        }

    }

    return (
        <>
            <div className="row">
                {lista.map((field: any) => {
                    return (
                        <>
                            <span id={field.DataBeanProperties.PropertyEditor}>
                                {renderField(field.DataBeanProperties)}
                            </span>

                        </>
                    )
                })

                }
            </div>
            <div className="row">
                <div className="col-md-12">
                    <BasicButton icon="ri-send-plane-2-line" eventClick={updateForm}>{nameButton ? nameButton : 'Guardar'}</BasicButton>
                </div>
            </div>
            {/* <div className="row">
                <div className="col">
                    <PrintJson json={lista} />
                </div>
                <div className="col">
                    <PrintJson json={bean} />
                </div>
            </div> */}
        </>
    )
}