import { useState, useEffect } from 'react';
import { Singleton } from "../../../core/services/Singleton";
import { BasicPage } from "../../theme/Component/BasicPage";
import { BasicButton } from "../../theme/Component/BasicButton";
import { BasicTable } from "../../theme/Component/BasicTable";
import { ListMenu } from "../../theme/Component/ListMenu";
import useDataBean from '../../../core/services/dataBean/useDataBean';
import { _AbstractAccount } from '../../../core/services/dataBean/EntityCatalog';
import { toast } from 'react-toastify';
import { Modal } from '../../theme/Component/Modal';
import { EntityTypeConstantsEditor } from './Editor/EntityTypeConstants.editor';
import { DocTypeConstantsEditor } from './Editor/DocTypeConstants.editor';
import { SelectSiteID } from '../../admin/configuracion/SiteID/SelectSiteID';
import { BakingEntityEditor } from '../../erp/cellEditor/BankingEntity/BankingEntitySelect';
import { BankingAccountEditor } from '../../Talentohumano/ParametrosTalentoHumano/ThirdBankingAccount/BankingAccountSelect';
import { BasicSwitch } from '../../theme/Component/BasicSwitch';
import { FileInput } from '../../theme/Component/FileInput';
import { GenderTypeConstantsEditor } from './Editor/GenderTypeConstants.editor';
import { InputDate } from '../../theme/Input/InputDate';
import { BloodTypeConstantsEditor } from './Editor/BloodTypeConstants.editor';
import { RhTypeConstantsEditor } from './Editor/RhTypeConstants.editor';
import { MilitaryStatusConstantsEditor } from './Editor/MilitaryStatusConstants.editor';
import { CivilStatusConstantsEditor } from './Editor/CivilStatusConstants.editor';
import { RaceTypeConstantsEditor } from './Editor/RaceTypeConstants.editor';
import { HousingTypeConstantsEditor } from './Editor/HousingTypeConstants.editor';
import { StudiesTypeConstantsEditor } from './Editor/StudiesTypeConstants.editor';
import { AccountEditor } from '../../editor/AccountEditor';
import { CardTaps } from '../../theme/Component/CardTaps';
import CameraCapture from '../../theme/Component/CameraCapture';
import { ThirdBankingAccount } from '../../Talentohumano/ParametrosTalentoHumano/ThirdBankingAccount/ThirdBankingAccount';
import { PrintJson } from '../../theme/Component/PrintJson';


export const AbstractAccountAdminGestion = () => {


    const absAcco = useDataBean(_AbstractAccount);
    const single = Singleton.getInstance();
    const [lista, setLista] = useState<any>([]);
    const [nit, setNit] = useState<number>(null);
    const [name, setName] = useState<string>('');
    const [surname, setSurname] = useState<string>('');
    const [tipoinput, setTipoinput] = useState(0);
    const [modal, setModal] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-md' });
    const [modalEdit, setModalEdit] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });

    const getListaBN = () => {
        absAcco.instance.getAccountByNit(nit).then(
            (resp: any) => {
                setLista(resp)
                if (resp.length > 0) {
                    toast.success("Usuario/s Encontrado/s")
                } else {
                    absAcco.setBean({ ...absAcco, "Nit": nit })
                    setModal({ ...modal, "view": true, "name": "Crear Tercero" })
                }
            }
        );
    }

    const getListaBN2 = (cc: any) => {

        absAcco.instance.getAccountByNit(cc).then(
            (resp: any) => {
                setLista(resp)
                if (resp.length > 0) {
                    toast.success("Usuario/s Encontrado/s")
                } else {
                    absAcco.setBean({ ...absAcco, "Nit": nit })
                    setModal({ ...modal, "view": true, "name": "Crear Tercero" })
                }
            }
        );
    }

    const getListaS = () => {

        absAcco.instance.getAccount(null, surname).then(
            (resp: any) => {
                setLista(resp)
                if (resp.length > 0) {
                    toast.success("Usuario/s Encontrado/s")
                } else {
                    setModal({ ...modal, "view": true, "name": "Crear Tercero" })
                }
            }
        );
    }


    const getListaN = () => {

        absAcco.instance.getAccount(name, null).then(
            (resp: any) => {

                setLista(resp)
                if (resp.length > 0) {
                    toast.success("Usuario/s Encontrado/s")
                } else {
                    setModal({ ...modal, "view": true, "name": "Crear Tercero" })
                }
            }
        );
    }

    const createAcc = () => {
        absAcco.instance.createAccount(absAcco.bean.EntityType, absAcco.bean.DocType, absAcco.bean.Nit).then(
            (resp) => {
               //console.log(resp)
                getListaBN2(absAcco.bean.Nit)
                setModal({ ...modal, "view": false })
            }
        )
    }

    const updateAbstracAccount = () => {
        setIndexTap(0)
        let x = absAcco.bean;
        x.IDEmployee = single.getAccountID();
        x.LastUpdate = single.getCurrenDate();
        x.State = 67;
        updateAccount(x)
    }

    const updateAccount = (bean: any) => {
       //console.log(bean)
        absAcco.instance.updateAbstractAccount(bean).then(
            (resp) => {
               //console.log(resp);
                getListaBN2(absAcco.bean.Nit);
                setModalEdit({ ...modalEdit, "view": false })
            }
        )
    }

    const head = [
        { title: 'ID', property: "IDAccount" },
        { title: 'Cédula/Nit', property: 'Nit' },
        { title: 'Nombre', property: 'EntityName' },
        { title: 'Correo', property: 'eMail' },
        { title: 'Teléfono', property: 'Tel' },
        { title: 'Tipo de Naturaleza', property: 'EntityTypeName' },
    ]

    const listaBotones = () => {
        const botones = [

            { titulo: 'Editar', icono: 'ri-file-list-line', visible: single.canEdit(), evento: () => { setModalEdit({ ...modalEdit, "view": true, "name": "Editar Información" }) } },
            // { titulo: 'Eliminar', icono: 'ri-file-user-line', visible: single.canDelete(), evento: () => { setModalConfirm({ ...modalConfirm, "view": true }) } },
            // { titulo: 'Activar cuenta', icono: 'ri-file-user-line', visible: true, evento: () => { setModal1({ ...modal1, "view": true }) } },
            // { titulo: 'Agregar empresa', icono: 'ri-file-user-line', visible: true, evento: () => { setModalEditar({ ...modalEditar, "view": true }) } },

        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }

    const tapsNatural = [
        { id: 0, name: 'Información Básica' },
        { id: 1, name: 'Contacto y Ubicación' },
        { id: 2, name: 'Información Financiera' },
        { id: 3, name: 'Información Adicional' },
        { id: 4, name: 'Cuentas Bancarias' },
        { id: 5, name: 'Foto' },
    ]

    const tapsJuridica = [
        { id: 0, name: 'Información Basica' },
        { id: 1, name: 'Contacto y Ubicación' },
        { id: 2, name: 'Información Financiera y Otros' },
        { id: 3, name: 'Cuentas Bancarias' },
    ]

    const [indexTap, setIndexTap] = useState(0);

    useEffect(() => {
        setIndexTap(0)
    }, [lista])

    return (
        <>
            <BasicPage titulo="Consultar y Editar Cuenta de Tercero">
                <div className="row">
                    <div className="col-md-4">
                        <label>Filtrar por:</label>
                        <select className="form-control" value={tipoinput} onChange={(e: any) => { setTipoinput(e.target.value) }}>
                            <option value={0}>...</option>
                            <option value={1}>Identificación</option>
                            <option value={2}>Nombres</option>
                            <option value={3}>Apellidos</option>
                        </select>
                    </div>
                    <div className="col-md-4">
                        {tipoinput == 3 &&
                            <>
                                <label>Buscar</label>
                                <input type="text" onChange={(e: any) => setSurname(e.target.value)} className="form-control inputSurname " placeholder="Filtrar pór Apellido" />
                            </>
                        }
                        {tipoinput == 2 &&
                            <>
                                <label>Buscar</label>
                                <input type="text" onChange={(e: any) => setName(e.target.value)} className="form-control inputName " placeholder="Filtrar por Nombre" />
                            </>
                        }
                        {tipoinput == 1 &&
                            <>
                                <label>Buscar</label>
                                <input type="text" onChange={(e: any) => setNit(e.target.value)} className="form-control inputNit " placeholder="Filtrar por Cedula / Nit" />
                            </>
                        }
                    </div>

                    <div className="col-md-4 mt-4">
                        {tipoinput == 3 &&
                            <BasicButton value="BUSCAR" icon="ri-search-line" eventClick={getListaS} ></BasicButton>
                        }
                        {tipoinput == 2 &&
                            <>
                                <BasicButton value="BUSCAR" icon="ri-search-line" eventClick={getListaN} ></BasicButton>
                            </>}
                        {tipoinput == 1 &&
                            <>
                                <BasicButton value="BUSCAR" icon="ri-search-line" eventClick={getListaBN} ></BasicButton>
                            </>}
                    </div>
                </div>
                <div className="row">
                    <BasicTable listButtons={listaBotones()} head={head} body={lista} rowSet={absAcco.setBean} />
                </div>
            </BasicPage>

            {modal.view &&
                <Modal modal={modal} updateModal={setModal} eventModal={() => { createAcc() }}>
                    <div className="row">
                        <div className="col-12">
                            <EntityTypeConstantsEditor idSelector={absAcco.bean.EntityType} onChange={(e) => { absAcco.setBean({ ...absAcco.bean, "EntityType": e }) }} label='Tipo de Tercero' />
                        </div>
                        <div className="col-12">
                            <DocTypeConstantsEditor idSelector={absAcco.bean.DocType} onChange={(e) => { absAcco.setBean({ ...absAcco.bean, "DocType": e }) }} label='Tipo de Documento' />
                        </div>
                        <div className="col-12">
                            <label>NIT</label>
                            <input type="number" className='form-control' name='Nit' value={absAcco.bean.Nit} onChange={absAcco.handleChange} />
                        </div>
                    </div>
                </Modal>
            }
            {modalEdit.view &&
                <Modal modal={modalEdit} updateModal={setModalEdit} eventModal={() => { updateAbstracAccount() }}>
                    <>
                        {/* {absAcco.bean.EntityType !== 3 || absAcco.bean.EntityType !== 4 && */}
                        {!absAcco.bean.EntityType &&
                            <div className="col-12">
                                <EntityTypeConstantsEditor idSelector={absAcco.bean.EntityType} onChange={(e) => { absAcco.setBean({ ...absAcco.bean, "EntityType": e }) }} label='Tipo de Tercero' />
                            </div>
                        }
                        {absAcco.bean.EntityType == 3 &&
                            <CardTaps index={indexTap} updateIndex={setIndexTap} taps={tapsJuridica}>
                                {indexTap == 0 &&
                                    <div className="row">
                                        <div className="col-6">
                                            <label>Tipo de Entidad</label>
                                            <input className='form-control' value={absAcco.bean.EntityTypeName} disabled />
                                        </div>
                                        <div className="col-6">
                                            <label>No Documento</label>
                                            <input type="number" className='form-control' value={absAcco.bean.Nit} disabled />
                                        </div>
                                        <div className="col-6">
                                            <label>Digito de Verificacion</label>
                                            <input type="number" className='form-control' value={absAcco.bean.VerificationDigit} disabled />
                                        </div>
                                        <div className="col-6">
                                            <label>Razon Social</label>
                                            <input type="text" className='form-control' name='Name1' value={absAcco.bean.Name1} onChange={absAcco.handleChange} />
                                        </div>
                                    </div>
                                }
                                {indexTap == 1 &&
                                    <div className="row">
                                        <div className="col-6">
                                            <label>Domicilio</label>
                                            <SelectSiteID edit onChange={(e) => { absAcco.setBean({ ...absAcco.bean, "IDSite": e.IDLn }) }} idLnSite={absAcco.bean.IDSite} />
                                        </div>
                                        <div className="col-6">
                                            <label>Dirección</label>
                                            <input type="text" className='form-control' name='Address' value={absAcco.bean.Address} onChange={absAcco.handleChange} />
                                        </div>
                                        <div className="col-6">
                                            <label>Telefono</label>
                                            <input type="text" className='form-control' name='Tel' value={absAcco.bean.Tel} onChange={absAcco.handleChange} />
                                        </div>
                                        <div className="col-6">
                                            <label>Correo Electronico</label>
                                            <input type="text" className='form-control' name='eMail' value={absAcco.bean.eMail} onChange={absAcco.handleChange} />
                                        </div>
                                    </div>
                                }
                                {indexTap == 2 &&
                                    <div className="row">
                                        <div className="col-6">
                                            <BakingEntityEditor label='Entidad Bancaria' idSelector={absAcco.bean.BankingID} onChange={(e) => { absAcco.setBean({ ...absAcco.bean, "BankingID": e }) }} />
                                        </div>
                                        <div className="col-6">
                                            <BankingAccountEditor label='Tipo de Cuenta' idSelector={absAcco.bean.BankingAccountType} onChange={(e) => { absAcco.setBean({ ...absAcco.bean, "BankingAccountType": e }) }} />
                                        </div>
                                        <div className="col-6">
                                            <label>No Cuenta Bancaria</label>
                                            <input type="text" className='form-control' name='BankingAccountNumber' value={absAcco.bean.BankingAccountNumber} onChange={absAcco.handleChange} />
                                        </div>
                                        <div className="col-6">
                                            <BasicSwitch label='Responsable de Renta' estado={absAcco.bean.RentTax} eventChange={(e) => { absAcco.setBean({ ...absAcco.bean, "RentTax": e }) }} />
                                        </div>
                                        <div className="col-6">
                                            <FileInput docAcept='image/tiff, image/png , image/jpeg, image/gif, image/bmp' MediaContext={'photos'} directory='photos' Media={absAcco.bean.Photo} onCreate={(e) => { absAcco.setBean({ ...absAcco.bean, "Photo": e.Media }) }} label='Foto' />
                                        </div>
                                    </div>
                                }
                                {indexTap == 3 &&
                                    <ThirdBankingAccount isModal idAccount={absAcco.bean.IDAccount} />
                                }
                                <div className="col-6">
                                    <EntityTypeConstantsEditor disabled={true} label='Estado del Registro' idSelector={absAcco.bean.State} onChange={(e) => { absAcco.setBean({ ...absAcco.bean, "State": e }) }} />
                                </div>
                            </CardTaps>
                        }

                        {absAcco.bean.EntityType == 4 &&
                            <>
                                <CardTaps index={indexTap} updateIndex={setIndexTap} taps={tapsNatural}>
                                    {indexTap == 0 &&
                                        <div className="row">
                                            <div className="col-6">
                                                <label>Tipo de Entidad</label>
                                                <input className='form-control' value={absAcco.bean.EntityTypeName} disabled />
                                            </div>
                                            <div className="col-6">
                                                <label>No Documento</label>
                                                <input type="number" className='form-control' value={absAcco.bean.Nit} disabled />
                                            </div>
                                            <div className="col-6">
                                                <label>Digito de Verificacion</label>
                                                <input type="number" className='form-control' value={absAcco.bean.VerificationDigit} disabled />
                                            </div>
                                            <div className="col-6">
                                                <DocTypeConstantsEditor label='Tipo Documento' idSelector={absAcco.bean.DocType} onChange={(e) => { absAcco.setBean({ ...absAcco.bean, "DocType": e }) }} />
                                            </div>
                                            <div className="col-6">
                                                <label>Primer Nombre</label>
                                                <input type="text" className='form-control' name='Name1' value={absAcco.bean.Name1} onChange={absAcco.handleChange} />
                                            </div>
                                            <div className="col-6">
                                                <label>Segundo Nombre</label>
                                                <input type="text" className='form-control' name='Name2' value={absAcco.bean.Name2} onChange={absAcco.handleChange} />
                                            </div>
                                            <div className="col-6">
                                                <label>Primer Apellido</label>
                                                <input type="text" className='form-control' name='Surname1' value={absAcco.bean.Surname1} onChange={absAcco.handleChange} />
                                            </div>
                                            <div className="col-6">
                                                <label>Segundo Apellido</label>
                                                <input type="text" className='form-control' name='Surname2' value={absAcco.bean.Surname2} onChange={absAcco.handleChange} />
                                            </div>
                                            <div className="col-6">
                                                <label>Titulo</label>
                                                <input type="text" className='form-control' name='CommercialName' value={absAcco.bean.CommercialName} onChange={absAcco.handleChange} />
                                            </div>
                                            <div className="col-6">
                                                <label>Fecha Nacimiento</label>
                                                <InputDate name='Fecha Nacimiento' value={absAcco.bean.BornDate} setDate={(e) => { absAcco.setBean({ ...absAcco.bean, "BornDate": e }) }} />
                                            </div>
                                            <div className="col-6">
                                                <GenderTypeConstantsEditor label='Genero' idSelector={absAcco.bean.GenType} onChange={(e) => { absAcco.setBean({ ...absAcco.bean, "GenType": e }) }} />
                                            </div>
                                            <div className="col-6">
                                                <BloodTypeConstantsEditor label='Tipo de Sangre' idSelector={absAcco.bean.BloodType} onChange={(e) => { absAcco.setBean({ ...absAcco.bean, "BloodType": e }) }} />
                                            </div>
                                            <div className="col-6">
                                                <RhTypeConstantsEditor label='Tipo de RH' idSelector={absAcco.bean.RhType} onChange={(e) => { absAcco.setBean({ ...absAcco.bean, "RhType": e }) }} />
                                            </div>
                                        </div>
                                    }
                                    {indexTap == 1 &&
                                        <div className="row">
                                            <div className="col-6">
                                                <SelectSiteID label='Lugar de Nacimiento' edit onChange={(e) => { absAcco.setBean({ ...absAcco.bean, "IDBornSiteLn": e.IDLn }) }} idLnSite={absAcco.bean.IDBornSiteLn} />
                                            </div>
                                            <div className="col-6">
                                                <SelectSiteID label='Lugar de Residencia' edit onChange={(e) => { absAcco.setBean({ ...absAcco.bean, "IDResidenceSiteLn": e.IDLn }) }} idLnSite={absAcco.bean.IDResidenceSiteLn} />
                                            </div>
                                            <div className="col-6">
                                                <label>Dirección</label>
                                                <input type="text" className='form-control' name='Address' value={absAcco.bean.Address} onChange={absAcco.handleChange} />
                                            </div>
                                            <div className="col-6">
                                                <label>Telefono</label>
                                                <input type="text" className='form-control' name='Tel' value={absAcco.bean.Tel} onChange={absAcco.handleChange} />
                                            </div>
                                            <div className="col-6">
                                                <label>Correo Electronico</label>
                                                <input type="text" className='form-control' name='eMail' value={absAcco.bean.eMail} onChange={absAcco.handleChange} />
                                            </div>
                                        </div>
                                    }
                                    {indexTap == 2 &&
                                        <div className="row">
                                            <div className="col-6">
                                                <BakingEntityEditor label='Entidad Bancaria' idSelector={absAcco.bean.BankingID} onChange={(e) => { absAcco.setBean({ ...absAcco.bean, "BankingID": e }) }} />
                                            </div>
                                            <div className="col-6">
                                                <BankingAccountEditor label='Tipo de Cuenta' idSelector={absAcco.bean.BankingAccountType} onChange={(e) => { absAcco.setBean({ ...absAcco.bean, "BankingAccountType": e }) }} />
                                            </div>
                                            <div className="col-6">
                                                <label>No Cuenta Bancaria</label>
                                                <input type="text" className='form-control' name='BankingAccountNumber' value={absAcco.bean.BankingAccountNumber} onChange={absAcco.handleChange} />
                                            </div>
                                            <div className="col-6">
                                                <label>Dependencia</label>
                                                <input type="text" className='form-control' name='Dependency' value={absAcco.bean.Dependency} onChange={absAcco.handleChange} />
                                            </div>
                                            <div className="col-6">
                                                <label>Cargo</label>
                                                <input type="text" className='form-control' name='Chargement' value={absAcco.bean.Chargement} onChange={absAcco.handleChange} />
                                            </div>
                                            <div className="col-6">
                                                <div className="row">
                                                    <div className="col-8">
                                                        <AccountEditor idAccount={absAcco.bean.IDTaxDependentAccount} label='Dependiente para Impuestos' onChange={(e) => { absAcco.setBean({ ...absAcco.bean, "IDTaxDependentAccount": e.IDAccount }) }} />
                                                    </div>
                                                    <div className="col-4 d-flex align-items-end">
                                                        <BasicButton clase='danger' icon='ri-delete-back-2-line' eventClick={() => { absAcco.setBean({ ...absAcco.bean, "IDTaxDependentAccount": -1 }) }} >Eliminar</BasicButton>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <BasicSwitch label='Responsable de Renta' estado={absAcco.bean.RentTax} eventChange={(e) => { absAcco.setBean({ ...absAcco.bean, "RentTax": e }) }} />
                                            </div>
                                        </div>
                                    }
                                    {indexTap == 3 &&
                                        <div className="row">
                                            <div className="col-6">
                                                <FileInput docAcept='image/tiff, image/png , image/jpeg, image/gif, image/bmp' MediaContext={'photos'} directory='photos' Media={absAcco.bean.SignImage} onCreate={(e) => { absAcco.setBean({ ...absAcco.bean, "SignImage": e.Media }) }} label='Imágen de Firma Mecánica' />
                                            </div>
                                            <div className="col-6">
                                                <FileInput docAcept='image/tiff, image/png , image/jpeg, image/gif, image/bmp' MediaContext={'photos'} directory='photos' Media={absAcco.bean.Photo} onCreate={(e) => { absAcco.setBean({ ...absAcco.bean, "Photo": e.Media }) }} label='Foto' />
                                            </div>
                                            <div className="col-6">
                                                <MilitaryStatusConstantsEditor label='Estatus Militar' idSelector={absAcco.bean.MilitaryStatus} onChange={(e) => { absAcco.setBean({ ...absAcco.bean, "MilitaryStatus": e }) }} />
                                            </div>
                                            <div className="col-6">
                                                <CivilStatusConstantsEditor label='Estado Civil' idSelector={absAcco.bean.CivilStatus} onChange={(e) => { absAcco.setBean({ ...absAcco.bean, "CivilStatus": e }) }} />
                                            </div>
                                            <div className="col-6">
                                                <RaceTypeConstantsEditor label='Grupo Étnico' idSelector={absAcco.bean.IDEthnicGroup} onChange={(e) => { absAcco.setBean({ ...absAcco.bean, "IDEthnicGroup": e }) }} />
                                            </div>
                                            <div className="col-6">
                                                <HousingTypeConstantsEditor label='Tipo Vivienda' idSelector={absAcco.bean.HousingType} onChange={(e) => { absAcco.setBean({ ...absAcco.bean, "HousingType": e }) }} />
                                            </div>
                                            <div className="col-6">
                                                <StudiesTypeConstantsEditor label='Nivel de Estudios' idSelector={absAcco.bean.StudiesLevel} onChange={(e) => { absAcco.setBean({ ...absAcco.bean, "StudiesLevel": e }) }} />
                                            </div>

                                            <div className="col-6">
                                                <BasicSwitch label='Tiene Pasaporte' estado={absAcco.bean.Passport} eventChange={(e) => { absAcco.setBean({ ...absAcco.bean, "Passport": e }) }} />
                                            </div>
                                            <div className="col-6">
                                                <label>No Pasaporte</label>
                                                <input type="text" className='form-control' name='PassportNumber' value={absAcco.bean.PassportNumber} onChange={absAcco.handleChange} />
                                            </div>
                                            <div className="col-6">
                                                <label>Altura (CM)</label>
                                                <input type="text" className='form-control' name='Height' value={absAcco.bean.Height} onChange={absAcco.handleChange} />
                                            </div>
                                            <div className="col-6">
                                                <label>Peso (KG)</label>
                                                <input type="text" className='form-control' name='Weight' value={absAcco.bean.Weight} onChange={absAcco.handleChange} />
                                            </div>
                                            <div className="col-6">
                                                <label>Talla Pantalon</label>
                                                <input type="text" className='form-control' name='CapSize' value={absAcco.bean.CapSize} onChange={absAcco.handleChange} />
                                            </div>
                                            <div className="col-6">
                                                <label>Talla Zapatos</label>
                                                <input type="text" className='form-control' name='ShoesSize' value={absAcco.bean.ShoesSize} onChange={absAcco.handleChange} />
                                            </div>
                                            <div className="col-6">
                                                <label>Talla Camisa</label>
                                                <input type="text" className='form-control' name='DressSize' value={absAcco.bean.DressSize} onChange={absAcco.handleChange} />
                                            </div>
                                        </div>
                                    }
                                    {indexTap == 4 &&
                                        <ThirdBankingAccount isModal idAccount={absAcco.bean.IDAccount} />
                                    }
                                    {indexTap == 5 &&
                                        <CameraCapture directory='photos' uploadFile={(e) => {
                                            
                                            absAcco.setBean({ ...absAcco.bean, "Photo": e.Media })
                                        }} />
                                    }
                                    <div className="col-6">
                                        <EntityTypeConstantsEditor disabled={true} label='Estado del Registro' idSelector={absAcco.bean.State} onChange={(e) => { absAcco.setBean({ ...absAcco.bean, "State": e }) }} />
                                    </div>
                                </CardTaps>
                            </>
                        }
                    </>
                </Modal>
            }

        </>
    )
}