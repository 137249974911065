import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Singleton } from '../../../core/services/Singleton';
import { _single } from '../../../core/services/dataBean/EntityCatalog';


interface Props {
    key?: number,
    DataBeanProperties: any
}

export const PadreMenu = ({ DataBeanProperties }: Props) => {
    
    const [link, setLink] = useState('');
    const [Properties,setProperties] = useState<any>({Hijos:[]});

    useEffect(()=>{
        if(DataBeanProperties)
        {
            
            // DataBeanProperties.Hijos.forEach((item:any)=>{
            //     if(item.IDRemoteAbstractAction>0)
            //         item.URL2="/remote-abstract-action/"+btoa(JSON.stringify({
            //             ClassForName:item.ClassForName,
            //             IDRemoteAbstractAction:item.IDRemoteAbstractAction,
            //             Name:item.Name
            //         }));
            // })
            
            setProperties(DataBeanProperties);
        }
    },[DataBeanProperties])

    const getMenuURL=(item:any)=>{
        let url = item.URL;
        if(item.IDRemoteAbstractAction)
        {
            url="/remote-abstract-action/"+item.IDRemoteAbstractAction+"/"+btoa(JSON.stringify({
                ClassForName:item.ClassForName,
                IDRemoteAbstractAction:item.IDRemoteAbstractAction,
                Name:unescape(encodeURIComponent(item.Name)),                
                Key:_single.getRandomInt()
            }));

        }
        else{
            url+="/"+btoa(JSON.stringify({                
                Name:unescape(encodeURIComponent(item.Name)),
                IDLn:item.IDLn,
                Key:_single.getRandomInt()
            }));
        }
        return url;
    }

    return (

        <li style={{ listStyleType: 'none' }} className="nav-item" key={Properties.IDApplicationType}>
            <a className="nav-link menu-link title-link" key={Singleton.getInstance().getRandomInt()} href={'#sidebar' + Properties.IDApplicationType} data-bs-toggle="collapse" role="button" aria-expanded="false"
                aria-controls={'sidebar' + Properties.IDApplicationType}>
                <div className="row">
                    <div className="col">
                        <div style={{ display: 'flex' }}>
                            <div> <i className={`${Properties.ClassForName} align-middle me-2 colori`} key={'icone1'}></i></div>
                            <div>  <span className='mt-2' data-key={'t-' + Properties.IDApplicationType}>{Properties.Name}</span></div>
                        </div>
                    </div>
                </div>
            </a>
            <div className="collapse menu-dropdown" id={'sidebar' + Properties.IDApplicationType} >
                <ul className="nav nav-sm flex-column">
                    {Properties && Properties.Hijos.map((element: any, _i: number) => (
                        <li className={link === element.URL ? 'nav-item activeLink' : 'nav-item '} key={_i} style={{ margin: '0', padding: '0' }}
                            onClick={() => {
                                setLink(element.URL);              
                               //console.log(element);
                                                                                                                  
                            }}>
                            <NavLink key={_i} to={`/app${getMenuURL(element)}`} style={{ margin: '0', padding: '0' }} >
                                <div style={{ display: 'flex', marginLeft: '15px' }}>
                                    <div><i className='ri-arrow-drop-right-line' style={{ color: 'white' }}></i></div>
                                    <div> <span className='nav-link title-link-son' data-key={element.IDLn}>  {element.Name} </span></div>
                                </div>
                            </NavLink>
                        </li>
                    ))}
                </ul>
            </div>
        </li>

    );
}