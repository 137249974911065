
import { BsChevronCompactDown, BsFillArrowRightCircleFill } from 'react-icons/bs'
import { ReactNode, useEffect, useState } from 'react'
import { FaSyncAlt, FaTimes } from 'react-icons/fa';
import { Singleton } from '../../../core/services/Singleton';
import { BpmService } from '../../../core/services/BpmService';
import { Modal } from '../../theme/Component/Modal';
import { CourseTopicmodal } from './CourseTopicmodal';





export const Miscursos = () => {
    const [loading, setLoading] = useState(false);
    const single = Singleton.getInstance();
    const service = new BpmService();
    const [lista, setLista] = useState([]);
    const [modal, setModal] = useState<any>({ name: 'Temas del curso', tabIndex: -1, view: false, btnClose: false, btnSubmit: false, format: 'modal-lg' });
    const [user, setUser] = useState<any>({});
    const [bean, setBean] = useState<any>({});


    const idBean = 'IDCourse';

    useEffect(() => {
        setUser(single.getAccountID());
        getLista();
    }, [idBean])

    const getLista = () => {

        single.spinner(true);
        service.getCourseCatalogPorPropiedad('State', 1, null).subscribe(
            (resp: any) => {

                if (resp.DataBeanProperties.ObjectValue) {
                    setLista(resp.DataBeanProperties.ObjectValue);
                }
                single.spinner(false);
            }
        );
    }

    const abrirmodal = (Curso: any) => {
        setModal({ ...modal, "view": true });
        setBean(Curso)
       //console.log(Curso)
    }


    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <h3> Habilidades para avanzar</h3>


                    </div>

                    <div className="col-md-12 mt-4">

                        <p>La tecnología y el mundo laboral evolucionan muy rápido, pero con nosotros, podrás mantener el ritmo. Consigue los conocimientos que necesitas para lograr tus objetivos y garantizar la competitividad logrando mejorar tus Habilidades para tu presente (y tu futuro). Da tus primeros pasos con nosotros.</p>
                    </div>
                </div>
            </div>

            <div className="container-fluid">
                <div className="row">



                    {Array.isArray(lista) && lista.map((propiedadcurso: any, index: number) => {
                        return (
                            <>
                                <div className="col-md-6" key={index}>
                                    <div className="card" style={{ padding: '1rem', marginTop: '20px' }}>
                                        <div key={propiedadcurso.id}>


                                            <div className="card-header d-flex justify-content-between align-items-center">
                                                <span><strong style={{ color: '#003366' }}>{propiedadcurso.DataBeanProperties.Name}</strong> </span>
                                                <div>
                                                    {/* 
                                                    <FaTimes style={{ cursor: 'pointer' }} /> */}
                                                </div>
                                            </div>
                                            <div className="card-body-curso " >
                                                {propiedadcurso.DataBeanProperties.Description}
                                            </div>
                                        </div>
                                        <hr></hr>
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-md-4"><strong style={{ color: '#003366' }}>Finaliza:</strong></div>
                                                <div className="col-md-6"> {propiedadcurso.DataBeanProperties.Upto}

                                                </div>
                                                <div className="col-md-2">
                                                    <button className="new button" onClick={() => {
                                                        abrirmodal(propiedadcurso.DataBeanProperties)
                                                    }}>
                                                        <BsFillArrowRightCircleFill />
                                                    </button>
                                                </div>

                                            </div>
                                        </div>

                                    </div>


                                </div>
                            </>

                        )
                    })
                    }




                </div>
            </div>

            <Modal modal={modal} updateModal={setModal} >
                <>
                    <CourseTopicmodal IDCourse={bean.IDCourse} />

                </>
            </Modal>


        </>
    )
}
