import { create } from 'zustand'
import { Singleton } from '../../../../core/services/Singleton'
import { BpmService } from '../../../../core/services/BpmService';
import { BpmServiceall } from '../../../../core/services/BpmServiceall';
import { ModalGeneric } from '../../../../interfaces';
import { toast } from 'react-toastify';
import { useDashboardStore } from '../../../pages/hooks/useDashboardStore';

export interface Imp {
    idImp: number,
    impName: string
}

export interface ProcedureImp {
    IDAccount: number
    IDLnFunctionalID: number
    Name: string
    StateName: string
    IDProcedureImp: number
    IDEmployee: number
    AlphaCode: string
    ProcedureName: string
    BusinessProcessName: string
}

export interface ProcedureDoc {
    ProcedureActionName: string
    Description: string
    IDAction: number
    IsOptional: boolean
    Media: string
    MediaContext: string
    FormCode: string
}

export const APPROVAL_VIEW = '0'
export const ASSIGN_VIEW = '1'
export const MANAGE_VIEW = '2'
export const VERIFY_VIEW = '3'
export const STEPS_PROCESS_VIEW = '4'
export const ASSIGN_USER_VIEW = '5'
export const ASSIGN_TO_ME = 'ASSIGN_TO_ME'
export const MY_REQUEST = 'MY_REQUEST'
const modalC = {
    name: 'Asignación',
    tabIndex: -1,
    btnClose: true,
    btnSubmit: true,
    format: 'modal-sm',
    view: false
}

const modalDoc = {
    name: '',
    tabIndex: -1,
    btnClose: false,
    btnSubmit: false,
    format: 'modal-xl',
    view: true
}

interface InboxStore {
    impList: Imp[]
    officeSelected: number
    docSelected: ProcedureDoc | undefined
    procedureImpSelected: ProcedureImp | undefined
    userAssignList: []
    requestDocs: []
    activeTab: string
    activeMFilter: string
    reactiveView: string
    modalC: ModalGeneric
    modalDoc: ModalGeneric
    refreshList: boolean
    goNextStage: () => void
    onSelectDoc: (doc: ProcedureDoc) => void
    onSelectProcedure: (procedureImp: ProcedureImp) => void
    onChangeTab: (key: string) => void,
    onChangeMFilter: (key: string) => void,
    onSelectOffice: (idOffice: number) => void,
    onChangeReactive: (key: string) => void,
    getProcedureImpForAssign: (idAccount: number, idOffice: number) => void
    getProcedureImpForResponse: (idAccount: number) => void
    getProcedureImpForVerify: (idAccount: number) => void
    getProcedureImpByAccount: (idAccount: number) => void
    getProcedureActionForResponse: (idAccount: number, idProcedureImp: number, assign: boolean, fromClick: boolean) => void
    getProcedureActionForVerify: () => void
    assignProcedureToWorkGroup: (selectedUserToAssign: number, modalConfirm: ModalGeneric, setModalConfirm: React.Dispatch<React.SetStateAction<ModalGeneric>>) => void
    responseProcedureAction: (idProcedureImp: number, Map: any) => void
    verifyProcedureAction: () => void
    declineProcedureAction: (obs: string) => void
}

const service = new BpmService();
const bpm = new BpmServiceall();
const single = Singleton.getInstance()


export const useInboxStore = create<InboxStore>((set, get) => ({
    impList: [],
    userAssignList: [],
    requestDocs: [],
    officeSelected: -1,
    docSelected: undefined,
    procedureImpSelected: undefined,
    activeTab: ASSIGN_VIEW,
    activeMFilter: MY_REQUEST,
    modalC: modalC,
    modalDoc: modalDoc,
    reactiveView: STEPS_PROCESS_VIEW,
    refreshList: false,
    
    onChangeTab: (activeTab: string) => {
        set({ activeTab, procedureImpSelected: undefined, impList: [] });
    },
    onChangeMFilter: (activeMFilter: string) => {
        set({ activeMFilter, procedureImpSelected: undefined });
    },
    onSelectProcedure: (procedureImpSelected: ProcedureImp) => {
        set({ procedureImpSelected, reactiveView: STEPS_PROCESS_VIEW });
    },
    onSelectDoc: (docSelected: ProcedureDoc) => {
        set({ docSelected, reactiveView: VERIFY_VIEW });
    },
    onSelectOffice: (officeSelected: number) => {
        set({
            officeSelected,
            userAssignList: [],
            procedureImpSelected: undefined
        })
    },
    onChangeReactive: (view: string) => {
        if (view === ASSIGN_USER_VIEW) {
            single.spinner(true)
            bpm.getWorkGroupMemberCatalog(get().procedureImpSelected?.IDLnFunctionalID ?? 0, get().officeSelected)
                .subscribe((resp: any) => {
                    single.spinner(false)
                   //console.log("Se está ejecutando el 4", resp);
                    set({ userAssignList: resp.DataBeanProperties.ObjectValue })
                })
            set({ userAssignList: [] })
        }
        set({ reactiveView: view })
    },
    getProcedureImpForAssign: (idAccount: number, idOffice: number) => {
        single.spinner(true)
        service.getProcedureImpForAssign(idAccount, idOffice)
            .subscribe((resp: any) => {
                single.spinner(false)
               //console.log("Se está ejecutando el 1", resp);
                set({ impList: resp.DataBeanProperties.ObjectValue.DataBeanProperties.List })
            })
        set({ impList: [] })
    },
    getProcedureImpForResponse: (idAccount: number) => {
        single.spinner(true)
        service.getProcedureImpForResponse(idAccount)
            .subscribe((resp: any) => {
                single.spinner(false)
               //console.log("Se está ejecutando el 2", resp);
                set({ impList: resp.DataBeanProperties.ObjectValue })
            })
        set({ impList: [] })
    },
    getProcedureImpForVerify: (idAccount: number) => {
        single.spinner(true)
        service.getProcedureImpForVerify(idAccount)
            .subscribe((resp: any) => {
                single.spinner(false)
               //console.log("Se está ejecutando el 3", resp);
                set({ impList: resp.DataBeanProperties.ObjectValue })
            })
        set({ impList: [] })
    },
    getProcedureImpByAccount: (idAccount: number) => {
        single.spinner(true)
        service.getProcedureImpByAccount(idAccount)
            .subscribe((resp: any) => {
                single.spinner(false)
               //console.log("Se está ejecutando el 4", resp);
                set({ impList: resp.DataBeanProperties.ObjectValue })
            })
        set({ impList: [] })
    },
    assignProcedureToWorkGroup: (idAccount: number, modalConfirm: ModalGeneric, setModalConfirm: React.Dispatch<React.SetStateAction<ModalGeneric>>) => {
        single.spinner(true)
        bpm.assignProcedureImpToGroupMember(get().procedureImpSelected?.IDProcedureImp ?? 0, idAccount, single.getAccountID())
            .subscribe((resp: any) => {
                single.spinner(false)
                if (resp.DataBeanProperties.ObjectValue) {

                    /* Toda la lógica después de la asignación, avanzar bandeja básicamente */
                    set({ refreshList: !get().refreshList, procedureImpSelected: undefined }) // Para que el use Effect dispare el refresh de la lista
                    setModalConfirm({ ...modalConfirm, view: false })
                    toast.success('Se ha completado la asignación')
                }
            })
    },
    getProcedureActionForResponse: (idAccount, idProcedureImp, assign, fromClick) => {
        single.spinner(true)
        service.getProcedureActionForResponse(idAccount, idProcedureImp, assign)
            .subscribe((resp: any) => {
                single.spinner(false)

                /* Si ya no existen acciones (docs, form, services) que ejecutar, 
                avisar que no hay accion */

                if (resp.DataBeanProperties.ObjectValue.length > 0) {
                    set((state) => ({ ...state, reactiveView: MANAGE_VIEW, requestDocs: resp.DataBeanProperties.ObjectValue }))
                } else {
                    if (fromClick) {
                        //INFORMAR
                        toast.info('⏱ Proceso en espera por gestión!')
                    } else {
                        toast.success('🚀 Proceso Completado!')
                        set({ procedureImpSelected: undefined, refreshList: !get().refreshList })
                    }
                }
            })
        set({ requestDocs: [] })
    },
    goNextStage: () => {
        single.spinner(true)
        service.goNextStage(get().procedureImpSelected?.IDProcedureImp ?? 0)
            .subscribe((resp: any) => {
                if (resp.DataBeanProperties.ObjectValue) {
                    toast.success('🚀 Proceso Completado!')
                    set({ procedureImpSelected: undefined, refreshList: !get().refreshList })
                }
            })

    },
    getProcedureActionForVerify: () => {
        single.spinner(true)
        service.getProcedureActionForVerify(get().procedureImpSelected?.IDEmployee ?? 0, get().procedureImpSelected?.IDProcedureImp ?? 0)
            .subscribe((resp: any) => {
                if (resp.DataBeanProperties.ObjectValue.length > 0) {
                    single.spinner(false)
                    set((state) => ({ ...state, requestDocs: resp.DataBeanProperties.ObjectValue, reactiveView: STEPS_PROCESS_VIEW }))
                } else {
                    toast.success('🚀 Proceso Completado!')
                    set({ procedureImpSelected: undefined, refreshList: !get().refreshList })
                }
            })
    },
    responseProcedureAction: (idProcedureImp: number, Map: any) => {
        single.spinner(true)
        const pI = get().procedureImpSelected
        service.responseProcedureAction(idProcedureImp, Map)
            .subscribe((resp: any) => {
                if (resp.DataBeanProperties.ObjectValue.DataBeanProperties) {
                    if (resp.DataBeanProperties.ObjectValue) {
                        toast.success('Acción completada correctamente')
                        get().activeMFilter === ASSIGN_TO_ME
                            ? get().getProcedureActionForResponse(pI?.IDEmployee ?? 0, pI?.IDProcedureImp ?? 0, true, false)
                            : get().getProcedureActionForResponse(pI?.IDAccount ?? 0, pI?.IDProcedureImp ?? 0, false, false)
                    }
                }
                single.spinner(false)
            })
    },
    verifyProcedureAction: () => {
       //console.log(get().docSelected);
        single.spinner(true)
        service.verifyProcedureAction(get().docSelected?.IDAction ?? 0)
            .subscribe((resp: any) => {
                single.spinner(false)
                if (resp.DataBeanProperties.ObjectValue) {
                    /* Actualizar lista de documentos */
                    toast.success('Aprobación completada!')
                    get().getProcedureActionForVerify()
                } else {
                    toast.error('Algo salió mal ⚠')
                }
            })
    },
    declineProcedureAction: (obs: string) => {
        single.spinner(true)
        service.declineProcedureAction(get().docSelected?.IDAction ?? 0, obs)
            .subscribe((resp: any) => {
                single.spinner(false)
                if (resp.DataBeanProperties.ObjectValue) {
                    get().getProcedureActionForVerify()
                    toast.dismiss('Se ha rechazado el documento!')
                } else {
                    toast.error('Algo salió mal ⚠')
                }
            })
    }
}))
