import { useEffect, useState } from "react";
import { _DaoInfo } from "../../core/services/dataBean/EntityCatalog";
import useDataBean from "../../core/services/dataBean/useDataBean";
import { DataBeanProperties } from '../../core/services/model/server-response.interface';
import { PrintJson } from "../theme/Component/PrintJson";
import { BasicButton } from "../theme/Component/BasicButton";

interface Props {
    classForName: string;
    daoclassForName: string;
}
export const DaoUtil = ({ classForName ,daoclassForName}: Props) => {

    const service = useDataBean(_DaoInfo);


    const [response, setResponse] = useState<any>({});
    const [clase, setClase] = useState<string>('');
    const [claseDao, setClaseDao] = useState<string>('');

    useEffect(() => {

        setClase(classForName);
    }, [classForName])

    useEffect(() => {

        setClaseDao(daoclassForName);
    }, [daoclassForName])


    const rebuild = () => {
        service.instance.rebuildAppDeplyment([clase]).then(
            (resp: any) => {
                setResponse(resp);
            }
        )
    }
    const rebuildDao = () => {
        service.instance.rebuildDaoInfo([claseDao]).then(
            (resp: any) => {
                setResponse(resp);
            }
        )
    }

    return (
        <div className="row">
             <div className="col-md-6">
                <h6>Recostruir base de datos</h6>
                <div className="col-md-12">
                        <label>classForName</label>
                        <input type="text" className="form-control" value={claseDao} onChange={(e) => { setClaseDao(e.target.value) }} />
                    </div>
                    <div className="col-md-6 ">
                        <BasicButton icon="ri-refresh-line" eventClick={rebuildDao}>Rebuild</BasicButton>
                    </div>
            </div>
            <div className="col-md-6">
                <h6>Recostruir Servicios</h6>
                <div className="row">

                    <div className="col-md-12">
                        <label>classForName</label>
                        <input type="text" className="form-control" value={clase} onChange={(e) => { setClase(e.target.value) }} />
                    </div>
                    <div className="col-md-6 ">
                        <BasicButton icon="ri-refresh-line" eventClick={rebuild}>Rebuild</BasicButton>
                    </div>
                    
                </div>
            </div>
           
            <div className="col-md-12 mt-4">
                <hr />
                        <PrintJson json={response} />
                    </div>
        </div>
    )
}