import React, { useState, useEffect } from 'react';
import { eliminarCookies } from '../../../utils/helpers';
import { BpmService } from '../../../core/services/BpmService';
import { useDashboardStore } from '../../pages/hooks/useDashboardStore';

interface Props {
  login: boolean;
  setIsLogged: Function;
}

const SessionTimeout = ({ login, setIsLogged }: Props) => {

  const { setLoading } = useDashboardStore();
  const service = new BpmService();
  const [sessionDuration, setDuracion] = useState(60 * 5);
  const [timeRemaining, setTimeRemaining] = useState(sessionDuration);
  const [shouldResetSession, setShouldResetSession] = useState(false);

  //  useEffect(()=>{
  //   //console.log(timeRemaining);

  //  },[timeRemaining])

  useEffect(() => {
    getProperty();
  }, [])

  useEffect(() => {
    const interval = setInterval(() => {
      if (login && timeRemaining > 0) {
        setTimeRemaining(prevTime => prevTime - 1);

        const sessionStartTime = localStorage.getItem('sessionStartTime');
        if (sessionStartTime) {
          const currentTime = Math.floor(Date.now() / 1000);
          const elapsedTime = currentTime - parseInt(sessionStartTime);
          const maxDuration = sessionDuration * 60;
          if (elapsedTime >= maxDuration) {
            closeSession();
          }
        }
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [login, timeRemaining]);

  useEffect(() => {
    const sessionStartTime = localStorage.getItem('sessionStartTime');
    if (sessionStartTime) {
      const currentTime = Math.floor(Date.now() / 1000);
      const elapsedTime = currentTime - parseInt(sessionStartTime);
      const remainingTime = sessionDuration - elapsedTime;
      if (remainingTime > 0) {
        setTimeRemaining(remainingTime);
      } else {
        closeSession();
      }
    } else {
      localStorage.setItem('sessionStartTime', Math.floor(Date.now() / 1000).toString());
    }
  }, []);

  useEffect(() => {
    const handleMouseMove = () => {
      ////console.log("Se movio el mouse");
      setShouldResetSession(true);
    };

    const handleClick = () => {
      ////console.log("Se clickeo el mouse");
      setShouldResetSession(true);
    };

    const handleKeyPress = () => {
      setShouldResetSession(true);
    };

    const handleTouchMove = () => {
      setShouldResetSession(true);
    }

    window.addEventListener('touchmove', handleTouchMove);
    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('click', handleClick);
    window.addEventListener('keypress', handleKeyPress);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('click', handleClick);
      window.removeEventListener('keypress', handleKeyPress);
    };
  }, []);

  useEffect(() => {
    if (timeRemaining === 0) {
      closeSession();
    }

    if (shouldResetSession === true) {
      resetSesion();
    }
  }, [timeRemaining, shouldResetSession]);

  const minutes = Math.floor(timeRemaining / 60);
  const seconds = timeRemaining % 60;

  const closeSession = () => {
    logout();
  };

  const resetSesion = () => {
    setTimeRemaining(sessionDuration);
    setShouldResetSession(false);
    localStorage.setItem('sessionStartTime', Math.floor(Date.now() / 1000).toString());
  };

  const logout = () => {
    localStorage.clear();
    eliminarCookies();
    setIsLogged(false);
    window.location.reload();
  }

  // Inico de la cofiguracion de las propiedades del sistema de la cual va a depender el tiempo limite de la sesion

  const getProperty = () => {
    setLoading(true);
    service.getSystemPropertyValue("time_session").subscribe(
      (resp: any) => {

        if (resp.DataBeanProperties.ObjectValue) {
         //console.log(resp.DataBeanProperties.ObjectValue);
          let tiempo: number = parseInt(resp.DataBeanProperties.ObjectValue);
         //console.log("nuevo tiempo ", tiempo);

          setDuracion(tiempo * 60);

        }
        setLoading(false);
      }
    );
  }

  return (
    <>
      {timeRemaining < 45 && login && (
        <div className='fixed-top' style={{ marginTop: '5%' }}>
          <div className='row' style={{ display: 'flex', justifyContent: "center" }}>
            <div className='col-md-6'>
              <div className='alert alert-warning text-center ' role='alert'>
                <p>
                  <strong>Su sesión está pronta a finalizar</strong> <br />Tiempo restante: {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
                </p>
              </div>
            </div>
          </div>
        </div >
      )}
    </>
  );
};

export default SessionTimeout;
