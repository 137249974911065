import { useState, useEffect } from 'react';
import { FinanceService } from '../../../../core/services/FinanceService';

interface Props {
    idSelector: number;
    onChange: any;
    label?: string;
}
export const SelectBudgetIDType = ({ idSelector, onChange, label }: Props) => {

    const service = new FinanceService();
    const [lista, setLista] = useState([]);
    const [id, setID] = useState(NaN);



    useEffect(() => {


        setID(idSelector);
        getLista();

    }, [])
    useEffect(() => {

        onChange(id);

    }, [id])

    const setValue = (e: any) => {
        setID(parseInt(e));
        // onChange(id);
    }

    const getLista = () => {



        service.getBudgetIDTypeCatalog(null).subscribe(
            (resp: any) => {

               //console.log("BudgetType ", resp);

                if (resp.DataBeanProperties.ObjectValue) {
                    const response = resp.DataBeanProperties.ObjectValue;
                    setLista(response);
                    if (response.length > 0) {
                        setID(response[0].DataBeanProperties.IDBudgetIDType)
                    }
                }
            }
        );
    }

    return (
        <>
            <label>{label ?? "Tipos de Catálogo Presupuestal"}</label>
            <select className="form-select" value={id} onChange={(e) => { setValue(e.target.value) }}>
                {/* <option value={0}>...</option> */}
                {lista &&
                    lista.map((item: any) => {
                        return (
                            <>
                                <option key={item.DataBeanProperties.IDBudgetIDType} value={item.DataBeanProperties.IDBudgetIDType} >{item.DataBeanProperties.Name}</option>
                            </>)
                    })
                }
            </select>
        </>
    )
}