import { useState, useEffect } from 'react';
import useDataBean from '../../../../core/services/dataBean/useDataBean';
import { _EmployeeDiscountType } from '../../../../core/services/dataBean/EntityCatalog';

interface Props {
    idSelector: number;
    onChange: any;
    label?: string;
}

export const EmployeeDiscountTypeEditor = ({ idSelector, onChange, label }: Props) => {
    const EmployeeDiscountType = useDataBean(_EmployeeDiscountType);
    const [lista, setLista] = useState<any>([]);
    const [selectedItem, setSelectedItem] = useState<any>(null);

    useEffect(() => {
        if (idSelector >= 0) {
            const foundItem = lista.find((item: any) => item.DataBeanProperties.IDType === idSelector);
            if (foundItem) {
                setSelectedItem(foundItem);
            }
        }
    }, [idSelector, lista]);

    useEffect(() => {
        getLista();
    }, []);

    useEffect(() => {
        if (selectedItem) {
            onChange(selectedItem.DataBeanProperties);
        }
    }, [selectedItem]);

    const setValue = (e: any) => {
        const selectedID = e.target.value === "" ? null : parseInt(e.target.value);
        const foundItem = lista.find((item: any) => item.DataBeanProperties.IDType === selectedID);
        setSelectedItem(foundItem || null);
    };

    const getLista = () => {
        EmployeeDiscountType.instance.getEmployeeDiscountTypeCatalog().then((resp: any) => {
            if (resp) {
                setLista(resp);
               //console.log(resp);
            }
        });
    };

    return (
        <>
            <label>{label ?? "Tipo de Descuento"}</label>
            <select className="form-select" value={selectedItem?.DataBeanProperties.IDType || ''} onChange={(e) => setValue(e)}>
                <option value="">...</option>
                {lista &&
                    lista.map((item: any, index: number) => (
                        <option key={index} value={item.DataBeanProperties.IDType}>
                            {item.DataBeanProperties.Name}
                        </option>
                    ))
                }
            </select>
        </>
    );
};
