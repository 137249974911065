import { useEffect, useState } from "react";
import { AdminService } from "../../../core/services/AdminService";
import { Singleton } from "../../../core/services/Singleton";
import { BasicTable } from "../../theme/Component/BasicTable";
import { DataBeanProperties, ObjectValue } from '../../../core/services/model/server-response.interface';

interface Props{
    idDocumentTemplate:number
}
export const DictionaryWords=({idDocumentTemplate}:Props)=>{

    const single = Singleton.getInstance();
    const service = new AdminService();
    const [bean,setBean] = useState<any>({});

    const [lista,setLista] = useState<any>([]);

    useEffect(()=>{
        getDictionaryWords();
    },[idDocumentTemplate])

    const getDictionaryWords=()=>{
        single.spinner(true);
        service.getDictionaryWords(idDocumentTemplate).subscribe(
            (resp:any)=>{
               //console.log(resp);
                
                if(resp.DataBeanProperties.ObjectValue)
                {
                    let list = resp.DataBeanProperties.ObjectValue;
                    
                    
                    list.forEach((element:any) => {
                            element.DataBeanProperties.Prop = element.DataBeanProperties.Property;
                            // element.DataBeanProperties.Prop = `${element.DataBeanProperties.Property.replace(/[<>]/g, '')} `;
                    });
                   //console.log(list);
                    setLista(list);
                }
                else{
                    setLista([]);
                }
                single.spinner(false);
            }
        )
    }

    const head = [
        {title:'Campo',property:'Value',visible:true},
        {title:'Nombre Propiedad',property:'Prop',visible:true},
        
    ]

    return(
        <div className="row">
            <div className="col">
                <BasicTable body={lista} head={head} rowSet={setBean}></BasicTable>
            </div>
        </div>
    )
}