import { useState, useEffect } from 'react';
import { ContractService } from "../../../../core/services/ContractService";
import { Singleton } from "../../../../core/services/Singleton";
import { BasicTable } from '../../../theme/Component/BasicTable';
import { ModalConfirm } from '../../../theme/Component/ModalConfirm';
import { Modal } from '../../../theme/Component/Modal';
import { BasicButton } from '../../../theme/Component/BasicButton';
import { SelectorTramites } from './SelectorTramites';
import { ListMenu } from '../../../theme/Component/ListMenu';
import { useDashboardStore } from '../../../pages/hooks/useDashboardStore';

interface Props {
    IDOffice: number;
}

export const Listatramitesoffice = ({ IDOffice, }: Props) => {

    const single = Singleton.getInstance()
    const { setLoading } = useDashboardStore();
    const service = new ContractService();
    const [lista, setLista] = useState([]);
    const [listaselect, setListaselect] = useState([]);
    const [idModule, setIDModule] = useState(0);
    const [bean, setBean] = useState<any>({});
    const [modal, setModal] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-md' });
    const [modalConfirm, setModalConfirm] = useState<any>({ name: 'Confirmar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });

    // Llave primaria del bean    

    const idBean = 'IDOfficeBusinessProcess';

    const head = [
        { title: 'ID', property: idBean },
        { title: 'Nombre del tramite', property: 'BusinessProcessName' },
    ]

    const head1 = [
        { title: 'ID', property: 'IDBusinessProcess' },
        { title: 'Nombre del tramite', property: 'Name' },
        { title: 'Código', property: 'Code' },
    ]

    useEffect(() => {
        getLista();
    }, [IDOffice])


    useEffect(() => {
        getListadelect();
    }, [idModule])

    const setRowBean = (bean: any) => {
       //console.log("Row : ", bean);
        setBean(bean);
    }

    const getLista = () => {

        setLoading(true);
        service.getOfficeBusinessProcessCatalog(IDOffice).subscribe(
            (resp: any) => {


                if (resp.DataBeanProperties.ObjectValue) {
                    setLista(resp.DataBeanProperties.ObjectValue);
                }

                setLoading(false);
            }
        );
    }


    const agregarItem = () => {

        let b = {
            Name: '',
            ResponseClass: "",
            LimitedValues: "",
            LimitedWithValues: false,
            Validated: false,
            JsonServiceName: null,
            IDJsonService: null,

        }

        setBean(b);
        setModal({ ...modal, 'view': true });

    }

    const eliminarItem = () => {

        setLoading(true);
        service.removeOfficeToBusinessOffice(bean.IDBusinessProcess, IDOffice).subscribe(
            (resp: any) => {
                setLoading(false);
                if (resp.DataBeanProperties.ObjectValue) {
                    getLista();
                }
            }
        );
        setModalConfirm({ ...modalConfirm, 'view': false });
    }

    const updateItem = () => {

        setModal({ ...modal, 'view': false });

        setLoading(true);
        service.addOfficeToBusinessProcess(bean.IDBusinessProcess, IDOffice).subscribe(
            (resp: any) => {
                setLoading(false);
                if (resp.DataBeanProperties.ObjectValue) {
                    getLista();
                }
            });
    }


    const getListadelect = () => {
        setLoading(true);
        service.getBusinessProcessCatalog(null, null, null, idModule, null, null).subscribe(
            (resp: any) => {

                if (resp.DataBeanProperties.ObjectValue) {
                    setListaselect(resp.DataBeanProperties.ObjectValue);
                }
                setLoading(false)
            }
        );
    }

    const iconButtonsItem = [
        { nombre: 'Agregar', visible: single.canCreate(), icono: 'ri-file-add-line', evento: agregarItem }
    ]

    const listaBotones = () => {
        const botones = [
            // { titulo: 'Editar', icono: 'ri-file-list-line', visible: true, evento: () => { setModal({ ...modal, "view": true }) } },
            { titulo: 'Eliminar', icono: 'ri-file-user-line', visible: true, evento: () => { setModalConfirm({ ...modalConfirm, "view": true }) } },
        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }

    return (
        <>

            <BasicTable listButtons={listaBotones()} iconButtons={iconButtonsItem} head={head} body={lista} rowSet={setRowBean}></BasicTable>


            <ModalConfirm modal={modalConfirm} updateModal={setModalConfirm} eventModal={eliminarItem}>
                {bean.BusinessProcessName}
            </ModalConfirm>

            <Modal modal={modal} updateModal={setModal} eventModal={updateItem}>
                <div className="row">
                    <div className="col-md-12">
                        <SelectorTramites titulo="Clase de tramites" idSelector={idModule} onChange={(e: any) => { setIDModule(e) }} />
                        {idModule > 0 &&
                            <BasicTable head={head1} body={listaselect} rowSet={setRowBean}></BasicTable>
                        }
                    </div>
                </div>
            </Modal>
        </>
    )
}