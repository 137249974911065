import { useEffect, useState } from "react";
import { BasicButton } from "../../../theme/Component/BasicButton"
import { EstudioPrevioNatural } from "./EstudioPrevioNatural";
import { EstudioPrevioJuridico } from "./EstudioPrevioJuridico";
import { Alert } from "../../../theme/Component/Alert";
import { ContractService } from "../../../../core/services/ContractService";
import { useDashboardStore } from "../../../pages/hooks/useDashboardStore";
import { BpmService } from "../../../../core/services/BpmService";
import { PrintJson } from "../../../theme/Component/PrintJson";
import { Singleton } from "../../../../core/services/Singleton";

interface Props {
    adquisitioItem: any;
    vista: number;
    setVista: any;
    rol: number;
    _idArea?: number;
    updateMap: any;
}
const service = new ContractService();
const _service = new BpmService();
export const EstudioPrevio = ({ adquisitioItem, vista, setVista, rol, _idArea, updateMap }: Props) => {

    const { setLoading } = useDashboardStore();
    const [item, setItem] = useState<any>({});
    const [estudio, setEstudio] = useState<any>({});
    const [listItems, setListItems] = useState<number>(null);

    // InitStructure general

    const initEstructure = {
        Dependence: _idArea,
        Justification: adquisitioItem.Justification,
        ObjectContract: adquisitioItem.Description,
        requestType: 0,
        Coverage: '',
        ObligationList: '',
        Recruitment: {
            Name: '',
            Trainning: '',
            Exp: '',
        },
        ObligationText: '',
        Term: '',
        Factor: '',
        SST: '',
        ItemArticles: '',
        RiskMatrix: []
    }

    //Servicios
    useEffect(() => {
        if (adquisitioItem.IDHiringProcess) {
            getPreviousStudyCatalogPorPropiedad()
        }
    }, [adquisitioItem])

    // useEffect(() => {



    // }, [estudio])

    const setBodyItems = (estudio: any) => {
        if (estudio.Body) {
            const prevBody = JSON.parse(estudio.Body ?? '{}');
           //console.log("prevBody => ", prevBody);
           //console.log("prevBody => ", item);

            prevBody.Dependence = _idArea;
            setItem(prevBody);
            if (prevBody.RiskMatrix.length > 0) {
                setItem({ ...prevBody, RiskMatrix: prevBody.RiskMatrix })
            } else {
                getRiskFactorTemplete()
            }
        }
    }

    const getAdquisitionArticleCatalog = () => {
        service.getAdquisitionArticleCatalog(adquisitioItem.IDAdquisitionItem, null, null, null).subscribe(
            (resp: any) => {
                setListItems(resp.DataBeanProperties.ObjectValue.DataBeanProperties.List.length)
            }
        );
    }

    const getRiskFactorTemplete = () => {
        // setLoading(true);
        _service.getRiskMatrixTemplete().subscribe(
            (resp: any) => {
               //console.log("Matriz del riego => ", resp);

                if (resp.DataBeanProperties.ObjectValue) {
                   //console.log(JSON.parse(resp.DataBeanProperties.ObjectValue[0]));
                    setItem({ ...item, RiskMatrix: JSON.parse(resp.DataBeanProperties.ObjectValue[0]) })
                }
                setLoading(false);
            }
        );
    }

    const getPreviousStudyCatalogPorPropiedad = () => {
        // setLoading(true);
       //console.log(adquisitioItem);

        service.getPreviousStudyCatalogPorPropiedad("IDHiringProcess", adquisitioItem.IDHiringProcess, null).subscribe(
            (resp: any) => {
                if (resp.DataBeanProperties.ObjectValue) {
                    let items = resp.DataBeanProperties.ObjectValue;
                    if (items.length > 0) {
                        setEstudio(items[0].DataBeanProperties);
                        setBodyItems(items[0].DataBeanProperties);


                    }
                    else {
                        updatePreviousStudy({
                            IDHiringProcess: adquisitioItem.IDHiringProcess,
                            Body: JSON.stringify(initEstructure),
                            TypePerson: estudio.TypePerson
                        });
                    }
                }
                setLoading(false);
            }
        )
    }

    const updatePreviousStudy = (bean: any) => {
        // setLoading(true);
        if (bean.IDHiringProcess) {
            service.updatePreviousStudy(bean).subscribe(
                (resp: any) => {
                    if (resp.DataBeanProperties.ObjectValue) {
                        let item = resp.DataBeanProperties.ObjectValue;
                        setEstudio(item.DataBeanProperties);
                        setBodyItems(item.DataBeanProperties);
                    }

                }
            )
        }

    }
    const updateExport = () => {
        updateMap(estudio);
    }

    return (
        <>
            <div className="row">
                <div className="col-md-12">
                    <div className="row mb-3 d-flex justify-content-center">
                        <div className="col-md-2">
                            <label>Tipo de Contratación</label>
                        </div>
                        <div className="col-md-4">
                            <select className="form-control" value={estudio.TypePerson} onChange={(e) => { setEstudio({ ...estudio, "TypePerson": parseInt(e.target.value) }) }}>
                                <option value={null}>...</option>
                                <option value={11}>Persona Natural</option>
                                <option value={10}>Persona Juridica</option>
                            </select>
                        </div>
                    </div>
                    {/* <PrintJson json={item} /> */}
                    {(estudio.TypePerson == 11 && estudio.IDHiringProcess) &&
                        <EstudioPrevioNatural onChange={() => { getAdquisitionArticleCatalog() }} riskMatrixData={{ risks: item.RiskMatrix }} _Study={estudio} _item={item} adquisitioItem={adquisitioItem} rol={rol} ></EstudioPrevioNatural>
                    }
                    {estudio.TypePerson == 10 && estudio.IDHiringProcess &&
                        <EstudioPrevioJuridico onChange={() => { getAdquisitionArticleCatalog() }} riskMatrixData={{ risks: item.RiskMatrix }} _Study={estudio} _item={item} adquisitioItem={adquisitioItem} rol={rol}></EstudioPrevioJuridico>
                    }
                    {estudio.TypePerson != 10 && estudio.TypePerson != 11 &&
                        <>
                            <div className="row">
                                <div className="col-md-12" style={{ display: "flex", justifyContent: "center" }}>
                                    <Alert clase="warning">
                                        <strong>Seleccione un tipo de contratación.</strong>
                                    </Alert>
                                </div>
                            </div>
                        </>
                    }
                </div>
                <div className="col-md-12">
                    <BasicButton disable={!listItems} icon=' bx bx-send' clase="success" eventClick={updateExport}>Enviar para revisión</BasicButton>
                </div>
            </div>
        </>
    )
}