import { useEffect, useState } from "react";
import { _CompetitionType, _Entailment, _MacroProcess } from "../../../../core/services/dataBean/EntityCatalog";

import { Singleton } from "../../../../core/services/Singleton";
import { toast } from "react-toastify";
import { ListMenu } from "../../../theme/Component/ListMenu";
import { BasicPage } from "../../../theme/Component/BasicPage";
import { BasicTable } from "../../../theme/Component/BasicTable";
import { Modal } from "../../../theme/Component/Modal";
import { ModalConfirm } from "../../../theme/Component/ModalConfirm";
import useDataBean from "../../../../core/services/dataBean/useDataBean";
import { Submacroprocces } from "./Submacroprocces";



export const MacroProcess = () => {

    const MacroProcess = useDataBean(_MacroProcess);

    const single = Singleton.getInstance();

    const [lista, setLista] = useState<any>([]);
    const [modalsubprocces, setModalsubprocces] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modal, setModal] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-md' });
    const [modalC, setModalC] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });

    useEffect(() => {
        getLista();
    }, [])

    const getLista = () => {

        MacroProcess.instance.getMacroProcessCatalog().then(
            (resp: any) => {
                if (resp) {
                    setLista(resp)
                   //console.log(resp)
                }
            }
        )

    }

    const create = () => {
        MacroProcess.setBean({

        });
        setModal({ ...modal, "name": "Agregar Macro Proceso", "view": true });
    }

    const deleteItem = () => {
        MacroProcess.instance.deleteMacroProcess(MacroProcess.bean).then(
            (resp: any) => {
                getLista();
                setModalC({ ...modalC, "view": false });
            }
        )

    }

    const editaItem = () => {
       //console.log(MacroProcess.bean);
        MacroProcess.update().then(
            (resp: any) => {
                getLista();
                setModal({ ...modal, "view": false });
            }
        ).catch(err => {
            toast.error(err);
        })

    }

    const head = [
        { title: 'ID', property: 'IDCompetitionType', visible: true },
        { title: 'Nombre', property: 'Name', visible: true },
        { title: 'Descripción', property: 'Description', visible: true },

    ]

    const iconButtonsItem = [
        { nombre: 'Agregar', visible: single.canCreate(), icono: 'ri-file-add-line', evento: create }
    ]
    const listaBotones = () => {
        const botones = [
            { titulo: 'Sub Procesos', icono: 'ri-file-user-line', visible: true, evento: () => { setModalsubprocces({ ...modalsubprocces, "name": `${MacroProcess.bean.Name} `, "view": true }) } },
            { titulo: 'Editar', icono: 'ri-edit-2-line', visible: single.canEdit(), evento: () => { setModal({ ...modal, "view": true }) } },
            { titulo: 'Eliminar', icono: 'ri-delete-bin-line', visible: single.canDelete(), evento: () => { setModalC({ ...modalC, "view": true }) } },

        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }


    return (
        <BasicPage titulo=" Macroprocesos ">
            <div className="row">
                <div className="col-md-12">

                    <BasicTable body={lista} head={head} rowSet={MacroProcess.setBean} listButtons={listaBotones()} iconButtons={iconButtonsItem}></BasicTable>
                    <Modal modal={modal} updateModal={setModal} eventModal={editaItem}>
                        <div className="row">
                            <div className="col-md-12">
                                <label>Nombre</label>
                                <input type="text" name="Name" className="form-control" value={MacroProcess.bean.Name} onChange={MacroProcess.handleChange} />
                                {/* <SelectFunctionalID renderValue="" onChange={()=>{}}/> */}
                            </div>
                            <div className="col-md-12">
                                <label>Description</label>
                                <textarea name="Description" className="form-control" value={MacroProcess.bean.Description} onChange={MacroProcess.handleChange} />
                            </div>

                        </div>
                    </Modal>
                    <Modal modal={modalsubprocces} updateModal={setModalsubprocces} eventModal={editaItem}>
                        <Submacroprocces IDMacroProcess={MacroProcess.bean.IDMacroProcess} />
                    </Modal>
                    <ModalConfirm modal={modalC} updateModal={setModalC} eventModal={deleteItem}>
                        <strong>Eliminar item</strong>
                    </ModalConfirm>
                </div>

            </div>
        </BasicPage>
    )
}