import { useEffect, useState } from "react";
import useDataBean from "../../../../core/services/dataBean/useDataBean";
import { Singleton } from "../../../../core/services/Singleton";
import { toast } from "react-toastify";
import { ListMenu } from "../../../theme/Component/ListMenu";
import { BasicPage } from "../../../theme/Component/BasicPage";
import { BasicTable, MASK_NUMBER } from "../../../theme/Component/BasicTable";
import { Modal } from "../../../theme/Component/Modal";
import { ModalConfirm } from "../../../theme/Component/ModalConfirm";
import { _TaxSchemeContext } from "../../../../core/services/dataBean/EntityCatalog";
import { SelectAccountantTree } from "../../../contabilidad/catalogo-cuentas/AccountantTree/SelectAccountantTree";
import { AccountEditor } from "../../../editor/AccountEditor";
import { SelectSiteID } from "../../configuracion/SiteID/SelectSiteID";
import { TaxCodeSchemeEditor } from "../TaxCodeScheme/TaxCodeScheme.editor";
import { TaxSchemeContextStateEditor } from "../TaxScheme/TaxSchemeContextState.editor";
import { BasicSwitch } from "../../../theme/Component/BasicSwitch";



export const TaxSchemeContext = () => {

    const taxScheme = useDataBean(_TaxSchemeContext);
    const single = Singleton.getInstance();
    const [lista, setLista] = useState<any>([]);
    const [modal, setModal] = useState<any>({ tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });
    const [modalC, setModalC] = useState<any>({ name: "Eliminar", tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });

    useEffect(() => {
        getLista();
    }, [])

    const getLista = () => {

        taxScheme.instance.getTaxSchemeContextCatalog(null).then(
            (resp: any) => {
                if (resp) {
                    setLista(resp)
                }
            }
        )

    }

    const create = () => {
        taxScheme.setBean({
            IDAccount: single.getAccountID(),
        });
        setModal({ ...modal, "view": true, "name": `Tipos de Esquemas de Impuestos por Entidad Recaudadora` });
    }

    const deleteItem = () => {
        taxScheme.deleteItemVoid(taxScheme.bean.IDTaxSchemeContext).then(
            (resp: any) => {
                getLista();
                setModalC({ ...modalC, "view": false });
            }
        )

    }
    const editaItem = () => {
       //console.log(taxScheme.bean);
        taxScheme.update().then(
            (resp: any) => {
                getLista();
                setModal({ ...modal, "view": false });
            }
        ).catch(err => {
            toast.error(err);
        })
    }

    const head = [
        { title: 'ID', property: 'IDTaxSchemeContext', visible: true, mask: MASK_NUMBER },
        { title: 'Código', property: 'Code', visible: true },
        { title: 'Nombre', property: 'Name', visible: true },
        { title: 'Descripción', property: 'Description', visible: true, Style: { width: "25vw" } },
        { title: 'Entidad Recaudadora', property: 'AccountName', visible: true },
        { title: 'Cuenta Contable Retención/Descuento', property: 'AccountantIDName', visible: true },
        { title: 'Cuenta Contable Vr Agregado', property: 'AccountantIDAddedValueName', visible: true },
        { title: 'Ubicación Geográfica', property: 'SiteIDName', visible: true },
        { title: 'Estado', property: 'StateName', visible: true },
        { title: 'Esquema de Códigos de Clasificación', property: 'TaxCodeSchemeName', visible: true },
        { title: 'Aplicar Unicamente a Ubicación Geográfica', property: 'ApplyOnlyToSiteID', visible: true },
    ]

    const iconButtonsItem = [
        { nombre: 'Agregar', visible: single.canCreate(), icono: 'ri-file-add-line', evento: create }
    ]
    const listaBotones = () => {
        const botones = [
            { titulo: 'Editar', icono: 'ri-edit-2-line', visible: single.canEdit(), evento: () => { setModal({ ...modal, "name": `Editar ${taxScheme.bean.Name}`, "view": true }) } },
            { titulo: 'Eliminar', icono: 'ri-delete-bin-line', visible: single.canDelete(), evento: () => { setModalC({ ...modalC, "view": true }) } },
        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }


    return (
        <>
            <BasicPage titulo="Tipos de Esquemas de Impuestos por Entidad Recaudadora">
                <div className="row">
                    <div className="col-md-12">
                        <BasicTable body={lista} head={head} rowSet={taxScheme.setBean} listButtons={listaBotones()} iconButtons={iconButtonsItem}></BasicTable>
                    </div>
                </div>
            </BasicPage>
            <Modal modal={modal} updateModal={setModal} eventModal={editaItem}>
                <div className="row">
                    <div className="col-6">
                        <label>Nombre</label>
                        <input type="text" name="Name" className="form-control" value={taxScheme.bean.Name} onChange={taxScheme.handleChange} />
                    </div>
                    <div className="col-6">
                        <label>Código</label>
                        <input type="text" name="Code" className="form-control" value={taxScheme.bean.Code} onChange={taxScheme.handleChange} />
                    </div>
                    <div className="col-12">
                        <label>Descripción</label>
                        <textarea name="Description" className="form-control" value={taxScheme.bean.Description} onChange={taxScheme.handleChange} />
                    </div>
                    <div className="col-6">
                        <TaxCodeSchemeEditor idSelector={taxScheme.bean.IDTaxCodeScheme} onChange={(e) => { taxScheme.setBean({ ...taxScheme.bean, "IDTaxCodeScheme": e }) }} />
                    </div>
                    <div className="col-6">
                        <AccountEditor label="Entidad Recaudadora" idAccount={taxScheme.bean.IDAccount} onChange={(e: any) => { taxScheme.setBean({ ...taxScheme.bean, "IDAccount": e.IDAccount }) }} />
                    </div>
                    <div className="col-6">
                        <SelectAccountantTree label="Cuenta Contable Retención/Descuento" jsonCode={taxScheme.bean.AccountantIDStr} onChange={(e) => { taxScheme.setBean({ ...taxScheme.bean, "AccountantIDStr": e.JsonCode }) }} />
                    </div>
                    <div className="col-6">
                        <SelectAccountantTree label="Cuenta Contable Vr Agregado" jsonCode={taxScheme.bean.AccountantIDAddedValueStr} onChange={(e) => { taxScheme.setBean({ ...taxScheme.bean, "AccountantIDAddedValueStr": e.JsonCode }) }} />
                    </div>
                    <div className="col-6">
                        <SelectSiteID label="Ubicación Geográfica" edit idLnSite={taxScheme.bean.IDLnSiteID} onChange={(e) => taxScheme.setBean({ ...taxScheme.bean, "IDLnSiteID": e.IDLn })} />
                    </div>
                    <div className="col-6">
                        <TaxSchemeContextStateEditor idSelector={taxScheme.bean.State} onChange={(e) => { taxScheme.setBean({ ...taxScheme.bean, "State": e }) }} />
                    </div>
                    <div className="col-6">
                        <BasicSwitch label="Aplicar Unicamente a Ubicación Geográfica" estado={taxScheme.bean.State} eventChange={(e) => { taxScheme.setBean({ ...taxScheme.bean, "ApplyOnlyToSiteID": e }) }} />
                    </div>
                </div>
            </Modal>

            <ModalConfirm modal={modalC} updateModal={setModalC} eventModal={deleteItem}>
                <strong>Eliminar item {taxScheme.bean.Name}</strong>
            </ModalConfirm>
        </>
    )
}