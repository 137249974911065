import { useEffect, useState } from "react";
import { Singleton } from "../../../core/services/Singleton"
import { TalentService } from "../../../core/services/TalentService";
import { useDashboardStore } from "../../pages/hooks/useDashboardStore";
import { BasicPage } from "../../theme/Component/BasicPage"
import { ListMenu } from "../../theme/Component/ListMenu";
import { BasicTable } from "../../theme/Component/BasicTable";
import { ModalConfirm } from "../../theme/Component/ModalConfirm";
import { Modal } from "../../theme/Component/Modal";

import { AccountEditor } from "../../editor/AccountEditor";
import { ContractEditor } from "../../erp/cellEditor/ContractEditor";
import { SelectFunctionalID } from "../../admin/configuracion/functionalID/SelectFunctionalID";

import { SelectTHConstantsValue } from "../../bpm/config/Componentes/SelectTHConstantsValue";
import { InputDate } from "../../theme/Input/InputDate";
import { toast } from "react-toastify";
import { PrintJson } from "../../theme/Component/PrintJson";




export const EducationalSupport = () => {

    const single = Singleton.getInstance()
    const { setLoading } = useDashboardStore();
    const talentService = new TalentService();
    const [lista, setLista] = useState([]);
    const [bean, setBean] = useState<any>({});
    const [modal, setModal] = useState<any>({ name: 'Editar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-lg' });

    const [modalConfirm, setModalConfirm] = useState<any>({ name: 'Confirmar', tabIndex: -1, view: false, btnClose: true, btnSubmit: true, format: 'modal-sm' });


    const idBean = 'IDEducationalSupport';

    const head = [
        { title: 'ID', property: idBean, visible: false },
        { title: 'Numero radicado', property: 'FiledNumber' },
        { title: 'Fecha Inicial', property: 'StartDate' },
        { title: 'Fecha Fin', property: 'EndDate' },
        { title: 'Funcionario', property: 'EntityName' },
        // { title: 'Nombre Contrato', property: 'IDContract' },
        { title: 'Área / Dependencia', property: 'Name', visible: false },
        { title: 'Programa', property: 'IDProgramName' },
        { title: 'Formación', property: 'IDTrainingTypeName' },
        { title: 'Entidad Educativa', property: 'IDEducationalEntityName' },
        { title: 'Periodo Academico', property: 'IDAcademicPeriodName' },
        { title: 'Calificación', property: 'Cualification' },
        { title: 'Apoyo Educativo', property: 'Amount' },
        { title: '% de apoyo', property: 'PercentAmount' },
        { title: 'fecha de comite', property: 'CommitteeDate' },
        { title: 'Estado', property: 'SupportStatus' },
        // { title: 'Fecha registro', property: 'Since' },


        // // { title: 'Registro', property: 'State' },
        // { title: 'Empleado', property: 'IDEmployee' },
        // { title: 'Fecha actualizacion', property: 'UpdateDate' },
    ]

    useEffect(() => {

        getLista();
    }, [idBean])

    const setRowBean = (bean: any) => {

        setBean(bean);
    }

    const getLista = () => {

        setLoading(true);
        let maps = {
            State: 1
        }
        talentService.getEducationalSupportCatalogRenderPorPropiedades(maps, null, null).subscribe(
            (resp: any) => {

                if (resp.DataBeanProperties.ObjectValue) {
                    setLista(resp.DataBeanProperties.ObjectValue);
                }
                setLoading(false);
            }
        );
    }

    const agregarItem = () => {
        let b = {
            IDAccount: 0,
            IDContract: 0,
            FuntionalID: 0,
            IDProgram: 0,
            State: 1,
            IDTrainingType: 0,
            IDEducationalEntity: 0,
            IDAcademicPeriod: 0,
            Cualification: 0,
            Amount: 0,
            PercentAmount: 0,
            CommitteeDate: single.getCurrenDate(),
            StartDate: single.getCurrenDate(),
            EndDate: single.getCurrenDate(),
            FiledNumber: '',
            AmountPercent: 0,
            SupportStatus: 42,
            IDEmployee: single.getAccountID()

        }
        setBean(b);
        setModal({ ...modal, ['view']: true });

    }

    const eliminarItem = () => {
        bean.State = 2;
       //console.log(bean);
        updateItem();
        setModalConfirm({ ...modalConfirm, ['view']: false });
    }

    // const updateItem = () => {
    //     setModal({ ...modal, ['view']: false });
    //     setLoading(true);
    //     talentService.updateEducationalSupport(bean).subscribe(
    //         (resp: any) => {
    //             setLoading(false);
    //             if (resp.DataBeanProperties.ObjectValue) {
    //                 getLista();
    //             }
    //             single.update(bean);
    //         }
    //     );
    // }

    const updateItem = () => {
        bean.UpdateDate = single.getCurrenDate();
        bean.IDEmployee = single.getAccountID();

        if (bean.StartDate) {
            let d = new Date(bean.StartDate);
            bean.MonthSupport = d.getMonth();
            bean.YearSupport = d.getFullYear();
        }



        if (bean.IDContract > 0) {
            setModal({ ...modal, ['view']: false });
            setLoading(true);
            talentService.updateEducationalSupport(bean).subscribe(
                (resp: any) => {
                    setLoading(false);
                    if (resp.DataBeanProperties.ObjectValue) {
                        getLista();
                    }
                    single.update(bean);
                }
            );
        } else {

            toast.error('El funcionario no cuenta con un contrato de trabajo')
        }
    }

    const listaBotones = () => {
        const botones = [

            { titulo: 'Editar', icono: 'ri-file-list-line', visible: true, evento: () => { setModal({ ...modal, "view": true }) } },
            { titulo: 'Eliminar', icono: 'ri-file-user-line', visible: true, evento: () => { setModalConfirm({ ...modalConfirm, "view": true }) } },


        ]
        return (
            <ListMenu listaBotones={botones} />
        )
    }

    const iconButtonsItem = [
        { nombre: 'Agregar', visible: single.canCreate(), icono: 'ri-file-add-line', evento: agregarItem }
    ]

    return (
        <>
            <BasicPage titulo='Apoyo Educativo'>
                <div className="row">
                    <div className="col-md-12 p-2">
                        <BasicTable listButtons={listaBotones()} iconButtons={iconButtonsItem} head={head} body={lista} rowSet={setRowBean}></BasicTable>
                    </div>
                </div>
            </BasicPage>

            <ModalConfirm modal={modalConfirm} updateModal={setModalConfirm} eventModal={eliminarItem}>
                {bean.Name}
            </ModalConfirm>

            <Modal modal={modal} updateModal={setModal} eventModal={updateItem}>
                <div className="row">
                    <div className="col-md-12">
                        <AccountEditor idAccount={bean.IDAccount} label="Nombre del Funcionario" onChange={({ IDAccount }: any) => { setBean({ ...bean, "IDAccount": IDAccount }) }} />
                    </div>
                    <div className="col-md-6">
                        <ContractEditor idAccount={bean.IDAccount} onChange={(IDContract: any) => { setBean({ ...bean, "IDContract": IDContract }) }} />
                    </div>
                    <div className="col-md-6">
                        <SelectFunctionalID functionalIDLn={bean.FuntionalID} onChange={(e: any) => { setBean({ ...bean, "FuntionalID": e.IDLn }) }} />
                    </div>
                    <div className="col-md-6">
                        <label className="form-label">Nombre del Programa</label>
                        <SelectTHConstantsValue code={101} value={bean.IDProgram} onChange={(e: any) => { setBean({ ...bean, "IDProgram": e }) }}></SelectTHConstantsValue>
                    </div>
                    <div className="col-md-6">
                        <label className="form-label">Tipo de Formación</label>
                        <SelectTHConstantsValue code={102} value={bean.IDTrainingType} onChange={(e: any) => { setBean({ ...bean, "IDTrainingType": e }) }}></SelectTHConstantsValue>
                    </div>
                    <div className="col-md-6">
                        <label className="form-label">Entidad Educativa</label>
                        <SelectTHConstantsValue code={103} value={bean.IDEducationalEntity} onChange={(e: any) => { setBean({ ...bean, "IDEducationalEntity": e }) }}></SelectTHConstantsValue>
                    </div>
                    <div className="col-md-6">
                        <label className="form-label">Periodo Académico / Semestre</label>
                        <SelectTHConstantsValue code={104} value={bean.IDAcademicPeriod} onChange={(e: any) => { setBean({ ...bean, "IDAcademicPeriod": e }) }}></SelectTHConstantsValue>
                    </div>
                    <div className="col-md-6">
                        <label className="form-label">Notas Semestre Anterior</label>
                        <input type="number" className="form-control" value={bean.Cualification} onChange={(e) => { setBean({ ...bean, ['Cualification']: e.target.value }) }} />
                    </div>
                    <div className="col-md-6">
                        <label className="form-label">Valor Semestre</label>
                        <input type="number" className="form-control" value={bean.Amount} onChange={(e) => { setBean({ ...bean, ['Amount']: e.target.value }) }} />
                    </div>
                    <div className="col-md-6">
                        <label className="form-label">% Otorgado</label>
                        <input type="number" className="form-control" value={bean.PercentAmount} onChange={(e) => { setBean({ ...bean, ['PercentAmount']: e.target.value }) }} />
                    </div>
                    <div className="col-md-6">
                        <InputDate name="desde" label="Fecha de Comite" value={bean.CommitteeDate} setDate={(e: any) => { setBean({ ...bean, "CommitteeDate": e }) }} />
                    </div>
                    <div className="col-md-12">
                        <label className="form-label">Valor Apoyo Educativo</label>
                        <input type="number" className="form-control" value={bean.AmountPercent} onChange={(e) => { setBean({ ...bean, ['AmountPercent']: e.target.value }) }} />

                    </div>
                    <div className="col-md-12">
                        <label className="form-label">Estado programado</label>
                        <SelectTHConstantsValue code={105} value={bean.SupportStatus} onChange={(e: any) => { setBean({ ...bean, "SupportStatus": e }) }}></SelectTHConstantsValue>
                    </div>


                    <div className="col-md-6">
                        <InputDate name="desde" label="Fecha de Inicio cobertura" value={bean.StartDate} setDate={(e: any) => { setBean({ ...bean, "StartDate": e }) }} />
                    </div>
                    <div className="col-md-6">
                        <InputDate name="hasta" label="Fecha de Finalización cobertura" value={bean.EndDate} setDate={(e: any) => { setBean({ ...bean, "EndDate": e }) }} />
                    </div>
                    <div className="col-md-6">
                        <label className="form-label">Radicado</label>
                        <input type="text" className="form-control" value={bean.FiledNumber} onChange={(e) => { setBean({ ...bean, ['FiledNumber']: e.target.value }) }} />
                    </div>
                </div>
            </Modal>

            {/* <Modal modal={modalTHC} updateModal={setModalTHC}>
                <div className="row">
                    <div className="col-md-12">
                        <THConstantsValue idTHConstants={bean[idBean]} />SupportStatus
                    </div>
                </div>
            </Modal> */}

        </>
    )
}